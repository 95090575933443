import React from 'react';
import {Divider, Flex} from "antd";
import UserAvatar from "../../../../components/misc/UserAvatar";
import styled from "styled-components";
import useSystemBreakpoint from "../../../../hooks/useSystemBreakpoint";
import NewIdeaButton from "./NewIdeaButton";
import FilterIdeaButton from "./FilterIdeaButton";
import {useUserContext} from "../../../../context/UserContext";

const WriteIdeaContainer = styled(Flex)`
    width: 100%;
    max-width: 746px;
`

function NewIdeaSection({form, handleSearchOrFilter}) {
    const {isPhone} = useSystemBreakpoint()
    const {_user} = useUserContext()

    return (
        <>
            <WriteIdeaContainer vertical={true}>
                <WriteIdeaContainer gap={8} align="center" style={{marginBottom: isPhone && 24}}>
                    <UserAvatar data={_user}/>
                    <NewIdeaButton/>
                    <FilterIdeaButton form={form} handleSearchOrFilter={handleSearchOrFilter}/>
                </WriteIdeaContainer>
                <Divider style={{background:"#F0F2F5", borderBlockStart: "1px solid white", margin: isPhone && "0px"}}/>
            </WriteIdeaContainer>
        </>
    );
}

export default NewIdeaSection;