import React from 'react'
import styled from 'styled-components'
import {MinusCircleOutlined, CloseCircleOutlined, CheckCircleOutlined} from '@ant-design/icons'
import {Typography} from "antd";
import {FlexContainer} from "../authentication/StyledComponents";
import {
    dust_red_1, dust_red_4,
    neutral_3,sunset_orange_4,
    polar_green_1, polar_green_6,
    sunset_orange_1
} from "../../utils/Colors";

const StyledFlexContainer = styled(FlexContainer)`
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: white;
    padding: 16px;

    //&:not(:hover)::-webkit-scrollbar {
    //    display: none;
    //}

`


export default function HorizontalScrolling({
    gap=8,
                                          children,
                                          ...props
                                      }) {


    return (

        <StyledFlexContainer gap={gap} {...props}>
            {children}
        </StyledFlexContainer>

    );
}