import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Button from "../../components/data_entry/Button";
import {Col, Input, Row, Tooltip} from "antd";
import {DownloadOutlined, PlusOutlined} from "@ant-design/icons";
import {GLOBAL_PATHS} from "../../utils/RouteUtils";
import {
    useLayoutContext
} from "../../../modules/project/components/layout/context/LayoutContext";
import ProgramTable from "../../../modules/program/components/ProgramTable";
import {CardContainer} from "../../components/misc/CardContainer";
import {FlexContainer} from "../../components/authentication/StyledComponents";
import DynamicDropdown from "../../components/data_entry/DynamicDropdown";
import RemixIcon from '../../components/data_display/RemixIcon';


export default function ManagerPrograms() {
    const [search, setSearch] = useState("")
    const {t} = useTranslation();
    const history = useHistory();
    const {initializeLayout} = useLayoutContext();
    const [bodyRequest, setBodyRequest] = useState({status__in: ['active']})
    const [isExportLoading, setIsExportLoading] = useState(false);
    const getOrder = useRef();

    function handleRowClick(record) {
        history.push(`/programs/${record.id}/phases`)
    }

    function handleExport() {
        const detail = {
            setIsLoading: setIsExportLoading,
            search,
            body: bodyRequest,
            order: getOrder.current,
        }
        const EVENT_NAME = 'generate-program-list';
        const event = new CustomEvent(EVENT_NAME, {detail});
        dispatchEvent(event);
    }

    const handleActive = (item) => {
        setBodyRequest({status__in: item.value})
    }

    useEffect(() => {
        initializeLayout({
            navbarMenuItems: [
                {
                    label: <Link to={``}>{t("Manage Programs")}</Link>,
                    key: `/programs`
                },
            ]
        });
    }, []);

    return (
      <CardContainer>
        <Row gutter={[0, 16]} justify="end">
          <FlexContainer gap={8}>
            <Input.Search
              onSearch={(value) => setSearch(value)}
              allowClear
              data-cy="search-program-input"
            />

            <DynamicDropdown
              title=""
              items={[
                { label: t("Actives"), key: "1", value: ["active"] },
                {
                  label: t("Inactive/Archived"),
                  key: "2",
                  value: ["inactive", "archived"],
                },
              ]}
              handleChange={handleActive}
            />

            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                history.push(GLOBAL_PATHS.TEMPLATES_PROGRAM);
              }}
              data-cy="new-program-button"
            >
              {t("New program")}
            </Button>
            <Tooltip title={t("Export")}>
              <Button loading={isExportLoading} type='default' onClick={handleExport}>
                <RemixIcon remixIconName={"ri-download-cloud-line"} />
              </Button>
            </Tooltip>
          </FlexContainer>

          <Col xs={24}>
            <ProgramTable
              url={"programs/home"}
              onRowClick={handleRowClick}
              search={search}
              bodyRequest={bodyRequest}
              getOrder={getOrder}
            />
          </Col>
        </Row>
      </CardContainer>
    );
}
