import {useTranslation} from "react-i18next";
import {Link, useRouteMatch} from "react-router-dom";
import ExtraHeader from "../../../../modules/project/components/layout/ExtraHeader";
import React, {useEffect} from "react";
import {
    useLayoutContext
} from "../../../../modules/project/components/layout/context/LayoutContext";
import ProgramForm from "./ProgramForm/ProgramForm";

export default function ProgramCreate(props) {
    const {t} = useTranslation();
    const {initializeLayout,} = useLayoutContext();

    useEffect(() => {
        initializeLayout({
            extraHeaderContent: <ExtraHeader breadcrumbItems={[
                {label: t("Programs"), key: 1},
                {label: t("Create"), key: 2},
            ]}/>,
            showAvatar: false,
            navbarMenuItems: [
                {
                    label: <Link to={`/programs/create`}>{t("Program")}</Link>,
                    key: `/programs/create`
                },
            ]
        });
    }, []);

    // return <Program {...props} />
    return <ProgramForm {...props} isCreating={true} />
}