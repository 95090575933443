import {useEffect, useState} from "react";
import api from "../../services/api";
import Select from "./Select";
import {useTranslation} from "react-i18next";

export default function RequestSelect({
                                          url,
                                          valueField = "id",
                                          labelField,
                                          formatLabel,
                                          dataAccessor,
                                          showSearch = true,
                                          translate = true,
                                          version = "v1",
                                          preserveData = false,
                                          isChoiceArray = false,
                                          onLoadValue,
                                          value,
                                          onChange,
                                          defaultActiveFirstOption,
                                          ...props
                                      }) {

    const {t} = useTranslation()
    const [options, setOptions] = useState([]);

    formatLabel = formatLabel ? formatLabel : (item, defaultValue) => defaultValue;

    const getFormattedLabel = (item) => {
        if (isChoiceArray) {
            if (typeof item === "string") {
                return translate ? t(item) : item
            }
        }
        return translate ? t(item[1]) : item[1];
    }

    const getValue = (item) => {
        if (isChoiceArray) {
            if (typeof item === "string") {
                return item;
            }
            return item[0];
        }
        return item[valueField];
    }


    const getChoice = (item) => {
        return {
            label: formatLabel(item, getFormattedLabel(item)),
            value: getValue(item),
            data: preserveData ? item : undefined,
        }
    }

    const fetchData = async () => {
        try {
            const response = await api.get(url, {version});
            let data
            if (dataAccessor) {
                data = response[dataAccessor].map((item) => getChoice(item))
            } else {
                data = response.data.map((item) => getChoice(item));
            }
            if (!value && defaultActiveFirstOption) {
                onChange(data[0]?.value);
            }
            setOptions(data);
        } catch (error) {
        }
    }

    useEffect(() => {
        url && void fetchData();
    }, [url]);

    useEffect(() => {
        if (value && options.length > 0 && onLoadValue) {
            const option = options.find((option) => option.value === value);
            onLoadValue(option);
        }
    }, [value, options]);

    return (
        <Select options={options} showSearch={showSearch}
                onChange={onChange}
                value={value}
                {...props}
        />
    );
}

