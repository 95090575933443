import React, {useEffect, useRef, useState} from "react";
import Drawer from "../../../../components/layout/Drawer";
import HeaderMessagesTopicOffCanvas from "./HeaderMessagesTopicOffCanvas";
import Chat from "../../../../components/other/chat/Chat";
import api from "../../../../services/api";
import {BuildFieldErrorsDict} from "../../../../utils/Utils";


export default function MessagesTopicOffCanvas({topic_id, open, showGoToProgram, ...props}) {


    const refOffCanvas = useRef();
    const [topic, setTopic] = useState()
    const [isLoading, setIsLoading] = useState(true)

    async function fetchTopic(search = '') {
        try {
            setIsLoading(true)
            const response = await api.get(`/topics/${topic_id}`,);
            setTopic(response.data)

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        if (topic_id) {
            void fetchTopic()
        }
    }, [topic_id])
    useEffect(() => {
        if (!open) {
            setTopic(null)
        }
    }, [open])


    return (

        <Drawer ref={refOffCanvas} width={1000} open={open}
                loading={isLoading}
                title={<HeaderMessagesTopicOffCanvas topic={topic} showGoToProgram={showGoToProgram}/>}
                bodyStyle={{
                    background: '#FAFAFA'
                }}
                {...props}
        >
            <Chat topic={topic}/>
        </Drawer>

    );
}

