import {Card, Col, Form as FormAnt, Row, Space} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import SectionScoring from "./components/SectionScoring";
import SectionScoreDistributionAuthors
    from "./components/SectionScoreDistributionAuthors";
import SectionScoreDistributionEvaluator
    from "./components/SectionScoreDistributionEvaluator";
import SectionRanking from "./components/SectionRanking";
import HelpButton from "./components/HelpButton";
import FixedFooter from "../../../../components/layout/FixedFooter";
import Button from "../../../../components/data_entry/Button";
import {
    FlexContainer
} from "../../../../components/authentication/StyledComponents";
import useDefaultGetRequest from "../../../../hooks/useDefaultGetRequest";
import Loading from "../../../../components/feedback/Loading";
import Form from "../../../../components/data_entry/form/Form";
import api from "../../../../services/api";
import {
    ToastNotification
} from "../../../../components/feedback/ToastNotification";
import * as ConstantsMessage from "../../../../utils/fileWithMessage";
import {BuildFieldErrorsDict} from "../../../../utils/Utils";
import {
    useLayoutContext
} from "../../../../../modules/project/components/layout/context/LayoutContext";
import SectionStore from "./components/SectionStore";
import {
    APROVED_APPLICATION_GAMIFICATION,
    CLASSIFIED_APPLICATION_GAMIFICATION, FINISHED_ASSESSMENTS_GAMIFICATION,
    SUBMIT_APPLICATION_GAMIFICATION
} from "../../../../utils/Constants";


const FORM_NAMES = {
    MANUAL_POINTS_VALIDATION: 'manual_points_validation',
    DAYS_TO_VALIDATE_POINTS: 'days_to_validate_points',

    SUBMIT_APPLICATION: SUBMIT_APPLICATION_GAMIFICATION,
    CLASSIFIED_APPLICATION: CLASSIFIED_APPLICATION_GAMIFICATION,
    APROVED_APPLICATION: APROVED_APPLICATION_GAMIFICATION,
    FINISHED_ASSESSMENTS: FINISHED_ASSESSMENTS_GAMIFICATION,

    SHOW_RANKING_IN_HOME: 'show_ranking_in_home',
    RANKINGS_ACTIVATED: 'rankings_activated',


    STORE_ACTIVATED: 'store_activated',
    MANUAL_SHOPPING_CONFIRMATION: 'manual_shopping_confirmation',
}

const DYNAMICS_ITEMS_FORM_NAME = [
    FORM_NAMES.SUBMIT_APPLICATION,
    FORM_NAMES.CLASSIFIED_APPLICATION,
    FORM_NAMES.APROVED_APPLICATION,
    FORM_NAMES.FINISHED_ASSESSMENTS,
]


export default function GamificationPage({gamificationData, isLoadingGamification}) {
    const {t} = useTranslation();
    const [form] = FormAnt.useForm()
    const [isLoading, setIsLoading] = useState()
    const {updateWindown} = useLayoutContext()



    useEffect(() => {
        function handleInitialValues() {
            if (gamificationData) {
                const dinamicItems = {}
                DYNAMICS_ITEMS_FORM_NAME.forEach((key) => {
                    dinamicItems[key] = gamificationData?.points?.find(({type_action}) => type_action === key)?.points
                })
                form.setFieldsValue({...gamificationData, ...dinamicItems})
            }
        }

        handleInitialValues()
    }, [gamificationData]);

    async function getValuesForm() {
        let data = await form.getFieldsValue()
        const newData = {...data}
        const points = []
        DYNAMICS_ITEMS_FORM_NAME.forEach((key) => {
            points.push({points: newData[key], type_action: key})
            delete newData[key]
        })
        newData.points_settings_list = points
        return newData
    }

    async function handleSubmit() {
        try {
            setIsLoading(true);
            const data = await getValuesForm()
            const response = await api.patch(`/gamification_settings/${gamificationData.id}`, data);

            ToastNotification(t(ConstantsMessage.SUCCESS));

            updateWindown()

        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, null);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Form form={form} onFinish={handleSubmit} disabled={isLoading}>

                <Row gutter={[16, 16]} style={{marginBottom: 60}}>
                    <Col xs={24}>
                        <HelpButton/>

                    </Col>
                    {isLoadingGamification ? <Col xs={24}><Loading/></Col> :
                        (<>
                            <Col xs={24}>
                                <SectionScoring FORM_NAMES={FORM_NAMES}/>
                            </Col>
                            <Col xs={24}>
                                <SectionScoreDistributionAuthors
                                    FORM_NAMES={FORM_NAMES}/>

                            </Col>
                            <Col xs={24}>
                                <SectionScoreDistributionEvaluator
                                    FORM_NAMES={FORM_NAMES}/>
                            </Col>
                            <Col xs={24}>
                                <SectionRanking FORM_NAMES={FORM_NAMES}/>
                            </Col>
                              <Col xs={24}>
                                <SectionStore FORM_NAMES={FORM_NAMES} form={form}/>
                            </Col>
                        </>)}


                </Row> </Form>
            <FixedFooter>
                <Card bodyStyle={{padding: '12px 24px'}}>
                    <FlexContainer justify={'end'}>
                        <Button
                            data-cy="submit-gamification-settings-button"
                            onClick={form.submit}
                            loading={isLoadingGamification || isLoading}>{t('Save')}</Button>
                    </FlexContainer>
                </Card>

            </FixedFooter>

        </>

    );
}