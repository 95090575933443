import React from 'react'
import styled from 'styled-components'
import {Space, Typography} from "antd";
import SilviaImage from '../../../../assets/img/silvia.png'

import {
    FlexContainer,
    GrayContainer
} from "../../authentication/StyledComponents";
import UserAvatar from "../../misc/UserAvatar";
import ClipboardButton from "../../button/ClipboardButton";
import PerfilMessage from "./PerfilMessage";


const Text = styled.span`
    white-space: pre-wrap;
`


export default function RobotMessage({message, ...props}) {


    return (

        <GrayContainer padding={'24px'}>
            <FlexContainer direction={'column'} align={'start'}>
                <PerfilMessage data={{picture: SilviaImage}} name={'Silvia'}/>
                <Text>
                    {message}
                </Text>
                <ClipboardButton style={{marginTop: 8}} text={message}
                                 label={''}/>
            </FlexContainer>

        </GrayContainer>

    );
}