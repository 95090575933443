import ProjectForm from "../../components/ProjectForm";
import {useProjectRequest} from "../../hooks/useProjectRequest";
import {Button, Card, Form} from "antd";
import FixedFooter from "../../../../global/components/layout/FixedFooter";
import React from "react";
import {useTranslation} from "react-i18next";
import Flex from "../../../../global/components/responsive/Flex";


function ProjectCreatePage() {

    const {
        form, isLoading, handleSubmitLoading
    } = useProjectRequest()
    const {t} = useTranslation()

    return (
        <>
            <Form form={form} onFinish={handleSubmitLoading}>
                <ProjectForm project={null} form={form}/>
            </Form>
            <FixedFooter>
                <Card>
                    <Flex justify="end">
                        <Button type="primary" onClick={form.submit} loading={isLoading} data-cy="save-project-settings-button">{t("Create")}</Button>
                    </Flex>
                </Card>
            </FixedFooter>
        </>
    )


}

export default ProjectCreatePage;