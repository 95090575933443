import React, {useEffect, useState} from "react";
import {Col, Row, Space, Typography} from "antd";
import {CaretLeftOutlined, CaretRightOutlined, LoadingOutlined} from "@ant-design/icons";
import styled from "styled-components"
import LegendItem from "./LegendItem";
import Flex from "../../responsive/Flex";

const {Text} = Typography

const paginationIconStyle = {
    fontSize: 16,
    color: "gray",
    cursor: "pointer"
}

const StyledLegendContainer = styled.div`
    display: grid;
    grid-template-columns: ${props => `repeat(${props.cols}, 1fr)`};
    grid-template-rows: ${props => `repeat(${props.rows}, 1fr)`};
    gap: ${props => props.gap || "8px"};
`;

export const getLabelsFromData = (data, idSourceKey, colorField, colorSourceKey, chartInstance) => {

    // const colors = chartInstance?._context?.views?.[0].theme?.category20
    const colors = ['#1783FF', '#00C9C9', '#F0884D', '#D580FF', '#7863FF', '#60C42D', '#BD8F24', '#FF80CA', '#2491B3', '#17C76F', '#AABA01', '#BC7CFC', '#237CBC', '#2DE379', '#CE8032', '#FF7AF4', '#545FD3', '#AFE410', '#D8C608', '#FFA1E0']

    const key = idSourceKey || colorField

    if (!data) return []
    const uniqueValues = [];
    const uniqueKeys = new Set(data?.map((item) => item[key]));
    let order = 0

    uniqueKeys.forEach((uniqueKey) => {
        const item = data?.find(item => item[key] === uniqueKey);

        uniqueValues.push({
            id: item[key],
            text: item.label || item[colorField],
            color: item.color || (colorSourceKey ? item[colorSourceKey] : colors?.[order]),
            order: order
        });
        order++
    });

    return uniqueValues;
}


export default function ChartLegendWrapper({chart: ChartComponent, config, extra, expand, afterUpdateData}) {

    let {data, _legend, drawerConfig} = config

    const [loading, setLoading] = useState(false);

    if (expand) {
        config = {...config, ...drawerConfig}
        _legend = {..._legend, ...drawerConfig?._legend}
    }

    let {colorField, colorSourceKey, idSourceKey, rows, cols, gap, labelFormatter} = _legend

    if (!colorField) {
        throw Error('"colorField" not provided.')
    }
    const key = idSourceKey || colorField

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = cols * rows

    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = startIndex + itemsPerPage;

    const [chartInstance, setChartInstance] = useState()

    const [labels, setLabels] = useState(getLabelsFromData(data, idSourceKey, colorField, colorSourceKey, chartInstance))
    //
    useEffect(() => {
        setLabels(getLabelsFromData(data, idSourceKey, colorField, colorSourceKey, chartInstance))
    }, [chartInstance]);

    const [disabledLabels, setDisabledLabels] = useState(new Set())
    const displayLabels = (labels?.slice(startIndex, endIndex))

    const toggleLabel = async (id) => {
        setDisabledLabels(prevState => {
            if (prevState.has(id)) {
                prevState.delete(id)
            } else {
                prevState.add(id)
            }
            return new Set(prevState)
        });
    }

    const handleLabelClick = async (id) => {
        setLoading(true);
        await toggleLabel(id);
        setLoading(false);
    }

    const totalPages = Math.ceil(labels?.length / itemsPerPage);
    const needsPagination = totalPages > 1

    const renderLabel = (label) => {
        let labelDisplay = {
            ...label,
            originalText: label.text
        }
        const originalLabelColor = label.color

        if (labelFormatter) {
            labelDisplay = {...labelDisplay, ...labelFormatter(label, colorField)}
            labelDisplay.color = labelDisplay.color || originalLabelColor
        }
        return (
            <LegendItem
                key={label.id}
                label={labelDisplay}
                isActive={!(disabledLabels.has(label.id))}
                onClick={() => handleLabelClick(label.id)}
            />
        )
    }

    const displayData = data.filter(item => !disabledLabels.has(item[key]));
    const chartConfig = {
        ...config,
        data: displayData,
        legend: false,
        onReady: ({chart}) => {
            config.onReady && config.onReady({chart});
            (!chartInstance && !colorSourceKey) && setChartInstance(chart);
        }
    };

    useEffect(() => {
        afterUpdateData && afterUpdateData(displayData)
    }, [displayData]);

    useEffect(() => {
        setLabels(getLabelsFromData(data, idSourceKey, colorField, colorSourceKey, chartInstance))
    }, [chartInstance]);

    return (
        <>
            <div className="plot">
                {!loading ? <ChartComponent {...chartConfig} /> : <LoadingOutlined />}
            </div>
            <div className="legend">
                <Row>
                    <Col span={needsPagination ? 20 : 24}>
                        <StyledLegendContainer rows={rows} cols={cols} gap={gap}>
                            {displayLabels?.map(label => (
                                renderLabel(label)
                            ))}
                        </StyledLegendContainer>
                    </Col>
                    {needsPagination &&
                        <Col span={4} style={{
                            display: "flex",
                            alignItems: "start",
                            justifyContent: "center"
                        }}>
                            <div className="pagination">
                                <Space wrap={false}>
                                    {!(currentPage === 1) && <CaretLeftOutlined style={paginationIconStyle}
                                                                                onClick={() => setCurrentPage(currentPage - 1)}/>}
                                    <Text
                                        style={{color: "gray", whiteSpace: "nowrap"}}>{currentPage}/{totalPages}</Text>
                                    {!(currentPage === totalPages) && <CaretRightOutlined style={paginationIconStyle}
                                                                                          onClick={() => setCurrentPage(currentPage + 1)}/>}
                                </Space>
                            </div>
                        </Col>
                    }
                </Row>
            </div>
            {extra &&
                <div className="extra">
                    {extra}
                </div>
            }
        </>
    )
}