import {useTranslation} from "react-i18next";
import RadioFormField
    from "../../../components/data_entry/form/RadioFormField";
import React, {useEffect, useMemo, useState} from "react";
import {Form} from "antd";
import SolvCollapse from "../../../components/misc/SolvCollapse";
import BreakLayoutSystemContent
    from "../../../components/layout/BreakLayoutSystemContent";
import styled from "styled-components";
import ApplicationFormContentEditable from "../ApplicationFormContentEditable";

const SectionContainer = styled.div`
    margin: -24px;
`

export default function SectionInput({
                                         phaseForm,
                                         formInstance,
                                         sections,
                                         formBuild,
                                         ...props
                                     }) {
    const {t} = useTranslation();
    const [currentSection, setCurrentSection] = useState(null)
    const sectionValue = Form.useWatch(props.item.name, formInstance)

    const options = useMemo(() => {
        const choices = props?.item?.choices?.map((item, count) => ({
            key: count,
            value: item.value,
            label: item.value
        }));
        return choices;
    }, [props?.item?.choices])

    useEffect(() => {
        const sections = phaseForm?.sections?.sections

        if (sectionValue && sections?.length > 0) {
            const section_id = props?.item?.choices?.find(({value}) => value === sectionValue)?.section
            const section = sections.find(({id}) => id === section_id)
            setCurrentSection(section)


        }
    }, [sectionValue, sections])

    return (
        <>
            <RadioFormField mode={'multiple'} options={options} {...props}/>


            {currentSection && <SectionContainer>
                <SolvCollapse
                    title={currentSection.name}
                    active={true} locked={true}
                    bordered={true}>

                    <ApplicationFormContentEditable
                        phaseForm={{
                            ...phaseForm,
                            form: currentSection.questions
                        }} {...formBuild}/>
                </SolvCollapse>

            </SectionContainer>}

        </>
    )
}