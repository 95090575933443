import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {neutral_2} from "../../../../utils/Colors";
import styled from 'styled-components';
import {Col, Row, Spin, Typography} from "antd";
import {
    ProgramChartsFactory
} from "../components/ProgramChartFactory";
import {
    FlexContainer
} from "../../../../components/authentication/StyledComponents";
import ButtonFilterProgramsChart
    from "../components/ButtonFilterProgramsChart";
import {useInnovationPanelContext} from "../context/InnovationPanelContext";
import DrawerProgramsSelectChart
    from "../components/DrawerProgramsSelectChart";

const Container = styled.div`
    background: ${neutral_2};
    padding: 16px;
    border-radius: 12px;
`


export default function SectionProgramsChartsBuild() {
    const {t} = useTranslation();
    const {
        initialDate,
        finalDate,
        programChartList,
        deleteProgramChart,
        programsFilters,
        updateColumnProgramChart, PROGRAM_FILTER_FORM_NAMES
    } = useInnovationPanelContext()
    const [open, setOpen] = useState(false)

    const positionRef = useRef()

    function handleOpenAddChartDrawer(position) {
        setOpen(true)
        positionRef.current = position
    }

    return (
        <>
            <Container>
                <Row gutter={[19, 19]}>
                    <Col xs={24}>
                        <FlexContainer justify={'space-between'}>
                            <Typography.Title style={{marginBottom: 0}}
                                              level={5}>
                                {t('Programs')}
                            </Typography.Title>
                            <ButtonFilterProgramsChart/>

                        </FlexContainer>
                    </Col>


                    {programChartList.map(({type, columnSize =1}, position) => (
                        <Col xs={24} lg={8 * columnSize} key={position}>
                            <ProgramChartsFactory type={type}
                                                  columnSize={columnSize}
                                                  initialDate={initialDate}
                                                  finalDate={finalDate}
                                                  programs={programsFilters[PROGRAM_FILTER_FORM_NAMES.PROGRAMS__ID__IN]}
                                                  bodyFilters={programsFilters}
                                                  onDelete={() => deleteProgramChart(position)}
                                                  onChangeColumns={(columnSize) => updateColumnProgramChart(position, columnSize)}
                                                  handleOpenDrawer={() => handleOpenAddChartDrawer(position)}/>
                        </Col>

                    ))}

                </Row>
            </Container>
            <DrawerProgramsSelectChart open={open} setOpen={setOpen}
                                       position={positionRef.current}/>

        </>
    )
}
