import React from 'react'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  position: relative;
  margin-top: 8px;
  margin-bottom: 8px;
  align-items: ${(props) => props.position ? props.position : ""};
  button{
    margin:0 !important;
  }
`;


export default function InputContainer(props) {
    return (
        <StyledContainer position={props.position} {...props} data-input={'inputParentContainer'}>
            {props.children}
        </StyledContainer>
    )
}