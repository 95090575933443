import React from 'react';

import {useTranslation} from "react-i18next";
import {CardContainer} from "../../../../global/components/misc/CardContainer";
import {Button, Col, Row, Space, Typography} from "antd";
import TooltipInformation
    from "../../../../global/components/data_display/TooltipInformation";
import ColorPickerFormField
    from "../../../../global/components/data_entry/form/ColorPickerFormField";
import {ACCEPT_IMAGE_FORMATS} from "../../../../global/utils/Constants";
import AttachmentFormField
    from "../../../../global/components/data_entry/form/AttachmentFormField";
import {
    FlexContainer
} from "../../../../global/components/authentication/StyledComponents";
import EnvironmentPreview from "./EnvironmentPreview";
import WideSpace from "../../../../global/components/layout/WideSpace";


export default function SectionEnvironmentApperance({
                                                        isLoading,
                                                        FORM_NAMES,
                                                        form
                                                    }) {

    const {t} = useTranslation()


    return (
        <CardContainer>


            <Row>
                <Col xs={{span: 24, order: 2}}
                     lg={{span: 6, order: 1}}>
                    <Row>
                        <Col xs={24}>
                            <Typography.Title
                                level={5}>{t('Environment')}</Typography.Title>
                            <Typography.Title level={5}><Space
                                size={4}>{t('Colors')}
                               </Space>
                            </Typography.Title>
                        </Col>
                        <Col xs={24}>
                            <Row>
                                <Col xs={8} lg={8} xxl={4}> <ColorPickerFormField
                                    form={{
                                        name: FORM_NAMES.PRIMARY_BTN_COLOR
                                    }}
                                    label={t('Primary color')}/>
                                </Col>
                                <Col xs={8} lg={8} xxl={4}><ColorPickerFormField
                                    form={{
                                        name: FORM_NAMES.PRIMARY_BTN_TEXT_COLOR
                                    }}
                                    label={t('Primary text color')}/></Col>
                                <Col xs={8} lg={8} xxl={4}><ColorPickerFormField
                                    form={{
                                        name: FORM_NAMES.BACKGROUND_MENU
                                    }}
                                    label={t('Menu color')}/></Col>
                                <Col xs={8} lg={8} xxl={4}><ColorPickerFormField
                                    form={{
                                        name: FORM_NAMES.TEXT_COLOR_MENU
                                    }}
                                    label={t('Text menu color')}/></Col>
                                <Col xs={8} lg={8} xxl={4}><ColorPickerFormField
                                    form={{
                                        name: FORM_NAMES.ACTIVE_MENU_COLOR
                                    }}
                                    label={t('Action menu color')}/></Col>


                            </Row>

                        </Col>
                        <Col xs={24}>
                            <AttachmentFormField
                                accept={ACCEPT_IMAGE_FORMATS}
                                maxCount={1} MAX_SIZE={3}
                                // removeAction={()=>form.setFieldValue("profile_upload", undefined)}
                                form={{
                                    name: FORM_NAMES.FAVICON,
                                    label: <Space wrap
                                                  size={4}>{t('Favicon browser')}
                                       <Typography.Text
                                            type={'secondary'}>(64x64px)</Typography.Text></Space>
                                }}/>

                        </Col>
                        <Col xs={24}>

                            <AttachmentFormField
                                accept={ACCEPT_IMAGE_FORMATS}
                                maxCount={1} MAX_SIZE={3}
                                form={{
                                    name: FORM_NAMES.IMG_ACTION_BAR,
                                    label: <Space
                                        size={4}>{t('Logo menu')}
                                       <Typography.Text
                                            type={'secondary'}>(138X45)</Typography.Text></Space>
                                }}/>

                        </Col>
                        <Col xs={24}>

                            <AttachmentFormField
                                accept={ACCEPT_IMAGE_FORMATS}
                                maxCount={1} MAX_SIZE={3}
                                form={{
                                    name: FORM_NAMES.IMG_PROFILE,
                                    label: <Space
                                        size={4}>{t('Profile menu')}
                                        <Typography.Text
                                            type={'secondary'}>(32x32px)</Typography.Text></Space>
                                }}/>

                        </Col>
                    </Row>
                </Col>
                <Col xs={{span: 24, order: 1}}
                     lg={{span: 18, order: 2}}>
                    <Typography.Title
                        level={5}>{t('Preview')}</Typography.Title>
                    <EnvironmentPreview form={form}
                                        FORM_NAMES={FORM_NAMES}/>
                </Col>
                <Col xs={{span: 24, order: 3}}
                     style={{marginTop: 26}}>
                    <FlexContainer justify={'end'}>
                        <Button type="primary" htmlType="submit"
                                loading={isLoading}>{t('Save')}</Button>
                    </FlexContainer>
                </Col>
            </Row>


        </CardContainer>

    );
}