import React, {useEffect, useMemo, useState} from 'react';


import {useTranslation} from "react-i18next";
import {
    useLayoutContext
} from "../../../../modules/project/components/layout/context/LayoutContext";
import useDefaultGetRequest from "../../../hooks/useDefaultGetRequest";
import {useParams} from "react-router-dom";
import {Alert, Col, Row} from "antd";

import Loading from "../../../components/feedback/Loading";
import ExtraHeader
    from "../../../../modules/project/components/layout/ExtraHeader";
import CardAssesment from "../../../components/other/CardAssesment";

import HorizontalScrolling
    from "../../../components/other/HorizontalScrolling";
import BreakLayoutSystemContent
    from "../../../components/layout/BreakLayoutSystemContent";

import {neutral_4, neutral_5} from "../../../utils/Colors";
import SolvCollapse from "../../../components/misc/SolvCollapse";
import TeamCard
    from "../../../components/other/application/ApplicationDrawer/components/TeamSolutionTab/components/TeamCard";
import styled from 'styled-components';
import WideSpace from "../../../components/layout/WideSpace";
import EvaluationFormBuild
    from "../../../components/data_entry/form/EvaluationFormBuild/EvaluationFormBuild";
import {groupByN} from "../../../utils/Utils";
import ChallengeCard from "../../home/HomePage/components/ChallengeCard";
import useSystemBreakpoint from "../../../hooks/useSystemBreakpoint";
import Carousel from "../../../components/misc/Carousel";
import useEventListener from "../../../hooks/useEventListener";


export default function EvaluationAssesmentsPage() {
    const {t} = useTranslation()
    let {id_assessment} = useParams()
    const {isDesktop} = useSystemBreakpoint();
    const {initializeLayout,} = useLayoutContext();
    const [isAllAssessments, setIsAllAssessments] = useState(false)
    const {
        data: currentAssessment,
        isLoading: isLoadingCurrentAssessment,
        fetchData
    } = useDefaultGetRequest({
        url: `assessments/${id_assessment}`,
        config: {version: 'v2'},
        redirectRoute: '/evaluations',
        makeRequest: !!id_assessment,
        dependence: [id_assessment]
    })
    // console.log(currentAssessment)
    const {
        data: assessments,
        isLoading: isLoadingAssessments
    } = useDefaultGetRequest({
        url: `evaluator_assessments/${currentAssessment?.phase}`,
        config: {version: 'v2'},
        params: {
            all_evaluations: true,
            ordering: ['application__challenge', 'status']
        },
        makeRequest: !!currentAssessment?.id,
        dependence: [currentAssessment]
    })


    useEventListener(`fetch-assessment-data`, fetchData);

    useEffect(() => {
        if (currentAssessment) {
            initializeLayout({
                extraHeaderContent: <ExtraHeader
                    left={`${currentAssessment?.phase_name} - ${currentAssessment?.program_name} `}
                    returnPath={`/evaluations`}/>,
                // navbarMenuItems: [
                //     {
                //         label: <Link to={``}>{t("Evaluations")}</Link>,
                //         key: `/evaluations`
                //     },
                //
                //
                // ]
            });
        }
    }, [currentAssessment]);


    const renderAssessments = useMemo(() => {
// currentAssessment
            const filteredAssessments = assessments?.length > 0 ? assessments.filter(({id}) => currentAssessment?.id !== id) : []

            const _currentAssessment = currentAssessment ? currentAssessment : []
            return groupByN([_currentAssessment, ...filteredAssessments], !isDesktop ? 1 : 3).map((group, index) => (
                <div key={index}>
                    <Row gutter={8} align={'bottom'}
                         style={{padding: 16, background: 'white', height: 112}}>
                        {group.map(({
                                        id,
                                        ...assessment
                                    }) => (
                            <Col span={!isDesktop ? 24 : 8} key={index}>
                                <CardAssesment
                                    key={id}
                                    id={id}
                                    actived={id === currentAssessment?.id}
                                    {...assessment}/>
                            </Col>

                        ))}
                    </Row>
                </div>
            ))
        }, [currentAssessment, assessments]
    )

    return (
        <>
            {isLoadingCurrentAssessment ? <Loading/> : (
                <>

                    <BreakLayoutSystemContent>

                        <Row>
                            <Col xs={24}>
                                {isLoadingAssessments ?
                                    <Loading/> : <Carousel
                                        autoplay={false}
                                        // autoplaySpeed={10000}
                                        arrows={true}
                                        dots={false}
                                    >
                                        {/*<HorizontalScrolling gap={16} align={'end'}>*/}
                                        {renderAssessments}

                                        {/*</HorizontalScrolling>*/}
                                        {/*</Carousel>*/}
                                    </Carousel>}
                            </Col>
                        </Row>
                    </BreakLayoutSystemContent>
                    <BreakLayoutSystemContent vertical={false}
                                              style={{borderTop: `1px solid ${neutral_4}`}}>
                        <SolvCollapse
                            title={t("Author information")}
                            bordered
                        >
                            <TeamCard solution={currentAssessment?.application}
                                      team={currentAssessment?.application?.team}
                            />

                        </SolvCollapse>
                        {currentAssessment?.phase_open ? <></> :
                            !currentAssessment?.phase_active ?
                                <Col xs={24}>
                                    <Alert
                                        message="Phase inactivated"
                                        description="This evaluation is in a stage that is not currently active, so it is not possible to make it available there."
                                        type="warning"
                                        showIcon
                                    />
                                </Col>
                                :
                                <Col xs={24}>
                                    <Alert
                                        message="Outside the evaluation period"
                                        description="This application is outside the evaluation period and can no longer be evaluated."
                                        type="warning"
                                        showIcon
                                    />
                                </Col>
                        }
                    </BreakLayoutSystemContent>
                    {currentAssessment &&
                        <EvaluationFormBuild assessment={currentAssessment}/>}

                </>
            )}


        </>

    )
}