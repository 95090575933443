import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button, Typography } from "antd";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { neutral_1 } from "../../../global/utils/Colors";

const { Text } = Typography;

const StyledContainer = styled.div`
  position: relative;
`;

const StyledTextContent = styled.div`
  height: ${({ collapsed }) => collapsed ? "58px" : "100%"};
  overflow: hidden;
  background-color: transparent;
  padding-bottom: 24px;
  font-size: 12px;

`;

const StyledButtonContainer = styled.div`
  position: absolute;
  bottom: -16px;
  text-align: center;
  width: 100%;
`;

const StyledButton = styled(Button)`
  background-color: ${neutral_1} !important;
  color: var(--font-default-color) !important;
  box-shadow: 0 -2px 5px 2px rgba(0, 0, 0, 0.1);
`;

const BoxShadow = styled.div`
  width: 100%;
  height: 10px;
  position: absolute;
  bottom: -10px;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
`;

function DetailsCollapse({ children, value }) {
    const [collapsed, setCollapsed] = useState(true);
    const outerBox = useRef();
    const innerBox = useRef();
    const [textHeight, setTextHeight] = useState(0);
    const [containerHeight, setContainerHeight] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        if (outerBox.current && innerBox.current) {
            setContainerHeight(outerBox.current?.offsetHeight);
            setTextHeight(innerBox.current?.offsetHeight);
        }
    }, [collapsed, children]);
    
    const handleClick = (event) => {
        event.stopPropagation();
        setCollapsed(!collapsed)
    }
    return <StyledContainer ref={outerBox}>
        <StyledTextContent collapsed={collapsed}>
            <div ref={innerBox}>
                <Text type="secondary">{value}</Text>
                {children}
            </div>
        </StyledTextContent>
        <BoxShadow />
        {(textHeight > containerHeight - 16 || !collapsed) && <StyledButtonContainer>
            <StyledButton
                type="text"
                collapsed={collapsed}
                onClick={handleClick}
            >{collapsed ? <>{t("Read more")}</> : <>{t("Read less")}</>} {React.createElement(collapsed ? DownOutlined : UpOutlined)}</StyledButton>
        </StyledButtonContainer>}
    </StyledContainer>;
}

export default DetailsCollapse;