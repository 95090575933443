import { AppstoreOutlined, BarcodeOutlined, ProjectOutlined } from "@ant-design/icons";
import { Badge, Col, Row } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import RemixIcon from "../../../../../../data_display/RemixIcon";
import EnvironmentLink from "../../../../../../link/EnvironmentLink";
import InfoCardItem from "../../../../../../misc/InfoCardItem";
import { APPLICATION_COLOR, PROJECT_COLOR } from "../../../../../../../utils/Colors";
import { useApplicationDrawerContext } from "../../../context/ApplicationDrawerContext";
import TruncateText from "../../../../../../responsive/TruncateText";

function ApplicationInfoCards({ solution, setChallengeDrawerOpen }) {
    const { t } = useTranslation();
    const {
        groupedApplications,
    } = useApplicationDrawerContext();

    const countGroupedApplications = groupedApplications?.length || 0
    const countLinkedProjects = solution?.project ? 1 : 0

    return (
        <Row gutter={[8, 8]}>
            <Col xs={12} sm={12} md={12} lg={12}>
                <EnvironmentLink clientUrl={solution.program.client_url} to={`/available-programs/detail/${solution.program.id}`}>
                    <InfoCardItem 
                        icon={<AppstoreOutlined />} 
                        title={t("Program")} 
                        text={solution?.program.name} 
                    />
                </EnvironmentLink>
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
                <InfoCardItem 
                    icon={<RemixIcon remixIconName={"ri-puzzle-line"} />} 
                    title={t("Challenge")} 
                    text={solution?.challenge_name} 
                    onClick={() => setChallengeDrawerOpen(true)}
                />
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
                <InfoCardItem
                    icon={<BarcodeOutlined />} 
                    title={t("Code")} 
                    text={solution?.code} 
                    copyable={true}
                />
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
                <InfoCardItem 
                    icon={<RemixIcon remixIconName={"ri-file-info-line"} />} 
                    title={t("Type")} 
                    text={t(`${solution?.program?.application_name_display}`)} 
                />
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
                <InfoCardItem 
                    icon={<ProjectOutlined />} 
                    title={t('Projects')}
                    text={countLinkedProjects} 
                    iconColor= {PROJECT_COLOR}
                />
            </Col>
            <Col xs={6} sm={6} md={6} lg={6}>
                <InfoCardItem 
                    icon={<RemixIcon remixIconName={"ri-git-merge-line"} />} 
                    title={<TruncateText>{t("Grouped Applications")}</TruncateText>} 
                    text={countGroupedApplications} 
                    iconColor={APPLICATION_COLOR}
                />
            </Col>
        </Row>
    );
}

export default ApplicationInfoCards;
