import React from 'react';
import {Badge} from "antd";
import Button from "../data_entry/Button";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import RemixIcon from "../data_display/RemixIcon";



const StyledBadge = styled(Badge)`
    margin-top: -4px;
    span {
        color: var(--primary_btn_color);
    }

   
`

export default function FilterButton({onClick, hasText, filters={},...props}) {
    const {t} = useTranslation()
    const AMOUNT_FILTERS =  Object.values(filters).length
    const HAS_FILTERS = Object.values(filters).length > 0
    return (
        <Button type={HAS_FILTERS? 'primary': 'default'} icon={<RemixIcon remixIconName={'ri-filter-line'}/>}
                onClick={onClick}>{hasText && t('Filters')}{AMOUNT_FILTERS > 0 && <StyledBadge color={'white'} count={AMOUNT_FILTERS}/>}</Button>
    )
}