import React, {useEffect, useMemo} from "react";
import {Link, Redirect, Switch, useLocation,} from "react-router-dom";

import {
    useLayoutContext
} from "../../modules/project/components/layout/context/LayoutContext";
import {useTranslation} from "react-i18next";
import {ManagerRoute} from "./PrivateRoute";
import GamificationPage
    from "../pages/gamification/pages/gamification/GamificationPage";
import PointsManagementPage
    from "../pages/gamification/pages/pointsManagement/PointsManagementPage";
import RankingPage from "../pages/gamification/pages/ranking/RankingPage";
import {Badge} from "antd";
import {useThemeContext} from "../context/ThemeContext";
import useDefaultGetRequest from "../hooks/useDefaultGetRequest";
import BonusCampaignPage
    from "../pages/gamification/pages/bonusCampaign/BonusCampaignPage";
import ShopItemsPage
    from "../pages/gamification/pages/shopItems/ShopItemsPage";
import StoryActivityPage
    from "../pages/gamification/pages/storeActivity/StoryActivityPage";


const PATH_ROUTES = {
    GAMIFICATION: '/gamification',
    POINTS_MANAGEMENT: '/gamification/points_management',
    RANKING: '/gamification/ranking',
    SHOP_ITEMS: '/gamification/shop_items',
    BONUS_CAMPAIGN: '/gamification/bonus_campaign',
    STORE_ACTIVITY: '/gamification/story_activity'
    // INNOVATIONS_PANEL: '/home/innovation_panel'
}
export default function RoutesGamification() {
    const location = useLocation();
    const {t} = useTranslation();
    const {initializeLayout} = useLayoutContext()
    const {themeConfig: {client}} = useThemeContext();
    const {
        data: gamificationData,
        isLoading: isLoadingGamification
    } = useDefaultGetRequest({
        url: '/gamification_settings/get_client_settings',
    })

    const RoutesItems = useMemo(() => [
        {
            label: <Link data-cy="general-settings-link-tab"
                to={PATH_ROUTES.GAMIFICATION}>{t("General settings")}</Link>,
            key: PATH_ROUTES.GAMIFICATION,
            route: <ManagerRoute
                exact
                render={() => <GamificationPage
                    gamificationData={gamificationData}
                    isLoadingGamification={isLoadingGamification}/>}
            />
        },


        {
            label: <Link data-cy="points-management-link-tab"
                to={PATH_ROUTES.POINTS_MANAGEMENT}>{t("Points management")}
                {client?.manual_points_validation &&
                    <Badge style={{marginLeft: 10}}
                           count={gamificationData?.pending_actions_count}/>}</Link>,
            key: PATH_ROUTES.POINTS_MANAGEMENT,
            route: <ManagerRoute
                exact
                render={() => <PointsManagementPage count={gamificationData?.pending_actions_count}/>}
            />
        },
        {
            label: <Link data-cy="ranking-link-tab" to={PATH_ROUTES.RANKING}>{t("Ranking")}</Link>,
            key: PATH_ROUTES.RANKING,
            route: <ManagerRoute
                exact
                component={RankingPage}
            />
        },
        {
            label: <Link to={PATH_ROUTES.SHOP_ITEMS}>{t("Store Items")}</Link>,
            key: PATH_ROUTES.SHOP_ITEMS,
            route: <ManagerRoute
                exact
                component={ShopItemsPage}
            />
        },
        {
            label: <Link
                to={PATH_ROUTES.BONUS_CAMPAIGN}>{t("Bonus campaign")}</Link>,
            key: PATH_ROUTES.BONUS_CAMPAIGN,
            route: <ManagerRoute
                exact
                component={BonusCampaignPage}
            />
        },
        {
            label: <Link to={PATH_ROUTES.STORE_ACTIVITY}>{t("Store activity")}
                {client?.manual_shopping_confirmation &&
                    <Badge style={{marginLeft: 10}}
                           count={gamificationData?.pending_purchases_count}/>}
            </Link>,
            key: PATH_ROUTES.STORE_ACTIVITY,
            route: <ManagerRoute
                exact
                render={() => <StoryActivityPage count={gamificationData?.pending_purchases_count}/>}
            />
        },


    ], [isLoadingGamification, gamificationData])

    useEffect(() => {
        initializeLayout({
            navbarMenuItems: RoutesItems
        });
    }, [RoutesItems]);

    return (

        <Switch>
            {RoutesItems.map(({
                                  route, key,
                                  hasPermission
                              }) => (React.cloneElement(route, {
                hasPermission,
                key,
                path: key
            })))}
            <Redirect to={{
                pathname: "/404",
                state: {broken_url: window.location.host + location.pathname}
            }}/>
        </Switch>);
}
