import React, {Children, useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import {Flex} from "antd";

const Carousel = styled.div`
    width: 100%;
    overflow: hidden;
    content-visibility: auto;
    height: ${props => `${props.$height}px`};
`;

const CarouselAbsoluteContainer = styled.div`
    width: 100%;
    position: absolute;
`;

const ButtonsContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    font-size: 32px;

    & > * {
        z-index: 999;
        padding: 8px;
        display: flex;
        align-items: center;
        color: black;
        position: absolute;
        opacity: 0.2;
        height: 100%;
        top: 0;

        :hover {
            opacity: 0.4;
        }
    }

    > :nth-child(1) {
        left: 0;
    }

    > :nth-child(2) {
        right: 0;
    }

`;

const CarouselList = styled.ul`
    --gap: ${props => `${props.$gap}px`};
    --element-size: calc(${props => `${100 / props.$pageSize}%`} - var(--gap));
    --animation-speed: ${props => `${props.$animationSpeed}ms`};
    --margin: calc(var(--element-size) + var(--gap) / 2);
    position: relative;
    all: unset;
    gap: var(--gap);
    display: flex;
    list-style: none;
    overflow: hidden;

    li:nth-child(1) {
        transition-timing-function: ease-in-out;
    }

    li:nth-child(1).static {
        margin-left: calc(-1 * var(--margin));
    }

    li:nth-child(1).left {
        transition-duration: var(--animation-speed);
        margin-left: calc(-1 * var(--margin));
    }

    li:nth-child(1).enter-left {
        transition-duration: 0ms;
        margin-left: calc(-2 * var(--margin));
    }

    li:nth-child(1).right {
        transition-duration: var(--animation-speed);
        margin-left: calc(-1 * var(--margin));
    }

    li:nth-child(1).enter-right {
        transition-duration: 0ms;
        margin-left: 0;
    }

    .justify-center-fix {
        justify-content: center;
        margin-left: -150px;
    }

`;

const ListItem = styled.li`
    all: unset;
    flex-shrink: 0;
    width: var(--element-size);
`;

const FLUID_ANIMATIONS = ["enter-right", "enter-left", "left", "right"];


function CarouselPro({
                         children,
                         items,
                         animationSpeed = 500,
                         autoplaySpeed = 0,
                         pageSize = 3,
                         renderButtons,
                         hideNavigationButtons = false,
                         renderItem,
                         gap = 8,
                         changePageOnItemClick = false,
                         onLoad,
                         justifyWhenCantNavigate = "start",
                         style,
                         onScrollRight,
                         onScrollLeft,
                         onAutoScroll,
                         autoScrollDeps = [],
                     }) {

    items = children ? Children.toArray(children) : items;

    const lock = useRef(false);
    const listRef = useRef(null);

    const directionRef = useRef("right");
    const touchOriginRef = useRef(null);
    const [mouseOver, setMouseOver] = useState(false);
    const [itemIndex, setItemIndex] = useState(0);
    const [dimensions, setDimensions] = useState()

    const isManualScroll = useRef(false)
    const [visibleItems, setVisibleItems] = useState([]);
    const count = items?.length

    const getCalculatedPageSize = () => {
        if (window.screen.width <= 1024) return 1;
        if (count === 1) return 1;
        if (count < pageSize) return count;
        return pageSize;
    }

    pageSize = getCalculatedPageSize();
    const canNavigate = !hideNavigationButtons && count > pageSize;

    const {isPhone} = useSystemBreakpoint()

    // centerWhenCantNavigate = centerWhenCantNavigate && !canNavigate && !isPhone

    // const [page, setPage] = useState(0);
    // const pageIndex = itemIndex - (page * pageSize);
    // const pageDisplay = page + 1;
    // const itemIndexDisplay = itemIndex + 1;

    const handlePageChanging = (direction, enableOnScroll = true) => {
        if (lock.current === true || !canNavigate) return;

        if (enableOnScroll) {
            switch (direction) {
                case "right":
                    onScrollRight && onScrollRight();
                    break;
                case "left":
                    onScrollLeft && onScrollLeft();
                    break;
            }
        }

        directionRef.current = direction;
        setItemIndex((current) => {
            let nextItemIndex;
            switch (direction) {
                case "right":
                    const increase = current + 1;
                    nextItemIndex = (increase % count);

                    break;
                case "left":
                    const decrease = current - 1;
                    nextItemIndex = decrease >= 0 ? decrease : count - 1;
                    break;
                default:
                    throw Error('Invalid direction');
            }
            return nextItemIndex;
        });


    }

    const calculateVisibleItems = (ignoreLock = false) => {
        if (lock.current === true && ignoreLock === false) return;
        let visibleItemsTemp = items?.slice(itemIndex, itemIndex + pageSize);
        let auxLeft = [];
        let auxRight = [];

        if (itemIndex === 0) {
            auxLeft = [items.at(-1)]
        } else {
            auxLeft = [items[itemIndex - 1]];
        }

        const nextItem = (itemIndex + pageSize);
        if (nextItem + 1 > count) {
            auxRight = items.slice(0, pageSize - (visibleItemsTemp.length - 1));
        } else {
            auxRight = [items[nextItem]]
        }
        visibleItemsTemp = [...auxLeft, ...visibleItemsTemp, ...auxRight];
        setVisibleItems(visibleItemsTemp);
    }

    const setAnimations = (...animations) => {
        const classList = listRef.current?.firstChild?.classList
        if (!classList) return;
        animations.forEach((animation) => {
            if (Array.isArray(animation)) {
                const [anim, removeTimeout] = animation;
                classList.add(anim);
                lock.current = true;
                setTimeout(() => classList.remove(anim), 5);
                setTimeout(() => lock.current = false, removeTimeout);
            } else {
                classList.add(animation);
            }
        })
    }

    const resetAnimations = (toggleStatic = false, animations = [...FLUID_ANIMATIONS, "static"]) => {
        const classList = listRef.current?.firstChild?.classList;
        if (classList) {
            classList.remove(...animations);
        }
        if (toggleStatic) {
            classList?.add("static");
        }
    }

    const handleAnimatedPageChange = () => {
        if (lock.current === true || !canNavigate) return;
        resetAnimations();
        switch (directionRef.current) {
            case "left":
                return setAnimations("left", ["enter-left", animationSpeed]);
            case "right":
                return setAnimations("right", ["enter-right", animationSpeed]);
        }
    }


    const renderButtonsDefault = () => {
        return <>
            <span onClick={() => handlePageChanging("left")} style={{cursor:"pointer"}}> &lsaquo; </span>
            <span onClick={() => handlePageChanging("right")} style={{cursor:"pointer"}}> &rsaquo; </span>
        </>
    }

    const handleTouchStart = (event) => {
        if (lock.current === true || !canNavigate) return;
        touchOriginRef.current = event.targetTouches[0].screenX;
    }

    const handleTouchEnd = (event) => {
        if (lock.current === true || !canNavigate) return;
        const touchDelta = event.changedTouches[0].screenX - touchOriginRef.current
        if (touchDelta > 0) {
            handlePageChanging("left");
        }
        if (touchDelta < 0) {
            handlePageChanging("right");
        }
    }
    const renderVirtualizedItems = (item, index) => {
        return (
            <ListItem
                key={index}
                className={index === 0 && "static"}
                onClick={() => {
                    if (changePageOnItemClick && pageSize > 1) {
                        isManualScroll.current = true
                        handlePageChanging(index < (visibleItems?.length / 2) ? "left" : "right", false)
                        isManualScroll.current = false
                    }
                }}
            >
                {renderItem ? renderItem(item) : item}
            </ListItem>
        );
    }

    useEffect(() => {
        const handleResize = () => {
            calculateVisibleItems(true);
            resetAnimations(true);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        calculateVisibleItems();
        handleAnimatedPageChange();
        // setPage(Math.floor(itemIndex / pageSize));
    }, [itemIndex]);

    useEffect(() => {
        let autoplayInterval;
        if (autoplaySpeed > 0 && !mouseOver) {
            autoplayInterval = setInterval(() => {
                handlePageChanging("right");
            }, autoplaySpeed);
        }
        return () => autoplayInterval && clearInterval(autoplayInterval);
    }, [autoplaySpeed, mouseOver, itemIndex]);

    useEffect(() => {
        if (visibleItems.length > 0) {
            setDimensions({height: listRef.current?.firstChild?.getBoundingClientRect().height})
        }
    }, [visibleItems]);

    useEffect(() => {
        onLoad && onLoad({setItemIndex})
    }, []);

    useEffect(() => {
        calculateVisibleItems();
    }, [children])

    return (
        <>
            {canNavigate ?
                <Carousel
                    // $height={listRef.current?.getBoundingClientRect().height}
                    $height={dimensions?.height}
                    $listItemSize={listRef.current?.firstChild?.getBoundingClientRect().width}
                    onMouseEnter={() => setMouseOver(true)}
                    onMouseLeave={() => setMouseOver(false)}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchEnd}
                    style={style}
                >
                    <CarouselAbsoluteContainer>
                        {canNavigate && <ButtonsContainer>
                            {renderButtons ?
                                renderButtons(
                                    () => handlePageChanging("left"),
                                    () => handlePageChanging("right")
                                ) : renderButtonsDefault()
                            }
                        </ButtonsContainer>}
                        <CarouselList $pageSize={pageSize} $animationSpeed={animationSpeed} ref={listRef}
                                      $gap={gap}
                                      style={{justifyContent: "start"}}
                        >
                            {visibleItems.map((item, index) => renderVirtualizedItems(item, index))}
                        </CarouselList>
                    </CarouselAbsoluteContainer>
                </Carousel> :
                <Flex gap={gap} justify={justifyWhenCantNavigate} style={{...style, width: "100%"}}>
                    {children.map(child => renderItem ? renderItem(child) : child)}
                </Flex>
            }
        </>
    );
}

export default CarouselPro