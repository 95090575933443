import { AppstoreOutlined, CheckCircleOutlined, FlagOutlined, ThunderboltFilled } from "@ant-design/icons";
import { Card, Typography, Flex } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useUserContext } from "../../../context/UserContext";
import useSystemBreakpoint from "../../../hooks/useSystemBreakpoint";
import { neutral_2 } from "../../../utils/Colors";
import { DateFormatUser } from "../../../utils/dates";
import WideSpace from "../../layout/WideSpace";
import TruncateText from "../../responsive/TruncateText";
import { renderAvatars } from "../../tables/utils";
import ApplicationTagStatus from "../../tag/ApplicationTagStatus";
import SolvTag from "../../tag/SolvTag";

const {Text, Title} = Typography

const StyledTeamText = styled(Text)`
    font-family: Roboto, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
`

const StyledIconTagContainer = styled.div`
    flex: 0 1 auto !important;
    overflow: hidden;
`

function IconTag({icon, content, iconSize = 16, ...props}) {
    return (
        <SolvTag color={neutral_2} bordered {...props}>
            <Flex align="center" gap={4}>
                <Text style={{fontSize: iconSize}}>{icon}</Text>
                <TruncateText>
                    {content}
                </TruncateText>
            </Flex>
        </SolvTag>

    )
}

export default function ApplicationInfoCardV2({application}) {
    const {_user} = useUserContext()
    const {isPhone} = useSystemBreakpoint()
    const {t} = useTranslation()

    let programNameChoice = application?.program?.internal_inovation_choice_display

    return (
        <Card>
            <WideSpace direction="vertical">
                <Flex justify="space-between" vertical={isPhone} gap={4}>
                    <Flex align="center" gap={4}>
                        {renderAvatars(application?.team?.members, {maxCount: 5})}
                        <TruncateText>
                            <StyledTeamText>{application?.team?.name}</StyledTeamText>
                        </TruncateText>
                    </Flex>
                    <Flex align="center" justify={"space-between"} style={{flexDirection: isPhone && "row-reverse"}} gap={8}>
                        <Flex gap={2}>
                            <ThunderboltFilled/>
                            <Text
                                style={{whiteSpace: "nowrap"}}>{DateFormatUser(application?.date_submited, _user)}
                            </Text>
                        </Flex>
                        <ApplicationTagStatus application={application}/>
                    </Flex>
                </Flex>
                <Flex align={isPhone ? "center" : "middle"} gap={6}>
                    <TruncateText>
                        <Title level={5} style={{fontSize: isPhone && "14px"}}>{application?.name}</Title>
                    </TruncateText>
                    <IconTag icon={<CheckCircleOutlined/>} iconSize={12} content={t(programNameChoice)} style={{height:"fit-content"}}/>
                </Flex>
                <Flex style={{display: isPhone && "grid"}} gap={8}>
                    <StyledIconTagContainer>
                        <IconTag icon={<AppstoreOutlined/>}
                                content={
                                    <Flex align="center" gap={8}>
                                        {application?.program?.name}
                                        <Text type="secondary"> {">"} {application?.current_phase?.title}</Text>
                                    </Flex>
                                }
                        />
                    </StyledIconTagContainer>
                    {application?.challenge &&
                        <StyledIconTagContainer>
                            <IconTag icon={<FlagOutlined/>} content={application?.challenge?.title}/>
                        </StyledIconTagContainer>
                    }
                </Flex>
            </WideSpace>

        </Card>
    );
}
