import React, {useEffect, useState} from "react";
import {any, BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import {useThemeContext} from "../../../../../global/context/ThemeContext";
import {useLayoutContext} from "../../../components/layout/context/LayoutContext";
import {useTranslation} from "react-i18next";
import api from "../../../../../global/services/api";
import {Col, Form, Input, InputNumber, Row, Space, Typography} from "antd";

const {Text} = Typography

export default function IndicatorExtraDescription({indicator, formulaForm, fetchIndicator}) {
    const {t} = useTranslation();
    const [components, setComponents] = useState([]);
    const hasAverageFormula = any(components.map((component) => component["is_average"]));
    const {themeConfig} = useThemeContext();
    const {openNotification} = useLayoutContext();

    const handleSubmit = async (data) => {
        let payload = components.map((component) => {
            const id = component["id"];
            return data.hasOwnProperty(id) ? {...component, weight: data[id]} : {...component};
        });

        try {
            await api.post(`project_management/client_indicators/${themeConfig.client.id}/update_formulas`, payload);
            openNotification({type: "success", message: t("Indicator settings updated.")});
            await fetchIndicator(indicator["id"]);
        } catch (error) {
            const fields = Object.keys(error.response.data);
            const errorsData = {"errors": {}};
            fields.forEach((field) => {
                errorsData["errors"][`${field}`] = error.response.data[field]["weight"];
            });
            error.response.data = errorsData;
            BuildFieldErrorsDict(error, formulaForm.setFields, false);
        }
    };

    useEffect(() => {
        if (indicator) {
            setComponents(indicator["components"]);
        }
    }, [indicator]);

    useEffect(() => {
        formulaForm.resetFields();
    }, [components]);

    return indicator["has_formulas"] && (
        <Space direction="vertical" size="large" style={{width: "100%"}}>
            <Space direction="vertical" size={0}>
                <Text type="secondary">{t("Grouped Indicators:")}</Text>
                <Text strong>{components.map(component => t(component["indicator_name"])).join(", ")}</Text>
            </Space>
            {hasAverageFormula &&
                <>
                    <Text
                        type="secondary">{t("Insert the weight in the indicators that make up this formula. Weight Distribution from 0 to 100")}</Text>
                    <Space direction="vertical" size="large">
                        <Form form={formulaForm} onFinish={handleSubmit}>
                            {components.map((component) => component["is_average"] && (
                                <Row>
                                    <Col span={16}>
                                        <Text>{t(component["indicator_name"])}</Text>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item initialValue={component["weight"]} name={`${component["id"]}`}>
                                            <InputNumber min={0} max={100}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ))}
                            <Input hidden type="submit"/>
                        </Form>
                    </Space>
                </>
            }
        </Space>
    );
}