import React from 'react';
import styled from "styled-components";
import No_Profile from "../../assets/Icon/no_profile.svg"
import Cam from "../../assets/Icon/cam.svg"

const StyledCircleImage = styled.img`
  border-radius: 50%;
  display: block;
  border: 1px solid rgb(221, 221, 221);
  background-color: white;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
  object-fit: contain;

`

export default function CircleImage(props) {
    return (
        <StyledCircleImage alt={'no image'} {...props}
                           src={props.src ? props.src : props.program ? Cam : No_Profile}/>
    );
}