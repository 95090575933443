import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
// import "antd/dist/antd.min.css";
// import "antd/dist/antd.variable.min.css";
import 'remixicon/fonts/remixicon.css'

import './global/i18n';
import GlobalStyle from "./global/GlobalStyle";

ReactDOM.render(
    <>
        <GlobalStyle/>
        <App/>
    </>,

    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
