import React, {useEffect, useState} from 'react';
import {HeartFilled, HeartOutlined} from "@ant-design/icons";
import {Avatar, Flex, Tooltip, Typography} from "antd";
import UserAvatar from "../../../../components/misc/UserAvatar";
import api from "../../../../services/api";
import {BuildFieldErrorsDict} from "../../../../utils/Utils";
import {useUserContext} from "../../../../context/UserContext";
import {useTranslation} from "react-i18next";

const {Text} = Typography

const LIKE_ICON_SIZE = '20px'

export default function LikeIconContainer({topicReactions, object, object_id, setTopicReaction}) {

    const {t} = useTranslation()

    const count = topicReactions?.count
    const has_reacted = topicReactions?.has_reacted
    const users = topicReactions?.users

    const [reacted, setReacted] = useState(has_reacted)
    const [reactionCount, setReactionCount] = useState(count)
    const [loading, setLoading] = useState(false)

    const {_user} = useUserContext();

    const handleReaction = (newReaction) => {
        if (reacted === newReaction) {
            return 0; // No change, return early
        }
        setReacted(newReaction);
        setReactionCount((prevCount) => newReaction ? prevCount + 1 : prevCount - 1);
    };

    const handleToggleReaction = async () => {
        try {
            setLoading(true)
            await api.post(`${object}/${object_id}/toggle_reaction`)
            handleReaction(!reacted)
        } catch (e) {
            BuildFieldErrorsDict(e, false, false)
        } finally {
            setLoading(false)
        }
    }

    return (
        topicReactions ?
        <Flex align="center" gap={4} style={{flexDirection: object === "comments"}}>
            {
                reacted ?
                    <HeartFilled onClick={!loading ? handleToggleReaction : undefined} style={{color: "#f70041", fontSize:LIKE_ICON_SIZE}}/> :
                    <HeartOutlined onClick={!loading ? handleToggleReaction : undefined}  style={{fontSize:LIKE_ICON_SIZE}}/>
            }
            <Text type={object === "comments"}>{reactionCount}</Text>
            {object !== "comments" &&
                <Tooltip title={t("Reactions")}>
                    <Flex align="center" style={{cursor: setTopicReaction && "pointer"}}
                          onClick={() => setTopicReaction && setTopicReaction(object_id)}
                    >
                        <Avatar.Group max={{count: 3}} size={20} style={{pointerEvents: "none"}}>
                            {reacted &&
                                <UserAvatar data={_user} size={20}/>
                            }
                            {users?.map(({user}) => (
                                <UserAvatar key={user?.id} data={user} size={20}/>
                            ))}
                        </Avatar.Group>
                    </Flex>
                </Tooltip>
            } 
        </Flex> : <div></div>
    );
}
