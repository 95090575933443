import {Card, Col, Row, Space, Tooltip, Typography} from "antd";
import * as icons from "@ant-design/icons";
import {EditTwoTone, InfoCircleOutlined} from "@ant-design/icons";
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import api from "axios";
import { ToastNotification } from './../../../../../../global/components/feedback/ToastNotification';
import { ERROR, SUCCESS } from "../../../../../../global/utils/fileWithMessage";


const {Text} = Typography;

const StyledCard = styled(Card)`
  border-radius: 8px;

  .ant-card-head-title {
    font-size: 12px;
    font-weight: normal;
    margin-top: 24px;
    padding: 0;
  }

  .ant-card-head {
    border-bottom: 0;
  }

  .ant-card-extra {
    margin-top: 24px;
    padding: 0;
  }

  .ant-card-body {
    padding: 24px 24px 24px 5px;
  }

  .drag-icon {
    font-size: 20px;
    display: grid;
    place-content: center;
  }

  .highlighted-text {
    font-size: 38px;
    font-weight: 500;
  }
`;

const CardHeader = styled(Space)`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const DragArea = styled(Col)`
  display: grid;
  place-content: center;
`;

const CardContent = styled(Space)`
  width: 100%;
  padding-right: 24px;
  padding-top: 12px;
`;

export function ValueComponent({color, value, prefix, onClick}) {
    return (
        <Space size="small" style={{lineHeight: "normal"}}>
            <Typography.Text type="secondary" style={{fontSize: 10}}>{prefix}</Typography.Text>
            <div onClick={onClick}>
                <Typography.Text className="highlighted-text" style={{color: color}}>{value}</Typography.Text>
            </div>
        </Space>
    );
}

export function IndicatorInput({input, value, prefix, onChange, onFinishEditing, ...props}) {

    return React.createElement(input, {
        onPressEnter: onFinishEditing,
        onBlur: onFinishEditing,
        onChange: onChange,
        prefix: <Text type="secondary" style={{fontSize: 12}}>{prefix}</Text>,
        suffix: <EditTwoTone/>,
        size: "large",
        value: value,
        style: {width: "100%"},
        ...props,
    });
}

function IndicatorCheckpointCard({indicatorResponse, children, ...props}) {
    const {t} = useTranslation();

    const toggleIndicatorVisibility = async () => {
        try {
            await api.patch(`/project_management/project_indicator_responses/${indicatorResponse.id}`, {visible: false});
            ToastNotification (t('Hidden Indicator'));
            dispatchEvent(new CustomEvent('update-status-report'));
        } catch (error) {
            ToastNotification(ERROR);
        }
    }


    return (
        <StyledCard className={!indicatorResponse["is_editable"] && "disabled"} {...props}>
            <Row style={{width: "100%"}}>
                <DragArea span={1}>
                    <icons.MoreOutlined/>
                </DragArea>
                <Col span={23}>
                    <CardHeader>
                        <Tooltip placement="topRight" title={t(indicatorResponse["indicator"]["description"])} arrowPointAtCenter={true}>
                            <Typography.Text style={{fontSize: 12}}>{t(indicatorResponse["indicator"]["name"])}</Typography.Text>
                        </Tooltip>
                        <icons.EyeOutlined style={{cursor: "pointer"}} onClick={toggleIndicatorVisibility} />
                    </CardHeader>
                    <CardContent size={0} direction="vertical">
                        {children}
                    </CardContent>
                </Col>
            </Row>
        </StyledCard>
    );
}

export default IndicatorCheckpointCard;