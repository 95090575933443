import {useThemeContext} from "../../../../../../../global/context/ThemeContext";
import {getScaledValueIfScaledIndicator} from "../../../../../utils/utils";

export function useChartColor({indicator, indicatorResponse}) {
    const {getLevelColor} = useThemeContext();
    const getColor = (value) => getLevelColor({
        value,
        levelsColorized: indicator["levels_colorized"],
        inverseImportancy: indicator["inverse_importancy"],
    });

    const getColorByImportancy = (value) => (
         getColor(getScaledValueIfScaledIndicator({value, indicatorResponse, indicator}))
    )

    return {getColor, getColorByImportancy}
}