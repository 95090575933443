import {Space, Tooltip} from 'antd';
import {renderFileType} from '../tables/utils';
import {Typography} from 'antd';

const {Link} = Typography;


export default function FileIconLinks({files, size}) {
    return (
        <Space wrap="wrap" onClick={(e)=>e.stopPropagation()}>
            {files?.map((file, index) => {
                const path = file.url || file.path;
                const name = file.name || file.path;
                return <Link title={name} href={path} target="_blank" key={index} help={"Test"}>{renderFileType(name, size)}</Link>
            })}
        </Space>
    );
}