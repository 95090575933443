import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import Button from "../../../../../components/data_entry/Button";

import {DateFormatByUserTimezone} from "../../../../../utils/dates";
import useTable from "../../../../../hooks/useTable";
import {Col, Row} from "antd";
import Table from "../../../../../components/data_display/Table";
import Drawer from "../../../../../components/layout/Drawer";
import {RANKING_STATUS} from "../../../../../utils/Constants";
import DrawerRankingPlacements
    from "../../../../../components/data_display/DrawerRankingPlacements";
import {PERCENTAGE_CAMPAIGN} from "../../../../../utils/Choices";
import Search from "../../../../../components/data_entry/Search";


const Title = styled.h2`
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0px;
`


export default function CampaignHistory({url, hideColumns = []}) {

    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState('')
    const [openPlacements, setOpenPlacements] = useState(false)
    const [rankingId, setRankingId] = useState(false)
    const {t} = useTranslation();
    const {
        handleRequestTable,
        tableData,
        isLoading,
        tableParams,
        handleTableChange,
    } = useTable({
        url: url,
        waitExternalLoading: !open,
        extraParams: {search: search},
        hasPagination: true,
        dependenceRequest: [open, search]
    })


    function handleOpenPlacements(ranking) {
        setRankingId(ranking.id)
        setOpenPlacements(true)
    }

    function onClose() {
        setOpen(false)
    }

    const columns = [
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
            width: 200

        },
        {
            title: t('Source'),
            dataIndex: 'source_name',
            key: 'source_name',
        },
        {
            title: t('Period'),
            dataIndex: 'date_start',
            key: 'date_start',
            render: (value, campaign) =>
                <>  {DateFormatByUserTimezone(campaign?.date_start)} - {DateFormatByUserTimezone(campaign?.date_end)}
                </>
        },
        {
            title: t('Bonus'),
            dataIndex: 'amount',
            key: 'amount',
            render: (value, campaign) =>
                `${campaign?.amount}${campaign?.method === PERCENTAGE_CAMPAIGN ? '%' : ''}`
        },
        {
            title: t('Additional points generated'),
            dataIndex: 'total_aditional_points',
            key: 'total_aditional_points',
            noEmptyIcon: true
        },

    ]


    return (
        <>
            <Button onClick={() => setOpen(true)} type={'default'}>
                {t('View history')}
            </Button>
            <Drawer width={1200} title={t('Campaign history')} open={open}
                    onClose={onClose}>

                <Row gutter={[16, 16]}>
                    <Col xs={24} md={12} lg={18}>
                        <Title>
                            {t('List of previous campaigns')}
                        </Title>
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <Search onSearch={setSearch}/>
                    </Col>
                    <Col xs={24}>
                        <Table
                            columns={columns.filter(({dataIndex}) => !hideColumns.includes(dataIndex))}
                            onChange={handleTableChange}
                            tableParams={tableParams}
                            loading={isLoading}
                            noScroll
                            dataSource={tableData}
                        />
                    </Col>
                </Row>
            </Drawer>
            <DrawerRankingPlacements id={rankingId} open={openPlacements}
                                     setOpen={setOpenPlacements}
                                     hasClose={false}/>
        </>
    )
}
