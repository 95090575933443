import { useState } from "react";
import {Typography, Space} from "antd";
import SolvCollapse from "../../../../../../misc/SolvCollapse";
import SolvTable from "../../../../../../tables/SolvTable";
import {renderLink, renderProfile} from "../../../../../../tables/utils";
import {useTranslation} from "react-i18next";
import DrawerProfileUser from "../../../../../../../pages/profile/components/DrawerProfileUser/DrawerProfileUser";
import { CrownOutlined } from '@ant-design/icons';

const { Link } = Typography;

export default function TeamMemberTable({members, handleOpenDrawer}) {
    const {t} = useTranslation();

    const columns = [
        {
            title: t("Profile"),
            dataIndex: "name",
            render: (name, member) => <Space>
                {renderProfile(name, member)}
                <CrownOutlined style={{ visibility: member.is_owner ? "visible" : "hidden" }} />
            </Space>
        },
        {
            title: t("Email"),
            dataIndex: "email",
        },
        {
            title: t("Linkedin"),
            dataIndex: "linkedin",
            render: renderLink,
        },
        {
            title: t("Biography"),
            dataIndex: "biography",
        },
    ];
    return (
        <SolvCollapse title={t("Members")} active={true} locked={true} padding={0}>
            <SolvTable columns={columns} dataSource={members} onRowClick={handleOpenDrawer}/>
        </SolvCollapse>
        
    );
}
