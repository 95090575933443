import React from "react";
import {useTranslation} from "react-i18next";
import {
    danger, neutral_7, polar_green_6, primary_5, primary_7,
} from "../../../../utils/Colors";
import EmptyChart from "../components/EmptyChart";
import ChartCardWithDelete from "../components/ChartCardWithDelete";
import ROIChart
    from "../../../../../modules/project/pages/ProjectHome/components/DashboardCharts/ROIChart";
import ChartCard from "../../../../components/data_display/ChartCard";
import {useThemeContext} from "../../../../context/ThemeContext";
import {
    ClientIndicatorBarChart,
    ClientIndicatorColumnChart
} from "../../../../../modules/project/pages/ProjectHome/components/DashboardCharts/ClientIndicatorChart";
import Empty from "../../../../components/data_display/Empty";
import ProjectRadarChart
    from "../../../../../modules/project/pages/ProjectHome/components/DashboardCharts/ProjectRadarChart";
import RiskChart
    from "../../../../../modules/project/pages/ProjectHome/components/DashboardCharts/ColumnChartProject";
import StackedColumnChart
    from "../../../../../modules/project/pages/ProjectHome/components/DashboardCharts/StackedColumnChart";
import TotalExpectedInvestmentChart
    from "../../../../../modules/project/pages/ProjectHome/components/DashboardCharts/TotalExpectedInvestmentChart";
import TotalInvestmentMadeChart
    from "../../../../../modules/project/pages/ProjectHome/components/DashboardCharts/TotalInvestmentMadeChart";


export const PROJECT_CHART_NAMES = { //OBS: se mudar esses valores, vai ter q mudar nos que tiverem salvos nas configurações dos usuários
    DINAMIC: 'dinamic',
    PROJECT_RADAR: 'project_radar',
    RISK: 'risk',
    TOTAL_EXPECTED_INVESTIMENT: 'total_expectedinvestiment',
    TOTAL_INVESTIMENT_MADE: 'total_investiment_made',
    PROJECT_BY_PHASES: 'project_by_phases',


}


export function DrawerProjectChartFactory({
    bodyFilters,
                                              type,
                                              initialDate,
                                              finalDate,
                                              projects, indicator,
                                              ...props
                                          }) {
    const {t} = useTranslation();

    switch (type) {
        case PROJECT_CHART_NAMES.PROJECT_RADAR:
            return <ChartCard
                title={t('Project Radar')} {...props}>

                <ProjectRadarChart filters={bodyFilters} extraParams={{
                    initial: initialDate,
                    final: finalDate,
                    id__in: projects
                }}/>
            </ChartCard>
        case PROJECT_CHART_NAMES.RISK:
            return <ChartCard
                title={t('Projects by Risk')} {...props}>
                <RiskChart filters={bodyFilters} extraParams={{
                    initial: initialDate,
                    final: finalDate,
                    id__in: projects
                }}/>
            </ChartCard>
        case PROJECT_CHART_NAMES.PROJECT_BY_PHASES:
            return <ChartCard
                title={t('Projects by Phases')} {...props}>
                <StackedColumnChart filters={bodyFilters} extraParams={{
                    initial: initialDate,
                    final: finalDate,
                    id__in: projects
                }}/>
            </ChartCard>
        case PROJECT_CHART_NAMES.TOTAL_EXPECTED_INVESTIMENT:
            return <ChartCard

                title={t('Total Expected Investment')} {...props}>

                <TotalExpectedInvestmentChart filters={bodyFilters} extraParams={{
                    initial: initialDate,
                    final: finalDate,
                    id__in: projects
                }}/>
            </ChartCard>
        case PROJECT_CHART_NAMES.TOTAL_INVESTIMENT_MADE:
            return <ChartCard
                plotHeight={'252px'}
                title={t('Total Investment Made')} {...props}>
                <TotalInvestmentMadeChart filters={bodyFilters} extraParams={{
                    initial: initialDate,
                    final: finalDate,
                    id__in: projects
                }}/>
            </ChartCard>

        case PROJECT_CHART_NAMES.DINAMIC:
            return <ChartCard
                title={indicator.name}>
                {<BuildClientIndicatorChart filters={bodyFilters}  initialDate={initialDate}
                                            finalDate={finalDate}
                                            projects={projects}
                                            indicator={indicator}/>}
            </ChartCard>


    }

}


export function ProjectChartsFactory({bodyFilters,
                                         type,
                                         handleOpenDrawer,
                                         initialDate,
                                         finalDate, isLoadingUpdateCharts,
                                         projects, indicator,
                                         ...props
                                     }) {
    const {t} = useTranslation();

    if (indicator && !indicator.is_active) {

        return <ChartCardWithDelete
            title={t(indicator.name)} {...props}>
            <Empty
                description={t('This chart cannot be displayed because its indicator is disabled.')}/>
        </ChartCardWithDelete>
    }

    switch (type) {
        case PROJECT_CHART_NAMES.PROJECT_RADAR:
            return <ChartCardWithDelete
                title={t('Project Radar')} {...props}>

                <ProjectRadarChart render={!isLoadingUpdateCharts}
                                   filters={bodyFilters} extraParams={{
                                       initial: initialDate,
                                       final: finalDate,
                                       id__in: projects
                                   }}/>
            </ChartCardWithDelete>
        case PROJECT_CHART_NAMES.RISK:
            return <ChartCardWithDelete
                title={t('Projects by Risk')} {...props}>
                <RiskChart filters={bodyFilters} extraParams={{
                    initial: initialDate,
                    final: finalDate,
                    id__in: projects
                }}/>
            </ChartCardWithDelete>
        case PROJECT_CHART_NAMES.PROJECT_BY_PHASES:
            return <ChartCardWithDelete
                title={t('Projects by Phases')} {...props}>
                <StackedColumnChart filters={bodyFilters} extraParams={{
                    initial: initialDate,
                    final: finalDate,
                    id__in: projects
                }}/>
            </ChartCardWithDelete>
        case PROJECT_CHART_NAMES.TOTAL_EXPECTED_INVESTIMENT:
            return <ChartCardWithDelete
                title={t('Total Expected Investment')} {...props}>
                <TotalExpectedInvestmentChart filters={bodyFilters} extraParams={{
                    initial: initialDate,
                    final: finalDate,
                    id__in: projects
                }}/>
            </ChartCardWithDelete>
        case PROJECT_CHART_NAMES.TOTAL_INVESTIMENT_MADE:
            return <ChartCardWithDelete
                plotHeight={'252px'}
                title={t('Total Investment Made')} {...props}>
                <TotalInvestmentMadeChart filters={bodyFilters} extraParams={{
                    initial: initialDate,
                    final: finalDate,
                    id__in: projects
                }}/>
            </ChartCardWithDelete>


        case PROJECT_CHART_NAMES.DINAMIC:
            return <ChartCardWithDelete
                title={indicator.name} {...props}>
                {<BuildClientIndicatorChart filters={bodyFilters} initialDate={initialDate}
                                            finalDate={finalDate}
                                            projects={projects}
                                            indicator={indicator}/>}
            </ChartCardWithDelete>
        default:
            return <EmptyChart onClick={handleOpenDrawer}/>

    }

}

function BuildClientIndicatorChart({filters,
                                       indicator,
                                       initialDate,
                                       finalDate,
                                       projects,
                                       ...props
                                   }) {
    const {themeConfig} = useThemeContext();

    if (indicator.view === "percent") {
        return <ClientIndicatorBarChart request={{
            url: `/analytics/dashboard/project_client_dashboard/${themeConfig.client.id}/client_indicators_report`,
            params: {
                initial: initialDate,
                final: finalDate,
                id__in: projects
            },
            body:filters
        }} clientIndicator={indicator} {...props} />
    }
    return <ClientIndicatorColumnChart request={{
        url: `/analytics/dashboard/project_client_dashboard/${themeConfig.client.id}/client_indicators_report`,
        params: {
            initial: initialDate,
            final: finalDate,
            id__in: projects
        },
        body:filters
    }} clientIndicator={indicator} {...props}  />
}