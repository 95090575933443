import React from "react";

import {Space, Typography} from "antd";
import {useContextProfileUser} from "../context/ContextProfileUser";
import Drawer from "../../../../../components/layout/Drawer";
import styled from "styled-components";
import DrawerTabs from "../../../../../components/layout/DrawerTabs";
import TabDrawerProfileUser from "./TabDrawerProfileUser";
import UserAvatar from "../../../../../components/misc/UserAvatar";
import {useTranslation} from "react-i18next";
import TabDrawerEvaluationsUser from "./TabDrawerEvaluationsUser";
import { usePermissionContext } from "../../../../../context/PermissionContext";


const {Text} = Typography;

const StyledTitleDrawer = styled(Text)`
    font-size: 16px;
    font-weight: 500;
`;


const DEFAULT_ACTIVE_KEY = '1'
export default function MainProfileUser({id, open, setOpen, ...props}) {
    const {t} = useTranslation();
    const {hasProgramAdminOrManagerPermission} = usePermissionContext()
    const {
        userData,
        isLoading,
    } = useContextProfileUser();

    function getItems() {
        const items = [
            {
                label: t("Perfil"),
                key: "1",
                children: <TabDrawerProfileUser/>
            },
            {
                label: t("Evaluations"),
                key: "2",
                children: <TabDrawerEvaluationsUser/>,
                permission: hasProgramAdminOrManagerPermission
            },
        ]
        return items.filter((object) => object.hasOwnProperty("permission") ? object.permission : true)
    }

    return (
        <Drawer
            hideTopBorder
            title={
                <Space size="small">
                    <UserAvatar data={userData}/>
                    <StyledTitleDrawer>{userData?.name}</StyledTitleDrawer>
                </Space>
            }
            bodyStyle={{
                position: "relative",
                padding: 0,
            }}
            isGrayBody
            open={open}
            setOpen={setOpen}
            loading={isLoading}
        >
            <DrawerTabs defaultActiveKey={DEFAULT_ACTIVE_KEY}
                        items={getItems()}/>
        </Drawer>
    )
}