import {
    useLayoutContext
} from "../../../components/layout/context/LayoutContext";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Button, Checkbox, Form as FormAntd, Input, Space, Modal, Typography} from "antd";
import React, {useEffect, useState} from "react";
import api from "../../../../../global/services/api";
import Drawer from "../../../../../global/components/layout/Drawer";
import {
    CloseCircleOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import CheckboxFormField
    from "../../../../../global/components/data_entry/form/CheckboxFormField";

import InputFormField
    from "../../../../../global/components/data_entry/form/InputFormField";
import Form from "../../../../../global/components/data_entry/form/Form";

const {confirm} = Modal;


const FORM_NAMES = {
    EMAIL: 'email',
    PERMISSION_MANAGER: 'permission',
}

export default function MemberDrawer({
                                         open,
                                         setOpen,
                                         setDataSource,
                                         editMember,
                                         setEditMember
                                     }) {
    const {openNotification} = useLayoutContext();
    const {t} = useTranslation();
    const {id} = useParams();
    const [loading, setLoading] = useState(false)

    const [form] = FormAntd.useForm();


    const handleSubmit = (data) => {

        data.permission = data.permission || []
        data.permission.push('viewer')
        if (editMember) {
            void handleEditSubmit(data);
            return
        }
        void handleAddSubmit(data);
    }

    const handleAddSubmit = async (data) => {
        try {
            setLoading(true)
            await api.post(`/project_management/project/${id}/add_member`, data);
            form.resetFields();
            setOpen(false);
            setDataSource(undefined);
            openNotification({
                type: "success",
                message: t("Added member successfully")
            });
        } catch (exception) {
            openNotification({
                type: "error",
                message: exception.response?.data.errors
            });
        } finally {
            setLoading(false)
        }
    };

    const handleEditSubmit = async (data) => {
        try {
            setLoading(true)
            await api.patch(`/project_management/member/${editMember.id}`, data);
            form.resetFields();
            setOpen(false);
            setDataSource(undefined);
            setEditMember(undefined)
            openNotification({
                type: "success",
                message: t("Edited member successfully")
            });
        } catch (exception) {
            openNotification({
                type: "error",
                message: exception.response?.data.errors
            });
        } finally {
            setLoading(false)
        }
    }

    const handleDelete = async (data) => {
        const {id} = data;
        try {
            setLoading(true)
            await api.delete(`/project_management/member/${id}`);
            setOpen(false);
            setDataSource(undefined);
            openNotification({
                type: "success",
                message: t("Removed member successfully")
            })
        } catch (exception) {
            openNotification({
                type: "error",
                message: exception.response?.data.errors
            });
        } finally {
            setLoading(false)
        }
    };

    const confirmDeletion = (data) => {
        confirm({
            title: t("Remove member from this project"),
            content: t("This action will prevent a member from managing and tracking the project."),
            icon: <CloseCircleOutlined style={{color: "red"}}/>,
            okText: t("Remove"),
            okButtonProps: {'data-cy': 'confirm-remove-member-button'},
            okType: "danger",
            cancelText: t("Cancel"),
            onOk() {
                void handleDelete(data);
            },
        });
    };

    useEffect(() => {
        if (editMember) {

            form.setFieldsValue({
                [FORM_NAMES.EMAIL]: editMember.username,
                [FORM_NAMES.PERMISSION_MANAGER]: editMember.permissions
            })
        }
    }, [editMember]);

    return (
        <Drawer
            title={editMember ? t("Edit Member") : t("New Member")} open={open}
            onClose={() => setOpen(!open)}
            footer={
                <div style={{textAlign: "end"}}>
                    <Space>
                        {editMember && <Button danger
                                               onClick={() => confirmDeletion(editMember)}
                                               data-cy="remove-member-button"
                                               loading={loading}>{t("Remove")}</Button>}
                        <Button type="primary" onClick={() => form.submit()}
                                data-cy="submit-new-member-button"
                                loading={loading}>{t("Save")}</Button>
                    </Space>
                </div>}>
            <Form layout="vertical" form={form} onFinish={handleSubmit}>
                <InputFormField data-cy="email-input" disabled={!!editMember}
                                placeholder={t("example@email.com")} form={{
                                    label: t("Collaborator Email"),
                                    name:FORM_NAMES.EMAIL,
                                    rules: [{
                                    required: true,
                                    message: t('This field is require')
                                }, {
                                    type: 'email',
                                    message: t('Please provide a valid email')
                                }]
                                }}/>

                <Typography.Title level={5}>{t("Permissions")}:</Typography.Title>
                <CheckboxFormField

                    options={[
                        {label: t("Project Manager"), value: "manager"},
                    ]} form={
                    {
                        "data-cy": "permission-checkbox",
                        name: FORM_NAMES.PERMISSION_MANAGER,
                        help:
                            <Space><ExclamationCircleOutlined/>{t('You can change settings, perform checkpoints and carry out project planning.')}
                            </Space>
                    }
                }/>

                <CheckboxFormField //apenas visual
                    mode={'single'}
                    disabled
                    checked={true}
                    label={t('Viewer')} form={
                    {
                        // name: 'viewer',
                        initialValue: true,
                        help:
                            <Space><ExclamationCircleOutlined/>{t('You can view the dashboard. Every entered user can view this project. If you want to become a manager, select the “Project Manager” permission.')}
                            </Space>
                    }
                }/>
            </Form>
        </Drawer>
    )
        ;
}