import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import styled from "styled-components";
import RemixIcon from "./RemixIcon";
import {
    polar_green_6,
    polar_green_7,
    polar_green_8
} from "../../utils/Colors";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import {FlexContainer} from "../authentication/StyledComponents";
import {
    DatetimeFormatByTimezone
} from "../../utils/dates";
import Button from "../data_entry/Button";
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import {Col, Form as FormAnt, Modal, Row, Tooltip} from "antd";
import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../utils/Utils";
import Drawer from "../layout/Drawer";
import Form from "../data_entry/form/Form";
import TextAreaFormField from "../data_entry/form/TextAreaFormField";
import {ToastNotification} from "../feedback/ToastNotification";
import * as ConstantsMessage from "../../utils/fileWithMessage";
import TagPurchaseGamification from "./TagPurchaseGamification";
import {
    AWAITING_VALIDATION_PURCHASE,
    REVOKED_PURCHASE
} from "../../utils/Constants";


const Container = styled.div`
    width: 100%;
    padding: 16px 24px;
    background: white;
    border-radius: 8px;
`
const IconContainer = styled.div`
    width: 15px;
    height: 15px;

    display: flex;
    justify-content: center;
    align-items: center;

    & > * {
        font-size: 15px;
    }
`

const Title = styled.span`
    font-weight: bold;
    font-size: 16px;
    text-decoration: ${({isRevoked}) => isRevoked ? 'line-through' : 'unset'};
`
const ValidateButton = styled(Button)`
    &:not(:disabled) {
        background: ${polar_green_7} !important;
        border: 1px solid ${polar_green_8} !important;

        &:hover {
            border-color: ${polar_green_8} !important;
            background: ${polar_green_6} !important;
        }

    }
`


export default function PurchaseStoreGamificationCard({
                                                          setHasChange,
                                                          noActions = false,
                                                          id,
                                                          buyer_name,
                                                          item_name,
                                                          created_at,
                                                          dropInfo = false,
                                                          status = REVOKED_PURCHASE,

                                                      }) {
    const {t} = useTranslation()
    const {isPhone} = useSystemBreakpoint()
    const [form] = FormAnt.useForm()
    const [_status, _setStatus] = useState(status)
    const [disabledButtons, setDisabledButtons] = useState(false)
    const [isLoading, setIsLoading] = useState()
    const [open, setOpen] = useState(false)
    const internalDrop = dropInfo || isPhone


    useEffect(() => {
        if (_status !== AWAITING_VALIDATION_PURCHASE) {
            setDisabledButtons(true)
        }
    }, [_status]);

    function handleValidate() {
        Modal.confirm({
            title: t("Validate"),
            icon: <ExclamationCircleOutlined/>,
            content: t("Are you sure you want to validate the purchase?"),
            onOk: async () => {
                try {

                    setIsLoading(true);

                    await api.post(`/store_purchase/${id}/confirm`);
                    _setStatus('successful')
                    setHasChange(true)
                } catch (error) {
                    BuildFieldErrorsDict(error, null, null)
                } finally {
                    setIsLoading(false);
                }
            },
            cancelButtonProps: {loading: isLoading},
            okText: t('Yes'),
            cancelText: t('No')

        });
    }

    async function handleRevoke(data) {
        Modal.confirm({
            title: t("Revoke"),
            icon: <ExclamationCircleOutlined/>,
            content: t("Are you sure you want to revoke the purchase?"),

            onOk: async () => {
                try {
                    setIsLoading(true);

                    await api.post(`/store_purchase/${id}/revoke`, data);

                    ToastNotification(t(ConstantsMessage.SUCCESS))
                    _setStatus(REVOKED_PURCHASE)
                    setHasChange(true)
                    setOpen(false)
                } catch (error) {
                    BuildFieldErrorsDict(error, form.setField, null)
                } finally {
                    setIsLoading(false);
                }
            },
            cancelButtonProps: {loading: isLoading},
            okText: t('Yes'),
            cancelText: t('No')

        });

    }


    return (
        <Container>
            <FlexContainer gap={8} align={'center'}>
                <IconContainer>

                    <RemixIcon
                        remixIconName={'ri-shopping-basket-line'}/>
                </IconContainer>
                <Title isRevoked={_status === REVOKED_PURCHASE}>
                    {item_name}
                </Title>
                <TagPurchaseGamification status={_status}/>
            </FlexContainer>
            <FlexContainer gap={4} align={'start'}
                           wrap={'wrap'}
                           direction={'column'}>
                <Info icon={<RemixIcon
                    remixIconName={'ri-calendar-2-line'}/>}
                      text={DatetimeFormatByTimezone(created_at, false)}/>
                <Info icon={<RemixIcon remixIconName={'ri-barcode-fill'}/>}
                      text={`${t('ID da Transação')}: ${id}`}/>
                <Info icon={<RemixIcon remixIconName={'ri-user-line'}/>}
                      text={buyer_name}/>
            </FlexContainer>
            {!noActions && <Row gutter={[16, 16]} style={{marginTop: 16}}>
                <Col xs={12}><ValidateButton isLoading={isLoading}
                                             disabled={disabledButtons}
                                             onClick={handleValidate} block
                                             icon={
                                                 <CheckCircleOutlined/>}>{t('Validate')}</ValidateButton></Col>
                <Col xs={12}> <Button isLoading={isLoading}
                                      disabled={disabledButtons} block
                                      onClick={() => setOpen(true)}
                                      icon={<RemixIcon
                                          remixIconName={'ri-arrow-go-back-line'}/>}
                                      danger>{t('Revoke')}</Button></Col>
            </Row>}


            <Drawer isGrayBody title={t('Revoke')} open={open}
                    onClose={() => setOpen(false)} footer={
                <FlexContainer justify="end">
                    <Button isLoading={isLoading}
                            disabled={disabledButtons}
                            onClick={form.submit}
                            icon={<RemixIcon
                                remixIconName={'ri-arrow-go-back-line'}/>}
                            danger>{t('Revoke')}</Button>
                </FlexContainer>
            }>
                <Form form={form} disabled={isLoading}
                      onFinish={handleRevoke}>


                    <TextAreaFormField form={{
                        name: 'justification_invalid',
                        label: t('Justification'), rules: [{
                            required: true,
                            message: t('This field is require')
                        }],
                    }}/>
                </Form>
            </Drawer>

        </Container>
    )
}

const StyledFlexContainer = styled(FlexContainer)`
    font-size: 14px;
    color: #00000073;
`

const Text = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
`

function Info({icon, text, showTooltip = false}) {
    return (

        <StyledFlexContainer gap={8}>
            {icon}
            <Tooltip title={showTooltip && text}>
                <Text style={{whiteSpace: 'nowrap'}}>{text}</Text>
            </Tooltip>

        </StyledFlexContainer>
    )
}