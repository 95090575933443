import React, {createContext, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Modal} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
//context

const ConfirmUnsavedChangesContext = createContext({});

//provider
const ConfirmUnsavedChangesProvider = ({children}) => {
    const [openConfirmation, _setOpenConfirmation] = useState(false)
    const [message, _setMessage] = useState(null)

    const history = useHistory();
    const {t} = useTranslation();


    // const handleOK =


        useEffect(() => {
            if (openConfirmation) {
                history.block((prompt) => {
                    Modal.confirm({
                            icon: <ExclamationCircleOutlined/>,
                            title: <>{message ? message.title : t("There are unsaved changes")}</>,
                            content: t("Do you really want to leave?"),
                            onOk: () => {
                                setOpenConfirmation(false)
                                deleteMessage()
                                history.block(() => {
                                });
                                history.push(prompt.pathname);
                            },
                            okText: t('Yes'),
                            okButtonProps:{'data-cy': 'confirm-leave-button'},
                            cancelText: t('No')
                        }
                    )
                    return "true";
                });
            }

            return () => {
                history.block(() => {
                });
            };
        }, [history, openConfirmation]);


    const setOpenConfirmation = (value) => {
        value !== openConfirmation && _setOpenConfirmation(value)
    }

    const setMessage = (messageJson) => {
        _setMessage(messageJson)
    }

    const deleteMessage = () => {
        setMessage(null)
    }

    return (
        <ConfirmUnsavedChangesContext.Provider
            value={{
                setOpenConfirmation,
                setMessage,
                deleteMessage
            }}>
            {children}
        </ConfirmUnsavedChangesContext.Provider>
    );
};

//hook

const useConfirmUnsavedChangesContext = () => {
    return useContext(ConfirmUnsavedChangesContext);
}

export {useConfirmUnsavedChangesContext, ConfirmUnsavedChangesProvider};


