import { Space, Typography, Button } from "antd";
import UserAvatar from "../../../../components/misc/UserAvatar";
import styled from "styled-components";
import StyledCardWithOverlay from "./StyledCardWithOverlay";
import TruncateText from "../../../../components/responsive/TruncateText";
import { useThemeContext } from "../../../../context/ThemeContext";
import { DatetimeFormatByTimezone } from "../../../../utils/dates";
import { useTranslation } from "react-i18next";
import { ACTIONS_CAMPAIGN_CHOICES } from "../../../../utils/Choices";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import useSystemBreakpoint from "../../../../hooks/useSystemBreakpoint";

const { Text } = Typography;

const StyleTitle = styled.div`
    display: flex;
    z-index: 2;
    align-items: start;
    color: white;
    flex-direction: column;
`;

const StyleText = styled(Text)`
    position: relative;
    z-index: 2;
    color: white;
    font-weight: ${({ fontWeight }) => fontWeight || 400};
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "14px")};
    line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : "normal")};
`;

const StyleButton = styled(Button)`
    position: relative;
    z-index: 2;
`;

const translationOptions = (value) => {
    const action = ACTIONS_CAMPAIGN_CHOICES.find((item) => item.value === value);
    return action ? action.label : value;
};

export default function CampaignStoryCard({ campaignItem }) {
    const {themeConfig} = useThemeContext();
    const {t} = useTranslation();
    const history = useHistory();
    const {isPhone} = useSystemBreakpoint()

    return (
        <StyledCardWithOverlay
            hoverable
            bgImage={campaignItem?.img_url}
            title={
                <StyleTitle>
                    <Space size={4}>
                        <UserAvatar size={24} data={{username: themeConfig?.client_name, picture: themeConfig.img_profile}}/>
                        <StyleText fontWeight={400}>{campaignItem?.name}</StyleText>
                    </Space>
                    <StyleText fontSize={16} fontWeight={500}>{t(translationOptions(campaignItem.type_action))}</StyleText>
                </StyleTitle>
            }
            // style={!isPhone && {maxWidth: 317}}
            // style={{minWidth:317}}
        >
                <StyleText fontSize={16} fontWeight={500}>
                    {`${t("Until the day")} ${DatetimeFormatByTimezone(campaignItem.date_end)}`}
                </StyleText>

                <StyleText 
                    fontSize={30} 
                    fontWeight={500} 
                    lineHeight={1.2} 
                >
                    <TruncateText lines={2}>
                        {`${t("Bonus")} ${campaignItem.amount}${campaignItem.method === "percentage" ? "%" : ""}`}
                    </TruncateText>
                </StyleText>

                <StyleText fontWeight={700}>
                    <TruncateText lines={4}>
                        {campaignItem.description}
                    </TruncateText>
                </StyleText>

                <StyleButton 
                       onClick={() => {
                        const targetPath = campaignItem.source_id
                            ? `/available-programs/detail/${campaignItem.source_id}`
                            : '/home';
                        history.push(targetPath);
                    }}
                    type="primary">{t("Enjoy")}!</StyleButton>

        </StyledCardWithOverlay>
    );
}
