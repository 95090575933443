import React, {useEffect, useState} from 'react';
import {Card, Col, Flex, Row, Statistic, Typography} from "antd";
import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../utils/Utils";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import TruncateText from "../../components/responsive/TruncateText";
import {
    AppstoreOutlined,
    ArrowRightOutlined,
    BulbOutlined,
    ProjectOutlined,
    SmileOutlined,
    UserOutlined
} from "@ant-design/icons";
import RemixIcon from "../../components/data_display/RemixIcon";
import {neutral_6} from "../../utils/Colors";
import HelpCard from "../../components/other/HelpCard";
import {Link} from "react-router-dom";
import {useLayoutContext} from "../../../modules/project/components/layout/context/LayoutContext";
import Loading from "../../components/feedback/Loading";
import CardNumber from "../../components/data_display/CardNumber";

function DatabaseHome(props) {

    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [statistics, setStatistics] = useState()

    const fetchStatistics = async () => {
        try {
            setLoading(true)
            const {data} = await api.get('database/statistics')
            setStatistics(data)
        } catch (error) {
            BuildFieldErrorsDict(error, false, false)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        !statistics && void fetchStatistics()
    }, [statistics]);

    const icons = {
        applications: <BulbOutlined/>,
        programs: <AppstoreOutlined/>,
        projects: <ProjectOutlined/>,
        members: <RemixIcon remixIconName="ri-building-2-line"/>,
        companies: <UserOutlined/>
    }

    return (
        loading ? <Loading/> :
        <Flex vertical={true} gap={24}>
            <HelpCard
                title={t("How does the database work?")}
                description={t("The database tables display all the information related to your system. If you want to export specific information, filter the table with the desired data and then export it. This way, you will only have the data you need.")}
                icon={<SmileOutlined/>}
            />
            <Row gutter={[16, 16]}>
                {
                    statistics &&
                    Object.entries(statistics).map(([key, {label, count}]) => (
                        <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={6} key={key}>
                            <Link to={`/database/${key}`}>
                                <CardNumber icon={icons[key]} label={t(label)} number={count}/>
                            </Link>
                        </Col>
                    ))}
            </Row>
        </Flex>
    );
}

export default DatabaseHome;