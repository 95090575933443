import React from "react";
import {Draggable} from "react-beautiful-dnd";
import styled from 'styled-components';

const Item = styled.div.attrs(({ dataCloseRef }) => ({
        'data-close-ref':  dataCloseRef
    }))`
    
    display: flex;
    flex-direction: row;
    align-items: center;
    padding:  ${(props) => (props.render_clone ? '8px 16px;' : '0px')}
    background: #fff;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    border: ${(props) => (props.isDragging ? '1px dashed #4099ff' : 'none')};
    width:100%;
    transition: 0.2s ease;
    margin-bottom:${(props) => (props.render_clone ? '0px' : '7px')};
    &:hover {
        background-color: ${(props) => (props.render_clone ? '#eeedef' : 'inherit')};
        box-shadow: ${(props) => (props.render_clone ? 'inset 4px 0px #949395' : 'none')};    
        cursor: pointer;
        // -webkit-transform: scale(1.05);
        // transform: scale(1.05);
    }
`;

const Clone = Item

function DraggableComponent(props) {
    return (
        <Draggable key={props.id}      draggableId={props.id} index={props.index} useClone={props.render_clone}>
            {(provided, snapshot) => (
                <>
                    <Item
                        dataCloseRef={'true'}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        isDragging={snapshot.isDragging}
                        render_clone={props.render_clone}
                        style={{
                            ...provided.draggableProps.style,
                            transform: (provided.draggableProps.style.transition !== 'none' && provided.draggableProps.style.transition !== null) || !props.render_clone ? provided.draggableProps.style.transform : 'none'
                        }}
                    >
                        {props.children}
                    </Item>
                    {(snapshot.isDragging && props.render_clone) && (
                        <Clone render_clone={props.render_clone}>{props.children}</Clone>
                    )}
                </>
            )}
        </Draggable>
    )

}

export default DraggableComponent