import React from 'react';
import styled from 'styled-components'
import { Spin } from 'antd';
const Color = styled.div`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  ${({value}) => {
      if(value === 100){
          return `color: #73D13D;`;
      }else if(value >= 50){
           return `color: #FAAD14;`
      }
       return `color: #FF7875;`
  }}
`

export default function PercentTable({value, children, ...props}) {
    return (
        <Color  {...props} value={value}>
            {value !== null && children}
        </Color>
    );
}