import {Alert, Form, Switch} from "antd";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import CollapseFormItem from "../../../../../../global/components/data_entry/CollapseFormItem";
import Select from "../../../../../../global/components/data_entry/Select";
import TransparentCollapse from "../../../../../../global/components/misc/TransparentCollapse";
import useOptionsTranslation from "../../../../../../global/hooks/useOptionsTranslation";
import {useRules} from "../../../../../../global/hooks/useRules";
import {
    CHALLENGE_MAPPING,
    CHOICES_TEAM_TYPE,
    CONTINUOUS,
    INTERNAL_INNOVATION,
    OPEN_INNOVATION
} from "../../../../../../global/utils/Choices";
import {secondary_color} from "../../../../../../global/utils/Colors";
import WideSpace from "../../../../../../global/components/layout/WideSpace";

const StyledAlert = styled(Alert)`
    .ant-alert-message {
        color: ${secondary_color};
    }
`

export default function GuidelinesSettingsCollapse({
                                                       form,
                                                       FORM_NAMES,
                                                       hasApplication,
                                                       program,
                                                       hide,
                                                       setHelpDrawerOpen
                                                   }) {
    const {t} = useTranslation()

    const programType = Form.useWatch(FORM_NAMES.PROGRAM_TYPE, form) || program?.[FORM_NAMES.PROGRAM_TYPE]
    const programDuration = Form.useWatch(FORM_NAMES.DURATION_TYPE, form) || program?.[FORM_NAMES.DURATION_TYPE]

    return (
        <>
            {
                !hide &&
                <TransparentCollapse header={t("Guidelines")}>
                    <WideSpace direction="vertical" size="middle">
                        <StyledAlert
                            message={t("You will not be able to change the participation of organizers, self-evaluation, and who can enroll after the start of the registration.")}
                            type="warning" style={{width: "100%"}}/>

                        {programType === CHALLENGE_MAPPING && (<>
                            <TeamTypeField FORM_NAMES={FORM_NAMES} hasApplication={hasApplication}/>
                            <ApplicationBannerRequiredField FORM_NAMES={FORM_NAMES} hasApplication={hasApplication}/>
                            <AllowManagingToBeCandidateField FORM_NAMES={FORM_NAMES} hasApplication={hasApplication}/>
                            <AllowEvaluatorToBeCandidateField FORM_NAMES={FORM_NAMES} hasApplication={hasApplication}/>
                            <RunDeleteUnfinishedApplicationField FORM_NAMES={FORM_NAMES}/>
                            <HiddenPhaseProgramField FORM_NAMES={FORM_NAMES}/>

                        </>)}

                        {programType === INTERNAL_INNOVATION && (<>
                            <TeamTypeField FORM_NAMES={FORM_NAMES} hasApplication={hasApplication}/>
                            <ApplicationBannerRequiredField FORM_NAMES={FORM_NAMES} hasApplication={hasApplication}/>
                            <AllowEvaluatorToBeCandidateField FORM_NAMES={FORM_NAMES} hasApplication={hasApplication}/>
                            <AllowManagingToBeCandidateField FORM_NAMES={FORM_NAMES} hasApplication={hasApplication}/>
                            <RunDeleteUnfinishedApplicationField FORM_NAMES={FORM_NAMES}/>
                            <HiddenPhaseProgramField FORM_NAMES={FORM_NAMES}/>

                        </>)}

                        {programType === OPEN_INNOVATION && (<>
                            <TeamTypeField FORM_NAMES={FORM_NAMES} hasApplication={hasApplication}/>
                            {/* <ApplicationBannerRequiredField FORM_NAMES={FORM_NAMES} hasApplication={hasApplication}/> */}
                            <AllowManagingToBeCandidateField FORM_NAMES={FORM_NAMES} hasApplication={hasApplication}/>
                            <AllowEvaluatorToBeCandidateField FORM_NAMES={FORM_NAMES} hasApplication={hasApplication}/>
                            <RunDeleteUnfinishedApplicationField FORM_NAMES={FORM_NAMES}/>
                            <HiddenPhaseProgramField FORM_NAMES={FORM_NAMES}/>
                            <EnableApplicationEditingField FORM_NAMES={FORM_NAMES} hasApplication={hasApplication} programDuration={programDuration}/>
                            {/*<AllowApplicantEvaluationDetailsField FORM_NAMES={FORM_NAMES}*/}
                            {/*                               hasApplication={hasApplication}/>*/}
                        </>)}

                    </WideSpace>
                </TransparentCollapse>
            }
        </>

    );
}

function VisibilityPublicField({FORM_NAMES}) {
    const {t} = useTranslation()
    return (
        <CollapseFormItem input={<Switch/>}
                          name={FORM_NAMES.IS_CONFIDENTIAL}
                          extraFormItemProps={{valuePropName: "checked"}}
                          label={t("Hidden Inscription")}
                          help={t("When active, access to application data will only be allowed after registration has ended.")}
        />

    )
}


function EnableApplicationEditingField({
                                           FORM_NAMES,
                                           hasApplication,
                                           programDuration
                                       }) {
    const {t} = useTranslation()
    return (
        programDuration !== CONTINUOUS && (
            <CollapseFormItem name={FORM_NAMES.ENABLE_APPLICATION_EDITING}
                              help={t('Allows candidates to chat with our AI Silvia to help them answer questions on the application forms.')}
                              label={t("Edit After Submit")}
                              input={<Switch data-cy="enable-application-editing-switch" disabled={hasApplication}/>}
                              extraFormItemProps={{valuePropName: "checked"}}
            />
        )
    )
}


function HiddenPhaseProgramField({FORM_NAMES}) {
    const {t} = useTranslation()
    return (
        <CollapseFormItem input={<Switch data-cy="enable-application-editing-switch"/>}
                          name={FORM_NAMES.HIDE_PHASES}
                          label={t("Hide step for participants")} S
                          help={t("Hide the view of program steps for participants who are not in them.")}
                          extraFormItemProps={{valuePropName: "checked"}}
        />
    )
}


function TeamTypeField({FORM_NAMES, hasApplication,}) {
    const {t} = useTranslation()
    const {translationOptions} = useOptionsTranslation()
    const {IS_REQUIRED} = useRules()

    return (
        <CollapseFormItem
            input={<Select mode="multiple" options={translationOptions(CHOICES_TEAM_TYPE)}
                           data-cy={"team-type-select"} disabled={hasApplication}/>}
            name={FORM_NAMES.TEAM_TYPE} label={t("Who can sign up?")} rules={[IS_REQUIRED]}
            help={t("The options above refer to which type of registration is allowed.")}
        />
    )
}

function AllowEvaluatorToBeCandidateField({FORM_NAMES, hasApplication}) {
    const {t} = useTranslation()
    return (
        <CollapseFormItem input={<Switch data-cy="allow-evaluator-to-be-candidate-switch" disabled={hasApplication}/>}
                          name={FORM_NAMES.ALLOW_EVALUATOR_TO_BE_CANDIDATE}
                          help={t("Allows you to evaluate your own application.")}
                          label={t("Self-evaluation")}
                          extraFormItemProps={{valuePropName: "checked"}}
        />
    )
}

function AllowManagingToBeCandidateField({FORM_NAMES, hasApplication}) {
    const {t} = useTranslation()
    return (
        <CollapseFormItem input={<Switch data-cy="allow-managing-to-be-candidate-switch" disabled={hasApplication}/>}
                          name={FORM_NAMES.ALLOW_MANAGING_TO_BE_CANDIDATE}
                          help={t("Allows managers and evaluators of this program to also register for it.")}
                          label={t("Participation of Organizers")}
                          extraFormItemProps={{valuePropName: "checked"}}
        />
    )
}

function RunDeleteUnfinishedApplicationField({FORM_NAMES}) {
    const {t} = useTranslation()
    return (
        <CollapseFormItem input={<Switch data-cy="delete-unfinished-applications-switch"/>}
                          name={FORM_NAMES.RUN_DELETE_UNFINISHED_APPLICATIONS}
                          help={t('Automatically deletes applications that do not start filling out the form within 24 hours.')}
                          label={t("Delete inactive applications")}
                          extraFormItemProps={{valuePropName: "checked"}}
        />
    )
}

function AllowApplicantEvaluationDetailsField({FORM_NAMES, hasApplication}) {
    const {t} = useTranslation()
    return (
        <CollapseFormItem
            input={<Switch data-cy="allow-applicant-evaluation-details-switch" disabled={hasApplication}/>}
            name={FORM_NAMES.ALLOW_APPLICANTS_FEEDBACK_VIEW}
            help={t('The application author will be able to see notes and feedback given by evaluators at that stage. Evaluators are not identified.')}
            label={t("Allow the program application to see the notes and feedback given by the authors")}
            extraFormItemProps={{valuePropName: "checked"}}
        />
    )
}

function ApplicationBannerRequiredField({FORM_NAMES, hasApplication}){
    const {t} = useTranslation()
    return (
        <CollapseFormItem
            input={<Switch data-cy="application-banner-required-switch"/>}
            name={FORM_NAMES.APPLICATION_BANNER_REQUIRED}
            help={t('The images in the innovation feed are obtained from the question \"application identified image\", answered by the user when signing up for an internal program.')}
            label={t("Requirement for an identifying image for the application")}
            extraFormItemProps={{valuePropName: "checked"}}
        />
    )
}
