import { extractText } from "../../utils/Utils";
import styled from 'styled-components';

const StyledLineClamp = styled.div`
    display: -webkit-box;
    -webkit-line-clamp: ${props=>props.$lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: ${props=>props.$height ?? "unset"};
`

export default function TruncateText({children, title, lines=1, height, style}) {
    return (
        <StyledLineClamp 
            $lines={lines} 
            $height={height}
            title={title || extractText(children)}
            style={style}
        >
            {children}
        </StyledLineClamp>
    );
}
