import styled from 'styled-components'

export const StyledInput = styled.input`
  border: 1px solid rgb(221, 221, 221);
  padding:${(props) => props.preLabel?"7px 5px 7px 10px !important":"7px 5px"};
  border-radius:${(props) => props.preLabel?"0 6px 6px 0":"6px"};
  outline: none;
  color: #949494;
  width: 100%;
  &::placeholder {
    color: #c4c4c4;
    font-weight: 400;
    font-style: normal;
  }

  &:focus {
    border: 1px solid #949494;
  }
`

export const StyledOptionLabel = styled.label`
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
  line-height: 21.09px;
  color: #949494;
  &:hover{
    cursor:pointer
  }
`