import React, {useEffect, useState} from "react";
import api from "../../global/services/api";
import Loading from "../../global/components/feedback/Loading";
import {BuildFieldErrorsDict} from "../../global/utils/Utils";
import CardHistory from "../../global/components/data_display/CardHistory";
import Empty from "../../global/components/data_display/Empty";

export default function DetailModalHistory({application}) {
    const [logs, setLogs] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetchApplicationLogs()
    }, [])

    const fetchApplicationLogs = async () => {
        try {
            setIsLoading(true)
            const response = await api.get(`/logs?id=${application.id}&model=application`);
            setLogs(response.data)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        } finally {
            setIsLoading(false);
        }
    };


    return (
        <>
            {isLoading ? <Loading/> : logs.length > 0 ?
               (logs.map((item, index) =>
                   <CardHistory key={index} {...item}/>
               )) : <Empty/>
            }
        </>
    )
}
