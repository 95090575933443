import React from "react";
import DefaultComponent from "./DefaultComponent";
import FileInput from "../../../Form/FileInput";

function DocumentForm(props) {

    return (
        <DefaultComponent {...props}>
            <FileInput />
        </DefaultComponent>
    )
}

export default DocumentForm