import React from 'react';
import {useProgramDetailContext} from "../context/ProgramDetailContext";
import PhasesTimeLine from "../../../../components/data_display/PhasesTimeline";

export default function ScheduleProgramTab() {
    const { program } = useProgramDetailContext();
    return (
        <PhasesTimeLine programId={program?.id} program={program?.program}/>
    );
}
