import {Redirect, Route, useHistory} from "react-router-dom";


export const PrivateRoute = (props) => {
    return  <Route{...props}/>
}

export const ManagerRoute = ({hasPermission=true, ...props}) => {
    return hasPermission ? <Route{...props}/> : <Redirect to={{pathname: '/home'}}/>
}

export const EvaluatorRoute = (props) => {
    return props.hasPermission ? <Route{...props}/> : <Redirect to={{pathname: '/home'}}/>
}

export const AdminRoute = (props) => {
    return props.hasPermission ? <Route{...props}/> : <Redirect to={{pathname: '/home'}}/>
}

export const RoutePermission = (props) => {
    return props.hasPermission ? <Route{...props}/> : <Redirect to={{pathname: '/home'}}/>
}

export const ProjectManagementRoute = (props) => {
    return props.hasPermission ? <Route{...props}/> : <Redirect to={{pathname: '/404'}}/>
}
