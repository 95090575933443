import {EditOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {Button, Modal, Typography} from "antd";
import {useTranslation} from "react-i18next";
import WideSpace from "../../../../../../layout/WideSpace";
import FileButton from "../../../../../../button/FileButton";
import SimpleList from "../../../../../../data_display/SimpleList";
import LinkRedirectConfirm from "../../../../../LinkRedirectConfirm";
import api from "../../../../../../../services/api";
import {useLayoutContext} from "../../../../../../../../modules/project/components/layout/context/LayoutContext";
import useEnvironment from "../../../../../../../hooks/useEnvironment";
import useApplication from "../../../../../../../hooks/useApplication";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import {useEffect} from "react";
import DangerousHtmlPreviewer from "../../../../../../data_display/DangerousHtmlPreviewer";

const {Text} = Typography;
const {confirm} = Modal;

export default function ApplicationFormContent({form, phase, solution}) {
    const {t} = useTranslation();
    const {openNotification} = useLayoutContext();
    const headerFormTypes = ["paragraph", "header"];
    const {handleApplicationRedirect} = useApplication();
    const history = useHistory();

    const getFieldType = (name) => {
        return form.find(item => item.name === name);
    }

    const renderFieldValue = (item) => {
        const fieldType = getFieldType(item.name).type;
        if (!item.value) {
            return "-";
        }

        switch (fieldType) {
            case "image":
            case "document":
                return <FileButton link={item.value}/>
            case "link":
                return <LinkRedirectConfirm link={item.value} style={{fontSize:16, textDecoration:"underline"}}/>
            case "checkbox":
            case "multiple_select":
                return <SimpleList items={item.value}/>
            case "section":
                return <Text style={{fontSize: 16}}>{item.value}t</Text>;
            default:
                return <Text style={{fontSize: 16}}>{item.value}</Text>;
        }
    }

    const renderFormField = (item) => {
        return <WideSpace direction="vertical" size={0} style={{paddingBottom: 16}}>
            <Text style={{fontSize: 16, fontWeight: 500, lineHeight: "initial"}}>{item.label}</Text>
            {renderFieldValue(item)}
        </WideSpace>
    }

    const renderFormHeader = (item) => {
        let fontSize = 16;
        if (item.type === "header") {
            fontSize = 20;
        }
        return <WideSpace direction="vertical" size={0} style={{paddingBottom: 16}}>
            <Typography.Text style={{fontSize, fontWeight: 500, lineHeight: "initial"}}>{item.label}</Typography.Text>
            {/* Tratado no backend */}
            <Typography.Text type="secondary">{<DangerousHtmlPreviewer content={item.help_text}/>}</Typography.Text>
        </WideSpace>
    }

    const renderFormItem = (item) => {
        if (headerFormTypes.includes(item.type)) {
            return renderFormHeader(item);
        }
        return renderFormField(item);
    }

    const handleEditApplicationForm = async (solution) => {
        try {
            const response = await api.patch(`v2/applications/${solution.id}/reopen_form`, {});
            handleApplicationRedirect(response.data, "reopen=true");
        } catch (error) {
            openNotification({type: "error", message: t('Cannot reopen application')});
        }
    }


    return (
        <WideSpace direction="vertical" size={24}>
            {phase.short_description && <Text style={{fontSize: 16, fontWeight: 500}}>{phase.short_description}</Text>}
            <WideSpace size={4} direction="vertical">
                {(solution && solution.logged_user_is_member && solution.program.enable_application_editing) && (
                    <div style={{textAlign: "end"}}>
                        <Button onClick={() => handleEditApplicationForm(solution)}><EditOutlined/> {t("Edit Form")}
                        </Button>
                    </div>
                )}
                {
                    form?.map((item, key) => renderFormItem(item))
                }
            </WideSpace>
        </WideSpace>
    )
}
