import React, {useState} from "react";
import {NavLink, useHistory} from 'react-router-dom';
import api from "../../services/api";
import {useTranslation} from "react-i18next";
import LayoutLogin from "../../layouts/authentication/LayoutLogin";
import {Col, Row, Form as FormAntd} from "antd";
import Form from "../../components/data_entry/form/Form";
import {
    AuthButton,
    PassWordCheckList
} from "../../components/authentication/StyledComponents";
import {ToastNotification} from "../../components/feedback/ToastNotification";
import PasswordFormField from "../../components/data_entry/form/PasswordFormField";
import InputFormField from "../../components/data_entry/form/InputFormField";

import * as ConstantsMessage from "../../utils/fileWithMessage";
import CheckboxFormField from "../../components/data_entry/form/CheckboxFormField";
import {GLOBAL_PATHS} from "../../utils/RouteUtils";
import { BuildFieldErrorsDict } from "../../utils/Utils";

export default function Register() {
    const {t} = useTranslation()
    const history = useHistory();
    const [form] = FormAntd.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [values, setValues] = useState({password: '', confirm_password: ''});

    const handleSubmit = async () => {

        try {
            setIsLoading(true)
            const data = await form.getFieldsValue()
            let response = await api.post('/auth/register', {
                ...data,
                accepted_terms: data.accepted_terms[0].toString()
            })

            if (response.status === 201) {
                ToastNotification(t(ConstantsMessage.SUCCESS));

                history.push('/notice_confirm_email/' + form.getFieldValue('email'))


            }
        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, false);
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <LayoutLogin>
            {/*{isLoading ? <Loading/> : (*/}
            <Form form={form}

                      onValuesChange={(changedValues, allValues) => {

                          setValues(allValues)
                      }}
                      requiredMark={false}
                      onFinish={handleSubmit}

                      style={{marginTop: 16}}>
                <Row>
                    <Col xs={24}>
                        <InputFormField form={{
                            label: t("First Name"),
                            name: 'first_name',

                            rules: [{
                                required: true,
                                // message: t('First name is required')
                            },
                            ]


                        }}
                                        placeholder={t("Type your name here...")}/>
                    </Col>
                    <Col xs={24}>
                        <InputFormField form={{
                            label: t("Last Name"),
                            name: 'last_name',

                            rules: [{
                                required: true,
                                // message: t('Last name is required')
                            },
                            ]


                        }}
                                        placeholder={t("Type your last name here...")}/>
                    </Col>
                    <Col xs={24}>
                        <InputFormField form={{
                            label: t('Email'),
                            name: 'email',

                            rules: [{
                                required: true,
                                // message: t('E-mail is required')
                            }, {
                                type: 'email',
                                // message: t('Please provide a valid email')
                            }
                            ]


                        }}
                        placeholder={t("Type your email here...")}/>
                    </Col>
                    <Col xs={24}>
                        <InputFormField form={{
                            label: t('Phone'),
                            name: 'mobile_phone_number',
                            rules: [{required: true}]
                        }}
                        placeholder={t("Type your phone here...")}/>
                    </Col>
                    <Col xs={24}>
                        <PasswordFormField
                            form={{
                                label: t("New Password"),
                                name: 'password',
                                rules: [{
                                    required: true,
                                    // message: t('Password is required')
                                },
                                ]
                            }}
                            placeholder={t("Type your password here...")}/>

                    </Col>
                    <Col xs={24}>
                        <PasswordFormField
                            form={{
                                label: t("Confirm Password"),
                                name: 'confirm_password',
                                rules: [{
                                    required: true,
                                    // message: t('Confirm password is required')
                                },
                                ]
                            }}
                            placeholder={t("Type your password here...")}/>

                    </Col>
                    <Col xs={24}>
                        <PassWordCheckList
                            color={'var(--login_text_color_cover)'}
                            rules={["minLength", "specialChar", "number", "capital", 'lowercase', "match"]}
                            minLength={8}
                            iconSize={12}
                            value={values.password || ''}
                            valueAgain={values.confirm_password || ''}
                            onChange={(isValid) => {
                                setDisabledButton(isValid)
                            }}
                            messages={{
                                minLength: t("Password has more than 8 characters."),
                                specialChar: t("Password has special characters."),
                                number: t("Password has a number."),
                                capital: t("Password has a capital letter."),
                                lowercase: t("Password has a lowercase letter"),
                                match: t("Passwords match."),
                            }}
                        />
                    </Col>
                    <Col xs={24}>
                        <CheckboxFormField form={{
                            name: 'accepted_terms',
                            rules: [{
                                required: true,
                                message: t('Terms is required')
                            }],
                        }}
                                           options={[
                                               {
                                                   label: `${t("I have read & accepted the")} ${t("terms & conditions")}`,
                                                   value: true,
                                               },
                                           ]}/>
                    </Col>


                    <Col xs={24} style={{marginTop: 24}}>
                            <AuthButton block
                                        htmlType="submit"
                                        disabled={!disabledButton}
                                        loading={isLoading}>{t('Create account')}</AuthButton>
                        <NavLink
                            to={`${GLOBAL_PATHS.LOGIN}`}>{t('Already have an account')}? {t('Go back to Log in')}</NavLink>
                    </Col>

                </Row>

            </Form>
            {/*)}*/}
        </LayoutLogin>
    );
}