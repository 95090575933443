import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyPageCard from '../../../../../global/components/layout/EmptyPageCard';
import { Button, Space, Typography } from 'antd';

const { Text } = Typography;

export default function EmptyFinancialPlanning(props) {
    const {t} = useTranslation();

    return (
            <EmptyPageCard>
                <Space direction="vertical">
                    <Text>{t("Enter the initial planning information!")}</Text>
                    <Text>{t("This information is used to create the financial planning automatically.")}</Text>
                    <Text>{t("Just click the button below or in the 'planning information' tab.")}</Text>
                    <Button type="primary">{t("Insert here!")}</Button>
                </Space>
            </EmptyPageCard>
        )
}