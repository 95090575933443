import React, {useEffect, useRef, useState} from 'react';
import {Form as FormAntd, Modal} from "antd";
import {
    FlexContainer
} from "../../../../../../global/components/authentication/StyledComponents";
import {useTranslation} from "react-i18next";
import Button from "../../../../../../global/components/data_entry/Button";
import Form from "../../../../../../global/components/data_entry/form/Form";
import InputFormField
    from "../../../../../../global/components/data_entry/form/InputFormField";

const FORM_NAME = {
    NAME: 'name'
}

export default function ModalFormSection({
                                             editedSection,
                                             open,
                                             onSave,
                                             setOpen
                                         }) {
    const {t} = useTranslation()
    const [form] = FormAntd.useForm()

    function onClose() {
        setOpen(false)
        form.resetFields()
    }

    useEffect(() => {
        if (editedSection?.id) {
            form.setFieldsValue({name: editedSection?.name})
        }
    }, [editedSection]);

    return (
        <Modal
            open={open}
            title={editedSection?.id ? t('Rename section') : t('New section')}
            footer={<FlexContainer justify={'end'}>
                <Button onClick={form.submit}>{t('Save')}</Button>
            </FlexContainer>}
            onCancel={onClose}
        >
            <Form form={form} onFinish={(values) => {
                onSave?.(values)
                onClose()
            }}>
                <InputFormField form={{
                    name: FORM_NAME.NAME,
                    label: t('Section name'),
                    rules: [{
                        required: true,
                        message: t('This field is require')
                    }]
                }} data-cy="title-input"/>
            </Form>
        </Modal>
    )
}