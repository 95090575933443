import React, {useState} from 'react';
import {Col, Row, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {neutral_4} from "../../../../global/utils/Colors";
import {
    FlexContainer
} from "../../../../global/components/authentication/StyledComponents";
import RemixIcon from "../../../../global/components/data_display/RemixIcon";

import {
    ReactComponent as PlusGamificationIcon
} from "../../../../assets/Icon/amount_plus_gamification.svg";
import styled from "styled-components";
import {formatPoints} from "../../../../global/utils/Utils";
import LimitNumber
    from "../../../../global/components/data_display/LimitNumber";
import useDefaultGetRequest
    from "../../../../global/hooks/useDefaultGetRequest";
import {useProgramContext} from "../../../ProgramsNavigator";
import AmountCardGamification
    from "../../../../global/components/data_display/AmountCardGamification";
import ProgramGamificationAmounts
    from "./components/ProgramGamificationAmounts";
import SectionOpenRankingProgram from "./components/SectionOpenRankingProgram";
import SectionScheduledRankingProgram
    from "./components/SectionScheduledRankingProgram";
import CollapseFormItem
    from "../../../../global/components/data_entry/CollapseFormItem";
import RankingHistory
    from "../../../../global/pages/gamification/pages/ranking/components/RankingHistory";
import SectionOpenCampaignProgram
    from "./components/SectionOpenCampaignProgram";
import AddNewCampaignButton
    from "../../../../global/pages/gamification/pages/bonusCampaign/components/AddNewCampaignButton";
import AddNewCampaignProgramButton
    from "./components/AddNewCampaignProgramButton";
import SectionScheduledCampaignProgram
    from "./components/SectionScheduledCampaignProgram";
import CampaignHistory
    from "../../../../global/pages/gamification/pages/bonusCampaign/components/CampaignHistory";

const {Text} = Typography

const ListAmount = styled(FlexContainer)`
    //flex: 1;
    //width: min-content;
    overflow-y: scroll;
    width: 100%;
`
export default function ProgramGamification(props) {
    const {t} = useTranslation()
    const {program} = useProgramContext()
    const {
        data: gamificationAmounts,
        isLoading: isLoading
    } = useDefaultGetRequest({
        url: `/action/program/${program.id}/get_object_releted_counters/`,
        makeRequest: !!program.id,
        dependence: [program.id]
    })
    return (
        <Row gutter={[24, 24]}>
            <Col xs={24} style={{marginBottom: 16}}>
                <ProgramGamificationAmounts/>
            </Col>
            <Col xs={24}>
                <SectionOpenRankingProgram/>
            </Col>
            <Col xs={24}>
                <SectionScheduledRankingProgram/>
            </Col>
            <Col xs={24}>
                <CollapseFormItem label={t('View ranking history')}
                    // help={t('You can only have one ranking at a time. You cannot create a ranking whose date conflicts with another ranking.')}
                                  input={<RankingHistory
                                      hideColumns={['source_name']}
                                      url={`/ranking/program/${program?.id}/get_ranking_object_related/`}/>}/>
            </Col>
            <Col xs={24}>
                <SectionOpenCampaignProgram/>
            </Col>
            <Col xs={24}>
                <CollapseFormItem label={t('Create new campaign')}
                                  help={t('Set the time, period and bonuses that apply to the campaign.')}
                                  input={<AddNewCampaignProgramButton/>}/>
            </Col>
            <Col xs={24}>
                <SectionScheduledCampaignProgram/>
            </Col>
            <Col xs={24}>
                   <CollapseFormItem label={t('View campaign history')}
                              input={<CampaignHistory hideColumns={['source_name']} url={`/point_event/program/${program.id}/get_campaign_object_related/`}/>}/>
            </Col>
        </Row>
    );
}
