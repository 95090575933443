import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';

import {
    BulbOutlined,
    DownOutlined,
    HomeOutlined,
    MenuUnfoldOutlined,
    MessageOutlined
} from '@ant-design/icons';
import {
    Avatar,
    Badge,
    Col,
    Form,
    Layout,
    Row,
    Space,
    Typography
} from "antd";
import {
    FlexContainer
} from "../../../../global/components/authentication/StyledComponents";
import RemixIcon from '../../../../global/components/data_display/RemixIcon';
import Button from "../../../../global/components/data_entry/Button";
import InputFormField from "../../../../global/components/data_entry/form/InputFormField";
import SwitchFormField from "../../../../global/components/data_entry/form/SwitchFormField";
import UserAvatar from "../../../../global/components/misc/UserAvatar";
import { useUserContext } from "../../../../global/context/UserContext";
import { onChangePicture } from "../../../../modules/project/utils/utils";

const {Title} = Typography;


const StyledLayout = styled(Layout)`
  
  background: white;
  //height: 100%;
    overflow: hidden;
  width: 100%;
  border-radius: 9px;
  box-shadow: 0px 10.121326446533203px 31.488574981689453px 8.996734619140625px #0000000D;


  & .ant-switch-checked, & .ant-btn-primary {
    background-color: ${({background}) => background} !important;
    border-color: ${({background}) => background} !important;
    color: ${({color}) => color} !important;
  }
`

const Menu = styled.div`
  background-color: ${({background}) => background};
  height: 100%;
  width: 100%;
  padding: 8px 0;
`

const ContainerForm = styled(Col)`
  flex: ${props => props.isLg ? '0 0 40%' : 'auto'};
`
const StyledAuthButton = styled(Button)`

  background: ${({background}) => background} !important;
  color: ${({color}) => color} !important;

`


const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 16px;
  justify-content: space-between;
  position: relative;

  * {
    color: ${({color}) => color} !important;
  }

  & span.ant-badge {
    color: ${({color}) => color} !important;
  }


  & > *:first-child {
    flex: 1;
  }
`

const ProfileContainer = styled.div`
  position: absolute;
  padding: 8px;
  background-color: white;
  top: 50px;
  left: 60px;
  width: 150px;
  height: 50px;
  &  span {
    color: black !important;
  }
`

const StyledLogoImg = styled.img`
  object-fit: contain;
  max-width: 138px;
  height: 45px;
  display: block;
`
const StyledFlexContainer = styled(FlexContainer)`
  color: ${({color}) => color} !important;
  padding: 8px 16px;
  width: 100%;
  background-color: ${({
                         active,
                         active_menu_color
                       }) => active ? active_menu_color : 'transparent'};
`

const alert_red_color = '#EA5349'

const StyledBadge = styled(Badge)`
  & sup {
    box-shadow: 0 0 0 1px ${alert_red_color} !important;
  }
`
const Container = styled.div`
    background-color: white;
    padding: 8px;
  border-radius: 8px;
`

export default function EnvironmentPreview({FORM_NAMES, form}) {
    const {t} = useTranslation();
    const img_action_bar = Form.useWatch(FORM_NAMES.IMG_ACTION_BAR, form) || []
    const text_color_menu = Form.useWatch(FORM_NAMES.TEXT_COLOR_MENU, form)
    const primary_btn_text_color = Form.useWatch(FORM_NAMES.PRIMARY_BTN_TEXT_COLOR, form)
    const primary_btn_color = Form.useWatch(FORM_NAMES.PRIMARY_BTN_COLOR, form)
    const background_menu = Form.useWatch(FORM_NAMES.BACKGROUND_MENU, form)
    const active_menu_color = Form.useWatch(FORM_NAMES.ACTIVE_MENU_COLOR, form)
    const img_profile = Form.useWatch(FORM_NAMES.IMG_PROFILE, form)|| []


    const {_user,} = useUserContext();
    const menuOptions = [
        {
            key: 'profile',
            name: _user.full_name,
            icon: <UserAvatar size={24} data={_user}/>,
        },
        {

            key: 'home',
            name: t('Home'),
            icon: <HomeOutlined/>,
        },
        {
            key: 'messages',
            name: t('Messages'),
            icon: <MessageOutlined/>,
        },
        {
            active: true,
            key: 'solutions',
            name: t('Solutions'),
            icon: <BulbOutlined/>,
        },
        {
            key: 'open_applications_challenges',
            name: t('Open Challenges'),
            icon: <RemixIcon remixIconName={"ri-puzzle-line"} />,
        },


    ]

    const [imgActionBar, setImgActionBar] = useState()
    const [imgProfile, setImgProfile] = useState()


    useEffect(() => {
        if (img_action_bar) {
            onChangePicture(img_action_bar, setImgActionBar)

        }
    }, [img_action_bar])
    
        useEffect(() => {
        if (img_profile) {
            onChangePicture(img_profile, setImgProfile)

        }
    }, [img_profile])



    return (

        <StyledLayout background={primary_btn_color}
                      color={primary_btn_text_color}>
            <Row style={{height: '100%', }}
                 gutter={[16, 16]}>
                <Col xs={8}  xl={6} xxl={4}>
                    <Menu background={background_menu}>
                        <MenuHeader color={text_color_menu}>
                            <Space align={'center'}>
                                {/*<StyledLogoContainer collapsed={collapsed}>*/}
                                <StyledLogoImg
                                    src={imgActionBar}
                                    alt="logo"/>
                                <DownOutlined/>
                            </Space>
                            <MenuUnfoldOutlined/>
                            <ProfileContainer>
                                <Space>
                                    <Avatar size="small"
                                                   src={imgProfile}/>
                                    <span>{t('Environment')}</span>
                                </Space>
                            </ProfileContainer>
                        </MenuHeader>
                        <FlexContainer gap={8} direction={'column'}
                                       align={'start'}>
                            {menuOptions.map(({key, name, icon, active}) => (
                                <StyledFlexContainer active={active}
                                                     active_menu_color={active_menu_color}
                                                     justify={'start'}
                                                     key={key} gap={6}
                                                     color={text_color_menu}>
                                    {icon}
                                    <span>{name}</span>
                                </StyledFlexContainer>
                            ))}

                        </FlexContainer>
                    </Menu>
                </Col>
                <Col xs={16} xl={18} xxl={20} style={{}}>
                    <Row gutter={[16, 16]}>

                        <Col xs={24}>

                            <Container>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24}>
                                        <Typography.Title
                                            level={5}>{t('General')}</Typography.Title></Col>
                                    <Col xs={24}>
                                        <InputFormField form={{
                                            label: t('Name'),

                                        }}/>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <InputFormField
                                            // prefix={'@'}
                                            form={{
                                                label: t('E-mail for support'),

                                                rules: [{
                                                    type: 'email',
                                                    message: t('Please provide a valid email')
                                                }]
                                            }}/>

                                    </Col>

                                    <Col xs={24}>
                                        <SwitchFormField
                                            checked={true}
                                            form={{
                                                label: t("Make innovation with hidden inscription"),
                                                help: t('This option allows you to configure innovation programs with hidden subscriptions.'),
                                            }}/>
                                    </Col>
                                    <Col>

                                        <Button>{t('Save')}</Button>
                                    </Col>
                                </Row>
                            </Container>

                        </Col>
                    </Row>

                </Col>
            </Row>
        </StyledLayout>


    );
}