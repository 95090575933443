import React from 'react';
import Loading from "../feedback/Loading";
import {Empty} from "antd";


export default function ListOrEmpty({loading, list, callback,}) {


    return (
        <>
            {loading ? <Loading/> : list?.length > 0 ? list.map(callback) :
                <Empty/>}
        </>
    );
}

