import React from "react";
import DefaultComponent from "./DefaultComponent";
import {InputRendered} from "../StyledComponents";

function IntegerForm(props) {

    return (
        <DefaultComponent {...props}>
            <InputRendered placeholder={'Number'} type={'number'}/>
        </DefaultComponent>
    )
}

export default IntegerForm