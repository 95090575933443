import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    FilterOutlined,
    PlusOutlined,
} from "@ant-design/icons";
import {Collapse, Form as FormAnt, Space, Switch} from "antd";
import {
    useLayoutContext
} from "../../../../modules/project/components/layout/context/LayoutContext";
import useOptionsTranslation from "../../../hooks/useOptionsTranslation";
import * as ConstantsMessage from "../../../utils/fileWithMessage";
import Button from "../../../components/data_entry/Button";
import Drawer from "../../../components/layout/Drawer";
import {
    FlexContainer
} from "../../../components/authentication/StyledComponents";
import InformationCard from "../../../components/data_display/InformationCard";
import Form from "../../../components/data_entry/form/Form";
import SolvCollapse from "../../../components/misc/SolvCollapse";
import styled from "styled-components";
import CheckboxField from "../../../components/data_entry/CheckboxField";
import TagActionGamification
    from "../../../components/data_display/TagActionGamification";
import CheckboxWithSwitchFormField
    from "../../../components/data_entry/form/CheckboxWithSwitchFormField";
import DateRangePicker from "../../../components/data_entry/DateRangePicker";
import DateRangePickerFormField
    from "../../../components/data_entry/form/DateRangePickerFormField";
import {BuildFieldErrorsDict} from "../../../utils/Utils";
import {
    ToastNotification
} from "../../../components/feedback/ToastNotification";
import SimpleCollapse from "../../../components/misc/SimpleCollapse";
import FilterButton from "../../../components/button/FilterButton";


const StyledCollapse = styled(Collapse)`
    .ant-collapse-content-box {
        background: white;
        padding-top: 16px !important;
    }

    .ant-collapse-header-text {
        font-weight: 500;
        font-size: 14px;
    }

    border-radius: 8px;
    //background: red;
`
export default function FilterActionWalletButton({
                                                     filters,
                                                     setFilters,
                                                     FORM_NAMES_FILTERS
                                                 }) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [form] = FormAnt.useForm()

    useEffect(() => {
        if (open) {
            form.setFieldsValue({
                [FORM_NAMES_FILTERS.MOVIMENT]: filters[FORM_NAMES_FILTERS.MOVIMENT]? '': ['leave','entry'],
                [FORM_NAMES_FILTERS.STATUS]: filters[FORM_NAMES_FILTERS.STATUS],
                [FORM_NAMES_FILTERS.DATES]: [filters[FORM_NAMES_FILTERS.DATES]?.[0], filters[FORM_NAMES_FILTERS.DATES]?.[1]]
            })
        }
    }, [open])
    const STATUS_ITEMS = [

        {
            label: <TagActionGamification
                status={'pending'}/>, value: "pending"
        },
        {
            label: <TagActionGamification
                status={'consolidated'}/>, value: "consolidated"
        },
        {
            label: <TagActionGamification
                status={'revoked'}/>, value: "revoked"
        },
    ]
    const MOVIMENT_ITEMS = [
        {label: t("Output"), value: "leave"},
        {label: t("Input"), value: "entry"},
    ]

    function onClose() {
        setOpen(false)
    }

    async function getValuesForm() {
        let data = await form.getFieldsValue()
        const newData = {...data}


        newData[FORM_NAMES_FILTERS.MOVIMENT] = !newData[FORM_NAMES_FILTERS.MOVIMENT] || newData[FORM_NAMES_FILTERS.MOVIMENT]?.length === 2 ? '' : newData[FORM_NAMES_FILTERS.MOVIMENT][0]


        return newData
    }

    async function handleSubmit() {
        try {
            setIsLoading(true);
            let data = await getValuesForm()
            setFilters({
                [FORM_NAMES_FILTERS.DATES]: data[FORM_NAMES_FILTERS.DATES],
                [FORM_NAMES_FILTERS.MOVIMENT]: data[FORM_NAMES_FILTERS.MOVIMENT],
                [FORM_NAMES_FILTERS.STATUS]: data[FORM_NAMES_FILTERS.STATUS],
            })
            ToastNotification(t(ConstantsMessage.SUCCESS))
            onClose()

        } catch (error) {

            BuildFieldErrorsDict(error, form.setFields, null);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <>
            <FilterButton  onClick={() => setOpen(true)}/>

            <Drawer title={t('Filters')} open={open} onClose={onClose}
                    footer={
                        <FlexContainer justify="end" gap={8}>
                            <Button type={'default'}
                                    onClick={() => form.resetFields()}
                                    loading={isLoading}>{t("Reset filters")}</Button>
                            <Button
                                onClick={form.submit}
                                loading={isLoading}>{t("Save")}</Button>
                        </FlexContainer>
                    }>

                <Form style={{marginTop: 24}} form={form} disabled={isLoading}
                      onFinish={handleSubmit}>

                     <SimpleCollapse title={t('Status')}>
                        <CheckboxWithSwitchFormField
                                form={{name: FORM_NAMES_FILTERS.STATUS}}
                                options={STATUS_ITEMS}/>
                    </SimpleCollapse>
                   <SimpleCollapse title={t('Transaction type')}>
                          <CheckboxWithSwitchFormField
                                form={{name: FORM_NAMES_FILTERS.MOVIMENT}}
                                options={MOVIMENT_ITEMS}/>
                    </SimpleCollapse>
                    <SimpleCollapse title={t('Date')}>
                          <DateRangePickerFormField style={{width: '100%'}}
                                                      form={{name: FORM_NAMES_FILTERS.DATES}}/>
                    </SimpleCollapse>
                </Form>
            </Drawer>

        </>


    )
}
