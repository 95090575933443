import React, {useState} from 'react';
import {MessageOutlined} from "@ant-design/icons";
import {Avatar, Flex, Tooltip, Typography} from "antd";
import UserAvatar from "../../../../components/misc/UserAvatar";
import useEventListener from "../../../../hooks/useEventListener";
import {useUserContext} from "../../../../context/UserContext";
import { useTranslation } from 'react-i18next';

const {Text} = Typography

export default function MessageIconContainer({postId, topicComments, setTopicMessage}) {

    const [hasCommented, setHasCommented] = useState(topicComments?.has_commented)
    const [count, setCount] = useState(topicComments?.count)
    const users = topicComments?.users
    const {_user} = useUserContext()
    const {t} = useTranslation()

    const handleMessageUpdate = () => {
        setCount(count + 1)
        setHasCommented(true)
    }

    useEventListener(`update-message-post-count-${postId}`, handleMessageUpdate);

    return (
        <Tooltip title={t("Comments")}>
            <Flex align="center" gap={4} onClick={() => setTopicMessage ? setTopicMessage(postId) : undefined} style={{cursor:setTopicMessage && "pointer"}}>
                <MessageOutlined/>
                <Text>{count}</Text>
                <Avatar.Group max={{count: 3}} size={20} style={{pointerEvents: "none"}}>
                    {hasCommented &&
                        <UserAvatar data={_user} size={20}/>
                    }
                    {users?.map((user) => (
                        <UserAvatar key={user?.id} data={user} size={20}/>
                    ))}
                </Avatar.Group>
            </Flex>

        </Tooltip>
    );
}

