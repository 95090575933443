import { useEffect, useState, useContext } from "react";
import { Button, Typography, Form, Select, Space, Radio } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useLayoutContext } from "../../modules/project/components/layout/context/LayoutContext";
import SolvModal from "../components/layout/SolvModal";
import api from "../services/api"
import UserContext, {
    useUserContext
} from "../context/UserContext";
import moment from "moment-timezone";
import {cookie_api} from "../utils/Auth";
import { useTranslation } from "react-i18next";


const { Text } = Typography;

function WelcomeForm({form}){

    const [languageCodeChoices, setLanguageCodeChoices] = useState([])
    const {setShowWelcomeModal} = useLayoutContext();
    const {openNotification} = useLayoutContext()
    const {_user, setRefresh} = useUserContext();
    const languageCode = _user ? _user["language_code"] : "en-us"  
    const currentSystemTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const { t } = useTranslation();

    const changeTimezone = async () => {
        try {
            const response = await api.patch(`/auth/user`, {"time_zone": currentSystemTimezone});
            if (response.status === 200) {
                cookie_api.set(`user-${_user.id}_timezone`, currentSystemTimezone)
            }
        } catch (error) {}
    }

    const fetchLanguageChoices = async () => {
        try {
            const response = await api.get("/language");
            let languageData = response.data.map(
                (item) => ({value: item[0], label: item[1]})
            )
            setLanguageCodeChoices(languageData)
        } catch (error) {}
    };

    const keepTimezone = () => {
        cookie_api.set(`user-${_user.id}_timezone`, _user.time_zone);
    }

    const handleSubmit = async (data) => {
        try {
            if (data.changeTimezone){
                await changeTimezone();
            } else {
                keepTimezone();
            }
            delete data.changeTimezone

            await api.patch(`/auth/user`, data)
            openNotification({type: "success", message:t("Updated user locale settings")})
            setShowWelcomeModal(false);
            setRefresh(true);
        } catch (error) {
            openNotification({type:"error", message:t("Couldn't update user locale settings")})
        }
    }

    useEffect(() => {
        void fetchLanguageChoices()
    }, [])

    return(
        <Space direction="vertical">
            <Text>{t("This initial configuration serves to facilitate its use on the platform.")}</Text>        
            <Form layout="vertical" form={form} onFinish={handleSubmit}>

                <Form.Item 
                    name="language_code" 
                    label={t("Default language")} 
                    initialValue={languageCode} 
                    rules={[{ required: true }]}
                    extra={t("This is the system language")}
                    style={{marginBottom:8}}
                    >
                    <Select placeholder={t("Select a language")} >
                        {languageCodeChoices.map((item, key) =>(
                            <Option key={key} value={item.value}>{item.label}</Option>
                        )) }

                    </Select>

                </Form.Item>

                <Form.Item initialValue={true} label={`${t("Change time zone to")} ${currentSystemTimezone} (GMT ${(moment().format("Z"))})`} style={{marginBottom:8}} name="changeTimezone">

                    <Radio.Group buttonStyle="solid">
                        <Space>
                            <Radio.Button value={false}>{t("No")}</Radio.Button>
                            <Radio.Button value={true}>{t("Yes")}</Radio.Button>
                        </Space>
                    </Radio.Group>

                </Form.Item>

            </Form>
        </Space>
    )
}


function WelcomeModal(){ 
    const {showWelcomeModal, setShowWelcomeModal} = useLayoutContext();
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false)

    function handleSubmit(){
        setIsLoading(true)
        form.submit()
    }

    return (
        <SolvModal
            title={t("Welcome to Solv")}
            open={showWelcomeModal}
            setOpen={setShowWelcomeModal}
            closeIcon={< CloseCircleOutlined style={{color:"#000000D9", fontSize:16}}/>}
            centered
            width={480}
            footer={[
                <Button onClick={handleSubmit} loading={isLoading} type="primary" data-cy="save-welcome-config-button" >
                    {t("Save")}
                </Button>
            ]}
        >

            <WelcomeForm form={form}/>
      </SolvModal>
    );
};

export default WelcomeModal