import { createContext, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import ProjectCreateExtraHeader from "../components/ProjectCreateExtraHeader";
import {useLayoutContext} from "../../../components/layout/context/LayoutContext";
import { useTranslation } from "react-i18next";

const ProjectCreateContext = createContext({});

export function ProjectCreateContextProvider({ children }) {
    const { t } = useTranslation()
    const menuItems = [
        {
            label: <Link to="/project-portfolio/project/create">{t("Settings")}</Link>,
            key: '/project-portfolio/project/create',
        },
    ]
    

    const { initializeLayout } = useLayoutContext();

    useEffect(() => {
        initializeLayout({
            customBackgroundColor: "var(--solv-fading-background)",
            navbarMenuItems: menuItems,
            extraHeaderContent: <ProjectCreateExtraHeader />,
            useOldContentLayout: false,
            showAvatar: false,
        });
    }, []);

    return <ProjectCreateContext.Provider value={{}}> {children} </ProjectCreateContext.Provider>;
}


export function useProjectCreateContext() {
    return useContext(ProjectCreateContext);
}