import {Card, Col, Form as FormAnt, Row, Space} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import SectionOpenRanking from "./components/SectionOpenRanking";

import AddNewRankingButton from "./components/AddNewRankingButton";
import useDefaultGetRequest from "../../../../hooks/useDefaultGetRequest";
import {
    FlexContainer
} from "../../../../components/authentication/StyledComponents";
import InformationCard
    from "../../../../components/data_display/InformationCard";
import CollapseFormItem
    from "../../../../components/data_entry/CollapseFormItem";
import SectionScheduledRanking from "./components/SectionScheduledRanking";
import RankingHistory from "./components/RankingHistory";



export default function RankingPage() {
    const {t} = useTranslation();


    return (

        <FlexContainer gap={16} direction={'column'} align={'streech'}>
            <InformationCard title={t('Rankings by program')}
                             text={t('To add rankings to specific programs, visit the program page')}/>
            <CollapseFormItem label={t('Create new ranking')}
                              help={t('You can only have one ranking at a time. You cannot create a ranking whose date conflicts with another ranking.')}
                              input={<AddNewRankingButton/>}/>
            <SectionOpenRanking/>
            <SectionScheduledRanking/>
            <CollapseFormItem label={t('View ranking history')}
                // help={t('You can only have one ranking at a time. You cannot create a ranking whose date conflicts with another ranking.')}
                              input={<RankingHistory url={`/ranking`}/>}/>

        </FlexContainer>

    );
}

