import {useEffect, useState} from 'react';
import {Pie} from '@ant-design/plots';
import api from "../../../../../global/services/api";
import LoadingFull from '../../../../../global/components/other/LoadingFull';
import Empty from '../../../../../global/components/data_display/Empty';
import {
    BuildFieldErrorsDict,
    getPercent,
    sumArray
} from '../../../../../global/utils/Utils';
import {useTranslation} from 'react-i18next';
import ChartLegendWrapper
    from "../../../../../global/components/data_display/ChartWrapper/ChartLegendWrapper";

const DEFAULT_CONFIG_REQUEST = {
    version: 'v1'
}
export default function PieGraph({

                                     request,
                                     externalData = null,
                                     legend_key = 'label',
                                     value = "count",

                                     showPercentLabel = true,

                                     expand
                                 }) {
    const {t} = useTranslation();
    const [isLoading, setIsloading] = useState(false)
    const [datas, setDatas] = useState(externalData);
    const total = sumArray(datas, value)

    const colorField = legend_key


    const config = {
        data: datas,
        angleField: value,
        colorField: legend_key,
        autoFit: true,
        animate: null,
        label: showPercentLabel && {
            text: ({count}) => {
                return `${getPercent(count, total, 1)}%`;
            },
            fill: '#fff',
            fontSize: 14,
        },
        tooltip: (d) => (
            {
                name: t(d[legend_key]),
                value: `${d.count}`
            }
        ),
        interaction: {
            elementHighlightByColor: {
                link: true
            }
        },
        _legend: {
            colorField,
            rows: 1,
            cols: 3,
            labelFormatter: (label) => ({
                text: t(label.text),
            })
        },
    };


    useEffect(() => {
        if (!externalData && request?.url) {
            const fetchData = async () => {
                try {
                    setIsloading(true)
                    const config = {
                        params: request.params,
                        ...DEFAULT_CONFIG_REQUEST,
                        ...request.config
                    }
                    let response;
                    if (request?.body) {
                        response = await api.post(request?.url, request?.body, config);
                    } else {
                        response = await api.get(request?.url, config);
                    }
                    setDatas(response.data);
                } catch (error) {
                    BuildFieldErrorsDict(error, null, false);
                } finally {
                    setIsloading(false)
                }
            };
            fetchData();
        }
    }, [JSON.stringify(request)]);


    if (isLoading) return <LoadingFull/>

    return <>

        {datas?.length === 0 && <Empty/>}
        {datas?.length >= 1 &&
            <ChartLegendWrapper chart={Pie} config={config} expand={expand}/>}
    </>
}

