import {Card, Col, Row, Space, Typography} from "antd";
import TeamProfileSummary from "./TeamProfileSummary";
import TeamMemberTable from "./TeamMemberTable";
import WideSpace from "../../../../../../layout/WideSpace";
import {useTranslation} from "react-i18next";
import api from "../../../../../../../services/api";
import React, {useEffect, useState} from "react";
import Empty from "../../../../../../data_display/Empty";
import DrawerProfileUser
    from "../../../../../../../pages/profile/components/DrawerProfileUser/DrawerProfileUser";
import {valueOrEmpty} from "../../../../../../../utils/Utils";
import styled from "styled-components";
import UserAvatar from "../../../../../../misc/UserAvatar";
import RemixIcon from "../../../../../../data_display/RemixIcon";
import useSystemBreakpoint
    from "../../../../../../../hooks/useSystemBreakpoint";


const {Text, Link} = Typography;

const StyledTextData = styled(Text)`
    font-size: 12px;
    vertical-align: top;
`;

const StyledTextDescription = styled(Text)`
    font-size: 13px;
    line-height: 17px;
`;

const StyledTextTitle = styled(Text)`
    font-weight: 500;
`;
export default function TeamCard({solution, team}) {
    const {t} = useTranslation();
    const [members, setMembers] = useState(undefined);
    const [openDrawerProfile, setOpenDrawerProfile] = useState(false);
    const [memberId, setMemberId] = useState();
    const {isDesktop} = useSystemBreakpoint();
    const fetchMembers = async () => {
        try {
            const response = await api.get(`/teams/${team.id}/members_involved`);
            setMembers(response.data);
        } catch (exception) {
        }
    };

    useEffect(() => {
        void fetchMembers();
    }, [solution]);


    const handleOpenDrawer = (record) => {
        if (record) {
            setMemberId(record.user_id ?? record.id);
            setOpenDrawerProfile(true);
        }
    }

    return (
        <>
            <WideSpace direction="vertical">
                {team ? (
                    <>
                        <Card style={{borderRadius: 4}} bordered={false}>
                            <TeamProfileSummary team={team}
                                                handleOpenDrawer={() => handleOpenDrawer(members?.at(0))}/>
                        </Card>
                        {team.type !== "Individual" &&
                            <TeamMemberTable members={members}
                                             handleOpenDrawer={handleOpenDrawer}/>}
                    </>
                ) : (
                    <>
                        {solution && solution.author_name ? (
                            <Row>
                                {isDesktop &&
                                    <Col lg={3} style={{textAlign: "center"}}><UserAvatar
                                        data={{name: solution.author_name}} size={86}/></Col>}
                                <Col span={24} lg={21}>

                                    <WideSpace size={16} direction="vertical">
                                        <Space>
                                            {!isDesktop && <UserAvatar data={{name: solution.author_name}}/>}
                                            <div>
                                                <div>
                                                    <StyledTextTitle>{solution.author_name}</StyledTextTitle>

                                                </div>
                                                <div>
                                                    <StyledTextData
                                                        type="secondary">{solution.author_email}</StyledTextData>

                                                </div>


                                            </div>
                                        </Space>
                                        {solution.author_phone?.length > 8 &&
                                            <WideSpace direction="vertical" size="small">
                                                <StyledTextTitle>{t("General Data")}:</StyledTextTitle>
                                                <StyledTextData>{`${t("Phone number")}: ${valueOrEmpty(solution.author_phone)}`}</StyledTextData>

                                            </WideSpace>
                                        }
                                    </WideSpace>
                                </Col>
                            </Row>

                        ) : (<Empty/>)}
                    </>

                )}
            </WideSpace>
            <DrawerProfileUser
                id={memberId}
                open={openDrawerProfile}
                setOpen={setOpenDrawerProfile}
            />
        </>
    );
}
