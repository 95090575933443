import React, {useEffect, useRef, useState} from "react";
import "./styles.css";
import AllocationComponent from "../../../../../components/Phases/Allocation";
import {getProgram} from "../../../../../global/utils/_query";
import {useParams} from "react-router-dom";

import {useTranslation} from "react-i18next";
import {
    useLayoutContext
} from "../../../../../modules/project/components/layout/context/LayoutContext";
import ExtraHeader
    from "../../../../../modules/project/components/layout/ExtraHeader";
import {BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import api from "../../../../../global/services/api";

export default function PageAllocationEvaluators() {
    let {id, phase_id} = useParams();
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [phase, setPhase] = useState();
    const [program, setProgram] = useState({public: true});
    const {setIsConfidential, setNavbarMenuItems} = useLayoutContext();
    const elementAllocationComponents = useRef();


    const {initializeLayout,} = useLayoutContext();

    useEffect(() => {
        setNavbarMenuItems([])
        if (program && phase) {
            setIsConfidential(program["check_is_confidential"]);

            initializeLayout({
                extraHeaderContent: <ExtraHeader left={t("Evaluator Allocation")}
                    returnPath={`/programs/${program.id}/phases/${phase.id}/evaluators`}/>,
                showAvatar: false,
            });
        }
    }, [program, phase]);
    const getPhase = async () => {
        try {
            const response = await api.get(`phases/${phase_id}`);
            setPhase(response.data);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
    };


    useEffect(() => {
        getProgram(id, setIsLoading, setProgram);
        getPhase();
    }, []);

    return (
        <AllocationComponent ref={elementAllocationComponents} phase={phase}
                             program={program}/>
    );
}

