import {Row} from "antd";
import DonutGraph from "../components/DonutChart";
import GeoChartState from "../components/GeoChartState";
import BarChart from "../components/BarChart";
import RadarChart from "../components/RadarChart";
import {useTranslation} from "react-i18next";
import GeoChartCountry from "../components/GeoChartCountry";
import ColumnChart from "../components/ColumnChart";
import {useParams} from "react-router-dom/cjs/react-router-dom";
import React from "react";
import ChartCard
    from "../../../../../global/components/data_display/ChartCard";
import SolvCollapse from "../../../../../global/components/misc/SolvCollapse";
import GraphRow from "../components/GraphRow";
import GraphCol from "../components/GraphCol";


export default function SectionDashboardProgramApplicationData({filters}) {
    let {id} = useParams();
    const {t} = useTranslation();


    return (

        <SolvCollapse
            title={t('Application data')}
            bordered
            // active={false}
        >
            <GraphRow>
                <GraphCol>
                    <ChartCard title={t("Applications by state")}
                               expandible={true}>
                        <GeoChartState

                            request={{
                                url: `/dashboard/program_dashboard/${id}/applications_by_state`,
                                config: {
                                    version: 'v2'
                                },
                                body: filters,
                            }}
                        />
                    </ChartCard>
                </GraphCol>

                <GraphCol>
                    <ChartCard title={t("Applications by phases")}
                               expandible={true}>
                        <RadarChart
                            legend_key="phase"
                            groups={{
                                in_filling: {
                                    label: 'In Filling',
                                    color: '#B37FEB'
                                },
                                submitted: {
                                    label: 'Submited',
                                    color: '#096DD9'
                                },
                                given_up: {
                                    label: 'Canceled',
                                    color: '#FFD8BF'
                                },
                            }}
                            request={{
                                url: `/dashboard/program_dashboard/${id}/applications_by_phase`,
                                config: {
                                    version: 'v2'
                                }, body: filters,

                            }}/>
                    </ChartCard>
                </GraphCol>

                <GraphCol>
                    <ChartCard title={t("Stage assessments")}
                               expandible={true}>
                        <BarChart
                            legend_key="phase"
                            groups={{
                                waiting_evaluation: {
                                    label: 'waiting_evaluation',
                                    color: '#40A9FF'
                                },
                                evaluated: {
                                    label: 'evaluated',
                                    color: '#14C9C9'
                                },
                                invalid: {
                                    label: 'invalid',
                                    color: '#FFCBDB'
                                },
                                not_allocated: {
                                    label: 'not_allocated',
                                    color: '#F7BA1E'
                                },
                            }}
                            request={{
                                url: `/dashboard/program_dashboard/${id}/stage_assessments`,
                                config: {
                                    version: 'v2'
                                }, body: filters,

                            }}
                        />
                    </ChartCard>
                </GraphCol>

                <GraphCol>
                    <ChartCard title={t("Applications by country")}
                               expandible={true}>
                        <GeoChartCountry
                            request={{
                                url: `/dashboard/program_dashboard/${id}/applications_by_country`,
                                config: {
                                    version: 'v2'
                                }, body: filters,

                            }}/>
                    </ChartCard>
                </GraphCol>

                <GraphCol>
                    <ChartCard title={t("Registration by gender")}
                               expandible={true}>
                        <DonutGraph
                             request={{
                                url: `/dashboard/program_dashboard/${id}/registrations_by_gender`,
                                config: {
                                    version: 'v2'
                                }, body: filters,

                            }}
                            colorField="genre"
                            colorType={{
                                'male': '#165DFF',
                                'female': '#50CD89',
                                'not_declared': '#FFC700',
                                'others': '#0FC6C2'
                            }}/>
                    </ChartCard>
                </GraphCol>

                <GraphCol>
                    <ChartCard title={t("Applications by age group")}
                               expandible={true}>
                        <ColumnChart
                            request={{
                                url: `/dashboard/program_dashboard/${id}/registrations_by_age_group`,

                                config: {version: 'v2'},
                                body: filters,
                            }}
                            legend_key="group"/>
                    </ChartCard>
                </GraphCol>


            </GraphRow>
        </SolvCollapse>


    );
}