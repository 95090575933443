import { Card, Statistic as StatisticAnt } from "antd";
import { getScoreHeadlightColor } from "../../utils/Utils";
import styled from "styled-components";


const StyledCard = styled(Card)`
    /* filter: drop-shadow(2px 2px 4px #00000026); */
`

export default function Statistic({title, value = 0, useHeadlightColor = false, max=100, inverseScale=false}) {

    const valueStyle = useHeadlightColor ? { 
        color: getScoreHeadlightColor({ score: value, max, inverseScale }) 
    }: undefined;

    return (
        <StyledCard bodyStyle={{ padding: "16px 8px" }}>
            <StatisticAnt
                title={title}
                value={value}
                precision={2}
                valueStyle={valueStyle}
            />
        </StyledCard>
    );
}