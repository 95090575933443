import React, {createContext, useContext, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import ExtraHeader from "../../../modules/project/components/layout/ExtraHeader";
import {
    useLayoutContext
} from "../../../modules/project/components/layout/context/LayoutContext";
import {useTranslation} from "react-i18next";

const GamificationContext = createContext();

export const GamificationProvider = ({children}) => {
    const {initializeLayout} = useLayoutContext();
    const location = useLocation();
    const {t} = useTranslation();



    return <GamificationContext.Provider
        value={{}}>
        {children}
    </GamificationContext.Provider>
}

export const useGamificationContext = () => {
    return useContext(GamificationContext);
}

