import { BulbOutlined, LikeOutlined } from "@ant-design/icons";
import {Card, Col, Row, Space} from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import RemixIcon from "../../../../../components/data_display/RemixIcon";
import UserAvatar from "../../../../../components/misc/UserAvatar";
import LimitedSpace from "../../../../../components/responsive/LimitedSpace";
import TruncateText from "../../../../../components/responsive/TruncateText";
import PermissionTagsList from "../../../../../components/tag/PermissionTagsList";
import { usePermissionContext } from "../../../../../context/PermissionContext";
import { useThemeContext } from "../../../../../context/ThemeContext";
import useChallenge from "../../../../../hooks/useChallenge";
import useSystemBreakpoint from "../../../../../hooks/useSystemBreakpoint";
import ActionButton from "./components/ActionButton";
import UserInnovationStatistic from "./components/UserInnovationStatistic";
import React from "react";
import {ReactComponent as PendingClockIcon} from "../../../../../../assets/Icon/pending_clock.svg"
import GamificationPoints from "./components/GamificationPoints";
import {gold_6} from "../../../../../utils/Colors";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {useUserContext} from "../../../../../context/UserContext";
import useDefaultGetRequest from "../../../../../hooks/useDefaultGetRequest";

const StyledColumnContent = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow: hidden; 
`

const StyledCard = styled(Card)`
    border-radius: 16px;

    .ant-card-body {
        padding: 16px;
    }
`
const StyledContentAvatar = styled.div`
    display: flex;
    align-items: center;
`



export default function InnovationProfileCard({user, solutionsCount, approvedSolutionsCount}){
    const {t} = useTranslation();
    const {isDesktop} = useSystemBreakpoint();
     const screens = useBreakpoint();
    const {handleCreateSolutionAndRedirect, handleCreateChallenges} = useChallenge();
    const {themeConfig, } = useThemeContext();
    const {canSuggestChallenge, canSuggestSolution} = usePermissionContext();


     const {
        data: walletPoints,
    } = useDefaultGetRequest({
        url: '/wallet/get_wallet',
    })

    return(
        <StyledCard>
            <Row gutter={[16,16]}  align="middle">
                <Col span={!isDesktop ? 24 : 12} style={{display: "flex", gap: "12px"}} >
                    <StyledContentAvatar>
                        <UserAvatar size={!isDesktop? 33 : 66} data={user} />
                    </StyledContentAvatar>
                    <LimitedSpace flexWrap={false}>
                        {isDesktop ?
                            <>
                                <StyledColumnContent>
                                    <TruncateText style={{fontSize:16, fontWeight:500}}>{`${t("Hi")}, ${user.full_name}`}</TruncateText>
                                    <PermissionTagsList permissions={user.permissions} />
                                </StyledColumnContent>
                                <LimitedSpace className="keep-width">
                                    <UserInnovationStatistic icon={<BulbOutlined />} title={t("Suggested ideas")} value={solutionsCount} />
                                    <UserInnovationStatistic icon={<LikeOutlined />} title={t("Accepted ideas")} value={approvedSolutionsCount} />
                                    {screens.xxl && themeConfig?.client?.gamification_actived && <>
                                        <GamificationPoints color={'#6A78BE'} icon={<RemixIcon remixIconName={'ri-copper-coin-line'}/>} title={t("Points")} value={walletPoints?.wallet_points} />
                                        <GamificationPoints color={gold_6} icon={<PendingClockIcon/>} title={t("Pending")} value={walletPoints?.pending_points} />
                                    </>
                                    }
                                </LimitedSpace>
                            </>
                        :
                            <>
                                <TruncateText style={{fontSize:16, fontWeight:500}}>{`${t("Hi")}, ${user.full_name}`}</TruncateText>
                                <UserInnovationStatistic icon={<BulbOutlined />} title={isDesktop && t("Suggested ideas")} value={solutionsCount} />
                                <UserInnovationStatistic icon={<LikeOutlined />} title={ isDesktop && t("Accepted ideas")} value={approvedSolutionsCount} />
                            </>
                        }
                    </LimitedSpace>
                </Col>
                <Col span={!isDesktop ? 24 : 12} >
                    <Row style={{height:"100%"}} gutter={16}>
                        <Col span={12} flex={1}>
                            {canSuggestSolution && <ActionButton
                                icon={<BulbOutlined />}
                                onClick={handleCreateSolutionAndRedirect}
                                dark={true}
                                type="primary"
                                title={`${t("Suggest")} ${themeConfig?.client?.program_solution?.application_name_choice}`}
                                description={t("Solution to a current problem or opportunity for improvement")}
                                data-cy="suggest-solution-button"
                            />}
                        </Col>
                        <Col span={12}>
                             {canSuggestChallenge && <ActionButton
                                icon={<RemixIcon remixIconName={"ri-puzzle-line"} />}
                                // style={{backgroundColor: blue_1,  color: "#595959"}}
                                type="primary"
                                title={`${t("Suggest")} ${themeConfig?.client?.program_challenge?.application_name_choice}`}
                                onClick={handleCreateChallenges}
                                className="exclusive"
                                description={t("A problem or opportunity that, when solved, will generate a significant impact")}
                                data-cy="suggest-challenge-button"
                             />}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </StyledCard>
    )
}