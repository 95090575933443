import {Form, InputNumber, Popconfirm, Switch} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

import InformationCard
    from "../../../../../components/data_display/InformationCard";
import TransparentCollapse
    from "../../../../../components/misc/TransparentCollapse";
import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import CollapseFormItem
    from "../../../../../components/data_entry/CollapseFormItem";
import SwitchFormField
    from "../../../../../components/data_entry/form/SwitchFormField";


export default function SectionStore({FORM_NAMES, form}) {
    const {t} = useTranslation();

    const manualShoppingConfirmationValue = Form.useWatch(FORM_NAMES.MANUAL_SHOPPING_CONFIRMATION)

    return (

        <TransparentCollapse header={t("Rewards Store")}>
            <FlexContainer direction={'column'} gap={16} align={'streech'}>
                <CollapseFormItem

                    input={<Switch/>}
                    extraFormItemProps={{valuePropName: "checked"}}
                    name={FORM_NAMES.STORE_ACTIVATED}
                    help={t(
                        "Allows employees to access the store through the side menu and purchase items."
                    )}
                    label={t("View Store")}
                />
                <CollapseFormItem
                    inputFormField={<Popconfirm
                        title={t("If you disable manual confirmation, all pending purchases will be confirmed immediately.")}
                        onConfirm={() =>  {form.setFieldValue(FORM_NAMES.MANUAL_SHOPPING_CONFIRMATION, false)}}
                        onCancel={() =>  {form.setFieldValue(FORM_NAMES.MANUAL_SHOPPING_CONFIRMATION,true)}}
                        disabled={!manualShoppingConfirmationValue}
                        okText={t('Disable')}
                        cancelText={t('Keep activated')}
                    >
                        <SwitchFormField form={{name: FORM_NAMES.MANUAL_SHOPPING_CONFIRMATION}}/>
                    </Popconfirm>}

                    help={t(
                        "If disabled, all sales will be marked with the status finalized after purchase and points will be automatically discounted. If enabled, the manager must confirm the purchase manually."
                    )}
                    label={t("Manual confirmation of purchase by the manage")}
                />
            </FlexContainer>

        </TransparentCollapse>

    );
}

