import styled from "styled-components";
import { Card } from "antd";

const StyledCardWithOverlay = styled(Card)`
    width: 100%;
    height: ${(props) => props.height || '576px'};
    display: flex;
    flex-direction: column;
    color: #fff;
    border: none;
    border-radius: 16px;
    background-image: ${(props) => `url(${props.bgImage})`};
    background-size: cover;
    background-position: center;

    .ant-card-head {
        border: none;
        padding: 16px;
    }

    .ant-card-body {
        padding: 16px;
        gap: ${(props) => props.gap || '8px'};
        color: white;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: end;
    }

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.35);
        z-index: 1;
        border-radius: 16px;
    }
`;

export default StyledCardWithOverlay;
