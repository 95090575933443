import styled from "styled-components";

export const ConfirmContainer = styled.div`
  width: 500px;
  overflow: hidden;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Header = styled.div`
  background-color: #efefea;
  height: 40px;
  padding: 8px 8px 16px 8px;
  width: 100%;
  text-align: center;
  position: relative;
`
export const Body = styled.div`
  padding: 16px;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 4px;
  width: 100%;
  text-align: center;
`

export const Close = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: #4C4C4C;
  }

  :hover svg {
    fill: #efefea;
    transform: rotate(360deg);
  }

  :hover {
    background-color: #4C4C4C;

  }
`