import React, { useEffect, useState } from "react";

import {
    AppstoreOutlined, AuditOutlined,
    BulbOutlined,
    ExclamationCircleOutlined,
    WhatsAppOutlined
} from "@ant-design/icons";
import { Col, Row, Space, Typography } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import MetricsCard from "../../../../../components/data_display/MetricsCard";
import RemixIcon from "../../../../../components/data_display/RemixIcon";
import UserInfoCard from "../../../../../components/data_display/UserInfoCard";
import Loading from "../../../../../components/feedback/Loading";
import WideSpace from "../../../../../components/layout/WideSpace";
import UserAvatar from "../../../../../components/misc/UserAvatar";
import LinkRedirectConfirm from "../../../../../components/other/LinkRedirectConfirm";
import TruncateText from "../../../../../components/responsive/TruncateText";
import { usePermissionContext } from "../../../../../context/PermissionContext";
import { useUserContext } from "../../../../../context/UserContext";
import useSystemBreakpoint from "../../../../../hooks/useSystemBreakpoint";
import api from "../../../../../services/api";
import { cleanPhoneNumber } from "../../../../../utils/Utils";
import { useContextProfileUser } from "../context/ContextProfileUser";

const {Text} = Typography;


const StyledTextDescription = styled(Text)`
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
`;

const StyledTextTitle = styled(Text)`
    font-size: 16px;
    font-weight: 700;
`;

export default function TabDrawerProfileUser() {
    const {t} = useTranslation();
    const [metricsData, setMetricsData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const {isPhone, isDesktop} = useSystemBreakpoint();
    const {user_client,} = useUserContext();
    const {canSuggestChallenge, canSuggestSolution} = usePermissionContext();
    const {
        userData,
    } = useContextProfileUser();


    useEffect(() => {
        if (userData) {
            const fetchData = async () => {
                try {
                    setIsLoading(true);
                    const response = await api.get(`/user_info/${userData.id}/get_statistics`);
                    const data = response.data;
                    setMetricsData(data);
                } catch (error) {
                    console.error("Error:", error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchData();
        }
    }, [userData]);

    return (
        <>
            {
                isLoading ? <Loading/> : (
                    <WideSpace size={32} direction="vertical">
                        <Space size={isDesktop ? 16 : 8} align="start">
                            {<UserAvatar data={userData}
                                         size={isDesktop ? 100 : 50}/>}
                            <div>
                                <Space direction="vertical" size="small">
                                    <StyledTextTitle>{userData.get_full_name}</StyledTextTitle>
                                    <div>
                                        {userData.biography ? (
                                            <StyledTextDescription>
                                                <TruncateText
                                                    lines={5}>{userData.biography}</TruncateText>
                                            </StyledTextDescription>
                                        ) : (
                                            <StyledTextDescription>{t("This user did not write a bio")}</StyledTextDescription>
                                        )}
                                    </div>
                                </Space>
                            </div>
                        </Space>

                        <Row gutter={[16]} wrap={false}>
                            {userData.full_address &&
                                <Col flex={1} style={{width: 0}}>
                                    <UserInfoCard
                                        value={userData.full_address}
                                        icon={<RemixIcon
                                            remixIconName={"ri-map-pin-user-fill"}/>}
                                    />
                                </Col>
                            }
                            {userData.linkedin &&
                                <Col flex={1} style={{width: 0}}>
                                    <UserInfoCard
                                        // value={<a href={userData.linkedin} target={"_blank"}>{userData.name}</a>}
                                        value={<LinkRedirectConfirm
                                            link={userData.linkedin}
                                            text={userData.name}
                                            style={{fontSize: 12}}/>}
                                        icon={<RemixIcon
                                            remixIconName={"ri-linkedin-box-fill"}
                                            style={{color: "#1D39C4"}}/>}
                                    />
                                </Col>
                            }
                            {userData.mobile_phone_number &&
                                <Col flex={1} style={{width: 0}}>
                                    <UserInfoCard
                                        value={
                                            <LinkRedirectConfirm
                                                link={`https://wa.me/${cleanPhoneNumber(userData.mobile_phone_number)}`}
                                                text={`${(userData.mobile_phone_number)}`}
                                                style={{fontSize: 12}}
                                            />}
                                        icon={<WhatsAppOutlined
                                            style={{color: "#3F6600"}}/>}
                                    />
                                </Col>
                            }
                        </Row>

                        {!!user_client ? (
                            <Row gutter={[32, 32]}>
                                {canSuggestSolution && (
                                    <Col span={isPhone ? 24 : 12}>
                                        <MetricsCard
                                            value={metricsData.solutions_count}
                                            name={t("Suggested ideas")}
                                            icon={<BulbOutlined/>}
                                        />
                                    </Col>
                                )}
                                {canSuggestChallenge && (
                                    <Col span={isPhone ? 24 : 12}>
                                        <MetricsCard
                                            value={metricsData.challenges_count}
                                            name={t("Suggested Challenges")}
                                            icon={<RemixIcon remixIconName={"ri-puzzle-line"} />}
                                        />
                                    </Col>
                                )}
                                <Col span={isPhone ? 24 : 12}>
                                    <MetricsCard
                                        value={metricsData.programs_count}
                                        name={t("Programs")}
                                        icon={<AppstoreOutlined/>}
                                    />
                                </Col>
                                <Col span={isPhone ? 24 : 12}>
                                    <MetricsCard
                                        value={metricsData.assessments_count}
                                        name={t("Assessments made")}
                                        icon={<AuditOutlined/>}
                                    />
                                </Col>
                            </Row>
                        ) : (
                            <Text type="secondary">
                                <Space>
                                    <ExclamationCircleOutlined/>
                                    <span>{t("To see more information, access an environment.")}</span>
                                </Space>
                            </Text>
                        )}
                    </WideSpace>
                )
            }
        </>
    );
}
