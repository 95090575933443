import styled from 'styled-components'
import {
    ReactComponent as CollapseIcon
} from "../../assets/Icon/colapso_icon.svg"
import {ReactComponent as FilterIcon} from "../../assets/Icon/filter.svg"

import {CSVLink} from "react-csv";

export const CSVLinkHidden = styled(CSVLink)`
  width: 0px;
  height: 0px;
  visibility: hidden;
`

export const TitleTableStyle = styled.span`
  font-size: 14px;
`

export const SubTitleTable = styled.span`
  font-size: 14px;
  font-weight: bold;
  padding-left: 4px;

`

export const CollapseTable = styled.div`
  width: 100%;
  overflow: hidden;
  ${({topSpace}) => {
    if (topSpace) {
      return `height: calc(100vh - ${topSpace}px);`
    }
  }}
  display: flex;
  flex-direction: column;
  ${({hasCollapse}) => {
    if (hasCollapse) {
      return `padding-left: 18px;
              background: white;
              padding-right: 18px;
              box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.05);`
    }
  }}

`


export const SearchTable = styled.input
    `
      width: 264px;
      background: white;
      border: 1px solid #D9D9D9;
      padding: 4px 8px;
      outline: none;
    `


export const CollapseIconStyled = styled(CollapseIcon)

    `
      cursor: pointer;
      ${({isOpen}) => {
        if (isOpen) {
          return `


           transform: rotate(90deg);     
        

`
        }
      }}
    `
export const FilterIconStyled = styled(FilterIcon)`
  cursor: pointer;
  ${({isApplied}) => {
    if (isApplied) {
      return `
        & path{
           fill:#0d6efd !important; 
        }  
           
      `
    }
  }}

`
export const HeaderCollapseTable = styled.div`
  // padding-top: 10px;
  // padding-bottom: 5px;
  display: flex;

  & > :nth-child(2) {
    margin-left: 10px;
  }

`
export const HeaderBottom = styled.div`
  padding-bottom: 0;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  .first-child {
    flex-grow: 1;
  }
  
  .last-child{
    width: 100%;
  }
`


export const ButtonsContainerTable = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

`

export const TitleContainer = styled.div`
  //padding-left: 10px;
  padding-right: 40px;

`

export const StyledTable = styled.table`
  width: 100%;
  overflow-x: auto;

`


export const MainContainer = styled.div`
  position: relative;
  //padding-bottom: 20px;
  overflow-y: scroll;
  flex: 1;  
  display: flex;
  flex-direction: column;

`
export const ContainerTable = styled.div`
  overflow-x: auto;
  overflow-y: scroll;
  ${({topSpace})  => {
    if(topSpace){
      return ' flex: 1;'
    }else{
       return ' height: 50vh;'
    }
  }}
 
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ::-webkit-scrollbar {
    max-height: 8px;
  }

`

export const ActionsButtonsContainer = styled.div`
  background: white;
  height: 36px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

`

export const ActionButton = styled.button`
  height: 30px;
  min-width: 30px;
  border: none;
  background: white;
  border-bottom: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;

  :hover {
    background: #F0F0F0;
  }

  svg path {
    fill: black;
    fill-opacity: 0.5;
  }
`


export const CenterTableContainer = styled.tbody`
  //todo ver solução para nao dar warning no log
  width: 100%;
  display: flex;
  margin-top: 100px;
  justify-content: center;
  align-items: center;
  position: absolute;

  & > * {
    height: min-content;
  }
`


export const TableSubHeader = styled.span`
  color: #898989;
  display: inline-block;
  font-size: 8px;
  font-weight: normal;
  transform: translateY(-50%);
`
export const TableHeader = styled.p`
  margin: 0;
  font-size: 10px;
  color: #adb3bd;
  text-transform: uppercase;
  transform: translateY(-50%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`


export const StyledTrHeader = styled.tr`
  background: #FAFAFA;
  font-weight: 500;
  font-size: 14px;
  min-width: 100%;
  text-align: left;
  position: sticky;
  z-index: 1;
  top: 0;
`


export const StyledTrBody = styled.tr`


  :first-child td {
    box-shadow: none;
  }

  :last-child {
    padding-right: 120px;

  }

  ${props => {
    if (props.hasRowClick) {
      return `
      :hover{
          padding-right: 0px;
            background: rgba(0,0,0,0.03);
            cursor:pointer;
      
      }
            
        `
    }
  }}
`


export const StyledTh = styled.th`
  padding: 0px 30px 0px 16px;
  position: relative;
  background: white;
  white-space: nowrap;
  cursor: ${({hasActionClick}) => hasActionClick ? 'pointer': 'default'} !important;
  
  svg {
    visibility: visible;
  }

  :hover {
    background: rgba(232, 233, 236, 0.22);
    
  }

  //:hover svg {
  //  visibility: visible;
  //}

  ${props => {
    let values;
    if (props.fixed) {
      values = `
            position: sticky;
             backdrop-filter: blur(10px);
             z-index:99;
        `
    }

    if (props.hasChecked) {
      values += 'left:36px;'
    } else {
      values += 'left: 0;'
    }

    if (props.hasAction) {
      values += 'right: 0;'
    }

    return values
  }}
  ${props => {
    if (props.isChecked) {
      return `
            padding-right: 0px;
           
        `
    }
  }}
  :last-child {
    padding-right: 120px;

    span {
      right: 100px;
    }
  }
`


export const StyledTd = styled.td`
  padding: 6px 16px 6px 16px;
  max-width: 550px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 0 2px rgba(0, 0, 0, 0.06) inset;
  ${props => {
    let values;
    if (props.fixed) {
      values = `
            position: sticky;
            // backdrop-filter: blur(10px);
            background-color: rgba(255, 255, 255, 0.9);
        `
    }

    if (props.hasChecked) {
      values += 'left: 36px;'
    } else {
      values += 'left: 0;'
    }
    if (props.hasAction) {
      values += 'right: 0;'
    }


    return values
  }}


  ${props => {
    if (props.isChecked) {
      return `
            padding-right: 0px;
            background:white;
        `
    }
  }}



`
export const IconOrderHeader = styled.span`
  position: absolute;
  width: 9px;
  height: min-content;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;

`


export const PaginationContainer = styled.div`
  position: sticky;
  left: 0;
  bottom: 0;
  padding: 0px 16px;
  background: white;
  display: flex;
  z-index: 1;
  justify-content: space-between;
  //box-shadow: 0 -2px rgba(0, 0, 0, 0.06);
`


export const LabelPagination = styled.span`
  color: #898989;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  padding-top: 6px;
`
export const ButtonPagination = styled.button`
  background: inherit;
  border: none;
  color: #898989;
  font-size: 16px;
  line-height: 18px;
  padding: 6px 16px 0 16px;

  :hover {
    color: black;
    font-weight: bold;
  }

  ${props => {
    if (props.disabled) {
      return `
              visibility: hidden;
        `
    }
  }}

`


export const ImagesContainer = styled.div`
  //display: flex;
  position: relative;
  overflow: visible;
  width: calc(var(--childSize) * 27px);

  & > * {

    position: absolute;
    top: 0;
    left: calc(var(--indexImage) * 27px);
    z-index: calc(var(--childSize) - var(--indexImage));
    transform: translateY(-50%);
    cursor: pointer;
    box-shadow: 0 0 0 1.5px white;


    :hover {
      z-index: 97;
      box-shadow: 0 0 0 1.5px var(--active_menu_color);

    }
  }
`