import React from "react";
import DefaultComponent from "./DefaultComponent";
import CheckBox from "../../../Form/CheckBox";


function CheckboxForm(props) {

    return (
        <DefaultComponent {...props}>
            <CheckBox optionsDirection={'column'} id={props.id} options={props.choices && props.choices.map((choice) => ({
                                      label: choice.value,
                                      value: choice.value,
                                      id: choice.value
                                  }))}/>
        </DefaultComponent>
    )
}

export default CheckboxForm