import React, {useEffect, useRef, useState} from 'react';
import SendMessageChat from "./SendMessageChat";
import BodyMessageChat from "./BodyMessageChat";
import styled from 'styled-components';
import {FlexContainer} from "../../authentication/StyledComponents";
import api from "../../../services/api";
import {BuildFieldErrorsDict} from "../../../utils/Utils";

const Container = styled(FlexContainer)`
    max-height: 100%;
    min-height: 100%;

    & > *:nth-child(2) {
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 8px;
    }

`

export default function Chat({topic}) {

    const [isLoading, setIsLoading] = useState(false)
    const [search, setSearch] = useState('')
    const [textChat, setTextChat] = useState('')
    const [comments, setComments] = useState([])
    const [commentParent, setCommentParent] = useState()
    const initialUrl = (`/comments/topic/${topic?.id}?page=1&page_size=5`)
    const [nextUrl, setNextUrl] = useState(initialUrl)

    const hasChangedSearch = useRef(true);

    async function fetchMessages(search = '') {
        try {
            setIsLoading(true)

            let url = nextUrl
            let keepComments = [...comments];

            if (hasChangedSearch.current) {
                url = initialUrl;
                keepComments = [];
                hasChangedSearch.current = false;
            }

            if (search) {
                url = `${url}&search=${search}`
            }

            const response = await api.get(url);
            setComments(undefined)
            setComments([...keepComments, ...response.data.results])
            setNextUrl(response.data.next)

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        } finally {
            setIsLoading(false);
        }
    };

    function resetFields() {
        setTextChat('')
        setComments([])
    }

    async function handleSendMessage() {
        try {
            setSearch('')

            setIsLoading(true)

            const response = await api.post(`/comments`, {
                topic: topic.id,
                comment: textChat,
                parent: commentParent
            });

            setTextChat('')
            hasChangedSearch.current = true

            void fetchMessages()

            dispatchEvent(new CustomEvent(`update-message-post-count-${topic?.object?.id}`))

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        } finally {
            setIsLoading(false);
        }
    }

    const handleFetchMessages = () => {
        if (topic && !isLoading && nextUrl) {
            // setComments([])
            void fetchMessages()
        }
    }

    // useEffect(() => {
    //     if (topic) {
    //         setComments([])
    //         void fetchMessages()
    //     }
    // }, [topic])

    function handleSearchChange(value) {
        hasChangedSearch.current = true;
        setSearch(value)
    }

    function handleTextChatChange(value) {
        setTextChat(value)
    }

    useEffect(() => {
        return () => {
            resetFields()
        }
    }, [])

    return (
        <Container direction={'column'}
                   align={'stretch'}>
            <BodyMessageChat search={{
                onChange: handleSearchChange,
                value: search,
                onSearch: (value) => fetchMessages(value)
            }}
                             comments={comments} isLoading={isLoading} topic={topic}
                             handleFetchMessages={handleFetchMessages} nextUrl={nextUrl}
            />
            <SendMessageChat isLoading={isLoading}
                             onChange={handleTextChatChange}
                             value={textChat}
                             onSendMessage={handleSendMessage}/>
        </Container>

    );
}