import React, {useState} from 'react';
import {Card, Modal, Typography} from "antd";
import Flex from "../../../responsive/Flex";
import UserAvatar from "../../../misc/UserAvatar";
import {CloseCircleOutlined, DeleteOutlined} from "@ant-design/icons";
import {DateFormatByUserTimezone} from "../../../../utils/dates";
import {useUserContext} from "../../../../context/UserContext";
import WideSpace from "../../../layout/WideSpace";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {danger, neutral_6} from "../../../../utils/Colors";
import {BuildFieldErrorsDict} from "../../../../utils/Utils";
import api from "../../../../services/api";
import {ToastNotification} from "../../../feedback/ToastNotification";
import RemixIcon from "../../../data_display/RemixIcon";
import useSystemBreakpoint from "../../../../hooks/useSystemBreakpoint";
import TruncateText from "../../../responsive/TruncateText";

const {Text} = Typography
const {confirm} = Modal;

const StyledDeleteText = styled(Text)`
    color: ${({$disabled}) => $disabled ? neutral_6 : danger};
    cursor: pointer;
    pointer-events: ${({$disabled}) => $disabled && "none"};
`

export default function NoteCard({note, fetchData}) {

    const {_user} = useUserContext()
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const {isPhone} = useSystemBreakpoint()

    const loggedUserIsOwner = note?.owner?.id === _user?.id

    const handleDelete = async () => {
        try {
            setLoading(true)
            void await api.delete(`/user_notes/${note?.id}`)
            void await fetchData()
            ToastNotification(t("Note susscessfuly deleted!"))
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setLoading(false)
        }
    }

    const confirmDelete = () => {
        confirm({
            title: t("Do you want to delete notes?"),
            content: t("By deleting the comment, it will not be possible to recover it."),
            icon: <CloseCircleOutlined style={{color: "red"}}/>,
            okText: t("Yes"),
            okType: "danger",
            cancelText: t("No"),
            okButtonProps:{'data-cy': 'confirm-delete-note-button'},
            onOk: () => {
                void handleDelete();
            },
        });
    }

    return (
        <Card>
            <WideSpace direction="vertical">
                <Flex justify="space-between" align="middle">
                    <Flex>
                        <UserAvatar data={note?.owner} size={"small"}/>
                            <TruncateText>
                                <Text>{note?.owner?.full_name}</Text>
                            </TruncateText>
                    </Flex>
                    <Flex align="center" fullHeight={true}>
                        <Flex gap={2}>
                            <RemixIcon remixIconName={"ri-calendar-check-line"}/>
                            <Text style={{whiteSpace:"nowrap"}}> {DateFormatByUserTimezone(note?.created_at, _user)}</Text>
                        </Flex>
                        {loggedUserIsOwner &&
                            <StyledDeleteText title={t("Delete")} $disabled={loading}
                                              onClick={confirmDelete} data-cy={`delete-note-button-${note?.id}`}
                            >
                                <DeleteOutlined/>
                            </StyledDeleteText>
                        }
                    </Flex>
                </Flex>
                <Text>{note.comment}</Text>
            </WideSpace>
        </Card>
    );
}
