import {useTranslation} from "react-i18next";
import Drawer from "../../../../../../layout/Drawer";
import styled from "styled-components";
import {Badge, Card, Form as FormAnt, Modal, Space, Typography} from "antd";
import WideSpace from "../../../../../../layout/WideSpace";
import SolvTag from "../../../../../../tag/SolvTag";
import {ClockCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {gold_6, sunset_orange_6} from "../../../../../../../utils/Colors";
import {CHOICES_REQUEST_STATUS} from "../../../../../../../utils/Choices";
import RequestForm from "./RequestForm";
import RequestAnswer from "./RequestAnswer";
import Flex from "../../../../../../responsive/Flex";
import Button from "../../../../../../data_entry/Button";
import {useApplicationDrawerContext} from "../../../context/ApplicationDrawerContext";
import {usePermissionContext} from "../../../../../../../context/PermissionContext";
import React, {useEffect, useState} from "react";
import api from "../../../../../../../services/api";
import {ToastNotification} from "../../../../../../feedback/ToastNotification";
import {BuildFieldErrorsDict} from "../../../../../../../utils/Utils";
import {useUserContext} from "../../../../../../../context/UserContext";

const {Text} = Typography

const StyledCard = styled(Card)`
    gap: 16px;
    border-radius: 10px;
`

const StyledBadge = styled.div`
    gap: 8px;
    display: flex;
    position: unset;
    transform: unset;
    background: ${({$background}) => $background || "red"};
    font-weight: 700;
`

const showForm = (request, solution) => request?.status === "waiting" && (solution?.logged_user_is_member === true || solution === undefined)
const showDelete = (request, solution, user) => request?.status === "waiting" && (solution?.logged_user_is_member === false) && (request?.owner === user?.id)
const showAcceptRefuse = (request, solution, user) =>  request?.status === "answered" && (solution?.logged_user_is_member === false) && (request?.owner === user?.id)

const {confirm} = Modal

function RequestDrawerBody({request, form, attachmentForm, setOpen, fetchRequests, solution}) {
    if (showForm(request, solution)) {
        return (
            <RequestForm
                request={request}
                form={form}
                attachmentForm={attachmentForm}
                setOpen={setOpen}
                fetchRequests={fetchRequests}
            />
        )
    }
    return (
        <RequestAnswer request={request} solution={solution} />
    )
}


export function RequestResponseDrawer({open, setOpen, request, fetchRequests}){
    
    const {t} = useTranslation();
    const [form] = FormAnt.useForm();
    const [attachmentForm] = FormAnt.useForm();

    const handleOnClose = () => {
        form.resetFields()
        attachmentForm.resetFields()
    }

    return (<Drawer
            open={open}
            setOpen={setOpen}
            title={t("Request V2")}
            footer={

                <Flex justify="end">
                      {showForm(request) &&<Button onClick={form.submit} data-cy="submit-request-answer-button">{t("Respond")}</Button>}
                </Flex>
            }
            onClose={handleOnClose}
        >
            <WideSpace direction="vertical" size="middle">
                <StyledCard>
                    <WideSpace direction="vertical">
                        <Flex justify="space-between">
                            <Space direction="vertical" size={4}>
                                <Text strong>{t("Request title")}</Text>
                                <Text>{request?.title}</Text>
                            </Space>
                            <Space direction="vertical" size={4}>
                                <Text strong>{t("Deadline V2")}</Text>
                                {request?.status === "waiting" && request?.deadline > 0 ?
                                    <Flex gap={4}>
                                        <ClockCircleOutlined style={{color: sunset_orange_6}} />
                                        <Badge  color={sunset_orange_6} count={request?.deadline_display} />
                                    </Flex>
                                    :
                                    <SolvTag
                                        color={CHOICES_REQUEST_STATUS[request?.status]?.color}>
                                        {t(CHOICES_REQUEST_STATUS[request?.status]?.label)}
                                    </SolvTag>
                                }
                            </Space>
                        </Flex>

                        <WideSpace direction="vertical" size={4}>
                            <Text strong>{t("Request V2")}</Text>
                            <Text>{request?.description}</Text>
                        </WideSpace>

                    </WideSpace>
                </StyledCard>

                <RequestDrawerBody
                    request={request}
                    form={form}
                    attachmentForm={attachmentForm}
                    setOpen={setOpen}
                    fetchRequests={fetchRequests}
                />
            </WideSpace>
        </Drawer>
    );   
}


export default function RequestDrawer({open, setOpen, request, fetchRequests}) {

    const {t} = useTranslation();
    const [loading, setLoading] = useState(false)
    const [action, setAction] = useState("")
    const [form] = FormAnt.useForm();
    const [attachmentForm] = FormAnt.useForm();
    const {solution, hasEvaluatorRequestPermission} = useApplicationDrawerContext();
    const {hasProgramAdminOrManagerPermission} = usePermissionContext();
    const {_user} = useUserContext();

    const handleOnClose = () => {
        form.resetFields()
        attachmentForm.resetFields()
    }

    const handleDeleteRequest = async () => {
        try {
            setAction("delete")
            setLoading(true)
            await api.delete(`/diligences/${request.id}`)
            ToastNotification(t("Request successfully deleted!"))
            setOpen(false)
            await fetchRequests()
        } catch (error){
            BuildFieldErrorsDict(error, null, true)
        } finally {
            setLoading(false)
            setAction("")
        }
    }

    const confirmDelete = () => {
        confirm({
            title: t("The Application Proponent has already been notified regarding the request. Are you sure you want to delete?"),
            icon: <CloseCircleOutlined style={{color: "red"}}/>,
            okText: t("Yes"),
            okType: "danger",
            cancelText: t("No"),
            onOk: () => {void handleDeleteRequest();},
        });
    }

    const ACTION_ACCEPT = "accept"
    const ACTION_REJECT = "reject"

    const ACTION_MESSAGES = {
        [ACTION_ACCEPT]: {
            title:t("Approve request"),
            content: t("You will not be able to undo this action. Do you want to approve this request?"),
        },
        [ACTION_REJECT]: {
            title:t("Reject request"),
            content: t("You will not be able to undo this action. Do you want to reject this request?")
        }
    }

    const handleAction = async (action) => {
        try {
            setAction(action)
            setLoading(true)
            await api.post(`/diligences/${request.id}/${action}`)
            ToastNotification(t(`Request successfully ${action}ed!`))
            setOpen(false)
            await fetchRequests()
        } catch (error){
            BuildFieldErrorsDict(error, null, true)
        } finally {
            setLoading(false)
            setAction("")
        }
    }

    const confirmAction = (action) => {
        confirm({
            title: ACTION_MESSAGES[action]["title"],
            content: ACTION_MESSAGES[action]["content"],
            icon: <ExclamationCircleOutlined style={{color: gold_6}}/>,
            okText: t(action),
            okType: action === ACTION_REJECT ? "danger" : "default",
            okButtonProps: {'data-cy': "confirm-action-button"},
            cancelText: t("Cancel"),
            onOk: () => {void handleAction(action);},
        });
    }

    return (
        <Drawer
            open={open}
            setOpen={setOpen}
            title={t("Request V2")}
            footer={
                <Flex justify="end">
                    {showForm(request, solution) &&
                        <Button onClick={form.submit} data-cy="submit-request-answer-button">{t("Respond")}</Button>
                    }

                    {(hasProgramAdminOrManagerPermission || hasEvaluatorRequestPermission)
                        && showDelete(request, solution, _user) &&
                        <Button danger onClick={() => {confirmDelete()}} loading={loading && action==="delete"}>
                            {t("Delete")}
                        </Button>
                    }

                    {(hasProgramAdminOrManagerPermission || hasEvaluatorRequestPermission)
                        && showAcceptRefuse(request, solution, _user) &&
                        <Flex justify="end">
                            <Button danger data-cy={"reject-request-button"}
                                    onClick={() => {confirmAction(ACTION_REJECT)}} loading={loading && action===ACTION_REJECT}>
                                {t("Mark as rejected")}
                            </Button>
                            <Button onClick={() => {confirmAction(ACTION_ACCEPT)}}
                                    loading={loading && action===ACTION_ACCEPT} data-cy={"accept-request-button"}>
                                {t("Mark as accepted")}
                            </Button>
                        </Flex>
                    }

                </Flex>
            }
            onClose={handleOnClose}
        >
            <WideSpace direction="vertical" size="middle">
                <StyledCard>
                    <WideSpace direction="vertical">
                        <Flex justify="space-between">
                            <Space direction="vertical" size={4}>
                                <Text strong>{t("Request title")}</Text>
                                <Text>{request?.title}</Text>
                            </Space>
                            <Space direction="vertical" size={4}>
                                <Text strong>{t("Deadline V2")}</Text>
                                {request?.status === "waiting" && request?.deadline > 0 ?
                                    <Flex gap={4}>
                                        <ClockCircleOutlined style={{color: sunset_orange_6}} />
                                        <Badge  color={sunset_orange_6} count={request?.deadline_display} />
                                    </Flex>
                                    :
                                    <SolvTag
                                        color={CHOICES_REQUEST_STATUS[request?.status]?.color}>
                                        {t(CHOICES_REQUEST_STATUS[request?.status]?.label)}
                                    </SolvTag>
                                }
                            </Space>
                        </Flex>

                        <WideSpace direction="vertical" size={4}>
                            <Text strong>{t("Request V2")}</Text>
                            <Text>{request?.description}</Text>
                        </WideSpace>

                    </WideSpace>
                </StyledCard>

                <RequestDrawerBody
                    request={request}
                    form={form}
                    attachmentForm={attachmentForm}
                    setOpen={setOpen}
                    fetchRequests={fetchRequests}
                    solution={solution}
                />
            </WideSpace>
        </Drawer>
    );
}