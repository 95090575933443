export const MAX_LIMIT_GAMIFICATION_POINTS = 999999


export const PHASE = {
    INFORMATION: 3,
    EVALUATION: 2,
    APPLICATION: 1
}

export const ASSESSMENT = {
    NOT_ANSWERED: 1,
    ANSWERED: 2,
    SENDED: 3,
    INVALID: 4,
}

export const RANKING_STATUS = {
    OPENED: 'opened',
    SCHEDULED: 'scheduled',
    IN_VALIDATION: 'in_validation',
    CLOSED: 'closed',
}

export const CAMPAIGN_STATUS = {
    SCHEDULED: 'scheduled',
    IN_PROGRESS: 'in_progress',
    FINISHED_ASSESSMENT: 'finished',
}


export const EVALUATION_FORMATS = {
    weighted: 'Weighted Average',
    unweighted: 'Unweighted Average',
}

export const ACCEPT_IMAGE_FORMATS = 'image/jpg, image/png, image/jpeg'
export const BLOCK_KEY = 'block'
export const FREE_PLAN = 1
export const ACCEPT_DOCUMENT_FORMATS = 'application/pdf, application/zip, application/x-zip-compressed'
export const ACCEPT_SHEETS_FORMAT = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
export const ACCEPT_ALL_FORMATS = `${ACCEPT_IMAGE_FORMATS}, ${ACCEPT_DOCUMENT_FORMATS}`
export const ACCEPT_REQUEST_FORMATS = `${ACCEPT_IMAGE_FORMATS}, ${ACCEPT_SHEETS_FORMAT}, application/pdf`

export function generateGamificationsActionsMessage(type_action, description) {

    if (type_action === 'manually_action') {
        return description
    }

    const ACTIONS_MESSAGE = {
        'submit_application': 'Application submitted to a program',
        'classified_application': 'Application approved at one stage',
        'aproved_application': 'Application approved in the program',
        'finished_assessment': 'Application evaluation',
        'purchase_in_store': 'Purchase made in the store',
        // 'manually_action': 'Points added manually',
    }

    return ACTIONS_MESSAGE[type_action]
}


export const AWAITING_VALIDATION_PURCHASE = 'awaiting_validation'
export const SUCCESSFUL_PURCHASE = 'successful'
export const REVOKED_PURCHASE = 'revoked'


export const SUBMIT_APPLICATION_GAMIFICATION = 'submit_application'
export const CLASSIFIED_APPLICATION_GAMIFICATION = 'classified_application'
export const APROVED_APPLICATION_GAMIFICATION = 'aproved_application'
export const FINISHED_ASSESSMENTS_GAMIFICATION = 'finished_assessment'

