import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Modal from "react-bootstrap/Modal";
import TextArea from "./Form/TextArea";
import api from "../global/services/api";

import {BuildFieldErrorsDict} from "../global/utils/Utils";
import ButtonDefault from "./Buttons/ButtonDefault";
import {ToastNotification} from "../global/components/feedback/ToastNotification";

export default function AbolishModal(props) {
    const {t} = useTranslation();
    const [data, setData] = useState("")

    const getListIDsAssociated = (allocations) => {
        let result = []
        allocations.forEach((allocation) => (
            result.push(allocation.id)
        ))
        return result
    }

    const handleSubmit = async () => {
        try {
            let applications_json = {
                'evaluator': props.active.id,
                'applications': JSON.stringify(getListIDsAssociated([props.data])),
                'phase': props.phase.id,
                'justification': data
            }

            let evaluators_json = {
                'evaluators': JSON.stringify(getListIDsAssociated([props.active])),
                'application': props.data.id,
                'phase': props.phase.id,
                'justification': data
            }

            const response = await api.post(`/evaluator_application_phase/invalidate_evaluation_by_${props.evaluatorAbolish ? "evaluators" : "applications"}`, props.evaluatorAbolish ? evaluators_json : applications_json);
            ToastNotification(t('Successfully'))
            if (props.evaluatorAbolish) {
                props.getAllocation(props.data.id)
            } else {
                props.getAllocation(props.active.id)
            }
            props.refreshCounts(response.data.evaluator_count_applications)
            props.onHide()
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        }
    };

    return (
        <>
            <Modal
                {...props}
                size="md"
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{t("Invalidation modal")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>{t("Confirm the invalidation of the evaluation? Justify below:")}</h6>
                    <TextArea required onChange={(e) => setData(e.target.value)}/>
                </Modal.Body>
                <Modal.Footer>
                    <ButtonDefault color="tertiary" onClick={() => props.onHide()}
                                   label={t("Close")}/>
                    <ButtonDefault disabled={data.length === 0} color="secondary" onClick={() => handleSubmit()}
                                   label={t("Invalidate")}/>
                </Modal.Footer>
            </Modal>
        </>
    );
};