import React, {useState} from 'react';
import Drawer from '../../../../../../layout/Drawer';
import {useTranslation} from 'react-i18next';
import {Form as FormAntd, Row} from "antd";
import Button from '../../../../../../data_entry/Button';
import InputFormField from '../../../../../../data_entry/form/InputFormField';
import TextAreaFormField from '../../../../../../data_entry/form/TextAreaFormField';
import SelectFormField from "../../../../../../data_entry/form/SelectFormField";
import {BuildFieldErrorsDict} from "../../../../../../../utils/Utils";
import api from "../../../../../../../services/api";
import {useRules} from "../../../../../../../hooks/useRules";
import useOptionsTranslation from "../../../../../../../hooks/useOptionsTranslation";
import {CHOICES_REQUEST_TYPE} from "../../../../../../../utils/Choices";
import {ToastNotification} from "../../../../../../feedback/ToastNotification";
import InputNumberFormField from "../../../../../../data_entry/form/InputNumberFormField";


export default function RequestFormDrawer({open, setOpen, solution, fetchRequests, setLoading, loading}) {
    const {t} = useTranslation();
    const [form] = FormAntd.useForm();

    const {IS_REQUIRED} = useRules();
    const {translationOptions} = useOptionsTranslation();

    const handleSubmit = async (data) => {
        try {
            setLoading(true);
            // data.deadline = data.deadline || 0
            void await api.post(`/diligences?application=${solution?.id}`, data);
            void fetchRequests()
            setOpen(false)
            form.resetFields()
            ToastNotification(t("Request successfully created!"))
            dispatchEvent(new Event("fetch-assessment-data"));
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Drawer
            open={open}
            setOpen={setOpen}
            title={t("Request a requisition")}
            footer={[
                <Row justify="end">
                    <Button type="primary" onClick={form.submit} data-cy="submit-request-create-button" loading={loading}>
                        {t("Request a requisition")}
                    </Button>
                </Row>
            ]}
            isGrayBody={true}
        >
            <FormAntd form={form} layout="vertical" onFinish={handleSubmit}>
                <InputFormField
                    form={{
                        name: "title",
                        label: t("Request title"),
                        rules: [IS_REQUIRED],
                    }}
                    data-cy="request-title-input"
                />
                <SelectFormField
                    form={{
                        help: t("The attachment type requires the author to send at least one attachment. The clarification type only requires a text response."),
                        label: t("Type of request"),
                        name: "type_dilig",
                        initialValue:"file"
                    }}
                    options={
                        translationOptions(CHOICES_REQUEST_TYPE)
                    }
                    data-cy="request-type-select"
                />
                <InputNumberFormField
                    // type="number"
                    min={0}
                    form={{
                        help: t("Enter the response time in hours. After this period, if it has not been responded to, the request will be closed with an ‘unanswered’ status. Leave 0 to not set a deadline."),
                        label: t("Deadline for response (in hours)"),
                        name: "deadline",
                        initialValue:48,
                        rules: [IS_REQUIRED],
                    }}
                    data-cy="request-deadline-input"
                />
                <TextAreaFormField
                    form={{
                        name: "description",
                        label: t("Context"),
                        rules: [IS_REQUIRED],
                    }}
                    data-cy="request-description-input"
                />
            </FormAntd>
        </Drawer>
    );
}