import { Typography } from "antd";
import ExtraHeader from "../../../components/layout/ExtraHeader";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

function ProjectCreateExtraHeader() {
    const { t } = useTranslation()
    return <ExtraHeader
        left={<Text>{t("New Project")}</Text>}
        returnPath="/project-portfolio/list"
    />;
}

export default ProjectCreateExtraHeader;