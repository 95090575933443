import {createContext, useContext, useEffect, useState} from "react";
import UserContext, {useUserContext} from "./UserContext";
import {useThemeContext} from "./ThemeContext";
import {HOST_ENTREPRENEUR} from "../../settings";


const PermissionContext = createContext({});

export function PermissionContextProvider({children}) {
    const [isLoading, setIsLoading] = useState(true);
    // Program Management permissions
    const {_user, user_client} = useUserContext();
    const {themeConfig} = useThemeContext();

    const [isLoadingPermissions, setIsLoadingPermissions] = useState(true);
    const [hasProgramViewerPermission, setHasProgramViewerPermission] = useState(false);
    const [hasProgramEvaluatorPermission, setHasProgramEvaluatorPermission] = useState(false);
    const [hasProgramManagerPermission, setHasProgramManagerPermission] = useState(false);
    const [hasProgramAdminPermission, setHasProgramAdminPermission] = useState(false);
    const [hasProgramAdminOrManagerPermission, setHasProgramAdminOrManagerPermission] = useState(false);
    const [hasSameDomainChallenge, setHasSameDomainChallenge] = useState();
    const [hasSameDomainSolution, setHasSameDomainSolution] = useState();
    const [hasGamificationAdminPermission, setHasGamificationAdminPermission] = useState(false);
    const [hasColaboratorPermission, setHasColaboratorPermission] = useState();

    // Project Management Permissions
    const [hasProjectViewerPermission, setHasProjectViewerPermission] = useState(false);
    const [hasProjectManagementPermission, setHasProjectManagementPermission] = useState(false);
    const [hasProjectAdminPermission, setHasProjectAdminPermission] = useState(false);
    const [hasProjectAdminOrManagerPermission, setHasProjectAdminOrManagerPermission] = useState(false);
    const [hasFullAdminPermission, setHasFullAdminPermission] = useState(false);

    const canSuggestSolution = (
        themeConfig?.client?.program_solution?.pk &&
        hasSameDomainSolution
    );
    const hasProjectPortfolioMenuAccessPermission = (
        themeConfig?.client_manager?.is_project_manager_module && user_client && (
            hasProjectAdminOrManagerPermission || hasProjectViewerPermission
        )
    );
    
    function checkAmbientHasDebit() {
        return themeConfig?.client?.has_debit
    }

    //  function hasAdminPermission() {
    //     return (hasProgramAdminPermission || hasProjectAdminPermission)
    // }

    const canSuggestChallenge = (
        themeConfig?.client?.program_challenge?.pk &&
        hasSameDomainChallenge
    );

    const programHasChallengeMappingPermission = (program) => {
        return program?.type === "challenge_mapping"
    }

    const checkProjectPermissions = (projectPermissions, ...permissions) => {
        return hasProjectAdminPermission || permissions.some((permission)=>projectPermissions?.includes(permission));
    }

    useEffect(() => {
        if (_user) {
            const {permissions} = _user;
            setIsLoadingPermissions(true)
            setHasGamificationAdminPermission(permissions.is_program_admin || permissions.is_gamification_manager);

            setHasProgramViewerPermission(permissions.is_program_viewer);
            setHasProgramEvaluatorPermission(permissions.is_program_evaluator);
            setHasProgramManagerPermission(permissions.is_program_manager);
            setHasProgramAdminPermission(permissions.is_program_admin);
            setHasProgramAdminOrManagerPermission(permissions.is_program_admin_or_manager)
            setHasSameDomainChallenge(permissions.is_same_domain_challenge)
            setHasSameDomainSolution(permissions.is_same_domain_solution)
            setHasColaboratorPermission(permissions.is_colaborator)

            setHasProjectViewerPermission(permissions.is_project_viewer);
            setHasProjectManagementPermission(permissions.is_project_manager);
            setHasProjectAdminPermission(permissions.is_project_admin);
            setHasProjectAdminOrManagerPermission(permissions.is_project_admin_or_manager);
            setHasFullAdminPermission(permissions.is_program_admin || permissions.is_project_admin);
            setIsLoadingPermissions(false)

            setIsLoading(false);
        }else{
             setIsLoadingPermissions(false)
        }
    }, [_user]);

    // }

    return <PermissionContext.Provider
        value={{
            hasProgramViewerPermission,
            hasProgramEvaluatorPermission,
            hasProgramManagerPermission,
            hasProgramAdminPermission,
            hasProgramAdminOrManagerPermission,
            hasSameDomainSolution,
            hasGamificationAdminPermission,
            hasProjectPortfolioMenuAccessPermission,
            hasProjectViewerPermission,
            hasProjectManagementPermission,
            hasProjectAdminPermission,
            hasProjectAdminOrManagerPermission,
            hasSameDomainChallenge,
            canSuggestSolution,
            canSuggestChallenge,
            hasColaboratorPermission,
            checkProjectPermissions,
            checkAmbientHasDebit, isLoadingPermissions,
            hasFullAdminPermission,
            isLoading,
            programHasChallengeMappingPermission
        }}
    >
        {children}
    </PermissionContext.Provider>;
}

export function usePermissionContext() {
    return useContext(PermissionContext);
}
