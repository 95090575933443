import {CompactPicker} from "react-color";
import styled from "styled-components";
import { useState} from "react";
import {COLORS} from "../../../../global/utils/Colors";


const StyledContainer = styled.div`
  div {
    box-shadow: none !important;
  }
`;





function InputColorPicker({value, onChange, id, colors = COLORS, form, submit, ...props}) {

    const [color, setColor] = useState(value);
    const handleChange = (color, _) => {
        setColor(color);
        if (form){
            form.setFieldsValue({[id]: color?.hex || '#000000'});
            if (submit){
                form.submit();
            }
        }
    };


    return <>
        <StyledContainer>
            <CompactPicker
                colors={colors}
                color={color}
                onChange={handleChange}
            />
        </StyledContainer>
    </>;
}

export default InputColorPicker;