import styled from 'styled-components';
import React from "react";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import { page_background } from '../../utils/Colors';

const BreakLayoutContent = styled.div`
    height: min-content;
    background-color: ${page_background};
    margin: ${({isDesktop, vertical, horizontal}) => {
        const padding = isDesktop ? -24 : -8
        const padding_vertical = vertical ? padding : 0
        const padding_horizontal = horizontal ? padding : 0

        return `${padding_vertical}px ${padding_horizontal}px 0px`
    }};
`
export default function BreakLayoutSystemContent({vertical=true, horizontal=true,children, ...props}) {

    const {isDesktop} = useSystemBreakpoint();

    return (

        <BreakLayoutContent vertical={vertical} horizontal={horizontal} isDesktop={isDesktop} {...props}>
            {children}

        </BreakLayoutContent>

    );
}
