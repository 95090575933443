import {Column, Bar} from "@ant-design/plots";
import React, {useEffect, useState} from "react";
import api from "../../../../../../global/services/api";
import {useTranslation} from "react-i18next";
import {
    BuildFieldErrorsDict,
    getMaxValueFromArray
} from "../../../../../../global/utils/Utils";
import {formatNumber} from "../../../../utils/utils";
import ChartLegendWrapper
    from "../../../../../../global/components/data_display/ChartWrapper/ChartLegendWrapper";
import Loading from "../../../../../../global/components/feedback/Loading";
import ChartTotalDisplay
    from "../../../../../../global/components/data_display/ChartTotalDisplay";
import {truncateChars} from "../../utils/chartUtils";
import useProjectChart from "../../hooks/useProjectChart";
import {Empty} from "antd";

function getFieldName(indicator) {
    if (["rating", "percent"].includes(indicator.view)) {
        return "scaled_value";
    }
    return "stored_value";
}

const xyConfig = {
    grid: true,
    gridLineWidth: 1,
}

const xField = "project_name"
const colorField = "project_name"
const colorSourceKey = "project_color"

export function ClientIndicatorBarChart({
                                            request,
                                            clientIndicator,
                                            expand
                                        }) {
    const [clientIndicatorData, setClientIndicatorData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const {handleChartClickProjectRedirect} = useProjectChart();
    const isPercent = clientIndicator?.view === "percent"
    const {t} = useTranslation();


    const xField = "project_name"
    const yField = getFieldName(clientIndicator)


    const axis = {
        x: {
            tickLength: 0,
        },
        y: {
            ...xyConfig, tickLength: 0,
            labelFormatter: (d) => `${d}%`
        }
    }

    let config = {
        data: clientIndicatorData,
        xField,
        yField,
        colorField: xField,
        autoFit: true,
        animate: null,
        axis: {
            y: {...axis.y},
            x: {
                ...axis.x,
                labelFormatter: (text) => truncateChars(text, 5),
                labelAlign: "horizontal",
                tickLength: 0,
            },
        },
        style: {
            fill: (d) => d[yField] === 0 ? "rgba(0,0,0,0)" : d[colorSourceKey],
            cursor: "pointer",
        },
        tooltip: {
            title: false,
            items: [(d) => ({
                name: t(d[xField]),
                value: isPercent ? `${d[yField]}%` : d[yField],
                color: d[colorSourceKey] || "#000",
            })],
        },
        scale: {
            y: {
                domain: [0, 100]
            }
        },
        markBackground: {
            style: {
                fill: '#eee'
            }
        },
        _legend: {
            colorField,
            colorSourceKey,
            rows: 1,
            cols: 3,
        },
        drawerConfig: {
            axis: {
                x: {
                    ...axis.x,
                    labelFormatter: (text) => truncateChars(text, 20),
                }
            },
            _legend: {
                rows: 2,
            }
        },
        onReady: ({chart}) => {
            chart.on('interval:click', (event) => {
                handleChartClickProjectRedirect({event});
            });
        }
    };

    if (clientIndicator?.scale_max) {
        config = {
            ...config, xAxis: {
                grid: {line: {style: {lineDash: [4, 2]}}},
                max: clientIndicator?.scale_max && clientIndicator?.scale_max,
            },
        }
    }

    useEffect(() => {
        if (clientIndicator?.id && request?.url) {
            const fetchData = async () => {
                try {
                    setIsLoading(true)
                    const config = {
                        params: {
                            ...request.params,
                            indicator: clientIndicator?.id
                        },
                        ...request.config
                    }
                    let response;
                    if (request?.body) {
                        response = await api.post(request.url, request?.body, config)
                    } else {
                        response = await api.get(request.url, config)
                    }
                    setClientIndicatorData(response.data.data);
                } catch (exception) {
                } finally {
                    setIsLoading(false)
                }
            };
            void fetchData();
        }
    }, [clientIndicator?.id, JSON.stringify(request)]);

    if (isLoading) return <Loading/>
    return (
        clientIndicatorData?.length > 0 ?
            <ChartLegendWrapper chart={Bar} config={config} expand={expand}/> :
            <Empty
                description={t("There isn't enough data to build this graphic.")}/>
    );

};


export function ClientIndicatorColumnChart({
                                               request,
                                               clientIndicator,
                                               expand
                                           }) {
    const [clientIndicatorData, setClientIndicatorData] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const {t} = useTranslation();
    const {handleChartClickProjectRedirect} = useProjectChart();


    const colorSourceKey = "project_color"
    const yField = getFieldName(clientIndicator)
    const isCurrency = clientIndicator?.view === "currency"

    const axis = {
        x: {
            tickLength: 0,
        },
        y: {
            grid: true,
            gridLineWidth: 1,
            tickLength: 0,
            labelFormatter: isCurrency ? (d) => `R$ ${formatNumber(d)}` : (d) => d,
        }
    }

    let config = {
        data: clientIndicatorData,
        xField,
        yField,
        colorField,
        animate: null,
        autoFit: true,
        axis: {
            ...axis,
            x: {
                labelFormatter: (text) => truncateChars(text, 5),
                labelAlign: "horizontal",
                tickLength: 0,
            },
        },
        style: {
            fill: (d) => d[colorSourceKey],
            cursor: "pointer",
        },
        scale: {
            y: {
                domain: [0, getMaxValueFromArray(clientIndicatorData, yField, true, 10)]
            }
        },
        markBackground: {
            style: {
                fill: "transparent",
            }
        },
        tooltip: {
            title: false,
            items: [(d) => ({
                name: t(d[xField]),
                value: isCurrency ? `R$ ${formatNumber(d[yField])}` : d[yField],
                color: d[colorSourceKey] || "#000",
            })],
        },
        _legend: {
            colorField,
            colorSourceKey,
            rows: 1,
            cols: 3,
        },
        drawerConfig: {
            axis: {
                ...axis,
                x: {
                    ...axis.x,
                    labelFormatter: (text) => truncateChars(text, 20),
                    // labelAlign: "horizontal",
                    labelAutoRotate: true
                },
            },
            _legend: {
                rows: 2,
            }
        },
        onReady: ({chart}) => {
            chart.on('interval:click', (event) => {
                handleChartClickProjectRedirect({event});
            });
        }
    };

    useEffect(() => {
        if (clientIndicator?.id && request?.url) {
            const fetchData = async () => {
                try {
                    setIsLoading(true)
                    const config = {
                        params: {
                            ...request.params,
                            indicator: clientIndicator?.id
                        },
                        ...request.config
                    }
                    let response;
                    if (request?.body) {
                        response = await api.post(request.url, request?.body, config)
                    } else {
                        response = await api.get(request.url, config)
                    }
                    let {data: indicators, total} = response.data
                    setClientIndicatorData(indicators);
                    setTotal(total);
                } catch (exception) {
                    BuildFieldErrorsDict(error, null, false);
                } finally {
                    setIsLoading(false)
                }
            };
            void fetchData();
        }
    }, [clientIndicator?.id, JSON.stringify(request)]);


    if (isLoading) return <Loading/>
    return (
        clientIndicatorData?.length > 0 ? <ChartLegendWrapper
            chart={Column}
            config={config}
            expand={expand}
            extra={isCurrency &&
                <ChartTotalDisplay description="Total" total={total}/>
            }
        /> : <Empty
            description={t("There isn't enough data to build this graphic.")}/>
    )
};
