import React, {useEffect, useState} from 'react';
import './style.css'
import api from "../../../../global/services/api";
import Card from "../../../../components/Phases/Card";
import {useParams} from "react-router-dom";

import {confirmAlert} from "react-confirm-alert";
import {getProgram} from "../../../../global/utils/_query";
import * as ConstantsMessage from "../../../../global/utils/fileWithMessage";
import {BuildFieldErrorsDict} from "../../../../global/utils/Utils";
import {useTranslation} from "react-i18next";
import {ToastNotification} from "../../../../global/components/feedback/ToastNotification";

function Phases() {
    const [phases, setPhases] = useState([]);
    const [program, setProgram] = useState({public: true})
    const {t} = useTranslation();
    let {id} = useParams();

    const handleDeletePhase = async (program_id, phase_id) => {
        try {
            const response = await api.delete(`/phases/${phase_id}`);
            if (response.status === 204) {
                getPhases()
                ToastNotification(t(ConstantsMessage.SUCCESS))
            }
        } catch (error) {
            BuildFieldErrorsDict(error, null, t('Error deleting phase'));
        }
    };


    const submit = (program_id, phase_id) => {
        confirmAlert({
            title: t('Delete Phase'),
            message: t('Are you sure you want to delete your phase?'),
            buttons: [
                {
                    label: t('No'),
                    onClick: () => ''
                },
                {
                    label: t('Yes'),
                    onClick: () => handleDeletePhase(program_id, phase_id)
                }
            ]
        });
    };


    const getPhases = async () => {
        try {
            const response = await api.get(`programs/${id}/phases`);
            setPhases(response.data)

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        }
    };

    useEffect(() => {
        getPhases()
        getProgram(id, null, setProgram)
    }, []);

    return (
        <>
           {phases.map((phase) => <Card key={'card-phase' + phase.id}
                                        onDelete={submit}
                                        phase={phase}/>)}
       </>
    );
}

export default Phases;