import { AppstoreOutlined, BarsOutlined, CalendarOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { DateFormatByUserTimezone } from "../../../../utils/dates";
import UserAvatar from "../../../misc/UserAvatar";
import LimitedSpace from "../../../responsive/LimitedSpace";
import TruncateText from "../../../responsive/TruncateText";
import DotInfoCard from "../../DotInfoCard";


export default function ApplicationInfo({application}) {
    const {t} = useTranslation()
    return (
        <LimitedSpace>
            <UserAvatar data={application?.team} size={16}/>
            <TruncateText>{application.team?.name}</TruncateText>
            {application?.date_submited &&
                <DotInfoCard icon={<CalendarOutlined/>} label={t("Submited")}
                             info={DateFormatByUserTimezone(application.date_submited)}/>
            }
            <LimitedSpace style={{width: "100%"}} flexWrap={false}>
                <DotInfoCard icon={<AppstoreOutlined style={{verticalAlign: "baseline"}}/>} label={t("Program")} info={application.program.name}/>
                <DotInfoCard icon={<BarsOutlined/>} label={t("Phase")} info={application?.current_phase?.title}/>
            </LimitedSpace>
        </LimitedSpace>
    );
}

