import {Link} from "react-router-dom";
import useEnvironment from "../../hooks/useEnvironment";
import styled from "styled-components";

const StyledLink = styled(Link)`
 color: ${({secondary}) => secondary ? "#00000073" : ""};
`

export default function EnvironmentLink({children, to, clientUrl, style, secondary}) {
    const {handleEnvironmentRedirect} = useEnvironment();

    const handleRedirect = (e) => {
        e.preventDefault();
        handleEnvironmentRedirect(clientUrl, to);
    }

    return <StyledLink  secondary={secondary} style={style} onClick={handleRedirect}>{children}</StyledLink>
}