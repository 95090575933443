import {Column} from "@ant-design/plots";
import {useEffect, useState} from "react";
import api from "../../../../../global/services/api";
import {
    BuildFieldErrorsDict,
    getMaxValueFromArray
} from "../../../../../global/utils/Utils";
import LoadingFull from "../../../../../global/components/other/LoadingFull";
import Empty from "../../../../../global/components/data_display/Empty";
import {useTranslation} from "react-i18next";
import {
    truncateChars
} from "../../../../../modules/project/pages/ProjectHome/utils/chartUtils";
import ChartLegendWrapper
    from "../../../../../global/components/data_display/ChartWrapper/ChartLegendWrapper";


const DEFAULT_CONFIG_REQUEST = {
    version: 'v1'
}
export default function StackedColumnChart({
                                               externalData,
                                               onColumnClick,
                                               request, legend_key,
                                               groups,
                                               expand
                                           }) {

    const {t} = useTranslation();
    const [isLoading, setIsloading] = useState(false)
    const [data, setData] = useState();
    const colorField = "label"
    const yField = "value"


    const axis = {
        x: {
            tickLength: 0,
        },
        y: {
            tickCount: 10,
            tickFilter: Number.isInteger,
            tickLength: 0,
        }
    }

    // const data =

    const config = {
        data: data,
        xField: legend_key,
        yField,
        colorField,
        autoFit: true,
        label: null,
        animate: null,
        style: {
            fill: (item) => {
                return item.color
            },
        },
        stack: true,
        axis: {
            ...axis,
            x: {
                labelFormatter: (text) => truncateChars(text, 5),
                labelAlign: "horizontal",
                tickLength: 0,
            },
        },
        tooltip: {
            // só ta funcionando com o código repetido ;)
            items: [
                (item) => ({
                    name: t(item.label),
                    value: item[yField],
                    color: item.color
                }),
                (item) => ({
                    name: t(item.label),
                    value: item[yField],
                    color: item.color
                }),
            ]
        },
        scale: {
            y: {
                domain: [0, getMaxValueFromArray(data, yField, true)]
            }
        },
        markBackground: {
            style: {
                fill: "transparent"
            }
        },
        _legend: {
            colorField,
            rows: 1,
            cols: 3,
            labelFormatter: (item) => {

                return {
                    text: t(item.text),
                    color: item.color
                }
            }
        },
        drawerConfig: {
            axis: {
                x: {
                    ...axis.x,
                    labelFormatter: (text) => truncateChars(text, 20),
                    // labelAlign: "horizontal",
                    labelAutoRotate: true
                },
                y: {...axis.y},
            },
        },
        onReady: ({chart}) => {
            chart.on('interval:click', (event) => {
                onColumnClick?.(event)
            });
        }
    };

    function formatData(data) {
        return data.reduce((acc, item) => {
            const values = Object.keys(groups).map(
                (key_group) => ({
                    [legend_key]: item[legend_key],
                    label: groups[key_group].label,
                    value: item[key_group],
                    color: groups[key_group].color,
                    object: item
                })
            )
            acc.push(...values);
            return acc;
        }, []);
    }


    useEffect(() => {
        if (!externalData && request?.url) {
            const fetchData = async () => {
                try {
                    setIsloading(true)
                    const config = {
                        params: request.params,
                        ...DEFAULT_CONFIG_REQUEST,
                        ...request.config
                    }
                    let response;
                    if (request?.body) {
                        response = await api.post(request?.url, request?.body, config);
                    } else {
                        response = await api.get(request?.url, config);
                    }
                    const data = formatData(response.data)
                    setData(data);
                } catch (error) {
                    BuildFieldErrorsDict(error, null, false);
                } finally {
                    setIsloading(false)
                }
            };
            void fetchData();
        } else if (externalData) {
            setData(formatData(externalData))
        }
    }, [JSON.stringify(request)]);


    if (isLoading) return <LoadingFull/>

    return (
        <>
            {data?.length === 0 && <Empty/>}
            {data?.length >= 1 &&
                <ChartLegendWrapper chart={Column} config={config}
                                    expand={expand}/>}
        </>
    );
}
