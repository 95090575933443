import {useTranslation} from "react-i18next";
import SolvTag from "../../../../global/components/tag/SolvTag";
import Table from "../../../../global/components/data_display/Table";


export function renderStatus(status) {
    return <SolvTag color={status.is_active && "#f6ffed"}>{status.label}</SolvTag>;
}

function ClientIndicatorTable({dataSource, onRowClick}) {
    const {t} = useTranslation();

    const columns = [
        {
            title: t("Name"),
            dataIndex: "name",
            width: "420px",
            render: (value, record) => {
                return (
                    <span data-cy={`indicator-row-${record.id}`}>{value}</span>
                )
            }
        },
        {
            title: t("Indicator Type"),
            dataIndex: "type",
        },
        {
            title: t("Details"),
            dataIndex: "details",
        },
        {
            title: t("Status"),
            dataIndex: "status",
            render: renderStatus,
        },
    ];

    return <Table columns={columns} dataSource={dataSource} onRowClick={onRowClick}
                  data-cy="client-indicators-table"/>;
}

export default ClientIndicatorTable;