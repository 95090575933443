import { Button, Card, Form } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import BreakLayoutSystemContent from "../../../../global/components/layout/BreakLayoutSystemContent";
import FixedFooter from "../../../../global/components/layout/FixedFooter";
import WideSpace from "../../../../global/components/layout/WideSpace";
import Flex from "../../../../global/components/responsive/Flex";
import useSystemBreakpoint from "../../../../global/hooks/useSystemBreakpoint";
import { ProjectDescriptionForm } from "../../components/ProjectDescriptionForm";
import ProjectForm from "../../components/ProjectForm";
import { useProjectRequest } from "../../hooks/useProjectRequest";
import ProjectIndicatorList from "./components/IndicatorList";
import MemberList from "./components/MemberList";
import { useProjectManagementContext } from "./context/ProjectManagementContext";
import HelpCard from "../../../../global/components/other/HelpCard";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom";


function ProjectManagementSettingsPage() {
    const {project, setProject} = useProjectManagementContext();
    const {t} = useTranslation();
    const {isDesktop} = useSystemBreakpoint();
    const {
        form, isLoading, handleSubmitLoading,
        handleDeleteProject, handleSave, cleanFormValues
    } = useProjectRequest(project, setProject);
    let { id } = useParams();



    useEffect(() => {
        if (project) {
            const dict = {...project}
            delete dict['avatar']
            form.setFieldsValue({...dict})
        }
    }, [project, form])

    return (
        <>
            <BreakLayoutSystemContent style={{padding: isDesktop ? 24 : 8, marginBottom: 80}}>
                <WideSpace direction="vertical" size="large">
                    <Form form={form} initialValues={cleanFormValues(project)} layout="vertical" autoComplete="off"
                        onFinish={handleSubmitLoading}>
                        <WideSpace direction="vertical" size="large">
                            <ProjectForm project={project} form={form}/>
                            <ProjectDescriptionForm project={project} form={form}/>
                        </WideSpace>
                    </Form>
                    {/*<ProjectIndicatorList/>*/}
                    <HelpCard
                        title={t("Initial Indicators")}
                        description={t("Project indicators are now updated in the planning tab. Click on the button on the side to configure the initial planning or click on the tab above.")}
                        icon={<InfoCircleOutlined/>}
                    >
                        <Link to={`/project-portfolio/project/${id}/timeline`}>
                            <Button type="primary">
                                {t("Go to planning")}
                            </Button>
                        </Link>
                    </HelpCard>
                    <MemberList/>
                </WideSpace>
            </BreakLayoutSystemContent>
            <FixedFooter>
                <Card>
                    <Flex justify="end">
                        <Button danger onClick={handleDeleteProject} data-cy="delete-project-button">
                            {t("Delete")}
                        </Button>
                        <Button type="primary" onClick={handleSave} loading={isLoading} data-cy="save-project-settings-button">{t("Save")}</Button>
                    </Flex>
                </Card>
            </FixedFooter>
        </>
    );
}

export default ProjectManagementSettingsPage;
