import TruncateText from "../../../../../../components/responsive/TruncateText";
import styled from "styled-components";

const StyledUserInnovationStatisticContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8px;
    
    * {
        line-height: 22px;
    }
`

const StyledIcon = styled.div`
    color: #00000073;
    font-size: 24px;
`

const StyledValue = styled.div`
  font-size: 20px;
  font-weight: 500;
`

const StyledTitle = styled(TruncateText)`
  font-size: 14px;
  color: #00000073;
`

export default function UserInnovationStatistic({icon, title, value}) {
    return <StyledUserInnovationStatisticContainer>
        <StyledIcon>{icon}</StyledIcon>
        <StyledValue>{value}</StyledValue>
        <StyledTitle>{title}</StyledTitle>
    </StyledUserInnovationStatisticContainer>
}
