import {Flex, Typography} from 'antd';
import copy from 'copy-to-clipboard';
import {useTranslation} from 'react-i18next';
import {ToastNotification} from '../feedback/ToastNotification';
import InfoCard from "../data_display/InfoCard";
import React from 'react';
import {CopyOutlined} from '@ant-design/icons';
import TruncateText from '../responsive/TruncateText';
import {Link} from "react-router-dom";

const {Text} = Typography;

const InfoCardItem = ({icon, title, text, copyable, isLink, onClick, iconColor, ...props}) => {
    const {t} = useTranslation();

    const iconStyle = {
        fontSize: 14,
        color: iconColor || "#00000073",
    };

    const titleStyle = {
        fontSize: 8,
        fontWeight: 700,
    };

    const textStyle = {
        fontSize: 12,
        fontWeight: 700,
        color: '#595959',
    };

    const handleCopy = () => {
        copy(text);
        ToastNotification(t('Copied'));
    };

    const handleClick = (e) => {
        e.preventDefault();
        if (copyable) {
            handleCopy();
        }
        if (onClick) {
            onClick();
        }
    };

    return (
        <a
            href="javascript:void(0);"
            onClick={handleClick}
            {...props}
        >
            <InfoCard style={{ borderRadius: 10 }} borderColor padding={[4,8]}>
                <Flex gap={4}>
                    {icon && React.cloneElement(icon, { style: iconStyle })}
                    <Flex vertical={true} style={{lineHeight: "normal"}}>
                        <Text type="secondary" style={titleStyle}>{title}</Text>
                        <Flex gap={4}>
                            <TruncateText>
                                {
                                    isLink ?
                                        <Link href={text} target={'_blank'}>
                                            {text}
                                        </Link> :
                                        <Text style={textStyle}>{text}</Text>
                                }
                            </TruncateText>
                            {copyable && <CopyOutlined onClick={handleCopy} style={{ cursor: 'pointer' }} />}
                        </Flex>
                    </Flex>
                </Flex>
            </InfoCard>
        </a>
    );
};

export default InfoCardItem;