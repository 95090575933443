import React from 'react'

import {Empty, Modal} from "antd";
import {useTranslation} from "react-i18next";
import Form from "../../../../../../global/components/data_entry/form/Form";
import ApplicationFormContentEditable
    from "../../../../../../global/pages/application/ApplicationFormContentEditable";


export default function ModalPhaseFormPreview({
                                                  open, phaseForm,
                                                  setOpen
                                              }) {
    const {t} = useTranslation();
    const handleCloseModalPreview = () => setOpen(false);

    return (
        <Modal open={open} setOpen={setOpen}
               width={700}
               footer={null}
               onCancel={handleCloseModalPreview} title={t("Form preview")}>


            {phaseForm?.form?.length > 0 ? (

                    <Form>
                        <ApplicationFormContentEditable
                            // form={form}
                            phaseForm={phaseForm}
                            // application={application}
                        />
                    </Form>

            ) : <Empty/>}


        </Modal>
    );
}