import TruncateText
    from "../../../../../../components/responsive/TruncateText";
import styled from "styled-components";

import LimitNumber
    from "../../../../../../components/data_display/LimitNumber";

const StyledUserInnovationStatisticContainer = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 8px;
    color: ${({color}) => color};
    font-size: 22px;

    * {
        line-height: 22px;
    }

    svg, i {
        width: 22px;
        height: 22px;
    }

    i {
        color: ${({color}) => color};
    }
`


const StyledTitle = styled(TruncateText)`
    font-size: 20px;
    font-weight: 500;

`
const LIMIT_POINTS = 1000000
export default function GamificationPoints({color, icon, title, value}) {


    return <StyledUserInnovationStatisticContainer color={color}>
        {icon}
        <StyledTitle>

            <LimitNumber value={value}/> {title}
        </StyledTitle>
    </StyledUserInnovationStatisticContainer>
}
