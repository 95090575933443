import React, {createContext, useContext, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import ExtraHeader from "../../../modules/project/components/layout/ExtraHeader";
import {
    useLayoutContext
} from "../../../modules/project/components/layout/context/LayoutContext";
import {useTranslation} from "react-i18next";

const RewardsStoreContext = createContext();

export const RewardsStoreProvider = ({children}) => {
    const {initializeLayout} = useLayoutContext();
    const location = useLocation();
    const {t} = useTranslation();



    return <RewardsStoreContext.Provider
        value={{}}>
        {children}
    </RewardsStoreContext.Provider>
}

export const useRewardsStoreContext = () => {
    return useContext(RewardsStoreContext);
}

