import styled from 'styled-components';

const StyledDiv = styled.div`
    display: flex;
    gap: ${props=>props.$gap ? `${props.$gap}px`: "unset"};
    align-items: ${props=>props.$align ?? "unset"};
    justify-content: ${props=>props.$justify ?? "unset"};
    flex-direction: ${props=>props.$vertical ? 'column': 'row'};
    flex-grow: ${props=>props.$grow || 'unset'};
    flex-shrink: ${props=>props.$shrink || 'unset'};
    height: ${props=>props.$height};
`

export default function Flex({children, gap=8, align, justify, vertical = false, grow=false, shrink=false, fullHeight=false, style, ...props }) {
    return (
        <StyledDiv 
            $gap={gap} 
            $align={align} 
            $justify={justify} 
            $vertical={vertical} 
            $grow={!!grow ? 1 : 0} 
            $shrink={!!shrink ? 1: 0}
            $height={fullHeight && "100%"}
            style={style}
            {...props}
        >
            {children}
        </StyledDiv>
    );
}