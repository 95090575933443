import {ClockCircleOutlined} from "@ant-design/icons";
import {Button, Card, Row, Skeleton, Tag, Typography, Grid} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import EnvironmentLink from "../../../../components/link/EnvironmentLink";
import TruncateText from "../../../../components/responsive/TruncateText";
import api from "../../../../services/api";
import {BuildFieldErrorsDict} from "../../../../utils/Utils";
import ChallengeCard from "./ChallengeCard";
import {useUserContext} from "../../../../context/UserContext";
import CarouselPro from "../../../../components/misc/CarouselPro";
import LimitedSpace from "../../../../components/responsive/LimitedSpace";
import useApplication from "../../../../hooks/useApplication";
import { DatetimeFormatByTimezone } from "../../../../utils/dates";
import DangerousHtmlPreviewer from "../../../../components/data_display/DangerousHtmlPreviewer";

const {Title, Text} = Typography;
const {useBreakpoint} = Grid;

const StyledCardProgram = styled(Card)`
    position: relative;
    overflow: hidden;
    border-radius: 16px;
    pointer-events: ${(props) => (props.isOpen ? "auto" : "none")};
    padding: 8px;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: ${({backgroundImage}) => 
            backgroundImage 
                ? `url(${backgroundImage})` 
                : "linear-gradient(to top, #ffffff 0%, #7D96B7D1 100%)"};
        background-size: cover;
        background-position: center;
        background-color: #00000003;
        background-blend-mode: darken;
        filter: ${(props) => (props.isOpen ? "brightness(50%)" : "grayscale(100%)")};
        z-index: 0;
    }

    .ant-card-body {
        position: relative;
        background: none;
        border-radius: 16px;
        color: #fff;
        display: flex;
        flex-direction: column;
        padding: 8px;
    }

    .ant-card-extra {
        color: #fff;
    }
`;

const StyledCardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
`;

const StyledButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
    padding-right: 24px;
`;

const StyledText = styled(Text)`
    color: #fff;
`
const StyledTextDescription = styled(Text)`
    padding: 16px;
    color: #fff;
`

const StyledTitle = styled(Title)`
    color: #fff !important;
`

export default function HomeProgramCard({program}) {
    const {t} = useTranslation();
    const [challenges, setChallenges] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const {handleApplyChallenge} = useApplication();

    const screens = useBreakpoint();
    const {user_client} = useUserContext();
    const daysLeft = program.application_phase_remaining_days;
    const hasToShowDaysLeft = program?.is_application_phase_open && program.duration === "temporary" && daysLeft && daysLeft <= 7

    const fetchChallenges = async () => {
        try {
            setLoading(true)
            const params = {program_id: program?.id}
            const response = await api.get(`v2/home/active_challenges?page_size=30`, {params});
            setChallenges(response.data);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        !challenges && void fetchChallenges();
    }, [challenges])

    return (
        <StyledCardProgram
            hoverable
            style={{width: "100%"}}
            isOpen={program.is_application_phase_open}
            backgroundImage={program.banner}
            data-cy={`program-card-${program.id}`}
        >
            <StyledCardContent>
                <StyledTextDescription>
                    <EnvironmentLink to={`/available-programs/detail/${program.id}`} clientUrl={program?.client_url}>
                        <Row gutter={16} justify={screens.md ? 'space-between' : 'start'}>           
                            <LimitedSpace size="small">
                                <TruncateText lines={2}>
                                    <StyledTitle level={3}>
                                        {program?.name || "-"}
                                    </StyledTitle>
                                </TruncateText>
                            </LimitedSpace>                       
                            {program.date_end && (
                                <LimitedSpace size="small">
                                    {hasToShowDaysLeft && (
                                        <Tag color="#000" style={{borderRadius: 8}}>
                                            {t("applicationPhaseRemainingDays", {daysLeft})}
                                        </Tag>
                                    )}
                                    <ClockCircleOutlined style={{color: "#fff"}}/>
                                    {program.is_application_phase_open ? (
                                        <StyledText>{`${t("Registrations up until")}: ${DatetimeFormatByTimezone(program.first_phase_end_date)}`}</StyledText>
                                    ) : (
                                        <StyledText>{t("Registration Closed")}</StyledText>
                                    )}
                                    
                                </LimitedSpace>                            
                            )}
                        </Row>
                    </EnvironmentLink>
                    <TruncateText lines={2}>
                        <DangerousHtmlPreviewer content={program.description} />
                    </TruncateText>
                </StyledTextDescription>
                {challenges?.length !== 0 ?
                    <Skeleton loading={loading}>
                        {
                            challenges &&
                            <CarouselPro pageSize={3} autoplaySpeed={5000}>
                                {challenges?.map(challenge => (
                                    <ChallengeCard
                                        challenge={challenge}
                                        isOpen={program.is_application_phase_open}
                                        onClick={()=>handleApplyChallenge(challenge)}
                                        data-cy={`challenge-card-${challenge.name}`}
                                        applicationNameDisplay={program.application_name_choice_display}
                                    />
                                ))}
                            </CarouselPro>
                        }
                    </Skeleton>
                    :
                    user_client &&
                    <StyledButtonContainer>
                        {program.is_application_phase_open &&
                            <Button type="default" onClick={()=>handleApplyChallenge(program)}
                                data-cy={`suggest-program-solution-button-${program.id}`}
                            >
                                {`${t("Suggest")} ${t(program.application_name_choice_display)}`}
                            </Button>}
                    </StyledButtonContainer>
                }
            </StyledCardContent>
        </StyledCardProgram>
    );
}

