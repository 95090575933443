import React from "react";
import profile_default from "../../../../assets/img/25x25.png";
import EasyCrop from "../../../../components/EasyCrop/EasyCrop";


export default function ProfileUploader({ onChange, avatarData }) {
    return (
        <EasyCrop
            type="circle"
            avatarData={avatarData}
            id="profile"
            onUploadImage={(image) => {
                onChange?.(image);
            }}
            ratio={100 / 100}
        />
    );
}
