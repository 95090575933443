import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";


//Gambiarra
export default function useUpdateLocalRequest() {
    const [makeRequest, setMakeRequest] = useState(1);
    const [update, setUpdate] = useState(false)


    useEffect(() => {
        if (update) {
            _makeRequest()
            setUpdate(false)
        }
    }, [update])

    function _makeRequest() {
        setMakeRequest(makeRequest + 1)
    }

    function updateLocalContent() {
        setUpdate(true)
    }

    return {
        updateLocalContent,
        makeRequest
    }
}