import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";
import {useContextProfileUser} from "../context/ContextProfileUser";

import useDefaultGetRequest from "../../../../../hooks/useDefaultGetRequest";
import {Col, Row, Space, Typography} from "antd";
import {FlexContainer} from "../../../../../components/authentication/StyledComponents";
import {AppstoreOutlined, SisternodeOutlined} from "@ant-design/icons";
import Select from "../../../../../components/data_entry/Select";
import {generateSelectOptions} from "../../../../../../modules/project/utils/utils";
import ListOrEmpty from "../../../../../components/data_display/ListOrEmpty";
import CardAssesmentWithActions from "../../../../../components/other/CardAssesmentWithActions";


export default function TabDrawerEvaluationsUser() {
    const {t} = useTranslation();
    const [programOptions, setProgramOptions] = useState()
    const [phaseOptions, setPhaseOptions] = useState()
    const [currentProgram, setCurrentProgram] = useState()
    const [currentPhase, setCurrentPhase] = useState()
    const [evaluatorId, setEvaluatorID] = useState()
    const [assessmentsCount, setAssessmentsCount] = useState(0)
    const [updateList, setUpdateList] = useState(0)
    const {
        userData,
        phase_id, program_id, updatePageOnClose
    } = useContextProfileUser();

    const {
        data: programs,
        isLoading: isLoading
    } = useDefaultGetRequest({
        url: `get_programs_and_phases_with_user_is_evaluator/${userData.id}`,
        config: {version: 'v2'},
        makeRequest: !!userData,
        dependence: [userData]
    })

    const {
        data: assesments,
        isLoading: isLoadingAssesments
    } = useDefaultGetRequest({
        url: `evaluator_assesments_phase/${evaluatorId}/${currentPhase}`,
        config: {version: 'v2'},
        makeRequest: !!currentPhase && !!evaluatorId && assessmentsCount > 0,
        dependence: [currentPhase, evaluatorId, assessmentsCount, updateList]
    })

    useEffect(() => {
        if (programs && programs.length>0) {
            const programOptions = generateSelectOptions(programs)
            setProgramOptions(programOptions)
            const _currentProgram = Number(program_id) || (programOptions.length > 0 && programOptions[0]?.value) || null
            setCurrentProgram(_currentProgram)
            setEvaluatorID(programs.find(({id}) => id === _currentProgram)?.evaluator_id)
        }
    }, [programs])

    useEffect(() => {
        if (programs && currentProgram && programs.length>0) {
            const phases = programs.find(({id}) => id === currentProgram).phases
            const phaseOptions = generateSelectOptions(phases, 'title')
            setPhaseOptions(phaseOptions)
            const _currentPhase = phaseOptions.find(({value}) => value === Number(phase_id)) || (phaseOptions.length > 0 && phaseOptions[0]) || null
            setCurrentPhase(_currentPhase?.value)
            setAssessmentsCount(phases.find(({id}) => id === _currentPhase.value)?.assessments_count || 0)

        }
    }, [programs, currentProgram])


    return (
        <Row>
            <Col xs={24}>
                <FlexContainer justify={'end'} gap={16}>
                    <Space size={8} style={{minWidth: 150}}>
                        <AppstoreOutlined />
                        <Select style={{width: 112}}
                                options={programOptions}
                                value={currentProgram}
                                onChange={setCurrentProgram}/>
                    </Space>
                    <Space size={8}>
                        <SisternodeOutlined/>
                        <Select style={{width: 112}}
                                disabled={!!!currentProgram}
                                options={phaseOptions}
                                value={currentPhase}
                                onChange={setCurrentPhase}/>
                    </Space>
                </FlexContainer>
            </Col>
            <Col xs={24}>
                <Typography.Title
                    style={{fontSize: 16, marginTop: 16, marginBottom: 16}}
                    level={5}>{t('Evaluations')}</Typography.Title>
                <ListOrEmpty loading={isLoadingAssesments || isLoading} list={assesments}
                             callback={
                                 (item) => <CardAssesmentWithActions key={item.id} onChangeStatus={() => {
                                     setUpdateList((item) => item+1)
                                     updatePageOnClose()
                                 }} style={{marginBottom: 16}}{...item}/>

                             }/>


            </Col>
        </Row>
    );
}
