import React, {useState} from 'react';
import {Button, Card, Flex, Form as AntForm, Modal, Typography} from "antd";
import HelpCard from "../../../../global/components/other/HelpCard";
import {InfoCircleOutlined, PlusOutlined} from "@ant-design/icons";
import FinancialMilestoneFormDrawer from "../../components/drawer/FinancialMilestoneFormDrawer";
import {useTranslation} from "react-i18next";
import InvestmentFinancialPlanningTable from "../../components/tables/InvestmentFinancialPlanningTable";
import FinancePlanningHelpModal from "../../components/modal/FinancePlanningHelpModal";
import {calendula_6} from "../../../../global/utils/Colors";
import api from "axios";
import {useProjectManagementContext} from "./context/ProjectManagementContext";
import {BuildFieldErrorsDict} from "../../../../global/utils/Utils";


const {Title} = Typography;
const {confirm} = Modal;

function InvestmentFinancialPlanningPage(props) {

    const {t} = useTranslation();
    const [form] = AntForm.useForm()
    const [drawerOpen, setDrawerOpen] = useState("")
    const [selectedFinancialPlanning, setSelectedFinancialPlanning] = useState(undefined)
    const {project, fetchProject} = useProjectManagementContext()

    const handleCompleteBaseline = async () => {
        try {
            const data = {"forecast_conclude_baseline": true}
            await api.post(`project_management/project/${project?.id}/complete_forecast_baseline`, data)
            await void fetchProject();
        } catch (error) {
            BuildFieldErrorsDict(error, false, false)
        } finally {

        }
    }

    const confirmCompleteBaseline = () => {
        confirm({
            title: t("Do you want to finalize the baseline?"),
            content: `${t("After finalizing the baseline, it will not be possible to change the estimated value of new milestones. You will be allowed to create other milestones, but it will not be possible to include the estimated value for them")}.`,
            icon: <InfoCircleOutlined style={{color: calendula_6}}/>,
            okText: t("Finalize"),
            cancelText: t("Continue editing"),
            onOk: () => {void handleCompleteBaseline();},
        });
    }

    return (
        <>
            <Flex vertical={true} gap={24}>
                <HelpCard
                    title={t("Investment Financial Planning")}
                    description={t("Financial planning serves to generate the initial sales baseline planning.")}
                    icon={<InfoCircleOutlined/>}
                    onClick={() => {setDrawerOpen("FinancePlanningHelpModal")}}
                />
                <Card>
                    <Flex vertical={true} gap={16}>
                        <Flex justify="space-between">
                            <Flex gap={4} align="center">
                                <Title style={{margin: 0}} level={5}>{t("Investment Financial Planning")}</Title>
                                <InfoCircleOutlined/>
                            </Flex>
                            <Flex gap={8}>
                                <Button onClick={() => confirmCompleteBaseline()}
                                        disabled={!!project?.forecast_conclude_baseline}
                                >
                                    {t("Complete baseline")}
                                </Button>
                                <Button icon={<PlusOutlined/>} type="primary"
                                        onClick={async () => {
                                            await form.resetFields()
                                            setDrawerOpen("FinancialMilestoneFormDrawer")
                                        }}>
                                    {t("New financial milestone")}
                                </Button>
                            </Flex>
                        </Flex>
                        <InvestmentFinancialPlanningTable
                            setSelectedFinancialPlanning={setSelectedFinancialPlanning}
                            setDrawerOpen={setDrawerOpen}
                            form={form}
                        />
                    </Flex>
                </Card>
            </Flex>
            <FinancialMilestoneFormDrawer
                open={drawerOpen === "FinancialMilestoneFormDrawer"}
                setOpen={setDrawerOpen}
                selectedFinancialPlanning={selectedFinancialPlanning}
                form={form}
            />
            <FinancePlanningHelpModal
                open={drawerOpen === "FinancePlanningHelpModal"}
                setOpen={setDrawerOpen}
            />
        </>
    );
}

export default InvestmentFinancialPlanningPage;