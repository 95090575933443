import React from "react";
import {v4 as uuid} from "uuid";
import HeaderForm from "./../../PhaseFormBuilder/FormComponents/HeaderForm";
import ParagraphForm
    from "./../../PhaseFormBuilder/FormComponents/ParagraphForm";
import IconHeader from "../../../../assets/IconsFormComponents/header.svg";
import IconParagraph
    from "../../../../assets/IconsFormComponents/paragraph.svg";
import IconScore from "../../../../assets/IconsFormComponents/score.svg";

import IconToggle from "../../../../assets/IconsFormComponents/toggle_line.svg";
import IconFeedback from "../../../../assets/newicons/SendFeedback.svg";
import ScoreForm from "./ScoreForm";
import QuestionForm from "./QuestionForm";
import FeedbackForm from "./FeedbackForm";
import YesNoForm from "./YesNoForm";

export const COMPONENTS = [
    // TEXT
    {type: 'title_form_group', name: "Components"},
    {type: 'header', name: "Big Title", icon: IconHeader},
    {type: 'paragraph', name: "Small Title", icon: IconParagraph},
    {type: 'score_with_feedback', name: "Rating Input", icon: IconScore},
    {type: 'feedback', name: "Feedback", icon: IconFeedback},
    {type: 'yes_no', name: "Yes No Selection", icon: IconToggle}, //TROCARICONE
]

// const addchoices = ['select', 'multiple_select', 'checkbox', 'radio']

export const createJsonComponent = (copy) => {
    if (copy.type === 'score_with_feedback') {
        return {
            help_text: "",
            label: "Title",
            name: 'f' + uuid().split('-').join(''),
            required: true,
            show_feedback: false,
            type: copy.type,
            weight: 0
        }
    } else if (copy.type === 'feedback') {
        return {
            help_text: "",
            label: "Title",
            name: 'feedback_' + uuid().split('-').join(''),
            required: !['header', 'paragraph'].includes(copy.type),
            type: copy.type,
        }
    } else if (copy.type === 'question') {
        return copy

    } else {
        return {
            help_text: copy['help_text'] || '',
            label: copy['label'] || 'Title',
            name: 'f' + uuid().split('-').join(''),
            type: copy.type,
        }

    }
}

function getComponent(props) {
    switch (props.type) {
        case 'header':
            return <HeaderForm {...props}/>
        case 'paragraph':
            return <ParagraphForm {...props} />
        case 'score_with_feedback':
            return <ScoreForm {...props}/>
        case 'feedback':
            return <FeedbackForm {...props}/>
        case 'yes_no':
            return <YesNoForm {...props}/>
        case 'question':
            return <QuestionForm {...props}/>
        default:
            return <div>COMPONENTE INEXISTENTE</div>
    }
}

export default getComponent