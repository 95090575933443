import {createContext, useContext, useEffect, useState} from "react";
import api from "../services/api";
import {BuildFieldErrorsDict, darkenHexColor} from "../utils/Utils";
import {useHistory} from "react-router-dom";
import {DEBUG} from "../../settings";
import {ConfigProvider} from "antd";

//context
const ThemeContext = createContext();

//provider
const ThemeProvider = ({children}) => {
    const history = useHistory();
    const [themeConfig, setThemeConfig] = useState([]);
    const indicatorColorLevels = themeConfig["indicator_color_levels"];
    const [refreshTheme, setRefreshTheme] = useState(true);

    useEffect(() => {
        if (themeConfig) {
            ConfigProvider.config({
                theme: {
                    primaryColor: themeConfig.primary_btn_color,
                },
            });
        }
    }, [themeConfig])

    const getThemeConfig = async () => {
        try {
            const response = await api.get(`theme/public/conf`);
            setThemeConfig(response.data);
            return response.data
        } catch (error) {
            if (!error.response) {
                if (!DEBUG) {
                    console.error(error)
                    // history.push('/maintenance')
                }
            }
        }
    }

    function getFaviconEl() {
        return document.getElementById("favicon_");
    }

    function getLevelColor({value, levelsColorized, inverseImportancy, defaultColor = "#8C8C8C"}) {
        if (!levelsColorized) {
            return defaultColor;
        }
        let levels = Object.values(JSON.parse(JSON.stringify(indicatorColorLevels)));
        if (inverseImportancy) {
            levels.at(0).color = indicatorColorLevels.high.color
            levels.at(-1).color = indicatorColorLevels.low.color
        }

        for (const item of levels) {
            if (value <= item["value"]) {
                return item["color"];
            }
        }
        return defaultColor;
    }

    useEffect(async () => {
        try {
            getThemeConfig()
            setRefreshTheme(false);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        }
    }, [refreshTheme]);

    useEffect(() => {
        document.documentElement.style.setProperty('--background_action_bar', themeConfig.background_action_bar);
        document.documentElement.style.setProperty('--text_color_action_bar', themeConfig.text_color_action_bar);
        document.documentElement.style.setProperty('--background_menu', themeConfig.background_menu);
        document.documentElement.style.setProperty('--text_color_menu', themeConfig.text_color_menu);
        document.documentElement.style.setProperty('--active_menu_color', themeConfig.active_menu_color);
        document.documentElement.style.setProperty('--action_color', themeConfig.action_color);
        document.documentElement.style.setProperty('--login_background_cover', themeConfig.login_background_cover);
        document.documentElement.style.setProperty('--login_text_color_cover', themeConfig.login_text_color_cover);
        document.documentElement.style.setProperty('--login_background_button', themeConfig.login_background_button);
        document.documentElement.style.setProperty('--login_text_color_button', themeConfig.login_text_color_button);
        document.documentElement.style.setProperty('--primary_btn_text_color', themeConfig.primary_btn_text_color);
        document.documentElement.style.setProperty('--primary_btn_color', themeConfig.primary_btn_color);
        document.documentElement.style.setProperty('--primary_btn_color_hover', darkenHexColor(themeConfig.primary_btn_color, 10));
        document.documentElement.style.setProperty('--primary_btn_color_light', themeConfig.primary_btn_color + "33");

        // document.documentElement.style.setProperty('--antd-primary-color', themeConfig.primary_btn_color);
        getFaviconEl().href = themeConfig.favicon;
    }, [themeConfig])

    return (
        <ThemeContext.Provider
            value={{
                themeConfig,
                getLevelColor,
                indicatorColorLevels,
                setRefreshTheme
            }}>{children}</ThemeContext.Provider>
    );

};

//hook
const useThemeContext = () => {
    return useContext(ThemeContext);
}

export {useThemeContext, ThemeProvider};