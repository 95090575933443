import React, { useEffect, useState } from 'react';
import { ToastNotification } from '../../../components/feedback/ToastNotification';
import api from "../../../services/api";
import Loading from '../../../components/feedback/Loading';
import PasswordFormField from '../../../components/data_entry/form/PasswordFormField';
import PasswordValidation from '../../../components/data_entry/PasswordValidation';
import { Card, Typography, Form as FormAnt, Col, Row } from "antd";
import Button from '../../../components/data_entry/Button';
import { useTranslation } from 'react-i18next';


const { Text } = Typography;

export default function PasswordChangeForm() {
    const { t } = useTranslation();
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [hasPassword, setHasPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [form] = FormAnt.useForm();

    useEffect(() => {
        validatePassword();
    }, []);

    async function validatePassword() {
        try {
            setIsLoading(true);
            const response = await api.get(`/auth/validate_password`);
            setHasPassword(response.data.has_password);
        } catch (error) {
            ToastNotification('Error', 'error');
        } finally {
            setIsLoading(false);
        }
    }

    const handlePasswordChange = async () => {
        try {
            setIsLoading(true);

            if (hasPassword && oldPassword === '') {
                ToastNotification(t('Please input your old password'), 'error');
                return;
            }

            const data = {
                old_password: oldPassword,
                new_password: newPassword,
                confirm_password: confirmPassword,
            };

            if (!hasPassword) {
                delete data.old_password;
            }

            await api.post(`/auth/change_password`, data);

            setHasPassword(true);
            ToastNotification(t('Saved successfully'));
            // window.location.reload();
            // logout();
        } catch (error) {
            console.error('Error', error);
            let errorMessage = 'Error';

            if (error.response && error.response.data && error.response.data.errors) {
                const fieldErrors = error.response.data.errors;
                const firstFieldWithError = Object.keys(fieldErrors)[0];
                const firstError = fieldErrors[firstFieldWithError][0];
                errorMessage = firstError;
            }

            ToastNotification(t(errorMessage), 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Card
            title={<Text style={{ fontSize: 16, fontWeight: 500 }}>{t('Change password')}</Text>}
        >
            {isLoading ? (
                <Loading />
            ) : (
                <FormAnt form={form}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <FormAnt.Item
                                name="old_password"
                                label={t('Old password')}
                                labelCol={{ span: 24 }}
                                onChange={(e) => setOldPassword(e.target.value)}
                                rules={[
                                    {
                                        required: hasPassword,
                                        message: t('Please input your old password'),
                                    },
                                ]}
                            >
                                <PasswordFormField
                                    placeholder={t('Type your old password here...')}
                                />
                            </FormAnt.Item>
                            <FormAnt.Item
                                name="new_password"
                                label={t('New Password')}
                                labelCol={{ span: 24 }}
                                onChange={(e) => setNewPassword(e.target.value)}
                                rules={[
                                    {
                                        required: true,
                                        message: t('Type your e-mail here'),
                                    },
                                ]}
                            >
                                <PasswordFormField
                                    placeholder={t('Type your e-mail here')}
                                />
                            </FormAnt.Item>
                            <FormAnt.Item
                                name="confirm_password"
                                label={t('Confirm Password')}
                                labelCol={{ span: 24 }}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                rules={[
                                    {
                                        required: true,
                                        message: t('Please input your confirm password'),
                                    },
                                ]}
                            >
                                <PasswordFormField
                                    placeholder={t('Type your new password here again...')}
                                />
                            </FormAnt.Item>
                        </Col>
                    </Row>
                    <PasswordValidation
                        newPassword={newPassword}
                        confirmPassword={confirmPassword}
                        t={t}
                    />
                    <Col span={24}>
                        <div style={{ textAlign: 'end', marginTop: '16px' }}>
                            <Button type="primary" onClick={handlePasswordChange}>
                                {t('Save')}
                            </Button>
                        </div>
                    </Col>
                </FormAnt>
            )}
        </Card>
    );
}