import React, {useEffect, useState} from 'react';
import CollapseFormItem from "../data_entry/CollapseFormItem";
import {useTranslation} from "react-i18next";
import Button from "../data_entry/Button";
import {TagOutlined} from "@ant-design/icons";
import Drawer from "../layout/Drawer";
import InputFormField from "../data_entry/form/InputFormField";
import Form from "../data_entry/form/Form";
import {Form as FormAntd} from "antd";
import SelectFormField from "../data_entry/form/SelectFormField";
import useDefaultGetRequest from "../../hooks/useDefaultGetRequest";
import {
    APPLICATION_TAG,
    PROGRAM_TAG,
    PROJECT_TAG,
    USER_TAG
} from "../../utils/Choices";
import CustomTag from "./CustomTag";
import {FlexContainer} from "../authentication/StyledComponents";
import api from "../../services/api";
import {ToastNotification} from "../feedback/ToastNotification";
import {SUCCESS} from "../../utils/fileWithMessage";
import {BuildFieldErrorsDict} from "../../utils/Utils";
import {
    useLayoutContext
} from "../../../modules/project/components/layout/context/LayoutContext";

const TITLE = {
    [USER_TAG]: 'Tag this user',
    [PROGRAM_TAG]: 'Tag this program',
    [PROJECT_TAG]: 'Tag this project',
    [APPLICATION_TAG]: 'Tag this application'
}
const HELP = {
    [USER_TAG]: 'Add tags to classify this user',
    [PROGRAM_TAG]: 'Add tags to classify this program',
    [PROJECT_TAG]: 'Add tags to classify this user',
    [APPLICATION_TAG]: 'Add tags to classify this application'
}

const FORM_NAMES = {
    TAGS_IDS: 'tags_ids'
}

export default function AttachingTags({
                                          refreshContentOnClose,
                                          // setUpdateContent,
                                          target, id,
                                      }) {
    const {t} = useTranslation()
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [form] = FormAntd.useForm()
    const {updateLayoutContent} = useLayoutContext()
    const URL_POST = {
        [USER_TAG]: `users_client/${id}/set_tags`,
        [PROGRAM_TAG]: `programs/${id}/set_tags`,
        [PROJECT_TAG]: `project_management/project/${id}/set_tags`,
        [APPLICATION_TAG]: `applications/${id}/set_tags`
    }
    const URL_GET = {
        [USER_TAG]: `users_client/${id}/get_tags`,
        [PROGRAM_TAG]: `programs/${id}/get_tags`,
        [PROJECT_TAG]: `project_management/project/${id}/get_tags`,
        [APPLICATION_TAG]: `applications/${id}/get_tags`
    }
    const {
        data: tags,
        isLoadingOptions
    } = useDefaultGetRequest({
        url: `api/tags`,
        hasPagination: true,
        params: {
            targets: target,
            page_size: 1000
        },
        makeRequest: open,
        dependence: [open]
    })

    const {
        data: object_tags,
        isLoadingObjectTags
    } = useDefaultGetRequest({
        url: URL_GET[target],
        // hasPagination: true,
        makeRequest: open,
        dependence: [open]
    })

    useEffect(() => {
        if (object_tags?.length > 0) {
            form.setFieldValue(FORM_NAMES.TAGS_IDS, object_tags.map(({id}) => id))
        }
    }, [object_tags]);

    function loading() {
        return isLoading || isLoadingOptions || isLoadingObjectTags
    }


    function optionsTags() {
        if (tags?.length > 0) {
            return tags.map((tag) => ({
                label: <CustomTag {...tag}/>, value: tag.id, object: tag
            }))
        }
        return []
    }

    function onClose() {
        form.resetFields()
        setOpen(false)
    }

    async function handleSubmit(data) {
        try {
            setIsLoading(true);
            let response = await api.post(URL_POST[target], data);
            refreshContentOnClose?.()
            ToastNotification(t(SUCCESS));
            onClose()
        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, false);
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <>
            <CollapseFormItem
                input={<Button onClick={() => setOpen(true)}
                               icon={<TagOutlined/>}
                               type={'default'}>{t('Tagging')}</Button>}

                help={t(HELP[target])}
                label={t(TITLE[target])}
            />
            <Drawer loading={loading()}
                    title={t("Tagging")}
                    open={open}
                    footer={<FlexContainer justify={'end'}>
                        <Button onClick={form.submit}>{t('Tagging')}</Button>
                    </FlexContainer>}
                    onClose={onClose}
            >
                <Form form={form} onFinish={handleSubmit}>
                    <SelectFormField
                        filterOption={(input, option) => (option?.object.name ?? '').toLowerCase().includes(input.toLowerCase())

                        } mode={'multiple'}
                        allowClear
                        options={optionsTags()}
                        form={{
                            label: t('Select one or more tags'),
                            name: FORM_NAMES.TAGS_IDS
                        }}/>
                </Form>

            </Drawer>

        </>
    )
}