import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import {
    FlexContainer
} from "../../../../../../global/components/authentication/StyledComponents";
import {
    ExclamationCircleFilled,
    EyeInvisibleOutlined,
    SettingOutlined
} from "@ant-design/icons";
import ApplicationDrawer
    from "../../../../../../global/components/other/application/ApplicationDrawer/ApplicationDrawer";
import {useHistory} from "react-router-dom";
import {PHASE} from "../../../../../../global/utils/Constants";
import Empty from "../../../../../../global/components/data_display/Empty";
import Button from "../../../../../../global/components/data_entry/Button";
import {Droppable} from "react-beautiful-dnd";
import ApplicationCardPhase from "./ApplicationCardPhase";
import {
    blue_5,
    neutral_4,
    secondary_color
} from "../../../../../../global/utils/Colors";


const Body = styled(FlexContainer)`
    padding: 0px 16px 16px;
    overflow-x: hidden;
    overflow-y: auto;
    flex: 1;
    //height: 150px;
    //height: 100%;
    //background-color: red;
    //    flex: 1;
    background: ${({isDraggingOver}) => isDraggingOver ? neutral_4 : 'none'};
    border: 1px dashed ${({isDraggingOver}) => isDraggingOver ? blue_5 : 'transparent'};

    //margin-top: 10px;
`

export default function BodyPhaseColumn({phase, isConfidencial, updateUrl, ...props}) {
    const {t} = useTranslation();
    const [showApplicationDrawer, setShowApplicationDrawer] = useState(false)
    const [applicationDrawer, setApplicationDrawer] = useState()
    const history = useHistory()

    if (!phase) return null
    const IS_PHASE_INFORMATION = phase.type === PHASE.INFORMATION
    // const IS_PHASE_APPLICATION = phase.type === PHASE.APPLICATION
    // const UPDATE_URL = `/programs/${phase.program_id}/phases/${phase.id}/update`

    function handleRedirectPageUpdate() {
        history.push(updateUrl)
    }

    function handleOpenApplicationDrawer(e, application) {
        setApplicationDrawer(application)
        setShowApplicationDrawer(true)
    }

    const IS_DROP_DISABLED = !phase.is_open || IS_PHASE_INFORMATION


    return (
        <> <Droppable droppableId={`${phase.id}`}
                      isDropDisabled={IS_DROP_DISABLED}
        >
            {(provided, {isDraggingOver}) => (
                <Body gap={16} direction={'column'}
                      isDraggingOver={isDraggingOver}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      data-cy={`droppable-phase-${phase?.id}`}
                >
                    {phase.applications.length === 0  ? (<>
                            {!isDraggingOver && (
                                <>
                                    {isConfidencial ? (
                                        <Empty alingText={'left'}
                                               image={
                                                   <EyeInvisibleOutlined
                                                       style={{
                                                           height: '100%',
                                                           fontSize: 62
                                                       }}/>}
                                               description={'Program with active stealth mode. It is not possible to show applications at this stage. Track progress through the program dashboard.'}>
                                            <Button
                                                icon={<SettingOutlined/>}
                                                onClick={handleRedirectPageUpdate}>
                                                {t('Configure the phase')}
                                            </Button>
                                        </Empty>

                                    ) : (IS_PHASE_INFORMATION ? (
                                        <Empty alingText={'left'}
                                               image={
                                                   <ExclamationCircleFilled
                                                       style={{
                                                           color: secondary_color,
                                                           height: '100%',
                                                           fontSize: 62
                                                       }}/>}
                                               description={'Informational Stage. This stage does not receive applications.'}>
                                            <Button
                                                icon={<SettingOutlined/>}
                                                onClick={handleRedirectPageUpdate}>
                                                {t('Configure the phase')}
                                            </Button>
                                        </Empty>) : (<Empty alingText={'left'}
                                                            description={'There is no application yet at this stage. Configure the step by clicking below or on the settings icon'}>
                                        <Button
                                            icon={<SettingOutlined/>}
                                            onClick={handleRedirectPageUpdate}>
                                            {t('Configure the phase')}
                                        </Button>
                                    </Empty>))}

                                </>
                            )
                            }</>
                    ) : (phase.applications.map((application, index) =>
                        <ApplicationCardPhase
                            index={index}
                            onClick={handleOpenApplicationDrawer}
                            key={application.id}
                            application={application}/>))}

                    {provided.placeholder}
                </Body>
            )}

        </Droppable>
            <ApplicationDrawer solutionId={applicationDrawer?.id}
                               open={showApplicationDrawer}
                               setOpen={setShowApplicationDrawer}/>
        </>


    );
}

