import { useRouteMatch, Switch, Route } from "react-router-dom";
import ListSolutions from "../../../global/pages/solutions/ListSolutions";
import SuggestedChallengesPage from "../../../global/pages/challenges/SuggestedChallengesPage";
import ListTopics from "../../../global/pages/messages/ListTopics";
import ListRequests from "../../../global/pages/requests/ListRequests";


export default function RoutesInitiativesApplications() {
    let { path } = useRouteMatch();

    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={() =><ListSolutions />}
            />
            <Route
                exact
                path={`${path}/suggested_applications_challenges`}
                render={() =><SuggestedChallengesPage />}
            />
            <Route
                exact
                path={`${path}/messages`}
                render={(props) =><ListTopics/>}
            />
             <Route
                exact
                path={`${path}/requests`}
                render={(props) =><ListRequests />}
            />
        </Switch>
    );
}

