import {createContext, useContext, useState} from "react";
import moment from "moment-timezone";
import UserContext, {useUserContext} from "./UserContext";
import {useTranslation} from "react-i18next";

//context

const AutoSaveContext = createContext({});

//provider
const AutoSaveProvider = ({children}) => {
    const [show, setShow] = useState(false)
    const [hasError, setHasError] = useState(true)
    const [isSaving, setIsSaving] = useState(false)
    const [msg, setMsg] = useState('')
    const {_user, user_client} = useUserContext();
    const showAutoSave = () => {
        setShow(true)
        setHasError(false)
        setMsg('')
    }
    const {t} = useTranslation()
    const hideAutoSave = () => {
        setShow(false)
    }

    const setAutoSaveSuccess = () => {
        setHasError(false)
        setMsg( `${t("Saved at")} ${moment().tz(_user.time_zone).format('HH:mm')}`)
        stopSave()
    }

    const setAutoSaveError = () => {
        setHasError(true)
        stopSave()
    }

    const startSave = () => {
        setIsSaving(true)
    }
    const stopSave = () => {
        setIsSaving(false)
    }
    return (
        <AutoSaveContext.Provider
            value={{
                show,
                hasError,
                isSaving,
                msg,
                showAutoSave,
                hideAutoSave,
                startSave,
                setAutoSaveSuccess,
                setAutoSaveError,
                stopSave
            }}>{children}</AutoSaveContext.Provider>
    );
};

//hook

const useAutoSaveContext = () => {
    return useContext(AutoSaveContext);
}

export {useAutoSaveContext, AutoSaveProvider};