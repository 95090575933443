import {useLayoutContext} from "../layout/context/LayoutContext";
import {useProjectManagementContext} from "../../pages/ProjectManagement/context/ProjectManagementContext";
import {Button, Form} from "antd";
import api from "../../../../global/services/api";
import Drawer from "../../../../global/components/layout/Drawer";
import React from "react";
import {LoadingOutlined} from "@ant-design/icons";
import IndicatorProjectCard from "../../pages/ProjectManagement/components/IndicatorProjectCard";
import IndicatorProjectHistory from "../../pages/ProjectManagement/components/IndicatorProjectHistory";
import { useTranslation } from "react-i18next";
import { ToastNotification } from "../../../../global/components/feedback/ToastNotification";


function ProjectIndicatorDrawer({open, onClose, currentUpdatingIndicatorResponse, refresh}) {
    const {openNotification} = useLayoutContext();
    const {setRefreshBadges} = useProjectManagementContext();
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const handleIndicatorResponseUpdate = async (data) => {
        try {
            await api.patch(`/project_management/project_indicator_responses/${currentUpdatingIndicatorResponse?.id}`, data);
            openNotification({type: "success", message: t("Indicator Updated Successfully")});
            setRefreshBadges && setRefreshBadges(["settingsBadges"]);
            refresh && refresh();
            onClose();
        } catch (error) {
            if (error.response?.data?.errors) {
                ToastNotification(error.response.data.errors, 'error')
            }
        }
    };

    return <>
        <Drawer
            title={t("Edit Indicator")}
            open={open}
            onClose={onClose}
            footer={
                currentUpdatingIndicatorResponse && <>
                    <div style={{textAlign: "end"}}>
                        <Button type="primary" onClick={form.submit} data-cy="submit-indicator-button">{t("Save")}</Button>
                    </div>
                </>
            }
        >
            {currentUpdatingIndicatorResponse ?
                <IndicatorProjectCard
                    form={form}
                    isEditing={true}
                    handleSubmit={handleIndicatorResponseUpdate}
                    indicatorResponse={currentUpdatingIndicatorResponse}
                    indicator={currentUpdatingIndicatorResponse.indicator}
                />: <LoadingOutlined />
            }
            <IndicatorProjectHistory indicatorResponse={currentUpdatingIndicatorResponse} />
        </Drawer>
    </>;
}

export default ProjectIndicatorDrawer;