import {
    FlagOutlined,
    FolderOpenOutlined,
    ProjectOutlined
} from "@ant-design/icons";
import {Badge, Button, Modal} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {
    useLayoutContext
} from "../../../../../../../modules/project/components/layout/context/LayoutContext";
import ChallengeDrawerForm
    from "../../../../../../../pages/Programs/TabBarProgram/Challenges/components/ChallengeDrawerForm";
import {
    usePermissionContext
} from "../../../../../../context/PermissionContext";
import api from '../../../../../../services/api';
import ChallengeInfoCard
    from "../../../../../data_display/ApplicationInfoCard/ChallengeInfoCard";
import ProjectInfoCard
    from "../../../../../data_display/ApplicationInfoCard/ProjectInfoCard";
import RemixIcon from "../../../../../data_display/RemixIcon";
import CollapseFormItem from "../../../../../data_entry/CollapseFormItem";
import Loading from "../../../../../feedback/Loading";
import WideSpace from "../../../../../layout/WideSpace";
import TransparentCollapse from "../../../../../misc/TransparentCollapse";
import ApplicationDrawer from "../../ApplicationDrawer";
import {
    useApplicationDrawerContext
} from "../../context/ApplicationDrawerContext";
import ApplicationGroupDrawer from "../ApplicationGroupDrawer";
import ApplicationReturnPhaseDrawer from "../ApplicationReturnPhaseDrawer";
import LinkApplicationProjectDrawer from "../LinkApplicationProjectDrawer";
import GroupedApplicationCard from "./components/GroupedApplicationCard";
import AttachingTags from "../../../../../tag/AttachingTags";
import {
    APPLICATION_TAG,
    PROGRAM_TAG,
    PROJECT_TAG
} from "../../../../../../utils/Choices";

const StyledBadge = styled(Badge)`
    sup {
        font-weight: bold;
        color: white;
    }
`

export default function ApplicationSettingsTab({postUpdate, setOpen,refreshContentOnClose}) {
    const {t} = useTranslation()

    const {
        solution,
        groupedApplications,
        fetchGroupedApplications,
        originatedChallenges,
        fetchOriginatedChallenges,
        setOriginatedChallenges,
    } = useApplicationDrawerContext()

    const [loading, setLoading] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [currentEditingApplication, setCurrentEditingApplication] = useState();
    const [openApplicationGroupDrawer, setOpenApplicationGroupDrawer] = useState(false);
    const [openReturnPhaseDrawer, setOpenReturnPhaseDrawer] = useState(false);
    const [openChallengeDrawer, setOpenChallengeDrawer] = useState(false)
    const [openProjectDrawer, setOpenProjectDrawer] = useState(false)
    const {openNotification} = useLayoutContext();
    const {hasProgramAdminPermission} = usePermissionContext();
    const [challengeId, setChallengeId] = useState()

    const enableToLink = (originatedChallenges !== undefined) && !(["given_up", "archived", "grouped"].includes(solution?.status))

    const hasSettingsPermission = (
        solution &&
        (hasProgramAdminPermission || solution?.program.permissions.is_program_manager)
    );

    const hasGroupPermission = hasSettingsPermission && solution.status !== "grouped";

    const handleFetchGroupedApplication = async () => {
        setLoading(true);
        await fetchGroupedApplications();
        setLoading(false);
    }

    const handleFetchOriginatedChallenges = async () => {
        setLoading(true);
        await fetchOriginatedChallenges();
        setLoading(false);
    }

    const handleUpdateApplication = () => {
        void handleFetchGroupedApplication();
        postUpdate && postUpdate()
    }

    useEffect(() => {
        if (solution) {
            // !groupedApplications && void handleFetchGroupedApplication();
            !originatedChallenges && void handleFetchOriginatedChallenges()
        }
    }, [solution, originatedChallenges]);

    const handleOnUpdate = () => {
        setOpen(false);
        postUpdate && postUpdate();
    }

    const handleOpenDrawer = (application) => {
        setCurrentEditingApplication(application.id);
        setOpenDrawer(true);
    }

    const handleOpenChallengeDrawer = (challenge) => {
        setChallengeId(challenge.id)
        setOpenChallengeDrawer(true)
    }

    const handleArchiveApplication = async () => {
        try {
            await api.patch(`/applications/${solution.id}/archive_unarchive`);
            setOpen(false);
            postUpdate && postUpdate();
            openNotification({
                type: "success",
                message: t("Application archived successfuly")
            });
        } catch (error) {
            openNotification({
                type: "error",
                message: error.response?.data.errors
            });
        }
    }

    const getArchiveModalDescription = () => {
        if (!solution.is_archived) {
            return {
                title: t("Do you want to archive this application?"),
                content: t("This action can be undone. When the application is archived, it will not appear in the database by default."),
                okText: t("Archive"),
                cancelText: t("Give up"),
            }
        }
        return {
            title: t("Do you want to unarchive this application?"),
            content: t("The application will reappear in the program database by default."),
            okText: t("Unarchive"),
            cancelText: t("Give up"),
        }
    }

    const confirmArchiveApplication = () => {
        Modal.confirm({
            ...getArchiveModalDescription(),
            onOk: () => handleArchiveApplication()
        });
    }

    useEffect(() => {
        !openChallengeDrawer && setChallengeId(undefined)
    }, [openChallengeDrawer]);

    return (
        <>
            {loading && <Loading/>}
            {!loading &&
                <WideSpace direction="vertical" size={24}>
                    <TransparentCollapse header={t("Grouped Applications")}>
                        <WideSpace direction="vertical" size={16}>
                            {
                                groupedApplications?.map((application, key) => (
                                    <GroupedApplicationCard
                                        application={application}
                                        handleUpdateApplication={handleUpdateApplication}
                                        hasPermission={hasGroupPermission}
                                        onClick={() => handleOpenDrawer(application)}
                                    />
                                ))
                            }
                            {hasGroupPermission &&
                                <CollapseFormItem
                                    label={t("Associate with an existing application.")}
                                    help={t("Associate this application with an existing solution or challenge proposal.")}
                                    input={<Button type='default'
                                                   icon={<RemixIcon
                                                       remixIconName={"ri-git-merge-line"}/>}
                                                   onClick={() => setOpenApplicationGroupDrawer(true)}>
                                        {t('Group')}
                                    </Button>
                                    }/>
                            }
                        </WideSpace>
                    </TransparentCollapse>

                    {enableToLink &&
                        <>
                            <TransparentCollapse header={t("Challenge")}>
                                <WideSpace direction="vertical" size="middle">
                                    {
                                        originatedChallenges?.map(challenge => (
                                            <ChallengeInfoCard
                                                color={'#B37FEB'}
                                                solution={solution}
                                                challenge={challenge}
                                                onClick={() => {
                                                    handleOpenChallengeDrawer(challenge)
                                                }}/>
                                        ))
                                    }
                                    {(originatedChallenges?.length === 0) &&
                                        <CollapseFormItem
                                            label={t("Create a new challenge from this application")}
                                            help={t("It will be created a new challenge in a program of your choice based on this application.")}
                                            input={<Button type='default'
                                                           icon={
                                                               <FlagOutlined/>}
                                                           onClick={() => setOpenChallengeDrawer(true)}>
                                                {t('Create Challenge')}
                                            </Button>
                                            }/>
                                    }
                                </WideSpace>
                            </TransparentCollapse>

                            {hasSettingsPermission &&
                                <TransparentCollapse
                                    header={t("Project Link")}>
                                    <WideSpace direction="vertical"
                                               size="middle">

                                        {solution.project &&
                                            <ProjectInfoCard color={'#1C78BA'}
                                                             applicationId={solution?.id}
                                                             project={solution?.project}/>
                                        }

                                        {!solution.project &&
                                            <CollapseFormItem
                                                label={t("Link this application to a project")}
                                                help={t("This application will be linked to a project.")}
                                                input={<Button type='default'
                                                               data-cy="link-project-button"
                                                               icon={
                                                                   <ProjectOutlined/>}
                                                               onClick={() => setOpenProjectDrawer(true)}>
                                                    {t('Link the Project')}
                                                </Button>
                                                }/>
                                        }
                                    </WideSpace>
                                </TransparentCollapse>}
                        </>
                    }

                    {hasSettingsPermission &&
                        <TransparentCollapse header={t("Archive")}>
                            <WideSpace direction="vertical" size="middle">
                                {hasSettingsPermission &&
                                    <CollapseFormItem
                                        label={t("Archive application")}
                                        help={t("This application will be removed from the program's database once it is archived.")}
                                        input={<Button type='default'
                                                       icon={
                                                           <FolderOpenOutlined/>}
                                                       onClick={() => confirmArchiveApplication()}>
                                            {!solution.is_archived ? t("Archive application") : t("Unarchive application")}
                                        </Button>
                                        }/>
                                }
                            </WideSpace>
                        </TransparentCollapse>
                    }

                    {solution?.id && <TransparentCollapse header={t("Tagging application")}>
                        <WideSpace direction="vertical" size="middle">
                            {solution?.id && <AttachingTags refreshContentOnClose={refreshContentOnClose} target={APPLICATION_TAG}
                                                           id={solution?.id}/>}
                        </WideSpace>
                    </TransparentCollapse>}

                </WideSpace>
            }

            {hasGroupPermission &&
                <ApplicationGroupDrawer
                    groupApplication={solution}
                    open={openApplicationGroupDrawer}
                    setOpen={setOpenApplicationGroupDrawer}
                    onJoin={handleOnUpdate}
                />}

            {hasSettingsPermission &&
                <ApplicationReturnPhaseDrawer
                    solution={solution}
                    open={openReturnPhaseDrawer}
                    setOpen={setOpenReturnPhaseDrawer}
                />
            }

            <ApplicationDrawer solutionId={currentEditingApplication}
                               open={openDrawer} setOpen={setOpenDrawer}
                               postUpdate={fetchGroupedApplications}/>

            <ChallengeDrawerForm
                open={openChallengeDrawer} setOpen={setOpenChallengeDrawer}
                program={solution?.program}
                externalOriginatingApplicationID={solution?.id}
                challengeId={challengeId}
                onAfterSubmit={() => {
                    setOriginatedChallenges(undefined)
                }}
                initialFieldsValue={{
                    name: solution?.name
                }}
            />

            <LinkApplicationProjectDrawer open={openProjectDrawer}
                                          setOpen={setOpenProjectDrawer}
                                          application={solution}/>
        </>
    );
}

