import React, {useEffect} from 'react';
import {Link, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Col, Row} from "antd";
import ExtraHeader
    from "../../../modules/project/components/layout/ExtraHeader";
import {
    useLayoutContext
} from "../../../modules/project/components/layout/context/LayoutContext";
import ProgramTable from "../../../modules/program/components/ProgramTable";
import {HOST_ENTREPRENEUR} from "../../../settings";
import {redirect_tag_a} from "../../utils/Utils";


export default function ManagerProgramsSolv() {
    const {t} = useTranslation();
    const history = useHistory();
    const host = window.location.host;
    const {
        initializeLayout,
    } = useLayoutContext();


    function handleRowClick(record) {
        const port = window.location.port ? ':' + window.location.port : ''
        const client_url = record.client_url;
        redirect_tag_a(`//${client_url + port}/programs/${record.id}/settings`)
    }


    useEffect(() => {
        initializeLayout({
             navbarMenuItems: [
                {
                    label: <Link to={``}>{t("Manage Programs")}</Link>,
                    key: `/programs`
                },
            ]
        });

    }, []);

    return (<Row>
        <Col xs={24}>
            <ProgramTable url={`programs/manager_programs`}
                          onRowClick={handleRowClick}/>
        </Col>
    </Row>)
}
