import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Carousel as CarouselAnt } from "antd";
import styled from "styled-components";

const StyledCarousel = styled(CarouselAnt)`
    .slick-arrow {
        all: unset !important;
        cursor: pointer !important;
        position: absolute !important;
        z-index: 1 !important;
        top: 50% !important;
    }
    
    .slick-dots{
        display: ${props => props.$forceHideDots && "none"} !important;
    }
    
    .slick-prev {    
        left: 0 !important;
    }
    
    .slick-prev::after {
        display: none;
    }

    .slick-next {
        right: 0 !important;
    }
    
    .slick-next::after {
        display: none;
    }

    .dark-dots button {
        background: #858585 !important;
    }
`;

export default function Carousel({
        children, autoplay=true, autoplaySpeed=3000, arrows=false, 
        dots=true, forceHideDots=false, useDarkDots, dotsPosition, prevArrow, nextArrow, ...props}) {
    
    prevArrow = prevArrow || <LeftOutlined />
    nextArrow = nextArrow || <RightOutlined />
    
    return (
        <StyledCarousel 
            dots={{className: dots && useDarkDots ? "dark-dots": "dots"}}
            $forceHideDots={forceHideDots}
            dotsPosition={dotsPosition}
            autoplay={autoplay}
            autoplaySpeed={autoplaySpeed} 
            arrows={arrows} 
            prevArrow={prevArrow} 
            nextArrow={nextArrow}
            {...props}
        >
            {children}
        </StyledCarousel>
    )
}