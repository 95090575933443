import React, {useState} from "react";
import StyledCardWithOverlay from "./StyledCardWithOverlay";
import {Typography} from "antd";
import styled from "styled-components";
import TruncateText from "../../../../components/responsive/TruncateText";
import CampaignsFullScreenModal from "./CampaignsFullScreenModal";
import useSystemBreakpoint from "../../../../hooks/useSystemBreakpoint";

const {Text} = Typography;

const StyledText = styled(Text)`
    position: relative;
    z-index: 2;
    color: white;
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 0.5;
`;

const TitleText = styled(StyledText)`
    font-size: 12px;
    font-weight: 700;
`;

const SubTitleText = styled(StyledText)`
    font-size: 8px;
    font-weight: 400;
`;

export default function CampaignFeedCard({item, keyId, onClick}) {
    const {isPhone, isDesktop} = useSystemBreakpoint()
    return (
        <StyledCardWithOverlay
            key={keyId}
            bgImage={item.img_url}
            height="76px"
            gap="2px"
            onClick={onClick}
            style={{cursor: "pointer"}}
        >
            <CardContainer>
                <SubTitleText>
                    <TruncateText lines={1}>{item.name}</TruncateText>
                </SubTitleText>
                <TruncateText lines={2}>
                    <TitleText>
                        {item.description}
                    </TitleText>
                </TruncateText>
            </CardContainer>
        </StyledCardWithOverlay>
    );
}
