import {useEffect} from 'react';
import SolvIcon from "../../assets/img/SolvCinza192.svg";
import {useTabFocus} from "./useTabFocus";

export const useNotifications = ({notifications}) => {

    const {isFocused, lastFocusedTab, thisTab} = useTabFocus()

    const canNotify = (timeDifference) => {
        if (!lastFocusedTab && isFocused) {
            return 'Notification' in window && timeDifference < 5000
        }
        if (lastFocusedTab === thisTab) {
            return 'Notification' in window && timeDifference < 5000
        }
        return false
    }

    useEffect(() => {
        const checkAndDisplayNotifications = (notifications) => {
            const currentTime = new Date();
            let shouldStop = false;

            notifications.forEach((notification) => {
                if (shouldStop) return;
                const creationTime = new Date(notification.date_create);
                const timeDifference = currentTime - creationTime;

                if (canNotify(timeDifference)) {
                    Notification.requestPermission().then((permission) => {
                        if (permission === 'granted') {
                            const browserNotification = new Notification(notification.title, {
                                body: notification.message,
                                icon: SolvIcon,
                            });
                            browserNotification.onclick = () => {
                                window.open(notification.url_action, '_blank');
                            };
                        }
                    });
                } else {
                    shouldStop = true;
                }
            });
        };
        notifications && checkAndDisplayNotifications(notifications);
    }, [notifications]);


};

export default useNotifications;