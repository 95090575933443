import {ModalProvider} from "./ModalMessageContext";
import {UserProvider} from "./UserContext";
import {AutoSaveProvider} from "./AutoSaveContext";
import {ThemeProvider} from "./ThemeContext";
import {ConfirmUnsavedChangesProvider} from "./ConfirmUnsavedChangesContext";
import {DatabaseProvider} from "../../modules/program/context/DatabaseContext";
import {
    LayoutContextProvider
} from "../../modules/project/components/layout/context/LayoutContext";
import {PermissionContextProvider} from "./PermissionContext";
import {
    SolvTourModalContextProvider
} from "../../modules/project/components/modal/SolvTourModalList";

const providers = [ThemeProvider, LayoutContextProvider, ModalProvider, UserProvider, PermissionContextProvider, SolvTourModalContextProvider, AutoSaveProvider, ConfirmUnsavedChangesProvider, DatabaseProvider];

export default function AppContext(props) {
    const {children, ...rest} = props;
    return (
        <>
            {providers.reduceRight((acc, Comp) => {
                return <Comp {...rest}>{acc}</Comp>
            }, children)}
        </>
    )
}