import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
    Alert,
    Col,
    Modal,
    Row,
    Space,
    Typography
} from "antd";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import Button from "../../../../../../../global/components/data_entry/Button";
import {
    ToastNotification
} from "../../../../../../../global/components/feedback/ToastNotification";
import {
    CardContainer
} from "../../../../../../../global/components/misc/CardContainer";
import useTable from "../../../../../../../global/hooks/useTable";
import api from "../../../../../../../global/services/api";
import * as ConstantsMessage from "../../../../../../../global/utils/fileWithMessage";

import styled from 'styled-components';
import Table from "../../../../../../../global/components/data_display/Table";
import Search from "../../../../../../../global/components/data_entry/Search";
import {
    get_color_status
} from "../../../../../../../global/components/other/Utils";
import PermissionTagsList from "../../../../../../../global/components/tag/PermissionTagsList";
import DrawerProfileUser from '../../../../../../../global/pages/profile/components/DrawerProfileUser/DrawerProfileUser';
import { neutral_6, secondary_color } from '../../../../../../../global/utils/Colors';
import { BuildFieldErrorsDict } from "../../../../../../../global/utils/Utils";
import SolvTag from './../../../../../../../global/components/tag/SolvTag';
import ProgramMembersOffCanvas from "./ProgramMembersOffCanvas";


const {Title, Link} = Typography;

const StyledAlertInfo = styled(Alert)`
    background-color: white;
    border-color: ${neutral_6};
    border-radius: 8px;
    color: black;

    .ant-alert-message {
        font-size: 14px;
        font-weight: 500;
    }

    .ant-alert-description {
        font-size: 12px;
        font-weight: 400;
        color: ${secondary_color}
    }
`

export default function ProgramMembers({program}) {

    const [showMemberDrawer, setShowMemberDrawer] = useState(false)
    const [openDrawerProfile, setOpenDrawerProfile] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [updateMember, setUpdateMember] = useState();
    const [userId, setUserId] = useState();
    const {t} = useTranslation();
    const [search, setSearch] = useState("");
    const {
        tableData, 
        tableParams, 
        isLoading: isLoadingTable, 
        resetTable,
        handleTableChange
    } = useTable({
        url: `programs/${program.id}/members_with_invites`,
        waitExternalLoading: !program.id,
        hasPagination: true,
        body: {},
        search,
        pageSize: 10
    })


    useEffect(() => {
        if (!showMemberDrawer) setUpdateMember(null)
    }, [showMemberDrawer])


    const columns = [
        {
            title: t("Name"),
            dataIndex: ["user_client","user","first_name"],
            sorter:'user_client__user__first_name',
            key: "name",
            render: (value, record) => (
                <Link href="#" onClick={()=>handleOpenProfileDrawer(record)}>{value}</Link>
            ) 
        },
        {
            title: "Status",
            dataIndex: "status",
            sorter:'user_client__is_active',
            key: "status",
            render: (value, record) => {

                if (!value) return '-'
                return <SolvTag
                    color={get_color_status(value)}>{t(value)}</SolvTag>
            }
        },
        {
            title: "Email",
            sorter:'user_client__user__email',
            dataIndex: "user_client.user.email",
            key: "email",
        },
        {
            title: t("Permission"),
            dataIndex: "permission_user_client",
            key: "permission",
            render: (value, record) => {

                const newValues = {}

                value.forEach((value) => {
                    if (value === 2) {
                        newValues['is_program_manager'] = true
                    } else if (value === 3) {
                        newValues['is_program_evaluator'] = true
                    }
                })
                return (

                    <div style={{
                        display: "flex",
                        gap: 8,
                        alignItems: 'center'
                    }}>

                        <PermissionTagsList permissions={newValues}/>
                    </div>
                );
            },
        },
        {
            title: t("Actions"),
            dataIndex: "action",
            key: "action",
            render: (_, record) => (
                <Space>
                   <Button loading={isLoading}
                            onClick={() => {
                                setUpdateMember(record)
                                setShowMemberDrawer(true)
                            }}
                            type="link">{t("Update")}</Button>
                    <Button loading={isLoading}
                            onClick={() => handleDelete(record)}
                            type="link">{t("Delete")}</Button>
                </Space>


            ),
        },]

    async function handleDelete(record) {


        Modal.confirm({
                icon: <ExclamationCircleOutlined/>,
                content: t("Do you really want to delete this member?"),
                onOk: async () => {
                    try {
                        setIsLoading(true)
                        await api.delete(`members/${record.id}`);
                        resetTable();
                        ToastNotification(t(ConstantsMessage.SUCCESS))
                    } catch (error) {
                        BuildFieldErrorsDict(error)
                    } finally {
                        setIsLoading(false)
                    }
                },
                okText: t('Yes'),
                cancelText: t('No')

            }
        )

    }

    const handleOpenProfileDrawer = (record) => {
        const id = record.user_client?.user.id
        setUserId(id);
        setOpenDrawerProfile(true);
    }


    return (
        <Row gutter={[24,24]}>
            <Col span={24}>
                <StyledAlertInfo
                    message={t("Manage program managers and evaluators")}
                    description={t("Add managers and evaluators and choose which stage each one will participate in. If automatic allocation is enabled, applications will automatically be routed to reviewers once the review stage begins.")}
                    type="info"
                />         
            </Col>
            <Col span={24}>
                <CardContainer bordered={false} style={{padding: '16px 8px'}}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={12}>
                        <Title level={5}>{t("Program organizers")}</Title>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Row gutter={16}>
                                <Col flex={'1'}>
                                    <Button htmlType="submit" hidden disabled></Button>
                                    <Search onSearch={(value) => setSearch(value)} allowClear/>
                                </Col>
                                <Col>
                                    <Button
                                        loading={isLoading}
                                        icon={<PlusOutlined/>}
                                        onClick={() => setShowMemberDrawer(true)}
                                        data-cy="new-member-button"> {t("New member")}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24}>
                            <Table dataSource={tableData}
                                onChange={handleTableChange}
                                loading={isLoadingTable}
                                tableParams={tableParams}
                                columns={columns}/>
                        </Col>
                    </Row>

                </CardContainer>
                <ProgramMembersOffCanvas 
                    setOpen={setShowMemberDrawer}
                    resetTable={resetTable}
                    program={program}
                    open={showMemberDrawer}
                    updateMember={updateMember}
                />
                <DrawerProfileUser
                    id={userId}
                    open={openDrawerProfile}
                    setOpen={setOpenDrawerProfile}
                />
            </Col>
        </Row>
    )
}