import React, {useEffect, useState} from 'react';

import useDatabase from "./hooks/useDatabase";
import useTable from "../../hooks/useTable";
import Table from "../../components/data_display/Table";
import {Button, Card, Flex, Form as AntForm, Input, Radio, Select, Tooltip, Typography} from "antd";
import {DownloadOutlined, InfoCircleOutlined} from "@ant-design/icons";
import FilterSettingsDrawer from "./components/FilterSettingsDrawer";
import {CSVLink} from "react-csv";
import useExportDatabase from "./hooks/useExportDatabase";
import {useTranslation} from "react-i18next";
import ColumnSettingsDrawer from "./components/ColumnSettingsDrawer";
import {useUserContext} from "../../context/UserContext";
import {useHistory, useLocation} from "react-router-dom";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import styled from "styled-components";
import TruncateText from "../../components/responsive/TruncateText";
import RemixIcon from "../../components/data_display/RemixIcon";
import useDefaultGetRequest from "../../hooks/useDefaultGetRequest";
import DynamicDropdown from "../../components/data_entry/DynamicDropdown";
import FilterButton from "../../components/button/FilterButton";


const {Text} = Typography

const StyledText = styled(Text)`
    color: ${({$selected}) => $selected && "var(--primary_btn_color)"};
`

function setupColsByLocalStorage(localStorageColumns, columns) {
    if (!localStorageColumns) return false;
    const allColumns = localStorageColumns;
    columns?.forEach((col)=>{
        if (allColumns.find((c)=>c.key === col.key) === -1) {
            allColumns.push(col);
        }
    })
    return (
        allColumns.map(loCo => {
            let matchingItem = columns?.find(col => col.key === loCo.key);
            if (matchingItem) {
                matchingItem.visible = loCo.visible;
                return matchingItem;
            }
            return null;
        }).filter(Boolean)
    )
}


function DatabaseSubLinksRadio({subLinks, resetAllStates}) {
    const history = useHistory()
    const location = useLocation();

    const handleRadioChange = (event) => {
        resetAllStates()
        history.push(event.target.value)
    }

    return (

        <Radio.Group onChange={handleRadioChange} value={location.pathname}>
            <Flex flex={1} justify={"start"}>
                {subLinks?.map(item => {
                    return (
                        <Radio.Button value={item.link}>
                            <TruncateText>
                                <StyledText $selected={item.link === location.pathname}>
                                    {item.label}
                                </StyledText>
                            </TruncateText>
                        </Radio.Button>
                    )
                })}
            </Flex>
        </Radio.Group>
    )
}

function DatabaseHistoricSelect({handleUpdateExtraParams}) {
    const {t} = useTranslation()
    return (
        <Flex style={{marginRight: -10}}>
            <Tooltip
                title={t("Choose between seeing the active applications in the phase or the complete history with all the applications.")}>
                <InfoCircleOutlined/>
            </Tooltip>
            <Select
                defaultValue={false}
                onChange={(value) => handleUpdateExtraParams({historic: value})}
                variant="borderless"
                dropdownStyle={{minWidth: 150}}
                options={[
                    {value: false, label: t("Actives in phase")},
                    {value: true, label: t("Historic")},
                ]}
            />
        </Flex>
    )
}

function DatabaseSettingsComponents({setSettingsOpen, filterForm, handleExport, setSearch, isExportLoading}) {
    const {t} = useTranslation()
    const {isDesktop} = useSystemBreakpoint()

    return (
        <Flex gap={8} style={{width: "100%"}}>
            <Input.Search
                data-cy={"search-database-input"}
                onSearch={(value) => setSearch(value)} allowClear
                style={isDesktop && {maxWidth: 300}}
            />

            {filterForm &&
                <Tooltip title={t("Filters")}>
                    <FilterButton onClick={() => setSettingsOpen("filter")}/>
                </Tooltip>
            }

            <Tooltip title={t("Select columns")}>
                <Button onClick={() => setSettingsOpen("columns")}>
                    <RemixIcon remixIconName={"ri-layout-column-line"}/>
                </Button>
            </Tooltip>

            <Tooltip title={t("Export")}>
                <Button loading={isExportLoading} onClick={handleExport}>
                    <RemixIcon remixIconName={"ri-download-cloud-line"}/>
                </Button>
            </Tooltip>

        </Flex>
    )
}

function ProgramPhasesDropdown({program, setCurrentPhase}) {

    const {t} = useTranslation()

    const {
        data: phases,
        // isLoading
    } = useDefaultGetRequest({
        url: `/programs/${program?.id}/phases`,
        makeRequest: !!program?.id,
        dataDefault: [],
        dependence: [program]
    })

    return (
        <DynamicDropdown
            title=""
            items={[
                {label: t("General"), key: 0, type: 0},
                ...(phases?.map(phase => ({
                    label: phase.title,
                    key: phase.id,
                    type: phase.type
                })) || []),
            ]}
            handleChange={({key, type}) => setCurrentPhase({id: key, type})}
        />)
}


export default function Database({name, scope, scopeObj, origin, setOrigin}) {

    const {t} = useTranslation()
    const {_user} = useUserContext()
    const {isDesktop} = useSystemBreakpoint()
    const [search, setSearch] = useState("")
    const [settingsOpen, setSettingsOpen] = useState("")
    const [firstLoad, setFirstLoad] = useState(true)
    const [projectColumns, setProjectColumns] = useState()
    const [form] = AntForm.useForm()

    const localStorageColumnsKey = scope ? `table-${scope}-${name}-user-${_user?.id}` : `table-${name}-user-${_user?.id}`

    let {
        columns, url, config, rowContentDrawer, onClick, handleOpenDrawer, handleUpdateExtraParams,
        handleUpdateFilter, filterForm, subLinks, requestData, setRequestData, fetchProjectColumns,
        extraBody, setExtraBody
    } = useDatabase({name, scope, scopeObj, setProjectColumns, origin})


    const body = requestData[name]?.body
    const extraParams = requestData[name]?.extraParams

    const [visibleColumns, setVisibleColumns] = useState(columns)

    const {tableData, setTableData, tableParams, handleTableChange, isLoading, handleRequestTable, order, resetAllStates} = useTable({
        url, search, config, forcePost: true, waitExternalLoading: true, extraParams, body:{...body, ...extraBody[name]}
    })

    const {
        handleExport,
        exportRef,
        exportHeaders,
        exportData,
        exportFileName,
        isExporting,
        handleExportProgramData,
        isExportingProgramData,
    } = useExportDatabase({
        columns: visibleColumns, url, config, body: {...body, ...extraBody[name]},
        extraParams, name, scope, scopeObj, search, order, form
    })

    const handleFetchTable = () => {
        if (scope) {
            scopeObj?.id && void handleRequestTable()
            return
        }
        handleRequestTable()
    }

    useEffect(() => {
        const COLUMNS = name === "projects" ? projectColumns : columns
        const localStorageColumns = JSON.parse(localStorage.getItem(localStorageColumnsKey));
        setVisibleColumns(setupColsByLocalStorage(localStorageColumns, COLUMNS) || COLUMNS)
        setRequestData({body: {}, extraParams})
        setExtraBody({[name]: {}})
        form.resetFields()
    }, [name, projectColumns]);

    useEffect(() => {
        setTableData(undefined)
        // setExtraBody({})
        // setRequestData({body:{}, extraParams})
        void handleFetchTable()
        // console.log("fetch 0")
    }, [name, scope, scopeObj]);

    useEffect(() => {
        if(tableData){
            void handleFetchTable()
            // console.log("fetch 1")
        }
    }, [body, extraBody, search]);

    useEffect(() => {
        if (visibleColumns && projectColumns) {

            const COLUMNS = name === "projects" ? projectColumns : columns
            let localStorageColumns = JSON.parse(localStorage.getItem(localStorageColumnsKey))
            const canUpdate = !firstLoad || localStorageColumns?.length !== COLUMNS?.length

            if (canUpdate) {
                localStorageColumns = visibleColumns?.map(col => ({key: col.key, visible: col.visible}))
            }

            localStorage.setItem(localStorageColumnsKey, JSON.stringify(localStorageColumns));
            setFirstLoad(false)
        }

    }, [visibleColumns, projectColumns]);

    useEffect(() => {
        !projectColumns && !origin && void fetchProjectColumns(setProjectColumns)
    }, [projectColumns, name, scope, scopeObj]);

    return (
        <>
            <Card>
                <Flex vertical={true} gap={16}>
                    <Flex justify={scope ? "space-between" : "end"} vertical={!isDesktop} gap={16}>
                        {subLinks &&
                            <DatabaseSubLinksRadio subLinks={subLinks} resetAllStates={resetAllStates}/>
                        }
                        <Flex gap={8} vertical={!isDesktop} align="end" justify="end">
                            {name === "programs" &&
                                // <ProgramPhasesDropdown program={scopeObj} currentPhase={currentPhase} setCurrentPhase={setCurrentPhase}/>
                                <DynamicDropdown
                                    title=""
                                    items={[
                                        {label: t("Actives"), key: "1", value: ["active"]},
                                        {label: t("Canceled/Archived"), key: "2", value: ["inactive", "archived"]},
                                    ]}
                                    handleChange={({value}) => {
                                        form.resetFields()
                                        setExtraBody(
                                            {[name]: {...extraBody?.[name], "status__in": value}}
                                        )
                                    }}
                                />
                            }
                            
                            {scope === "phases" &&
                                <DatabaseHistoricSelect handleUpdateExtraParams={handleUpdateExtraParams} extraParams={extraParams}/>
                            }
                            <DatabaseSettingsComponents
                                setSettingsOpen={setSettingsOpen}
                                filterForm={filterForm}
                                handleExport={handleExport}
                                setSearch={setSearch}
                                isExportLoading={isExporting}
                            />
                            {scope === "programs" && name === "application" &&
                                <Button icon={<DownloadOutlined />} onClick={handleExportProgramData} loading={isExportingProgramData}>{t("Export program data")}</Button>
                            }
                        </Flex>
                    </Flex>
                    <Table
                        columns={visibleColumns?.filter(col => col.visible === true) || []}
                        dataSource={tableData}
                        tableParams={tableParams}
                        onChange={handleTableChange}
                        loading={isLoading && !!visibleColumns}
                        size="middle"
                        onRowClick={(row) => onClick ? onClick(row) : handleOpenDrawer(row)}
                    />

                </Flex>
            </Card>
            {visibleColumns &&
                <ColumnSettingsDrawer
                    open={settingsOpen === "columns"}
                    setOpen={setSettingsOpen}
                    columns={visibleColumns}
                    setColumns={setVisibleColumns}
                    initialColumns={columns}
                />
            }
            {filterForm && tableData &&
                <FilterSettingsDrawer open={settingsOpen === "filter"}
                                      setOpen={setSettingsOpen}
                                      handleUpdateFilter={handleUpdateFilter}
                                      form={form}
                                      filterForm={filterForm}
                />
            }
            {exportData &&
                <CSVLink headers={exportHeaders} data={exportData} target={"_blank"}
                         filename={exportFileName} ref={exportRef}
                />
            }
            {rowContentDrawer && rowContentDrawer()}
        </>
    );
}

