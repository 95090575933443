import React, {useEffect, useState} from "react";
import DefaultComponent from "./DefaultComponent";
import RadioButton from "../../../Form/RadioButton";

function RadioForm(props) {


    return (
        <DefaultComponent {...props} >
            {/*<RadioButton optionsDirection={'column'} id={props.id}*/}
            {/*             options={choicesOptions.map((choice) => ({*/}
            {/*                 label: choice.value,*/}
            {/*                 value: choice.value,*/}
            {/*                 id: choice.value*/}
            {/*             }))}/>*/}
        </DefaultComponent>
    )
}

export default RadioForm