import { useTranslation } from "react-i18next";
import TeamSelectorComponent from "./TeamSelectorComponent";
import useTeamSelector from "./hooks/useTeamSelector";
import WideSpace from "../../layout/WideSpace";

export default function TeamListManager({ displayTypes, disabled = false, enableEditorMode = false }) {
    const {teams, setTeams, fetchUserTeams} = useTeamSelector();
    const {t} = useTranslation();
    
    return (
        <WideSpace direction="vertical" size={24}>
            <TeamSelectorComponent
                disabled={disabled}
                editorMode={true}
                title={t("Teams")}
                displayTypes={["Team"]}
                teams={teams}
                setTeams={setTeams}
                fetchUserTeams={fetchUserTeams}
            />
            <TeamSelectorComponent
                disabled={disabled}
                editorMode={true}
                title={t("Companies")}
                displayTypes={["Company"]}
                teams={teams}
                setTeams={setTeams}
                fetchUserTeams={fetchUserTeams}
            />
        </WideSpace>
    )
}
