import styled from "styled-components";
import {Space} from "antd";
import React from "react";

const StyledLimitedSpace = styled(Space)`
  max-width: 100%;
  display: flex;
  flex-wrap: ${({flexWrap})=>flexWrap ? "wrap" : "nowrap"};
  overflow: hidden;

  // ::before {
  //   content: " ";
  //   display: block;
  // }
  
  .ant-space-item {
    /* overflow: hidden; TODO: verificar se há algum erro com esse overflow */
    display: inline-grid;
  }

  .ant-space-item:has(.keep-width) {
    display: table;
  }
  
`


export default function LimitedSpace({children, flexWrap=true, ...props}) {
    return <StyledLimitedSpace flexWrap={flexWrap} {...props}>{children}</StyledLimitedSpace>
}