import React, {useEffect, useMemo, useRef, useState} from 'react';
import api from "../../../global/services/api";


import * as ConstantsMessage from "../../../global/utils/fileWithMessage";
import {
    BuildFieldErrorsDict,
} from "../../../global/utils/Utils";

import {useThemeContext} from "../../../global/context/ThemeContext";
import {useTranslation} from "react-i18next";
import {
    ToastNotification
} from "../../../global/components/feedback/ToastNotification";
import {Col, Row} from "antd";
import Form from "../../../global/components/data_entry/form/Form";
import {Form as FormAntd} from 'antd';
import {
    convertToFormData,
    getFiles
} from "../../../modules/project/utils/utils";
import SectionEnvironmentApperance
    from "./components/SectionEnvironmentApperance";
import SectionEnvironmentApperanceLogin
    from "./components/SectionEnvironmentApperanceLogin";
import {
    useLayoutContext
} from "../../../modules/project/components/layout/context/LayoutContext";


const FORM_NAMES = {
    LOGIN_LOGO_COVER: 'login_logo_cover',
    LOGIN_IMG_COVER: 'login_img_cover',
    LOGIN_TEXT_COLOR_BUTTON: 'login_text_color_button',
    LOGIN_BACKGROUND_BUTTON: 'login_background_button',
    IMG_ACTION_BAR: 'img_action_bar',
    FAVICON: 'favicon',
    IMG_PROFILE: 'img_profile',
    ACTIVE_MENU_COLOR: 'active_menu_color',
    TEXT_COLOR_MENU: 'text_color_menu',
    BACKGROUND_MENU: 'background_menu',
    PRIMARY_BTN_TEXT_COLOR: 'primary_btn_text_color',
    PRIMARY_BTN_COLOR: 'primary_btn_color',
}

export default function PageEnvironmentApperance() {
    const [isLoading, setIsLoading] = useState(false)
    const {t} = useTranslation()
    const [form] = FormAntd.useForm();
    const {updateWindown} = useLayoutContext()
    const {themeConfig} = useThemeContext()


    const handleSubmit = async (d) => {

        try {
            setIsLoading(true);
            const data = convertToFormData(await form.getFieldsValue())

            if (!data[FORM_NAMES.LOGIN_IMG_COVER]?.path) {

                data.append(`${FORM_NAMES.LOGIN_IMG_COVER}_upload`, data.get(`${FORM_NAMES.LOGIN_IMG_COVER}[]`));
                delete data[`${FORM_NAMES.LOGIN_IMG_COVER}_upload[]`]
            }

            if (!data[FORM_NAMES.LOGIN_LOGO_COVER]?.path) {
                data.append(`${FORM_NAMES.LOGIN_LOGO_COVER}_upload`, data.get(`${FORM_NAMES.LOGIN_LOGO_COVER}[]`));
                delete data[`${FORM_NAMES.LOGIN_LOGO_COVER}_upload[]`]
            }

            if (!data[FORM_NAMES.IMG_ACTION_BAR]?.path) {

                data.append(`${FORM_NAMES.IMG_ACTION_BAR}_upload`, data.get(`${FORM_NAMES.IMG_ACTION_BAR}[]`));
                delete data[`${FORM_NAMES.IMG_ACTION_BAR}_upload[]`]
            }

            if (!data[FORM_NAMES.FAVICON]?.path) {
                data.append(`${FORM_NAMES.FAVICON}_upload`, data.get(`${FORM_NAMES.FAVICON}[]`));
                delete data[`${FORM_NAMES.FAVICON}_upload[]`]
            }
            if (!data[FORM_NAMES.IMG_PROFILE]?.path) {
                data.append(`${FORM_NAMES.IMG_PROFILE}_upload`, data.get(`${FORM_NAMES.IMG_PROFILE}[]`));
                delete data[`${FORM_NAMES.IMG_PROFILE}_upload[]`]
            }

            await api.patch(`/theme/${themeConfig.id}`, data);

            ToastNotification(t(ConstantsMessage.SUCCESS));
            updateWindown()
        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields,)

        } finally {
            setIsLoading(false);
        }
    };


    const initialValues = useMemo(() => {

        if (themeConfig) {

            return {
                ...themeConfig,
                [FORM_NAMES.LOGIN_IMG_COVER]: themeConfig[FORM_NAMES.LOGIN_IMG_COVER] && getFiles([{
                    name: "Download",
                    path: themeConfig[FORM_NAMES.LOGIN_IMG_COVER]
                }]),
                [FORM_NAMES.LOGIN_LOGO_COVER]: themeConfig[FORM_NAMES.LOGIN_LOGO_COVER] && getFiles([{
                    name: "Download",
                    path: themeConfig[FORM_NAMES.LOGIN_LOGO_COVER]
                }]),

                [FORM_NAMES.FAVICON]: themeConfig[FORM_NAMES.FAVICON] && getFiles([{
                    name: "Download",
                    path: themeConfig[FORM_NAMES.FAVICON]
                }]),
                [FORM_NAMES.IMG_ACTION_BAR]: themeConfig[FORM_NAMES.IMG_ACTION_BAR] && getFiles([{
                    name: "Download",
                    path: themeConfig[FORM_NAMES.IMG_ACTION_BAR]
                }]),
                [FORM_NAMES.IMG_PROFILE]: themeConfig[FORM_NAMES.IMG_PROFILE] && getFiles([{
                    name: "Download",
                    path: themeConfig[FORM_NAMES.IMG_PROFILE]
                }]),
            }
        }
        return null
    }, [themeConfig])

    return (
        <Form disabled={isLoading} onFinish={handleSubmit} form={form}
              initialValues={initialValues}>
            <Row gutter={[24, 24]}>
                <Col xs={24}>
                    <SectionEnvironmentApperanceLogin form={form}
                                                      FORM_NAMES={FORM_NAMES}/>
                </Col>
                <Col xs={24}>

                    <SectionEnvironmentApperance form={form}
                                                 isLoading={isLoading}
                                                 FORM_NAMES={FORM_NAMES}/>
                </Col>
            </Row>
        </Form>

    );
}