import React, {useEffect} from 'react'
import styled from 'styled-components'
import InputContainer from "../InputContainer";
import Label from "../Label";
import MessageError from "../MessageError";
import {ReactComponent as PlusIcon} from "../../../assets/Icon/plus-square-regular.svg"

import {
    FileValidate,
    limit_image
} from "../../../global/utils/FileValidate";
import LimitUploadInfo from "../../LimitUploadInfo";
import {useTranslation} from "react-i18next";
import {ToastNotification} from "../../../global/components/feedback/ToastNotification";

const StyledImageContainer = styled.label`
  width: 200px;
  height: 200px;
  border-radius: 4px;
  border: 1px dashed #6E6E6E;
  background-color: #F4F4F4;
  position: relative;
  cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
  transition: 0.5s;
  overflow: hidden;
  ${({disabled}) => !disabled && (
          ` &:hover{
            background-color: #6E6E6E;
            border-color: #fff;
        }
        
        &:hover p{
            color: #fff;
        }
        
        svg path{
            transition:0.5s;
        }
        
        &:hover svg path{
            fill: #fff;
        }`
  )}


`

const StyledImagePreview = styled.img`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  object-fit: cover;
  position: absolute;
  background-color: #F4F4F4;
  top: 0;
  left: 0;
  z-index: 0;
  visibility: ${({value}) => (value && value !== '' && value ? 'visible' : 'hidden')};
`

const StyledLabelInfo = styled.p`
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  text-align: center;
  line-height: 22px;
  color: #6E6E6E;
  width: 139px;
  margin: 20px auto 0;
  transition: 0.5s;
`

const _typesAccept = [
    'image/png',
    'image/jpg',
    'image/jpeg',
]


export default function ImageInput(
    {
        elementDescription, id, required, name, label, messageError, onChange,
        value, disabled,
        typesAccept=_typesAccept,...props
    }) {
    const [imgPreview, setImgPreview] = React.useState(
        {
            img: value,
            name: name
        });
    const imagePreview = React.useRef()
    const {t} = useTranslation();

    useEffect(() => {
        if (value && typeof value === 'object') {
            convert_blob_to_base64(value)
        }

    }, [value])

    function convert_blob_to_base64(file) {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            // convert image file to base64 string
            setImgPreview({img: reader.result, name: file.name})
            imagePreview.current.style.visibility = 'visible'

        }, false);

        reader.readAsDataURL(file);
    }

    function handlePreviewImage({target}) {

        const file = target.files[0];
        if (file) {
            if (!FileValidate(file, 'image')) {
                ToastNotification(t("Maximum upload is ") + `${limit_image} MB`,'warning');
                target.value = ''
                return;
            }


            convert_blob_to_base64(file)
        }
    }

      function checkTypeAccepted({target}) {
        const file = target.files[0];
        return typesAccept.includes(file.type)

    }

    return (
        <InputContainer>
            {label &&
            <Label htmlFor={id} required={required}>{label} <LimitUploadInfo
                limit={limit_image}/></Label>}
            {elementDescription}
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <StyledImageContainer disabled={disabled}>
                    <input disabled={disabled} type={'file'} name={name}
                           data-value={value}
                           style={{display: 'none'}}
                           onChange={(event) => {
                                if (checkTypeAccepted(event)) {
                                   onChange && onChange(event);
                                   handlePreviewImage(event);
                               }else{
                                   ToastNotification(t('Invalid type'), 'error')
                               }
                           }}
                           accept="image/png, image/jpeg, image/png" {...props}/>
                    <PlusIcon
                        style={{margin: '53px auto 0', display: 'block',}}/>
                    <StyledLabelInfo>{t("Browse an image (jpg, jpeg, png)")}</StyledLabelInfo>
                    <StyledImagePreview ref={imagePreview} value={value}
                                        src={imgPreview.img}/>

                </StyledImageContainer>
                {messageError && <MessageError>{messageError}</MessageError>}
            </div>


        </InputContainer>
    )
}