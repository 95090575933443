import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';

const StyleDivContent = styled.div.attrs(() => ({
  className: 'select-count-list'
}))`
  text-align-last: ${({ aling }) => aling};
  font-size: ${({ font_size }) => font_size}px;
`


const text_aling = [
  'auto',
  'start',
  'end',
  'left',
  'right',
  'center',
  'justify'
];


function SelectCountList({count_selected= 0, count_total= 0, aling, margin, font_size = 11}) {
    const {t} = useTranslation();

    return (
        <StyleDivContent aling={aling} font_size={font_size} margin={margin}>{count_selected} {count_total > 0 ? t("of ") + count_total : '' } {t("selected")}</StyleDivContent>
    )
}

SelectCountList.propTypes = {
  aling: PropTypes.oneOf(text_aling).isRequired,
};

export default SelectCountList