import React, {useEffect, useState} from 'react';
import {Badge, Col, Form as FormAnt, Modal, Progress, Row, Space} from 'antd';
import {useTranslation} from "react-i18next";
import WideSpace from "../../../layout/WideSpace";
import styled from 'styled-components'
import {polar_green_6, primary_6, secondary_color} from "../../../../utils/Colors";
import ScoreEvaluationField from "./Fields/ScoreEvaluationField";
import {
    FlexContainer,
    QuestionContainer,
    QuestionTitle
} from "../../../authentication/StyledComponents";
import Form from "../Form";
import YesNoEvaluationField from "./Fields/YesNoEvaluationField";
import {BuildFieldErrorsDict} from "../../../../utils/Utils";
import api from "../../../../services/api";
import {convertToFormData} from "../../../../../modules/project/utils/utils";

import FixedFooter from "../../../layout/FixedFooter";
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    LoadingOutlined
} from "@ant-design/icons";
import moment from "moment-timezone";
import {useUserContext} from "../../../../context/UserContext";
import Button from "../../Button";
import ShowQuestionAnswer from "./Fields/ShowQuestionAnswer";
import {ASSESSMENT} from "../../../../utils/Constants";
import {
    useLayoutContext
} from "../../../../../modules/project/components/layout/context/LayoutContext";
import FeedbackField from "./Fields/FeedbackField";
import Empty from "../../../data_display/Empty";
import RemixIcon from '../../../data_display/RemixIcon';
import ApplicationDrawer from "../../../other/application/ApplicationDrawer/ApplicationDrawer";
import Flex from "../../../responsive/Flex";
import useEventListener from "../../../../hooks/useEventListener";


const Text = styled.span`
    font-size: 12px;
`

const StyledText = styled(Text)`
    color: ${secondary_color};
`

const StyledFlexContainer = styled(FlexContainer)`
    padding: 16px 32px;
    background: white;
    border-top: 1px solid rgba(0, 0, 0, .06);
`

const StyledSpace = styled(Space)`
    color: ${polar_green_6};

`


const FIELDS_NAMES = {
    BIG_TITLE: 'header',
    SMALL_TITLE: 'paragraph',
    FEEDBACK: 'feedback',
    SCORE_WITH_FEEDBACK: 'score_with_feedback',
    YES_NO: 'yes_no',
}


// Todo separar o autosave em um componente com todas suas funções
// Todo criar componentnes da listagem de respostas
// Todo talvez separar o factory


export default function EvaluationFormBuild({assessment, ...props}) {
    const {t} = useTranslation()
    const [form] = FormAnt.useForm()
    const {_user} = useUserContext()
    const {updateLayoutContent} = useLayoutContext()
    const [autoSaveMessage, setAutoSaveMessage] = useState()
    const [percent, setPercent] = useState(0)
    const [isLoadingAutoSave, setIsLoadingAutoSave] = useState()
    const [isLoadingSave, setIsLoadingSave] = useState()
    const [applicationDrawerOpen, setApplicationDrawerOpen] = useState(false)

    const requestCount = assessment?.application?.diligences?.length

    useEffect(() => {
        if (assessment) {
            startInitialValues()
            calculationPercent()
        }
    }, [assessment]);


    function startInitialValues() {
        const formatedInitialValues = {};
        assessment.form_evaluate_with_response?.forEach((item) => {
            function isType(type) {
                return item.type === type //so para facilitar no if
            }

            if (isType(FIELDS_NAMES.SCORE_WITH_FEEDBACK)) {

                formatedInitialValues[item.name] = {
                    type: FIELDS_NAMES.SCORE_WITH_FEEDBACK,
                    required: item.required,
                    show_feedback: item.show_feedback,
                    score: item.score ? Number(item.score) : null,
                    feedback: item[`feedback_${item.name}`],
                }
            }
            if (isType(FIELDS_NAMES.FEEDBACK)) {

                formatedInitialValues[item.name] = {
                    type: FIELDS_NAMES.FEEDBACK,
                    required: item.required,
                    feedback: item.value,
                }
            }

            if (isType(FIELDS_NAMES.YES_NO)) {
                formatedInitialValues[item.name] = {
                    type: FIELDS_NAMES.YES_NO,
                    value: item.value,
                    required: item.required,
                }
            }
        })

        form.setFieldsValue(formatedInitialValues)
    }

    function FACTORY_FIELD({type, ...field}) {

        switch (type) {
            case FIELDS_NAMES.SCORE_WITH_FEEDBACK:
                return (
                    <FormAnt.Item key={field.name} name={field.name} rules={[{
                        validator: async (rule, value) => {

                            if (field.required && value.score == null || (value.show_feedback && !value.feedback)) {
                                return Promise.reject()
                            }

                            return Promise.resolve();
                        }
                    }]}>
                        <ScoreEvaluationField
                            min={assessment.program_evaluation_scale_min}
                            max={assessment.program_evaluation_scale_max} {...field}/>
                    </FormAnt.Item>
                )
            case FIELDS_NAMES.FEEDBACK:
                return (
                    <FormAnt.Item key={field.name} name={field.name} rules={[{
                        validator: async (rule, value) => {
                            if (field.required && !value.feedback) {
                                return Promise.reject()

                            }
                            return Promise.resolve();
                        }
                    }]}>
                        <FeedbackField {...field}/>
                    </FormAnt.Item>
                )
            case FIELDS_NAMES.YES_NO:
                return (
                    <FormAnt.Item key={field.name} name={field.name} rules={[{
                        validator: async (rule, value) => {
                            if (field.required && !value.value) {
                                return Promise.reject()

                            }
                            return Promise.resolve();
                        }
                    }]}>
                        <YesNoEvaluationField {...field}/>
                    </FormAnt.Item>
                )

            default:
                return <FormAnt.Item key={field.name}><ShowQuestionAnswer
                    type={type} {...field}/></FormAnt.Item>
        }
    }

    async function getValuesForm() {
        let data = await form.getFieldsValue()
        const newData = {}
        Object.keys(data).forEach((key) => {

            function isType(type) {
                return data[key].type === type //so para facilitar no if
            }

            if (isType(FIELDS_NAMES.SCORE_WITH_FEEDBACK)) {
                newData[key] = data[key].score
                newData[`feedback_${key}`] = data[key][`feedback`]

            }

            if (isType(FIELDS_NAMES.FEEDBACK)) {
                newData[key] = data[key].feedback

            }

            if (isType(FIELDS_NAMES.YES_NO)) {
                newData[key] = data[key].value
            }
        })

        return convertToFormData(newData)
    }

    async function calculationPercent() {
        let data = await form.getFieldsValue()
        let requireds = Object.keys(data).filter((key) => data[key].required)
        let amountRequired = requireds.length
        if (amountRequired === 0) return 100


        let amountAnswered = 0
        requireds.forEach((key) => {

            function isType(type) {
                return data[key].type === type //so para facilitar no if
            }

            // debugger

            if (isType(FIELDS_NAMES.SCORE_WITH_FEEDBACK) && data[key].score != null) {
                if (data[key].show_feedback) {
                    if (data[key][`feedback`]) {
                        amountAnswered++
                    }
                } else {
                    amountAnswered++
                }
            }
            if (isType(FIELDS_NAMES.YES_NO) && data[key].value) {
                amountAnswered++
            }

            if (isType(FIELDS_NAMES.FEEDBACK) && data[key].feedback) {
                amountAnswered++
            }
        })

        setPercent(((amountAnswered / amountRequired) * 100).toFixed(2))

    }

    async function handleAutoSave() {
        try {
            setIsLoadingAutoSave(true)
            let data = await getValuesForm()

            const response = await api.post(`assessments/${assessment.id}/auto_save`, data, {version: 'v2'});
            setAutoSaveMessage(`${t("Saved at")} ${moment().tz(_user.time_zone).format('HH:mm')}`)
            calculationPercent()

        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, false)
        } finally {
            setIsLoadingAutoSave(false);
        }
    }

    async function handleSubmit() {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            title: t("Submit evaluation"),
            content: t("The evaluation cannot be edited after submission. Do you want to confirm shipping?"),
            onOk: async () => {
                try {
                    setIsLoadingSave(true)
                    let data = await getValuesForm()

                    const response = await api.post(`assessments/${assessment.id}/submit_evaluation`, data, {version: 'v2'});
                    updateLayoutContent()

                } catch (error) {
                    BuildFieldErrorsDict(error, form.setFields, false)
                } finally {
                    setIsLoadingSave(false);
                }
            },
            okButtonProps: {'data-cy': "confirm-submit-button"},
            okText: t('Yes'),
            cancelText: t('No')
        })

    }

    function canEvaluate() {
        return assessment.form_evaluate_with_response?.length > 0 && assessment.phase_open && assessment.status !== ASSESSMENT.SENDED && assessment.status !== ASSESSMENT.INVALID
    }


    return (<>

            <Form disabled={!canEvaluate() || isLoadingSave} form={form}
                  onValuesChange={handleAutoSave}
                  onFinish={handleSubmit} data-cy="evaluation-form">
                <Row style={{marginTop: 16, marginBottom: 64}}>

                    {assessment?.program_enable_evaluator_diligences &&
                        <Col xs={24} style={{marginBottom: 24}}>
                            <QuestionContainer onClick={() => setApplicationDrawerOpen(true)} style={{cursor:"pointer"}}>
                                <QuestionTitle strong>
                                    <Flex justify="space-between">
                                        <Flex>
                                            <RemixIcon remixIconName="ri-draft-line"/>
                                            {t("Requests")}
                                        </Flex>
                                        <Badge color={primary_6} count={requestCount} showZero/>
                                    </Flex>
                                </QuestionTitle>
                                <StyledText>{t("Click on the card and request or track a request for this application")}</StyledText>
                            </QuestionContainer>
                        </Col>
                    }

                    <Col xs={24} style={{marginBottom: 24}}>
                        <QuestionContainer>
                            <WideSpace direction={'vertical'}>
                                <QuestionTitle
                                    strong>{t('Title')}</QuestionTitle>
                                <Text
                                    strong>{assessment.application.name || '-'}</Text>
                                {assessment.application.challenge && <>
                                    <QuestionTitle
                                        strong>{t('Challenge')}</QuestionTitle>
                                    <Text
                                        strong>{assessment.application.challenge.name}</Text>
                                </>}
                            </WideSpace>
                        </QuestionContainer>
                    </Col>

                    {!assessment.form_evaluate_with_response ? <Col xs={24}><Empty/></Col>
                        : assessment.form_evaluate_with_response?.map((field) =>
                        <Col xs={24} key={field.name}>
                            {FACTORY_FIELD(field)}
                        </Col>)}

                </Row>
            </Form>
            <FixedFooter>
                <StyledFlexContainer justify={'end'}>
                    {isLoadingAutoSave ?
                        <LoadingOutlined/> : autoSaveMessage && <StyledSpace>
                        <CheckCircleOutlined/>
                        <span>{autoSaveMessage}</span>
                    </StyledSpace>}
                    <Progress percent={percent} style={{
                        width: 227,
                        marginLeft: 8,

                    }}/>
                    {canEvaluate() && <Button style={{marginLeft: 24}}
                                              loading={isLoadingSave}
                                              onClick={form.submit}
                                              data-cy="submit-evaluation-button">{t('Submit evaluation')}</Button>}
                </StyledFlexContainer>
            </FixedFooter>

            <ApplicationDrawer
                evaluatorRequestMode={true}
                solutionId={assessment?.application.id}
                open={applicationDrawerOpen}
                setOpen={setApplicationDrawerOpen}
            />

        </>
    )
        ;
}
