import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ToastNotification } from '../../../components/feedback/ToastNotification';
import api from "../../../services/api";
import { Card, Typography, Form as FormAnt, Col, Row, Input } from "antd";
import Button from '../../../components/data_entry/Button';
import { useTranslation } from 'react-i18next';
import { BuildFieldErrorsDict } from '../../../utils/Utils';

const { Text } = Typography;

export default function EmailChangeForm() {
  const { t } = useTranslation();
  const [form, setForm] = FormAnt.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isCodeChangeEmail, setIsCodeChangeEmail] = useState(false);
  const [tokenNewEmail, setTokenNewEmail] = useState('');
  const [tokenOwnerCurrent, setTokenOwnerCurrent] = useState('');
  const [previousEmail, setPreviousEmail] = useState('');
  const [userEmail, setUserEmail] = useState('');

  let { id } = useParams();

  const getUser = async () => {
    try {
      const response = await api.get('/auth/user/');
      setUserEmail(response.data.email);

    } catch (error) {
      BuildFieldErrorsDict(error, null, false);
    }
  };

  useEffect(() => {
    const fetchValue = async () => {
      await getUser()
    }
    fetchValue()
  }, [id]);


  const handleEmailChange = async () => {
    try {
      setIsLoading(true);

      const values = await form.validateFields();

      setPreviousEmail(values.email);

      const data = {
        email: values.email,
      };

      // console.log('Dados para Alteração de E-mail:', data);

      const response = await api.post(`/auth/change_email_user`, data);

      if (response.status === 200) {
        ToastNotification(t('Codes sent successfully'), 'success');
        setIsCodeChangeEmail(true);
        setTokenNewEmail('');
        setTokenOwnerCurrent('');
      } else {
        ToastNotification(t('Failed to save. Please try again.'), 'error');
      }
    } catch (error) {
      console.error(error);

      let errorMessage = t('Failed to save. Please try again.');

      if (error.response && error.response.data) {
        const { errors, code } = error.response.data;

        if (errors) {
          if (typeof errors === 'string') {
            errorMessage = errors;
          } else if (typeof errors === 'object') {
            const emailError = errors.email;
            if (emailError) {
              errorMessage = Array.isArray(emailError) ? emailError[0] : emailError;
            }
          }
        } else if (code === 'email_already_used') {
          errorMessage = t('Email already used');
        }
      }

      ToastNotification(t(errorMessage), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  async function handleConsumeChangeEmail(values) {
    try {
      setIsLoading(true);

      const data = {
        email: previousEmail,
        token_new_email: values.token_new_email,
        token_owner_current: values.token_owner_current,
      };

      // console.log('Dados para Validação de Tokens:', data);
      const response = await api.post(`/auth/validation_change_email_user/`, data);

      if (response.status === 200) {
        ToastNotification(t('Saved successfully'), 'success');
        window.location.reload();

      } else {
        ToastNotification(t('Failed to save. Please try again.'), 'error');
      }

    } catch (error) {
      console.error(error);
      ToastNotification(t('Failed to save. Please try again.'), 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFormFinish = async (values) => {
    try {
      setIsLoading(true);

      if (isCodeChangeEmail) {
        await handleConsumeChangeEmail(values);
      } else {
        await handleEmailChange(values);
      }
    } catch (error) {
      console.error('Failed to save. Please try again.', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card
      title={<Text style={{ fontSize: 16, fontWeight: 500 }}>{t('Change email')}</Text>}
    >
      <FormAnt form={form} onFinish={handleFormFinish}>
        <Row gutter={16}>
          {!isCodeChangeEmail && (
            <Col span={24}>
              <FormAnt.Item
                name="email"
                label={t('New email')}
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: t('This field is required'),
                  },
                ]}
              >
                <Input placeholder={t('Type your email here...')} />
              </FormAnt.Item>
            </Col>
          )}

          {isCodeChangeEmail && (
            <>
              <Col span={12}>
                <FormAnt.Item
                  name="token_owner_current"
                  label={t("Code in",{userEmail})}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the code sent to your old email'),
                    },
                  ]}
                  onChange={(e) => setTokenNewEmail(e.target.value)}
                  value={tokenNewEmail}
                >
                  <Input
                    placeholder={t("Code sent to old email")}
                  />
                </FormAnt.Item>
              </Col>
              <Col span={12}>
                <FormAnt.Item
                  name="token_new_email"
                  label={t("Code in ",{previousEmail})}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: t('Please enter the code sent to your new email'),
                    },
                  ]}
                  onChange={(e) => setTokenOwnerCurrent(e.target.value)}
                  value={tokenOwnerCurrent}
                >
                  <Input
                    placeholder={t("Code sent to new email")}
                  />

                </FormAnt.Item>
              </Col>

            </>
          )}
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <div style={{ textAlign: 'end', marginTop: '16px' }}>
              <Button type="primary" htmlType="submit">
                {t('Save')}
              </Button>
            </div>
          </Col>
        </Row>
      </FormAnt>
    </Card>
  );
}
