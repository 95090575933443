import { AppstoreOutlined, CalendarOutlined, UserOutlined } from "@ant-design/icons";
import { Card, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ToastNotification } from "../../../../../global/components/feedback/ToastNotification";
import UserAvatar from "../../../../../global/components/misc/UserAvatar";
import api from "../../../../../global/services/api";
import { DateFormatByUserTimezone } from "../../../../../global/utils/dates";


const {Text, Title} = Typography;

const TextStyle = styled(Text)`
    font-size: 12px;
`;

const renderInfo = (icon, label, value) => {
    return (
        <Space size={4}>
            <TextStyle strong>{icon} {label}:</TextStyle>
            {value}
        </Space>
    )
}

export default function OriginatingApplicationCard({originatingApplicationId, challengeId}) {

    const {t} = useTranslation()
    const [originatingApplication, setOriginatingApplication] = useState()

    const fetchOriginatingApplication = async () => {
        try {
            const url = `/applications/${originatingApplicationId}/vinculated_application`
            const config = challengeId && {params: {challenge: challengeId}}
            const {data} = await api.get(url, config)
            // console.log(data)
            setOriginatingApplication(data)
        } catch (error) {
            ToastNotification(error.response.data.errors, 'error')
        } finally {

        }
    }

    useEffect(() => {
        originatingApplicationId && void fetchOriginatingApplication()
    }, [originatingApplicationId]);

    return (
        <Card>
            <Space direction="vertical">
                <Text strong>{t("Origin of the challenge")}</Text>
                <Title level={5}>{originatingApplication?.name}</Title>
                {renderInfo(<UserOutlined/>, t("Application Author"),
                    <>
                        <UserAvatar size={16} data={{
                            picture: originatingApplication?.team?.picture,
                            name: originatingApplication?.team?.name
                        }}/>
                        <TextStyle>{originatingApplication?.team?.name}</TextStyle>
                    </>
                )}
                {renderInfo(<AppstoreOutlined />, t("Source program"), <TextStyle>{originatingApplication?.program.name}</TextStyle>)}

                {originatingApplication?.vinculation_date && renderInfo(
                    <CalendarOutlined/>, t("Vinculation Date"), <TextStyle>{DateFormatByUserTimezone(originatingApplication?.vinculation_date)}</TextStyle>)
                }
            </Space>
        </Card>
    );
}

