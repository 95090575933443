import React from 'react';

import {useTranslation} from "react-i18next";
import {CardContainer} from "../../../../global/components/misc/CardContainer";
import {Col, Row, Space, Typography} from "antd";
import TooltipInformation
    from "../../../../global/components/data_display/TooltipInformation";
import ColorPickerFormField
    from "../../../../global/components/data_entry/form/ColorPickerFormField";
import {ACCEPT_IMAGE_FORMATS} from "../../../../global/utils/Constants";
import AttachmentFormField
    from "../../../../global/components/data_entry/form/AttachmentFormField";
import LoginPreview from "./LoginPreview";
import WideSpace from "../../../../global/components/layout/WideSpace";


export default function SectionEnvironmentApperanceLogin({
                                                             FORM_NAMES,
                                                             form
                                                         }) {

    const {t} = useTranslation()


    return (
        <CardContainer>

            <Row>
                <Col xs={{span: 24, order: 2}}
                     lg={{span: 6, order: 1}}>
                    <Row>
                        <Col xs={24}>
                            <Typography.Title
                                level={5}>Login</Typography.Title>
                            <Typography.Title level={5}><Space
                                size={4}>{t('Colors')}
                             </Space>
                            </Typography.Title>
                        </Col>
                        <Col xs={24}>
                            <WideSpace size={24} align="start">
                                <ColorPickerFormField
                                    form={{
                                        name: FORM_NAMES.LOGIN_BACKGROUND_BUTTON
                                    }}
                                    label={t('Button color')}/>
                                <ColorPickerFormField
                                    form={{
                                        name: FORM_NAMES.LOGIN_TEXT_COLOR_BUTTON
                                    }}
                                    label={t('Button color text')}/>

                            </WideSpace>

                        </Col>
                        <Col xs={24}>
                            <AttachmentFormField
                                accept={ACCEPT_IMAGE_FORMATS}
                                maxCount={1} MAX_SIZE={3}
                                // removeAction={()=>form.setFieldValue("profile_upload", undefined)}
                                form={{
                                    name: FORM_NAMES.LOGIN_LOGO_COVER,
                                    label: <Space
                                        size={4} align={'center'}
                                        wrap>{t('Environment Avatar')}
                                       <Typography.Text
                                            type={'secondary'}>(
                                            240x240
                                            px)</Typography.Text></Space>
                                }}/>

                        </Col>
                        <Col xs={24}>

                            <AttachmentFormField
                                accept={ACCEPT_IMAGE_FORMATS}
                                maxCount={1} MAX_SIZE={3}
                                form={{
                                    name: FORM_NAMES.LOGIN_IMG_COVER,
                                    label: <Space
                                        size={4}>{t('Image')}
                                        <Typography.Text
                                            type={'secondary'}>(1080x1080px)</Typography.Text></Space>
                                }}/>

                        </Col>
                    </Row>
                </Col>
                <Col xs={{span: 24, order: 1}}
                     lg={{span: 18, order: 2}}>
                    <Typography.Title
                        level={5}>{t('Preview')}</Typography.Title>
                    <LoginPreview form={form}
                                  FORM_NAMES={FORM_NAMES}/>
                </Col>


            </Row>


        </CardContainer>

    );
}