import {AppstoreOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {Alert, Button, Card, Col, Empty, Form, Input, Modal, Row, Skeleton, Space, Typography,} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {useHistory, useLocation, useParams, useRouteMatch,} from "react-router-dom/cjs/react-router-dom.min";
import {useLayoutContext} from "../../../modules/project/components/layout/context/LayoutContext";
import ExtraHeader from "../../../modules/project/components/layout/ExtraHeader";
import {convertToFormData} from "../../../modules/project/utils/utils";
import ConfirmTimeoutButton from "../../components/button/ConfirmTimeoutButton";
import PhaseTag from "../../components/data_display/PhaseTag";
import RequestSelect from "../../components/data_entry/RequestSelect";
import TeamSelect from "../../components/data_entry/TeamSelector/TeamSelect";
import Loading from "../../components/feedback/Loading";
import FixedFooter from "../../components/layout/FixedFooter";
import WideSpace from "../../components/layout/WideSpace";
import EnvironmentLink from "../../components/link/EnvironmentLink";
import DisplayTextElements from "../../components/misc/DisplayTextElements";
import SolvCollapse from "../../components/misc/SolvCollapse";
import UserAvatar from "../../components/misc/UserAvatar";
import LimitedSpace from "../../components/responsive/LimitedSpace";
import TruncateText from "../../components/responsive/TruncateText";
import {useConfirmUnsavedChangesContext} from "../../context/ConfirmUnsavedChangesContext";
import {useThemeContext} from "../../context/ThemeContext";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import api from "../../services/api";
import {BuildFieldErrorsDict, getApplicationTitleConvention} from "../../utils/Utils";
import {DatetimeFormatByTimezone} from './../../utils/dates';
import ApplicationFormContentEditable from "./ApplicationFormContentEditable";
import ImageUploadField from "../../components/data_display/ImageUploadField";
import {useRules} from "../../hooks/useRules";
import {dust_red_4} from "../../utils/Colors";

const {confirm} = Modal;

const {Text} = Typography

export default function ApplicationFormPage() {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const [application, setApplication] = useState(undefined);
    const {program} = application || {};
    const [phaseForm, setPhaseForm] = useState(undefined);
    const {id} = useParams();
    const [isLoadingAction, setIsLoadingAction] = useState(true);
    const history = useHistory();
    const {openNotification} = useLayoutContext();
    const {isDesktop} = useSystemBreakpoint();
    const dateEnd = DatetimeFormatByTimezone(program?.first_phase_ending);
    // const applicationName = Form.useWatch('name', form);
    const {url} = useRouteMatch()
    const searchParams = new URLSearchParams(useLocation().search);
    const [reopenedApplication, setReopenedApplication] = useState(false);
    const [initalChange, setInitialChange] = useState(true);
    const {setOpenConfirmation} = useConfirmUnsavedChangesContext()
    const {IS_REQUIRED} = useRules();

    const {
        initializeLayout,
    } = useLayoutContext();
    const {themeConfig} = useThemeContext();

    const pageLayoutSettings = {
        extraHeaderContent: <>
            <ExtraHeader
                returnPath={"/home"}
                left={
                    <LimitedSpace flexWrap={false}>
                        <TruncateText className="keep-width">
                            {t("Form")}
                        </TruncateText>
                        {application?.current_phase &&
                            <PhaseTag phase={application.current_phase}/>}
                    </LimitedSpace>
                }
                right={
                    <EnvironmentLink clientUrl={program?.client_url}
                                     to={`/available-programs/detail/${program?.id}`}>
                        <LimitedSpace flexWrap={false}
                                      size={4}><AppstoreOutlined /> {isDesktop ? program?.name : ""}
                        </LimitedSpace>
                    </EnvironmentLink>
                }/>
        </>,
        navbarMenuItems: [
            {
                label: <Link to={url}>{t("Form")}</Link>,
                key: url
            },
        ]
    }

    const renderSubmissionType = (types) => {
        return types?.map((item) => t(item.name)).join(", ")
    }

    const formHasFields = () => {
        return (phaseForm?.form?.length >= 1)
    }

    const formIsVisible = () => {
        if (!formHasFields()) {
            return false
        }
        if (!application) {
            return null;
        }
        if (application.is_canceled) {
            return true;
        }
        if (program?.enable_application_editing || !application?.is_finished) {
            return true;
        }
        return false
    }

    const isEditingDisabled = () => {
        if (!application?.is_subscribing) {
            return true;
        }
        return application?.is_canceled || application.is_finished;
    }

    const areActionsDisabled = () => {
        if (application?.date_submited && !application?.logged_user_is_member) {
            return true;
        }
        return application?.is_canceled || isLoadingAction;
    }

    const hasLoadedPhaseForm = () => {
        return phaseForm !== undefined;
    }

    const fetchApplication = async () => {
        try {
            setApplication(undefined);
            const response = await api.get(`/applications/${id}`, {version: 'v2'});
            setApplication(response.data);
        } catch (error) {
            if (error.response?.status === 404) {
                history.push("/404");
            }
        }
    }

    const fetchPhaseForm = async () => {
        try {
            setIsLoadingAction(true);
            const response = await api.get(`/phases/${application.current_phase.id}/phase_form`);
            setPhaseForm(response.data);
        } catch (error) {
            setPhaseForm(null);
        } finally {
            setIsLoadingAction(false);
        }
    }


    const cancelApplication = async () => {
        try {
            // if (!isEditingDisabled()) {
            //     await api.post(`/applications/${application.id}/save`, form.getFieldsValue(), {version: "v2"});
            // }
            await api.post(`/applications/${application.id}/cancel`);
            resetShowChangeNotification()
            openNotification({
                type: "success",
                message: t("Application canceled successfuly.")
            });
            history.push('/home');
        } catch (error) {
            openNotification({
                type: "error",
                message: t("Error cancelling application.")
            });
        }
    }

    const getCleanedData = () => {
        const formValues = form.getFieldsValue(true);
        if (!formValues['team']) {
            formValues['team'] = application.team
        }
        formValues['banner'] = formValues['banner'][0]?.pk;
        const formData = convertToFormData(formValues, false);
        return formData;
    }

    const handleFormSave = async () => {
        try {
            // await form.validateFields();
            setIsLoadingAction(true);
            await api.patch(`v2/applications/${id}/reopen_form`, {});
            const formData = getCleanedData();
            await api.post(`/applications/${application.id}/save`, formData, {version: "v2"});
            resetShowChangeNotification()
            openNotification({
                type: "success",
                message: t("Application saved successfuly.")
            });
            setReopenedApplication(true);
        } catch (error) {
            if (error.response) {
                BuildFieldErrorsDict(error, form.setFields, false);
                openNotification({
                    type: "error",
                    message: error.response.data.errors
                });
            } else {
                openNotification({
                    type: "error",
                    message: t("Error saving application. Please check the form.")
                });
            }
        } finally {
            setIsLoadingAction(false);
        }
    }

    const getSubmissionMessage = () => {
        if (program?.enable_application_editing) {
            return t("editableSubmissionConfirmMessage", {
                dateEnd,
                interpolation: {escapeValue: false}
            })
        }
        return t("This form does not allow editing after submission. Do you want to submit?")
    }

    const submissionError = (error) => {
        if (error.response) {
            openNotification({
                type: "error",
                message: error.response.data.errors
            });
        } else {
            openNotification({
                type: "error",
                message: t("Error submitting application. Please check the form.")
            });
        }
    }

    const showConfirmApplicationReopened = () => {
        confirm({
            title: t("Application needs to be submitted"),
            icon: <ExclamationCircleOutlined/>,
            content: t(
                "The Form needs to be submitted to be valid. Applications that are not submitted upon reopening will be automatically excluded from the selection process.",
            ),
            okText: t('Got it'),
            okCancel: ''
        });
    }

    const showConfirmChangeApplicationTitle = () => {
        confirm({
            title: t("Application already has a title."),
            icon: <ExclamationCircleOutlined/>,
            content: t(
                "Do you want to replace the current title: \"{{currentTitle}}\" with the new title: \"{{newTitle}}\"?",
                {currentTitle: form.getFieldValue('name'), newTitle: searchParams.get('name')}
            ),
            okText: t("Yes"),
            cancelText: t("No"),
            onOk: handleChangeApplicationTitle
        });
    }

    const confirmApplicationSubmission = async () => {
        try {
            await form.validateFields();
            confirm({
                title: t("Do you want to submit the application?"),
                content: getSubmissionMessage(),
                okText: t("Submit Application"),
                width: 500,
                cancelText: t("No"),
                onOk: () => {
                    form.submit();
                },
            });
        } catch (error) {
            submissionError(error);
        }
    }

    function resetShowChangeNotification() {
        setOpenConfirmation(false)
        setInitialChange(true)
    }

    const handleChangeApplicationTitle = () => {
        form.setFieldsValue({name: searchParams.get("name")});
        searchParams.delete("name");
    }

    const handleApplicationSubmission = async (data) => {
        try {
            setIsLoadingAction(true);
            const formData = getCleanedData();
            await api.post(`/applications/${application.id}/save`, formData, {version: "v2"});
            resetShowChangeNotification()
            const response = await api.post(`/applications/${application.id}/submit_form`, {}, {version: "v2"});
            const protocol = response.data.protocol;
            history.push('/home');
            openNotification({
                type: "success",
                message: t("submittedApplication", {protocol})
            });
        } catch (error) {
            if (error.response) {
                BuildFieldErrorsDict(error, form.setFields, false)
            }
            submissionError(error);
        } finally {
            setIsLoadingAction(false);
        }
    }

    useEffect(() => {
        void fetchApplication();
        addEventListener("reloadApplication", fetchApplication);
        return () => {
            removeEventListener("reloadApplication", fetchApplication);
        }
    }, [id]);

    useEffect(() => {
        initializeLayout(pageLayoutSettings);
    }, [program, isDesktop])

    useEffect(() => {
        const selectedChallenge = Number.parseInt(searchParams.get("challenge")) || null;
        if (application) {
            // team = application.program.team_types_display
            form.setFieldsValue({
                challenge: application.challenge || selectedChallenge,
                team: application.team,
                name: application.name,
                banner: [{pk: application.banner, url: application.banner_url}]
            });
            void fetchPhaseForm();
        }
    }, [application]);
    
    useEffect(()=>{
        if (searchParams.get("reopen") === "true") {
            showConfirmApplicationReopened();
            searchParams.delete("reopen");
            history.push(`?${searchParams.toString()}`)
        }
    }, [id]);

    useEffect(() => {
        if (application) {
            if (searchParams.get("name") && form.getFieldValue("name") !== searchParams.get("name")){
                showConfirmChangeApplicationTitle();  
            }
            else if (searchParams.get("name")) {
                handleChangeApplicationTitle()
            }
        }
    }, [application]);

    useEffect(() => {
        if (reopenedApplication && !isLoadingAction) {
            setReopenedApplication(false);
            showConfirmApplicationReopened();
            dispatchEvent(new Event("reloadApplication"));
        }
    }, [reopenedApplication, isLoadingAction]);

    const timeLeftType = program?.days_left > 1 ? "success" : "danger";
    const hideTeamTypes = program?.team_types.length === 1 && program?.team_types.at(0).name == "Individual";

    return (
        <>
            {!application && <Loading/>}
            {application &&
                <Form form={form} layout="vertical" style={{display: "grid"}}
                      onFinish={handleApplicationSubmission}
                      onValuesChange={(value, values) => {
                          if (!initalChange) {
                              setOpenConfirmation(true)
                          }
                          setInitialChange(false)

                      }}
                      disabled={application.is_canceled || application.status === "grouped"}>
                    <WideSpace direction="vertical" size={32} style={{paddingBottom: "144px"}}>
                        <SolvCollapse active={true} locked={true}>
                            <WideSpace direction="vertical" size={16}>
                                <Space>
                                    <UserAvatar data={{
                                        username: program?.name,
                                        picture: program?.profile
                                    }}/>
                                    <Typography.Text
                                        style={{fontWeight: "700"}}>{program?.name || "-"}</Typography.Text>
                                </Space>
                                <Row>
                                    {program?.first_phase_ending &&
                                        <Col span={12}>
                                            <DisplayTextElements
                                                title={t("REGISTRATIONS UP UNTIL")}
                                                content={dateEnd}
                                                type={timeLeftType}/>
                                        </Col>}
                                    <Col span={12}>
                                        <DisplayTextElements
                                            title={t("TYPE OF SUBMISSION")}
                                            content={renderSubmissionType(program?.team_types)}
                                        />
                                    </Col>
                                </Row>
                            </WideSpace>
                        </SolvCollapse>

                        <Skeleton loading={!hasLoadedPhaseForm()}/>

                        {(hasLoadedPhaseForm() && formIsVisible()) &&
                            <>
                                {program?.has_challenges &&
                                    <div data-cy="challenge-collapse">
                                        <SolvCollapse
                                            title={
                                                <Text style={{fontSize:16}}>
                                                    <span style={{color:dust_red_4}}>*</span> {t("Select the Challenge")}
                                                </Text>
                                            }
                                            active={true} locked={true}
                                            bordered={true}>
                                            <Form.Item 
                                                    name="challenge"
                                                    style={{margin: 0}}
                                                    rules={[{
                                                        required: true,
                                                        message: t("This field is required")
                                                    }]}>
                                                <RequestSelect
                                                    url={`v2/programs/${program?.id}/challenge_list`}
                                                    labelField="name"
                                                    style={{display: "block"}}
                                                    placeholder={t("Select the challenge")}
                                                    allowClear={true}
                                                    disabled={isEditingDisabled()}
                                                    data-cy="challenge-select"
                                                />
                                            </Form.Item>
                                        </SolvCollapse>
                                    </div>
                                }

                                <Form.Item 
                                        name="team"
                                        style={{margin: 0}}
                                        hidden={hideTeamTypes}
                                        rules={[{
                                            required: true,
                                            message: t("This field is required")
                                        }]} validateTrigger="">
                                    <TeamSelect
                                        disabled={isEditingDisabled()}
                                        displayTypes={program?.team_types}
                                        enableFormAlert={!application?.is_finished}
                                    />
                                </Form.Item>

                                <div data-cy="form-collapse">
                                    <SolvCollapse title={t("Form")}
                                                  active={true}
                                                  locked={true}
                                                  bordered={true}>
                                        <Form.Item name="name"
                                                   label={t('application_name', {application: program?.application_name_display})}
                                                   rules={[{required: true}]}
                                                   style={{
                                                       flexDirection: "row-reverse",
                                                       fontWeight: 500
                                                   }}>
                                            <Input
                                                data-cy="name-input"/>
                                        </Form.Item>
                                        {["internal_innovation", "challenge_mapping"].includes(program.type) &&
                                            <ImageUploadField
                                                aspect={[16, 9]}
                                                width={24*16}
                                                height={24*9}
                                                form={{
                                                    name: "banner",
                                                    label: t("Application identifying image"),
                                                    help: "(png, jpeg, jpg) 3MB",
                                                    rules: program.programsettings?.application_banner_required ? [IS_REQUIRED]: []
                                                }}
                                            />
                                        }
                                        <ApplicationFormContentEditable
                                            form={form}
                                            hasIAChat={application.program.client_chat_ia_actived}
                                            phaseForm={phaseForm}
                                            application_id={application.id}
                                            application={application}/>
                                    </SolvCollapse>
                                </div>
                            </>
                        }

                        {(hasLoadedPhaseForm() && formIsVisible() === false) &&
                            <SolvCollapse active={true} locked={true}>
                                <Empty
                                    description={(application.is_finished && formHasFields()) ? t("You have already filled out this form.") : t("There is currently no form registered at this phase of the program. Try again at another time.")}>
                                    <Button type="primary"
                                            disabled={false}
                                            data-cy="back-to-homepage-button"><Link
                                        to="/home">{t("Back to Home Page")}</Link></Button>
                                </Empty>
                            </SolvCollapse>
                        }
                    </WideSpace>
                </Form>}

            <FixedFooter>
                {!application?.is_finished && <Alert message={<Typography.Text type="danger" strong>{t('Form not submitted')}</Typography.Text>} type="warning"/>}
                {formIsVisible() &&
                    <Card>
                        <Row justify="end">
                            {/*<Space>*/}
                            {/*    {isLoadingAction && <Spin/>}*/}
                            {/*    {!application?.is_finished && <Typography.Text*/}
                            {/*        type="danger">{t("Form not submitted")}</Typography.Text>}*/}
                            {/*</Space>*/}
                            <Space>
                                <ConfirmTimeoutButton content={<>
                                    <span>{t("Canceling the application will completely remove it from the selection process")}</span>
                                    <span>{t("Are you sure you want to withdraw from the process?")}</span>
                                    <Typography.Title strong level={5}
                                                      style={{color: 'red'}}>{t("This action cannot be reversed")}</Typography.Title></>}
                                                      danger type={'default'}
                                                      onConfim={cancelApplication}
                                                      disabled={areActionsDisabled()} okText={t("Give up")}/>

                                <Button type="default" onClick={handleFormSave}
                                        disabled={areActionsDisabled()}>{t("Save")}</Button>
                                {!application?.is_finished &&
                                    <Button type="primary"
                                            onClick={confirmApplicationSubmission}
                                            disabled={areActionsDisabled()}
                                            data-cy="submit-button">{t("Submit")}</Button>}
                            </Space>
                        </Row>
                    </Card>}
            </FixedFooter>
        </>
    );
}