import React from 'react'
import styled from 'styled-components'
import RequiredAsterisk from "../RequiredAsterisk";

const StyledLabel = styled.label`
  color: #949494;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  text-align: start;
  cursor: ${({htmlFor}) => htmlFor? 'pointer': ''};
`

export default function Label({htmlFor, required, ...props}) {

    return (
        <StyledLabel htmlFor={htmlFor} {...props}>{props.children}{required &&
        <RequiredAsterisk/>}</StyledLabel>
    )
}