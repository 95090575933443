import {Card, Empty} from "antd";
import EmptyIcon from "../../../assets/Icon/empty.svg";


function EmptyPageCard({children}) {
    return <Card>
        <Empty
            image={EmptyIcon}
            description={children}
        />
    </Card>;

}

export default EmptyPageCard;