import {Typography} from "antd";
import ExtraHeader from "../../../components/layout/ExtraHeader";
import DynamicDropdown from "../../../../../global/components/data_entry/DynamicDropdown";
import {useTranslation} from 'react-i18next';
import NoteCountButtonDrawer from "../../../../../global/components/other/note/NoteFormDrawer/NoteCountButtonDrawer";
import Flex from "../../../../../global/components/responsive/Flex";
import WideSpace from "../../../../../global/components/layout/WideSpace";

const {Text} = Typography;

function ProjectManagementExtraHeader({clientPhases, project, updatePhase, checkProjectPermissionContext}) {
    const {t} = useTranslation();
    const getCurrentPhase = () => {
        const currentProjectPhase = project && project["current_phase"];
        return currentProjectPhase?.phase?.key;
    }

    const currentPhase = getCurrentPhase();

    const handlePhaseChange = ({id}) => {
        updatePhase({phaseId: id});
    }

    return <ExtraHeader
        left={<Text>{project?.name}</Text>}
        right={
            clientPhases &&
                <Flex align="center" gap={8}>
                    {/* <DynamicDropdown
                        title={`${t("Phase")}:`}
                        items={clientPhases}
                        selectedKey={currentPhase}
                        handleChange={handlePhaseChange}
                        color="var(--font-default-color)"
                        data-cy="phases-dropdown"
                        disabled={!checkProjectPermissionContext("manager")}
                    /> */}
                    {project &&
                        <NoteCountButtonDrawer url={`/notes/fetch_note_by_project/${project?.id}`}/>
                    }
                </Flex>
        }

        returnPath={"/project-portfolio"}
    />;
}

export default ProjectManagementExtraHeader;