import React, {useEffect, useState} from "react";
import {LoadingOutlined} from "@ant-design/icons";
import {Alert, Col, Empty, Row, Space, Typography} from "antd";
import {useProjectManagementContext} from "../context/ProjectManagementContext";
import api from "../../../../../global/services/api";
import ProjectIndicatorDrawer from "../../../components/drawer/ProjectIndicatorDrawer";
import IndicatorProjectCard from "./IndicatorProjectCard";
import {useTranslation} from "react-i18next";
import TransparentCollapse from "../../../../../global/components/misc/TransparentCollapse";

const {Text} = Typography;

function ProjectIndicatorList() {
    const [showIndicatorOffCanvas, setShowIndicatorOffCanvas] = useState(false);
    const [currentUpdatingIndicatorResponse, setCurrentUpdatingIndicatorResponse] = useState(undefined);
    const [refreshIndicators, setRefreshIndicators] = useState(true);
    const {project} = useProjectManagementContext();
    const [indicatorResponseList, setIndicatorResponseList] = useState();
    const { t } = useTranslation();

    const fetchProjectIndicatorResponses = async () => {
        const response = await api.get(`/project_management/project/${project.id}/indicator_responses/main`);
        setIndicatorResponseList(response.data);
    };

    const handleShowIndicatorResponseUpdateCanvas = ({indicatorResponse}) => {
        setCurrentUpdatingIndicatorResponse(indicatorResponse);
        setShowIndicatorOffCanvas(true);
    };


    useEffect(() => {
        if (project && refreshIndicators) {
            void fetchProjectIndicatorResponses();
            setRefreshIndicators(false);
        }
    }, [project, refreshIndicators]);


    const getIndicatorType = ({indicatorResponse}) => {
        if (indicatorResponse["is_reached"]) {
            return undefined;
        }
        if (indicatorResponse["is_late"]) {
            return "warning";
        }
        if (indicatorResponse["is_blank"]) {
            return "error";
        }
        return undefined;
    };

    const renderIndicators = () => {
        if (indicatorResponseList && indicatorResponseList.length > 0) {
            return (
                <>
                    <Space direction="vertical" style={{display: "flex", paddingTop: 16}}>
                        <Row gutter={[16, 16]}>
                            {indicatorResponseList.map((indicatorResponse, key) => (
                                <Col lg={6} md={12} sm={12} xs={24} key={key} style={{display: "flex", flexDirection: "column"}}>
                                    <IndicatorProjectCard
                                        indicatorResponse={indicatorResponse}
                                        indicator={indicatorResponse.indicator}
                                        type={getIndicatorType({indicatorResponse})}
                                        onClick={() => handleShowIndicatorResponseUpdateCanvas({indicatorResponse})}
                                        data-cy={`indicator-card-${indicatorResponse.indicator.internal_code}`}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </Space>
                </>
            );
        }
        return <Empty/>;
    };

    return <>
        <TransparentCollapse header={t("Project Indicators")}>
            <Col xs={24}> 
                <Alert message={t('Enter the required indicator information')} type="warning"/>
            </Col>
            
            <ProjectIndicatorDrawer
                open={showIndicatorOffCanvas}
                onClose={() => setShowIndicatorOffCanvas(false)}
                currentUpdatingIndicatorResponse={currentUpdatingIndicatorResponse}
                setRefreshIndicators={setRefreshIndicators}
                refresh={() => setRefreshIndicators(true)}
            />
            {
                indicatorResponseList ? renderIndicators() : <LoadingOutlined/>
            }
        </TransparentCollapse>
    </>;
}

export default ProjectIndicatorList;