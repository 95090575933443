
import {Button} from "antd";
import {CopyOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import copy from 'copy-to-clipboard';
import {ToastNotification} from "../feedback/ToastNotification";
import React from "react";


export default function ClipboardButton({text, label='Copy', ...props}) {
    const {t} = useTranslation()

    function handleCopy() {
        copy(text)
        ToastNotification(t('Copied'))
    }

    return (
        <Button  icon={<CopyOutlined/>}
                onClick={handleCopy} {...props}>{label && t(label)}</Button>
    )
}