import React, {useMemo} from "react";
import useReactWebSocketReact from 'react-use-websocket';
import {WS_ENDPOINT} from "../../settings";


export const WS_STATUS = {
    UNINSTANTIATED: -1,
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
}

export default function useWebSocket({url, onOpen, openConnection, ...options}) {
    // Na duvida vai de DOC: https://www.npmjs.com/package/react-use-websocket
    const URL = useMemo(() => {
        if (openConnection && url) {
            return `${WS_ENDPOINT}${url}`
        }
        return null
    }, [openConnection, url])
    const methods = useReactWebSocketReact(URL, {
        onOpen: onOpen,
        ...options
    }, openConnection);
    return methods
}