import React from "react";
import {DEBUG} from "../../settings";
import {ToastNotification} from "../components/feedback/ToastNotification";

export const LimitCaractersBreadCrumbs = (value) => {
    if (value) {
        if (value.length > 30) {
            return value.substring(0, 30) + '...'
        } else {
            return value
        }
    }
    return ''
}

export function maskIntNumber(value) {

    if (!value) {
        if (value !== 0) return '-'
    }
    return maskNumber.format(value)
}

export const maskNumber = Intl.NumberFormat('pt-BR')

export const BuildFieldErrorsDict = (error, setValidatedFields, toastMessage = false) => {

    DEBUG && console.error(error)
    toastMessage && ToastNotification(toastMessage, 'error')
    if (error.response) {
        DEBUG && console.error(error.response)
        const {
            errors: errorsBack,
            code: errorCode
        } = error.response.data;
        if (errorCode === 'field_error') {
            //
            // let errorsDict = {}
            // Object.keys(errorsBack).forEach((keyErro) => {
            //     errorsDict[keyErro] = errorsBack[keyErro][0]
            // })
            // console.log(errorsDict)
            // setValidatedFields && setValidatedFields(errorsDict)
            let errors = []

            Object.entries(errorsBack).forEach((value) => {
                let erro = value[1]
                if (!(value[1] instanceof Array)) erro = [value[1]]
                errors.push({name: value[0], errors: erro})

            })
            setValidatedFields && setValidatedFields(errors)
        } else if (error.response.data) {
            let response_errors = error.response.data.errors || error.response.data.detail
            if (response_errors) {
                if (Array.isArray(response_errors)) {
                    ToastNotification(response_errors[0], 'error')
                } else {
                    ToastNotification(response_errors, 'error')
                }
            }
        }
    }

}


export function remove_empty_values(form_data) {
    let tags_to_remove = []
    for (var pair of form_data.entries()) {
        if (!pair[1]) {
            tags_to_remove.push(pair[0]);
        }
    }
    tags_to_remove.forEach((tag) => {
        form_data.delete(tag)
    })
    return form_data
}

export function convertByteToMegaByte(byte) {
    return byte / 1048576
}

export function getAllFilters(formData, object) {
    const allFilterData = formData || new FormData();
    for (const key in object) {
        if (Array.isArray(object[key])) {
            for (const value of object[key]) {
                allFilterData.append(key, value);
            }
            continue;
        }
        allFilterData.append(key, object[key]);
    }
    return allFilterData;
}

export function convertBooleanValues(obj) {
    const convertedObj = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (value === 'true') {
                convertedObj[key] = true;
            } else if (value === 'false') {
                convertedObj[key] = false;
            } else {
                convertedObj[key] = value;
            }
        }
    }
    return convertedObj;
}

export function any(iterable) {
    for (let index = 0; index < iterable.length; index++) {
        if (iterable[index]) return true;
    }
    return false;
}

export function all(iterable) {
    for (let index = 0; index < iterable.length; index++) {
        if (!iterable[index]) return false;
    }
    return true;
}

export function redirect_by_path() {
    let path = '/'
    try {
        let splited = window.location.pathname.split('/')
        if (splited[0].length > 0) {
            path += splited[0]
        } else {
            path += splited[1]
        }
    } catch (e) {
        ;
    }
    return path
}

export function redirect_tag_a(link) {
    const tag_link = window.document.createElement('a')
    tag_link.href = link
    tag_link.click()
}

export function push_by_path(history) {
    let path = redirect_by_path()
    history.push(path)
}


export function getFileExtensionByPath(path) {
    if (!path) return ''
    try {
        const match = path.match(/\.([a-zA-Z0-9]+)(\?.*)?$/);
        if (match) {
            return match[1];
        } else {
            return '';
        }
    } catch (error) {
        return '';
    }
}

export function groupByN(arr, n) {
    const groupedArray = [];
    for (let i = 0; i < arr.length; i += n) {
        groupedArray.push(arr.slice(i, i + n));
    }
    return groupedArray;
}

export function isURL(string) {
    try {
        new URL(string);
        return true;
    } catch (error) {
        return false;
    }
}

export function getScoreHeadlightColor({
                                           score,
                                           max = 100,
                                           inverseScale = false
                                       }) {
    score = Number.parseFloat(score);
    let minStart = 0;
    let maxStart = 0.7 * max;

    if (inverseScale) {
        [minStart, maxStart] = [maxStart, minStart];
    }

    const COLOR_LEVELS = [
        {value: minStart, color: "#FF4D4F"},
        {value: 0.3 * max, color: "#FFA940"},
        {value: maxStart, color: "#73D13D"},
    ];

    if (inverseScale) {
        COLOR_LEVELS.reverse();
    }


    let color = "#f5f5f5";
    COLOR_LEVELS.forEach((item) => {
        if (score >= item.value) {
            color = item.color;
        }
    })
    return color;
}

export function getOrDefault(object, key, then = '') {
    if (object) return object[key]
    return then
}


export function valueOrEmpty(value) {
    if (value) return value
    return '-'
}

export function generateOptionsField(values, key_label, key_value = 'id') {
    if (values instanceof Array) {
        return values.map((value) => {
            return {
                label: value[key_label],
                value: value[key_value],
            }
        })
    }
    return []
}


export function jsonToFormData(data) {
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {

        if (Array.isArray(value)) {
            value.forEach((item) => {
                formData.append(key, item);
            })
        } else {
            formData.append(key, value);
        }
    })
    return formData;
}


export function getTimeLimitType(daysLeft) {
    return daysLeft > 1 ? "success" : "danger"
}

export function extractText(element) {
    if (React.isValidElement(element)) {
        if (typeof element.props.children === 'string') {
            return element.props.children;
        } else if (Array.isArray(element.props.children)) {
            return element.props.children.map(child => extractText(child)).join('');
        } else if (element.props.children && typeof element.props.children === 'object') {
            return extractText(element.props.children);
        }
    } else if (typeof element === 'string') {
        return element;
    }

    return '';
};


export function sumArray(array, key = null) {
    try {
        return array.reduce((accumulator, current) => {
            const value = key ? current[key] : current;
            return value + accumulator;
        }, 0)
    } catch (e) {
        return 0;
    }
}

export function cleanPhoneNumber(str) {
    return str.replace(/[().\- ]/g, '');
}

export function getValueByValue(obj, value, inputKey, outputKey) {
    const result = obj.find(item => item[inputKey] === value);
    return result ? result[outputKey] : 0;
}

export function getPercent(individualValue, totalValue, toFixed = 0) {
    const percentage = (individualValue / totalValue) * 100;
    return percentage.toFixed(toFixed)
}

export function findMaxValueByKey(obj, key) {

    if (!obj) return null

    if (obj.length === 0) {
        return null; // Return null if the array is empty
    }

    let maxValue = obj[0][key];
    for (let i = 1; i < obj.length; i++) {
        if (obj[i][key] > maxValue) {
            maxValue = obj[i][key];
        }
    }
    return maxValue;
}

export function countValuesByKey(data, key) {
    if (!data) return null
    return data.reduce((acc, current) => {
        const value = current[key];
        if (acc[value]) {
            acc[value]++;
        } else {
            acc[value] = 1;
        }
        return acc;
    }, {});
}

export function ruleOfThree(value1, value2, proportion) {
    return (value2 * proportion) / value1;
}

export function getMaxValueFromArray(array, property, round = false, defaultMax = 0) {
    if (!array || array?.length === 0) return 0;
    const values = array.map(obj => round ? Math.round(obj[property]) : obj[property]);
    return Math.max(...values, defaultMax);
}

export function getFunnelColorGradient(data) {
    const initialColor = "#0E42D2"
    const interval = Math.round(255 / data?.length)
    const gradient = []
    for (let i = 255; i > 0; i -= interval) {
        const alpha = i.toString(16).padStart(2, "0");
        const newColor = `${initialColor}${alpha}`
        gradient.push(newColor)
    }
    return gradient
}


export function getProjectColor(data, value, key = "project") {
    const item = data?.find(item => item[key] === value)
    return item?.project_color || "#fff"
}

export function getApplicationTitleConvention(program) {
    const businessTypes = ['startup', 'company'];
    const extra = {application: program.application_name_display};
    if (businessTypes.includes(program.application_name_choice)) {
        return ["application_name", extra];
    }
    return ["application_title", extra];
}

export function darkenHexColor(hex, percentage) {
    // Remove the hash at the start if it's there

    if (!hex) return ""

    hex = hex?.replace(/^#/, '');

    // Parse r, g, b values from hex
    let r = parseInt(hex.slice(0, 2), 16);
    let g = parseInt(hex.slice(2, 4), 16);
    let b = parseInt(hex.slice(4, 6), 16);

    // Calculate the darker values
    r = Math.max(0, Math.min(255, Math.floor(r * (1 - percentage / 100))));
    g = Math.max(0, Math.min(255, Math.floor(g * (1 - percentage / 100))));
    b = Math.max(0, Math.min(255, Math.floor(b * (1 - percentage / 100))));

    // Convert back to hex and return
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}


export const toCompactNumber = (lenguage = "en") => Intl.NumberFormat(lenguage, {
    notation: "compact"
});
export const toFormatNumber = new Intl.NumberFormat('pt-br',
    {minimumFractionDigits: 0, maximumFractionDigits: 2}
);

export function formatPointNumber(value) {
    return toFormatNumber.format(value || 0)
}


export function generateFilterToStarBase({
                                             values = [],

                                             label
                                         }) {
    let filter = ''
    if (values?.length === 0) return ''

    values.forEach((item, index) => {
        filter += item

        if (index !== values.length - 1) {
            filter += ','
        }
    })
    return `${label}=${filter}&`
}
