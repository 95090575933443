import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import { neutral_5} from "../../../../../utils/Colors";
import styled from "styled-components";
import Drawer from "../../../../../components/layout/Drawer";
import {Col, Row} from "antd";
import {DateFormatByUserTimezone} from "../../../../../utils/dates";
import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import Pagination from "../../../../../components/navigation/Pagination";
import Loading from "../../../../../components/feedback/Loading";
import Empty from "../../../../../components/data_display/Empty";
import CardHistory from "../../../../../components/data_display/CardHistory";
import GamificationInfo
    from "../../../../../components/data_display/GamificationInfo";


const InfoContainer = styled.div`
    width: 100%;
    border-radius: 11px;
    padding: 16px;
    border: 1px solid ${neutral_5};
    background: white;
`


export default function DrawerHistoryShopItem({
                                                  item,
                                                  open,
                                                  setOpen,
                                              }) {

    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const [logHistorys, setLogHistorys] = useState([])

    function onClose() {
        setOpen(false)
    }


    const PaginationRequest = {
        url: `/store_itens/${item?.id}/history_logs`,
        setIsLoading: setIsLoading,
        setData: setLogHistorys,
        makeRequest: open && item?.id,
        extraParams: {
            // search: search
        },
        dependence: [open, item?.id]

    }

    return (
        <>
            <Drawer
                isGrayBody title={t('Item History')}
                open={open}
                onClose={onClose}>
                <Row gutter={[16, 16]}>

                    <Col xs={24}>
                        <InfoContainer>
                            <Row gutter={[8, 8]}>
                                <Col xs={24} sm={10}>
                                    <GamificationInfo title={t('Item')}
                                          value={item?.name}/>
                                </Col>
                                <Col xs={24} sm={6}>
                                    <GamificationInfo title={t('Amount')}
                                          value={item?.stock}/>
                                </Col>
                                <Col xs={24} sm={8}>
                                    <GamificationInfo title={t('Date of registration')}
                                          value={DateFormatByUserTimezone(item?.created_at, false)}/>
                                </Col>
                            </Row>
                        </InfoContainer>
                    </Col>
                    <Col xs={24}>
                        <Row>
                            {isLoading ? <Col xs={24}><Loading/> </Col> :
                                logHistorys?.length > 0 ?
                                    logHistorys?.map((logHistory) =>
                                        <Col xs={24} key={logHistory.id}>
                                            <CardHistory  {...logHistory}/></Col>) :
                                    <Col xs={24}><Empty/></Col>}

                        </Row>
                        <FlexContainer
                            style={{marginTop: 32}} justify={'end'}>
                            <Pagination request={PaginationRequest}

                                        showTotal={(total) => t('total_items', {total: total})}
                                        size="small"
                                        hideOnSinglePage
                            />
                        </FlexContainer>
                    </Col>

                </Row>


            </Drawer>
        </>
    )
}

