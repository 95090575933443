import React, {useEffect} from 'react';
import {useThemeContext} from "../../context/ThemeContext";
import styled from 'styled-components'
import {
    Col,
    Layout,
    Row,
    Typography
} from "antd";
import {useHistory} from "react-router-dom";

import CircularImage from "../../components/data_display/CircularImage";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const {Title} = Typography;

const EnviromentTitle = styled(Title)`
  width: max-content;
`
const StyledLayout = styled(Layout)`
  background: white;
`

const Image = styled.img`
  display: block;

  max-width: 100%;
  width: 100%;
  height: 100vh;

  object-fit: cover;
`
const Container = styled.div`

  padding: 16px 55px 16px;
  max-width: 500px;
  margin: 0 auto;

`
const ScrollContainer = styled.div`
  overflow: scroll;
  height: 100vh;

`

const ContainerForm = styled(Col)`
      flex: ${props => props.isLg ? '0 0 40%;' : 'auto;'}
`

export default function LayoutLogin({children}) {
    const {themeConfig} = useThemeContext()
    const history = useHistory();
    const {lg} = useBreakpoint()



    return (
        <>
            <StyledLayout>
                <Row style={{height: '100vh'}}>
                    <Col xs={0} lg={12} style={{flex: '0 0 60%', maxWidth: 'none'}}>
                        <Image src={themeConfig.login_img_cover}/>
                    </Col>
                    <ContainerForm isLg={lg}>
                        <ScrollContainer>
                            <Container>
                                <Row gutter={[0, 16]} style={{}}>
                                    <Col xs={24}>
                                        <CircularImage
                                            background={themeConfig.login_background_cover}
                                            size={125}
                                            style={{margin: '0 auto'}}
                                            src={themeConfig.login_logo_cover}/>
                                    </Col>
                                    <Col xs={24}>
                                        <EnviromentTitle level={4}
                                                         style={{margin: '0 auto'}}>
                                            {themeConfig.client_name || 'Solv innovation network'}
                                        </EnviromentTitle>
                                    </Col>
                                </Row>
                                {children}
                            </Container>
                        </ScrollContainer>
                    </ContainerForm>
                </Row>
            </StyledLayout>

        </>

    );
}