import {useTranslation} from "react-i18next";
import InfoCardBase from "../InfoCardBase";
import LimitedSpace from "../../responsive/LimitedSpace";
import {DateFormatByUserTimezone} from "../../../utils/dates";
import DotInfoCard from "../DotInfoCard";
import {CalendarOutlined, UnorderedListOutlined, FlagOutlined, BarsOutlined} from "@ant-design/icons";


export default function ChallengeInfoCard({challenge, solution, onClick, ...props}) {
    const {t} = useTranslation();

    return (
        <InfoCardBase
            title={t('Challenge')}
            subtitle={challenge.name}
            onClick={onClick}
            {...props}
        >
            <LimitedSpace>
                <DotInfoCard showDot={false} icon={<CalendarOutlined/>}
                             label={t("Start date")}
                             info={DateFormatByUserTimezone(challenge.date_start)}/>
                {challenge.date_end && <DotInfoCard icon={<CalendarOutlined/>}
                                                    label={t("End Date")}
                                                    info={DateFormatByUserTimezone(challenge.date_end)}/>}
                <DotInfoCard
                    icon={<UnorderedListOutlined/>}
                    label={t("Phase")}
                    info={solution?.phases.find(({id}) => id === solution?.current_phase)?.title}/>
                <DotInfoCard
                    icon={<BarsOutlined/>}
                    label={t("Program")}
                    info={challenge?.program_name}/>
            </LimitedSpace>

        </InfoCardBase>
    );
}