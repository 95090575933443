import React, {useEffect, useState} from "react";
import DefaultComponent from "./DefaultComponent";
import Select from "react-select";
import {useTranslation} from "react-i18next";


function SingleSelectForm(props) {
    const [options, setOptions] = useState([])
    const {t} = useTranslation()
    useEffect(() => {
        let newOptions = []
        props.choices && props.choices.map((option, index) =>
            newOptions.push({value: option.value, label: option.value})
        )
        setOptions(newOptions)
    }, [props.choices])
    return (
        <DefaultComponent {...props}>
            <Select options={options} placeholder={t("Select...")} />
        </DefaultComponent>
    )
}

export default SingleSelectForm