import React, { useState} from 'react';

import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {
    FlexContainer
} from "../../../../../../global/components/authentication/StyledComponents";
import {neutral_5} from "../../../../../../global/utils/Colors";
import RemixIcon
    from "../../../../../../global/components/data_display/RemixIcon";
import ModalFormSection from "./ModaFormSection";


const AddButtonContainer = styled(FlexContainer)`
    cursor: pointer;
    background: white;
    color: ${({actived}) => actived ? 'var(--primary_btn_color)' : 'black'};
    font-weight: bold;
    padding: 8px 16px;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    border-color: ${({actived}) => actived ? 'var(--primary_btn_color)' : neutral_5};
white-space: nowrap;
    &:hover {
        color: var(--primary_btn_color);
        border: 1px solid var(--primary_btn_color);
    }
`


export default function AddSectionButton({title, onClick, actived = true, ...props}) {
    const {t} = useTranslation()
    const [open, setOpen] = useState(false)
    return <>
        <AddButtonContainer gap={8} onClick={() => setOpen(true)}>
            <RemixIcon remixIconName={'ri-add-line'}/> {t('New section')}
        </AddButtonContainer>
        <ModalFormSection open={open} setOpen={setOpen} onSave={onClick}/>
    </>
}
