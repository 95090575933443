import { useEffect, useRef, useState } from "react";

export default function FixedFooter({children}) {
    const [width, setWidth] = useState(0);
    const [left, setLeft] = useState(0);
    const ref = useRef();

    const updateDimensions = () => {   
        const parent = ref.current?.parentElement.parentElement;
        setWidth(parent?.offsetWidth);
        setLeft(parent?.offsetLeft);
    }

    useEffect(()=>{
        const resizeObserver = new ResizeObserver(() => {
            updateDimensions();    
        });
        resizeObserver.observe(ref.current?.parentElement);
        return () => resizeObserver.disconnect();
    }, []);

    return <div ref={ref} style={{left, width, position: "fixed", bottom: 0, zIndex: "990"}}>{children}</div>
}
