import React from 'react';
import {useTranslation} from "react-i18next";
import Table from "../../../global/components/data_display/Table";
import useTable from "../../../global/hooks/useTable";
import {DateFormatByUserTimezone} from "../../../global/utils/dates";
import {PushpinOutlined} from "@ant-design/icons";
import {Space} from "antd";
import TruncateText from "../../../global/components/responsive/TruncateText";
import TagsList from "../../../global/components/tag/TagsList";

import useEventListener from '../../../global/hooks/useEventListener';
import DangerousHtmlPreviewer from "../../../global/components/data_display/DangerousHtmlPreviewer";
import { generateExportReport, REPORTS } from '../../../global/pages/database/hooks/useExportDatabase';

export default function ProgramTable({url, onRowClick, search, bodyRequest, getOrder}) {
    const {t} = useTranslation();

    const {
        order, tableData, setTableData, isLoading, tableParams, setTableParams, handleTableChange, resetTable
    } = useTable({
        url,
        search,
        body: bodyRequest,
        dependenceRequest:[bodyRequest]
    });
    getOrder.current = order;

    // todo falta status

    const columns = [{
        title: t('Program'), dataIndex: 'name', // sorter: 'equipment__pin_17',
        key: 'name',
        ellipsis: true,
        width: '400px',
        sorter: "name",
        render: (value, record) => {
            return <Space size={8}>
                <span data-cy={`program-row-${record.id}`}>{value}</span>
                {record.is_innovation_program && <PushpinOutlined/>}
            </Space>
        }
    }, {
        title: t('Date start'),
        dataIndex: 'date_start',
        key: 'date_start',
        width: '200px',
        sorter: "date_start",
        render: (value) => {
            return DateFormatByUserTimezone(value)
        }
    },
        {
        title: t('Date end'),
        dataIndex: 'date_end',
        key: 'date_end',
        width: '200px',
        sorter: "date_end",
        render: (value) => {
            return <>
                {value ? DateFormatByUserTimezone(value) : '-'}</>
        }
    },
        {
        title: t('Applications'),
        dataIndex: 'valid_applications_count',
        key: 'valid_applications_count',
        width: '200px',
        render: (value) => {
            return <>
                {value || '-'}</>
        }
    },
        {
        title: t('Description'),
        dataIndex: 'description',
        key: 'description',
          render: (value) => (
                <div style={{width: 500}}>
                    <TruncateText>
                        <DangerousHtmlPreviewer content={value} />
                    </TruncateText>
                </div>
            )
  },
      {
        title: t('Tags'),
        dataIndex: 'tags_display',
        key: 'tags_display',
        render: (tags) => <TagsList isCustom tags={tags} maxSize={2}/>
    },
    ]

    // useEffect(() => {
    //     setTableParams({tableParams, ...extraParams})
    // }, [extraParams]);

    useEventListener('generate-program-list', generateExportReport(REPORTS.PROGRAM_LIST));

    return (
        <>
            <Table columns={columns} onChange={handleTableChange}
                tableParams={tableParams}
                loading={isLoading}
                noScroll
                // showSizeChanger={false}
                onRowClick={onRowClick}
                dataSource={tableData}
                data-cy="program-table"
            />
        </>
    )
}
