import React from 'react'
import styled from 'styled-components'
import {ReactComponent as SearchIcon} from '../assets/Icon/search.svg'
import InputContainer from "./Form/InputContainer";



const StyledTextInput = styled.input`
  background-color: #F8F8F8;
  border: none;
  border-radius: 8px;
  overflow: hidden;
  padding: 14px 50px 14px 10px;
  outline: none;

`


const StyledButton = styled.button`
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  transition: 0.3s;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  
  &:hover{
    background-color: #6e6e6e;
  }
  
  & svg{
    width: 16px;
    height: 16px;
    transition: 0.3s;
  }
  
  & svg path{
    fill: #6e6e6e;
     transition: 0.3s;
  }
  
  &:hover svg path{
    fill: #F8F8F8;
  }
`


export default function Search({onChange, onClick, onBlur, value, placeholder, ...props}) {
    return (

        <InputContainer>
            <StyledTextInput placeholder={placeholder} value={value} onChange={onChange} onBlur={onBlur} onKeyPress={(event) => event.code === 'Enter' && onClick && onClick()}/>
            <StyledButton onClick={onClick}>
                <SearchIcon/>
            </StyledButton>
        </InputContainer>
    )

}