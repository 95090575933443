import {Typography, Form, Space,} from "antd";
import {CheckCircleOutlined,} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import SolvModal from "../layout/SolvModal";
import {
    useLayoutContext
} from "../../../modules/project/components/layout/context/LayoutContext";

import {ReactComponent as JumpIcon} from '../../../assets/Icon/Jump.svg'
import WideSpace from "../layout/WideSpace";
import styled from 'styled-components';
import {usePermissionContext} from "../../context/PermissionContext";
import Button from "../data_entry/Button";
import {useHistory} from "react-router-dom";


const Item = styled.div`
    font-size: 12px;
    font-weight: 600;
    display: flex;
    gap: 10px;
    justify-content: center;

    svg {
        width: 16px;
        height: 16px;
    }
`

export default function AlertBlockModal() {
    const {showAlertBlockModal, setShowAlertBlockModal} = useLayoutContext();
    const {hasFullAdminPermission} = usePermissionContext();
    const history = useHistory();
    const [form] = Form.useForm();
    const {t} = useTranslation();
    const benefits = [
        t('Unlimited Collaborators'), t('Open Innovation'), t('Unlimited projects'), t('Priority support.')
    ]

    function handleUpdatePlan() {
        history.push('/environment/financial')
        setShowAlertBlockModal(false)
    }

    return (
        <SolvModal
            // title={t("Welcome to Solv")}
            open={showAlertBlockModal}
            setOpen={setShowAlertBlockModal}
            centered
            footer={null}
        >
            <WideSpace align={'center'} direction={'vertical'} size={24}>
                <JumpIcon/>
                <Typography.Title
                    level={3}>{t('Turn ideas into impact')}</Typography.Title>
                <span>{t('Upgrade for numerous additional benefits:')}</span>
                <Space align={'start'} direction={'vertical'}>
                    {benefits.map((value,index) => (
                        <Item key={index}>
                            <CheckCircleOutlined/>
                            <span> {value}</span>
                        </Item>
                    ))}
                </Space>
                {hasFullAdminPermission ?
                    (
                        <Button
                            onClick={handleUpdatePlan}>{t('Update your plan')}</Button>
                    ) :
                    (
                        <span>{t('Talk to your environment administrator.')}</span>
                    )
                }
            </WideSpace>

            {/*<WelcomeForm form={form}/>*/}
        </SolvModal>
    );
};
