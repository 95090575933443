import React from 'react';
import Drawer from "./Drawer";

function BottomDrawer({height = 500, children, ...props}) {
    return (
        <Drawer
            placement="bottom"
            mask={false}
            autoFocus={false}
            isGrayBody={true}
            rootStyle={{width: 1058, justifySelf: "center"}}
            style={{borderRadius: "8px 8px 0"}}
            contentWrapperStyle={{borderRadius: "8px 8px 0"}}
            height={height}
            {...props}
        >
            {children}
        </Drawer>
    );
}

export default BottomDrawer;