import IndicatorRatingCheckpointCard from "./IndicatorRatingCheckpointCard";
import IndicatorCurrencyCheckpointCard from "./IndicatorCurrencyCheckpointCard";
import IndicatorDecimalCheckpointCard from "./IndicatorDecimalCheckpointCard";
import IndicatorTagCheckpointCard from "./IndicatorTagCheckpointCard";
import React from "react";
import IndicatorProgressSliderCheckpointCard from "./IndicatorProgressSliderCheckpointCard";
import { useTranslation } from "react-i18next";
import IndicatorProgressCheckpointCard from './IndicatorProgressCheckpointCard';

function IndicatorBuilder({indicatorResponse}) {
    const {indicator} = indicatorResponse;
    const { t } = useTranslation();
    const dataCy =  indicator.internal_code ? `indicator-card-${indicator.internal_code}` : `indicator-card-${indicator.indicator_view.name}`

    switch (indicator["indicator_view"]["name"]) {
        case "rating":
            return <IndicatorRatingCheckpointCard indicatorResponse={indicatorResponse} data-cy={dataCy}/>;
        case "currency":
            return <IndicatorCurrencyCheckpointCard indicatorResponse={indicatorResponse} data-cy={dataCy}/>;
        case "number":
            return <IndicatorDecimalCheckpointCard indicatorResponse={indicatorResponse} data-cy={dataCy}/>;
        // case "tags":
        //     return <IndicatorTagCheckpointCard indicatorResponse={indicatorResponse} data-cy={dataCy}/>;
        case "progress":
            return <IndicatorProgressCheckpointCard indicatorResponse={indicatorResponse} data-cy={dataCy} />;
        case "percent":
            return <IndicatorProgressSliderCheckpointCard indicatorResponse={indicatorResponse} data-cy={dataCy} />;
        default:
            return <>{t("Missing indicator")} {t(indicator["indicator_view"]["name"])}</>;
    }
}

function IndicatorCheckpointCardFactory({indicatorResponse}) {
    return <IndicatorBuilder indicatorResponse={indicatorResponse}/>;
}

export default IndicatorCheckpointCardFactory;