import React from "react";
import {useTranslation} from "react-i18next";
import {
    danger, neutral_7, polar_green_6, primary_5, primary_7,
} from "../../../../utils/Colors";
import EmptyChart from "../components/EmptyChart";
import ChartCardWithDelete from "../components/ChartCardWithDelete";
import RadarChart
    from "../../../../../pages/Programs/TabBarProgram/Dashboard/components/RadarChart";
import ChartCard from "../../../../components/data_display/ChartCard";
import StackedColumnChart
    from "../../../../../pages/Programs/TabBarProgram/Dashboard/components/StackedColumnChart";
import BarChart
    from "../../../../../pages/Programs/TabBarProgram/Dashboard/components/BarChart";
import ColumnChart
    from "../../../../../pages/Programs/TabBarProgram/Dashboard/components/ColumnChart";
import PieGraph
    from "../../../../../pages/Programs/TabBarProgram/Dashboard/components/PieChart";
import RoseChart
    from "../../../../../pages/Programs/TabBarProgram/Dashboard/components/RoseChart";
import GeoChartState
    from "../../../../../pages/Programs/TabBarProgram/Dashboard/components/GeoChartState";
import {useHistory} from "react-router-dom";


export const PROGRAM_CHART_NAMES = { //OBS: se mudar esses valores, vai ter q mudar nos que tiverem salvos nas configurações dos usuários
    APPLICATIONS_BY_PROGRAMS: 'applications_by_programs',
    APPLICATIONS_BY_CHALLENGES: 'applications_by_challenges',
    APPLICATIONS_BY_PERSONA: 'applications_by_persona',
    APPLICATIONS_BY_TEAM_SIZE: 'applications_by_team_size',
    APPLICATIONS_BY_STATE: 'applications_by_state',
    APPROVALS_BY_PROGRAMS: 'approvals_by_programs',
    APPLICATIONS_BY_INSTITUTION: 'applications_by_institution',
    APPLICATIONS_BY_TECHNOLOGY: 'applications_by_technology',
    APPLICATIONS_BY_TRANSACTION: 'applications_by_transaction',
    // APPLICATIONS_BY_SECTOR_CLIENT: 'applications_by_sector_client',
    ASSESSMENTS_BY_PROGRAMS: 'assessments_by_programs',
    // EMPTY: 'empty'
}


export function DrawerProgramChartFactory({
                                              type,
                                              initialDate,
                                              finalDate,
                                              programs,
                                              ...props
                                          }) {
    const {t} = useTranslation();

    switch (type) {
        case PROGRAM_CHART_NAMES.APPLICATIONS_BY_PROGRAMS:
            return <ChartCard
                title={t('Applications by program')} {...props}>
                <ApplicationByProgram initialDate={initialDate}
                                      finalDate={finalDate}
                                      programs={programs}/>
            </ChartCard>
        case PROGRAM_CHART_NAMES.APPLICATIONS_BY_CHALLENGES:
            return <ChartCard
                title={t('Applications by challenge')} {...props}>
                <ApplicationsByChallenge initialDate={initialDate}
                                         finalDate={finalDate}
                                         programs={programs}/>
            </ChartCard>
        case PROGRAM_CHART_NAMES.APPLICATIONS_BY_PERSONA:
            return <ChartCard
                title={t('Company by persona')} {...props}>
                <CompanyByPersona initialDate={initialDate}
                                  finalDate={finalDate} programs={programs}/>
            </ChartCard>
        case PROGRAM_CHART_NAMES.APPLICATIONS_BY_TEAM_SIZE:
            return <ChartCard
                title={t('Company size')} {...props}>
                <CompanySize initialDate={initialDate} finalDate={finalDate}
                             programs={programs}/>
            </ChartCard>
        case PROGRAM_CHART_NAMES.APPLICATIONS_BY_STATE:
            return <ChartCard
                title={t('Applications by state')} {...props}>
                <ApplicationsByState initialDate={initialDate}
                                     finalDate={finalDate}
                                     programs={programs}/>
            </ChartCard>
        case PROGRAM_CHART_NAMES.APPROVALS_BY_PROGRAMS:
            return <ChartCard
                title={t('Result of the programs')} {...props}>
                <ApprovalByProgram initialDate={initialDate}
                                   finalDate={finalDate} programs={programs}/>
            </ChartCard>
        case PROGRAM_CHART_NAMES.APPLICATIONS_BY_INSTITUTION:
            return <ChartCard
                title={t('Companies by sectors')} {...props}>
                <CompaniesBySectors initialDate={initialDate}
                                    finalDate={finalDate} programs={programs}/>
            </ChartCard>
        case PROGRAM_CHART_NAMES.APPLICATIONS_BY_TECHNOLOGY:
            return <ChartCard
                title={t('Companies by technology')} {...props}>
                <CompaniesByTechnology initialDate={initialDate}
                                       finalDate={finalDate}
                                       programs={programs}/>
            </ChartCard>
        case PROGRAM_CHART_NAMES.APPLICATIONS_BY_TRANSACTION:
            return <ChartCard
                title={t('Companies by type of transaction')} {...props}>
                <CompinaesByTypeOfTransaction initialDate={initialDate}
                                              finalDate={finalDate}
                                              programs={programs}/>
            </ChartCard>
        // case PROGRAM_CHART_NAMES.APPLICATIONS_BY_SECTOR_CLIENT:
        //     return <ChartCard
        //         title={t('Applications by sector client')} {...props}>
        //         <>APPLICATIONS_BY_SECTOR_CLIENT<  programs={programs}/>
        //     </ChartCard>
        case PROGRAM_CHART_NAMES.ASSESSMENTS_BY_PROGRAMS:
            return <ChartCard
                title={t('Assessments by programs')} {...props}>
                <AssessmentsByPrograms initialDate={initialDate}
                                       finalDate={finalDate}
                                       programs={programs}/>
            </ChartCard>

    }

}


export function ProgramChartsFactory({bodyFilters,
                                         type,
                                         handleOpenDrawer,
                                         initialDate,
                                         finalDate,
                                         programs,
                                         ...props
                                     }) {
    const {t} = useTranslation();


    switch (type) {
        case PROGRAM_CHART_NAMES.APPLICATIONS_BY_PROGRAMS:
            return <ChartCardWithDelete
                title={t('Applications by program')} {...props}>
                <ApplicationByProgram initialDate={initialDate}
                                      finalDate={finalDate}
                                      programs={programs} bodyFilters={bodyFilters}/>
            </ChartCardWithDelete>
        case PROGRAM_CHART_NAMES.APPLICATIONS_BY_CHALLENGES:
            return <ChartCardWithDelete
                title={t('Applications by challenge')} {...props}>
                <ApplicationsByChallenge initialDate={initialDate}
                                         finalDate={finalDate}
                                         programs={programs}  bodyFilters={bodyFilters}/>
            </ChartCardWithDelete>
        case PROGRAM_CHART_NAMES.APPLICATIONS_BY_PERSONA:
            return <ChartCardWithDelete
                title={t('Company by persona')} {...props}>
                <CompanyByPersona initialDate={initialDate}
                                  finalDate={finalDate} programs={programs}  bodyFilters={bodyFilters} />
            </ChartCardWithDelete>
        case PROGRAM_CHART_NAMES.APPLICATIONS_BY_TEAM_SIZE:
            return <ChartCardWithDelete
                title={t('Company size')} {...props}>
                <CompanySize initialDate={initialDate} finalDate={finalDate}
                             programs={programs} bodyFilters={bodyFilters}/>
            </ChartCardWithDelete>
        case PROGRAM_CHART_NAMES.APPLICATIONS_BY_STATE:
            return <ChartCardWithDelete
                title={t('Applications by state')} {...props}>
                <ApplicationsByState initialDate={initialDate}
                                     finalDate={finalDate}
                                     programs={programs} bodyFilters={bodyFilters}/>
            </ChartCardWithDelete>
        case PROGRAM_CHART_NAMES.APPROVALS_BY_PROGRAMS:
            return <ChartCardWithDelete
                title={t('Result of the programs')} {...props}>
                <ApprovalByProgram initialDate={initialDate}
                                   finalDate={finalDate} programs={programs} bodyFilters={bodyFilters}/>
            </ChartCardWithDelete>
        case PROGRAM_CHART_NAMES.APPLICATIONS_BY_INSTITUTION:
            return <ChartCardWithDelete
                title={t('Companies by sectors')} {...props}>

                <CompaniesBySectors initialDate={initialDate}
                                    finalDate={finalDate} programs={programs} bodyFilters={bodyFilters}/>
            </ChartCardWithDelete>
        case PROGRAM_CHART_NAMES.APPLICATIONS_BY_TECHNOLOGY:
            return <ChartCardWithDelete
                title={t('Companies by technology')} {...props}>
                <CompaniesByTechnology initialDate={initialDate}
                                       finalDate={finalDate}
                                       programs={programs} bodyFilters={bodyFilters}/>
            </ChartCardWithDelete>
        case PROGRAM_CHART_NAMES.APPLICATIONS_BY_TRANSACTION:
            return <ChartCardWithDelete
                title={t('Companies by type of transaction')} {...props}>
                <CompinaesByTypeOfTransaction initialDate={initialDate}
                                              finalDate={finalDate}
                                              programs={programs} bodyFilters={bodyFilters}/>
            </ChartCardWithDelete>
        // case PROGRAM_CHART_NAMES.APPLICATIONS_BY_SECTOR_CLIENT:
        //     return <ChartCardWithDelete
        //         title={t('Applications by sector client')} {...props}>
        //         <>APPLICATIONS_BY_SECTOR_CLIENT<  programs={programs} bodyFilters=bodyFilter/>
        //     </ChartCardWithDelete>
        case PROGRAM_CHART_NAMES.ASSESSMENTS_BY_PROGRAMS:
            return <ChartCardWithDelete
                title={t('Assessments by programs')} {...props}>
                <AssessmentsByPrograms initialDate={initialDate}
                                       finalDate={finalDate}
                                       programs={programs} bodyFilters={bodyFilters}/>
            </ChartCardWithDelete>

        default:
            return <EmptyChart onClick={handleOpenDrawer}/>
    }

}

function AssessmentsByPrograms({initialDate, finalDate, programs, bodyFilters}) {
      const history = useHistory();
    return (
        <StackedColumnChart
             onColumnClick={(e) => {
                history.push(`/programs/${e.data.data.object.id}/dashboard`)
            }}
            legend_key="name"
            groups={{
                answered_evaluation_count: {
                    label: 'Awaiting Evaluation',
                    color: primary_5
                },
                finished_evaluation_count: {
                    label: 'Evaluated',
                    color: '#14C9C9'
                },
                not_answered_evaluation_count: {
                    label: 'Not allocated',
                    color: '#F7BA1E'
                },
            }}
            request={{
                url: 'analytics/dashboard/manager_dashboard/assessments_by_programs',
                params: {
                    initial: initialDate,
                    final: finalDate,
                    id__in: programs
                },
                body:bodyFilters

            }}
        />
    )
}

function CompinaesByTypeOfTransaction({initialDate, finalDate, programs, bodyFilters}) {
    return (
        <PieGraph
            request={{
                url: `analytics/dashboard/manager_dashboard/applications_by_transaction`,
                params: {
                    initial: initialDate,
                    final: finalDate,
                    id__in: programs
                },
                body:bodyFilters
            }}
            showPercentLabel={false}
            legend_key="name"/>
    )
}

function CompaniesByTechnology({initialDate, finalDate, programs, bodyFilters}) {
    return (
        <RoseChart
            request={{
                url: `analytics/dashboard/manager_dashboard/applications_by_technology`,
                params: {
                    initial: initialDate,
                    final: finalDate,
                    id__in: programs
                },
                body:bodyFilters
            }}
            legend_key="name"/>
    )
}

function CompaniesBySectors({initialDate, finalDate, programs, bodyFilters}) {
    return (
        <PieGraph
            request={{
                url: `analytics/dashboard/manager_dashboard/applications_by_institution`,
                params: {
                    initial: initialDate,
                    final: finalDate,
                    id__in: programs
                },
                body:bodyFilters
            }}
            legend_key="name"/>
    )
}

function ApprovalByProgram({initialDate, finalDate, programs, bodyFilters}) {
      const history = useHistory();
    return (
        <StackedColumnChart
             onColumnClick={(e) => {
                history.push(`/programs/${e.data.data.object.id}/dashboard`)
            }}
            legend_key="name"
            request={{
                url: 'analytics/dashboard/manager_dashboard/approvals_by_programs',
                params: {
                    initial: initialDate,
                    final: finalDate,
                    id__in: programs
                },
                body:bodyFilters

            }}
            groups={{
                aproved_applications_count: {
                    label: 'Aproved',
                    color: polar_green_6
                },
                reproved_applications_count: {
                    label: 'Reproved',
                    color: danger
                },
                pending_applications_count: {
                    label: 'Pending',
                    color: neutral_7
                },
            }}

        />
    )
}

function ApplicationsByState({initialDate, finalDate, programs, bodyFilters}) {
    return (
        <GeoChartState

            request={{
                url: `analytics/dashboard/manager_dashboard/applications_by_state`,
                params: {
                    initial: initialDate,
                    final: finalDate,
                    id__in: programs
                },
                body:bodyFilters

            }}
        />
    )
}

function CompanySize({initialDate, finalDate, programs, bodyFilters}) {
    return (
        <ColumnChart
            request={{
                url: 'analytics/dashboard/manager_dashboard/applications_by_team_size',
                params: {
                    initial: initialDate,
                    final: finalDate,
                    id__in: programs
                },
                body:bodyFilters

            }}
            legend_key="name"
        />
    )
}

function CompanyByPersona({initialDate, finalDate, programs, bodyFilters}) {
    return (
        <ColumnChart
            request={{
                url: 'analytics/dashboard/manager_dashboard/applications_by_persona',
                params: {
                    initial: initialDate,
                    final: finalDate,
                    id__in: programs
                },
                body:bodyFilters

            }}
            legend_key="name"
        />
    )
}

function ApplicationsByChallenge({initialDate, finalDate, programs, bodyFilters}) {

    return (
        <StackedColumnChart
            legend_key="name"
            request={{
                url: 'analytics/dashboard/manager_dashboard/applications_by_challenges',
                params: {
                    initial: initialDate,
                    final: finalDate,
                    id__in: programs
                },
                body:bodyFilters

            }}
            groups={{
                submited_applications_count: {
                    label: 'submitted',
                    color: primary_7
                },
                pending_applications_count: {
                    label: 'not_submitted',
                    color: '#B37FEB'
                },
                cancelled_applications_count: {
                    label: 'given_up',
                    color: '#FFD8BF'
                },
            }}

        />
    )
}

function ApplicationByProgram({initialDate, finalDate, programs, bodyFilters}) {
    const history = useHistory();
    return (
        <StackedColumnChart
            onColumnClick={(e) => {
                history.push(`/programs/${e.data.data.object.id}/dashboard`)
            }}
            legend_key="name"
            request={{
                url: 'analytics/dashboard/manager_dashboard/applications_by_programs',
                params: {
                    initial: initialDate,
                    final: finalDate,
                    id__in: programs
                },
                body:bodyFilters

            }}
            groups={{
                pending_applications_count: {
                    label: 'Pending',
                    color: '#B37FEB'
                },
                submited_applications_count: {
                    label: 'Finished',
                    color: '#096DD9'
                },
                cancelled_applications_count: {
                    label: 'Canceled',
                    color: '#FFD8BF'
                },
            }}

            // fetchUrl={``}
        />
    )
}