import React from 'react';
import FinancialMilestoneFormDrawer
    from "../../modules/project/components/drawer/FinancialMilestoneFormDrawer";
import InvestmentFinancialPlanningTable
    from "../../modules/project/components/tables/InvestmentFinancialPlanningTable";
import IndicatorValueCard from "../../modules/project/components/IndicatorValueCard";
import {Flex, Typography} from "antd";
import {neutral_8} from "../utils/Colors";
import {useTranslation} from "react-i18next";
import FinancePlanningHelpModal from "../../modules/project/components/modal/FinancePlanningHelpModal";
import ForecastChart from "../../modules/project/pages/ProjectManagement/components/DashboardIndicatorCharts/ForecastChart";
import EmptyFinancialPlanning from '../../modules/project/pages/ProjectManagement/components/EmptyFinancialPlanning';


const {Title, Text} = Typography;

function TestPage(props) {
    const {t} = useTranslation();
    return (
        // <FinancialMilestoneFormDrawer open={true}/>
        // <InvestmentFinancialPlanningTable />
        // <Flex vertical={true} gap={16}>
        //     <IndicatorValueCard
        //         label={t("Total Expected Investment")}
        //         help={"This indicator is used to track the allocated resources for the project at all stages, so that you can understand how much the final project adhered to its budget"}
        //         prefix={"$:"}
        //         value={<Title level={2} style={{color: neutral_8, margin:0 }}>65.800,00</Title>}
        //     />
        //     <IndicatorValueCard
        //         label={"Timeframe to Achieve Estimated Annual Return"}
        //         help={"This indicator is used to track the allocated resources for the project at all stages, so that you can understand whether the estimated return was achieved within the stipulated timeframe"}
        //         prefix={"Remaining:"}
        //         value={
        //             <Title level={2} style={{color: neutral_8, margin:0 }}>12</Title>
        //         }
        //         suffix={
        //             <Text type="secondary">{t("weeks")}</Text>
        //         }
        //     />
        //     <IndicatorValueCard
        //         label={"Expected Completion Time"}
        //         help={"This indicator is used to track the allocated resources for the project at all stages, so that you can understand whether the estimated return was achieved within the stipulated timeframe"}
        //         prefix={"Remaining:"}
        //         value={
        //             <Title level={2} style={{color: neutral_8, margin:0 }}>2</Title>
        //         }
        //         suffix={
        //             <Text type="secondary">{t("months")}</Text>
        //         }
        //     />
        //     <IndicatorValueCard
        //         label={"Annual Return on Investment"}
        //         help={"The Annual Return on Investment (ROI) is a crucial financial indicator that measures the profitability and efficiency of an investment over a one-year period"}
        //         prefix={"$:"}
        //         value={<Title level={2} style={{color: neutral_8, margin:0 }}>15.500,00</Title>}
        //     />
        // </Flex>

        // <FinancePlanningHelpModal/>

        // <ForecastChart />
        <EmptyFinancialPlanning/>

    );
}

export default TestPage;