import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SolvCollapse from "../../../../components/misc/SolvCollapse";

import useTable from "../../../../hooks/useTable";
import Table from "../../../../components/data_display/Table";
import SolvTag from "../../../../components/tag/SolvTag";
import { assessmentStatusTagColor, dust_red_1, polar_green_1, sunset_orange_1 } from "../../../../utils/Colors";
import RemixIcon from "../../../../components/data_display/RemixIcon";
import { NavLink, useHistory } from "react-router-dom";
import { ASSESSMENT } from "../../../../utils/Constants";
import { Col } from "antd";

export default function AssesmentsCollapseTable({ id, program_name, title, program, isAllAssessments, evaluate }) {
    const { t } = useTranslation();
    const history = useHistory();

    const { tableData, isLoading, tableParams, handleTableChange, handleRequestTable } = useTable({
        useHandleRequestTable: false,
        hasPagination: false,
        extraParams: { all_evaluations: isAllAssessments, ordering:['application__challenge','status'] },
        url: `evaluator_assessments/${id}`, // hasPagination: true,
        // body: bodyRequest,
        config: { version: "v2" },
    });

    const columns = [
        {
            title: t("Application title"),
            key: "application__name",
            dataIndex: "application.name",
            sorter: "application__name",
            width: 400,
            render: (value, record) => <span data-cy={`application-title-row-${record.application.id}`}>{record.application.name}</span>,
        },
        {
            title: t("Score"),
            dataIndex: "score",
            key: "score",
            sorter: "score",
            width: 50,
        },
        {
            title: t("Status"),
            key: "status",
            dataIndex: "status",
            sorter: "status",
            width: 50,
            render: (value, record) => {
                if (!value) return "-";
                const statusColor = assessmentStatusTagColor[value];
                return (
                    <SolvTag color={statusColor} data-cy={`application-status-row-${record.application.id}`}>
                        {t(record.status_display)}
                    </SolvTag>
                );
            },
        },
    ];

    useEffect(() => {
        handleRequestTable();
    }, [isAllAssessments]);

    return (
        tableData?.length > 0 && (
            <Col xs={24}>
                <SolvCollapse
                    collapseStyle={{ border: "1px solid red" }}
                    title={
                        <NavLink to={`available-programs/detail/${program}`}>
                            {`${program_name} - ${title}`} <RemixIcon remixIconName={"ri-external-link-line"} />
                        </NavLink>
                    }
                    padding={0}
                    active={true}
                    bordered={true}
                    dataCy={`assessment-collapse-phase-${id}`}
                >
                    <Table
                        columns={columns}
                        onChange={handleTableChange}
                        tableParams={tableParams}
                        loading={isLoading}
                        noScroll
                        // showSizeChanger={false}
                        //    onRowClick={(object) => history.push(`/evaluations/${object.evaluate}/phases/${id}/?assessment=${object.id}`)}
                        onRowClick={(object) => history.push(`/evaluations/${object.id}`)}
                        dataSource={tableData}
                    />
                </SolvCollapse>
            </Col>
        )
    );
}
