import {Column} from "@ant-design/plots";
import {useChartColor} from "./hooks/useChartColor";
import {useTranslation} from "react-i18next";
import {
    DateFormat,
    DateFormatByUserTimezone,
    DateFormatUser,
    DatetimeByTimezoneSystem
} from "../../../../../../global/utils/dates";
import {useUserContext} from "../../../../../../global/context/UserContext";

function ColumnChart({data, indicator}) {
    const {getColorByImportancy} = useChartColor({indicator, indicatorResponse: data.at(-1)});
    const {t} = useTranslation()
    const {_user} = useUserContext();

    const config = {
        data,
        xField: "status_report_date",
        yField: "scaled_value",
        animate: null,
        legend: false,
        padding: 40,
        maxColumnWidth: 10,
        height:300,
        style: {
            fill: ({scaled_value}) => {
                return getColorByImportancy(scaled_value)
            },
            inset: 1
        },
        scale:{
            y:{
                tickCount: 10,
                domain:[indicator?.scale_min, indicator?.scale_max]
            }
        },
        tooltip: {
            title: ({order, status_report_date}) => (
                `Checkpoint Nº${order} - ${status_report_date.slice(0,10)}`
            ),
            items: [{channel: "y", name: t(indicator.name)}],
        },
        axis:{
            x:{
                title:"Checkpoint",
                tickLength: 0,
                labelFormatter: (text) => text.slice(0, 10),
                labelAutoRotate: true,
            },
            y:{
                title:t("Level"), 
                tickLength: 0,
            }
        },
        markBackground:{
            style: {
                fill:"transparent"
            }
        }

    };
    return <Column {...config} />;
}

export default ColumnChart;