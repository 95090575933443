import { Col, Modal, Row, } from "antd";

import React, {useRef, useState} from "react";
import {ExclamationCircleOutlined} from "@ant-design/icons"
import {useTranslation} from "react-i18next";



export default function ConfirmModal({
                                         body,
                                         okText = 'Yes',
                                         open,
                                         disabledOKButton = false,
                                         setOpen,
                                         title,
                                         onConfim,
    onClose,
                                         content,
                                         modal,
                                         awaitSeconds = 30,
                                         ...props
                                     }) {
    const {t} = useTranslation();


    function _onClose() {
        onClose?.()
        setOpen(false)


    }

    return (

        <Modal title={title} open={open} okText={okText}
               onCancel={_onClose}
               onOk={() => {
                   onConfim()
                   _onClose()
               }}
               cancelText={t("Cancel")}
               destroyOnClose closable={false}
               okButtonProps={{
                   // danger: true,
                   disabled: disabledOKButton,
                   "data-cy":`ok-modal-button`
               }}
               data-cy="confirm-modal"
        >
            <Row gutter={16}>
                <Col><ExclamationCircleOutlined style={{
                    fontSize: 22,
                    color: 'var(--ant-warning-color)'
                }}/></Col>
                <Col flex={'1'}>
                    {body}
                </Col>
            </Row>
        </Modal>


    )
}