import React from 'react'
import styled from 'styled-components'
import {
    MinusCircleOutlined,
    CloseCircleOutlined,
    CheckCircleOutlined
} from '@ant-design/icons'
import {Typography} from "antd";
import {FlexContainer} from "../authentication/StyledComponents";
import {
    dust_red_1, dust_red_4,
    neutral_3, sunset_orange_4,
    polar_green_1, polar_green_6,
    sunset_orange_1
} from "../../utils/Colors";
import {useHistory} from "react-router-dom";
import {ASSESSMENT} from "../../utils/Constants";

const StyledFlexContainer = styled(FlexContainer)`
    padding: 16px 24px;
    border-radius: 8px;
    border: 1px solid ${({
                             border_color,
                             actived
                         }) => actived ? 'transparent' : border_color};
    //min-width: 270px;
    //max-width: 270px;
    transition: 0.1s;
    height: ${({actived}) => actived ? '80px' : '60px'};
    box-shadow: ${({actived}) => actived ? '0px 2px 8px 0px rgba(0,0,0,0.1)' : 'none'};
    background-color: ${({
                             actived,
                             background_color
                         }) => actived ? background_color : 'white'};
    cursor: pointer;

    svg {
        color: ${({border_color}) => border_color};
    }

    &:hover {
        height: 80px;
    }
`

const StyledText = styled(Typography.Text)`

    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    flex-grow: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;



`
const Score = styled.div`
    border-radius: 8px;
    border: 1px solid ${neutral_3};
    padding: 8px;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: ${({color}) => color};
`

const STATUS_BG_COLORS = {
    [ASSESSMENT.NOT_ANSWERED]: sunset_orange_1,
    [ASSESSMENT.ANSWERED]: sunset_orange_1,
    [ASSESSMENT.SENDED]: polar_green_1,
    [ASSESSMENT.INVALID]: dust_red_1,
}

const STATUS_BORDER_COLORS = {
    [ASSESSMENT.NOT_ANSWERED]: sunset_orange_4,
    [ASSESSMENT.ANSWERED]: sunset_orange_4,
    [ASSESSMENT.SENDED]: polar_green_6,
    [ASSESSMENT.INVALID]: dust_red_4,
}

const STATUS_ICONS = {
    [ASSESSMENT.NOT_ANSWERED]: <MinusCircleOutlined/>,
    [ASSESSMENT.ANSWERED]: <MinusCircleOutlined/>,
    [ASSESSMENT.SENDED]: <CheckCircleOutlined/>,
    [ASSESSMENT.INVALID]: <CloseCircleOutlined/>,
}

export default function CardAssesment({
                                          id,
                                          status,
                                          application,
                                          actived = false,

                                          score,
                                          ...props
                                      }) {

    const background_color = STATUS_BG_COLORS[status]
    const border_color = STATUS_BORDER_COLORS[status]
    const icon = STATUS_ICONS[status]
    const history = useHistory();
    return (

        <StyledFlexContainer gap={8} background_color={background_color}
                             onClick={() => history.push(`/evaluations/${id}`)}
                             border_color={border_color}
                             actived={actived} {...props}>
            {icon}
            <StyledText>{application?.name}</StyledText>
            <Score color={border_color}>
                {score ? score : '-'}
            </Score>
        </StyledFlexContainer>

    );
}