import React from "react";
import {FilterTitle} from "../../Filters/StyledFilters";
import FormSelect from "../../Form/FormSelect/Index";
import {useTranslation} from "react-i18next";

function FilterQuestions(props) {
    const {questions, handleSubmitForm} = props
    const {t} = useTranslation();

    const getQuestionOptions = (choices) => {
        let options = []
        choices.map((option) => {
            options.push({value: option.value, label: option.value})
        })
        return options
    }

    return (
        <>
            {questions.length > 0 &&
                <>
                    <FilterTitle>{t("Form questions filter")}</FilterTitle>
                    {
                        questions.map((question) =>
                            <FormSelect key={question.name + '-div'} onChange={() => {
                                setTimeout(handleSubmitForm, 1)
                            }}
                                        name={question.name}
                                        options={getQuestionOptions(question.choices)}
                                        label={question.label}
                                        multiple={true}/>
                        )
                    }
                </>
            }
        </>
    )
}

export default FilterQuestions