import React from 'react'
import styled from "styled-components";
import Label from "./Label";
import MessageError from "./MessageError";
import InputContainer from "./InputContainer";
import {StyledOptionLabel} from "./GenericStyledForm";

const StyledHideInput = styled.input`
    width: 0px;
    &:checked + label{
        background-color:#2A84C6;
        box-shadow: inset 0px 0px 0px 1px #fff;
        
    }
  &:hover{
    cursor:pointer
  }
  
`

const StyledCustomRadio = styled.label`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid rgb(221,221,221);
    &:hover{
    cursor:pointer;
  }
`

export default function RadioButton(
    {
        label, elementDescription, options,
        onChange, required, id, name, messageError,
        inputValue, placeHolder, defaultChecked, optionsDirection, ...props
    }){


    return (
        <InputContainer>
            {label &&
            <Label required={required}>{label}</Label>}
            {elementDescription}
            <div style={{display: 'flex', flexDirection: optionsDirection, gap:15, flexWrap: 'wrap'}}>
                {options.map((option, index) =>
                    (
                        <div style={{
                            display: 'flex',
                            alignItems: 'baseline',
                            columnGap: 10,
                        }} key={option.id}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <StyledHideInput style={{width: 0}}

                                                 required={required}
                                                 type='radio'
                                                 placeholder={placeHolder}
                                                 onChange={onChange}
                                                 checked={defaultChecked && defaultChecked.includes(option.id)}
                                                 name={name} value={option.id}
                                                 {...option}
                                                 {...props}
                                                id={`${name}${index}`}
                                    />
                                <StyledCustomRadio htmlFor={`${name}${index}`}/>

                            </div>

                            <StyledOptionLabel
                                htmlFor={`${name}${index}`}>{option.label}</StyledOptionLabel>


                        </div>
                    )
                )}
            </div>
            {messageError && <MessageError>{messageError}</MessageError>}
        </InputContainer>
    )
}