import React from 'react'
import {
    Col,
    Row,
    Typography,
    Image
} from 'antd';
import {
    FlexContainer,
    QuestionContainer,
    QuestionTitle
} from "../../../../authentication/StyledComponents";
import WideSpace from "../../../../layout/WideSpace";
import {useTranslation} from "react-i18next";
import DangerousHtmlPreviewer
    from "../../../../data_display/DangerousHtmlPreviewer";
import Required from "./Required";
import FileQuestionAnswer from "./FileQuestionAnswer";


const FIELDS_NAMES = {
    DOCUMENT: 'document',
    IMAGE: 'image',
}


export default function ShowQuestionAnswer({
                                               label,
                                               help_text,
    required,
                                               ...props
                                           }) {
    const {t} = useTranslation()


    function FACTORY_FIELD({type, ...field}) {

        switch (type) {
            case FIELDS_NAMES.DOCUMENT:
                return (
                    <FileQuestionAnswer {...field}/>
                )
            case FIELDS_NAMES.IMAGE:
                return (
                    <Image width={240} src={field.value}/>
                )

            default:
                return <span>{field.value}</span>
        }
    }

    return (

        <QuestionContainer>
            <Row gutter={[19, 19]}>
                <Col xs={24}>
                    <Row>
                        {/*<Col xs={24} md={19} lg={20} xl={21}>*/}
                        <Col xs={24}>
                            <WideSpace direction={'vertical'} size={0}>
                                <QuestionTitle>
                                    <Required required={required}/>
                                    {label}
                                </QuestionTitle>
                                <Typography.Text
                                    type={'secondary'}><DangerousHtmlPreviewer
                                    content={help_text}/></Typography.Text>
                            </WideSpace>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24}>
                    {FACTORY_FIELD(props)}
                </Col>

            </Row>

        </QuestionContainer>


    )
}
