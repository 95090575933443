import React, {useState} from "react";
import SectionDashboardProgramGeneralData
    from "./sections/SectionDashboardProgramGeneralData";
import SectionDashboardProgramApplicationData
    from "./sections/SectionDashboardProgramApplicationData";
import SectionDashboardProgramCompanyData
    from "./sections/SectionDashboardProgramCompanyData";
import SectionDashboardProgramMetrics
    from "./sections/SectionDashboardProgramMetrics";
import SectionDashboardProgramPhases
    from "./sections/SectionDashboardProgramPhases";
import ButtonFilterDashboardDrawer
    from "./components/ButtonFilterDashboardDrawer";
import HeaderTitleContainer
    from "../../../../global/components/other/HeaderTitleContainer";
import {useTranslation} from "react-i18next";


export default function Dashboard() {
    const [filters, setFilters] = useState({})
    const {t} = useTranslation()
    return (
        <div style={{display: "flex", flexDirection: "column", gap: "20px"}}>
            <HeaderTitleContainer title={t('Program Dashboard')}
                                  rigthContent={<ButtonFilterDashboardDrawer
                                      setFilters={setFilters}
                                      filters={filters}/>}/>
            <SectionDashboardProgramMetrics filters={filters}/>

            <SectionDashboardProgramGeneralData filters={filters}/>
            <SectionDashboardProgramApplicationData filters={filters}/>

            <SectionDashboardProgramCompanyData filters={filters}/>
            <SectionDashboardProgramPhases filters={filters}/>

        </div>
    );
}