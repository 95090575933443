import React, {useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import {Modal, Typography} from 'antd';
import {useTranslation} from "react-i18next";
import {
    FlexContainer
} from "../../../../../global/components/authentication/StyledComponents";

import RemixIcon
    from "../../../../../global/components/data_display/RemixIcon";
import {

    ExclamationCircleOutlined
} from "@ant-design/icons";
import {
    lime_6,
    magenta_6,
    neutral_2, neutral_3,
    neutral_5, primary_6,
    primary_8
} from "../../../../../global/utils/Colors";
import Drawer from "../../../../../global/components/layout/Drawer";

import UserAvatar from "../../../../../global/components/misc/UserAvatar";
import useSystemBreakpoint
    from "../../../../../global/hooks/useSystemBreakpoint";
import api from "../../../../../global/services/api";
import {
    ToastNotification
} from "../../../../../global/components/feedback/ToastNotification";
import * as ConstantsMessage
    from "../../../../../global/utils/fileWithMessage";
import {BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import Button from "../../../../../global/components/data_entry/Button";
import TagsList from "../../../../../global/components/tag/TagsList";
import useDefaultGetRequest
    from "../../../../../global/hooks/useDefaultGetRequest";
import {useProgramContext} from "../../../../ProgramsNavigator";

const CompanyName = styled(Typography.Title)`
    margin-bottom: 0 !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
`

const Description = styled.div`
    margin-bottom: 32px;
    font-size: 14px;
 
`

const Address = styled.div`
    padding: 10px;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
    background: white;
    border-radius: 8px;
    border: 1px solid ${neutral_5};
    font-size: 12px;

    &:first-child {
        font-size: 24px;
        width: 20px;
        height: 20px;
        background: red;
    }
`

const UnfavoriteButton = styled.div`
    width: 100%;
    background: ${neutral_3};
    border-radius: 8px;

    padding: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 2px 2px #00000026;
    cursor: pointer;

    &:hover {
        background: white;
    }
`


const StyledDrawer = styled(Drawer)`
    .ant-drawer-footer {
        //border-top: none;
            //background: ${neutral_2};
    }
`

export default function DrawerStartupPerfil({
                                                startup_id,
                                                setOpen,
                                                open,
                                                resetTable
                                            }) {
    const {program} = useProgramContext();
    const {t} = useTranslation();
    const {isPhone} = useSystemBreakpoint()
    const [isLoading, setIsLoading] = useState(false)
    const [startup, setStartup] = useState(false)


    const {
        isLoading: isLoadingStartup
    } = useDefaultGetRequest({
        setData: setStartup,
        url: `/program_hunt/get_organization`,
        makeRequest: open && program?.id && startup_id,
        params: {
            id: startup_id,
            program_id: program?.id
        },
        dependence: [program?.id, startup_id, open]
    })

// const isPhone=false
    function onClose() {
        setOpen(false)
    }

    function calculeSize(list) {
        const amount = list.filter((item) => item).length

        if (isPhone) return 150
        if (amount === 2) return 165

        return 320
    }

    const textSizeRowOne = calculeSize([startup?.phone_number, startup?.site]) //GAMBIARRA
    const textSizeRowTwo = calculeSize([startup?.linkedin, startup?.instagram,])//GAMBIARRA
    const textSizeRowThree = calculeSize([startup?.twitter, startup?.email])//GAMBIARRA

    function handleUnfavorite() {
        Modal.confirm({
            title: t("Unfavorite"),
            icon: <ExclamationCircleOutlined/>,
            content: t("Are you sure you want to unfavorite the startup?"),
            onOk: async () => {
                try {
                    setIsLoading(true);

                    let response = await api.delete(`program_hunt/${startup?.hunt_pk}/unfavorite_organization`);
                    resetTable?.()
                    setStartup((startup) => ({...startup, favorited:false}))
                    ToastNotification(t(ConstantsMessage.SUCCESS));
                } catch (error) {
                    BuildFieldErrorsDict(error, null, null)
                } finally {
                    setIsLoading(false);
                }
            },
            okText: t('Yes'),
            cancelText: t('No')

        });
    }

    function handleFavorite() {
        Modal.confirm({
            title: t("Favorite"),
            icon: <ExclamationCircleOutlined/>,
            content: t("Are you sure you want to favorite the startup?"),
            onOk: async () => {
                try {
                    setIsLoading(true);

                    let response = await api.post(`program_hunt/favorite_organization`, {
                        id_organization: startup.id,
                        match_porcentage: 0,
                        program_id: program.id
                    });
                    resetTable?.()
                    setStartup((startup) => ({...startup, favorited:true, hunt_pk:response.data.hunt_pk}))
                    ToastNotification(t(ConstantsMessage.SUCCESS));
                } catch (error) {
                    BuildFieldErrorsDict(error, null, null)
                } finally {
                    setIsLoading(false);
                }
            },
            okText: t('Yes'),
            cancelText: t('No')

        });
    }

    return (

        <StyledDrawer open={open}
                      loading={isLoadingStartup}
                      onClose={onClose}

                      footer={
                          <FlexContainer>
                              {startup?.favorited ? (

                                  <Button loading={isLoading}
                                          onClick={handleUnfavorite}
                                          type={'default'} block
                                          icon={<RemixIcon
                                              style={{color: 'var(--primary_btn_color)'}}
                                              remixIconName={'ri-star-fill'}/>}>{t('Highlight remover for this startup')}</Button>
                              ) : (
                                  <Button loading={isLoading}
                                          onClick={handleFavorite}
                                          block
                                          icon={<RemixIcon
                                              remixIconName={'ri-star-fill'}/>}>{t('Highlight this startup')}</Button>

                              )}

                          </FlexContainer>

                      }
                      bodyStyle={{background: neutral_2}}
                      width={496}
                      title={t('Startup Profile')}>
            <FlexContainer gap={8} style={{marginBottom: 8}}>
                <UserAvatar data={startup}/>
                <CompanyName level={5}>{startup?.name}
                </CompanyName>
            </FlexContainer>
            {startup?.description && <Description>
                {startup?.description}
            </Description>}
           <TagsList style={{marginBottom: 16}} isCustom tags={startup?.tags}
                      maxSize={6}/>
            <Address>
                <RemixIcon style={{color: '#00000073', fontSize: 20}}
                           remixIconName={'ri-map-pin-2-fill'}/>
                <span>{startup?.address?.state}</span>
            </Address>
            <FlexContainer gap={24} style={{marginTop: 32}}>
                {startup?.phone_number && <CardInfo
                    textSize={textSizeRowOne}
                    link={`https://wa.me/${startup?.phone_number}`}
                    icon={<RemixIcon style={{color: lime_6,}}
                                     remixIconName={'ri-whatsapp-line'}/>}
                    text={startup?.phone_number}/>}
                {startup?.site &&
                    <CardInfo
                        textSize={textSizeRowOne}
                        link={startup?.site}
                        icon={<RemixIcon style={{color: '#03053D',}}
                                         remixIconName={'ri-chrome-line'}/>}
                        text={startup?.site}/>}

            </FlexContainer>

            <FlexContainer gap={24} style={{marginTop: 32}}>
                {startup?.linkedin && <CardInfo
                    textSize={textSizeRowTwo}
                    link={startup?.linkedin}
                    icon={<RemixIcon style={{color: primary_8,}}
                                     remixIconName={'ri-linkedin-box-line'}/>}
                    text={startup?.linkedin}/>}
                {startup?.instagram && <CardInfo
                    textSize={textSizeRowTwo}
                    link={startup?.instagram}
                    icon={<RemixIcon style={{color: magenta_6,}}
                                     remixIconName={'ri-instagram-line'}/>}
                    text={startup?.instagram}/>}
            </FlexContainer>

            <FlexContainer gap={24} style={{marginTop: 32}}>

                {startup?.twitter && <CardInfo
                    textSize={textSizeRowThree}
                    link={startup?.twitter}
                    icon={<RemixIcon style={{color: '#1677FF',}}
                                     remixIconName={'ri-twitter-line'}/>}
                    text={startup?.twitter}/>}
                {startup?.email && <CardInfo
                    textSize={textSizeRowThree}
                    link={`mailto:${startup?.email}`}
                    icon={<RemixIcon style={{color: '#A8071A',}}
                                     remixIconName={'ri-mail-line'}/>}
                    text={startup?.email}/>}
            </FlexContainer>

        </StyledDrawer>


    );
}


const InfoContainer = styled.a`
    border-radius: 8px;
    padding: 8px;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    background: white;
    width: 100%;
    border: 1px solid ${neutral_2};
    box-shadow: 0px 2px 8px 0px #00000026;

    cursor: pointer;

    &:hover {
        background: ${neutral_3};
    }



`

const Icon = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    * {
        font-size: 24px;
    }
`

const Text = styled.span`
    font-size: 12px;
    width: ${({width}) => width}px;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    color: black;

`

function CardInfo({link, icon, text, textSize}) {

    return (


        <InfoContainer href={link} target={'_blank'}>
            <Icon>{icon}</Icon>
            <Text width={textSize}>{text}</Text>
        </InfoContainer>


    )
}


function TextInfo({title, text}) {
    return (
        <FlexContainer direction={'column'} align={'start'}>
            <Typography.Title
                style={{whiteSpace: 'nowrap'}}
                level={5}>{title}</Typography.Title>
            <Typography.Text>{text}</Typography.Text>
        </FlexContainer>
    )
}