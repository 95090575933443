import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import {
    blue_5, neutral_3, neutral_4, neutral_5,
} from "../../../../../../global/utils/Colors";
import {
    FlexContainer
} from "../../../../../../global/components/authentication/StyledComponents";
import {
    PlusCircleFilled,
} from "@ant-design/icons";
import ModalCreatePhase
    from "../../../../../../components/Phases/ModalCreatePhase";
import HeaderPhaseColumn from "./HeaderPhaseColumn";
import BodyPhaseColumn from "./BodyPhaseColumn";
import {PHASE} from "../../../../../../global/utils/Constants";

const Container = styled.div`
    background: ${({open}) => open ? neutral_3 : neutral_4};
    border-radius: 16px;
    width: 271px;
overflow: hidden;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    

`

const CreatePhaseButton = styled(PlusCircleFilled)`
    cursor: pointer;
    padding: 16px;
    border-radius: 4px;


    :hover {
        background: ${neutral_5};
    }

`


export default function PhaseColumn({phase,isConfidencial, ...props}) {
    const {t} = useTranslation();
    const [showCreatePhase, setShowCreatePhase] = useState(false)

    const getUpdateUrl = () => {
        switch (phase.type){
            case PHASE.INFORMATION:
                return `/programs/${phase.program_id}/phases/${phase.id}/update`
            case PHASE.APPLICATION:
                return `/programs/${phase.program_id}/phases/${phase.id}/database/applications`
            case PHASE.EVALUATION:
                return `/programs/${phase.program_id}/phases/${phase.id}/evaluators`
        }
    }

    const updateUrl = getUpdateUrl()


    if(!phase.id) return null
    return (
        // <div style={{height: '100%'}}>
        <FlexContainer style={{height: '100%'}} align={'start'}>
            <Container open={phase.is_open}>
                <HeaderPhaseColumn phase={phase} updateUrl={updateUrl}/>
                <BodyPhaseColumn phase={phase} isConfidencial={isConfidencial} updateUrl={updateUrl}/>
            </Container>
            <CreatePhaseButton onClick={() => setShowCreatePhase(true)} data-cy={`create-phase-button-${phase?.id}`}/>
            <ModalCreatePhase open={showCreatePhase}
                              onCancel={() => setShowCreatePhase(false)}
                              phase={phase}/>


        </FlexContainer>
// </div>
    );
}

