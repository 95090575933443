import React from 'react'
import styled from 'styled-components'
import {ReactComponent as FileIcon} from '../../assets/Icon/file-svgrepo-com.svg'
import {DatetimeByTimezoneSystem} from "../../global/utils/dates";
import {getFileExtensionByPath} from "../../global/utils/Utils";
import { renderFileType } from '../../global/components/tables/utils';

const FilesContainer = styled.div`
`
const FileItem = styled.div`
  display: flex;
  //width: 300px;
  gap: 8px;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;

  & > *:nth-child(2) {
    flex: 1;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #0E4B78;
  }


  & > a:nth-child(2):hover {

    text-decoration: underline;
  }

  & > span:nth-child(2):hover {
    cursor: text;
    //text-decoration: underline;
  }
`

const Date = styled.span`
    color: #00000073;
  font-size: 10px;

`

const FileIconContainer = styled.div`
  width: 30px;
  height: 30px;

  & svg {
    width: 100%;
    height: 100%;
  }
`

export default function FilesView({files}) {
    
    return (
        <FilesContainer>
            {files.length > 0 ? (
                files.map((file, index) => {
                    return (
                        <FileItem key={index}>
                            {renderFileType(file.path)}
                            <a title={file.name} href={file.path}
                               target={'_blank'}>{file.name}.{getFileExtensionByPath(file.path)} <Date>{DatetimeByTimezoneSystem(file.Date_update)}</Date></a>
                        </FileItem>
                    )
                })
            ) : null}
        </FilesContainer>
    )
}