import React from 'react'
import styled from 'styled-components'
import InputContainer from "./InputContainer";
import Label from "./Label";
import MessageError from "./MessageError";
import {StyledOptionLabel} from "./GenericStyledForm";


const StyledHideInput = styled.input`
    width: 0px;
    &:checked + label{
        background-color:#2A84C6;
        box-shadow: inset 0px 0px 0px 1px #fff;
        
    }
`

const StyledCustomCheck = styled.label`
    width: 16px;
    height: 16px;
    border: 2px solid rgb(221,221,221);
    border-radius: 2px;
    cursor: pointer;
  background-color: white;
`

export default function CheckBox(
    {
        label, elementDescription, options,
        onChange, required, id, name, messageError,
        inputValue, placeHolder, defaultChecked, optionsDirection, ...props
    }) {

    return (
        <InputContainer hidden={props.hidden}>
            {label &&
            <Label required={required}>{label}</Label>}
            {elementDescription}
            <div style={{display: 'flex', flexDirection: optionsDirection, gap:15, flexWrap: 'wrap'}}>


                {options.map((option, index) =>
                    (
                        <div style={{
                            display: 'flex',
                            alignItems: 'baseline',
                            columnGap: 10,
                        }} key={option.id}
                           data-cy="custom-bootstrap-checkbox-container">
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <StyledHideInput style={{width: 0}}
                                                 required={required}
                                                 type='checkbox'
                                                 placeholder={placeHolder}
                                                 onChange={onChange}
                                                 defaultChecked={defaultChecked && defaultChecked.includes(option.id)}
                                                 name={name} value={option.value}
                                                 {...option}
                                                 {...props}
                                                id={`${id}${option.id}${index}`}
                                    />
                                <StyledCustomCheck htmlFor={`${id}${option.id}${index}`}/>

                            </div>

                            <StyledOptionLabel
                                htmlFor={`${id}${option.id}${index}`}>{option.label}</StyledOptionLabel>


                        </div>
                    )
                )}
            </div>
            {messageError && <MessageError>{messageError}</MessageError>}
        </InputContainer>
    )
}