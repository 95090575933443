import {
    Flex, Grid, Layout, Menu
} from "antd";
import React, {useState} from 'react';
import {useTranslation} from "react-i18next";
import {NavLink, useLocation} from "react-router-dom";
import styled from "styled-components";
import {
    FlexContainer
} from "../../global/components/authentication/StyledComponents";
import RemixIcon from "../../global/components/data_display/RemixIcon";
import {
    MenuCollapseButton
} from "../../global/components/layout/MenuCollapseButton";
import {
    NotificationButton
} from "../../global/components/layout/NotificationButton";
import {useThemeContext} from "../../global/context/ThemeContext";
import useSystemBreakpoint from "../../global/hooks/useSystemBreakpoint";
import {
    useLayoutContext
} from "../../modules/project/components/layout/context/LayoutContext";
import {getSelectedMenuItems} from "../../modules/project/utils/utils";
import InviteOffCanvas from "../InviteOffCanvas";

const {Header} = Layout;

const StyledHeader = styled(Header)`
    background: white;
    min-height: 64px;
    width: 100%;
    align-items: center;
    padding-left: ${({isPhone}) => isPhone ? "8px" : "16px"};
    padding-right: ${({isPhone}) => isPhone ? "8px" : "16px"};
    height: max-content;
    border-bottom: 1px solid var(--font-fading-color);
`

const StyledFlexContainer = styled(FlexContainer)`
    color: black;
    height: 100%;
    padding: 0 8px;

    :hover {
        cursor: pointer;
    }

`

export default function ActionBar() {
    const {
        navbarMenuItems,
        extraHeaderContent,
        actionBarRef
    } = useLayoutContext();
    const {t} = useTranslation();
    const {themeConfig: {client}} = useThemeContext()
    const {isPhone, isDesktop} = useSystemBreakpoint()
    const location = useLocation();
    const screens = Grid.useBreakpoint();
    const hasExtraHeader = (extraHeaderContent !== undefined);



    return <StyledHeader ref={actionBarRef}
                         hasExtraHeader={extraHeaderContent !== undefined}
    >
        {extraHeaderContent && extraHeaderContent}
        {(!extraHeaderContent || !screens.md || navbarMenuItems) &&

            <Flex justify={"space-between"} align={"center"} style={{lineHeight: hasExtraHeader ? "46px" : "64px"}}>

                {!screens.md &&
                    <MenuCollapseButton/>
                }

                {navbarMenuItems &&
                    <Flex flex={1} style={{width: "90%"}}>
                        <Menu mode="horizontal" items={navbarMenuItems}
                              hasExtraHeaderContent={!!!extraHeaderContent}
                              selectedKeys={[getSelectedMenuItems({
                                  items: navbarMenuItems,
                                  pathname: location.pathname
                              })]}
                              style={{width: "100%"}}
                        />
                    </Flex>
                }

                {!extraHeaderContent &&
                    <Flex align={"center"} gap={16}>
                        {client?.gamification_actived &&
                            <NavLink to={'/my_wallet'}>
                                <RemixIcon style={{fontSize: 18, color:'black'}} remixIconName={'ri-wallet-line'}/>
                            </NavLink>
                        }
                        <NotificationButton/>
                    </Flex>
                }

            </Flex>
        }

    </StyledHeader>
}