import React, {useState} from 'react'
import './style.css'
import List from "../../../assets/Icon/list.svg";
import WarningIcon from "../../../assets/Icon/warning.svg";
import {Link, useHistory} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import {Button} from "react-bootstrap";
import {ReactComponent as School} from "../../../assets/Icon/school.svg"
import {ReactComponent as Account} from "../../../assets/Icon/account.svg"

import ButtonDefault from "../../../components/Buttons/ButtonDefault";
import {
    DatetimeByTimezone,
    DatetimeFormatByTimezone,
    DatetimeNow
} from "../../../global/utils/dates";
import {useTranslation} from "react-i18next";
import {PHASE} from "../../../global/utils/Constants";
import ModalCreatePhase from "../ModalCreatePhase";


function Card({phase, onDelete}) {
    const {t} = useTranslation()
    const history = useHistory();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);

    function generateCreatePhaseLink(type){
        history.push(`/programs/${phase.program}/create_phase?sequence=${phase.sequence}&type=${type}`);
    }

    const handleShow = () => setShow(true);


    function description(description) {
        if (description) {
            return (
                <>
                    <h1 className='card-content-label'>{t('Description')}</h1>
                    <p className='card-content-description'>{description}</p>
                </>
            )
        } else {
            return (
                <>
                    <h1 className='card-content-label'>{t('Description')}</h1>
                    <p className='card-content-description'
                       style={{height: 24}}/>
                </>
            )
        }
    }


    function evaluators(evaluatorsCount, type) {

        if (type === PHASE.EVALUATION) {
            return (
                <div><span
                    className='card-content-label'>{t('Evaluators')}: </span>
                    <span
                        className='card-content-label'>{evaluatorsCount ?? 0}</span>
                </div>
            )
        }
    }

    function status(status) {
        if (status) {
            return (
                <label className='phase-warning'>
                    <img src={WarningIcon}/><span>{t(status)}</span>
                </label>
            )
        }
    }

    function dates(date_start, date_end) {
        let block_date_start = <><span
            className='card-content-label'>{t("Launch time")}: </span><span
            className='card-content-label'>{date_start ? DatetimeFormatByTimezone(date_start) : t("No launch time")}</span></>

        let block_date_end = <><span
            className='card-content-label date_end'>{t('Deadline')}: </span><span
            className='card-content-label'>{DatetimeFormatByTimezone(date_end)}</span></>

        if (status) {
            return (
                <div className='phase-dates'>

                    {block_date_start}
                    {date_end && block_date_end}

                </div>
            )
        }
    }

    const checkPhaseStatus = (startDate, finishDate) => {

        if (DatetimeByTimezone(startDate) > DatetimeNow()) {
            return (
                <span style={{
                    color: "#646464",
                    padding: "2px 6px",
                    backgroundColor: "#f8f8f8",
                    borderRadius: 4
                }}>{t('Not started')}</span>
            )
        } else if (DatetimeNow() > DatetimeByTimezone(finishDate)) {
            return (
                <span style={{
                    color: "#646464",
                    padding: "2px 6px",
                    backgroundColor: "#f8f8f8",
                    borderRadius: 4
                }}>{t('Finished')}</span>
            )
        } else {
            return (
                <span style={{
                    color: "#28A745",
                    backgroundColor: "#DEF7E4",
                    padding: "2px 6px",
                    borderRadius: 4
                }}>{t('Running')}</span>
            )
        }
    }


    function checkIsInformationType() {
        return phase.type !== PHASE.INFORMATION
    }

    return (
        <>
<ModalCreatePhase open={show} onCancel={handleClose} phase={phase} />
            {/*<Modal show={show} onHide={handleClose} animation={false}>*/}
            {/*    <Modal.Header closeButton>*/}
            {/*        <Modal.Title>{t('Create new phase')}</Modal.Title>*/}
            {/*    </Modal.Header>*/}
            {/*    <Modal.Body>*/}
            {/*        <Button className="EvaEntreButton"*/}
            {/*                onClick={() => generateCreatePhaseLink(PHASE.EVALUATION)}*/}
            {/*                data-cy="evaluation-phase-button">*/}
            {/*            <School fill={'var(--primary_btn_color)'}/> {t('Evaluation phase')}*/}
            {/*        </Button>*/}
            {/*        <Button className="EvaEntreButton"*/}
            {/*                 onClick={() => generateCreatePhaseLink(PHASE.APPLICATION)}*/}
            {/*                data-cy="application-phase-button">*/}
            {/*            <Account fill={'var(--primary_btn_color)'} src={Account}/> {t('Application phase')}*/}
            {/*        </Button>*/}
            {/*        <Button className="EvaEntreButton"*/}
            {/*                 onClick={() =>generateCreatePhaseLink(PHASE.INFORMATION)}*/}
            {/*                data-cy="information-phase-button">*/}
            {/*            <Account fill={'var(--primary_btn_color)'} src={Account}/> {t('Information phase')}*/}
            {/*        </Button>*/}
            {/*    </Modal.Body>*/}
            {/*    <Modal.Footer className={'card-custom'}>*/}
            {/*        <ButtonDefault action="secondary"*/}
            {/*                       onClick={handleClose} label={t('Close')}>*/}
            {/*        </ButtonDefault>*/}
            {/*    </Modal.Footer>*/}
            {/*</Modal>*/}

            <div className='card-phase-container'
                 data-phase-sequence={phase.sequence}
                 data-cy={`card-phase-container-${phase.id}`}
            >

                <Link
                    to={`/programs/${phase.program}/phases/${phase.id}/${phase.type === PHASE.EVALUATION ? 'evaluators': 'update'}`}
                    className="cardLinkEdit2">

                    <header className='card-header-container'>
                        <div className='card-index'>{phase.sequence}</div>
                        <h2 className='card-title'>{phase.title}</h2>
                    </header>
                    <div className='card-content-container'>
                        <div className='card-content-action-buttons'>
                            {/* oi
                            <img src={EditIcon} /> */}
                            <div style={{alignSelf: "flex-end"}}>
                                <span className='card-content-label-app'
                                      style={{
                                          backgroundColor: "#f8f8f8",
                                          fontWeight: 500,
                                          padding: 0
                                      }}>
                                    {/* {phase.is_active ? "ACTIVE" : "DEACTIVATED"} */}
                                    {checkPhaseStatus(phase.date_start, phase.date_end)}
                                </span>
                            </div>
                            {checkIsInformationType() && <div style={{
                                backgroundColor: "#F8F8F8",
                                borderRadius: 4,
                                marginTop: 2
                            }}>
                                <span className='card-content-label-app'
                                      style={{
                                          padding: 0,
                                          paddingLeft: 2
                                      }}>{phase.applications}</span>
                                <span
                                    className='card-content-label-app'> {t('Application')} </span>
                            </div>}

                        </div>

                        {description(phase.short_description)}


                        {evaluators(phase.evaluators, phase.type)}
                        <div className='card-content-info'>
                            <div>
                                <div className='card-content-label-type'>
                                    <img src={List} className="listIcon"/>
                                    {phase.type === PHASE.APPLICATION && t("Application phase")}
                                    {phase.type === PHASE.EVALUATION && t("Evaluation phase")}
                                    {phase.type === PHASE.INFORMATION && t("Information phase")}
                                </div>
                            </div>
                            {dates(phase.date_start, phase.date_end)}
                            {checkIsInformationType() && status(phase.get_message_phase)}
                        </div>

                    </div>

                </Link>
                <div className='card-action-container'>
                    <div className='card-action-button' style={{
                        width: "100%",
                        justifyContent: "flex-end",
                        display: "flex"
                    }}>
                        {/*<Button to={`/programs/${phase.program}/phases/${phase.sequence}/create`} title='Add phase'/>*/}
                        {/* <Button onClick={handleShow} title='Add phase' className="addPhasePatern">
                            <img src={More} style={{ marginBottom: 3, marginRight: 4 }} /> Create phase
                        </Button> */}
                        <ButtonDefault action={'primary create'} onClick={handleShow}
                                       label={t('Add phase')}
                                       data-cy="add-phase-button"/>
                    </div>
                </div>

            </div>
        </>
    );
}


export default Card;