import React from 'react';
import './style.css'
import DateInput from "../Form/DateInput";
import SelectInput from "../Form/SelectInput";
import {useTranslation} from "react-i18next";

function ProgramFilter({linkTo, program}) {
    const {t} = useTranslation()


    return (

        <div className={'program-filter-container'}>
            <div className={'input-container'}>
                <SelectInput
                    options={
                        [{label: `${t('teste')}`, value: 'teste'},
                            {label: `${t('teste2')}`, value: 'teste2'},]
                    }/>
            </div>
            <div className={'input-container'}>
                <SelectInput/>
            </div>
            <div className={'input-container'}>
                <DateInput/>
            </div>

        </div>


)
}

export default ProgramFilter;