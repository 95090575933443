import React from 'react'
import {Col} from "react-bootstrap";
import styled from 'styled-components'
import CircleImage from "../CircleImage";
import {DatetimeFormatByTimezone} from "../../global/utils/dates";
import {useTranslation} from "react-i18next";

const StyledContent = styled.div`
  width: 100%;
  background-color: rgb(248, 248, 248);
  border-radius: 8px;
  display: flex;
  padding: 8px;
  padding-left: 1px;
  padding-bottom: 5px;
  justify-content: space-between;

`

const StyledInternal = styled.div`
  display: flex;
  flex: 6 1 0%;

`

const StyledInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;

`

const StyledAdditionalInformation = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2 1 0%;
  font-size: 13px;
  font-weight: 500;


`

const StyledChallenge = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`

const StyledPInfo = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 500px;
  display: inline-block;
  margin-bottom: 0px;
  color: rgb(100, 100, 100);
  font-size: 12px;
  height: 100%;
`


const StyledSpanBold = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: rgb(76, 76, 76);
  margin-right: 4px;

`
const StyledSpanOverflowPadrao = styled.span`
  font-weight: 400;
  margin-left: 8px;
  font-size: 12px;
overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height:18px;
`

export default function ApplicationCard({item}) {
    const {t} = useTranslation();

    return (
        <>
            <Col>
                <StyledContent key={'application-' + item.id}>
                    <StyledInternal>
                        <CircleImage program={true}
                                     src={item.team?.picture}
                                     width="50"
                                     height="50"/>

                        <StyledInfoCard>
                            <StyledSpanOverflowPadrao title={item.team.name}>
                                <StyledSpanBold>{item.team.name}</StyledSpanBold></StyledSpanOverflowPadrao>
                            <StyledSpanOverflowPadrao title={item.team.description}>
                                {item.team.description}</StyledSpanOverflowPadrao>
                            {item.challenge && (
                                <StyledChallenge>
                                    <StyledSpanBold>{t("Challenge")}: </StyledSpanBold>
                                    <StyledPInfo title={item.challenge.name}>{item.challenge.name}</StyledPInfo>
                                </StyledChallenge>
                            )}
                        </StyledInfoCard>
                    </StyledInternal>
                    <StyledAdditionalInformation>
                        <StyledSpanOverflowPadrao>
                            <StyledSpanBold>{t("Application date")}: </StyledSpanBold>
                            {DatetimeFormatByTimezone(item.date_create)}
                        </StyledSpanOverflowPadrao>
                        <StyledSpanOverflowPadrao title={item.current_phase.title}>
                            <StyledSpanBold>{t("Phase")}:</StyledSpanBold>{item.current_phase.title}</StyledSpanOverflowPadrao>
                        {/*<div style={{alignSelf: 'flex-end'}}>*/}
                        {/*    <SpanStatusApplication isApproved={item.status}/>*/}
                        {/*</div>*/}
                    </StyledAdditionalInformation>
                </StyledContent>
            </Col>
        </>
    )
}