import React, {useState} from 'react';
import {Button, Card, Flex, Modal, Typography} from "antd";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import RemixIcon from "../../../../global/components/data_display/RemixIcon";


const {Text, Title} = Typography;

const StyledModal = styled(Modal)`

    .ant-modal-content {
        padding: 0 !important;
    }

    .ant-modal-header {
        padding: 20px;
        background: #000000D9;
    }

    .ant-modal-title .ant-typography {
        font-size: 30px !important;
        color: white !important;
    }

    .ant-modal-body {
        padding: 20px 20px 0 20px;
    }

    .ant-modal-footer {
        padding: 20px !important;
    }
`

const HELP = [
    [
        {
            title: "What is the purpose of the baseline?",
            description: "The financial baseline of a project serves as an initial reference point that documents the planned costs and resources. It is essential for monitoring financial performance throughout the project, allowing comparisons between actual expenditures and the planned values, as well as facilitating cost control and identifying variations that may require adjustments. Additionally, the financial baseline provides a clear and shared view of the expected costs among all stakeholders, which improves communication and decision-making. It also allows for evaluating the effectiveness of financial management after the project's completion, helping to measure its success",
        },
        {
            title: "Why create financial milestones?",
            description: "The financial milestone helps in the future financial vision of the project by providing an estimate based on the financial baseline and what has been spent to generate the forecast graph. The financial milestone can be excluded or modified until the baseline is finalized or the checkpoint data is updated.",
        },
    ],
    [
        {
            title: "What is the forecast model used?",
            description: "To make forecasts, the regression model is used, where historical data is analyzed to predict future values. This model examines the relationship between a dependent variable and one or more independent variables that influence it.\nFor example, in a financial context, regression can be used to predict future sales based on historical sales data, marketing expenses, and economic trends. This provides a solid foundation for predictions and strategic decisions.",
        },
        {
            title: "Track and compare programs and projects",
            description: "With the initial dashboards, the progress of programs and projects can be compared and tracked directly on the home screen, with the option to apply specific filters to optimize the company's performance.",
            icon: <RemixIcon remixIconName="ri-timer-flash-line"/>
        },
    ]
]

export default function FinancePlanningHelpModal({open, setOpen}) {

    const {t} = useTranslation();
    const [currentPage, setCurrentPage] = useState(0)

    return (
        <StyledModal
            open={open}
            width={854}
            closeIcon={false}
            title={
                <Flex gap={8} className="styled-modal-title">
                    <Text level={2}><RemixIcon remixIconName={"ri-funds-line"}/></Text>
                    <Text>
                        {t("Finance Planning")}
                    </Text>
                </Flex>
            }
            footer={
                <Flex gap={8} justify="end">
                    <Button onClick={() => setOpen(false)}>
                        {t("Skip tutorial")}
                    </Button>
                    <Button type="primary"
                            onClick={() => setCurrentPage(currentPage + 1)}
                            disabled={currentPage === HELP.length - 1}
                    >
                        {t("Next")}
                    </Button>
                </Flex>
            }
        >
            <Flex vertical={true} gap={16}>
                <Text>{t("The Solv is an end-to-end innovation management system. To assist in management, we have three main points.")}</Text>

                {HELP[currentPage].map((item, index) => (
                    <Card key={index}>
                        <Flex vertical={true} gap={8}>
                            <Flex gap={8}>
                                {item?.icon && item?.icon}
                                <Title level={5} style={{margin: 0}}>{t(item?.title)}</Title>
                            </Flex>
                            <Text>{t(item?.description)}</Text>
                        </Flex>
                    </Card>
                ))}

            </Flex>
        </StyledModal>
    );
}
