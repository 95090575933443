import React from 'react';
import styled from 'styled-components';
import {Card, Flex, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {ArrowRightOutlined} from "@ant-design/icons";

import TruncateText from "../responsive/TruncateText";

const StyledCard = styled(Card)`
    //max-width: 424px;
    width: 100%;
     background: ${({background}) => background && background};
    cursor: pointer;
    padding: 32px;

    h4 {
        margin: 0;
    }

    & > * {
        margin: 0;
    }

    .ant-card-body {
        padding: 0;
    }
   &:hover {
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

    }
`

export default function CardNumber({icon, label, number, customStyle, ...props}) {
    const {t} = useTranslation()

    const font_color = customStyle?.color || 'black'
    return (

        <StyledCard background={customStyle?.background} {...props}>
            <Flex vertical={true} gap={24}>
                <Flex align="center" gap={4}>
                    {icon && <Typography.Text style={{fontSize: 16, color: customStyle?.color}}>{icon}</Typography.Text>}
                    <TruncateText>
                        <Typography.Title level={4} style={{color: font_color}}>{label}</Typography.Title>
                    </TruncateText>
                </Flex>
                <Flex justify="space-between">
                    <Typography.Title
                        style={{margin: 0, color: font_color}}>{number}</Typography.Title>
                    <ArrowRightOutlined style={{fontSize: 20, color: font_color}}/>
                </Flex>
            </Flex>
        </StyledCard>
    )
}