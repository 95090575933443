import React, {useState} from "react";
import {FlexContainer} from "../authentication/StyledComponents";
import styled from "styled-components";

const Title = styled.h3`
    font-size: 14px;
    font-weight: 700;
`

export default function GamificationInfo({title, value}) {
    return (
        <FlexContainer align={'start'} direction={'column'}>
            <Title>{title}</Title>
            <span>{value}</span>
        </FlexContainer>
    )
}