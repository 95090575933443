import React from 'react'
import styled from 'styled-components'

import {
    CheckCircleOutlined,
    ExclamationCircleOutlined
} from '@ant-design/icons';
import {
    FlexContainer,
    GrayContainer
} from "../authentication/StyledComponents";
import UserAvatar from "../misc/UserAvatar";
import {useTranslation} from "react-i18next";


const Text = styled.span`
    white-space: pre-wrap;
`


export default function ErrorChatMessage({showError, ...props}) {
    const {t} = useTranslation()

    if(!showError) return null
    return (

        <GrayContainer padding={'24px'}>
            <FlexContainer gap={8}  align={'start'}>
                <ExclamationCircleOutlined style={{fontSize: 24}}/>
                <Text>
                    {t('An unexpected error has occurred. Try refreshing the page.')}
                </Text>
            </FlexContainer>

        </GrayContainer>

    );
}