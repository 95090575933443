import React, {useEffect, useState} from 'react'
import {
    Typography,
    Form as FormAnt, Checkbox
} from "antd";
import {useTranslation} from "react-i18next";
import Button from "../../../../../../../global/components/data_entry/Button";
import {
    FlexContainer
} from "../../../../../../../global/components/authentication/StyledComponents";
import WideSpace
    from "../../../../../../../global/components/layout/WideSpace";
import Form from "../../../../../../../global/components/data_entry/form/Form";
import Drawer from "../../../../../../../global/components/layout/Drawer";
import styled from 'styled-components';
import RequestSelectFormField
    from "../../../../../../../global/components/data_entry/form/RequestSelectFormField";
import {modules} from "../../../../../../../settings";
import api from "../../../../../../../global/services/api";
import {
    BuildFieldErrorsDict, jsonToFormData,
} from "../../../../../../../global/utils/Utils";
import {
    ToastNotification
} from "../../../../../../../global/components/feedback/ToastNotification";
import * as ConstantsMessage
    from "../../../../../../../global/utils/fileWithMessage";
import EmailTagsFormField
    from "../../../../../../../global/components/data_entry/form/EmailTagsFormField";

const {Text} = Typography;

const StyledForm = styled(Form)`
  //.ant-form-item {
  //  margin: 0;
  //}
`
const FORM_NAME = {
    PERMISSIONS: 'permission',
    EMAILS: 'emails',
    PHASES: 'phases',
}

export default function ProgramMembersOffCanvas({open, setOpen, program, updateMember, resetTable, phase}) {

    const {t} = useTranslation();
    const [permissions, setPermissions] = useState([]);
    const [form] = FormAnt.useForm()
    const [isLoading, setIsLoading] = useState(false);
    const permissionsValue = FormAnt.useWatch(FORM_NAME.PERMISSIONS, form)
    const excludePermissions = phase ? ['manager'] : [];

    
    useEffect(() => {
            const fetchPermissions = async () => {
                try {
                    const params = {
                        module: modules.PROGRAM
                    }
                    const response = await api.get(`permissions_user_client`, {params});
                    setPermissions(response.data.filter((i) => i.name !== "admin" && !excludePermissions.includes(i.name)).map((item) => {
                        if (item.name === 'manager') {
                            return {
                                ...item,
                                label: t("Program Manager")
                            }
                        } else if (item.name === 'evaluator') {
                            return {
                                ...item,
                                label: t("Evaluator"),
                                key: 'evaluator'
                            }

                        }
                        return item
                    }));
                } catch (error) {
                    BuildFieldErrorsDict(error, null, false);
                }
            };
            fetchPermissions();
        }, []
    )

    useEffect(() => {
        if (open && updateMember) {
            form.setFieldsValue({
                permission: updateMember.permission_user_client,
                emails: updateMember.user_client.user.email,
                phases: updateMember.phases,
            })

        }
    }, [open, updateMember])

    async function onSubmit() {
        try {
            setIsLoading(true)
            const data = await form.getFieldsValue()
            const formData = jsonToFormData(data)
            let response;
            if (updateMember) {
                data.permission.forEach((item) => {
                    formData.append('permission_user_client', item)
                })
                response = await api.patch(`members/${updateMember.id}`, formData);

                ToastNotification(t(ConstantsMessage.SUCCESS));
            } else {
                response = await api.post(`/programs/${program.id}/add_members`, formData);
                ToastNotification(response.data.detail)
            }

            handleCloseDrawer()

        } catch (error) {

            if (error.response.data.detail) {
                ToastNotification(error.response.data.detail, 'warning')
            }
            // todo quando vem uma lista de error so mostra o primeiro index.
            BuildFieldErrorsDict(error, null, false)
        } finally {
            resetTable();
            setIsLoading(false)
        }
    }


    function handleCloseDrawer() {
        form.resetFields()
        setOpen(false)
    }

    return (

        <Drawer
            title={updateMember ? t('Update User') : t("New User")}
            open={open}
            onClose={handleCloseDrawer}
            footer={
                <FlexContainer justify="end"><Button
                    onClick={form.submit}
                    loading={isLoading}
                    data-cy="submit-button">{updateMember ? t('Update') : t("Insert")}</Button></FlexContainer>
            }
        >
            <WideSpace direction="vertical" size="middle"
                       style={{height: "100%"}}>
                <StyledForm form={form}
                            onFinish={onSubmit}
                            disabled={isLoading}>

                    <EmailTagsFormField
                        disabled={updateMember?.id}
                        form={{
                            name: FORM_NAME.EMAILS,
                            rules: [{
                                required: true,
                                message: t('This field is require')
                            }],
                            label: t("Employee email"),
                        }}/>
                    <Typography.Title level={5}>{t("Permissions")}:</Typography.Title>
                    <FormAnt.Item
                        rules={[{
                            required: true,
                            message: t('This field is require'),
                        }]}
                        //alue={phase ? permissions.filter((perm)=>perm.name === 'evaluator').map(perm=>perm.id) : 'undefined'}
                        initialValue={phase && permissions.filter(perm=>perm.name === 'evaluator').map(perm=>perm.id)}
                        name={FORM_NAME.PERMISSIONS}
                        >
                        <Checkbox.Group disabled={!!phase}>
                            <WideSpace direction={'vertical'}
                                       data-cy="permission-checkboxes-container">
                                {permissions.map(({id, label}) => (
                                    <Checkbox
                                        value={id}
                                        data-cy={name.toLowerCase().replace(/ /g, '-')}>
                                        {label}
                                    </Checkbox>
                                ))}
                            </WideSpace>
                        </Checkbox.Group>
                    </FormAnt.Item>
                    {permissionsValue?.some((item) => item === permissions.find((_perm) => _perm.key === 'evaluator')?.id) &&
                        <RequestSelectFormField
                            data-cy="evaluation-phase-select"
                            labelField={'title'}
                            mode={'multiple'}
                            url={`programs/${program.id}/phases?is_evaluator=1`}
                            form={{
                                name:FORM_NAME.PHASES,
                                rules: [{
                                    required: true,
                                    message: t('This field is require')
                                }],
                                initialValue: phase && [phase.id],
                                label: t("Select the evaluation phase you will be allocated"),
                            }}/>}
                </StyledForm>
            </WideSpace>
        </Drawer>

    )
}