import { Card, Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { GrayContainer } from "../../components/authentication/StyledComponents";
import { BugFilled, StarFilled } from "@ant-design/icons";
import DangerousHtmlPreviewer from "../../components/data_display/DangerousHtmlPreviewer";
import WideSpace from "../../components/layout/WideSpace";
import { DateFormatByUserTimezone } from "../../utils/dates";


const { Text, Title } = Typography;

function ReleaseNoteCard({release}) {
    const { t } = useTranslation();
    return (
        <Card>
            <WideSpace direction='vertical' size={32}>
                <div>
                    <Title level={2} style={{ margin: 0 }}> {t("Version")} {release?.version}</Title>
                    <Text type="secondary">
                        {t("Launched on")} {DateFormatByUserTimezone(release?.release_date)}
                    </Text>
                </div>
                <GrayContainer>
                    <WideSpace direction='vertical'>
                        <Text strong>{t("Quick summary")}</Text>
                        <DangerousHtmlPreviewer content={release?.summary} />
                    </WideSpace>
                </GrayContainer>

                <div>
                    <Space size='small' justify={'center'} style={{marginBottom:10}}>
                        <StarFilled style={{fontSize: 20}}/>
                        <Title level={4} style={{marginBottom:0}}>{t("What's new?")}</Title>
                    </Space>
                    <DangerousHtmlPreviewer content={release?.release_news} />
                </div>

                <div>
                    <Space size='small'>
                        <BugFilled style={{fontSize: 14}}/>
                        <Text style={{fontSize: 16}} strong>{t("Bug fixes")}</Text>
                    </Space>
                    <DangerousHtmlPreviewer content={release?.release_bugfixes} />
                </div>
            </WideSpace>
        </Card>
    );
}

export default ReleaseNoteCard;
