import { Button } from "antd";
import styled from "styled-components";

const StyledButton = styled(Button)`

    color: ${props=>props.color} !important;
    border-color: ${props=>props.color} !important;
    svg {
        fill: ${props=>props.color} !important;
    }

    &:hover {
        color: white !important;
        background: ${props=>props.color} !important;
        svg {
            fill: white !important;
        }
    }
`;

export default function ColorfulButton({children, color, ...props}) {
    return <StyledButton color={color} {...props}>{children}</StyledButton>
}