import {Scatter} from "@ant-design/charts";
import React, {useEffect, useState} from "react";
import api from "../../../../../../global/services/api";
import {ChartRenderer, truncateChars} from "../../utils/chartUtils";
import {useTranslation} from "react-i18next";
import {useThemeContext} from "../../../../../../global/context/ThemeContext";
import {BuildFieldErrorsDict} from "../../../../../../global/utils/Utils";
import { formatNumber } from "../../../../utils/utils";
import ChartLegendWrapper from "../../../../../../global/components/data_display/ChartWrapper/ChartLegendWrapper";

function ROIChart({filters,parent, columnOptions, expand}) {

    const [data, setData] = useState(undefined);
    const [isActive, setIsActive] = useState(undefined);
    const {themeConfig} = useThemeContext();
    const {t} = useTranslation();

    const xField = "roi"
    const yField = "risk"
    const sizeField = "project_investment"
    const colorField = "project"
    const colorSourceKey = "color"

    const xyConfig = {
        grid: true,
        gridLineWidth: 1,
    }

    const getTooltipConfig = (d, column, channel) => {
        const field = column[channel].field
        return ({
            channel,
            name: t(field),
            value:channel === 'x'? `R$ ${formatNumber(d[field])}` : d[field],
            color: d.color
        })
    }

    const fetchData = async () => {
        try {
            const response = await api.post(`/analytics/dashboard/project_client_dashboard/${themeConfig.client.id}/anual_return_on_investment_report`, filters);
            setData(response.data.responses);
            setIsActive(response.data.is_active);
        } catch (exception) {
            console.error(error);
            BuildFieldErrorsDict(error, null, false);
        }
    };

    const axis = {
        x: {
            title: 'ROI',
            labelFormatter: (text) => `R$ ${formatNumber(text)}`,
            labelAlign: "horizontal",
            size: 40,
            tickLength: 0,
            labelAutoHide: true,
            ...xyConfig,
        },
        y: {
            title: `${t('Risk')}`,
            tickCount: 10,
            tickLength: 0,
            ...xyConfig,
        }
    }

    const config = {
        data,
        xField,
        yField,
        sizeField,
        colorField,
        autoFit:true,
        animate:null,
        axis: {
            x: {
                title: 'ROI',
                labelFormatter: (text) => `R$ ${truncateChars(formatNumber(text),5)}`,
                labelAlign: "horizontal",
                size: 40,
                tickLength: 0,
                labelAutoHide: true,
                ...xyConfig,
            },
            y: {
                title: `${t('Risk')}`,
                tickCount: 10,
                tickLength: 0,
                ...xyConfig,
            }
        },
        scale: {
            size: {type: 'linear', range: [4, 30]},
            y: {domain: [0, 10]}
        },
        style: {
            fill: (d) => `${d.color}a0`,
            stroke: (d) => d.color,
            cursor:"pointer",
        },
        tooltip: {
            title: (d) => d[colorField],
            items: [
                (d, index, data, column) => (getTooltipConfig(d, column, "x")),
                (d, index, data, column) => (getTooltipConfig(d, column, "y")),
                (d) => ({
                    name: t("Investment in the project"),
                    value: `R$ ${formatNumber(d.project_investment)}`,
                    color: d.color
                }),
            ],
        },
        _legend: {
            colorField,
            colorSourceKey,
            rows: 1,
            cols: 3,
        },
        drawerConfig:{
            axis,
            _legend:{
                rows:2,
            },
        },
        // onReady: ({chart}) => {
        //     chart.on('point:click', (e)=> {
        //         console.log(e)
        //     });
        // }
    };

    useEffect(() => {
        void fetchData();
    }, [filters]);
    
    return (
        <ChartRenderer 
            data={data} 
            chart={<ChartLegendWrapper chart={Scatter} config={config}/>}
            parent={parent}
            isActive={isActive}
            columnOptions={columnOptions}
            expand={expand}
        />
    );
}

export default ROIChart;
