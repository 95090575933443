import React from 'react';
import {Space, Typography} from "antd";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import styled from 'styled-components';

const StyledText = styled(Typography.Text)`
  font-size: ${({size}) => `${size}px`};
`

export default function ShortInformation({size=12, children}) {


    return (


        <StyledText type={'secondary'} size={size}>

            <Space>
                <ExclamationCircleOutlined/>
                {children}
            </Space>
        </StyledText>

    )
}