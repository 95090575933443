import {useProgramDetailContext} from "../context/ProgramDetailContext";
import FilesTable from "../../../../components/data_entry/FilesTable";

export default function AttachmentsProgramTab() {
    const {program, hasProgramPermission} = useProgramDetailContext()
    return (
        <FilesTable
            hasPermission={hasProgramPermission}
            fileType="program"
            id={program?.id}
            table={{
            extraColumns: [],
            request: {
                url_post: `/file_upload/content/application/object/${program?.id}/upload/`,
                url_get: `/file_upload/content/application/object/${program?.id}/list_uploads/`
            }
        }}/>
    );
}

