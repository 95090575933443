import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Col, Row, Typography} from "antd";
import {
    FlexContainer
} from "../../../../components/authentication/StyledComponents";
import Search from "../../../../components/data_entry/Search";
import Pagination from "../../../../components/navigation/Pagination";
import Loading from "../../../../components/feedback/Loading";
import Empty from "../../../../components/data_display/Empty";
import StoreItemCard from "./components/StoreItemCard";
import DrawerBuyStreItem from "./components/DrawerBuyStreItem";

export default function StorePage() {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false)
    const [storeItemId, setStoreItemId] = useState()
    const [isLoading, setIsLoading] = useState()
    const [storeItens, setStoreItens] = useState([])
    const [search, setSearch] = useState([])

    const PaginationRequest = {
        url: `/store_itens`,
        setIsLoading: setIsLoading,
        setData: setStoreItens,
        extraParams: {
            search: search
        },
        dependence: [search]

    }

    function handleStoreItem(id){
        setStoreItemId(id)
        setOpen(true)
    }

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24} lg={14}>
                    <Typography.Title style={{marginBottom: 0}} level={5}>
                        {t('Store Items')}
                    </Typography.Title>
                </Col>
                <Col xs={24} lg={10}>
                    <FlexContainer gap={16}>
                        <Search onSearch={setSearch}/>

                    </FlexContainer>

                </Col>
                <Col xs={24}>
                    <Row>
                        {isLoading ? <Col xs={24}><Loading/> </Col> :
                            <Col xs={24}>
                                <Row gutter={[24, 24]}>
                                    {storeItens?.length > 0 ?

                                        storeItens?.map((storeItem) =>

                                            <Col key={storeItem?.id} xs={24}
                                                 md={12} lg={8} xxl={4}
                                            ><StoreItemCard
                                                onClick={() => handleStoreItem(storeItem.id)}
                                                key={storeItem.id}
                                                storeItem={storeItem}/></Col>)

                                        : <Col xs={24}><Empty description={t('There are no items available for purchase in the store.')}/></Col>}
                                </Row>
                            </Col>
                        }

                    </Row>
                </Col>
                <Col xs={24}>
                    <Row justify={'end'}>
                        <Col>
                            <Pagination request={PaginationRequest}/>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <DrawerBuyStreItem setOpen={setOpen} open={open} id={storeItemId}/>
        </>
    )
        ;
}