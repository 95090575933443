import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import Button from "../../../../../components/data_entry/Button";
import useTable from "../../../../../hooks/useTable";
import {Col, Row} from "antd";
import Table from "../../../../../components/data_display/Table";
import Drawer from "../../../../../components/layout/Drawer";
import Search from "../../../../../components/data_entry/Search";
import CustomTag from "../../../../../components/tag/CustomTag";
import api from "../../../../../services/api";
import {BuildFieldErrorsDict} from "../../../../../utils/Utils";
import {
    useLayoutContext
} from "../../../../../../modules/project/components/layout/context/LayoutContext";


const Title = styled.h2`
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0px;
`


export default function DrawerTagReletedObject({
                                                   updateListLocalContent,
                                                   tag,
                                                   open,
                                                   setOpen
                                               }) {

    const [search, setSearch] = useState('')
    const [hasChange, setHasChange] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const {updateLayoutContent} = useLayoutContext()
    const {t} = useTranslation();
    const {
        tableData,
        isLoading: isLoadingTable,
        tableParams,
        handleTableChange,
        resetTable
    } = useTable({
        url: `api/tags/${tag?.id}/get_all_related_objects`,
        waitExternalLoading: !open,
        extraParams: {search: search},
        hasPagination: true,
        dependenceRequest: [open, search, tag?.id]
    })


    function onClose() {
        setSearch('')
        if (hasChange) {
            updateListLocalContent()
            setHasChange(false)
        }
        setOpen(false)
    }

    const columns = [
        {
            title: t('Title'),
            dataIndex: 'title',
            sorter: "name",
            key: 'title',
            width: 200

        },
        {
            title: t('Type'),
            sorter: "type",
            dataIndex: 'type',
            key: 'type',
            render: (value) => {
                const target_values = {
                    'userclient': t('User'),
                    'program': t('Program'),
                    'project': t('Project'),
                    'application': t('Application')
                }
                return target_values[value]
            }
        },
        {
            title: t("Actions"),
            dataIndex: "action",
            key: "action",
            render: (_, record) => (

                <Button loading={isLoading}
                        onClick={() => handleRemoveRelated(record)}
                        type="link">{t("Delete")}</Button>


            ),
        },

    ]

    async function handleRemoveRelated(record) {
        try {
            setIsLoading(true)
            const response = await api.post(`/api/tags/${tag.id}/${record.type}/${record.id}/delete_tag_at_object`);
            setHasChange(true)
            resetTable()
        } catch (error) {
            BuildFieldErrorsDict(error);
        } finally {
            setIsLoading(false)
        }
    }


    return (


        <Drawer width={1057}
                title={<CustomTag icon={tag?.icon} color={tag?.color}
                                  name={tag?.name}/>} open={open}
                onClose={onClose}>

            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <Title>
                        {t('All items in this tag')}
                    </Title>
                </Col>
                <Col xs={24} md={12}>
                    <Search onSearch={setSearch}/>


                </Col>
                <Col xs={24}>
                    <Table
                        columns={columns}
                        onChange={handleTableChange}
                        tableParams={tableParams}
                        loading={isLoadingTable}
                        noScroll
                        dataSource={tableData}
                    />
                </Col>
            </Row>
        </Drawer>
    )
}
