import React, {createContext, useContext, useEffect, useState} from 'react';
import {Table as AntTable} from "antd";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import styled from "styled-components";
import useEventListener from "../../hooks/useEventListener";
import api from "../../services/api";
import {ToastNotification} from "../feedback/ToastNotification";
import Empty from "./Empty";

const DraggableTableContext = createContext({})

function DraggableTableContextProvider({children, orderKey, fetchUrl, patchUrl, id, dataSource, setDataSource}) {

    const [data, setData] = useState()

    // todo: add loading

    const fetchData = async () => {
        try {
            const {data} = await api.get(fetchUrl)
            setData(data)
        } catch (error) {
            ToastNotification(error.response.data.detail, 'error')
        } finally {

        }
    }

    const patchData = async (data) => {
        try {
            const payload = data.map((item) => ({id: item.id, [orderKey]: item[orderKey]}))
            void api.patch(patchUrl, payload)
        } catch (error) {
            ToastNotification(error.response.data.detail, 'error')
        } finally {

        }
    }

    const onDragEnd = (result) => {

        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const startIndex = result.source.index - 1;
        const endIndex = result.destination.index - 1;
        const cloneData = Array.from(data);
        const [removed] = cloneData.splice(startIndex, 1);

        cloneData.splice(endIndex, 0, removed);

        const sortedResults = cloneData.map((item, index) => ({...item, [orderKey]: index + 1}))

        patchUrl && void patchData(sortedResults)
        setData(sortedResults);
        setDataSource && setDataSource(sortedResults)

    };

    useEventListener(`refresh-ordering-table-${id}`, () => {
        void fetchData()
    })

    useEffect(() => {
        !data && dataSource && setData(dataSource)
        !data && fetchUrl && fetchData()
    }, [data]);

    return (
        <DraggableTableContext.Provider value={{onDragEnd, data, orderKey}}>
            {children}
        </DraggableTableContext.Provider>
    )
}


const DroppableTableBody = ({...props}) => {
    const {onDragEnd} = useContext(DraggableTableContext)

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided) =>
                    <>
                        <tbody {...props} ref={provided.innerRef} {...provided.droppableProps} />
                        {provided.placeholder}
                    </>
                }
            </Droppable>
        </DragDropContext>
    )
}

const StyledRow = styled.tr`
    background: ${props => (props.snapshot.isDragging) && "#fafafa"};
    cursor: pointer;
`

const DraggableRow = ({className, children, ...props}) => {
    const key = props["data-row-key"]

    return (
        <Draggable
            key={key}
            draggableId={String(key)}
            index={key}
        >
            {
                (provided, snapshot) => {

                    const transform = provided?.draggableProps?.style?.transform;
                    if (transform) {
                        const t = transform.split(',')[1].replace(")", "");
                        provided.draggableProps.style.transform = `translate(0px, ${t})`;
                    }

                    return (
                        <>
                            <StyledRow className={className}
                                       snapshot={snapshot}
                                       ref={provided.innerRef}
                                       {...props}
                                       {...provided.draggableProps}
                            >
                                {children.length > 0 && children.map((child) => {
                                    return (React.cloneElement(child, {
                                            record: {
                                                ...child.props.record,
                                                _dragHandleProps: provided.dragHandleProps
                                            }
                                        }
                                    ))
                                })}
                            </StyledRow>
                            {provided.placeholder}
                        </>
                    )
                }
            }
        </Draggable>
    )
}

function Table({columns, onRowClick, ...props}) {
    const {data, orderKey} = useContext(DraggableTableContext)

    const getComponents = (data) => {
        if (data?.length > 0) {
            return ({
                body: {
                    wrapper: DroppableTableBody,
                    row: DraggableRow
                }
            })
        }
    }

    return (
        <AntTable
            pagination={false}
            dataSource={data}
            columns={columns}
            rowKey={orderKey}
            onRow={(record) => {
                return {
                    onClick: (e) => onRowClick && onRowClick(record)
                }
            }}
            components={getComponents(data)}
            scroll={{x: "max-content"}}
            locale={{emptyText: <Empty/>}}
            loading={!data}
            {...props}
        />
    )
}

function OrderingTable({
                           orderKey = "sequence",
                           columns,
                           dataSource,
                           setDataSource,
                           fetchUrl,
                           patchUrl,
                           onRowClick,
                           id = "0",
                           ...props
                       }) {
    // TODO: tentar deixar apenas data ou dataSource

    return (
        <DraggableTableContextProvider fetchUrl={fetchUrl} patchUrl={patchUrl} orderKey={orderKey} id={id}
                                       dataSource={dataSource} setDataSource={setDataSource}>
            <Table columns={columns} onRowClick={onRowClick} {...props}/>
        </DraggableTableContextProvider>
    )
}

export default OrderingTable;