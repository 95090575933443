import {Card, Skeleton, Typography} from 'antd';
import styled from 'styled-components';
import React from 'react';
import TruncateText from '../responsive/TruncateText';
import Flex from '../responsive/Flex';
import { neutral_3 } from '../../utils/Colors';
import {FlexContainer} from "../authentication/StyledComponents";

const { Text } = Typography;

const StyledCard = styled(Card)`
    //box-shadow: 0px 0px 8px #00000026;
    height: 80px;
    display: flex;
    padding: 12px;
    overflow: hidden;
    justify-content: space-between;
    border: 1px solid ${neutral_3};
    border-radius: 6px;
    .ant-card-body{
        padding: 0;
        width: 100%;
    }
    span {
        color: ${props => props.$color};
    }
`
const StyledTextName = styled(Text)`
    font-size: 12px;
    font-weight: 400;
`
const StyledTextValue = styled(Text)`
    font-size: 20px;
    font-weight: 700;
    white-space: nowrap;
`;

const StyledTextIcon = styled(Text)`
    font-size: 16px;
`;

const StyledExtraIcon = styled(Text)`
    width: 42px;
`;

const StyledTotalValue = styled.span`
  font-size: 16px; 
  margin-left: 4px;
`;

const StyledTextWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export default function MetricsCard({ value, loading,totalValue, name, icon, extraIcon, color = "#000" }) {
    return (
        <StyledCard $color={color}>
             {loading ? <Skeleton active={true} paragraph={true} style={{paddingTop:8}}/> : <>
             <Flex justify="space-between" vertical gap={8}>
                <TruncateText lines={1}>{<StyledTextName>{name}</StyledTextName>}</TruncateText>

                <Flex align='center' gap={8} grow={true}>
                    <StyledTextIcon>{icon}</StyledTextIcon>
                    <StyledTextWrapper gap={8}>
                        <StyledTextValue>{value}</StyledTextValue>
                        {totalValue && (
                            <StyledTextValue>{`/${totalValue}`}</StyledTextValue>
                        )}
                    </StyledTextWrapper>

                </Flex>

            </Flex>
            </>}

        </StyledCard>
    );
}
