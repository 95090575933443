import React, {useEffect, useState} from 'react';


import {useTranslation} from "react-i18next";
import {
    SettingOutlined
} from "@ant-design/icons";
import Button from "../../../../../../global/components/data_entry/Button";

import Drawer from "../../../../../../global/components/layout/Drawer";

import PhaseMembers from "./Members/PhaseMembers";
import {
    useLayoutContext
} from "../../../../../../modules/project/components/layout/context/LayoutContext";


export default function ButtonManagerEvaluator({program, phase}) {
    const {t} = useTranslation()
    const [open, setOpen] = useState()
    const [updatePage, setUpdatePage] = useState()
    const {updateLayoutContent} = useLayoutContext()


    useEffect(() => {
        if (!open && updatePage) {

            updateLayoutContent()
        }
    }, [open]);

    function handleOpenModal() {
        setOpen(true)
        setUpdatePage(false)
    }

    function updatePageOnClose() {
        setUpdatePage(true)
    }

    return (

        <>
            <Button icon={<SettingOutlined/>}
                    onClick={handleOpenModal}>{t('Manage')}
            </Button>

            <Drawer open={open} onClose={() => setOpen(false)}
                    title={t('Manage evaluators of this phase')}
                    width={'1024px'}>
                <PhaseMembers updatePageOnClose={updatePageOnClose}
                              program={program} phase={phase}/>
            </Drawer>
        </>


    )
}