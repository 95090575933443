import React, {useEffect} from 'react'
import {
    Col,
    Divider,
    Form as FormAntd,
    Row,
    Typography,
    Radio,
    Space
} from 'antd';
import {
    FlexContainer,
    QuestionContainer,
    QuestionTitle
} from "../../../../authentication/StyledComponents";
import WideSpace from "../../../../layout/WideSpace";
import {useTranslation} from "react-i18next";
import {PieChartOutlined} from '@ant-design/icons'
import styled from 'styled-components'
import TextArea from "../../../TextArea";
import Required from "./Required";
import FieldError from "./FieldError";

const {Text} = Typography;

const Weight = styled.span`
    font-size: 12px;
`

const ScoreLabel = styled.span`
    font-weight: bold;
    font-size: 14px;
`

const StyledRadio = styled(Radio)`
    margin-right: 0px;

`

export default function ScoreEvaluationField({
                                                 label,
                                                 help_text,
                                                 required,
                                                 weight,
                                                 show_feedback,
                                                 onChange,
                                                 value,
                                                 name,
                                                 min = 0,
                                                 max = 10,
                                                 ...props
                                             }) {
    const {t} = useTranslation()
    const {status} = FormAntd.Item.useStatus(); //error
     const hasError = status === 'error'

    max = max > 10 ? 10 : max
    min = min < 0 ? 0 : min
    if (min > max) {
        let aux = min
        min = max
        max = aux
    }
    // console.log(value)
    const SCORES = Array.from({length: max+1}, (_, i) => i ).slice(min)

    function handleScoreChange({target: {value: fieldValue}}) {
        onChange?.({...value, score: fieldValue})
    }

    function handleFeedBackChange({target: {value: fieldValue}}) {
        onChange?.({...value, feedback: fieldValue})
    }

    
    function checkErrorScore(){
        return hasError && !value?.score
    }
    function checkErrorFeedback(){
        return hasError && !value?.feedback
    }

    return (

        <QuestionContainer {...props}>
            <Row gutter={[19, 19]}>
                <Col xs={24}>
                    <Row>
                        <Col flex={'1'}>
                            <WideSpace direction={'vertical'} size={0}>
                                <QuestionTitle>
                                    {label && <Required required={required}/>}
                                    {label}
                                </QuestionTitle>
                                <Typography.Text
                                    style={{fontSize: 14}}
                                    type={'secondary'}>{help_text}</Typography.Text>
                            </WideSpace>
                        </Col>
                        {weight !== 0 && <Col >
                            <FlexContainer justify={'end'} gap={8}>
                                <PieChartOutlined/>
                                <Weight>{`${t('Weight')}: ${weight}%`}</Weight>
                            </FlexContainer>
                        </Col>}
                    </Row>
                </Col>
                <Col xs={24}>
                    <Divider style={{marginTop: 0, marginBottom: 8}}/>

                    <FlexContainer justify={'center'} style={{border: checkErrorScore()? '1px solid red': 'none'}}>

                        <Radio.Group value={value?.score}
                                     onChange={handleScoreChange} data-cy="score-container">
                            <Space size={16}>
                                {SCORES.map((value) => (
                                    <Space key={value}
                                           direction={'vertical'}
                                           align={'center'}
                                           size={4} data-cy="score-radio">
                                        <StyledRadio value={value}/>
                                        <ScoreLabel>
                                            {value}
                                        </ScoreLabel>

                                    </Space>
                                ))}


                            </Space>


                        </Radio.Group>
                    </FlexContainer>
                    {checkErrorScore() && <FieldError/>}
                </Col>

                {show_feedback && <Col xs={24}>
                    <QuestionTitle>
                        <Required required={required}/>{t('Feedback')}:
                    </QuestionTitle>
                    <TextArea onChange={handleFeedBackChange}
                        // status={checkErrorFeedback() && 'error'}
                        style={{marginTop: 8}}
                        value={value?.feedback}
                        data-cy="score-feedback-input"
                    />
                    {checkErrorFeedback() && <FieldError/>}
                </Col>}
            </Row>

        </QuestionContainer>


    )
}
