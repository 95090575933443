import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Loading from "../../../../../global/components/feedback/Loading";
import CardHistory
    from "../../../../../global/components/data_display/CardHistory";
import Empty from "../../../../../global/components/data_display/Empty";
import api from "../../../../../global/services/api";
import {BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import Drawer from "../../../../../global/components/layout/Drawer";

export default function ProgramHistoryOffCanvas({program, open, ...props}) {
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [logs, setLogs] = useState([])


     useEffect(() => {
         if(open) fetchLogs()
    }, [open])

    const fetchLogs = async () => {
        try {
            setIsLoading(true)
            const response = await api.get(`/logs?id=${program.id}&model=program`);
            setLogs(response.data)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Drawer open={open} title={t('History')} {...props}>
            {isLoading ? <Loading/> : logs.length > 0 ?
                (logs.map((item, index) =>
                    <CardHistory key={index} {...item}/>
                )) : <Empty/>
            }
        </Drawer>

    );
}

