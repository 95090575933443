import {Button, Col, Form, Input, Row} from "antd";
import React, {useEffect, useState} from "react";
import {UserAddOutlined} from "@ant-design/icons";
import SolvCollapse from "../../../../../global/components/misc/SolvCollapse";
import {useTranslation} from "react-i18next";
import ProjectMemberTable from "../../../components/tables/ProjectMemberTable";
import {useParams} from "react-router-dom";
import api from "../../../../../global/services/api";
import MemberDrawer from "./MemberDrawer";


function MemberList() {
    const [openMemberDrawer, setOpenMemberDrawer] = useState(false);
    const [members, setMembers] = useState(undefined);
    
    const {t} = useTranslation();
    const {id} = useParams();
    const [form] = Form.useForm();


    const fetchMembers = async (params) => {
        try {
            const response = await api.get(`/project_management/table/${id}/member_table`, {params});
            setMembers(response.data.items);
        } catch (exception) {
        }
    };

    const handleSearch = (data) => {
        void fetchMembers(data);
    }

    useEffect(() => {
        if (!members) {
            void fetchMembers();
        }
    }, [members]);

    return <>
        <MemberDrawer open={openMemberDrawer} setOpen={setOpenMemberDrawer} setDataSource={setMembers}/>
        <SolvCollapse title={t("Members")} active={true} locked={true} bordered={true} >
            <div style={{padding: "16px 8px"}}>
                <Row justify="space-between">
                    <Col>
                        <Form onFinish={handleSearch} form={form}>
                            <Form.Item name={"search"}>
                                <Input.Search placeholder={t("Search Member")} onSearch={form.submit}/>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col push="right">
                        <Button type="primary" onClick={() => setOpenMemberDrawer(true)} data-cy="new-member-button"><UserAddOutlined/>{t("New Member")}</Button>
                    </Col>
                </Row>
            </div>
            <ProjectMemberTable dataSource={members} setDataSource={setMembers} />
        </SolvCollapse>
    </>;
}

export default MemberList;