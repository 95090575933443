import {useTranslation} from "react-i18next";
import FilterButton
    from "../../../../../../global/components/button/FilterButton";
import Drawer from "../../../../../../global/components/layout/Drawer";
import {
    FlexContainer
} from "../../../../../../global/components/authentication/StyledComponents";
import Button from "../../../../../../global/components/data_entry/Button";
import Form from "../../../../../../global/components/data_entry/form/Form";
import {APPLICATION_TAG} from "../../../../../../global/utils/Choices";
import React, {useEffect, useState} from "react";
import {Form as FormAnt} from "antd";

import FilterTags from "../../../../../../global/components/other/FilterTags";

const FORM_NAMES = {
    TAGS_ISNULL: 'tags__isnull',
    TAGS__ID__IN: 'tags__id__in[]'
}

export default function ButtonFilterPhaseDrawer({
                                                    setFilters,
                                                    filters,
                                                    isLoading,
                                                }) {
    const {t} = useTranslation();

    const [open, setOpen] = useState(false)
    const [form] = FormAnt.useForm()
    // const [internalFilters, setInternalFilters] = useState({})

    useEffect(() => {
        if (open) {
            form.setFieldsValue({
                [FORM_NAMES.TAGS_ISNULL]: filters[FORM_NAMES.TAGS_ISNULL],
                [FORM_NAMES.TAGS__ID__IN]: filters[FORM_NAMES.TAGS__ID__IN]
            });
            // if (Object.values(filters).length > 0) {
            //     // console.log(filters)
            //     form.setFieldsValue({})
            // } else {
            //     form.resetFields()
            // }
        }
    }, [open])

    function handleSubmit(data) {

        if (data[FORM_NAMES.TAGS_ISNULL] || data[FORM_NAMES.TAGS__ID__IN]?.length === 0 || !data[FORM_NAMES.TAGS__ID__IN]) {

            delete data[FORM_NAMES.TAGS__ID__IN]

        }
        if (!data[FORM_NAMES.TAGS_ISNULL]) {
            delete data[FORM_NAMES.TAGS_ISNULL]
        }
        setFilters(data)
        onClose()
    }


    function handleResetFilters() {
        form.resetFields()
    }

    function onClose() {
        setOpen(false)
    }

    return (
        <>
            <FilterButton
                filters={filters}
                onClick={() => setOpen(true)}/>
            <Drawer title={t('Filters')} open={open}
                    onClose={onClose}
                    footer={<FlexContainer justify={'end'} gap={10}>
                        <Button
                            type={'default'}
                            onClick={handleResetFilters}>{t('Reset filters')}</Button>
                        <Button onClick={form.submit}>{t('Save')}</Button>
                    </FlexContainer>}
            >
                <Form style={{marginTop: 8}} form={form}
                      disabled={isLoading}
                      onFinish={handleSubmit}>
                    <FilterTags filterName={FORM_NAMES.TAGS__ID__IN}
                                noTagName={FORM_NAMES.TAGS_ISNULL}
                                targets={[APPLICATION_TAG]}/>
                </Form>
            </Drawer>
        </>
    )
}