import {useTranslation} from "react-i18next";
import useSystemBreakpoint from "../../../../../../../../hooks/useSystemBreakpoint";
import {responseYesOrNoTagColor} from "../../../../../../../../utils/Colors";
import SolvTag from "../../../../../../../tag/SolvTag";
import {Row, Space, Typography} from "antd";
import React, {Fragment} from "react";
import PercentTable from "../../../../../../../feedback/PercentTable";
import SolvTable from "../../../../../../../tables/SolvTable";

const {Text} = Typography


export default function AssessmentFormStatisticResponseYesOrNo({comp_yes_no_questions, viewerMode}) {
    const {t} = useTranslation();
    const {isDesktop} = useSystemBreakpoint();

    const getResponseInfo = (value) => {
        let displayValue = '';
        let statusColor = responseYesOrNoTagColor[""];

        if (value === true) {
            displayValue = t('Yes');
            statusColor = responseYesOrNoTagColor["yes"];
        } else if (value === false) {
            displayValue = t('No');
            statusColor = responseYesOrNoTagColor["no"];
        }

        return {displayValue, statusColor};
    };

    const columns = [
        {
            title: t("Evaluator"),
            dataIndex: "evaluator",
            width: isDesktop && 224,
            render: (value) => viewerMode ? "-" : value
        },
        {
            title: t("Answer"),
            dataIndex: "answer",
            width: isDesktop && 184,
            render: (value, record) => {
                const {displayValue, statusColor} = getResponseInfo(value);
                return <SolvTag color={statusColor} style={{textTransform: 'capitalize'}}>{displayValue}</SolvTag>;
            }
        },
    ];

    return <Space style={{width: "100%"}} direction="vertical" size={16}>
        {comp_yes_no_questions.map((question, key) => <Fragment key={key}>
            <Row justify="space-between" align="middle">
                <Text style={{fontSize: 16, fontWeight: 500}}>{t(question.title)}</Text>
                {question.total >= 0 &&
                    <Space size={4}>
                        <Text style={{fontSize: 12, fontWeight: 400}}
                              type="secondary">{t("Percentages of yes responses")}: </Text>
                        <PercentTable style={{fontSize: 24, fontWeight: 400}}
                                      value={question.total}>{`${question.total}%`}</PercentTable>
                    </Space>
                }
            </Row>
            <SolvTable columns={columns} dataSource={question.responses}/>
        </Fragment>)}
    </Space>
}
