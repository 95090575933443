import Cookies from 'js-cookie'
import api from "../services/api";

import {SESSION_COOKIE_DOMAIN, SESSION_COOKIE_PREFIX} from "../../settings";

export const cookie_api = Cookies.withAttributes({path: '/', domain: SESSION_COOKIE_DOMAIN, expires: 365 * 20})

export function hasAcceptedCookies() {
    return !!cookie_api.get(`${SESSION_COOKIE_PREFIX}_cookie_accepted`)
}

export function saveAcceptedCookies() {
    cookie_api.set(`${SESSION_COOKIE_PREFIX}_cookie_accepted`, true)
    return true

}
