let endpoint;
let ws_endpoint;
let debug;
let reactAppGoogleApiKey;
let sessionCookieDoamin;
let hostEntrepreneur;
let sessionCookiePrefix;
let enviroment_name;
let react_app_version;

if (process.env.REACT_APP_ENV === 'prod') {
    endpoint = 'https://api.solv.network/'
    ws_endpoint = 'wss://api.solv.network/ws/'
    sessionCookieDoamin = '.solv.network';
    hostEntrepreneur = 'app.solv.network';
    sessionCookiePrefix = 'solv_network';
    enviroment_name = 'PROD';
    debug = false

} else if (process.env.REACT_APP_ENV === 'homo') {
    endpoint = 'https://api.homolog.solv.network/'
    ws_endpoint = 'wss://api.homolog.solv.network/ws/'
    sessionCookieDoamin = '.homolog.solv.network';
    hostEntrepreneur = 'app.homolog.solv.network';
    sessionCookiePrefix = 'solv_homolog';
    enviroment_name = 'HOMOLOGATION';
    debug = false

} else if (process.env.REACT_APP_ENV === 'demo') {
    endpoint = 'https://api.demo.solv.network/'
    ws_endpoint = 'ws://api.demo.solv.network/ws/'
    sessionCookieDoamin = '.demo.solv.network';
    hostEntrepreneur = 'app.demo.solv.network';
    sessionCookiePrefix = 'solv_demo';
    enviroment_name = 'DEMONSTRATION';
    debug = false
} else {
    endpoint = 'http://api.solv.networklocal:8000/'
    ws_endpoint = 'ws://api.solv.networklocal:8001/ws/'
    sessionCookieDoamin = '.solv.networklocal';
    hostEntrepreneur = 'app.solv.networklocal:3000';
    // sessionCookieDoamin = 'localhost';
    // hostEntrepreneur = 'localhost:3000';
    sessionCookiePrefix = 'solv_local';
    enviroment_name = 'LOCAL';
    debug = true
}


if (process.env.REACT_APP_GOOGLE_API_KEY) {
    reactAppGoogleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
}

if (process.env.REACT_APP_VERSION) {
    react_app_version = process.env.REACT_APP_VERSION;
}

// if (process.env.MICROSOFT_APP_CLIENT_ID) {
//     microsoft_client_id = process.env.MICROSOFT_APP_CLIENT_ID;
// }

export const modules = {
    PROGRAM: 'program',
    PROJECT: 'project',
}

export const BASE_ENDPOINT = endpoint;
export const WS_ENDPOINT = ws_endpoint;
export const DEBUG = debug;
export const SESSION_COOKIE_DOMAIN = sessionCookieDoamin;
export const HOST_ENTREPRENEUR = hostEntrepreneur;
export const SESSION_COOKIE_PREFIX = sessionCookiePrefix;
export const REACT_APP_GOOGLE_API_KEY = reactAppGoogleApiKey;
export const MICROSOFT_APP_CLIENT_ID = "268616f7-47e5-4948-9284-75bf0ddab4d5";
export const GOOGLE_APP_CLIENT_ID = "525426341570-im09f8mp792ujq2dtdkjsimsj8nfq3r9.apps.googleusercontent.com";
export const GOOGLE_CAPTCHA_SITE_ID = "6LfzgfgpAAAAADCqr08QliqETwS3meZi45qMac04";
export const ENVIROMENT = enviroment_name;
export const APP_VERSION = react_app_version;