import {Col, Row} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import Search from "../../components/data_entry/Search";
import Loading from "../../components/feedback/Loading";
import Empty from "../../components/data_display/Empty";
import Pagination from "../../components/navigation/Pagination";
import CardTopic from "../../components/data_display/CardTopic";
import {useHistory} from "react-router-dom";
import ShortInformation from "../../components/data_display/ShortInformation";


export default function ListTopics() {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState()
    const [topics, setTopics] = useState([])
    const [search, setSearch] = useState('')
    const history = useHistory();


    return (
        <Row gutter={[12, 12]}>
            <Col xs={24}>
                <Search placeholder={t("Search")} onSearch={setSearch}/>
            </Col>
            <Col xs={24}>
                <ShortInformation>
                    {t("This is your message center. Listed below are the programs you are participating in or managing. Select a program to view message threads from that program's applications.")}
                </ShortInformation>
            </Col>
            <Col xs={24}>

                <Row gutter={[16, 16]}>
                    {isLoading ? <Col xs={24}><Loading/></Col> :
                        topics.length > 0 ? topics.map(({
                                                            pk,
                                                            object_id,
                                                            title,
                                                            total_messages_count,
                                                            unread_messages_count,
                                                            permission
                                                        }) => (
                            <Col xs={24}>
                                <CardTopic
                                    key={pk}
                                    permission={permission}
                                    onClick={() => history.push(`/available-programs/detail/${object_id}/messages`)}
                                    totalMessages={total_messages_count}
                                    title={title}
                                    totalUnreadMessage={unread_messages_count}/>
                            </Col>

                        )) : <Col xs={24}><Empty/></Col>
                    }
                </Row>

            </Col>
            <Col xs={24}>
                <Row justify={'end'}>
                    <Col>
                        <Pagination request={{
                            url: `topics/home_messages`,
                            setIsLoading: setIsLoading,
                            setData: setTopics,
                            extraParams: {
                                search: search
                            },
                            dependence: [search]
                        }}/>
                    </Col>
                </Row>
            </Col>
        </Row>

    )
}