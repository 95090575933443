import React, { useEffect, useState } from 'react';
import api from "../../services/api";
import TreeSelect from './TreeSelect';


function RequestTreeSelect({url, urlParams, version="v1", ...props}) {
    const [treeData, setTreeData] = useState([]);

    const fetchData = async () => {
        try {
            const response = await api.get(url, {...urlParams, version});
            setTreeData(response.data);
        } catch (error) {}
    }

    useEffect(() => {
        url && void fetchData();
    }, [url]);

    return (
        <TreeSelect treeData={treeData} {...props}/>
    );
}

export default RequestTreeSelect;