import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Col, Form as FormAntd, Row, Space, Typography} from "antd";
import styled from 'styled-components';
import Button from "../../../../../../global/components/data_entry/Button";
import {neutral_6,} from "../../../../../../global/utils/Colors";
import {FilterOutlined} from "@ant-design/icons";
import Drawer from "../../../../../../global/components/layout/Drawer";
import {
    FlexContainer
} from "../../../../../../global/components/authentication/StyledComponents";
import Form from "../../../../../../global/components/data_entry/form/Form";
import useOptionsTranslation
    from "../../../../../../global/hooks/useOptionsTranslation";
import {
    convertToFormData
} from "../../../../../../modules/project/utils/utils";
import FilterButton
    from "../../../../../../global/components/button/FilterButton";
import FilterTags from "../../../../../../global/components/other/FilterTags";
import {APPLICATION_TAG} from "../../../../../../global/utils/Choices";
import SimpleCollapse
    from "../../../../../../global/components/misc/SimpleCollapse";
import RadioFormField
    from "../../../../../../global/components/data_entry/form/RadioFormField";
import CheckboxWithSwitchFormField
    from "../../../../../../global/components/data_entry/form/CheckboxWithSwitchFormField";

const Container = styled.div`
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 4px;

    :hover {
        background: ${neutral_6};
    }

    ${({actived}) => actived && `    color: var(--primary_btn_text_color);
    background:  var(--primary_btn_color);`}
`


const FORM_NAMES = {
    WAS_SUBMITTED: 'was_submitted',
    CHALLENGE: 'application__challenge__id__in',
    TEAM_TYPE: 'application__team__type',
    SIZE: 'application__team__sectors_size__id__in',
    COMPANY_SECTOR: 'application__team__sectors_institution__id__in',
    COMPANY_PERSONA: 'application__team__sectors_persona__id__in',
    COMPANY_TECHNOLOGY: 'application__team__sectors_technology__id__in',
    COMPANY_TRANSACTION: 'application__team__sectors_transaction__id__in',
    COMPANY_CLIENT_SECTORS: 'application__team__sectors_client__id__in',
    TAGS_ISNULL: 'application__tags__isnull',
    TAGS__ID__IN: 'application__tags__id__in[]',
}


const CHOICES_SUBMITTED = [
    {
        value: true,
        label: "Yes",
    },
    {
        value: false,
        label: "No",
    },
]
const CHOICES_TEAM_TYPE = [{
    value: 'Team',
    label: 'Team'
}, {value: 'Company', label: 'Company'}, {
    value: 'Individual',
    label: 'Individual'
}]


export default function FilterButtonApplicationSelection({
                                                             onChange
                                                         }) {
    const {t} = useTranslation()
    const [filters, setFilters] = useState()
    let {id: progra_id} = useParams()
    const [open, setOpen] = useState(false)
    const [form] = FormAntd.useForm()
    const {translationOptions} = useOptionsTranslation()


    function handleResetFilters() {
        form.resetFields()
    }

    function handleOpen() {
        form.setFieldsValue(filters)
        setOpen(true)
    }

    async function getValuesForm() {
        let data = await form.getFieldsValue()
        if (data[FORM_NAMES.TAGS_ISNULL] || data[FORM_NAMES.TAGS__ID__IN]?.length === 0 || !data[FORM_NAMES.TAGS__ID__IN]) {

            delete data[FORM_NAMES.TAGS__ID__IN]

        }
        if (!data[FORM_NAMES.TAGS_ISNULL]) {
            delete data[FORM_NAMES.TAGS_ISNULL]
        }

        return data
    }

    async function handleApply() {
        const data = await getValuesForm()


        const filteredData = {}
        Object.keys(data).forEach((key) => {
            const currentItem = data[key]
            if (currentItem) {

                filteredData[key] = currentItem
            }
        })
        onChange(convertToFormData(filteredData, false))
        setFilters(filteredData)
    }

    function onClose() {
        // form.resetFields()
        setOpen(false)
    }

    return (

        <>
            <FilterButton onClick={handleOpen}/>
            <Drawer open={open} onClose={onClose}
                    destroyOnClose
                    title={t('Filter applications')}
                    footer={
                        <FlexContainer justify={'end'} gap={10}>
                            <Button
                                type={'default'}
                                onClick={handleResetFilters}>{t('Reset filters')}</Button>
                            <Button onClick={form.submit}>{t('Save')}</Button>
                        </FlexContainer>
                    }>
                <Form form={form} onFinish={handleApply}>
                    <FilterTags filterName={FORM_NAMES.TAGS__ID__IN}
                                noTagName={FORM_NAMES.TAGS_ISNULL}
                                targets={[APPLICATION_TAG]}/>

                    <SimpleCollapse title={t('Submited')}>
                        <RadioFormField
                            form={{name: FORM_NAMES.WAS_SUBMITTED}}
                            options={translationOptions(CHOICES_SUBMITTED)}/>


                    </SimpleCollapse>
                    <SimpleCollapse title={t('Challenges')}>
                        <RadioFormField allowClear form={{

                            name: FORM_NAMES.CHALLENGE,
                        }}
                                        request={{url: `programs/${progra_id}/challenges`}}/>


                    </SimpleCollapse>
                    <SimpleCollapse title={t('Application Type')}>

                        <RadioFormField
                            form={{name: FORM_NAMES.TEAM_TYPE}}
                            options={translationOptions(CHOICES_TEAM_TYPE)}/>


                    </SimpleCollapse>

                    <SimpleCollapse title={t('Size')}>

                        <CheckboxWithSwitchFormField
                            translateLabelOptions
                            search form={{
                            name: FORM_NAMES.SIZE,
                        }}
                            request={{url: '/sectors_size'}}/>

                    </SimpleCollapse>

                    <SimpleCollapse title={t('Company sectors')}>

                        <CheckboxWithSwitchFormField
                            translateLabelOptions
                            search form={{

                            name: FORM_NAMES.COMPANY_SECTOR,
                        }}
                            request={{url: '/sectors_institution'}}/>

                    </SimpleCollapse>

                    <SimpleCollapse title={t('Company personas')}>

                        <CheckboxWithSwitchFormField
                            translateLabelOptions
                            search form={{

                            name: FORM_NAMES.COMPANY_PERSONA,
                        }}
                            request={{url: '/sectors_persona'}}/>

                    </SimpleCollapse>

                    <SimpleCollapse title={t('Technologies')}>

                        <CheckboxWithSwitchFormField
                            translateLabelOptions
                            search form={{

                            name: FORM_NAMES.COMPANY_TECHNOLOGY,
                        }}
                            request={{url: '/sectors_technology'}}/>

                    </SimpleCollapse>
                    <SimpleCollapse title={t('Transactions type')}>

                        <CheckboxWithSwitchFormField
                            translateLabelOptions
                            search form={{

                            name: FORM_NAMES.COMPANY_TRANSACTION,
                        }}
                            request={{url: '/sectors_transaction'}}/>

                    </SimpleCollapse>

                    <SimpleCollapse title={t('Client sectors')}>

                        <CheckboxWithSwitchFormField
                            translateLabelOptions
                            search form={{

                            name: FORM_NAMES.COMPANY_CLIENT_SECTORS,
                        }}
                            request={{url: '/sectors_client'}}/>

                    </SimpleCollapse>


                </Form>
            </Drawer>
            {/*<Drawer></Drawer>*/}
        </>

    )
}