import React from 'react';
import styled from "styled-components";
import {Tabs} from "antd";
import useSystemBreakpoint from '../../hooks/useSystemBreakpoint';

const StyledDrawerTabs = styled(Tabs)`
  height: 100%;
  padding: 0;

  .ant-tabs-nav {
    margin: 0;
    background-color: white;
    flex-shrink: 1;
  }

  .ant-tabs-content-holder {
    padding: ${({isPhone}) => isPhone ? "8px": "16px"};
    overflow: auto;
    overflow-x: hidden;
    height: inherit;
  }

  & .ant-tabs-tabpane, .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-nav-wrap {
	height: 38px;
    padding-left: ${({isPhone}) => isPhone ? "8px": "16px"};
    overflow-x: auto;
    white-space: nowrap;
  }

`;

function DrawerTabs({items, ...props}) {
	const {isPhone} = useSystemBreakpoint();
  const shownItems = items?.filter((item)=>!item.hidden);
	return <StyledDrawerTabs isPhone={isPhone} items={shownItems} {...props} />;
}

export default DrawerTabs;