import React, {useEffect, useState} from 'react';
import Form from "../../../../../global/components/data_entry/form/Form";
import WideSpace from "../../../../../global/components/layout/WideSpace";
import {BuildFieldErrorsDict,} from "../../../../../global/utils/Utils";
import {
    ACTIVE,
    CHOICES_TEAM_TYPE,
    CONTINUOUS,
    OPEN_INNOVATION,
    SOLUTION,
    TEMPORARY
} from "../../../../../global/utils/Choices";
import {useTranslation} from "react-i18next";
import {Card, Col, Form as FormAnt, Modal, Row, Space} from "antd";
import GeneralSettingsCollapse from "./components/GeneralSettingsCollapse";
import DescriptionSettingsCollapse from "./components/DescriptionSettingsCollapse";
import GuidelinesSettingsCollapse from "./components/GuidelinesSettingsCollapse";
import {FlexContainer} from "../../../../../global/components/authentication/StyledComponents";
import Button from "../../../../../global/components/data_entry/Button";
import api from "../../../../../global/services/api";
import {ToastNotification} from "../../../../../global/components/feedback/ToastNotification";
import * as ConstantsMessage from "../../../../../global/utils/fileWithMessage";
import {useHistory} from "react-router-dom";
import ProgramHistoryOffCanvas from "../offcanvas/ProgramHistoryOffCanvas";
import {BarcodeOutlined, ExclamationCircleOutlined, FieldTimeOutlined} from "@ant-design/icons";
import ScoreEvaluatorCollapse from "./components/ScoreEvaluatorCollapse";
import ProgramConfigOffCanvas from "../offcanvas/help/ProgramConfigOffCanvas";
import InfoCardItem from "../../../../../global/components/misc/InfoCardItem";
import MetricsAndTrackingCollapse from "./components/MetricsAndTrackingCollapse";
import FixedFooter from '../../../../../global/components/layout/FixedFooter';
import OtherSettingsCollapse from "./components/OtherSettingsCollapse";
import useOptionsTranslation from "../../../../../global/hooks/useOptionsTranslation";
import HiddenInscriptionsCollapse from './components/HiddenInscriptionsCollapse';
import {useThemeContext} from '../../../../../global/context/ThemeContext';
import BannerUploader
    from "../../../../../global/components/data_entry/BannerUploader";
import CollapseFormItem
    from "../../../../../global/components/data_entry/CollapseFormItem";
import AttachingTags
    from "../../../../../global/components/tag/AttachingTags";

const FORM_NAMES = {
    PROGRAM_TYPE: 'type',
    APPLICATION_NAME_CHOICE: 'application_name_choice',
    DURATION_TYPE: 'duration',
    PROGRAM_STATUS: 'status',
    BANNER: 'banner',
    NAME: 'name',
    STRATEGIC_ALIGMENT: 'sectors',
    DESCRIPTION: 'description',
    // PROGRAM_DOMAINS: 'program_domains',
    TEAM_TYPE: 'team_types',
    APPLICATION_BANNER_REQUIRED: 'application_banner_required',

    ENABLE_APPLICATION_EDITING: 'enable_application_editing',
    ALLOW_SIMPLE_INITIATIVE: 'allow_simple_initiative',// gestor se inscrever no program
    ALLOW_MANAGING_TO_BE_CANDIDATE: 'allow_managing_to_be_candidate',// gestor se inscrever no program
    ALLOW_EVALUATOR_TO_BE_CANDIDATE: 'allow_evaluator_to_be_candidate',// permitir avaliador ser candidato
    ENABLE_EVALUATOR_DILIGENCES: 'enable_evaluator_diligences',

    PUBLIC: 'public',

    SCORE_RANGE: 'score_range',//uso somente no front.
    EVALUATION_SCALE_MIN: 'evaluation_scale_min',//uso somente para enviar para o back.
    EVALUATION_SCALE_MAX: 'evaluation_scale_max',//uso somente para enviar para o back.
    EVALUATION_FORMAT: 'evaluation_format',

    IS_CONFIDENTIAL: 'is_confidential',
    AUTO_ALLOCATION: 'auto_allocation',
    APPLICATION_MAX_EVALUATORS: 'application_max_evaluators',
    RUN_DELETE_UNFINISHED_APPLICATIONS: 'run_delete_unfinished_applications',
    HIDE_PHASES: 'hide_phases',

    TRACKING_CONFIGURATION: 'tracking_configuration',//USO para enviar para o back
    PROGRAM_SETTINGS: 'programsettings',//USO para enviar para o back

    FACEBOOK_PIXEL_ID: 'facebook_pixel_id',
    HAS_FACEBOOK_PIXEL_ID: 'has_facebook_pixel_id',//USO SOMENTE NO FRONT
    GOOGLE_ANALYTICS_ID: 'google_analytics_id',
    HAS_GOOGLE_ANALYTICS_ID: 'has_google_analytics_id',//USO SOMENTE NO FRONT
    GOOGLE_TAG_MANAGER_ID: 'google_tag_manager_id',
    HAS_GOOGLE_TAG_MANAGER_ID: 'has_google_tag_manager_id',//USO SOMENTE NO FRONT

    NAME_REQUIRED: "is_name_required",
    EMAIL_REQUIRED: "is_email_required",
    PHONE_REQUIRED: "is_phone_required",

    ALLOW_APPLICANTS_FEEDBACK_VIEW: 'allow_applicants_feedback_view',
    ALLOW_AUTHOR_SEE_GRADES: 'allow_applicants_feedback_view',
    TEAM_EVALUATION_RESULT_VIEW_TYPE: 'team_evaluation_result_view_type'
}

const INITIAL_VALUES = {
    [FORM_NAMES.SCORE_RANGE]: [0, 10],
    [FORM_NAMES.PROGRAM_TYPE]: OPEN_INNOVATION,
    [FORM_NAMES.APPLICATION_NAME_CHOICE]: SOLUTION,
    [FORM_NAMES.DURATION_TYPE]: TEMPORARY,
    [FORM_NAMES.PROGRAM_STATUS]: ACTIVE,
    [FORM_NAMES.PUBLIC]: false,
    [FORM_NAMES.ENABLE_APPLICATION_EDITING]: true,
    [FORM_NAMES.HIDE_PHASES]: false,
    // [FORM_NAMES.ALLOW_EVALUATOR_TO_BE_CANDIDATE]: false,
    [FORM_NAMES.ALLOW_MANAGING_TO_BE_CANDIDATE]: true,

    [FORM_NAMES.HAS_FACEBOOK_PIXEL_ID]: false,
    [FORM_NAMES.HAS_GOOGLE_ANALYTICS_ID]: true,
    [FORM_NAMES.HAS_GOOGLE_TAG_MANAGER_ID]: true,
    [FORM_NAMES.NAME_REQUIRED]: true,
    [FORM_NAMES.EMAIL_REQUIRED]: false,
    [FORM_NAMES.PHONE_REQUIRED]: false,
    [FORM_NAMES.ENABLE_EVALUATOR_DILIGENCES]: false,

    [FORM_NAMES.ALLOW_APPLICANTS_FEEDBACK_VIEW]: false,
    [FORM_NAMES.TEAM_EVALUATION_RESULT_VIEW_TYPE]: "score_average",

    [FORM_NAMES.APPLICATION_BANNER_REQUIRED]: false,

    // [FORM_NAMES.IS_CONFIDENTIAL]: false,
}


export default function ProgramForm({program, isCreating, ...props}) {
    const {t} = useTranslation()
    const [form] = FormAnt.useForm()
    const history = useHistory()
    const [isLoading, setIsLoading] = useState()
    const [openHistory, setOpenHistory] = useState(false)
    const publicField = FormAnt.useWatch(FORM_NAMES.PUBLIC, form)
    const [helpDrawerOpen, setHelpDrawerOpen] = useState()
    const {themeConfig} = useThemeContext()
    const isConfidentialMode = themeConfig?.client?.is_confidential_mode

    async function getFieldsValue() {
        let data = await form.getFieldsValue()

        data[FORM_NAMES.EVALUATION_SCALE_MIN] = data[FORM_NAMES.SCORE_RANGE][0]
        data[FORM_NAMES.EVALUATION_SCALE_MAX] = data[FORM_NAMES.SCORE_RANGE][1]


        if (data[FORM_NAMES.ENABLE_APPLICATION_EDITING] === undefined) {
            data[FORM_NAMES.ENABLE_APPLICATION_EDITING] = false
        }
        if (data[FORM_NAMES.DURATION_TYPE] === CONTINUOUS) {
            data[FORM_NAMES.ENABLE_APPLICATION_EDITING] = false
        }

        if (data[FORM_NAMES.ALLOW_MANAGING_TO_BE_CANDIDATE] === undefined) {
            data[FORM_NAMES.ALLOW_MANAGING_TO_BE_CANDIDATE] = false
        }
        if (data[FORM_NAMES.ALLOW_EVALUATOR_TO_BE_CANDIDATE] === undefined) {
            data[FORM_NAMES.ALLOW_EVALUATOR_TO_BE_CANDIDATE] = false
        }

        if (data[FORM_NAMES.ALLOW_SIMPLE_INITIATIVE] === undefined) {
            if (program !== undefined) {
                data[FORM_NAMES.ALLOW_SIMPLE_INITIATIVE] = program.allow_simple_initiative
            } else {
                data[FORM_NAMES.ALLOW_SIMPLE_INITIATIVE] = false
            }
        } else {
            data[FORM_NAMES.TEAM_TYPE] = [1, 2, 3]
        }

        if (data[FORM_NAMES.PUBLIC] === undefined) {
            data[FORM_NAMES.PUBLIC] = true
        } else {
            data[FORM_NAMES.PUBLIC] = data[FORM_NAMES.PUBLIC]
        }

        data[FORM_NAMES.PROGRAM_SETTINGS] = {
            [FORM_NAMES.NAME_REQUIRED]: data[FORM_NAMES.NAME_REQUIRED],
            [FORM_NAMES.EMAIL_REQUIRED]: data[FORM_NAMES.EMAIL_REQUIRED],
            [FORM_NAMES.PHONE_REQUIRED]: data[FORM_NAMES.PHONE_REQUIRED],
            [FORM_NAMES.TRACKING_CONFIGURATION]: {
                [FORM_NAMES.FACEBOOK_PIXEL_ID]: data[FORM_NAMES.HAS_FACEBOOK_PIXEL_ID] ? data[FORM_NAMES.FACEBOOK_PIXEL_ID] : null, // limpa o id se o booleano estiver desativado
                [FORM_NAMES.GOOGLE_ANALYTICS_ID]: data[FORM_NAMES.HAS_FACEBOOK_PIXEL_ID] ? data[FORM_NAMES.GOOGLE_ANALYTICS_ID] : null, //limpa o id se o booleano estiver desativado
                [FORM_NAMES.GOOGLE_TAG_MANAGER_ID]: data[FORM_NAMES.HAS_FACEBOOK_PIXEL_ID] ? data[FORM_NAMES.GOOGLE_TAG_MANAGER_ID] : null, //limpa o id se o booleano estiver desativado
                "event": "page_view"
            },
            [FORM_NAMES.TEAM_EVALUATION_RESULT_VIEW_TYPE]: data[FORM_NAMES.TEAM_EVALUATION_RESULT_VIEW_TYPE],
            [FORM_NAMES.APPLICATION_BANNER_REQUIRED]: data[FORM_NAMES.APPLICATION_BANNER_REQUIRED],
        }

        delete data[FORM_NAMES.FACEBOOK_PIXEL_ID]
        delete data[FORM_NAMES.GOOGLE_ANALYTICS_ID]
        delete data[FORM_NAMES.GOOGLE_TAG_MANAGER_ID]
        delete data[FORM_NAMES.HAS_FACEBOOK_PIXEL_ID]
        delete data[FORM_NAMES.HAS_GOOGLE_ANALYTICS_ID]
        delete data[FORM_NAMES.HAS_GOOGLE_TAG_MANAGER_ID]

        delete data[FORM_NAMES.NAME_REQUIRED]
        delete data[FORM_NAMES.EMAIL_REQUIRED]
        delete data[FORM_NAMES.PHONE_REQUIRED]

        delete data[FORM_NAMES.TEAM_EVALUATION_RESULT_VIEW_TYPE]
        delete data[FORM_NAMES.APPLICATION_BANNER_REQUIRED]

        return data
    }

    async function handleSubmit() {
        try {
            setIsLoading(true)

            const data = await getFieldsValue()

            let response;
            if (program) {
                response = await api.patch(`/programs/${program.id}`, data);
            } else {
                response = await api.post(`/programs`, data);
            }

            if (data[FORM_NAMES.BANNER]?.startsWith("blob")) {
                const formData = new FormData()
                const blob = await fetch(data[FORM_NAMES.BANNER]).then((r) => r.blob())
                formData.append("banner_upload", blob, "teste.png");
                await api.patch(`/programs/${response.data.id}`, formData, {
                    headers: {"content-type": "multipart/form-data"},
                });
            }
            if (!program) history.push(`/programs/${response.data.id}/settings`)
            ToastNotification(t(ConstantsMessage.SUCCESS));
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false)
        }
    }

    const handleConfirmDeletion = (invite) => {
        Modal.confirm({
            title: t("Delete program"),
            icon: <ExclamationCircleOutlined/>,
            content: t("Are you sure you want to delete your program?"),
            onOk: async () => {
                try {
                    setIsLoading(true);

                    let response = await api.delete(`/programs/${program.id}`);
                    if (response.status === 204) {
                        history.push("/programs");
                        ToastNotification(t(ConstantsMessage.SUCCESS));
                    }
                } catch (error) {
                    BuildFieldErrorsDict(error, null, null)
                } finally {
                    setIsLoading(false);
                }
            },
            okText: t('Yes'),
            cancelText: t('No')

        });
    };

    const hasApplication = program?.applications_count > 0;

    function getInitialTrackingValues() {
        const facebookPixelId = program[FORM_NAMES.PROGRAM_SETTINGS]?.[FORM_NAMES.TRACKING_CONFIGURATION]?.[FORM_NAMES.FACEBOOK_PIXEL_ID]
        const googleAnalyticsId = program[FORM_NAMES.PROGRAM_SETTINGS]?.[FORM_NAMES.TRACKING_CONFIGURATION]?.[FORM_NAMES.GOOGLE_ANALYTICS_ID]
        const googleTagManagerId = program[FORM_NAMES.PROGRAM_SETTINGS]?.[FORM_NAMES.TRACKING_CONFIGURATION]?.[FORM_NAMES.GOOGLE_TAG_MANAGER_ID]

        return {
            [FORM_NAMES.HAS_FACEBOOK_PIXEL_ID]: !!facebookPixelId,
            [FORM_NAMES.HAS_GOOGLE_ANALYTICS_ID]: !!googleAnalyticsId,
            [FORM_NAMES.HAS_GOOGLE_TAG_MANAGER_ID]: !!googleTagManagerId,
            [FORM_NAMES.FACEBOOK_PIXEL_ID]: facebookPixelId,
            [FORM_NAMES.GOOGLE_ANALYTICS_ID]: googleAnalyticsId,
            [FORM_NAMES.GOOGLE_TAG_MANAGER_ID]: googleTagManagerId,
        }
    }

    function getInitialSimpleInitiativeValues() {

        const isNameRequired = program[FORM_NAMES.PROGRAM_SETTINGS]?.[FORM_NAMES.NAME_REQUIRED]
        const isEmailRequired = program[FORM_NAMES.PROGRAM_SETTINGS]?.[FORM_NAMES.EMAIL_REQUIRED]
        const isPhoneRequired = program[FORM_NAMES.PROGRAM_SETTINGS]?.[FORM_NAMES.PHONE_REQUIRED]

        return {
            [FORM_NAMES.NAME_REQUIRED]: isNameRequired,
            [FORM_NAMES.PHONE_REQUIRED]: isPhoneRequired,
            [FORM_NAMES.EMAIL_REQUIRED]: isEmailRequired,
        }

    }

    function getProgramSettings() {
        const teamEvaluationResultViewType = program[FORM_NAMES.PROGRAM_SETTINGS]?.[FORM_NAMES.TEAM_EVALUATION_RESULT_VIEW_TYPE]
        const applicationBannerRequired = program[FORM_NAMES.PROGRAM_SETTINGS]?.[FORM_NAMES.APPLICATION_BANNER_REQUIRED]

        return {
            [FORM_NAMES.TEAM_EVALUATION_RESULT_VIEW_TYPE]: teamEvaluationResultViewType,
            [FORM_NAMES.APPLICATION_BANNER_REQUIRED]: applicationBannerRequired,
        }

    }

    useEffect(() => {
        //inicializa os campos do program
        if (program) {

            form.setFieldsValue({
                ...program,
                // [FORM_NAMES.PROGRAM_DOMAINS]: generateOptionsField(program.program_domains, 'domain', 'domain').map((item) => (`@${item.label}`)),
                [FORM_NAMES.SCORE_RANGE]: [program[FORM_NAMES.EVALUATION_SCALE_MIN], program[FORM_NAMES.EVALUATION_SCALE_MAX]],
                [FORM_NAMES.IS_CONFIDENTIAL]: program[FORM_NAMES.IS_CONFIDENTIAL],
                [FORM_NAMES.PUBLIC]: program[FORM_NAMES.PUBLIC],

                ...getInitialTrackingValues(),
                ...getInitialSimpleInitiativeValues(),
                ...getProgramSettings(),
            })

        } else {
            form.setFieldsValue(INITIAL_VALUES)
        }
    }, [program]);


    const allowSimpleInitiative = FormAnt.useWatch(FORM_NAMES.ALLOW_SIMPLE_INITIATIVE, form) || program?.ALLOW_SIMPLE_INITIATIVE
    useEffect(() => {
        if (allowSimpleInitiative) {
            form.setFieldsValue({
                [FORM_NAMES.TEAM_TYPE]: [1, 2, 3],
                [FORM_NAMES.ALLOW_EVALUATOR_TO_BE_CANDIDATE]: true,
                [FORM_NAMES.ENABLE_APPLICATION_EDITING]: false,
                [FORM_NAMES.RUN_DELETE_UNFINISHED_APPLICATIONS]: false,
                [FORM_NAMES.HIDE_PHASES]: false,
            })
        }
    }, [allowSimpleInitiative]);


    return (<>

            {program && (
                <>
                    <Row gutter={24}>
                        <Col span={12}>
                            <InfoCardItem icon={<BarcodeOutlined/>}
                                          title={t("Program link")}
                                          text={`${document.location.origin}/show_program/${program.slug}`}
                                          copyable={true}
                                          isLink={true}
                            />
                        </Col>
                        <Col span={12}>
                            <InfoCardItem icon={<FieldTimeOutlined/>}
                                          title={t("History")}
                                          text={t("See history")}
                                          onClick={() => {
                                              setOpenHistory(true)
                                          }}
                            />
                        </Col>
                    </Row>
                    <ProgramHistoryOffCanvas open={openHistory}
                                             setOpen={setOpenHistory}
                                             program={program}/>
                </>
            )}
            <Form form={form} onFinish={handleSubmit} style={{marginBottom: 88, marginTop: program && 16}}>

                <WideSpace direction="vertical" size={24}>
                    <GeneralSettingsCollapse form={form}
                                             hasApplication={hasApplication}
                                             program={program}
                                             FORM_NAMES={FORM_NAMES}
                                             setHelpDrawerOpen={setHelpDrawerOpen}
                    />



                    {(isCreating || program?.description !== undefined) &&
                        <DescriptionSettingsCollapse form={form}
                                                     program={program}
                                                     FORM_NAMES={FORM_NAMES}
                        />
                    }

                    <GuidelinesSettingsCollapse form={form}
                                                hasApplication={hasApplication}
                                                program={program}
                                                FORM_NAMES={FORM_NAMES}
                                                setHelpDrawerOpen={setHelpDrawerOpen}
                                                hide={allowSimpleInitiative}
                    />


                    {isConfidentialMode &&
                        <HiddenInscriptionsCollapse form={form}
                                                    program={program}
                                                    FORM_NAMES={FORM_NAMES}
                        />
                    }

                    <ScoreEvaluatorCollapse form={form}
                                            program={program}
                                            FORM_NAMES={FORM_NAMES}
                                            setHelpDrawerOpen={setHelpDrawerOpen}
                    />

                    <MetricsAndTrackingCollapse form={form}
                                                program={program}
                                                FORM_NAMES={FORM_NAMES}
                    />

                    {program &&
                        <OtherSettingsCollapse program={program}
                                               setIsLoading={setIsLoading}
                                               FORM_NAMES={FORM_NAMES}
                        />
                    }

                    {/* {program && <ProgramMembers title={t("Program organizers")} program={program}/>} */}
                </WideSpace>
            </Form>
            <FixedFooter>
                <Card>
                    <Row gutter={[20, 20]}>
                        <Col xs={24}>
                            <FlexContainer justify={'end'}>
                                <Space>
                                    {program &&
                                        <Button danger type={'default'}
                                                disabled={program.is_innovation_program || hasApplication}
                                                loading={isLoading}
                                                onClick={handleConfirmDeletion}>{t("Delete")}</Button>}

                                    <Button htmlType="submit"
                                            data-cy="submit-program-form-button"
                                            onClick={form.submit}
                                            loading={isLoading}
                                    >
                                        {t("Save")}
                                    </Button>

                                </Space>
                            </FlexContainer>
                        </Col>
                    </Row>
                </Card>
            </FixedFooter>
            <ProgramConfigOffCanvas help={helpDrawerOpen} setHelp={setHelpDrawerOpen}/>
        </>
    )
}