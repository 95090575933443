import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Col, Progress, Row, Space, Tooltip, Typography} from "antd";
import {useParams} from "react-router-dom";
import DisplayApplicationsCount from "./components/DisplayApplicationsCount";
import {CardContainer} from "../../../../../global/components/misc/CardContainer";
import {FlexContainer} from "../../../../../global/components/authentication/StyledComponents";
import Search from "../../../../../global/components/data_entry/Search";
import ButtonExportData from "../../../../../global/components/other/ButtonExportData";

import Table from "../../../../../global/components/data_display/Table";
import useTable from "../../../../../global/hooks/useTable";
import useSystemBreakpoint from "../../../../../global/hooks/useSystemBreakpoint";
import AprovedOrReprovedButtons from "./components/AprovedOrReprovedButtons";
import FooterApplicationSelection from "./components/FooterApplicationSelection";
import ApplicationDrawer from "../../../../../global/components/other/application/ApplicationDrawer/ApplicationDrawer";
import FilterButtonApplicationSelection from "./components/FilterButtonApplicationSelection";
import api from "../../../../../global/services/api";

import {BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import PercentTable from "../../../../../global/components/feedback/PercentTable";
import {CSVLink} from "react-csv";
import styled from 'styled-components';
import {ToastNotification} from "../../../../../global/components/feedback/ToastNotification";
import {SUCCESS} from "../../../../../global/utils/fileWithMessage";
import RadioButton from "../../../../../global/components/data_entry/RadioButton";
import { renderProfile } from "../../../../../global/components/tables/utils";
import UserAvatar from "../../../../../global/components/misc/UserAvatar";

const {Link} = Typography;

export const CSVLinkHidden = styled(CSVLink)`
    width: 0px;
    height: 0px;
    visibility: hidden;
`
const QUERY_PARAMS_NAMES = {
    SEARCH: 'search',
    STATUS: 'status',
    CALCULATE_YES_NO_QUESTION: 'calculate_yes_no_question',

}

const DEFAULT_APPLICATION_STATUS = 'pending'

export default function PageApplicationSelection() {
    const {t} = useTranslation()
    let {phase_id} = useParams()
    const URL = `phases/${phase_id}/history_allocation`
    const [applicationStatusFilter, setApplicationStatusFilter] = useState(DEFAULT_APPLICATION_STATUS)
    const [search, setSearch] = useState('')
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
    const {isDesktop} = useSystemBreakpoint()
    const [openApplicationDrawer, setOpenApplicationDrawer] = React.useState(false);
    const [applicationId, setApplicationId] = React.useState(false);
    const [filters, setFilters] = useState()
    const [data_csv, setData_csv] = useState()
    const [isExportLoading, setIsExportLoading] = useState(false)
    const exportRef = useRef()
    const [exportColumns, setExportColumns] = useState([
        {title: t('Application Title'), dataIndex: 'project_name'},
        {title: t('Author'), dataIndex: 'team', render: (value)=>value.name},
        {
            title: t('Status'),
            dataIndex: 'is_aproved',
            render: (value) => value ? t('Aproved') : value === false ? t('Reproved') : 'Pending'
        },
        {title: t('Score'), dataIndex: 'score_average'},
        {title: t('Evaluators'), dataIndex: 'evaluators_count'},
        {title: t('Performed'), dataIndex: 'performed',},
        {title: t('Maximum score'), dataIndex: 'score_max'},
        {title: t('Minimum score'), dataIndex: 'score_min'},
        {title: t('Standard Deviation'), dataIndex: 'standard_deviation'},
        {title: t('Sum'), dataIndex: 'score_sum'},
        {title: t('No weight'), dataIndex: 'score_unweighted'},
    ])
    const [columns, setColumns] = useState([

        {
            title: t('Conclusion'),
            dataIndex: 'is_aproved',
            sorter: 'is_aproved',
            key: 'is_aproved',
            width: '150px',
            onCell: () => ({onClick: false}),
            render: (value, object) => {

                return <AprovedOrReprovedButtons initialState={value}
                                                 historyApplication={object}/>
            }
        },
        {
            title: t('Code'),
            dataIndex: 'application_code',
            sorter: 'application__code',
        },
        {
            title: t("Author"),
            dataIndex: "team",
            render: (value) => <Space size={4}><UserAvatar data={value} /> {value.name}</Space>,
        },
        {
            title: t('Application Title'),
            dataIndex: 'project_name',
            sorter: 'project_name',
            key: 'project_name',
            ellipsis: true,
            width: "150px",

        },
        {
            title: <Tooltip title={t('Weighted average of the evaluations')}
                            p>{t('Score')}</Tooltip>,
            dataIndex: 'score_average',
            sorter: 'score_average',
            key: 'score_average',
            width: '10px',

        },

        {
            title: t('Performed'),
            dataIndex: 'performed',
            // sorter: 'all_evaluations',
            key: 'performed',
            width: '10px',
        },
        {
            title: t('Conclusion'),
            dataIndex: 'evaluators_count',
            // sorter: 'all_evaluations',
            key: 'evaluators_count',
            width: '200px',
            render: (value, object) => {

                if (value === 0) return '-'
                const percent = ((object.finished_assessments_count / value) * 100).toFixed(2)

                return <Progress percent={percent}/>
            }
        },
        {
            title: <Tooltip title={t('Maximum score')} p>{t('Máx')}</Tooltip>,
            dataIndex: 'score_max',
            sorter: 'score_max',
            key: 'score_max',
            width: '10px',

        },

        {
            title: <Tooltip title={t('Minimum score')} p>{t('Min')}</Tooltip>,

            dataIndex: 'score_min',
            sorter: 'score_min',
            key: 'score_min',
            width: '10px',

        },


        {
            title: <Tooltip
                title={t('Standard Deviation, the distance of the scores from the mean; the closer to 0, the closer the scores are to each evaluation')}
                p>{t('Standard Deviation')}</Tooltip>,
            // title: t('Standard Deviation'),
            dataIndex: 'standard_deviation',
            sorter: 'standard_deviation',
            key: 'standard_deviation',
            width: '10px',

        },
        {
            title: t('Sum'),
            dataIndex: 'score_sum',
            sorter: 'score_sum',
            key: 'score_sum',
            width: '10px',
        },

    ])


    useEffect(() => {
        const fetchColumns = async () => {
            try {
                const response = await api.get(`phases/${phase_id}/get_yes_no_columns`);

                const newColumns = response.data.map(({label, name}) => ({
                    title: label,
                    dataIndex: ['total_yes_no_question', name],
                    key: 'total_yes_no_question',
                    // width: '10px',
                    render: (value, object) => {

                        return (<PercentTable value={value}>
                            {value}% {t('Yes')}
                        </PercentTable>)
                    }
                }))
                const newExportColumns = response.data.map(({
                                                                label,
                                                                name
                                                            }) => ({
                    title: label,
                    dataIndex: ['total_yes_no_question', name],
                }))
                setColumns([...columns, ...newColumns])
                setExportColumns([...exportColumns, ...newExportColumns])
            } catch (error) {
                BuildFieldErrorsDict(error, null, false);
            }
        };
        fetchColumns()
    }, []);

    const {
        tableData, isLoading, tableParams, handleTableChange,
        handleRequestTable, resetTable
    } = useTable({
        url: URL,
        hasPagination: true,
        extraParams: {
            [QUERY_PARAMS_NAMES.SEARCH]: search,
            [QUERY_PARAMS_NAMES.STATUS]: applicationStatusFilter,
            [QUERY_PARAMS_NAMES.CALCULATE_YES_NO_QUESTION]: 'yes',
        },
        body: filters || {},
        dependenceRequest: [search, applicationStatusFilter, phase_id, filters],
    })


    async function handleOnRowClick(historyApplication, event) {


        setApplicationId(historyApplication.application_id)
        setOpenApplicationDrawer(true);

    }


    async function handleExport() {
        try {

            setIsExportLoading(true)

            const response = await api.post(URL, null, {
                params:
                    {
                        [QUERY_PARAMS_NAMES.CALCULATE_YES_NO_QUESTION]: 'yes',
                    }
            });
            let tableitems = []
            response.data.forEach((item) => {
                const data = {}
                exportColumns.forEach((column) => {
                    let main_accessor = column.dataIndex
                    let csvValue;
                    if (Array.isArray(main_accessor)) {
                        //verifica se tem lookup

                        let value = item
                        main_accessor.forEach((lookup) => {

                            value = value[lookup]

                        })
                        csvValue = value


                    } else {
                        csvValue = item[main_accessor]


                    }

                    if (column.render) {
                        data[column.title] = column.render(csvValue, item)

                    } else {
                        data[column.title] = csvValue
                    }
                })

                tableitems.push(data)
            })
            setData_csv(tableitems)

            ToastNotification(t(SUCCESS))
        } catch (error) {
            BuildFieldErrorsDict(error, null)
        } finally {
            setIsExportLoading(false);
        }
    }

    useEffect(() => {
        if (data_csv) {
            exportRef.current.link.click()
            setData_csv(null)
        }
    }, [data_csv]);

    return (
        <>
            <Row gutter={[24, 24]} style={{marginBottom: 80}}>
                <Col xs={24}>
                    <DisplayApplicationsCount/>
                </Col>
                <Col xs={24}>

                    <CardContainer>
                        <Row gutter={[16, 16]}>
                            {isDesktop &&
                                <Col md={12}>
                                    <Row>
                                        <Col>
                                            <RadioButton
                                                onChange={setApplicationStatusFilter}
                                                defaultValue={DEFAULT_APPLICATION_STATUS}
                                                block={true}
                                                options={[
                                                    {
                                                        value: 'pending',
                                                        label: t('Pending')
                                                    },
                                                    {
                                                        value: 'approved',
                                                        label: t('Approved')
                                                    },
                                                    {
                                                        value: 'repproved',
                                                        label: t('Reproved')
                                                    },

                                                    {
                                                        value: 'all',
                                                        label: t('All')
                                                    },
                                                ]}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            }

                            <Col lg={12} xs={24}>
                                <Row justify="end" gutter={8}>
                                    <Space size="small">
                                        <FlexContainer gap={16}>
                                            <Search style={{'flex': '1'}}
                                                    onSearch={(value) => setSearch(value)}
                                                    allowClear/>
                                        </FlexContainer>

                                        <ButtonExportData
                                            loading={isExportLoading}
                                            onClick={handleExport}
                                        />
                                        <CSVLinkHidden
                                            ref={exportRef}
                                            filename={"applications.csv"}
                                            data={data_csv || []}
                                            separator={";"}
                                        />

                                        <FilterButtonApplicationSelection
                                            onChange={setFilters}/>
                                    </Space>
                                </Row>

                            </Col>

                            <Col xs={24}>
                                <Table columns={columns}

                                       onChange={handleTableChange}
                                       tableParams={tableParams}
                                       onRowClick={handleOnRowClick}
                                       loading={isLoading || isLoadingSubmit}
                                       noScroll
                                       dataSource={tableData}/>
                            </Col>
                        </Row>
                    </CardContainer>
                </Col>
            </Row>

            <ApplicationDrawer solutionId={applicationId}
                               open={openApplicationDrawer}
                               setOpen={setOpenApplicationDrawer}/>

            <FooterApplicationSelection setIsLoadingSubmit={setIsLoadingSubmit}
                                        isLoadingSubmit={isLoadingSubmit}/>
        </>

    )
}

