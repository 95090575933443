import React from "react";


export default function MultiLineText({ text }) {
  
    const textWithLineBreaks = text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br/>
        </React.Fragment>
    ));

    return (
        <p>
            {textWithLineBreaks}
        </p>
    );
};