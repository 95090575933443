import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {Col, Row, Space, Typography} from "antd";
import styled from 'styled-components';
import Button from "../../../../../../global/components/data_entry/Button";
import {lime_6, volcano_7} from "../../../../../../global/utils/Colors";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";
import api from "../../../../../../global/services/api";
import {BuildFieldErrorsDict} from "../../../../../../global/utils/Utils";

const AprovedButton = styled(Button)`
    background: ${lime_6} !important;
    border: none;
    color: white !important;

    &.ant-btn-primary[disabled] {
        background: ${lime_6};
        color: white;
        opacity: 0.5;
    }
`

const ReprovedButton = styled(Button)`
    background: ${volcano_7} !important;
    color: white !important;
    border: none;

    &.ant-btn-primary[disabled] {
        background: ${volcano_7};
        color: white;
        opacity: 0.5;
    }

`

export default function AprovedOrReprovedButtons({
                                                     initialState,
                                                     historyApplication
                                                 }) {
    const {t} = useTranslation()
    const [status, setStatus] = useState()
    const [isApprovedLoading, setIsApprovedLoading] = useState(false)
    const [isReprovedLoading, setIsReprovedLoading] = useState(false)



    const isAproved = status === true
    const isReproved = status === false

    useEffect(() => {
        if(historyApplication.id){
            setStatus(initialState)
        }
    }, [historyApplication]);

    function resetState() {
        aproveOrReproveApplication('pending').then(() => {
            setStatus(null)
        }).catch(error => console.error('Error:', error));
    }

    function handleAproved(e) {
        e.stopPropagation()
        if (isAproved) {
            resetState()
        } else {
            aproveOrReproveApplication('approve', setIsApprovedLoading).then(
                () => {
                    setStatus(true)
                }
            ).catch(error => console.error('Error:', error));

        }
    }

    function handleReproved(e) {
        e.stopPropagation()
        if (isReproved) {
            resetState()
        } else {
            aproveOrReproveApplication('reprove', setIsReprovedLoading).then(
                () => {
                    setStatus(false)
                }
            ).catch(error => console.error('Error:', error));

        }
    }


    async function aproveOrReproveApplication(action, setLoading) {
        try {
            setLoading?.(true)
            const data = {
                action: action,
                applications: [historyApplication.id],
            };
            await api.post(`applications/change_status`, data);

        } catch (error) {

            BuildFieldErrorsDict(error, null, false);
            return Promise.reject()
        } finally {
            setLoading?.(false)
        }
    }

    return (

        <Space size={8}>
            {!isReproved &&
                <AprovedButton loading={isApprovedLoading}
                               disabled={historyApplication.was_submitted || isReprovedLoading}
                               onClick={handleAproved}
                               icon={<CheckCircleOutlined/>}
                               data-cy={`approve-allocation-${historyApplication?.id}`}
                >
                    {isAproved && t('Approved')}</AprovedButton>}
            {!isAproved &&
                <ReprovedButton loading={isReprovedLoading}
                                disabled={historyApplication.was_submitted || isApprovedLoading}
                                onClick={handleReproved}
                                icon={<CloseCircleOutlined/>}
                                data-cy={`reprove-allocation-${historyApplication?.id}`}
                >{isReproved && t('Reproved')}</ReprovedButton>}
        </Space>

    )
}