import React from 'react'
import styled from 'styled-components'
import Label from "./Label";
import MessageError from "./MessageError";
import InputContainer from "./InputContainer";

const StyledDiv = styled.div`
  flex-direction: column;
  display: flex;
  align-items: ${(props) => props.position ? props.position : ""};
`

const StyledInputSwitch = styled.input`
  -webkit-appearance: none;
  border-radius: 50px !important;
  padding: 0;
  //width: 100% ;
  width: 50px;
  height: 20px;
  cursor: pointer;
  transition: 0.5s;
  position: relative;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);

  &:checked {
    background-color: var(--brand-tertiary-color);
  }

  &:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    //margin-top: 1px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    //transform: scale(1);
    background-color: var(--brand-primary-color);
    transform: scale(1.1);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    transition: 0.5s;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
  }

  &:checked:before {
    left: 100%;
    transform: translateX(-100%) scale(1.1);
  }
`


export default function ToggleSwitch({
                                         position,
                                         id,
                                         required,
                                         label,
                                         messageError,
                                         elementDescription,
                                         ...props
                                     }) {
    return (
        <InputContainer position={position} >
            {label &&
                <Label htmlFor={id} required={required}>{label}</Label>}
            {elementDescription}
            <StyledDiv position={position}>
                <StyledInputSwitch type={'checkbox'} id={id}
                                   required={required} {...props} />
                {messageError && <MessageError
                    style={{textAlign: 'left'}}>{messageError}</MessageError>}
            </StyledDiv>
        </InputContainer>
    )
}