import { Card, Col, Row, Space, Typography } from "antd";
import styled from "styled-components";
import React from "react";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import Flex from './../responsive/Flex';


const StyledButtonCard = styled(Card)`
  cursor: pointer;
  
  .ant-card-body {
        display: flex;
        height: ${props => props.$height || "initial"};
        flex-direction: "column";
  }

  :hover {
    background-color: #f5f5f5;
  }

`;

export default function CardButton({
    icon,
    sufixIcon,
    text,
    type = "secondary",
    style,
    iconStyle,
    onClick,
    height,
    justify = 'space-between',
    ...props
}) {
    const { isDesktop } = useSystemBreakpoint()

    return (
        <StyledButtonCard $height={height} onClick={onClick} bodyStyle={style} {...props}>

            <Flex justify={justify} gap={8} grow={1} align="center">
                <Flex gap={16} align="center">
                    {isDesktop &&
                        <Typography.Text style={{fontWeight: "700"}} type={type}>
                            {icon && React.cloneElement(icon, {
                                style: {
                                    fontSize: 32,
                                    verticalAlign: "middle", ...iconStyle
                                }
                            })}
                        </Typography.Text>
                    }
                    <Typography.Text style={{fontWeight: "700"}} type={type}>
                        {text}
                    </Typography.Text>
                </Flex>

                {sufixIcon && 
                    <Typography.Text type={type}>
                        {React.cloneElement(sufixIcon, {
                            style: {
                                fontSize: 32,
                                verticalAlign: "middle", ...iconStyle
                            }
                        })}
                    </Typography.Text>
                }

            </Flex>
        </StyledButtonCard>
    )
}