import React, { useEffect, useState } from "react";
import { useUserContext } from "../../context/UserContext";
import { Typography } from "antd";
import WideSpace from "../../components/layout/WideSpace";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Card, Space, Form } from "antd";
import Button from "../../components/data_entry/Button";
import ProfileForm from "./components/ProfileForm";
import { BuildFieldErrorsDict } from "../../utils/Utils";
import api from "../../services/api";
import DrawerProfileUser from "./components/DrawerProfileUser/DrawerProfileUser";
import ProfileUploader from "./components/ProfileUploader";
import { ToastNotification } from "../../components/feedback/ToastNotification";
import * as ConstantsMessage from "../../../global/utils/fileWithMessage";
import { useParams, Link, useHistory } from 'react-router-dom';

const LinkStyle = styled(Link)`
    color: #1890ff;
    text-decoration: underline !important;
    cursor: pointer;

`;

const StyledCard = styled(Card)`
    .ant-card-body {
        padding: 16px 24px;
    }
`;

export default function Profile() {
    const { t } = useTranslation();
    const { _user, set_User } = useUserContext();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [openDrawerProfile, setOpenDrawerProfile] = useState(false);
    const [form] = Form.useForm();
    const [profileImg, setProfileImg] = useState(null);
    const [validateFields, setValidateFields] = useState({});
    const {id: userId} = _user;

    const onFinish = async () => {
        try {
            setIsLoading(true);

            const formData = new FormData();

            const config = {
                headers: { "content-type": "multipart/form-data" },
            };

            Object.keys(form).forEach((key) => {
                formData.append(key, form[key].value ?? "");
            });

            if (profileImg?.startsWith("blob")) {
                const blob = await fetch(profileImg).then((r) => r.blob());
                formData.append("picture", blob, "picture.png");
            }

            const response = await api.patch(`/auth/user`, formData, config);

            if (response.status === 200) {
                ToastNotification(t(ConstantsMessage.SUCCESS));
                window.location.reload();
            }
        } catch (error) {
            BuildFieldErrorsDict(error, setValidateFields, false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleImageChange = (image) => {
        setProfileImg(image);
    };

    const handleLogout = () => {
        try {
            api.post('logout/')

        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
        set_User(null);
        history.push("/login");
    };

    return (
        <Space style={{ width: "100%" }} direction="vertical" size="large">
            <StyledCard>
                <WideSpace direction="vertical">
                    <Form form={form} onFinish={onFinish}>
                        <div style={{ display: "flex", justifyContent: "end", alignItems: "end" }}>
                            <WideSpace size="middle">
                                <Form.Item name="profileImage" style={{ all: "unset" }}>
                                    <ProfileUploader onChange={handleImageChange} avatarData={{..._user, picture: profileImg || _user?.picture_url}} />
                                </Form.Item>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <Typography.Text style={{ fontSize: 24, fontWeight: 500 }}>
                                        {_user.full_name}
                                    </Typography.Text>
                                    <Typography.Text style={{ fontWeight: 400 }}>{_user.username}</Typography.Text>
                                    <LinkStyle to="#" onClick={() => setOpenDrawerProfile(true)}>
                                        {t("View profile")}
                                    </LinkStyle>
                                </div>
                            </WideSpace>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    {t("Save")}
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                    <DrawerProfileUser
                        id={userId}
                        open={openDrawerProfile}
                        setOpen={setOpenDrawerProfile}
                    />
                </WideSpace>
            </StyledCard>
            <StyledCard
                title={
                    <Typography.Text style={{ fontSize: "16px", fontWeight: 500 }}>
                        {t("Change personal information")}
                    </Typography.Text>
                }
            >
                <ProfileForm />
            </StyledCard>
            <StyledCard
                title={
                    <Typography.Text style={{ fontSize: "16px", fontWeight: 500 }}>{t("Disconnect")}</Typography.Text>
                }
            >
                <div style={{ textAlign: "end", marginTop: "16px" }}>
                    <Button type="default" onClick={(event) => handleLogout()}>
                        {t("Logout")}
                    </Button>
                </div>
            </StyledCard>
        </Space>
    );
}
