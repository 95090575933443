import React from 'react';
import {Gauge} from "@ant-design/plots";
import {useChartColor} from "./hooks/useChartColor";

export default function RiskChart({data, indicator}) {
    const {scaled_value} = data
    const {getColorByImportancy} = useChartColor({indicator, indicatorResponse: data});
    const valueColor = getColorByImportancy(scaled_value)

    const config = {
        data: {
            target: scaled_value,
            total: 10,
            name: 'risk',
            thresholds: [0, 3, 7, 10],
        },
        animate: null,
        scale: {
            color: {
                range: [valueColor, '#95DE64', '#FFC069', '#FF4D4F'],
            },
            y:{
                type:"linear",
                tickCount:10,
            }
        },
        style: {
            fill: ({scaled_value}) => {
                return getColorByImportancy(scaled_value)
            },
        },
        height:280,
        legend: false,
        tooltip:false,
        // inset:25,
        // padding:0,
        // margin:0,
        paddingBottom:-100,
        autoFit: true,
    };

    return (
        <Gauge {...config}/>
    );
}
