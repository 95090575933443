import WideSpace from "../../../../../../layout/WideSpace";
import SolutionDescriptionCollapseTitle from "./SolutionDescriptionCollapseTitle";
import CollapseContentManagerView from "./CollapseContentManagerView";
import CollapseContentUserView from "./CollapseContentUserView";
import LockerCollapse from "../../../../../../misc/LockerCollapse";
import { Badge } from "antd";
import { useApplicationDrawerContext } from "../../../context/ApplicationDrawerContext";
import {usePermissionContext} from "../../../../../../../context/PermissionContext";

function getPhaseCollapseBackgroundColor(phase, solution) {
    const now = new Date();
    if ((phase.date_end && new Date(phase.date_end) <= now) || solution.current_phase != phase.id){
        return "#F5F5F5";
    }
}


export default function DescriptionSolutionCollapses({phases}){

    const {solution} = useApplicationDrawerContext();
    const {hasProgramAdminOrManagerPermission} = usePermissionContext();
    
    const getPhaseResult = (phase) => {
        return solution.solution_history.find((item) => item.step === phase.id);
    }


    return(
        <WideSpace size={16} direction={"vertical"}>
            {phases.map((phase, phaseIndex) => {
                const solutionResult = getPhaseResult(phase) || {};
                const backgroundColor = getPhaseCollapseBackgroundColor(phase, solution);
                const locked = phase.is_locked || solutionResult.step === undefined

                return (
                    <LockerCollapse
                        headerBackground={backgroundColor}
                        contentStyle={{background: backgroundColor}}
                        key={phaseIndex}
                        active={!locked && solutionResult.step === solution.current_phase}
                        title={<SolutionDescriptionCollapseTitle phase={phase} solution={solution} solutionResult={solutionResult} />}
                        bordered={true}
                        locked={locked}
                        renderExtra={solutionResult?.has_phase_actions && <Badge color={"#FF4D4F"} count={1} />}
                    >
                        {!solution.logged_user_is_member && hasProgramAdminOrManagerPermission ?
                            <CollapseContentManagerView phase={phase} solutionResult={solutionResult} />:
                            <CollapseContentUserView phase={phase} solutionResult={solutionResult} />
                        }
                    </LockerCollapse>
                )
            })}
        </WideSpace>
    )
}
