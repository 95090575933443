import React, {useState} from "react";
import {
    NavLink,
} from "react-router-dom";
import {useHistory} from 'react-router-dom';

import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../utils/Utils";
import {useTranslation} from "react-i18next";
import {ToastNotification} from "../../components/feedback/ToastNotification";
import LayoutLogin from "../../layouts/authentication/LayoutLogin";
import {Col, Row, Form as FormAntd} from "antd";
import InputFormField from "../../components/data_entry/form/InputFormField";
import {GLOBAL_PATHS} from "../../utils/RouteUtils";
import Form from "../../components/data_entry/form/Form";
import {AuthButton} from "../../components/authentication/StyledComponents";
import ReCaptchaButton from "../../components/authentication/ReCaptchaButton";


export default function Recover() {
    const {t} = useTranslation()
    const history = useHistory();
    const [form] = FormAntd.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(false);


    const handleSubmit = async () => {
        const data = {'email': form.getFieldValue('username')};
        try {
            setIsLoading(true)
            const response = await api.put('auth/send_email_recover_password/', data);
            ToastNotification(response.data.detail)
            history.push(GLOBAL_PATHS.LOGIN)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <LayoutLogin>
            {/*{isLoading ? <Loading/> : (*/}
            <Form form={form}
                  requiredMark={false}
                  onFinish={handleSubmit}
                  style={{marginTop: 16}}>
                <Row>
                    <Col xs={24}>
                        <InputFormField form={{
                            style: {marginBottom: 4},
                            label: t("Enter your user's email address and we will send you an email to change your password"),
                            name: 'username',

                            rules: [{
                                required: true,
                                message: t('E-mail is required')
                            }, {
                                type: 'email',
                                message: t('Please provide a valid email')
                            }
                            ]


                        }} placeholder={t('E-mail')}/>
                        <NavLink
                            to={`${GLOBAL_PATHS.LOGIN}`}>{t('Go back to Log in')}</NavLink>
                    </Col>
                    <Col xs={24} style={{marginTop: 24}}>
                        <ReCaptchaButton setCaptchaValue={setCaptchaValue}/>
                    </Col>
                    <Col xs={24} style={{marginTop: 24}}>
                        <AuthButton block
                                    disabled={!captchaValue}
                                    htmlType="submit"
                                    loading={isLoading}>{t('Send')}</AuthButton>
                    </Col>
                </Row>

            </Form>
            {/*)}*/}
        </LayoutLogin>
    );
}