import React, {useContext, useEffect} from 'react';
import {HOST_ENTREPRENEUR} from "../../../settings";
import UserContext, {useUserContext} from "../../context/UserContext";
import {
    UserRegisterConfirmation
} from "../../../components/Auth/UserRegisterConfirmation";
import RoutesEntrepreneur from "../../routes/RoutesEntrepreneur";
import LayoutSystem from "../LayoutSystem";
import RoutesAmbient from "../../routes/RoutesAmbient";
import {useThemeContext} from "../../context/ThemeContext";
import {usePermissionContext} from "../../context/PermissionContext";
import {Redirect, useHistory} from "react-router-dom";
import Loading from "../../components/feedback/Loading";
import {ToastNotification} from "../../components/feedback/ToastNotification";
import {useTranslation} from "react-i18next";


const IGNORED_PATHS_ADMIN_USER = ['/environment/' +
'financial', '/profile']
const IGNORED_PATHS_INTERNAL_USER = ['/financial_block', '/profile']

function LayoutSwitch({location}) {
    const {
        _user,
        user_client,
        userLoading,
        isAuthenticated
    } = useUserContext();
    const {t} = useTranslation()
    const {
        checkAmbientHasDebit,
        hasFullAdminPermission,
        isLoadingPermissions
    } = usePermissionContext();
    const history = useHistory()


    const getLayout = () => {

        if (_user.is_valid) {
            if (checkAmbientHasDebit() && user_client) {
// debugger
                if (hasFullAdminPermission) {
                    if (!IGNORED_PATHS_ADMIN_USER.includes(location.pathname)) {
                        history.push("/environment/financial")
                    }
                } else if (!IGNORED_PATHS_INTERNAL_USER.includes(location.pathname)) {
                    history.push("/financial_block")

                }
            }
            return window.location.host === HOST_ENTREPRENEUR ?
                <RoutesEntrepreneur/> : <RoutesAmbient/>
        } else {
            return <UserRegisterConfirmation/>
        }
    }

    useEffect(() => {
        if (!userLoading && !isAuthenticated()) {
            ToastNotification(t('Login Expired'), 'error')
        }
    }, [userLoading])
    return isLoadingPermissions || userLoading ?
        <Loading/> : isAuthenticated() ? <LayoutSystem
            content={
                getLayout()
            }
        /> : <Redirect to={'/login'}/>
}

export default LayoutSwitch;