import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {CardContainer} from "../../global/components/misc/CardContainer";
import PermissionTagsList
    from "../../global/components/tag/PermissionTagsList";
import {Col, Row, Space, Tag, Typography} from "antd";
import Button from "../../global/components/data_entry/Button";
import {PlusOutlined} from "@ant-design/icons";
import Search from "../../global/components/data_entry/Search";
import Table from "../../global/components/data_display/Table";
import {DateFormatByUserTimezone} from "../../global/utils/dates";
import useTable from "../../global/hooks/useTable";
import UserAvatar from "../../global/components/misc/UserAvatar";
import UserPermissionDrawer
    from "../../global/components/misc/UserPermissionDrawer";
import TagsList from "../../global/components/tag/TagsList";


export default function PageEnvironmentUsers() {

    const {t} = useTranslation();
    const [searchRequest, setSearchRequest] = useState()
    const [open, setOpen] = useState(false)
    const [userClientId, setUserClientId] = useState()

    const {
        tableData,
        isLoading,
        tableParams,
        handleTableChange,
        handleRequestTable,
        resetTable

    } = useTable({
        url: 'users_client/collaborators',
        body: {}, search: searchRequest
    })
    const table = {
        columns: [
            {
                title: t('Profile'),
                dataIndex: 'user', // sorter: 'equipment__pin_17',
                sorter: 'user__first_name',
                key: 'user',
                width: '200px',
                render: (value, object) => {
                    return (
                        <Space>
                            <UserAvatar data={object.user}/>
                            {value.first_name}
                        </Space>
                    );
                },
            },
            {
                title: t('Status'),
                sorter: 'is_active',
                dataIndex: 'is_active', // sorter: 'equipment__pin_17',
                key: 'is_active',
                render: (value, object) => {
                    let is_await = value == false && object.in_queue
                    return (
                        <Tag
                            color={value ? 'green' : is_await ? 'orange' : 'red'}>{value ? t('Active') : is_await ? t('In queue') : t('Inactive')}</Tag>
                    );
                },
            },
            {
                title: t('Email'),
                sorter: 'user__email',
                dataIndex: 'user.email', // sorter: 'equipment__pin_17',
                key: 'user',
            },
            {
                title: t("Permissions"),
                dataIndex: 'user_permissions', // sorter: 'equipment__pin_17',
                key: 'user_permissions',
                width: '200px',
                render: (user_permissions) => {
                    return (

                        <PermissionTagsList
                            permissions={user_permissions}/>

                    );
                },
            },
            {
                title: t('Tags'),
                dataIndex: 'tags_display',
                key: 'tags_display',
                render: (tags) => <TagsList isCustom tags={tags} maxSize={2}/>
            },

            {
                title: t("Date joined"),
                sorter: 'date_create',
                dataIndex: 'user', // sorter: 'equipment__pin_17',
                key: 'user',
                render: (value) => {
                    return <>
                        {value.date_joined ? DateFormatByUserTimezone(value.date_joined) : '-'}</>
                }


            },
        ],
        onRowClick: (record) => {
            setUserClientId(record.id)
            setOpen(true)
        }

    }

    return (
        <CardContainer>
            <Row gutter={[16, 16]}>
                <Col xs={24} lg={10}>
                    <Typography.Title
                        level={5}>{t('Collaborators')}</Typography.Title>
                </Col>
                <Col xs={24} lg={14}>
                    <Row justify={'end'} gutter={[16, 16]}>
                        <Col flex={1}>
                            <Search
                                placeholder={t("Search")}
                                onSearch={(value) => setSearchRequest(value)}
                            />
                        </Col>
                        <Col>
                                <Button
                                icon={<PlusOutlined/>}
                                onClick={() => setOpen(true)}
                            >
                                {t('New User')}
                            </Button>
                        </Col>
                    </Row>
                </Col>

                <Col xs={24}>
                    <Table columns={table.columns} onChange={handleTableChange}
                           tableParams={tableParams}
                           loading={isLoading}
                           noScroll
                           onRowClick={table.onRowClick}
                           dataSource={tableData}
                           data-cy="program-table"
                    />
                </Col>
            </Row>
            <UserPermissionDrawer
                resetTable={resetTable}
                userClientId={userClientId}
                setUserClientId={setUserClientId}
                open={open}
                setOpen={setOpen}
                handleRequestTable={handleRequestTable}
            />
        </CardContainer>
    )

}
