import React from "react";
import DefaultComponent from "./DefaultComponent";
import Input from "../../../Form/Input";
import {useTranslation} from "react-i18next";

function DateForm(props) {
    const {t} = useTranslation()
    return (
        <DefaultComponent{...props}>
            <Input type={'date'} placeholder={t('Type your answer here...')} />
        </DefaultComponent>
    )
}
export default DateForm