import React from 'react';
import {Collapse} from "antd";
import styled from "styled-components";


const StyledCollapse = styled(Collapse)`

    margin-bottom: 16px;
    .ant-collapse-header {
        padding: 12px 16px !important;
    }

    .ant-collapse-content-box {
        background: white;
        padding-top: 16px !important;
    }

    .ant-collapse-header-text {
        font-weight: 500;
        font-size: 14px;
    }

    border-radius: 8px;
    //background: red;
`

export default function SimpleCollapse({title, children}) {
    return (
        <StyledCollapse defaultActiveKey={['1']} expandIconPosition={'end'} bordered={false}>
            <Collapse.Panel header={title} key="1">
                {children}
            </Collapse.Panel>
        </StyledCollapse>
    );
}

