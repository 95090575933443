import React from 'react'
import ButtonBase from "../Base/index"
import './style.scss'
import {ReactComponent as ExportIcon} from '../../../assets/Icon/maximize.svg'
import {ReactComponent as More} from '../../../assets/Icon/more.svg'
import {useTranslation} from "react-i18next";
import styled from 'styled-components'
import {neutral_1} from "../../../global/utils/Colors";

const ExportIconStyled = styled(ExportIcon)`
  margin-right: 5px;
`

const PrimaryButton = styled(ButtonBase)`
  //background-color: var(--login_background_button) !important;
  background-color: var(--primary_btn_color) !important;
  color: var(--primary_btn_text_color) !important;
  border: 1px solid transparent !important;

  &:hover {
    border: 1px solid var(--primary_btn_text_color) !important;
  }

  &:disabled {
    opacity: 0.6 !important;
    cursor: not-allowed !important;
  }

  svg {
    fill: var(--primary_btn_text_color) !important;
  }

`

const SecondaryButton = styled(ButtonBase)`
  background-color: white !important;
  color: var(--primary_btn_color) !important;
  border: 1px solid var(--primary_btn_color) !important;

  &:hover {
    background-color: var(--primary_btn_color) !important;
    color: white !important;
    /* border: 1px solid var(--gray-6-color) !important; */
    svg{
        fill: white !important;
    }
  }

  &:disabled {
    opacity: 0.6 !important;
    cursor: not-allowed !important;
  }
  
  svg{
    fill: var(--primary_btn_color) !important;
  }
  
`

const GiveUpButton = styled(ButtonBase)`
    background-color: white !important;
    border: 1px solid #D9D9D9 !important;
    color: rgba(0,0,0,0.85);
  
    &:hover {
        background-color: #FF4D4F !important;
        color: ${neutral_1};
    }
`
function ButtonDefault(props) {
    const {action, label, img, color, disabled, buttonRef} = props
    const {t} = useTranslation();

    return (
        <>
            {(() => {
                switch (action) {
                    case 'primary':
                        return <PrimaryButton buttonRef={buttonRef} disabled={disabled} color="secondary" {...props}>
                            {label}
                        </PrimaryButton>;
                    case 'primary create':
                        return <PrimaryButton buttonRef={buttonRef} disabled={disabled} color="secondary" {...props}>
                            <More style={{
                                marginRight: 8,
                                marginBottom: 3
                            }}/>{label}
                        </PrimaryButton>;
                    case 'secondary':
                        return <SecondaryButton buttonRef={buttonRef} disabled={disabled} color="secondary" {...props}>
                            {label}
                        </SecondaryButton>;
                    case 'neutral':
                        return <GiveUpButton buttonRef={buttonRef} disabled={disabled} {...props} color="neutral">
                            {label}
                        </GiveUpButton>;
                    case 'tertiary':
                        return <SecondaryButton buttonRef={buttonRef} disabled={disabled} color="secondary" {...props}>
                            {label}
                        </SecondaryButton>;
                    case 'create':
                        return <SecondaryButton buttonRef={buttonRef} disabled={disabled} color="secondary" {...props}>
                            <More style={{
                                fill:"white",
                                marginRight: 8,
                                marginBottom: 4
                            }}/>{label}
                        </SecondaryButton>;
                    case 'update':
                        return <SecondaryButton buttonRef={buttonRef} disabled={disabled}
                                                color="primary" {...props}><img
                            alt={'icon'} src={img} style={{
                            marginRight: 8,
                            marginBottom: 2,
                            marginLeft: 8
                        }}/>{label}
                        </SecondaryButton>;
                    case 'approve':
                        return <ButtonBase buttonRef={buttonRef} disabled={disabled} color="primary"
                                           className="buttonBaseApprove" {...props}>
                            {/*<img alt={'icon'} src={approve} style={{marginBottom: 2, marginRight: 4, marginLeft: -4}}/>*/}
                            {label ? label : t("Approve")}
                        </ButtonBase>;
                    case 'export':
                        return <SecondaryButton buttonRef={buttonRef} disabled={disabled}
                                                className="buttonBaseExport"  {...props}>
                            {label ? label : t("Reprove")}
                        </SecondaryButton>;

                    case 'reprove':
                        return <ButtonBase buttonRef={buttonRef} disabled={disabled} color="primary"
                                           className="buttonBaseReprove"  {...props}>
                            {/*<img alt={'icon'} src={reprove} style={{marginBottom: 2, marginRight: 4, marginLeft: -4}}/>*/}
                            {label}
                        </ButtonBase>;
                    case 'expand':
                        return <SecondaryButton buttonRef={buttonRef} disabled={disabled} color="primary"
                                                {...props}>
                            <ExportIconStyled/>
                            {label}
                        </SecondaryButton>;
                    default:
                        return <SecondaryButton buttonRef={buttonRef} disabled={disabled}
                                                color={color}  {...props}>{img &&
                            <img alt={'icon'} src={img} style={{
                                marginRight: 8,
                                marginBottom: 2,
                                marginLeft: 8
                            }}/>}{label} </SecondaryButton>;
                }
            })()}

        </>
    )
}

export default ButtonDefault;