import ReactPixel from 'react-facebook-pixel';
import {useEffect} from "react";

export default function useFacebookPixel({id, event}) {

    const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
    };

    useEffect(() => {

        if (id) {
            ReactPixel.init(id, null, options);
            ReactPixel.pageView(); // For tracking page view
            // ReactPixel.track(event, data); // For tracking default events. More info about standard events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#standard-events
            // ReactPixel.trackSingle('PixelId', event, data); // For tracking default events.
            // ReactPixel.trackCustom(event, data); // For tracking custom events. More info about custom events: https://developers.facebook.com/docs/facebook-pixel/implementation/conversion-tracking#custom-events
            // ReactPixel.trackSingleCustom('PixelId', event, data); // For tracking custom events.
        }
    }, [id])

}