import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {
    PlusOutlined,
} from "@ant-design/icons";
import {Form as FormAnt} from "antd";
import Button from "../../../../../components/data_entry/Button";
import Drawer from "../../../../../components/layout/Drawer";
import Form from "../../../../../components/data_entry/form/Form";
import InputFormField
    from "../../../../../components/data_entry/form/InputFormField";
import DateRangePickerFormField
    from "../../../../../components/data_entry/form/DateRangePickerFormField";
import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import api from "../../../../../services/api";
import {
    ToastNotification
} from "../../../../../components/feedback/ToastNotification";
import * as ConstantsMessage from "../../../../../utils/fileWithMessage";
import {BuildFieldErrorsDict} from "../../../../../utils/Utils";
import {
    useLayoutContext
} from "../../../../../../modules/project/components/layout/context/LayoutContext";
import moment from "moment/moment";
import InformationCard
    from "../../../../../components/data_display/InformationCard";
import SelectFormField
    from "../../../../../components/data_entry/form/SelectFormField";
import {
    ACTIONS_CAMPAIGN_CHOICES,
    TYPE_BONUS_CAMPAIGN_CHOICES, VALUE_CAMPAIGN
} from "../../../../../utils/Choices";
import useOptionsTranslation from "../../../../../hooks/useOptionsTranslation";
import InputNumberFormField
    from "../../../../../components/data_entry/form/InputNumberFormField";
import BannerUploader
    from "../../../../../components/data_entry/BannerUploader";
import imageChallenge from "../../../../../../assets/img/upload_challenge.png";
import ImageUpload
    from "../../../../../components/data_entry/ImageUpload";
import useDefaultGetRequest from "../../../../../hooks/useDefaultGetRequest";
import ImageUploadField
    from "../../../../../components/data_entry/form/ImageUploadField";


const FORM_NAMES = {
    NAME: 'name',
    DESCRIPTION: 'description',

    STOCK: 'stock',
    PRICE: 'price',
    IMAGE_FILE: 'image_file',

}

export default function DrawerAddOrEditShopItem({open, setOpen, shopItemId}) {
    const {t} = useTranslation();
    const IS_EDIT_MODE = !!shopItemId
    const [isLoading, setIsLoading] = useState(false)
    const [form] = FormAnt.useForm()
    const {updateLayoutContent} = useLayoutContext()
    const {
        data: shopItem,
        isLoading: isLoadingShopItem,
    } = useDefaultGetRequest({
        url: `/store_itens/${shopItemId}`,
        dependence: [shopItemId, open],
        makeRequest: !!shopItemId && open
    })


    function onClose() {
        setOpen(false)
        form.resetFields()
    }

    async function getValuesForm() {
        let data = await form.getFieldsValue()
        const newData = {...data}
        newData[FORM_NAMES.IMAGE_FILE] = data[FORM_NAMES.IMAGE_FILE][0].pk
        return newData
    }

    async function handleSubmit() {
        try {
            setIsLoading(true);
            let data = await getValuesForm()

            if (IS_EDIT_MODE) {
                await api.patch(`/store_itens/${shopItemId}`, data);
            } else {
                await api.post(`/store_itens`, data);
            }
            updateLayoutContent()
            onClose()
            ToastNotification(t(ConstantsMessage.SUCCESS));


        } catch (error) {
            const startDateError = error?.response?.data?.errors?.[FORM_NAMES.START_DATE][0]
            if (startDateError) {

                form.setFields([{
                    name: FORM_NAMES.DATES,
                    errors: [startDateError]
                }])

            }
            BuildFieldErrorsDict(error, form.setFields, null);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (shopItem) {
            const items = {...shopItem}
            items[FORM_NAMES.IMAGE_FILE] = shopItem.img_url
            form.setFieldsValue(items)
        }
    }, [shopItem]);


    return (
        <>


            <Drawer title={IS_EDIT_MODE ? t('Edit item') : t('Add new item')}
                    open={open} onClose={onClose}
                    isLoading={isLoadingShopItem}
                    footer={
                        <FlexContainer justify="end"><Button
                            onClick={form.submit}
                            loading={isLoading}>{IS_EDIT_MODE ? t('Edit') : t('Add')}</Button></FlexContainer>
                    }>

                <Form style={{marginTop: 24}} form={form} disabled={isLoading}
                      onFinish={handleSubmit}>

                    <InputFormField disabled={IS_EDIT_MODE} form={{
                        name: FORM_NAMES.NAME,
                        label: t('Name'), rules: [{
                            required: true,
                            message: t('This field is require')
                        }],
                    }}/>
                    <InputNumberFormField min={0} form={{
                        name: FORM_NAMES.STOCK,
                        help: t('Set stock to 0 to remove the item from the store but keep it in the table.'),
                        label: t('Stock'), rules: [{
                            required: true,
                            message: t('This field is require')
                        }],
                    }}/>
                    <InputNumberFormField min={0} form={{
                        name: FORM_NAMES.PRICE,
                        label: t('Price (points)'), rules: [{
                            required: true,
                            message: t('This field is require')
                        }],
                    }}/>
                    <InputFormField form={{
                        name: FORM_NAMES.DESCRIPTION,
                        label: t('Description'), rules: [{
                            required: true,
                            message: t('This field is require')
                        }],
                    }}/>

                    <ImageUploadField form={{
                        name: FORM_NAMES.IMAGE_FILE,
                        label: t("Image"),
                        rules: [{
                            required: true,
                            message: t('This field is require')
                        }],
                        help: `${t("Size")} (472x472). Max. 2MB`
                    }}/>

                </Form>
            </Drawer>

        </>


    )
}
