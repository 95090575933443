import React, {useState} from 'react'
import styled from 'styled-components'
import {Col} from "react-bootstrap";
import {hasAcceptedCookies, saveAcceptedCookies} from "../../utils/Auth";

import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

const StyledCookieNotificationDivMain = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const StyledCookieNotificationDiv = styled.div`
  width: 80%;
  position: fixed;
  bottom: 0;
  border-radius: 4px;
  margin-bottom: 4px;
  padding: 8px 16px;
  color: var(--login_text_color_cover);
  background-color: var(--login_background_cover);
  z-index: 9999;
  display: flex;
  white-space: break-spaces;
  align-items: center;
  border: 1px solid var(--active_menu_color);
  animation: shake 1.2s cubic-bezier(.36, .07, .19, .97) both infinite;

  &:hover {
    animation: none;
  }

  transform: translate3d(0, 0, 0);
  @keyframes shake {
    10%, 90% {
      transform: translate3d(0, -2px, 0);
    }

    20%, 80% {
      transform: translate3d(0, 2px, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(0, -3px, 0);
    }

    40%, 60% {
      transform: translate3d(0, 3px, 0);
    }
  }
`

const StyledResponsiveThemeButton = styled.button`
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  color: var(--login_text_color_button);
  height: 30px;
  align-items: center;
  background-color: var(--login_background_button);
  box-shadow: 0px -3.38082px 10.0172px rgba(0, 0, 0, 0.055), 0px -1.79553px 5.32008px rgba(0, 0, 0, 0.0444282), 0px -0.747159px 2.21381px rgba(0, 0, 0, 0.030926);
  border-radius: 60px;
  border: 1px solid transparent;
  padding: 2px 16px;
  display: flex;
  float: right;

  &:hover {
    border: 1px solid var(--login_text_color_button);
  }
`

export default function CookieNotification() {
    const [isAuthCookie, setIsAuthCookie] = useState(hasAcceptedCookies())
    const {t} = useTranslation();
    const location = useLocation();

    const pathInIgnoredPaths = () =>{
        return location.pathname.includes('/simple_initiative_registration')
    }

    const acceptedCookie = () => {
        setIsAuthCookie(true)
        saveAcceptedCookies()


    }

    return (
        <>
            {(!isAuthCookie && !pathInIgnoredPaths() )?
                <StyledCookieNotificationDivMain>
                    <StyledCookieNotificationDiv>
                        <Col xs={9} lg={10}>
                            <span>{t("This site uses cookies to offer you a better browsing experience. You need to accept to remove this message.")}</span>
                        </Col>
                        <Col xs={3} lg={2}>
                            <StyledResponsiveThemeButton
                                onClick={acceptedCookie}
                                data-cy="accept-cookie-button"
                            >
                                {t("Accept")}
                            </StyledResponsiveThemeButton>
                        </Col>
                    </StyledCookieNotificationDiv>
                </StyledCookieNotificationDivMain>
                : null}
        </>
    )
}