import React, {useEffect, useRef, useState} from 'react';
import {DragDropContext} from 'react-beautiful-dnd';
import style from './style.module.css'
import DroppableComponent from "./../PhaseFormBuilder/CoreComponents/droppable";
import DraggableComponent from "./../PhaseFormBuilder/CoreComponents/draggable";
import DraggableCard from "./../PhaseFormBuilder/CoreComponents/DraggableCard";
import {Col, Row} from "react-bootstrap";
import getComponent, {COMPONENTS, createJsonComponent} from "./FormComponents/Factory";
import {DraggableContent, DropableContent} from "../PhaseFormBuilder/StyledComponents";
import {useConfirmUnsavedChangesContext} from "../../../global/context/ConfirmUnsavedChangesContext";
import {useTranslation} from "react-i18next";
import {getIcon} from "../PhaseFormBuilder/FormComponents/DefaultComponent";
import { useProgramContext } from '../../../pages/ProgramsNavigator';
import { EVALUATION_FORMATS } from './../../../global/utils/Constants';


function EvaluatorFormBuilder({

                                  questions,
                                  scale,
                                  formRef,
                                  handleAutoSave,
                                  form,
                                  setFormulario,
                                  onChangeValue,
                                  remaningScore,
                                  program
                              }) {


    //Functions of form
    const [scroll, setScroll] = useState(false)
    const [animationHiddenItems, setAnimationHiddenItems] = useState(false)
    const openedComponent = useRef(null);
    const {setOpenConfirmation, setMessage} = useConfirmUnsavedChangesContext()
    const {t} = useTranslation();

    useEffect(() => {
        if (scroll === true) {
            scrollToBottom()
            setScroll(false)
        }
    }, [formRef.current, scroll])

    const HAS_WEIGHTED = program?.evaluation_format === "weighted"

    useEffect(() => {
        if (HAS_WEIGHTED && remaningScore > 0.0) {
            setOpenConfirmation(true)
            setMessage(
                {
                    title: t('Incomplete form'),
                    body: t('The form needs to distribute all the weights'),
                    cancelButton: t('No, Go Back'),
                    okButton: t('Yes, set later'),
                })
        } else {
            setOpenConfirmation(false)
        }
    }, [remaningScore])

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    };
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const copy = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [copy] = sourceClone.splice(droppableSource.index, 1);
        let json = createJsonComponent(copy)
        json = {...json, label: json.label}
        if (json.choices) {
            json.choices = json.choices.map((item) => ({...item, 'value': t(item.value)}))

        }
        destClone.splice(droppableDestination.index, 0, json);
        return destClone;
    };

    const remove = (index) => {
         // form.splice(index, 1)
        formRef.current.splice(index, 1)
        handleAutoSave(formRef.current)
        setFormulario([...formRef.current])
    }

    function handleRemoveClick(event, index) {
        const timeout = 500
        const containerParent = event.target.closest('div[data-close-ref]')
        containerParent.style.transition = `${timeout / 1000}s`
        containerParent.style.transform = 'translateX(100%)'
        containerParent.style.opacity = 0
        setTimeout(() => {
            remove(index)
        }, timeout)
    }


    const onDragEnd = result => {
        const {source, destination} = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId !== destination.droppableId) {
            let item_dragable_list = source.droppableId === 'components_list' ? COMPONENTS : questions
            const result = copy(
                item_dragable_list,
                formRef.current,
                source,
                destination
            );
            openedComponent.current && openedComponent.current.click()
            setScroll(formRef.current?.length === destination.index)
             setFormulario(result);
             formRef.current= result
            handleAutoSave(formRef.current)
        } else if (source.droppableId === 'form') {
            const items = reorder(
                formRef.current,
                source.index,
                destination.index
            );
             setFormulario(items);
             formRef.current= items
            handleAutoSave(formRef.current)
        }
    };

    const checkQuestionInForm = (name) => {
        let exists = false;
        formRef.current.forEach((item) => {
            if (item.name === name) exists = true
        })
        return exists
    }

    function createComponent(json, index) {
        switch (json.type) {
            case 'division':
                return <hr key={json.type + index}
                           style={{margin: 0}}/>

            case 'title_form_group':
                return <h2 key={json.type + index}
                           style={{
                               fontWeight: 'bold',
                               fontSize: 14,
                               color: "#C4C4C4",
                               textTransform: 'uppercase',
                               margin: '14px 16px 4px 16px'
                           }}>{t(json.name)}</h2>
            default:
                return (
                    <DraggableComponent id={json.type} index={index}
                                        key={json.type}
                                        render_clone={true}>
                        {/*<StyleDragImgDiv>*/}
                        {/*    <StyleDragImg src={DragIcon}/>*/}
                        {/*</StyleDragImgDiv>*/}
                        <DraggableCard {...json}/>
                    </DraggableComponent>
                )
        }

    }

    function startAnimation() {
        setAnimationHiddenItems(true)
    }

    function endAnimation() {
        setAnimationHiddenItems(false)
    }

    return (

        <DragDropContext onDragEnd={onDragEnd}>
            <Row style={{margin: 0}}>
                <Col xs={6} md={9} xl={9} style={{paddingLeft: 0, height: "70vh", overflow: "auto"}}>
                    <DropableContent>
                        <DroppableComponent id={'form'} showContent={true}>

                            {
                                form?.map((component_item, index) =>
                                    <DraggableComponent
                                        id={component_item.name}
                                        index={index}
                                        key={component_item.name + index}>

                                        {getComponent({
                                            ...component_item,
                                            index,
                                            remaningScore,
                                            scale,
                                            openedComponent,
                                            program,
                                            remove: handleRemoveClick,
                                            onchange: onChangeValue,
                                        })}
                                    </DraggableComponent>
                                )}
                        </DroppableComponent>
                    </DropableContent>
                </Col>
                {/*<Col sm={3} md={3} xl={3} style={{position: 'fixed', right: '0', maxWidth: '22%'}}>*/}
                <Col xs={6} md={3} xl={3}
                     style={{right: '0', top: "0px"}}>
                    <div style={{top: "80px"}}>
                        <label style={{width: '100%'}}> <span
                            style={{fontWeight: 'bold', fontSize: 14}}>{t("Evaluation Format")}:</span> {t(EVALUATION_FORMATS[program.evaluation_format])}
                        </label>
                        {HAS_WEIGHTED && <div>
                            <label style={{width: '100%'}}> <span
                                style={{fontWeight: 'bold', fontSize: 14}}>{t("Remaining weight")}:</span> {remaningScore}
                            </label>
                            <label style={{width: '100%'}}> <span style={{
                                fontWeight: 'bold',
                                fontSize: 14
                            }}>{t("Total weight")}:</span> {100 - remaningScore}/100</label>
                        </div>}
                        
                        <DraggableContent>
                            <DroppableComponent id={'components_list'}
                                                disabled_drop={true}>
                                {COMPONENTS.map(createComponent)}
                            </DroppableComponent>


                        </DraggableContent>
                        <DraggableContent>


                            <DroppableComponent id={'questions_list'}
                                                disabled_drop={true}
                                                showScroll={true}>

                                <h2 data-parent-effect={''}
                                    className={style.animation}
                                    style={{
                                        transition: '0.5s',
                                        background: animationHiddenItems ? '#C4C4C4' : '#fff',
                                        fontWeight: 'bold',
                                        fontSize: 14,
                                        color: animationHiddenItems ? '#fff' : '#C4C4C4',
                                        textTransform: 'uppercase',
                                        padding: '14px 16px 4px 16px'
                                    }}>{t("Hidden itens")}</h2>
                                {questions?.length > 0 ? questions?.map((question, index) => (!checkQuestionInForm(question.name)) &&
                                    <DraggableComponent

                                        id={'question' + question.name}
                                        index={index}
                                        key={'question' + question.name}
                                        render_clone={false}>
                                        <div style={{margin: '8px 16px'}} onAnimationStart={startAnimation}
                                             onAnimationEnd={endAnimation}>


                                            <DraggableCard name={question.label}
                                                           icon={getIcon(question.type_question)}/>
                                        </div>
                                    </DraggableComponent>
                                ) : <span>{t("Empty")}</span>}
                            </DroppableComponent>

                        </DraggableContent>

                    </div>
                </Col>
            </Row>
        </DragDropContext>
    )
}

export default EvaluatorFormBuilder