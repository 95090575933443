import React, {useEffect, useMemo, useRef, useState} from 'react';
import './style.css';
import api from "../../../../../global/services/api";

import Loading from "../../../../../global/components/feedback/Loading";
import EvaluatorFormBuilder
    from "../../../../../components/Phases/EvaluatorFormBuilder";
import {useParams} from "react-router-dom";

import {
    useAutoSaveContext
} from "../../../../../global/context/AutoSaveContext";
import * as ConstantsMessage
    from "../../../../../global/utils/fileWithMessage";
import ButtonDefault from "../../../../../components/Buttons/ButtonDefault";
import {confirmAlert} from "react-confirm-alert";
import {BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import {useTranslation} from "react-i18next";
import {Alert} from "react-bootstrap";
import styled from 'styled-components';
import {
    ToastNotification
} from "../../../../../global/components/feedback/ToastNotification";
import {
    CardContainer
} from "../../../../../global/components/misc/CardContainer";
import { useProgramContext } from '../../../../ProgramsNavigator';

const AlertContainer = styled.div`
  width: 74%;
`;

function PhaseEvaluateForm() {
    let {id, phase_id} = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const [form, setFormulario] = useState([]);
    const [dataRequest, setDataRequest] = useState(false)
    const formRef = useRef([]);


    const [remaningScore, setRemaningScore] = useState();
    const {program} = useProgramContext();

    const {t} = useTranslation();

    useEffect(() => {
        setRemaningScore(remaningScoreValue())
    }, [form])

    useEffect(() => {
        const getPhaseData = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(`phases/${phase_id}/phase_evaluator_form`);
                setDataRequest(response.data)
                formRef.current = _validQuestionOnForm(response.data.form, response.data.questions)
                setFormulario(formRef.current)

            } catch (error) {
                BuildFieldErrorsDict(error, null, false);
            } finally {
                setIsLoading(false);
            }
        };
        getPhaseData();
    }, []);


    // useEffect(() => {
    //
    // }, [form]);


    //INIT AUTO SAVE
    const {
        showAutoSave,
        hideAutoSave,
        startSave,
        setAutoSaveSuccess,
        setAutoSaveError,
    } = useAutoSaveContext();
    // const [isChangedForm, setIsChangedForm] = useState(false);
    // const [isRequestSending, setIsRequestSending] = useState(false);

    useEffect(() => {
        showAutoSave();
        return () => {
            hideAutoSave();
        };
    }, []);

    // useEffect(() => {
    //     !isChangedForm && setIsChangedForm(true);
    // }, [form]);
    //
    // useEffect(() => {
    //     if (isChangedForm && !isRequestSending && dataRequest.id) {
    //         handleAutoSave();
    //     }
    // }, [isChangedForm, isRequestSending, form.length]);

    const handleAutoSave = async (form) => {
        // setIsChangedForm(false);
        // setIsRequestSending(true);
        // startSave();
        try {
            const data = {'form': form};
            await api.patch(`phase_evaluator_forms/${phase_id}`, data);
            // setAutoSaveSuccess();
        } catch (e) {
            BuildFieldErrorsDict(e, null, false);
            setAutoSaveError();
        } finally {
            // setIsRequestSending(false);
        }
    };
    //END AUTO SAVE

    const onChangeValue = (index, key, value) => {
        formRef.current[index][key] = value
        handleAutoSave(formRef.current)

        if (key === 'weight') {
           setRemaningScore(remaningScoreValue());
        }

    };

    function remaningScoreValue(){
         let total = 0.00;
            form.forEach((element) => {
                'weight' in element && (total += parseFloat(element.weight));
            });
        let remaining_aux = parseFloat(100 - total)
        if (remaining_aux < 0){
            remaining_aux = 0
        }
        return remaining_aux.toFixed(2)
    }



    function _validQuestionOnForm(form, questions) {

        if (form && questions) {
            let makeUpdateForm = false;
            const form_validated = form.filter((item) => {
                if (item.type === 'question') {
                    const hasQuestionOnFOrm = questions.some((question) => {
                        return item.name === question.name;
                    });
                    if(!hasQuestionOnFOrm){
                        makeUpdateForm = true
                    }
                    return hasQuestionOnFOrm
                } else {
                    return true;
                }
            });
            if(makeUpdateForm){
                handleAutoSave(form_validated)
            }
            return form_validated;
        } else {
            return questions;
        }
    }


    const formIsInvalid = (toastError) => {
        let isInvalid = false;
        let amount_weidth_pending = 0;
        if (program.evaluation_format !== "weighted") {
            return false;
        }
        form.forEach((element) => {
            if ('weight' in element && element.weight === 0.00) {
                isInvalid = true;
                amount_weidth_pending += 1;
            }
        });

        if (amount_weidth_pending > 0) {
            toastError && ToastNotification(`${t("Weight cannot be 0 and There are")} ${amount_weidth_pending} ${t("score input with weight egual 0")}`, 'warning');
        }
        if (parseFloat(remaningScore) !== 0.00) {
            isInvalid = true;
            toastError && ToastNotification(t('The sum of weights  must equal 100'), 'warning');
        }
        if (form.length === 0) {
            toastError && ToastNotification(t("Empty form cannot be saved"), 'warning');
            isInvalid = true;
        }
        return isInvalid;
    };

    function handleReset() {
        confirmAlert({
            title: t('Reset form'),
            message: t('Are you sure you want to reset the current form?'),
            buttons: [
                {
                    label: t('No'),
                    onClick: () => '',
                },
                {
                    label: t('Yes'),
                    onClick: () => {
                        formRef.current = dataRequest.questions
                        setFormulario(formRef.current);
                        handleAutoSave(formRef.current)
                    },
                },
            ],
        });
    }

    const handleSaveForm = async () => {
        if (formIsInvalid(true)) return;
        const data = {'form': form};
        try {
            const response = await api.patch(`phase_evaluator_forms/${dataRequest.id}`, data);
            if (response.status === 200) {
                ToastNotification(t(ConstantsMessage.SUCCESS));
            }
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
    };


    // useEffect(() => {
    //     getProgram(id, setIsLoading, setProgram);
    // }, []);

    return (
        <CardContainer>
            {dataRequest.is_evaluation_submited ?
                <AlertContainer><Alert variant="warning">
                    <Alert.Heading>{t("Attention!")}</Alert.Heading>
                    <p>
                        {t("there are evaluations submitted at this phase, edits of this form will not be applied to the submitted evaluations.")}
                    </p>
                </Alert> </AlertContainer> : ''}

            <div className={'phase-form-container'}>

                {isLoading ? <Loading/> :
                    <EvaluatorFormBuilder 
                        questions={dataRequest.questions}
                        scale={dataRequest.program_scale}
                        form={form}
                        handleAutoSave={handleAutoSave}
                        formRef={formRef}
                        setFormulario={setFormulario}
                        onChangeValue={onChangeValue}
                        remaningScore={remaningScore}
                        program={program}
                    />
                }
                <div
                    className="d-flex align-items-center justify-content-end gap-2">
                    <ButtonDefault
                        style={{marginTop: 0, marginBottom: 0}}
                        color={'secondary'}
                        onClick={handleReset}
                        label={t('Reset')}/>
                    <ButtonDefault
                        style={{marginTop: 0, marginBottom: 0}}
                        action={'primary'}
                        onClick={handleSaveForm}
                        label={t('Finish')}
                        data-cy="finish-form-button"/>
                </div>

            </div>
        </CardContainer>
    );
}

export default PhaseEvaluateForm;