import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {
    FlexContainer
} from "../../../../components/authentication/StyledComponents";
import {neutral_5} from "../../../../utils/Colors";
import InnovationDateImage from "../../../../../assets/img/innovation_date.png"
import InnovationFilterImage
    from "../../../../../assets/img/innovation_filter.png"
import InnovationExpandGraph from "../../../../../assets/img/expand_graph.png"
import { Typography, Space, Modal} from "antd";
import Button from "../../../../components/data_entry/Button";
import {
    CalendarOutlined,
    FilterOutlined, PieChartOutlined,
    QuestionCircleOutlined
} from "@ant-design/icons";
import useSystemBreakpoint from "../../../../hooks/useSystemBreakpoint";

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
    color: white;
    font-size: 20px;
    cursor: pointer;

`


export default function ButtonHelp({}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false)


    function onClose(){
        setOpen(false)
    }

    return (
        <>

            <StyledQuestionCircleOutlined onClick={() => setOpen(true)}/>

            <Modal open={open}
                   width={1200}
                   onCancel={onClose}
                   // okText={'Close'}
                   footer={<FlexContainer
                       justify={'end'}><Button onClick={onClose}>{t('Close')}</Button></FlexContainer>}
                // footer={<>}
                // onOk={handleOk}
                // onCancel={handleCancel}
            >
                <FlexContainer gap={24} direction={'column'}>
                    <HelpCard hasBorder={false}
                              title={<Typography.Title
                                  level={3}>{t('Innovation Panel')}</Typography.Title>}
                              text={t('Welcome to the Innovation Dashboard, an essential tool for managers to monitor and manage their programs and projects. This dashboard is designed to provide a comprehensive and personalized view of the indicators and metrics that are most relevant to you. The Innovation Dashboard only displays active programs for which you are the manager.')}/>
                    <HelpCard
                        title={<Space align={'center'}>
                            <CalendarOutlined/>
                            <Typography.Title
                                level={5}>{t('Data time period')}</Typography.Title>
                        </Space>}
                        img={InnovationDateImage}
                        text={t('By default, the dashboard displays data for the entire period, but you can view specific information based on the time period you want. Simply click on the date field, select the start and end date, and the dashboard will automatically update the data displayed.')}/>
                    <HelpCard title={<Space align={'center'}>
                        <FilterOutlined/>
                        <Typography.Title
                            level={5}>{t('Program and Project Filter')}</Typography.Title>
                    </Space>}
                              text={t('To the right of the graphs, you will find the "Program Filter" option. This tool allows you to further refine the information displayed, showing only the programs you want to analyze. By default, SOLV comes with up to 5 active programs in the filter. You can click on the filter to select the programs you want to see.')}
                              img={InnovationFilterImage}/>
                    <HelpCard title={<Space align={'center'}>
                        <PieChartOutlined/>
                        <Typography.Title
                            level={5}>{t('Customizable graphics')}</Typography.Title>
                    </Space>}
                              text={t('INNOVATION_EXPAND_TEXT')}
                              img={InnovationExpandGraph}/>

                </FlexContainer>
            </Modal>
        </>


    )
}

const CardCointainer = styled.div`
    width: 100%;
    border-radius: 8px;
    padding: 16px;
    border: ${({hasBorder}) => hasBorder ? `1px solid ${neutral_5}` : 'none'};

`
const Title = styled.div`
    & * {
        margin-bottom: 0px !important;
    }

    margin-bottom: 8px;

`

const Image = styled.img`
    object-fit: cover;
    max-width: 100%;
`

function HelpCard({title, text, img, hasBorder = true, ...props}) {
    const {isDesktop} = useSystemBreakpoint()
    return (
        <CardCointainer gap={8} hasBorder={hasBorder} {...props}>
            <Title>{title}</Title>
            <FlexContainer align={'start'} wrap={isDesktop?'noWrap': 'wrap'} gap={8}>
                <Typography.Text >
                    {text}
                </Typography.Text>
                {img && <Image src={img}/>}
            </FlexContainer>
        </CardCointainer>
    )
}

