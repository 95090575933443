import {Badge, Col, Space, Modal, Button} from "antd";
import SolvCollapse from "../../../../global/components/misc/SolvCollapse";
import ApplicationActionCardButton
    from "../../../../global/components/other/application/ApplicationDrawer/components/SettingsTab/components/ApplicationActionCardButton";
import {APPLICATION_COLOR} from "../../../../global/utils/Colors";
import WideSpace from "../../../../global/components/layout/WideSpace";
import {ArrowRightOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import RemixIcon from "../../../../global/components/data_display/RemixIcon";
import {useEffect, useState} from "react";
import LinkProjectApplicationDrawer from "./components/LinkProjectApplicationDrawer";
import api from "../../../../global/services/api";
import {useParams} from "react-router-dom/cjs/react-router-dom.min";
import ApplicationInfoCard from "../../../../global/components/data_display/ApplicationInfoCard/ApplicatioInfoCard";
import {ToastNotification} from "../../../../global/components/feedback/ToastNotification";
import {BuildFieldErrorsDict} from "../../../../global/utils/Utils";
import Loading from "../../../../global/components/feedback/Loading";

const {confirm} = Modal

function ProjectManagementLinkedPage() {
    const {t} = useTranslation();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingFetch, setLoadingFetch] = useState(false)
    const [linkedApplications, setLinkedApplications] = useState();
    const {id} = useParams();


    const fetchLinkedApplications = async () => {
        try {
            setLoadingFetch(true)
            const response = await api.get(`project_management/project/${id}/get_linked_applications`)
            setLinkedApplications(response.data)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        } finally {
            setLoadingFetch(false)
        }
    }

    const handleUnlinkApplication = async (applicationId) => {
        try {
            setLoading(true)
            const data = {application: applicationId}
            await api.post(`project_management/project/${id}/unlink_application`, data)
            ToastNotification(t("Application successfully unlinked"))
            setLinkedApplications(undefined)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        } finally {
            setLoading(false)
        }
    }

    const confirmUnlinkApplication = (applicationId) => {
        confirm({
            title: t("You are unlinking a application from this project"),
            icon: <ExclamationCircleOutlined/>,
            content: t("Do you really want to unlink this application?"),
            okText: t("Yes"),
            cancelText: t("No"),
            okButtonProps:{"data-cy": "confirm-unlink-application-button"},
            onOk: () => handleUnlinkApplication(applicationId)
        });
    }

    useEffect(() => {
        !linkedApplications && fetchLinkedApplications()
    }, [linkedApplications])

    return (
        <>
            <Col span={24}>
                <SolvCollapse title={t("Project Link")} active={true} bordered={true}>
                    <WideSpace direction="vertical" size="middle">
                        {loadingFetch ? <Loading/> :
                            <>
                                <ApplicationActionCardButton
                                    text={t("Link the application")}
                                    help={t("This project will be linked to an application.")}
                                    icon={<RemixIcon remixIconName="ri-git-merge-line"
                                                     style={{color: APPLICATION_COLOR}}/>}
                                    sufixIcon={
                                        <Space>
                                            <Badge count={t("NEW")} color="#096DD9"/>
                                            <ArrowRightOutlined/>
                                        </Space>
                                    }
                                    onClick={() => setOpenDrawer(true)}
                                    data-cy="link-application-button"
                                />
                                {linkedApplications?.map((application) => (
                                    <ApplicationInfoCard key={application.id} application={application}>
                                        <Button type='default' onClick={() => confirmUnlinkApplication(application?.id)}
                                                loading={loading} data-cy={`unlink-application-${application.id}-button}`}>
                                            <RemixIcon remixIconName="ri-delete-bin-7-line"/>
                                            {t("Remove from project")}
                                        </Button>
                                    </ApplicationInfoCard>
                                ))}
                            </>
                        }
                    </WideSpace>
                </SolvCollapse>
            </Col>
            <LinkProjectApplicationDrawer open={openDrawer} setOpen={setOpenDrawer} afterSubmit={() => {
                setLinkedApplications(undefined)
            }}/>
        </>
    );
}

export default ProjectManagementLinkedPage;