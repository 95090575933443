import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import api from "../../global/services/api";
import {Col, Container, Row} from "react-bootstrap";
import {BuildFieldErrorsDict} from "../../global/utils/Utils";
import {useTranslation} from "react-i18next";

const Info = styled.p`
  text-align: left;
    margin-bottom: 4px;
  white-space: pre-line;
`

const Label = styled.span`
  font-weight: bold;
`

const Text = styled.p`
  margin-bottom: 16px;
  white-space: pre-line;
`

const Description = styled.div`
  height: 300px;
  overflow: scroll;
`

function ConfirmInteraction({applications, members, managers, interaction}) {
    const {t} = useTranslation();
    const [totalizers, setTotalizers] = useState({})


    useEffect(() => {
        if (applications || members || managers) {
            getTotalizersInteraction()
        }

    }, [])


    const getTotalizersInteraction = async () => {
        try {
            const response = await api.post(`interaction/get_emails_totalizers`, {
                'members': members,
                'managers': managers,
                'applications': applications
            })

            setTotalizers(response.data)

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        }
    }

    return (
            <Container>
                <Row>
                    <Col>
                        <div>
                            <Info><Label>{t("Subject")}: </Label></Info>
                            <Text>
                                {interaction.title_email}
                            </Text>
                        </div>
                        <div>
                            <Info><Label>{t("Message")}: </Label></Info>
                            <Text>
                                <Description>
                                    {interaction.message_email}
                                </Description>
                            </Text>
                        </div>
                    </Col>
                    <Col xs={5}>
                        <Info><Label>{t("Managers")}:</Label> {totalizers.managers}</Info>
                        <Info><Label>{t("Evaluators")}:</Label> {totalizers.evaluators}</Info>
                        <Info><Label>{t("Company members")}:</Label> {totalizers.members_team}</Info>
                        <Info><Label>{t("Total")}:</Label> {totalizers.total_emails}
                        </Info>
                    </Col>
                </Row>
            </Container>
    );
}

export default ConfirmInteraction;