import Modal from "react-bootstrap/Modal";
import React, {useState} from "react";
import FormInteraction from "./FormInteraction";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const StyleModalBodyOverrider = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  min-height: 560px;

`


function NewInteraction(props) {

    const [title, setTitle] = useState('form')
    const {t} = useTranslation()
    return (
        <>
            <Modal
                {...props}
                size="xl"
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {props.interaction.id ? props.interaction.status !== 'created' ? `${t('Info interaction')} ${title}` : `${t('Update interaction')} ${title}` : `${t('Create interaction')} ${title}`}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <StyleModalBodyOverrider>
                        <FormInteraction setTitle={setTitle} interaction={props.interaction} close={props.onHide}
                                         createManualInteraction={props.createManualInteraction}
                                         updateManualInteraction={props.updateManualInteraction}/>

                    </StyleModalBodyOverrider>

                </Modal.Body>
            </Modal>

        </>


    );
}

export default NewInteraction;