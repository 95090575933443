import { useState } from "react";
import { BuildFieldErrorsDict } from "../utils/Utils";
import { useEffect } from "react";
import api from "../services/api";


export default function usePhaseForm(application, phase){
    const [form, setForm] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

      function generateQuestionResponses(questionFormPhase, sections) {
        let newQuestionFormPhase = []

        questionFormPhase.forEach((item) => {

            if (application.response_form[item.name]) {
                item.value = application.response_form[item.name].value;
            }
            newQuestionFormPhase.push(item)

            if (item.type === "section") {
                const section_id = item.choices.find(({value}) => value === item.value[0]).section
                const section = sections.find(({id}) => id === section_id)
                let list_children = []
                if(section_id) {
                    list_children= generateQuestionResponses(section.questions, sections)
                }

                newQuestionFormPhase = [...newQuestionFormPhase, ...list_children]
            }
        });

        return newQuestionFormPhase
    }


       const getForm = async () => {
        try {
            const responseQuestionForm = await api.get(`phases/${phase.id}/phase_form`);
            let questionFormPhase = responseQuestionForm.data.form;
            let newQuestionFormPhase = []
            if (application.response_form && questionFormPhase) {
                newQuestionFormPhase = generateQuestionResponses(questionFormPhase, responseQuestionForm.data?.sections?.sections)
            }
            setForm(newQuestionFormPhase);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        } finally {
            setIsLoading(false);
        }
    }

    const getAssessmentFormResponse = async () => {
        try {
            const responseQuestionForm = await api.get(`applications/${application.id}/assessment_by_phase_order_score/${phase.id}`);
            setForm(responseQuestionForm.data);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        switch(phase.type) {
            case 1:
                void getForm();
                break;
            case 2:
                void getAssessmentFormResponse();
                break;
            default:
                setIsLoading(false);
        }
    }, [application, phase]);

    return {form, isLoading};
}