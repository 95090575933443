import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Row, Space, Typography} from 'antd';
import {useTranslation} from "react-i18next";
import {
    FlexContainer
} from "../../../../../global/components/authentication/StyledComponents";

import Loading from "../../../../../global/components/feedback/Loading";
import Empty from "../../../../../global/components/data_display/Empty";
import Search from "../../../../../global/components/data_entry/Search";
import CardFilterStartup
    from "../../../../../global/components/data_display/CardFilterStartup";



export default function ChooseManualFilter({
                                          openDrawerManualStatupsList,
                                          isLoading,
                                          filters
                                      },) {
    const {t} = useTranslation()
    return (
        <>


            <Row style={{marginTop: 8}} gutter={[20, 20]}>

                <Col xs={24}>
                    <Row gutter={[32, 32]}>
                        {isLoading ? (<Col xs={24}><Loading/></Col>) : (
                            filters.length > 0 ? (filters.map((tag) => (
                                <Col xs={24} md={10} lg={8} xl={6}><CardFilterStartup
                                    onClick={() => openDrawerManualStatupsList({tags: [tag.id]})}
                                    title={tag.name}
                                    number={tag.organizations_counter}/></Col>
                            ))) : (<Col xs={24}><Empty
                                description={t('No filters at the moment')}/></Col>)
                        )}

                    </Row>
                </Col>
            </Row>
        </>
    )
}