import React from 'react'
import styled from 'styled-components'
import {useTranslation} from "react-i18next";

const Limit = styled.span`
  font-size: 10px

`

export default function LimitUploadInfo({limit}){
    const {t} = useTranslation();
    return (
        <Limit>
            {`(${t('Max upload')} - ${limit} MB)`}
        </Limit>
    )
}