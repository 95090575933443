import React, {useEffect, useRef} from 'react'
import Label from './Label'
import MessageError from './MessageError'
import styled from 'styled-components'
import InputContainer from "./InputContainer";
import InterrogationTip from "../InterrogationTip";
import CharactersCounter from "./CharactersCounter";

const StyledTextArea = styled.textarea`
  color: #949494;
  padding: 7px 5px;
  font-size: 18px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgb(221, 221, 221);
  overflow-y: scroll;
  -ms-overflow-style: none;
  min-height: ${({minHeight}) => minHeight ? minHeight : '100px'};
  outline: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::placeholder {
    color: #c4c4c4;
  }

`

export default function TextArea({
                                     elementDescription,
                                     required,
                                     id,
                                     label,
                                     messageError,
                                     defaultValue,
                                     inputName,
                                     height,
                                     information,
                                     autoResize,
                                     ...props
                                 }) {
    const text_area_ref = useRef(null);

    const resize = () => {
        text_area_ref.current.setAttribute('style', `height:auto`)
        text_area_ref.current.setAttribute('style', `height:${text_area_ref.current.scrollHeight}px`)
    }

    useEffect(() => {
        autoResize && resize()
    }, [props.value])

    return (
        <InputContainer data-cy="textarea-container">
            <div style={{display: "flex", alignItems: "center"}}>
                {label &&
                    <Label htmlFor={id} required={required}>{label}</Label>}
                {information ? <InterrogationTip title={information}/> : null}
            </div>
            {elementDescription}
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <StyledTextArea minHeight={height} name={inputName} required={required}
                                ref={text_area_ref}
                                id={id} {...props}
                                defaultValue={defaultValue}/>
                <CharactersCounter value={defaultValue} maxLenght={props.maxLength}/>
                {messageError && <MessageError>{messageError}</MessageError>}
            </div>

        </InputContainer>
    )
}