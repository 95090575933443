import styled from "styled-components";
import WideSpace from "../layout/WideSpace";
import {Button, Modal, Typography, Form as FormAntd, Space} from "antd";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import api from "../../services/api";
import {ToastNotification} from "../feedback/ToastNotification";
import {ERROR, SUCCESS} from "../../utils/fileWithMessage";
import Drawer from "../layout/Drawer";
import {useUserContext} from "../../context/UserContext";
import CheckboxFormField from "../data_entry/form/CheckboxFormField";
import InputFormField from "../data_entry/form/InputFormField";
import Form from "../data_entry/form/Form";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import RequestSelectFormField from "../data_entry/form/RequestSelectFormField";
import {BuildFieldErrorsDict} from "../../utils/Utils";
import AttachingTags from "../tag/AttachingTags";
import {APPLICATION_TAG, USER_TAG} from "../../utils/Choices";

const {Text} = Typography;
const {confirm} = Modal

const StyledFooter = styled(WideSpace)`
    justify-content: end;
`

// const StyledForm = styled(Form)`
//   .ant-form-item {
//     margin: 0;
//   }
// `

const FORM_NAMES = {
    EMAIL: 'email',
    IS_ADMIN: 'is_admin',
    IS_GAMIFICATION_MANAGER: 'is_gamification_manager',
    MANAGER_PROGRAM: 'manager_program',
    PROGRAMS: 'programs',
    MANAGER_PROJECT: 'manager_project',
    PROJECTS: 'projects',
    COLLABORATOR: 'collaborator',
}

export default function UserPermissionDrawer({
                                                 resetTable,
                                                 userClientId,
                                                 setUserClientId,
                                                 open,
                                                 setOpen,
                                                 handleRequestTable
                                             }) {
    const {t} = useTranslation();
    const [userClient, setUserClient] = useState(undefined);
    const [isLoading, setIsLoading] = useState(undefined);
    const [hasChange, setHasChange] = useState(false);
    const [form] = FormAntd.useForm();
    const {setRefresh, _user} = useUserContext();
    const isAdminValue = FormAntd.useWatch(FORM_NAMES.IS_ADMIN, form)
    const isManagerProgramValue = FormAntd.useWatch(FORM_NAMES.MANAGER_PROGRAM, form)
    const isManagerProjectValue = FormAntd.useWatch(FORM_NAMES.MANAGER_PROJECT, form)

    useEffect(() => {
        form.setFieldsValue({
            [FORM_NAMES.MANAGER_PROJECT]: isAdminValue,
            [FORM_NAMES.IS_GAMIFICATION_MANAGER]: isAdminValue,
            [FORM_NAMES.MANAGER_PROGRAM]: isAdminValue,
        })
    }, [isAdminValue])

    useEffect(() => {
        const fetchUserPermission = async () => {
            try {
                const response = await api.get(`/users_client/${userClientId}/user_permissions`);
                form.setFieldsValue({
                    [FORM_NAMES.EMAIL]: response.data.user.email,
                    [FORM_NAMES.MANAGER_PROGRAM]: response.data.user_permissions.is_program_manager,
                    [FORM_NAMES.PROGRAMS]: response.data.programs_ids,
                    [FORM_NAMES.MANAGER_PROJECT]: response.data.user_permissions.is_project_manager,
                    [FORM_NAMES.PROJECTS]: response.data.projects_ids,
                    [FORM_NAMES.IS_ADMIN]: response.data.is_admin,
                    [FORM_NAMES.IS_GAMIFICATION_MANAGER]: response.data.user_permissions.is_gamification_manager,
                })
                setUserClient(response.data);
            } catch (error) {
            }
        }
        userClientId && void fetchUserPermission();
    }, [userClientId]);

    useEffect(() => {
        if (userClient) {
            const {user_permissions: userPermissions} = userClient;
            form.setFieldsValue({
                admin: userPermissions.is_program_admin || userPermissions.is_project_admin,
                program_manager: userPermissions.is_program_manager,
                program_evaluator: userPermissions.is_program_evaluator,
                project_manager: userPermissions.is_project_manager,
            });
        }
    }, [userClient])

    async function getFieldsValue() {
        const data = {...await form.getFieldsValue()}
        delete data[FORM_NAMES.COLLABORATOR]
        delete data[FORM_NAMES.MANAGER_PROGRAM]
        delete data[FORM_NAMES.MANAGER_PROJECT]
        // if(data[FORM_NAMES.IS_ADMIN]){
        //     delete data[FORM_NAMES.PROGRAMS]
        //     delete data[FORM_NAMES.PROJECTS]
        // }

        return data
    }


    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            const data = await getFieldsValue()

            const response = await api.post(`/users_client/add_collaborator`, data);
            ToastNotification(response.data.detail);
            handleRequestTable()
            onClose()
        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, null)
        } finally {
            setIsLoading(false)
        }
    }

    const toggleUserActivation = async () => {
        try {
            await api.post(`/users_client/${userClientId}/toggle_user_activation`);
            ToastNotification(t(SUCCESS));
            onClose()
            handleRequestTable()
        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, null)
        }
    }

    const confirmUserActivationToggle = () => {
        if (userClient?.is_active) {
            confirm({
                title: t("Deactivate User"),
                content: t("When you deactivate a user, they will no longer have access to their environment on the SOLV platform. Do you wish to proceed with deactivation? This action can be reversed if you decide to do so in the future."),
                okText: t("Yes"),
                cancelText: t("No"),
                okButtonProps: {
                    danger: true,
                },
                onOk() {
                    toggleUserActivation();
                }
            });
        } else {
            toggleUserActivation();
        }
    }

    // function onClose() {
    //     setOpen(false)
    //     setUserClient(false)
    //     form.resetFields()
    // }

    function onClose() {
        setUserClientId(null)
        if (hasChange) {
            resetTable()
        }
        setHasChange(false)
        setOpen(false)
    }

    function refreshContentOnClose() {
        setHasChange(true)
    }

    return (
        <Drawer
            isGrayBody
            title={userClientId ? t("Update user") : t("New User")}
            open={open}
            onClose={onClose}
            setOpen={setOpen}
            destroyOnClose={true}
            footer={
                <StyledFooter>
                    {userClientId && <Button
                        onClick={confirmUserActivationToggle}>{userClient?.is_active ? t("Deactivate User") : t("Activate User")}</Button>}
                    <Button type="primary"
                            loading={isLoading}
                            onClick={form.submit}>{t("Save")}</Button>
                </StyledFooter>
            }
        >
            <Form form={form} onFinish={handleSubmit}
                  preserve={false}
                  disabled={isLoading}
            >
                <InputFormField disabled={!!userClientId} form={{
                    label: t('Collaborator email'),
                    name: FORM_NAMES.EMAIL,

                    rules: [{
                        required: true,
                        message: t('E-mail is required')
                    }, {
                        type: 'email',
                        message: t('Please provide a valid email')
                    }
                    ]


                }}/>


                <CheckboxFormField
                    mode={'single'}
                    label={t('General Environment Administrator')} form={
                    {
                        name: FORM_NAMES.IS_ADMIN,
                        help:
                            <Space><ExclamationCircleOutlined/>{t('You have access to the entire system, you can add and remove users and change plans.')}
                            </Space>
                    }
                }/>

                <CheckboxFormField
                    mode={'single'}
                    disabled={isAdminValue}
                    label={t('Gamification Manager')} form={
                    {
                        name: FORM_NAMES.IS_GAMIFICATION_MANAGER,
                        help:
                            <Space><ExclamationCircleOutlined/>{t('The gamification manager has access to the gamification tab and gamification options in programs.')}
                            </Space>
                    }
                }/>

                <CheckboxFormField
                    disabled={isAdminValue}
                    mode={'single'}
                    label={t('Program Manager')}
                    form={
                        {
                            name: FORM_NAMES.MANAGER_PROGRAM,
                            help:
                                <Space><ExclamationCircleOutlined/>{t('You can change settings, allocate evaluators and check applications of the programs you manage.')}
                                </Space>
                        }
                    }/>
                {!isAdminValue && isManagerProgramValue &&
                    <RequestSelectFormField
                        form={{
                            name: FORM_NAMES.PROGRAMS,
                            rules: [{
                                required: true,
                                message: t('This field is require')
                            }],
                            label: t("Select the programs that the user will manage"),

                        }} url={'/programs?fields=id,name'} version={'v2'}
                        mode="multiple"/>}

                <CheckboxFormField
                    mode={'single'}
                    label={t('Project Manager')}
                    disabled={isAdminValue} form={
                    {
                        name: FORM_NAMES.MANAGER_PROJECT,
                        help:
                            <Space>
                                <ExclamationCircleOutlined/>{t('You can change settings, create tasks, and modify indicators for the projects you manage.')}
                            </Space>
                    }
                }/>
                {!isAdminValue && isManagerProjectValue &&
                    <RequestSelectFormField
                        form={{
                            name: FORM_NAMES.PROJECTS,
                            rules: [{
                                required: true,
                                message: t('This field is require')
                            }],
                            label: t("Select the projects that the user will manage"),

                        }} url={'/project_management/projects?fields=id,name'}
                        version={'v2'} mode="multiple"/>}


                <CheckboxFormField
                    mode={'single'}
                    label={t('Collaborator')}
                    disabled={true}
                    form={{
                        name: FORM_NAMES.COLLABORATOR,
                        initialValue: true
                    }}/>
                {userClientId && <AttachingTags
                    refreshContentOnClose={refreshContentOnClose}
                    target={USER_TAG}
                    id={userClientId}/>}
            </Form>

        </Drawer>
    );
}