import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {
    useLayoutContext
} from "../../../modules/project/components/layout/context/LayoutContext";
import {Col, Row, Space} from "antd";
import PointsCard from "./components/PointsCard";
import WalletTransactionHistory from "./components/WalletTransactionHistory";
import {neutral_3, neutral_5} from "../../utils/Colors";
import styled from "styled-components";
import {FlexContainer} from "../../components/authentication/StyledComponents";
import RemixIcon from "../../components/data_display/RemixIcon";
import useDefaultGetRequest from "../../hooks/useDefaultGetRequest";
import {
    APROVED_APPLICATION_GAMIFICATION,
    CLASSIFIED_APPLICATION_GAMIFICATION, FINISHED_ASSESSMENTS_GAMIFICATION,
    SUBMIT_APPLICATION_GAMIFICATION
} from "../../utils/Constants";
import {formatPointNumber} from "../../utils/Utils";

const InfoContainer = styled.div`
    background: white;
    width: 100%;
    border-radius: 8px;
    padding: 16px;
    border: 1px solid ${neutral_5};
`

const TextInfo = styled.span`
    white-space: pre-line;
    font-size: 16px;
    line-height: 3px;
`
const Title = styled.span`
    font-size: 14px;
    color: black;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 6px;
`

const Tag = styled.div`
    padding: 6px 8px;
    color: black;
    border-radius: 8px;
    background: ${neutral_3};
    border: 1px solid ${neutral_5};
`

const DYNAMICS_ITEMS_FORM_NAME = [
    SUBMIT_APPLICATION_GAMIFICATION,
    CLASSIFIED_APPLICATION_GAMIFICATION,
    APROVED_APPLICATION_GAMIFICATION,
    FINISHED_ASSESSMENTS_GAMIFICATION,
]


export default function WalletPage() {
    const {t} = useTranslation();

    const {
        initializeLayout,
    } = useLayoutContext();
    const [points, setPoints] = useState()
    const {
        data: gamificationData,
    } = useDefaultGetRequest({
        url: '/gamification_settings/get_client_settings',
    })

     const {
        data: walletPoints,
    } = useDefaultGetRequest({
        url: '/wallet/get_wallet',
    })

    useEffect(() => {
        initializeLayout({
            navbarMenuItems: [
                {
                    label: <Link to={`my_wallet`}>{t("Points wallet")}</Link>,
                    key: `/my_wallet`
                },
            ]
        });
    }, []);

    useEffect(() => {
        function handleInitialValues() {
            if (gamificationData) {
                const dinamicItems = {}
                DYNAMICS_ITEMS_FORM_NAME.forEach((key) => {
                    dinamicItems[key] = gamificationData?.points?.find(({type_action}) => type_action === key)?.points
                })
                setPoints(dinamicItems)
            }
        }

        handleInitialValues()
    }, [gamificationData]);

    return (
        <Row gutter={[24, 24]}>
            <Col xs={24}>
                <PointsCard
                    helpText={t('Understand how to get points')}
                    drawerTitle={t('Points and actions')}
                    drawerBody={
                        <Row gutter={[16, 16]}>
                            <Col xs={24}>
                                <TextInfo>
                                    {t('drawer_help_text_point_valid')}
                                </TextInfo>
                            </Col>
                            <Col xs={24}>
                                <InfoContainer>
                                    <FlexContainer direction={'column'}
                                                   align={'start'} gap={16}>
                                        <FlexContainer wrap={'wrap'}
                                                       justify={'space-between'}>
                                            <Title>
                                                <RemixIcon
                                                    style={{fontSize: 16}}
                                                    remixIconName={'ri-copper-coin-line'}/>
                                                {t('Environment Score')}
                                            </Title>

                                        </FlexContainer>
                                        <AmountInfo
                                            amount={points?.[SUBMIT_APPLICATION_GAMIFICATION]}
                                            text={t('Enrollment')}/>
                                        <AmountInfo
                                            amount={points?.[CLASSIFIED_APPLICATION_GAMIFICATION]}
                                            text={t('One-step approval')}/>
                                        <AmountInfo
                                            amount={points?.[FINISHED_ASSESSMENTS_GAMIFICATION]}
                                            text={t('Evaluation')}/>
                                        <AmountInfo
                                            amount={points?.[APROVED_APPLICATION_GAMIFICATION]}
                                            text={t('Approval in the program')}/>
                                    </FlexContainer>

                                </InfoContainer>
                            </Col>
                        </Row>
                    }
                    value={walletPoints?.wallet_points}
                    text={t('valid points')}/>
            </Col>
            <Col xs={24} lg={12}>
                <PointsCard
                    helpText={t('Understand your outstanding points')}
                    drawerTitle={t('Pending points')}
                    drawerBody={
                        <Row gutter={[16, 16]}>
                            <Col xs={24}>
                                <TextInfo>
                                    {t('When you perform actions, the points acquired go through a processing period before being validated, during which they remain as pending. During this period, they can be rectified by managers, if necessary. After this period, they are added to your points portfolio.')}
                                </TextInfo>
                            </Col>
                            <Col xs={24}>
                                <InfoContainer>
                                    <FlexContainer wrap={'wrap'}
                                                   justify={'space-between'}>
                                        <Title>
                                            <RemixIcon style={{fontSize: 16}}
                                                       remixIconName={'ri-copper-coin-line'}/>
                                            {t('Processing time')}
                                        </Title>
                                        <span>{gamificationData?.manual_points_validation ? t('The manager needs to validate') : `${gamificationData?.days_to_validate_points} ${t('days')}`} </span>
                                    </FlexContainer>
                                </InfoContainer>
                            </Col>
                        </Row>
                    }
                    value={walletPoints?.pending_points} black
                    text={t('pending points')}/>
            </Col>
            <Col xs={24} lg={12}>
                <PointsCard
                    helpText={t('Understand your retained points')}
                    drawerTitle={t('Points retained')}
                    drawerBody={
                        <TextInfo>{t('The points used in the purchase are retained for a period of time to ensure that the transaction is validated. After this period, the points will be confirmed and subtracted from your final balance. If the purchase is not validated, the points will be returned to you.')}</TextInfo>}
                    value={walletPoints?.retained_points} black
                    text={t('points retained (store)')}/>
            </Col>
            <Col xs={24}>
                <WalletTransactionHistory/>
            </Col>

        </Row>

    );
}


function AmountInfo({amount, text}) {
    return (
        <Space gap={8}>
            <Tag color={neutral_5}>
                <RemixIcon
                    style={{fontSize: 16, marginRight: 10}}
                    remixIconName={'ri-copper-coin-line'}/>
                {formatPointNumber(amount)}
            </Tag>
            {text}
        </Space>
    )
}

