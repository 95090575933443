export function get_color_application_status(situation) {
    let color;

    if (situation === 'closed' || situation === 'is_canceled') {
        color = '#FFF1F0';
    } else if (situation === 'approved') {
        color = '#F6FFED';
    } else {
        color = '#F0F5FF';
    }
    return color
}

export function get_color_status(value) {
    let color;
    if (value === 'Active') {
        color = '#F6FFED';
    } else if (value === 'In queue') {
        color='orange'
    } else {
        color = '#FFF1F0';
    }
    return color
}


export function getBackgroundColorByPhaseDate(phase) {
    const now = new Date();
    if ((phase.date_end && new Date(phase.date_end) <= now) || !phase.is_current) {
        return "#F5F5F5";
    }
}