import React from 'react';
import {Card, Row, Space, Typography} from "antd";
import UserAvatar from "../../../../global/components/misc/UserAvatar";
import DangerousHtmlPreviewer from "../../../../global/components/data_display/DangerousHtmlPreviewer";
import WideSpace from "../../../../global/components/layout/WideSpace";
import styled from "styled-components";
import tinycolor from "tinycolor2";

const {Text} = Typography

const StyledSummaryCard = styled(Card)`
    --base-color: ${({$color}) => {
        if (!$color) return;
        const luminance = tinycolor($color).getLuminance();
        if (luminance >= 0.6) {
            return "black"
        } else {
            return "white";
        }
    }};

    background: ${props => props.$color};
    overflow: hidden;
    border-radius: 8px;

    .ant-card-body {
        padding: 16px 16px 0 16px
    }

    .auto-color-text * {
        color: var(--base-color);
    }

`

const ProjectDescriptionContainer = styled.div`
    overflow: hidden;
    height: 220px;
`

function ModelSummaryCard({project, projectName, projectDescription, color, avatar}) {
    let avatarDisplay = avatar ? avatar?.[0] : project?.avatar_display?.[0];

    if (avatarDisplay instanceof File) {
        avatarDisplay = {url: URL.createObjectURL(avatarDisplay)}
    }

    return (
        <WideSpace direction="vertical" size="large">
            <StyledSummaryCard $color={color}>
                <Row gutter={12}>
                    <ProjectDescriptionContainer>
                        <Space direction="vertical" className="auto-color-text">
                            <Space>
                                <UserAvatar data={{...avatarDisplay, name: projectName}}/>
                                <Text>{projectName}</Text>
                            </Space>
                            <DangerousHtmlPreviewer content={projectDescription}/>
                        </Space>
                    </ProjectDescriptionContainer>
                </Row>
            </StyledSummaryCard>
        </WideSpace>
    );
}

export default ModelSummaryCard;