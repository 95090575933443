import {useTranslation} from "react-i18next";
import useSystemBreakpoint from "../../../../../../hooks/useSystemBreakpoint";
import { Typography} from "antd";
import Button from '../../../../../../components/data_entry/Button'
import styled from "styled-components";

const {Text} = Typography;

const StyledIconButton = styled(Button)`
  padding: 16px;
  height: 100%;
  width: 100%;
  text-align: left;
  white-space: normal;
  border: none;
  border-radius: 8px;

  * {
    color: inherit !important;
  }
  
`

const StyledIconButtonContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`

const StyledButtonDescription = styled.div`
  display: flex; 
  flex-direction: column;
`

export default function ActionButton({title, description, dark, icon, type, style, onClick, className, ...props}) {
    const {t} = useTranslation();
    const {isDesktop} = useSystemBreakpoint();
    const titleFontSize = !isDesktop ? 16 : 20;
    const iconFontSize = !isDesktop ? 16 : 24;
    return <StyledIconButton type={type} style={style} onClick={onClick} dark={dark} className={className} {...props}>
        <StyledIconButtonContainer >
            <Text style={{fontSize: iconFontSize}}>{icon}</Text>
            <StyledButtonDescription>
                <Text style={{ fontSize: titleFontSize, fontWeight: 500 }}>{title}</Text>
                {isDesktop && <Text style={{ fontSize: "10px" }}> {description} </Text>}
            </StyledButtonDescription>
        </StyledIconButtonContainer>
    </StyledIconButton>
}