import React, {useEffect, useState} from 'react';
import {Alert, Button, Flex, Typography} from "antd";
import {neutral_8} from "../../../../global/utils/Colors";
import IndicatorValueCard from "../IndicatorValueCard";
import Drawer from "../../../../global/components/layout/Drawer";
import TransparentCollapse from "../../../../global/components/misc/TransparentCollapse";
import {useTranslation} from "react-i18next";
import HelpCard from "../../../../global/components/other/HelpCard";
import {InfoCircleOutlined} from "@ant-design/icons";
import ProjectIndicatorDrawer from "./ProjectIndicatorDrawer";
import api from "../../../../global/services/api";
import {useProjectManagementContext} from "../../pages/ProjectManagement/context/ProjectManagementContext";
import {BuildFieldErrorsDict} from "../../../../global/utils/Utils";
import {ToastNotification} from "../../../../global/components/feedback/ToastNotification";

const {Title} = Typography;

function IndicatorValueText({indicatorResponse, value}) {

    if (indicatorResponse.indicator.indicator_type === "date") {
        if (indicatorResponse.natural_date) {
            value = indicatorResponse.natural_date
        }
    } else {
        value = indicatorResponse.value_display
    }

    value = value || "-"

    return <Title level={2} style={{color: neutral_8, margin: 0}}>{value}</Title>
}

export default function PlanningInformationDrawer({open, setOpen, indicatorResponseList, fetchProjectIndicatorResponses}) {

    const {t} = useTranslation();
    const {project} = useProjectManagementContext();
    const [selectedIndicatorResponseIndex, setSelectedIndicatorResponseIndex] = useState()
    const [forecastIsConfigured, setForecastIsConfigured] = useState()
    const [loading, setLoading] = useState()

    const handleCreateInitialPlanning = async () => {
        try {
            setLoading(true)
            const {data} = await api.post(`/project_management/project/${project.id}/configure_forecast`);
            setForecastIsConfigured(true);
            ToastNotification(data?.message);
            dispatchEvent(new Event("refresh-investment-financial-planning-table"))
            setOpen(false);
        } catch (error) {
            BuildFieldErrorsDict(error, false, false)
        } finally {
            setLoading(false)
        }
    }

    const disableCreateInitialPlanning = () => {
        if (!indicatorResponseList) return true;
        return !!indicatorResponseList?.find(item => ["06", "15"].includes(item.indicator.internal_code) &&
            item.is_blank === true &&
            item.indicator.indicator_type !== "tags"
        );
    }

    useEffect(() => {
        setForecastIsConfigured(project?.forecast_is_configured);
    }, [project])

    return (
        <>
            <Drawer
                open={open}
                setOpen={setOpen}
                width={"80%"}
                isGrayBody={true}
                title={t("Planning Information")}
                destroyOnClose={true}
                footer={
                    project && !forecastIsConfigured &&
                    <Flex justify="end">
                        <Button type="primary" onClick={() => handleCreateInitialPlanning()}
                                disabled={disableCreateInitialPlanning()} loading={loading}>
                            {t("Create initial planning")}
                        </Button>
                    </Flex>
                }
            >
                <Flex vertical={true} gap={16}>
                    <HelpCard
                        title={t("Initial Indicators")}
                        description={`${t("The initial indicators of a project are used to record the initial scope and the financial and physical estimates for the future")}.`}
                        icon={<InfoCircleOutlined/>}
                    />
                    <TransparentCollapse
                        header={t("Initial Project Indicators")}
                    >
                        <Flex vertical={true} gap={16}>
                            <Alert message={t("Any change can be checked in the indicator history.")} type="warning"/>
                            {indicatorResponseList?.map((indicatorResponse, index) => {
                                return (
                                    <IndicatorValueCard
                                        key={index}
                                        label={t(indicatorResponse.indicator.name)}
                                        help={t(indicatorResponse.indicator.description)}
                                        prefix={indicatorResponse.indicator.indicator_type === "number" && "$:"}
                                        value={<IndicatorValueText indicatorResponse={indicatorResponse}/>}
                                        suffix={indicatorResponse.suffix}
                                        onClick={() => {
                                            setSelectedIndicatorResponseIndex(index)
                                        }}
                                    />
                                )
                            })}
                        </Flex>
                    </TransparentCollapse>
                </Flex>
            </Drawer>

            {indicatorResponseList &&
                <ProjectIndicatorDrawer
                    open={selectedIndicatorResponseIndex !== undefined}
                    onClose={() => setSelectedIndicatorResponseIndex(undefined)}
                    currentUpdatingIndicatorResponse={indicatorResponseList[selectedIndicatorResponseIndex]}
                    refresh={() => fetchProjectIndicatorResponses()}
                />
            }

        </>
    );
}
