import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {
    FlexContainer
} from "../../../../components/authentication/StyledComponents";
import {PieChartOutlined} from "@ant-design/icons";
import {neutral_8} from "../../../../utils/Colors";

const Container = styled(FlexContainer)`
    border-radius: 12px;
    padding: 16px;
    width: 100%;
    height: 100%;
    min-height: 350px;
    background: white;
    color: var(--primary_btn_color);

    svg {
        font-size: 64px;
    }

    cursor: pointer;

    :hover {
        color: white;
        background: ${neutral_8};
    }
`

export default function EmptyChart({onClick}) {
    const {t} = useTranslation();


    return (

        <Container justify={'center'} direction={'column'} gap={19}
                   onClick={onClick}>
            <PieChartOutlined/>
            <span>{t('Click to add a new chart')}</span>
        </Container>

    )
}

