import SolvTag from "./SolvTag";
import React from "react";
import {ICONS_CHOICES} from "../../utils/Choices";
import {Tooltip} from "antd";
import styled from 'styled-components'


export default function CustomTag({ name, color, icon, ...props }) {
  return (
     <SolvTag style={{width: 'min-content'}}
                                 color={color}
              icon={ICONS_CHOICES.find((item) => icon === item.value)?.label} {...props}><Tooltip title={name}>{name}</Tooltip></SolvTag>
  );
}

