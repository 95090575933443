import React, {useState} from 'react';
import {neutral_4} from "../../utils/Colors";
import {FlexContainer} from "../authentication/StyledComponents";
import LimitNumber from "./LimitNumber";
import styled from "styled-components";
import {Tooltip} from "antd";

const Container = styled.div`

    * {
        color: ${({color}) => color};
        fill: ${({color}) => color};
        stroke: ${({color}) => color};

    }

    padding: 16px;
    flex-shrink: 0;
    width: ${({width}) => width};
    background: white;
    border-radius: 8px;
    border: 1px solid ${neutral_4};
`
const Title = styled.div`
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px;
     white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`
const Number = styled.div`
    font-weight: bold;
    font-size: 24px;
`
const IconContainer = styled.div`

    font-weight: bold;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    * {
        font-size: 24px;
        width: 24px;
        height: 24px;
    }

`

export default function AmountCardGamification({width='100%', title, value, icon, color}) {
    return (
        <Container color={color} width={width}>
            <Tooltip title={title}>
                 <Title>{title}</Title>
            </Tooltip>

            <FlexContainer gap={16}>
                <IconContainer>{icon}</IconContainer>
                <Number><LimitNumber value={value}/></Number>
            </FlexContainer>
        </Container>
    )
}
