import React from "react";
import {Modal, Typography} from "antd";
import {useTranslation} from "react-i18next";
import styled from 'styled-components'
import {neutral_1, neutral_3, neutral_5} from "../../global/utils/Colors";
import ApplicationImage from "../../assets/img/application.png";
import EvaluationImage from "../../assets/img/evaluation.png";
import InformationImage from "../../assets/img/information.png";
import {
    FlexContainer
} from "../../global/components/authentication/StyledComponents";
import WideSpace from "../../global/components/layout/WideSpace";
import {PHASE} from "../../global/utils/Constants";
import {useHistory} from "react-router-dom";

const StyledButton = styled.div`
    border-radius: 8px;
    border: 1px solid ${neutral_5};
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    gap: 16px;
    align-items: center;

    &:hover {
        background: ${neutral_3};
    }

    img {

    }
`

const StyledModal = styled(Modal)`
    .ant-modal-header{
        border-bottom: none;
        padding-bottom: 0;
    }
`
const Title = styled.h5`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
`
const Text = styled(Typography.Text)`
    font-size: 12px;
    margin-bottom: 0;
`
export default function ModalCreatePhase({phase, ...props}) {
    const {t} = useTranslation()
    const history = useHistory()
    function generateCreatePhaseLink(type) {
        history.push(`/programs/${phase.program_id}/create_phase?sequence=${phase.sequence}&type=${type}`);
    }

    return (
        <StyledModal bodyStyle={{borderTop: 'none'}} footer={false}
               title={t('Create new phase')} {...props}>
            <WideSpace size={16} direction={'vertical'}>
                <CustomButton title={t('Evaluation phase')}
                              text={t('Analysis and selection of projects based on defined criteria to guarantee the best ideas.')}
                              img={EvaluationImage}
                              onClick={() => generateCreatePhaseLink(PHASE.EVALUATION)}
                              data-cy="create-phase-button-modal-2"
                />
                <CustomButton title={t('Submission/participation phase')}
                              text={t('Submission of proposals and additional information by program participants.')}
                              img={ApplicationImage}
                              onClick={() => generateCreatePhaseLink(PHASE.APPLICATION)}
                              data-cy="create-phase-button-modal-1"

                />
                <CustomButton title={t('Information phase')}
                              text={t('Disclosure of information, rules and changes. Stage without form submission or evaluation and does not receive applications.')}
                              img={InformationImage}
                              onClick={() => generateCreatePhaseLink(PHASE.INFORMATION)}
                              data-cy="create-phase-button-modal-3"
                />
            </WideSpace>

        </StyledModal>
    )
}

function CustomButton({img, title, text, ...props}) {
    return (
        <StyledButton block type={'default'} {...props}>
            <img src={img}/>
            <FlexContainer align={'start'} direction={'column'} gap={4}>
                <Title level={5}>{title}</Title>
                <Text type="secondary">{text}</Text>
            </FlexContainer>
        </StyledButton>
    )
}