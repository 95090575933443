import React from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import RankingCard from "../../../../../components/data_display/RankingCard";
import useDefaultGetRequest from "../../../../../hooks/useDefaultGetRequest";
import {CAMPAIGN_STATUS} from "../../../../../utils/Constants";
import Loading from "../../../../../components/feedback/Loading";
import {Col, Row} from "antd";
import Empty from "../../../../../components/data_display/Empty";
import CampaignCard from "../../../../../components/data_display/CampaignCard";

const Title = styled.h2`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
`
export default function SectionScheduledCampaign() {
    const {t} = useTranslation();
    const {
        data: campaigns,
        isLoading
    } = useDefaultGetRequest({
        url: `/point_event`,
        params: {
            status: CAMPAIGN_STATUS.SCHEDULED,
            environment_rank:true,
            ordering: 'date_start',
            page_size:1000
        },
        dataDefault: []
    })


    return (

        <>

            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Title
                level={6}>{t('Scheduled campaigns')}</Title>
                </Col>
                {isLoading ? (
                    <Col xs={24}><Loading height={'100px'}/></Col>
                ) : campaigns?.results?.length > 0 ? campaigns?.results?.map((campaign) =>
                    <Col xs={24} key={campaign.id}>
                        <CampaignCard url={`point_event/${campaign.id}`} campaign={campaign}/></Col>
                ) : <Col xs={24}><Empty
                    description={t('There are no campaigns scheduled at the moment.')}/></Col>}

            </Row>


        </>

    );
}
