import React from 'react';
import TransparentCollapse from "../../../../../../global/components/misc/TransparentCollapse";
import CollapseFormItem from "../../../../../../global/components/data_entry/CollapseFormItem";
import {useTranslation} from "react-i18next";
import Button from "../../../../../../global/components/data_entry/Button";
import {Modal} from "antd";
import {ExclamationCircleOutlined, SisternodeOutlined} from "@ant-design/icons";
import api from "../../../../../../global/services/api";
import {ToastNotification} from "../../../../../../global/components/feedback/ToastNotification";
import {BuildFieldErrorsDict} from "../../../../../../global/utils/Utils";
import useSystemBreakpoint from "../../../../../../global/hooks/useSystemBreakpoint";
import { useThemeContext } from '../../../../../../global/context/ThemeContext';
import WideSpace from '../../../../../../global/components/layout/WideSpace';

export default function OtherSettingsCollapse({program, setIsLoading, FORM_NAMES}) {

    const {t} = useTranslation()
    const {isPhone} = useSystemBreakpoint();
    const {themeConfig} = useThemeContext();


    function handleDuplicateProgram() {
        Modal.confirm({
                icon: <ExclamationCircleOutlined/>,
                title: t('Duplicate program'),
                content: t("The new program will have the same steps and forms. Only entries will not be copied. Do you want to proceed?"),
                onOk: async () => {
                    try {
                        setIsLoading(true)
                        const response = await api.post(`/clone/program_template/${program.id}`);
                        ToastNotification(t('Successfully'))
                        history.push(`/programs/${response.data.new_program_id}/settings`)
                    } catch (error) {
                        BuildFieldErrorsDict(error, null, false)

                    } finally {
                        setIsLoading(false)
                    }
                },
                okText: t('Duplicate'),
                cancelText: t('Go back')
            }
        )

    }


    return (
        <TransparentCollapse header={t("Other Settings")}>
            <WideSpace direction="vertical" size="middle">
                {program && <CollapseFormItem
                    label={t("Duplicate program")}
                    help={t("The program will duplicate the settings, forms and steps.")}
                    input={<Button type={'default'}
                                // block={isPhone}
                                icon={<SisternodeOutlined/>}
                                onClick={handleDuplicateProgram}>
                            {t('New program from this')}
                        </Button>
                    }
                />}
            </WideSpace>
        </TransparentCollapse>
    )
}

