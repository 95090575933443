import React, {useEffect, useState} from 'react';
import Drawer from '../../../../layout/Drawer';
import {useTranslation} from 'react-i18next';
import {Badge, Form as FormAntd, Row, Space} from 'antd';
import Button from '../../../../data_entry/Button';
import Form from "../../../../data_entry/form/Form";
import RequestSelectFormField from "../../../../data_entry/form/RequestSelectFormField";
import api from "../../../../../services/api";
import {BuildFieldErrorsDict} from "../../../../../utils/Utils";
import {ToastNotification} from "../../../../feedback/ToastNotification";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {useApplicationDrawerContext} from "../context/ApplicationDrawerContext";

export default function LinkApplicationProjectDrawer({open, setOpen, application}) {
    const {t} = useTranslation();
    const [form] = FormAntd.useForm();
    const [loading, setLoading] = useState(false)
    const {setSolution} = useApplicationDrawerContext()


    const handleLinkProject = async (data) => {
        try {
            setLoading(true)
            await api.post(`v1/applications/${application?.id}/link_project`, data)
            setSolution(undefined)
            setOpen(false)
            ToastNotification(t("Project successfully linked"), "success")
        } catch (error){
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        form.setFieldValue('project', application?.project)
    }, [application]);

    return (
        <Drawer
            title={
                <Space>
                   { t("Link application to a project") }
                    <Badge count={t("NEW")} color="#096DD9"/>
                </Space>
            } open={open}
            onClose={() => setOpen(false)}
            footer={
                <Row justify="end">
                    <Button type="primary" onClick={form.submit} loading={loading} data-cy="submit-link-project-button">{t("Link")}
                    </Button>
                </Row>
        }>
            <Form form={form} onFinish={handleLinkProject} >
                <RequestSelectFormField
                    url="/project_management/project/select_list"
                    form={{
                        name: "project",
                        label: t("Select the project"),
                        required:true,
                    }}
                    data-cy="project-select"
                />
            </Form>
        </Drawer>
    );
}
