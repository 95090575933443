import React, {useEffect, useRef, useState} from "react";
import {Col, Container, Modal, Row} from "react-bootstrap";
import styled from 'styled-components'
import Input from "../../../../../components/Form/Input";
import api from "../../../../../global/services/api";

import * as ConstantsMessage from "../../../../../global/utils/fileWithMessage";
import Label from "../../../../../components/Form/Label";
import GroupImage from '../../../../../assets/img/Group_139.svg'
import {useHistory, useParams} from "react-router-dom";
import Loading from "../../../../../global/components/feedback/Loading";
import NotContent from "../../../../../components/NotContent/NotContent";
import CircleImage from "../../../../../components/CircleImage";
import NoProfile from "../../../../../assets/Icon/no_profile.svg";
import ConfirmInteraction
    from "../../../../../components/Interaction/ConfirmInteraction";
import {StepWizard} from "../../../../../components/StepWizard";
import Subtitle from "../../../../../components/Title/Subtitle";
import ToggleSwitch from "../../../../../components/Form/ToggleSwitch";
import CheckBox from "../../../../../components/Form/CheckBox";
import TextArea from "../../../../../components/Form/TextArea";
import {confirmAlert} from "react-confirm-alert";
import MessageError from "../../../../../components/Form/MessageError";
import ButtonDefault from "../../../../../components/Buttons/ButtonDefault";
import Title from "../../../../../components/Title/Title";
import {ReactComponent as CloseIcon} from '../../../../../assets/Icon/clear.svg'
import IconButton from "../../../../../components/IconButton/Index";
import FilterIcon from "../../../../../assets/Icon/filter.svg";
import OffCanvasComponent from "../../../../../components/Offcanvas";
import FormSelect from "../../../../../components/Form/FormSelect/Index";
import {SUCCESS} from "../../../../../global/utils/fileWithMessage";
import ApplicationCard
    from "../../../../../components/Interaction/ApplicationCard";
import {BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import {useTranslation} from "react-i18next";
import ShowNumberList
    from "../../../../../components/Pagination/ShowNumberList/ShowNumberList";
import InfiniteScroll from "react-infinite-scroller";
import SelectCountList
    from "../../../../../components/Pagination/SelectCountList/SelectCountList";
import {ToastNotification} from "../../../../../global/components/feedback/ToastNotification";
import {CardContainer} from "../../../../../global/components/misc/CardContainer";

const StyleDivVariables = styled.div`
  margin-top: 8px;
  text-align: center;
  background-color: #F8F8F8;
  border: 1px solid #DDDDDD;
  border-radius: 6px;
  min-height: 372px;
  word-wrap: break-word;

`

const Info = styled.p`
  text-align: left;
  margin-bottom: 4px;
  white-space: pre-line;
`

const LabelBold = styled.span`
  font-weight: bold;

`

const Text = styled.p`
  //margin-top: 4px;
  margin-bottom: 16px;
  white-space: pre-line;
`

const Filtercontainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`
const ShowNumberListContainer = styled.div`
  display: flex;
  justify-content: ${({value}) => value === 0 ? 'end': 'space-between'};

`

const StyleDivListCard = styled.div`
  flex: 3 1 0;
  display: flex;
  align-items: center;
`

const ConfirmContainer = styled.div`
  width: 400px;
  overflow: hidden;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgb(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;

`
const Header = styled.div`
  background-color: #efefea;
  height: 40px;
  padding: 8px 8px 16px 8px;
  width: 100%;
  text-align: center;
  position: relative;
`
const Body = styled.div`
  //padding: ;
`
const ButtonContainer = styled.div`
  padding: 4px;
  width: 100%;
  text-align: center;
`
const ImagemContainer = styled.div`

  //width: 100%;
  text-align: center;
  height: 200px;

  img {
    width: 100%;
    height: 100%;
  }
`
const Close = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: #4C4C4C;
  }

  :hover svg {
    fill: #efefea;
    transform: rotate(360deg);
  }

  :hover {
    background-color: #4C4C4C;

  }
`


const StyleDivBodyListCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  background-color: rgb(248, 248, 248);
  //margin: 5px;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 6px;
  height: 82px;

`

const StyleDivBodyCard = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  width: 100%;
  gap: 8px;

`


const StyleDivSpan = styled.div`
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #DDDDDD;

`
const StyleDivApplyListCards = styled.div`
  overflow: auto;
  max-height: 327px;

`

const Description = styled.div`
  height: 300px;
  overflow: scroll;
`

const StyleDivMemberListCards = styled.div`
  overflow: auto;
  max-height: 327px;

`

const StyleDivButtons = styled.div`
  justify-content: space-between;
  text-align: end;
  padding-top: 30px;
`

const StyleDivFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
`


const StyledInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-overflow: ellipsis;
`
const ToggleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 0 4px;
`

const StyledSpanBold = styled.span`
  font-size: 12px;
  font-weight: 700;
  color: rgb(76, 76, 76);
  margin-right: 4px;

`
const StyledSpanOverflowPadrao = styled.span`
  font-weight: 400;
  margin-left: 8px;
  font-size: 12px;

`

const CardVariable = styled.label`
  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  margin-bottom: 10px !important;
`


function FormInteraction() {
    let {id, id_interaction} = useParams()
    const {t} = useTranslation()
    const history = useHistory()
    const [isGoPageTo, setIsGoPageTo] = useState(false)


    const [companys, setCompanys] = useState({
        active: false,
        active_some_all: true
    })

    const [evaluators, setEvaluators] = useState({
        active: false,
        active_some_all: true
    })

    const [managers, setManagers] = useState({
        active: false,
        active_some_all: true
    })
    const [membersPage, setMembersPage] = useState(1)
    const [membersManagePage, setMembersManagerPage] = useState(1)
    const [applicationsPage, setApplicationPage] = useState(1)
    const [amountApplications, setAmountApplications] = useState(0)
    const [amountMembers, setAmountMembers] = useState(0)
    const [amountMembersManager, setAmountMembersManager] = useState(0)
    const [loadingApplications, setLoadingApplications] = useState(false)
    const [loadingManager, setLoadingManager] = useState(false)
    const [loadingEvaluators, setLoadingEvaluators] = useState(false)
    const [orderApplications, setOrderApplications] = useState('name')
    const [orderMembers, setOrderMembers] = useState('name')
    const [challenges, setChallenges] = useState([])
    const [showModalPreview, setShowModalPreview] = useState(false)
    const [previewEmail, setPreviewEmail] = useState({})
    const [previewForm, setPreviewForm] = useState({})
    const [emailPreview, setEmailPreview] = useState()
    const [phases, setPhases] = useState([])
    const [selectedChallenges, setSelectedChallenges] = useState([])
    const [selectedPhases, setSelectedPhases] = useState([])
    const [selectedPhasesMembers, setSelectedPhasesMembers] = useState([])
    const [statusApplication, setStatusApplication] = useState(null)
    const [statusMembers, setStatusMembers] = useState(null)
    const [hasMoreApplications, setHasMoreApplications] = useState(true)
    const [hasMoreMembers, setHasMoreMembers] = useState(true)
    const [hasMoreMembersManager, setHassMoreMembersManager] = useState(true)

    function changeForm({target}) {
        const value = target.value
        const name = target.name
        setPreviewForm({...previewForm, [name]: value})
    }

    useEffect(() => {
        if (showModalPreview) {
            let title_email = interaction.title_email
            let message_email = interaction.message_email
            Object.keys(previewForm).forEach((key) => {
                if (previewForm[key].trim()) {
                    title_email = title_email.replaceAll(key, previewForm[key])
                    message_email = message_email.replaceAll(key, previewForm[key])
                }

            })
            setPreviewEmail({
                title_email: title_email,
                message_email: message_email
            })
        }

    }, [previewForm, showModalPreview])

    async function handleSendTestMail() {

        try {
            setIsLoadingPreview(true)
            const data = {
                email: emailPreview,
                title_email: previewEmail.title_email,
                message_email: previewEmail.message_email,
            }
            if (emailPreview && emailPreview.trim()) {
                const response = await api.post(`interaction/send_interaction_preview`, data)
                ToastNotification(t(SUCCESS))
            } else {
                ToastNotification(t('Email is required'),'error')
            }

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        } finally {
            setIsLoadingPreview(false)
        }
    }

    const getChallenges = async () => {
        try {
            const response = await api.get(`programs/${id}/challenges`);
            let aux = []
            response.data.map((option) => {
                aux.push({value: option.id, label: option.name})
            })
            setChallenges(aux);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        }
    };

    const getPhases = async () => {
        try {
            const response = await api.get(`programs/${id}/phases`);
            let aux = []
            response.data.map((option) => {

                aux.push({value: option.id, label: option.title})
            })
            setPhases(aux);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        }
    };
    const filterOptions = [
        {
            value: 'name',
            label: `${t('Name (A-Z)')}`
        },
        {
            value: '-name',
            label: `${t('Name (Z-A)')}`
        },
    ]
    const formQuestions = useRef(null);
    const formQuestionsMembers = useRef(null);
    const [resetFilters, setResetFilters] = useState(false);
    const [resetFiltersMembers, setResetFiltersMembers] = useState(false);
    const [showClearFilters, setShowClearFilters] = useState(false);
    const [showClearFiltersMembers, setShowClearFiltersMembers] = useState(false);

    const handleClearFilters = () => {

        setResetFilters(true)
        setApplications([])
        setApplicationPage(1)
        setShowClearFilters(false)
        setOrderApplications('name')
        setSelectedChallenges([])
        setStatusApplication(null)
    }

    const handleClearFiltersMembers = () => {

        setResetFiltersMembers(true)
        setMembers([])
        setMembersPage(1)
        setShowClearFiltersMembers(false)
        setOrderMembers('name')
        setMembersSelecteds([])
        setStatusMembers([])
    }

    useEffect(() => {
        showClearFilters === false && getApplications()
        setResetFilters(false)
    }, [showClearFilters])

    useEffect(() => {
        showClearFiltersMembers === false && getMembers()
        setResetFiltersMembers(false)
    }, [showClearFiltersMembers])

    const handleSubmitFilterApplications = async (ordening = false) => {

        setHasMoreApplications(false)
        let formData = new FormData(formQuestions.current);
        //VALIDACAO SE POSSUI DADOS PRA REALIZAR A REQUISICAO
        let tags_to_remove = []
        let contador = 0
        for (var pair of formData.entries()) {
            contador = contador + 1;

            if (pair[1] === '') {
                tags_to_remove.push(pair[0]);
            }
        }
        if (tags_to_remove.length === contador && !ordening) {

            handleClearFilters()
            return //todos vazios
        }
        tags_to_remove.forEach((tag) => {
            formData.delete(tag)
        })
        if (contador - tags_to_remove.length >= 1) {
            setShowClearFilters(true)
        } else {
            setShowClearFilters(false)
        }
        //VALIDACAO SE POSSUI DADOS PRA REALIZAR A REQUISICAO
        try {

            setLoadingApplications(false)
            setApplicationsSelecteds([])
            setShowClearFilters(true)
            let response = await api.post(`programs/${id}/applications?page=1&page_size=1000&orderning=${ordening || orderApplications}`, formData);

            if (response.data.length === 0) {
                ToastNotification(t('No applications with this filters'),'warning');
            }


            setApplications(response.data.results)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setLoadingApplications(false)
        }
    }


    const handleSubmitFilterEvaluators = async (ordening = false) => {
        let formData = new FormData(formQuestionsMembers.current);
        //VALIDACAO SE POSSUI DADOS PRA REALIZAR A REQUISICAO
        let tags_to_remove = []
        let contador = 0
        for (var pair of formData.entries()) {
            contador = contador + 1;
            if (pair[1] === '') {
                tags_to_remove.push(pair[0]);
            }
        }
        if (tags_to_remove.length === contador && !ordening) {
            handleClearFiltersMembers()

            return //todos vazios
        }
        tags_to_remove.forEach((tag) => {
            formData.delete(tag)
        })
        if (contador - tags_to_remove.length >= 1) {
            setShowClearFiltersMembers(true)
        } else {
            setShowClearFiltersMembers(false)
        }
        //VALIDACAO SE POSSUI DADOS PRA REALIZAR A REQUISICAO
        try {

            setLoadingEvaluators(false)
            setMembersSelecteds([])
            setShowClearFiltersMembers(true)
            let response = await api.post(`programs/${id}/members?page=1&page_size=1000&permission=evaluator&orderning=${ordening || orderApplications}`, formData);
            setMembersPage(1)
            if (response.data.length === 0) {
                ToastNotification(t('No evaluators with this filters'),'warning');
            }


            setMembers(response.data.results)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setLoadingEvaluators(false)
        }
    }

    const [interaction, setInteraction] = useState({'object_id': id})
    const [templateCustomized, setTemplateCustomized] = useState(null)
    const [validateFields, setValidateFields] = useState({})
    const [showErrorToToggles, setShowErrorToToggles] = useState(false)
    const [showFilters, setShowFilters] = useState(false);
    const [showFiltersApplications, setShowFiltersApplications] = useState(false);
    const [showFiltersMembers, setShowFiltersMembers] = useState(false);

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingPreview, setIsLoadingPreview] = useState(false)
    const [selectAllEvaluators, setSelectAllEvaluators] = useState(false)
    const [selectAllApplications, setSelectAllApplications] = useState(false)
    const [selectAllManager, setSelectAllManager] = useState(false)
    const [applications, setApplications] = useState([])
    const [members, setMembers] = useState([])
    const [membersManager, setMembersManager] = useState([])
    const [disableElements, setDisableElements] = useState(false)

    const [applicationsSelecteds, setApplicationsSelecteds] = useState([])
    const [membersSelecteds, setMembersSelecteds] = useState([])
    const [managerSelecteds, setManagerSelecteds] = useState([])

    useEffect(() => {
        getTemplateCustomized()
        getMembers()
        getManagers()
        getChallenges()
        getPhases()
    }, []);


    useEffect(() => {
        setIsGoPageTo(
            !companys.active_some_all ||
            !evaluators.active_some_all ||
            !managers.active_some_all)
    }, [companys, evaluators, managers]);


    function handleCompanySelectorChange({target}) {

        if (target.checked) {
            setCompanys({
                ...companys,
                active: target.checked
            })
            setApplicationsSelecteds(applications.map((item) => item.id))
        } else {
            setCompanys({
                active_some_all: true,
                active: target.checked
            })
            setApplicationsSelecteds([])

        }


    }

    function handleEvaluatorSelectorChange({target}) {

        if (target.checked) {
            setEvaluators({
                ...evaluators,
                active: target.checked
            })
            setMembersSelecteds(members.map((item) => item.id))
        } else {
            setEvaluators({
                active_some_all: true,
                active: target.checked
            })
            setMembersSelecteds([])
        }


    }

    function handleManagerSelectorChange({target}) {

        if (target.checked) {
            setManagers({
                ...managers,
                active: target.checked
            })
            setManagerSelecteds(membersManager.map((item) => item.id))
        } else {
            setManagers({
                active_some_all: true,
                active: target.checked
            })
            setManagerSelecteds([])
        }


    }


    async function fetchInteraction(id_interaction) {
        try {

            setIsLoading(true)
            const response = await api.get(`interaction/${id_interaction}`);

            const interaction = response.data

            interaction.targets.applications && setApplicationsSelecteds(interaction.targets.applications)
            interaction.targets.members && setMembersSelecteds(interaction.targets.members)
            interaction.targets.managers && setManagerSelecteds(interaction.targets.managers)

            interaction.target_switchs && fillSwitchs(interaction.target_switchs)
            if (interaction.status !== 'created') {
                setDisableElements(true)
            }
            setInteraction(response.data)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
            history.push('/404')
        } finally {
            setIsLoading(false)
        }
    }

    function fillSwitchs(json) {
        const target_switchs_json = JSON.parse(json)
        setCompanys(target_switchs_json.companys)
        setManagers(target_switchs_json.managers)
        setEvaluators(target_switchs_json.evaluators)
    }


    React.useEffect(() => {
        if (id_interaction) {
            fetchInteraction(id_interaction)
        }
    }, [id_interaction])


    const getTemplateCustomized = async () => {
        try {

            const response = await api.get(`interaction/get_template_customized`);
            setTemplateCustomized(response.data)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        }
    }
    const getApplications = async () => {
        try {
            setLoadingApplications(true)
            let response = await api.get(`programs/${id}/applications?page=${applicationsPage}`);
            if (applicationsPage === 1) {
                setApplications(response.data.results);
            } else {
                setApplications([...applications, ...response.data.results]);
            }

            setAmountApplications(response.data.count)

            if (!!response.data.next) {
                setApplicationPage(applicationsPage + 1)
                setHasMoreApplications(true)

            } else {
                setHasMoreApplications(false)

            }
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        } finally {
            setLoadingApplications(false)
        }
    };


    const getMembers = async () => {
        try {
            setLoadingEvaluators(true)
            const response = await api.get(`programs/${id}/members?page=${membersPage}&permission=evaluator`);

            if (membersPage === 1) {
                setMembers(response.data.results);
            } else {
                setMembers([...members, ...response.data.results]);
            }

            setAmountMembers(response.data.count)
            if (!!response.data.next) {
                setMembersPage(membersPage + 1)
                setHasMoreMembers(true)
            } else {
                setHasMoreMembers(false)
            }

            setMembers(response.data.results);

            setAmountMembers(response.data.count)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        } finally {
            setLoadingEvaluators(false)
        }
    };

    const getManagers = async () => {
        try {
            setLoadingManager(true)
            const response = await api.get(`programs/${id}/members?page=${membersManagePage}&permission=manager`);

             if(membersManagePage === 1){
               setMembersManager(response.data.results);
            }else{
                setMembersManager([...managers, ...response.data.results]);
            }

            setAmountMembersManager(response.data.count)
            if(!!response.data.next){
                setMembersManagerPage(membersManagePage+1)
                setHassMoreMembersManager(true)
            }else{
                setHassMoreMembersManager(false)
            }

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        } finally {
            setLoadingManager(false)
        }
    };

    const addSelectedApplication = (value) => {
        const auxlist = [...applicationsSelecteds, value];
        setApplicationsSelecteds(auxlist)
    }

    const addSelectedEvaluator = (value) => {
        const auxlist = [...membersSelecteds, value];
        setMembersSelecteds(auxlist)
    }

    const addSelectedManager = (value) => {
        const auxlist = [...managerSelecteds, value];
        setManagerSelecteds(auxlist)
    }


    const removeSelectedmanager = (value) => {
        const auxlist = managerSelecteds.filter(item => item !== value);
        setManagerSelecteds(auxlist)
    }
    const removeSelectedMember = (value) => {
        const auxlist = membersSelecteds.filter(item => item !== value);
        setMembersSelecteds(auxlist)
    }

    const removeSelectedApplication = (value) => {
        const auxlist = applicationsSelecteds.filter(item => item !== value);
        setApplicationsSelecteds(auxlist)
    }

    const onChangeApplicationSelection = (checkbox) => {
        let value = parseInt(checkbox.value)
        checkbox.checked ? addSelectedApplication(value) : removeSelectedApplication(value)
    }

    const onChangeMemberSelection = (checkbox) => {
        let value = parseInt(checkbox.value)
        checkbox.checked ? addSelectedEvaluator(value) : removeSelectedMember(value)
    }

    const onChangeManagerSelection = (checkbox) => {
        let value = parseInt(checkbox.value)
        checkbox.checked ? addSelectedManager(value) : removeSelectedmanager(value)
    }


    const handleSubmit = async () => {
        try {
            let response;


            interaction['targets'] = {
                'members': membersSelecteds,
                'managers': managerSelecteds,
                'applications': applicationsSelecteds,
            }
            interaction['target_switchs'] = JSON.stringify({
                'companys': companys,
                'evaluators': evaluators,
                'managers': managers,
            })
            if (interaction.id) {
                response = await api.patch(`/interaction/${interaction.id}`, interaction);

            } else {
                response = await api.post(`/interaction`, interaction);

            }

            ToastNotification(t(ConstantsMessage.SUCCESS));
            history.push(`/programs/${id}/interaction`)
        } catch (error) {
            BuildFieldErrorsDict(error, setValidateFields, 'fill in all required fields')

        }
    };


    const handleSend = async () => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <ConfirmContainer>
                        <Header>
                            <Title
                                noCamel={true}>{t('Ready for launch?')}</Title>
                            <Close onClick={() => {
                                onClose();
                            }}>
                                <CloseIcon/>
                            </Close>
                        </Header>
                        <Body>
                            <ImagemContainer>
                                <img
                                    src={GroupImage}/>
                            </ImagemContainer>

                        </Body>


                        <ButtonContainer>

                            <ButtonDefault
                                style={{
                                    margin: 0,
                                    marginTop: 10,
                                    // visibility: 'hidden'
                                }}
                                onClick={async () => {
                                    try {
                                        let response;


                                        interaction['targets'] = {
                                            'members': membersSelecteds,
                                            'managers': managerSelecteds,
                                            'applications': applicationsSelecteds,
                                        }
                                        interaction['target_switchs'] = JSON.stringify({
                                            'companys': companys,
                                            'evaluators': evaluators,
                                            'managers': managers,
                                        })

                                        if (interaction.id) {
                                            response = await api.patch(`/interaction/${interaction.id}`, interaction);
                                            await api.post(`interaction/${interaction.id}/send_interaction`)

                                        } else {
                                            response = await api.post(`/interaction`, interaction);
                                            await api.post(`interaction/${response.data.id}/send_interaction`)
                                        }


                                        ToastNotification(t(ConstantsMessage.SUCCESS));
                                        history.push(`/programs/${id}/interaction`)
                                    } catch (error) {
                                        BuildFieldErrorsDict(error, setValidateFields, t('fill in all required fields'))
                                    } finally {
                                        onClose();
                                    }
                                }}
                                color={'primary'}
                                label={t('Go!')}
                                data-cy="submit-button"/>
                        </ButtonContainer>
                    </ConfirmContainer>
                );
            }
        });

    };

    const handleInputChange = (event) => {
        const inputId = event.currentTarget.id;
        const value = event.currentTarget.value;
        setInteraction((currentInteraction) => ({
                ...currentInteraction,
                [inputId]: value
            })
        )
    }

    const handleCopy = (event) => {
        const elementText = document.createElement('input')
        elementText.type = 'text'
        elementText.value = event.target.attributes.getNamedItem('data-value').value
        elementText.style.position = 'fixed';
        elementText.style.top = 0;
        elementText.style.left = 0;
        elementText.style.width = '2em';
        elementText.style.height = '2em';
        elementText.style.padding = 0;
        elementText.style.border = 'none';
        elementText.style.outline = 'none';
        elementText.style.boxShadow = 'none';
        elementText.style.background = 'transparent';
        document.body.appendChild(elementText)
        elementText.select();
        document.execCommand('copy')
        document.body.removeChild(elementText);
        ToastNotification(t('Copied'))
    }


    const ConfigurationPage = () => {
        return (
            <>

                <Container>
                    <Row>
                        <Col xs={6} style={{padding: 0}}>

                            <Input
                                onChange={handleInputChange}
                                type={'text'}
                                id={'name'}
                                name={'name'}
                                value={interaction.name}
                                required={true}
                                messageError={validateFields.name}
                                label={t('Interaction title')}
                                disabled={disableElements}
                                data-cy="name-input"/>


                        </Col>

                    </Row>

                    <Row>
                        <Col xs={9} style={{padding: 0}}>
                            <Subtitle>{t('To:')}</Subtitle>
                            <Row>
                                <Col>

                                    <ToggleContainer>
                                        <div>
                                            <ToggleSwitch
                                                data-handlecardevent={false}
                                                onChange={handleCompanySelectorChange}
                                                id={'company'}
                                                disabled={disableElements}
                                                name={'company'}
                                                checked={companys.active}
                                            />
                                        </div>

                                        <Label
                                            htmlFor={'company'}>{t('Company / Team members / Individual')}</Label>
                                    </ToggleContainer>

                                </Col>
                                {companys.active && (
                                    <Col>

                                        <ToggleContainer>
                                            <Label
                                                htmlFor={'company_some_all'}>{t('Choose')}</Label>
                                            <div>
                                                <ToggleSwitch
                                                    data-handlecardevent={false}
                                                    disabled={disableElements}
                                                    onChange={({target}) => {
                                                        if (target.checked) {
                                                            setSelectAllApplications(false)
                                                            setApplicationsSelecteds(applications.map((item) => item.id))

                                                        } else {
                                                            setApplicationsSelecteds([])
                                                        }
                                                        setCompanys({
                                                            ...companys,
                                                            active_some_all: target.checked
                                                        })
                                                    }}
                                                    id={'company_some_all'}

                                                    name={'company_some_all'}
                                                    checked={companys.active_some_all}
                                                />
                                            </div>

                                            <Label
                                                htmlFor={'company_some_all'}>{t('Select All')}</Label>
                                        </ToggleContainer>

                                    </Col>
                                )}

                            </Row>
                            <Row>
                                <Col>

                                    <ToggleContainer>
                                        <div>
                                            <ToggleSwitch
                                                disabled={disableElements}
                                                data-handlecardevent={false}
                                                onChange={handleEvaluatorSelectorChange}
                                                id={'evaluators'}

                                                name={'evaluators'}
                                                checked={evaluators.active}
                                            />
                                        </div>

                                        <Label
                                            htmlFor={'evaluators'}>{t('Evaluators')}</Label>
                                    </ToggleContainer>

                                </Col>
                                {evaluators.active && (
                                    <Col>

                                        <ToggleContainer>
                                            <Label
                                                htmlFor={'evaluators_some_all'}>{t('Choose')}</Label>
                                            <div>
                                                <ToggleSwitch
                                                    disabled={disableElements}
                                                    data-handlecardevent={false}
                                                    id={'evaluators_some_all'}
                                                    onChange={({target}) => {
                                                        if (target.checked) {
                                                            setSelectAllEvaluators(false)
                                                            setMembersSelecteds(members.map((item) => item.id))

                                                        } else {
                                                            setMembersSelecteds([])
                                                        }
                                                        setEvaluators({
                                                            ...evaluators,
                                                            active_some_all: target.checked
                                                        })
                                                    }}
                                                    name={'evaluators_some_all'}
                                                    checked={evaluators.active_some_all}
                                                />
                                            </div>

                                            <Label
                                                htmlFor={'evaluators_some_all'}>{t('Select All')}</Label>
                                        </ToggleContainer>

                                    </Col>
                                )}

                            </Row>
                            <Row>
                                <Col>

                                    <ToggleContainer>
                                        <div>
                                            <ToggleSwitch
                                                disabled={disableElements}
                                                data-handlecardevent={false}
                                                onChange={handleManagerSelectorChange}
                                                id={'manager'}
                                                name={'manager'}
                                                checked={managers.active}
                                            />
                                        </div>

                                        <Label
                                            htmlFor={'manager'}>{t('Program manager')}</Label>
                                    </ToggleContainer>

                                </Col>
                                {managers.active && (
                                    <Col>

                                        <ToggleContainer>
                                            <Label
                                                htmlFor={'manager_some_all'}>{t('Choose')}</Label>
                                            <div>
                                                <ToggleSwitch
                                                    data-handlecardevent={false}
                                                    disabled={disableElements}
                                                    onChange={({target}) => {
                                                        if (target.checked) {
                                                            setSelectAllManager(false)
                                                            setManagerSelecteds(membersManager.map((item) => item.id))

                                                        } else {
                                                            setManagerSelecteds([])
                                                        }
                                                        setManagers({
                                                            ...managers,
                                                            active_some_all: target.checked
                                                        })
                                                    }}
                                                    id={'manager_some_all'}

                                                    name={'manager_some_all'}
                                                    checked={managers.active_some_all}
                                                />
                                            </div>

                                            <Label
                                                htmlFor={'manager_some_all'}>{t('Select All')}</Label>
                                        </ToggleContainer>

                                    </Col>
                                )}

                            </Row>
                            <Row>
                                <Col>
                                    {showErrorToToggles && <MessageError
                                        style={{textAlign: 'left'}}>{t('Choosen at last one')}</MessageError>}
                                </Col>

                            </Row>


                        </Col>

                    </Row>
                </Container>


            </>
        )

    }

    function openPreview() {

        setPreviewEmail({
            title_email: interaction.title_email,
            message_email: interaction.message_email
        })
        setShowModalPreview(true)
    }


    const pagMessage = () => {
        return (
            <Container>
                <Row>
                    <Col xs={9} style={{padding: 0}}>

                        <input hidden={true}/>
                        <Input onChange={handleInputChange}
                               type={'text'}
                               id={'title_email'}
                               name={'title_email'}
                               value={interaction.title_email}
                               required={true}
                               messageError={validateFields.title_email}
                               label={t('Subject')}
                               disabled={disableElements}
                               data-cy="subject-input"/>


                        <TextArea
                            onChange={handleInputChange}
                            id={'message_email'}
                            value={interaction.message_email}
                            messageError={validateFields.message_email}
                            required={true}
                            label={t('Body')}
                            height={'300px'}
                            disabled={disableElements}
                            data-cy="body-input"/>

                    </Col>

                    <Col xs={3} style={{paddingRight: 0}}>
                        <StyleDivVariables
                            title={t('Click on the variable to copy')}>
                            <StyleDivSpan>
                                <CardVariable> {t('Variables')} </CardVariable>
                                <p style={{fontSize: 13}}>{t('Click on the variable to copy')}</p>
                            </StyleDivSpan>
                            {templateCustomized && templateCustomized.variables.map((item) =>
                                <CardVariable
                                    key={item.id}
                                    data-value={item.name}
                                    style={{cursor: 'pointer'}}
                                    onClick={handleCopy}>{item.name}</CardVariable>)}

                        </StyleDivVariables>
                    </Col>
                </Row>
                <Modal size="xl" show={showModalPreview}
                       onHide={() => {
                           setShowModalPreview(false)
                       }}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {t('Preview')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {isLoadingPreview ?
                            (<Loading/>) :
                            (
                                <Container>


                                    <Row>
                                        <Col xs={6}>
                                            <Row>
                                                <Input
                                                    onChange={({target}) => {
                                                        const value = target.value
                                                        const name = target.name
                                                        setEmailPreview(target.value)
                                                    }} type={'email'}
                                                    required={true}
                                                    name={'email'}
                                                    label={t('Enter your e-mail')}
                                                    value={emailPreview}/>
                                            </Row>
                                            <Row>
                                                {templateCustomized && templateCustomized.variables.map((item) => (
                                                    <Col xs={12}>
                                                        <Input name={item.name}
                                                               onChange={changeForm}
                                                               label={item.name}
                                                               value={previewForm[item.name]}/>
                                                    </Col>
                                                ))}
                                            </Row>

                                        </Col>
                                        <Col xs={6}>


                                            <div>
                                                <Info><LabelBold>{t('Subject:')} </LabelBold></Info>
                                                <Text>
                                                    {previewEmail.title_email}
                                                </Text>
                                            </div>
                                            <div>
                                                <Info><LabelBold>{t('Message:')} </LabelBold></Info>
                                                <Text>
                                                    <Description>
                                                        {previewEmail.message_email}
                                                    </Description>

                                                </Text>
                                            </div>


                                        </Col>
                                    </Row>


                                </Container>
                            )}

                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonDefault action="primary"
                                       onClick={handleSendTestMail}
                                       label={t('Send a test email')}/>
                    </Modal.Footer>
                </Modal>
            </Container>
        )

    }


    const PageTo = () => {

        return (
            <Container>
                {/*<StepWizard contentList={pagesTo} initalStep={0}/>*/}
            </Container>
        )

    }

    function handleSelectAllEvaluators({target}) {
        const checked = target.checked
        setSelectAllEvaluators(checked)
        if (checked) {

            setMembersSelecteds(members.map((item) => item.id))
        } else {
            setMembersSelecteds([])
        }

    }

    function handleSelectAllManager({target}) {
        const checked = target.checked
        setSelectAllManager(checked)
        if (checked) {

            setManagerSelecteds(membersManager.map((item) => item.id))
        } else {
            setManagerSelecteds([])
        }

    }


    function handleSelectAllApplications({target}) {
        const checked = target.checked
        setSelectAllApplications(checked)
        if (checked) {
            setApplicationsSelecteds(applications.map((item) => item.id))
        } else {
            setApplicationsSelecteds([])
        }

    }

    const pagSelectMembers = () => {
        return (
            <>
                <Container>
                    <Subtitle>{t('Choose evaluator(s)')}</Subtitle>
                    <div className={'d-flex justify-content-between'}>
                        <div style={{marginLeft: 5}}>
                            <CheckBox id='AllCheckedEvalators'

                                      name='AllCheckedEvalators'
                                      onChange={handleSelectAllEvaluators}
                                      options={[{
                                          id: 'select_all_evaluators_1',
                                          label: `${t('Select all')}`,
                                          checked: selectAllEvaluators,
                                          disabled: disableElements,
                                      }]}/>
                        </div>
                        <Filtercontainer>


                            <IconButton
                                onClick={() => setShowFiltersMembers(true)}
                                iconImage={FilterIcon}/>
                        </Filtercontainer>

                    </div>

                        <>
                            {members.length === 0 ?
                                    <NotContent
                                        title={t("Don't have evaluators yet")}/>
                                :
                                <>
                                    <ShowNumberListContainer
                                        value={membersSelecteds.length}
                                        selected={membersSelecteds.length}>
                                        {membersSelecteds.length === 0 ? '' :
                                            <SelectCountList count_selected={membersSelecteds.length}/>}
                                        <ShowNumberList
                                            aling={'right'}
                                            count_displayed={members.length}
                                            count_total={amountMembers}/>
                                    </ShowNumberListContainer>
                                    <StyleDivMemberListCards>
                                        <InfiniteScroll
                                            loadMore={getMembers}
                                            hasMore={hasMoreMembers}
                                            useWindow={false}
                                            threshold={450}
                                            loader={<Loading/>}
                                        >
                                            {members && members.map((member, index) =>
                                                <div
                                                    key={'member' + member.id}>
                                                    <StyleDivListCard>
                                                        <StyleDivBodyListCard>
                                                            <StyleDivBodyCard>

                                                                <div>
                                                                    <CheckBox
                                                                        id='evaluators'
                                                                        name='evaluators'
                                                                        defaultChecked={membersSelecteds}
                                                                        onChange={(event) => onChangeMemberSelection(event.target)}

                                                                        options={[{
                                                                            id: member.id,
                                                                            value: member.id,
                                                                            checked: membersSelecteds.includes(member.id),
                                                                            disabled: disableElements,
                                                                        }]}/>
                                                                </div>
                                                                <CircleImage
                                                                    src={member.user_client.user?.picture ?? NoProfile}
                                                                    width="50"
                                                                    height="50"/>
                                                                <StyledInfoCard>
                                                                    <StyledSpanOverflowPadrao>
                                                                        <StyledSpanBold>{member.user_client.user.first_name}</StyledSpanBold>
                                                                        {member.permission_user_client_list.map((permission) =>

                                                                            <li key={permission.name + permission.id}>{permission.name}</li>)}
                                                                    </StyledSpanOverflowPadrao>

                                                                </StyledInfoCard>
                                                            </StyleDivBodyCard>
                                                        </StyleDivBodyListCard>
                                                    </StyleDivListCard>
                                                </div>
                                            )}
                                        </InfiniteScroll>
                                    </StyleDivMemberListCards>
                                </>}
                        </>

                    <OffCanvasComponent show={showFiltersMembers}
                                        handleClose={setShowFiltersMembers}
                                        isRight={true}
                                        title={t('Filter evaluators')}>


                        <a href={"#"} hidden={!showClearFiltersMembers}
                           onClick={handleClearFiltersMembers}>{t('Clear filters')}</a>
                        <FormSelect onChange={(e) => {
                            setOrderMembers(e.value)

                            setTimeout(() => handleSubmitFilterEvaluators(e.value), 1000)
                        }}
                                    id={'orderning'}
                                    name={'orderning'}
                                    options={filterOptions}
                                    value={orderMembers}
                                    label={t('Order by')}
                                    multiple={false}/>

                        <form ref={formQuestionsMembers}>

                            <FormSelect onChange={(e) => {

                                setSelectedPhasesMembers(e.map((item) => item.value))
                                setTimeout(handleSubmitFilterEvaluators, 1000)
                            }}
                                        name={'phases__id__in'}
                                        options={phases}
                                        label={t('Phases')}
                                        value={selectedPhasesMembers}
                                        multiple={true}/>

                            <FormSelect onChange={(e) => {

                                setStatusMembers(e.map((item) => item.value))
                                setTimeout(handleSubmitFilterEvaluators, 1000)
                            }}
                                        name={'assessments__status__in'}
                                        options={[{
                                            label: `${t('Sent')}`,
                                            value: 3
                                        }, {
                                            label: `${'Answered'}`,
                                            value: 2
                                        }, {
                                            label: `${'Not answered'}`,
                                            value: 1
                                        }]}
                                        label={t('Status')}
                                        value={statusMembers}
                                        multiple={true}/>

                        </form>
                    </OffCanvasComponent>
                </Container>

            </>
        )
    }

    const pagSelectManager = () => {
        return (
            <>
                <Container>
                    <Subtitle>{t('Choose program manager(s)')}</Subtitle>
                    <div className={'d-flex justify-content-between'}>
                        <div style={{marginLeft: 5}}>
                            <CheckBox id='AllCheckedManager'

                                      name='AllCheckedManager'
                                      onChange={handleSelectAllManager}
                                      options={[{
                                          id: 'select_all_manager',
                                          label: `${t('Select all')}`,
                                          checked: selectAllManager,
                                          disabled: disableElements,
                                      }]}/>
                        </div>
                        <Filtercontainer>
                            {/*{managerSelecteds.length} {t('Selected')}*/}
                        </Filtercontainer>
                    </div>
                    {isLoading ? (<Loading/>) : (
                        <>
                            {membersManager.length === 0 ?
                                    <NotContent
                                        title={t("Don't have program manager yet")}/>
                                :
                                <>
                                    <ShowNumberListContainer
                                        value={managerSelecteds.length}
                                        selected={managerSelecteds.length}>
                                        {managerSelecteds.length === 0 ? '' :
                                            <SelectCountList count_selected={managerSelecteds.length}/>}
                                        <ShowNumberList aling={'right'}
                                            count_displayed={membersManager.length}
                                            count_total={amountMembersManager}/>
                                    </ShowNumberListContainer>
                                    <StyleDivMemberListCards>
                                        <InfiniteScroll
                                            loadMore={getManagers}
                                            hasMore={hasMoreMembersManager}
                                            useWindow={false}
                                            threshold={450}
                                            loader={<Loading/>}
                                        >
                                            {membersManager && membersManager.map((member, index) =>
                                                <div
                                                    key={'member_manager_' + member.id}>
                                                    <StyleDivListCard>
                                                        <StyleDivBodyListCard>
                                                            <StyleDivBodyCard>

                                                                <div>

                                                                    <CheckBox
                                                                        id='manager'
                                                                        name='manager'
                                                                        defaultChecked={managerSelecteds}
                                                                        onChange={(event) => onChangeManagerSelection(event.target)}

                                                                        options={[{
                                                                            id: member.id,
                                                                            value: member.id,
                                                                            checked: managerSelecteds.includes(member.id),
                                                                            disabled: disableElements,
                                                                        }]}/>
                                                                </div>
                                                                <CircleImage
                                                                    src={member.user_client.user?.picture ?? NoProfile}
                                                                    width="50"
                                                                    height="50"/>
                                                                <StyledInfoCard>
                                                                    <StyledSpanOverflowPadrao>
                                                                        <StyledSpanBold>{member.user_client.user.first_name}</StyledSpanBold>
                                                                        {member.permission_user_client_list.map((permission) =>

                                                                            <li key={permission.name + permission.id}>{permission.name}</li>)}
                                                                    </StyledSpanOverflowPadrao>

                                                                </StyledInfoCard>
                                                            </StyleDivBodyCard>
                                                        </StyleDivBodyListCard>
                                                    </StyleDivListCard>
                                                </div>
                                            )}
                                        </InfiniteScroll>
                                    </StyleDivMemberListCards>
                                </>}

                        </>
                    )}
                </Container>

            </>
        )
    }

    const pagSelectApplications = () => {
        return (
            <>
                <Container>
                    <Subtitle>{t('Choose application(s)')}</Subtitle>
                    <div className={'d-flex justify-content-between'}>
                        <div style={{marginLeft: 5}}>
                            <CheckBox id='AllCheckedCompany'
                                      pagSelectApplications
                                // ref={checkAllElement}
                                      name='AllCheckedCompany'
                                      onChange={handleSelectAllApplications}
                                      options={[{
                                          id: 'select_all_company_1',
                                          label: `${t('Select all')}`,
                                          checked: selectAllApplications,
                                          disabled: disableElements,
                                      }]}/>
                        </div>
                        <Filtercontainer>
                        <span>
                    {/*{applicationsSelecteds.length} {t('Selected')}*/}
                        </span>

                            <IconButton
                                onClick={() => setShowFiltersApplications(true)}
                                iconImage={FilterIcon}/>
                        </Filtercontainer>
                    </div>

                        <>
                            {applications.length === 0 ?
                                    <NotContent
                                        title={t("Don't have application yet")}/>
                                :
                                <>

                                    <ShowNumberListContainer
                                        value={applicationsSelecteds.length}
                                        selected={applicationsSelecteds.length}>
                                        {applicationsSelecteds.length === 0 ? '' :
                                            <SelectCountList count_selected={applicationsSelecteds.length}/>}
                                        <ShowNumberList
                                            aling={'right'}
                                            count_displayed={applications.length}
                                            count_total={amountApplications}/>
                                    </ShowNumberListContainer>
                                    <StyleDivApplyListCards>
                                        <InfiniteScroll
                                            loadMore={getApplications}
                                            hasMore={ !loadingApplications && hasMoreApplications}
                                            useWindow={false}
                                             threshold={450}
                                            loader={<Loading/>}
                                        >
                                            {applications && applications.map((item, index) =>(
                                                <div
                                                    key={'application' + item.id + index}>
                                                    <StyleDivListCard>
                                                        <StyleDivBodyListCard>
                                                            <StyleDivBodyCard>
                                                                <div>
                                                                    <CheckBox
                                                                        id='applications'
                                                                        // ref={checkAllElement}
                                                                        name='applications'
                                                                        defaultChecked={applicationsSelecteds}
                                                                        onChange={(event) => onChangeApplicationSelection(event.target)}

                                                                        options={[{
                                                                            id: item.id,
                                                                            value: item.id,
                                                                            checked: applicationsSelecteds.includes(item.id),
                                                                            disabled: disableElements,
                                                                        }]}/>
                                                                </div>
                                                                <ApplicationCard
                                                                    item={item}/>

                                                            </StyleDivBodyCard>
                                                        </StyleDivBodyListCard>
                                                    </StyleDivListCard>

                                                </div>)
                                            )}
                                        </InfiniteScroll>
                                    </StyleDivApplyListCards>
                                </>}
                        </>

                    <OffCanvasComponent show={showFiltersApplications}
                                        handleClose={setShowFiltersApplications}
                                        isRight={true}
                                        title={t('Filter applications')}>
                        <a href={"#"} hidden={!showClearFilters}
                           onClick={handleClearFilters}>{t('Clear filters')}</a>
                        <FormSelect onChange={(e) => {
                            setOrderApplications(e.value)

                            setTimeout(() => handleSubmitFilterApplications(e.value), 1000)
                        }}
                                    id={'orderning'}
                                    name={'orderning'}
                                    options={filterOptions}
                                    value={orderApplications}
                                    label={t('Order by')}
                                    multiple={false}/>

                        <form ref={formQuestions}>
                            <FormSelect onChange={(e) => {

                                setSelectedChallenges(e.map((item) => item.value))
                                setTimeout(handleSubmitFilterApplications, 1000)
                            }}
                                        name={'challenge__id__in'}
                                        options={challenges}
                                        label={t('Challenges')}
                                        value={selectedChallenges}
                                        multiple={true}/>
                            <FormSelect onChange={(e) => {

                                setSelectedPhases(e.map((item) => item.value))
                                setTimeout(handleSubmitFilterApplications, 1000)
                            }}
                                        name={'current_phase__id__in'}
                                        options={phases}
                                        label={t('Phases')}
                                        value={selectedPhases}
                                        multiple={true}/>

                            {resetFilters === false &&
                            <FormSelect onChange={(e) => {

                                setStatusApplication(e.value)
                                setTimeout(handleSubmitFilterApplications, 1000)
                            }}
                                        name={'is_finished'}
                                        options={[{
                                            label: `${'Finished'}`,
                                            value: 'true'
                                        }, {
                                            label: `${'Pending'}`,
                                            value: 'false'
                                        },]}
                                        label={t('Status')}
                                        value={statusApplication}
                                        multiple={false}/>}

                        </form>
                    </OffCanvasComponent>
                </Container>


            </>
        )
    }

    function clearValidation() {
        const newValidade = {}
        Object.keys(validateFields).forEach((key) => {
            newValidade[key] = ''
        })
        setValidateFields(newValidade)
    }

    const pagConfirmInteracton = () => {


        return (

            <ConfirmInteraction applications={applicationsSelecteds}
                                managers={managerSelecteds}
                                interaction={interaction}
                                members={membersSelecteds}/>


        )
    }


    const pages = [
        {
            'id': 1,
            'header': `${t('Configuration')}`,
            'content': ConfigurationPage(),
            'firstButton': null,
            'lastButton': {
                'label': `${t('Next')}`,
                'type': 'primary',
                'action': (setCurrentStep) => {
                    clearValidation()

                    const isClicked = (companys.active || evaluators.active || managers.active)
                    setShowErrorToToggles(!isClicked)
                    if (interaction.name && isClicked) {
                        if (isGoPageTo) {
                            setCurrentStep(1)
                        } else {
                            setCurrentStep(2)
                        }
                    } else {
                        ToastNotification(t('Fields requireds'),'error')
                        setValidateFields({
                            ...validateFields,
                            name: interaction.name ? '' : t('This field is required')
                        })
                    }

                }
            }
        },
        {
            'id': 2,
            'header': `${t('To')}`,
            'subpages': [
                {
                    'subheader': `${t('Company/Team members/ Individual')}`,
                    'content': pagSelectApplications(),
                    'firstButton': {
                        'label': `${'Return'}`,
                        'type': 'tertiary',
                        'action': (setCurrentStep, setCurrentStepSubPage) => {

                            setCurrentStep(0)
                        }
                    },
                    'lastButton': {
                        'label': `${t('Next')}`,
                        'type': 'primary',
                        'action': (setCurrentStep, setCurrentStepSubPage) => {

                            if (!evaluators.active_some_all) {
                                setCurrentStepSubPage(1)
                            } else if (!managers.active_some_all) {
                                setCurrentStepSubPage(2)
                            } else {
                                setCurrentStep(2)
                            }

                        }
                    },
                    'active': !companys.active_some_all
                },
                {
                    'subheader': `${t('Evaluators')}`,
                    'content': pagSelectMembers(),
                    'firstButton': {
                        'label': `${t('Return')}`,
                        'type': 'tertiary',
                        'action': (setCurrentStep, setCurrentStepSubPage) => {
                            if (!companys.active_some_all) {
                                setCurrentStepSubPage(0)
                            } else {
                                setCurrentStep(0)
                            }

                        }
                    },
                    'lastButton': {
                        'label': `${t('Next')}`,
                        'type': 'primary',
                        'action': (setCurrentStep, setCurrentStepSubPage) => {
                            if (!managers.active_some_all) {
                                setCurrentStepSubPage(2)
                            } else {
                                setCurrentStep(2)
                            }

                        }
                    },
                    'active': !evaluators.active_some_all
                },
                {
                    'subheader': `${t('Manager')}`,
                    'content': pagSelectManager(),
                    'firstButton': {
                        'label': `${t('Return')}`,
                        'type': 'tertiary',
                        'action': (setCurrentStep, setCurrentStepSubPage) => {
                            if (!evaluators.active_some_all) {
                                setCurrentStepSubPage(1)
                            } else if (!companys.active_some_all) {
                                setCurrentStepSubPage(0)
                            } else {
                                setCurrentStep(0)
                            }

                        }
                    },
                    'lastButton': {
                        'label': `${t('Next')}`,
                        'type': 'primary',
                        'action': (setCurrentStep, setCurrentStepSubPage) => {
                            setCurrentStep(2)
                        }
                    },
                    'active': !managers.active_some_all
                },
            ],
            'content': PageTo(),
            'firstButton': null,
            'lastButton': null
        },
        {
            'id': 3,
            'header': `${t('Message')}`,
            'content': pagMessage(),
            'firstButton': {
                'label': `${t('Return')}`,
                'type': 'tertiary',
                'action': (setCurrentStep) => {

                    if (isGoPageTo) {
                        setCurrentStep(1)
                    } else {
                        setCurrentStep(0)
                    }
                }
            },
            'middleButton': {
                'label': `${t('Preview')}`,
                'type': 'secondary',
                'action': (setCurrentStep) => {
                    openPreview()
                }
            },
            'lastButton': {
                'label': `${t('Next')}`,
                'type': 'primary',
                'action': (setCurrentStep) => {
                    clearValidation()
                    if (interaction.message_email && interaction.title_email) {
                        setCurrentStep(3)
                    } else {
                        ToastNotification(t('Fields requireds'),'error')
                        setValidateFields({
                            ...validateFields,
                            title_email: interaction.title_email ? '' : t('This field is required'),
                            message_email: interaction.message_email ? '' : t('This field is required'),
                        })
                    }

                }
            }
        },
        {
            'id': 4,
            'header': `${t('Confirm')}`,
            'content': pagConfirmInteracton(),
            'firstButton': {
                'label': `${t('Return')}`,
                'type': 'tertiary',
                'action': (setCurrentStep) => {
                    setCurrentStep(2)
                }
            },
            'middleButton': !disableElements && {
                'label': `${t('Save')}`,
                'type': 'secondary',
                'action': (setCurrentStep) => {
                    handleSubmit()
                }
            },
            'lastButton': {
                'label': disableElements ? t('Go back') : t('Save and send'),
                'type': 'primary',
                'action': (setCurrentStep) => {
                    if (disableElements) {
                        history.push(`/programs/${id}/interaction`)
                    } else {

                        if (membersSelecteds.length > 0 || managerSelecteds.length > 0 || applicationsSelecteds.length > 0) {
                            handleSend()
                        } else {
                            ToastNotification(t('No emails to be sent'),'error')
                        }

                    }

                }
            }
        },
    ]

    return (
        <CardContainer>
            {isLoading ? <Loading/> : <StepWizard contentList={pages} initalStep={0}/>}
        </CardContainer>
    )
}

export default FormInteraction