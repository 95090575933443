import React from 'react';
import styled from 'styled-components'
import {neutral_6} from "../../utils/Colors";
const Loading = styled.div`
    background-color: ${neutral_6};
    width: 56px;
    height: 24px;
    border-radius: 8px;
    display: flex;
    gap: 8px;
    padding: 8px;
    
`

const Dot = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: white;

    animation-name: loading;
    animation-duration: 1s;
    animation-delay: ${({delay}) => delay}s;
    //animation-direction: alternate;
    animation-iteration-count: infinite;
    
    @keyframes loading {
        0% {
            
            transform: translateY(0%);
        }
        25% {
            transform: translateY(-50%);
        }
         75% {
            transform: translateY(0%);
        }
         100% {
            transform: translateY(50%);
        }
    }
`

export default function ChatLoading({loading, ...props}) {
    if(!loading) return null
    return (
        <Loading {...props}>
            <Dot delay={0}/>
            <Dot delay={0.1}/>
            <Dot delay={0.2}/>
        </Loading>
    );
}

