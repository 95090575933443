import api from "../services/api";
import {ToastNotification} from "../components/feedback/ToastNotification";
import {BuildFieldErrorsDict} from "../utils/Utils";
import {useTranslation} from "react-i18next";
import * as ConstantsMessage from "../utils/fileWithMessage";
import useEnvironment from "./useEnvironment";
import {useState} from "react";


export default function useApplication() {
    const [loadingRequest, setLoadingRequest] = useState(false)
    const {t} = useTranslation();
    const {handleEnvironmentRedirect} = useEnvironment();

    const handleApplicationRedirect = (application, queryParams="") => {
        if (application.internal_inovation_choice === "challenge") {
            handleEnvironmentRedirect(application.client_url, `/applications_challenges/${application.id}?${queryParams}`)
        } else {
            handleEnvironmentRedirect(application.client_url, `/solutions/${application.id}?${queryParams}`)
        }
    }

    const handleApplyChallenge = async (challenge, extraParams) => {
        const queryParams = new URLSearchParams({...extraParams})
        if (challenge.program_type === "challenge") {
            queryParams.set("challenge", challenge.id);
        }
        try {
            setLoadingRequest(true)
            const dataForm = {
                "program_id": challenge.program_id || challenge.id,
            }
            const response = await api.post(`/applications`, dataForm);
            if (response.status === 201) {
                handleApplicationRedirect(response.data, queryParams.toString());
                ToastNotification(t(ConstantsMessage.SUCCESS));
            }
        } catch (error) {
            if (error.response?.status === 301) {
                const data = error.response.data
                const existingApplication = {
                    id:data.application_id,
                    client_url: challenge.client_url
                }
                queryParams.delete("challenge");
                handleApplicationRedirect(existingApplication, queryParams);
                ToastNotification(error.response.data.detail, "warning")
            } else {
                BuildFieldErrorsDict(error, null, false)
            }
        }finally {
            setLoadingRequest(false)
        }
    }

    return {
        handleApplyChallenge,
        handleApplicationRedirect,
        loadingRequest
    };
}