import {SESSION_COOKIE_PREFIX} from "../../settings";
import {cookie_api} from "./Auth";

export const FALLBACK_LNG = ['en-us']
export const AVAIABLE_LANGUAGES = ['en-us','pt-br']

export function getLanguage() {
    return cookie_api.get(`${SESSION_COOKIE_PREFIX}_language`) || 'en-us'
}
export function saveLanguage(language_string) {
    cookie_api.set(`${SESSION_COOKIE_PREFIX}_language`, language_string)
    return true
}

export function getCurrencySymbol() {
    const lang = getLanguage();
    return lang === 'en-us'? '$' : 'R$';
}


export function refreshLanguage(language_string) {
    if(language_string !== getLanguage()){
        saveLanguage(language_string)
        window.location.reload()
    }
    return true
}


export function get_item_cookie(key,default_value = ''){
    let value = cookie_api.get(`${SESSION_COOKIE_PREFIX}_${key}`)
    if (value){
        return value
    }
    return default_value
}


export function save_item_cookie(key,value){
    cookie_api.set(`${SESSION_COOKIE_PREFIX}_${key}`, value)
    return true
}