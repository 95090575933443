import {Col, Modal, Row, Typography} from "antd";
import ApplicationInfoCard from "../../../../../../data_display/ApplicationInfoCard/ApplicatioInfoCard";
import {DeleteOutlined} from "@ant-design/icons";
import api from "../../../../../../../services/api";
import {useTranslation} from "react-i18next";
import {useLayoutContext} from "../../../../../../../../modules/project/components/layout/context/LayoutContext";
import ApplicationInfoCardV2 from "../../../../../../data_display/ApplicationInfoCard/ApplicationInfoCardV2";

const {Text} = Typography

export default function GroupedApplicationCard({application, handleUpdateApplication, hasPermission, ...props}) {

    const {t} = useTranslation()
    const {openNotification} = useLayoutContext();

    const handleUngroupApplication = async () => {
        try {
            const url = `/applications/${application.id}/ungroup`
            void await api.post(url)
            void handleUpdateApplication()
            openNotification({type: "success", message: t("Application ungrouped successfuly")});
        } catch (error) {}
    }

    const confirmUngroupApplication = () => {
        Modal.confirm({
            title: `${t("Ungroup")} ${t("application")}` ,
            content: t("When ungrouping the application, it will return to the selection process at the stage where it was discontinued."),
            okText: t("Ungroup"),
            cancelText: t("Give up"),
            onOk: () => handleUngroupApplication()
        });
    }

    return (
        <Row gutter={8} align="middle" wrap={false}>
            <Col flex={1}>
                {/*<ApplicationInfoCard application={application} {...props}/>*/}
                <ApplicationInfoCardV2 application={application}/>
            </Col>

            {hasPermission &&
                <Text type="danger"
                      onClick={() => confirmUngroupApplication()}
                      style={{cursor: "pointer"}}>
                    <DeleteOutlined/>
                </Text>
            }

        </Row>
    );
}
