import {useTranslation} from "react-i18next";
import {Space, Typography, Button, Modal} from "antd";
import { CloseCircleOutlined, CrownOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import SolvCollapse from "../../../misc/SolvCollapse";
import WideSpace from "../../../layout/WideSpace";
import SolvTable from "../../../tables/SolvTable";
import api from "../../../../../global/services/api";
import TeamMemberInviteDrawer from "./TeamMemberInviteDrawer";
import { renderProfile } from "../../../tables/utils";
import { BuildFieldErrorsDict } from "../../../../utils/Utils";
import { useUserContext } from "../../../../context/UserContext";
import { ToastNotification } from "../../../feedback/ToastNotification";
import DrawerProfileUser from "../../../../pages/profile/components/DrawerProfileUser/DrawerProfileUser";

const {confirm} = Modal;
const {Link} = Typography;


export default function TeamMemberInvitesTable({teamId, onClose, onAdd=null}) {
    const [invites, setInvites] = useState(undefined);
    const [openDrawerProfile, setOpenDrawerProfile] = useState(false);
    const [userId, setUserId] = useState();
    const [showTeamMemberInviteDrawer, setShowTeamMemberInviteDrawer] = useState(false);
    const {_user} = useUserContext();

    const {t} = useTranslation();
    
    const fetchTeamInvites = async (teamId) => {
        try {
            const response = await api.post(`/teams/${teamId}/members_involved`);
            setInvites(response.data);
        } catch (error) {
            ToastNotification(t("Error loading data"), 'error')
        }
    }
    
    const resetTable = () => {
        setInvites(undefined);
        fetchTeamInvites(teamId);
    }

    const handleRemoveMember = async (record) => {
        try {
            await api.delete(`/team_members/${record.id}/delete_team_member`);
            resetTable();
            if (record.user_id === _user.id) {
                dispatchEvent(new Event("reloadTeams"));
                onClose();
            }
            ToastNotification(t("Successfully"));
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
    }
    
    const confirmDeletion = (record) => {
        confirm({
            title: t("Remove member from this team"),
            content: t("When a member is removed, he immediately loses access to view applications that were submitted in programs that were held in programs by that time or company. This means that all information, data and details related to these applications become inaccessible to the removed member."),
            icon: <CloseCircleOutlined style={{color: "red"}}/>,
            okText: t("Remove"),
            okType: "danger",
            cancelText: t("Cancel"),
            okButtonProps: { 'data-cy': 'remove-modal-button' },
            onOk() {
                handleRemoveMember(record);
            },
        });
    };

    const handleShowTeamMemberInviteDrawer = () => {
        setShowTeamMemberInviteDrawer(true);
    }

    const handleOpenProfileDrawer = (record) => {
        setUserId(record.user_id);
        setOpenDrawerProfile(true);
    }

    const columns = [
        {
            title: t("Profile"),
            dataIndex: "name",
            render: (name, member)=><Space>
                <Link href="#" onClick={()=>handleOpenProfileDrawer(member)}>{renderProfile(name, member)}</Link>
                <CrownOutlined style={{visibility: member.is_owner? "visible": "hidden"}}/>
            </Space>
        },
        {
            title: t("Email"),
            dataIndex: "email",
        },
        {
            title: t("Actions"),
            key: "action",
            action: true,
            render: (_, record) => (
                <Space size="middle">
                   {record.has_remove_permission && <Typography.Link data-cy={`remove-member-link-${record.id}`} onClick={() => confirmDeletion(record)}>{t("Remove")}</Typography.Link>}
                </Space>
            ),
        },
    ];

    useEffect(()=>{
        teamId && fetchTeamInvites(teamId);
    }, [teamId]);

    return (
        <>
            <TeamMemberInviteDrawer open={showTeamMemberInviteDrawer} setOpen={setShowTeamMemberInviteDrawer} resetTable={resetTable} teamId={teamId} onAdd={onAdd}/>
            <SolvCollapse title={t("Members")} active={true} locked={true} padding={0}>
                <WideSpace direction="vertical">
                    <WideSpace style={{justifyContent: "end"}}>
                        <Button onClick={handleShowTeamMemberInviteDrawer} data-cy={"add-member-button"}>{t("Add")}</Button>
                    </WideSpace>
                    <SolvTable columns={columns} dataSource={invites} data-cy="team-invite-table"/>
                </WideSpace>
            </SolvCollapse>
            <DrawerProfileUser
                id={userId}
                open={openDrawerProfile}
                setOpen={setOpenDrawerProfile}
            />
        </>
    );
}
