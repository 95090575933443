import {Button, Col, Form, Input, Row, Select, Slider, Space, Switch} from "antd";
import {useEffect, useState} from "react";
import Drawer from "../../../../../global/components/layout/Drawer";
import DatePickerLocal from "../../../../../global/components/data_entry/DatePickerLocal";
import { useTranslation } from "react-i18next";

const {TextArea} = Input

function TaskOffCanvas({ open, setOpen, task, taskChoices, form, handleSubmit, handleDelete, container }) {
    const { t } = useTranslation();
    const isEditing = !!task;
    task = task || {};
    const [selectedTaskType, setSelectedTaskType] = useState();
    const [taskTypeOptions] = useState([
        { label: t("Task"), value: "task" },
        { label: t("Task"), value: "project", hidden: true },
        { label: t("Fixed Task"), value: "fixedTask" },
        { label: t("Milestone"), value: "milestone" },
    ]);

    const handleTaskTypeChange = (value) => {
        if (value === "fixedTask"){
            form.setFieldsValue({"isDisabled": true, "type": "task"});
        } else {
            form.setFieldsValue({"isDisabled": false, "type": value});
        }
        setSelectedTaskType(value);
        return value;
    };

    const updateDates = (date) => {
        form.setFieldsValue({start: date, end: date});
        return date;
    }
    useEffect(() => {
        setSelectedTaskType(form.getFieldValue("form_type"));
    }, [form.getFieldsValue()]);

    return (
        <Drawer
            container={container}
            open={open}
            onClose={() => setOpen(false)}
            title={t("Task")}
            footer={
                <div style={{ textAlign: "end" }}>
                    <Space>
                        {(task && !task["is_main"]) && <Button danger onClick={()=>handleDelete({id: task.id})}>{t("Delete")}</Button> }
                        <Button type="primary" htmlType="submit" onClick={() => form.submit()} data-cy="submit-button">{t("Save")}</Button>
                    </Space>
                </div>}
        >
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Form.Item name="form_type" label={`${t("Activity Type")}:`} rules={[{ required: true }]} getValueFromEvent={handleTaskTypeChange}>
                    <Select options={taskTypeOptions} disabled={isEditing} getPopupContainer={()=>container} data-cy="activity-type-select"/>
                </Form.Item>
                <Form.Item name="type" hidden>
                    <Input />
                </Form.Item>
                <Form.Item name="name" label={`${t("Task Name")}:`} rules={[{ required: true }]}>
                    <Input data-cy="name-input"/>
                </Form.Item>
                {((selectedTaskType === "task" || selectedTaskType === "milestone") && !task?.is_main) && <>
                    {selectedTaskType === "task" &&
                        <Form.Item name="project_task" label={`${t("It is a subtask of")}:`}>
                            <Select placeholder={t("Select the task")} options={taskChoices} allowClear getPopupContainer={()=>container} data-cy="subtask-of-select"/>
                        </Form.Item>
                    }
                    <Form.Item name="dependency" label={`${t("Depends On")}:`}>
                        <Select placeholder={t("Select dependencies")} options={taskChoices} allowClear getPopupContainer={()=>container} data-cy="depends-on-select"/>
                    </Form.Item>
                </>}

                {selectedTaskType === "milestone" &&
                    <>
                        <Form.Item name="date" label={`${t("Date")}:`} getValueFromEvent={updateDates}>
                            <DatePickerLocal placeholder={t("Select date")} style={{ display: "block" }} getPopupContainer={()=>container} data-cy="date-select"/>
                        </Form.Item>
                    </>
                }
                <Row gutter={16} hidden={selectedTaskType === "milestone"}>
                    <Col span={12}>
                        <Form.Item name="start" label={`${t("Start Date")}:`} rules={[{ required: true }]}>
                            <DatePickerLocal disabled={task["has_children"]} placeholder={t("Select date")} style={{ display: "block" }} getPopupContainer={()=>container} data-cy="date-start-select" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="end" label={`${t("End Date")}:`} rules={[{ required: true }]}>
                            <DatePickerLocal disabled={task["has_children"]} placeholder={t("Select date")} style={{ display: "block" }} getPopupContainer={()=>container} data-cy="date-end-select" />
                        </Form.Item>
                    </Col>
                </Row>
                {(selectedTaskType === "task" || selectedTaskType === "project") && <Form.Item name="progress" label={`${t("Progress")}:`}>
                    <Slider disabled={task["has_children"]} getTooltipPopupContainer={()=>container} data-cy="progress-slider"/>
                </Form.Item>}
                <Form.Item name="details" label={`${t("Activity Details")}:`} rules={[{ required: false }]}>
                    <TextArea placeholder={t("Give some details about your activity")} style={{ height: 164 }} data-cy="activity-details-input"/>
                </Form.Item>
                <Form.Item name="isDisabled" valuePropName="checked" hidden><Switch /></Form.Item>
                <Input type="submit" hidden />
            </Form>
        </Drawer>
    );
}

export default TaskOffCanvas;