import { useTranslation } from 'react-i18next';
import CollapseFormItem from '../../../../../../global/components/data_entry/CollapseFormItem';
import TransparentCollapse from '../../../../../../global/components/misc/TransparentCollapse';
import { Switch } from 'antd';

export default function HiddenInscriptionsCollapse({FORM_NAMES, program}) {
    const {t} = useTranslation()

    return (
        <TransparentCollapse header={t("Hidden Inscription")} >
            <CollapseFormItem input={<Switch disabled={program?.is_confidential}/>}
                          name={FORM_NAMES.IS_CONFIDENTIAL}
                          label={t("Hidden Inscription")}
                          extraFormItemProps={{valuePropName:"checked"}}
                          help={t("When active, access to application data will only be allowed after registration has ended.")}
            />
        </TransparentCollapse>
    );
}