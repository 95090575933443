import React, {useEffect} from 'react'
import {
    Col,
    Form as FormAntd,
    Row,
} from 'antd';
import {
    QuestionContainer,
    QuestionTitle
} from "../../../../authentication/StyledComponents";
import {useTranslation} from "react-i18next";
import TextArea from "../../../TextArea";
import Required from "./Required";
import FieldError from "./FieldError";


export default function FeedbackField({
                                          label,
                                          help_text,
                                          required,
                                          weight,
                                          show_feedback,
                                          onChange,
                                          value,
                                          name,
                                          ...props
                                      }) {
    const {t} = useTranslation()
    const {status} = FormAntd.Item.useStatus(); //error
    const hasError = status === 'error'

    function checkErrorFeedback() {
        return hasError && !value?.feedback
    }

    function handleFeedBackChange({target: {value: fieldValue}}) {
        onChange?.({...value, feedback: fieldValue})
    }


    return (

        <QuestionContainer>
            <Row gutter={[19, 19]} data-cy="textarea-container">
                <Col xs={24}>
                    <QuestionTitle>
                        {label && <Required required={required} label={label}/>}
                        {label}
                    </QuestionTitle>
                    <TextArea onChange={handleFeedBackChange}
                        // status={checkErrorFeedback() && 'error'}
                              style={{marginTop: 8}}
                              value={value?.feedback}/>
                    {checkErrorFeedback() && <FieldError/>}
                </Col>
            </Row>

        </QuestionContainer>


    )
}
