import { CloseOutlined } from "@ant-design/icons";
import { Grid, notification } from "antd";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
    ToastNotification
} from "../../../../../global/components/feedback/ToastNotification";
import useQuery from "../../../../../global/hooks/useQuery";
import "../../../../../global/components/feedback/_feedback.css";
import {BLOCK_KEY} from "../../../../../global/utils/Constants";
import * as ConstantsMessage
    from "../../../../../global/utils/fileWithMessage";
import useEventListener from "../../../../../global/hooks/useEventListener";

const CloseIconStyled = styled(CloseOutlined)`
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LayoutContext = createContext({});

const defaultConfigLayout = {
    'noMargin': true,
    'fullWidth': false,
    'noBackground': true
}

export function LayoutContextProvider({children}) {
    const [collapsed, setCollapsed] = useState(false);
    const [customBackgroundColor, setCustomBackgroundColor] = useState(undefined);
    const [navbarMenuItems, _setNavbarMenuItems] = useState(undefined);
    const [pageBottomContent, setPageBottomContent] = useState(undefined);
    const [isConfidential, setIsConfidential] = useState(false);
    const [extraHeaderContent, setExtraHeaderContent] = useState(undefined);
    const [showAvatar, setShowAvatar] = useState(false);
    const [useOldContentLayout, setUseOldContentLayout] = useState(defaultConfigLayout);
    const [isMobile, setIsMobile] = useState(false);
    const [shouldUpdateContent, setShouldUpdateContent] = useState(false);
    const screens = Grid.useBreakpoint();
    const location = useLocation();
    const refLayoutContent = useRef();
    const actionBarRef = useRef();
    const {t} = useTranslation();

    let query = useQuery();
    const [showWelcomeModal, setShowWelcomeModal] = useState(false)
    const [showAlertBlockModal, setShowAlertBlockModal] = useState(false)
    const [showTourModal, setShowTourModal] = useState(false);
    const [hasActionBar, setHasActionBar] = useState(true);

    const [showNotificationArea, setShowNotificationArea] = useState(false);






    const setNavbarMenuItems = (items) => {
        const allowedItems = items?.filter((item) => {
            if (!item.hasOwnProperty("hasPermission")) {
                return true;
            }
            return item.hasPermission;
        })
        _setNavbarMenuItems(allowedItems);
    }

    const initializeLayout = ({
                                  customBackgroundColor,
                                  navbarMenuItems,
                                  extraHeaderContent,
                                  pageBottomContent,
                                  hasActionBar = true,
                                  showAvatar = true,
                                  useOldContentLayout = defaultConfigLayout
                              }) => {
        setCustomBackgroundColor(customBackgroundColor);
        setHasActionBar(hasActionBar)
        setPageBottomContent(pageBottomContent);
        setNavbarMenuItems(navbarMenuItems);
        setExtraHeaderContent(extraHeaderContent);
        setShowAvatar(showAvatar);
        setUseOldContentLayout(useOldContentLayout);
    };

    const forceContentBackground = () => {
        setUseOldContentLayout({
            'noMargin': true,
            'fullWidth': false,
            'noBackground': true
        })
    }

    useEffect(() => {
        return () => {
            setIsConfidential(false);
            resetPageBottom();
        };
    }, [location]);

    useEffect(() => {
        // console.log(location)
        if (query.get(BLOCK_KEY)) {
            setShowAlertBlockModal(true)
        }
    }, [location]);

    useEffect(() => {//fecha botao quando tiver no m]obile e quando trocar de tela
        setCollapsed(!screens.md)
    }, [screens, location])

    useEffect(() => {
        if (isConfidential) ToastNotification(t('A program is running in stealth mode, restricting access to application information to maintain confidentiality'), 'warning')
    }, [isConfidential])


    const resetPageBottom = () => {
        setPageBottomContent(false);
    };

    // const openNotification = ({
    //                               type,
    //                               message,
    //                               description,
    //                               placement,
    //                               getContainer
    //                           }) => {
    //     notification[type]({message, description, placement, getContainer});
    // };

    const openNotification = ({
        type,
        message,
        description,
        placement,
        getContainer,
      }) => {
        notification[type]({
          message,
          description,
          placement,
          getContainer,
          closeIcon: <CloseIconStyled />,
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
          },
          // duration:99999
        });
    };

    const updateLayoutContent = () => {
        setShouldUpdateContent(true);
    }
    const updateWindown = () => {
        setTimeout(() => {
            window.location.reload()
        }, 1000)
    }

    useEffect(() => {
        if (shouldUpdateContent) {
            setShouldUpdateContent(false);
        }
    }, [shouldUpdateContent]);


    return <LayoutContext.Provider
        value={{
            refLayoutContent,
            navbarMenuItems, setNavbarMenuItems,
            showAvatar, setShowAvatar,
            extraHeaderContent, setExtraHeaderContent,
            collapsed, setCollapsed, openNotification,
            customBackgroundColor, setCustomBackgroundColor,
            pageBottomContent, setPageBottomContent,
            resetPageBottom,
            isConfidential, setIsConfidential,
            useOldContentLayout, setUseOldContentLayout,
            initializeLayout,
            isMobile,
            setShowWelcomeModal,
            showWelcomeModal,
            showTourModal,
            setShowTourModal,
            forceContentBackground,
            shouldUpdateContent,
            hasActionBar,
            actionBarRef,
            updateWindown,
            updateLayoutContent, showAlertBlockModal, setShowAlertBlockModal,
            showNotificationArea, setShowNotificationArea,
        }}>
        {children}
    </LayoutContext.Provider>;
}

export function useLayoutContext() {
    return useContext(LayoutContext);
}