import { ExpandAltOutlined } from "@ant-design/icons";
import { Alert, Col, Form, Row, Skeleton, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { AttachmentUploader } from "../../../global/components/data_entry/AttachmentUploader";
import ColorPicker from "../../../global/components/data_entry/ColorPicker";
import TransparentCollapse from "../../../global/components/misc/TransparentCollapse";
import { PROJECT_COLORS } from "../../../global/utils/Colors";
import { ACCEPT_IMAGE_FORMATS } from "../../../global/utils/Constants";
import ModelSummaryCard from "../pages/ProjectManagement/ModelSummaryCard";
import ModelChart from "./../pages/ProjectManagement/components/ModelChart";
import CkEditor, { SIMPLE_EDITOR_OPTIONS } from "./CKEditor";
import IndicatorDashboardCard from "./IndicatorDashboardCard";
import CollapseFormItem from "../../../global/components/data_entry/CollapseFormItem";
import WideSpace from "../../../global/components/layout/WideSpace";


const {Text} = Typography;

export function ProjectDescriptionForm({ project, form }) {
    const { t } = useTranslation();
    const isLoadingData = !project && project !== null;
    const color = Form.useWatch("color", form);
    const avatar = Form.useWatch("avatar", form);
    const projectDescription = Form.useWatch("description", form);

    return (
        <TransparentCollapse header={t("Description")}>
            <Skeleton loading={isLoadingData}>
                <Row gutter={[16, 16]}>
                    <Col xs={24}> 
                        <Alert message={t('The examples below serve to guide you when editing the project. To actually make the changes, click save.')} type="warning"/>
                    </Col>

                    <Col xs={24} style={{padding: 16}}>
                        <Row style={{paddingBottom:8}}>
                            <Text>{t("Project examples")}</Text>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col lg={16} xs={24}>
                                <ModelSummaryCard
                                    project={project}
                                    projectName={project?.name}
                                    projectDescription={projectDescription}
                                    color={color}
                                    avatar={avatar}
                                />
                            </Col>
                            <Col lg={8} xs={24}>
                                <IndicatorDashboardCard
                                    title={<Typography.Text style={{ fontWeight: 500 }}>{t("Graphic")}</Typography.Text>}
                                    extra={<ExpandAltOutlined />}
                                    bodyStyle={{ paddingBottom: 0 }}
                                >
                                    <ModelChart color={color} />
                                </IndicatorDashboardCard>
                            </Col>
                        </Row>
                    </Col>

                    <Col xs={24}>
                        <WideSpace direction="vertical" size="middle">
                            <CollapseFormItem
                                name="color"
                                label={t("Project Color")}
                                input={<ColorPicker colors={PROJECT_COLORS} customizable={false} dataCy="project-color-picker" />}
                                extraFormItemProps={{ initialValue: "#BAE7FF" }}
                            />
                            <CollapseFormItem
                                name="avatar"
                                label={t("Image")}
                                help={t(
                                    "Image size 240 x 240 pixels with square aspect ratio. The image will automatically adjust to maintain this aspect ratio, but if you insert an image of another aspect ratio, it may be cropped."
                                )}
                                input={
                                    <AttachmentUploader
                                        accept={ACCEPT_IMAGE_FORMATS}
                                        maxCount={1}
                                        showUploadList={{
                                            showRemoveIcon: false,
                                        }}
                                        dataCy="project-avatar-upload"
                                    />
                                }
                                extraFormItemProps={{ valuePropName: "fileList" }}
                            />
                            <CollapseFormItem
                                name="description"
                                label={t("Project Description")}
                                input={
                                    <CkEditor flex height="200px" {...SIMPLE_EDITOR_OPTIONS}/>
                                }
                                extraFormItemProps={{ initialValue: project?.description }}
                                wideInput
                                data-cy="project-description-input"
                            />
                        </WideSpace>
                    </Col>
                </Row>
            </Skeleton>
        </TransparentCollapse>
    );
}
