import React from "react";
import { useTranslation } from "react-i18next";
import { useThemeContext } from "../../../../context/ThemeContext";
import styled from "styled-components";
import { Space, Typography } from "antd";
import useSystemBreakpoint from "../../../../hooks/useSystemBreakpoint";

const { Title } = Typography;

const HeaderContainer = styled.div`
    height: 200px;
    width: 100%;
    position: relative;
`;
const ImageBG = styled.img`
    width: 100%;
    height: 200px;
    background-position: 40% 40%;
    object-fit: cover;
    position: absolute;
    transform: scale(100%);
`;

const StyledInputContainer = styled.div`
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: -24px;
    gap: 16px;
    padding: 32px 24px 32px 24px;
    background: rgba(0, 0, 0, 0.55);
    backdrop-filter: blur(3px);
    position: absolute;

    .ant-input-search {
        max-width: 500px;
    }
`;

export default function SectionWithInput({ title, children, icon }) {
    const { t } = useTranslation();
    const { themeConfig } = useThemeContext();
    const { isPhone } = useSystemBreakpoint();

    return (
        <HeaderContainer isPhone={isPhone}>
            <ImageBG src={themeConfig.login_img_cover} />
            <StyledInputContainer>
                    <Space size="middle">
                        <Title
                            level={isPhone ? 3 : 2}
                            style={{
                                color: "white",
                                marginBottom: 0,
                            }}
                        >
                            {title}
                        </Title>
                        {icon}
                    </Space>
                    {children}
            </StyledInputContainer>
        </HeaderContainer>
    );
}
