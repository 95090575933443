import Drawer from "../../layout/Drawer";
import {useTranslation} from "react-i18next";
import {Alert, Button, Col, Form as FormAnt, Input, Modal, Row, Space, Steps, Typography} from "antd";
import React, {useEffect, useState} from "react";
import RequestSelect from "../RequestSelect";
import SearchAddressInput from "../SearchAddressInput";
import DatePickerLocal from "../DatePickerLocal";
import useSystemBreakpoint from "../../../hooks/useSystemBreakpoint";
import api from "../../../services/api";
import {useLayoutContext} from "../../../../modules/project/components/layout/context/LayoutContext";
import {AttachmentUploader} from "../AttachmentUploader";
import {ACCEPT_DOCUMENT_FORMATS, ACCEPT_IMAGE_FORMATS} from "../../../utils/Constants";
import {convertToFormData, getFiles} from "../../../../modules/project/utils/utils";
import {BuildFieldErrorsDict} from "../../../utils/Utils";
import Form from "../form/Form";
import Loading from "../../feedback/Loading";
import TeamMemberInvitesTable from "./components/TeamMemberInvitesTable";
import styled from 'styled-components';
import {CardContainer} from "../../misc/CardContainer";
import PhoneNumberFormField from "../form/PhoneNumberFormField";

const StyledDrawer = styled(Drawer)`
    .ant-steps-item{
        min-width: 47%;
    }
    & .ant-drawer-body{
        background: rgba(250, 250, 250, 1);
    }
`

const StyledSteps = styled(Steps)`
    margin-bottom: 32px;
    &.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description  {
        max-width: 100%;
    }
`

export default function TeamDrawer({open, onCreate, onClose, formType, teamId, teams, enableFormAlert, setTeams}) {
    const {isDesktop} = useSystemBreakpoint()
    const {openNotification} = useLayoutContext();
    const [form] = FormAnt.useForm()
    const {t} = useTranslation()
    const rules = [{required: true}]
    const formTypeDisplay = t(formType)
    const [loading, setLoading] = useState(false)
    const [loadingTeam, setLoadingTeam] = useState(!!teamId);
    // const [editingTeam, setEditingTeam] = useState(undefined);
    const {confirm} = Modal;
    const [step, setStep] = useState(0);
    const [teamCreated, setTeamCreated] = useState(false);
    const [teamMembersConfirmation, setTeamMembersConfirmation] = useState(false)

    const formItemsCol1 = [
        {name: "name", label: t("Name"), input: <Input data-cy="name-input"/>, rules},
        {name: "description", label: t("Description"), input: <Input.TextArea data-cy="description-input"/>, rules},
        {name: "short_description", label: t("Describe your business in a few words"), input: <Input showCount maxLength={100} data-cy="short-description-input"/>, type: "Company", rules},
        {name: "address", label: t("Headquarters city"), input: <SearchAddressInput/>, type: "Company", rules},
        {name:"phone_number", render: <PhoneNumberFormField required form={{name: "phone_number", label:t("Phone number")}}/>, type: "Company"},
        {name: "email", label: t("Email"), input: <Input data-cy="email-input"/>, type: "Company", rules: [...rules,  {type: 'email', message: t('Please provide a valid email')}]},
        {name: "cnpj", label: t("Company document ID"), input: <Input data-cy="cnpj-input"/>, type: "Company"},
        {name: "site", label: t("Site"), input: <Input data-cy="site-input"/>, type: "Company"},
        {
            name: "number_of_employees",
            label: t("Number of employees"),
            input: <RequestSelect url="/number_of_employees" labelField="name" valueField="value" data-cy="number-of-employees-input"/>,
            type: "Company",
        },
        {
            name: "sectors_size",
            label: t("Size"),
            input: <RequestSelect url="/sectors_size" labelField="name" data-cy="sectors-size-input"/>,
            type: "Company",
        },
        {
            name: "sectors_institution",
            label: t("Company sectors"),
            input: <RequestSelect url="/sectors_institution" labelField="name" mode="multiple" data-cy="sectors-institution-input"/>,
            type: "Company",
        },
        {
            name: "date_start_operating",
            label: t("When did your company start operating?"),
            input: <DatePickerLocal style={{width: "100%"}} data-cy="date-start-operating-input"/>,
            type: "Company"
        },
    ]

    const formItemsCol2 = [
        {
            name: "sectors_persona",
            label: t("Company personas"),
            input: <RequestSelect url="/sectors_persona" labelField="name" mode="multiple" data-cy="sectors-persona-input"/>,
            type: "Company",
        },
        {
            name: "sectors_technology",
            label: t("Technologies"),
            input: <RequestSelect url="/sectors_technology" labelField="name" mode="multiple" data-cy="sectors-technology-input"/>,
            type: "Company",
        },
        {
            name: "sectors_transaction",
            label: t("Transactions type"),
            input: <RequestSelect url="/sectors_transaction" labelField="name" mode="multiple" data-cy="sectors-transaction-input"/>,
            type: "Company",
        },
        {
            name: "sectors_client",
            label: t("Client sectors"),
            input: <RequestSelect url="/sectors_client" labelField="name" mode="multiple" data-cy="sectors-client-input"/>,
            type: "Company",
        },
        {name: "facebook", label: "Facebook", input: <Input data-cy="facebook-input"/>, type: "Company"},
        {name: "instagram", label: "Instagram", input: <Input data-cy="instagram-input"/>, type: "Company"},
        {name: "twitter", label: "Twitter", input: <Input data-cy="twitter-input"/>, type: "Company"},
        {name: "linkedin", label: "Linkedin", input: <Input data-cy="linkedin-input"/>, type: "Company"},
        {name: "address", label: t("Headquarters city"), input: <SearchAddressInput/>, type: "Team"},
        {
            name: "profile_upload",
            valuePropName: "fileList",
            label: t("Insert the team image", {teamType:formTypeDisplay.toLowerCase()}),
            input: <AttachmentUploader accept={ACCEPT_IMAGE_FORMATS} maxCount={1} MAX_SIZE={3}
                        removeAction={()=>form.setFieldValue("profile_upload", undefined)}
                        data-cy="profile-upload"
                    >
                        <Button>{t("Click to Upload")}</Button>
                    </AttachmentUploader>,
            // type: "Team",
            help: `${t("Size")} (130x130). Max. 3MB`
        },
        {
            name: "attach_presentation",
            valuePropName: "fileList",
            label: t("Attach presentation"),
            input: <AttachmentUploader
                    accept={ACCEPT_DOCUMENT_FORMATS}
                    maxCount={1}
                    removeAction={()=>form.setFieldValue("attach_presentation", undefined)}
                    data-cy="presentation-upload"
                >
                    <Button>{t("Click to Upload")}</Button>
                </AttachmentUploader>,
            type: "Company",
            help: t("OnlyFileTypeSize", {file_type: "PDF", file_size:"10MB"})
        }
    ]

    const fetchTeam = async () => {
        try {
            setLoadingTeam(true);
            const response = await api.get(`/teams/${teamId}`);
            // setEditingTeam(response.data);
            const {address} = response.data;
            form.setFieldsValue({
                ...response.data,
                address: {
                    city: address?.city,
                    state: address?.state,
                    country: address?.country,
                    lat:  address?.lat,
                    long:  address?.long,
                },
                profile_upload: response.data?.picture && getFiles([{name: "Download", path: response.data?.picture}]),
                attach_presentation: response.data.attach_presentation && getFiles([{name: "Download", path: response.data.attach_presentation}])
            });
        }
        catch (error) {
             BuildFieldErrorsDict(error, null, false)
        }
        finally {
            setLoadingTeam(false)
        }
    }

    const handleFormSubmit = async (data) => {
        try {
            setLoading(true);
            const formData = convertToFormData({
                ...data,
                address: JSON.stringify(data.address),
                type: formType,
            }, false, false);

            let response;
            if (teamId) {
                response = await api.patch(`/teams/${teamId}`, formData);
                setTeams(teams.map((team)=>team.id === teamId ? response.data: team));
            } else {
                response = await api.post(`/teams`, formData);
                onCreate?.(response.data.id)
                setTeams([...teams, response.data]);
                setTeamCreated(response.data.id)
                setTeamMembersConfirmation(true)
            }
            openNotification({type: "success", message: t('Successfully', {formType: formTypeDisplay})});
            setStep(1)
        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, false)
            openNotification({type: "error", message: t('errorCreating', {formType: formTypeDisplay})});
        } finally {
            setLoading(false);
        }
    }

    useEffect(()=>{
        setStep(0)
        if (teamId) {
            fetchTeam();
        } else {
            form.resetFields();
            // setEditingTeam(undefined);
        }
    }, [teamId]);

    const onChangeStep = (value) => {
        setStep(value);
    };

    const onAdd = () => {
        setTeamMembersConfirmation(false)
    };

    const handleCloseDrawer = (verify = true) => {
        if (teamMembersConfirmation && verify){
            confirm({
                // title: t("Remove member from this team"),
                content: t("If you leave now, your team/company will be created without additional members"),
                // icon: <CloseCircleOutlined style={{color: "red"}}/>,
                okText: t("Leave"),
                okType: "danger",
                cancelText: t("Stay"),
                // okButtonProps: { 'data-cy': 'remove-modal-button' },
                onOk() {
                    onClose()
                },
            });
        } else{
            onClose()
        }
    }


    return (
        <StyledDrawer open={open} onClose={handleCloseDrawer} width={1000} title={teamId ? `${t("Update")} ${formTypeDisplay}`: `${t("Create")} ${formTypeDisplay}`} destroyOnClose
                footerStyle={{padding: 0}}
                footer={
                    <>
                        {enableFormAlert && <Alert
                            message={<Space>
                                <Typography.Text type="danger" strong>{t('Form not submitted')}.</Typography.Text>
                                <Typography.Text>{t("After creating the team/company, keep filling out the form.")}</Typography.Text>
                            </Space>}
                            type="warning"
                        />}
                        <Row justify={"end"} style={{padding: 16}}>
                            {step === 0 && <Button type="primary" onClick={form.submit} loading={loading} data-cy="submit-button">{t("Next")}</Button>}
                            {step === 1 && <Button type="primary" onClick={()=>handleCloseDrawer(false)} loading={loading} data-cy="submit-button">{t("Finish")}</Button>}
                        </Row>
                    </>

                }
        >
            {loadingTeam && <Loading />}
            {!loadingTeam &&
                <StyledSteps
                    current={step}
                    onChange={teamId && onChangeStep}
                    items={[
                      {
                        title: t(`${formType} details`),
                        description:t(`Fill in your ${formType} details`),
                      },
                      {
                        title: t(`${formType} members`),
                        description:t('Add member(s)'),
                      }
                    ]}
                  />
            }
            {(!loadingTeam && step===0) && <Form form={form} onFinish={handleFormSubmit}>
                <Row gutter={32}>
                    <Col span={(isDesktop && formType === "Company") ? 12 : 24}>
                        {formItemsCol1.map((item,index) => (
                            (!item.type || item.type === formType) &&
                            <Col span={24} key={item.name}>
                                {item.render ? (item.render): <FormAnt.Item  name={item.name} label={item.label} rules={item.rules} help={item.help} initialValue={undefined}>
                                    {item.input}
                                </FormAnt.Item>}
                            </Col>
                        ))}
                    </Col>

                    <Col span={(isDesktop && formType === "Company") ? 12 : 24}>
                        {formItemsCol2.map((item, index) => (
                            (!item.type || item.type === formType) &&
                            <Col span={24} key={item.name} >
                                <FormAnt.Item  name={item.name} label={item.label} rules={item.rules} help={item.help} valuePropName={item.valuePropName} initialValue={item.initialValue}>
                                    {item.input}
                                </FormAnt.Item>
                            </Col>
                        ))}
                    </Col>
                </Row>
                <Button htmlType="submit" hidden></Button>
            </Form>}
            {(step===1) &&
                <CardContainer> <TeamMemberInvitesTable teamId={teamId || teamCreated} onClose={onClose} onAdd={onAdd}/></CardContainer>
            }
        </StyledDrawer>
    )
}