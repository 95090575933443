import React, {useEffect, useState} from 'react';
import {Bar} from '@ant-design/plots';
import api from "../../../../../global/services/api";
import {
    BuildFieldErrorsDict,
    getMaxValueFromArray
} from '../../../../../global/utils/Utils';
import LoadingFull from '../../../../../global/components/other/LoadingFull';
import Empty from '../../../../../global/components/data_display/Empty';
import {useTranslation} from 'react-i18next';
import ChartLegendWrapper
    from "../../../../../global/components/data_display/ChartWrapper/ChartLegendWrapper";

const DEFAULT_CONFIG_REQUEST = {
    version: 'v1'
}
function BarChart({request, legend_key, groups, expand,onColumnClick}) {
    const {t} = useTranslation();
    const [datas, setDatas] = useState();
const [isLoading, setIsloading] = useState(false)
    useEffect(() => {
        if (request?.url) {
            const fetchData = async () => {
                try {
                     setIsloading(true)
                    const config = {
                        params: request.params,
                        ...DEFAULT_CONFIG_REQUEST,
                        ...request.config
                    }
                    let response;
                    if (request?.body) {
                        response = await api.post(request?.url, request?.body, config);
                    } else {
                        response = await api.get(request?.url, config);
                    }
                    const data = response.data?.reduce((acc, item) => {

                        const values = Object.keys(groups).map(
                            (key_group) => ({
                                [legend_key]: item[legend_key],
                                label: groups[key_group].label,
                                value: item[key_group],
                                color: groups[key_group].color,
                                object: item
                            })
                        )
                        acc.push(...values);
                        return acc;
                    }, []);
                    setDatas(data);
                } catch (error) {
                    BuildFieldErrorsDict(error, null, false);
                }finally {
                    setIsloading(false)
                }
            };
            fetchData();
        }
    }, [JSON.stringify(request)]);


    const colorField = 'color';

    const config = {
        data: datas,
        xField: legend_key,
        yField: 'value',
        colorField: 'value',
        stack: true,
        autoFit: true,
        style: {
            maxWidth: 15,
             fill: (item) => {
                return item.color
            },
             cursor: "pointer",
        },
        axis: {
            y: {
                size: 20,
                labelAutoRotate: [{type: "rotate", optionalAngles: [90]}],
                tickLength: 0,
            },
            x: {
                size: 100,
                labelAutoEllipsis: [{type: "ellipsis", maxLength: 10}],
                labelAlign: "horizontal",
                tickLength: 0,
            }
        },
        scale: {
            y: {
                domain: [0, getMaxValueFromArray(datas, 'value', true)]
            }
        },
        markBackground: {
            style: {
                fill: "transparent"
            }
        },
        tooltip: {
            items: [
                (item) =>({
                    name: t(item.label),
                    value: item["value"],
                    color: item.color,
                }),

            ]
        },
        _legend: {
            colorField,
            rows: 1,
            cols: 3,
           labelFormatter: (label) => {
                return {
                    ...label,
                    text: t(label.text),

                    // color: label.color
                }
            }
        },
        drawerConfig: {
            _legend: {
                rows: 2,
            }
        },
         onReady: ({chart}) => {
            chart.on('interval:click', (event) => {
                onColumnClick?.(event)
            });
        }
    };

 if (isLoading) return <LoadingFull/>

    return (
        <>
            {datas?.length === 0 && <Empty/>}
            {datas?.length >= 1 &&
                <ChartLegendWrapper chart={Bar} config={config}
                                    expand={expand}/>}
        </>
    );
};
export default BarChart;