import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import api from "../services/api";
import axios from "axios";
import {BuildFieldErrorsDict} from "../utils/Utils";
import {message, Upload} from "antd";
import {ToastNotification} from "../components/feedback/ToastNotification";
import {useTranslation} from "react-i18next";
import {ACCEPT_ALL_FORMATS} from "../utils/Constants";


const UPLOAD_STATUS = {
    DONE: 'done',
    ERROR: 'error',
    UPLOADING: 'uploading'
}
const BODY_AWS = {
    AWS_ACCESS_KEY_ID: "AWSAccessKeyId",
    CONTENT_TYPE: "Content-Type",
    KEY: "key",
    POLICY: "policy",
    SIGNATURE: "signature",
    FILE: "file",
}

const URL = 'file_upload/'

export default function useFileUpload({
                                          externalOnChange,
                                          accept = ACCEPT_ALL_FORMATS,
                                          MAX_SIZE = 10,
                                          MAX_TOTAL_SIZE
                                      }) {
    const {t} = useTranslation()
    const [fileList, setFileList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [awsList, setAwsList] = useState([])
    const [isUploading, setUploading] = useState(false);

    async function getCredentials(file) {
        const data = {
            name: file.name,
            is_dir: true,
            module: "program", //todo vim de fora depois
            content_type: file.type
        }
        return (await api.post(URL, data, {version: 'v2'}))?.data;
    }

    async function saveAWS(credentials, file) {
        const URL_AWS = credentials.url

        const formData = new FormData()
        formData.append(BODY_AWS.AWS_ACCESS_KEY_ID, credentials.fields[BODY_AWS.AWS_ACCESS_KEY_ID])
        formData.append(BODY_AWS.CONTENT_TYPE, credentials.fields[BODY_AWS.CONTENT_TYPE])
        formData.append(BODY_AWS.KEY, credentials.fields[BODY_AWS.KEY])
        formData.append(BODY_AWS.POLICY, credentials.fields[BODY_AWS.POLICY])
        formData.append(BODY_AWS.SIGNATURE, credentials.fields[BODY_AWS.SIGNATURE])
        formData.append(BODY_AWS.FILE, file)


        return await axios.create().post(URL_AWS, formData)
    }

    async function customRequest({
                                     file,
                                     onError,
                                     onSuccess,
                                 }) {
        try {
            // TODO: se suportar apenas um arquivo, deletar instância de file upload, antes de atualizar
            setUploading(true);
            const credentialsImage = await getCredentials(file)
            await saveAWS(credentialsImage, file)
            setAwsList([...awsList, {pk: credentialsImage.pk, file: file}])
            onSuccess()
        } catch (error) {
            BuildFieldErrorsDict(error, null, t('Error saving image'));
            onError()
        } finally {
            setUploading(false);
        }
    }



    function onChange(e) {
        setFileList(e.fileList.filter(({status}) => status !== UPLOAD_STATUS.ERROR))
    }

    function onRemove(fileRemoved) {
        setAwsList((awsList) => awsList.filter(({file}) => file.uid !== fileRemoved.uid))
        setFileList((fileList) => fileList.filter((file) => file.uid !== fileRemoved.uid))
    }

    useEffect(() => {
        externalOnChange?.(awsList)
        // console.log(awsList);
    }, [awsList]);


    const beforeUpload = (file) => {

        const validSize = file.size / 1024 / 1024 <= MAX_SIZE;
        if (!accept?.includes(file.type)) {

            ToastNotification(t("Invalid file type(s)"), 'error')
            return Upload.LIST_IGNORE;
        }
        if (!validSize) {
            ToastNotification(t(`File size limit is: ${MAX_SIZE}MB`), 'error')
            return Upload.LIST_IGNORE;
        }
        if (MAX_TOTAL_SIZE) {
            const totalSize = fileList.reduce((acc, value) => (acc + value.size), 0) / 1024 / 1024
            if ((totalSize + validSize) > MAX_TOTAL_SIZE) {
                ToastNotification(`${t("The total file size cannot exceed")} ${MAX_TOTAL_SIZE}MB.`, 'error')
                return Upload.LIST_IGNORE;
            }
        }
        return true;
    }
    return {
        isLoading,
        fileList,
        isUploading,
        beforeUpload,
        onRemove,
        customRequest,
        accept,
        onChange
    }
}