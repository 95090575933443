import React, {useRef, useState} from "react";
import {Row} from "react-bootstrap";
import IconRemove from "../../../../assets/IconsFormComponents/trash.svg";
import {
    ButtonsContent, Checkbox,
    Content, ContentNoPointerEvents, Description, InputContent,
    LabelInput, OpenOptionsButton,
    OptionsContent, RemainingScore, RemoveButton,
    RenderedContent, RenderedContentColumn, StyledInputNumber
} from "../../PhaseFormBuilder/StyledComponents";

import './style.css'
import IconCog from "../../../../assets/IconsFormComponents/cog.svg";
import TextArea from "../../../Form/TextArea";
import styled from "styled-components";
import DragIcon from "../../../../assets/Icon/drag.svg";
import {useTranslation} from "react-i18next";
import {ComponentNameContent, SpanTitle} from "../../PhaseFormBuilder/FormComponents/DefaultComponent";
import {ToastNotification} from "../../../../global/components/feedback/ToastNotification";
import ScoreEvaluationField
    from "../../../../global/components/data_entry/form/EvaluationFormBuild/Fields/ScoreEvaluationField";


const StyleDragImg = styled.img`
  width: 22px;
  margin-right: 2px;
  transform: rotate(90deg);
`

const StyleDragImgDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  //margin-bottom: -15px;
  margin-top: -2px;
  padding: 0;
`

const formatter_decimal_2_places = value => {
    if (!value) return '';
    const regex = new RegExp(`^-?\\d+(?:\\.\\d{0,2})?`);
    const result = value.match(regex);
    return result ? result[0] : '';
  };

function ScoreForm(props) {
    const {t} = useTranslation()
    const {program} = props;
    const scoreInputRef = useRef(null);
    const [showRemaining, setShowRemaining] = useState(false);
    const [openOptions, setOpenOptions] = useState(true);
    const [weight, setWeight] = useState(props.weight);
    const scale = props?.scale || {"min":0,"max":10}
    const elementParentRootScore = React.useRef()

    const closeComponente = React.useCallback(({target}) => {
        if (!elementParentRootScore.current) {
            document.removeEventListener('click', closeComponente)
            return null
        }
        if (!elementParentRootScore.current.contains(target)) setOpenOptions(false)

    }, [])
    // React.useEffect(() => {
    //     if (openOptions) {
    //         document.addEventListener('click', closeComponente)
    //     } else {
    //         document.removeEventListener('click', closeComponente)
    //     }
    // }, [openOptions])

    const changeWeigth = (value) => {
        if (value === '') {
            value = '0.0'
        }
        let newValue = parseFloat(value)
        if (newValue >= 0.0 && newValue <= (parseFloat(props.remaningScore) + parseFloat(weight)).toFixed(2)) {
            setWeight(value)
            props.onchange(props.index, 'weight', newValue)
        } else {
            ToastNotification(t('invalid weight, the sum must equal 100'),'error')
        }
    }

    const checkRemaining = (show) => {
        show !== showRemaining && setShowRemaining(show)
    }
    const handleInputFocus = (event) => {
        event.target.select()
        setOpenOptions(true)
    }
    const handleClickRendered = (event) => {
        setOpenOptions(true)
        setTimeout(() => {
            scoreInputRef.current.focus();
        }, 100);
    }
    const renderOptions = () => {
        return (
            <>
                <TextArea disabled={true} required={props.required}
                          label={'Feedback'}/>

                <OptionsContent>
                    <Checkbox type={"checkbox"}
                              onChange={(e) => props.onchange(props.index, 'required', e.target.checked)}
                              defaultChecked={props.required}/>Required
                </OptionsContent>
            </>

        )
    }

    return (
        <Row ref={elementParentRootScore} style={{
            margin: 0,
            padding: 5,
            width: '100%',
            display: "flex",
            border: '1px solid #ddd',
            borderRadius: '8px',
            background: '#fff',
        }}
            data-cy="draggable-card"
        >
            <StyleDragImgDiv>
                <ComponentNameContent type={props.type} hide={true}/>
                <StyleDragImg src={DragIcon}/>
                <ComponentNameContent type={props.type} hide={false}/>
            </StyleDragImgDiv>

            <Content
                style={{width: '100%', justifyContent: 'space-between',}}>
                <InputContent style={{justifyContent: 'flex-start'}}>
                    {/*<EditableIcon src={IconEdit} alt={t('Editable')}/>*/}
                    <LabelInput style={{width: "calc(100% - 70px)"}}
                                type={'text'} defaultValue={props.label}
                                autoComplete={'off'}
                                onFocus={handleInputFocus}
                                onChange={(e) => props.onchange(props.index, 'label', e.target.value)}
                                data-cy="paragraph-input"/>
                    {!openOptions && <RemainingScore onClick={handleClickRendered}>{t('Weight:')} {weight} </RemainingScore>}
                </InputContent>
                <ButtonsContent>
                    <OpenOptionsButton style={{'visibility': 'hidden'}}
                                       onClick={() => setOpenOptions(!openOptions)}
                                       alt={t('Open Component')}><img src={IconCog}
                                                                      alt={t('Open Component')}/></OpenOptionsButton>
                    <RemoveButton type={"button"}
                                  onClick={(event) => props.remove(event, props.index)}
                                  data-cy="remove-question-button">
                        <img src={IconRemove} alt={t('Remove Component')}/>
                    </RemoveButton>

                </ButtonsContent>
            </Content>
            {/*<RenderedContent show={openOptions}*/}
            {/*                 onClick={handleClickRendered}>*/}
            {/*    <ContentNoPointerEvents>*/}
            {/*        <ScoreEvaluationField min={scale.min} max={scale.max} weight={weight}/>*/}
            {/*    </ContentNoPointerEvents>*/}
            {/*</RenderedContent>*/}
            <RenderedContent show={!openOptions}
                             style={{flexWrap: 'wrap'}}>
                {/*<EditableIcon src={IconEdit} fixed={true} alt={'editable'}/>*/}
                <Description placeholder={t("Description")}
                             autoComplete={'off'}
                             onFocus={(e) => e.target.select()}
                             onChange={(e) => props.onchange(props.index, 'help_text', e.target.value)}
                             defaultValue={props.help_text}
                             data-cy="description-input"/>
                {/*<RenderedContent title={t("Example of how the evaluator will select the grade in this field")}>*/}
                <SpanTitle title={t("Example of how the evaluator will select the grade in this field")}>
                    <ContentNoPointerEvents>
                        <ScoreEvaluationField style={{width:'100%'}} min={scale.min} max={scale.max} weight={weight}/>
                    </ContentNoPointerEvents>
                </SpanTitle>
                {/*</RenderedContent>*/}
                <Content style={{
                    marginTop: '-8px',
                    marginBottom: 4,
                    alignItems: "center",
                    justifyContent: 'flex-end',
                    marginLeft: '-64px',
                }}>
                    {showRemaining &&
                        <RemainingScore
                            onClick={handleClickRendered}>{t('Remaning weight:')} {props.remaningScore} </RemainingScore>}
                </Content>
                {program.evaluation_format === "weighted" && <Content style={{
                    marginTop: `${showRemaining ? '-8px' : '8px'}`,
                    marginBottom: 8,
                    alignItems: "center",
                    justifyContent: 'flex-end',
                    marginLeft: '-64px',
                }}>
                    <label style={{margin: 0}}>{t('Weight of this criterion')}</label>
                    {/*<ScoreInput type={'text'} value={weight}*/}
                    {/*            style={{width: 50}}*/}
                    {/*    // onFocus={(e) => e.target.select()}*/}
                    {/*            ref={scoreInputRef}*/}
                    {/*            onFocus={() => checkRemaining(true)}*/}
                    {/*            onBlur={() => checkRemaining(false)}*/}
                    {/*            onChange={(e) => changeWeigth(e.target.value)}*/}
                    {/*            data-cy="score-input"*/}
                    {/*/>*/}
                    <StyledInputNumber
                        style={{ width: 61}}
                        value={weight}
                        defaultValue="0.0"
                        min="0"
                        max="100"
                        step="0.01"
                        controls={false}
                        onChange={changeWeigth}
                        stringMode
                        formatter={formatter_decimal_2_places}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        data-cy="score-input"
                      />
                </Content>}
            </RenderedContent>
            <OptionsContent flex={'end'}>
                <Checkbox type={'checkbox'}
                          onChange={(e) => props.onchange(props.index, 'show_feedback', e.target.checked)}
                          defaultChecked={props.show_feedback} data-cy="enable-evaluator-comments-checkbox"/> {t("Enable evaluator's comments")}
            </OptionsContent>
            <RenderedContentColumn show={!props.show_feedback}>
                {renderOptions()}
            </RenderedContentColumn>
        </Row>
    )

}

export default ScoreForm