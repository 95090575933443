import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Form from "../../../components/data_entry/form/Form";
import {Col, Form as FormAntd, Row} from "antd";
import InputFormField from "../../../components/data_entry/form/InputFormField";
import Drawer from "../../../components/layout/Drawer";
import {useParams} from "react-router-dom";
import {PlusOutlined} from "@ant-design/icons";
import Button from "../../../components/data_entry/Button";
import {BuildFieldErrorsDict} from "../../../utils/Utils";
import api from "../../../services/api";
import ShortInformation
    from "../../../components/data_display/ShortInformation";
import {
    FlexContainer
} from "../../../components/authentication/StyledComponents";

export default function CreateTopicOffCanvas({
                                                 setOpen,
                                                 handleRefresh,
                                                 program,
                                                 open,
                                                 programId,
                                                 ...props
                                             }) {
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [form] = FormAntd.useForm();
    const {program_id} = useParams()

    if (!programId) {
        programId = program_id
    }

    const onSubmit = async () => {
      
        try {
            setIsLoading(true)
            let data = await form.getFieldsValue()
            data = {
                ...data,
                object_id: programId
            }
            const response = await api.post(`topics`, data);
            form.resetFields()
            setOpen(false)
            handleRefresh()
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Drawer open={open} title={t('New topic')}
                setOpen={setOpen}
                footer={<Row justify={'end'}>
                    <Col><Button icon={<PlusOutlined/>}
                                 onClick={async () => await form.submit()}>
                        {t('Create')}
                    </Button></Col></Row>}
                {...props}
        >
            <Form form={form}
                      onFinish={onSubmit}
                      style={{marginTop: 16}}>
                <Row>
                    <Col xs={24}>
                        <InputFormField form={{
                            label: t('Title'),
                            name: 'title',

                            rules: [{
                                required: true,
                                message: t('Title is required')
                            }]
                        }}/>
                    </Col>

                    <Col xs={24}>
                        <FlexContainer justify={"center"}>
                            <ShortInformation>
                                {t("You are creating a statement that is accessible to everyone in your company.")}
                            </ShortInformation>
                        </FlexContainer>

                    </Col>
                </Row>
            </Form>
        </Drawer>

    );
}

