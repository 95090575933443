import React, {useEffect, useMemo} from "react";
import {Link, Redirect, Switch, useLocation,} from "react-router-dom";

import {
    useLayoutContext
} from "../../modules/project/components/layout/context/LayoutContext";
import {useTranslation} from "react-i18next";
import {ManagerRoute} from "./PrivateRoute";
import {useThemeContext} from "../context/ThemeContext";
import TagsPage from "../pages/tags/pages/tags/TagsPage";


const PATH_ROUTES = {
    TAGS: '/tags',

    // INNOVATIONS_PANEL: '/home/innovation_panel'
}
export default function RoutesTags() {
    const location = useLocation();
    const {t} = useTranslation();
    const {initializeLayout} = useLayoutContext()
    const {themeConfig: {client}} = useThemeContext();


    const RoutesItems = useMemo(() => [
        {
            label: <Link data-cy="general-settings-link-tab"
                to={PATH_ROUTES.TAGS}>{t("Overview")}</Link>,
            key: PATH_ROUTES.TAGS,
            route: <ManagerRoute
                exact
                component={TagsPage}/>

        },

    ], [])

    useEffect(() => {
        initializeLayout({
            navbarMenuItems: RoutesItems
        });
    }, [RoutesItems]);

    return (

        <Switch>
            {RoutesItems.map(({
                                  route, key,
                                  hasPermission
                              }) => (React.cloneElement(route, {
                hasPermission,
                key,
                path: key
            })))}
            <Redirect to={{
                pathname: "/404",
                state: {broken_url: window.location.host + location.pathname}
            }}/>
        </Switch>);
}
