import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "../../../layout/Drawer";
import DrawerTabs from "../../../layout/DrawerTabs";
import LimitedSpace from "../../../responsive/LimitedSpace";
import TruncateText from "../../../responsive/TruncateText";
import LoadingFull from "../../LoadingFull";
import AttachmentChallengeTab from "./components/AttachmentChallengeTab/AttachmentChallengeTab";
import DescriptionChallengeTab from "./components/DescriptionChallengeTab/DescriptionChallengeTab";
import ScheduleChallengeTab from "./components/ScheduleChallengeTab/ScheduleChallengeTab";
import useChallengeDrawerContext, { ChallengeDrawerContextProvider } from "./context/ChallengeDrawerContext";


function _ChallengeDrawer({challengeIndex, open, setOpen, challengePreview}) {
    const {t} = useTranslation()
    const {challenge, isLoading, fetchChallenge, fetchProgram} = useChallengeDrawerContext();

    useEffect(() => {
        if (open && !challengePreview) {
            if (challengeIndex?.program_type === "challenge") {
                fetchChallenge(challengeIndex.id);
            } else {
                fetchProgram(challengeIndex.id);
            }
        }
    }, [challengeIndex, open]);


    const items = [
        {
            label:
                <>{t("Description")}</>,
            key: '1',
            children: <DescriptionChallengeTab challenge={challengePreview || challenge} preview={!!challengePreview} />
        }, {
            label: <>{t("Schedule")} </>,
            key: '2',
            children: <ScheduleChallengeTab challenge={challenge}/>,
            hidden: !!challengePreview
        }, {
            label: t("Attachments"),
            key: '3',
            children: <AttachmentChallengeTab/>,
            hidden: !!challengePreview,
        },
    ]

    const getTitle = () => {
        return (
            <LimitedSpace style={{justifyContent: "space-between"}}
                          flexWrap={false}>
                <LimitedSpace direction="horizontal" flexWrap={false}>
                    <TruncateText>{challenge?.name}</TruncateText>
                </LimitedSpace>
                {/* <TruncateText>
                    {challenge && <EnvironmentLink
                        clientUrl={challenge?.program.client_url}
                        to={`/available-programs/detail/${challenge.program.id}`}>
                        <LimitedSpace
                            style={{fontSize: 14, fontWeight: 400}}>
                            <AppstoreOutlined />{isDesktop && t("Go to program")}
                        </LimitedSpace>
                    </EnvironmentLink>}
                </TruncateText> */}
            </LimitedSpace>
        )
    }

    return (
        <Drawer open={open} setOpen={setOpen} width={1000}
                hideTopBorder={true} title={getTitle()}
                bodyStyle={{
                    position: "relative",
                    padding: 0,
                    background: "#FAFAFA"
                }}
        >
            {isLoading ? <LoadingFull/> : <DrawerTabs defaultActiveKey="1" items={items}/>}
        </Drawer>
    );
}

export default function ChallengeDrawer({challengeIndex, open, setOpen, challengePreview}) {
    return (
        <ChallengeDrawerContextProvider>
            <_ChallengeDrawer challengeIndex={challengeIndex} open={open} setOpen={setOpen} challengePreview={challengePreview}/>
        </ChallengeDrawerContextProvider>
    );
}