import React from 'react'
import styled from 'styled-components'
import NeoCinza from "../assets/img/NeoCinza.svg"
import PoweredByNeo from "../assets/img/PoweredByNeo.svg"

const StyledMainDiv = styled.div`
  width: 100%;
  height: ${({slim}) => slim === true? "50px" : "80px"};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:${({slim}) => slim === true ? "white" : "transparent" } ;
`

const MaxWidthContent = styled.div`
  max-width:1280px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const StyledImg = styled.img`
  height: ${({slim}) => slim == true ? "20px" : "30px"};
  margin:0 16px;
`

export default function Footer({slim}) {

    return (
        <StyledMainDiv slim={slim}>
            <MaxWidthContent>
            <StyledImg slim={slim} src={NeoCinza}/>
            <StyledImg slim={slim} src={PoweredByNeo}/>
            </MaxWidthContent>
        </StyledMainDiv>
    )
}