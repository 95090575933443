import React from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";

import {Col, Empty, Row} from "antd";

import {useProgramContext} from "../../../../ProgramsNavigator";
import useDefaultGetRequest
    from "../../../../../global/hooks/useDefaultGetRequest";
import {RANKING_STATUS} from "../../../../../global/utils/Constants";
import Loading from "../../../../../global/components/feedback/Loading";
import RankingCard
    from "../../../../../global/components/data_display/RankingCard";

const Title = styled.h2`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
`
export default function SectionScheduledRankingProgram() {
    const {t} = useTranslation();
    const {program} = useProgramContext()
    const {
        data: rankings,
        isLoading
    } = useDefaultGetRequest({
        url: `/ranking/program/${program?.id}/get_ranking_object_related/`,
        makeRequest: !!program.id,
        params: {
            status: RANKING_STATUS.SCHEDULED,
            ordering: 'open_at',
            page_size: 1000,
            // environment_rank: false
        },
        dataDefault: []
    })


    return (

        <>

            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Title
                        level={6}>{t('Scheduled rankings')}</Title>
                </Col>
                {isLoading ? (
                    <Col xs={24}><Loading height={'100px'}/></Col>
                ) : rankings?.results?.length > 0 ? rankings?.results?.map((ranking) =>
                    <Col xs={24} key={ranking.id}><RankingCard
                        url={`/ranking/${ranking.id}`}
                        ranking={ranking}/></Col>
                ) : <Col xs={24}><Empty
                    description={t('There are no rankings scheduled at the moment.')}/></Col>}

            </Row>


        </>

    );
}
