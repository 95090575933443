import React, { useState} from "react";
import {useTranslation} from "react-i18next";
import {FlexContainer} from "../authentication/StyledComponents";
import {Col, Row, Typography} from "antd";


import RankingPositionCard from "./RankingPositionCard";
import Pagination from "../navigation/Pagination";
import Loading from "../feedback/Loading";
import Empty from "./Empty";
import styled from "styled-components";
import {DateFormatByUserTimezone} from "../../utils/dates";

const Container = styled.div`
    width: 350px;
    border-radius: 8px;
    //padding: 16px;
    background: white;
    position: sticky;
    top: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 80vh;
    min-height: 30vh;
`



export default function RankingPlacements({
                                              open_at,
                                              close_at, endless,
                                              id,
                                              title,
                                              open,
                                          }) {

    const [isLoadingPositions, setIsLoadingPositions] = useState(false)
    const [positions, setPositions] = useState([])
    const {t} = useTranslation();


    const PaginationRequest = {
        url: `/ranking/${id}/get_ranking_data`,
        setIsLoading: setIsLoadingPositions,
        setData: setPositions,
        makeRequest: !!id,

        dependence: [id]

    }


    return (
        <Container>


            <Row gutter={[16, 16]}>
                <Col xs={24}>

                    <FlexContainer gap={16}
                                   style={{padding: '16px 16px 0px 16px'}}
                                   direction={'column'} align={'start'}>

                        <Typography.Title style={{marginBottom: 0}}
                                          level={4}>{title}</Typography.Title>


                        <Typography.Text
                            type={'secondary'}> {DateFormatByUserTimezone(open_at)} {(!endless) && <>- {DateFormatByUserTimezone(close_at)}</>}</Typography.Text>

                    </FlexContainer>
                </Col>

            </Row>
            {isLoadingPositions ? <Loading/> :
                positions?.length > 0 ?
                    positions?.map((position) =>

                        <RankingPositionCard key={position.id}
                                             position={position}/>) :
                    <Empty
                        description={t('There are no users in the ranking yet')}/>}

                <FlexContainer style={{padding: 16}} justify={'end'}>
                <Pagination request={PaginationRequest}

                            showTotal={(total) => t('total_ranking_users', {total: total})}
                            size="small"
                            hideOnSinglePage
                />
            </FlexContainer>


        </Container>
    )
}
