import {Gantt, ViewMode} from "gantt-task-react";
import {LoadingOutlined, PlusOutlined, RightOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {Card, Empty, Tooltip} from "antd";
import dateFormat from "dateformat";
import {getLanguage} from "../../../../../../global/utils/translation";
import "./index.css";
import { useTranslation } from "react-i18next";

function TaskTooltip({task}) {
    const { t } = useTranslation();
    return <>
        <Card title={task.name} style={{width: 300, height: "100%"}}>
            <div>{t("Type")}: {t(task["form_type"])} </div>
            <div>{t("Duration")}: {task["days_duration"]} {t("days")}</div>
            <div> {(task.type === "task" && !task.isDisabled) && `${t("Progress")}: ${task.progress == null ? 0 : task.progress}%`}</div>
        </Card>
    </>;
}

const TaskTableHeader = ({headerHeight, fontFamily, fontSize}) => {
    const { t } = useTranslation();
    return (<div className="gantt-header" style={{
        height: headerHeight,
        display: "grid",
        gridTemplateColumns: "5fr 3fr 2fr 1fr 1fr",
        alignItems: "center",
        padding: "8px",
        fontSize,
        fontFamily,
    }}>
        <div style={{textAlign: "left"}}>{t("Task")}</div>
        <div style={{textAlign: "left"}}>{t("Start Date")}</div>
        <div style={{textAlign: "left"}}>{t("End Date")}</div>
        <div style={{textAlign: "left"}}></div>
        <div style={{textAlign: "left"}}></div>
    </div>);
};

const TaskTable = ({tasks, rowWidth, rowHeight, handleExpand, handleAddSubtask}) => {
    return (<div style={{width: rowWidth}}>
        {tasks.map((task, id) => {
            const isProject = task.type === "project" && task.has_children;
            return (<div className="gantt-task" style={{
                display: "grid",
                gridTemplateColumns:  "5fr 3fr 2fr 1fr 1fr",
                padding: "8px",
                alignItems: "center",
                width: rowWidth,
                height: rowHeight,
                color: task.progress === 100 ? "#8c8c8c" : "#000",
            }} key={id}>
                <Tooltip title={task.name}>
                    <div style={{
                        textAlign: "left",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textIndent: 16 * task.level,
                        fontWeight: isProject ? "500" : "unset",
                        fontSize: 12,
                        // fontStyle: !isProject ? "italic": "unset",
                    }}>{task.name}</div>
                </Tooltip>
                <div style={{textAlign: "left", fontSize: 12}}>{dateFormat(task.start, "dd/mm/yyyy")}</div>
                <div style={{textAlign: "left", fontSize: 12}}>{dateFormat(task.end, "dd/mm/yyyy")}</div>
                <div style={{display: "flex", justifyContent: "center", color: "#000"}}>
                    {isProject &&
                        <RightOutlined
                            onClick={() => handleExpand(task)}
                            style={{fontSize: 12, rotate: !task.hideChildren ? "90deg" : "0deg"}}/>}
                </div>
                <div style={{display: "flex", justifyContent: "center", color: "#000", fontSize: 12}}>
                    {task.can_add_subtask && <PlusOutlined onClick={() => handleAddSubtask(task)}/>}
                </div>
            </div>);
        })}
    </div>);
};

function ProjectTimeline({tasks, viewMode, viewDate, showTable, handleAddSubtask, handleExpand, handleDelete, onDoubleClick, handleDragging}) {
    const [taskList, setTaskList] = useState();

    const getColumnWidth = () => {
        switch (viewMode) {
            case ViewMode.Month:
                return 300;
            case ViewMode.Week:
                return 100;
            case ViewMode.Year:
                return 300;
            default:
                return 60;
        }
    };


    const renderLoading = () => {
        if (!taskList) return <div style={{textAlign: "center"}}><LoadingOutlined/></div>;
        if (!taskList?.length > 0) return <Empty/>;
    };

    useEffect(() => {
        if (tasks) {
            setTaskList(tasks);
        }
    }, [tasks]);


    return (
        <>
            {renderLoading()}
            {taskList?.length > 0 && 
                <Gantt
                    tasks={tasks}
                    viewMode={viewMode}
                    columnWidth={getColumnWidth()}
                    onDoubleClick={onDoubleClick}
                    onDateChange={handleDragging}
                    onProgressChange={handleDragging}
                    onExpanderClick={handleExpand}
                    onDelete={({id}) => {
                        void handleDelete({id});
                    }}
                    locale={getLanguage()}
                    viewDate={viewDate}
                    TooltipContent={TaskTooltip}
                    todayColor="#FFF2E8"
                    ganttHeight={500}
                    listCellWidth={showTable ? "450px" : ""}
                    TaskListTable={
                        (props) =>
                            <TaskTable
                                {...props}
                                handleExpand={handleExpand}
                                handleAddSubtask={handleAddSubtask}
                                style={{rowHeight: "50px"}}
                            />}
                    TaskListHeader={TaskTableHeader}
                />}
        </>);
}

export default ProjectTimeline;