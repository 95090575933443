import React, {useEffect, useState} from 'react'

// import PDFImage from '../../../assets/img/pdf-img.png'
import PDFImage from '../../../assets/img/files-PNG-Pic.png'
import InputContainer from "../InputContainer";
import Label from "../Label";
import {
    ReactComponent as PlusIcon
} from "../../../assets/Icon/plus-square-regular.svg";
import MessageError from "../MessageError";
import styled from "styled-components";
import FileIcon from "../../../assets/Icon/file-icon.svg";
import FileFile from '../../../assets/Icon/file-svgrepo-com.svg'
import {
    FileValidate,
    limit_file,
    limit_pdf
} from "../../../global/utils/FileValidate";

import LimitUploadInfo from "../../LimitUploadInfo";
import {useTranslation} from "react-i18next";
import {
    ToastNotification
} from "../../../global/components/feedback/ToastNotification";

const StyledImageContainer = styled.label`
  width: 200px;
  height: 200px;
  border-radius: 4px;
  border: 1px dashed #6E6E6E;
  background-color: #F4F4F4;
  position: relative;
  cursor: ${({disabled}) => disabled ? 'default' : 'pointer'};
  transition: 0.5s;
  overflow: hidden;
  ${({disabled}) => !disabled && (
          ` &:hover{
            background-color: #6E6E6E;
            border-color: #fff;
        }
        
        &:hover p{
            color: #fff;
        }
        
        svg path{
            transition:0.5s;
        }
        
        &:hover svg path{
            fill: #fff;
        }`
  )}


`

const StyledImagePreview = styled.img`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  object-fit: cover;
  position: absolute;
  background-color: #F4F4F4;
  top: 0;
  left: 0;
  z-index: 0;
  visibility: ${({value}) => (value && value !== '' && value ? 'visible' : 'hidden')};
`

const StyledLabelInfo = styled.p`
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  text-align: center;
  line-height: 22px;
  color: #6E6E6E;
  width: 160px;
  margin: 20px auto 0;
  transition: 0.5s;
`

const StyledFileContainer = styled.div`
  width: 100%;
  margin: 8px 0 4px 0;
  white-space: nowrap;
  display: flex;
  gap: 4px;
`

const StyledFileIcon = styled.img`
  width: 24px;
  height: 24px;
`


const _typesAccept = [
    'application/pdf'
]


function FileInput(
    {
        elementDescription,
        id,
        required,
        name,
        label,
        messageError,
        onChange,
        value,
        disabled,
        refElementFile,
        file,
        setResetInput,
        resetInput,
        showName,
        typesAccept=_typesAccept,
        ...props
    }) {
    const imagePreview = React.useRef()
    const {t} = useTranslation();
    const [imgPreview, setImgPreview] = useState(
        {
            img: value ? PDFImage : '',
            name: ''
        });

    useEffect(() => {
        if (value && typeof value === 'object') {
            convert_blob_to_base64(value)
        }

    }, [value])

    function convert_blob_to_base64(file) {
        const reader = new FileReader();

        reader.addEventListener("load", function () {
            // convert image file to base64 string
            setImgPreview({img: PDFImage, name: file.name})
            imagePreview.current.style.visibility = 'visible'

        }, false);

        reader.readAsDataURL(file);
    }

    React.useEffect(() => {
        if (resetInput) {
            imagePreview.current.style.visibility = 'hidden'
            setResetInput(false)
            refElementFile.current.value = "";
            //So seta imagem de preview pra hidden, se precisar realmente remover futuramente, deve ser feito aqui
        }
    }, [resetInput])

    function handlePreviewImage({target}) {
        const file = target.files[0];
        if (file) {
            if (!FileValidate(file, 'pdf')) {
                ToastNotification(t("Maximum upload is") + ` ${limit_pdf} MB`, 'warning');
                target.value = ''
                return;
            }


            convert_blob_to_base64(file)
        }
    }

    function checkTypeAccepted({target}) {
        const file = target.files[0];
        return typesAccept.includes(file.type)

    }

    return (
        <InputContainer>
            {label &&
                <Label htmlFor={id} required={required}>{label}
                    <LimitUploadInfo
                        limit={limit_pdf}/></Label>}
            {elementDescription}
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <StyledImageContainer disabled={disabled}>
                    <input ref={refElementFile}
                           data-value={value}
                           disabled={disabled} type={'file'} name={name}
                           style={{display: 'none'}}
                           onChange={(event) => {
                               if (checkTypeAccepted(event)) {
                                   onChange && onChange(event);
                                   handlePreviewImage(event);
                               }else{
                                   ToastNotification(t('Invalid type'), 'error')
                               }
                           }}
                           accept="application/pdf" {...props}
                           data-cy="pdf-file-input"/>
                    <PlusIcon
                        style={{margin: '53px auto 0', display: 'block',}}/>
                    <StyledLabelInfo>{t("Browse a document")}</StyledLabelInfo>
                    <StyledImagePreview ref={imagePreview} value={value}
                                        src={imgPreview.img}/>

                </StyledImageContainer>
                {imgPreview.img && <StyledFileContainer>
                    <StyledFileIcon src={FileFile}/>

                    {imgPreview.name ? <span>{imgPreview.name}</span> :
                        <a href={value} target={'_blank'}>{t("Show file")}</a>}

                </StyledFileContainer>
                }
                {messageError && <MessageError>{messageError}</MessageError>}
            </div>


        </InputContainer>
    )
}

export default FileInput;