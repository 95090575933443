import {useEffect} from "react";
import {useTranslation} from "react-i18next";

import styled from 'styled-components'
import {
    useLayoutContext
} from "../../modules/project/components/layout/context/LayoutContext";
import {Col, Row, Typography} from "antd";
import BlockImage from '../../assets/img/block_image.png'
import WideSpace from "../components/layout/WideSpace";
import {usePermissionContext} from "../context/PermissionContext";
import {useHistory} from "react-router-dom";

export default function FinancialBlockPage() {
    const {t} = useTranslation();
    const {initializeLayout} = useLayoutContext();
    1
    const {checkAmbientHasDebit} = usePermissionContext();
    useEffect(() => {
        if (!checkAmbientHasDebit()) {
            history.push('/')
        }
        initializeLayout({hasActionBar: false});
    }, []);

    return (
        <WideSpace direction={'vertical'} align={'center'} size={24}>
            <img src={BlockImage}/>
            <Typography.Title
                level={3}> {t('Late environmental payment')}</Typography.Title>
            {t('It appears that this environment is not up to date with payments, so the environment is not accessible. Contact your administrator.')}
        </WideSpace>

    );
}
