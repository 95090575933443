import { PlusOutlined } from "@ant-design/icons";
import { Col, Input, Row } from "antd";
import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import DynamicDropdown from "../../components/data_entry/DynamicDropdown";
import { FlexContainer } from "../../components/authentication/StyledComponents";
import Table from "../../components/data_display/Table";
import Button from "../../components/data_entry/Button";
import WideSpace from "../../components/layout/WideSpace";
import { CardContainer } from "../../components/misc/CardContainer";
import ApplicationDrawer from "../../components/other/application/ApplicationDrawer/ApplicationDrawer";
import GrowRadioButton from "../../components/responsive/GrowRadioButton";
import TruncateText from "../../components/responsive/TruncateText";
import ApplicationTagStatus from "../../components/tag/ApplicationTagStatus";
import { usePermissionContext } from "../../context/PermissionContext";
import { useUserContext } from "../../context/UserContext";
import useChallenge from "../../hooks/useChallenge";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import useTable from "../../hooks/useTable";
import { DateFormatByUserTimezone } from "../../utils/dates";

const DEFAULT_BODY_TABLE = {
    ALL_CHALLENGES: '',
    ACTIVE: true
}
const NAMES_FILTES = {
    MY_CHALLENGE: 'team__members__user_id',
    IS_ACTIVE: 'is_active'
}


export default function ListSolutions() {
    const {t} = useTranslation();
    const {_user} = useUserContext();
    const [solutionDrawerOpen, setSolutionDrawerOpen] = useState(false)
    const {isDesktop} = useSystemBreakpoint()
    const [solutionId, setSolutionId] = useState();
    const {handleCreateSolutionAndRedirect} = useChallenge();
    const [search, setSearch] = useState("")

    const {
        canSuggestSolution,
    } = usePermissionContext();

    const [bodyRequest, setBodyRequest] = useState({
        [NAMES_FILTES.IS_ACTIVE]: DEFAULT_BODY_TABLE.ACTIVE,
        [NAMES_FILTES.MY_CHALLENGE]: _user.id
    })


    const {
        tableData, isLoading, tableParams, handleTableChange,
        handleRequestTable, resetTable
    } = useTable({
        useHandleRequestTable: false,
        url: `solutions/by_client`,
        hasPagination: true,
        body: bodyRequest,
        config: {version: "v2"},
        search
    })

    useEffect(() => {
        handleRequestTable()
    }, [bodyRequest, search])

    const openSolutionDrawer = (id) => {
        setSolutionId(id);
        setSolutionDrawerOpen(true);   
    }

    function handleRowClick(record) {
        openSolutionDrawer(record.id);
    }

    const columns = [    
        {
            title: t('Code'), 
            dataIndex: 'code',
            sorter: 'code',
            key: 'code',
            ellipsis: true,
            width: "30px",
            render: (value) => <span data-cy={`solution-code-${value}`}>{value}</span>
        },
        {
            title: t('Title'), dataIndex: 'name',
            sorter: 'name',
            key: 'name',
            render: (value) => <div style={{width: 500}}><TruncateText>{value || "-"}</TruncateText></div>
        }, 
        {
            title: t('Status'),
            dataIndex: 'status_display',
            // sorter: 'is_aproved',
            key: 'status_display',
            ellipsis: true,
            width: '170px',
            render: (value, record) => {
                if (!value) return '-';
                // const statusColor = getApplicationStatusColor(record);
                // return <SolvTag color={statusColor} style={{ textTransform: 'capitalize'}}>{value}</SolvTag>
                return <ApplicationTagStatus application={record} />
            }
        }, 
        {
            title: t('Owner'),
            dataIndex: 'team.name',
            sorter: 'team__name',
            key: 'team',
            ellipsis: true,
            width: '150px',
        },
        {
            title: t('Step'),
            dataIndex: 'current_phase.title',
            sorter: 'current_phase__title',
            key: 'current_phase',
        },
        {
            title: t('Program'),
            dataIndex: 'program',
            sorter: 'program__name',
            key: 'program',
            render: (value) => <div style={{width: 250}}><TruncateText>{value?.name}</TruncateText></div>
        },
        {
            title: t('Registration date'),
            dataIndex: 'date_submited',
            sorter: 'date_submited',
            key: 'date_submited',
            ellipsis: true,
            width: '100px',
            render: (value) => {
                return DateFormatByUserTimezone(value)
            }
        }
    ]

    function handleMyChallenges(value) {

        if (value) {
            setBodyRequest({
                ...bodyRequest,
                [NAMES_FILTES.MY_CHALLENGE]: _user.id
            })
        } else {
            setBodyRequest({[NAMES_FILTES.IS_ACTIVE]: bodyRequest.is_active})
        }
    }

    function handleActive({key}) {
        setBodyRequest({...bodyRequest, [NAMES_FILTES.IS_ACTIVE]: key})
    }

    return (
        <CardContainer>
            <Row gutter={[0, 16]}>
                <WideSpace direction={!isDesktop && "vertical"} style={{justifyContent:"space-between", flexDirection: isDesktop && "row-reverse"}}>
                    <Col xs={24}>
                        <FlexContainer justify={"space-between"} gap={16}>

                            <Input.Search data-cy={"search-solution-input"} onSearch={(value) => setSearch(value)} allowClear/>

                            <DynamicDropdown title="" items={[
                                {label: t("Actives"), key: true},
                                {label: t("Canceled/Archived"), key: false},
                            ]}
                                 handleChange={handleActive}
                            />

                            {canSuggestSolution && <Button
                                icon={<PlusOutlined/>}
                                onClick={handleCreateSolutionAndRedirect}>
                                {t("Suggest Solution")}
                            </Button>}
                        </FlexContainer>
                    </Col>
                    <Col xs={24} data-cy="solution-list-mode-container">
                        <GrowRadioButton
                             onChange={handleMyChallenges}
                             defaultValue={_user.id}
                             block={!isDesktop}
                             options={[
                                 {value: _user.id, label: t('My Solutions')},
                                 {value: '', label: t('All Solutions')},]}
                        />
                    </Col>
                </WideSpace>
                <Col xs={24}>
                    <Table columns={columns} onChange={handleTableChange}
                           tableParams={tableParams}
                           loading={isLoading}
                           noScroll
                        // showSizeChanger={false}
                           onRowClick={handleRowClick}
                           dataSource={tableData}
                           data-cy="solution-table"
                    />
                </Col>
            </Row>
            <ApplicationDrawer solutionId={solutionId} open={solutionDrawerOpen} setOpen={setSolutionDrawerOpen} postUpdate={resetTable}/>
        </CardContainer>
    )
}
