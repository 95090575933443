import React, {useState} from 'react';
import {BulbOutlined} from "@ant-design/icons";
import styled from "styled-components";
import {Button, Flex, Form, Input, Modal, Select, Typography} from "antd";
import {useTranslation} from "react-i18next";
import UserAvatar from "../../../../components/misc/UserAvatar";
import RequestSelect from '../../../../components/data_entry/RequestSelect';
import { useUserContext } from '../../../../context/UserContext';
import api from "../../../../../global/services/api";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useApplication from '../../../../hooks/useApplication';
const {Text} = Typography

const StyledButton = styled(Button)`
    flex: 1;
    justify-content: start;
`

const user = {
    "picture": "https://solv-homolog.s3.amazonaws.com/portfolio/team/img/b886cd43-068b-4bb3-aa4f-104e61460ae1.jpg?noCache=18:56:15",
    "avatar_color": null,
    "name": "Cypress Team"
}

const TempSelect = () => (
    <Select
        defaultValue="lucy"
        style={{
            // width: 120,
            flexGrow: 1
        }}
        options={[
            {
                value: 'jack',
                label: 'Jack',
            },
            {
                value: 'lucy',
                label: 'Lucy',
            },
            {
                value: 'Yiminghe',
                label: 'yiminghe',
            },
            {
                value: 'disabled',
                label: 'Disabled',
                disabled: true,
            },
        ]}
    />
)


function NewIdeaModal({open, setOpen}) {
    const {_user} = useUserContext();
    const {t} = useTranslation();
    const history = useHistory();
    const {handleApplyChallenge} = useApplication();
    const [programId, setProgramId] = useState(null);
    const [title, setTitle] = useState('');

    const handleSubmit = () => {
        const data = {'program_id': programId};
        const extra = {name: title}
        handleApplyChallenge(data, extra);
    }

    return (
        <Modal open={open} onCancel={() => setOpen(false)} closeIcon={false}
               footer={
                   <Flex flex={1}>
                       <Button type="primary" onClick={handleSubmit} style={{flexGrow: 1}}>{t("Continue Form")}</Button>
                   </Flex>
               }
        >
            <Flex vertical={true} gap={16} style={{marginBottom: 32}}>
                <Flex gap={8}>
                    <UserAvatar data={_user}/>
                    <RequestSelect defaultActiveFirstOption={true} value={programId} onChange={setProgramId} url={'v2/programs/feed_program_options'} labelField={'name'} />
                </Flex>
                <Input.TextArea value={title} onChange={(e)=>setTitle(e.target.value)} autoSize={{maxRows: 20}} prefix={<BulbOutlined/>} placeholder={t("Do you have any idea in mind?")}/>
            </Flex>  
        </Modal>
    );
}

function NewIdeaButton(props) {
    const {t} = useTranslation()
    const [open, setOpen] = useState(false)
    return (
        <>
            <StyledButton icon={<BulbOutlined/>} onClick={() => setOpen(true)}>
                <Text type="secondary">{t("Write your idea")}</Text>
            </StyledButton>
            <NewIdeaModal open={open} setOpen={setOpen}/>
        </>
    );
}

export default NewIdeaButton;