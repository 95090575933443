import {Radar} from "@ant-design/plots";
import React, {useEffect, useState} from "react";
import {useUserContext} from "../../../../../../global/context/UserContext";
import api from "../../../../../../global/services/api";
import {useTranslation} from "react-i18next";
import {useThemeContext} from "../../../../../../global/context/ThemeContext";
import {ChartRenderer, truncateChars} from "../../utils/chartUtils";
import {getProjectColor} from "../../../../../../global/utils/Utils";
import ChartLegendWrapper
    from "../../../../../../global/components/data_display/ChartWrapper/ChartLegendWrapper";
import Loading from "../../../../../../global/components/feedback/Loading";


function ProjectRadarChart({filters,
                               parent,
    render=true,//gambiarra
                               extraParams,
                               drawerConfig = {},
                               columnOptions,
                               expand
                           }) {
    const [data, setData] = useState(undefined);
    const [isActive, setIsActive] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const {themeConfig} = useThemeContext();
    const {user_client} = useUserContext();
    const {t} = useTranslation();


    const colorField = "project_name"
    const xField = "name"
    const yField = "stored_value"
    const colorSourceKey = "project_color"

    const axis = {
        x: {
            labelAlign: "horizontal",
            grid: true,
            tickLength: 0,
            style: {
                lineStrokeWidth: 0,
            },
        },
        y: {
            zIndex: 1,
            title: false
        }
    }
    const config = {
        data,
        xField,
        yField,
        colorField,
        autoFit: true,
        animate: null,
        area: {
            style: {
                fillOpacity: 0.04,
                fill: (d) => d[0][colorSourceKey],
            },
        },
        scale: {
            x: {
                padding: 0.5,
                align: 0,
            },
            y: {
                tickCount: 1,
                domainMax: 11,
            }
        },
        axis: {
            ...axis,
            x: {
                labelFormatter: (text) => truncateChars(t(text), 10),
                labelAlign: "horizontal",
                grid: true,
                tickLength: 0,
                style: {
                    lineStrokeWidth: 0,
                },
            }
        },
        style: {
            lineWidth: 2,
            stroke: (d) => d[0][colorSourceKey],
        },
        tooltip: {
            title: (d) => d.name,
            items: [(d) => ({
                name: d[colorField],
                value: d[yField],
                color: getProjectColor(data, d.project),
            })]
        },
        interaction: {
            tooltip: {
                marker: false,
            }
        },
        _legend: {
            colorField,
            colorSourceKey,
            rows: 1,
            cols: 3,
        },
        drawerConfig: {
            axis,
            _legend: {
                rows: 2,
            },
        },
    };

    useEffect(() => {
        if (user_client) {
            const fetchData = async () => {
                try {
                        setIsLoading(true)
                    const response = await api.post(`/analytics/dashboard/project_client_dashboard/${themeConfig.client.id}/project_radar_report`, filters,{params: extraParams});
                    setIsActive(response.data.is_active);
                    setData(response.data.responses?.map((responseIndicator) => ({
                            ...responseIndicator,
                            name: t(responseIndicator.name),
                            stored_value: responseIndicator.stored_value / 10,
                        }),
                    ));
                } catch (exception) {
                }finally {
                setIsLoading(false)
            }
            };
            fetchData();
        }
    }, [user_client, JSON.stringify(extraParams), filters]);

    if(isLoading || !render) return <Loading/>
    return (

        <ChartRenderer
            data={data}
            parent={parent}
            expand={expand}
            chart={<ChartLegendWrapper chart={Radar} config={config}/>}
            isActive={isActive}
            columnOptions={columnOptions}
        />
    );
}

export default ProjectRadarChart;