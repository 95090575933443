import {useTranslation} from "react-i18next";
import {Col, Row} from "antd";
import AssessmentFormStatisticResponseYesOrNo from "./AssessmentFormStatisticResponseYesOrNo";
import AssessmentFormContent from "./AssessmentFormContent";
import React from "react";
import Statistic from "../../../../../../../misc/Statistic";
import styled from "styled-components";


const CustomCard = styled.div`
    //width: 172px;
    border-radius: 2px;
`;

const StatisticCard = ({title, value, useHeadlightColor, max, inverseScale}) => (
    <CustomCard>
        <Statistic title={title} value={value} useHeadlightColor={useHeadlightColor} max={max}
                   inverseScale={inverseScale}/>
    </CustomCard>
);

export default function AssessmentDetailedView({form, solutionResult, viewerMode = false}) {
    const {t} = useTranslation();
    return (
        <>
            <Row gutter={[8, 8]} style={{paddingBottom: 16}}>
                {viewerMode === false ?
                    <>
                        <Col xs={12} md={6}>
                            <StatisticCard title={t("Score")} value={solutionResult.score_average}
                                           useHeadlightColor={true}/>
                        </Col>
                        <Col xs={12} md={6}>
                            <StatisticCard title={t("Max")} value={solutionResult.score_max} useHeadlightColor={true}/>
                        </Col>
                        <Col xs={12} md={6}>
                            <StatisticCard title={t("Min")} value={solutionResult.score_min} useHeadlightColor={true}/>
                        </Col>
                        <Col xs={12} md={6}>
                            <StatisticCard title={t("Std. Deviation")} value={solutionResult.standard_deviation}
                                           useHeadlightColor={true} max={10} inverseScale={true}/>
                        </Col>
                    </> :
                    <Col xs={24}>
                        <StatisticCard title={t("Score")} value={solutionResult.score_average}
                                       useHeadlightColor={true}/>
                    </Col>
                }
                {/*<StatisticCard title={t("Avg. Notas NP")} value={solutionResult.score_unweighted} useHeadlightColor={false}/>*/}
            </Row>
            <Row gutter={[8, 24]}>
                {form.comp_yes_no_questions &&
                    <Col span={24}>
                        <AssessmentFormStatisticResponseYesOrNo comp_yes_no_questions={form.comp_yes_no_questions}
                                                                viewerMode={viewerMode}/>
                    </Col>}
                <Col span={24}>
                    <AssessmentFormContent comp_score={form.comp_score} viewerMode={viewerMode}/>
                </Col>
            </Row>
        </>
    )
}