import {Column} from "@ant-design/plots";
import React, {useEffect, useState} from "react";
import api from "../../../../../../global/services/api";
import {useTranslation} from "react-i18next";
import {ChartRenderer, truncateChars} from "../../utils/chartUtils";
import {useThemeContext} from "../../../../../../global/context/ThemeContext";
import {formatNumber} from "../../../../utils/utils";
import {getMaxValueFromArray} from "../../../../../../global/utils/Utils";
import ChartLegendWrapper
    from "../../../../../../global/components/data_display/ChartWrapper/ChartLegendWrapper";
import ChartTotalDisplay
    from "../../../../../../global/components/data_display/ChartTotalDisplay";
import useProjectChart from "../../hooks/useProjectChart";
import Loading from "../../../../../../global/components/feedback/Loading";

export default function TotalInvestmentMadeChart({
                                                     filters,
                                                     parent,
                                                     extraParams,
                                                     columnOptions,
                                                     expand
                                                 }) {
    const [data, setData] = useState(undefined);
    const [total, setTotal] = useState(0);
    const [isActive, setIsActive] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const {handleChartClickProjectRedirect} = useProjectChart();
    const {themeConfig} = useThemeContext();
    const {t} = useTranslation();


    const xField = "project_name"
    const yField = "stored_value"
    const colorField = "project_name"
    const colorSourceKey = "project_color"

    const axis = {
        x: {
            labelAlign: "horizontal",
            // size: 5,
            tickLength: 0,
        },
        y: {
            grid: true,
            gridLineWidth: 1,
            tickLength: 0,
            labelFormatter: (d) => `R$ ${formatNumber(d)}`,
        }
    }

    const config = {
        data,
        xField,
        yField,
        colorField,
        autoFit: true,
        animate: null,
        axis: {
            ...axis,
            x: {
                ...axis.x,
                labelFormatter: (text) => truncateChars(t(text), 5),
                tickLength: 0,
            },
        },
        style: {
            fill: (d) => d.project_color,
            cursor: "pointer",
        },
        tooltip: {
            title: ({name}) => t(name),
            items: [(d) => ({
                name: t(d.project_name),
                value: `R$ ${formatNumber(d.stored_value)}`,
                color: d.project_color || "#000",
            })
            ],
        },
        scale: {
            y: {
                domain: [0, getMaxValueFromArray(data, yField, true, 10)]
            }
        },
        markBackground: {
            style: {
                fill: "transparent"
            }
        },
        _legend: {
            colorField,
            colorSourceKey,
            rows: 1,
            cols: 3,
        },
        drawerConfig: {
            axis: {
                ...axis,
                x: {
                    ...axis.x,
                    labelFormatter: (text) => truncateChars(text, 20),

                }
            },
            _legend: {
                rows: 2,
            },
        },
        onReady: ({chart}) => {
            chart.on('interval:click', (event) => {
                handleChartClickProjectRedirect({event});
            });
        }
    };

    useEffect(() => {

        const fetchData = async () => {
            try {
                setIsLoading(true)
                const response = await api.post(`/analytics/dashboard/project_client_dashboard/${themeConfig.client.id}/total_investment_made_report`, filters, {params: extraParams});
                setData(response.data.responses);
                setTotal(response.data.total);
                setIsActive(response.data.is_active);
            } catch (exception) {
            } finally {
                setIsLoading(false)
            }
        };
        fetchData();

    }, [JSON.stringify(extraParams), filters]);


    if (isLoading) return <Loading/>
    return (
        <ChartRenderer
            data={data}
            isActive={isActive}
            chart={
                <ChartLegendWrapper
                    chart={Column}
                    config={config}
                    extra={<ChartTotalDisplay description="Accomplished Total"
                                              total={total}/>}
                    expand={expand}
                />
            }
            parent={parent}
            columnOptions={columnOptions}
        />
    );
};
