import React from 'react';
import {Space, Typography} from 'antd';
import {FileOutlined} from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import styled from 'styled-components';
import {blue_3, blue_6} from "../../../../../utils/Colors";

const StyledFileOutLine = styled(FileOutlined)`
    font-size: 79px;
`

const Link = styled.span`
    font-size: 14px;
    color: ${blue_6};
    
    
`

export default function FileQuestionAnswer({
                                               value,
                                               ...props
                                           }) {
    const {t} = useTranslation()


    return (
        <a target={'_blank'} href={value}>
            <Space size={16}>
                <StyledFileOutLine/>
                <Link level={5}>
                    {t('Click here to see the file')}
                    {/*{getNameByURL()}*/}
                </Link>
            </Space>

        </a>
    )
}
