import IndicatorCheckpointCard, {IndicatorInput, ValueComponent} from "./IndicatorCheckpointCard";
import {InputNumber, Space} from "antd";
import {useStatusReportContext} from "../../context/StatusReportContext";
import {EditTwoTone} from "@ant-design/icons";
import { useState} from "react";
import {getLanguage} from "../../../../../../global/utils/translation";
import IndicatorHistory from "./IndicatorHistory";
import {formatNumber} from "../../../../utils/utils";
import { useTranslation } from "react-i18next";

function IndicatorDecimalCheckpointCard({indicatorResponse, ...props}) {
    const { t } = useTranslation();
    const [isEditing, setIsEditing] = useState(false);
    const {indicator} = indicatorResponse;
    const {updateIndicatorResponse} = useStatusReportContext();
    const decimalSeparator = getLanguage() === "pt-br" ? "," : ".";
    const handleValueChange = (value) => {
        updateIndicatorResponse({
            id: indicatorResponse["id"],
            values: {"stored_value": value}
        });
    }

    return <>
        <IndicatorCheckpointCard indicatorResponse={indicatorResponse} {...props}>
            {isEditing ?
                <IndicatorInput
                    input={InputNumber}
                    stringMode={true}
                    decimalSeparator={decimalSeparator}
                    value={indicatorResponse["stored_value"]}
                    prefix={t(indicator["indicator_view"]["field_prefix"])}
                    onChange={handleValueChange}
                    onFinishEditing={()=>setIsEditing(false)}
                />
                :
                <Space onClick={() => setIsEditing(true)} style={{cursor: "text"}}>
                    <ValueComponent value={formatNumber(indicatorResponse["stored_value"])} prefix={t(indicator["indicator_view"]["field_prefix"])}/> <EditTwoTone type="primary"/>
                </Space>
            }
            <IndicatorHistory indicatorResponse={indicatorResponse} />
        </IndicatorCheckpointCard>
    </>
}

export default IndicatorDecimalCheckpointCard;