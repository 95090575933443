import styled from "styled-components";
import Modal from "react-bootstrap/Modal";

export const LongCardContainer = styled.div`
  background-color: #F8F8F8;
  width: 100%;
  border-radius: 8px;
  padding: 10px 40px 10px 10px;
  display: flex;
  position: relative;
  gap: 10px;
  cursor: pointer;

  margin-bottom: 10px;
    ${({disabled}) => {
            if (disabled){
                return `
                opacity: 0.6;
                `
            }
        }}
  &:hover {
    box-shadow: 0px 0px 0px 1px #DDDDDD;
    ${({disabled}) => {
        if (disabled){
            return `
            box-shadow: none; 
            cursor: not-allowed;
           
            `
        }
    }}
  }
`

export const CustomTitleModal = styled(Modal.Title)`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  line-height: 25px;
  max-height: 64px;
`