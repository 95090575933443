import React from 'react';
import styled from "styled-components";
import {Space} from "antd";

const StyledSpace = styled(Space)` 
    width: 100% !important;
  
    .ant-space-item:has(> .ant-form-item-hidden) {
        display: none;
    }
`

export default function WideSpace({children, ...props}) {
    return (
        <StyledSpace {...props}>
            {children}
        </StyledSpace>
    );
}