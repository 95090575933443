import React, {useEffect} from 'react';
import Drawer from "../../../components/layout/Drawer";
import Form from "../../../components/data_entry/form/Form";
import {Button, Flex, Form as AntForm} from "antd";
import {useTranslation} from "react-i18next";


export default function FilterSettingsDrawer({open, setOpen, handleUpdateFilter, filterForm, name, extraParams}) {

    const {t} = useTranslation()
    const [form] = AntForm.useForm()

    const handleSubmitForm = async () => {
        form.submit()
        setOpen(false)
    }

    return (
        <Drawer
            open={open} setOpen={setOpen}
            title={t("Filter Settings")} destroyOnClose={true}
            footer={
                <Flex justify="end" gap={8}>
                    <Button onClick={() => form.resetFields()}>{t("Clear Filters")}</Button>
                    <Button type="primary" onClick={handleSubmitForm}>{t("Apply filters")}</Button>
                </Flex>
            }
        >
            <Form form={form} onFinish={handleUpdateFilter}>
                {filterForm}
            </Form>
        </Drawer>
    );
}

