import React from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { ClockCircleOutlined, FileOutlined } from '@ant-design/icons';
import { lime_6, volcano_7 } from '../../../../../../global/utils/Colors';
import useDefaultGetRequest from '../../../../../../global/hooks/useDefaultGetRequest';
import MetricsCard
    from "../../../../../../global/components/data_display/MetricsCard";

function DisplayAssessements() {
    const {t} = useTranslation()
    let {phase_id} = useParams()

    const {
        data: evaluatorsData,
        isLoading: isLoadingEvaluatorsData
    } = useDefaultGetRequest({
        url: `evaluator_assessments/phase/${phase_id}/count_status_by_assessments`,
        config: {version: 'v2'},
        dependence: [phase_id]
    })

    return (
    <Row gutter={[16, 16]} style={{paddingBottom: 16}}>
        <Col xs={24} sm={12} lg={12}>
            <MetricsCard
                color={lime_6}
                loading={isLoadingEvaluatorsData}
                icon={<FileOutlined/>}
                name={t('Completed evaluations')}
                value={evaluatorsData?.done_evaluations}
            />
        </Col>
        <Col xs={24} sm={12} lg={12}>
            <MetricsCard
                color={volcano_7}
                loading={isLoadingEvaluatorsData}
                icon={<ClockCircleOutlined/>}
                name={t('Pending evaluations')}
                value={evaluatorsData?.pending_evaluations}
            />
        </Col>
    </Row>
    );
}

export default DisplayAssessements;