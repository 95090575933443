import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import Button from "../../../../../components/data_entry/Button";
import Drawer from "../../../../../components/layout/Drawer";


import {Badge, Col, Row} from "antd";
import {useThemeContext} from "../../../../../context/ThemeContext";
import DateRangePicker
    from "../../../../../components/data_entry/DateRangePicker";
import useDefaultGetRequest from "../../../../../hooks/useDefaultGetRequest";
import useSystemBreakpoint from "../../../../../hooks/useSystemBreakpoint";
import Loading from "../../../../../components/feedback/Loading";
import TransactionGamificationCard
    from "../../../../../components/data_display/TransactionGamificationCard";
import Empty from "../../../../../components/data_display/Empty";
import Pagination from "../../../../../components/navigation/Pagination";
import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";


const QUERY_PARAMS_NAMES = {
    START_DATE: 'start',
    END_DATE: 'end',
    USER_CLIENT: 'user_client',
}


export default function UserHistorypointsButton({userId, open, setOpen}) {
    const {t} = useTranslation();
    const [dates, setDates] = useState([])
    const [actions, setActions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const {isPhone} = useSystemBreakpoint()
    const {themeConfig: {client}} = useThemeContext();

    function onClose() {
        setOpen(false)
        setDates([])
    }

    const PaginationRequest = {
        url: `action`,
        setIsLoading: setIsLoading,
        setData: setActions,
        makeRequest: open,
        extraParams: {
            [QUERY_PARAMS_NAMES.START_DATE]: dates[0],
            [QUERY_PARAMS_NAMES.END_DATE]: dates[1],
            [QUERY_PARAMS_NAMES.USER_CLIENT]: userId,


        },
        dependence: [...dates, open]
    }

    return (


        <Drawer width={512} isGrayBody title={t('See history')}
                open={open}
                onClose={onClose}>


            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <DateRangePicker value={dates} style={{width: '100%'}}
                                     onChange={(date) => setDates(date)}/>
                </Col>
                {isLoading ? (<Col xs={24}><Loading/></Col>) :
                    (
                        actions?.length > 0 ? actions?.map((action) =>
                                <TransactionGamificationCard dropInfo
                                                             key={action.id} {...action}/>) :
                            <Col xs={24}><Empty/></Col>
                    )}
                <Col xs={24}>
                    <FlexContainer justify={'end'}>
                        <Pagination request={PaginationRequest}

                            // showTotal={(total) => t('total_ranking_users', {total: total})}
                                    size="small"
                                    hideOnSinglePage
                        />
                    </FlexContainer>
                </Col>
            </Row>
        </Drawer>


    )
}
