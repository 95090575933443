
import React from "react";
import {useTranslation} from "react-i18next";

import {
    ReactComponent as PlusGamificationIcon
} from "../../../../../../assets/Icon/amount_plus_gamification.svg"
import {
    ReactComponent as MinusGamificationIcon
} from "../../../../../../assets/Icon/amount_minus_gamification.svg"
import useDefaultGetRequest from "../../../../../hooks/useDefaultGetRequest";

import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import styled from "styled-components";
import AmountCardGamification
    from "../../../../../components/data_display/AmountCardGamification";
import RemixIcon from "../../../../../components/data_display/RemixIcon";
import SquaresLoading from "../../../../../components/feedback/SquaresLoading";

const ListAmount = styled(FlexContainer)`

    overflow-y: scroll;
    width: 100%;
`
export default function SectionAmountPoints({refreshCounts}) {
    const {t} = useTranslation();
   
    const {
        data: counters,
        isLoading
    } = useDefaultGetRequest({
        url: '/action/counters',
        dependence:[refreshCounts]
    })


    return (
        <>
            {isLoading  ? <SquaresLoading /> :
                <>  <ListAmount gap={16}>

                    <AmountCardGamification width={'238px'}
                                            value={counters?.all_valid_points}
                                            title={t('Total valid points')}
                                            color={"#6A78BE"}
                                            icon={<RemixIcon
                                                remixIconName={'ri-copper-coin-line'}/>}/>
                    <AmountCardGamification width={'238px'}
                                            title={t('Pending points')}
                                            color={"#BAA775"}
                                            value={counters?.pending_points}
                                            icon={<RemixIcon
                                                remixIconName={'ri-copper-coin-line'}/>}/>
                    <AmountCardGamification width={'238px'}
                                            title={t('Points awarded (last 7 days)')}
                                            color={"#6CBE6A"}
                                            value={counters?.valid_points_last_7_days}
                                            icon={<PlusGamificationIcon/>}/>
                    <AmountCardGamification width={'238px'}
                                            title={t('Points spent (last 7 days)')}
                                            color={"#DD7373"}
                                            value={counters?.spent_points_last_7_days}
                                            icon={<MinusGamificationIcon/>}/>
                    <AmountCardGamification width={'238px'}
                                            title={t('Total points spent')}
                                            color={"#DD7373"}
                                            value={counters?.all_spent_points}
                                            icon={<RemixIcon
                                                remixIconName={'ri-copper-coin-line'}/>}/>


                </ListAmount>
                </>
            }
        </>
    );
}
