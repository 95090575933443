import styled from 'styled-components';
import {useHistory, Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React from "react";
import {useLayoutContext} from "../../../modules/project/components/layout/context/LayoutContext";

import {AliwangwangOutlined, ArrowUpOutlined} from '@ant-design/icons';
import { StyledMainMenu } from './MenuBody';
import {Badge, Menu as MenuAnt} from "antd";
import RemixIcon from '../data_display/RemixIcon';
import { useUserContext } from '../../context/UserContext';



const CustomButton = styled.div`
    background: linear-gradient(95.51deg, #AD2102 9.18%, #FA541C 100%);
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: ${({collapsed}) => collapsed? 'center':'start'};
    gap: 10px;
    font-size: 16px;
    font-weight: 700;
    color: white;
    cursor: pointer;
`

export default function MenuFooter({show_assing_button, defaultSelectedKeys}) {

    const history = useHistory();
    const {t} = useTranslation();
    const {collapsed} = useLayoutContext();

    const {summaryCounts} = useUserContext();

    const items = [
        {
            key: 'updates',
            name: <>{t('Updates')} <Badge count={summaryCounts.unseen_releases} /></>,
            link: '/updates',
            icon: <RemixIcon remixIconName={"ri-newspaper-line"}/>,
        },
        {
            key: 'support',
            name: t('Support'),
            link: '/support',
            icon: <AliwangwangOutlined />,
        },
    ];

    return (
        <>
            <div style={{flexGrow: 1, display:"flex", alignItems:"end", color:"white"}}>
                <StyledMainMenu selectedKeys={defaultSelectedKeys}>
                    {items.map(({key, name, link, icon}) => (
                        <MenuAnt.Item key={key} icon={icon} style={{display:"inline-flex"}}><Link to={link}>{name}</Link></MenuAnt.Item>
                    ))}
                </StyledMainMenu>
            </div>

            {show_assing_button &&
                <CustomButton
                    collapsed={collapsed}
                    onClick={() => history.push("/environment/financial")}>

                    <ArrowUpOutlined/>{!collapsed && t('Subscribe to solv')}
                </CustomButton>}
        </>

    );
}

