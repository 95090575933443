import React from 'react'
import styled from 'styled-components'
import CircleImage from "./CircleImage";
import BannerImg from "../assets/img/1024x240.png"

const StyledHeaderChallengeContainer = styled.div`
  background-color: #F8F8F8;
  width: 100%;
  min-height: ${(props) => (props.type==='Individual' ? 0 : 250)}px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  padding-top: 36px;

`

const StyledBannerContainer = styled.div`
  min-width: 100%;
  max-height: 240px;
  overflow: hidden;
  border-radius: 8px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
`

const StyledContentContainer = styled.div`
  display: flex;
  padding: 10px;
  column-gap: 10px;

`

const StyledLogoContainer = styled.div`
  width: 130px;
  height: 130px;
  margin: ${(props) => (props.type==='Individual' ? 0 : -30)}px 0 0 0;
  flex-shrink: 0;
`

const StyledTextContainer = styled.div`
  flex-grow: 1;
  position: relative;

`

export default function HeaderFormInfo({team, hasBanner=true, banner, profile, children}) {

    return (
        <StyledHeaderChallengeContainer type={team?.type}>

            <StyledContentContainer>
                <StyledLogoContainer type={team?.type}>
                    <CircleImage program={true} width={'100%'} height={'100%'} src={profile}/>
                </StyledLogoContainer>
                <StyledTextContainer>
                    {children}
                </StyledTextContainer>
            </StyledContentContainer>
        </StyledHeaderChallengeContainer>
    )
}