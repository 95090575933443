import React, {useEffect, useMemo, useState} from "react";
import api from "../../../global/services/api";
import * as ConstantsMessage from "../../../global/utils/fileWithMessage";
import {
    BuildFieldErrorsDict,
    generateOptionsField
} from "../../../global/utils/Utils";
import {useTranslation} from "react-i18next";
import {
    ToastNotification
} from "../../../global/components/feedback/ToastNotification";
import {
    FlexContainer
} from "../../../global/components/authentication/StyledComponents";
import {Alert, Modal, Space, Switch, Tag, Typography} from "antd";
import Loading from "../../../global/components/feedback/Loading";
import {Row, Col, Form as FormAnt} from 'antd'
import Form from "../../../global/components/data_entry/form/Form";
import Button from "../../../global/components/data_entry/Button";
import SectionEmail from "./components/SectionEmail";
import SectionEnvironmentGeneral from "./components/SectionEnvironmentGeneral";
import SectionEnvironmentIA from "./components/SectionEnvironmentIA";
import TransparentCollapse
    from "../../../global/components/misc/TransparentCollapse";
import CollapseFormItem
    from "../../../global/components/data_entry/CollapseFormItem";
import {
    useLayoutContext
} from "../../../modules/project/components/layout/context/LayoutContext";


const FORM_NAMES = {
    NAME: 'name',
    SUPPORT_EMAIL: 'support_mail',
    CLIENT_DOMAINS: 'client_domains',
    CLIENT_DOMAINS_LIST: 'client_domains_list',
    IS_CACHE_BROWSER_LOGIN: 'is_cache_browser_login',
    IS_CONFIDENTIAL_MODE: 'is_confidential_mode',
    CHAT_IA_ACTIVED: 'chat_ia_actived',
    GAMIFICATION_ACTIVED: 'gamification_actived'

}


export default function PageEnvironmentGeneral() {
    const [isLoading, setIsLoading] = useState(false);
    const {t} = useTranslation();
    const [client, setClient] = useState(null);
    const [form] = FormAnt.useForm()
    const {updateWindown} = useLayoutContext()
    useEffect(() => {
        const fetchClient = async () => {
            try {
                const response = await api.get(`clients/client_request`);
                setClient(response.data);
            } catch (error) {
                BuildFieldErrorsDict(error, null, false);
            }
        }
        fetchClient()
    }, []);

    async function handleSubmit() {
        try {
            setIsLoading(true);
            const data = await form.getFieldsValue()
            data[FORM_NAMES.CLIENT_DOMAINS_LIST] = data[FORM_NAMES.CLIENT_DOMAINS].map((value) => {
                if (value.includes('@')) {
                    return value.split('@')[1]
                }
                return value
            })
            data['theme'] = "não usa mais"
            await api.put(`/clients/${client.id}`, data);
            ToastNotification(t(ConstantsMessage.SUCCESS));
            updateWindown()
        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, null);
        } finally {
            setIsLoading(false);
        }
    }


    const initialValues = useMemo(() => {
        if (client) {
            return {
                ...client,
                [FORM_NAMES.CLIENT_DOMAINS]: generateOptionsField(client[FORM_NAMES.CLIENT_DOMAINS], 'domain', 'domain').map((item) => (`${item.label}`))
            }
        }
        return null
    }, [client])

    return (
        <Row gutter={[24, 24]}>

            {!client ?
                <Col xs={24}><Loading/></Col> :
                <>
                    <Col xs={24}>
                        <Form disabled={isLoading}
                              initialValues={initialValues}
                              form={form}
                              onFinish={handleSubmit}>
                            <Row gutter={[24, 24]}>
                                <Col xs={24}>
                                    <SectionEnvironmentGeneral form={form}
                                                               FORM_NAME={FORM_NAMES}/>
                                </Col>
                                <Col xs={24}>
                                    <SectionEnvironmentIA form={form}
                                                          FORM_NAMES={FORM_NAMES}/>
                                </Col>
                                <Col xs={24}>

                                    <TransparentCollapse
                                        header={t("Gamification")}>
                                        <CollapseFormItem
                                            input={<Switch/>}
                                            extraFormItemProps={{valuePropName: 'checked'}}
                                            name={FORM_NAMES.GAMIFICATION_ACTIVED}
                                            label={t('Enable gamification in the environment')}/>
                                    </TransparentCollapse>

                                </Col>
                                <Col xs={24}>
                                    <FlexContainer justify={'end'}>
                                        <Button htmlType={'submit'}
                                                loading={isLoading}>{t("Save")}</Button>
                                    </FlexContainer>

                                </Col>

                            </Row>
                        </Form>
                    </Col>


                    <Col xs={24}>
                        <SectionEmail/>
                    </Col>
                </>
            }
        </Row>
    );
}
