import React from 'react';
import {Alert as AntAlert} from "antd";
import styled from 'styled-components';

const StyledAlert = styled(AntAlert)`
    background-color: ${props => props.type === "default" && "white"};
    border: ${props => props.type === "default" && "white"};
    min-height: 54px;
`

export default function Alert({type="default", ...props}) {
    return (
        <StyledAlert type={type} {...props}/>
    )
}
