import { useState } from "react";
import api from "../../../../services/api";


export default function useTeamSelector() {
    const [teams, setTeams] = useState(undefined);

    const fetchUserTeams = async () => {
        try {
            const response = await api.get(`/teams`);
            const orderedTeams = response.data.sort((a, b) => {
                if (a.type == "Individual" && b.type != "Individual") return -1;
                if (a.type != "Individual" && b.type == "Individual") return 1;
                return 0;
            });
            setTeams(orderedTeams);
        } catch (error) {}
    }

    return {teams, setTeams, fetchUserTeams}
}