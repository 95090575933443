import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


export default function useProjectChart(){
    const history = useHistory();

    const handleChartClickProjectRedirect = ({event, fieldName = 'project'}) => {
        const id = event.data.data[fieldName]
        history.push(`/project-portfolio/project/${id}/dashboard`);
    }

    return {
        handleChartClickProjectRedirect
    }


}