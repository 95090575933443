import styled from "styled-components";
import { buildStyles, CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Space, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const { Text } = Typography;

const StyledCircularProgressbar = styled(CircularProgressbarWithChildren)`
  object-fit: contain !important;
  position: absolute;

  text {
    fill: ${({ color }) => color} !important;
    font-weight: 700 !important;
    font-size: 24px;
    border: 1px solid black;
  }
`;

const ProgressValue = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: grid;
  place-content: center;
  text-align: center;

  * {
    color: ${({ color }) => color};
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  height: 300px;
  position: relative;

  div[data-test-id="CircularProgressbarWithChildren"] {
    height: 100% !important;
    width: 100% !important;

  }

  .CircularProgressbar {
    height: inherit !important;
    width: inherit;
  }
`;

function ProgressValueComponent({ data, indicator }) {
    const { t } = useTranslation();
    return (
        <Space direction="vertical" size={0}>
            <Text style={{ fontSize: 24, fontWeight: 500 }}>{data["value_display"]}%</Text>
            <Text style={{ fontSize: 12 }}>{t(indicator["indicator_dashboard_view"]["field_prefix"])}</Text>
        </Space>
    );
}

function ExpectedTimeComponent({ data }) {
    const { t } = useTranslation();
    return <Space direction="vertical">
        {!data["is_reached"] && (data["is_late"] ? <Text>{t("Delay of")}</Text> : <Text>{t("Forecast:")}</Text>)}
        <Text>{data["is_reached"] ? t("Completed") : data["natural_date"]}</Text>
    </Space>;
}

function ProgressChart({ children, value, color }) {
    const styles = buildStyles({
        trailColor: "#F5F5F5",
        pathColor: color,
    });

    return <StyledContainer>
        <StyledCircularProgressbar value={value} strokeWidth={5} styles={styles} />
        <ProgressValue color={color}>
            <div style={{ color: color }}>{children}</div>
        </ProgressValue>
    </StyledContainer>;
}


export function ProgressChartBuilder({ indicator, data, color }) {

    if (indicator["internal_code"] === "06") {
        return (<ProgressChart value={data["completed_progress"]} color={data["is_late"] ? "#FF7875" : "#9254DE"}>
            <ExpectedTimeComponent data={data} indicator={indicator} />
        </ProgressChart>);
    }

    const getColor = () => {
        if (color) {
            return color;
        }
        if (data["stored_value"] > 100){
            return "#FF7875";
        }
        if (indicator["levels_colorized"]) {
            return "#52C41A";
        }
        return "#597EF7";
    }

    return (
        <ProgressChart value={data["stored_value"]} color={getColor()}>
            <ProgressValueComponent data={data} indicator={indicator} />
        </ProgressChart>
    );
}

export default ProgressChart;