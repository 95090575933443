import React, { useState} from "react";
import NavItemLink from "../../../components/NavItemLink/NavItemLink";
import {useTranslation} from "react-i18next";
import Drawer from "../layout/Drawer";
import {Col, Row} from "antd";
import {DatetimeFormatByTimezone} from "../../utils/dates";
import Empty from "./Empty";
import CardHistory from "./CardHistory";

export default function HistoricPhaseButton({phase}) {
    const [open, setOpen] = useState(false);
    const {t} = useTranslation();
    const handleTranslateHistoricLog = (log) => {
        let logMessage = "";
        let hasStartDateMessage = log.start_date_before !== log.start_date_after
        let hasEndDateMessage = log.due_date_after !== log.due_date_before
        if (hasStartDateMessage) {
            logMessage += t("The initial date has been changed from.", {
                dateBefore: DatetimeFormatByTimezone(log.start_date_before, false, t('dd/mm/yyyy --:--')),
                dateAfter: DatetimeFormatByTimezone(log.start_date_after, false, t('dd/mm/yyyy --:--')),
                interpolation: {escapeValue: false},
            });

        }

        if (hasEndDateMessage) {
            logMessage += hasStartDateMessage ? "\n" : '';
            logMessage += t("The due date has been changed from.", {
                dateBefore: DatetimeFormatByTimezone(log.due_date_before, false, t('dd/mm/yyyy --:--')),
                dateAfter: DatetimeFormatByTimezone(log.due_date_after, false, t('dd/mm/yyyy --:--')),
                interpolation: {escapeValue: false},
            });
        }

        return logMessage;
    };

    if (!phase) return null
    return (
        <>
            <NavItemLink
                to="#"
                onClick={(e) => setOpen(true)}
            >
                {t("History")}
            </NavItemLink>


            <Drawer
                title={t("History")}
                open={open}
                onClose={() => setOpen(false)}
            >
                <Row>
                    {phase.phase_history?.length > 0 ? (
                        phase.phase_history.map((historicLog, index) => (
                            <Col xs={24}>
                                <CardHistory key={index}
                                             date={historicLog.created_at}
                                             message={handleTranslateHistoricLog(historicLog)}
                                             owner={historicLog.user}
                                             title={historicLog.title}/>
                            </Col>
                        ))
                    ) : (
                        <Col xs={24}>
                            <Empty/>
                        </Col>
                    )}
                </Row>
            </Drawer>


        </>


    );
}
