import React from "react";
import {useTranslation} from "react-i18next";
import {AuditOutlined, FundOutlined, ProjectOutlined} from "@ant-design/icons";
import {Col, Row} from "antd";
import MetricsCard from "../../../../components/data_display/MetricsCard";
import RemixIcon from "../../../../components/data_display/RemixIcon";
import {
    cyan_7, golden_purple_6,
    magenta_5,
    primary_7, primary_8,
    sunset_orange_8
} from "../../../../utils/Colors";
import useDefaultGetRequest from "../../../../hooks/useDefaultGetRequest";
import SquaresLoading from "../../../../components/feedback/SquaresLoading";
import {useInnovationPanelContext} from "../context/InnovationPanelContext";


export default function SectionMetrics() {
    const {t} = useTranslation();
    const {
        initialDate,
        finalDate,
        programsFilters, PROGRAM_FILTER_FORM_NAMES,
        projectsFilters, PROJECT_FILTER_FORM_NAMES,

    } = useInnovationPanelContext()

    const {
        data: programMetrics,
        isLoading: isLoadingProgram
    } = useDefaultGetRequest({
        url: `analytics/dashboard/manager_dashboard/counters_program`,

        params: {
            initial: initialDate,
            final: finalDate,
            [PROGRAM_FILTER_FORM_NAMES.PROGRAMS__ID__IN]: programsFilters[PROGRAM_FILTER_FORM_NAMES.PROGRAMS__ID__IN]

        },
        body: programsFilters,
        dependence: [initialDate, finalDate, JSON.stringify(programsFilters)]
    })


    const {
        data: projectMetrics,
        isLoading: isLoadingProjects
    } = useDefaultGetRequest({
        url: `analytics/dashboard/manager_dashboard/counters_project`,

        params: {
            initial: initialDate,
            final: finalDate,
            [PROJECT_FILTER_FORM_NAMES.PROJECTS__ID__IN]: projectsFilters[PROJECT_FILTER_FORM_NAMES.PROJECTS__ID__IN]
        },
        body:projectsFilters,
        dependence: [initialDate, finalDate,  JSON.stringify(projectsFilters)]
    })

    if (isLoadingProgram || isLoadingProjects) return <SquaresLoading/>
    return (
        <Row gutter={[16, 16]}>
            <MetricsCol>
                <MetricsCard
                    value={programMetrics.solutions_counter}
                    name={t("Solutions")}
                    icon={<RemixIcon
                        remixIconName={"ri-flashlight-fill"}/>}
                    color={magenta_5}/>
            </MetricsCol>
            <MetricsCol>
                <MetricsCard
                    value={programMetrics.challenges_application_counter}
                    name={t("Suggested Challenges")}
                    icon={<RemixIcon
                        remixIconName={"ri-flag-line"}/>}
                    color={sunset_orange_8}/>
            </MetricsCol>
            <MetricsCol>
                <MetricsCard
                    value={programMetrics.assessments_counter}
                    name={t("Evaluations")}
                    icon={<AuditOutlined/>}
                    color={cyan_7}/>
            </MetricsCol>
            <MetricsCol>
                <MetricsCard
                    value={projectMetrics.projects_counter}
                    name={t("Projects")}
                    icon={<ProjectOutlined/>}
                    color={primary_7}/>
            </MetricsCol>
            <MetricsCol>
                <MetricsCard
                    value={projectMetrics.checkpoints_counter}
                    name={t("Checkpoints")} icon={<RemixIcon
                    remixIconName={"ri-file-line"}/>} color={golden_purple_6}/>
            </MetricsCol>
            <MetricsCol>
                <MetricsCard
                    value={projectMetrics.tasks_counter}
                    name={t("Tasks")}
                    icon={<FundOutlined/>} color={primary_8}/>
            </MetricsCol>

        </Row>
    )
}

function MetricsCol({children, ...props}) {
    return (
        <Col xs={12} md={8} lg={4} {...props}>
            {children}
        </Col>
    )
}