import {useProgramDetailContext} from "../context/ProgramDetailContext";
import ListProgramTopics from "../../../messages/ListProgramTopics";
import {Redirect} from "react-router-dom";

export default function MessagesProgramTab() {
    const {hasProgramPermission, getProgramInfo} = useProgramDetailContext()

    const program = getProgramInfo();

    return (
        <>
            {hasProgramPermission !== false ?
                <ListProgramTopics program={program} updateTabBar={false}/>
                :
                <Redirect to={`/available-programs/detail/${program?.id}`}/>
            }
        </>
    );
}

