import React from 'react';
import {useTranslation} from "react-i18next";
import {Badge, Card, Typography} from "antd";
import styled from "styled-components";
import Flex from "../../../../../../../global/components/responsive/Flex";
import LockerCollapse from "../../../../../../../global/components/misc/LockerCollapse";
import WideSpace from "../../../../../../../global/components/layout/WideSpace";
import {blue_6} from "../../../../../../../global/utils/Colors";
import AssessmentSimplifiedView
    from "../../../../../../../global/components/other/application/ApplicationDrawer/components/DescriptionSolutionTab/components/assessmentComponents/AssessmentSimplifedView";
import AssessmentDetailedView
    from "../../../../../../../global/components/other/application/ApplicationDrawer/components/DescriptionSolutionTab/components/assessmentComponents/AssessmentDetailedView";
import PhaseFeedbackCard
    from "../../../../../../../global/components/other/application/ApplicationDrawer/components/DescriptionSolutionTab/components/assessmentComponents/PhaseFeedbackCard";


const StyledCard = styled(Card)`
    border-radius: 8px;
`

const {Text} = Typography


export default function AssessmentViewTypeHelp({type}) {

    const {t} = useTranslation()

    const explanations = {
        "score_average": {
            title: t("What is average per question?"),
            description: t("The average per question shows, per application, the average of the marks given in that question and the overall average for the application."),
        },
        "detailed_evaluation": {
            title: t("What is Detailed Assessment?"),
            description: t("The detailed assessment shows all the marks given for that question without detailing the evaluators, feedback per assessment and the overall average of the application.")

        }
    }

    const HELP = {
        explanation: explanations?.[type],
        phase: {title: t("Evaluation"), sequence: 4},
        feedback: t("Lack of Project Detail: The project documentation and presentation lack essential details. It is important to provide clear information on the following aspects: Specific objectives: What exactly does the application intend to solve? What problems does it address? Scope: What is the scope of the project? What functionality is included? Recommendations: Develop a detailed plan, including milestones and deliverables. Seek mentoring or consulting from experts in the field to fill knowledge gaps."),
        form: {
            "comp_score": [
                {
                    "title": t("Question about initiatives."),
                    "type": "score_with_feedback",
                    "answer_avg": "50.00",
                    "reponses": [
                        {
                            "evaluator": "Evaluator",
                            "scale": {
                                "min": 0,
                                "max": 10
                            },
                            "score": "5",
                            "feedback": t("I liked")
                        }
                    ]
                },
                {
                    "title": t("Question about project maturity."),
                    "type": "score_with_feedback",
                    "answer_avg": "20.00",
                    "reponses": [
                        {
                            "evaluator": "Evaluator",
                            "scale": {
                                "min": 0,
                                "max": 10
                            },
                            "score": "2",
                            "feedback": t("I didn't like it")
                        }
                    ]
                },
                {
                    "title": t("Question about project feasibility."),
                    "type": "score_with_feedback",
                    "answer_avg": "20.00",
                    "reponses": [
                        {
                            "evaluator": "Evaluator",
                            "scale": {
                                "min": 0,
                                "max": 10
                            },
                            "score": "2",
                            "feedback": ""
                        }
                    ]
                }
            ],
        },
        solutionResult: {
            "is_aproved": true,
            "standard_deviation": "3.00",
            "status": "approved",
            "status_display": "Approved",
            "step": 1493,
            "score_max": "35.00",
            "score_min": "35.00",
            "score_unweighted": "30.00",
            "score_average": "30.00",
            "protocol": null,
            "is_finished": true,
            "has_phase_actions": false
        }
    }

    return (
        <WideSpace direction="vertical" size={24}>
            <StyledCard>
                <Flex vertical={true} gap={8}>
                    <Text strong={true}>{HELP?.explanation?.title}</Text>
                    <Text>{HELP?.explanation?.description}</Text>
                </Flex>
            </StyledCard>
            <LockerCollapse
                headerBackground="white"
                active={true}
                bordered={true}
                title={
                    <Flex align="center">
                        <Badge color={blue_6} count={HELP?.phase?.sequence}/>
                        <Text> {HELP?.phase?.title}</Text>
                    </Flex>
                }
            >
                <WideSpace direction="vertical" size={24}>
                    {type === "score_average" ?
                        <AssessmentSimplifiedView form={HELP.form} solutionResult={HELP.solutionResult}/>
                        : <AssessmentDetailedView form={HELP.form} solutionResult={HELP.solutionResult} viewerMode={true}/>
                    }
                    <PhaseFeedbackCard feedback={HELP.feedback}/>
                </WideSpace>
            </LockerCollapse>
        </WideSpace>
    );
}

