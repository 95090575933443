import React, { useState} from "react";
import {useProgramContext} from "../../../ProgramsNavigator";
import {primary_6} from "../../../../global/utils/Colors";

import {
    FlexContainer
} from "../../../../global/components/authentication/StyledComponents";
import {Modal} from "antd";
import {useTranslation} from "react-i18next";
import Button from "../../../../global/components/data_entry/Button";
import RemixIcon from "../../../../global/components/data_display/RemixIcon";
import api from "../../../../global/services/api";
import {BuildFieldErrorsDict} from "../../../../global/utils/Utils";
import {
    ExclamationCircleOutlined,
    ReloadOutlined,
    SearchOutlined
} from "@ant-design/icons";
import DrawerManualSearchStartups
    from "./components/DrawerManualSearchStartups";


export default function ButtonsSearchSection({handleExternalTableUpdate,setOpenSocket, hasRadarData}) {
    const {program} = useProgramContext();
    const {t} = useTranslation()
    const [openMoreStarts, setOpenMoreStarts] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    async function handleInitialSearch() {
        try {
            setIsLoading(true)
            const response = await api.post(`/program_hunt/${program.id}/start_radar`);
            setOpenSocket(true)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        } finally {
            setIsLoading(false)
        }
    }

    function handleRestartStartupsSearch() {
        Modal.confirm({
            title: t('Reset the startup search radar'),
            icon: <ExclamationCircleOutlined style={{color: primary_6}}/>,
            content: t("When you restart the radar, ALL previously selected startups will be removed from the table. If you prefer, you can manually remove startups one by one instead of restarting the radar. Are you sure you want to restart the radar?"),
            okText: t('Keep current'),
            cancelText: t('Restart'),
            onCancel: handleInitialSearch,
        });

    }


    return (
        <>
            <FlexContainer justify={'end'} gap={8} wrap={'wrap'}>
                <Button
                    type={'default'}
                    loading={isLoading}
                    icon={<SearchOutlined/>}
                    onClick={() => setOpenMoreStarts(true)}
                > {t("Manual Search")}
                </Button>
                {hasRadarData?.organizations_count === 0 ? (
                    <Button loading={isLoading} //SEARCH IA BUTTON

                            icon={<RemixIcon
                                remixIconName={"ri-sparkling-fill"}/>}
                            onClick={handleInitialSearch}>{t('Search for AI Startups')}</Button>
                ) : (
                    <Button loading={isLoading} //RESTART IA BUTTON
                            icon={<ReloadOutlined/>}
                            type={'default'}
                            onClick={handleRestartStartupsSearch}
                    >{t('Reset Silvia\'s radar')}</Button>
                )}

            </FlexContainer>
            <DrawerManualSearchStartups handleExternalTableUpdate={handleExternalTableUpdate} open={openMoreStarts}
                                        setOpen={setOpenMoreStarts}/>
        </>
    );
}
