import React from "react";
import {Draggable} from "react-beautiful-dnd";
import styled from 'styled-components';

const Item = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding:  0;
    margin-bottom:8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    border-radius:8px;
    border: ${(props) => (props.isDragging ? '1px dashed #4099ff' : '1px dashed #f8f8f8')};
    background: ${(props) => (props.isSelected ? '#ddeeff' : props.isRelated ? '#ddeeff' : '#f8f8f8')};
    transition: 0.5s;
    width:100%;
       &:hover{
        background-color: #d5d4d4;
        border: 1px dashed #636363;
    }

`;

const Clone = Item

const MultipleSelect = styled.div`
 width:100%;
    display: flex;
    align-items: center;
    padding:  0;
    justify-content:center;
 
`

function DraggableComponent(props) {
    return (
        <Draggable key={props.id} draggableId={props.id} index={props.index}
                   isDragDisabled={props.isDisabled || props.isRelated || props.was_submitted} useClone={props.render_clone}>
            {(provided, snapshot) => (
                <>
                    <Item
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        isDragging={snapshot.isDragging}
                        render_clone={props.render_clone}
                        isSelected={props.isSelected}
                        isRelated={props.isRelated}
                        style={{
                            ...provided.draggableProps.style,
                            transform: (provided.draggableProps.style.transition !== 'none' && provided.draggableProps.style.transition !== null) || !props.render_clone ? provided.draggableProps.style.transform : 'none'
                        }}
                    >
                        {props.isMultipleSelected > 1 && snapshot.isDragging && props.isDragedSelected?
                            <MultipleSelect>{`${props.isMultipleSelected} - Cards Selected's`} </MultipleSelect> : <>{props.children}</> }
                    </Item>
                    {props.render_clone && (
                        <Clone hidden={!snapshot.isDragging} render_clone={props.render_clone}>{props.children}</Clone>
                    )}
                </>
            )}
        </Draggable>
    )

}

export default DraggableComponent