import {Button, Col, Row, Space} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import {useState} from "react";
import {useHistory} from "react-router-dom";
import Drawer from "../../../../global/components/layout/Drawer";
import DynamicDropdown from "../../../../global/components/data_entry/DynamicDropdown";
import ProjectCollapseList from "../../components/ProjectCollapseList";

import {useTranslation} from "react-i18next";
import ProjectCollapse from "../../components/ProjectCollapse";
import {get_item_cookie, save_item_cookie} from "../../../../global/utils/translation";


export function TableSettingsPanel() {
    const [openColumnsSettingsDrawer, setOpenColumnsSettingsDrawer] = useState(false);
    const { t } = useTranslation()

    return (
        <>
            <Drawer title={t("Columns Settings")} open={openColumnsSettingsDrawer} onClose={() => { setOpenColumnsSettingsDrawer(false); }} />
            <Space>
                {/*<DatabaseOutlined onClick={() => setOpenColumnsSettingsDrawer(true)} />*/}
            </Space>
        </>

    );
}


function ProjectHomePage() {
    const { t } = useTranslation()
    const [key, setKey] = useState(get_item_cookie('project_home_view','all'));
    const history = useHistory();
    const groupByOptions = [
        { label: t("Phase"), key: "phase" },
        { label: t("All"), key: "all" },
    ]
    const handleChange = (item) => {
        setKey(item.key);
        save_item_cookie('project_home_view',item.key)
    };
    return (<>
        <Space direction="vertical" style={{width: "100%"}}>
            <Row wrap={true} >
                <Col flex="max-content">
                    <DynamicDropdown title="" selectedKey={key} items={groupByOptions} handleChange={handleChange} data-cy="projects-view-mode-dropdown"/>
                </Col>
                <Col>
                    <Space size="small" wrap={true}>
                        {/* <Button><DownloadOutlined />{t("Archived")}</Button> */}
                        <Button type="primary" onClick={() => history.push("/project-portfolio/project/create")}
                                data-cy="new-project-button"
                        >
                            <PlusOutlined />{t("New Project")}
                        </Button>
                        {/* {key === "phase" && <TableSettingsPanel />} */}
                    </Space>
                </Col>
            </Row>
            <div style={{display: "block", overflow: "auto"}}>
                {key === "phase" ? <ProjectCollapseList /> : <ProjectCollapse title={<>{t("All Projects")}</>} mdExtra={<TableSettingsPanel />}/>}
            </div>
        </Space>
    </>);
}

export default ProjectHomePage;