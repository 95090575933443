import React, {useEffect, useState} from "react";
import {useProgramContext} from "../../../ProgramsNavigator";
import styled from "styled-components";
import {neutral_2} from "../../../../global/utils/Colors";
import PerfilMessage
    from "../../../../global/components/other/chat/PerfilMessage";
import SilviaImage from "../../../../assets/img/silvia.png";

import {
    FlexContainer
} from "../../../../global/components/authentication/StyledComponents";
import {Typography} from "antd";
import {useTranslation} from "react-i18next";
import StartupsTableSection from "./components/StartupsTableSection";

import useWebSocket from "../../../../global/hooks/useWebSocket";
import LoadingSearch from "./components/LoadingSearch";
import useDefaultGetRequest
    from "../../../../global/hooks/useDefaultGetRequest";
import ButtonsSearchSection from "./ButtonsSearchSection";

const Container = styled.div`
    background: white;
    padding: 24px 8px;
    border-radius: 12px;
`

export default function TabStartupsPrograms({hasPermission, location}) {
    const {program} = useProgramContext();
    const [openSocket, setOpenSocket] = useState(true)
    const [updateTable, setUpdateTable] = useState(false)
    const {t} = useTranslation()

    useEffect(() => {
        if(updateTable){
            setUpdateTable(false)
        }
    }, [updateTable])

    const {//PRIMEIRO A SER CHAMADO È O SOCKET, OBS: SOCKET VAI ESTA SEMPRE ABERTO
        lastJsonMessage,
    } = useWebSocket({
        url: `program-hunt/${program.id}`,
        openConnection: !!program.id && openSocket,
    });
    const SOCKET_FINISHED = lastJsonMessage?.status === 'finished'
    useEffect(() => {
        if (openSocket && SOCKET_FINISHED) {
            setOpenSocket(false)
        }
    }, [openSocket, lastJsonMessage?.status]);


    const {//HAS RADAR chama depois que o status do socket for "finished"
        data: hasRadarData,
        isLoading: isLoadingHasRadar
    } = useDefaultGetRequest({
        url: `program_hunt/${program.id}/has_radar`,
        makeRequest: !!program.id && SOCKET_FINISHED, //todo descomentar e remover o de baixo
        // makeRequest:  !!program.id ,
        dependence: [program.id, lastJsonMessage?.status]
    })

    const LOADING = lastJsonMessage?.status === 'pending' || isLoadingHasRadar

    function handleExternalTableUpdate() {
        setUpdateTable(true)
    }

    return (
        <Container>
            <FlexContainer direction={'column'} align={'stretch'}>
                <PerfilMessage data={{picture: SilviaImage}}
                               name={t('Silvia\'s Radar')}/>
                <Typography.Text style={{marginBottom: 24}}
                                 type={'secondary'}>{t('Startup Hunter helps you find startups that meet your specific needs. Search by industry, location, or let us know what you’re looking to solve, and we’ll help you find them. Our radar uses your program description and segmentation to match it against startup data. During the AI search, your screen will be unavailable to all program managers while we find the best startups for you.')}</Typography.Text>

                {LOADING ? <LoadingSearch/> : (
                    <>
                        <ButtonsSearchSection
                            handleExternalTableUpdate={handleExternalTableUpdate}
                            setOpenSocket={setOpenSocket}
                            hasRadarData={hasRadarData}/>
                        <StartupsTableSection updateTable={updateTable} hasRadarData={hasRadarData}/>
                    </>

                )}


            </FlexContainer>
        </Container>

    );
}
