import {Flex, Typography} from "antd";
import RemixIcon from "./RemixIcon";
import React from "react";
import {useTranslation} from "react-i18next";

const {Text} = Typography

export default function HappyEmpty({message = "There are no data yet." }){
    const {t} = useTranslation()
    return(
        <Flex align="center" flex={1} vertical={true} gap={0}>
            <Text style={{fontSize:48}} type="secondary">
                <RemixIcon remixIconName={"ri-emotion-happy-line"}/>
            </Text>
            <Text type="secondary">{t(message)}</Text>
        </Flex>
    )
}