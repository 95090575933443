import React from 'react';
import styled from 'styled-components';
import {
    blue_1, dust_red_6, neutral_1, polar_green_6
} from "../../../../../../global/utils/Colors";
import {
    FlexContainer
} from "../../../../../../global/components/authentication/StyledComponents";
import {renderAvatars} from "../../../../../../global/components/tables/utils";
import ApplicationTagStatus
    from "../../../../../../global/components/tag/ApplicationTagStatus";
import {Draggable} from "react-beautiful-dnd";
import TagsList from "../../../../../../global/components/tag/TagsList";


const Card = styled(FlexContainer)`
    padding: 8px 18px;
    width: 100%;
    background: ${({isDragging}) => isDragging ? blue_1 : neutral_1};
    border-left: 1px solid ${
            ({status}) => status == 'aproved' ? polar_green_6 : status === 'reproved' ? dust_red_6 : 'none'
    };
    border-radius: 9px;
    height: 114px;
    min-height: 114px;
    max-height: 114px;
    cursor: pointer;

    :active {
        cursor: ${({isDragDisabled}) => isDragDisabled ? 'not-allowed' : 'grab'};
    }
`
const Text = styled.div`
    white-space: initial;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
`

export default function ApplicationCardPhase({
                                                 application,
                                                 onClick,
                                                 phase,
                                                 ...props
                                             }) {
    const IS_REPROVED = application.status === 'reproved'
    const IS_DRAG_DISABLED = IS_REPROVED

    return (
        <Draggable
            isDragDisabled={IS_DRAG_DISABLED}
            draggableId={`${application.id}`} index={props.index}
        >
            {(provided, snapshot) => (
                <Card direction={'column'} justify={'space-between'}
                      align={'stretch'}
                      {...snapshot}
                      status={application.status}
                      onClick={(e) => onClick?.(e, application)}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      data-cy={`application-card-phase-${application?.id}`}
                      {...props}>
                     <FlexContainer justify={'space-between'}>
                            {renderAvatars(application.members)}
                        <ApplicationTagStatus application={application}/>

                    </FlexContainer>
                    <Text>
                        {application.name || '-'}
                    </Text>

                    <TagsList isCustom tags={application?.tags_display} maxSize={2}/>
                </Card>)}

        </Draggable>)
}