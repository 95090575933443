import React from 'react';
import Select from "react-select";
import MessageError from "../MessageError";
import InputContainer from "../InputContainer";
import Label from "../Label";
import InterrogationTip from "../../InterrogationTip";
import {useTranslation} from "react-i18next";
import styled from 'styled-components'

const StyledSelect = styled(Select)`
  #menu {
    background-color: red !important;
  }
`

const FormSelect = (props) => {
    const {t} = useTranslation();
    const {
        label,
        name,
        onChange,
        required,
        placeholder,
        id,
        value,
        messageError,
        clearable,
        elementDescription,
        options,
        multiple,
        information,
        disabled=false
    } = props

    const checkValue = () => {
        if (value) {
            if (multiple) {
                return options.filter((item) => value.includes(item.value))
            } else {

                return options.find((item) => item.value === value)
            }
        }
    }
    const customStyles = {
        control: (styles) => ({
            ...styles,
            padding: '7px 5px',
            color: '#949494',
            borderColor: 'rgb(221, 221, 221)',
            cursor: 'pointer',
            outline: 'none'
        }),
        menu: (provided, state) => ({
            ...provided,
            zIndex: "999"
        }),
    }

    return (
        <InputContainer>
            <div style={{display: "flex", alignItems: "center"}}>
                {label && <Label htmlFor={id} required={required}>{label}</Label>}
                {information ? <InterrogationTip title={information}/> : null}
            </div>
            {elementDescription}
            <div style={{display: 'flex', flexDirection: 'column',}}>
                {multiple ?
                    <StyledSelect styles={customStyles} isDisabled={disabled}
                                  menuPlacement="auto" id={id} name={name} onChange={onChange}
                                  style={messageError && (value === undefined || value === '') ? {border: "1px solid red"} : {}}
                                  options={options} placeholder={placeholder ? placeholder : t("Select...")}
                                  isClearable={clearable} isMulti={multiple}
                                  value={checkValue()}
                    /> :
                    <StyledSelect styles={customStyles}  isDisabled={disabled}
                                  menuPlacement="auto" id={id} name={name} onChange={onChange}
                                  style={messageError && (value === undefined || value === '') ? {border: "1px solid red"} : {}}
                                  options={options} placeholder={placeholder ? placeholder : t("Select...")}
                                  isClearable={clearable}
                                  value={checkValue()}
                    />}

                {messageError && <MessageError style={{textAlign: 'left'}}>{messageError}</MessageError>}
            </div>
        </InputContainer>
    );
}

export default FormSelect