import {useTranslation} from "react-i18next";
import WideSpace from "../../../../../../layout/WideSpace";
import usePhaseForm from "../../../../../../../hooks/usePhaseForm";
import { useApplicationDrawerContext } from "../../../context/ApplicationDrawerContext";
import LoadingFull from "../../../../../LoadingFull";
import ApplicationStatisticPanel from "./ApplicationStatisticPanel";
import ApplicationFormContent from "./ApplicationFormContent";
import { Typography } from "antd";

const {Text} = Typography;

export default function CollapseContentManagerView({phase, solutionResult}){
    const {t} = useTranslation();
    const {solution, renderPhaseContent} = useApplicationDrawerContext();
    const {form, isLoading} = usePhaseForm(solution, phase);

    return(
        <WideSpace direction="vertical">
            {isLoading ? <LoadingFull /> : 
                renderPhaseContent({
                    phase, solutionResult, 
                    applicationContent: <ApplicationFormContent form={form} phase={phase} />, 
                    evaluationContent: <ApplicationStatisticPanel form={form} solutionResult={solutionResult} phase={phase} />,
                    applicationIncompleteContent: solution.program.enable_application_editing ? <ApplicationFormContent form={form} phase={phase} solution={solution} /> : <Text style={{ fontSize: 16, fontWeight: 500 }}>{t("Pending form filling and sending")}.</Text>, 
                    evaluationIncompleteContent: <ApplicationStatisticPanel form={form} solutionResult={solutionResult} />
                })
            }
        </WideSpace>
    )
}