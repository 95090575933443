import React from "react";
import styled from 'styled-components';
import {useLayoutContext} from "../../../modules/project/components/layout/context/LayoutContext";
import {MenuCollapseButton} from "./MenuCollapseButton";
import EnvironmentDropdown from "./EnvironmentDropdown";


const Container = styled.div`
  display: flex;
  align-items: center;
  height: 64px;
  justify-content: space-between;
  padding: ${({$collapsed}) => !$collapsed && "0 16px 0 0"};
    
  & > *:first-child {
    flex: 1;
  }
    
`

export default function MenuHeader() {
    const {collapsed} = useLayoutContext();
    return (
        <Container $collapsed={collapsed}>
            {!collapsed && <EnvironmentDropdown/>}
            <MenuCollapseButton color="var(--text_color_menu)" />
        </Container>
    );
}
