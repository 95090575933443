import React from "react";
import {Droppable} from "react-beautiful-dnd";
import styled from "styled-components";

const Container = styled.div`
    border: 1px ${(props) => props.isDraggingOver && !props.isDropDisabled ? 'dashed #000' : 'none'};
    background: ${(props) => props.isDraggingOver ? '#f5fffc' : '#fff'};
    // box-shadow: ${(props) => props.isDropDisabled ? '0px 0px 8px rgba(221, 221, 221, 0.5)' : 'none'};
    box-shadow: 0px 0px 8px rgba(221, 221, 221, 0.5);
    border-radius: 8px;
    width:100%;
    overflow-y: ${(props) => props.isDropDisabled && 'scroll'}; 
    -ms-overflow-style:  ${(props) => props.isDropDisabled && 'none'};
    &::-webkit-scrollbar {
        display: ${(props) => props.isDropDisabled && 'scroll'};
    }
    padding-bottom:${(props) => props.isDraggingOver && '50px;'};
    height: calc(100vh - 50px);
    //padding-bottom: 10px;
    margin-bottom: 10px;
    overflow:scroll;
    margin:${(props) => !props.isDropDisabled && '0 46px 0 46px'};
`;

function DroppableComponent(props) {
    return (
        <Droppable droppableId={props.id} isDropDisabled={props.disabled_drop}>
            {(provided, snapshot) => (
                <Container
                    ref={provided.innerRef}
                    isDraggingOver={snapshot.isDraggingOver}
                    isDropDisabled={props.disabled_drop}
                >
                    {props.children}
                    {provided.placeholder}
                </Container>
            )}
        </Droppable>
    )

}

export default DroppableComponent