import React, {createContext, useContext, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import ExtraHeader from "../../modules/project/components/layout/ExtraHeader";
import {
    useLayoutContext
} from "../../modules/project/components/layout/context/LayoutContext";
import {useTranslation} from "react-i18next";

const HomeContext = createContext();

export const HomeProvider = ({children}) => {
    const {initializeLayout} = useLayoutContext();
    const location = useLocation();
    const {t} = useTranslation();



    return <HomeContext.Provider
        value={{}}>
        {children}
    </HomeContext.Provider>
}

export const useHomeContext = () => {
    return useContext(HomeContext);
}

