import React from 'react'
import styled from 'styled-components'
import {Space, Typography} from "antd";

import {CheckCircleOutlined} from '@ant-design/icons';
import {
    FlexContainer,
    GrayContainer
} from "../../authentication/StyledComponents";
import UserAvatar from "../../misc/UserAvatar";
import {useUserContext} from "../../../context/UserContext";
import PerfilMessage from "./PerfilMessage";


const Container = styled.div`
    padding: 16px;
    width: 100%;
`
const Text = styled.span`
    white-space: pre-wrap;
`


export default function UserMessage({message, ...props}) {
    const {_user} = useUserContext()

    return (
        <Container>
            <FlexContainer direction={'column'} align={'end'}>
                <PerfilMessage data={{
                    name: _user.full_name,
                    picture: _user.picture_url
                }} name={_user.full_name}/>



                <Text>
                    {message}
                </Text>
            </FlexContainer>

        </Container>

    );
}