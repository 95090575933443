import React from "react";
import {useTranslation} from "react-i18next";

import {
    ReactComponent as PlusGamificationIcon
} from "../../../../../../assets/Icon/amount_plus_gamification.svg"
import {
    ReactComponent as MinusGamificationIcon
} from "../../../../../../assets/Icon/amount_minus_gamification.svg"
import useDefaultGetRequest from "../../../../../hooks/useDefaultGetRequest";

import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import styled from "styled-components";
import AmountCardGamification
    from "../../../../../components/data_display/AmountCardGamification";
import RemixIcon from "../../../../../components/data_display/RemixIcon";
import SquaresLoading from "../../../../../components/feedback/SquaresLoading";
import {Col, Row} from "antd";

const ListAmount = styled(FlexContainer)`

    overflow-y: scroll;
    width: 100%;
`
export default function SectionAmountPoints({}) {
    const {t} = useTranslation();

    const {
        data: counters,
        isLoading
    } = useDefaultGetRequest({
        url: '/store/get_store',
    })


    return (
        <Row gutter={[16,16]}>
            {isLoading ? <Col xs={24}><SquaresLoading/> </Col>:
                <>
                    <Col xs={8}>
                        <AmountCardGamification width={'100%'}
                                                value={counters?.purchases_30_days}
                                                title={t('Shopping (30 days)')}
                                                color={"#6A78BE"}
                                                icon={<RemixIcon
                                                    remixIconName={'ri-shopping-basket-line'}/>}/>
                    </Col>
                    <Col xs={8}>
                        <AmountCardGamification width={'100%'}
                                                value={counters?.points_spent_30_days}
                                                title={t('Points spent in store (30 days)')}
                                                color={"#6A78BE"}
                                                icon={<MinusGamificationIcon/>}/>
                    </Col>
                    <Col xs={8}>
                        <AmountCardGamification
                            width={'100%'}
                            value={counters?.total_purchases}
                            title={t('Items sold (total)')}
                            color={"#6A78BE"}
                            icon={<RemixIcon
                                remixIconName={'ri-shopping-basket-line'}/>}/>
                    </Col>

                </>
            }
        </Row>
    );
}
