import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import CropperComponent from "./CopperComponent";
import { getOrientation } from "get-orientation/browser";
import { getCroppedImg, getRotatedImage } from "./canvasUtils";
import Upload from "../../assets/img/upload.svg";
import { FileValidate, limit_image } from "../../global/utils/FileValidate";

import LimitUploadInfo from "../LimitUploadInfo";
import { useTranslation } from "react-i18next";
import ButtonDefault from "../Buttons/ButtonDefault";
import { convertByteToMegaByte } from "../../global/utils/Utils";
import { ToastNotification } from "../../global/components/feedback/ToastNotification";
import UserAvatar from "../../global/components/misc/UserAvatar";
import { Tooltip } from 'antd';

const ORIENTATION_TO_ANGLE = {
    3: 180,
    6: 90,
    8: -90,
};

function EasyCrop({ borderRadius = 8, size = 56, avatarData, objectFit, ...props }) {
    const [showModal, setShowModal] = useState(false);
    const [newImage, setnewImage] = useState("");
    const [imageQuality, setImageQuality] = useState(0.8);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const { t } = useTranslation();

    function showHideModalHandle() {
        setShowModal(!showModal);
    }

    function onShowModalHandle() {
        setnewImage(null);
        showHideModalHandle();
    }

    async function doCrop() {
        const croppedImage = await getCroppedImg(newImage, croppedAreaPixels, 0, imageQuality);
        props.onUploadImage(croppedImage);
    }

    function okModalHandle() {
        doCrop();
        showHideModalHandle();
    }

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => resolve(reader.result), false);
            reader.readAsDataURL(file);
        });
    }

    async function onFileChange(e) {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            if (!FileValidate(file, "image")) {
                ToastNotification(t("Maximum upload is MB", { limit_image }), "warning");
                e.target.value = "";
                return;
            }
            let imageDataUrl = await readFile(file);

            // apply rotation if needed
            const orientation = await getOrientation(file);
            const rotation = ORIENTATION_TO_ANGLE[orientation];
            if (rotation) {
                imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
            }
            if (convertByteToMegaByte(file.size) < 0.5) {
                setImageQuality(1);
            } else if (convertByteToMegaByte(file.size) < 1) {
                setImageQuality(0.9);
            }
            setnewImage(imageDataUrl);
        }
    }

    function onCropHandle(croppedAreaPixels) {
        setCroppedAreaPixels(croppedAreaPixels);
    }

    return (
        <Tooltip placement="top" title={t("Change image")} >    
            <div style={{ width: "100%", height: "100%", cursor: "pointer" }}>
                {avatarData ?
                    <UserAvatar onClick={onShowModalHandle} size={size} data={{picture: props.img, ...avatarData}}/> :
                    <img src={props.img} alt="Cropped"
                        style={props.type === "circle" ? {
                                width: '100%',
                                borderTopRightRadius: 8,
                                borderTopLeftRadius: 8,
                                borderRadius: "50%",
                                border: "3px solid white",
                                cursor: "pointer",
                                backgroundColor: "white"
                            } :
                            {
                                height: props.height ?? '100%',
                                width: '100%',
                                borderTopRightRadius: borderRadius,
                                borderTopLeftRadius: borderRadius,
                                cursor: "pointer"
                            }} onClick={onShowModalHandle}/>
                }

                <Modal show={showModal} onHide={showHideModalHandle} dialogClassName="input-modal-img">
                    <Modal.Header style={{fontSize: 22, fontWeight: 500}} closeButton>
                        {t("Add new image")}
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{height: "300px"}}>
                            {newImage ? (
                                <CropperComponent
                                    img={newImage}
                                    onCrop={onCropHandle}
                                    ratio={props.ratio}
                                    cropShape={props.type}
                                    objectFit={objectFit}
                                />
                            ) : (
                                <div className="divModalImageCrop">
                                    <img alt={"icon"} src={Upload} width="250" />
                                    <input
                                        className="inputModalImageCrop"
                                        type="file"
                                        onChange={onFileChange}
                                        accept="image/*"
                                        data-cy="easy-crop-image-input"
                                    />
                                    <span
                                        style={{
                                            fontSize: 18,
                                            fontWeight: 500,
                                            color: "var(--primary_btn_color)",
                                        }}
                                    >
                                        {t("Click here to upload your image")}
                                    </span>
                                    <LimitUploadInfo limit={limit_image} />
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonDefault action={"secondary"} onClick={showHideModalHandle} label={t("Cancel")} />
                        <ButtonDefault
                            action={"primary"}
                            onClick={okModalHandle}
                            label={t("OK")}
                            data-cy="easy-crop-ok-button"
                        />
                    </Modal.Footer>
                </Modal>
            </div>
        </Tooltip>
    );
}

export default EasyCrop;
