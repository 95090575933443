import moment from "moment";
import {getLanguage} from "../../../global/utils/translation";
import axios from 'axios';

export const formatter = Intl.NumberFormat(getLanguage(), {minimumFractionDigits: 2});

export function convertResponseDate(date) {
    return date ? moment(date) : null;
}

export async function sameRouteRequest(requestFunction, successCallback) {
    const urlBefore = window.location.pathname;
    const response = await requestFunction();
    const urlAfter = window.location.pathname
    if (urlBefore === urlAfter) {
        successCallback(response);
    }
}

export function convertRequestDate(date) {
    const djangoDate = moment(date.toDate()).format("YYYY-MM-DD");
    return date ? djangoDate : null;
}

export function convertDjangoDate(date) {
    return date ? moment(date).format("YYYY-MM-DD") : null;
}

export function formatNumber(value) {
    if(value === undefined){
        return 0
    }
    return formatter.format(value);
}

export function limitKeys(objects, keys) {
    return objects.map((obj) => {
        const filteredObj = {};
        keys.forEach((key) => {
            if (obj.hasOwnProperty(key)) {
                filteredObj[key] = obj[key];
            }
        });
        return filteredObj;
    });
}

export function momentToISODate(object) {
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            if (object[key] instanceof moment) {
                object[key] = moment(object[key]).format('YYYY-MM-DD');
            }
        }
    }
    return object;
}

export function convertToFormData(data, useBrackets = true, appendNull = true) {
    const formData = new FormData();
    for (const key in data) {
        if (data[key] === null || data[key] === undefined) {
            if (!appendNull) continue;
            formData.append(key, '');
        } else if (Array.isArray(data[key])) {
            for (let i = 0; i < data[key].length; i++) {
                formData.append(`${key}${useBrackets ? "[]" : ""}`, data[key][i]);
            }
        } else {
            formData.append(key, data[key]);
        }
    }
    return formData;
}

export function getFilePath(file) {
    if (file instanceof File) {
        return URL.createObjectURL(file);
    }
    return file.path;
}

export function getFiles(files) {
    return files.map((file) => {
        return {...file, url: getFilePath(file), name: file.name};
    });
}

export function getSelectedMenuItems({items, pathname}) {
    const item = items.slice().reverse().find((item) => item.isActiveForChildren ? pathname.includes(item.key) : pathname === item.key);
    return item?.key;
}

export function getIndicatorResponseScaledValue({value, min, max, indicator}) {
    const minScale = indicator["scale_min"] ?? min;
    const maxScale = indicator["scale_max"] ?? max;
    const percent = ((value - minScale) / (maxScale - minScale)) * 100;
    return parseInt(`${percent}`);
}

export function getScaledValueIfScaledIndicator({
                                                    value,
                                                    indicatorResponse,
                                                    indicator
                                                }) {
    if (!indicatorResponse) {
        return 0;
    }
    if (indicatorResponse["is_scalable"]) {
        return getIndicatorResponseScaledValue({value, indicator});
    }
    return value;
}

export function toTitleCase(str) {
    if (str) {
        return str.replace(/\w\S*/g, function (word) {
            return word[0].toUpperCase() + word.slice(1).toLowerCase();
        });
    }
    return ""
}


export function transformLink(link) {
    if (link.startsWith("http://") || link.startsWith("https://")) {
        return link;
    }
    return "https://" + link.replace("//", "")
}

export const onChangePicture = (files, setImgData) => {

    if (files[0]?.path) {
        setImgData(files[0]?.path)
    } else {
        const file = files[0]
        if (file) {
            const reader = new FileReader();
            reader.addEventListener("load", async () => {
                setImgData(reader.result);

            });
            reader.readAsDataURL(file);
        }
    }

};

export function generateSelectOptions(values, key_label='name', key_value = 'id') {
    if (values instanceof Array) {
        return values.map((value) => {
            return {
                label: value[key_label],
                value: value[key_value],
            }
        })
    }
    return []
}
