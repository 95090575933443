import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {ExclamationCircleOutlined, TrophyOutlined} from "@ant-design/icons";
import {FlexContainer} from "../authentication/StyledComponents";
import {Col, Input, Modal, Row, Typography} from "antd";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import {RANKING_STATUS} from "../../utils/Constants";
import {neutral_2, primary_6} from "../../utils/Colors";
import Button from "../data_entry/Button";
import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../utils/Utils";
import {
    DateFormatByUserTimezone,
} from "../../utils/dates";
import {
    useLayoutContext
} from "../../../modules/project/components/layout/context/LayoutContext";
import Drawer from "../layout/Drawer";

import RankingPositionCard from "./RankingPositionCard";
import Pagination from "../navigation/Pagination";
import Loading from "../feedback/Loading";
import Empty from "./Empty";
import DrawerRankingPlacements from "./DrawerRankingPlacements";
import {ToastNotification} from "../feedback/ToastNotification";
import * as ConstantsMessage from "../../utils/fileWithMessage";

const Container = styled.div`
    padding: 16px;
    border-radius: 8px;
    background: white;
`

const IconContainer = styled.div`
    padding: 11px 9px;
    border-radius: 8px;
    background: ${({background}) => background};

    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: white;
`
export default function RankingCard({ranking, url}) {
    const {t} = useTranslation();
    const {isPhone} = useSystemBreakpoint()
    const {updateLayoutContent} = useLayoutContext()
    const [isLoading, setIsLoading] = useState()
    const cardRef = useRef()
    const IS_RANKING_OPEN = ranking?.status === RANKING_STATUS.OPENED
    const IS_RANKING_PENDING = ranking?.status === RANKING_STATUS.SCHEDULED

    const bgColorIcon = IS_RANKING_PENDING ? 'black' : primary_6;


    function handleDeleteHanking() {
        Modal.confirm({
            title: t("Delete"),
            icon: <ExclamationCircleOutlined/>,
            content: t("Are you sure you want to delete the ranking?"),
            onOk: async () => {
                try {
                    setIsLoading(true);

                    let response = await api.delete(url);
                    ToastNotification(t(ConstantsMessage.SUCCESS));
                    updateLayoutContent()
                } catch (error) {
                    BuildFieldErrorsDict(error, null, null)
                } finally {
                    setIsLoading(false);
                }
            },
            okText: t('Yes'),
            cancelText: t('No')

        });

    }


    return (

        <Container ref={cardRef}>
            <FlexContainer gap={40}>
                {!isPhone && <IconContainer background={bgColorIcon}>
                    <TrophyOutlined/>
                </IconContainer>}
                <FlexContainer align={'start'} direction={'column'}
                               style={{flex: 1}}>
                    <Typography.Title level={5} style={{marginBottom: 0}}>
                        {ranking?.title}
                    </Typography.Title>
                    <Typography.Text type={'secondary'}>
                        {DateFormatByUserTimezone(ranking?.open_at)} {(ranking && !ranking.endless) && <>- {DateFormatByUserTimezone(ranking?.close_at)}</>}
                    </Typography.Text>
                </FlexContainer>
                {IS_RANKING_OPEN && <SeeButton id={ranking?.id}/>}
                {IS_RANKING_PENDING &&
                    <Button onClick={handleDeleteHanking}
                            loading={isLoading}
                            danger>{t('Delete')}</Button>}
            </FlexContainer>

        </Container>

    );
}


function SeeButton({id}) {

    const [open, setOpen] = useState(false)
    const {t} = useTranslation();


    return (
        <>
            <Button onClick={() => setOpen(true)}>{t('See')}</Button>
            <DrawerRankingPlacements id={id} open={open} setOpen={setOpen}/>
        </>
    )
}
