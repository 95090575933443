import React, {useState} from "react";
import {Form} from "react-bootstrap";
import {useHistory, useParams} from "react-router-dom";
import {useThemeContext} from "../../global/context/ThemeContext";
import Loading from "../../global/components/feedback/Loading";
import {
    ButtonLogin,
    ImageContainer,
    InputContainerCustom,
    LoginContainer,
    LogoContainer,
    MainContainer, StyledLabel, StyledLabelEmaill,
    StyledLogoImg
} from "./StyledAuthComponents";
import {useTranslation} from "react-i18next";

export default function NoticeBlockLogin() {
    const {themeConfig} = useThemeContext()
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory();
    const {email} = useParams()
    const {t} = useTranslation();

    return (
        <MainContainer>
            <Form style={{display: "contents"}}>
                {isLoading ? (<Loading/>) : (
                    <>
                        <ImageContainer>
                            <img src={themeConfig.login_img_cover}/>
                        </ImageContainer>
                        <LoginContainer>
                            <LogoContainer>
                                <StyledLogoImg src={themeConfig.login_logo_cover}/>
                            </LogoContainer>
                            <InputContainerCustom>
                                <StyledLabelEmaill>{t("User temporarily blocked")}</StyledLabelEmaill>
                                <StyledLabel>{t("Your user has been blocked due to number of wrong attempts",{email})}.</StyledLabel>
                                <StyledLabel>{t("Send an email to hello@solv.network or await 30 minutes")}.</StyledLabel>
                            </InputContainerCustom>
                            <ButtonLogin onClick={() => history.push('/login')}>{t("Go back to Log in")}</ButtonLogin>
                        </LoginContainer>
                    </>)}
            </Form>
        </MainContainer>
    )
}