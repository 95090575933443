import {convertByteToMegaByte} from "./Utils";


export const limit_image = 2
export const limit_pdf = 10
export const limit_file = 30




export function FileValidate(file, type) {
    let _type = type.toLowerCase() === 'image'.toLowerCase()
    let limit= 0

    if (_type === 'image'.toLowerCase()){
        limit = limit_image
    }
    else if (_type === 'pdf'){
        limit = limit_pdf
    }
    else{
        limit = limit_file
    }

    return !(convertByteToMegaByte(file.size) > limit)

}