import React, {useEffect, useMemo} from "react";
import {Link, Redirect, Switch, useLocation,} from "react-router-dom";
import {
    useLayoutContext
} from "../../modules/project/components/layout/context/LayoutContext";
import {useTranslation} from "react-i18next";
import {ManagerRoute} from "./PrivateRoute";
import {useThemeContext} from "../context/ThemeContext";
import StorePage from "../pages/rewardsStore/pages/store/StorePage";


const PATH_ROUTES = {
    STORE: '/rewards_store',
}

export default function RoutesRewardsStore() {
    const location = useLocation();
    const {t} = useTranslation();
    const {initializeLayout} = useLayoutContext()
    const {themeConfig: {client}} = useThemeContext();


    const RoutesItems = useMemo(() => [
        {
            label: <Link
                to={PATH_ROUTES.STORE}>{t("Rewards Store")}</Link>,
            key: PATH_ROUTES.STORE,
            route: <ManagerRoute
                exact
                component={StorePage}
            />
        },





    ], [])

    useEffect(() => {
        initializeLayout({
            navbarMenuItems: RoutesItems
        });
    }, [RoutesItems]);

    return (

        <Switch>
            {RoutesItems.map(({
                                  route, key,
                                  hasPermission
                              }) => (React.cloneElement(route, {
                hasPermission,
                key,
                path: key
            })))}
            <Redirect to={{
                pathname: "/404",
                state: {broken_url: window.location.host + location.pathname}
            }}/>
        </Switch>);
}
