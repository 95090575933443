import { LoadingOutlined } from "@ant-design/icons"
import styled from "styled-components"


const LoadingContainer = styled.div`
    display: grid; 
    height: 100%; 
    width: 100%;
    place-items: center;
`

export default function LoadingFull() {
    return <LoadingContainer><LoadingOutlined/></LoadingContainer>
}