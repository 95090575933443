import React from "react";
import Flex from "../../../../../../responsive/Flex";
import AssessmentSimplifiedView from "./assessmentComponents/AssessmentSimplifedView";
import AssessmentDetailedView from "./assessmentComponents/AssessmentDetailedView";
import PhaseFeedbackCard from "./assessmentComponents/PhaseFeedbackCard";


export default function ApplicationStatisticPanel({form, solutionResult, viewerMode = false, viewMode, phase}) {
    return (
        <Flex vertical={true} gap={16}>
            {viewMode === "score_average" ? <AssessmentSimplifiedView form={form} solutionResult={solutionResult}/> :
                <AssessmentDetailedView form={form} solutionResult={solutionResult} viewerMode={viewerMode}/>
            }
            {phase?.feedbacks?.map(feedback => <PhaseFeedbackCard feedback={feedback?.feedback_display}/>)}
        </Flex>

    );
}