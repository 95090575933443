import { LockOutlined, RightOutlined, RightSquareOutlined } from "@ant-design/icons";
import SolvCollapse from "./SolvCollapse";


export default function LockerCollapse({ children, content, title, renderExtra, padding, locked, active, bordered, bold, headerBackground, collapseStyle, contentStyle, handleActive}) {
    const expandIcon = locked ? <LockOutlined /> : <RightOutlined />;
    headerBackground = headerBackground || (locked ? "#F0F0F0" : "white");
    return (
        <SolvCollapse 
            content={content}
            title={title}
            renderExtra={renderExtra}
            padding={padding}
            locked={locked}
            active={active}
            bordered={bordered}
            bold={bold}
            collapseStyle={collapseStyle}
            contentStyle={contentStyle}
            handleActive={handleActive}
            expandIcon={expandIcon}
            headerBackground={headerBackground}
            showIconWhenLocked={true}
            >
                {children}
        </SolvCollapse>
    )

}