import { Badge, Form, Row, Space } from "antd";
import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../global/components/data_entry/Button";
import RequestTreeSelectFormField from "../../../../../global/components/data_entry/form/RequestTreeSelectFormField";
import Drawer from "../../../../../global/components/layout/Drawer";
import api from "../../../../../global/services/api";
import {useProjectManagementContext} from "../context/ProjectManagementContext";
import {ToastNotification} from "../../../../../global/components/feedback/ToastNotification";
import {BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import {useParams} from "react-router-dom";

function LinkProjectApplicationDrawer({ open, setOpen, afterSubmit }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const {id} = useParams()

    const handleLinkApplication = async (data) => {
        try {
            setLoading(true)
            await api.post(`/project_management/project/${id}/link_applications`, data)
            setOpen(false)
            afterSubmit && afterSubmit()
            ToastNotification(t("Application successfully linked"), "success")
        } catch (error){
            BuildFieldErrorsDict(error, null, false)
        }finally {
            setLoading(false)
        }
    }

    return (
        <Drawer
            title={
                <Space>
                    {t("Link project to a application")}
                    <Badge count={t("NEW")} color="#096DD9" />
                </Space>
            }
            open={open}
            onClose={() => setOpen(false)}
            footer={
                <Row justify="end">
                    <Button type="primary" onClick={form.submit} loading={loading}
                    data-cy="submit-link-application-button">
                        {t("Link")}
                    </Button>
                </Row>
            }
        >
            <Form layout="vertical" form={form} onFinish={handleLinkApplication}>
                <RequestTreeSelectFormField url={`/get_linkable_project_program_tree`}
                    multiple
                    optionsSettings={{
                        title: "name",
                        value: "id",
                        selectable: false,
                        children: {
                            name: "applications_json",
                            title: "title",
                            value: "id",
                        },
                    }}
                    form={{
                        label: t("Select the application"),
                        name: "applications",
                        required:true,
                    }}
                    data-cy="program-tree-select"
                />
            </Form>
        </Drawer>
    );
}

export default LinkProjectApplicationDrawer;
