import styled from 'styled-components';

export const StyledPhaseFeedBack = styled.div.attrs(() => ({
    className: 'applications'
}))`
 .moreInfo-left{
    display: flex;
    align-items: center;
    align-self: flex-start;
    font-size: 14px !important;
    width: 100%;
}

 .moreInfo-right{
    display: flex;
    align-items: center;
    align-self: flex-end;
    font-size: 14px !important;
    width: 100%;
}



    .buttons-modal{
    display: flex;
    justify-content: space-between;
    
    }
`;
