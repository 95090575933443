import React from 'react'
import styled from "styled-components";

const StyledDescription = styled.p`
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
    line-height: 23px;
    margin: 0;
  
  white-space: pre-line;
`

function Description(props) {
    return (
        <StyledDescription {...props}>{props.children}</StyledDescription>
    )
}

export default Description;