import {useTranslation} from "react-i18next";
import {Space, Typography} from "antd";
import styled from "styled-components";

const {Text} = Typography

const StyledSmallText = styled(Text)`
  font-size: 10px;
  font-weight: 400;
`

const StyledBoldText = styled(Text)`
  font-size: 14px;
  font-weight: 500;
`

export default function DisplayTextElements({title, content, mode = "inline", labelField, ...props}) {
    const {t} = useTranslation()

    const getValue = (item) => {
        if (!labelField || !item.hasOwnProperty(labelField)){
            return t(item)
        }
        return t(item[labelField])
    }

    const renderContent = () => {
        if (!(typeof content === "string")) {
            if (mode === "inline") {
                return (
                    <StyledBoldText {...props}>
                        {content?.map((item) => t(getValue(item))).join(", ")}
                    </StyledBoldText>
                )
            } else {
                return (
                    <>
                        {content?.map((item, key) => (
                            <StyledBoldText key={key}>{getValue(item)}</StyledBoldText>
                        ))}
                    </>
                )
            }
        }
        return <StyledBoldText {...props}>{content}</StyledBoldText>
    }


    return (
        <Space direction="vertical" size={0}>
            {title ? <StyledSmallText type="secondary">{t(title)}</StyledSmallText> : <></>}
            {content && renderContent()}
        </Space>
    )
}

