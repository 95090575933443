import React from "react";
import DefaultComponent from "./DefaultComponent";
import Input from "../../../Form/Input";
import {useTranslation} from "react-i18next";


function NumberForm(props) {
    const {t} = useTranslation()
    return (
        <DefaultComponent {...props}>
            <Input  placeholder={t('Enter your number here...')} />
        </DefaultComponent>
    )
}

export default NumberForm