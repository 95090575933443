import React from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {neutral_1, primary_9} from "../../../../../utils/Colors";
import {Tooltip} from "antd";
import RemixIcon from "../../../../../components/data_display/RemixIcon";
import {formatPointNumber, toFormatNumber} from "../../../../../utils/Utils";


const Container = styled.div`
    width: 100%;
    background: red;
    height: 348px;
    border-radius: 8px;
    overflow: hidden;
    background: ${neutral_1};
    box-shadow: 0px 2px 0px 0px #0000000B;
    cursor: pointer;
    //flex-shrink: 1;
`

const ImageContainer = styled.div`
    width: 100%;
    height: 240px;
    background: white;

    img {
        object-fit: contain;

        width: 100%;
        height: 100%;
    }
`
const Body = styled.div`
    padding: 16px;
    position: relative;
    height: 108px;
`
const Title = styled.span`
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
`

const Price = styled.span`
    //padding: 16px;
    color: ${primary_9};
    font-weight: bold;
    position: absolute;
    bottom: 16px;
    right: 16px;
`


export default function StoreItemCard({storeItem, ...props}) {
    const {t} = useTranslation();

    const priceFormated = formatPointNumber(storeItem?.price)
    return (

        <Container gap={16} {...props}>
            <ImageContainer>
                <img src={storeItem?.img_url}/>
            </ImageContainer>

            <Body>

                <Tooltip title={storeItem?.name}>
                    <Title>{storeItem?.name}</Title>
                </Tooltip>

                <Price><RemixIcon
                    remixIconName={'ri-copper-coin-line'}/>{priceFormated}
                </Price>
            </Body>
        </Container>

    );
}

