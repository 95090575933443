import {useTranslation} from "react-i18next";
import Table from "../../../../global/components/data_display/Table";
import { useEffect, useState } from "react";
import useTable from "../../../../global/hooks/useTable";
import { renderBadge, renderDate, renderInfo } from "../../../../global/components/tables/utils";
import { renderIndicator } from "./utils";
import api from "../../../../global/services/api";
import useEventListener from "../../../../global/hooks/useEventListener";
import { FileTextTwoTone } from '@ant-design/icons';

function StatusReportTable({projectId, onRowClick}){
    const {t} = useTranslation();
    const [indicatorColumns, setIndicatorColumns] = useState([]);
    const {
        tableData, tableParams, handleTableChange, isLoading, handleRequestTable
    } = useTable({
        url: `/project_management/table/${projectId}/status_report_table`,
    });
    useEventListener('update-status-reports', handleRequestTable);

    const fetchIndicatorColumns = async () => {
        const response = await api.get(`/project_management/table/status_report_table_columns`);
        const columns = response.data.map((col) => ({
            title: t(col.title),
            align: "center",
            dataIndex:  col.data_index,
            render: renderIndicator,
        }));
        setIndicatorColumns(columns);
    };

    useEffect(()=>{
        fetchIndicatorColumns();
    }, [])
    
    
    const columns = [
        {
            title: "N:",
            dataIndex: "order",
        },
        {
            title: t("Date"),
            dataIndex: "date",
            render: renderDate,
        },
        {
            title: t("Phase"),
            dataIndex: "phase",
            render: renderBadge,

        },
        {
            title: t("Attachments"),
            dataIndex: "attachments_count",
            render: (value)=>renderInfo(value, <FileTextTwoTone />),
        },
        ...indicatorColumns,
    ];

    return <Table 
            columns={columns}
            onRowClick={onRowClick}
            dataSource={tableData}
            tableParams={tableParams}
            onChange={handleTableChange}
            loading={isLoading}
        />
}

export default StatusReportTable;