import React from 'react';
import Drawer from "../../../../../../global/components/layout/Drawer";
import {useTranslation} from "react-i18next";
import { Space, Typography } from 'antd';
import styled from 'styled-components';
import ScoreEvaluationField from '../../../../../../global/components/data_entry/form/EvaluationFormBuild/Fields/ScoreEvaluationField';

const { Text, Title } = Typography;

const StyleText = styled(Text)`
    font-size: 16px;
`;

const ProgramTypeHelp = (t) => {
    return (
        <div>
            <Space direction='vertical' size="large">
                <StyleText>{t("Set the type of program")}:</StyleText>
                <Space>
                    <StyleText>
                        <StyleText strong>{t("Internal Innovation")}: </StyleText>
                        {t("Creates an exclusive program for the organization's employees, encouraging the generation of ideas and solutions within the corporate environment")}.
                    </StyleText>
                </Space>
                <Space>
                    <StyleText>
                        <StyleText strong>{t("External Innovation")}: </StyleText>
                        {t("It opens the program to the participation of anyone, whether internal or external to the organization, promoting a wide diversity of ideas and collaboration")}.
                    </StyleText>
                </Space>
                <Space>
                    <StyleText>
                        <StyleText strong>{t("Mapping of Challenges")}: </StyleText>
                        {t("Focuses on identifying and structuring specific challenges, which will be used later in innovation programs, both internal and external")}.
                    </StyleText>
                </Space>
            </Space>
        </div>
    )
}

const ProgramDurationHelp = (t) => {
    return (
        <div>
            <Space direction='vertical' size="large">
                <StyleText>
                    <StyleText strong>{t("Continuous")}: </StyleText>
                    {t("Creates a program without an end date, allowing a continuous flow of ideas and activities. Continuous programs do not have end dates for stages, favoring constant innovation.")}
                </StyleText>

                <StyleText>
                    <StyleText strong>{t("Temporary")}: </StyleText>
                    {t("Creates a program with a start and end date, providing a defined period for the execution of activities. Temporary programs have specific dates for each stage, allowing structured planning.")}
                </StyleText>
            </Space>
        </div>
    )
}

const ProgramStatusHelp = (t) => {
    return (
        <div>
            <Space direction="vertical" size="large">
                <StyleText>
                    <Text strong>{t("Active")}: </Text>
                    {t("The program is displayed in the environment.")}
                </StyleText>
                <StyleText>
                    <Text strong>{t("Inactive")}: </Text>
                    {t("The program is not displayed in the environment but still accepts applications.")}
                </StyleText>
                <StyleText>
                    <Text strong>{t("Archived")}: </Text>
                    {t("The program is not displayed in the environment but still accepts applications.")}
                </StyleText>
            </Space>
        </div>
    )
}

const SimplifiedRegistration = (t) => {
    return (
        <div>
            <Space direction="vertical" size="large">
                <StyleText>
                    {t("Allows participants to register for the program without having a SOLV account. Participants will need to fill out their name, email, and phone number, and then they can register for the program.")}
                </StyleText>
                <StyleText>
                    {t("You can embed the registration form directly on your website or send the form link to participants.")}
                </StyleText>
            </Space>
        </div>
    )
}

const AutomaticAllocation = (t) => {
    return (
        <div>
            <Space direction="vertical" size="large">
                <StyleText>
                    {t("When adding reviewers to the system, they will be randomly assigned to applications. Priority is given to the reviewer with fewer assignments currently assigned to them.")}
                </StyleText>
                <StyleText>
                    {t("Enabling this option also activates the automatic allocation button, allowing you to allocate reviewers automatically whenever you want.")}
                </StyleText>
            </Space>
        </div>
    )
}

// const RatingScaleExample = (t) => {
//     return (
//         <div>
//             <Space direction='vertical' size="large">
//                 <Title level={5}>
//                     {t('Example question')}
//                 </Title>
//                 <Text style={{fontSize:14}}type={'secondary'}>{t('The data below is fictitious, just an example, the weight and other elements of the question are changed in the form that can be found in the evaluation stages.')}</Text>
//                 <ScoreEvaluationField min={0} max={5} help_text={t('In financial and time terms.')} label={t('How viable is the solution?')} weight={50}/>
//             </Space>
//         </div>
//     )
// }

export default function ProgramConfigOffCanvas({help, setHelp}) {

    const {t} = useTranslation()

    const renderHelp = () => {
        switch (help) {
            case "Program Type":
                return ProgramTypeHelp(t)
            case "Duration Type":
                return ProgramDurationHelp(t)
            case "Program Status":
                return ProgramStatusHelp(t)
            case "Allow Registration without login and authentication":
                return SimplifiedRegistration(t)
            case "Automatic allocation of evaluators":
                return AutomaticAllocation(t)
            // case "Rating scale":
            //     return RatingScaleExample(t)
        }
    }

    return (
        <Drawer open={help} setOpen={setHelp} title={t(help)} loading={!help}>
            {renderHelp()}
        </Drawer>
    );
}

