import {DualAxes} from "@ant-design/plots";
import {useTranslation} from "react-i18next";
import LoadingFull from "../../../../../global/components/other/LoadingFull";
import {
    BuildFieldErrorsDict,
    getMaxValueFromArray
} from "../../../../../global/utils/Utils";
import api from "../../../../../global/services/api";
import Empty from "../../../../../global/components/data_display/Empty";
import {useEffect, useState} from "react";

const DEFAULT_CONFIG_REQUEST = {
    version: 'v1'
}
export default function BarLineChart({
                                         request,
                                         xField,
                                         yField,
                                         expand,
                                     }) {
    const {t} = useTranslation();
    const [data, setData] = useState();

    const getTooltipConfig = (d, column) => {
        const field = column.y.field
        return ({
            name: t(field),
            value: d[field],
        })
    }

    const maxDomain = Math.max(
        getMaxValueFromArray(data, yField[0]),
        getMaxValueFromArray(data, yField[1])
    );

    const scaleY = {domain: [0, maxDomain]}
    const config = {
        autoFit: true,
        xField,
        yField,
        data: data,
        animate: null,
        legend: {
            color: {
                itemMarker: (v) => {
                    if (v === yField[0]) return 'circle'
                    return 'smooth'
                },
                itemLabelText: (datum) => t(datum.label),
                itemLabelFontSize: 15,
                cols: 2,
                size: 30,
                colPadding: 30,
                position: 'bottom',
                layout: {
                    justifyContent: 'flex-start',
                    alignItems: 'flex-end',
                },
            }
        },
        children: [
            {
                type: 'interval',
                yField: yField[0],
                scale: {y: scaleY}
            },
            {
                type: 'line',
                yField: yField[1],
                scale: {
                    color: {relations: [[yField[1], '#14C9C9']]},
                    y: scaleY
                },
                style: {lineWidth: 2},
                axis: {y: false}
            },
            {
                type: 'point',
                sizeField: 5,
                yField: yField[1],
                scale: {
                    color: {relations: [[yField[1], '#14C9C9']]},
                    y: scaleY,
                },
                style: {lineWidth: 2, fill: '#fff'},
                axis: {y: false}
            },

        ],
        axis: {
            x: {
                labelAutoRotate: false,
                labelAutoHide: true,
                tickLength: 0,
            },
            y: {
                tickCount: 10,
                tickFilter: Number.isInteger,
                tickLength: 0,
            }
        },
        tooltip: {
            title: (d) => d[xField],
            // só funciona com o código repetido ;)
            items: [
                (d, index, data, column) => (getTooltipConfig(d, column)),
                (d, index, data, column) => (getTooltipConfig(d, column)),
            ]
        },
    };

    const fetchData = async () => {
        try {
            const config = {
                params: {
                    expand,
                    ...request?.params
                },
                ...DEFAULT_CONFIG_REQUEST,
                ...request.config
            }
            let response;
            if (request?.body) {
                response = await api.post(request?.url, request?.body, config);
            } else {
                response = await api.get(request?.url, config);
            }
            setData(response.data);
        } catch (error) {
            console.error(error);
            BuildFieldErrorsDict(error, null, false);
        }
    };

    useEffect(() => {
        void fetchData();
    }, [JSON.stringify(request)]);

    return (
        <>
            {data === undefined && <LoadingFull/>}
            {data?.length === 0 && <Empty/>}
            {data?.length >= 1 && <DualAxes {...config} />}
        </>
    );
}
