import {Form, InputNumber, Switch} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

import InformationCard from "../../../../../components/data_display/InformationCard";
import TransparentCollapse
    from "../../../../../components/misc/TransparentCollapse";
import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import CollapseFormItem
    from "../../../../../components/data_entry/CollapseFormItem";


export default function SectionScoring({FORM_NAMES}) {
    const {t} = useTranslation();
    const manualPointsValidationsValue = Form.useWatch(FORM_NAMES.MANUAL_POINTS_VALIDATION)

    return (

        <TransparentCollapse header={t("Scoring")}>
            <FlexContainer direction={'column'} gap={16} align={'streech'}>
                <InformationCard title={t('Point processing')}
                                 text={t('Points generated by shares have a processing period in which you can rectify them, before they are added to the portfolio. After this period, you can no longer rectify the shares.')}/>
                <CollapseFormItem

                    input={<Switch data-cy="manual-validation-switch"/>}
                    extraFormItemProps={{valuePropName: "checked"}}
                    name={FORM_NAMES.MANUAL_POINTS_VALIDATION}
                    help={t(
                        "If enabled, all points transactions will need to be manually confirmed during the processing period. Depending on the size of your business, there may be hundreds or thousands of transactions to process."
                    )}
                    label={t("Manual validation of points")}
                />

                <CollapseFormItem
                    input={<InputNumber disabled={manualPointsValidationsValue} min={1} data-cy="days-to-validate-points-input"/>}

                    name={FORM_NAMES.DAYS_TO_VALIDATE_POINTS}
                    help={t(
                        "While points are being processed, you can rectify actions and reverse the points granted. After this period, it is not possible to rectify the user's points. The default value is 7 days."
                    )}
                    label={t("Time to process points (in days)")}
                />
            </FlexContainer>

        </TransparentCollapse>

    );
}
