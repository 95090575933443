import {useTranslation} from "react-i18next";


export default function useOptionsTranslation() {
    const {t} = useTranslation();

    const translationOptions = (values) => {
        return values?.map(({label, ...items}) => ({label: t(label), ...items}))
    }

    return {translationOptions}
}