import React from 'react'
import {
    Col,
    Row,
    Typography,
    Form as FormAntd
} from 'antd';
import {
    FlexContainer,
    QuestionContainer,
    QuestionTitle
} from "../../../../authentication/StyledComponents";
import WideSpace from "../../../../layout/WideSpace";
import {useTranslation} from "react-i18next";
import DangerousHtmlPreviewer
    from "../../../../data_display/DangerousHtmlPreviewer";
import Button from "../../../Button";
import FieldError from "./FieldError";
import Required from "./Required";


const TYPES = {
    TRUE_VALUE: 'true',
    FALSE_VALUE: 'false'
}

export default function YesNoEvaluationField({
                                                 label,
                                                 help_text,
                                                 weight,
                                                 show_feedback,
                                                 onChange,
                                                 value,
                                                 name,
                                                 min = 0,
                                                 max = 10,
                                                 ...props
                                             }) {
    const {t} = useTranslation()
    const {status} = FormAntd.Item.useStatus(); //error
    const hasError = status === 'error'


    function validActive(internalValue) {
        return value?.value === internalValue ? 'primary' : 'default'
    }

    function handleOnChange(internalValue) {
        onChange?.({
            ...value,
            value: value.value === internalValue ? '' : internalValue
        })
    }

    function checkHasError() {
        return hasError && !value.value
    }

    return (

        <QuestionContainer>
            <Row gutter={[19, 19]}>
                <Col xs={24}>
                    <Row>
                        <Col xs={24} md={19} lg={20} xl={21}>
                            <WideSpace direction={'vertical'} size={0}>
                                <QuestionTitle>
                                    <Required required/>
                                    {label}
                                </QuestionTitle>
                                <Typography.Text
                                    type={'secondary'}><DangerousHtmlPreviewer
                                    content={help_text}/></Typography.Text>
                            </WideSpace>
                        </Col>
                    </Row>
                </Col>
                {/*<Col xs={24}>*/}
                {/*    <Row gutter={[8, 8]}  >*/}
                {/*        <Col xs={12}>*/}
                {/*            <Button type={validActive(TYPES.FALSE_VALUE)} block*/}
                {/*                    onClick={() => handleOnChange(TYPES.FALSE_VALUE)}>{t('No')}</Button>*/}
                {/*        </Col>*/}
                {/*        <Col xs={12}>*/}
                {/*            <Button type={validActive(TYPES.TRUE_VALUE)} block*/}
                {/*                    onClick={() => handleOnChange(TYPES.TRUE_VALUE)}>{t('Yes')}</Button>*/}
                {/*        </Col>*/}

                {/*    </Row>*/}
                {/*    {checkHasError() && <FieldError/>}*/}
                {/*</Col>*/}
                <Col xs={24} data-cy="yes-no-container">
                    <FlexContainer gap={8} style={{
                        border: checkHasError() ? '1px solid red' : 'none',
                        padding: checkHasError() ? 16 : 0
                    }}>

                        <Button type={validActive(TYPES.FALSE_VALUE)} block
                                onClick={() => handleOnChange(TYPES.FALSE_VALUE)}>{t('No')}</Button>


                        <Button type={validActive(TYPES.TRUE_VALUE)} block
                                onClick={() => handleOnChange(TYPES.TRUE_VALUE)}>{t('Yes')}</Button>


                    </FlexContainer>
                    {checkHasError() && <FieldError/>}
                </Col>

            </Row>

        </QuestionContainer>


    )
}
