import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import styled from "styled-components";
import RemixIcon from "./RemixIcon";
import {
    gold_1,
    polar_green_6,
    polar_green_7,
    polar_green_8
} from "../../utils/Colors";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import {FlexContainer} from "../authentication/StyledComponents";
import LimitNumber from "./LimitNumber";
import SolvTag from "../tag/SolvTag";
import {
    DateFormatByUserTimezone,
    DatetimeFormatByTimezone
} from "../../utils/dates";
import Button from "../data_entry/Button";
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    RollbackOutlined
} from "@ant-design/icons";
import {Col, Form as FormAnt, Modal, Row, Tooltip} from "antd";
import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../utils/Utils";
import Drawer from "../layout/Drawer";
import Form from "../data_entry/form/Form";
import InputNumberFormField from "../data_entry/form/InputNumberFormField";
import TextAreaFormField from "../data_entry/form/TextAreaFormField";
import CheckboxFormField from "../data_entry/form/CheckboxFormField";
import ShortInformation from "./ShortInformation";
import {ToastNotification} from "../feedback/ToastNotification";
import * as ConstantsMessage from "../../utils/fileWithMessage";
import {generateGamificationsActionsMessage} from "../../utils/Constants";
import TagActionGamification from "./TagActionGamification";


const Title = styled.span`
    font-size: 14px;
    font-weight: 500;
`


const Container = styled.div`
    width: 100%;
    padding: 16px 24px;
    background: white;
    border-radius: 8px;
`
const IconContainer = styled.div`
    width: 15px;
    height: 15px;

    display: flex;
    justify-content: center;
    align-items: center;

    & > * {
        font-size: 15px;
    }
`

const Points = styled.span`
    font-weight: bold;
    font-size: 16px;
    text-decoration: ${({isRevoked}) => isRevoked ? 'line-through' : 'unset'};
`
const ValidateButton = styled(Button)`
    &:not(:disabled) {
        background: ${polar_green_7} !important;
        border: 1px solid ${polar_green_8} !important;

        &:hover {
            border-color: ${polar_green_8} !important;
            background: ${polar_green_6} !important;
        }
    }
`
export const ICONS_TYPE_TRANSACTION = {
    'entry': <RemixIcon remixIconName={'ri-arrow-up-line'} style={{color: 'green'}}/>,
    'leave': <RemixIcon remixIconName={'ri-arrow-down-line'} style={{color: 'red'}}/>,
}


export default function TransactionGamificationCard({
                                                        setHasChange,
                                                        noActions = false,
                                                        id,
                                                        name,
                                                        type_transaction,
                                                        description,
                                                        type_action,
                                                        created_at,
                                                        dropInfo = false,
                                                        status = 'revoked',
                                                        total_points
                                                    }) {
    const {t} = useTranslation()
    const {isPhone} = useSystemBreakpoint()
    const [form] = FormAnt.useForm()
    const [_status, _setStatus] = useState(status)
    const [disabledButtons, setDisabledButtons] = useState(false)
    const [isLoading, setIsLoading] = useState()
    const [open, setOpen] = useState(false)
    const internalDrop = dropInfo || isPhone


    useEffect(() => {
        if (_status !== 'pending' || type_transaction === 'leave') {
            setDisabledButtons(true)
        }
    }, [_status]);

    function handleValidate() {
        Modal.confirm({
            title: t("Validate"),
            icon: <ExclamationCircleOutlined/>,
            content: t("Are you sure you want to validate the points?"),
            onOk: async () => {
                try {
                    setIsLoading(true);

                    await api.post(`/action/${id}/validate`);
                    setHasChange?.(true)
                    _setStatus('consolidated')
                } catch (error) {
                    BuildFieldErrorsDict(error, null, null)
                } finally {
                    setIsLoading(false);
                }
            },
            okButtonProps:{"data-cy": "confirm-validate-button"},
            cancelButtonProps: {loading: isLoading},
            okText: t('Yes'),
            cancelText: t('No')

        });
    }

    async function handleRevoke(data) {
        Modal.confirm({
            title: t("Revoke"),
            icon: <ExclamationCircleOutlined/>,
            content: t("Are you sure you want to revoke the points?"),

            onOk: async () => {
                try {
                    setIsLoading(true);

                    await api.post(`/action/${id}/revoke`, data);
                    setHasChange?.(true)
                    ToastNotification(t(ConstantsMessage.SUCCESS))
                    _setStatus('revoked')
                    setOpen(false)
                } catch (error) {
                    BuildFieldErrorsDict(error, form.setField, null)
                } finally {
                    setIsLoading(false);
                }
            },
            cancelButtonProps: {loading: isLoading},
            okText: t('Yes'),
            cancelText: t('No')

        });

    }


    return (
        <Container>
            <FlexContainer gap={8} align={'center'}>
                {/*<IconContainer>*/}
                    {ICONS_TYPE_TRANSACTION[type_transaction]}
                {/*</IconContainer>*/}
                <Points isRevoked={_status === 'revoked'}>
                    <LimitNumber
                        value={total_points}>{total_points}</LimitNumber>
                </Points>
                <TagActionGamification status={_status}/>
            </FlexContainer>
            <FlexContainer gap={internalDrop ? 4 : 8} align={'start'}
                           wrap={'wrap'}
                           direction={internalDrop ? 'column' : 'row'}>
                <Info icon={<RemixIcon
                    remixIconName={'ri-calendar-2-line'}/>}
                      text={DatetimeFormatByTimezone(created_at, false)}/>
                <Info icon={<RemixIcon remixIconName={'ri-barcode-fill'}/>}
                      text={`${t('ID da Transação')}: ${id}`}/>
                <Info
                    icon={<RemixIcon remixIconName={'ri-clipboard-line'}/>}
                    showTooltip
                    text={t(generateGamificationsActionsMessage(type_action, description))}/>
                <Info icon={<RemixIcon remixIconName={'ri-user-line'}/>}
                      text={name}/>
            </FlexContainer>
            {!noActions && <Row gutter={[16, 16]} style={{marginTop: 16}}>
                <Col xs={12}><ValidateButton isLoading={isLoading}
                                             disabled={disabledButtons}
                                             onClick={handleValidate} block
                                             data-cy={`validate-points-button-${id}`}
                                             icon={
                                                 <CheckCircleOutlined/>}>{t('Validate')}</ValidateButton></Col>
                <Col xs={12}> <Button isLoading={isLoading}
                                      data-cy={`revoke-points-button-${id}`}
                                      disabled={disabledButtons} block
                                      onClick={() => setOpen(true)}
                                      icon={<RemixIcon
                                          remixIconName={'ri-arrow-go-back-line'}/>}
                                      danger>{t('Revoke')}</Button></Col>
            </Row>}


            <Drawer isGrayBody title={t('Revoke')} open={open}
                    onClose={() => setOpen(false)} footer={
                <FlexContainer justify="end">
                    <Button isLoading={isLoading}
                            disabled={disabledButtons}
                            onClick={form.submit}
                            icon={<RemixIcon
                                remixIconName={'ri-arrow-go-back-line'}/>}
                            danger>{t('Revoke')}</Button>
                </FlexContainer>
            }>
                <Form form={form} disabled={isLoading}
                      onFinish={handleRevoke}>


                    <TextAreaFormField form={{
                        name: 'justification_invalid',
                        label: t('Justification'), rules: [{
                            required: true,
                            message: t('This field is require')
                        }],
                    }}/>
                </Form>
            </Drawer>

        </Container>
    )
}

const StyledFlexContainer = styled(FlexContainer)`
    font-size: 14px;
    color: #00000073;
`

const Text = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 200px;
`

function Info({icon, text, showTooltip = false}) {
    return (

        <StyledFlexContainer gap={8}>
            {icon}
            <Tooltip title={showTooltip && text}>
                <Text style={{whiteSpace: 'nowrap'}}>{text}</Text>
            </Tooltip>

        </StyledFlexContainer>
    )
}