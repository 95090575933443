import {Col, Row, Skeleton} from "antd";
import ColumnChart from "../components/ColumnChart";
import ChartCard
    from "../../../../../global/components/data_display/ChartCard";
import PieGraph from "../components/PieChart";
import RoseChart from "../components/RoseChart";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SolvCollapse from "../../../../../global/components/misc/SolvCollapse";
import GraphRow from "../components/GraphRow";
import GraphCol from "../components/GraphCol";


export default function SectionDashboardProgramCompanyData({filters}) {
    let {id} = useParams();
    const {t} = useTranslation();


    return (


        <SolvCollapse
            title={t('Company data')}
            bordered
            // active={true}
        >
            <GraphRow>
                <GraphCol>
                    <ChartCard title={t("Company by persona")}
                               expandible={true}>
                        <ColumnChart
                            request={{
                                url: `/dashboard/program_dashboard/${id}/applications_by_persona`,
                                body: filters,
                                config: {version: 'v2'}

                            }}
                            legend_key="persona_name"
                        />
                    </ChartCard>
                </GraphCol>

                <GraphCol>
                    <ChartCard title={t("Company size")} expandible={true}>
                        <ColumnChart
                            request={{
                                url: `/dashboard/program_dashboard/${id}/applications_by_team_size`,
                                body: filters,
                                config: {version: 'v2'}

                            }}
                            legend_key="size"

                        />
                    </ChartCard>
                </GraphCol>

                <GraphCol>
                    <ChartCard title={t("Companies by sectors")}
                               expandible={true}>
                        <PieGraph
                            request={{
                                url: `/dashboard/program_dashboard/${id}/applications_by_institution`,
                                config: {
                                    version: 'v2'
                                },
                                body: filters,
                            }}
                            legend_key="institution_name"/>
                    </ChartCard>
                </GraphCol>

                <GraphCol>
                    <ChartCard title={t("Companies by technology")}
                               expandible={true}>
                        <RoseChart
                            request={{
                                url: `/dashboard/program_dashboard/${id}/applications_by_technology`,
                                config: {
                                    version: 'v2'
                                },
                                body: filters,
                            }}
                            legend_key="technology_name"/>
                    </ChartCard>
                </GraphCol>

                <GraphCol>
                    <ChartCard
                        title={t("Companies by type of transaction")}
                        expandible={true}>
                        <PieGraph
                            request={{
                                url: `/dashboard/program_dashboard/${id}/applications_by_transaction`,
                                config: {
                                    version: 'v2'
                                },
                                body: filters,
                            }} legend_key="transaction_name"
                            showPercentLabel={false}/>
                    </ChartCard>
                </GraphCol>
            </GraphRow>
        </SolvCollapse>


    );
}