import { Typography} from "antd";
import React from "react";
import {DotBadge} from "../../utils/Render";
import LimitedSpace from "../responsive/LimitedSpace";
import TruncateText from "../responsive/TruncateText";

const {Text} = Typography

export default function DotInfoCard({icon, showDot=true,label, info, ...props}) {
    return (
        <LimitedSpace>
            {showDot && <DotBadge/>}
            <TruncateText>
                <Text type="secondary" style={{fontSize: 12}}>
                    {icon} <strong>{label}:</strong> {info}
                </Text>
            </TruncateText>
        </LimitedSpace>
    )
}

