import React from 'react'
import styled from 'styled-components'

const StyledError = styled.label`
  font-style: normal;
  font-size: 14px;
  line-height: 23.44px;
  color: ${({hasError}) => hasError ? '#aa392d' : '#949494'};
  text-align: end;
  transform: translateY(-20px);
  margin-right: 5px;
`

export default function CharactersCounter({value, maxLenght}) {
    return (
        <>
            {value && maxLenght !== 9999 &&
                <StyledError hasError={value.length > maxLenght}>{value.length}/{maxLenght}</StyledError>}
        </>
    )
}