import React from "react";
import {Line} from "@ant-design/plots";
import {useTranslation} from "react-i18next";
import {DateFormatUser} from "../../../../../../global/utils/dates";
import {useUserContext} from "../../../../../../global/context/UserContext";

function TendencyLineChart({data, indicator, min = -5, max = 5}) {
    const {t} = useTranslation();
    const xField = "status_report_date"
    const yField = "value_display"
    const {_user} = useUserContext();

    const config = {
        data,
        xField,
        yField,
        height:300,
        shapeField: 'line',
        colorField: yField,
        padding: 40,
        autoFit: true,
        legend: null,
        animate: null,
        axis: {
            x: {
                title: "Checkpoint",
                tickLength: 0,
                labelFormatter: (text) => text.slice(0, 10),
                labelAutoRotate: true,
            },
            y: {title: t("Value"), tickLength: 0},
        },
        style: {
            gradient: 'y',
            lineWidth: 1.5,
            lineJoin: 'round',
        },
        scale: {
            y: {type: 'linear', domain: [min, max], tickCount: 10},
            color: {
                type: 'threshold',
                domain: [0],
                range: ['red', 'blue'],
            }
        },
        tooltip: {
            title: ({order, status_report_date}) => (
                `Checkpoint Nº${order} - ${status_report_date.slice(0,10)}`
            ),
            items: [(d) => (
                {
                    name: t(indicator.name),
                    color: d[yField] <= 0 ? "red" : "blue",
                    value: d[yField]
                })
            ],
        },
    };
    return <Line {...config} />;
}

export default TendencyLineChart;