import styled from "styled-components";
import {neutral_1} from "../../../../global/utils/Colors";
import {InputNumber} from "antd";
export const ComponentMainRow = styled.div`
  margin: 0;
  padding: 5px;
  width: 100%;
  display: flex;
  gap: 4px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  flex-wrap: wrap;
`
export const Description = styled.textarea`
  color: #262626;
  font-size: 18px;
  width: 100%;
  //border: 1.5px dashed #cbcbcb;
  border-color: #fafafa;
  overflow-y: scroll;
  -ms-overflow-style: none;
  padding: 4px;

  &::-webkit-scrollbar {
    display: none;
  }

  ::placeholder{
    color: #878787;
  }
  
  &:focus-visible {
    //border: 1.5px dashed #cbcbcb;
    border-radius: 4px;
    outline: none;
  }
`

export const LabelTextAreaInput = styled.textarea`
  width: 100%;
  //margin-left: 12px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  color: #262626;
  font-size: 16px;
  line-height: 16px;
  background: inherit;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  border-color: #fafafa;

  &:focus-visible {
    outline: none;
  }
  
  ::placeholder{
    color: #878787;
  }
  
  overflow: hidden;
  resize: none;
`

export const LabelInput = styled.input`
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  background: inherit;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px;
  border: none;
  color: #949494;

  &:focus-visible {
    outline: none;
  }

  resize: none;
`
export const Checkbox = styled.input`
  width: 16px;
  height: 16px;
  margin-right: 2px;
  border-radius: 6px;
  border: 1px solid rgb(221, 221, 221);

  outline: none;
`

export const HelpText = styled.label`
  color: #949494;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 4px;
  width: 100%;
  white-space: pre-line;
`
export const HeaderInput = styled(LabelTextAreaInput)`
  font-size: 28px;
  line-height: 28px;
  width: calc(100% - 53px);
`

export const ParagraphInput = styled(LabelTextAreaInput)`
  font-size: 20px;
  line-height: 20px;
  width: calc(100% - 53px);
`

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  // margin-bottom:7px;
`;

export const ContentNoPointerEvents = styled(Content)`
  pointer-events: none;
  flex-direction: column;
  align-items: flex-start;

`
export const InputContent = styled(Content)`
  flex: 10;

`
export const ButtonsContent = styled(Content)`
  flex: 1;
  justify-content: end;
`
export const RenderedContent = styled(Content)`
  display: ${(props) => props.show ? 'none' : 'flex'};
  margin: 0;
  padding: 0;
  width: 100%;
  align-items: baseline;
`;

export const RenderedContentColumn = styled(RenderedContent)`
  display: ${(props) => props.show ? 'none' : 'flex'};
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  align-items: baseline;
`;

export const OptionsContent = styled(RenderedContent)`
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 28px;
  margin-top: 8px;
`;

export const ChoicesContent = styled(RenderedContent)`
  flex-direction: column;
  align-items: flex-start;
`

export const RemoveButtonText = styled.p`
  margin: -10px 0 0 0;
  padding: 0;
  font-size: 8px;
`
export const RemoveButton = styled.button`
  padding: 8px 8px 3px 8px;
  background: inherit;
  border: none;
  margin: 0;

  &:hover {
    background: #f8f8f8;
  }
`;

export const RemoveChoiceButton = styled.button`
  padding: 0 0 0 5px;
  background: ${neutral_1};
  border: none;
  margin: 0px 16px 0px 0px;

  &:hover {
    background: #f8f8f8;
  }
`;

export const OpenOptionsButton = styled(RemoveButton)`
`;

export const OptionInput = styled.input`
  margin-left: ${(props) => props.newOption ? '5px' : '5px'};
  margin-top: 3px;
  border: ${(props) => props.newOption ? ' 1.5px dashed #cbcbcb' : 'none'};
  max-width: 90%;
  width: min-content;

  &:focus-visible {
    outline: none;
    border: 1.5px dashed #cbcbcb;
    border-radius: 4px;
  }
`

export const MaxLenghtInput = styled.input`
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 3px;
  border: 1.5px dashed #cbcbcb;
  width: inherit;

  &:focus-visible {
    outline: none;
    border: 1.5px dashed #cbcbcb;
    border-radius: 4px;
  }
`

export const ImportButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  border-radius: 6px;
  border: inherit;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  background-color: #c6c6c6 !important;
  color: white !important;
  width: 100%;
  margin-top: 8px;

  &:hover {
    color: #4a718d;
  }
`;

export const DropableContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
`;

export const DraggableContent = styled(DropableContent)`
  // min-height:320px;
  margin-bottom: 4px;
  max-height: 50vh;
`


//RENDERED


export const InputRendered = styled.input`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  background: #F8F8F8;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px;
  border: none;
  color: #949494;
  width: 100%;

  &:focus-visible {
    outline: none;
  }
`

export const ScoreInput = styled(LabelInput)`
  margin-left: 3px;
  border: 1px solid rgb(221, 221, 221);
  //background: #F8F8F8;
`
export const StyledInputNumber = styled(InputNumber)`
  margin-left: 3px;
  border: 1px solid rgb(221, 221, 221);
  //background: #F8F8F8;
`
export const RemainingScore = styled.label`
  margin: 0;
  padding: 0;
  color: #949494;
  font-size: 10px;
  cursor: pointer;
`