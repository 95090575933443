import { useTranslation } from "react-i18next";
import ExtraHeader from "../../../modules/project/components/layout/ExtraHeader";
import { useLayoutContext } from "../../../modules/project/components/layout/context/LayoutContext";
import { useEffect } from "react";
import ReleaseNoteCard  from "./ReleaseNoteCard";
import { useUserContext } from "../../context/UserContext";
import { useState } from "react";
import Loading from './../../components/feedback/Loading';
import api from './../../services/api';
import WideSpace from "../../components/layout/WideSpace";

  
function UpdatesPage() {
    const {initializeLayout} = useLayoutContext();
    const {t} = useTranslation();
    const {updateSummaryCounts} = useUserContext();
    const [releases, setReleases] = useState();

    const pageLayoutSettings = {
        extraHeaderContent: <ExtraHeader left={t("Updates")} goBack={false}/>
    }
    
    const fetchReleases = async () => {
        try {
            const response = await api.get('v2/releases', {});
            setReleases(response.data);
        } catch (error) {}
    }

    useEffect(() => {
        initializeLayout(pageLayoutSettings);
        updateSummaryCounts({"unseen_releases": 0})
    }, []);

    useEffect(()=>{
        !releases && fetchReleases();
    }, [releases]);

    return (
        <>
            {releases ? 
                <WideSpace direction='vertical' size="large">
                    {releases.map((release, index) => (
                        <ReleaseNoteCard key={index} release={release} />
                    ))}
                </WideSpace>:
                <Loading />
            }
        </>
    );
}

export default UpdatesPage;