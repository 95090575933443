import React from 'react';
import './styles.css';
import Filter from '../../assets/Icon/filter.svg'
import Delete from '../../assets/Icon/delete_outline.svg'
import Edit from '../../assets/Icon/edit.svg'
import Eye from '../../assets/Icon/eye.svg'
import Expand from '../../assets/Icon/maximize.svg'
import Done from '../../assets/Icon/done.svg'
import Clear from '../../assets/Icon/clear.svg'
import Disabled from "../../assets/Icon/denied.svg"

function IconButton({onClick, iconImage, type, buttonRef, disabled, ...props}) {
    const ICONS = {
        "Delete": Delete,
        "Filter": Filter,
        "Edit": Edit,
        "Eye": Eye,
        "Expand": Expand,
        "Done": Done,
        "Clear": Clear,
        "Disabled":Disabled
    }

    return (
        <button disabled={disabled} ref={buttonRef} onClick={onClick} className="icon_button-container" {...props}>
            <img alt={'icon'} src={type ? ICONS[type] : iconImage}/>
        </button>
    );
}

export default IconButton;