import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import styled from "styled-components";
import {neutral_5} from "../../../../../utils/Colors";
import {Col, Image, Modal, Row, Typography} from "antd";
import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import useDefaultGetRequest from "../../../../../hooks/useDefaultGetRequest";
import Drawer from "../../../../../components/layout/Drawer";
import LimitNumber from "../../../../../components/data_display/LimitNumber";
import {
    BuildFieldErrorsDict,
    formatPointNumber,
    toFormatNumber
} from "../../../../../utils/Utils";
import GamificationInfo
    from "../../../../../components/data_display/GamificationInfo";
import purchaseConfirmImg
    from '../../../../../../assets/img/purchase_confirm_img.png';
import Button from "../../../../../components/data_entry/Button";
import {
    ToastNotification
} from "../../../../../components/feedback/ToastNotification";
import api from "axios";


const InfoContainer = styled.div`
    width: 100%;
    border-radius: 11px;
    padding: 16px;
    border: 1px solid ${neutral_5};
    background: white;
`


export default function DrawerBuyStreItem({
                                              id,
                                              open,
                                              setOpen
                                          }) {

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

    const {t} = useTranslation();


    function onClose() {
        setOpen(false)
    }

    const {
        data: storeItem,
        isLoading
    } = useDefaultGetRequest({
        url: `/store_itens/${id}`,
        makeRequest: open,
        dependence: [open]
    })


    function handleBuy() {
        Modal.confirm({
            icon: null,
            content: <Row gutter={[8, 8]}>
                <Col xs={24}><FlexContainer justify={'center'}>
                    <img src={purchaseConfirmImg}/>
                </FlexContainer></Col>
                <Col xs={24}><Typography.Title
                    level={5}> {t('Confirm purchase')}</Typography.Title></Col>
                <Col
                    xs={24}> {t('obs_purchase_item', {item: storeItem?.name})}</Col>
                <Col
                    xs={24}> {t("SOLV is not responsible for the processing or delivery of the purchased item. The processing and shipping of purchases is done by your company's management.")}</Col>
            </Row>,
            onOk: async () => {
                try {
                    setIsLoadingSubmit(true);
                    const response = await api.post(`store_purchase`, {item: storeItem?.id});

                    ToastNotification(t("Successfully"));
                    onClose()
                } catch (error) {
                    BuildFieldErrorsDict(error, null, false);
                } finally {
                    setIsLoadingSubmit(false);
                }
            },
            okText: t('Confirm'),
            cancelText: t('Go back')

        })
    }

    return (
        <>
            <Drawer loading={isLoading} isGrayBody title={t('See item')}
                    open={open}
                    onClose={onClose}


                    footer={<FlexContainer justify="end">
                        <Button
                            onClick={handleBuy}
                            loading={isLoadingSubmit}
                        >{t("Buy")}</Button></FlexContainer>
                    }>
                <Row gutter={[16, 16]}>
                    <Col>
                        <Image src={storeItem?.img_url}/>
                    </Col>
                    <Col xs={24}>
                        <InfoContainer>
                            <Row gutter={[8, 8]}>
                                <Col xs={24} sm={12}>
                                    <GamificationInfo title={t('Item')}
                                                      value={storeItem?.name}/>
                                </Col>
                                <Col xs={24} sm={6}>
                                    <GamificationInfo title={t('Stock')}
                                                      value={formatPointNumber(storeItem?.stock)}/>
                                </Col>
                                <Col xs={24} sm={6}>
                                    <GamificationInfo title={t('Price')}
                                                      value={<LimitNumber
                                                          value={storeItem?.price}/>}/>
                                </Col>
                                <Col xs={24}>
                                    <GamificationInfo title={t('Description')}
                                                      value={storeItem?.description}/>
                                </Col>
                            </Row>
                        </InfoContainer>
                    </Col>

                </Row>

            </Drawer>
        </>
    )
}
