import React, {useEffect, useRef, useState} from 'react';
import api from "../../../../../global/services/api";
import {useProjectManagementContext} from "../context/ProjectManagementContext";
import {Button, Card, Col, Row, Skeleton, Space, Typography} from "antd";
import styled from "styled-components";
import DangerousHtmlPreviewer from "../../../../../global/components/data_display/DangerousHtmlPreviewer";
import Carousel from "../../../../../global/components/misc/Carousel";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";
import FileIconLinks from "../../../../../global/components/misc/FileIconLinks";
import UserAvatar from "../../../../../global/components/misc/UserAvatar";
import tinycolor from "tinycolor2";
import {Link, useParams} from "react-router-dom";
import useSystemBreakpoint from "../../../../../global/hooks/useSystemBreakpoint";
import WideSpace from "../../../../../global/components/layout/WideSpace";
import {useTranslation} from "react-i18next";
import {DateFormat, DateFormatByUserTimezone} from "../../../../../global/utils/dates";


const {Text} = Typography

const StyledSummaryCard = styled(Card)`
    --base-color: ${({$color}) => {
        if (!$color) return;
        const luminance = tinycolor($color).getLuminance();
        if (luminance >= 0.6) {
            return "black"
        } else {
            return "white";
        }
    }};

    background: ${props => props.$color};
    overflow: hidden;
    border-radius: 8px;

    .ant-card-body {
        padding: 16px 16px 0 16px
    }

    .auto-color-text * {
        color: var(--base-color);
    }

`

const StyledCarouselContainer = styled.div`
    height: ${props => props.$isExpanded ? "100%" : "170px"};
    overflow: hidden;
`

const StyledDescriptionCard = styled(Card)`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    border-radius: 4px;
    min-height: 170px;

    .ant-card-body {
        padding: 24px 16px 0 24px;
    }
`

const ProjectDescriptionContainer = styled.div`
    overflow: hidden;
    height: ${props => props.$isExpanded ? "100%" : "175px"};
`

function DescriptionsCarousel({statusReports, isExpanded, toggleIsExpanded}) {
    const {id} = useParams()
    const {t} = useTranslation()
    const {isDesktop} = useSystemBreakpoint()

    return (
        <Skeleton loading={!statusReports}>
            {statusReports &&
                <StyledCarouselContainer $isExpanded={isExpanded}>
                    <StyledDescriptionCard>
                        <Link to={`/project-portfolio/project/${id}/checkpoints`}>
                            <Carousel forceHideDots={true} autoplaySpeed={5000}>
                                {statusReports.map((statusReport) => (
                                    <>
                                        <WideSpace style={{justifyContent: "space-between"}}>
                                            <Text>{t("Description V2")}: {DateFormatByUserTimezone(statusReport.date)}</Text>
                                            <FileIconLinks files={statusReport?.attachments}/>
                                        </WideSpace>
                                        <DangerousHtmlPreviewer content={statusReport.description}
                                                                style={{padding: 16, height: !isExpanded && 80}}/>
                                    </>
                                ))}
                            </Carousel>
                        </Link>
                        {!isDesktop &&
                            <Row justify="end" style={{padding: "8px 0"}}>
                                <Text onClick={toggleIsExpanded} style={{cursor: "pointer"}}>
                                    {isExpanded ? t("Reduce") : t("Expand")} {isExpanded ? <ArrowUpOutlined/> : <ArrowDownOutlined/>}
                                </Text>
                            </Row>
                        }
                    </StyledDescriptionCard>
                </StyledCarouselContainer>
            }
        </Skeleton>
    )
}

export default function ProjectSummary() {

    const {isDesktop} = useSystemBreakpoint()
    const {t} = useTranslation()
    const {project} = useProjectManagementContext();
    const [statusReportDescriptions, setStatusReportDescriptions] = useState(undefined)
    const [isExpandedDesktop, setIsExpandedDesktop] = useState(false)
    const [isExpandedMobile, setIsExpandedMobile] = useState(false)

    const toggleIsExpandedDesktop = () => setIsExpandedDesktop(!isExpandedDesktop)
    const toggleIsExpandedMobile = () => setIsExpandedMobile(!isExpandedMobile)

    const fetchStatusReportDescriptions = async () => {
        try {
            const response = await api.get(`/project_management/status_reports/${project?.id}/status_report_summary`);
            setStatusReportDescriptions(response.data);
        } catch (exception) {
            console.error(exception)
        }
    };

    useEffect(() => {
        !statusReportDescriptions && void fetchStatusReportDescriptions()
    }, [statusReportDescriptions]);


    return (
        <WideSpace direction="vertical" size="large">
            <StyledSummaryCard $color={project?.color}>
                <Row gutter={12}>
                    <Col xs={24} lg={12}>
                        <ProjectDescriptionContainer $isExpanded={isExpandedDesktop}>
                            <Space direction="vertical" className="auto-color-text">
                                <Space>
                                    <UserAvatar data={{url:project?.avatar, name:project?.name}}/>
                                    <Text>{project?.name}</Text>
                                </Space>
                                <DangerousHtmlPreviewer content={project?.description}/>
                            </Space>
                        </ProjectDescriptionContainer>
                    </Col>
                    {isDesktop &&
                        <Col span={12}>
                            <DescriptionsCarousel statusReports={statusReportDescriptions}
                                                  isExpanded={isExpandedDesktop}/>
                        </Col>
                    }
                </Row>
                <Row justify="end" style={{padding: "8px 0"}} className="auto-color-text">
                    <Text onClick={toggleIsExpandedDesktop} style={{cursor: "pointer"}}>
                        {isExpandedDesktop ? t("Reduce") : t("Expand")} {isExpandedDesktop ? <ArrowUpOutlined/> :
                        <ArrowDownOutlined/>}
                    </Text>
                </Row>
            </StyledSummaryCard>
            {!isDesktop &&
                <Row>
                    <DescriptionsCarousel statusReports={statusReportDescriptions}
                                          isExpanded={isExpandedMobile}
                                          toggleIsExpanded={toggleIsExpandedMobile}/>
                </Row>
            }
        </WideSpace>
    )
}
