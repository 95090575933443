import React from "react";
import DragIcon from "../../../../assets/Icon/drag.svg";
import IconEye from "../../../../assets/Icon/eye.svg";
import IconLocked from "../../../../assets/IconsFormComponents/locked.svg";
import {
    ComponentMainRow,
    Content,
    HelpText,
    RemoveButton, RemoveButtonText
} from "../../PhaseFormBuilder/StyledComponents";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import { SpanTitle} from "../../PhaseFormBuilder/FormComponents/DefaultComponent";

const LockedIcon = styled.img`
  width: 14px;
  margin-top: -3px;
`
const TitleQuestion = styled.h6`
    width: 100%;
    margin: 0;
    color: #949494;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
`
const StyleDragImg = styled.img`
  width: 22px;
  margin-right: 2px;
  transform: rotate(90deg);
`

const InfoLabel = styled.label`
  font-size: 9px;
`
const StyleDragImgDiv = styled.div`
  width: 100%;
  display: flex;
  //justify-content: center;
  justify-content: space-between;
  //margin-bottom: -15px;
  margin-top: -2px;
`

function QuestionForm(props) {
    const [showHelpText, setShowHelpText] = React.useState(false)
    const removeButton = React.useRef()
    const {t} = useTranslation()
    const elementParentRoot = React.useRef()

    const closeComponente = React.useCallback(({target}) => {
        if (!elementParentRoot.current) {
            document.removeEventListener('click', closeComponente)
            return null
        }
        if (!elementParentRoot.current.contains(target)) setShowHelpText(false)

    }, [])
    React.useEffect(() => {
        if (showHelpText) {
            document.addEventListener('click', closeComponente)
        } else {
            document.removeEventListener('click', closeComponente)
        }
    }, [showHelpText])

    function handleClick({currentTarget, target, ...event}) {
        if (!removeButton.current.contains(target)) {
            setShowHelpText(!showHelpText)
        }
    }

    return (
        <ComponentMainRow style={{background: '#eeedef'}} ref={elementParentRoot} onClick={handleClick}>
            <StyleDragImgDiv>
                <InfoLabel style={{visibility: "hidden"}}>{t('Field filled in the company/team application')}</InfoLabel>
                {/*<ComponentNameContent type={props.type} hide={true}/>*/}
                <StyleDragImg src={DragIcon}/>
                {/*<ComponentNameContent type={props.type}/>*/}
                <InfoLabel >{t('Field filled in the company/team application')}</InfoLabel>
            </StyleDragImgDiv>
            <Content style={{width: '100%', margin: 0}}>
                <div style={{width: '100%'}}>
                    <div style={{display: "flex"}}>
                        <SpanTitle title={t('Field filled in the company/team application')}>
                            <LockedIcon src={IconLocked} alt={t('Blocked Component')}/>
                            <TitleQuestion >{props.label}</TitleQuestion>
                        </SpanTitle>
                    </div>
                    {showHelpText && <HelpText
                        style={{width: '100%', margin: 0}}>{props.help_text !== '' ? props.help_text : ``}</HelpText>}
                </div>
                <RemoveButton ref={removeButton} type={"button"} style={{alignSelf: 'flex-start'}}
                              onClick={(event) => props.remove(event, props.index)}><img src={IconEye}
                                                                                         alt={t('Remove Component')}/>
                    <RemoveButtonText>{t('hide')}</RemoveButtonText>
                </RemoveButton>
            </Content>

        </ComponentMainRow>
    )
}

export default QuestionForm