import React from 'react'
import styled from 'styled-components'

const StyledError = styled.label`
    font-style: normal;
    font-size: 14px;
    line-height: 23.44px;
    color: #aa392d;
`

export default function Label({htmlFor, required, ...props}){

    return (
        <StyledError htmlFor={htmlFor} {...props}>{props.children}</StyledError>
    )
}