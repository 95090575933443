import ColumnChart from "../components/ColumnChart";
import ChartCard
    from "../../../../../global/components/data_display/ChartCard";
import PieGraph from "../components/PieChart";
import {useTranslation} from "react-i18next";
import SolvCollapse from "../../../../../global/components/misc/SolvCollapse";
import React, {useEffect, useState} from "react";
import api from "axios";
import {BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import GraphRow from "../components/GraphRow";
import GraphCol from "../components/GraphCol";
import StackedColumnChart from "../components/StackedColumnChart";
import {PHASE} from "../../../../../global/utils/Constants";
import LoadingFull from "../../../../../global/components/other/LoadingFull";
import Empty from "../../../../../global/components/data_display/Empty";
import {Col} from "antd";


const QUESTIONS_TYPES = {

    SELECT: "select",
    MULTIPLE_SELECT: "multiple_select",
    RADIO: "radio",
    SECTION: "section",
    CHECKBOX: "checkbox",
    SCORE_WITH_FEEDBACK: "score_with_feedback",
    YES_NO: "yes_no",

}

export default function FactoryDashboardProgramPhaseCharts({
                                                               phase,
                                                               ...props
                                                           }) {

    const {t} = useTranslation();
    const [phaseData, setPhaseData] = useState([])
    const [isLoading, setIsLoading] = useState()

    useEffect(() => {
        const fetchPhases = async () => {
            try {
                setIsLoading(true)
                const response = await api.get(`dashboard/phase_dashboard/${phase.id}/get_phase_graphics`, {version: 'v2'});
                setPhaseData(response.data)

            } catch (error) {
                BuildFieldErrorsDict(error, null, false)
            } finally {
                setIsLoading(false)
            }
        };
        fetchPhases();
    }, []);

    function FACTORY_CHARTS({type, values}) {
        switch (type) {
            case QUESTIONS_TYPES.SELECT:
                return <PieGraph externalData={values}/>;
            case QUESTIONS_TYPES.RADIO:
                return <PieGraph externalData={values}/>;
            case QUESTIONS_TYPES.SECTION:
                return <PieGraph externalData={values}/>;
            case QUESTIONS_TYPES.MULTIPLE_SELECT:
                return <ColumnChart legend_key={'label'} externalData={values}
                                    label={false}/>;
            case QUESTIONS_TYPES.CHECKBOX:
                return <ColumnChart legend_key={'label'} externalData={values}
                                    label={false}/>;
            case QUESTIONS_TYPES.SCORE_WITH_FEEDBACK:
                return <ColumnChart legend_key={'name'}
                                    value={'avg'}
                                    externalData={values}/>;
            case QUESTIONS_TYPES.YES_NO:
                return <StackedColumnChart legend_key={"name"}
                                           externalData={values}
                                           groups={{
                                               false_percent: {
                                                   label: 'No',
                                                   color: '#E84545'
                                               },
                                               pending_percent: {
                                                   label: 'Pending',
                                                   color: '#868686'
                                               },
                                               true_percent: {
                                                   label: 'Yes',
                                                   color: '#A3E28D'
                                               },
                                           }}/>


        }
    }


    return (

        <GraphRow gutter={[24, 24]}>
            {isLoading ?
                (<LoadingFull/>) :
                phaseData.length > 0 ? (phaseData.map((question) => (

                        <GraphCol>
                            <ChartCard title={t(question.label)}
                                       hasScroll={phase.type === PHASE.EVALUATION}
                                       expandible={true}>
                                {FACTORY_CHARTS(question)}
                            </ChartCard>
                        </GraphCol>

                    ))) :
                    (<Col xs={24}><Empty/></Col>)}

        </GraphRow>


    );
}