import React, {useState} from "react";
import {Modal, Space, Typography} from "antd";
import {useLayoutContext} from "../layout/context/LayoutContext";
import SolvTable from "../../../../global/components/tables/SolvTable";
import {useTranslation} from "react-i18next";
import {CloseCircleOutlined} from "@ant-design/icons";
import {
    renderDate,
    renderPermissions,
    renderProfile
} from "../../../../global/components/tables/utils";
import api from "../../../../global/services/api";
import DrawerProfileUser
    from '../../../../global/pages/profile/components/DrawerProfileUser/DrawerProfileUser';
import {get_color_status} from "../../../../global/components/other/Utils";
import SolvTag from "../../../../global/components/tag/SolvTag";
import MemberDrawer from "../../pages/ProjectManagement/components/MemberDrawer";


const {confirm} = Modal;
const {Link} = Typography;

function ProjectMemberTable({dataSource, setDataSource}) {
    const {t} = useTranslation();
    const [userId, setUserId] = useState();
    const {openNotification} = useLayoutContext();
    const [openDrawerProfile, setOpenDrawerProfile] = useState(false);
    const [openMemberDrawer, setOpenMemberDrawer] = useState(false);
    const [editMember, setEditMember] = useState(undefined)

    const handleOpenProfileDrawer = (record) => {
        setUserId(record.user_id);
        setOpenDrawerProfile(true);
    }

    const handleOpenMemberDrawer = (record) => {
        setEditMember(record)
        setOpenMemberDrawer(true)
    }

    const columns = [
        {
            title: t("Profile"),
            dataIndex: "name",
            // sorter: "user_client__user__name",
            render: (name, member) => <Link href="#"
                                            onClick={() => handleOpenProfileDrawer(member)}>{renderProfile(name, member)}</Link>

        },
        {
            title: t('Status'),
            dataIndex: "status",
            // sorter: "user_client__is_aproved",
            // sorter: 'is_aproved',
            key: 'status',
            width: '100px',
            render: (value, object) => {
                 if (!value) return '-'
                return (
                    <SolvTag
                        color={get_color_status(value)}>{t(value)}</SolvTag>
                )
            }
        },
        {
            title: t("Email"),
            // sorter: "user_client__user__username",
            dataIndex: "username",
        },
        {
            title: t("Membership Date"),
            dataIndex: "membership_date",
            // sorter: "created_at",
            render: renderDate,
        },
        {
            title: t("Permissions"),
            dataIndex: "permissions",
            render: (permissions) => renderPermissions(permissions, t)
        },
        {
            title: t("Actions"),
            key: "action",
            action: true,
            render: (record, _) => (
                <Space size="middle">
                    <Typography.Link onClick={() => {handleOpenMemberDrawer(record)}}
                                     data-cy={`edit-member-${record.id}`}>{t("Edit")}</Typography.Link>
                </Space>
            ),
        },
    ];

    return (
        <>
            <SolvTable columns={columns} dataSource={dataSource}
                       data-cy="project-members-table"/>
            <DrawerProfileUser
                id={userId}
                open={openDrawerProfile}
                setOpen={setOpenDrawerProfile}
            />
            <MemberDrawer open={openMemberDrawer} setOpen={setOpenMemberDrawer} setDataSource={setDataSource} editMember={editMember} setEditMember={setEditMember}/>
        </>
    )
}

export default ProjectMemberTable;