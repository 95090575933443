import React from 'react';
import {Space, Typography} from "antd";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const {Text} = Typography

const StyleText = styled(Text)`
    font-size: 16px;
`;

export default function AutoAllocationHelp(props) {
    const {t} = useTranslation()
    return (
        <div>
            <Space direction="vertical" size="large">
                <StyleText>
                    {t("When adding reviewers to the system, they will be randomly assigned to applications. Priority is given to the reviewer with fewer assignments currently assigned to them.")}
                </StyleText>
                <StyleText>
                    {t("Enabling this option also activates the automatic allocation button, allowing you to allocate reviewers automatically whenever you want.")}
                </StyleText>
            </Space>
        </div>
    );
}
