import { Form as FormAnt, Row, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { useLayoutContext } from "../../../../../../modules/project/components/layout/context/LayoutContext";
import Button from "../../../../data_entry/Button";
import Form from "../../../../data_entry/form/Form";
import Drawer from "../../../../layout/Drawer";
import RequestSelect from '../../../../data_entry/RequestSelect';
import { useState } from "react";
import ApplicationInfoCard from "../../../../data_display/ApplicationInfoCard/ApplicatioInfoCard";
import api from "../../../../../services/api";


export default function ApplicationGroupDrawer({groupApplication, open, setOpen, onJoin}) {
    const {openNotification} = useLayoutContext();
    const {t} = useTranslation();
    const [form] = FormAnt.useForm();
    const [applicationInfo, setApplicationInfo] = useState();
    
    const handleGroupApplication = async (data) => {
        try {
            await api.post(`/applications/${groupApplication.id}/group`, data);
            form.resetFields();
            setOpen(false);
            openNotification({type: "success", message: t("Application grouped successfuly")});
            onJoin && onJoin();
        } catch (error) {
            openNotification({type: "error", message: error.response?.data.errors});
        }
    };


    const confirmGroupApplication = (data) => {
        Modal.confirm({
            title: t("Group Application"),
            content: t("When grouping applications, the selected application for grouping will remain, while this application will be discontinued from the program."),
            okText: t("Group"),
            cancelText: t("Give up"),
            onOk: ()=> handleGroupApplication(data)
        });
    }

    const handleApplicationSelect = (value, option) => {
        const {data} = option;
        setApplicationInfo(data);
    }

    const helpText = t("The application selected in this modal will remain in the program while the current application will be grouped and discontinued from the selection process.");

    return (
        <Drawer
            title={t("Group Application")} open={open} onClose={() => setOpen(false)}
            footer={
                <Row justify="end">
                    <Button type="primary" onClick={() => form.submit()}>{t("Group Application")}</Button>
                </Row>}
            >
                <Form layout="vertical" form={form} onFinish={confirmGroupApplication}>
                    <FormAnt.Item label={t("Select an application to group")} name="linked_application" rules={[{required: true}]} help={helpText} >
                        <RequestSelect url={`v2/applications/${groupApplication.id}/linkable_applications`} labelField="name" preserveData={true} onSelect={handleApplicationSelect} />
                    </FormAnt.Item>
                </Form>
                {applicationInfo && <ApplicationInfoCard application={applicationInfo} />}
        </Drawer>
    );
}