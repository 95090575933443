import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLayoutContext } from '../../../modules/project/components/layout/context/LayoutContext';
import RoutesInitiativesApplications from '../../../modules/program/routes/RoutesInitiativesApplications';
import { Badge } from 'antd';
import styled from 'styled-components';
import { usePermissionContext } from '../../context/PermissionContext';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { HOST_ENTREPRENEUR } from '../../../settings';
import { alert_red_color } from '../../utils/Colors';
import { useUserContext } from '../../context/UserContext';

const StyledBadge = styled(Badge)`
  & sup {
    box-shadow: 0 0 0 1px ${alert_red_color} !important;
  }
`

function ApplicationSubscriptionsNavigatorPage(props) {
    const { t } = useTranslation();
    const { hasUnreadMessages, hasSameDomainChallenge } = usePermissionContext();
    const {user_client} = useUserContext();

    const initiativesMenuItems = [
        {
            label: <Link to={`/applications`}>{t("Solutions")}</Link>,
            key: `/applications`,
        },
        {
            label: <Link to={`/applications/suggested_applications_challenges`}>{t("Suggested Challenges")}</Link>,
            key: `/applications/suggested_applications_challenges`,
            hasPermission: hasSameDomainChallenge,
        },
        {
            label: <Link to={`/applications/messages`}>{t("Messages")}</Link>,
            name: <StyledBadge dot={hasUnreadMessages} color={alert_red_color} size={'large'}
                offset={[10, 20]}>{t('Messages')}</StyledBadge>,
            key: `/applications/messages`,
            hasPermission: !(window.location.host === HOST_ENTREPRENEUR),
        },
        {
            label: <Link to={`/applications/requests`}>{t("Requests")}</Link>,
            // name: <StyledBadge dot={hasUnreadMessages} color={alert_red_color} size={'large'} offset={[10, 20]}>{t('Messages')}</StyledBadge>,
            key: `/applications/requests`,
            hasPermission: !(window.location.host === HOST_ENTREPRENEUR) && !!user_client,
        },
    ]

    const {
        initializeLayout,
    } = useLayoutContext();

    useEffect(() => {
        initializeLayout({
            navbarMenuItems: initiativesMenuItems,
            customBackgroundColor:"#f0f2f5"
        });
    }, []);


    return (<RoutesInitiativesApplications />)
    
}

export default function ApplicationSubscriptionsNavigator() {
    return <ApplicationSubscriptionsNavigatorPage />
}
