import React, {useEffect, useState} from 'react';

import {useTranslation} from "react-i18next";
import {
    Col,
    Row,
    Space,
    Typography,
    Form as FormAntd,
    Modal,
    Alert
} from "antd";

import styled from 'styled-components'
import {neutral_4, orange} from "../../global/utils/Colors";
import {
    FlexContainer
} from "../../global/components/authentication/StyledComponents";
import {
    ReactComponent as RectangleIcon
} from "../../assets/Icon/Rectangle.svg";
import {
    ReactComponent as RectanglePurpleIcon
} from "../../assets/Icon/losango.svg";
import Button from "../../global/components/data_entry/Button";
import CardPrice from "../../global/components/other/CardPrice";
import Drawer from "../../global/components/layout/Drawer";
import api from "../../global/services/api";
import {SUCCESS} from "../../global/utils/fileWithMessage";
import {BuildFieldErrorsDict} from "../../global/utils/Utils";
import Form from "../../global/components/data_entry/form/Form";
import TextAreaFormField
    from "../../global/components/data_entry/form/TextAreaFormField";

import Loading from "../../global/components/feedback/Loading";
import {FREE_PLAN} from "../../global/utils/Constants";
import {CardContainer} from "../../global/components/misc/CardContainer";

const PlanContainer = styled.div`
    padding: 24px;
    margin-top: 32px;
    background-color: ${neutral_4};
`
const TitlePlan = styled.span`
    font-size: 16px;
`
const PricePlan = styled.span`
    font-size: 16px;
    font-weight: bold;
`

const StyledRectandleOrange = styled(RectangleIcon)`
    width: 16px;
    height: 16px;

    rect {
        fill: ${orange};
    }
`
const StyledLosangoOrange = styled(RectanglePurpleIcon)`
    width: 16px;
    height: 16px;

    rect {
        fill: ${orange};
    }
`

const StyledRectandleBlack = styled(RectangleIcon)`
    width: 16px;
    height: 16px;

    rect {
        fill: black;
    }
`


const Label = styled.span`
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;

`
const LastPrice = styled.span`
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;

`
const NewPrice = styled.span`
    font-size: 32px;
    line-height: 28px;
    font-weight: 600;
    color: ${orange};

`

const TextOrange = styled.span`
    color: ${orange};
`


const StyledButton = styled(Button)`
    background-color: ${orange};
    border: none;
`


export default function PageEnvironmentFinancial() {
    const {t} = useTranslation()
    const [form] = FormAntd.useForm()
    const [isLoading, setIsLoading] = useState(true)
    const [formIsLoading, setFormIsLoading] = useState()
    const [financialData, setFinancialData] = useState()
    // const {checkAmbientHasDebit} = usePermissionContext()
    useEffect(() => {

        const fetchFinancialData = async () => {
            try {
                setIsLoading(true)
                const response = await api.get(`/business/get_subscription`);
                setFinancialData(response.data);
            } catch (error) {
                BuildFieldErrorsDict(error, null, false)
            } finally {
                setIsLoading(false);
            }
        };
        fetchFinancialData();

    }, []);


    function NotFreePlan() {
        return FREE_PLAN !== financialData?.billing_plan
    }

    const PRICES = [
        {

name:'Gratuito',
            header: {
                icons: <StyledRectandleBlack/>,
                title: 'Startup',
                subtitle: t('Internal innovation and mapping of challenges')
            },
            // title: <Title>
            //     Grátis para sempre
            // </Title>,
            benefits: [
                t('Up to 10 employees'), t('Internal innovation'),
                t('Challenge mapping'), t('01 project in portfolio'),

            ],
            footer: <> {NotFreePlan() &&
                <Button block onClick={() => setOpen(true)}>
                    {t('Contact us')}
                </Button>}</>
        },

        {
            name:'Scale Up',

            header: {
                icons: <>
                    <StyledRectandleOrange/>
                    <StyledLosangoOrange/>
                </>,
                title: <TextOrange>Scale up</TextOrange>,
                subtitle: t('Internal innovation and mapping of challenges')
            },
            title: <Space direction={'vertical'}>
                <Space>
                    <Label>{t("from")}:</Label>
                    <LastPrice>R$ 14,90*</LastPrice>
                </Space>
                <Space>
                    <Label>{t("for")}:</Label>
                    <NewPrice>R$ 6,90</NewPrice>
                    <TextOrange>/m/{t('user')}</TextOrange>
                </Space>

            </Space>,
            benefits: [
                t('Up to 100 employees'), t('Internal innovation'),
                t('Challenge mapping'), t('02 projects in the portfolio'),
                t('Expert support')
            ],
            footer: <StyledButton onClick={() => setOpen(true)}
                                  block style={{background: orange}}>
                {t('Contact us')}
            </StyledButton>
        },
        {
            name:"Personalizado",
            style: {
                background: '#F5E9E5'
            },
            header: {
                icons: <>
                    <StyledRectandleOrange/>
                    <StyledLosangoOrange/>
                    <StyledLosangoOrange/>
                </>,
                title: <TextOrange>Corporate</TextOrange>,
                subtitle: <span style={{color:orange}}>{t('End-to-End Innovation')}</span>
            },
            title: <Space direction={'vertical'}>
                <Space>
                    {/*<Label>{t("from")}:</Label>*/}
                    {/*<LastPrice></LastPrice>*/}
                </Space>
                <Space>
                    <Label>{t("for")}:</Label>
                    <NewPrice>{t('Price on request')}</NewPrice>
                </Space>

            </Space>,
            benefits: [
                t('Unlimited collaborators'), t('Internal innovation'),
                t('Challenge mapping'), t('Open Innovation'),
                t('Unlimited Projects'), t('Priority support'),
            ],
            footer: <StyledButton onClick={() => setOpen(true)}
                                  block style={{background: orange}}>
                {t('Contact us')}
            </StyledButton>
        },
    ]
    const [open, setOpen] = useState(false)

    const handleSubmit = async () => {
        try {
            setFormIsLoading(true)
            const data = await form.getFieldsValue()

            const response = await api.post(`/business/send_contact_mail`, data);

            ToastNotification(t(SUCCESS));
            onClose()
        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, null)
        } finally {
            setFormIsLoading(false);
        }
    };

    function onClose() {
        setOpen(false)
        form.resetFields()
    }

    function amountRestUsers() {
        const value = financialData.user_limit - financialData.active_users
        return value <= 0 ? 0 : value
    }

    function amountProject() {
        const value = financialData.project_limit - financialData.total_projects
        return value <= 0 ? 0 : value
    }

    return (
        <>
            <Row gutter={[24, 24]}>
                {isLoading ? (<Col xs={24}><Loading/></Col>) : (<>
                    {financialData.has_debit && <Col xs={24}><Alert
                        message={t('It appears that this environment is temporarily inactive due to pending payments. To reactivate it, click the button below and bring your invoice up to date. We appreciate your understanding and eagerly await your return!')}
                        type="warning"/>
                        <FlexContainer style={{marginTop: 16}}
                                       justify={'center'}>
                            <Button
                                onClick={() => setOpen(true)}>{t('Click here')}</Button>
                        </FlexContainer>
                    </Col>}
                    <Col xs={24}>
                        <CardContainer>
                            <Typography.Title
                                level={5}>{t("Your plan")}</Typography.Title>
                            <PlanContainer>
                                <FlexContainer justify={'space-between'}>
                                    <TitlePlan>{financialData.billing_plan_name}</TitlePlan>
                                    {/*<PricePlan>R$ {financialData.price} por*/}
                                    {/*    usuário/mês</PricePlan>*/}
                                </FlexContainer>
                                <FlexContainer justify={'space-between'}>
                                    <Typography.Text
                                        type={'secondary'}>{financialData.active_users} {t('User(s)')}</Typography.Text>
                                    {/*<Typography.Text type={'secondary'}>Total:*/}
                                    {/*    R$*/}
                                    {/*    {financialData.total}/mes </Typography.Text>*/}
                                </FlexContainer>
                            </PlanContainer>
                        </CardContainer>
                    </Col>
                    <Col xs={24}>
                        <CardContainer>
                            <Typography.Title
                                level={5}>{t("Using the plan")}</Typography.Title>
                            <PlanContainer>
                                <FlexContainer justify={'space-between'}>
                                    <TitlePlan>{t('Users')}</TitlePlan>
                                    <PricePlan>{financialData.user_limit? amountRestUsers(): '∞'} {t('users remaining')}</PricePlan>
                                </FlexContainer>
                                <FlexContainer justify={'space-between'}>
                                    <Typography.Text
                                        type={'secondary'}></Typography.Text>
                                    <Typography.Text
                                        type={'secondary'}>{financialData.active_users}/{financialData.user_limit ?? t('Unlimited')} </Typography.Text>
                                </FlexContainer>
                            </PlanContainer>

                            {/* <PlanContainer>*/}
                            {/*    <FlexContainer justify={'space-between'}>*/}
                            {/*        <TitlePlan>{t('Programs')}</TitlePlan>*/}
                            {/*        <PricePlan>{amountProject()} {t('remaining projects')}</PricePlan>*/}
                            {/*    </FlexContainer>*/}
                            {/*    <FlexContainer justify={'space-between'}>*/}
                            {/*        <Typography.Text*/}
                            {/*            type={'secondary'}></Typography.Text>*/}
                            {/*        <Typography.Text*/}
                            {/*            type={'secondary'}>{financialData.total_programs}/{financialData.project_limit} </Typography.Text>*/}
                            {/*    </FlexContainer>*/}
                            {/*</PlanContainer>*/}
                            <PlanContainer>
                                <FlexContainer justify={'space-between'}>
                                    <TitlePlan>{t('Projects')}</TitlePlan>
                                    <PricePlan>{financialData.project_limit? amountProject(): '∞'} {t('remaining projects')}</PricePlan>
                                </FlexContainer>
                                <FlexContainer justify={'space-between'}>
                                    <Typography.Text
                                        type={'secondary'}></Typography.Text>
                                    <Typography.Text
                                        type={'secondary'}>{financialData.total_projects}/{financialData.project_limit ?? t('Unlimited')} </Typography.Text>
                                </FlexContainer>
                            </PlanContainer>
                        </CardContainer>
                    </Col>
                    <Col xs={24}>
                        <CardContainer>
                            <Typography.Title
                                level={5}>{t("Modify plan")}</Typography.Title>
                            <Row>
                                {PRICES.filter(({name}) => name !== financialData.billing_plan_name).map((price) => (
                                    <Col xs={24} lg={8}>
                                        <CardPrice {...price}/>
                                    </Col>

                                ))}
                            </Row>
                        </CardContainer>
                    </Col>


                    {NotFreePlan() && <Col xs={24}>
                        <CardContainer>
                            <Row>
                                <Col xs={24}><Typography.Title
                                    level={5}>{t("Cancel plan")}</Typography.Title></Col>
                                <Col xs={24}><Typography.Text
                                    type={'secondary'}
                                    style={{marginTop: 8}}>{t('To cancel your plan, talk to us.')}</Typography.Text></Col>
                                <Col xs={24} style={{marginTop: 32}}> <Button
                                    onClick={() => setOpen(true)}
                                    danger>
                                    {t('Cancel plan')}
                                </Button></Col>
                            </Row>


                        </CardContainer>
                    </Col>}

                </>)}
            </Row>
            <Drawer open={open} title={t('Contact us')} onClose={onClose}
                    footer={
                        <FlexContainer justify={'end'}><Button
                            loading={formIsLoading}
                            onClick={() => form.submit()}>{t('Send')}</Button></FlexContainer>}>
                <Form disabled={formIsLoading} form={form}
                      onFinish={handleSubmit}>
                    <TextAreaFormField form={{
                        name: 'message',
                        label: t("Describe how we can help"),
                        rules: [{
                            required: true,
                            message: t('This field is require')
                        }]
                    }}/>
                </Form>
            </Drawer>
        </>
    );
}