import styled from "styled-components";
import {Link} from "react-router-dom";
import PasswordChecklist from "react-password-checklist"

export const StyledPassWordCheckList = styled(PasswordChecklist)`
  margin-top: 16px;

  .li {
    //margin: 20px;
  }

  .valid {
    margin-bottom: 4px;
  }

  .invalid {
    margin-bottom: 2px;
    border: 1px solid transparent !important
  }
`

export const MainContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: var(--login_background_cover);
  display: flex;
`

export const ImageContainer = styled.div`
  min-width: 60%;
  min-height: 100%;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (max-width: 850px) {
    & {
      display: none;

    }
  }
`

export const LogoContainer = styled.div`
  min-width: 240px;
  max-width: 240px;
  min-height: 120px;
  max-height: 120px;
  margin: 0 auto;
  margin-bottom: 50px;
  text-align-last: center;
`

export const InviteMessageContainer = styled.div`
  //text-align: center;
  margin-top: 20px;
`
export const LogoInviteContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`


export const StyledLogoDiv = styled.div`
  max-width: 200px;
  max-height: 100px;
  margin-top: 16px;
`

export const StyledLogoImg = styled.img`
  max-width: 200px;
  max-height: 100px;
`

export const LoginContainer = styled.div`
  min-width: 50%;
  padding: 50px 130px 0;

  overflow: auto;
  @media screen and (max-width: 1244px) {
    & {
      padding: 50px 80px 0;
    }
  }

  @media screen and (max-width: 1000px) {
    & {
      padding: 50px 50px 0;

    }
  }

  @media screen and (max-width: 850px) {
    & {
      padding: 50px 80px 0;
      margin: 0 auto 0px;
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    & {
      padding: 50px 30px 0;
      margin: 0 auto 0px;
      width: 100%;
    }
  }
`

export const InputContainerCustom = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 90%;
  position: relative;
  margin-top: 8px;
  margin-bottom: 21px;
  //align-items: center;
`

export const StyledLabel = styled.label`
  color: black;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  align-self: ${(props) => props.end ? "flex-end" : ""};
`



export const InviteLabel = styled(StyledLabel)`
  display: block;
  text-align: center;
`

export const InviteTitle = styled(StyledLabel)`
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  text-align: center;
`

export const InputLogin = styled.input`
  border: none;
  border-bottom: 1px solid var(--login_text_color_cover);
  background-color: transparent;
  color: var(--login_text_color_cover);
  outline: none;
  margin-top: -5px;
  width: 100%;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-weight: 200;
  }

  &:active {
    background-color: transparent;
  }
`


export const ButtonLogin = styled.button`
  width: 90%;
  background-color: var(--login_background_button);
  padding: 10px;
  color: var(--login_text_color_button);
  font-weight: 600;
  border-radius: 5px;
  border: 1px solid transparent;
  font-size: 16px;

  svg {
    fill: var(--login_text_color_button);
    transform: rotate(270deg);
    margin: 0 8px;
    margin-top: -2px;
    width: 18px;
    height: 18px;
  }

  &:hover {
    //background-color: #CE3703FF;
    border: 1px solid var(--login_text_color_button);
  }

  &:disabled {
    &:hover {
      border: 1px solid transparent;
      cursor: not-allowed;
    }

    opacity: .6;
  }
`

export const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const IconContainer = styled.div`
  position: absolute;
  top: 2px;
  right: 5px;
  width: 13px;
  height: 13px;

  & svg {
    width: 100%;
    height: 100%;
  }
`

export const KeepOnlineContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 12px 0;
`

export const CheckboxContainer = styled.label`
  display: flex;
  //justify-content: center;
  align-items: center;
  gap: 8px;

  & input[type="checkbox"] {

    width: 16px;
    height: 16px;
    border-radius: 50%;
  }

  & ${StyledLabel} {
    cursor: pointer;
  }
`

export const LinkCustom = styled(Link)`
  color: var(--login_text_color_cover);

  &:hover {
    color: #d4d3d3;

  }
`

export const CreateAccountContainer = styled.p`
  color: var(--login_text_color_cover);
  font-weight: 300;
  margin: 30px auto;
  max-width: 300px;
  text-align: center;
`

export const AuthExternalContainer = styled.div`
  display: flex;
  //flex-direction: column;
  gap: 8px;
  flex: 1;
  width: 100%;
  justify-content: center;

  div {
    flex: 1;
  }

  * {
    margin: 0 auto;
  }
`


export const StyledLabelEmaill = styled.h2`
  color: var(--login_text_color_cover);
  font-weight: 400;
  margin-bottom: 20px;
  white-space: nowrap;
  align-self: center;
`

export const ButtonSendEmail = styled.button`
  width: 100%;
  background-color: var(--login_background_cover);
  color: var(--login_text_color_button);
  //text-decoration: underline !important;
  border: 1px solid transparent;

  &:hover {
    color: #d4d3d3;
  }
`

export const CustomRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const InputCheckboxLogin = styled(InputLogin)`
  cursor: pointer;
  width: fit-content;
`

export const StyledMidLabel = styled.span`
  display: flex;
  justify-content: center;
  color: var(--login_text_color_cover);
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  margin: 12px 0;
`

export const StyledContents = styled.div`
display:contents;
`