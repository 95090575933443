import TagsList from "./TagsList";
import {useTranslation} from "react-i18next";
import {useEffect, useMemo, useState} from "react";
import {blue_1, calendula_1} from "../../utils/Colors";

function PermissionTagsList({permissions, maxSize = 2}) {
    const {t} = useTranslation()
    const [_permissions, _setPermissions] = useState({})

    useEffect(() => {
        const newPermissions = {...permissions}
        if(permissions.is_program_admin || permissions.is_project_admin){
            newPermissions['is_admin'] = true
            delete newPermissions['is_program_admin']
            delete newPermissions['is_project_admin']
        }

        _setPermissions(newPermissions)
    }, [permissions])

    const permissionTagsSettings = {
        // is_program_admin: {name: t("Program Admin"), color: "#87E8DE"},
        // is_project_admin: {name: t("Project Admin"), color: "#FF9DC6"},
        is_admin: {name: t("Admin"), color: "#F0F5FF"},

        is_program_viewer: {name: t("Program Viewer"), color: "#FFF0F6"},
        is_program_evaluator: {name: t("Program Evaluator"), color: "#E6FFFB"},
        is_program_manager: {name: t("Program Manager"), color: "#F9F0FF"},
        is_application: {name: t("Application"), color: "#E6FFFB"},
        // is_program_admin_or_manager: {name: "", color: ""},
is_gamification_manager: {name: t("Gamification Manager"), color:calendula_1},

        is_project_viewer: {name: t("Project Viewer"), color: "#FFF0F6"},
        is_project_manager: {name: t("Project Manager"), color: blue_1},
        // is_project_admin_or_manager: {name: "", color: ""},
    }

    const tags = useMemo(() => {
        return Object.entries(_permissions)
            .filter(([permission, isEnabled]) => isEnabled && Object.keys(permissionTagsSettings).includes(permission))
            .map(([permission, isEnabled]) => (
                permissionTagsSettings[permission]
            ))
    }, [_permissions])


    return <TagsList tags={tags} maxSize={maxSize}/>
}

export default PermissionTagsList;