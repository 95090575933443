import React from "react";
import useChallengeDrawerContext from "../../context/ChallengeDrawerContext";
import FilesTable from "../../../../../data_entry/FilesTable";

export default function AttachmentChallengeTab() {
    const {challenge} = useChallengeDrawerContext();
    return (
        <FilesTable
            hasPermission={false}
            fileType="program"
            id={challenge.program.id}
            table={{
            extraColumns: [],
            request: {
                url_post: `/file_upload/content/application/object/${challenge.program?.id}/upload/`,
                url_get: `/file_upload/content/application/object/${challenge.program?.id}/list_uploads/`
            }
        }}/>
    );
}
