import {useTranslation} from "react-i18next";
import LimitedSpace from "../../../responsive/LimitedSpace";
import {CommentOutlined} from "@ant-design/icons";
import React from "react";
import {DotBadge} from "../../../../utils/Render";
import {DateFormatByUserTimezone, HourFormatByUserTimezone} from "../../../../utils/dates";
import UserAvatar from "../../../misc/UserAvatar";
import {Typography} from "antd";
import TruncateText from "../../../responsive/TruncateText";

const {Text} = Typography

export default function ApplicationInfoMessage({topic}) {
    const {t} = useTranslation()
    return (
        <LimitedSpace>
            <TruncateText>
                <Text type="secondary" style={{fontSize: 12}}>
                    {<CommentOutlined/>} {topic.total_messages_count} {t("Messages")}
                </Text>
            </TruncateText>
            <DotBadge/>
            {
                topic.last_message &&
                <TruncateText>
                    <Text type="secondary" style={{fontSize: 12}}>
                        {t("Last message")}: <strong>{DateFormatByUserTimezone(topic.last_message.created_at)}</strong> {t("at")}: <strong>{HourFormatByUserTimezone(topic.last_message.created_at, false)} </strong>
                        
                        {topic.last_message?.owner && <>
                            {t("by")} <UserAvatar data={topic.last_message.owner} size={16}/> {topic.last_message.owner.first_name} {topic.last_message.owner.last_name}
                        </>}
                    </Text>
                </TruncateText>
            }
        </LimitedSpace>
    )
}