import InfiniteScroll from "react-infinite-scroller";
import { CardContainer } from "../../components/misc/CardContainer";
import { useEffect, useRef, useState } from "react";
import api from './../../services/api';
import { BuildFieldErrorsDict } from "../../utils/Utils";
import RequestCardButton from "../../components/other/application/ApplicationDrawer/components/RequestsSolutionTab/components/RequestCardButton";
import { useLayoutContext } from "../../../modules/project/components/layout/context/LayoutContext";
import Loading from "../../components/feedback/Loading";
import { Col, Row, Typography } from "antd";

import Search from "../../components/data_entry/Search";
import { useTranslation } from "react-i18next";
import {RequestResponseDrawer} from "../../components/other/application/ApplicationDrawer/components/RequestsSolutionTab/components/RequestDrawer";


export default function ListRequests() {
    const hasChangedSearch = useRef(true);

    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [requests, setRequests] = useState([]);
    const initialUrl = 'diligences/list_mine?page=1&page_size=5'
    const [nextUrl, setNextUrl] = useState(initialUrl);
    const [search, setSearch] = useState("");
    const {refLayoutContent} = useLayoutContext();

    const [selectedRequest, setSelectedRequest] = useState();
    const [open, setOpen] = useState(false);

    const fetchRequests = async () => {
        try {
            setLoading(true);
            let url = nextUrl;
            let keepRequests = [...requests];
            if (hasChangedSearch.current) {
                url = initialUrl;
                keepRequests = [];
                hasChangedSearch.current = false;
            }
            if (!url) {
                return;
            }
            const config = {
                params: {search}
            }
            const {data} = await api.get(url, config);
            setRequests([...keepRequests, ...data.results]);
            setNextUrl(data.next);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        } finally {
            setLoading(false);
        }
    }
    
    const handleLoadMore = () => {
        if (!loading) {
            void fetchRequests();
        }
    }

    useEffect(()=>{
        void fetchRequests();
    }, [search]);


    const handleSearch = (value) => {
        hasChangedSearch.current = true;
        setSearch(value);
    }

    
    const handleShowRequest = (request) => {
        setSelectedRequest(request);
        setOpen(true);
    };

    const handleRefreshRequests = () => {
        hasChangedSearch.current = true;
        fetchRequests();
    }

    return <>
        <Row gutter={[0, 16]}>
            <Col span={24}>
                <CardContainer>
                    <Row justify="space-between" align="middle">
                        <Col><Typography.Text strong style={{fontSize: 16}}>{t("Your Requests")}</Typography.Text></Col>
                        <Search onSearch={handleSearch} allowClear style={{width: 264}}/>
                    </Row>
                </CardContainer>
            </Col>
            <Col span={24}>
                <InfiniteScroll
                    loadMore={handleLoadMore}
                    hasMore={!!nextUrl}
                    useWindow={false}
                    getScrollParent={() => refLayoutContent.current}
                    loader={<Loading style={{ height: 100 }} />}
                >

                    {requests.map((request, index) => (
                        <Col span={24} style={{ paddingBottom: 16 }} key={request.id}>
                            <RequestCardButton request={request} onClick={() => handleShowRequest(request)} />
                        </Col>
                    ))}
                </InfiniteScroll>
            </Col>
            {selectedRequest &&
                <RequestResponseDrawer
                    open={open}
                    setOpen={setOpen}
                    request={selectedRequest}
                    fetchRequests={handleRefreshRequests}
                />
            }
        </Row>
    </>
}