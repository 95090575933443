import React, {useEffect} from 'react';
import {Switch, useHistory, useLocation, useRouteMatch,} from "react-router-dom";
import {CardContainer} from "../../../global/components/misc/CardContainer";
import {ManagerRoute} from "../../../global/routes/PrivateRoute";
import Database from "../../../global/pages/database/Database";
import {Flex} from "antd";
import DatabaseHome from "../../../global/pages/database/DatabaseHome";
import DatabaseProgram from "../../../pages/Programs/TabBarProgram/Applications/DatabaseProgram";

export default function RoutesDatabase({
                                           hasPermission,
                                           indexRoute = "applications",
                                           scope,
                                           scopeObj,
                                           origin,
                                           setOrigin,
                                           setExtraHeaderLeft
                                       }) {
    let {path, url} = useRouteMatch();
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        const pathNameList = location.pathname.split('/')
        if (pathNameList[pathNameList.length - 1] === 'database') {
            history.push(`${location.pathname}/${indexRoute}`)
        }
    }, [location]);

    useEffect(() => {
        setOrigin?.(origin)
    }, [origin]);

    useEffect(() => {
        if(!location.pathname.includes("/database/program/")){
        setOrigin?.(undefined)
        }
    }, [location]);

    return (

            <Flex vertical={true} gap={8}>
                <Switch>
                    <ManagerRoute
                        path={`${path}/home`}
                        render={() => <DatabaseHome/>}
                        hasPermission={hasPermission}/>
                    <ManagerRoute
                        path={`${path}/companies`}
                        render={() => <Database name="companyTeams" scope={scope} scopeObj={scopeObj} origin={origin}/>}
                        hasPermission={hasPermission}/>
                    <ManagerRoute
                        path={`${path}/members`}
                        render={() => <Database name="member" scope={scope} scopeObj={scopeObj} origin={origin}/>}
                        hasPermission={hasPermission}/>
                    <ManagerRoute
                        path={`${path}/applications`}
                        render={() => <Database name="application" scope={scope} scopeObj={scopeObj} origin={origin}/>}
                        hasPermission={hasPermission}
                    />
                    {/*<ManagerRoute*/}
                    {/*    path={`${path}/solutions`}*/}
                    {/*    render={() => <Database name="application" origin={origin}/>}*/}
                    {/*    hasPermission={hasPermission}*/}
                    {/*/>*/}
                    {/*<ManagerRoute*/}
                    {/*    path={`${path}/challenges`}*/}
                    {/*    render={() => <Database name="challenge" origin={origin}/>}*/}
                    {/*    hasPermission={hasPermission}*/}
                    {/*/>*/}
                    <ManagerRoute
                        path={`${path}/programs`}
                        render={() => <Database name="programs" origin={origin} setOrigin={setOrigin}/>}
                        hasPermission={hasPermission}
                    />
                    {/*{!origin &&*/}
                    {/*    <ManagerRoute*/}
                    {/*        path={`${path}/program/:id`}*/}
                    {/*        render={() => <DatabaseProgram hasPermission={true} origin={"database"} setOrigin={setOrigin} setExtraHeaderLeft={setExtraHeaderLeft}/>}*/}
                    {/*        hasPermission={hasPermission}*/}
                    {/*    />*/}
                    {/*}*/}
                    <ManagerRoute
                        path={`${path}/projects`}
                        render={() => <Database name="projects"/>}
                        hasPermission={hasPermission}
                    />
                    <ManagerRoute
                        path={`${path}/assessments`}
                        render={() => <Database name="assessment" scope={scope} scopeObj={scopeObj} origin={origin}/>}
                        hasPermission={hasPermission}
                    />
                </Switch>
            </Flex>
    );
}
