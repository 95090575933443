import {Liquid} from '@ant-design/plots';
import styled from "styled-components";
import {Divider} from "antd";
import { useTranslation } from "react-i18next";
import {blue_3, blue_7} from "../../../../../../global/utils/Colors";


const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 8px;
  color: #000000D9;
  
  >:first-child{
    flex-shrink: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
`;

function LiquidChart({data, indicator}) {
    const {t} = useTranslation();
    const totalIndicator = data.indicator_total;
    const getPercent = () => {
        if (data.indicator_total_percentage < 0) {
            return 1;
        }
        return (100 - data.indicator_total_percentage) / 100;
    }
    const percent = getPercent()
    
    const config = {
        height:250,
        percent: parseFloat(Number(percent).toFixed(2)),
        style:{
            fill: percent >= 0 ? blue_3: "#FFF1F0",
            textFill: percent >= 0 ? blue_7: "#FF4D4F",
            textFontSize:32,
            textFontWeight:80,
        },
        legend:{
            itemLabelFontSize:24,
        },
        interaction:{
            tooltip: false,
        },
    }

    return <>
        <Liquid {...config}/>
        <div style={{padding: 0}}>
                <StyledRow>
                    <div>{t(indicator?.name)}</div>
                    <div>{data?.value_display}</div>
                </StyledRow>
                <Divider style={{margin: 0}} />
                <StyledRow>
                    <div>{t(totalIndicator?.indicator)}</div>
                    <div>{totalIndicator?.value_display}</div>
                </StyledRow>

        </div>
    </>;
}

export default LiquidChart;