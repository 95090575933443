import styled from "styled-components";
import {Card, Col, Row, Space, Typography} from "antd";
import {useTranslation} from "react-i18next";
import WideSpace from "../layout/WideSpace";
import TruncateText from "../responsive/TruncateText";


const {Text} = Typography

const StyledCard = styled(Card)`

  cursor: ${props => props.onClick && "pointer"};
  border: none;
  box-shadow: 0 2px 5px 0 #00000014;


  .ant-card-body {
    padding: ${({padding}) => padding};
  }

  border-left: ${({
                    borderColor,
                    borderSize
                  }) => (borderColor ? `${borderSize}px solid ${borderColor}` : "none")};
  border-radius: ${({borderRadius}) => (borderRadius ? "2px" : "none")};

  .ant-card-head {
    ${({headerBorder}) => !headerBorder && `border-bottom: unset;`}
  }
`;

const StyledExtraContent = styled(Space)`
  width: 100%;
  text-align: end;
  justify-content: right;

  .ant-space-item {
    justify-content: end;
    overflow: hidden;
    display: flex;
  }
`

const StyledProgramTypeText = styled(Text)`
  color: ${({color}) => color};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
`

export default function InfoCardBase({
                                         color,
                                         subtitle,
                                         title,
                                         extra,
                                         onClick,
                                         children
                                     }) {
    const {t} = useTranslation()

    return (
        <StyledCard borderColor={color} borderSize="2"
                    onClick={onClick} padding="16px">
            <WideSpace direction="vertical">
                <Row justify="space-between" wrap={false}>
                    <Col xs={16} md={20}>
                        <StyledProgramTypeText
                            color={color}>{title}</StyledProgramTypeText>
                    </Col>
                    <Col flex={1} style={{textAlign: "end"}}>
                        <StyledExtraContent size="small">
                            {extra}
                        </StyledExtraContent>
                    </Col>
                </Row>
                <TruncateText
                    style={{fontSize: 16, fontWeight: 700}}
                    lines={2}>{subtitle} </TruncateText>
                {children}
            </WideSpace>
        </StyledCard>
    );
}
