import ProjectCreatePage from "./ProjectCreatePage";
import {ProjectCreateContextProvider} from "./context/ProjectCreateContext";
function ProjectCreate() {
    return (
        <ProjectCreateContextProvider>
            <ProjectCreatePage/>
        </ProjectCreateContextProvider>
    );
}

export default ProjectCreate;