import {useTranslation} from "react-i18next";
import api from "../../../global/services/api";
import {useParams} from "react-router-dom";
import React, {useState} from "react";
import {BuildFieldErrorsDict} from "../../../global/utils/Utils";
import styled from "styled-components";
import {Col, Form as FormAnt, Row} from "antd";
import InputFormField
    from "../../../global/components/data_entry/form/InputFormField";
import Form from "../../../global/components/data_entry/form/Form";
import Button from "../../../global/components/data_entry/Button";
import {
    FlexContainer
} from "../../../global/components/authentication/StyledComponents";
import useDefaultGetRequest from "../../../global/hooks/useDefaultGetRequest";
import SimpleInitiativeRegistrationCollapse
    from "./components/SimpleInitiativeRegistrationCollapse";
import PhoneNumberFormField
    from "../../../global/components/data_entry/form/PhoneNumberFormField";
import ReCaptchaButton from "../../../global/components/authentication/ReCaptchaButton";


const StyledForm = styled(Form)`
    & .ant-form-item {
        margin-bottom: 16px;
    }
`


const FORM_NAME = {
    NAME: 'author_name',
    PHONE: 'author_phone',
    EMAIL: 'author_email',
}

export default function SimpleInitiativeRegistration() {
    const {t} = useTranslation();
    const [form] = FormAnt.useForm()
    const {slug} = useParams();
    const [isLoadingSubmit, setIsLoadingSubmit] = useState()
    const [captchaValue, setCaptchaValue] = useState(false);

    const {
        data: program,
        isLoading: isLoadinProgram,
    } = useDefaultGetRequest({
        url: `/public/program/${slug}`,
        dependence: [slug],
        redirectIfNotFound: true,
    })

    const programSettings = program?.programsettings

    const handleSubmit = async () => {
        try {
            setIsLoadingSubmit(true)
            const data = await form.getFieldsValue()
            let response = await api.post(`/simple_initiative`, {
                ...data,
                "program_id": program.id
            }, {'version': 'v2'});
            window.top.location.href = `/simple_initiative_registration_form/${response.data.uuid}`
        } catch (error) {
            BuildFieldErrorsDict(error, null)
        } finally {
            setIsLoadingSubmit(false)
        }
    }

    return (

        <SimpleInitiativeRegistrationCollapse program={program}
                                              title={t('Enrollment')}>
            <StyledForm disabled={isLoadingSubmit}
                // initialValues={initialValues}
                        form={form}
                        onFinish={handleSubmit}>
                <Row>

                    <Col xs={24}>
                        <InputFormField form={{
                            label: t('Your full name'),
                            name: FORM_NAME.NAME,
                            style: {fontWeight: 500},
                            rules: [
                                {
                                    required: programSettings?.is_name_required,
                                    message: t('This field is required')
                                },
                                {
                                    validator: async (rule, value) => {
                                        if (value) {
                                            if (value.split(' ').length < 2) {
                                                return Promise.reject(new Error(t('Please enter full name')))
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                }
                            ]
                        }}/>
                    </Col>
                    <Col xs={24}>
                        <PhoneNumberFormField form={{
                            label: t('Cell phone'),
                            name: FORM_NAME.PHONE,
                            style: {fontWeight: 500},
                            rules: [{
                                required: programSettings?.is_phone_required,
                                message: t('This field is required')
                            }]
                        }}/>
                    </Col>
                    <Col xs={24}>
                        <InputFormField form={{
                            label: t('E-mail'),
                            name: FORM_NAME.EMAIL,
                            style: {fontWeight: 500},
                            rules: [{
                                required: programSettings?.is_email_required,
                                message: t('This field is required')
                            }, {
                                type: 'email',
                                message: t('Please provide a valid email')
                            }]
                        }}/>
                    </Col>
                    <Col xs={24} style={{marginTop: 24}}>
                        <FlexContainer justify={'end'} style={{marginTop: 8}}>
                            <ReCaptchaButton setCaptchaValue={setCaptchaValue}/>
                        </FlexContainer>
                    </Col>
                    <Col xs={24}>
                        <FlexContainer justify={'end'} style={{marginTop: 8}}>
                            <Button loading={isLoadingSubmit}
                                    disabled={!captchaValue}
                                    onClick={form.submit}>
                                {t('Continue')}
                            </Button>
                        </FlexContainer>

                    </Col>

                </Row>
            </StyledForm>
        </SimpleInitiativeRegistrationCollapse>
    )
}