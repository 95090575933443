import {createContext, useContext, useState} from "react";
import api from "../../../../../services/api";

const ChallengeDrawerContext = createContext({})


export function ChallengeDrawerContextProvider({ children }) {

    const [challenge, setChallenge] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const fetchChallenge = async (id) => {
        try {
            if (!id) return;
            setIsLoading(true);
            const response = await api.get(`/challenge_details/${id}`, {version: "v2"});
            setChallenge(response.data);
        } finally {
            setIsLoading(false)
        }
    }

    const fetchProgram = async (id) => {
        try {
            if (!id) return;
            setIsLoading(true);
            const response = await api.get(`/challenge_details/${id}/program`, {version: "v2"});
            setChallenge(response.data)
        } finally {
            setIsLoading(false);
        }
    }

    const value = {
        challenge,
        isLoading,
        fetchChallenge,
        fetchProgram,
    }
    return <ChallengeDrawerContext.Provider value={value}>
        {children}
    </ChallengeDrawerContext.Provider>
}

export default function useChallengeDrawerContext() {
    return useContext(ChallengeDrawerContext);
}