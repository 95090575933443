import React from 'react';
import styled from 'styled-components'

const StyledTitle = styled.h1`
    font-size: 22px;
    color: #4C4C4C;
     font-weight: 600;
     margin: 0;
   text-transform: ${props => props.noCamel? 'initial':'capitalize'}
`

function Title({children,noCamel}) {

    return (
        <StyledTitle noCamel={noCamel}>
            {children}
        </StyledTitle>
    );
}

export default Title;