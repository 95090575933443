import React, {useEffect, useRef, useState} from 'react';
import {DragDropContext} from 'react-beautiful-dnd';
import DroppableComponent from "./CoreComponents/droppable";
import DraggableComponent from "./CoreComponents/draggable";
import DraggableCard from "./CoreComponents/DraggableCard";
import {Col, Row} from "react-bootstrap";
import getComponent, {
    COMPONENTS,
    createJsonComponent
} from "./FormComponents/Factory";
import {
    DraggableContent,
    DropableContent,
    ImportButton,
} from "./StyledComponents";
import ButtonDefault from "../../Buttons/ButtonDefault";
import {useTranslation} from "react-i18next";


function PhaseFormBuilder({
                              // form,
                              phaseForm,
                              updateCurrentForm,
                              // handleAutoSave,
                              formRef, currentSection,
                              // setFormulario,
                              onChangeValue,
                              copyForm,
                              handleOpenPreview
                          }) {
    const [form, setFormulario] = useState([])

    const [scroll, setScroll] = useState(false)
    const openedComponent = useRef(null);
    const {t} = useTranslation();

    useEffect(() => {
        if (scroll === true) {
            scrollToBottom()
            setScroll(false)
        }
    }, [formRef.current, scroll])

    useEffect(() => {
        setFormulario(formRef.current)
    }, [currentSection?.id])

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    };

    //Functions of form
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };


    const copy = (source, destination, droppableSource, droppableDestination) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        const [copy] = sourceClone.splice(droppableSource.index, 1);
        let json = createJsonComponent(copy.type)

        json = {...json, label: t(json.label)}
        if (json.choices) {
            json.choices = json.choices.map((item) => ({
                ...item,
                'value': t(item.value)
            }))

        }
        destClone.splice(droppableDestination.index, 0, json);

        return destClone;
    };

    const remove = (event, index) => {
        // form.splice(index, 1)
        const removedItem = formRef.current[index]
        formRef.current.splice(index, 1)

        // handleAutoSave(formRef.current, removedItem)

        setFormulario([...formRef.current])
        updateCurrentForm()
    }

    const onDragEnd = result => {
        const {source, destination} = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId !== destination.droppableId) {
            const result = copy(
                COMPONENTS,
                formRef.current,
                source,
                destination
            );
            openedComponent.current && openedComponent.current.click()
            setScroll(formRef.current.length === destination.index)
            setFormulario(result);
            formRef.current = result
            // handleAutoSave(formRef.current)
        } else if (source.droppableId === 'form') {
            const items = reorder(
                formRef.current,
                source.index,
                destination.index
            );
            setFormulario(items);
            formRef.current = items
            // handleAutoSave(formRef.current)
        }
        updateCurrentForm()
    };

    function createComponent(json, index) {
        switch (json.type) {
            case 'division':
                return <hr key={json.type + index}
                           style={{margin: 0}}/>

            case 'title_form_group':
                return <h2 key={json.type + index}
                           style={{
                               fontWeight: 'bold',
                               fontSize: 14,
                               color: "#C4C4C4",
                               textTransform: 'uppercase',
                               margin: '14px 16px 4px 16px'
                           }}>{t(json.name)}</h2>
            default:
                return (
                    <DraggableComponent
                        id={json.type}
                        index={index}
                        key={json.type}
                        render_clone={true}>
                        <DraggableCard {...json} />
                    </DraggableComponent>
                )
        }

    }

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Row style={{margin: 0}}>
                    <Col xs={6} md={9} xl={9} style={{
                        paddingLeft: 0,
                        height: "70vh",
                        overflow: "auto"
                    }}>
                        <DropableContent>
                            <DroppableComponent id={'form'} showContent={true}>
                                {form?.map((component_item, index) =>
                                    <DraggableComponent
                                        id={component_item.name}

                                        key={component_item.name}
                                        index={index}>
                                        {getComponent({
                                            ...component_item,
                                            phaseForm: phaseForm,
                                            parent_section: currentSection,
                                            index: index,
                                            remove: remove,
                                            onchange: onChangeValue,
                                            openedComponent: openedComponent,
                                        })}
                                    </DraggableComponent>
                                )}
                            </DroppableComponent>
                        </DropableContent>
                    </Col>
                    <Col xs={6} md={3} xl={3}
                         style={{right: '0', top: "0px"}}>
                        <div>
                            <DraggableContent>
                                <DroppableComponent id={'components_list'}
                                                    disabled_drop={true}
                                                    showScroll={true}>
                                    {COMPONENTS.map(createComponent)}
                                </DroppableComponent>
                            </DraggableContent>
                            <ImportButton
                                onClick={copyForm}>{t("Import")}</ImportButton>
                            {/*<PreviewButton onClick={handleOpenPreview}>Preview</PreviewButton>*/}
                            <ButtonDefault style={{width: '100%'}}
                                           color={'tertiary'}
                                           onClick={handleOpenPreview}
                                           label={t("Preview")}/>
                        </div>
                    </Col>
                </Row>
            </DragDropContext>


        </>
    )
}

export default PhaseFormBuilder