import {useTranslation} from "react-i18next";
import {ExclamationCircleTwoTone} from "@ant-design/icons";
import React from "react";
import {Modal} from "antd";

const {confirm} = Modal;

export const useDeactivateIndicator = () => {
    const {t} = useTranslation();
    const confirmDeactivateIndicator = ({indicator, handleConfirm}) => {

        const isUsed = indicator && indicator["is_used"];
        let confirmMessage;
        let title;

        if (isUsed) {
            confirmMessage = (
                `${t("When deactivating an indicator being used, it can no longer be updated and all formulas that depends on it will also be deactivated.")}
            ${t("Would you like to deactivate it?")}`
            );
            title = t("Indicator in use");
        } else {
            confirmMessage = (
                `${t("By deactivating the indicator, everyone who uses the environment will not be able to use this indicator and all formulas that depends on it will also be deactivated.")} 
            ${t("Would you like to deactivate it?")}`
            );
            title = t("Deactivate Indicator");
        }

        confirm({
            title: title,
            icon: <ExclamationCircleTwoTone twoToneColor={isUsed ? "#FF4D4F" : "#FAAD14"}/>,
            content: confirmMessage,
            okText: t("Yes"),
            okButtonProps: {danger: isUsed, "data-cy": "confirm-deactivate-indicator-button"},
            cancelText: t("No"),
            onOk() {
                handleConfirm();
            },
        });
    };

    return {confirmDeactivateIndicator};
};