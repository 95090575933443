import {Card, Row, Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
import React from "react";

export function IndicatorDashboardCard({children, title, description, style, bodyStyle }) {

    return <Card style={{height: "100%", ...style}} bodyStyle={{height: "100%", display: "flex", flexDirection: "column", ...bodyStyle}}>
        <Row justify="space-between" align="middle" style={{paddingBottom: "24px"}} wrap={false}>
            <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>{title}</div>
            {description &&
                <Tooltip style={{position: "fixed"}} title={description}>
                    <InfoCircleOutlined style={{ cursor: "pointer" }} />
                </Tooltip>
            }
        </Row>
        {children}
    </Card>;
}

export default IndicatorDashboardCard;