import React, { useEffect } from 'react';
import { GOOGLE_APP_CLIENT_ID } from "../../../settings";

const GoogleLogin = ({ buttonText, onSuccess, onFailure, disabled, refButton, ...props }) => {
    const handleCredentialResponse = (credential) => {
        // console.log('Credential received:', credential); // Adicione este log
        onSuccess(credential);
    };

    const handleFailure = (error) => {
        console.error('Authentication failed:', error); // Adicione este log
        onFailure(error);
    };

    const removeScript = (script) => {
        try {
            document.body.removeChild(script);
        } catch (e) {
            console.error('Error removing script:', e);
        }
    };

    useEffect(() => {
        console.log('Loading Google Sign-In script...'); // Adicione este log
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        script.onload = () => {
            console.log('Google Sign-In script loaded. Initializing...'); // Adicione este log
            window.google.accounts.id.initialize({
                client_id: GOOGLE_APP_CLIENT_ID,
                callback: handleCredentialResponse,
                cancel_on_tap_outside: false
            });
            window.google.accounts.id.prompt();
            window.google.accounts.id.renderButton(document.getElementById("signinDiv"), {
                theme: 'outline',
                size: 'large',
                width: 300,
            });
        };

        script.onerror = () => {
            console.error('Error loading Google Sign-In script.'); // Adicione este log
        };

        if (disabled) {
            removeScript(script);
        }

        return () => {
            removeScript(script);
        };
    }, [disabled]);

    return (
        <div id="signinDiv" ref={refButton} {...props} />
    );
};

export default GoogleLogin;
