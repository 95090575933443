import {Grid, Space} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {useLayoutContext} from "./context/LayoutContext";
import {useHistory} from "react-router-dom";
import React from "react";
import {
    MenuCollapseButton
} from "../../../../global/components/layout/MenuHeader";
import Breadcrumb from "../../../../global/components/layout/Breadcrumb";
import styled from 'styled-components';
import {
    FlexContainer
} from "../../../../global/components/authentication/StyledComponents";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const StyledFlexContainer = styled(FlexContainer)`
  height: 64px;
`
const StyledArrowLeftOutlined = styled(ArrowLeftOutlined)`
  font-size: ${({isMobile}) => isMobile ? '24px' : '16px'};
  vertical-align: middle;
`

export default function ExtraHeader({
                                        left,
                                        right,
                                        returnPath,
                                        breadcrumbItems,
                                        goBack = true
                                    }) {

    const history = useHistory();
    const {md} = useBreakpoint();

    return (
        <StyledFlexContainer justify={'space-between'}>

            <Space>
                <StyledArrowLeftOutlined
                    isMobile={!md}
                    onClick={() => returnPath ? history.push(returnPath) : history.go(-1)}/>
                {breadcrumbItems &&
                    <Breadcrumb breadcrumbItems={breadcrumbItems}/>}
                {left}
            </Space>
            {right}
        </StyledFlexContainer>);

}