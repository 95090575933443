import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Badge, Modal, Row, Space, Button, Flex} from "antd";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {
    useLayoutContext
} from "../../../../../modules/project/components/layout/context/LayoutContext";
import {usePermissionContext} from "../../../../context/PermissionContext";
import useSystemBreakpoint from "../../../../hooks/useSystemBreakpoint";
import api from "../../../../services/api";
import {ToastNotification} from "../../../feedback/ToastNotification";
import Drawer from "../../../layout/Drawer";
import DrawerTabs from "../../../layout/DrawerTabs";
import UserAvatar from "../../../misc/UserAvatar";
import LimitedSpace from "../../../responsive/LimitedSpace";
import TruncateText from "../../../responsive/TruncateText";
import ApplicationTagStatus from "../../../tag/ApplicationTagStatus";
import ApplicationSettingsTab
    from "./components/SettingsTab/ApplicationSettingsTab";
import AttachmentSolutionTab
    from "./components/AttachmentSolutionTab/AttachmentSolutionTab";
import DescriptionSolutionTab
    from "./components/DescriptionSolutionTab/DescriptionSolutionTab";
import HistoryTab from "./components/HistoryTab/HistoryTab";
import MessagesSolutionTab
    from "./components/MessagesSolutionTab/MessagesSolutionTab";
import TeamSolutionTab from "./components/TeamSolutionTab/TeamSolutionTab";
import {
    ApplicationDrawerContextProvider,
    useApplicationDrawerContext
} from "./context/ApplicationDrawerContext";
import TransparentBadge from "../../../data_display/TransparentBadge";
import RequestsSolutionTab from "./components/RequestsSolutionTab/RequestsSolutionTab";
import NoteCountButtonDrawer from "../../note/NoteFormDrawer/NoteCountButtonDrawer";
import LikeIconContainer from "../../../../pages/home/Feed/components/LikeIconContainer";


const {confirm} = Modal;

function CountIconBadge({count, icon, tooltip}) {
    return (
        <TransparentBadge tooltip={tooltip}>
            <Space>
                <div>{count}</div>
                {icon}
            </Space>
        </TransparentBadge>
    )
}

function ApplicationDrawerContextLess({
                                          solutionId,
                                          open,
                                          setOpen,
                                          defaultActiveKey,
                                          showGoToProgram,
                                          postUpdate,
                                          onMessagesRead,
                                          evaluatorRequestMode,
                                          commentMode
                                      }) {
    const {t} = useTranslation()
    const {
        solution,
        setSolution,
        fetchSolution,
        isLoading,
        messageCount,
        groupedApplications,
        fetchGroupedApplications,
        setGroupedApplications,
        setOriginatedChallenges,
        requestsCount,
        setCommentMode,
        setTopic,updateContentOnClose, refreshContentOnClose
    } = useApplicationDrawerContext();

    const {isPhone} = useSystemBreakpoint();
    const {hasProgramAdminOrManagerPermission} = usePermissionContext();
    const {updateLayoutContent} = useLayoutContext();
    postUpdate = postUpdate || updateLayoutContent;

    const countGroupedApplications = groupedApplications?.length || 0
    const countLinkedProjects = solution?.project ? 1 : 0

    const handleClose = () => {
        setSolution(null)
        setOpen(false);
        setGroupedApplications(undefined)
        setOriginatedChallenges(undefined)
        setTopic(null)

        if (onMessagesRead) {
            onMessagesRead({object_id: solutionId});
        }

        if (updateContentOnClose) {
            updateLayoutContent()
        }
    }


    const hasIncompletePhases = () => {
        return (solution?.solution_history.filter(item => item.has_phase_actions)).length > 0
    }

    const showRequestTab = !(solution?.current_phase === solution?.phases[0]?.id && solution?.status === "in_filling")

    function getItems() {
        const items = [
            {
                label: <>{t("Description")} {hasIncompletePhases() &&
                    <Badge count={1}/>}</>,
                key: "1",
                children: <DescriptionSolutionTab/>,
            }, {
                label: <>{t("Messages")} <Badge count={messageCount}/></>,
                key: "2",
                children: <MessagesSolutionTab/>,
                permission: (solution.topic_id && solution.user_has_relation) || commentMode
            }, {
                label: t("History"),
                key: "3",
                children: <HistoryTab/>
            }, {
                label: t("Attachments"),
                key: "4",
                children: <AttachmentSolutionTab/>
            }, {
                label: <>{t("Requests")} <Badge count={requestsCount}/></>,
                key: "5",
                children: <RequestsSolutionTab/>,
                permission: showRequestTab
            }, {
                label: t("Authors"),
                key: "6",
                children: <TeamSolutionTab/>
            }, {
                label: t("Settings"),
                key: "7",
                children: <ApplicationSettingsTab
                    setOpen={setOpen}
                    postUpdate={postUpdate}
refreshContentOnClose={refreshContentOnClose}
                />,
                permission: hasProgramAdminOrManagerPermission
            },
        ]
        return items.filter((object) => object.hasOwnProperty("permission") ? object.permission : true)
    }

    function getItemsEvaluatorRequest() {
        const items = [
            {
                label: t("Requests"),
                key: "1",
                children: <RequestsSolutionTab/>,
            }
        ]
        return items.filter((object) => object.hasOwnProperty("permission") ? object.permission : true)
    }


    const confirmDeleteApplication = () => {
        confirm({
            title: t("Do you want to delete this application?"),
            icon: <ExclamationCircleOutlined/>,
            content: t("This action cannot be undone. When you delete this application, it will no longer be in the program's database and cannot be accessed anymore."),
            okText: t("Delete"),
            okButtonProps: {danger: true, type: "secondary"},
            cancelText: t("Cancel"),
            cancelButtonProps: {type: "primary"},
            onOk: handleDeleteApplication
        });
    }

    const handleDeleteApplication = async () => {
        try {
            const url = `/applications/${solutionId}`
            await api.delete(url, {version: "v2"})
            ToastNotification(t("Application deleted successfully"));
            setOpen(false)
            postUpdate && postUpdate()
        } catch (error) {
            ToastNotification(error.response?.data.detail);
        }
    }

    useEffect(() => {
        if (solution) {
            !groupedApplications && fetchGroupedApplications();
        }
        setCommentMode(commentMode)
    }, [solution]);

    useEffect(() => {
        if (open) {
            !solution && fetchSolution(solutionId);
        }
    }, [solutionId, open, solution]);

    return (
        <>
            <Drawer
                hideTopBorder={true}
                width={1024}
                loading={isLoading}
                open={open}
                onClose={handleClose}
                bodyStyle={{
                    position: "relative",
                    padding: 0,
                    background: "#FAFAFA"
                }}
                destroyOnClose={true}
                headerPadding={isPhone ? "8px 8px 4px 8px" : "16px 16px 4px 16px"}
                title={
                    <Flex gap={8} justify="space-between" align={"center"}>
                        <LimitedSpace flexWrap={false} align="center">
                            <UserAvatar size="small" className="keep-width"
                                        data={solution?.team}/>
                            <TruncateText>{solution?.name}</TruncateText>
                            {/* {!isPhone &&
                                <>
                                    <CountIconBadge count={countLinkedProjects}
                                                    icon={<ProjectOutlined style={{color: PROJECT_COLOR, fontSize: 12}}/>}
                                                    tooltip={
                                                        solution?.project ? `${t("Linked to the following project")}: ${solution?.project?.name}` :
                                                            t("Linked to no project")
                                                    }
                                    />
                                    <CountIconBadge count={countGroupedApplications}
                                                    icon={
                                                        <RemixIcon remixIconName="ri-git-merge-line" style={{color: APPLICATION_COLOR, fontSize: 12}} />
                                                    }
                                                    tooltip={t("associatedOtherApplications", {countGroupedApplications})}
                                    />
                                </>
                                
                            } */}
                            <Flex>
                                <ApplicationTagStatus className="keep-width"
                                                      application={solution}/>
                            </Flex>
                        </LimitedSpace>
                        <Flex gap={8} align="center">
                            {hasProgramAdminOrManagerPermission && solutionId &&
                                <NoteCountButtonDrawer url={`/notes/fetch_note_by_application/${solutionId}`}/>
                            }
                        </Flex>
                    </Flex>
                }
                footer={
                    <Row justify="space-between">
                        <LikeIconContainer object={"topics"} object_id={solution?.topic_id} topicReactions={solution?.topic_reactions}/>
                        <Space>
                            {solution?.is_team_manager &&
                                <Button type="default" danger size="small" onClick={confirmDeleteApplication}>{t("Delete")}</Button>
                            }
                        </Space>
                    </Row>
                }
            >
                {solution && <DrawerTabs defaultActiveKey={defaultActiveKey}
                                         items={evaluatorRequestMode ? getItemsEvaluatorRequest() : getItems()}/>}
            </Drawer>
        </>
    );
}


export default function ApplicationDrawer({
                                              solutionId,
                                              open,
                                              setOpen,
                                              defaultActiveKey = "1",
                                              showGoToProgram = true,
                                              postUpdate,
                                              onMessagesRead,
                                              evaluatorRequestMode = false,
                                              commentMode = false,
                                          }) {
    return (
        <ApplicationDrawerContextProvider>
            <ApplicationDrawerContextLess solutionId={solutionId}
                                          open={open}
                                          setOpen={setOpen}
                                          defaultActiveKey={defaultActiveKey}
                                          showGoToProgram={showGoToProgram}
                                          postUpdate={postUpdate}
                                          onMessagesRead={onMessagesRead}
                                          evaluatorRequestMode={evaluatorRequestMode}
                                          commentMode={commentMode}
            />
        </ApplicationDrawerContextProvider>)
}