import React from "react";
import styled from 'styled-components'
import {NavLink} from "react-router-dom";

const LinkItemContainer = styled(NavLink)`
  display: inline-flex;
  align-items: center;
  column-gap: 7px;
  color: #ADADAD;
  margin: 6px 4px;
  //text-transform: capitalize;
  //background-color: white;
  text-decoration: ${({textDecoration})=>!textDecoration ? "none": "underline"} !important;
  border: none;
  border-bottom: solid 1px transparent;
  transition: 0.3s;
  opacity: 0.7;

  span {
    font-size: 13px;
    //font-family: Whitney, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    color:var(--text_color_menu);
  }

  svg {
    stroke: var(--text_color_menu);
    fill: var(--text_color_menu);
    transition: 0.3s;
    width: 13px;
    height: 13px;
    opacity: 0.8;
  }

  &:hover {
    text-decoration: ${({textDecoration})=>!textDecoration ? "none": "underline"} !important;
    opacity: 1;
    //background-color: var(--active_menu_color);
    color:var(--active_menu_color);
    //border-bottom-color: var(--active_menu_color);
  }

  &:hover svg {
    //fill: var(--active_menu_color);
    //stroke: var(--active_menu_color);
  }

  &.active {
    opacity: 1;
    //background-color: var(--active_menu_color);
    color: var(--active_menu_color);
    //border-bottom-color: var(--active_menu_color);
  }

  &.active svg {
    //stroke: var(--active_menu_color);
    //fill: var(--active_menu_color);
  }

`

export default function NavItemLink({to, children, textDecoration=false, ...props}) {
    return (
        <LinkItemContainer textDecoration={textDecoration} to={to} {...props}>
            {children}
        </LinkItemContainer>

    )
}