import {BuildFieldErrorsDict} from "../global/utils/Utils";
import React, {createContext, useContext, useEffect, useState} from "react";
import api from "../global/services/api";
import {Link, useParams, useRouteMatch} from "react-router-dom";
import {useTranslation} from "react-i18next";
import ExtraHeader from "../modules/project/components/layout/ExtraHeader";
import RoutesPhases from "../modules/program/routes/RoutesPhases";
import {
    useLayoutContext
} from "../modules/project/components/layout/context/LayoutContext";
import {PHASE} from "../global/utils/Constants";
import { ProgramContextProvider, useProgramContext } from "./ProgramsNavigator";
import Loading from "../global/components/feedback/Loading";


export const PhasesContext = createContext();

const PhasesContextProvider = ({children}) => {

    const [phase, setPhase] = useState();

    const {phase_id} = useParams();

    const getPhase = async () => {
        try {
            const response = await api.get(`phases/${phase_id}`);
            setPhase(response.data);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
    };

    useEffect(() => {
        if (phase_id) {
            getPhase();
        }
    }, [phase_id]);

    return <PhasesContext.Provider
        value={{phase}}>{children}</PhasesContext.Provider>;
};

export const usePhasesContext = () => {
    return useContext(PhasesContext);
};

function PhasesNavigatorPage({hasPermission}) {
    const {t} = useTranslation();
    const {phase} = usePhasesContext();
    const {url} = useRouteMatch();
    const {
        initializeLayout,
    } = useLayoutContext();
    const {program, isLoadingProgram} = useProgramContext();

    const isEvaluationPhase = (phase) => {
        return phase.type === PHASE.EVALUATION;
    }

    const isApplicationOrEvaluationPhase = (phase) => {
        return phase.type === PHASE.APPLICATION || phase.type === PHASE.EVALUATION
    }

    const getFormLink = (phase) => {
        return isEvaluationPhase(phase) ? `${url}/evaluateform` : `${url}/form`
    }


    useEffect(() => {
        if (phase) {

            const returnPath = url.split("/").slice(0, -1).join("/");
            const breadcrumbs = [
                {label: phase["program_name"], key: returnPath},
                {label: phase["title"], key: url},
            ]

            const navItem = [
                    {
                        label: <Link
                            to={`${url}/evaluators`}>{t("Evaluators")}</Link>,
                        key: `${url}/evaluators`,
                        hasPermission: isEvaluationPhase(phase)
                    },
                    {
                        label: <Link
                            to={`${url}/assessments`}>{t("Evaluations")}</Link>,
                        key: `${url}/assessments`,
                        hasPermission: isEvaluationPhase(phase)
                    },
                    {
                        label: <Link
                            to={getFormLink(phase)}>{t("Form")}</Link>,
                        key: getFormLink(phase),
                        hasPermission: isApplicationOrEvaluationPhase(phase),
                    },
                    // {
                    //     label: <Link
                    //         to={`${url}/allocation_evaluators`}>{t("Evaluator Allocation")}</Link>,
                    //     key: `${url}/allocation_evaluators`,
                    //     hasPermission: isEvaluationPhase(phase),
                    // },
                    {
                        label: <Link
                            to={`${url}/application-selection`}>{t("Application Selection")}</Link>,
                        key: `${url}/application-selection`,
                        hasPermission: isEvaluationPhase(phase),
                    },
                    {
                        label: <Link
                            to={`${url}/send-feedback`}>{t("Feedback")}</Link>,
                        key: `${url}/send-feedback`,
                        hasPermission: isEvaluationPhase(phase),
                    },
                    {
                        label: <Link
                            to={`${url}/database`}>{t("Database")}</Link>,
                        key: `${url}/database`,
                        hasPermission: isApplicationOrEvaluationPhase(phase),
                        isActiveForChildren: true,
                    },
                    {
                        label: <Link
                            to={`${url}/update`}>{t("General")}</Link>,
                        key: `${url}/update`,
                    },
                ]

            initializeLayout({
                navbarMenuItems: navItem,
                extraHeaderContent: <ExtraHeader breadcrumbItems={breadcrumbs}
                                                 returnPath={returnPath}/>,
                showAvatar: false,
            });
        }
    }, [phase, location.pathname]);



    return (<>
        {isLoadingProgram ? <Loading /> : <RoutesPhases hasPermission={hasPermission}/>}
    </>);
}


export default function PhasesNavigator({hasPermission}) {
    return (
        <ProgramContextProvider>
            <PhasesContextProvider>
                <PhasesNavigatorPage hasPermission={hasPermission} />
            </PhasesContextProvider>
        </ProgramContextProvider>
    );
}
