import IndicatorCheckpointCard, {IndicatorInput, ValueComponent} from "./IndicatorCheckpointCard";
import {Space} from "antd";
import {useStatusReportContext} from "../../context/StatusReportContext";
import {EditTwoTone} from "@ant-design/icons";
import MoneyInput from "../../../../components/form/MoneyInput";
import {useState} from "react";
import IndicatorHistory from "./IndicatorHistory";
import { useTranslation } from "react-i18next";

function IndicatorCurrencyCheckpointCard({indicatorResponse, ...props}) {
    const { t } = useTranslation();
    const [isEditing, setIsEditing] = useState(false);
    const {indicator} = indicatorResponse;
    const {updateIndicatorResponse} = useStatusReportContext();
    const handleValueChange = (value, maskedValue) => {
        updateIndicatorResponse({id: indicatorResponse["id"], values: {"stored_value": value, "value_display": maskedValue}});
    };

    return <>
        <IndicatorCheckpointCard indicatorResponse={indicatorResponse} {...props}>
            {indicatorResponse["has_formulas"] ?
                <ValueComponent value={indicatorResponse["value_display"]} prefix={indicator["indicator_view"]["field_prefix"]}/> :

                isEditing ?
                    <IndicatorInput
                        input={MoneyInput}
                        value={indicatorResponse["stored_value"]}
                        prefix={t(indicator["indicator_view"]["field_prefix"])}
                        onChange={handleValueChange}
                        onFinishEditing={() => setIsEditing(false)}
                    /> :
                    <Space onClick={() => setIsEditing(true)} style={{cursor: "text"}}>
                        <ValueComponent value={indicatorResponse["value_display"]} prefix={t(indicator["indicator_view"]["field_prefix"])}/> <EditTwoTone type="primary"/>
                    </Space>
            }
            <IndicatorHistory indicatorResponse={indicatorResponse}/>
        </IndicatorCheckpointCard>
    </>;
}

export default IndicatorCurrencyCheckpointCard;