import { useState } from "react";
import { useContext } from "react";
import { createContext } from "react";

const SolutionDrawerSharedContext = createContext({});

export function SolutionDrawerSharedContextProvider({ children }) {
    const [open, setOpen] = useState(false);
    const [solutionId, setSolutionId] = useState(undefined);

    const openCurrentSolution = (id) => {
        setSolutionId(id);
        setOpen(true);
    }

    return <SolutionDrawerSharedContext.Provider value={{ openCurrentSolution, solutionId, open, setOpen }}>{children}</SolutionDrawerSharedContext.Provider>
}

export function useSolutionDrawerSharedContextProvider() {
    return useContext(SolutionDrawerSharedContext);
}