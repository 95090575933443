import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {FieldTimeOutlined} from '@ant-design/icons'
import {
    DatetimeFormatByTimezone
} from "../../../../../utils/dates";
import useTable from "../../../../../hooks/useTable";
import {Col, Row} from "antd";
import Table from "../../../../../components/data_display/Table";
import Drawer from "../../../../../components/layout/Drawer";
import DrawerRankingPlacements
    from "../../../../../components/data_display/DrawerRankingPlacements";
import Search from "../../../../../components/data_entry/Search";
import {neutral_5} from "../../../../../utils/Colors";
import {
    generateGamificationsActionsMessage
} from "../../../../../utils/Constants";
import TagActionGamification
    from "../../../../../components/data_display/TagActionGamification";
import LimitNumber from "../../../../../components/data_display/LimitNumber";

const StyledFieldTimeOutlined = styled(FieldTimeOutlined)`
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;

    //background: red;

    :hover {
        background: ${neutral_5};
    }
`
export default function HistoryTransactionsButton({url, hideColumns = []}) {

    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState('')
    const [openPlacements, setOpenPlacements] = useState(false)
    const [rankingId, setRankingId] = useState(false)
    const {t} = useTranslation();
    const {
        handleRequestTable,
        tableData,
        isLoading,
        tableParams,
        handleTableChange,
    } = useTable({
        url: '/action',
        waitExternalLoading: !open,
        extraParams: {search: search},
        hasPagination: true,
        dependenceRequest: [open, search]
    })


    function handleOpenPlacements(ranking) {
        setRankingId(ranking.id)
        setOpenPlacements(true)
    }

    function onClose() {
        setOpen(false)
    }

    const columns = [
        {
            title: t('Code'),
            dataIndex: 'id',
            key: 'id',
            width: 50

        },
        {
            title: t('Receiver'),
            dataIndex: 'name',
            key: 'name',

        },
        {
            title: t('Points'),
            dataIndex: 'total_points',
            key: 'total_points',
            render: (value, campaign) =>
                <>  {`${campaign.type_transaction === 'levar' ? '-' : '+'}`}{LimitNumber({value: value})}
                </>
        },
        {
            title: t('Date'),
            dataIndex: 'created_at',
            key: 'created_at',
            render: (value, campaign) =>
                <>  {DatetimeFormatByTimezone(value, false)}
                </>
        },
        {
            title: t('Action'),
            dataIndex: 'type_action',
            key: 'type_action',
            width: 250,
            render: (value, campaign) =>
                t(generateGamificationsActionsMessage(value, campaign?.justification_invalid))
        },
        {
            title: t('Status'),
            dataIndex: 'status',
            key: 'status',
            render: (value, _) => <TagActionGamification status={value}/>
        },

        {
            title: t('Validated by'),
            dataIndex: 'validated_by',
            key: 'validated_by',
            render: (value, gamification) => <>
                {gamification?.status === 'consolidated' && !value ? t('Automatic') : value || '-'}
            </>
        },
        {
            title: t('Revoked by'),
            dataIndex: 'invalidated_by',
            key: 'invalidated_by',
        },

    ]


    return (
        <>
            <StyledFieldTimeOutlined
                onClick={() => setOpen(true)}
            />
            <Drawer width={1200} title={t('Transaction history')} open={open}
                    onClose={onClose}>

                <Row gutter={[16, 16]}>

                    <Col xs={24} md={12} lg={6}>
                        <Search onSearch={setSearch}/>
                    </Col>
                    <Col xs={24}>
                        <Table
                            columns={columns.filter(({dataIndex}) => !hideColumns.includes(dataIndex))}
                            onChange={handleTableChange}
                            tableParams={tableParams}
                            loading={isLoading}
                            noScroll
                            dataSource={tableData}
                        />
                    </Col>
                </Row>
            </Drawer>
            <DrawerRankingPlacements id={rankingId} open={openPlacements}
                                     setOpen={setOpenPlacements}
                                     hasClose={false}/>
        </>
    )
}
