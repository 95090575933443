import React, {useMemo, useState} from "react";
import {
    ColumnWidthOutlined, DeleteOutlined,
    ExpandAltOutlined,
} from "@ant-design/icons";
import {Card, Dropdown, Space, Tooltip} from "antd";
import styled from "styled-components";
import Drawer from "../layout/Drawer";
import {useTranslation} from "react-i18next";
import { primary_1} from "../../utils/Colors";
import {FlexContainer} from "../authentication/StyledComponents";


const StyledDrawer = styled(Drawer)`
    .ant-drawer-body {
        overflow: ${({height}) => !height && 'hidden'};
    }
`

const StyledCardContainer = styled(Card)`
    border-radius: 12px;
    //padding: 8px;

    .ant-card-body {
        height: ${({height}) => height || "365px"};
        padding: 0px 16px 16px 16px;
    }

    .ant-card-head {
        padding: 0px 16px;
        border-bottom: none;
    }
`

const StyledPlotContainer = styled.div`
    height: 100%;
    ${({hasScroll}) => hasScroll && `
        overflow-x: auto;
        overflow-y: hidden;
    `}

    display: flex;
    flex-direction: column;

    .plot {
        flex-grow: 1;
        height: ${({height, plotHeight}) => plotHeight || height || "95%"};
        width: ${({hasScroll}) => hasScroll ? 'max-content' : '100%'};
    }

    .legend {
        flex-grow: 1;
    }

    .extra {
        padding-top: 8px;
    }

`;


const ActionContainer = styled.div`
    padding: 0px 8px;
    border-radius: 4px;
    &:hover {
        cursor: pointer;
        background: var(--primary_btn_color);
        color: white;
    }
`

const ItemDrop = styled.div`

    margin: -4px -12px;
    padding: 4px 8px;
    //.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child & {
    background: ${({active}) => active && primary_1};
    font-weight: ${({active}) => active && 'bold'};
    //}
`

export default function ChartCard({
                                      onDelete,
                                      children,
                                      title,
                                      onChangeColumns,
                                      expandible, columnSize,
                                      extraActions,
                                      extraDrawer,
                                      drawerProps,
                                      hasScroll,
                                      height,
                                      plotHeight,
                                      ...props
                                  }) {
    const {t} = useTranslation()
    const [expand, setExpand] = useState(false);


    const extraButtons = useMemo(() => {

        return <Space size={8}>
            {onChangeColumns && <Dropdown trigger={'click'}
                                          menu={{
                                              items: [{
                                                  key: '1',
                                                  label: <ItemDrop active={1 === columnSize}
                                                      onClick={() => onChangeColumns(1)}>{t('Compact size')}</ItemDrop>
                                              }, {
                                                  key: '2',
                                                  label: <ItemDrop active={2 === columnSize}
                                                      onClick={() => onChangeColumns(2)}>{t('Medium size')}</ItemDrop>
                                              }, {
                                                  key: '3',
                                                  label: <ItemDrop active={3 === columnSize}
                                                      onClick={() => onChangeColumns(3)}>{t('Large size')}</ItemDrop>
                                              },]
                                          }}>
                <ActionButton icon={<ColumnWidthOutlined/>}
                              title={t('Change chart size')}/>


            </Dropdown>}


            {expandible &&
                <ActionButton icon={<ExpandAltOutlined/>}
                              title={t('View expanded chart')}
                              onClick={() => setExpand(true)}/>
            }
            {onDelete &&
                <ActionButton icon={<DeleteOutlined/>}
                              title={t('Remove chart')} onClick={onDelete}/>
            }
        </Space>
    }, [extraActions, columnSize])

    return (
        <StyledCardContainer
            title={title}
            extra={extraButtons}
            height={height}
            {...props}
        >
            <StyledPlotContainer plotHeight={plotHeight} hasScroll={hasScroll}
                                 height={height}>
                {children}
            </StyledPlotContainer>

            {expandible &&
                <StyledDrawer 
                    title={<FlexContainer justify="space-between">{title} {extraDrawer}</FlexContainer>} 
                    open={expand}
                    setOpen={setExpand}
                    width={1000}
                    height={height}
                    destroyOnClose={true} {...drawerProps} >
                    <StyledPlotContainer height={height}>
                        {expand && React.cloneElement(children, {expand})}
                    </StyledPlotContainer>
                </StyledDrawer>}
        </StyledCardContainer>

    )
}


function ActionButton({title, icon, ...props}) {
    return (
        <Tooltip title={title}>
            <ActionContainer {...props}>
                {icon}
            </ActionContainer>
        </Tooltip>
    )
}