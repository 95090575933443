import { useProgramDetailContext } from "../context/ProgramDetailContext";
import { Card, Col, Row, Typography } from "antd";
import UserAvatar from "../../../../components/misc/UserAvatar";
import { ArrowRightOutlined } from "@ant-design/icons";
import WideSpace from "../../../../components/layout/WideSpace";
import styled from "styled-components";
import DisplayTextElements from "../../../../components/misc/DisplayTextElements";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/data_entry/Button";
import useSystemBreakpoint from "../../../../hooks/useSystemBreakpoint";
import Empty from "../../../../components/data_display/Empty";
import { useState } from "react";
import { getTimeLimitType } from "../../../../utils/Utils";
import ChallengeDrawer from "../../../../components/other/challenge/ChallengeDrawer/ChallengeDrawer";
import Loading from "../../../../components/feedback/Loading";
import useApplication from "../../../../hooks/useApplication";
import {toTitleCase} from "../../../../../modules/project/utils/utils";
import { DatetimeFormatByTimezone } from "../../../../utils/dates";
import TruncateText from "../../../../components/responsive/TruncateText";


const {Text} = Typography;

const StyledBoldFixedLines = styled(TruncateText)`
  font-size: 16px;
  font-weight: 700;
`;

const StyledButton = styled(Button)`
  width: 100%;
`

const StyledCardContentContainer = styled.div`
  height:100%;
  display:flex; 
  flex-direction:column;
  justify-content:space-between;
  gap: 8px;
`

const StyledClickableCard = styled(Card)`
    transition: 500ms;
    :hover {
        filter: brightness(96%);
        scale: 1.02;
    }
`

export default function ChallengesProgramTab() {
    const { t } = useTranslation()
    const { isDesktop } = useSystemBreakpoint()
    const { program, getProgramInfo, challenges } = useProgramDetailContext()
    const programInfo = getProgramInfo();
    const dateEnd = DatetimeFormatByTimezone(program?.first_phase_ending);

    const { handleApplyChallenge } = useApplication();
    const [openChallengeDrawer, setOpenChallengeDrawer] = useState(false);
    const [challengeIndex, setChallengeIndex] = useState(null);

    function handleChallengeDrawer(challenge) {
        setChallengeIndex(challenge);
        setOpenChallengeDrawer(true);
    }


    return (
        <>
            <Row gutter={[32, 16]}>
                {
                    challenges?.length > 0 &&
                    challenges?.map((challenge, index) => (
                        <Col span={isDesktop ? 8 : 24} style={{ display: "flex", flexDirection: "column" }}>
                            <StyledClickableCard style={{ flexGrow: 1 }} bodyStyle={{ height: "100%" }} onClick={() => handleChallengeDrawer(challenge)}>
                                <StyledCardContentContainer>
                                    <WideSpace direction="vertical">
                                        <Row justify="space-between">
                                            <UserAvatar size="large" data={{ picture: program?.client_profile }} />
                                            <ArrowRightOutlined />
                                        </Row>
                                        <TruncateText><Text type="secondary">{programInfo?.name}</Text></TruncateText>
                                        <StyledBoldFixedLines>{challenge.name}</StyledBoldFixedLines>
                                        <TruncateText><Text type="secondary">{challenge.description}</Text></TruncateText>
                                        {programInfo?.first_phase_ending &&
                                            <DisplayTextElements title={t("REGISTRATIONS UP UNTIL")} content={DatetimeFormatByTimezone(programInfo?.first_phase_ending)}
                                                type={getTimeLimitType(programInfo?.days_left)} />
                                        }
                                        <DisplayTextElements
                                            title={t("TYPE OF SUBMISSION")}
                                            content={programInfo?.team_types_display}
                                            mode="list"
                                        />
                                    </WideSpace>
                                    <Row>
                                        <StyledButton onClick={() => handleApplyChallenge(challenge)}>{`${t("Suggest")} ${t(toTitleCase(programInfo?.application_name_choice))}`}</StyledButton>
                                    </Row>

                                </StyledCardContentContainer>
                            </StyledClickableCard>
                        </Col>
                    ))
                }
                {challenges?.length === 0 ?
                    <div style={{ flexGrow: "1", display: "grid", placeContent: "center" }}>
                        <Empty />
                    </div> : 
                    !challenges && <Loading />
                }
            </Row>
            <ChallengeDrawer challengeIndex={challengeIndex} open={openChallengeDrawer}
                setOpen={setOpenChallengeDrawer} />
        </>
    );
}
