import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import ProjectManagementExtraHeader from "../components/ProjectManagementExtraHeader";
import {Badge, Space} from "antd";
import {useLayoutContext} from "../../../components/layout/context/LayoutContext";
import api from "../../../../../global/services/api";

import { useTranslation } from "react-i18next";
import { usePermissionContext } from "../../../../../global/context/PermissionContext";
import { PROJECT_COLOR } from "../../../../../global/utils/Colors";

const ProjectManagementContext = createContext({});

export function ProjectManagementContextProvider({ children }) {
    const { t } = useTranslation();
    let { id } = useParams();
    const {openNotification} = useLayoutContext();
    const [clientPhases, setClientPhases] = useState(undefined);

    // setProject(null) deve ser evitado, pois alguns componentes verificam isso para saber se o componente deve armazenar estado do projeto
    const [project, setProject] = useState(undefined);
    const [projectPermissions, setProjectPermissions] = useState(undefined);
    const history = useHistory();
    const [settingsBadges, setSettingsBadges] = useState();
    const [timelineBadges, setTimelineBadges] = useState();
    const [lateCheckpointStatus, setLateCheckpointStatus] = useState(false);
    const [refreshBadges, setRefreshBadges] = useState(["*"]);
    const {checkProjectPermissions} = usePermissionContext();

    const checkProjectPermissionContext = (...permissions)=>checkProjectPermissions(projectPermissions, ...permissions);

    function getMenuItems({ id, badges, projectPermissions})  {
        
        return [
            {
                label: <Link to={`/project-portfolio/project/${id}/dashboard`}>{t("Dashboard")}</Link>,
                key: `/project-portfolio/project/${id}/dashboard`,
                hasPermission: checkProjectPermissions(projectPermissions, "viewer", "manager")
            },
            {
                label: <Link to={`/project-portfolio/project/${id}/timeline`}>
                    <Space size={2}>
                        {t("Planning")}
                        
                        <>
                            <Badge style={{background: "#F0F0F0", color: "rgba(0, 0, 0, 0.45)"}} count={badges.timelineBadges["complete_tasks_count"]} />
                            <Badge style={{background: `${PROJECT_COLOR}`}} count={badges.timelineBadges["ongoing_tasks_count"]} />
                            <Badge count={badges.timelineBadges["late_tasks_count"]} />
                        </>
                        
                    </Space>
                </Link>,
                key: `/project-portfolio/project/${id}/timeline`,
                hasPermission: checkProjectPermissions(projectPermissions, "manager")
            },
            {
                label: <Link to={`/project-portfolio/project/${id}/checkpoints`}>{t("Checkpoints")} {badges.lateCheckpointBadges && <Badge status="danger" count={1} />}</Link>,
                key: `/project-portfolio/project/${id}/checkpoints`,
                hasPermission: checkProjectPermissions(projectPermissions, "manager")
            },
            {
                label: <Link to={`/project-portfolio/project/${id}/linked`}>{t("Linked")}</Link>,
                key: `/project-portfolio/project/${id}/linked`,
                hasPermission: checkProjectPermissions(projectPermissions, "manager"),
            },
            {
                label: <Link to={`/project-portfolio/project/${id}/settings`}>
                        <Space size={2}>
                            {t("Settings")}
                            {badges.settingsBadges > 0 && 
                                <>
                                    <Badge count={badges.settingsBadges["blank_indicators_count"]} />
                                    <Badge style={{ backgroundColor: 'orange' }}  count={badges.settingsBadges["late_indicators_count"]} />
                                </>
                            }
                        </Space>
                </Link>,
                key: `/project-portfolio/project/${id}/settings`,
                hasPermission: checkProjectPermissions(projectPermissions, "manager")
            },
    
        ];
    }

    const badges = {
        settingsBadges: settingsBadges || {},
        timelineBadges: timelineBadges || {},
        lateCheckpointBadges: lateCheckpointStatus,
    }

    const fetchClientPhases = async () => {
        const response = await api.get(`/project_management/project_phases_management/by_current_client`);
        setClientPhases(response.data.map((phase) => ({
            ...phase,
            label: t(phase.label)
        })));
    }

    const fetchProject = async () => {
        try {
            const response = await api.get(`/project_management/project/${id}`);
            setProject(response.data);
            setLateCheckpointStatus(response.data["next_checkpoint_info"]["overdue"]);
        } catch (error) {
            history.push('/404');
        }
    }

    const fetchProjectPermissions = async () => {
        try {
            const response = await api.get(`/project_management/project/${id}/permissions`);
            setProjectPermissions(response.data);
        } catch (error) {}
    }


    const updatePhase = async ({phaseId}) => {
        try {
            const payload = {
                phase: phaseId,
            };
            await api.post(`/project_management/project/${project?.id}/change_current_phase`, payload);
            void fetchProject(project?.id);
            openNotification({type: "success", message: t("Project updated!"), placement: "bottomRight"});
        } catch (error) {
            openNotification({type: "error", message: t("Couldn't update project phase!"), placement: "bottomRight"});
        }
    };

    const fetchSettingsBadges = async () => {
        const response = await api.get(`/project_management/project/${id}/badges/settings`);
        setSettingsBadges(response.data);
    }

     const fetchTimelineBadges = async () => {
        const response = await api.get(`/project_management/project/${id}/badges/timeline`);
        setTimelineBadges(response.data);
    }

    useEffect(()=>{
        if (!id) {
            return;
        }
        if (!refreshBadges){
            return;
        }
        const refreshAll = refreshBadges.includes("*");
        if (refreshBadges.includes("settingsBadges") || refreshAll) {
            void fetchSettingsBadges();
        }
        if (refreshBadges.includes("timelineBadges") || refreshAll) {
            void fetchTimelineBadges();
        }
        setRefreshBadges(undefined);
    }, [id, refreshBadges])

    useEffect(() => {
        if (id) {
            void fetchProjectPermissions();
            void fetchProject();
            void fetchClientPhases();
        }
    }, [id])


    const { initializeLayout } = useLayoutContext();
    useEffect(() => {
        const menuItems = getMenuItems({ id, badges, projectPermissions });
        initializeLayout({
            navbarMenuItems: menuItems,
            extraHeaderContent: <ProjectManagementExtraHeader clientPhases={clientPhases} checkProjectPermissionContext={checkProjectPermissionContext} project={project} updatePhase={updatePhase} />,
            customBackgroundColor: "var(--solv-fading-background)",
            showAvatar: false,
            useOldContentLayout: false,
        });
    }, [settingsBadges, timelineBadges, lateCheckpointStatus, project, projectPermissions, clientPhases]);

    return <ProjectManagementContext.Provider value={{ project, projectPermissions, clientPhases, setProject, fetchProject, updatePhase, setRefreshBadges, setLateCheckpointStatus, checkProjectPermissionContext }}> {children} </ProjectManagementContext.Provider>;
}


export function useProjectManagementContext() {
    return useContext(ProjectManagementContext);
}