import React, {useEffect, useState} from 'react';
import {Rose} from '@ant-design/plots';
import api from "../../../../../global/services/api";
import {BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import Empty from "../../../../../global/components/data_display/Empty";
import LoadingFull from "../../../../../global/components/other/LoadingFull";
import {useTranslation} from 'react-i18next';
import ChartLegendWrapper
    from "../../../../../global/components/data_display/ChartWrapper/ChartLegendWrapper";


const DEFAULT_CONFIG_REQUEST = {
    version: 'v1'
}

export default function RoseChart({
                                      request,

                                      legend_key,
                                      value = 'count',

                                  }) {
    const {t} = useTranslation();
    const [datas, setDatas] = useState()
    const [isLoading, setIsloading] = useState(false)
    const colorField = legend_key

    const config = {
        data: datas,
        autoFit: true,
        xField: legend_key,
        yField: value,
        colorField,
        axis: false,
        animate: null,
        scale: {y: {type: 'sqrt'}, x: {padding: 0}},
        interaction: {
            elementHighlightByColor: {
                link: true
            }
        },
        label: false,
        tooltip: {
            title: false,
            items: [(item) => (
                {
                    name: t(item[legend_key]),
                    field: legend_key,
                    channel: "x",
                    value: item[value]
                })
            ],
        },
        style: {height: "284px", width: "100%"},
        _legend: {
            colorField,
            rows: 1,
            cols: 3,
            labelFormatter: (label) => ({
                text: t(label.text),
            })
        },
        drawerConfig: {
            _legend: {
                labelFormatter: (label) => ({
                    text: t(label.text),
                }),
                rows: 2,
            }
        }
    };

    useEffect(() => {
        if (request?.url) {
            const fetchData = async () => {
                try {
                    setIsloading(true)
                    const config = {
                        params: request.params,
                        ...DEFAULT_CONFIG_REQUEST,
                        ...request.config
                    }
                   let response;
                    if (request?.body) {
                        response = await api.post(request?.url, request?.body, config);
                    } else {
                        response = await api.get(request?.url, config);
                    }
                    setDatas(response.data);
                } catch (error) {
                    BuildFieldErrorsDict(error, null, false);
                } finally {
                    setIsloading(false)
                }
            };
            fetchData();
        }
    }, [JSON.stringify(request)]);

    if (isLoading) return <LoadingFull/>

    return <>
        {datas?.length === 0 && <Empty/>}
        {/*{datas?.length >= 1 && <Rose {...config} />}*/}
        {datas?.length >= 1 &&
            <ChartLegendWrapper chart={Rose} config={config}/>}

    </>

};