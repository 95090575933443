import React, {useState} from "react";
import styled from "styled-components";
import {Col, Row, Tooltip,} from 'antd';
import {useTranslation} from "react-i18next";

import Table from "../../../../../global/components/data_display/Table";

import useTable from "../../../../../global/hooks/useTable";
import {
    generateFilterToStarBase
} from "../../../../../global/utils/Utils";
import DrawerStartupPerfil from "./DrawerStartupPerfil";

const Container = styled.div`
    background: white;
    padding: 24px;
    border-radius: 12px;
`
const Description = styled.div`
    margin-bottom: 32px;
    font-size: 14px;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
`

export default function TableManualStartupFilter({
                                                     choosedFilter,
                                                     handleExternalTableUpdate
                                                 },) {
    const {t} = useTranslation()
    const [open, setOpen] = useState(false)
    const [startup, setStartup] = useState()
    const tagsFilters = generateFilterToStarBase({
        values: choosedFilter?.tags,
        label: 'tags'
    },)
    const stateFilters = generateFilterToStarBase({
        values: choosedFilter?.states,
        label: 'state'
    })

    const {
        tableData,
        isLoading,
        tableParams,
        handleTableChange,
    } = useTable({
        url: `/program_hunt/search_organizations_manual?${tagsFilters}${stateFilters}`,
        extraParams: {
            search: choosedFilter?.search,

        },
        dependenceRequest: [JSON.stringify(choosedFilter)]
    });

    const columns = [
        {
            title: t('Company'),
            dataIndex: 'name',
            key: 'name',

        },
        {
            title: t('Industry'),
            dataIndex: 'industry',
            key: 'industry',

        },

        {
            title: t('Location'),
            dataIndex: 'address.state',
            key: 'address',

        },
        {
            title: t('Description'),
            dataIndex: 'description',
            key: 'description',
            width: 300,
            render: value => <Tooltip title={value}>
                <Description>{value || '-'}</Description>
            </Tooltip>

        },
    ]

    function handleOnRowClick(value) {

        setStartup(value)
        setOpen(true)
    }

    return (
        <>


            <Row style={{marginTop: 24}} gutter={[20, 20]}>


                <Col xs={24}>
                    <Table columns={columns}
                           onChange={handleTableChange}
                           tableParams={tableParams}
                           loading={isLoading}
                           noScroll
                        // showSizeChanger={false}
                           onRowClick={handleOnRowClick}
                           dataSource={tableData}
                           data-cy="program-table"
                    />
                </Col>
            </Row>
            <DrawerStartupPerfil open={open} setOpen={setOpen}
                                 startup_id={startup?.id}
                                 resetTable={handleExternalTableUpdate}
            />
        </>
    )
}