import {
    AuditOutlined,
    BulbOutlined,
    DatabaseOutlined,
    HomeOutlined,
    ProjectOutlined,
    SettingOutlined,
    TagOutlined
} from "@ant-design/icons";
import {Badge, Menu as MenuAnt} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import styled from "styled-components";

import ManageProgramsIcon from "../../../assets/IconComponent/ManageProgramsIcon";


import {useLayoutContext} from "../../../modules/project/components/layout/context/LayoutContext";
import {usePermissionContext} from "../../context/PermissionContext";
import {useThemeContext} from "../../context/ThemeContext";
import {useUserContext} from "../../context/UserContext";
import UserAvatar from "../misc/UserAvatar";
import RemixIcon from "../data_display/RemixIcon";

const alert_red_color = '#EA5349'

// const StyledBadge = styled(Badge)`
//   & sup {
//     box-shadow: 0 0 0 1px ${alert_red_color} !important;
//   }
// `
const StyledBadgeEvaluation = styled(Badge)`
  & sup {
    box-shadow: 0 0 0 1px var(--primary_btn_color) !important;
     color: var(--primary_btn_text_color) !important;
     background-color: var(--primary_btn_color) !important;
  }
    
`

export const StyledMainMenu = styled(MenuAnt)`
    background: inherit !important;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    padding: 0 8px 0 8px;
    
    .ant-layout-sider-children {
        min-width: 100%;
    }

    .svg {
        fill: red;
    }

    &.ant-menu-inline-collapsed {
        width: 65px !important;
    }

    .ant-menu-item.ant-menu-item-selected {
            //width: ${({collapsed}) => collapsed ? '64px' : '100%'};
    }

    //li:not(.ant-menu-item-selected).ant-menu-item :where(a,.ant-menu-item-icon) {
    //  font-size: 14px;
    //  color: var(--text_color_menu) !important;
    //}

    .antd-menu-item, .anticon, a, span {
        font-size: 14px;
        color: var(--text_color_menu) !important;
    }

    .ant-menu-item-selected {
        background: var(--active_menu_color) !important;
    }


    & li.ant-menu-item:not(.ant-menu-item-selected) sup {
        box-shadow: 0 0 0 1px var(--active_menu_color) !important;
        background-color: var(--active_menu_color) !important;
    }

    & .ant-menu-title-content {
        display: block;
    }
    
    //.ant-menu-item{
        //padding-left: 16px !important;
    //}

`;


export default function MenuBody({defaultSelectedKeys}) {
    const {t} = useTranslation();
    const {collapsed} = useLayoutContext();
    const {user_client, _user, hasUnreadMessages, evaluationsCount} = useUserContext();
    const {themeConfig: {client_manager, client}} = useThemeContext()

    const {
        hasProgramEvaluatorPermission,
        hasProgramAdminPermission,
        hasGamificationAdminPermission,
        hasProgramAdminOrManagerPermission,
        hasProjectPortfolioMenuAccessPermission,
        hasProjectAdminPermission,
    } = usePermissionContext();


    const userAvatarMenuFix = collapsed ? {margin: "0px -4px 0 -6px", top:"4px", left:"2px"} : {margin: "0px -4px 0 -6px"}

    const menuOptions = [
        {
            key: 'profile',
            name: _user.full_name,
            link: '/profile',
            icon:<UserAvatar size={24} data={_user} style={userAvatarMenuFix}/>,
        },
        {
            key: 'home',
            name: t('Start'),
            link: '/home/innovation_panel',
            icon: <HomeOutlined/>,
        },
        {
            key: 'applications',
            name: t('Registration and Applications'),
            link: '/applications',
            icon: <BulbOutlined/>,
        },
         {
            key: 'rewards_store',
            name: t('Rewards Store'),
            link: '/rewards_store',
            icon: <RemixIcon remixIconName={'ri-shopping-basket-line'}/>,
             permission: user_client && client?.gamification_actived && client?.store_activated
        },
        // {
        //     key: 'applications_challenges',
        //     name: t('Challenges',)
        //     link: '/applications_challenges',
        //     icon: <FlagOutlined/>,
        // },
        {
          key: 'evaluations',
          // name: t('Evaluations'),
          name: <StyledBadgeEvaluation count={evaluationsCount} color={alert_red_color} size={'small'}
                       offset={[15, 5]}>{t('Evaluations')}</StyledBadgeEvaluation>,
          link: '/evaluations',
          icon: <AuditOutlined/>,
          permission: hasProgramEvaluatorPermission
        },
        // {
        //     key: 'open_applications_challenges',
        //     name: t('Open Programs'),
        //     link: '/open_applications_challenges',
        //     icon: <AppstoreOutlined />,
        // },
        {
            key: 'programs',
            name: t('Manage Programs'),
            link: '/programs',
            icon: <ManageProgramsIcon className="anticon ant-menu-item-icon" />,
            permission: user_client && user_client.is_active && hasProgramAdminOrManagerPermission
        },
        // {
        //     key: 'dashboard',
        //     name: t('Dashboard'),
        //     link: '/dashboard',
        //     icon: <DotChartOutlined/>,
        //     permission: user_client && user_client.is_active && hasProgramAdminPermission
        // },
        {
          key: 'project-portfolio',
          name: t('Portfolio of Initiatives'),
          link: '/project-portfolio',
          icon: <ProjectOutlined />,
          permission: hasProjectPortfolioMenuAccessPermission
        },
        {
            key: 'database',
            name: t('Database'),
            link: '/database',
            icon: <DatabaseOutlined />,
            permission: user_client && user_client.is_active && hasProgramAdminPermission
      },
        {
            key: 'gamification',
            name: t('Gamification'),
            link: '/gamification',
            icon: <RemixIcon
                                remixIconName={"ri-game-line"}/>,
            permission: user_client && hasGamificationAdminPermission && client?.gamification_actived 
        },
           {
            key: 'tags',
            name: t('Tags'),
            link: '/tags',
            icon:  <TagOutlined />,
            permission: user_client  && (hasProgramAdminPermission || hasProjectAdminPermission)
        },
        {
            key: 'environment',
            name: t('Manage Environment'),
            link: '/environment',
            icon: <SettingOutlined/>,
            permission: user_client && user_client.is_active && hasProgramAdminPermission
        },

    ]


    return <StyledMainMenu
        // theme="dark"
        mode="inline"
        collapsed={collapsed}
        selectedKeys={defaultSelectedKeys}
    >
        {menuOptions
            .filter((object) => object.hasOwnProperty('permission') ? object.permission : true)
            .map(({key, name, link, icon}) => (
                <MenuAnt.Item key={key} icon={icon}><Link to={link}>{name}</Link></MenuAnt.Item>
            ))}


    </StyledMainMenu>

}
