import {InputNumber, Switch} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

import InformationCard from "../../../../../components/data_display/InformationCard";
import TransparentCollapse
    from "../../../../../components/misc/TransparentCollapse";
import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import CollapseFormItem
    from "../../../../../components/data_entry/CollapseFormItem";


export default function SectionScoreDistributionEvaluator({FORM_NAMES}) {
    const {t} = useTranslation();

    return (

        <TransparentCollapse header={t("Score distribution for evaluators")}>
            <FlexContainer direction={'column'} gap={16} align={'streech'}>

                <CollapseFormItem
                    input={<InputNumber min={0} data-cy="points-per-assessment-review-input"/>}
                    name={FORM_NAMES.FINISHED_ASSESSMENTS}
                     label={t("When evaluating an application")}
                    help={t(
                        "Reviewers earn points when they review applications, once per application. If the review is returned or invalidated, they will lose the points acquired."
                    )}

                />

            </FlexContainer>

        </TransparentCollapse>

    );
}
