import { useTranslation } from "react-i18next";

function validateLinkStructure(link) {
    if ((link === "") || (link === undefined)) {
        return true;
    }
    const pattern = /^(?:https?:\/\/)?(w{3}\.)?[\w_-]+((\.\w{2,}){1,2})(\/([\w\._-]+\/?)*(\?[\w_-]+=[^\?\/&]*(\&[\w_-]+=[^\?\/&]*)*)?)?$/
    return pattern.test(link);
}

export default function useFormValidations() {
    const {t} = useTranslation();

    function linkValidator(rule, value, callback) {
        if (!validateLinkStructure(value)) {
            callback(t("Invalid link"));
        } else {
            callback();
        }
    };

    const values = {
        linkValidator,
    }

    return values;
}