import React from 'react';


import {useTranslation} from "react-i18next";
import useDefaultGetRequest
    from "../../../../../../global/hooks/useDefaultGetRequest";
import {useParams} from "react-router-dom";
import {Col, Row} from "antd";
import {
    blue_6,
    dust_red_7,
    lime_6
} from "../../../../../../global/utils/Colors";
import {
    BulbOutlined
} from "@ant-design/icons";
import RemixIcon
    from "../../../../../../global/components/data_display/RemixIcon";
import MetricsCard
    from "../../../../../../global/components/data_display/MetricsCard";


export default function DisplayApplicationsCount() {
    const {t} = useTranslation()
    let {phase_id} = useParams()
    const {
        data: applicationsCountData,
        isLoading: isLoadingApplicationsCountData
    } = useDefaultGetRequest({
        url: `/phases/${phase_id}/history_allocation_counters`,
        dependence: [phase_id]
    })


    return (

        <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} lg={8}>
                <MetricsCard color={lime_6}
                                 loading={isLoadingApplicationsCountData}
                                 icon={
                                     <BulbOutlined/>}
                                 name={t('Approved applications')}
                                 value={applicationsCountData?.approved_applications}/></Col>
            <Col xs={24} sm={12} lg={8}>
                <MetricsCard color={dust_red_7}
                                 loading={isLoadingApplicationsCountData}
                                 icon={
                                     <RemixIcon
                                         remixIconName={'ri-file-forbid-line'}/>}
                                 name={t('Reproved applications')}
                                 value={applicationsCountData?.repproved_applications}/></Col>
            <Col xs={24} lg={8}>
                <MetricsCard color={blue_6}
                                 loading={isLoadingApplicationsCountData}
                                 icon={
                                     <BulbOutlined/>}
                                 name={t('Pending applications')}
                                 value={applicationsCountData?.pending_applications}/></Col>

        </Row>

    )
}