import React from 'react'

import './style.css'

function SelectInput(
    { inputId, inputName, textLabel, textAlert, options, }) {
    return (
        <div className={'input-container'}>
            <label htmlFor={inputId}>{textLabel}</label>
            <select name={inputName} id={inputId}>
                {options && options.map((option,index) =>
                    <option key={inputId+'-option-'+index} value={option.value}>{option.label}</option>
                )}

            </select>

            {textAlert ?
                <p className={'input-alert'}>{textAlert}</p> : <></>}
        </div>
    )
}

export default SelectInput;