import {useTranslation} from "react-i18next";

import {FlexContainer} from "../authentication/StyledComponents";
import Button from "../data_entry/Button";
import RemixIcon from "../data_display/RemixIcon";
import {Badge, Space, Typography} from "antd";
import styled from 'styled-components';
import Drawer from "../layout/Drawer";
import HeaderMessagesTopicOffCanvas
    from "../../pages/messages/offcanvas/MessagesTopicOffCanvas/HeaderMessagesTopicOffCanvas";
import Chat from "../other/chat/Chat";
import React, {cloneElement, useEffect, useRef, useState} from "react";
import TextArea from "../data_entry/TextArea";
import useWebSocket from "../../hooks/useWebSocket";
import ChatLoading from "../feedback/ChatLoading";
import RobotMessage from "../other/chat/RobotMessage";
import UserMessage from "../other/chat/UserMessage";
import moment from "moment-timezone";
import loading from "../feedback/Loading";
import ErrorChatMessage from "../other/ErrorChatMessage";
import {useThemeContext} from "../../context/ThemeContext";

const StyledBadge = styled(Badge)`
    sup {
        font-weight: bold;
    }
`

const CHAT_TYPE = {
    HUMAN: 'human',
    ROBOT: 'robot',
}

export default function IAInputButton({
                                          application_id,
                                          question_id,
                                          actived,
                                          children,
                                          ...props
                                      }) {
    const {t} = useTranslation();
    const [openDrawer, setOpenDrawer] = useState(false)
    const [sendMessage, setSendMessage] = useState('')
    const [messages, setMessages] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [showError, setShowError] = useState(false)
    const refContainerMessages = useRef();
    const textAreaRef = useRef();
    const initialMessage = props.value
    const NO_HISTORY_MESSAGES = messages.length === 0
    const {themeConfig} = useThemeContext()
    const clonedChildren = cloneElement(children, {...props, ...children.props})

    useEffect(() => {
        if (messages.length > 0) {
            refContainerMessages.current.parentElement.scrollTo(0, refContainerMessages.current.scrollHeight)
        }
    }, [messages])

    useEffect(() => {
        if (initialMessage) { //reseta as messagems caso o valor do input mude
            setMessages([])
        }
    }, [initialMessage]);

    const {
        sendJsonMessage,
        lastJsonMessage,
    } = useWebSocket({
        url: `chat-ia/application/${application_id}/form/suggestion_answer/${question_id}`,
        openConnection: openDrawer ,
        onOpen: () => {
            if (NO_HISTORY_MESSAGES) {
                setIsLoading(true)
                sendJsonMessage({message: initialMessage})
            }
        },
        onClose: (e) => {
            openDrawer && setShowError(true)
            setIsLoading(false)
        },
    });


    useEffect(() => {
        if (lastJsonMessage) {
            setMessages([...messages, {
                type: CHAT_TYPE.ROBOT,
                message: lastJsonMessage.message
            }])

            setIsLoading(false)


        }

    }, [lastJsonMessage])


    useEffect(() => {
        if (textAreaRef.current && !isLoading) {
            textAreaRef.current.focus({
                cursor: 'end',
            });
        }
    }, [isLoading]);

    function handleClose() {
        setOpenDrawer(false)
        // setMessages([])
        setSendMessage('')
        setIsLoading(true)
        setShowError(false)
    }

    function handleSendMessage(message) {
        setIsLoading(true)
        sendJsonMessage({message: message})
        setMessages([...messages, {
            type: CHAT_TYPE.HUMAN,
            message: message
        }
        ])
        setSendMessage('')
    }

    function FACTORY_MESSAGE_COMPONENTE({type, message}) {
        switch (type) {
            case CHAT_TYPE.HUMAN:
                return <UserMessage message={message}/>;

            case CHAT_TYPE.ROBOT:
                return <RobotMessage message={message}/>;

            default:
                setIsLoading(false);
        }
    }

    const DISABLED_TEXT_AREA = isLoading || showError
    const DISABLED_SEND_BUTTON = !sendMessage || isLoading

    return (<>

        <FlexContainer gap={8} direction={'column'} align={'end'}>
            {clonedChildren}
            {actived &&
                <StyledBadge count={'BETA'} color={'black'} offset={[-165, 5]}>
                    <Button type={'default'} disabled={!props.value}
                            icon={<RemixIcon
                                remixIconName={"ri-sparkling-fill"}/>}
                            onClick={() => setOpenDrawer(true)}>{t('Inspire with AI')}</Button></StyledBadge>}
        </FlexContainer>

        <Drawer width={708} open={openDrawer}
                onClose={handleClose}
                footer={<FlexContainer gap={8} align={'end'}
                                       direction={'column'}>
                    <TextArea innerRef={textAreaRef} value={sendMessage}
                              placeholder={t('Ask Silvia to review your answer, give you tips or check your spelling.')}
                              disabled={DISABLED_TEXT_AREA}
                              onChange={({target: {value}}) => setSendMessage(value)}/>
                    <Button disabled={DISABLED_SEND_BUTTON}
                            onClick={() => handleSendMessage(sendMessage)}>{t('Send')}</Button>
                </FlexContainer>}
                title={<Space>
                    <RemixIcon
                        remixIconName={"ri-sparkling-fill"}/>
                    <StyledBadge
                        count={'BETA'}
                        color={'black'} offset={[30, 13]}>
                        <Typography.Title
                            style={{marginBottom: 0}}
                            level={4}>{t('Inspire with AI')}</Typography.Title>
                    </StyledBadge></Space>}

        >
            <FlexContainer ref={refContainerMessages} align={'start'} gap={16}
                           direction={'column'} style={{height: '100%', width: '100%'}}>
                <FlexContainer gap={16} align={'start'} gat={16}
                               direction={'column'}
                               style={{flexGrow: 1, width: '100%'}}>
                    {messages.map((message) => (
                        FACTORY_MESSAGE_COMPONENTE(message)
                    ))}
                    <ErrorChatMessage showError={showError}/>
                    <ChatLoading loading={isLoading}/>
                    <div style={{marginBottom: 16}}/>
                </FlexContainer>


            </FlexContainer>


        </Drawer>
    </>)
}