import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import styled from "styled-components";
import {
    FlexContainer
} from "../../../components/authentication/StyledComponents";
import RemixIcon from "../../../components/data_display/RemixIcon";
import LimitNumber from "../../../components/data_display/LimitNumber";
import useSystemBreakpoint from "../../../hooks/useSystemBreakpoint";
import {neutral_7} from "../../../utils/Colors";
import Drawer from "../../../components/layout/Drawer";
import {QuestionCircleOutlined} from "@ant-design/icons";


const Container = styled.div`
    padding: 40px;
    height: 100%;
    color: white;
    border-radius: 8px;
    background: ${({black}) => black ? 'linear-gradient(90deg, rgba(60,61,61,1) 21%, rgba(5,5,5,1) 73%)' : 'linear-gradient(0deg, rgba(106, 120, 190, 0.85), rgba(106, 120, 190, 0.85))'};
`
const MinText = styled.span`
    font-size: 12px;
    font-weight: bold;
`
const Points = styled.span`
    font-size: ${({isPhone}) => isPhone ? '30px' : '40px'};
    font-weight: bold;
`
const Title = styled.h2`
    font-size: ${({isPhone}) => isPhone ? '16px' : '20px'};
    white-space: nowrap;
    font-weight: bold;
    color: white;
    margin-bottom: 0;
`

const Help = styled.div`
    font-size: 14px;
    color: ${({black}) => black ? neutral_7 : '#C7CEF0'
    };
`

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`

    cursor: pointer;

`

export default function PointsCard({
                                       value,
                                       drawerTitle,
                                       text,
                                       black,
                                       helpText,
                                       drawerBody
                                   }) {
    const {t} = useTranslation()
    const {isPhone} = useSystemBreakpoint()
    const [open, setOpen] = useState(false)
    return (
        <>
            <Container black={black}>
                <MinText>{t('You have')}</MinText>
                <FlexContainer gap={8} align={'center'} wrap={'wrap'}>
                    {!isPhone && <RemixIcon style={{fontSize: 16}}
                                            remixIconName={'ri-copper-coin-line'}/>}
                    <Points isPhone={isPhone}><LimitNumber
                        value={value}/></Points>
                    <Title>{text}</Title>
                </FlexContainer>

                <Help black={black}>{helpText} <StyledQuestionCircleOutlined onClick={() => setOpen(true)}/></Help>
            </Container>
            <Drawer open={open} onClose={() => setOpen(false)} title={drawerTitle}>
                {drawerBody}
            </Drawer>
        </>
    )
}