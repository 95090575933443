import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import InfoCardBase from "../InfoCardBase";
import LimitedSpace from "../../responsive/LimitedSpace";
import {DateFormatByUserTimezone} from "../../../utils/dates";
import DotInfoCard from "../DotInfoCard";
import {
    CalendarOutlined,
    UnorderedListOutlined,
    DisconnectOutlined,
    ProjectOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import {Button, Modal} from "antd";
import api from "../../../services/api";
import {ToastNotification} from "../../feedback/ToastNotification";
import {BuildFieldErrorsDict} from "../../../utils/Utils";
import {useApplicationDrawerContext} from "../../other/application/ApplicationDrawer/context/ApplicationDrawerContext";

const {confirm} = Modal

function ProjectInfoCard({applicationId, project, onClick, ...props}) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false)
    const {setSolution} = useApplicationDrawerContext()

    const confirmUnlinkProject = () => {
        confirm({
            title: t("You are unlinking a project from this application"),
            icon: <ExclamationCircleOutlined/>,
            content: t("Do you really want to unlink this project?"),
            okText: t("Yes"),
            cancelText: t("No"),
            okButtonProps:{"data-cy": "confirm-unlink-project-button"},
            onOk: handleUnlinkProject
        });
    }

    const handleUnlinkProject = async () => {
        try {
            setLoading(true)
            await api.post(`v1/applications/${applicationId}/unlink_project`)
            setSolution(undefined)
            ToastNotification(t("Application successfully unlinked"), "success")
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setLoading(false)
        }
    }

    return (
        <InfoCardBase title={t("Project")} onClick={onClick} {...props}>
            <LimitedSpace>
                <DotInfoCard showDot={false} icon={<CalendarOutlined/>} label={t("Start date")}
                             info={DateFormatByUserTimezone(project.date_start)}/>
                <DotInfoCard icon={<CalendarOutlined/>} label={t("End Date")}
                             info={DateFormatByUserTimezone(project.date_end)}/>
                <DotInfoCard
                    icon={<UnorderedListOutlined/>}
                    label={t("Phase")}
                    info={project.current_phase.phase.name}
                />
                <DotInfoCard icon={<ProjectOutlined/>} label={t("Project")} info={project.name}/>
            </LimitedSpace>
            <Button type='default' onClick={() => confirmUnlinkProject()} loading={loading}
                    data-cy="unlink-project-button"
            >
                {!loading && <DisconnectOutlined/>}
                {t("Unlink")}
            </Button>
        </InfoCardBase>
    );
}

export default ProjectInfoCard;
