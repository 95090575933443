import React from 'react';
import {WhatsAppOutlined} from "@ant-design/icons";
import styled from 'styled-components';
import {neutral_6, polar_green_7} from "../../utils/Colors";
import {Tooltip} from "antd";
import {useTranslation} from "react-i18next";

const StyledWhatsIcon = styled(WhatsAppOutlined)`
    padding: 8px;
    font-size: 16px;
    border-radius: 8px;
   
    color: ${({phone}) => phone ? polar_green_7 : neutral_6};
    cursor: ${({phone}) => phone ? 'pointer' : 'not-allowed'} !important;
    :hover{
        background: ${({phone}) => phone ? neutral_6 : 'none'}; 
    }
`


export default function WhatsAppRequest({phone ,text='', onClick}) {

    const {t} = useTranslation()

    const title = phone ? '' : t('This user did not register their cell phone number')


    function handleWhatsAppRequest(e) {
        onClick?.(e)
        if (!phone) return
        const tag_a = document.createElement('a')
        // tag_a.href = `https://wa.me/${phone}`
        // tag_a.href = `https://api.whatsapp.com/send?phone=${phone}&text=teste`
        tag_a.href = `https://api.whatsapp.com/send?phone=${phone}&text=${text}`
        tag_a.target = '_blank'
        tag_a.click()
    }
    return (
        <Tooltip title={title}>

            <StyledWhatsIcon onClick={handleWhatsAppRequest} phone={phone}/>


        </Tooltip>
    );
}

