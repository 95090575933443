import React from 'react'

const DatabaseContext = React.createContext()


export function DatabaseProvider({children}) {

    const [companiesUrl, setCompaniesUrl] = React.useState()
    const [companiesMembersUrl, setCompaniesMembersUrl] = React.useState()
    const [applicationsUrl, setApplicationsUrl] = React.useState()
    const [challengesUrl, setChallengesUrl] = React.useState()
    const [namesHiddenApplicationsFilters, setNamesHiddenApplicationsFilters] = React.useState([])
    const [namesHiddenCompaniesFilters, setNamesHiddenCompaniesFilters] = React.useState([])
    const [assessmentsUrl, setAssessmentsUrl] = React.useState()
    const [solutionsUrl, setSolutionsUrl] = React.useState()

    
    function changeCompaniesUrls(mainUrl) {
        setCompaniesUrl(mainUrl)
    }

    function changeCompaniesMembersUrl(mainUrl) {
        setCompaniesMembersUrl(mainUrl)
    }

    function changeApplicationsUrl(mainUrl) {
        setApplicationsUrl(mainUrl)
    }

    function changeChallengesUrl(mainUrl) {
        setChallengesUrl(mainUrl)
    }

    function hiddenApplicationFilter(namesInputs) {
        setNamesHiddenApplicationsFilters(namesInputs)
    }
    function hiddenCompaniesFilter(namesInputs) {
        setNamesHiddenCompaniesFilters(namesInputs)
    }

    function changeAssessmentsUrl(mainUrl) {
        setAssessmentsUrl(mainUrl)
    }

    function changeSolutionsUrl(mainUrl) {
        setSolutionsUrl(mainUrl)
    }

    function hiddenOrShowComponent(component) {
        if (!namesHiddenApplicationsFilters.find((name) => name === component.props.name)) return component
        return <></>
    }

    return (
        <DatabaseContext.Provider value={{
            companiesMembersUrl,
            companiesUrl,
            changeCompaniesUrls,
            hiddenOrShowComponent,
            changeCompaniesMembersUrl,
            changeApplicationsUrl,
            applicationsUrl,
            namesHiddenApplicationsFilters,
            hiddenApplicationFilter,
            namesHiddenCompaniesFilters,
            hiddenCompaniesFilter,
            assessmentsUrl,
            changeAssessmentsUrl,
            changeChallengesUrl,
            challengesUrl,
            solutionsUrl,
            changeSolutionsUrl
        }}>
            {children}
        </DatabaseContext.Provider>
    )
}

export function useDatabaseContext() {
    return React.useContext(DatabaseContext)
}