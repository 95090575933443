import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {
    FlexContainer
} from "../../../../components/authentication/StyledComponents";
import Drawer from "../../../../components/layout/Drawer";
import {Col, Row, Radio, Typography} from "antd";
import Button from "../../../../components/data_entry/Button";
import {
    DrawerProgramChartFactory,
    PROGRAM_CHART_NAMES
} from "./ProgramChartFactory";
import {useInnovationPanelContext} from "../context/InnovationPanelContext";
import Empty from "../../../../components/data_display/Empty";

const StyledRadio = styled(Radio)`

    width: 100%;

    & > :last-child {
        width: 100%;
    }
`

export default function DrawerProgramsSelectChart({
                                                      open,
                                                      setOpen,
                                                      position,
                                                  }) {
    const {t} = useTranslation();
    const [choice, setChoice] = useState()
    const {
        initialDate,
        finalDate,
        programsFilters,
        PROGRAM_FILTER_FORM_NAMES,
        addProgramChart,
        programChartList

    } = useInnovationPanelContext()

    const options = useMemo(() => {


        if (open) {
            const programForFilter = programChartList.map(({type}) => type)

            return Object.values(PROGRAM_CHART_NAMES).filter((value) => !programForFilter?.includes(value)).map((value) => value)
        }
        return []
    }, [programChartList, open])


    function onClose() {
        setOpen(false)
        setChoice(null)
    }

    return (
        <Drawer open={open} onClose={onClose}
                title={t('Select chart')}
                destroyOnClose
                footer={<FlexContainer justify={'end'}>
                    <Button disabled={!!!choice}
                            onClick={() => {
                                onClose()
                                addProgramChart(position, choice)

                            }}>{t('Save')}</Button>
                </FlexContainer>}
                width={1024}>
            <Radio.Group style={{width: '100%'}} value={choice}
                         onChange={({target: {value}}) => setChoice(value)}>
                <Row gutter={[16, 16]}>
                    {options.length === 0 && open &&
                        <Col xs={24}><Empty/></Col>}
                    {options.map((chart, index) => (
                        <Col xs={24} lg={12} key={chart}>
                            <StyledRadio value={chart}>
                                <DrawerProgramChartFactory type={chart}
                                                           bodyFilters={programsFilters}
                                                           programs={programsFilters[PROGRAM_FILTER_FORM_NAMES.PROGRAMS__ID__IN]}
                                                           initialDate={initialDate}
                                                           finalDate={finalDate}/>

                            </StyledRadio>
                        </Col>
                    ))}

                </Row>
            </Radio.Group>
        </Drawer>

    )
}

