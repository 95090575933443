import React, {useEffect, useMemo} from "react";
import {Link, Redirect, Switch, useLocation,} from "react-router-dom";
import Home from "../pages/home/HomePage/Home";
import InnovationPanel from "../pages/home/InnovationPanel/InnovationPanel";
import {
    useLayoutContext
} from "../../modules/project/components/layout/context/LayoutContext";
import {useTranslation} from "react-i18next";
import {ManagerRoute} from "./PrivateRoute";
import {usePermissionContext} from "../context/PermissionContext";
import {HOST_ENTREPRENEUR} from "../../settings";
import Feed from "../pages/home/Feed/Feed";
import { Badge, Space } from "antd";
import { useUserContext } from "../context/UserContext";


const PATH_ROUTES = {
    HOME: '/home',
    INNOVATIONS_PANEL: '/home/innovation_panel',
    FEED: '/home/feed'
}
export default function RoutesHome() {
    const location = useLocation();
    const {t} = useTranslation();
    const {initializeLayout} = useLayoutContext()
    const {user_client} = useUserContext();
    const host = window.location.host;

    const {
        hasProgramAdminOrManagerPermission,
        hasProjectAdminOrManagerPermission,
        hasProjectViewerPermission,
        hasProgramViewerPermission,
        isLoadingPermissions
    } = usePermissionContext()

    const RoutesItems = useMemo(() =>{
        if(!isLoadingPermissions){
         return [    
            {
                label: <Link
                    to={PATH_ROUTES.INNOVATIONS_PANEL}>{t("Innovation Panel")}</Link>,
                key: PATH_ROUTES.INNOVATIONS_PANEL,
                hasPermission: host != HOST_ENTREPRENEUR && (hasProgramAdminOrManagerPermission ||
                    hasProjectAdminOrManagerPermission || hasProgramViewerPermission || hasProjectViewerPermission),
                route: <ManagerRoute
                    exact
                    component={InnovationPanel}
                />
            },
            {
                label: <Link to={PATH_ROUTES.HOME}>{t("Available Programs")}</Link>,
                key: PATH_ROUTES.HOME,
                route: <ManagerRoute
                    exact
                    component={Home}
                />
            },
            {
                label: (
                        <Space size={4}>
                            <Link to={PATH_ROUTES.FEED}>
                                {t("Feed")} 
                            </Link>
                        </Space>
                    ),
                key: PATH_ROUTES.FEED,
                hasPermission: host != HOST_ENTREPRENEUR && !!user_client,
                route: <ManagerRoute
                    exact
                    component={Feed}
                />
            },
        ]
        }else{return []}}, [isLoadingPermissions])

    useEffect(() => {
        initializeLayout({
            navbarMenuItems: RoutesItems
        });
    }, [RoutesItems]);

    return (

        <Switch>
            {RoutesItems.map(({
                                  route, key,
                                  hasPermission
                              }) => (React.cloneElement(route, {
                hasPermission,
                key,
                path: key
            })))}
            <Redirect to={{
                pathname: "/404",
                state: {broken_url: window.location.host + location.pathname}
            }}/>
        </Switch>);
}
