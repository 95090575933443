import React from "react";
import DefaultComponent from "../../PhaseFormBuilder/FormComponents/DefaultComponent";
import TextArea from "../../../Form/TextArea";
import {useTranslation} from "react-i18next";

function FeedbackForm(props) {
    const {t} = useTranslation()
    return (
        <DefaultComponent{...props}>
            <TextArea placeholder={t('Type your answer here...')} />
        </DefaultComponent>
    )
}

export default FeedbackForm