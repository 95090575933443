import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Col, Row,} from "antd";
import styled from "styled-components";
import Button from "../../data_entry/Button";
import InputField from "../../data_entry/InputField";


const StyledInputField = styled(InputField)`
  //border-top: none;
  //border-right: none;
  //border-left: none;
`
const Container = styled.div`
  width: 100%;
`


export default function SendMessageChat({
                                            isLoading,
                                            onChange,
                                            onSendMessage,
                                            value
                                        }) {
    const {t} = useTranslation()


    return (
        <Container>
            <Row align={'center'} gutter={[8,8]}>
                <Col flex={'1'}>
                    <StyledInputField
                        onPressEnter={onSendMessage}
                        onChange={({target: {value}}) => {
                            onChange?.(value);
                        }}
                        value={value}
                    />
                </Col>
                <Col>
                    <Button loading={isLoading}
                            onClick={onSendMessage}>{t('Send')}</Button>
                </Col>
            </Row>
        </Container>
    );
}

