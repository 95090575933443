import { useTranslation } from "react-i18next";
import { isURL } from "../../utils/Utils";
import ColorfulButton from "./ColorfulButton";
import {FileImageOutlined, FileOutlined, FilePdfOutlined} from "@ant-design/icons";


export default function FileButton({link}) {
    const {t} = useTranslation();
    const imageTypes = ["jpg", "jpeg", "png"]
    const href = link

    const replaceLink = (link) => {
        link = link.replaceAll(/\+/g, '%2B');
        link = link.replaceAll(/ /g, '+');

        return link
    }

    if (isURL(link)) {
        const url = new URL(link);
        link = url.pathname;
    }

    const getProps = () => {
        if (imageTypes.some(extension => link.endsWith(extension))){
            return {icon: <FileImageOutlined />, children: "IMG", color: "#ADC6FF"}
        }
        if (link.endsWith("pdf")) {
            return {icon: <FilePdfOutlined />, children: "PDF", color: "#FFA39E"}
        }
        return {icon: <FileOutlined />, children: t("Document"), color: "var(--ant-primary-color)"}
    }

    return <a href={replaceLink(href)} target="_blank"><ColorfulButton  {...getProps()}/></a>;
}