import React, {useState} from 'react';

import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";
import {Col, Progress, Row, Typography} from "antd";
import {UsergroupAddOutlined} from "@ant-design/icons";
import DisplayEvaluators from "./components/DisplayEvaluators";
import GrowRadioButton
    from "../../../../../global/components/responsive/GrowRadioButton";
import {
    CardContainer
} from "../../../../../global/components/misc/CardContainer";
import Search from "../../../../../global/components/data_entry/Search";
import {usePhasesContext} from "../../../../PhasesNavigator";
import Button from "../../../../../global/components/data_entry/Button";
import useSystemBreakpoint
    from "../../../../../global/hooks/useSystemBreakpoint";
import {
    FlexContainer
} from "../../../../../global/components/authentication/StyledComponents";
import useTable from "../../../../../global/hooks/useTable";
import Table from "../../../../../global/components/data_display/Table";
import WhatsAppRequest
    from "../../../../../global/components/data_display/WhatsAppRequest";
import ButtonExportData
    from "../../../../../global/components/other/ButtonExportData";
import ButtonManagerEvaluator from "./components/ButtonManagerEvaluator";
import DrawerProfileUser
    from "../../../../../global/pages/profile/components/DrawerProfileUser/DrawerProfileUser";


const DEFAULT_PENDING_FILTER = null

const QUERY_PARAMS_NAMES = {
    SEARCH: 'search',
    WITH_PENDING_EVALUATIONS: 'with_pending_evaluations',
    PHASES: 'phases',
}

export default function PageEvaluatorsListPhase() {
    const {t} = useTranslation()

    const {phase} = usePhasesContext()
    const {id, phase_id} = useParams()
    const [isPendingEvaluators, setIsPendingEvaluators] = useState(DEFAULT_PENDING_FILTER)
    const [search, setSearch] = useState('')
    const {isPhone} = useSystemBreakpoint()
    const isDisabledAlocationButton = phase?.evaluators === 0
    const history = useHistory()
    const [openProfileEvaluator, setOpenProfileEvaluator] = React.useState(false);
    const [evaluatorID, setEvaluatorId] = React.useState();

    async function handleOnRowClick(evaluator, event) {

        setEvaluatorId(evaluator.user_id)
        setOpenProfileEvaluator(true);

    }

    const {
        tableData, isLoading, tableParams, handleTableChange,
    } = useTable({
        // useHandleRequestTable: false,
        url: `program_evaluators`,
        hasPagination: true,
        extraParams: {
            [QUERY_PARAMS_NAMES.SEARCH]: search,
            [QUERY_PARAMS_NAMES.WITH_PENDING_EVALUATIONS]: isPendingEvaluators,
            [QUERY_PARAMS_NAMES.PHASES]: phase_id,
        },
        dependenceRequest: [search, isPendingEvaluators, phase_id],

        config: {version: "v2"}
    })

    const columns = [{
        title: t('Evaluator'), dataIndex: 'name',
        sorter: 'user_client__user__first_name',
        key: 'name',
        ellipsis: true,
        width: "250px",

    }, {
        title: t('Done evaluations'),
        dataIndex: 'done_evaluations',
        sorter: 'done_evaluations',
        key: 'done_evaluations',
        width: '50px',


    }, {
        title: t('Pending evaluations'),
        dataIndex: 'pending_evaluations',
        sorter: 'pending_evaluations',
        key: 'pending_evaluations',
        width: '50px',
    },
        {
            title: t('Total evaluations'),
            dataIndex: 'total_evaluations',
            sorter: 'total_evaluations',
            key: 'total_evaluations',
            width: '50px',

        },
        {
            title: t('Conclusion'),
            dataIndex: 'percent_done',
            sorter: 'percent_done',
            key: 'percent_done',
            width: '200px',
            render: (value, object) => {

                if (object.total_evaluations === 0) return '-'
                const percent = ((object.done_evaluations / object.total_evaluations) * 100).toFixed(2)

                return <Progress percent={percent}/>
            }
        },
        {
            title: t('Request'),
            dataIndex: 'phone',
            key: 'phone',
            width: '100px',
            render: (value) => {
                return <WhatsAppRequest phone={value}
                                        onClick={(e) => e.stopPropagation()}
                                        text={t('Hello!\nWe noticed that your review for the innovation program has not yet been received. We need you to carry out your assessments. Thank you in advance for your collaboration!')}/>
            }
        },]


    function handleClickAllocateEvaluators() {
        history.push(`/programs/${id}/phases/${phase_id}/allocation_evaluators`)
    }

    return (
        <>

            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <DisplayEvaluators/>
                </Col>

                <Col xs={24}>
                    <Typography.Title
                        level={5}>{t('Evaluators at this stage')}</Typography.Title>
                    <CardContainer>
                        <Row gutter={[16, 16]}>
                            {!isPhone && <Col xs={24} lg={6} xl={5}>
                                <GrowRadioButton
                                    onChange={setIsPendingEvaluators}
                                    defaultValue={DEFAULT_PENDING_FILTER}
                                    block={true}
                                    options={[
                                        {value: true, label: t('Pending')},
                                        {value: null, label: t('All')},]}
                                />


                            </Col>}

                            <Col flex={'1'}>
                                <FlexContainer gap={16}>
                                    <Search style={{'flex': '1'}}
                                            onSearch={(value) => setSearch(value)}
                                            allowClear/>
                                    {isPhone && <ButtonExportData
                                        name={`${phase?.title}.csv`}
                                        url={`/program_evaluators/export`}
                                        request={{
                                            params: {phases: phase?.id},
                                            version: 'v2'
                                        }}/>}
                                </FlexContainer>

                            </Col>
                            <Col xs={24} sm={22} lg={10} xl={8}>
                                <FlexContainer gap={16} justify={'end'}>
                                    <Button
                                        onClick={handleClickAllocateEvaluators}
                                        disabled={isDisabledAlocationButton}
                                        icon={<UsergroupAddOutlined/>}
                                        data-cy="allocate-evaluators-button"
                                    >{t('Allocate evaluators')}</Button>

                                    <ButtonManagerEvaluator
                                        program={{id: phase?.program}}
                                        phase={phase}/>
                                </FlexContainer>
                            </Col>
                            {!isPhone && <Col xs={2} lg={1}>

                                <ButtonExportData
                                    name={`${phase?.title}.csv`}
                                    url={`/program_evaluators/export`}
                                    request={{
                                        params: {phases: phase?.id},
                                        version: 'v2'
                                    }}/>
                            </Col>}
                            <Col xs={24}>
                                <Table columns={columns}
                                       onChange={handleTableChange}
                                       tableParams={tableParams}
                                       loading={isLoading}
                                       onRowClick={handleOnRowClick}
                                       noScroll
                                       dataSource={tableData}/>
                            </Col>
                        </Row>
                    </CardContainer>
                </Col>

            </Row>
            <DrawerProfileUser
                id={evaluatorID}
                phase_id={phase_id}
                program_id={id}
                open={openProfileEvaluator}
                setOpen={setOpenProfileEvaluator}
            />


        </>

    )
}