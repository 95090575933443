import React from 'react';
import {
    Switch,
    useRouteMatch,
} from "react-router-dom";

import {ManagerRoute} from "../../../global/routes/PrivateRoute";
import PhaseFeedback from "../../../pages/Programs/TabBarProgram/Phases/PhaseFeedback";
import DatabasePhase from "../../../pages/Programs/TabBarProgram/Phases/DatabasePhase";
import PhaseEvaluateForm from "../../../pages/Programs/TabBarProgram/Phases/PhaseEvaluateForm";
import PageUpdatePhase
    from "../../../pages/Programs/TabBarProgram/Phases/PageUpdatePhase";
import PageEvaluatorsListPhase
    from "../../../pages/Programs/TabBarProgram/Phases/PageEvaluatorsListPhase/PageEvaluatorsListPhase";
import PageApplicationSelection
    from "../../../pages/Programs/TabBarProgram/Phases/PageApplicationSelection/PageApplicationSelection";
import PageAssessmentsListPhase from '../../../pages/Programs/TabBarProgram/Phases/PageAssessmentsListPhase/PageAssessmentsListPhase';
import PhaseForm
    from "../../../pages/Programs/TabBarProgram/Phases/PhaseForm/PhaseForm";


export default function RoutesPhases({hasPermission}) {
    let { path } = useRouteMatch();

    return (
        <Switch>
             <ManagerRoute
                path={`${path}/evaluators`}
                hasPermission={hasPermission}
                render={(props) =>
                              <PageEvaluatorsListPhase />}
            />
             <ManagerRoute
                path={`${path}/assessments`}
                hasPermission={hasPermission}
                render={(props) =>
                              <PageAssessmentsListPhase />}
            />
            <ManagerRoute
                path={`${path}/update`}
                hasPermission={hasPermission}
                render={(props) =>
                              <PageUpdatePhase />}
            />
            <ManagerRoute
                path={`${path}/form`}
                hasPermission={hasPermission}
                render={(props) =>
                              <PhaseForm />}
            />
            <ManagerRoute
                path={`${path}/evaluateform`}
                hasPermission={hasPermission}
                render={(props) =>
                              <PhaseEvaluateForm />}
            />
            {/*<ManagerRoute*/}
            {/*    path={`${path}/application-selection-old`}*/}
            {/*    hasPermission={hasPermission}*/}
            {/*    render={(props) =>*/}
            {/*                  <ApplicationSelection />}*/}
            {/*/>*/}
            <ManagerRoute
                path={`${path}/application-selection`}
                hasPermission={hasPermission}
                render={(props) =>
                              <PageApplicationSelection />}
            />
            <ManagerRoute
                path={`${path}/send-feedback`}
                hasPermission={hasPermission}
                render={(props) =>
                              <PhaseFeedback />}
            />
            <ManagerRoute
                path={`${path}/database`}
                hasPermission={hasPermission}
                render={(props)=>
                    <DatabasePhase hasPermission={hasPermission}/>
                }
            />

        </Switch>
    );
}