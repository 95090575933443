// export const lime_1 = '#ffffff'falta colocar a cor certa
// export const lime_2 = '#fafafa'falta colocar a cor certa
// export const lime_3 = '#f5f5f5' falta colocar a cor certa
// export const lime_4 = '#f0f0f0'falta colocar a cor certa
// export const lime_5 = '#d9d9d9'falta colocar a cor certa
export const lime_6 = '#5B8C00'
export const lime_7 = '#7CB305'
// export const lime_8 = '#595959'falta colocar a cor certa
// export const lime_9 = '#434343'falta colocar a cor certa
// export const lime_10 = '#262626'falta colocar a cor certa
// export const lime_11 = '#1f1f1f'falta colocar a cor certa
// export const lime_12 = '#141414'falta colocar a cor certa
// export const lime_13 = '#000000'falta colocar a cor certa

export const page_background = '#F0F2F5'

export const calendula_1 = '#FFFBE6'
// export const calendula_2 = '#fafafa'falta colocar a cor certa
// export const calendula_3 = '#f5f5f5' falta colocar a cor certa
// export const calendula_4 = '#f0f0f0'falta colocar a cor certa
// export const calendula_5 = '#d9d9d9'falta colocar a cor certa
export const calendula_6 = '#FAAD14'
export const calendula_7 = '#D48806'
// export const calendula_8 = '#595959'falta colocar a cor certa
// export const calendula_9 = '#434343'falta colocar a cor certa
// export const calendula_10 = '#262626'falta colocar a cor certa
// export const calendula_11 = '#1f1f1f'falta colocar a cor certa
// export const calendula_12 = '#141414'falta colocar a cor certa
// export const calendula_13 = '#000000'falta colocar a cor certa


// export const golden_purple_1 = '#ffffff'falta colocar a cor certa
// export const golden_purple_2 = '#fafafa'falta colocar a cor certa
// export const golden_purple_3 = '#f5f5f5' falta colocar a cor certa
// export const golden_purple_4 = '#f0f0f0'falta colocar a cor certa
// export const golden_purple_5 = '#d9d9d9'falta colocar a cor certa
export const golden_purple_6 = '#722ED1'
export const golden_purple_7 = '#531DAB'
// export const golden_purple_8 = '#595959'falta colocar a cor certa
// export const golden_purple_9 = '#434343'falta colocar a cor certa
// export const golden_purple_10 = '#262626'falta colocar a cor certa
// export const golden_purple_11 = '#1f1f1f'falta colocar a cor certa
// export const golden_purple_12 = '#141414'falta colocar a cor certa
// export const golden_purple_13 = '#000000'falta colocar a cor certa


export const polar_green_1 = '#F6FFED'
// export const polar_green_2 = '#fafafa'falta colocar a cor certa
export const polar_green_3 = '#B7EB8F'
// export const polar_green_4 = '#f0f0f0'falta colocar a cor certa
// export const polar_green_5 = '#d9d9d9'falta colocar a cor certa
export const polar_green_6 = '#52C41A'
export const polar_green_7 = '#389E0D'
export const polar_green_8 = '#237804'
// export const polar_green_9 = '#434343'falta colocar a cor certa
// export const polar_green_10 = '#262626'falta colocar a cor certa
// export const polar_green_11 = '#1f1f1f'falta colocar a cor certa
// export const polar_green_12 = '#141414'falta colocar a cor certa
// export const polar_green_13 =


export const sunset_orange_1 = '#FFF7E6'
// export const sunset_orange_2 = '#fafafa'falta colocar a cor certa
export const sunset_orange_3 = '#FFD591'
export const sunset_orange_4 = '#FFC069'
// export const sunset_orange_5 = '#d9d9d9'falta colocar a cor certa
export const sunset_orange_6 = '#FA8C16'
// export const sunset_orange_7 = '#531DAB'falta colocar a cor certa
export const sunset_orange_8 = '#AD8B00'
// export const sunset_orange_9 = '#434343'falta colocar a cor certa
// export const sunset_orange_10 = '#262626'falta colocar a cor certa
// export const sunset_orange_11 = '#1f1f1f'falta colocar a cor certa
// export const sunset_orange_12 = '#141414'falta colocar a cor certa
// export const sunset_orange_13 = '#000000'falta colocar a cor certa

export const dust_red_1 = '#FFF1F0'
// export const dust_red_2 = '#fafafa'falta colocar a cor certa
export const dust_red_3 = '#FFA39E'
export const dust_red_4 = '#FF7875'
export const dust_red_5 = '#FF4D4F'
export const dust_red_6 = '#F5222D'
export const dust_red_7 = '#CF1322'
// export const dust_red_8 = '#595959'falta colocar a cor certa
// export const dust_red_9 = '#434343'falta colocar a cor certa
// export const dust_red_10 = '#262626'falta colocar a cor certa
// export const dust_red_11 = '#1f1f1f'falta colocar a cor certa
// export const dust_red_12 = '#141414'falta colocar a cor certa
// export const dust_red_13 = '#000000'falta colocar a cor certa


// export const volcano_1 = '#ffffff'falta colocar a cor certa
// export const volcano_2 = '#fafafa'falta colocar a cor certa
// export const volcano_3 = '#f5f5f5' falta colocar a cor certa
// export const volcano_4 = '#f0f0f0'falta colocar a cor certa
// export const volcano_5 = '#d9d9d9'falta colocar a cor certa
// export const volcano_6 = '#5B8C00'falta colocar a cor certa
export const volcano_7 = '#D4380D'
// export const volcano_8 = '#595959'falta colocar a cor certa
// export const volcano_9 = '#434343'falta colocar a cor certa
// export const volcano_10 = '#262626'falta colocar a cor certa
// export const volcano_11 = '#1f1f1f'falta colocar a cor certa
// export const volcano_12 = '#141414'falta colocar a cor certa
// export const volcano_13 = '#000000'falta colocar a cor certa


// export const cyan_1 = '#ffffff'falta colocar a cor certa
// export const cyan_2 = '#fafafa'falta colocar a cor certa
// export const cyan_3 = '#f5f5f5' falta colocar a cor certa
// export const cyan_4 = '#f0f0f0'falta colocar a cor certa
// export const cyan_5 = '#d9d9d9'falta colocar a cor certa
// export const cyan_6 = '#5B8C00'falta colocar a cor certa
export const cyan_7 = '#08979C'
// export const cyan_8 = '#595959'falta colocar a cor certa
// export const cyan_9 = '#434343'falta colocar a cor certa
// export const cyan_10 = '#262626'falta colocar a cor certa
// export const cyan_11 = '#1f1f1f'falta colocar a cor certa
// export const cyan_12 = '#141414'falta colocar a cor certa
// export const cyan_13 = '#000000'falta colocar a cor certa


// export const magenta_1 = '#ffffff'falta colocar a cor certa
// export const magenta_2 = '#fafafa'falta colocar a cor certa
// export const magenta_3 = '#f5f5f5' falta colocar a cor certa
// export const magenta_4 = '#f0f0f0'falta colocar a cor certa
export const magenta_5 = '#F759AB'
export const magenta_6 = '#EB2F96'
// export const magenta_7 = '#08979C'
// export const magenta_8 = '#595959'falta colocar a cor certa
// export const magenta_9 = '#434343'falta colocar a cor certa
// export const magenta_10 = '#262626'falta colocar a cor certa
// export const magenta_11 = '#1f1f1f'falta colocar a cor certa
// export const magenta_12 = '#141414'falta colocar a cor certa
// export const magenta_13 = '#000000'falta colocar a cor certa



export const primary_1 = '#E6F7FF'
// export const primary_2 = '#fafafa'falta colocar a cor certa
// export const primary_3 = '#f5f5f5' falta colocar a cor certa
// export const primary_4 = '#f0f0f0'falta colocar a cor certa
export const primary_5 = '#40A9FF'
export const primary_6 = '#1890FF'
export const primary_7 = '#096DD9'
export const primary_8 = '#0050B3'
export const primary_9 = '#003A8C'
// export const primary_10 = '#262626'falta colocar a cor certa
// export const primary_11 = '#1f1f1f'falta colocar a cor certa
// export const primary_12 = '#141414'falta colocar a cor certa
// export const primary_13 = '#000000'falta colocar a cor certa


export const text_color = 'rgba(0, 0, 0, 0.85)';
export const secondary_color = '#00000073'
export const danger = '#FF4D4F'
export const alert_red_color = '#EA5349'

export const COLORS = [
    "#BAE7FF", "#FFCCC7", "#FFD8BF", "#FFE7BA", "#FAECB2", "#F5F3A9",
    "#EAF99D", "#D9F7BE", "#B5F5EC", "#D6E4FF", "#EFDBFF", "#FFD6E7",

    "#91D5FF", "#FFA39E", "#FFBB96", "#FFD591", "#FFE58F", "#FFFB8F",
    "#EAFF8F", "#B7EB8F", "#87E8DE", "#ADC6FF", "#D3ADF7", "#FFADD2",

    "#69C0FF", "#FF7875", "#FF9C6E", "#FFC069", "#FFD666", "#FFF566",
    "#D3F261", "#95DE64", "#5CDBD3", "#85A5FF", "#B37FEB", "#FF85C0",
];

export const PROJECT_COLORS = [
    ...COLORS,
    "#4295E2", "#EB7173", "#E87A4E", "#FFAA42", "#FAC344", "#FAE846",
    "#B8DB50", "#91D463", "#46D1CC", "#6581DB", "#9B65DE", "#DD6CA7",

    "#5A91CC", "#CD7E88", "#C6715E", "#E1A25E", "#EBB54B", "#ECDB6B",
    "#778858", "#4F7344", "#4A989B", "#4E5FB5", "#7354A6", "#A8608A",

    "#4F7FB2", "#C24E5C", "#B26656", "#D19C63", "#D6AC5B", "#D9C966",
    "#7E8B65", "#6D8467", "#548C8E", "#616B9A", "#654B8F", "#93617E",

    "#345F8D", "#B25661", "#965243", "#B08352", "#C18F2B", "#BBAD56",
    "#677A45", "#4D6446", "#3D7072", "#4F577E", "#543981", "#7E4064",

    "#3E5A78", "#8D535A", "#98594B", "#B0906D", "#A68646", "#988E4C",
    "#67764C", "#608455", "#31585A", "#444C72", "#4D3A6E", "#6F455D",
];

export const blue_1 = '#E6F7FF'
export const blue_2 = '#bae7ff'
export const blue_3 = '#91d5ff'
export const blue_4 = '#69c0ff'
export const blue_5 = '#40a9ff'
export const blue_6 = '#1890ff'
export const blue_7 = '#096dd9'
export const blue_8 = '#0050b3'
export const blue_9 = '#003a8c'
export const blue_10 = '#002766'


export const neutral_1 = '#ffffff'
export const neutral_2 = '#fafafa'
export const neutral_3 = '#f5f5f5'
export const neutral_4 = '#f0f0f0'
export const neutral_5 = '#d9d9d9'
export const neutral_6 = '#bfbfbf'
export const neutral_7 = '#8c8c8c'
export const neutral_8 = '#595959'
export const neutral_9 = '#434343'
export const neutral_10 = '#262626'
export const neutral_11 = '#1f1f1f'
export const neutral_12 = '#141414'
export const neutral_13 = '#000000'

export const gold_1 = "#FFFBE6"
export const gold_3 = "#FFE58F"
export const gold_6 = "#FAAD14"
export const purple_5 = "#9254DE"

// export const sunrise_1 = '#FFF7E6'falta colocar a cor certa
// export const  sunrise_2 = '#fafafa'falta colocar a cor certa
// export const  sunrise_3 = '#FFD591'falta colocar a cor certa
// export const  sunrise_4 = '#FFC069'falta colocar a cor certa
// export const  sunrise_5 = '#d9d9d9'falta colocar a cor certa
export const  sunrise_6 = '#D4B106'
// export const  sunrise_7 = '#D4B106'
// export const  sunrise_8 = '#AD8B00'falta colocar a cor certa
export const  sunrise_9 = '#876800'
// export const  sunrise_10 = '#262626'falta colocar a cor certa
// export const  sunrise_11 = '#1f1f1f'falta colocar a cor certa
// export const  sunrise_12 = '#141414'falta colocar a cor certa
// export const  sunrise_13 = '#000000'falta colocar a cor certa


export const success = "#52C41A"
export const error = "#F5222D"

export const phaseTagColor = {
    1: "#FFECF4",
    2: "#E6FFFB",
    3: "#FAFAFA"
}

export const assessmentStatusTagColor = {
    1: sunset_orange_1, // Not answered
    2: sunset_orange_1, // 'Answered'
    3: polar_green_1,   // 'Sent'
    4: dust_red_1,      // 'Invalid'
}

export const responseYesOrNoTagColor = {
    "yes": "#E6FFFB",
    "no": "#FFF7E6",
}

export const orange = '#FB3D14';
export const PROJECT_COLOR = "#1C78BA"
export const APPLICATION_COLOR = "#C290A5"

export const ASSESSMENT_STATUS_COLOR = {
    'Not answered': sunset_orange_1,
    'Answered': blue_1,
    'Sent': polar_green_1,
    'Invalid': dust_red_1,
}