import React from 'react';
import {neutral_6} from "../../utils/Colors";
import {Card, Flex, Typography} from "antd";
import {useTranslation} from "react-i18next";
import styled from 'styled-components';

const {Text} = Typography

const StyledCard = styled(Card)`
    padding: 8px 16px;
    .ant-card-body {
        padding: 0;
    }
    border-color: ${neutral_6};
    cursor: ${({onClick}) => onClick && "pointer"};
`
function HelpCard({title, description, icon, onClick, children}) {
    const {t} = useTranslation()
    return (
        <StyledCard onClick={onClick}>
            <Flex gap={16}>
                {icon}
                <Flex vertical={true} flex={1}>
                    <Text strong={true}>{t(title)}</Text>
                    <Text
                        type="secondary">{t(description)}</Text>
                </Flex>
                <Flex align='center'>
                    {children}
                </Flex>
            </Flex>
        </StyledCard>
    );
}

export default HelpCard;