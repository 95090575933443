import { Avatar, Badge, Progress, Space, Typography } from "antd";
import UserAvatar from "../misc/UserAvatar";
import TagsList from "../tag/TagsList";
import SolvTag from "../tag/SolvTag";
import { FileTextTwoTone, FilePdfOutlined, FileImageTwoTone } from "@ant-design/icons";
import { DateFormat } from "../../utils/dates";
import {useTranslation} from "react-i18next";

const {Text} = Typography;

export function renderBadge(value, color) {
    return <Badge count={value} showZero={true} color={color || "var(--primary_btn_color)"} />
}

export function renderDate(value) {
    return !value ? "-" : DateFormat(value, "DD/MM/YYYY");
}

export function renderFileType(value, fontSize=16) {
    const textStyle = { fontSize };

    const [pathname] = value.split("?");
    if (pathname.endsWith('.pdf')) {
        return <Text type="danger" style={textStyle}><FilePdfOutlined /></Text>;

    } else if (pathname.endsWith('.png') || pathname.endsWith('.jpg') || pathname.endsWith('.png')) {
        return <Text style={textStyle}><FileImageTwoTone /></Text>
    }

    return <Text  style={textStyle}><FileTextTwoTone /></Text>

}

export function renderProgress(value) {
    return <Progress percent={value} style={{ width: "150px" }} />;
}

export function renderTags(tags, maxSize = 3) {
    return <TagsList tags={tags} maxSize={maxSize} />
}

export function renderAvatars(users, props) {
    return <Avatar.Group maxCount={props?.maxCount || 3}>
        {users?.map((user) => (
            <UserAvatar key={user?.id} data={user} {...props}/>
        ))}
    </Avatar.Group>
}


export function renderCurrency(value) {
    return `R$${value}`
}

export function renderPermissions(permissions, t) {
    return <div style={{display: "flex", alignItems: "center", gap: 8}}>
        {permissions.map((permission, index) => <SolvTag color="#fafafa" key={index}>{t ? t(permission) : permission}</SolvTag>)}
    </div>;
}

export function renderProfile(name, member) {
    return <Space size="small"><UserAvatar data={member} />{name || '-'}</Space>;
}

export function renderLink(link) {
    return <a href={link} target="_blank">{link}</a>
}

export function renderInfo(info, icon) {
    return <Space size={2}>{info} {icon}</Space>
}

export function renderYesNo(value, t){
    // const {t} = useTranslation();
    const text = value === "true" ? t("Yes") : t("No")
    const color = value === "true" ? "green" : "red"
    return <span style={{color}}>{text}</span>
}