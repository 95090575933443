import React from 'react'
import styled from 'styled-components'
import ButtonDefault from "./Buttons/ButtonDefault";
import {useTranslation} from "react-i18next";

const MainContainer = styled.div`
  width: 100%;
  overflow: hidden;
`

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: center;
  overflow: hidden;
  align-items: flex-start;
`
const SubHeaderContainer = styled(HeaderContainer)`
  margin: 8px 0;
  height: 27px;
`

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  overflow: hidden;
  height: 450px;

  & > * {
    flex-basis: auto;
    min-width: 100%;
    flex-shrink: 0;
  }
`

const FooterContainer = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const LastButtons = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  align-items: center;
`

const StepHeaderItem = styled.span.attrs(props => (
    {
        className: props.ClassName
    }
))`

  padding-bottom: 2px;

  &.active {
    border-bottom: 1px solid var(--brand-primary-color);
    color: var(--brand-primary-color);
  }

`

export function StepWizard({contentList, initalStep}) {

    const {t} = useTranslation();
    const [currentStep, setCurrentStep] = React.useState(initalStep)
    const [currentStepSubPage, setCurrentStepSubPage] = React.useState(0)

    React.useEffect(() => {
        if (contentList[currentStep].subpages) {
            const subpages = contentList[currentStep].subpages
            for (let index = 0; subpages.length; index++) {
                if (subpages[index].active) {
                    setCurrentStepSubPage(index)
                    break;
                }
            }

        }
    }, [currentStep])

    return (
        <MainContainer>
            <HeaderContainer>
                {contentList.map((item, index) => {
                    return (
                        <>
                            <StepHeaderItem
                                className={`${index === currentStep ? 'active' : ''}`}>
                                {item.header}
                            </StepHeaderItem>

                        </>

                    )

                })}
            </HeaderContainer>
            <SubHeaderContainer>
                {contentList[currentStep].subpages && (
                    <>
                        {contentList[currentStep].subpages.map((item, index) => {
                            return (
                                <>
                                    {item.active && (
                                        <StepHeaderItem
                                            className={`${index === currentStepSubPage ? 'active' : ''}`}>
                                            {item.subheader}
                                        </StepHeaderItem>
                                    )}

                                </>

                            )

                        })}
                    </>
                )}
            </SubHeaderContainer>

            <ContentContainer>
                {!contentList[currentStep].subpages ?
                    (
                        contentList[currentStep].content
                    ) :
                    (
                        contentList[currentStep].subpages[currentStepSubPage].content
                    )
                }

            </ContentContainer>
            <FooterContainer>


                {!contentList[currentStep].subpages && contentList.map((item, index) => currentStep === index && (
                    <>
                        <div>
                            {item.firstButton && (

                                <ButtonDefault
                                    style={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                        marginRight: 30
                                    }}
                                    action={item.firstButton.type}
                                    onClick={() => item.firstButton.action(setCurrentStep)}
                                    label={item.firstButton.label}/>
                            )}
                        </div>


                        <LastButtons>
                            {item.middleButton && (
                                <ButtonDefault
                                    style={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                        marginRight: 30
                                    }}
                                    action={item.middleButton.type}
                                    onClick={() => item.middleButton.action(setCurrentStep)}
                                    label={item.middleButton.label}/>
                            )}
                            {item.lastButton && (
                                <ButtonDefault
                                    style={{
                                        marginTop: 0,
                                        marginBottom: 0,
                                        marginRight: 30
                                    }}
                                    action={item.lastButton.type}
                                    onClick={() => item.lastButton.action(setCurrentStep)}
                                    label={item.lastButton.label}
                                    data-cy="last-button"/>
                            )}
                        </LastButtons>


                    </>
                ))}


                {contentList[currentStep].subpages && (

                    contentList[currentStep].subpages.map((item, index) => currentStepSubPage === index && (
                        <>
                            <div>
                                {item.firstButton && (
                                    <ButtonDefault
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 0,
                                            marginRight: 30
                                        }}
                                        action={item.firstButton.type}
                                        onClick={() => item.firstButton.action(setCurrentStep, setCurrentStepSubPage)}
                                        label={item.firstButton.label}/>
                                )}
                            </div>


                            <LastButtons>
                                {item.middleButton && (
                                    <ButtonDefault
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 0,
                                            marginRight: 30
                                        }}
                                        action={item.middleButton.type}
                                        onClick={() => item.middleButton.action(setCurrentStep)}
                                        label={item.middleButton.label}/>
                                )}
                                {item.lastButton && (
                                    <ButtonDefault
                                        style={{
                                            marginTop: 0,
                                            marginBottom: 0,
                                            marginRight: 30
                                        }}
                                        action={item.lastButton.type}
                                        onClick={() => item.lastButton.action(setCurrentStep, setCurrentStepSubPage)}
                                        label={item.lastButton.label}/>
                                )}
                            </LastButtons>

                        </>
                    ))
                )}


            </FooterContainer>
        </MainContainer>
    )
}