import {Button, Col, Row, Space, Typography} from "antd";
import ROIChart from "./components/DashboardCharts/ROIChart";
import ProjectRadarChart from "./components/DashboardCharts/ProjectRadarChart";
import TotalExpectedInvestmentChart
    from "./components/DashboardCharts/TotalExpectedInvestmentChart";
import React, {useEffect, useState} from "react";
import api from "../../../../global/services/api";
import EmptyPageCard from "../../../../global/components/layout/EmptyPageCard";
import {Link, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useThemeContext} from "../../../../global/context/ThemeContext";
import TotalInvestmentMadeChart
    from "./components/DashboardCharts/TotalInvestmentMadeChart";
import {
    ClientIndicatorBarChart,
    ClientIndicatorColumnChart,
} from "./components/DashboardCharts/ClientIndicatorChart";
import styled from "styled-components";
import ChartCard from "../../../../global/components/data_display/ChartCard";
import FunnelChartProject
    from "./components/DashboardCharts/FunnelChartProject";
import RiskChart from "./components/DashboardCharts/ColumnChartProject";
import TimelineChart from "./components/DashboardCharts/TimelineChart";
import {
    usePermissionContext
} from "../../../../global/context/PermissionContext";
import Alert from "../../../../global/components/data_display/Alert";
import {PlusOutlined} from "@ant-design/icons";
import StackedColumnChart
    from "./components/DashboardCharts/StackedColumnChart";
import ButtonFilterProjectDashboardDrawer
    from "./components/ButtonFilterProjectDashboardDrawer";
import {
    FlexContainer
} from "../../../../global/components/authentication/StyledComponents";
import ForecastChart from "../ProjectManagement/components/DashboardIndicatorCharts/ForecastChart";

const {Text} = Typography;
const StyledRow = styled(Row)`
    .ant-card:has(.inactive) {
        display: none;
    }

    .ant-col:not(:has(div)) {
        display: none;
    }
`

function GraphCol({children}) {
    return (
        <Col xs={24} sm={24} md={12} lg={12} xl={8}>
            {children}
        </Col>
    )
}

function BuildClientIndicatorChart({filters, indicator, ...props}) {
    const {themeConfig} = useThemeContext();

    if (indicator.view === "percent") {
        return <ClientIndicatorBarChart request={{
            url: `/analytics/dashboard/project_client_dashboard/${themeConfig.client.id}/client_indicators_report`, body: filters
        }} clientIndicator={indicator} {...props} />
    }
    return <ClientIndicatorColumnChart request={{
        url: `/analytics/dashboard/project_client_dashboard/${themeConfig.client.id}/client_indicators_report`, body: filters
    }} clientIndicator={indicator} {...props}  />
}

const columnOptions = {
    xs: 24, sm: 24, md: 12, lg: 12, xl: 8
}

function ProjectDashboardPage() {
    const [hasProjects, setHasProjects] = useState(undefined);
    const [clientIndicators, setClientIndicators] = useState([])
    const [filters, setFilters] = useState([])
    const {themeConfig} = useThemeContext();
    const {t} = useTranslation();
    const {hasProjectAdminOrManagerPermission} = usePermissionContext();
    const history = useHistory();

    const checkExistingProjects = async () => {
        try {
            const response = await api.get(`/analytics/dashboard/project_client_dashboard/${themeConfig.client.id}/has_projects`);
            setHasProjects(response.data);
        } catch (exception) {
        }
    };

    const fetchClientIndicators = async () => {
        try {
            const {data} = await api.get(`/analytics/dashboard/project_client_dashboard/${themeConfig.client.id}/fetch_client_indicators`);
            setClientIndicators(data);
        } catch (exception) {
        }
    };

    useEffect(() => {
        void checkExistingProjects()
        void fetchClientIndicators()
    }, []);

    return <>
        {hasProjects && <Space direction="vertical" style={{width: "100%"}}>
            <StyledRow gutter={[32, 24]}>

                <Col span={24}>
                    <Alert
                        message={
                            <Row justify="space-between" align="middle">
                                <div>{t("Click on a project to see more details about them.")}</div>
                                <FlexContainer gap={8}>
                                    <ButtonFilterProjectDashboardDrawer
                                        setFilters={setFilters}
                                        filters={filters}/>
                                    {
                                        hasProjectAdminOrManagerPermission &&
                                        <Button type="primary"
                                                onClick={() => history.push("/project-portfolio/project/create")}
                                                data-cy="new-project-button"
                                        >
                                            <PlusOutlined/>{t("New Project")}
                                        </Button>

                                    }

                                </FlexContainer>

                            </Row>
                        }
                        extra={<div>teste</div>}
                    />
                </Col>

                <ROIChart
                    filters={filters}
                    parent={
                        <ChartCard title={t("Return on Investment")}
                                   expandible={true}/>
                    }
                    columnOptions={columnOptions}
                />

                <TotalInvestmentMadeChart
                     filters={filters}
                    parent={
                        <ChartCard title={t("Total Investment Made")}
                                   expandible={true}/>
                    }
                    columnOptions={columnOptions}
                />

                <TotalExpectedInvestmentChart
                     filters={filters}
                    parent={
                        <ChartCard
                            title={t("Total Expected Investment")}
                            expandible={true}/>
                    }
                    columnOptions={columnOptions}
                />

                <RiskChart
                     filters={filters}
                    parent={
                        <ChartCard
                            title={t("Projects by Risk")} expandible={true}
                        />
                    }
                    columnOptions={columnOptions}
                />

                <ProjectRadarChart
                     filters={filters}
                    parent={
                        <ChartCard title={t("Project Radar")}
                                   expandible={true}/>
                    }
                    columnOptions={columnOptions}
                />

                {/*<FunnelChartProject*/}
                {/*    parent={*/}
                {/*        <ChartCard title={t("Projects by Phases")} expandible={true} />*/}
                {/*    }*/}
                {/*    columnOptions={columnOptions}*/}
                {/*/>*/}

                <StackedColumnChart
                     filters={filters}
                    parent={
                        <ChartCard title={t("Projects by Phases")}
                                   expandible={true}/>
                    }
                    columnOptions={columnOptions}
                />

                {clientIndicators.map((indicator, key) =>
                    <GraphCol key={key}>
                        <ChartCard
                            title={indicator.name}
                            expandible={true}
                            // description={indicator.description}
                        >
                            {<BuildClientIndicatorChart  filters={filters}
                                indicator={indicator}/>}
                        </ChartCard>
                    </GraphCol>
                )}

                <Col span={24}>
                    <ChartCard title={t("Timeline")} expandible={true}

                               drawerProps={{width: '100vw'}} height="auto">
                        <TimelineChart  filters={filters}/>
                    </ChartCard>
                </Col>

            </StyledRow>

        </Space>}
        {hasProjects === false &&
            <EmptyPageCard>
                <Space direction="vertical">
                    <Text>{t("No projects available")}</Text>
                    {hasProjectAdminOrManagerPermission &&
                        <Link to={`/project-portfolio/project/create`}><Button
                            type="primary">{t("Create New Project")}</Button></Link>}
                    <Text
                        type="secondary">{t("Dashboard data is based on the project checkpoint. When creating the first project there will be data to be displayed.")}</Text>
                </Space>
            </EmptyPageCard>
        }

    </>
}

export default ProjectDashboardPage;