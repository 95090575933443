import React, {useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import {Space} from "antd";

import ChartCard from "../../../../components/data_display/ChartCard";
import {ColumnWidthOutlined, DeleteOutlined} from "@ant-design/icons";


export default function ChartCardWithDelete({children, onDelete,...props}) {
    const {t} = useTranslation();
    return <ChartCard onDelete={onDelete} expandible


                      {...props}>
        {children}
    </ChartCard>
}
