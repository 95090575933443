import React, {useEffect, useState} from 'react'
import {
    Col, Row, Typography, Modal,
} from "antd";
import {ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import Button from "../../../../../../../global/components/data_entry/Button";
import {
    CardContainer
} from "../../../../../../../global/components/misc/CardContainer";
import useTable from "../../../../../../../global/hooks/useTable";
import api from "../../../../../../../global/services/api";
import {
    ToastNotification
} from "../../../../../../../global/components/feedback/ToastNotification";
import * as ConstantsMessage
    from "../../../../../../../global/utils/fileWithMessage";

import Table from "../../../../../../../global/components/data_display/Table";
import {
    get_color_status
} from "../../../../../../../global/components/other/Utils";
import PermissionTagsList
    from "../../../../../../../global/components/tag/PermissionTagsList";
// import PhaseMembersOffCanvas from "./PhaseMembersOffCanvas";
import {BuildFieldErrorsDict} from "../../../../../../../global/utils/Utils";
import DrawerProfileUser from '../../../../../../../global/pages/profile/components/DrawerProfileUser/DrawerProfileUser';
import Search from "../../../../../../../global/components/data_entry/Search";
import SolvTag from './../../../../../../../global/components/tag/SolvTag';
import ProgramMembersOffCanvas from '../../../../Program/ProgramForm/components/Members/ProgramMembersOffCanvas';

const {Title, Link} = Typography;


export default function PhaseMembers({program, phase,updatePageOnClose}) {

    const [showMemberDrawer, setShowMemberDrawer] = useState(false)
    const [openDrawerProfile, setOpenDrawerProfile] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [updateMember, setUpdateMember] = useState();
    const [userId, setUserId] = useState();
    const {t} = useTranslation();
    const [search, setSearch] = useState("");
    const {
        tableData, 
        tableParams, 
        isLoading: isLoadingTable, 
        resetTable,
        handleTableChange
    } = useTable({
        url: `programs/${program.id}/members_with_invites`,
        waitExternalLoading: !program.id,
        hasPagination: true,
        body: {},
        extraParams: {phase_id: phase?.id, permission:'evaluator'},
        search,
        pageSize: 10
    })


    useEffect(() => {
        if (!showMemberDrawer) setUpdateMember(null)
    }, [showMemberDrawer])


    const columns = [
        {
            title: t("Name"),
            dataIndex: ["user_client","user","first_name"],
            sorter:'user_client__user__first_name',
            key: "name",
            render: (value, record) => (
                <Link href="#" onClick={()=>handleOpenProfileDrawer(record)}>{value}</Link>
            ) 
        },
        {
            title: "Status",
            dataIndex: "status",
            sorter:'user_client__is_active',
            key: "status",
            render: (value, record) => {

                if (!value) return '-'
                return <SolvTag
                    color={get_color_status(value)}>{t(value)}</SolvTag>
            }
        },
        {
            title: "Email",
            sorter:'user_client__user__email',
            dataIndex: "user_client.user.email",
            key: "email",
        },
        {
            title: t("Permission"),
            dataIndex: "permission_user_client",
            key: "permission",
            render: (value, record) => {

                const newValues = {}

                value.forEach((value) => {
                    if (value === 2) {
                        newValues['is_program_manager'] = true
                    } else if (value === 3) {
                        newValues['is_program_evaluator'] = true
                    }
                })
                return (

                    <div style={{
                        display: "flex",
                        gap: 8,
                        alignItems: 'center'
                    }}>

                        <PermissionTagsList permissions={newValues}/>
                    </div>
                );
            },
        },
        {
            title: t("Actions"),
            dataIndex: "action",
            key: "action",
            render: (_, record) => (

                    <Button loading={isLoading}
                            onClick={() => handleDelete(record)}
                            type="link">{t("Delete")}</Button>



            ),
        },]

    async function handleDelete(record) {


        Modal.confirm({
                icon: <ExclamationCircleOutlined/>,
                title: t("Do you really want to delete this member from this phase?"),
            content: t("NOTE: if the evaluator is not linked to another phase, he or she will be removed from the program"),
                onOk: async () => {
                    try {
                        setIsLoading(true)

                        await api.post(`members/${record.id}/remove_phase_from_evaluator`, {phase_id: phase.id});
                        resetTable();
                        updatePageOnClose()
                        ToastNotification(t(ConstantsMessage.SUCCESS))
                        
                    } catch (error) {
                        BuildFieldErrorsDict(error)
                    } finally {
                        setIsLoading(false)
                    }
                },
                okText: t('Yes'),
                cancelText: t('No')

            }
        )

    }

    const handleOpenProfileDrawer = (record) => {
        const id = record.user_client?.user.id
        setUserId(id);
        setOpenDrawerProfile(true);
    }


    return (
        <>
            <CardContainer bordered={false} style={{padding: '16px 8px'}}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} lg={12}>
                       <Title level={5}>{t("Evaluators")}</Title>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Row gutter={16}>
                            <Col flex={'1'}>
                                <Button htmlType="submit" hidden disabled></Button>
                                <Search onSearch={(value) => setSearch(value)} allowClear/>
                            </Col>
                            <Col>
                                <Button
                                    loading={isLoading}
                                    icon={<PlusOutlined/>}
                                    onClick={() => setShowMemberDrawer(true)}
                                    data-cy="new-member-button"> {t("New member")}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24}>
                        <Table dataSource={tableData}
                               onChange={handleTableChange}
                               loading={isLoadingTable}
                               tableParams={tableParams}
                               columns={columns}/>
                    </Col>
                </Row>


            </CardContainer>
            <ProgramMembersOffCanvas
                setOpen={setShowMemberDrawer}
                updatePageOnClose={updatePageOnClose}
                resetTable={resetTable}
                program={program}
                phase={phase}
                open={showMemberDrawer}
            />
            <DrawerProfileUser
                id={userId}
                open={openDrawerProfile}
                setOpen={setOpenDrawerProfile}
            />
        </>
    )
}