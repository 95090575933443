import { Button, Card, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ChallengeDrawer from "../../../../components/other/challenge/ChallengeDrawer/ChallengeDrawer";
import TruncateText from "../../../../components/responsive/TruncateText";
import { blue_1 } from "../../../../utils/Colors";
import { useUserContext } from "../../../../context/UserContext";

const {Text} = Typography

const StyledCard = styled(Card)`
    filter: ${({ isOpen }) => (isOpen ? "none" : "grayscale(100%)")};
    //:hover {
    //    background-color: ${blue_1};
    //}
    span:hover{
        color:var(--primary_btn_color_hover)
    }
    border-radius: 16px;
    
`;

const StyledChallengeCardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTitleText = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #00000085;
`;

const StyledDescriptionContainer = styled.div`
  height: 64px;
`;

const StyledDescriptionText = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #00000073;
`;

const StyledLinkContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;

export default function ChallengeCard({challenge, index, isOpen, onClick, applicationNameDisplay}) {
    const {t} = useTranslation();
    const [openChallengeDrawer, setOpenChallengeDrawer] = useState(false);
    const [selectedChallenge, setSelectedChallenge] = useState(null);
    const {user_client} = useUserContext();

    const handleDrawerChallenge = (record) => {
        setOpenChallengeDrawer(true);
        setSelectedChallenge(record);
    }

    return <StyledCard isOpen={isOpen} bordered={false} bodyStyle={{padding: "16px"}} style={{width:"100%"}}>
            <a onClick={() => handleDrawerChallenge(challenge)} data-cy={`challenge-card-title-${challenge.id}`}>
            <StyledChallengeCardContainer>
                <StyledTitleText>
                    <TruncateText>{challenge.name}</TruncateText>
                </StyledTitleText>
                <StyledDescriptionContainer>
                    <StyledDescriptionText>
                        <TruncateText lines={3}>
                            {challenge.description}
                        </TruncateText>
                    </StyledDescriptionText>
                </StyledDescriptionContainer>
                <StyledTitleText style={{textAlign: "right"}}>
                    <Text type="secondary">{index}</Text>
                </StyledTitleText>
            </StyledChallengeCardContainer>
            </a>
                {isOpen && user_client &&
                    <StyledLinkContainer>
                            <Button type="link" onClick={onClick}
                                data-cy={`suggest-program-solution-button-${challenge.name}`}
                            >
                                 {`${t("Suggest")} ${t(applicationNameDisplay)}`}
                            </Button>
                    </StyledLinkContainer>
                }
            <ChallengeDrawer challengeIndex={selectedChallenge} open={openChallengeDrawer} setOpen={setOpenChallengeDrawer}/>
    </StyledCard>
}