import React from 'react';
import {Switch, useRouteMatch,} from "react-router-dom";

import {ManagerRoute} from "../../../global/routes/PrivateRoute";

import Challenges
    from "../../../pages/Programs/TabBarProgram/Challenges/Challenges";

import DatabaseProgram
    from "../../../pages/Programs/TabBarProgram/Applications/DatabaseProgram";
import InteractionProgram
    from "../../../pages/Programs/TabBarProgram/InteractionProgram";
import Interaction from "../../../pages/Interaction";
import ProgramForm
    from "../../../pages/Programs/TabBarProgram/Program/ProgramForm/ProgramForm";
import Dashboard
    from '../../../pages/Programs/TabBarProgram/Dashboard/Dashboard';
import {usePermissionContext} from "../../../global/context/PermissionContext";
import TabPhaseProgram
    from "../../../pages/Programs/TabBarProgram/Phases/TabPhaseProgram/TabPhaseProgram";
import ProgramMembers
    from '../../../pages/Programs/TabBarProgram/Program/ProgramForm/components/Members/ProgramMembers';
import TabStartupsPrograms
    from "../../../pages/Programs/TabBarProgram/TabStartupsPrograms/TabStartupsPrograms";
import ProgramGamification
    from "../../../pages/Programs/TabBarProgram/Gamification/ProgramGamification";
import {useThemeContext} from "../../../global/context/ThemeContext";
import {ENVIROMENT} from "../../../settings";

export default function RoutesProgram({program, hasPermission}) {
    let {path} = useRouteMatch();
    const {programHasChallengeMappingPermission, hasGamificationAdminPermission} = usePermissionContext()
    const {themeConfig: {client}} = useThemeContext();
    return (
        <Switch>
            <ManagerRoute
                path={`${path}/dashboard`}
                hasPermission={hasPermission}
                render={() => <Dashboard/>}
            />

            {/*<ManagerRoute path={`${path}/phases-old`}*/}
            {/*              hasPermission={hasPermission}*/}
            {/*              render={(props) =>*/}
            {/*                  <Phases {...props}/>}/>*/}

            <ManagerRoute path={`${path}/phases`}
                          hasPermission={hasPermission}
                          render={(props) =>
                              <TabPhaseProgram {...props}/>}/>

            <ManagerRoute path={`${path}/challenges`}
                          exact
                          hasPermission={hasPermission && !programHasChallengeMappingPermission(program)}
                          render={(props) =>
                              <Challenges {...props}/>}/>

            <ManagerRoute path={`${path}/interaction`}
                          exact
                          hasPermission={hasPermission}
                          render={(props) =>
                              <InteractionProgram {...props}/>}/>
            <ManagerRoute path={`${path}/interaction/create_interaction`}
                          exact
                          hasPermission={hasPermission}
                          render={(props) =>
                              <Interaction {...props}/>}/>
            <ManagerRoute path={`${path}/interaction/:id_interaction`}
                          hasPermission={hasPermission}
                          render={(props) =>
                              <Interaction {...props}/>}/>

            <ManagerRoute path={`${path}/database`}
                          hasPermission={hasPermission}
                          render={(props) =>
                              <DatabaseProgram
                                  hasPermission={hasPermission} {...props}/>}/>
            <ManagerRoute path={`${path}/gamification`}
                          hasPermission={hasGamificationAdminPermission && client?.gamification_actived}
                          component={ProgramGamification}/>

            <ManagerRoute path={`${path}/organizers`}
                          hasPermission={hasPermission}
                          render={(props) =>
                              <ProgramMembers program={program} {...props}/>}/>
            { process.env.REACT_APP_ENV !== 'prod' && <ManagerRoute path={`${path}/startups`}
                          hasPermission={hasPermission}
                          render={(props) =>
                              <TabStartupsPrograms {...props}/>}/>}
            <ManagerRoute path={`${path}/settings`}
                          hasPermission={hasPermission}
                          render={(props) =>
                              <ProgramForm program={program} {...props}/>}/>
        </Switch>
    );
}