import React from "react";
import TextAreaForm from "./TextAreaForm";
import TextInputForm from "./TextInputForm";
import SingleSelectForm from "./SingleSelectForm";
import {v4 as uuid} from "uuid";
import HeaderForm from "./HeaderForm";
import ParagraphForm from "./ParagraphForm";
import DateForm from "./DateForm";
import MultipleSelectForm from "./MultipleSelectForm";
import CheckboxForm from "./CheckboxForm";
import RadioForm from "./RadioForm";
import ImageForm from "./ImageForm";
import DocumentForm from "./DocumentForm";
import EmailForm from "./EmailForm";
import LinkForm from "./LinktForm";
import IntegerForm from "./IntegerForm";
import IconHeader from "../../../../assets/IconsFormComponents/new_header.svg";
import IconParagraph from "../../../../assets/IconsFormComponents/new_paragraph.svg";
import IconTextInput from "../../../../assets/IconsFormComponents/text_input.svg";
import IconTextArea from "../../../../assets/IconsFormComponents/text_area.svg";
import IconEmail from "../../../../assets/IconsFormComponents/email.svg";
import IconLink from "../../../../assets/IconsFormComponents/link.svg";
import IconNumber from "../../../../assets/IconsFormComponents/number.svg";
import IconDate from "../../../../assets/IconsFormComponents/date.svg";
import IconSelect from "../../../../assets/IconsFormComponents/new_select.svg";
import IconMultiSelect from "../../../../assets/IconsFormComponents/new_multi_select.svg";
import IconCheckbox from "../../../../assets/IconsFormComponents/new_checkbox.svg";
import IconRadioButton from "../../../../assets/IconsFormComponents/radio_button.svg";
import IconImage from "../../../../assets/IconsFormComponents/image.svg";
import IconDocument from "../../../../assets/IconsFormComponents/document.svg";
import NumberForm from "./NumberForm";
import SectionIcon from "../../../../assets/IconsFormComponents/section_icon.svg";
import SectionRadioForm from "./SectionRadioForm";


export const COMPONENT_MAX_LENGTH_DEFAULT = {
    text_area: 9999,
    text_input: 255,
}

export const COMPONENTS = [
    // TEXT
    {type: 'title_form_group', name: "Heading", icon: IconHeader},
    {type: 'header', name: "Big Title", icon: IconHeader},
    {type: 'paragraph', name: "Small Title", icon: IconParagraph},
    {type: 'division'},
    //Inputs
    {type: 'title_form_group', name: "Questions", icon: IconHeader},
    {type: 'text_input', name: "Short answer", icon: IconTextInput},
    {type: 'text_area', name: "Long answer", icon: IconTextArea},
    {type: 'email', name: "Email", icon: IconEmail},
    {type: 'link', name: "Link", icon: IconLink},
    {type: 'number', name: "Number", icon: IconNumber},
    //Date
    {type: 'date', name: "Date", icon: IconDate},
    {type: 'division'},
    //List
    {type: 'title_form_group', name: "checklist", icon: IconHeader},
    {type: 'select', name: "Single choice list", icon: IconSelect},
    {type: 'multiple_select', name: "Multiple choice list", icon: IconMultiSelect},
    {type: 'radio', name: "Single choice checkbox", icon: IconRadioButton},
     {type: 'section', name: "Section", icon: SectionIcon},
    {type: 'checkbox', name: "Multiple choice checkbox", icon: IconCheckbox},
    {type: 'division'},
    //File Inputs
    {type: 'title_form_group', name: "Upload", icon: IconHeader},
    {type: 'image', name: "Image upload", icon: IconImage},
    {type: 'document', name: "Document upload", icon: IconDocument},
]

const addchoices = ['select', 'multiple_select', 'checkbox', 'radio', 'section'];
const limitedInput = ['text_area', 'text_input'];

export const createJsonComponent = (type) => {
    let json = {
        help_text: "",
        label: "Title",
        name: 'f' + uuid().split('-').join(''),
        required: !['header', 'paragraph'].includes(type),
        type: type,
    }
    addchoices.includes(type) && (json["choices"] = [{value: 'new option'}])
    limitedInput.includes(type) && (json["max_length"] = COMPONENT_MAX_LENGTH_DEFAULT[type])
    return json
}

export default function getComponent(props) {
    switch (props.type) {
        case 'header':
            return <HeaderForm {...props}/>
        case 'paragraph':
            return <ParagraphForm {...props}/>
        case 'text_area':
            return <TextAreaForm {...props}/>
        case 'text_input':
            return <TextInputForm  {...props}/>
        case 'date':
            return <DateForm {...props}/>
        case 'select':
            return <SingleSelectForm {...props}/>
        case 'multiple_select':
            return <MultipleSelectForm {...props}/>
        case 'checkbox':
            return <CheckboxForm {...props} />
        case 'radio':
            return <RadioForm {...props} />
        case 'section':
            return <SectionRadioForm {...props} />
        case 'image':
            return <ImageForm {...props} />
        case 'document':
            return <DocumentForm {...props} />
        case 'email':
            return <EmailForm {...props} />
        case 'link':
            return <LinkForm {...props} />
        case 'integer':
            return <IntegerForm {...props} />
        case 'number':
            return <NumberForm {...props} />
        default:
            return <div>COMPONENTE INEXISTENTE</div>
    }
}