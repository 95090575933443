import {PlusOutlined} from "@ant-design/icons";
import {Col, Row} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ExtraHeader from "../../../modules/project/components/layout/ExtraHeader";
import {useLayoutContext} from "../../../modules/project/components/layout/context/LayoutContext";
import Search from "../../components/data_entry/Search";
import Loading from "../../components/feedback/Loading";
import Empty from "../../components/data_display/Empty";
import Pagination from "../../components/navigation/Pagination";
import CardTopic from "../../components/data_display/CardTopic";
import Button from "../../components/data_entry/Button";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import CreateTopicOffCanvas from "./offcanvas/CreateTopicOffCanvas";
import MessagesTopicOffCanvas from "./offcanvas/MessagesTopicOffCanvas/MessagesTopicOffCanvas";
import {FlexContainer} from "../../components/authentication/StyledComponents";
import ApplicationDrawer from "../../components/other/application/ApplicationDrawer/ApplicationDrawer";
import ApplicationTopicInfoCard from "../../components/data_display/ApplicationInfoCard/ApplicationTopicInfoCard";


export default function ListProgramTopics({program, updateTabBar = true}) {
    const {t} = useTranslation();
    const {initializeLayout} = useLayoutContext();
    const [isLoading, setIsLoading] = useState()
    const [topics, setTopics] = useState([])
    const [openCreate, setOpenCreate] = useState(false)
    const [currentTopic, setCurrentTopic] = useState()
    const [search, setSearch] = useState('')
    const [refresh, setRefresh] = useState(false)
    const {md} = useBreakpoint();
    const [openMessageTopicOffCanvas, setOpenMessageTopicOffCanvas] = useState(false)
    const [openApplicationDrawer, setOpenApplicationDrawer] = useState(false);
    const userPermission = handlePermission()

    const PaginationRequest = {
        url: `topics/program/${program?.id}`,
        setIsLoading: setIsLoading,
        setData: setTopics,
        extraParams: {
            search: search
        },
        dependence:[search]
    }

    function handlePermission() {
        if (program?.permissions.is_program_manager) return 'is_program_manager'
        if (program?.permissions.is_program_evaluator) return 'is_program_evaluator'
        return 'is_application'
    }

    const handleMessagesRead = (currentTopic) => {
        if (!currentTopic || !currentTopic.object_id) return;
        setTopics((prevTopics) =>
          prevTopics.map((topic) =>
            topic.solution && topic.solution.id === currentTopic.object_id
              ? { ...topic, unread_messages_count: 0 }
              : topic
          )
        );
      };

    useEffect(() => {
        if (program && updateTabBar) {
            initializeLayout({
                // navbarMenuItems: menuItems,

                useOldContentLayout: false,
                extraHeaderContent: <ExtraHeader
                    left={<FlexContainer gap={8}>
                        <span>{program?.name}</span>
                        {/*<PhaseTag*/}
                        {/*    phase={program.current_phase}>{program.current_phase.title}</PhaseTag>*/}
                    </FlexContainer>}
                    returnPath={'/messages'}/>

            });
        }
    }, [program]);

    function handleReRequest() {
        return search || refresh
    }

    function handleRefresh() {
        setRefresh(!refresh)
    }

    const handleOpenMessages = (topic) => {
        setCurrentTopic(topic)
        if (topic.type === "application") {
            setOpenApplicationDrawer(true)
        } else {
            setOpenMessageTopicOffCanvas(true)
        }
    }

    if (!program) return <Loading/>
    return (

        <Row gutter={[12, 12]}>

            <Col flex={'auto'}>
                <Search placeholder={t("Search")} onSearch={setSearch}/>
            </Col>
            {userPermission === 'is_program_manager' &&
                <Col flex={'0'}>
                    {/*<div >*/}
                    <Row justify={'end'}>
                        <Col xs={24}>

                            <Button
                                icon={<PlusOutlined/>}
                                onClick={() => {
                                    setOpenCreate(true)
                                }}
                            >
                                {md && t("New topic")}
                            </Button>
                        </Col>
                    </Row>

                </Col>}
            <Col xs={24}>

                <Row gutter={[16, 16]}>
                    {isLoading ? <Col xs={24}><Loading/></Col> :
                        topics.length > 0 ? topics.map((topic) => (
                            <Col xs={24} key={topic.id}>
                                {
                                    topic.solution ?
                                        <ApplicationTopicInfoCard topic={topic} onClick={() => handleOpenMessages(topic)} />
                                        :
                                        <CardTopic
                                            permission={[userPermission]}
                                            lastMessage={topic.last_message}
                                            onClick={() => handleOpenMessages(topic)}
                                            totalMessages={topic.total_messages_count}
                                            title={topic.title}
                                            hasPin={topic.type === 'program' || topic.type === 'evaluator'}
                                            totalUnreadMessage={topic.unread_messages_count}/>
                                }
                            </Col>

                        )) : <Col xs={24}><Empty/></Col>
                    }
                </Row>

            </Col>
            <Col xs={24}>
                <Row justify={'end'}>
                    <Col>
                        <Pagination request={PaginationRequest}
                                    reRequest={handleReRequest()}/>
                    </Col>
                </Row>
            </Col>
            <CreateTopicOffCanvas handleRefresh={handleRefresh}
                                  open={openCreate}
                                  setOpen={setOpenCreate}
                                  programId={program?.id}/>
            <MessagesTopicOffCanvas open={(openMessageTopicOffCanvas && !!currentTopic)}
                                    topic_id={currentTopic?.id}
                                    setOpen={setOpenMessageTopicOffCanvas}
                                    showGoToProgram={updateTabBar}/>

            <ApplicationDrawer solutionId={currentTopic?.object_id} open={(openApplicationDrawer && !!currentTopic)}
                               setOpen={setOpenApplicationDrawer} defaultActiveKey={"2"} showGoToProgram={false} postUpdate={handleRefresh} onMessagesRead={handleMessagesRead}/>

        </Row>

    )
}