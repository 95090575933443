import React, {useEffect, useState} from "react";
import {Chart} from "react-google-charts";
import {
    REACT_APP_GOOGLE_API_KEY
} from "../../../../../confis/env_files/env_homologacao";
import api from "../../../../../global/services/api";
import {
    BuildFieldErrorsDict,
    sumArray
} from "../../../../../global/utils/Utils";
import LoadingFull from "../../../../../global/components/other/LoadingFull";

export const defaultOptions = {
    enableRegionInteractivity: true,
    backgroundColor: "#ffffff",
};

function ChartCountry({data, options}) {
    return (
        <Chart
            chartType="GeoChart"
            data={data}
            options={options}
            width="100%"
            height="100%"
            mapsApiKey={REACT_APP_GOOGLE_API_KEY}
        />
    )
}
const DEFAULT_CONFIG_REQUEST = {
    version: 'v1'
}
export default function GeoChartCountry({request}) {
    const [data, setData] = useState();
    const [maxAxis, setMaxAxis] = useState(0);

    const getColorAxis = () => {
        return {
            colors: ["#D9D9D9", "#87E8DE", "#36CFC9", "#006D75"],
            values: [0, maxAxis],
        }
    }

    const fetchData = async () => {
        try {
            const config = {
                params: request.params,
                ...DEFAULT_CONFIG_REQUEST,
                ...request.config
            }
            let response;
            if (request?.body) {
                response = await api.post(request?.url, request?.body, config);
            } else {
                response = await api.get(request?.url, config);
            }


            let chartData = [['country', 'count']]
            response.data?.forEach(element => {
                chartData.push([element.country, element.count])
            });
            setMaxAxis(sumArray(response.data, 'count'));
            setData(chartData);
        } catch (error) {
            console.error(error);
            BuildFieldErrorsDict(error, null, false);
        }
    };

    useEffect(() => {
        void fetchData();
    }, [JSON.stringify(request)]);

    const options = {
        ...defaultOptions,
        sizeAxis: {minValue: 0, maxValue: maxAxis},
        colorAxis: getColorAxis(),
    }

    return (
        <>
            {data ? <ChartCountry data={data} options={options}/> :
                <LoadingFull/>}
        </>
    )
}