import {Space} from "antd";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import {
    renderAvatars,
    renderBadge,
    renderDate
} from "../../../../global/components/tables/utils";
import {renderIndicator} from "./utils";
import useSystemBreakpoint from "../../../../global/hooks/useSystemBreakpoint";
import Table from "../../../../global/components/data_display/Table";
import useTable from "../../../../global/hooks/useTable";
import React, {useEffect} from "react";
import TagsList from "../../../../global/components/tag/TagsList";

function ProjectTable({phase, indicatorColumns, setProjectCount}) {
    const {t} = useTranslation();
    const {isDesktop} = useSystemBreakpoint()

    const {
        tableData, tableParams, handleTableChange, isLoading
    } = useTable({
        url: `/project_management/table/project_table` + (phase ? `/${phase?.order}` : ''),
        hasPagination: true,
    })

    const groupColumns = !phase ? [
        {
            title: t("Phase"),
            dataIndex: "current_phase",
            render: renderBadge,
        },
    ] : [];

    indicatorColumns.forEach((indicatorColumn) => {
        indicatorColumn.title = t(indicatorColumn.title);
        indicatorColumn.align = "center";
        indicatorColumn.dataIndex = indicatorColumn.data_index;
        indicatorColumn.render = renderIndicator;
    })

    const columns = [
        {
            title: t("Project"),
            dataIndex: "name",
            fixed: isDesktop && "left",
            width: "300px",
            render: (_, record) => {
                return <Space size="middle"><Link
                    to={`/project-portfolio/project/${record.id}/dashboard`}
                    data-cy={`project-row-${record.id}`}>{record.name}</Link></Space>
            }
        },
        ...groupColumns,
        {
            title: t('Tags'),
            dataIndex: 'tags_display',
            key: 'tags_display',
            render: (tags) => <TagsList isCustom tags={tags} maxSize={2}/>
        },
        {
            title: t("Start Date"),
            dataIndex: "date_start",
            render: renderDate
        },
        {
            title: t("End Date"),
            dataIndex: "date_end",
            render: renderDate
        },
        ...indicatorColumns,
        {
            title: "Stakeholders",
            dataIndex: "stakeholders",
            width: "200px",
            render: renderAvatars,
        },
        {
            title: "Checkpoints",
            dataIndex: "status_report_count",
            width: "100px",
        },
    ];

    useEffect(() => {
        tableParams && setProjectCount(tableParams.pagination?.total)
    }, [tableParams]);

    return (<Table
        columns={columns}
        dataSource={tableData}
        tableParams={tableParams}
        onChange={handleTableChange}
        loading={isLoading}
        data-cy="projects-table"
    />);
}


export default ProjectTable;