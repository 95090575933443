import React, {useEffect, useMemo} from 'react';
import {useTranslation} from "react-i18next";
import {Form, Switch, Typography} from "antd";
import {
    CHOICES_APPLICATION_NAME_TYPE,
    CHOICES_PROGRAM_DURATION,
    CHOICES_PROGRAM_STATUS,
    CHOICES_PROGRAM_TYPE,
    OPEN_INNOVATION, PROGRAM_TAG
} from "../../../../../../global/utils/Choices";
import useOptionsTranslation from "../../../../../../global/hooks/useOptionsTranslation";
import FormLabelHelp from "../../../../../../global/components/misc/FormLabelHelp";
import TransparentCollapse from "../../../../../../global/components/misc/TransparentCollapse";
import CollapseFormItem from "../../../../../../global/components/data_entry/CollapseFormItem";
import Select from "../../../../../../global/components/data_entry/Select";
import WideSpace from "../../../../../../global/components/layout/WideSpace";
import ClipboardLink from "../../../../../../global/components/link/ClipboardLink";
import {Link} from "react-router-dom";
import AttachingTags
    from "../../../../../../global/components/tag/AttachingTags";

const {Text} = Typography


export default function GeneralSettingsCollapse({form, FORM_NAMES, program, hasApplication, setHelpDrawerOpen}) {
    const {t} = useTranslation();
    const {translationOptions} = useOptionsTranslation();
    const programType = Form.useWatch(FORM_NAMES.PROGRAM_TYPE, form)

    const simpleInitiativeLink = program?.id ?
                           `${document.location.origin}/simple_initiative_registration/${program?.slug}`
                            : t("http:// address will be displayed below after saving the program")
    const EMBED = `<iframe  width="343" height="470" src="${simpleInitiativeLink}"/>`


    const APPLICATION_NAME_CHOICE_OPTIONS = useMemo(() => {
        // mudar as opções do Application_name de acordo com o Application_Type
        let options = CHOICES_APPLICATION_NAME_TYPE[programType]
        if (options) return options
        return CHOICES_APPLICATION_NAME_TYPE['default']
    }, [programType]);


    async function handleOnChangeProgramType(value) {
        // reseta o valor de Application_name de acordo com as opções de Application_Type
        const options = CHOICES_APPLICATION_NAME_TYPE[value] ? CHOICES_APPLICATION_NAME_TYPE[value] : CHOICES_APPLICATION_NAME_TYPE.default
        const valueApplicationName = await form.getFieldValue(FORM_NAMES.APPLICATION_NAME_CHOICE)
        const optionsHasValue = options.some((item) => item.value === valueApplicationName)

        if (!optionsHasValue) {
            form.setFieldValue(FORM_NAMES.APPLICATION_NAME_CHOICE, options[0].value)
        }
    }

    useEffect(() => {
        programType && void handleOnChangeProgramType(programType)
    }, [programType])

    return (
        <TransparentCollapse header={t("General")}>
            <WideSpace direction="vertical" size="middle">

                <CollapseFormItem
                    input={<Select options={translationOptions(CHOICES_PROGRAM_TYPE)}
                                   disabled={hasApplication}
                                   data-cy="program-type-select"/>}
                    name={FORM_NAMES.PROGRAM_TYPE}
                    label={<FormLabelHelp label="Program Type" setHelpDrawerOpen={setHelpDrawerOpen}/>}
                    help={t("Determines the focus of this program and permitted guidelines.")}
                    initialOpen={true}
                    renderContent={
                        () => (
                            <>
                                <CollapseFormItem name={FORM_NAMES.APPLICATION_NAME_CHOICE}
                                                  label={t("Name of the Registration Item")}
                                                  help={t("Select what the user will see at the time of submission.")}
                                                  input={<Select
                                                      options={translationOptions(APPLICATION_NAME_CHOICE_OPTIONS)}
                                                      disabled={hasApplication && programType === OPEN_INNOVATION}
                                                      data-cy="application-name-select"
                                                  />}
                                />
                            </>
                        )
                    }
                />

                <CollapseFormItem
                    input={<Select options={translationOptions(CHOICES_PROGRAM_STATUS)}
                                   data-cy="program-status-select"/>}
                    name={FORM_NAMES.PROGRAM_STATUS}
                    label={<FormLabelHelp label="Program Status" setHelpDrawerOpen={setHelpDrawerOpen}/>}
                    help={t("The Program only appears at the base of the environment and externally when it is active.")}
                />

                <CollapseFormItem
                    input={<Select options={translationOptions(CHOICES_PROGRAM_DURATION)} disabled={hasApplication}
                                   data-cy="program-duration-select"/>}
                    name={FORM_NAMES.DURATION_TYPE}
                    label={<FormLabelHelp label="Duration Type" setHelpDrawerOpen={setHelpDrawerOpen}/>}
                    help={t("Programs can have continuous stages or with a determined completion period.")}
                />

                <CollapseFormItem
                    input={<Switch data-cy="public-link-switch" disabled={hasApplication}/>}
                    name={FORM_NAMES.ALLOW_SIMPLE_INITIATIVE}
                    label={
                        <FormLabelHelp label="Allow Registration without login and authentication"
                                       setHelpDrawerOpen={setHelpDrawerOpen}/>
                    }
                    help={t("Allows people not registered with SOLV to register for the program through the public link.")}
                    extraFormItemProps={{valuePropName:"checked"}}
                    renderContent={(value) =>
                        <>

                            <CollapseFormItem
                                input={
                                    program?.id &&
                                        <ClipboardLink link={simpleInitiativeLink} embed={EMBED} hideLink={true} disabled={!value}/>
                                }
                                label={"Link"}
                                help={
                                    !value?
                                        <Text type="secondary" disabled={true}>{simpleInitiativeLink}</Text> :
                                        <a href={simpleInitiativeLink}>{simpleInitiativeLink}</a>
                                }
                            />


                            {/*sempre desabilitado*/}
                            <CollapseFormItem input={<Switch disabled={true}/>} name={FORM_NAMES.NAME_REQUIRED}
                                              label={t("Name Requirement")}
                                              help={t("Requires that the name be provided.")}
                                              extraFormItemProps={{valuePropName:"checked"}}
                            />
                            <CollapseFormItem input={<Switch disabled={!value || hasApplication}/>} name={FORM_NAMES.PHONE_REQUIRED}
                                              label={t("Phone Requirement")}
                                              help={t("Requires that the phone be provided.")}
                                              extraFormItemProps={{valuePropName:"checked"}}
                            />
                            <CollapseFormItem input={<Switch disabled={!value || hasApplication}/>} name={FORM_NAMES.EMAIL_REQUIRED}
                                              label={t("E-mail Requirement")}
                                              help={t("Requires that the E-mail be provided.")}
                                              extraFormItemProps={{valuePropName:"checked"}}
                            />


                        </>
                    }
                />

                {program?.id &&  <AttachingTags target={PROGRAM_TAG} id={program?.id}/>}
            </WideSpace>

        </TransparentCollapse>
    )
}


