import React from 'react'
import styled from 'styled-components'
import {Space, Typography} from "antd";

import {CheckCircleOutlined} from '@ant-design/icons';
import {
    FlexContainer,
    GrayContainer
} from "../../authentication/StyledComponents";
import UserAvatar from "../../misc/UserAvatar";
import {useUserContext} from "../../../context/UserContext";





export default function PerfilMessage({data, name}) {
    const {_user} = useUserContext()

    return (
                <Space align={'center'} size={8}>

                    <UserAvatar style={{height: 24, width: 24}} data={data}/>
                    <Typography.Title level={5}
                                      style={{marginBottom: 0}}>{name}</Typography.Title>
                </Space>



    );
}