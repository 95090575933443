import React, {useEffect, useState} from 'react';
import api from "../../../global/services/api";
import FormSelect from "../../Form/FormSelect/Index";
import {FilterTitle} from "../../Filters/StyledFilters";
import {BuildFieldErrorsDict} from "../../../global/utils/Utils";
import {useTranslation} from "react-i18next";


export default function ApplicationsHistorySectorsFilters({resetFilters, handleSubmitForm}) {
    const {t} = useTranslation();
    //FILTER SECTORS
    const [sectorsSizeChoices, setSectorsSizeChoices] = useState([])
    const [sectorsClientChoices, setSectorsClientChoices] = useState([])
    const [sectorsTechnologyChoices, setSectorsTechnologyChoices] = useState([])
    const [sectorsPersonaChoices, setSectorsPersonaChoices] = useState([])
    const [sectorsTransactionChoices, setSectorsTransactionChoices] = useState([])
    const [sectorsInstitutionChoices, setSectorsInstitutionChoices] = useState([])
    const [type, setType] = useState([{
        value: 'Team',
        label: t('Team')
    }, {value: 'Company', label: t('Company')}, {value: 'Individual', label: t('Individual')}])

    const fetchSectorsInstitutionChoices = async () => {
        try {
            const response = await api.get("/sectors_institution");
            let aux = []
            response.data.map((option) => {
                aux.push({value: option.id, label: t(option.name)})
            })
            setSectorsInstitutionChoices(aux)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        }
    };

    const fetchSectorsTransactionChoices = async () => {
        try {
            const response = await api.get("/sectors_transaction");
            let aux = []
            response.data.map((option) => {
                aux.push({value: option.id, label: t(option.name)})
            })
            setSectorsTransactionChoices(aux)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        }
    };

    const fetchSectorsPersonaChoices = async () => {
        try {
            const response = await api.get("/sectors_persona");
            let aux = []
            response.data.map((option) => {
                aux.push({value: option.id, label: t(option.name)})
            })
            setSectorsPersonaChoices(aux)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        }
    };

    const fetchSectorsTechnologyChoices = async () => {
        try {
            const response = await api.get("/sectors_technology");
            let aux = []
            response.data.map((option) => {
                aux.push({value: option.id, label: t(option.name)})
            })
            setSectorsTechnologyChoices(aux)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        }
    };

    const fetchSectorsClientChoices = async () => {
        try {
            const response = await api.get("/sectors_client");
            let aux = []
            response.data.map((option) => {
                aux.push({value: option.id, label: t(option.name)})
            })
            setSectorsClientChoices(aux)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        }
    };

    const fetchSectorsSizeChoices = async () => {
        try {
            const response = await api.get("/sectors_size");
            let aux = []
            response.data.map((option) => {
                aux.push({value: option.id, label: t(option.name)})
            })
            setSectorsSizeChoices(aux)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        }
    };

    useEffect(() => {
        fetchSectorsClientChoices()
        fetchSectorsInstitutionChoices()
        fetchSectorsPersonaChoices()
        fetchSectorsTransactionChoices()
        fetchSectorsSizeChoices()
        fetchSectorsTechnologyChoices()
    }, []);

    const onChangeSelect = () => {
        setTimeout(handleSubmitForm, 1)
    }

    return (
        <>
            {resetFilters === false &&
                <>
                    <FilterTitle>{t("Companies & teams filter")}</FilterTitle>
                    <FormSelect options={type}
                                onChange={onChangeSelect}
                                label={t('Application Type')}
                                clearable={true}
                                name={'application__team__type'}/>
                    <FormSelect options={sectorsSizeChoices} onChange={onChangeSelect}
                                label={t("Size")} multiple={true}
                                name={'application__team__sectors_size__id__in'}/>
                    <FormSelect options={sectorsInstitutionChoices} onChange={onChangeSelect}
                                label={t("Company sectors")} multiple={true}
                                name={'application__team__sectors_institution__id__in'}/>
                    <FormSelect options={sectorsPersonaChoices} onChange={onChangeSelect}
                                label={t("Company personas")} multiple={true}
                                name={'application__team__sectors_persona__id__in'}/>
                    <FormSelect options={sectorsTechnologyChoices} onChange={onChangeSelect}
                                label={t("Technologies")} multiple={true}
                                name={'application__team__sectors_technology__id__in'}/>
                    <FormSelect options={sectorsTransactionChoices} onChange={onChangeSelect}
                                label={t("Transactions type")} multiple={true}
                                name={'application__team__sectors_transaction__id__in'}/>
                    <FormSelect options={sectorsClientChoices} onChange={onChangeSelect}
                                label={t("Client sectors")} multiple={true}
                                name={'application__team__sectors_client__id__in'}/>
                </>}
        </>
    )
}