import React, {Suspense, useEffect} from 'react';
import './App.css';
import RoutesGlobal from './global/routes/RoutesGlobal.js'
import ModalMessage from "./components/ModalMessage/ModalMessage";
import {BrowserRouter} from "react-router-dom";
import 'react-rangeslider/lib/index.css'
import AppProvider from "./global/context/_compose";
import CookieNotification from "./global/components/authentication/CookieNotification";
import {ConfigProvider, theme} from "antd";
import {getLanguage} from "./global/utils/translation";
import ptBR from "antd/es/locale/pt_BR";
import enUS from "antd/es/locale/en_US";

export default function App() {
    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'ResizeObserver loop completed with undelivered notifications.') {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div',
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay',
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, [])

    const currentLocale = getLanguage() === "pt-br" ? ptBR : enUS;

    // solução provisória

    const colorPrimary = "var(--primary_btn_color)"

    const _theme = {
        // algorithm: theme.darkAlgorithm,
        token: {
            colorPrimaryHover: "var(--primary_btn_color_hover)",
        },
        components: {
            DatePicker: {
                colorPrimary,
                activeBorderColor: "var(--primary_btn_color)",
                hoverBorderColor: "var(--primary_btn_color_hover)",
                controlItemBgActive: "var(--primary_btn_color_light)"
            },
            Slider: {
                handleColor: "var(--primary_btn_color)",
                handleActiveColor: "var(--primary_btn_color_hover)",
                colorPrimaryBorderHover: "var(--primary_btn_color_hover)",
                trackBg: "var(--primary_btn_color)",
                dotActiveBorderColor: "var(--primary_btn_color)",
                trackHoverBg: "var(--primary_btn_color_hover)",
            },
            Input: {
                colorPrimary,
                activeBorderColor: "var(--primary_btn_color)",
                hoverBorderColor: "var(--primary_btn_color)"
            },
            Radio: {
                colorPrimary,
                activeBorderColor: "var(--primary_btn_color)",
                hoverBorderColor: "var(--primary_btn_color)"
            },
            Select: {
                colorPrimary,
                activeBorderColor: "var(--primary_btn_color)",
                hoverBorderColor: "var(--primary_btn_color)"
            },
            Button: {
                colorPrimary,
                primaryColor: "var(--primary_btn_text_color)",
            },
            Checkbox: {
                colorPrimary,
                primaryColor: "var(--primary_btn_text_color)",
            },
            Switch: {colorPrimary},
            Table: {colorPrimary},
            Menu: {colorPrimary},
            Tabs: {colorPrimary},
            Spin: {colorPrimary},
        }
    }

    return (
        <ConfigProvider locale={currentLocale} theme={_theme}>
            <Suspense fallback="Traduzindo">
                <BrowserRouter getUserConfirmation={() => {/* Empty callback to block the default browser prompt */
                }}>
                    <AppProvider>
                        {/*<Routes/>*/}
                        <RoutesGlobal/>
                        <ModalMessage/>
                        <CookieNotification/>
                    </AppProvider>
                </BrowserRouter>
            </Suspense>
        </ConfigProvider>
    );
}