import React, { useEffect, useState } from "react";
import api from "../../global/services/api";
import {getAllFilters, BuildFieldErrorsDict} from "../../global/utils/Utils";
import CircleImage from "../CircleImage";
import { useSortBy, useTable } from "react-table";
import Loading from "../../global/components/feedback/Loading";
import {
    ButtonPagination,
    ActionsButtonsContainer,
    ContainerTable,
    ActionButton,
    IconOrderHeader,
    ImagesContainer,
    LabelPagination,
    MainContainer,
    PaginationContainer,
    StyledTable,
    StyledTd,
    StyledTh,
    StyledTrBody,
    StyledTrHeader,
    TableHeader,
    TableSubHeader,
    CSVLinkHidden,
    FilterIconStyled,
    CenterTableContainer,
} from "./StyleTable";
import { ReactComponent as OrderUpIcon } from "../../assets/Icon/orderUpIcon.svg";
import { ReactComponent as ExportIcon } from "../../assets/Icon/export_program.svg";
import { ReactComponent as SumIcon } from "../../assets/Icon/more.svg";
import { ReactComponent as OrderBottomIcon } from "../../assets/Icon/orderBottomIcon.svg";
import { ReactComponent as OrderTwinIcon } from "../../assets/Icon/orderTwinIcon.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import DraggableOrdenation from "../DraggableComponents/DraggableOrdenation";
import { useTranslation } from "react-i18next";
import DragIcon from "../../assets/Icon/drag.svg";
import CheckBox from "../Form/CheckBox";
import styled from "styled-components";
import NotContent from "../NotContent/NotContent";
import FilterButtons from "../Filters/FilterButtons";
import OffCanvasComponent from "../Offcanvas";
import useExportTable from "./hooks/useExportTable";
import useFilterTable from "./hooks/useFilterTable";
import moment from "moment";
import SelectCountList from "../Pagination/SelectCountList/SelectCountList";
import Label from "../Form/Label";
import {Tooltip} from "antd";
import useEventListener from './../../global/hooks/useEventListener';


const StyleDragImg = styled.img`
    width: 22px;
    margin-right: 2px;

    &:hover {
        cursor: grabbing;
    }
`;

const StyledTextContainer = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const StyledCustomLabel = styled(Label)`
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    line-height: 21.09px;
    color: #949494;
`;

export default function Table({
    columns,
    url,
    filterComponents,
    titleFilter,
    hasExport,
    refresh,
    hasManageColumns,
    hasPagination,
    pageSize,
    hasChecked,
    idTable,
    searchValue,
    onRowClick,
    exportFileName,
    actionColumnCell,
    setSelecteds,
    topSpace,
    refreshTable,
    isExternalLoading,
    extraFilters,
}) {
    const { t } = useTranslation();
    const hasQueryParams = url?.search(/\?.+=/) != -1;
    const querySeparation = hasQueryParams ? "&" : "?";
    const [data, setData] = useState([]);
    const [checkedAll, setCheckedAll] = useState(false);
    const [checkeds, setCheckeds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasPrev, setHasPrev] = useState(false);
    const [showColumns, setShowColumns] = useState(false);
    const [hasNext, setHasNext] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [order, setOrder] = useState([]);
    const [columnsDefault, setColumnsDefault] = useState(columns ? columns : []);
    const [columnsTable, setColumnsTable] = useState([]);
    const [lastSearch, setLastSearch] = useState(null);
    const [idTimeoutSearch, setIdTimeoutSearch] = useState();

    const {
        filterData,
        handleClearFilters,
        formFilter,
        showActionFilters,
        buildFilterInfo,
        showingFilters,
        hideFilters,
        showFilters,
        resetFilter,
        showClearFilters,
    } = useFilterTable();

    const params = new URLSearchParams({
        order: order,
        search: searchValue
    }).toString();

    const paginationParams = new URLSearchParams({
        page: currentPage,
        page_size: pageSize
    }).toString();

    const { exportRef, data_csv, handleExport } = useExportTable({
        setIsLoading,
        isLoading,
        url: `${url}${querySeparation}${params}`,
        columnsTable,
        filterData: filterData && filterData,
    });

    'useEventListener(`export-table-data`, handleExport);'

    useEffect(() => {
        setIsLoading(isExternalLoading);
    }, [isExternalLoading]);

    function renderTypeColumn(column) {
        if (column.type === "list") {
            column["Cell"] = ({ value: values, row }) => {
                const list_str = values?.reduce((prev, current, index) => {
                    return `${prev}, ${current}`;
                });
                return list_str ? list_str : "";
            };
        }

        if (column.type === "img_circle") {
            column["Cell"] = ({ value, row }) => {
                return (
                    <CircleImage
                        src={value}
                        program
                        width="40"
                        // onClick={() => column.onClick(row)}
                    />
                );
            };
        }
        if (column.type === "date") {
            column["Cell"] = ({ value, row }) => {
                if (!value) return "";
                return <span title={moment(value).tz("UTC").format("DD/MM/YYYY")}>{moment(value).tz("UTC").format("DD/MM/YYYY")}</span>;
            };
        }
        if (column.type === "image_list") {
            column["Cell"] = ({ value, row }) => {
                return <ImageListTable images={value} onClick={column.onClick} />;
            };
        }

        // if (column.type === 'image_list') {
        //     column['Cell'] = ({value, row}) => {
        //         return <CheckBox options={[{
        //             id: row.id,
        //
        //         }]}/>
        //     }
        // }

        // if (column.type === 'img') {
        //     column['Cell'] = ({value, row}) => {
        //         return <CircleImage src={value} program width="40"/>
        //     }
        // }
        //     if (column.type === 'image_list') {
        //         column['Cell'] = ({value, row}) => {
        //             return <ImageListTable images={value}
        //                                    onClick={column.onClick}/>
        //         }
        //     }
        //     // if (column.type === 'image_list') {
        //     //     column['Cell'] = ({value, row}) => {
        //     //         return <CheckBox options={[{
        //     //             id: row.id,
        //     //
        //     //         }]}/>
        //     //     }
        //     // }
        //     return column
    }

    useEffect(() => {
        setColumnsTable(columnsDefault.filter(({ hidden }) => !hidden));

    }, [columnsDefault]);

    useEffect(() => {
        setColumnsDefault(
            columns.map((column, index) => {
                renderTypeColumn(column);
                column["id"] = column.accessor;
                column["hidden"] = columnsDefault.find(({accessor})=>  accessor === column.accessor)?.hidden;

                return column;
            })
        );
    }, [columns]);

    function onSelectRow({ target }, object) {
        if (target.checked) {
            setCheckeds([...checkeds, object]);
        } else {
            setCheckeds((checkeds) => {
                return checkeds.filter((item) => object.id !== item.id);
            });
            setCheckedAll(false);
        }
    }

    useEffect(() => {
        setCheckedAll(checkedAll);
        if (checkedAll) {
            setCheckeds(data.map((row) => row));
        } else {
            setCheckeds([]);
        }
    }, [checkedAll]);

    const getData = async () => {
        try {
            setCheckedAll(false);
            setCheckeds([]);
            setIsLoading(true);
            const allFilters = getAllFilters(filterData, extraFilters);
            if (hasPagination) {
                const response = await api.post(
                    `${url}${querySeparation}${params}&${paginationParams}`,
                    allFilters
                );
                setData(response.data.results ? response.data.results : []);
                setHasPrev(!!response.data.previous);
                setHasNext(!!response.data.next);
                setTotalRows(response.data.count);
                setTotalPages(Math.ceil(response.data.count / pageSize));
            } else {
                const response = await api.post(
                    `${url}${querySeparation}${params}`,
                    allFilters
                );
                setData(response.data ? response.data : []);
                setTotalRows(response.data.length);
            }
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        } finally {
            setIsLoading(false);
        }
    };

    function fetchData() {
        if (extraFilters){
            void getData();
            return;
        }
        if (searchValue !== lastSearch) {
            if (idTimeoutSearch) {
                clearTimeout(idTimeoutSearch);
                setIdTimeoutSearch(null);
            }
            setCurrentPage(1);
            setLastSearch(searchValue);
        } else {
            if (searchValue && currentPage === 1) {
                setIsLoading(true);

                setIdTimeoutSearch(
                    setTimeout(() => {
                        getData();
                    }, 800)
                );
            } else {
                getData();
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, [currentPage, order, searchValue, lastSearch, refresh, extraFilters]);

    useEffect(() => {
        if (currentPage === 1) {
            fetchData();
        } else {
            setCurrentPage(1);
        }
    }, [filterData, url]);

    const handleChange = (currentItem) => {
        if (!currentItem.fixed) {
            setIsLoading(true);
            setColumnsDefault(
                columnsDefault.map((column) => {
                    if (currentItem.id === column.id) column.hidden = !column.hidden;
                    return column;
                })
            );
            setTimeout(() => {
                setIsLoading(false);
            }, 200);
        }
    };

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        state: { sortBy },
    } = useTable(
        {
            columns: columnsTable,
            data,
            manualSortBy: hasPagination,
        },
        useSortBy
    );

    useEffect(() => {
        if (hasPagination) {
            if (sortBy.length > 0) {
                const order = columnsDefault.find((c) => c.accessor === sortBy[0].id && c.order_by);

                if (order) {
                    let current_order = `${sortBy[0].desc ? "-" : ""}${order.order_by}`;

                    if (current_order !== order) {
                        setOrder(current_order);
                    }
                }
            }
        }
    }, [sortBy]);

    useEffect(() => {
        setSelecteds && setSelecteds(checkeds);
    }, [checkeds]);

    const renderCheck = (item) => {
        return (
            <div style={{ display: "flex" }}>
                <StyleDragImg src={DragIcon} />
                {!item.fixed ? (
                    <CheckBox
                        id={`check-${item.accessor}`}
                        onChange={() => handleChange(item)}
                        options={[
                            {
                                id: `check-${item.accessor}`,
                                label: t(item.Header),
                                checked: !item.hidden,
                            },
                        ]}
                    />
                ) : (
                    <StyledCustomLabel>{t(item.Header)}</StyledCustomLabel>
                )}
            </div>
        );
    };

    function calculateShowing() {
        if (hasPagination) {
            if (!totalRows) {
                return `0 - 0 ${t("of")} 0 ${t("Entries")}`;
            }
            if (currentPage === totalPages) {
                return `${totalRows - rows.length + 1} - ${totalRows} ${t("of")} ${totalRows} Entries`;
            }
            return `${rows.length * (currentPage - 1) + 1} - ${rows.length * currentPage} ${t("of")} ${totalRows} ${t("Entries")}`;
        } else {
            return `${totalRows} ${t("Entries")}`;
        }
    }

    function changeObject(id, newObject) {
        const newData = data.map((item) => {
            if (id === item.id) {
                return newObject;
            }
            return item;
        });
        setCheckeds((values) => {
            //isso atualiza o state checkeds caso os dados das rows sejam alterados
            const newValues = values.map((checked) => {
                const obj = newData.find((item) => item.id === checked.id);
                if (obj) return obj;
                return checked;
            });
            return newValues;
        });

        setData(newData);
    }

    function removeObject(id) {
        //todo quando tira todas as colunas esta ficando com a tabela vazia, mesmo tendo action
        const newData = data.filter((item) => item.id !== id);
        setData(newData);
        setTotalRows((total) => total - 1);
    }

    function calculate_caracteres(value){
        const amount_caracteres = 16
        if(value?.length > amount_caracteres){
            return `${value.substring(0, amount_caracteres)}...`
        }
        return value
    }

    return (
        <>
            {checkeds.length > 0 && <SelectCountList count_selected={checkeds.length} />}
            <MainContainer>
                <ContainerTable topSpace={topSpace}>
                    <StyledTable {...getTableProps()}>
                        <thead>
                            {headerGroups.map((headerGroup) => {
                                return <StyledTrHeader {...headerGroup.getHeaderGroupProps()}>
                                    {hasChecked && (
                                        <StyledTh fixed={true} isChecked={true}>
                                            <CheckBox
                                                id={`check-all-${idTable}`}
                                                onChange={() => setCheckedAll(!checkedAll)}
                                                options={[
                                                    {
                                                        id: `check-all-${idTable}`,
                                                        label: "",
                                                        checked: checkedAll,
                                                    },
                                                ]}
                                            />
                                        </StyledTh>
                                    )}
                                    {headerGroup.headers.map((column) => (
                                        <StyledTh
                                            hasChecked={hasChecked}SSSSS
                                            hasActionClick={!hasPagination || column.order_by}
                                            fixed={column.fixed}
                                            {...column.getHeaderProps(column.getSortByToggleProps())}
                                            title={column.tooltip ? t(column.tooltip): t(column.render("Header"))}
                                        >
                                            <StyledTextContainer style={{maxWidth: 150, ...column.columnStyle}}>
                                                <TableSubHeader>{column.Sub_Header && t(column.Sub_Header)}</TableSubHeader>
                                                <TableHeader>
                                                    {t(column.render("Header"))}
                                                </TableHeader>
                                            </StyledTextContainer>
                                            {(!hasPagination || column.order_by) && <IconOrder column={column} />}
                                        </StyledTh>
                                        // </Tooltip>
                                    ))}

                                    {!!actionColumnCell && (
                                        <StyledTh fixed={true} hasAction={true}>
                                            <TableSubHeader></TableSubHeader>
                                            <TableHeader> {t("Action")}</TableHeader>
                                        </StyledTh>
                                    )}
                                </StyledTrHeader>
                            })}
                        </thead>
                        {isLoading ? (
                            <CenterTableContainer>
                                <Loading />
                            </CenterTableContainer>
                        ) : (
                            <tbody {...getTableBodyProps()}>
                                {data.length > 0 ? (
                                    rows.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <StyledTrBody hasRowClick={!!onRowClick} {...row.getRowProps()} data-cy={`table-tr-${row.original.id}`}>
                                                {hasChecked && (
                                                    <StyledTd fixed={true} isChecked={true}>
                                                        <CheckBox
                                                            id={`check-${idTable}-${row.original.id}`}
                                                            onChange={(event) => onSelectRow(event, row.original)}
                                                            options={[
                                                                {
                                                                    id: `check-${idTable}-${row.original.id}`,
                                                                    label: "",
                                                                    checked: checkeds.find((object) => object.id === row.original.id),
                                                                },
                                                            ]}
                                                        />
                                                    </StyledTd>
                                                )}
                                                {row.cells.map((cell) => {
                                                    return (
                                                        <StyledTd
                                                            onClick={() =>
                                                                onRowClick &&
                                                                onRowClick({
                                                                    object: row.original,
                                                                    rowTable: row,
                                                                })
                                                            }
                                                            hasChecked={hasChecked}
                                                            title={!cell.column.no_title && cell.value}
                                                            fixed={cell.column.fixed}
                                                            {...cell.getCellProps()}
                                                        >
                                                            <StyledTextContainer style={{width: 150, ...cell.column.columnStyle}}>{cell.render("Cell")}</StyledTextContainer>
                                                        </StyledTd>
                                                    );
                                                })}

                                                {!!actionColumnCell && (
                                                    <StyledTd fixed={true} hasAction={true}>
                                                        {actionColumnCell({
                                                            refreshTable,
                                                            object: row.original,
                                                            rowTable: row,
                                                            actionsRow: {
                                                                changeObject,
                                                                removeObject,
                                                            },
                                                        })}{" "}
                                                    </StyledTd>
                                                )}
                                            </StyledTrBody>
                                        );
                                    })
                                ) : (
                                    <CenterTableContainer>
                                        <NotContent title={t("Don't have items to show yet.")} />
                                    </CenterTableContainer>
                                )}
                            </tbody>
                        )}
                    </StyledTable>
                </ContainerTable>
                <PaginationContainer>
                    <LabelPagination>{calculateShowing()}</LabelPagination>
                    {hasPagination && (
                        <div>
                            <ButtonPagination
                                disabled={!hasPrev}
                                onClick={() => {
                                    setCurrentPage(currentPage - 1);
                                }}
                            >
                                {"<"}
                            </ButtonPagination>
                            <LabelPagination>
                                {data.length > 0 ? currentPage : 0} of {totalPages}
                            </LabelPagination>

                            <ButtonPagination
                                disabled={!hasNext}
                                onClick={() => {
                                    setCurrentPage(currentPage + 1);
                                }}
                            >
                                {">"}
                            </ButtonPagination>
                        </div>
                    )}
                </PaginationContainer>
                <ActionsButtonsContainer>
                    {hasExport && (
                        <CSVLinkHidden
                            ref={exportRef}
                            className="SCVlink"
                            filename={exportFileName ? `${exportFileName}.csv` : "export_data.csv"}
                            data={data_csv}
                            separator={";"}
                        />
                    )}
                    {/* {hasExport && (
                        <ActionButton title={t("Export")} onClick={handleExport}>
                            <ExportIcon />
                        </ActionButton>
                    )} */}

                    {hasManageColumns && (
                        <ActionButton title={t("Column display")} onClick={() => setShowColumns(true)}>
                            <SumIcon />
                        </ActionButton>
                    )}
                    {!!filterComponents && (
                        <ActionButton title={t("Filter")} onClick={showFilters}>
                            <FilterIconStyled isApplied={!!filterData} />
                        </ActionButton>
                    )}
                </ActionsButtonsContainer>

                <Offcanvas show={showColumns} onHide={() => setShowColumns(false)} placement={"end"}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>{t("Columns displayed")}</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <DraggableOrdenation
                            id={"column-ordenation"}
                            draggableItens={columnsDefault}
                            setDraggableItens={setColumnsDefault}
                            renderCard={renderCheck}
                            handleUpdateSequence={() => {}}
                        />
                    </Offcanvas.Body>
                </Offcanvas>

                <OffCanvasComponent show={showingFilters} handleClose={hideFilters} isRight={true} title={t(titleFilter)}>
                    <form ref={formFilter} onChange={showActionFilters}>
                        <FilterButtons showClearFilters={true} handleClearFilters={handleClearFilters} handleApplyFilters={buildFilterInfo} />

                        {!resetFilter && filterComponents}
                    </form>
                </OffCanvasComponent>
            </MainContainer>
        </>
    );
}

function IconOrder({ column }) {
    return <IconOrderHeader>{column.isSorted ? column.isSortedDesc ? <OrderBottomIcon /> : <OrderUpIcon /> : <OrderTwinIcon />}</IconOrderHeader>;
}

function ImageListTable({ images, onClick }) {
    return (
        <ImagesContainer style={{ "--childSize": images.length }}>
            {images &&
                images.map((obj, index) => (
                    <CircleImage onClick={() => onClick(obj)} style={{ "--indexImage": index }} src={obj.url} program width="40" height={"40"} />
                ))}
        </ImagesContainer>
    );
}
