import { Typography } from 'antd';
import CardButton from './../../../../../../button/CardButton';
import { ArrowRightOutlined, PlusSquareOutlined } from '@ant-design/icons';
import TruncateText from '../../../../../../responsive/TruncateText';
import styled from 'styled-components';

const { Text } = Typography;

const StyledCardButton = styled(CardButton)`
  border-color: ${props => props.$color || 'initial'};
  /* *{ */
  &:not(svg):not(.ant-badge) {
    color: ${props => props.$color || 'initial'};
  }
`;

export default function ApplicationActionCardButton({ text, help, onClick, color, icon=<PlusSquareOutlined/>, sufixIcon=<ArrowRightOutlined />, ...props }) {
    return (
        <StyledCardButton $color={color}
            text={<>
                <Text style={{
                    fontSize: 16,
                    fontWeight: 700
                }}>{text}</Text>
                {help && <TruncateText>
                    <Text style={{
                        fontSize: 14,
                        fontWeight: 400
                    }}>{help}</Text>
                </TruncateText>}
            </>}
            iconStyle={{ fontSize: 16 }}
            sufixIcon={sufixIcon}
            icon={icon}
            onClick={onClick}
            bodyStyle={{
                padding: 16
            }}
            {...props}
        />
    );
}