import React, {useEffect, useState} from "react";
import DefaultComponent from "./DefaultComponent";
import RadioButton from "../../../Form/RadioButton";

export default function SectionRadioForm(props) {


    return (
        <>

        <DefaultComponent {...props} />
            </>
    )
}

