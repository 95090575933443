import styled from "styled-components";
import {FlexContainer} from "../authentication/StyledComponents";
import {Col, Row, Space, Typography} from "antd";
import {blue_6} from "../../utils/Colors";
import {useTranslation} from "react-i18next";
import copy from 'copy-to-clipboard';
import {ToastNotification} from "../feedback/ToastNotification";
import RemixIcon from "../data_display/RemixIcon";
import React from "react";
import ClipboardButton from "../button/ClipboardButton";

const Title = styled(Typography.Text)`
    font-size: 14px;
    line-height: 14px;

`
const Link = styled.a`
    font-weight: 500;
    font-size: 12px;
    color: ${blue_6};
    line-height: 22px;
    overflow-wrap: break-word;
    word-break: break-word;
    display: block;
`

export default function ClipboardLink({title, link, embed, hideLink, disabled}) {
    const {t} = useTranslation()

    function handleCopy(link) {
        copy(link)
        ToastNotification(t('Copied'))
    }

    return <Row gutter={[16,8]}>
            <Col flex={'1'}>
                <FlexContainer style={{overflow: 'hidden'}} direction={'column'} align={'start'}>
                    {title && <Title>
                        {title}
                    </Title>}
                    <Link href={link} target={'_blank'}>
                        {!hideLink && link}
                    </Link>
                    {embed && 
                        <Space>
                            <ClipboardButton disabled={disabled} label={t('Embed')} text={embed} icon={ <RemixIcon remixIconName={"ri-code-s-slash-line"}/> } />
                            <ClipboardButton disabled={disabled} text={link}/>
                        </Space>}
                </FlexContainer>
            </Col>
        </Row>
}