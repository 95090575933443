import React, {useState} from "react";
import {
    useParams
} from "react-router-dom";
import {useHistory} from 'react-router-dom';
import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../utils/Utils";
import {useTranslation} from "react-i18next";
import LayoutLogin from "../../layouts/authentication/LayoutLogin";
import {Col, Row, Form as FormAntd} from "antd";
import Form from "../../components/data_entry/form/Form";
import {
    AuthButton,
    PassWordCheckList
} from "../../components/authentication/StyledComponents";
import {ToastNotification} from "../../components/feedback/ToastNotification";
import PasswordFormField from "../../components/data_entry/form/PasswordFormField";

export default function ResetPassword() {
    const {t} = useTranslation()
    const history = useHistory();
    const [form] = FormAntd.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [disabledButton, setDisabledButton] = useState(false);
    const [values, setValues] = useState({password: '', confirm_password: ''});

    const {uidb64, token} = useParams()


    const handleSubmit = async () => {

        try {
            const data = {
                'new_password': form.getFieldValue('password'),
                'uidb64': uidb64,
                'token': token,
            };
            setIsLoading(true)
            const response = await api.post('auth/reset_password/', data);
            ToastNotification(t('Successfully'))
            history.push('/login')
        } catch (error) {
            BuildFieldErrorsDict(error, false, false)
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <LayoutLogin>
            {/*{isLoading ? <Loading/> : (*/}
                <Form form={form}
                          onValuesChange={(changedValues, allValues) => {
                              setValues(allValues)
                          }}
                          requiredMark={false}
                          onFinish={handleSubmit}

                          style={{marginTop: 16}}>
                    <Row>
                        <Col xs={24}>
                            <PasswordFormField
                                type={'password'}
                                form={{
                                    label: t("New Password"),
                                    name: 'password',
                                    rules: [{
                                        required: true,
                                        message: t('Password is required')
                                    },
                                    ]
                                }}
                                placeholder={t('Password')}/>

                        </Col>
                        <Col xs={24}>
                            <PasswordFormField
                                type={'password'}
                                form={{
                                    label: t("Confirm Password"),
                                    name: 'confirm_password',
                                    rules: [{
                                        required: true,
                                        message: t('Confirm password is required')
                                    },
                                    ]
                                }}
                                placeholder={t('Password')}/>

                        </Col>
                        <Col xs={24}>
                            <PassWordCheckList
                                color={'var(--login_text_color_cover)'}
                                rules={["minLength", "specialChar", "number", "capital", 'lowercase', "match"]}
                                minLength={8}
                                iconSize={12}
                                value={values.password || ''}
                                valueAgain={values.confirm_password || ''}
                                onChange={(isValid) => {
                                    setDisabledButton(isValid)
                                }}
                                messages={{
                                    minLength: t("Password has more than 8 characters."),
                                    specialChar: t("Password has special characters."),
                                    number: t("Password has a number."),
                                    capital: t("Password has a capital letter."),
                                    lowercase: t("Password has a lowercase letter"),
                                    match: t("Passwords match."),
                                }}
                            />

                        </Col>


                        <Col xs={24} style={{marginTop: 24}}>
                                <AuthButton block
                                            htmlType="submit"
                                            disabled={!disabledButton}
                                            loading={isLoading}>{t('Access')}</AuthButton>
                        </Col>
                    </Row>

                </Form>
            {/*)}*/}
        </LayoutLogin>
    );
}