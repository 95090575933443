import React, {useEffect, useState} from "react";
import {Pie} from "@ant-design/plots";
import api from "../../../../../global/services/api";
import {
    BuildFieldErrorsDict,
    getPercent,
    getValueByValue
} from "../../../../../global/utils/Utils";
import LoadingFull from "../../../../../global/components/other/LoadingFull";
import Empty from "../../../../../global/components/data_display/Empty";
import {useTranslation} from "react-i18next";
import ChartLegendWrapper
    from "../../../../../global/components/data_display/ChartWrapper/ChartLegendWrapper";

const DEFAULT_CONFIG_REQUEST = {
    version: 'v1'
}

function DonutChart({
                        request,
                        angleField = "count",
                        colorField,
                        colorType,
                        expand
                    }) {
    const {t} = useTranslation();
    const [data, setData] = useState();
    const totalValue = data?.reduce((acc, item) => acc + item[angleField], 0);

    const config = {
        data,
        angleField: angleField,
        colorField: colorField,
        autoFit: true,
        innerRadius: .8,
        label: false,
        inset: 0,
        animate: null,
        interaction: {
            elementHighlightByColor: {
                link: true
            }
        },
        annotations: [{
            type: 'text',
            tooltip: false,
            style: {
                text: t("Participants"),
                x: '50%',
                y: '45%',
                textAlign: 'center',
                fontSize: 12,
                fontWeight: "400",
            },
        },
            {
                type: 'text',
                tooltip: false,
                style: {
                    text: `${data?.length}`,
                    x: '50%',
                    y: '55%',
                    textAlign: 'center',
                    fontSize: 32,
                    fontWeight: "800",
                },
            }],
        tooltip: {
            items: [
                (d) => ({
                    name: t(d[colorField]),
                    value: `${d.count}`,
                    color: colorType[d[colorField]]
                })
            ]
        },
        style: {
            fill: (data) => {
                const type = data[colorField];
                const color = colorType[type];
                return color || "#000";
            },
        },
        _legend: {
            colorField,
            rows: 1,
            cols: 3,
            labelFormatter: (label) => {
                const value = getValueByValue(data, label.text, "genre", "count")
                return {
                    text: `${t(label.text)} ${getPercent(value, totalValue)}%`,
                    color: colorType[label.text],
                }
            },
        },
    }

    const fetchData = async () => {
        try {
            const config = {
                params: request.params,
                ...DEFAULT_CONFIG_REQUEST,
                ...request.config
            }
            let response;
            if (request?.body) {
                response = await api.post(request?.url, request?.body, config);
            } else {
                response = await api.get(request?.url, config);
            }
            setData(response.data);
        } catch (error) {
            console.error(error);
            BuildFieldErrorsDict(error, null, false);
        }
    };

    useEffect(() => {
        void fetchData();
    }, [JSON.stringify(request)]);

    return (
        <>
            {data === undefined && <LoadingFull/>}
            {data?.length === 0 && <Empty/>}
            {data?.length >= 1 &&
                <ChartLegendWrapper chart={Pie} config={config}
                                    expand={expand}/>}
        </>
    );
}

export default DonutChart;
