import {Col, Row, Switch, Typography} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";
import {GamificationCard} from "../../pages/gamification/pages/gamification/components/StyledComponents";
import {
    FlexContainer
} from "../authentication/StyledComponents";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import styled from "styled-components";


const InfoCardContainer = styled(GamificationCard)`

`

const Title = styled.h3`
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0px;
`

export default function InformationCard({title, text}) {

    const {t} = useTranslation()
    return (
        <InfoCardContainer>
            <FlexContainer gap={13}>
                <ExclamationCircleOutlined/>
                <FlexContainer direction={'column'} align={'start'}>
                    <Title>{t(title)}</Title>
                    <Typography.Text
                        type={'secondary'}>{t(text)}</Typography.Text>
                </FlexContainer>
            </FlexContainer>
        </InfoCardContainer>
    )
}
