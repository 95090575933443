import React from 'react';
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Support } from "../../components/Support";
import RoutesPortfolio from "../../modules/project/routes/RoutesPortfolio";
import Database from "../../pages/Database";
import PhasesNavigator from "../../pages/PhasesNavigator";
import PageAllocationEvaluators from "../../pages/Programs/TabBarProgram/Phases/Allocation/PageAllocationEvaluators";
import PageCreatePhase from "../../pages/Programs/TabBarProgram/Phases/PageCreatePhase";
import PhaseEvaluateForm from "../../pages/Programs/TabBarProgram/Phases/PhaseEvaluateForm";
import Phases from "../../pages/Programs/TabBarProgram/Phases/Phases";
import ProgramCreate from "../../pages/Programs/TabBarProgram/Program/ProgramCreate";
import ProgramsNavigator from "../../pages/ProgramsNavigator";
import Loading from "../components/feedback/Loading";
import { usePermissionContext } from "../context/PermissionContext";
import { useThemeContext } from "../context/ThemeContext";
import FinancialBlockPage from "../pages/FinancialBlockPage";
import ApplicationFormPage from '../pages/application/ApplicationFormPage';
import EnvironmentNavigatorPage from "../pages/environment/EnvironmentNavigatorPage";
import EvaluationAssesmentsPage from "../pages/evaluation/EvaluationAssesmentsPage/EvaluationAssesmentsPage";
import EvaluationListPage from "../pages/evaluation/EvaluationListPage/EvaluationListPage";
import ManagerPrograms from "../pages/programs/ManagerPrograms";
import ProgramDetail from "../pages/programs/ProgramDetail/ProgramDetail";
import TemplatesProgram from "../pages/programs/TemplatesProgram";
import ApplicationSubscriptionsNavigator from '../pages/solutions/ApplicationSubscriptionsNavigator';
import UpdatesPage from '../pages/updates/UpdatesPage';
import HomeNavigator from "../../pages/HomeNavidator/HomeNavigator";
import GamificationNavigator from "../pages/gamification/GamificationNavigator";
import Teste from "../utils/Teste";
import ProfileNavigator from './../pages/profile/index';

import {
    AdminRoute,
    EvaluatorRoute,
    ManagerRoute,
    RoutePermission
} from "./PrivateRoute";
import WalletPage from "../pages/wallet/WalletPage";
import RewardsStoreNavigator
    from "../pages/rewardsStore/RewardsStoreNavigator";
import TagsNavigator from "../pages/tags/TagsNavigator";


export default function RoutesClient() {

    const {
        hasProgramEvaluatorPermission,
        hasProgramAdminPermission,
        hasFullAdminPermission,
        hasProgramAdminOrManagerPermission,
        hasSameDomainChallenge,
        hasGamificationAdminPermission,
        hasProjectAdminPermission,
        hasProjectPortfolioMenuAccessPermission,
        isLoadingPermissions,
    } = usePermissionContext();
    const {themeConfig: {client_manager, client}} = useThemeContext()

    const location = useLocation();
    return (
        <>
            {isLoadingPermissions ? <Loading/> : <Switch>
                <Route path="/" exact component={() => <Redirect to={'/home'}/>}/>rewards_store

                {/*ROTAS CLIENTE*/}
                <Route path="/home" component={HomeNavigator}/>
                <ManagerRoute path="/gamification" component={GamificationNavigator} hasPermission={hasGamificationAdminPermission && client?.gamification_actived } />
                <ManagerRoute path="/tags" component={TagsNavigator} hasPermission={hasProgramAdminPermission || hasProjectAdminPermission} />
                <ManagerRoute path="/rewards_store" component={RewardsStoreNavigator} hasPermission={client?.gamification_actived && client?.store_activated} />
                 {/*<ManagerRoute path="/programs/:id"*/}
                 {/*               hasPermission={client_manager?.is_program_manager_module && hasProgramAdminOrManagerPermission}*/}
                 {/*               render={() => <ProgramsNavigator*/}
                 {/*               hasPermission={client_manager?.is_program_manager_module && hasProgramAdminOrManagerPermission}/>}/>*/}

                {/*<Route path="/messages" exact component={MessageNavigation}/>*/}
                <Route path="/financial_block" exact component={FinancialBlockPage}/>

                <AdminRoute path="/database" hasPermission={hasProgramAdminPermission}
                            render={() => <Database hasPermission={hasProgramAdminPermission}/>}/>

                <AdminRoute path="/environment" hasPermission={hasFullAdminPermission}
                            render={() => <EnvironmentNavigatorPage hasPermission={hasFullAdminPermission}/>}/>

                <EvaluatorRoute path="/evaluations" exact component={EvaluationListPage}
                                hasPermission={hasProgramEvaluatorPermission}/>

                <EvaluatorRoute path="/evaluations/:id_assessment" component={EvaluationAssesmentsPage}
                                hasPermission={hasProgramEvaluatorPermission}/>

                <ManagerRoute path="/programs" exact component={ManagerPrograms}
                                hasPermission={client_manager?.is_program_manager_module && hasProgramAdminOrManagerPermission}/>

                <ManagerRoute path="/programs/templates/" exact component={TemplatesProgram}
                                hasPermission={client_manager?.is_program_manager_module && hasProgramAdminOrManagerPermission}/>

                <ManagerRoute path="/programs/create" component={ProgramCreate}
                                hasPermission={client_manager?.is_program_manager_module && hasProgramAdminOrManagerPermission}/>


                <ManagerRoute path="/programs/:id/create_phase"
                                component={PageCreatePhase}
                                hasPermission={client_manager?.is_program_manager_module && hasProgramAdminOrManagerPermission}/>

                <ManagerRoute path="/programs/:id/phases/:phase_id/allocation_evaluators" //OBS: FOI REMOVIDO DA ROTA DO PHASENAVEGATE
                                hasPermission={client_manager?.is_program_manager_module && hasProgramAdminOrManagerPermission}
                                component={PageAllocationEvaluators}/>

                <ManagerRoute path="/programs/:id/phases/:phase_id"
                                hasPermission={client_manager?.is_program_manager_module && hasProgramAdminOrManagerPermission}
                                render={() => <PhasesNavigator
                                hasPermission={client_manager?.is_program_manager_module && hasProgramAdminOrManagerPermission}/>}/>

                <ManagerRoute path="/programs/:id"
                                hasPermission={client_manager?.is_program_manager_module && hasProgramAdminOrManagerPermission}
                                render={() => <ProgramsNavigator
                                hasPermission={client_manager?.is_program_manager_module && hasProgramAdminOrManagerPermission}/>}/>

                <ManagerRoute path="/programs/:id/phases/:phase_id/evaluateform" component={PhaseEvaluateForm}
                              hasPermission={client_manager?.is_program_manager_module && hasProgramAdminOrManagerPermission}/>

                <ManagerRoute path="/programs/:id/phases/:id/delete" component={Phases}
                              hasPermission={client_manager?.is_program_manager_module && hasProgramAdminOrManagerPermission}/>

                <Route path="/profile" render={()=><ProfileNavigator />}/>
                <Route path="/support" exact component={Support}/>

                {/*ROTAS EMPREENDEDOR*/}
                <Route path="/available-programs/detail/:id" render={() => <ProgramDetail/>}/>

                <Route path="/application_form/:id" exact component={ApplicationFormPage}/>

                <Route path= "/applications" render={()=> <ApplicationSubscriptionsNavigator/>} />
                <Route path="/solutions/:id" exact component={ApplicationFormPage}/>
                <ManagerRoute path="/my_wallet"  hasPermission={client?.gamification_actived } exact component={WalletPage}/>

                <RoutePermission path="/applications_challenges/:id(\d+)" exact component={ApplicationFormPage}
                                 hasPermission={hasSameDomainChallenge}/>

                {/*<Route path="/open_applications_challenges" render={() => <OpenChallengesPage/>}/> todo validar remoção*/}

                <RoutePermission path={"/project-portfolio"} hasPermission={hasProjectPortfolioMenuAccessPermission} component={RoutesPortfolio}/>

                <Route path="/updates" component={UpdatesPage}/>

                {/*<Route path="/dev/teste" exact component={Teste}/>*/}
                <Redirect to={{pathname: '/404', state: {broken_url: window.location.host + location.pathname}}}/>
            </Switch>}
        </>
    );
}

