import { Badge, Space } from "antd";
import ProjectCollapse from "./ProjectCollapse";
import { useProjectPortfolioContext } from "../context/ProjectPortfolioContext";
import { useTranslation } from "react-i18next";


function ProjectCollapseList() {
    const {t} = useTranslation();
    const { phases } = useProjectPortfolioContext();
    return (
        <Space size={32} direction="vertical" style={{ display: "flex" }}>
            {phases.map((phase, key) =>
                <ProjectCollapse
                    phase={phase}
                    title={
                        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                            <Badge count={phase["order"]} color="blue" />
                            <div style={{ width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{t(phase["label"])}</div>
                        </div>}
                    data-cy={`project-collapse`}
                />
            )}
        </Space>
    );
}

export default ProjectCollapseList;