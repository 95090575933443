import React, {useEffect, useState} from "react";
import {Column} from "@ant-design/plots";
import api from "../../../../../global/services/api";
import {
    BuildFieldErrorsDict,
    findMaxValueByKey,
    getMaxValueFromArray
} from "../../../../../global/utils/Utils";
import Empty from "../../../../../global/components/data_display/Empty";
import LoadingFull from "../../../../../global/components/other/LoadingFull";
import {useTranslation} from "react-i18next";

const DEFAULT_CONFIG_REQUEST = {
    version: 'v1'
}
export default function ColumnChart({
                                        request,
                                        externalData = null,
                                        legend_key,
                                        value = "count",
                                    }) {
    const {t} = useTranslation();
    const [isLoading, setIsloading] = useState(false)
    const [datas, setDatas] = useState(externalData);

    useEffect(() => {
        if (!externalData && request?.url) {
            const fetchData = async () => {
                try {
                    setIsloading(true)
                    const config = {
                        params: request.params,
                        ...DEFAULT_CONFIG_REQUEST,
                        ...request.config
                    }
                    let response;
                    if (request?.body) {
                        response = await api.post(request?.url, request?.body, config);
                    } else {
                        response = await api.get(request?.url, config);
                    }
                    setDatas(response.data);
                } catch (error) {
                    BuildFieldErrorsDict(error, null, false);
                } finally {
                    setIsloading(false)
                }

            };
            fetchData();
        }
    }, [JSON.stringify(request)]);


    const config = {
        data: datas,
        xField: legend_key,
        yField: value,
        autoFit: true,
        padding: 40,
        animate: null,
        axis: {
            x: {
                labelFormatter: (datum) => t(datum),
                labelAlign: "horizontal",
                size: 40,
                labelAutoEllipsis: [{type: "ellipsis", maxLength: 5}],
                tickLength: 0,
            },
            y: {
                tickCount: findMaxValueByKey(datas, value),
                tickLength: 0,
            }
        },
        label: {
            text: value,
            textBaseline: "bottom",
        },
        tooltip: {
            title: false,
            items: [(item) => (
                {
                    name: t(item[legend_key]),
                    // field: legend_key,
                    channel: "x",
                    value: item[value]
                })
            ],
        },
        scale: {
            y: {
                domain: [0, getMaxValueFromArray(datas, value, true)]
            }
        },
        markBackground: {
            style: {
                fill: "transparent"
            }
        },
    };

    if (isLoading) return <LoadingFull/>

    return (
        <>
            {datas?.length === 0 && <Empty/>}
            {datas?.length >= 1 && <Column {...config} />}
        </>
    );
}
