import { useTranslation } from "react-i18next";
import EnvironmentLink from "../../link/EnvironmentLink";
import ApplicationInfoCardBase from "./ApplicationInfoCardBase";
import ApplicationTagStatus from "../../tag/ApplicationTagStatus";


export default function ApplicationInfoCard({ application, onClick, children }) {
    const {t} = useTranslation();
    return (
        <ApplicationInfoCardBase
            application={application}
            color={application.logged_user_is_member ? "#FF9DC6":  "#C290A5"}
            title={t(application?.program.type_display)}
            subtitle={application.date_submited ? application.name :
                <EnvironmentLink clientUrl={application.program?.client_url}
                    to={`/solutions/${application.id}`}>{t("Complete the form submission.")}
                </EnvironmentLink>}
            extra={
                <ApplicationTagStatus application={application}/>
            }
            onClick={onClick}
        >
            {children && children}
        </ApplicationInfoCardBase>
    );
}