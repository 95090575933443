import {Form as FormAnt, Row, Modal} from "antd";
import {useTranslation} from "react-i18next";
import {
    useLayoutContext
} from "../../../../../../modules/project/components/layout/context/LayoutContext";
import Button from "../../../../data_entry/Button";
import Form from "../../../../data_entry/form/Form";
import Drawer from "../../../../layout/Drawer";
import {useEffect, useState} from "react";
import ApplicationInfoCard
    from "../../../../data_display/ApplicationInfoCard/ApplicatioInfoCard";
import api from "../../../../../services/api";
import SelectFormField from "../../../../data_entry/form/SelectFormField";
import {
    BuildFieldErrorsDict,
    generateOptionsField
} from "../../../../../utils/Utils";
import {ToastNotification} from "../../../../feedback/ToastNotification";
import {SUCCESS} from "../../../../../utils/fileWithMessage";


export default function ApplicationReturnPhaseDrawer({
                                                         solution,
                                                         open,
                                                         setOpen
                                                     }) {
    const {openNotification} = useLayoutContext();
    const {t} = useTranslation();
    const [form] = FormAnt.useForm();
    const [applicationInfo, setApplicationInfo] = useState();
    const [phasesOptions, setPhasesOptions] = useState();
    const {updateLayoutContent} = useLayoutContext()
    const handleSubmit = async (data) => {

        Modal.confirm({
            title: t("Do you want to change the phase of this application?"),
            content: t("This action cannot be undone. When changing the stage, the application will have to go through the selection process again from the selected stage."),
            okText: t("Change phase"),
            cancelText: t("Give up"),
            onOk: async () => {
                try {
                    await api.post(`/applications/change_application_phase`, {...data, application_id: solution.id}, {version: 'v2'});
                    ToastNotification(SUCCESS)

                    updateLayoutContent()
                } catch (error) {
                    BuildFieldErrorsDict(error)
                }
            }
        });

    };


    useEffect(() => {

        const fetchPhases = async (data) => {
            try {
                const response = await api.get(`/applications/get_previous_phases/${solution?.id}`, {version: 'v2'},);
                setPhasesOptions(response.data.filter(({
                                                           is_open,
                                                           type_display
                                                       }) => is_open && type_display.toLowerCase() === 'Candidate'.toLowerCase()))

            } catch (error) {
                openNotification({
                    type: "error",
                    message: error.response?.data.errors
                });
            }
        };
        solution && fetchPhases()
    }, [solution])


    return (
        <Drawer
            title={t("Return to a Previous Step")} open={open}
            onClose={() => setOpen(false)}
            footer={
                <Row justify="end">
                    <Button type="primary"
                            onClick={() => form.submit()}>{t("Change phase")}</Button>
                </Row>}
        >
            <Form layout="vertical" form={form}
                  onFinish={handleSubmit}>
                <SelectFormField
                    options={generateOptionsField(phasesOptions, 'title')}

                    form={{
                        label: t("Select the stage of this program"),
                        name: "new_phase_id",
                        rules: [{required: true}],
                        help: t('Only phases that have already been passed by the application can be selected. When you return to the application phase, you will be able to reformulate your idea, which means that the author will need to submit the form again.')
                    }}/>

            </Form>
            {applicationInfo &&
                <ApplicationInfoCard application={applicationInfo}/>}
        </Drawer>
    );
}