import {createContext, useContext, useEffect, useState} from "react";
import api from "../../../global/services/api";


const ProjectPortfolioContext = createContext({});


function ProjectPortfolioContextProvider({children}){

    const [phases, setPhases] = useState([]);
    const fetchPhases = async () => {
        const response = await api.get(`/project_management/project_phases_management/by_current_client`);
        setPhases(response.data);
    };

    const getIndicatorResponseScaledValue = ({value, indicatorResponse, min, max}) => {
        const {indicator} = indicatorResponse;
        const minScale = indicator["scale_min"] ?? min;
        const maxScale = indicator["scale_max"] ?? max;
        const percent = ((value - minScale) / (maxScale - minScale)) * 100;
        return parseInt(`${percent}`);
    }

    useEffect(()=>{
        void fetchPhases();
    }, [])

    return <ProjectPortfolioContext.Provider value={{ phases, setPhases, getIndicatorResponseScaledValue}}>{children}</ProjectPortfolioContext.Provider>
}

export function useProjectPortfolioContext() {
    return useContext(ProjectPortfolioContext);
}

export default ProjectPortfolioContextProvider;