import styled from "styled-components";
import {Card, Tooltip, Typography} from "antd";
import Flex from "../../../../../../../responsive/Flex";
import {neutral_3, neutral_6} from "../../../../../../../../utils/Colors";
import {useTranslation} from "react-i18next";
import {AuditOutlined} from "@ant-design/icons";
import useSystemBreakpoint from "../../../../../../../../hooks/useSystemBreakpoint";
import {useApplicationDrawerContext} from "../../../../context/ApplicationDrawerContext";
import React from "react";

const {Text} = Typography


const StyledCard = styled(Card)`
    border-radius: 8px;
    border-color: ${({$border}) => $border};
`

function AverageText({average, text}) {
    return (
        <Flex align="center" style={{whiteSpace: "nowrap"}} justify="end">
            <Text type="secondary">{text}:</Text>
            <Text strong={true} style={{fontSize: 24}}>{average}</Text>
        </Flex>
    )
}


const StyledDiv = styled.div`
    cursor: pointer;
    background: ${neutral_3};
    padding: 0 8px;
    border-radius: 8px;
`

function WeightedTooltipSpan({weight}) {
    const {t} = useTranslation()
    return (
        <Tooltip placement="topLeft"
                 title={t("In weighted average programs, each question has an associated weight. This weight directly influences the final grade.")}>
            <Flex gap={4} align="center">
                <StyledDiv>
                    <AuditOutlined style={{fontSize: 12}}/>
                    <Text style={{fontSize: 12}} type="secondary"> {t("Weight in this question")}: {weight}</Text>
                </StyledDiv>
            </Flex>
        </Tooltip>
    )
}

export default function AssessmentSimplifiedView({form, solutionResult}) {
    const {t} = useTranslation()
    const {isPhone} = useSystemBreakpoint()
    const {solution} = useApplicationDrawerContext();

    console.log(solutionResult)


    return (
        <Flex vertical={true} gap={8} style={{padding: 8}}>
            {form?.comp_score?.map(question => (
                question?.answer_avg &&
                <StyledCard>
                    <Flex gap={16} vertical={isPhone} align={isPhone ? "normal" : "center"} justify="space-between">
                        <Flex vertical={true}>
                            {question?.title}
                            {solution?.program.evaluation_format === "weighted" &&
                                <WeightedTooltipSpan weight={question?.weight}/>
                            }
                        </Flex>
                        <AverageText average={question?.answer_avg} text={t("Average")}/>
                    </Flex>
                </StyledCard>
            ))}
            <StyledCard $border={neutral_6}>
                <Flex align="center" justify="space-between">
                    <Text strong={true}>{t("Final note")}</Text>
                    <AverageText average={solutionResult?.score_average} text={t("Final Average")}/>
                </Flex>
            </StyledCard>
        </Flex>
    );
}