import React from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import useDefaultGetRequest from "../../../../../hooks/useDefaultGetRequest";
import {
    CAMPAIGN_STATUS,
} from "../../../../../utils/Constants";
import Loading from "../../../../../components/feedback/Loading";
import {Col, Row} from "antd";
import Empty from "../../../../../components/data_display/Empty";
import CampaignCard from "../../../../../components/data_display/CampaignCard";

const Title = styled.h2`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
`
export default function SectionOpenCampaign({}) {
    const {t} = useTranslation();
    const {
        data: campaigns,
        isLoading
    } = useDefaultGetRequest({
        url: `/point_event`,
        params: {status: CAMPAIGN_STATUS.IN_PROGRESS, environment_campaign:true},
        dataDefault: []
    })


    return (

        <>

            <Row gutter={[16,16]}>
                <Col xs={24}><Title
                level={6}>{t('Current campaigns')}</Title></Col>
                {isLoading ? (
                    <Col xs={24}><Loading height={'100px'}/></Col>
                ) : campaigns?.results?.length > 0 ? campaigns?.results?.map((campaign) =>
                    <Col xs={24} key={campaign.id}><CampaignCard
                        campaign={campaign}/></Col>
                ) : <Col xs={24}><Empty
                    description={t('There are no active campaigns at the moment.')}/></Col>}

            </Row>


        </>

    );
}
