import React, {useRef, useState} from "react";
import {Button, Carousel, Checkbox, Typography} from "antd";
import SolvModal from "../../../../global/components/layout/SolvModal";
import {CloseOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import api from "../../../../global/services/api";
import {useSolvModalContext} from "./SolvTourModalList";

function VideoIframe({step}) {
    return <div style={{paddingTop: 10}}>
        <iframe
            width="470"
            height="315"
            src={step.content}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen>
        </iframe>
    </div>;
}

function SolvTourModal() {
    const {t} = useTranslation();
    const {Text} = Typography;
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const {
        showTourModal, setShowTourModal, currentOnboard, showCheckbox,
        refreshOnboard, currentStep, setCurrentStep,
    } = useSolvModalContext();

    const {steps = []} = currentOnboard || {};
    const firstStep = steps[0];
    const carousel = useRef(null);

    const contentTypes = {
        video: VideoIframe,
    };

    const updateUserHasViewed = async () => {
        try {
            await api.post(`/onboard/viewed_step/${currentOnboard.id}`);
        } catch (error) {
            console.error(error);
        }
    };

    const isLastStep = () => {
        return steps.length - 1 === currentStep;
    };

    const afterClose = () => {
        if (dontShowAgain && showCheckbox) {
            void updateUserHasViewed();
            refreshOnboard();
        }
    };

    const userCanView = () => {
        if (showCheckbox) {
            return (showTourModal && !currentOnboard?.user_has_viewed);
        } else {
            return showTourModal;
        }
    };

    const renderCarousel = () => {
        return <Carousel
            carousel={carousel}
            beforeChange={(_, current) => setCurrentStep(current)}
            infinite={false}
            arrows={false}
            dots={false}
        >
            {steps.map((step) => (
                <div>
                    {(steps.length > 1) && <Typography.Title level={5}>{t(step.name)}</Typography.Title>}
                    {t(step.description)}
                    {React.createElement(contentTypes[step.content_type], {step})}
                </div>),
            )}
        </Carousel>;
    };

    return currentOnboard?.steps ? (
        <SolvModal
            title={<Text style={{fontSize: 16}}>{(steps.length === 1) ? t(firstStep?.name) : t(currentOnboard.name)}</Text>}
            open={userCanView()}
            setOpen={setShowTourModal}
            closeIcon={<CloseOutlined type="secondary"/>}
            footer={
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div>
                        {showCheckbox && <Checkbox onChange={e => setDontShowAgain(e.target.checked)} checked={dontShowAgain}>
                            {t("Don't show again")}
                        </Checkbox>}
                    </div>
                    <div>
                        {steps.length > 1 && <>
                            <Button onClick={carousel.current?.prev}>Prev</Button>
                            {!isLastStep() && <Button type="primary" onClick={carousel.current?.next}>Next</Button>}
                        </>}
                        {isLastStep() && <Button type="primary" onClick={() => setShowTourModal(false)}>{t("Understood")}</Button>}
                    </div>
                </div>
            }
            destroyOnClose={true}
            afterClose={afterClose}
            centered
        >
            {renderCarousel()}
        </SolvModal>
    ) : <></>;
}

export default SolvTourModal;