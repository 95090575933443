import IndicatorCheckpointCard from "./IndicatorCheckpointCard";
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import styled from "styled-components";
import 'react-circular-progressbar/dist/styles.css';
import {Space, Typography} from "antd";
import { useTranslation } from "react-i18next";

const {Text} = Typography;

const StyledCircularProgressbar = styled(CircularProgressbarWithChildren)`
  height: 120px;

  text {
    fill: ${({color}) => color} !important;
    font-weight: 700 !important;
    font-size: 24px;
    border: 1px solid black;
  }
`;

const StyledValuePrefix = styled(Text)`
  font-size: 14px;
`;

const StyledValuePreview = styled(Text)`
  font-weight: 500;
  font-size: 20px;
  color: ${({color}) => color};
`;


function IndicatorProgressCheckpointCard({indicatorResponse, ...props}) {
    const {indicator} = indicatorResponse;
    const value = indicatorResponse["stored_value"];
    const { t } = useTranslation();

    let color = "#85A5FF";
    if (indicator["levels_colorized"]) {
        color = "#597EF7";
    }

    const colorizedStyles = buildStyles({
        trailColor: "#597EF7",
        pathColor: color,
    });

    return (
        <IndicatorCheckpointCard indicatorResponse={indicatorResponse} {...props}>
            <Space size={12}>
                <StyledCircularProgressbar
                    color={color}
                    value={value}
                    strokeWidth={5}
                    text={`${value}%`}
                    styles={colorizedStyles}
                />
                <StyledValuePrefix type="secondary">{t(indicator["indicator_view"]["field_prefix"])}</StyledValuePrefix>
                <StyledValuePreview color={color}>{value}%</StyledValuePreview>
            </Space>
        </IndicatorCheckpointCard>
    );
}

export default IndicatorProgressCheckpointCard;