import React from 'react';
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Support } from "../../components/Support";
import Evaluations from "../../pages/Evaluations/Index";
import ProfileNavigator from '../pages/profile/index';
import ManagerProgramsSolv from "../pages/programs/ManagerProgramsSolv";
import UpdatesPage from './../pages/updates/UpdatesPage';
import ApplicationSubscriptionsNavigator from '../pages/solutions/ApplicationSubscriptionsNavigator';
import HomeNavigator from '../../pages/HomeNavidator/HomeNavigator';

function RoutesEntrepreneur() {
    const location = useLocation()

    return (
        <Switch>
            <Route path="/" exact component={() => {
                return <Redirect to={'/home'} />
            }} />


            <Route path="/home" component={HomeNavigator}/>
            <Route path="/programs" exact component={ManagerProgramsSolv} />
            <Route path="/profile" render={() => <ProfileNavigator />} />
            <Route path="/support" exact component={Support} />
            <Route path="/updates" component={UpdatesPage}/>

            {/*<Route path="/programs/:id" component={ShowProgram}/>*/}

            {/*Applications*/}
            {/* <Route path="/applications_challenges" render={() => <ChallengesNavigator/>}/> */}
            {/*<Route path="/open_applications_challenges" render={() => <OpenChallengesPage />} todo validar remoção/>*/}

            {/*Teams*/}
            <Route path= "/applications" render={()=> <ApplicationSubscriptionsNavigator/>} />

            <Route path="/evaluations" exact component={Evaluations} />
            <Redirect to={{
                pathname: '/404',
                state: { broken_url: window.location.host + location.pathname }
            }} />
        </Switch>


    );
}

export default RoutesEntrepreneur;