import React from "react";
import { Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import dragdrop from "../../../../assets/Icon/dragdrop.svg"
import {useTranslation} from "react-i18next";
import {neutral_1} from "../../../../global/utils/Colors";

const Container = styled.div`
overflow: hidden;
    border: 1px ${(props) => props.isDraggingOver ? 'dashed #000' : 'none'};
    // background: ${(props) => props.isDraggingOver ? '#f5fffc' : '#fff'};
    box-shadow: ${(props) => props.isDropDisabled ? '0px 0px 8px rgba(221, 221, 221, 0.5)' : 'none'};
    width:100%;
    margin-bottom: ${(props) => props.isDropDisabled ? '0px' : '80px'};
    overflow-y: ${(props) => props.showScroll && 'scroll'}; 
    // -ms-overflow-style:  ${(props) => props.isDropDisabled && 'none'};
    // &::-webkit-scrollbar {
    //     display: ${(props) => props.isDropDisabled && 'none'};
    // }
    padding-bottom:${(props) => props.isDraggingOver && props.addPadding && '50px;'}
    background-color:${neutral_1};
`;

function DroppableComponent(props) {
    const {t} = useTranslation();

    return (
        <Droppable droppableId={props.id} isDropDisabled={props.disabled_drop}>
            {(provided, snapshot) => (
                <Container
                    ref={provided.innerRef}
                    isDraggingOver={snapshot.isDraggingOver}
                    isDropDisabled={props.disabled_drop}
                    addPadding={props.children.length > 0}
                    showScroll={props.showScroll}
                >
                    {(props.children.length === 0 && props.showContent) ? <div style={{
                        backgroundColor: neutral_1,
                        display: 'flex',
                        alignItems: 'flex-start',
                        alignContent: 'center',
                        justifyContent: 'center',
                        border: '1px dashed #C4C4C4',
                        borderRadius: 7,
                        color: '#aaa',
                        minHeight: 300
                    }}>
                        <div style={{ marginTop: 50, fontSize: 22, fontWeight: 300, }}>
                            <span style={{ marginRight: 6 }}>{t("Drag and drop items here")}</span>
                            <img src={dragdrop} style={{ marginTop: -4 }} /></div>
                    </div>
                        : props.children}
                </Container>
            )}
        </Droppable>
    )

}

export default DroppableComponent