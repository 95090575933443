import styled from "styled-components";
import {Badge, Typography} from "antd";
import React from "react";

const {Text} = Typography

const StyledBadge = styled(Badge)`
  .ant-badge-status-dot {
    width: 3px;
    height: 3px;
  }
`
export const DotBadge = () => <StyledBadge color="#000" dot/>;
