import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import {useTranslation} from "react-i18next";
import useDefaultGetRequest from "../../../../hooks/useDefaultGetRequest";
import api from "../../../../services/api";
import {
    ToastNotification
} from "../../../../components/feedback/ToastNotification";
import * as ConstantsMessage from "../../../../utils/fileWithMessage";
import {BuildFieldErrorsDict} from "../../../../utils/Utils";
import {useUserContext} from "../../../../context/UserContext";
import {PROGRAM_CHART_NAMES} from "../components/ProgramChartFactory";
import {useThemeContext} from "../../../../context/ThemeContext";
import {PROJECT_CHART_NAMES} from "../components/ProjectChartFactory";

const InnovationPanelContext = createContext();

const DEFAULT_PROGRAM_LIST = [
    PROGRAM_CHART_NAMES.APPLICATIONS_BY_PROGRAMS,
    PROGRAM_CHART_NAMES.APPLICATIONS_BY_CHALLENGES,
    PROGRAM_CHART_NAMES.APPLICATIONS_BY_PERSONA,
    PROGRAM_CHART_NAMES.APPLICATIONS_BY_STATE,
    PROGRAM_CHART_NAMES.APPROVALS_BY_PROGRAMS,
    PROGRAM_CHART_NAMES.APPLICATIONS_BY_INSTITUTION,
]
const PROGRAM_FILTER_FORM_NAMES = {
    TAGS_ISNULL: 'tags__isnull',
    TAGS__ID__IN: 'tags__id__in[]',
    PROGRAMS__ID__IN: 'id__in'
}

const PROJECT_FILTER_FORM_NAMES = {
    TAGS_ISNULL: 'tags__isnull',
    TAGS__ID__IN: 'tags__id__in[]',
    PROJECTS__ID__IN: 'id__in'
}
export const InnovationPanelProvider = ({children}) => {
    const {t} = useTranslation();
    const [projects, setProjects] = useState([])
    const [programsFilters, setProgramsFilters] = useState({})
    const [projectsFilters, setProjectsFilters] = useState({})
    // const [projectsFilters, setProjectsFilters] = useState({})
    const [isInitialLoading, setIsInitialLoading] = useState(true)
    const [isLoadingUpdateCharts, setIsLoadingUpdateCharts] = useState(false)
    const [dates, setDates] = useState([])
    const {
        innovationPanelSettings,
        updateInnovationChartsPanelSettings,
        updateInnovationFilterChartsPanelSettings
    } = useUserContext()
    const {
        themeConfig: {client}
    } = useThemeContext()


    //PROGRAMAS
    const {
        data: programsFilter,
    } = useDefaultGetRequest({
        url: `analytics/dashboard/manager_dashboard/get_programs`,
    })


    async function updateChartList(chartList, position, choice, type) {
        try {

            setIsLoadingUpdateCharts(true)
            chartList.splice(position, 1, choice)
            const data = {
                widgets: {
                    ...innovationPanelSettings.widgets,
                    [type]: chartList
                }
            }
            await api.patch(`analytics/innovation_painel_settings/${innovationPanelSettings.id}`, data);
            updateInnovationChartsPanelSettings(type, chartList)
            ToastNotification(t(ConstantsMessage.SUCCESS));
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoadingUpdateCharts(false)
        }
    }

    async function updateFilterList(filterList, type) {
        try {

            setIsLoadingUpdateCharts(true)
            const data = {
                filters: {
                    ...innovationPanelSettings.filters,
                    [type]: filterList
                }
            }
            await api.patch(`analytics/innovation_painel_settings/${innovationPanelSettings.id}`, data);
            updateInnovationFilterChartsPanelSettings(type, filterList)
            ToastNotification(t(ConstantsMessage.SUCCESS));
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoadingUpdateCharts(false)
        }
    }


    const programChartList = useMemo(() => {
        //seta a lista vinda do back, se vim vazia vai setar a lista default
        if (!(innovationPanelSettings?.widgets?.programs) || innovationPanelSettings?.widgets?.programs?.length === 0) return DEFAULT_PROGRAM_LIST.map((item) => ({
            type: item,
            columnSize: 1
        }))

        const programs = innovationPanelSettings.widgets.programs.map((item) => {

            if (!item || typeof item === 'string') {
                return {
                    columnSize: 1,
                    type: item
                }
            }
            return item
        })

        if (programs.length < 6) {//resolve bugs de posição
            return new Array(6).fill('').map((_, index) => programs[index] || '')
        }
        return programs
    }, [innovationPanelSettings?.widgets?.programs])

    function addProgramChart(position, choice) {
        const programs = innovationPanelSettings.widgets.programs.length === 6 ? innovationPanelSettings.widgets.programs : programChartList
        updateChartList(programs, position, {
            type: choice,
            columnSize: 1
        }, 'programs')


    }

    async function updateColumnProgramChart(position, column) {
        const programs = innovationPanelSettings.widgets.programs.length === 6 ? innovationPanelSettings.widgets.programs : programChartList
        const newItem = programs[position]
        newItem.columnSize = column
        updateChartList(programs, position, newItem, 'programs')


    }

    async function updateProgramsFilters(filterList,) {
        updateFilterList(filterList, 'programs')
    }

    async function deleteProgramChart(position) {
        const programs = innovationPanelSettings.widgets.programs.length === 6 ? innovationPanelSettings.widgets.programs : programChartList
        updateChartList(programs, position, '', 'programs')
    }


    //PROJECTOS
    const {
        data: projectsFilter,
    } = useDefaultGetRequest({
        url: `analytics/dashboard/manager_dashboard/get_projects`,
    })


    const {
        isLoading: isLoadingIndicators,
        data: indicatorsProject,
    } = useDefaultGetRequest({
        url: `analytics/dashboard/project_client_dashboard/${client?.id}/get_innovation_panel_charts`,
        makeRequest: !!client?.id,
        dependence: [client?.id],
        dataDefault: []
    })


    const projectChartList = useMemo(() => {
        function get_default_from_internal_code(indicator) {
            switch (indicator.internal_code) {
                case "24":
                    return `${indicator.id}-${PROJECT_CHART_NAMES.PROJECT_RADAR}`
                case "25":
                    return `${indicator.id}-${PROJECT_CHART_NAMES.PROJECT_BY_PHASES}`
                case "23":
                    return `${indicator.id}-${PROJECT_CHART_NAMES.RISK}`
                case "15":
                    return `${indicator.id}-${PROJECT_CHART_NAMES.TOTAL_EXPECTED_INVESTIMENT}`
                case "21":
                    return `${indicator.id}-${PROJECT_CHART_NAMES.TOTAL_INVESTIMENT_MADE}`


            }


        }


        if (!(innovationPanelSettings?.widgets?.projects) || innovationPanelSettings?.widgets?.projects?.length === 0) {
            const defaultGraphics = ["24", "25", "23", "15", "21"]//internal code do indicador
            const projectsDefault = indicatorsProject.filter((indicator) => defaultGraphics.includes(indicator.internal_code)).map(get_default_from_internal_code).map((item) => ({
                type: item,
                columnSize: 1
            }))

            return [...projectsDefault, {type: '', columnSize: 1}]
        }

        const projects = innovationPanelSettings.widgets.projects.map((item) => {

            if (!item || typeof item === 'string') {
                return {
                    columnSize: 1,
                    type: item
                }
            }
            return item
        })

        if (projects.length < 6) {//resolve bugs de posição
            return new Array(6).fill('').map((_, index) => projects[index] || '')
        }
        return projects
    }, [innovationPanelSettings?.widgets?.projects, indicatorsProject])


    async function addProjectChart(position, choice) {
        const projects = innovationPanelSettings.widgets.projects.length === 6 ? innovationPanelSettings.widgets.projects : projectChartList
        updateChartList(projects, position, {
            type: choice,
            columnSize: 1
        }, 'projects')
    }

    async function updateColumnProjectChart(position, column) {
        const projects = innovationPanelSettings.widgets.projects.length === 6 ? innovationPanelSettings.widgets.projects : projectChartList
        const newItem = projects[position]
        newItem.columnSize = column
        updateChartList(projects, position, newItem, 'projects')


    }

    async function updateProjectsFilters(filterList, choice) {
        updateFilterList(filterList, 'projects')
    }

    async function deleteProjectChart(position) {
        const projects = innovationPanelSettings.widgets.projects.length === 6 ? innovationPanelSettings.widgets.projects : projectChartList
        updateChartList(projects, position, '', 'projects')
    }


    useEffect(() => {
        if (Array.isArray(programsFilter) && Array.isArray(projectsFilter) && innovationPanelSettings) {
            const programs = innovationPanelSettings?.filters?.programs.filter((savedProgram) => programsFilter.some((program) => savedProgram === program.id))
            if (programs.length > 0) {
                setProgramsFilters({
                    [PROGRAM_FILTER_FORM_NAMES.PROGRAMS__ID__IN]: programs
                })
            }


            const projects = innovationPanelSettings?.filters?.projects.filter((savedProject) => projectsFilter.some((project) => savedProject === project.id))
            if (projects.length > 0) {
                setProjectsFilters({
                    [PROJECT_FILTER_FORM_NAMES.PROJECTS__ID__IN]: projects
                })
            }
            setIsInitialLoading(false)
        }
    }, [innovationPanelSettings?.filters?.programs, innovationPanelSettings?.filters?.projects, JSON.stringify(programsFilter), JSON.stringify(projectsFilter)]);

    return <InnovationPanelContext.Provider
        value={{
            programChartList,
            isLoadingUpdateCharts,
            dates,
            setDates,
            addProgramChart,
            deleteProgramChart,
            addProjectChart,
            deleteProjectChart,
            projectChartList,
            projects,
            updateColumnProgramChart,
            updateColumnProjectChart,
            setProjects,
            updateProjectsFilters,
            updateProgramsFilters,
            initialDate: dates[0],
            finalDate: dates[1],
            isLoadingIndicators,
            programsFilters,
            setProgramsFilters,
            setProjectsFilters,
            isInitialLoading,
            indicatorsProject,
            projectsFilters,
            PROJECT_FILTER_FORM_NAMES,
            PROGRAM_FILTER_FORM_NAMES
        }}>
        {children}
    </InnovationPanelContext.Provider>
}

export const useInnovationPanelContext = () => {
    return useContext(InnovationPanelContext);
}

