import React, {useState} from 'react';

import {
    DownloadOutlined, LoadingOutlined
} from "@ant-design/icons";

import styled from 'styled-components';
import {neutral_6} from "../../utils/Colors";
import {BuildFieldErrorsDict} from "../../utils/Utils";
import api from "../../services/api";

const Container = styled.div`
    padding: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
        background: ${neutral_6};
    }
`
export default function ButtonExportData({url, loading=false, onClick, request, name}) {
    const [isLoading, setIsLoading] = useState()

    async function handleExport() {
        try {
            setIsLoading(true)
            const response = await api.get(url, {
                responseType: 'blob',
                ...request
            });

            const href = URL.createObjectURL(response.data);
            const tag_a = document.createElement('a');
            tag_a.href = href;
            tag_a.setAttribute('download', name);
            tag_a.click();
            URL.revokeObjectURL(href);

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        } finally {
            setIsLoading(false)
        }
    }

    return (
        <Container onClick={onClick ? onClick : handleExport}>
            {isLoading || loading ? <LoadingOutlined/> : <DownloadOutlined/>}
        </Container>
        // <a href={`${BASE_ENDPOINT}v2/program_evaluators/export?phases=508`} download>

        // </a>


    )
}