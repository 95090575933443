import React from 'react';
import {Skeleton} from 'antd';
import {FlexContainer} from "../authentication/StyledComponents";


export default function SquaresLoading({
                                           amount = 10,
                                           animation = true,
                                           ...props
                                       }) {
    return (
        <FlexContainer justify={'space-between'} gap={16} style={{overflow: 'hidden'}}>
            {Array(amount).fill('').map((_, index) => <Skeleton.Node
                key={index}
                active={animation}> </Skeleton.Node>)}
        </FlexContainer>
    );
}

