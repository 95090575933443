import IndicatorCheckpointCard from "./IndicatorCheckpointCard";
import {Select, Space} from "antd";
import {useStatusReportContext} from "../../context/StatusReportContext";
import {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";
import SolvTag from "../../../../../../global/components/tag/SolvTag";


function IndicatorTagCheckpointCard({indicatorResponse, ...props}) {
    const {indicator} = indicatorResponse;
    const {updateIndicatorResponse} = useStatusReportContext();
    const [selectedTag, setSelectedTag] = useState();
    const {t} = useTranslation();

    const findTag = (id) => {
        return indicator["all_tags"].find((tag)=>`${tag["id"]}` === `${id}`);
    }

    const handleValueChange = (value) => {
        const tag = findTag(value);
        setSelectedTag(tag);
        updateIndicatorResponse({id: indicatorResponse["id"], values: {tags: [tag]}});
    }
    const getOptions = () => {
        return indicator["all_tags"].map((tag)=>({
            label: t(tag["name"]),
            value: tag["id"]
        }));
    }
    const options = getOptions();

    const currentTag = indicatorResponse["tags"][0];

    useEffect(()=>{
        if (selectedTag === undefined || selectedTag === null){
            setSelectedTag(currentTag ?? findTag(options[0]?.value));
        }
    }, [selectedTag]);

    return <>
        <IndicatorCheckpointCard indicatorResponse={indicatorResponse} {...props}>
            <Space direction="vertical">
                <SolvTag color={selectedTag?.color}>{t(selectedTag?.name)}</SolvTag>
                <Select value={selectedTag?.id} onChange={handleValueChange} options={options}  data-cy={`indicator-tag-select-${indicatorResponse.indicator.internal_code}`}/>
            </Space>
        </IndicatorCheckpointCard>
    </>
}

export default IndicatorTagCheckpointCard;