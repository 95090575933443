import BarLineChart from "../components/BarLineChart";
import FunnelChart from "../components/FunnelChart";
import StackedColumnChart from "../components/StackedColumnChart";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom/cjs/react-router-dom";
import React, {useState} from "react";
import ChartCard
    from "../../../../../global/components/data_display/ChartCard";
import SolvCollapse from "../../../../../global/components/misc/SolvCollapse";
import GraphRow from "../components/GraphRow";
import GraphCol from "../components/GraphCol";
import DateRangePicker
    from "../../../../../global/components/data_entry/DateRangePicker";
import moment from "moment";


export default function SectionDashboardProgramGeneralData({filters}) {
    let {id} = useParams();
    const {t} = useTranslation();

    const [applicationsPerDayDates, setApplicationsPerDayDates] = useState([
        moment().subtract(30, 'days').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
    ]);


    return (
        <SolvCollapse
            title={t('General Data')}
            bordered
            active={true}
        >
            <GraphRow>

                <GraphCol>
                    <ChartCard title={t("New applications per day")}
                               expandible={true}
                               extraDrawer={
                                   <DateRangePicker
                                       value={applicationsPerDayDates}
                                       onChange={(value) => setApplicationsPerDayDates(value)}
                                   />
                               }
                               bodyStyle={{padding: "8px 8px 12px 8px"}}>
                        <BarLineChart
                            request={{
                                url: `/dashboard/program_dashboard/${id}/applications_per_day`,
                                params: {
                                    min_date: applicationsPerDayDates[0],
                                    max_date: applicationsPerDayDates[1]
                                },
                                body: filters,
                                config: {version: 'v2'}
                            }}
                            xField="day"
                            yField={["applications_submitted", "applications_in_filling"]}

                        />
                    </ChartCard>
                </GraphCol>

                <GraphCol>
                    <ChartCard title={t("Application by challenge")}
                               expandible={true}>
                        <StackedColumnChart
                            legend_key="challenge"
                            request={{
                                url: `/dashboard/program_dashboard/${id}/applications_by_challenge`,
                                body: filters,
                                config: {version: 'v2'}
                            }}
                            groups={{
                                submitted: {
                                    label: 'submitted',
                                    color: '#096DD9'
                                },
                                not_submitted: {
                                    label: 'not_submitted',
                                    color: '#B37FEB'
                                },
                                given_up: {
                                    label: 'given_up',
                                    color: '#FFD8BF'
                                },
                            }}

                        />


                    </ChartCard>
                </GraphCol>

                <GraphCol>
                    <ChartCard title={t("Application funnel")}
                               expandible={true}>
                        <FunnelChart
                            request={{
                                url: `/dashboard/program_dashboard/${id}/applications_funnel`,
                                body: filters,
                                config: {version: 'v2'}
                            }}
                            xField="phase"/>
                    </ChartCard>
                </GraphCol>

            </GraphRow>
        </SolvCollapse>

    );
}