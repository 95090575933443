import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Col, Form as FormAnt, Row, Typography} from "antd";
import Button from "../../../../../components/data_entry/Button";
import Drawer from "../../../../../components/layout/Drawer";
import Form from "../../../../../components/data_entry/form/Form";

import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import InputFormField
    from "../../../../../components/data_entry/form/InputFormField";
import {
    ICONS_CHOICES,
    TAG_TARGETS_CHOICES
} from "../../../../../utils/Choices";
import SelectFormField
    from "../../../../../components/data_entry/form/SelectFormField";
import useOptionsTranslation from "../../../../../hooks/useOptionsTranslation";
import InputColorFormField
    from "../../../../../components/data_entry/form/InputColorFormField";
import SolvTag from "../../../../../components/tag/SolvTag";
import {BuildFieldErrorsDict} from "../../../../../utils/Utils";
import * as ConstantsMessage from "../../../../../utils/fileWithMessage";
import {
    ToastNotification
} from "../../../../../components/feedback/ToastNotification";
import api from "axios";
import {
    useLayoutContext
} from "../../../../../../modules/project/components/layout/context/LayoutContext";
import CustomTag from "../../../../../components/tag/CustomTag";
import {COLORS} from "../../../../../utils/Colors";


const FORM_NAMES = {
    NAME: 'name',
    COLOR: 'color',

    ICON: 'icon',
    PARENT: 'parent',
    TARGETS: 'targets',
}

export default function AddUpdateNewTagDrawer({
                                                  updateLocalContent,
                                                  setOpen,
                                                  open,
                                                  parent,
                                                  isMainTag,
                                                  tag
                                              }) {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const [form] = FormAnt.useForm()
    const iconValue = FormAnt.useWatch(FORM_NAMES.ICON, form);
    const nameValue = FormAnt.useWatch(FORM_NAMES.NAME, form);
    const colorValue = FormAnt.useWatch(FORM_NAMES.COLOR, form);
    const {updateLayoutContent} = useLayoutContext()

    const {translationOptions} = useOptionsTranslation();

    const HAS_PARENT = !!parent?.id
    const IS_EDIT = !!tag?.id


    useEffect(() => {
        if (open && (HAS_PARENT || IS_EDIT)) {
            const data = {}
            if (HAS_PARENT) {
                data[FORM_NAMES.ICON] = parent.icon,
                    data[FORM_NAMES.COLOR] = parent.color,
                    data[FORM_NAMES.TARGETS] = parent.targets

            }
            if (IS_EDIT) {
                data[FORM_NAMES.NAME] = tag[FORM_NAMES.NAME]
                data[FORM_NAMES.TARGETS] = tag[FORM_NAMES.TARGETS]
                data[FORM_NAMES.COLOR] = tag[FORM_NAMES.COLOR]
                data[FORM_NAMES.ICON] = tag[FORM_NAMES.ICON]
            }


            form.setFieldsValue(data)
        }
    }, [open, HAS_PARENT, IS_EDIT])

    useEffect(() => {
        if (open && !HAS_PARENT && !IS_EDIT) {
           const iconIndex = Math.floor(Math.random() * 13)
           const colorIndex = Math.floor(Math.random() * 36)
            const data = {
                [FORM_NAMES.COLOR]: COLORS[colorIndex],
                [FORM_NAMES.ICON]: ICONS_CHOICES[iconIndex].value

            }

            form.setFieldsValue(data)
        }
    }, [open, HAS_PARENT, IS_EDIT])

    function onClose() {
        setOpen(false)
        form.resetFields()
    }

    async function getValuesForm() {
        let data = await form.getFieldsValue()
        const newData = {...data}
        if (HAS_PARENT) {
            newData['parent'] = parent.id
        }
        return newData
    }


    async function handleSubmit() {
        try {
            setIsLoading(true);
            let data = await getValuesForm()

            if (IS_EDIT) {
                const response = await api.patch(`/api/tags/${tag.id}`, data);
            } else {
                const response = await api.post(`/api/tags`, data);
            }
            onClose()
            if (HAS_PARENT) {
                updateLocalContent()
            } else {
                updateLayoutContent()
            }

            ToastNotification(t(ConstantsMessage.SUCCESS));


        } catch (error) {

            BuildFieldErrorsDict(error, form.setFields, null);
        } finally {
            setIsLoading(false);
        }
    };

    function handleTitle() {
        if (IS_EDIT) {
            return t('Edit tag')
        }
        if (isMainTag) {
            return t('Create new tag')
        } else {
            return t('Create new sub-tab')
        }
    }

    const DISABLED = IS_EDIT

    return (


        <Drawer title={handleTitle()}
                open={open} onClose={onClose}
                footer={
                    <FlexContainer justify="end"><Button
                        onClick={form.submit}
                        loading={isLoading}>{t("Save")}</Button></FlexContainer>
                }>
            <Form form={form} disabled={isLoading} onFinish={handleSubmit}>
                <Row>
                    <Col xs={24}>
                        <InputFormField showCount maxLength={30} form={{
                            name: FORM_NAMES.NAME,
                            label: t('Tag name'), rules: [{
                                required: true,
                                message: t('This field is require')
                            }],
                        }}/>
                    </Col>
                    <Col xs={24}> <SelectFormField
                        mode={'multiple'}
                        allowClear
                        disabled={DISABLED}

                        options={translationOptions(TAG_TARGETS_CHOICES)}
                        form={{
                            help: t('You will not be able to edit this field after creating the tag.'),
                            name: FORM_NAMES.TARGETS,
                            label: t('Applies to'),
                            rules: [{
                                required: true,
                                message: t('This field is require')
                            }],
                        }}/></Col>
                    <Col xs={12}>
                        <InputColorFormField
                            // disabled={DISABLED}
                            form={{
                                name: FORM_NAMES.COLOR,
                                label: t('Color'),
                                rules: [{
                                    required: true,
                                    message: t('This field is require')
                                }],
                            }}/>
                    </Col>
                    <Col xs={12}>
                        <SelectFormField
                            style={{width: 60}}
                            allowClear
                            // disabled={DISABLED}
                            options={ICONS_CHOICES}
                            form={{
                                name: FORM_NAMES.ICON,
                                label: t('Icon'),
                                rules: [{
                                    required: true,
                                    message: t('This field is require')
                                }],
                            }}/>
                    </Col>
                    <Col xs={24}>
                        <Typography.Text>{t('Preview')}</Typography.Text>
                        <CustomTag icon={iconValue} color={colorValue}
                                   name={nameValue}/>
                    </Col>
                </Row>


            </Form>
        </Drawer>


    )
}
