import {InputNumber, Switch} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

import InformationCard from "../../../../../components/data_display/InformationCard";
import TransparentCollapse
    from "../../../../../components/misc/TransparentCollapse";
import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import CollapseFormItem
    from "../../../../../components/data_entry/CollapseFormItem";
import {MAX_LIMIT_GAMIFICATION_POINTS} from "../../../../../utils/Constants";


export default function SectionScoreDistributionAuthors({FORM_NAMES}) {
    const {t} = useTranslation();

    return (

        <TransparentCollapse header={t("Score distribution for authors")}>
            <FlexContainer direction={'column'} gap={16} align={'streech'}>
                <InformationCard title={t('Actions that do not generate points')}
                                 text={t('Leave the value as 0 if you prefer not to award points for this action.')}/>

                <CollapseFormItem
                    input={<InputNumber min={0} max={MAX_LIMIT_GAMIFICATION_POINTS} data-cy="points-per-submission-input"/>}
                    name={FORM_NAMES.SUBMIT_APPLICATION}
                     label={t("When submitting an application")}
                    help={t(
                        "Authors who submit an application will receive points."
                    )}

                />
                <CollapseFormItem
                    input={<InputNumber min={0} max={MAX_LIMIT_GAMIFICATION_POINTS} data-cy="points-per-advance-stage-input"/>}
                    name={FORM_NAMES.CLASSIFIED_APPLICATION}
                     label={t("When an application advances to a new stage")}
                    help={t(
                        "Authors who have submitted an application will earn points when that application advances to a new stage, only once per stage. If the application moves back a stage, it will lose the points earned for the previous advancement."
                    )}

                />
                <CollapseFormItem
                    input={<InputNumber min={0} max={MAX_LIMIT_GAMIFICATION_POINTS} data-cy="points-per-final-approve-input"/>}
                    name={FORM_NAMES.APROVED_APPLICATION}
                     label={t("When your application is approved in the last stage of a program")}
                    help={t(
                        "People who submitted an application will earn points when that application is approved in the last stage, once per program. If a new stage is added after the last one, authors who were in the last stage will lose the points earned in that category."
                    )}

                />
            </FlexContainer>

        </TransparentCollapse>

    );
}
