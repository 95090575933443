import {useTranslation} from "react-i18next";
import TeamSelectorComponent from "./TeamSelectorComponent";
import useTeamSelector from "./hooks/useTeamSelector";
import {Form, Typography} from "antd";
import {dust_red_4} from "../../../utils/Colors";
import React from "react";

const {Text} = Typography;

export default function TeamSelect({value, onChange, displayTypes, disabled = false, enableFormAlert}) {
    const {t} = useTranslation();
    const {teams, setTeams, fetchUserTeams} = useTeamSelector({displayTypes});
    const {status} = Form.Item.useStatus();

    return (
        <TeamSelectorComponent
            status={status}
            value={value}
            onChange={onChange}
            displayTypes={displayTypes}
            disabled={disabled}
            editorMode={false}
            title={
                <Text style={{fontSize: 16}}>
                    <span style={{color: dust_red_4}}>*</span> {t("Select the Participant")}
                </Text>
            }
            teams={teams}
            setTeams={setTeams}
            fetchUserTeams={fetchUserTeams}
            enableFormAlert={enableFormAlert}
        />
    )
}
