
export default function SimpleList({items, fontSize=16}) {
    return (
        <ul>
            {items.map((item, index) => (
                <li key={index} style={{fontSize}}>{item}</li>
            ))}
        </ul>
    );
}

