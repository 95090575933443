import styled from 'styled-components';

export const StyledSpanStatusApplication = styled.span.attrs(() => ({
    className: 'span-status-application'
}))`

.pendingSubmitedText {
    font-size: 14px;
    color: #F4D400;
    background-color: #F8F8F8;
    text-align: center;
    border-radius: 8px;
    padding: 0px 5px;
    height: 23px;
    border: 1px solid #F4D400;
    margin-right: 8px;
    margin-top: 0px
}

.submitedText {
    font-size: 14px;
    color: #51bb25;
    background-color: #F8F8F8;
    text-align: center;
    border-radius: 8px;
    padding: 0px 5px;
    height: 23px;
    border: 1px solid #51bb25;
    margin-right: 8px;
    margin-top: 0px
}

.noSubmitedText {
    background-color: #F8F8F8;
    border: 1px solid #dc3545;
    font-size: 14px;
    color: #dc3545;
    text-align: center;
    border-radius: 8px;
    padding: 0px 5px;
    height: 23px;
    margin-right: 8px
}

`;
