import React from 'react';
import {Row, Typography} from "antd";
import {useTranslation} from "react-i18next";
import WideSpace from "../../../../../layout/WideSpace";
import {
    useApplicationDrawerContext
} from '../../context/ApplicationDrawerContext';
import FilesTable from "../../../../../data_entry/FilesTable";
import UserAvatar from "../../../../../misc/UserAvatar";

const {Text} = Typography;

export default function AttachmentSolutionTab() {
    const {t} = useTranslation();
    const {solution} = useApplicationDrawerContext();

    const extraColumns = [

        {
            title: t("Member"),
            dataIndex: 'owner',
            render: (owner) => <UserAvatar data={owner}/>
        },

    ];


    return (
        <>
            <WideSpace direction="vertical" size={24}>
                <FilesTable hasPermission={solution.logged_user_is_member} fileType={'application'} id={solution.id} table={{
                    extraColumns: extraColumns,
                }}/>
                <Row justify="center">
                    <Text type="secondary" style={{fontSize: 12, fontWeight: 400}}>
                        {t("The attachments included in the proposal are external to the form. If you want to view the attachments inserted into the form, please check the data input step.")}
                    </Text>
                </Row>
            </WideSpace>
        </>
    );
}
