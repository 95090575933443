import React from 'react';
import {Space, Typography} from "antd";
import ScoreEvaluationField from "../../../../../../../global/components/data_entry/form/EvaluationFormBuild/Fields/ScoreEvaluationField";
import {useTranslation} from "react-i18next";


const {Text, Title} = Typography


export default function ScoreHelp({scoreRange}) {
    const {t} = useTranslation()
    const HELP = {
        exampleQuestion: t("The data below is fictitious, just an example, the weight and other elements of the question are changed in the form that can be found in the evaluation stages."),
    }
    return (
        <Space direction="vertical" size="large">
            <Title level={5}>{t("Example question")}</Title>
            <Text style={{fontSize: 14}} type={"secondary"}>
                {HELP.exampleQuestion}
            </Text>
            <ScoreEvaluationField
                min={scoreRange[0]}
                max={scoreRange[1]}
                help_text={t("In financial and time terms.")}
                label={t("How viable is the solution?")}
                weight={50}
            />
        </Space>
    );
}

