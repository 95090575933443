import IndicatorCheckpointCard, { ValueComponent } from "./IndicatorCheckpointCard";
import { Slider } from "antd";
import { useStatusReportContext } from "../../context/StatusReportContext";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const SliderStyler = styled.div`
  .ant-slider-disabled {
    opacity: 0.4;
  }
  
  .ant-slider-disabled .ant-slider-track {
    background-color: ${({ color }) => color} !important;
  }
  .ant-slider-disabled .ant-slider-handle {
    display: none;
  }
  
`;


function IndicatorProgressSliderCheckpointCard({ indicatorResponse, ...props }) {
    const { t } = useTranslation();
    const { indicator } = indicatorResponse;
    const { updateIndicatorResponse } = useStatusReportContext();

    const handleValueChange = (value) => {
        updateIndicatorResponse({ id: indicatorResponse["id"], values: { "scaled_value": value, "value_display": value } });
    };

    const color = indicator["levels_colorized"] ? "#597EF7" : "#8C8C8C";

    const sliderStyle = {
        backgroundColor: color,
        borderColor: color,
    };

    return <>
        <IndicatorCheckpointCard indicatorResponse={indicatorResponse} {...props}>
            <ValueComponent
                value={indicatorResponse["value_display"]}
                prefix={t(indicator["indicator_view"]["field_prefix"])}
                color={color}
            />
            <SliderStyler color={color} data-cy={`indicator-slider-${indicatorResponse.indicator.internal_code}`}>
                <Slider
                    disabled={indicatorResponse["has_formulas"]}
                    value={indicatorResponse["scaled_value"]}
                    onChange={handleValueChange}
                    trackStyle={sliderStyle}
                    handleStyle={sliderStyle}
                    min={indicator["scale_min"]}
                    max={indicator["scale_max"]}

                />
            </SliderStyler>
        </IndicatorCheckpointCard>
    </>;
}

export default IndicatorProgressSliderCheckpointCard;