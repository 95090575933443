import React, {useState} from "react";
import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../utils/Utils";
import {useTranslation} from "react-i18next";
import LayoutLogin from "../../layouts/authentication/LayoutLogin";
import {NavLink, useParams} from "react-router-dom";
import {ToastNotification} from '../../components/feedback/ToastNotification';
import {Space, Typography} from 'antd';
import {GLOBAL_PATHS} from "../../utils/RouteUtils";
import Loading from "../../components/feedback/Loading";

export default function NoticeConfirmEmail() {
    const {t} = useTranslation()
    const {email} = useParams()
    const [isLoading, setIsLoading] = useState(false);

    const sendItAgain = async () => {
        if (email) {
            try {
                setIsLoading(true)
                const data = {
                    'email': email,
                };
                const response = await api.post('auth/register_confirm_email_check/' + email, data);
                if (!response.data.is_confirmed_email) {
                    ToastNotification(t('Email sent'))
                }
            } catch (error) {
                BuildFieldErrorsDict(error, null, false)
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <LayoutLogin>
            {isLoading ? <Loading/> :
                <Space align={"center"} direction={"vertical"}
                       style={{width: '100%', marginTop: 40}}>
                    <Typography.Title level={4}>
                        {t("Confirmation email has just been sent")}!
                    </Typography.Title>
                    <Typography.Paragraph>{t("Please check your email and confirm your email address")}.</Typography.Paragraph>
                    <Typography.Paragraph>{t("Didn't get the email")}?<br/>
                        <NavLink to={"#"}
                                 onClick={sendItAgain}>{t("Send it again")}</NavLink></Typography.Paragraph>

                    <Typography.Paragraph>
                        {t("Email already confirmed")}?<br/>
                        <NavLink
                            to={GLOBAL_PATHS.LOGIN}> {t("Login to your account")}.</NavLink></Typography.Paragraph>
                </Space>
            }
        </LayoutLogin>
    );
}