import {Avatar, Tooltip} from 'antd';
import styled from 'styled-components';

const StyledAvatar = styled(Avatar)`
    background: ${({StyledAvatar, color}) => StyledAvatar ? color : '#d8d8d8'};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
`

function UserAvatar({data, ...props}) {
    const image = data?.picture || data?.picture_url || data?.url || data?.path;
    const name = data?.username || data?.name || data?.full_name || " "

    return (
        <Tooltip title={name}>
            <StyledAvatar
                src={image}
                hasImage={!!image}
                style={!image && {backgroundColor: data?.avatar_color}} {...props} > {(name)[0].toUpperCase()}
            </StyledAvatar>
        </Tooltip>
    )
}

export default UserAvatar;