import React from 'react'
import {Grid, Tag} from "antd";
import styled from 'styled-components';
const Container = styled.div`
    position: fixed;
    z-index: 999999;
`
export default function DevTools() {
    const screens = Grid.useBreakpoint()

    return (
        <Container>

            Current break point:{' '}
            {Object.entries(screens)
                .filter((screen) => !!screen[1])
                .map((screen) => (
                    <Tag color="blue" key={screen[0]}>
                        {screen[0]}
                    </Tag>
                ))}
        </Container>
    )
}