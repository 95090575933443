import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {FlexContainer} from "../authentication/StyledComponents";
import {Col, Image, Modal, Row,} from "antd";
import Button from "../data_entry/Button";
import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../utils/Utils";
import {
    useLayoutContext
} from "../../../modules/project/components/layout/context/LayoutContext";
import Drawer from "../layout/Drawer";
import useDefaultGetRequest from "../../hooks/useDefaultGetRequest";
import styled from "styled-components";
import {neutral_4, neutral_5} from "../../utils/Colors";
import {DateFormatByUserTimezone} from "../../utils/dates";
import {PERCENTAGE_CAMPAIGN} from "../../utils/Choices";
import RemixIcon from "./RemixIcon";
import AmountCardGamification from "./AmountCardGamification";
import GamificationInfo from "./GamificationInfo";


const InfoContainer = styled.div`
    width: 100%;
    border-radius: 11px;
    padding: 16px;
    border: 1px solid ${neutral_5};
    background: white;
`


export default function DrawerCampaignDetail({
                                                 id,
                                                 open,
                                                 setOpen,
                                                 hasClose = true
                                             }) {

    const [isLoadingForm, setIsLoadingForm] = useState(false)
    const [positions, setPositions] = useState([])
    const {t} = useTranslation();
    const {updateLayoutContent} = useLayoutContext()

    function onClose() {
        setOpen(false)
    }

    const {
        data: campaign,
        isLoading
    } = useDefaultGetRequest({
        url: `/point_event/${id}`,
        makeRequest: open,
        dependence: [open]
    })


    function handleCloseCampaign() {
        Modal.confirm({
            title: t("Close campaign"),
            icon: <ExclamationCircleOutlined/>,
            content: t("Are you sure you want to close the campaign?"),
            onOk: async () => {
                try {
                    setIsLoadingForm(true);

                    await api.post(`/point_event/${id}/finish_campaign`);
                    updateLayoutContent()
                } catch (error) {
                    BuildFieldErrorsDict(error, null, null)
                } finally {
                    setIsLoadingForm(false);
                }
            },
            cancelButtonProps: {loading: isLoadingForm},
            okText: t('Yes'),
            cancelText: t('No')

        });

    }

    return (
        <>
            <Drawer loading={isLoading} isGrayBody title={t('See campaign')} open={open}
                    onClose={onClose}


                    footer={
                        hasClose && <FlexContainer justify="end">
                            <Button
                                onClick={handleCloseCampaign}
                                loading={isLoadingForm}
                                danger>{t("Close campaign")}</Button></FlexContainer>
                    }>
                <Row gutter={[16, 16]}>

                    <Col xs={24}>
                        <InfoContainer>
                            <Row gutter={[8, 8]}>
                                <Col xs={24} sm={10}>
                                    <GamificationInfo title={t('Title')}
                                          value={campaign?.name}/>
                                </Col>
                                <Col xs={24} sm={4}>
                                    <GamificationInfo title={t('Bonus')}
                                          value={`${campaign?.amount}${campaign?.method === PERCENTAGE_CAMPAIGN ? '%' : ''}`}/>
                                </Col>
                                <Col xs={24} sm={10}>
                                    <GamificationInfo title={t('Period')}
                                          value={`${DateFormatByUserTimezone(campaign?.date_start)} - ${DateFormatByUserTimezone(campaign?.date_end)}`}/>
                                </Col>
                            </Row>
                        </InfoContainer>
                    </Col>
                    <Col xs={24}>
                        <AmountCardGamification
                            value={campaign?.total_aditional_points}
                            title={t('Additional points generated')}
                            color={"#6A78BE"}
                            icon={<RemixIcon
                                remixIconName={'ri-copper-coin-line'}/>}/>
                    </Col>
                      <Col xs={24}>
                        <Image src={campaign?.img_url}/>
                    </Col>
                </Row>


            </Drawer>
        </>
    )
}

