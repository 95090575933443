import React from "react";
import DefaultComponent from "./DefaultComponent";
import TextArea from "../../../Form/TextArea";
import {useTranslation} from "react-i18next";

function TextAreaForm(props) {
    const {t} = useTranslation();

    return (
        <DefaultComponent{...props}>
            <TextArea placeholder={t("Type your answer here...")} />
        </DefaultComponent>
    )
}

export default TextAreaForm