import {useEffect, useRef, useState} from "react";
import useTable from "../../../hooks/useTable";
import {useTranslation} from "react-i18next";
import api from "../../../services/api";
import {ToastNotification} from "../../../components/feedback/ToastNotification";
import {BuildFieldErrorsDict} from "../../../utils/Utils";

export const REPORTS = {
    PROGRAM_LIST: `v2/program_export_controller/export_program_list`,
    PROJECT_LIST: 'v2/project_management/project_export_controller/export_project_list',
    STATUS_REPORT_LIST: `v2/project_management/project_export_controller/export_status_report_list`,
}

export const generateExportReport = (url) => {
    return async (event) => {
        try {
            event.detail?.setIsLoading?.(true);
            const {search, order, body, columns} = event.detail;
            const params = new URLSearchParams(
                Object.entries({ search, order }).filter(([_, value]) => value !== undefined)
            );
            const data = {filters: body, columns: columns};
            await api.post(`${url}?${params.toString()}`, data);
            // ToastNotification(response.data.message);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        } finally {
            dispatchEvent(new Event(`open-notification-export-tab`))
            event.detail?.setIsLoading?.(false);
        }
    }
}


function getColumnKey(column) {
    if (column.exportKey) {
        return column.exportKey
    }

    const dataIndex = column.dataIndex
    if (Array.isArray(dataIndex)) {
        return dataIndex.join('.')
    }
    return dataIndex
}

function getExportFileName(name, scope, scopeObj) {
    const {t} = useTranslation()

    const tableTitleByName = {
        application: t("Applications"),
        assessment: t("Assessments"),
        companyTeams: t("Companies & Teams"),
        member: t("Team Members"),
        challenge: t("Challenges")
    }

    if (scope) {
        switch (scope) {
            case "programs":
                return `${t("Program")} - ${scopeObj.name} - ${tableTitleByName[name]}.csv`
            case "phases":
                return `${t("Program")} - ${scopeObj.program_name} - ${t("Phase")} - ${scopeObj.title} - ${tableTitleByName[name]}.csv`
        }
    }
    return `${tableTitleByName[name]}.csv`
}

function getValue(obj, path) {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
}

function setValue(obj, path, value) {
    const keys = path.split('.');
    keys.reduce((acc, part, index) => {
        // If we are at the last part of the path, set the value
        if (index === keys.length - 1) {
            acc[part] = value;
        } else {
            // Ensure that the path exists; if not, create an empty object
            if (!acc[part]) {
                acc[part] = {};
            }
        }
        return acc[part];
    }, obj);
}

function fixData(columns, data) {
    if (!columns || !data) return;
    return data?.map(item => {
        const transformedItem = {...item}
        if (!item?.team) {
            item.team = {}
        }
        columns.forEach(column => {
            let value = getValue(item, column.dataIndex.join(".")) || "-";
            if (column.exportCallback && value !== "-") {
                value = column.exportCallback(value, item);
            }
            setValue(transformedItem, column.dataIndex.join("."), value);
        });
        return transformedItem;
    });
}

export default function useExportDatabase({
                                              columns,
                                              url,
                                              config,
                                              body,
                                              extraParams,
                                              name,
                                              scope,
                                              scopeObj,
                                              search,
                                              order,
                                          }) {

    const exportRef = useRef();
    const exportFileName = getExportFileName(name, scope, scopeObj)
    const [isExporting, setIsExporting] = useState(false)
    const [isExportingProgramData, setIsExportingProgramData] = useState(false)

    const headers = columns?.map(column => (column.visible && {
        label: column.title,
        key: getColumnKey(column)
    }))?.filter(item => item !== false)

    const {tableData, setTableData, handleRequestTable, isLoading} = useTable({
        url,
        config,
        body,
        extraParams,
        waitExternalLoading: true,
        forcePost: true,
        hasPagination: false
    })


    const handleExport = () => {
        const event = {
            detail: {setIsLoading: setIsExporting, search, order, body, columns: columns.filter(col=>col.visible).map(col=>(col.dataIndex))}
        }
        if (name === "programs") {
            void generateExportReport(REPORTS.PROGRAM_LIST)(event)
        }

        else if (name === "projects"){
            void generateExportReport(REPORTS.PROJECT_LIST)(event)
        }

        else {
            handleRequestTable()
        }

    }

    const handleExportProgramData = async () => {
        try {
            setIsExportingProgramData(true)
            const response = await api.get(`v2/program_export_controller/export_program_applications_with_response/${scopeObj?.id}`)
            ToastNotification(response?.data?.message)
        } catch (error){
            BuildFieldErrorsDict(error, null, false)
        }
        finally {
            setIsExportingProgramData(false)
        }
    }

    useEffect(() => {
        tableData && exportRef.current?.link?.click()
        setTableData(undefined)
    }, [tableData, scopeObj]);

    const exportData = (fixData(columns, tableData))
    return ({
        handleExport,
        exportRef,
        exportHeaders: headers,
        exportData,
        exportFileName,
        isExportLoading: isLoading,
        isExporting: isExporting || isLoading,
        isExportingProgramData,
        handleExportProgramData,
    })

}