import {useTranslation} from "react-i18next";
import useSystemBreakpoint from "../../../../../../../../hooks/useSystemBreakpoint";
import {Slider, Space, Typography} from "antd";
import SolvTable from "../../../../../../../tables/SolvTable";
import React from "react";
import styled from "styled-components";
import {getScoreHeadlightColor} from "../../../../../../../../utils/Utils";


const {Text} = Typography

const StyledSlider = styled(Slider)`
    cursor: initial;

    .ant-slider-handle:after {
        cursor: initial;
    }
`

function renderSlider(value, row) {
    const min = parseInt(row?.scale?.min || 0)
    const max = parseInt(row?.scale?.max || 10)
    const color = getScoreHeadlightColor({score: value, max: max});


    return <StyledSlider value={value} min={min} max={max}
                         trackStyle={{backgroundColor: color}}
                         handleStyle={{borderColor: color}}
                         tooltip={{open: false}}
                         marks={{
                             [min]: <Text style={{fontSize: 10}}>{min}</Text>,
                             [value]: {label: <Text style={{fontWeight: 700, fontSize: 14, color}}>{value}</Text>},
                             [max]: <Text style={{fontSize: 10, textWrap: "nowrap"}}>{max}</Text>
                         }}
    />
}


export default function AssessmentFormContent({comp_score, viewerMode}) {
    const {t} = useTranslation();
    const {isDesktop} = useSystemBreakpoint();

    const columns = [
        {
            title: t("Evaluator"),
            dataIndex: "evaluator",
            // width: isDesktop && 224,
            render: (value) => viewerMode ? "-" : value
        },
        {
            title: t("Score"),
            dataIndex: "score",
            width: isDesktop && 184,
            render: renderSlider,
        },
        {
            title: t("Feedback"),
            dataIndex: "feedback",
        }
    ];


    return <Space style={{width: "100%"}} direction="vertical" size={16}>
        {comp_score.map((question, key) => {
            const hiddenColumns = []
            if (question?.type !== "score_with_feedback") {
                hiddenColumns.push("score")
            } else if (!isDesktop) {
                hiddenColumns.push("feedback")
            }
            return (
                <>
                    <Text style={{fontSize: 18, fontWeight: 500}}>{question.title}</Text>
                    <SolvTable columns={columns} dataSource={question.reponses} hiddenColumns={hiddenColumns}/>
                </>
            )
        })}
    </Space>
}