import {Button} from "antd";
import React, {useEffect, useState} from "react";
import SolvCollapse from "../../../../global/components/misc/SolvCollapse";
import api from "../../../../global/services/api";
import {PlusOutlined} from "@ant-design/icons";
import {useLayoutContext} from "../../components/layout/context/LayoutContext";
import {useTranslation} from "react-i18next";
import ClientIndicatorTable from "../../components/tables/ClientIndicatorTable";
import WideSpace from "../../../../global/components/layout/WideSpace";
import IndicatorDrawer from "./components/IndicatorDrawer";

function ProjectSettingsPage() {
    const {t} = useTranslation();
    const [showIndicatorDrawer, setShowIndicatorDrawer] = useState(false);
    const [currentIndicator, setCurrentIndicator] = useState(undefined);
    const [clientIndicators, setClientIndicators] = useState(undefined);
    const {openNotification} = useLayoutContext();

    const handleOpenIndicatorDrawer = (record) => {
        setCurrentIndicator(undefined)
        record?.id ? void fetchUpdateIndicator(record.id) : setCurrentIndicator(record)
        setShowIndicatorDrawer(true)
    };

    const fetchClientIndicators = async () => {
        try {
            const response = await api.get(`/project_management/table/client_indicator_table`);
            setClientIndicators(response.data.items.map((indicator) => ({
                ...indicator, name: t(indicator.name)
            })));
        } catch (exception) {
        }
    }

    const handleIndicatorCreate = () => {
        setShowIndicatorDrawer(false)
        setClientIndicators(undefined)
    }

    const fetchUpdateIndicator = async (id) => {
        const response = await api.get(`/project_management/client_indicators/${id}`);
        setCurrentIndicator(response.data);
    };

    const showSuccessMessage = () => {
        openNotification({type: "success", message: t("Indicator status updated!")});
    };

    const toggleIndicatorStatus = async (id) => {
        await api.patch(`/project_management/client_indicators/${id}`, {is_active: !currentIndicator?.is_active});
        void fetchClientIndicators();
    };

    const handleIndicatorActivation = async () => {
        try {
            await toggleIndicatorStatus(currentIndicator?.id);
            setShowIndicatorDrawer(false);
            showSuccessMessage();
        } catch (error) {
            setShowIndicatorDrawer(false);
            openNotification({type: "error", message: error.response.data.errors});
        }
    };

    useEffect(() => {
        !clientIndicators && void fetchClientIndicators()
    }, [clientIndicators])

    return (
        <>
            <WideSpace direction="vertical">
                <SolvCollapse title={t("Indicators")} active={true} locked={true} padding={0}
                              renderExtra={
                                  <Button type="primary" onClick={() => handleOpenIndicatorDrawer({})}
                                          data-cy="insert-indicator-button"
                                  >
                                      <PlusOutlined/>{t("Insert Indicator")}
                                  </Button>
                              }>
                    <ClientIndicatorTable dataSource={clientIndicators} onRowClick={handleOpenIndicatorDrawer}/>
                </SolvCollapse>
            </WideSpace>
            <IndicatorDrawer
                openDrawer={showIndicatorDrawer}
                setOpenDrawer={setShowIndicatorDrawer}
                onClose={handleIndicatorCreate}
                currentIndicator={currentIndicator}
                fetchUpdateIndicator={fetchUpdateIndicator}
                handleIndicatorActivation={handleIndicatorActivation}
            />
        </>
    );
}

export default ProjectSettingsPage;