import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {
    ExclamationCircleOutlined,
    PlusOutlined, SettingOutlined,
} from "@ant-design/icons";
import Button from "../../../../../components/data_entry/Button";
import Drawer from "../../../../../components/layout/Drawer";

import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import api from "../../../../../services/api";
import {
    ToastNotification
} from "../../../../../components/feedback/ToastNotification";
import * as ConstantsMessage from "../../../../../utils/fileWithMessage";
import {BuildFieldErrorsDict} from "../../../../../utils/Utils";
import {
    useLayoutContext
} from "../../../../../../modules/project/components/layout/context/LayoutContext";

import RemixIcon from "../../../../../components/data_display/RemixIcon";
import {neutral_5} from "../../../../../utils/Colors";
import {Modal} from "antd";
import Table from "../../../../../components/data_display/Table";
import useTable from "../../../../../hooks/useTable";
import Flex from "../../../../../components/responsive/Flex";
import DrawerAddPoints from "./DrawerAddPoints";
import UserHistorypointsButton from "./UserHistorypointsButton";
import {DateFormatByUserTimezone} from "../../../../../utils/dates";
import TagPurchaseGamification
    from "../../../../../components/data_display/TagPurchaseGamification";
import {SUCCESSFUL_PURCHASE} from "../../../../../utils/Constants";


const QUERY_PARAMS_NAMES = {
    SEARCH: 'search',
}

export default function SectionPurchaseTable({search}) {
    const {t} = useTranslation();
    const [currentUserToAddPoint, setCurrentUserToAddPoint] = useState()
    const [userId, setUserId] = useState()
    const [openUserHistoryPoint, setOpenCurrentUserHistoryPoint] = useState()


    const {
        tableData, isLoading, tableParams, handleTableChange, resetTable
    } = useTable({
        // useHandleRequestTable: false,
        url: `store_purchase`,
        hasPagination: true,
        extraParams: {
            [QUERY_PARAMS_NAMES.SEARCH]: search,
        },
        dependenceRequest: [search],
    })

    const columns = [

        {
            title: t('Buyer'),
            dataIndex: 'buyer_name',
            sorter: 'buyer_name',
            key: 'buyer_name',
        }, {
            title: t('Item'),
            dataIndex: 'item_name',
            sorter: 'item_name',
            key: 'item_name',
        },
        {
            title: t('Status'),
            dataIndex: 'status',
            sorter: 'status',
            key: 'status',
            render: (value) => <TagPurchaseGamification status={value}/>

        },
        {
            title: t('Purchase date'),
            dataIndex: 'created_at',
            sorter: 'created_at',
            key: 'created_at',
            render: (value) => DateFormatByUserTimezone(value)

        }, {
            title: t('Sales Manager'),
            dataIndex: 'user_confirmed_name',
            sorter: 'user_confirmed_name',
            key: 'user_confirmed_name',
            render: (value, purchase) => <>
                {purchase?.status === SUCCESSFUL_PURCHASE && !value ? t('Automatic') : value || '-'}
            </>

        },
        {
            title: t('Confirmation date'),
            dataIndex: 'updated_at',
            sorter: 'updated_at',
            key: 'updated_at',
            render: (value, purchase) => <>
                {purchase?.status === SUCCESSFUL_PURCHASE ? DateFormatByUserTimezone(value) : '-'}
            </>

        },

    ]


    function updateCounts() {
        // handleRefreshCounts()
        resetTable()
    }

    return (
        <>
            <Table columns={columns}
                   onChange={handleTableChange}
                   tableParams={tableParams}
                   loading={isLoading}
                // onRowClick={handleOnRowClick}
                   noScroll
                   dataSource={tableData}/>
            <DrawerAddPoints updateCounts={updateCounts}
                             user_id={currentUserToAddPoint}
                             onClose={() => setCurrentUserToAddPoint(null)}/>
            <UserHistorypointsButton userId={userId}
                                     open={openUserHistoryPoint}
                                     setOpen={setOpenCurrentUserHistoryPoint}/>
        </>


    )
}
