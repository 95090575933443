import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import api from "../../../../global/services/api";
import {getProgram} from "../../../../global/utils/_query";
import {BuildFieldErrorsDict} from "../../../../global/utils/Utils";
import {useTranslation} from "react-i18next";
import RoutesDatabase from "../../../../modules/program/routes/RoutesDatabase";
import {useDatabaseContext} from "../../../../modules/program/context/DatabaseContext";
import {useLayoutContext} from "../../../../modules/project/components/layout/context/LayoutContext";
import {Flex, Select, Tooltip} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";


export default function DatabasePhase({hasPermission, location}) {
    const {
        changeCompaniesUrls,
        changeCompaniesMembersUrl,
        changeApplicationsUrl,
        hiddenApplicationFilter,
        changeAssessmentsUrl
    } = useDatabaseContext();
    const {id, phase_id} = useParams();
    const [phase, setPhase] = useState({});
    const [program, setProgram] = useState({public: true});
    const {setIsConfidential} = useLayoutContext();
    const {t} = useTranslation();
    const [extraFilters, setExtraFilters] = useState({"historic": false});

    useEffect(() => {
        if (program && phase) {
            setIsConfidential(program["check_is_confidential"] && phase["is_confidential"])
        }
    }, [program, phase]);

    useEffect(() => {
        changeCompaniesUrls(`phases/${phase_id}/teams_database`);
        changeCompaniesMembersUrl(`phases/${phase_id}/team_members_database`);
        changeApplicationsUrl(`phases/${phase_id}/applications_database`);
        changeAssessmentsUrl(`/database/phase/${phase_id}/evaluations_database`);
        hiddenApplicationFilter(["current_phase__id__in"]);
    }, [location]);

    const getPhase = async () => {
        try {
            const response = await api.get(`phases/${phase_id}`);
            setPhase(response.data);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
    };

    useEffect(() => {
        void getProgram(id, null, setProgram);
        void getPhase();
    }, []);

    const handleFilterChange = (value) => {
        setExtraFilters({"historic": value});
    }

    return (
        <>
            <RoutesDatabase
                extraFilters={extraFilters}
                hideLinks={extraFilters?.hasOwnProperty("historic") && {membersLink: true, companiesLink: true}}
                hideExtraContentOn={(currentLink, links) => {
                    return currentLink !== links.applicationsLink
                }}
                scope="phases"
                scopeObj={phase}
                extraContent={
                    phase?.type === 1 &&
                    <Flex style={{marginRight:-10}}>
                        <Tooltip
                            title={t("Choose between seeing the active applications in the phase or the complete history with all the applications.")}>
                            <InfoCircleOutlined/>
                        </Tooltip>
                        <Select
                            defaultValue={false}
                            onChange={handleFilterChange}
                            variant="borderless"
                            dropdownStyle={{minWidth:150}}
                            options={[
                                {value: false, label: t("Actives in phase")},
                                {value: true, label: t("Historic")},
                            ]}
                        />
                    </Flex>
                }
                hasPermission={hasPermission}
            />
        </>
    );
}
