import {useTranslation} from "react-i18next";
import WideSpace from "../../../../../../layout/WideSpace";
import {Button, Typography, Row} from "antd";
import {useApplicationDrawerContext} from "../../../context/ApplicationDrawerContext";
import usePhaseForm from "../../../../../../../hooks/usePhaseForm";
import LoadingFull from "../../../../../LoadingFull";
import ApplicationFormContent from "./ApplicationFormContent";
import SolvTag from "../../../../../../tag/SolvTag";
import {useEffect} from "react";
import {useState} from "react";
import api from "../../../../../../../services/api";
import EnvironmentLink from "../../../../../../link/EnvironmentLink";
import ApplicationTagStatus from "../../../../../../tag/ApplicationTagStatus";
import ApplicationStatisticPanel from "./ApplicationStatisticPanel";


const {Text} = Typography;

export default function CollapseContentUserView({phase, solutionResult}) {
    const {t} = useTranslation()
    const [feedbacks, setFeedbacks] = useState(undefined);
    const {solution, renderPhaseContent} = useApplicationDrawerContext();
    const {form, isLoading} = usePhaseForm(solution, phase);

    const getFeedback = async () => {
        try {
            const response = await api.get(`solutions/${solution.id}/phase/${phase.sequence}/feedbacks`, {version: "v2"});
            setFeedbacks(response.data);
        } catch (exception) {
        }
    }

    useEffect(() => {
        void getFeedback();
    }, [phase, solution]);

    const applicationContent = () => (
        <WideSpace direction="vertical" size={24}>
            <Row justify="space-between">
                <Text style={{fontSize: 16, fontWeight: 500}}>{t("This phase requires form filling")}.</Text>
                <EnvironmentLink to={`/solutions/${solution.id}`} clientUrl={solution.program.client_url}>
                    <Button type="primary">{t("Open Form")}</Button>
                </EnvironmentLink>
            </Row>
        </WideSpace>
    )

    const evaluationContent = (phase) => {
        if (solution.program.allow_applicants_feedback_view) {
            return <ApplicationStatisticPanel form={form} solutionResult={solutionResult} viewerMode={true} viewMode={solution?.program?.programsettings?.team_evaluation_result_view_type} phase={phase}/>
        }
        const lastFeedback = feedbacks?.at(-1);
        return <>
            <ApplicationTagStatus application={solutionResult}/>
            {feedbacks ?
                (lastFeedback?.message && <>
                    <Text style={{fontSize: 16, fontWeight: 500}}>{t("Company feedback generated by Solv")}:</Text>
                    <Text>{lastFeedback.message}</Text>
                </>) :
                <LoadingFull/>
            }
        </>
    }


    return (
        <WideSpace direction="vertical">
            {isLoading ? <LoadingFull/> :
                renderPhaseContent({
                    phase, solutionResult,
                    applicationContent: <ApplicationFormContent form={form} phase={phase}/>,
                    evaluationContent: evaluationContent(phase),
                    applicationIncompleteContent: solution.program.enable_application_editing ?
                        <ApplicationFormContent form={form} phase={phase} solution={solution}/> : applicationContent(),
                    evaluationIncompleteContent: <Text style={{
                        fontSize: 16,
                        fontWeight: 500
                    }}>{t("This application has not yet been evaluated at this phase")}.</Text>
                })
            }
        </WideSpace>
    )
}
