import React, {useState} from "react";
import ProjectIndicatorDrawer from "./drawer/ProjectIndicatorDrawer";
import api from "../../../global/services/api";
import {Space, Card, Typography} from "antd";
import {Link, useParams} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { renderIndicator } from "./tables/utils";
import useTable from "../../../global/hooks/useTable";
import Table from "../../../global/components/data_display/Table";
import styled from 'styled-components';
import { useProjectManagementContext } from "../pages/ProjectManagement/context/ProjectManagementContext";

const {Text} = Typography;

const StyledCard = styled(Card)`
    .ant-card-head-title {
        white-space: normal !important;
    }
`;

function CheckpointProjectIndicatorsList({statusReport, maxTagsOverflow=1}) {
    const { t } = useTranslation();
    const [currentUpdatingIndicatorResponse, setCurrentUpdatingIndicatorResponse] = useState();
    const [showIndicatorDrawer, setShowIndicatorDrawer] = useState(false);
    const {id} = useParams();
    const {checkProjectPermissionContext} = useProjectManagementContext();

    const {
        tableData, tableParams, isLoading, handleTableChange, resetTable
    } = useTable({
        url: `/project_management/project_indicator_responses/project/${id}/list`,
        // waitExternalLoading: !statusReport,
        hasPagination: true,
        pageSize: 10
    });


    const fetchCurrentUpdatingIndicator = async (id) => {
        setCurrentUpdatingIndicatorResponse(undefined);
        const response = await api.get(`/project_management/project_indicator_responses/${id}`);
        setCurrentUpdatingIndicatorResponse(response.data);
    }
    const handleOpenProjectIndicatorDrawer = (record) => {
        void fetchCurrentUpdatingIndicator(record.id);
        setShowIndicatorDrawer(true);
    }

    const columns = [
        {title: t("Indicator"), dataIndex: "name", width: "280px", key: 0, render: (name)=>t(name)},
        {title: t("Value"), dataIndex: "", key: 1, render: (indicator)=>renderIndicator(indicator, maxTagsOverflow)},
    ];

    if (tableData?.length === 0) {
        return <></>
    }
    return <>
        <ProjectIndicatorDrawer
            currentUpdatingIndicatorResponse={currentUpdatingIndicatorResponse}
            open={showIndicatorDrawer}
            onClose={()=>setShowIndicatorDrawer(false)}
            refresh={resetTable}
        />
        <StyledCard
            padding={"1px"}
            headStyle={{textWrap: "wrap"}}
            bodyStyle={{padding: 0}}
            title={<Space size="small" direction="vertical">
                    <Text>{t("Project Indicators")}</Text>
                    <Text style={{fontSize: 12, fontWeight: "normal"}}>
                        {t("Editable indicators only in project")} <Link to={`/project-portfolio/project/${id}/settings`}>{t("settings")}</Link>.
                    </Text>
            </Space>}
        > 
           <Table 
                columns={columns} 
                scroll={{ x: 0 }}
                dataSource={tableData} 
                onRowClick={checkProjectPermissionContext("manager") && handleOpenProjectIndicatorDrawer} 
                onChange={handleTableChange}
                isLoading={isLoading} 
                tableParams={tableParams}
                showSizeChanger={false}
                showQuickJumper={false}
                hideOnSinglePage={true}
            />
        </StyledCard>
    </>;
}

export default CheckpointProjectIndicatorsList;