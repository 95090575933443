import styled from "styled-components";
import {Button} from "antd";
import PasswordChecklist from "react-password-checklist";
import {neutral_2, neutral_5, text_color} from "../../utils/Colors";
export const AuthButton = styled(Button)`

  background: var(--login_background_button) !important;
  color: var(--login_text_color_button) !important;

`


export const PassWordCheckList = styled(PasswordChecklist)`

  li {
    align-items: center;
  }

  li span {
    color: ${(color) => color ? color : text_color} !important;
    opacity: 1;

  }

  .li {
    //margin: 20px;
  }

  .valid {
    margin-bottom: 4px;
  }

  .invalid {
    margin-bottom: 2px;
    border: 1px solid transparent !important
  }
`

export const FlexContainer = styled.div`
    display: flex;
    align-items: ${({align}) => align ? align : 'center'};
    justify-content: ${({justify}) => justify ? justify : 'start'};
    
    gap: ${({gap}) => gap ? gap : 0}px;
    //width: 100%;
    flex-direction: ${({direction}) => direction ? direction : 'row'} !important;
    flex-wrap: ${({wrap}) => wrap ? wrap : 'nowrap'} !important;

`


export const QuestionContainer = styled.div`
    border-radius: 8px;
    overflow: hidden;
    padding: 24px;
    border: 1px solid ${neutral_5};
    background-color: white;
`

export const QuestionTitle = styled.span`
    font-weight: bold;
    font-size: 14px;
`
export const QuestionDescription = styled.span`
    font-weight: bold;
    font-size: 12px;
`

export const GrayContainer = styled.div`
    background: ${neutral_2};
    display: block;
    border: 1px solid ${neutral_5};
    padding: ${({padding}) => padding? padding: '16px'};
    border-radius: 8px;
    width: 100%;
`