import {useEffect, useState} from "react";
import {
    get_filter_params,
    set_filter_params
} from "../utils/TableStorageService";
import api from "../services/api";
import {BuildFieldErrorsDict} from "../utils/Utils";

export default function useTable({
    url,
    makeRequest=true,
    waitExternalLoading=false,
    hasPagination = true,
    fetchData,
    search = '',
    sorter = '',
    pageSize = 10,
    useHandleRequestTable = true,
    processResponse,
    config,
    body,
    dependenceRequest=[],
    extraParams,
    addLoad = true,
    forcePost = false
}) {
    const [tableData, setTableData] = useState()
    const [isLoading, setIsLoading] = useState()
    const [order, setOrder] = useState(get_filter_params('ordening'))
    const [firstRequest, setFirstRequest] = useState(true);

    const INITIAL_TABLE_PARAMS = {
        // sorter: get_filter_params('sorter', null),
        sorter: sorter,
        pagination: {
            // current: get_filter_params('currentPage', 1),
            // pageSize: get_filter_params('pageSize', 20),
            current: 1,
            pageSize: pageSize
        },
    }

    const [tableParams, setTableParams] = useState(INITIAL_TABLE_PARAMS);

    function resetAllStates(){
        setOrder('')
        setTableParams(INITIAL_TABLE_PARAMS)
    }

    function resetTable() {
        handleTableChange({
            ...tableParams.pagination,
            current: 1
        })
    }


    function handleRequestTable() {
        if (firstRequest) {
            handleTableChange(tableParams.pagination, null, tableParams.sorter)
            setFirstRequest(false)
        } else {
            resetTable()
        }
    }

    useEffect(() => {
        if (useHandleRequestTable && !waitExternalLoading && makeRequest) {
            handleRequestTable()
        }
    }, [search, url, ...dependenceRequest])


    const handleTableChange = (pagination, filters, sorter, extra) => {
        let orderAux = '';
        let _order = order;
        if (sorter && sorter.order && Object.keys(sorter).length > 0) {
            orderAux = sorter.order === 'ascend' ? '' : '-'
            orderAux += sorter.column.sorter
            setOrder(orderAux)
            _order = orderAux
        } else if (sorter && sorter.order === undefined) {
            setOrder('')
            _order = orderAux
        }
        let fetchInfo = fetchData ? fetchData : fetchDataDefault
        void fetchInfo({
            setIsLoading,
            pagination,
            order: _order,
            setTableParams,
            setTableData,
            firstRequest
        });

        // set_filter_params('search', search)
        // set_filter_params('sorter', sorter)
        // set_filter_params('pageSize', pagination.pageSize)
        // set_filter_params('currentPage', pagination.current)
        // set_filter_params('ordening', _order)

    };

    const getProcessedResponse = (data, response) => {
        if (processResponse) {
            data = processResponse(data, response);
        }
        return data;
    }

    const fetchDataDefault = async ({
                                        setIsLoading,
                                        pagination,
                                        order,
                                        setTableParams,
                                        setTableData
                                    }) => {

        // Função de exemplo e fetch default
        try {
            setIsLoading(true)
            const params = {search, ...extraParams};
            if (hasPagination) {
                params["page_size"] = pagination.pageSize;
                params["page"] = pagination.current
            }
            params["order"] = order || ""

            let response;

            if (body || forcePost) {
                response = await api.post(`${url}`, body, {params, ...config});
            } else {
                response = await api.get(`${url}?`, {params, ...config});
            }

            let total;
            let data = getProcessedResponse(response.data, response);

            if (hasPagination) {
                if(!(data.hasOwnProperty("results"))){
                    throw new Error("The backend needs to return an paginated object format ({results: ..., count:...}).)")
                }
                total = data.count
                data = data.results
            }

            setTableData(data);
            setTableParams((tableParams) => {
                return {
                    ...tableParams,
                    pagination: {
                        ...pagination,
                        total: total
                    }
                }
            })
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoading(false);
        }
    };


    return {
        order,
        isLoading,
        setIsLoading,
        tableData,
        setTableData,
        tableParams,
        setTableParams,
        resetTable,
        handleTableChange,
        handleRequestTable,
        resetAllStates
    }
}