import UserAvatar from "../../../../../misc/UserAvatar";
import {Col, Row, Space, Typography, Image, Button} from "antd";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import WideSpace from "../../../../../layout/WideSpace";
import DisplayTextElements from "../../../../../misc/DisplayTextElements";
import useSystemBreakpoint from "../../../../../../hooks/useSystemBreakpoint";
import {getTimeLimitType} from "../../../../../../utils/Utils";
import ChallengeDefaultImage from "../../../../../../../assets/img/challenge_default_image.png"
import {toTitleCase} from "../../../../../../../modules/project/utils/utils";
import useApplication from "../../../../../../hooks/useApplication";
import { DatetimeFormatByTimezone } from "../../../../../../utils/dates";
import MultiLineText from "../../../../../data_display/MultiLineText";
import { useUserContext } from "../../../../../../context/UserContext";

const {Text} = Typography;

const StyledBoldText = styled(Text)`
  font-size: 14px;
  font-weight: 500;
`;

const StyledImage = styled(Image)`
  height:300px;
  object-fit: cover;
  img{
    width: 100%;
    height: 100%;
  }
`


export default function DescriptionChallengeTab({challenge, preview=false}) {
    const {t} = useTranslation();
    const {isDesktop} = useSystemBreakpoint();
    const {handleApplyChallenge} = useApplication();
    const dateEnd = DatetimeFormatByTimezone(challenge?.program.first_phase_ending);

    return (
        <WideSpace direction="vertical" size="large">
            <Row gutter={[32, 0]} height={300}>
                <Col span={isDesktop ? 12 : 24}>
                    <WideSpace direction="vertical" size="middle"
                               style={{height: "100%", justifyContent: "space-between"}}>
                        <WideSpace direction="vertical">
                            <Row>
                                <Space>
                                    <UserAvatar size="large" data={{picture: challenge?.client_profile}}/>
                                    <StyledBoldText>{challenge?.program.name}</StyledBoldText>
                                </Space>
                            </Row>
                            <Row>
                                {challenge?.program.first_phase_ending &&
                                    <Col span={12}>
                                        <DisplayTextElements title={t("REGISTRATIONS UP UNTIL")} content={dateEnd} type={getTimeLimitType(challenge?.program.days_left)}/>
                                    </Col>
                                }
                                <Col span={12}>
                                    <DisplayTextElements
                                        title={t("TYPE OF SUBMISSION")}
                                        content={challenge?.program.team_types_display}
                                        labelField="name"
                                        mode="list"
                                    />
                                </Col>
                            </Row>
                        </WideSpace>

                    <Row>
                        <Col flex="auto">
                            <Button style={{width: "100%"}} type="primary"
                                    disabled={preview}
                                    onClick={() => handleApplyChallenge(challenge)}
                                    data-cy="suggest-button">
                                {`${t("Suggest")} ${t(toTitleCase(challenge?.program.application_name_display))}`}
                            </Button>
                        </Col>
                    </Row>
                
                    </WideSpace>
                </Col>
                {isDesktop && (
                    <Col span={12}>
                        <StyledImage width="100%" src={challenge?.profile ? challenge?.profile: ChallengeDefaultImage}/>
                    </Col>
                )}
            </Row>
            <Row>
               {challenge?.description && 
                    <WideSpace direction="vertical">
                        <StyledBoldText>{t("Description")}</StyledBoldText>
                        <MultiLineText text={challenge.description} />
                    </WideSpace>
                }
            </Row>
        </WideSpace>
    );
}
