import React from 'react';
import styled from 'styled-components'

const StyledSubtitle = styled.h1`
    font-size: 18px;
    color: #4C4C4C;
     font-weight: 500;
     margin: 0;

  overflow: hidden;
  text-overflow: ellipsis;
`

export default function Subtitle({children}) {


    return (
        <StyledSubtitle>
            {children}
        </StyledSubtitle>
    );
}

