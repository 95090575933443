import React from 'react'
import styled from 'styled-components'
import { OverlayTrigger, Tooltip} from "react-bootstrap";
import QuestionMark from "../assets/Icon/question_mark.svg"

const StyledImagePreview = styled.img`
  max-height:20px;
  max-width:20px;
  margin-left:4px;
  margin-top:-4px;
  &:hover{
    cursor:help
  }
`

export default function InterrogationTip({title}) {
    return (
        <>
            <OverlayTrigger
                placement="auto"
                overlay={<Tooltip id="button-tooltip-2">{title}</Tooltip>}>
             <StyledImagePreview src={QuestionMark}/>
            </OverlayTrigger>
        </>
    )
}