import styled from 'styled-components';

const StyledContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -10px;
  opacity: ${props => props.show ? '0' : '1'};
  pointer-events: ${props => props.show ? 'none' : 'visible'};
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.2);
  transition: 0.5s ease-in-out;
  transition-delay: ${props => props.show ? '0.5s' : '0s'};
  z-index: 999;
`

const StyledOffCanvas = styled.div`
  background: white;
  width: 30%;
  //margin-top: 50px;
  height: 100vh;
  transition: transform .7s ease-in-out;
  transform: translateX(${props => props.show ? '-100%' : '0%'});
  display: ${props => props.show ? 'flex' : 'flex'};
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px;
  border: 2px solid #dee2e6;
  overflow-y: auto;
  padding-bottom: 40px;
`;

const StyledOffCanvasRight = styled(StyledOffCanvas)`
  transform: translateX(${props => props.show ? '100%' : '0%'});
  left: auto;
  right: 0;
`
const StyledBtnClose = styled.button`
  padding: 1rem 1rem;
  margin: -1rem -10px -1rem auto;
  float: right;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1;
  color: black;
  text-shadow: 0 1px 0 #fff;
  opacity: .9;
  text-transform: none;
  font-family: inherit;
  background-color: transparent;
  border: 0;
  border-radius: 4px;

`
const StyledOffCanvasTitle = styled.span`
  float: left;
  font-size: 18px;
  font-weight: 500;
  color: black;
  text-shadow: 0 1px 0 #fff;
  opacity: .9;
  text-transform: none;
  font-family: inherit;
  background-color: transparent;
`

const closeFilter = (event, handleClose) => {
    if (event.currentTarget === event.target) {
        handleClose(false)
    }
}


export default function OffCanvasComponent({show, isRight, handleClose, title, children}) {
    return (
        <StyledContainer onClick={(event) => closeFilter(event, handleClose)} show={!show}>
            {
                isRight ?
                    <StyledOffCanvasRight show={!show}>
                        <div>
                            <StyledOffCanvasTitle>{title || ''}</StyledOffCanvasTitle>
                            <StyledBtnClose
                                onClick={() => handleClose(false)}>x</StyledBtnClose>
                        </div>
                        {children}
                    </StyledOffCanvasRight> :
                    <StyledOffCanvas show={!show}>
                        <div>
                            <StyledOffCanvasTitle>{title || ''}</StyledOffCanvasTitle>
                            <StyledBtnClose
                                onClick={() => handleClose(false)}>x</StyledBtnClose>
                        </div>
                        {children}
                    </StyledOffCanvas>}
        </StyledContainer>


    );
}