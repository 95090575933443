import React from "react";
import { Space, Card, Typography } from "antd";
import useTable from "../../../../../global/hooks/useTable";
import Table from '../../../../../global/components/data_display/Table';
import styled from 'styled-components';
import api from './../../../../../global/services/api';
import { ToastNotification } from "../../../../../global/components/feedback/ToastNotification";
import { ERROR, SUCCESS } from "../../../../../global/utils/fileWithMessage";
import { useTranslation } from "react-i18next";

const { Text } = Typography;
const StyledCard = styled(Card)`
    .ant-card-head-title {
        white-space: normal !important;
    }

`;

function HiddenIndicatorList({statusReport}) {
    const { t } = useTranslation();


    const {
        tableData, tableParams, isLoading, handleTableChange, resetTable
    } = useTable({
        url: `/project_management/project_indicator_responses/status_report/${statusReport.id}/list?`,
        extraParams: {hidden: true},
        waitExternalLoading: !statusReport,
        hasPagination: true,
        pageSize: 10
    });

    const toggleIndicatorVisibility = async (item) => {
        try {
            await api.patch(`/project_management/project_indicator_responses/${item.id}`, {visible: true});
            ToastNotification(SUCCESS);
            dispatchEvent(new CustomEvent('update-status-report'));
        } catch (error) {
            ToastNotification(ERROR);
        }
    }

    // TODO: Adicionar cypress
    const columns = [
        { title: t("Indicator"), dataIndex: "name", width: "50%", key: 0, render: (name)=>t(name) },
        {
            title: t("Actions"),
            key: "action",
            action: true,
            render: (_, record) => (
                <Space size="middle">
                   <Typography.Link onClick={()=>toggleIndicatorVisibility(record)}>{t("Show")}</Typography.Link>
                </Space>
            ),
        },
    ];


    return (<StyledCard
            padding={"1px"}
            bodyStyle={{ padding: 0 }}
            title={<Space size="small" direction="vertical">
                <Text>{t("Hidden Indicators")}</Text>
                <Text style={{ fontSize: 12, fontWeight: "normal" }}>
                    {`${t("Indicators can only be edited while they are being displayed")}.`}
                </Text>
            </Space>}
        >
            <Table 
                columns={columns} 
                scroll={{ x: 0 }} 
                dataSource={tableData} 
                onChange={handleTableChange}
                isLoading={isLoading} 
                tableParams={tableParams}
                showSizeChanger={false}
                showQuickJumper={false}
                hideOnSinglePage={true}
            />
        </StyledCard>)
}

export default HiddenIndicatorList;