import React, {useContext, useEffect, useState} from "react";
import {
    NavLink,
    useParams
} from "react-router-dom";
import {useHistory} from 'react-router-dom';
import {
    hasAcceptedCookies,

} from "../../utils/Auth";
import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../utils/Utils";
import {useTranslation} from "react-i18next";
import UserContext, {useUserContext} from "../../context/UserContext";
import {ToastNotification} from "../../components/feedback/ToastNotification";
import LayoutLogin from "../../layouts/authentication/LayoutLogin";
import {Col, Row, Form as FormAntd} from "antd";
import InputFormField from "../../components/data_entry/form/InputFormField";
import {GLOBAL_PATHS} from "../../utils/RouteUtils";
import Form from "../../components/data_entry/form/Form";
import {AuthButton} from "../../components/authentication/StyledComponents";
import ReCaptchaButton from "../../components/authentication/ReCaptchaButton";

export default function MagicLink() {
    const {t} = useTranslation()
    const history = useHistory();
    const [form] = FormAntd.useForm();
    let {token} = useParams();
    const {setRefresh} = useUserContext();
    const [isLoading, setIsLoading] = useState(true);
    const [captchaValue, setCaptchaValue] = useState(false);

    const handleLoginWithEmail = async () => {
        try {
            if (!hasAcceptedCookies()) {
                ToastNotification(t("Please accept cookies"), 'warning');
                history.push('/login')
                return
            }
            const response = await api.post(`auth/magic-link-consume/${token}`);
            if (response.status !== 200) {
                ToastNotification(t("Authentication failed, try again"), 'error')
                history.push('/login')
                return
            }

            setRefresh(true)
            history.push('/')
        } catch (error) {
            BuildFieldErrorsDict(error, null, t("Authentication failed, try again"))
            history.push('/login')
        }
    }

    useEffect(() => {
        if (token !== 'register') {
            handleLoginWithEmail()
        } else {
            setIsLoading(false)
        }
    }, [token]);

    const LoginWithEmail = async () => {

        try {
            setIsLoading(true)
            const data = {
                "email": form.getFieldValue('username')
            }

            const response = await api.post("auth/magic-link-send", data);
            if (response.status === 200) {

                ToastNotification(t(`We sent a magic link to your email`))
                history.push(GLOBAL_PATHS.LOGIN)
            }

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {

            setIsLoading(false)

        }
    }

    return (
        <LayoutLogin>
            {/*{isLoading ? <Loading/> : (*/}
            <Form form={form}
                  requiredMark={false}
                  onFinish={LoginWithEmail}
                  style={{marginTop: 16}}>
                <Row>
                    <Col xs={24}>
                        <InputFormField form={{
                            style: {marginBottom: 4},
                            label: t("Fill in the email so we can send you the magic link."),
                            name: 'username',

                            rules: [{
                                required: true,
                                message: t('E-mail is required')
                            }, {
                                type: 'email',
                                message: t('Please provide a valid email')
                            }
                            ]


                        }} placeholder={t('E-mail')}/>
                        <NavLink
                            to={`${GLOBAL_PATHS.LOGIN}`}>{t('Go back to Log in')}</NavLink>
                    </Col>
                    <Col xs={24} style={{marginTop: 24}}>
                        <ReCaptchaButton setCaptchaValue={setCaptchaValue}/>
                    </Col>
                    <Col xs={24} style={{marginTop: 24}}>
                        <AuthButton block
                                    disabled={!captchaValue}
                                    htmlType="submit"
                                    loading={isLoading}>{t('Access with magic link')}</AuthButton>
                    </Col>
                </Row>

            </Form>
            {/*)}*/}
        </LayoutLogin>
    );
}