import React, {useEffect, useState} from 'react';
import {
    Col,
    Collapse,
    List,
    Modal,
    Row,
    Space,
    Tooltip,
    Typography
} from "antd";
import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import styled from "styled-components";
import {
    AppstoreOutlined,
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    PlusOutlined,
    ProjectOutlined,
    TeamOutlined
} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import RemixIcon from "../../../../../components/data_display/RemixIcon";
import {
    danger,
    neutral_1,
    neutral_10,
    neutral_3
} from "../../../../../utils/Colors";
import Button from "../../../../../components/data_entry/Button";
import useSystemBreakpoint from "../../../../../hooks/useSystemBreakpoint";
import useDefaultGetRequest from "../../../../../hooks/useDefaultGetRequest";
import Loading from "../../../../../components/feedback/Loading";
import AddUpdateNewTagDrawer from "./AddUpdateNewTagDrawer";
import CustomTag from "../../../../../components/tag/CustomTag";
import api from "../../../../../services/api";
import {
    ToastNotification
} from "../../../../../components/feedback/ToastNotification";
import {BuildFieldErrorsDict} from "../../../../../utils/Utils";
import {
    useLayoutContext
} from "../../../../../../modules/project/components/layout/context/LayoutContext";
import DrawerTagReletedObject from "./DrawerTagReletedObject";
import useUpdateLocalRequest from "../../../../../hooks/useUpdateLocalRequest";

const MAX_AMOUNT = 99


function formatAmount(amount) {

    if (amount > MAX_AMOUNT) {
        return `${MAX_AMOUNT}+`
    }

    return amount
}

const StyledCollapse = styled(Collapse)`
    .actions {
        visibility: hidden;
    }

    .ant-collapse-header {
        &:hover {
            background: ${neutral_3};

            .actions {
                visibility: visible;
            }
        }


    }

    .ant-collapse-content {
        border: none;

        .ant-collapse-content-box {

            border-top: 1px solid rgba(5, 5, 5, 0.06);
            padding: 0 !important;

        }
    }
`
const StyledList = styled(List)`

    border: none;

`


const StyledListItem = styled(List.Item)`
    .actions {
        visibility: hidden;
    }

    &:hover {
        background: ${neutral_3};

        .actions {
            visibility: visible;
        }
    }
`

const NewSubTabButton = styled(Button)`
    justify-content: start;
    color: #00000073;
    border-top: 1px solid rgba(5, 5, 5, 0.06);

    :hover {

    }
`

const TagContainer = styled(FlexContainer)`

    .name:hover {

        text-decoration: underline;
        cursor: pointer !important;
    }

    //background: red;
`

export default function TagList({updateListLocalContent,
                                    externalLoading,
                                    search, tag, ...props
                                }) {
    const {t} = useTranslation()
    const {makeRequest, updateLocalContent} = useUpdateLocalRequest();
    const [childrenCount, setChildrenCount] = useState(1)
    const [open, setOpen] = useState(false)
    const [subTag, setSubTag] = useState()
    const [openCollapse, setOpenCollapse] = useState(['1'])
    const [update, setUpdate] = useState(false)

    const [openReletedDrawer, setOpenReletedDrawer] = useState(false)
    const [tagDrawer, setTagDrawer] = useState()


    useEffect(() => {
        if (!open) {
            setSubTag(false)
        }
    }, [open])

    function handleOpenRelatedDrawer(object) {
        setOpenReletedDrawer(true)
        setTagDrawer(object)
    }

    const {
        data: tagsChildren,
        isLoading: isLoading,
    } = useDefaultGetRequest({
        url: `api/tags`,
        hasPagination: true,
        params: {
            parent: tag?.id,
            page_size: 1000,
            search_with_children: search,
        },
        makeRequest: !externalLoading,
        dataDefault: [],
        dependence: [tag?.id, makeRequest, search]
    })


    useEffect(() => {
        if (tagsChildren?.length > 0) {
            setChildrenCount(tagsChildren.length + 1)
        }
    }, [tagsChildren]);


    function handleOpen(open) {


        if (search) {
            if (tagsChildren?.length > 0) {

                return ['1']
            } else {
                return []
            }
        }
        return openCollapse
    }


    function handleEditSubTag(tag) {
        setOpen(true)
        setSubTag(tag)

    }


    return (
        <> <StyledCollapse
            expandIconPosition={'end'}
            // activeKey={handleOpen()}
            activeKey={openCollapse}
            onChange={setOpenCollapse}
            bordered={false} collapsible="icon"
            items={[
                {
                    key: '1',
                    style: {
                        background: 'white',
                        borderRadius: 8
                    },
                    label: <MainTag
                        tag={tag}
                        onClick={handleOpenRelatedDrawer}
                        childrenCount={childrenCount}/>,
                    children:
                        isLoading ?
                            <Loading height={'100px'}/> :
                            <FlexContainer direction={'column'}
                                           align={'streech'}>
                                {tagsChildren?.length > 0 &&
                                    <StyledList
                                        dataSource={tagsChildren}
                                        bordered
                                        renderItem={(item) => (
                                            <StyledListItem
                                                key={item.id}>
                                                <SubTab
                                                    onClick={handleOpenRelatedDrawer}
                                                    tag={item}
                                                    onEdit={handleEditSubTag}
                                                    updateLocalContent={updateLocalContent}/>
                                            </StyledListItem>
                                        )}
                                    />}
                                <FlexContainer justify='start'>
                                    <NewSubTabButton type={'link'}
                                                    onClick={() => setOpen(true)}
                                                    icon={
                                                        <PlusOutlined/>}>
                                        {t('New sub-tag')}
                                    </NewSubTabButton>
                                </FlexContainer>
                            </FlexContainer>

                },
            ]} {...props}/>
            <AddUpdateNewTagDrawer updateLocalContent={updateLocalContent} setOpen={setOpen}
                                   open={open}
                                   parent={tag}
                                   tag={subTag}
                                   isMainTag={false}/>
            <DrawerTagReletedObject updateListLocalContent={updateListLocalContent} open={openReletedDrawer}
                // setHasChange={setHasChange}
                                    setOpen={setOpenReletedDrawer}
                                    tag={tagDrawer}/>
        </>

    )
}


const SubTagContainer = styled.div`
    padding-left: 16px;
    width: 100%;
    //padding-right: 16px;


`

const Name = styled(Typography.Text)`

    min-width: min-content;
    max-width: 215px;
    //flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    cursor: pointer;
`


function MainTag({
                     onClick,
                     tag, childrenCount
                 }) {
    const {t} = useTranslation()

    const {updateLayoutContent} = useLayoutContext()
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false)

    function handleEdit() {
        setOpen(true)
    }

    function handleDeleteMainTag() {
        Modal.confirm({
                icon: <ExclamationCircleOutlined/>,
                title: t('Confirm removal'),
                content: t("You are removing {{amount}} item(s) from this tag. Do you want to continue?", {amount: childrenCount}),
                onOk: async () => {
                    try {
                        setIsLoading(true)
                        const response = await api.delete(`/api/tags/${tag?.id}`);
                        ToastNotification(t('Successfully'))
                        updateLayoutContent()
                        // resetTable()
                    } catch (error) {
                        BuildFieldErrorsDict(error, null, false)

                    } finally {
                        setIsLoading(false)
                    }
                },
                okButtonProps: {danger, loading: isLoading},
                cancelButtonProps: {loading: isLoading},
                okText: t('Delete'),
                cancelText: t('Go back')
            }
        )

    }

    return (
        <>
            <TagContainer onClick={() => onClick(tag)}
                          justify={'space-between'} gap={8}>
                <FlexContainer gap={4}>
                    <CustomTag className={'name'} icon={tag?.icon}
                               color={tag?.color}
                               name={tag?.name} onClick={() => onClick(tag)}/>
                    <Typography.Text
                        type={'secondary'}>{tag?.objects_counter_total}</Typography.Text>
                </FlexContainer>

                <TagAmounts
                    onDelete={handleDeleteMainTag}
                    onEdit={handleEdit}
                    applications_counter={tag?.applications_counter}
                    userclient_counter={tag?.userclient_counter}
                    project_counter={tag?.project_counter}
                    programs_counter={tag?.programs_counter}/>
            </TagContainer>
            <AddUpdateNewTagDrawer open={open} setOpen={setOpen} isMainTag
                                   tag={tag}/>

            <DrawerTagReletedObject/>
        </>

    )
}


function SubTab({updateLocalContent, tag, onEdit, onClick}) {
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState(false)

    function handleDeleteMainTag() {
        Modal.confirm({
                icon: <ExclamationCircleOutlined/>,
                title: t('Confirm removal'),
                content: t("You are removing an item from this tag. Do you want to continue?"),
                onOk: async () => {
                    try {
                        setIsLoading(true)
                        const response = await api.delete(`/api/tags/${tag?.id}`);
                        ToastNotification(t('Successfully'))
                        updateLocalContent()
                        // resetTable()
                    } catch (error) {
                        BuildFieldErrorsDict(error, null, false)

                    } finally {
                        setIsLoading(false)
                    }
                },
                okButtonProps: {danger, loading: isLoading},
                cancelButtonProps: {loading: isLoading},
                okText: t('Delete'),
                cancelText: t('Go back')
            }
        )

    }


    return <SubTagContainer>
        <TagContainer justify={'space-between'} onClick={() => onClick(tag)}>

            <FlexContainer gap={4}>
                <Name className={'name'}
                      onClick={() => onClick(tag)}>{tag?.name}</Name>
                <Typography.Text style={{whiteSpace: 'nowrap'}}
                                 type={'secondary'}>
                    <Tooltip title={tag?.objects_counter_total}>
                        {formatAmount(tag?.objects_counter_total)}
                    </Tooltip></Typography.Text>

            </FlexContainer>


            <TagAmounts
                onDelete={handleDeleteMainTag}
                onEdit={() => onEdit(tag)}
                {...tag}/>


        </TagContainer>


    </SubTagContainer>
}

const NameType = styled(Space)`
    color: #00000040;


`
const SubName = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;

    min-width: 30px;
    max-width: 60px;
`
const Amount = styled.div`
    color: #00000073;


`

function TagAmounts({
                        onDelete, onEdit,
                        applications_counter = 0,
                        programs_counter = 0,
                        project_counter = 0,
                        userclient_counter = 0
                    }) {

    const {t} = useTranslation()
    const {isDesktop} = useSystemBreakpoint()
    return (

        <FlexContainer gap={16}>


            {isDesktop && <TagAmount icon={<RemixIcon
                remixIconName={'ri-lightbulb-line'}/>}
                                     title={t('Application')}
                                     amount={applications_counter}/>}


            {isDesktop && <TagAmount icon={<AppstoreOutlined/>}
                                     title={t('Programs')}
                                     amount={programs_counter}/>}


            {isDesktop && <TagAmount icon={<ProjectOutlined/>}
                                     title={t('Projects')}
                                     amount={project_counter}/>}


            <FlexContainer>
                {isDesktop && <TagAmount icon={<TeamOutlined/>}
                                         title={t('User')}
                                         amount={userclient_counter}/>}

                <FlexContainer className={'actions'} style={{marginLeft: 8}}>
                    <ActionContainer onClick={(e) => {
                        e.stopPropagation()
                        onEdit()
                    }}>
                        <EditOutlined/>
                    </ActionContainer>
                    <ActionContainer
                        className={'delete'}
                        onClick={(e) => {
                            e.stopPropagation()
                            onDelete()
                        }}
                    >
                        <DeleteOutlined/>
                    </ActionContainer>
                </FlexContainer>
            </FlexContainer>


        </FlexContainer>
    )
}


const ActionContainer = styled.div`

    padding: 0px 8px;
    border-radius: 4px;

    &:hover {
        color: var(--primary_btn_text_color);
        background: ${({disabled}) => disabled ? "transparent" : "var(--primary_btn_color)"};

        &.delete {
            background: ${({disabled}) => disabled ? "transparent" : danger};

            svg {
                color: ${({disabled}) => disabled ? neutral_10 : 'white'};


            }
        }
    }


    &.delete {

        svg {
            color: ${({disabled}) => disabled ? neutral_10 : danger};


        }
    }

    color: ${({disabled}) => disabled && neutral_10} !important;
    cursor: ${({disabled}) => disabled ? "not-allowed" : 'pointer'};




`

function TagAmount({icon, title, amount}) {


    return (
        <FlexContainer gap={8}>
            <NameType>
                {icon}
                <SubName>{title}</SubName>
            </NameType>
            <Amount>
                <Tooltip title={amount}>
                    {formatAmount(amount)}
                </Tooltip>

            </Amount>
        </FlexContainer>

    )
}