import React from 'react';
import DetailModalHistory from '../../../../../../../components/DetailModal/DetailModalHistory';
import { useApplicationDrawerContext } from '../../context/ApplicationDrawerContext';

export default function HistoryTab(props) {
    const {solution} = useApplicationDrawerContext();
    return (
        <DetailModalHistory application={solution} />
    );
}
