import React from "react";

import {TagsProvider} from "./TagsContext";
import RoutesTags from "../../routes/RoutesTags";

export default function TagsNavigator() {
    return <TagsProvider>
        <RoutesTags/>
    </TagsProvider>
}
