import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import api from "axios";
import RemixIcon
    from "../../../../../global/components/data_display/RemixIcon";
import {Col, Row, Skeleton} from "antd";
import {AuditOutlined, UserOutlined} from "@ant-design/icons";
import LoadingCustomIcon
    from "../../../../../global/components/customIcons/LoadingCustomIcon";
import {
    MetricsCustomIcon
} from "../../../../../global/components/customIcons/MetricsCustomIcon";
import MetricsCard
    from "../../../../../global/components/data_display/MetricsCard";
import {BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import {dust_red_5} from "../../../../../global/utils/Colors";


function MetricsCol({children}) {
    return (
        <Col xs={12} md={8} lg={8} xl={4}>
            {children}
        </Col>
    )
}

export default function SectionDashboardProgramMetrics({filters}) {
    let {id} = useParams();
    const {t} = useTranslation();
    const [applicationsStatics, setApplicationsStatics] = useState();


    useEffect(() => {
        const fetchApplicationsStatics = async () => {
            try {
                const response = await api.post(`/dashboard/program_dashboard/${id}/applications_statistics`, filters,{version: 'v2'});
                setApplicationsStatics(response.data)
            } catch (error) {
                console.error(error);
                BuildFieldErrorsDict(error, null, false);
            }
        };
        void fetchApplicationsStatics();
    }, [filters]);

    return (
        <Row gutter={[16, 16]}>
            <Skeleton active={true}
                      loading={applicationsStatics === undefined}>
                <MetricsCol>
                    <MetricsCard
                        value={applicationsStatics?.submitted_total}
                        name={t("Submitted applications")}
                        extraIcon={<MetricsCustomIcon/>}
                        icon={<RemixIcon
                            remixIconName={"ri-flashlight-fill"}/>}
                        color="#F759AB"/>
                </MetricsCol>
                <MetricsCol>
                    <MetricsCard
                        value={applicationsStatics?.in_filling}
                        name={t("Applications in filling")}
                        extraIcon={<MetricsCustomIcon/>}
                        icon={<RemixIcon
                            remixIconName={"ri-flashlight-fill"}/>}
                        color="#D46B08"/>
                </MetricsCol>
                <MetricsCol>
                    <MetricsCard
                        value={applicationsStatics?.given_up}
                        name={t("Applications canceled")}
                        extraIcon={<MetricsCustomIcon/>}
                        icon={<RemixIcon
                            remixIconName={"ri-flashlight-fill"}/>}
                        color={dust_red_5}/>
                </MetricsCol>
                <MetricsCol>
                    <MetricsCard
                        value={applicationsStatics?.approved}
                        name={t("Approved applications")}
                        extraIcon={<MetricsCustomIcon/>}
                        icon={<RemixIcon
                            remixIconName={"ri-flashlight-fill"}/>}
                        color="#5B8C00"/>
                </MetricsCol>
                <MetricsCol>
                    <MetricsCard
                        value={applicationsStatics?.reproved}
                        name={t("Reproved applications")}
                        extraIcon={<MetricsCustomIcon/>}
                        icon={<RemixIcon
                            remixIconName={"ri-flashlight-fill"}/>}
                        color="#D4380D"/>
                </MetricsCol>
                <MetricsCol>
                    <MetricsCard
                        value={applicationsStatics?.assessments_finished}
                        totalValue={applicationsStatics?.assessments_total}
                        name={t("Assessments")} icon={<AuditOutlined/>}
                        extraIcon={<LoadingCustomIcon/>} color="#08979C"/>
                </MetricsCol>
            </Skeleton>
        </Row>
    );
}