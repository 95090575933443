function PreventPropagationWrapper({children, ...props}) {
    const stopPropagation = (e) => {
        e.stopPropagation();
    };
    return (
        <div onClick={stopPropagation} onMouseDown={stopPropagation} onKeyDown={stopPropagation}
             onKeyUp={stopPropagation} {...props}>
            {children}
        </div>
    );
}

export default PreventPropagationWrapper;