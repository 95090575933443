import React, {useEffect, useMemo, useState} from 'react';
import {Funnel} from '@ant-design/plots';
import api from "../../../../../global/services/api";
import {
    BuildFieldErrorsDict,
    getFunnelColorGradient
} from "../../../../../global/utils/Utils";
import LoadingFull from "../../../../../global/components/other/LoadingFull";
import Empty from "../../../../../global/components/data_display/Empty";
import ChartLegendWrapper
    from '../../../../../global/components/data_display/ChartWrapper/ChartLegendWrapper';

const DEFAULT_CONFIG_REQUEST = {
    version: 'v1'
}
export default function FunnelChart({
                                        request,
                                        xField,
                                        yField = 'count',
                                        expand
                                    }) {
    const [data, setData] = useState();

    const colorGradient = useMemo(() => getFunnelColorGradient(data), [data])

    const fetchData = async () => {
        try {
            const config = {
                params: request.params,
                ...DEFAULT_CONFIG_REQUEST,
                ...request.config
            }
            let response;
            if (request?.body) {
                response = await api.post(request?.url, request?.body, config);
            } else {
                response = await api.get(request?.url, config);
            }
            setData(response.data)
        } catch (error) {
            console.error(error);
            BuildFieldErrorsDict(error, null, false);
        }
    };

    const colorField = xField

    const config = {
        data,
        xField,
        yField,
        colorField,
        autoFit: true,
        // label: null,
        style: {fill: (d, index) => (data && colorGradient[index])},
        axis: {
            x: {
                title: false,
                tickLength: 0,
                labelAutoEllipsis: [{type: "ellipsis", maxLength: 5}],
                size: 100,
            },
            y: false,
        },
        tooltip: {
            title: false,
            items: [(d, index, data, column) => (
                {
                    color: colorGradient[index],
                    name: d[xField],
                    value: d[yField]
                }
            )],
        },
        _legend: {
            colorField,
            rows: 1,
            cols: 3,
            labelFormatter: (label) => {
                return {
                    color: colorGradient[label.order],
                }
            },
        },
        drawerConfig: {
            _legend: {
                rows: 2,
            }
        }
    }

    useEffect(() => {
       void fetchData();
    }, [JSON.stringify(request)]);

    return (
        <>
            {data === undefined && <LoadingFull/>}
            {data?.length === 0 && <Empty/>}
            {data?.length >= 1 &&
                <ChartLegendWrapper chart={Funnel} config={config}
                                    expand={expand}/>}
        </>
    )
}