import React from 'react';
import {StyledSpanStatusApplication} from './styles';
import {useTranslation} from "react-i18next";

function SpanStatusApplication({isApproved,}) {
    const {t} = useTranslation()

    return (
        <StyledSpanStatusApplication>
       <span
           className={isApproved ? "submitedText" : isApproved === null ? "pendingSubmitedText" : "noSubmitedText"}>
            {isApproved ? t("Approved") : isApproved === null ? t("Pending") : t("Reproved")}
       </span>
        </StyledSpanStatusApplication>
    );
}

export default SpanStatusApplication;