import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Col, Row, Space, Spin} from "antd";
import {BuildFieldErrorsDict} from "../../../../utils/Utils";
import api from "../../../../services/api";
import styled from "styled-components";
import {
    ReactComponent as GraphIcon
} from "../../../../../assets/Icon/graph3.svg";
import {
    FlexContainer
} from "../../../../components/authentication/StyledComponents";
import {useHistory, useParams} from "react-router-dom";
import CircularImage from "../../../../components/data_display/CircularImage";
import ApplicationDrawer from "../../../../components/other/application/ApplicationDrawer/ApplicationDrawer";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {blue_6} from "../../../../utils/Colors";


const GoTo = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${blue_6};
  gap: 8px;
  margin-right: 8px;
  cursor: pointer;
`

export default function HeaderMessagesTopicOffCanvas({topic, open, showGoToProgram, ...props}) {
    const {t} = useTranslation()
    const {program_id} = useParams()
    const history = useHistory();
    const [solutionDrawerOpen, setSolutionDrawerOpen] = useState(false)
    const {sm} = useBreakpoint();


    return (
        <>
            <FlexContainer
                justify={'space-between'}><Space size={8}><CircularImage
                size={24}
                src={topic?.object?.picture}/> {t(topic?.title)}
            </Space>
                {
                    !showGoToProgram ? <></> :
                    topic?.type === 'application' ? (<GoTo
                            onClick={() => setSolutionDrawerOpen(true)}><GraphIcon/> {sm && t('Go to application')}
                        </GoTo>) :
                        <GoTo
                            onClick={() => history.push(`/available-programs/detail/${program_id}`)}><GraphIcon/> {sm && t('Go to program')}
                        </GoTo>
                }
            </FlexContainer>
            {topic?.type === 'application' &&
                <ApplicationDrawer solutionId={topic?.object?.id}
                                   open={solutionDrawerOpen}
                                   setOpen={setSolutionDrawerOpen}
                                   />}
        </>
    );
}

