import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Form as FormAnt, Input } from "antd";
import { useTranslation } from "react-i18next";
import Button from "../../../components/data_entry/Button";
import SearchAddressInput from "../../../components/data_entry/SearchAddressInput";
import DatePickerLocal from "../../../components/data_entry/DatePickerLocal";
import TextArea from "../../../components/data_entry/TextArea";
import Loading from "../../../components/feedback/Loading";
import api from "../../../services/api";
import RequestSelect from "../../../components/data_entry/RequestSelect";
import { ToastNotification } from "../../../components/feedback/ToastNotification";
import PhoneNumberFormField
    from "../../../components/data_entry/form/PhoneNumberFormField";

function FormCol({ children }) {
    return (
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            {children}
        </Col>
    )
}

export default function ProfileForm() {
    const { t } = useTranslation();
    const [form] = FormAnt.useForm();
    const rules = [{ required: true }];
    const [isLoading, setIsLoading] = useState(false);
    const [validateFields, setValidateFields] = useState({});
    const [profileData, setProfileData] = useState({});

    const save = async () => {
        try {
            setIsLoading(true);
            const formData = new FormData();
            const formValues = form.getFieldsValue();

            Object.keys(formValues).forEach((key) => {
                let value = formValues[key];

                if (key === "address" && value) {
                    value = JSON.stringify({
                        city: value.city || "",
                        state: value.state || "",
                        country: value.country || "",
                        lat: value.lat || null,
                        long: value.long || null,
                    });
                }

                formData.append(key, value || "");
            });
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };

            const response = await api.patch(`/auth/user`, formData, config);

            if (response.status === 200) {
                ToastNotification(t("Saved successfully"));
                window.location.reload();
                // console.log(response.data);
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
                const fieldErrors = error.response.data.errors;
                setValidateFields(fieldErrors);
            } else {
                console.error("Error", error);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const fetchProfileData = async () => {
        try {
            setIsLoading(true);
            const response = await api.get("/auth/user");
            setProfileData(response.data);
            form.setFieldsValue(response.data);
        } catch (error) {
            console.error("Error", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchProfileData();
    }, []);

    return (
        <FormAnt form={form}>
            {isLoading ? (
                <Loading />
            ) : (
                <>
                    <Row gutter={[16, 16]}>
                        <FormCol>
                            <FormAnt.Item name="first_name" label={t("Name")} rules={rules} labelCol={{ span: 24 }} style={{all: "unset"}}>
                                <Input placeholder={t("Type your name here...")} />
                            </FormAnt.Item>
                        </FormCol>
                        <FormCol>
                            <FormAnt.Item name="last_name" label={t("Last name")} labelCol={{ span: 24 }} style={{all: "unset"}}>
                                <Input placeholder={t("Type your last name here...")} />
                            </FormAnt.Item>
                        </FormCol>
                        <FormCol>
                            <FormAnt.Item name="address" label={t("City")} labelCol={{ span: 24 }} style={{all: "unset"}}>
                                <SearchAddressInput placeholder={t("City")} />
                            </FormAnt.Item>
                        </FormCol>
                        <FormCol>
                            <FormAnt.Item name="linkedin" label={t("Linkedin")} labelCol={{ span: 24 }} style={{all: "unset"}}>
                                <Input placeholder={t("Type your linkedin here...")} />
                            </FormAnt.Item>
                        </FormCol>
                        <FormCol>
                            <FormAnt.Item name="time_zone" label={t("Time zone")} rules={rules} labelCol={{ span: 24 }} style={{all: "unset"}}>
                                <RequestSelect url="/time_zones" isChoiceArray placeholder={t("Time zone")} />
                            </FormAnt.Item>
                        </FormCol>
                        <FormCol>
                            <FormAnt.Item name="language_code" label={t("Language")} labelCol={{ span: 24 }} style={{all: "unset"}}>
                                <RequestSelect url="/language" isChoiceArray translate placeholder={t("Language")} />
                            </FormAnt.Item>
                        </FormCol>
                        <FormCol>
                            <FormAnt.Item name="date_of_birth" label={t("Date of birth")} labelCol={{ span: 24 }} style={{all: "unset"}}>
                                <DatePickerLocal style={{ width: "100%" }} placeholder={t("Date of birth")} />
                            </FormAnt.Item>
                        </FormCol>
                        <FormCol>
                            <FormAnt.Item name="genre" label={t("Genre")} labelCol={{ span: 24 }} style={{all: "unset"}}>
                                <RequestSelect url="/genre" isChoiceArray translate placeholder={t("Genre")} />
                            </FormAnt.Item>
                        </FormCol>
                        <FormCol>
                            <PhoneNumberFormField form={{name: "mobile_phone_number", label: t("Mobile phone"), rules: rules, labelCol: {span:24}}}/>

                        </FormCol>
                    </Row>
                    <Col span={24}>
                        <FormAnt.Item name="biography" label={t("Bio")} labelCol={{ span: 24 }} >
                            <TextArea style={{ width: "100%" }} placeholder={t("Type your bio here...")} />
                        </FormAnt.Item>
                    </Col>
                    <Col span={24}>
                        <Typography.Text type="secondary" style={{ fontSize: 14, fontWeight: 400 }}>
                            {t("We use your date of birth, city and gender for statistics on innovation initiatives")}.
                        </Typography.Text>
                    </Col>
                    <Col span={24}>
                        <div style={{ textAlign: "end", marginTop: "16px" }}>
                            <Button type="primary" onClick={save}>
                                {t("Save")}
                            </Button>
                        </div>
                    </Col>
                </>
            )}
        </FormAnt>
    );
}