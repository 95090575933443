import {Col, List, Row, Typography} from "antd";
import React, {useEffect, useState} from "react";
import api from "../../../../../global/services/api";
import {useTranslation} from "react-i18next";
import {LoadingOutlined} from "@ant-design/icons";

function IndicatorProjectHistory({indicatorResponse}) {
    const [indicatorHistory, setIndicatorHistory] = useState(undefined);
    const {t} = useTranslation();

    const fetchIndicatorHistory = async () => {
        const response = await api.get(`/project_management/project_indicator_responses/${indicatorResponse.id}/logs`);
        setIndicatorHistory(response.data);
    };

    useEffect(() => {
        if (indicatorResponse?.id) {
            setIndicatorHistory(undefined);
            void fetchIndicatorHistory();
        }
    }, [indicatorResponse]);

    return (
        (indicatorResponse && indicatorHistory) ?
            indicatorHistory.length > 0 && <>
                <Row style={{padding: "8px 14px 8px 0px"}}>
                    <Typography.Text style={{fontSize: "12px"}}>{t("History of Changes")}</Typography.Text>
                </Row>
                <List>
                    {indicatorHistory.map((history, index) =>
                        (<List.Item key={index} style={{padding: "16px 24px 16px 24px"}}>
                            <Col>
                                <Row>
                                    <Typography.Title level={5}>{t("Data Change")}</Typography.Title>
                                </Row>
                                <Row>
                                    <Typography.Paragraph>
                                        <Typography.Text type="secondary">{history["details"]}</Typography.Text>
                                    </Typography.Paragraph>
                                </Row>
                                <Row>
                                    <Typography.Text type="secondary">{t("User")}: {history["actor"]} </Typography.Text>
                                </Row>
                                <Row>
                                    <Typography.Text type="secondary">{t("Data")}: {history["date"]}</Typography.Text>
                                </Row>
                            </Col>
                        </List.Item>))}
                </List>
            </>:
            <LoadingOutlined/>
    );
}

export default IndicatorProjectHistory;