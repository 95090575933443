import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { Form } from 'react-bootstrap';
import './style.css'

function CropperComponent(props){
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1);
    // const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    function onCropComplete(croppedArea, croppedAreaPixels) {
      props.onCrop(croppedAreaPixels);
    }

    const getObjectFit = () => {
        if (props?.objectFit){
            return props?.objectFit
        }
        return props.cropShape === 'circle' ? 'contain' : 'horizontal-cover'
    }

    return (
      <>
        <div className='crop-container'>
          <Cropper
            image={props.img}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={props.ratio}
            objectFit={getObjectFit()}
            // objectFit='contain'
            cropShape= {props.cropShape === 'circle' ? 'round' : 'rect'}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            />
        </div>
        <div className="controls">
          <Form.Control type="range" value={zoom} onChange={e => setZoom(e.target.value)} min={1} max={3} step={0.01} />
          {/* <Form.Range value={zoom} onChange={e => setZoom(e.target.value)} min={1} max={3} step={0.1}/> */}
        </div>
      </>
    )
}

export default CropperComponent;