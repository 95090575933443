import React from 'react';
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import {Button} from "react-bootstrap";
import {useModalContext} from "../../global/context/ModalMessageContext";
import {useTranslation} from "react-i18next";

const ModalMessage = () => {
    const {t} = useTranslation()
    const {modalState: {title,message, visible}, closeModal} = useModalContext();
    return (
        <>
            <Modal show={visible} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{message}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        {t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default ModalMessage;


