import React from "react";
import {BellOutlined,} from "@ant-design/icons";
import {Badge} from "antd";
import {useUserContext} from "../../context/UserContext";
import {useLayoutContext} from "../../../modules/project/components/layout/context/LayoutContext";

// const Container = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 100%;
//   cursor: pointer;
//   padding: 0px 8px;
//
//   & > * {
//     color: ${({color}) => color ? color : 'black'};
//     font-size: ${({isMobile}) => isMobile ? '24px' : '16px'};
//   }
// `

export function NotificationButton() {
    const {notifications, readNotifications} = useUserContext()
    const readNotificationCount = notifications?.filter(notification => notification.is_read || readNotifications?.includes(notification.id)).length || 0;
    const unreadNotificationCount = (notifications?.length - readNotificationCount) || 0;
    const {setShowNotificationArea} = useLayoutContext()

    return (
        <Badge status="danger" count={unreadNotificationCount}
               style={{cursor: "pointer"}}
               onClick={() => setShowNotificationArea(true)}>
            <BellOutlined style={{
                fontSize: 18,
                cursor: 'pointer'
            }}/>
        </Badge>
    )
}
