import React from 'react';
import {Form as FormAnt} from 'antd';
import {ToastNotification} from "../../feedback/ToastNotification";
import {useTranslation} from "react-i18next";

export default function Form({children,  ...props}) {
    const {t} = useTranslation()
    return (
        <FormAnt load  scrollToFirstError onFinishFailed={() => ToastNotification(t("Some fields are required"),'error')}  preserve layout="vertical" {...props}>
            {children}
        </FormAnt>
    );
}
