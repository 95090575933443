import React from 'react';
import styled from "styled-components";
import {StyledCardApplication} from './styles';
import CircleImage from "../CircleImage";
import CheckBox from "../Form/CheckBox";
import {useTranslation} from "react-i18next";

const StyledSendTag = styled.span`
  white-space: nowrap;
`

function CardApplication(props) {
    const {t} = useTranslation();

    return (
        <StyledCardApplication ref={props.refElement}>
            <div key={'application' + props.application.id} className={`divApplyCard `}>
                <div style={{
                    flex: 3,
                    display: "flex",
                    alignItems: "center",
                }}>
                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        padding: '17px 8px 8px 8px',
                        flexDirection: "column",
                        width: "100%",
                        height: "100%",
                        justifyContent: "space-evenly"
                    }}>
                        <div style={{
                            display: "flex",
                            alignItems: "center",
                            alignSelf: "flex-start",
                            width: "100%"
                        }}>
                            <div>
                                <CheckBox id={props.application.id}
                                          onChange={props.onClickSelected}
                                          defaultChecked={props.checked}
                                          options={[{id: props.application.id, label: ''}]}/>
                            </div>


                            <CircleImage
                                style={{marginLeft: 8}}
                                src={props.application.team?.picture}
                                width="40px"/>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: 8,
                                width: "100%"
                            }}>
                                <span
                                    className={'name-startup'}
                                    onClick={() => props.onClickName()}
                                    style={{
                                        fontWeight: 700,
                                        color: "#4C4C4C",
                                        fontSize: 12
                                    }}>{props.application.team?.name || props.application.author_name}
                                </span>
                                <span
                                    style={{
                                        fontWeight: 400,
                                        color: "#4C4C4C",
                                        fontSize: 12
                                    }}>
                                    {props.application.team?.short_description}
                                </span>
                            </div>
                            <StyledSendTag
                                className={props.status.status === "dont_sent" ? "noSubmitedText" : props.status.is_send === true ? "submitedText" : "pendingSubmitedText"}>
                                {props.status.status == "dont_sent" ? t("Marked as not sent") : props.status.status == "written" ? t("Written") : props.status.is_send === true ? t("Already sent") : t("Pending")}
                            </StyledSendTag>
                        </div>
                        {props.children}
                    </div>
                </div>
            </div>
        </StyledCardApplication>
    );
}

export default CardApplication;