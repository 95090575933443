import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Space, Typography} from 'antd';
import {useTranslation} from "react-i18next";
import {
    FlexContainer
} from "../../../../../global/components/authentication/StyledComponents";
import {
    RadarChartOutlined,
} from "@ant-design/icons";
import {
    neutral_2, neutral_8,
} from "../../../../../global/utils/Colors";
import Drawer from "../../../../../global/components/layout/Drawer";
import Search from "../../../../../global/components/data_entry/Search";
import api from "../../../../../global/services/api";
import {BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import ChooseManualFilter from "./ChooseManualFilter";
import TableManualStartupFilter from "./TableManualStartupFilter";
import ManualStartupsFilterButton from "./ManualStartupsFilterButton";

const Container = styled.div`
    background: white;
    padding: 24px;
    border-radius: 12px;
`

export default function DrawerManualSearchStartups({handleExternalTableUpdate,
                                                       open,
                                                       setOpen,
                                                   }) {
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [filters, setFilters] = useState([]);
    const [choosedFilter, setChoosedFilter] = useState()
    //adicionar tags_id/tag_objec/states_id


    useEffect(() => {
        const fetchFilters = async () => {
            try {
                setIsLoading(true)
                const response = await api.get(`program_hunt/get_counters`);
                setFilters(response.data)
            } catch (error) {
                BuildFieldErrorsDict(error, null, false);
            } finally {
                setIsLoading(false);
            }
        }
        if (open && filters.length === 0) fetchFilters()
    }, [open])

    function onClose() {
        setOpen(false)
        setChoosedFilter(null)
    }

    function openDrawerManualStatupsList(object) {
        setChoosedFilter((filter) => ({...filter, ...object}))

    }


    function handleOnSearch(value) {
        if (value || !value && choosedFilter?.search){
            openDrawerManualStatupsList({search: value})
        }
    }


    return (

        <Drawer open={open}
                onClose={onClose}
                bodyStyle={{background: neutral_2}}
                width={1200}
                title={<Space><RadarChartOutlined style={{color: neutral_8}}
                                                  size={8}/> <Typography.Title
                    style={{marginBottom: 0}}
                    level={5}>{t('Manual Startup Radar')}</Typography.Title></Space>}>
            <Container>
                <FlexContainer align={'stretch'} direction={'column'}>
                    <Typography.Title level={5}
                                      style={{marginBottom: 0}}>{t('Search for the desired startup')}</Typography.Title>
                    <Typography.Text
                        type={'secondary'}>{t('Search by Sectors of Activity or type what you want')}</Typography.Text>
                </FlexContainer>
                <FlexContainer style={{marginTop: 24}} gap={16}>
                    <Search placeHolder={t('Keywords')}
                            onSearch={handleOnSearch} allowClear/>
                    {choosedFilter && <ManualStartupsFilterButton
                        choosedFilter={choosedFilter}
                        setChoosedFilter={setChoosedFilter}/>}
                </FlexContainer>

            </Container>

            {choosedFilter ? (
                    <TableManualStartupFilter choosedFilter={choosedFilter} handleExternalTableUpdate={handleExternalTableUpdate}/>) :
                (<ChooseManualFilter
                    openDrawerManualStatupsList={openDrawerManualStatupsList}
                    filters={filters} isLoading={isLoading}/>)}

        </Drawer>


    );
}

