import React, {useEffect, useMemo, useState} from "react";
import api from "../../../../global/services/api";
import * as ConstantsMessage from "../../../../global/utils/fileWithMessage";
import {
    BuildFieldErrorsDict,
    generateOptionsField
} from "../../../../global/utils/Utils";
import {useTranslation} from "react-i18next";
import {
    ToastNotification
} from "../../../../global/components/feedback/ToastNotification";
import {CardContainer} from "../../../../global/components/misc/CardContainer";
import {
    FlexContainer
} from "../../../../global/components/authentication/StyledComponents";
import {Alert, Modal, Space, Tag, Typography} from "antd";
import {APP_VERSION} from "../../../../settings";
import {Row, Col, Form as FormAnt} from 'antd'
import InputFormField
    from "../../../../global/components/data_entry/form/InputFormField";
import DomainTagsFormField
    from "../../../../global/components/data_entry/form/DomainTagsFormField";
import SwitchFormField
    from "../../../../global/components/data_entry/form/SwitchFormField";
import SolvCollapse from "../../../../global/components/misc/SolvCollapse";
import {ExclamationCircleOutlined} from "@ant-design/icons";




export default function SectionEnvironmentGeneral({form, FORM_NAME}) {
    const {t} = useTranslation();


    return (
        <CardContainer>

            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Typography.Title
                        level={5}>{t('General')}</Typography.Title></Col>
                <Col xs={24}>
                    <InputFormField form={{
                        label: t('Name'),
                        name: FORM_NAME.NAME,

                        rules: [{
                            required: true,
                            message: t('This field is required')
                        }]
                    }}/>
                </Col>
                <Col xs={24} md={12}>
                    <InputFormField
                        // prefix={'@'}
                        form={{
                            label: t('E-mail for support'),
                            name: FORM_NAME.SUPPORT_EMAIL,

                            rules: [{
                                type: 'email',
                                message: t('Please provide a valid email')
                            }]
                        }}/>

                </Col>
                <Col xs={24} md={12}>
                    <DomainTagsFormField
                        form={{
                            label: t("Official company email extensions"),
                            name: FORM_NAME.CLIENT_DOMAINS,
                            help: t('Email with the above extensions will be automatically added as new employees to the company\'s environment in Solv.')
                        }}/>
                </Col>
                <Col xs={24}>
                    <Col xs={24}>
                        <SolvCollapse
                            padding={'24px 0px'}
                            title={<Typography.Text
                                type={'secondary'}>{t("Advanced Settings")}</Typography.Text>}
                            bordered>
                            <Row>
                                {/*<Col xs={24} md={12}>*/}
                                {/*    <SwitchFormField form={{*/}
                                {/*        label: t("Cached login credentials in browser active"),*/}
                                {/*        name: FORM_NAME.IS_CACHE_BROWSER_LOGIN,*/}
                                {/*    }}/>*/}
                                {/*</Col>*/}
                                <Col xs={24} md={12}>
                                    <SwitchFormField
                                        onChange={(value) => {
                                            if (value) {
                                                Modal.confirm({
                                                    icon:
                                                        <ExclamationCircleOutlined/>,
                                                    title: t("Hidden Subscription Enabled"),
                                                    content: t("This option allows you to select the application of innovation programs that can hide its data during the registration period."),
                                                    onCancel: () => {
                                                        form.setFieldValue(FORM_NAME.IS_CONFIDENTIAL_MODE, false)
                                                    },
                                                    okText: t('Yes'),
                                                    cancelText: t('No')

                                                })
                                            }
                                        }}
                                        form={{
                                            label: t("Make innovation with hidden inscription"),
                                            name: FORM_NAME.IS_CONFIDENTIAL_MODE,
                                            help: t('This option allows you to configure innovation programs with hidden subscriptions.'),
                                        }}/>
                                </Col>
                            </Row>

                        </SolvCollapse>

                    </Col>

                </Col>

            </Row>
            <FlexContainer justify={'space-between'}>
                <Typography.Text
                    type={'secondary'}>{`${t('System version')}: ${APP_VERSION}`}</Typography.Text>

            </FlexContainer>

        </CardContainer>


    );
}
