import React from 'react';
import {Card, Flex, Typography} from "antd";

const {Text} = Typography;

export default function IndicatorValueCard({label, help, prefix, value, suffix, onClick}) {
    return (
        <Card bordered={false} onClick={onClick} style={{cursor: "pointer"}}>
            <Flex align={"center"} justify="space-between" gap={8}>
                <Flex vertical={true} style={{maxWidth: 750}}>
                    <Text>{label}</Text>
                    <Text type="secondary">{help}</Text>
                </Flex>
                <Flex align="center" gap={4} style={{whiteSpace: "nowrap"}}>
                    <Text type="secondary">{prefix}</Text>
                    {value}
                    {suffix}
                </Flex>
            </Flex>
        </Card>
    );
}

