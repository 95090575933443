import {InputNumber, Switch} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";

import InformationCard from "../../../../../components/data_display/InformationCard";
import TransparentCollapse
    from "../../../../../components/misc/TransparentCollapse";
import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import CollapseFormItem
    from "../../../../../components/data_entry/CollapseFormItem";


export default function SectionRanking({FORM_NAMES}) {
    const {t} = useTranslation();

    return (

        <TransparentCollapse header={t("Ranking")}>
            <FlexContainer direction={'column'} gap={16} align={'streech'}>
                <CollapseFormItem

                    input={<Switch data-cy="enable-rankings-switch"/>}
                    extraFormItemProps={{valuePropName: "checked"}}
                    name={FORM_NAMES.RANKINGS_ACTIVATED}
                    help={t(
                        "Points earned while ranking is disabled will not be considered. This disables all program rankings."
                    )}
                    label={t("Enable rankings")}
                />
                <CollapseFormItem
                    input={<Switch data-cy="ranking-visibility-switch"/>}
                    extraFormItemProps={{valuePropName: "checked"}}
                    name={FORM_NAMES.SHOW_RANKING_IN_HOME}
                    help={t(
                        "If disabled, the ranking will not be displayed on the SOLV home page."
                    )}
                    label={t("Ranking visibility")}
                />
            </FlexContainer>

        </TransparentCollapse>

    );
}
