import {Card, Col, Modal, Radio, Row, Space, Typography} from "antd";
import styled from "styled-components";
import React, {useRef, useState} from "react";
import {ExclamationCircleOutlined} from "@ant-design/icons"
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import {FlexContainer} from "../authentication/StyledComponents";
import Button from "../data_entry/Button";
import {useTranslation} from "react-i18next";


const StyledButtonCard = styled(Card)`
    cursor: pointer;

    .ant-card-body {
        display: grid;
        place-content: center;
        height: ${props => props.$height || "initial"};
    }

    :hover {
        background-color: #f5f5f5;
    }

`;

export default function ConfirmTimeoutButton({
                                          okText = '',
                                          title, onConfim, content,
                                          modal,
    awaitSeconds=30,
                                          ...props
                                      }) {
    const {t} = useTranslation();
    const [disabledGiveUpButton, setDisabledGiveUpButton] = useState()
    const [show, setShow] = useState(false)
    const [seconds, setSeconds] = useState(awaitSeconds)
    const idInterval = useRef()
    const idTimeOut = useRef()
    function onClose(){
        setShow(false)
                       setDisabledGiveUpButton(false)
        setSeconds(awaitSeconds)
        clearInterval(idInterval.current)
        clearInterval(idTimeOut.current)
    }

    return (
        <>
            <Modal title={title} open={show} okText={`${seconds? `(${seconds})`:''} ${okText}`}
                   onCancel={onClose}
                   onOk={() => {
                        onConfim()
                       onClose()
                   }}
                   cancelText={t("Go Back")}
                   destroyOnClose closable={false}
                   okButtonProps={{
                       danger: true,
                       disabled: !disabledGiveUpButton
                   }}
                  >
                <Row gutter={16}>
                    <Col><ExclamationCircleOutlined style={{
                        fontSize: 22,
                        color: 'var(--ant-warning-color)'
                    }}/></Col>
                    <Col flex={'1'}>
                        <Space direction={'vertical'}>
                            {content}
                            <Radio onChange={({target: {checked}}) => {

                                idInterval.current = setInterval(() => {

                                    setSeconds((seconds) => seconds -1)
                                },1000)
                                idTimeOut.current = setTimeout(() => {
                                    clearInterval(idInterval.current);
                                   setDisabledGiveUpButton(true)
                                  }, seconds * 1000);
                            }}>{t("I am fully aware and confirm my decision to withdraw from the selection process for the program in which I am participating. I have no intention of resuming the registration I made.")}</Radio>
                        </Space>
                    </Col>
                </Row>
            </Modal>
            <Button
                onClick={() => setShow(true)} {...props}> {okText}</Button>
        </>

    )
}