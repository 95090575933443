import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {getProgram} from "../../../../global/utils/_query";
import RoutesDatabase from "../../../../modules/program/routes/RoutesDatabase";
import {useLayoutContext} from "../../../../modules/project/components/layout/context/LayoutContext";

export default function DatabaseProgram({hasPermission, location, origin, setOrigin, setExtraHeaderLeft}) {
    const [program, setProgram] = useState({public: true});
    let {id} = useParams();
    const {setIsConfidential} = useLayoutContext();

    // useEffect(() => {
    //     if (program) {
    //         setIsConfidential(program["check_is_confidential"])
    //     }
    //     setExtraHeaderLeft && setExtraHeaderLeft(program.name)
    // }, [program]);

    useEffect(() => {
        void getProgram(id, null, setProgram);
    }, []);

    return (
        <RoutesDatabase hasPermission={hasPermission} scope={"programs"} scopeObj={program} origin={origin} setOrigin={setOrigin} setExtraHeaderLeft={setExtraHeaderLeft}/>
    );
}
