import React, {useEffect} from 'react';
import {ReactComponent as ImageNotFound} from "../../../assets/Icon/magnet.svg";
import {ReactComponent as Arrow} from "../../../assets/Icon/arrow.svg";
import {ReactComponent as LogoDefault} from "../../../assets/img/solv/logoDefault.svg";
import MaintenanceIcon from "../../../assets/img/solv/maintenance.svg";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import {StyledLogoDiv, StyledLogoImg} from "../StyledAuthComponents";
import {useThemeContext} from "../../../global/context/ThemeContext";
import {useTranslation} from "react-i18next";
import api from "../../../global/services/api";
import {neutral_1} from "../../../global/utils/Colors";

const DivMainNotFound = styled.div`
  display: flex;
  height: 100vh;
  @media screen and (max-height: 600px) {
    height: 100%;
  }
`

const DivNotFoundLeft = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  padding: 0 70px;
  //padding-top: 8%
  svg {
    fill: var(--login_background_cover);
  }

  @media screen and (max-width: 960px) {
    align-self: auto;
    padding: 50px 70px;
  }
  @media screen and (max-height: 600px) {
    padding: 50px 70px;
  }
`

const StyledHello = styled.span`
  margin-top: 25px;
  color: ${({defaultColor}) => !defaultColor ? "var(--login_background_cover)" : "#000749"};
  //color: var(--login_background_cover);
  font-weight: 600;
  font-size: 28px;
`

const StyledMidDescription = styled.span`
  color: ${({defaultColor}) => !defaultColor ? "var(--login_background_cover)" : "#000749"};
  font-weight: ${({bold}) => bold === true ? "550" : "400"};
  font-size: 26px;
  margin-top: 25px;
  line-height: 35px;
`

const StyledNotFoundButton = styled.button`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: ${({defaultColor}) => !defaultColor ? "var(--login_text_color_button)" : neutral_1};
  height: 37px;
  display: flex;
  align-items: center;
  background-color: ${({defaultColor}) => !defaultColor ? "var(--login_background_button)" : "#FF4704"};
  box-shadow: 0px -3.38082px 10.0172px rgba(0, 0, 0, 0.055), 0px -1.79553px 5.32008px rgba(0, 0, 0, 0.0444282), 0px -0.747159px 2.21381px rgba(0, 0, 0, 0.030926);
  border-radius: 60px;
  border: 1px solid transparent;
  padding: 0px 20px;
  gap: 8px;
  margin-top: 50px;
  align-self: flex-start;

  svg {
    stroke: ${({defaultColor}) => !defaultColor ? "var(--login_text_color_button)" : neutral_1};
    fill: none;
  }

  &:hover {
    border: 1px solid ${({defaultColor}) => !defaultColor ? "var(--login_text_color_button)" : neutral_1};
  }
`

const DivNotFoundRight = styled.div`
  flex: 4;
  display: flex;
  flex-direction: column;
  background-color: ${({defaultColor}) => !defaultColor ? "var(--login_background_cover)" : "#000749"};
  justify-content: center;
  line-height: 120px;
  padding-top: 2%;
  padding-right: 50px;
  padding-left: 100px;
  @media screen and (max-width: 960px) {
    & {
      display: none;
    }
  }
`

const SpanNotFoundNothing = styled.span`
  font-size: 120px;
  font-weight: 600;
  color: ${({defaultColor}) => !defaultColor ? "var(--text_color_menu);" : " #FFF"};
  //margin-left: 15%;
`

const SpatNotFoundToBe = styled.span`
  font-size: 120px;
  font-weight: 400;
  color: ${({defaultColor}) => !defaultColor ? "var(--login_text_color_button)" : " #FF4704"};
  margin-bottom: ${({defaultColor}) => !defaultColor ? "0px" : "20px"};
`

const StyledMaintenanceIcon = styled.img`
  width: 330px;
  align-self: center;
`

export function NotFound({location}) {
    const history = useHistory()
    const {themeConfig} = useThemeContext()
    const {t} = useTranslation();

    // useEffect(() => {
    //
    //     const handleLogError = () => {
    //         location.state && api.post(`error`, {'broken_url': location.state.broken_url})
    //     }
    //
    //     handleLogError()
    //
    // }, [])

    return (
        <DivMainNotFound>
            <DivNotFoundLeft>
                <ImageNotFound/>
                <StyledHello>{t("Hello")}!</StyledHello>
                <StyledMidDescription>{t("The page you are trying to access does not exist or is unavailable")}.</StyledMidDescription>
                <StyledMidDescription>{t("But you can always go back to the Home Page")}.
                    ;)</StyledMidDescription>
                <StyledNotFoundButton
                    onClick={() => history.push("/home")}><Arrow/> {t("Back to Home Page")}
                </StyledNotFoundButton>
            </DivNotFoundLeft>
            <DivNotFoundRight>
                <SpanNotFoundNothing>{t("Nothing")} </SpanNotFoundNothing>
                <SpatNotFoundToBe> {t("to be sad about")}.</SpatNotFoundToBe>
                <StyledLogoDiv>
                    <StyledLogoImg src={themeConfig.login_logo_cover}/>
                </StyledLogoDiv>
            </DivNotFoundRight>
        </DivMainNotFound>
    );
}

export function Maintenance() {
    const {t} = useTranslation();

    return (
        <DivMainNotFound>
            <DivNotFoundLeft>
                <StyledMaintenanceIcon src={MaintenanceIcon}/>
                <StyledHello defaultColor>{t("Hello")}!</StyledHello>
                <StyledMidDescription defaultColor
                                      bold>{t("The solv is now in maintenance")}.</StyledMidDescription>
                <StyledMidDescription
                    defaultColor>{t("Our team is working right now to develop the best for you")}.</StyledMidDescription>
                {/*<StyledNotFoundButton defaultColor onClick={() => history.push("/feed")}><Arrow/> Back to Home*/}
                {/*    Page</StyledNotFoundButton>*/}
            </DivNotFoundLeft>
            <DivNotFoundRight defaultColor>
                <SpanNotFoundNothing
                    defaultColor>{t("People")} </SpanNotFoundNothing>
                <SpatNotFoundToBe defaultColor>{t("at")} </SpatNotFoundToBe>
                <SpatNotFoundToBe defaultColor>{t("work")}.</SpatNotFoundToBe>
                <StyledLogoDiv>
                    <LogoDefault/>
                </StyledLogoDiv>
            </DivNotFoundRight>
        </DivMainNotFound>
    );
}

export function NotPermission() {
    const history = useHistory()
    const {themeConfig} = useThemeContext()
    const {t} = useTranslation();

    return (
        <DivMainNotFound>
            <DivNotFoundLeft>
                <ImageNotFound/>
                <StyledHello>{t("Hello")}!</StyledHello>
                <StyledMidDescription>{t("The page you are trying to access needs permission")}.</StyledMidDescription>
                <StyledMidDescription>{t("Contact the program manager to get the permission")}.</StyledMidDescription>
                <StyledNotFoundButton
                    onClick={() => history.push("/home")}><Arrow/> {t("Back to Home Page")}
                </StyledNotFoundButton>
            </DivNotFoundLeft>
            <DivNotFoundRight>
                <SpanNotFoundNothing>{t("You")} </SpanNotFoundNothing>
                <SpatNotFoundToBe> {t("shall not pass")}.</SpatNotFoundToBe>
                <StyledLogoDiv>
                    <StyledLogoImg src={themeConfig.login_logo_cover}/>
                </StyledLogoDiv>
            </DivNotFoundRight>
        </DivMainNotFound>
    );
}