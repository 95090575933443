import {useTranslation} from "react-i18next";
import useSystemBreakpoint
    from "../../../../../../../hooks/useSystemBreakpoint";
import LimitedSpace from "../../../../../../responsive/LimitedSpace";
import {Badge, Space, Typography} from "antd";
import {ClockCircleOutlined} from "@ant-design/icons";
import {
    DateFormatByUserTimezone,
    DatetimeNow
} from "../../../../../../../utils/dates";
import {blue_6} from "../../../../../../../utils/Colors";
import TruncateText from "../../../../../../responsive/TruncateText";

const {Text} = Typography


export default function SolutionDescriptionCollapseTitle({
                                                             phase,
                                                             solution,
                                                             solutionResult
                                                         }) {
    const {t} = useTranslation()
    const {isDesktop} = useSystemBreakpoint()


    function getBadgeBackgroundColor() {
        const today = DatetimeNow();
        let startDateObj = new Date(phase.startDate)
        let endDateObj = new Date(phase.endDate)

        if ((today < startDateObj) || solution.current_phase !== phase.id) {
            return "#8C8C8C"
        } else if (today > endDateObj) {
            return "#BFBFBF"
        }
        return null

    }
    return (
        <LimitedSpace aling={"center"} direction="horizontal" flexWrap={false} style={{height: "inherit", alignItems:"center"}}>
            <Badge color={getBadgeBackgroundColor() || blue_6}
                   count={phase.sequence}/>
            <TruncateText>
                {phase.title}
            </TruncateText>
            <Text type="secondary" style={{fontSize: 12, fontWeight: 400}}>
                <Space size={16}>
                    {(isDesktop && solution.program.duration !== "continuous") &&
                        <>
                            {phase.date_start && <div>
                                <ClockCircleOutlined/> {t("Start")} {DateFormatByUserTimezone(phase.date_start)}
                            </div>}
                            {phase.date_end && <div>
                                <ClockCircleOutlined/> {t("End")} {DateFormatByUserTimezone(phase.date_end)}
                            </div>}
                        </>
                    }
                    {solutionResult.protocol &&
                        <Text style={{fontSize:12}} type="secondary">{t('Protocol')} {solutionResult.protocol}</Text>}
                </Space>
            </Text>
        </LimitedSpace>
    )
}