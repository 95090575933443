import React, {useState} from "react";
import NoProfile from '../../../assets/Icon/no_profile.svg'
import IconButton from "../../../components/IconButton/Index";
import styled from 'styled-components'
import {useTranslation} from "react-i18next";
import CheckBox from "../../Form/CheckBox";
import {confirmAlert} from "react-confirm-alert";
import {
    Body,
    ButtonContainer,
    Close,
    ConfirmContainer,
    Header
} from "./StyledAllocationComponents";
import Title from "../../Title/Title";
import {ReactComponent as CloseIcon} from "../../../assets/Icon/clear.svg";
import ButtonDefault from "../../Buttons/ButtonDefault";
import AbolishModal from "../../AbolishModal";
import ApplicationDrawer
    from "../../../global/components/other/application/ApplicationDrawer/ApplicationDrawer";

export const StyledAllocationCard = styled.div`
    opacity: ${(props) => props.invalid ? "0.3" : "1"};

`

const Name = styled.span`
    font-weight: 700;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-height: 48px;
`


function ApplicationCard({
                             name,
                             index,
                             application,
                             selectionApplication,
                             showCheckbox,
                             onChangeApplicationSelection,
                             selected,
                             remove,
                             phase,
                             active,
                             refreshCounts,
                             getAllocation,
                             refreshApplications
                         }) {

    const [showModal, setShowModal] = useState(false)

    const [applicationId, setApplicationId] = useState(undefined)
    const [openApplicationDrawer, setOpenApplicationDrawer] = React.useState(false);

    const dateApp = application.justification_invalidate && application.justification_invalidate.date
    const ownerApp = application.justification_invalidate && application.justification_invalidate.owner
    const descApp = application.justification_invalidate && application.justification_invalidate.desc
    const eyeButtonElement = React.useRef()
    const {t} = useTranslation()

    const setDataModalInfoApplication = async (application) => {
        setApplicationId(application.application_id);
        setOpenApplicationDrawer(true)

    }

    function handleSelectionApplication({target}) {
        if (!eyeButtonElement.current.contains(target)) {
            selectionApplication(application)
        }
    }

    const handleConfirmDeleteAllocation = () => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <ConfirmContainer>
                        <Header>
                            <Title noCamel={true}>{t("WARNING")}</Title>
                            <Close onClick={() => {
                                onClose();
                            }}>
                                <CloseIcon/>
                            </Close>
                        </Header>
                        <Body>
                            <p>{t("Do you want to delete the evaluation?")}</p>
                        </Body>
                        <ButtonContainer>
                            <ButtonDefault onClick={onClose}
                                           action={'secondary'}
                                           label={t("Cancel")}/>
                            <ButtonDefault
                                onClick={() => {
                                    remove(index, application.id);
                                    onClose();
                                }}
                                action={'primary'}
                                label={t("Confirm")}
                                data-cy="confirm-delete-button"
                            />
                        </ButtonContainer>
                    </ConfirmContainer>
                );
            }
        });
    };

    return (
        <>
            <AbolishModal getAllocation={getAllocation}
                          refreshCounts={refreshCounts} phase={phase}
                          active={active} data={application} show={showModal}
                          onHide={() => setShowModal(false)}/>
            <ApplicationDrawer solutionId={applicationId}
                               open={openApplicationDrawer}
                               setOpen={setOpenApplicationDrawer}
                               postUpdate={refreshApplications}/>

            <StyledAllocationCard
                title={application.evaluator_evaluation_status_id === 4 &&
                    t("Inactivated evaluation on by \n Justification:", {
                        dateApp,
                        ownerApp,
                        descApp
                    })}
                invalid={application.evaluator_evaluation_status_id === 4 || application.was_submitted}
                className="divAllocationCard"
                style={{
                    width: "100%",
                    height: 58,
                    alignItems: 'center',
                    display: "flex"
                }}>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 0,
                    marginLeft: 4,
                    marginRight: 4,
                }}>
                    {!remove && <div>
                        <CheckBox
                            disabled={application.evaluator_evaluation_status_id === 4}
                            id={name}
                            hidden={!showCheckbox}
                            options={[{
                                id: application.id,
                                value: application.id,
                                checked: selected,
                                onChange: (event) => onChangeApplicationSelection(event.target),
                            }]}/>
                    </div>}
                    {/*<Form.Group className="mb-0 " controlId="formBasicCheckbox">*/}
                    {/*   <Form.Check className={`${showCheckbox ? 'mr-2': ''}`} type="checkbox" hidden={!showCheckbox} value={application.id} checked={selected}*/}
                    {/*                onChange={(event) => onChangeApplicationSelection(event.target)}/>*/}
                    {/*</Form.Group>*/}
                </div>

                <div style={{flex: 10, display: 'flex'}}
                     onClick={handleSelectionApplication}>
                    <div style={{
                        height: 32,
                        width: 32,
                        borderRadius: "50%",
                        alignSelf: "center",
                        marginRight: 6,
                        marginLeft: 0
                    }}>
                        <img alt={'team'}
                             src={application.team?.picture ? application.team?.picture : NoProfile}
                             style={{width: 32, borderRadius: "50%"}}/>
                    </div>
                    <div style={{
                        flex: 3,
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "center",
                        fontWeight: 700,
                        fontSize: 14,
                        lineHeight: 1,
                        height: "90%",
                        justifyContent: "space-evenly"
                    }}>
                        <Name>{`${application.project_name}`} </Name>
                        <span style={{
                            fontSize: 12,
                            alignSelf: "baseline",
                            border: "1px solid black",
                            padding: "2px 3px",
                            borderRadius: 4,
                            backgroundColor: "rgb(209 209 209)",
                            marginTop: 4
                        }}>{t("Assessments")}: {application.finished_assessments_count}/{application.get_count_assassment_current_phase}</span>
                    </div>

                    <div style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                        fontWeight: 500,
                        fontSize: 12
                    }}>


                        {remove && <>

                            {application.evaluator_evaluation_status_id === 1 ?
                                (
                                    <IconButton
                                        disabled={application.evaluator_evaluation_status_id === 4}
                                        onClick={handleConfirmDeleteAllocation}
                                        type={"Delete"}
                                        data-cy="delete-button"
                                    />
                                ) :
                                (<IconButton
                                    disabled={application.evaluator_evaluation_status_id === 4}
                                    onClick={() => setShowModal(true)}
                                    type={"Disabled"}
                                />)}
                        </>}
                        <IconButton buttonRef={eyeButtonElement}
                                    onClick={() => setDataModalInfoApplication(application)}
                                    type="Expand"/>
                    </div>
                </div>
            </StyledAllocationCard>

        </>


    )
}

export default ApplicationCard