import React, {useEffect, useState} from 'react';


import {Col, Row} from "antd";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {
    useLayoutContext
} from "../../../../modules/project/components/layout/context/LayoutContext";
import {
    FlexContainer
} from "../../../components/authentication/StyledComponents";
import Empty from "../../../components/data_display/Empty";
import RemixIcon from "../../../components/data_display/RemixIcon";
import Loading from "../../../components/feedback/Loading";
import GrowRadioButton from '../../../components/responsive/GrowRadioButton';
import useDefaultGetRequest from "../../../hooks/useDefaultGetRequest";
import {
    calendula_7,
    golden_purple_7,
    lime_6,
} from "../../../utils/Colors";
import AssesmentsCollapseTable from "./components/AssesmentsCollapseTable";
import {AppstoreOutlined} from "@ant-design/icons";
import MetricsCard from "../../../components/data_display/MetricsCard";

export default function EvaluationListPage() {
    const {t} = useTranslation();
    const {initializeLayout,} = useLayoutContext();
    const [isAllAssessments, setIsAllAssessments] = useState(false);

    const {
        data: evaluationsData,
        isLoading: isLoadingJourney
    } = useDefaultGetRequest({
        url: 'evaluations',
        params: isAllAssessments && {all_evaluations: true},
        config: {version: 'v2'},
        dependence: [isAllAssessments]
    })

    useEffect(() => {
        initializeLayout({
            navbarMenuItems: [
                {
                    label: <Link to={``}>{t("Evaluations")}</Link>,
                    key: `/evaluations`
                },


            ]
        });
    }, []);


    return (
        <>

            <Row gutter={[24, 24]}>
                <Col xs={24}>
                    <Row gutter={[16, 16]}>
                        <Col xs={8} sm={8} lg={8}>
                            <MetricsCard
                                loading={isLoadingJourney}
                                value={evaluationsData?.evaluations_finished}
                                name={t("Done evaluations")}
                                icon={<RemixIcon
                                    remixIconName={"ri-file-edit-fill"}/>}
                                color={lime_6}/>
                        </Col>
                        <Col xs={8} sm={8} lg={8}> <MetricsCard
                            loading={isLoadingJourney}
                            color={calendula_7}
                            icon={<RemixIcon
                                remixIconName={"ri-file-history-line"}/>}
                            name={t('Pending evaluations')}
                            value={evaluationsData?.evaluations_pending}/></Col>
                        <Col xs={8} sm={8} lg={8}> <MetricsCard
                            loading={isLoadingJourney}
                            color={golden_purple_7}
                            icon={<AppstoreOutlined/>}
                            name={t('Evaluated programs')}
                            value={evaluationsData?.programs_count}/></Col>
                    </Row>
                </Col>
                {isLoadingJourney ? <Loading/> : (
                    <>
                        <Col xs={24}>
                            <FlexContainer justify={'end'}>
                                <GrowRadioButton
                                    onChange={(value) => setIsAllAssessments(value)}
                                    defaultValue={isAllAssessments}
                                    options={[
                                        {value: true, label: t('All reviews')},
                                        {
                                            value: false,
                                            label: t('Pending evaluations')
                                        }
                                    ]}
                                />
                            </FlexContainer>
                        </Col>
                        {evaluationsData?.phases?.length === 0 ? (
                            <Col xs={24}><Empty
                                title='There is no evaluation form at this stage.'
                                description='Contact the program manager and request an evaluation form for this stage.'/></Col>) : (
                            evaluationsData?.phases?.map((phase) =>
                                <AssesmentsCollapseTable
                                    isAllAssessments={isAllAssessments} {...phase}/>
                            )
                        )}
                    </>
                )}
            </Row>


        </>

    )
}