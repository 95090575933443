import {useTranslation} from "react-i18next";
import {APPLICATION_STATUS_CHOICES, CHOICES_GENRE, CHOICES_TEAM_TYPE} from "../../../utils/Choices";
import SelectFormField from "../../../components/data_entry/form/SelectFormField";
import RequestSelectFormField from "../../../components/data_entry/form/RequestSelectFormField";
import React, {useEffect, useState} from "react";
import api from "../../../services/api";
import {BuildFieldErrorsDict} from "../../../utils/Utils";
import {Flex} from "antd";
import SimpleCollapse from "../../../components/misc/SimpleCollapse";
import CheckboxWithSwitchFormField from "../../../components/data_entry/form/CheckboxWithSwitchFormField";
import ApplicationTagStatus from "../../../components/tag/ApplicationTagStatus";
import SwitchFormField from "../../../components/data_entry/form/SwitchFormField";
import DateRangePickerFormField from "../../../components/data_entry/form/DateRangePickerFormField";


function getIdKeyByScope(scopeObj, scope) {
    const idKeyByScope = {
        programs: scopeObj["id"],
        phases: scopeObj["program"]
    }
    return idKeyByScope[scope]
}

export function ApplicationFilterForm({scope, scopeObj, extraParams, ...props}) {
    const {t} = useTranslation()
    const [questions, setQuestions] = useState()

    async function fetchQuestions(id) {
        try {
            const response = await api.get(`programs/${id}/get_filter_questions`);
            setQuestions(response.data)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        }
    }

    const getFormItemName = (name) => {
        if(extraParams?.historic){
            return `application__${name}`
        }
        return name
    }

    useEffect(() => {
        if (scope && !questions) {
            void fetchQuestions(getIdKeyByScope(scopeObj, scope))
        }
    }, []);

    return (
        <>
            <Flex vertical={true} gap={8}>
                <SimpleCollapse title={t("Status")}>
                    <CheckboxWithSwitchFormField
                        form={{name: getFormItemName("status__in")}}
                        // search={true}
                        // getSearchValues={(options) => options.map(option => option.label.props.application.status_display)}
                        options={APPLICATION_STATUS_CHOICES.map(option => (
                            {
                                ...option,
                                label: <ApplicationTagStatus
                                    application={{
                                        status: option.value,
                                        status_display: t(option.label)
                                    }}
                                />,
                            }))
                        }
                    />
                </SimpleCollapse>
                {!scope &&
                    <SimpleCollapse title={t("Program")}>
                        <Flex style={{height:278, overflow:"auto"}}>
                            <CheckboxWithSwitchFormField
                                request={{url: "/clients/simple_programs_list"}}
                                search={true}
                                valueField="id"
                                labelField="name"
                                form={{name:getFormItemName("program__id__in"), style:{width:"100%"}}}
                            />
                        </Flex>
                    </SimpleCollapse>
                }
                <SimpleCollapse title={t("Type of Submission")}>
                    <CheckboxWithSwitchFormField
                        form={{name: getFormItemName("program__type__in")}}
                        options={[
                            {value: "open_innovation,internal_innovation", label: t("Solution")},
                            {value: "challenge_mapping", label: t("Challenge")}
                        ]}
                        hideSwitch={true}
                        // search={true}
                    />
                </SimpleCollapse>
                <SimpleCollapse title={t("Archived")}>
                    <Flex align={"center"} gap={8}>
                        <SwitchFormField form={
                            {name: "show_archived", layout: "horizontal", noStyle: true}} defaultValue={false} size="small"
                        />
                        <span>{t("Show archived")}</span>
                    </Flex>
                </SimpleCollapse>
                <SimpleCollapse title={t("Author type")}>
                    <CheckboxWithSwitchFormField
                        form={{name: getFormItemName("team__type__in")}}
                        options={CHOICES_TEAM_TYPE.map(option => (
                            {
                                value: option.label,
                                label: t(option.label)
                            }))
                        }
                        hideSwitch={true}
                        // search={true}
                    />
                </SimpleCollapse>
                <SimpleCollapse title={t("Submission interval")}>
                    <DateRangePickerFormField
                        form={{name: getFormItemName("date_submited__range")}}
                        style={{width: "100%"}}
                    />
                </SimpleCollapse>
            </Flex>
        </>
    );
}

export function CompaniesTeamsFilterForm() {
    const {t} = useTranslation()

    const formItems = [
        {url: "/sectors_size", label: t("Size"), name: "sectors_size__id__in"},
        {url: "/sectors_institution", label: t("Company Sectors"), name: "sectors_institution__id__in"},
        {url: "/sectors_persona", label: t("Company Persona"), name: "sectors_persona__id__in"},
        {url: "/sectors_technology", label: t("Technologies"), name: "sectors_technology__id__in"},
        {url: "/sectors_transaction", label: t("Transaction type"), name: "sectors_transaction__id__in"},
        {url: "/sectors_client", label: t("Client Sectors"), name: "sectors_client__id__in"},
    ];

    const choicesTeamTypeFix = CHOICES_TEAM_TYPE.map(type => ({label: t(type.label), value: type.label}))

    return (
        <>
            <SelectFormField form={{label: t("Application Type"), name: "type"}} options={choicesTeamTypeFix} allowClear={true}/>
            {formItems.map((item, index) => {
                const {url, label, name} = item
                return <RequestSelectFormField mode={"multiple"} key={index} url={url} form={{label, name}}/>
            })}
        </>
    );
}

export function TeamMemberFilterForm() {
    const {t} = useTranslation()
    return (
        <>
            <SelectFormField form={{label: t("Gender"), name: "genre"}} options={CHOICES_GENRE} allowClear={true}/>
            <RequestSelectFormField mode={"multiple"} url={"teams/teams_for_filter"}
                                    form={{label: t("Team"), name: "teams__team__id__in"}}/>
        </>
    )
}