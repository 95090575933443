import {LoadingOutlined} from '@ant-design/icons';
import React, {useEffect, useState} from 'react';
import {Button, Col, Empty, Row, Skeleton, Space, Typography} from 'antd';
import api from "../../../../global/services/api";
import {Link, useParams} from "react-router-dom";
import {ProgressChartBuilder} from "./components/DashboardIndicatorCharts/ProgressChart";
import {IndicatorHistoryChart} from "./components/IndicatorCheckpoint/IndicatorHistory";
import ColumnChart from "./components/DashboardIndicatorCharts/ColumnChart";
import LiquidChart from "./components/DashboardIndicatorCharts/LiquidChart";
import LineAreaChart from "./components/DashboardIndicatorCharts/LineAreaChart";
import {useProjectManagementContext} from "./context/ProjectManagementContext";
import IndicatorDashboardCard from "../../components/IndicatorDashboardCard";
import EmptyPageCard from "../../../../global/components/layout/EmptyPageCard";
import {useTranslation} from "react-i18next";
import RiskChart from "./components/DashboardIndicatorCharts/RiskChart";
import TendencyLineChart from './components/DashboardIndicatorCharts/TendencyLineChart';
import CheckpointProjectIndicatorsList from "../../components/CheckpointProjectIndicatorsList";
import ProjectSummary from "./components/ProjectSummary";
import WideSpace from "../../../../global/components/layout/WideSpace";
import ForecastChart from "./components/DashboardIndicatorCharts/ForecastChart";

const {Text} = Typography;

function IndicatorPlot({indicator}) {
    const [reportData, setReportData] = useState(undefined);
    const {id} = useParams();
    const {t} = useTranslation();
    const fetchReportData = async () => {
        try {
            const params = {}
            if (indicator?.is_default === false) {
                params.indicator = indicator.id;
            } else {
                params.internal_code = indicator?.internal_code;
                if(`${indicator?.internal_code}` === '23'){
                    params.limit = 5
                }
            }
            const response = await api.get(`/project_management/project_dashboard/${id}/fetch_responses_report`, {params});
            setReportData(response.data.map((data) => ({
                ...data,
                "order": `${data.order}`
            })));
        } catch (exception) {
        }
    };

    const renderReport = () => {
        if (reportData.length === 0) {
            return <Empty description={t("There isn't enough data to build this graphic.")}/>;
        }
        const lastReport = reportData.at(-1) || {
            "stored_value": 0,
            "scaled_value": 0,
            "value_display": 0,
        };
        const lastFiveReports = JSON.parse(JSON.stringify(reportData));
        
        switch (indicator["indicator_dashboard_view"]["name"]) {
            case "dashboard_area":
                return <LineAreaChart data={reportData} indicator={indicator}/>;
            case "dashboard_number_chart":
                return <LineAreaChart data={reportData} indicator={indicator} smooth={false} color={"#9254DE"}/>;
            case "dashboard_tendency":
                return <TendencyLineChart data={reportData} min={indicator["scale_min"]} max={indicator["scale_max"]}
                                          indicator={indicator}/>;
            case "dashboard_progress":
                return <ProgressChartBuilder indicator={indicator} data={lastReport}/>;
            case "dashboard_percent":
                return <ProgressChartBuilder indicator={indicator} data={lastReport} color={"#597EF7"}/>;
            case "dashboard_risk":
                return <>
                    <RiskChart data={lastReport} indicator={indicator}/>
                    <Row justify="center">
                        <IndicatorHistoryChart
                            data={lastFiveReports}
                            indicator={indicator}
                            yField="scaled_value"
                            indicatorResponse={lastReport}/>
                    </Row>
                </>;
            case "dashboard_column":
                return <ColumnChart data={reportData} indicator={indicator}/>;
            case "dashboard_liquid":
                const lastInvestmentReport = reportData.sort((a, b)=>parseInt(a.order) - parseInt(b.order)).at(-1);
                return <LiquidChart data={lastInvestmentReport} indicator={indicator}/>;
            default:
                return <Empty/>;
        }
    };

    useEffect(() => {
        if (!reportData) {
            void fetchReportData();
        }
    }, [reportData]);

    return <IndicatorDashboardCard title={t(indicator.name)} description={t(indicator.description)}>
        {reportData ? renderReport() :
            <Space style={{display: "grid", placeContent: "center", height: "100%"}}><LoadingOutlined/></Space>}
    </IndicatorDashboardCard>;
}

function ProjectManagementDashboardPage() {
    const [indicators, setIndicators] = useState(undefined);
    const {project} = useProjectManagementContext();
    const {id} = useParams();
    const {t} = useTranslation();
    
    const fetchIndicators = async () => {
        try {
            const response = await api.get(`/project_management/project_dashboard/${id}/fetch_indicators`);
            setIndicators(response.data);
        } catch (exception) {
        }
    };

    useEffect(() => {
        if (!indicators) {
            void fetchIndicators();
        }
    }, [indicators]);

    const renderDashboardPage = () => {
        if (!project?.dashboard_updated_at) {
            return (
                <EmptyPageCard>
                    <Space direction="vertical">
                        <Text>{t("No checkpoint available")}</Text>
                        <Link to={`/project-portfolio/project/${id}/checkpoints`}>
                            <Button type="primary">{t("Create Your Checkpoint")}</Button>
                        </Link>
                        <Text type="secondary">{t("Dashboard data is based on the project checkpoint. When creating the first checkpoint there will be data to be shown in the checkpoint.")}</Text>
                    </Space>
                </EmptyPageCard>
            )
        }
        
        return (
                <>
                    <WideSpace direction="vertical" size="large">
                        <ProjectSummary/>
                        {/*<Row justify="end">*/}
                        {/*    <Text type="secondary">{t("Updated at")}: {project?.dashboard_updated_at} ({t("last updated checkpoint")}) <ReloadOutlined/></Text>*/}
                        {/*</Row>*/}
                        <Row justify="start" gutter={[24, 32]}>
                            {indicators?.map((indicator) => (
                                <Col xs={24} sm={24} md={12} lg={12} xl={8} xxl={6} style={{minHeight: 320}}>
                                    <IndicatorPlot indicator={indicator}/>
                                </Col>
                            ))}
                            {indicators?.length === 0 &&
                                <Empty style={{width: "100%", height: "100%"}}/>
                            }
                        </Row>
                        <Row gutter={[24, 32]}>
                            <Col span={24}>
                                <ForecastChart/>
                            </Col>
                            <Col span={24}>
                                <CheckpointProjectIndicatorsList maxTagsOverflow={7}/>
                            </Col>
                        </Row>
                    </WideSpace>
                </>
            )
    };

    const isLoading = !project || !indicators;

    return <Skeleton loading={isLoading}>
        {renderDashboardPage()}
    </Skeleton>    
}

export default ProjectManagementDashboardPage;