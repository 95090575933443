import React, {useState} from "react";
import NoProfile from '../../../assets/Icon/no_profile.svg'
import IconButton from "../../../components/IconButton/Index";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import CheckBox from "../../Form/CheckBox";
import {confirmAlert} from "react-confirm-alert";
import {
    Body,
    ButtonContainer,
    Close,
    ConfirmContainer,
    Header
} from "./StyledAllocationComponents";
import Title from "../../Title/Title";
import {ReactComponent as CloseIcon} from "../../../assets/Icon/clear.svg";
import ButtonDefault from "../../Buttons/ButtonDefault";
import AbolishModal from "../../AbolishModal";
import {StyledAllocationCard} from "./ApplicationCard";


const Name = styled.span`
    font-weight: 700;
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    max-height: 48px;
`

function EvaluatorCard({
                           name,
                           index,
                           evaluator,
                           selectionEvaluator,
                           showCheckbox,
                           onChangeEvaluatorSelection,
                           selected,
                           remove,
                           openModal,
                           active,
                           phase,
                           refreshCounts,
                           getAllocation,
                       }) {
    const [showModal, setShowModal] = useState(false)
    const eyeButtonElement = React.useRef()
    const {t} = useTranslation()
    const dateApp = evaluator.justification_invalidate && evaluator.justification_invalidate.date
    const ownerApp = evaluator.justification_invalidate && evaluator.justification_invalidate.owner
    const descApp = evaluator.justification_invalidate && evaluator.justification_invalidate.desc
    function handleSelectionEvaluator({target}) {
        if (!eyeButtonElement.current.contains(target)) {
            selectionEvaluator(evaluator)
        }
    }

    const handleConfirmDeleteAllocation = () => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <ConfirmContainer>
                        <Header>
                            <Title noCamel={true}>{t("WARNING")}</Title>
                            <Close onClick={() => {
                                onClose();
                            }}>
                                <CloseIcon/>
                            </Close>
                        </Header>
                        <Body>
                            <p>{t("Do you want to confirm the deletion?")}</p>
                        </Body>
                        <ButtonContainer>
                            <ButtonDefault onClick={onClose}
                                           action={'secondary'}
                                           label={t("Cancel")}/>
                            <ButtonDefault
                                onClick={() => {
                                    remove(index, evaluator.id);
                                    onClose();
                                }}
                                action={'primary'}
                                label={t("Confirm deletion")}
                                data-cy="confirm-delete-button"
                            />
                        </ButtonContainer>
                    </ConfirmContainer>
                );
            }
        });

    };

    return (
        <StyledAllocationCard
            title={evaluator.evaluator_evaluation_status_id === 4 &&
                t("Inactivated evaluation on by \n Justification:", {
                    dateApp,
                    ownerApp,
                    descApp
                })}
            invalid={evaluator.evaluator_evaluation_status_id === 4}
            className="divAllocationCard"
            style={{
                width: "100%",
                height: 58,
                alignItems: 'center',
                display: "flex"
            }}>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flex: 0,
                marginLeft: 4,
                marginRight: 4,
            }}>
                <AbolishModal evaluatorAbolish getAllocation={getAllocation}
                              refreshCounts={refreshCounts} phase={phase}
                              active={evaluator} data={active} show={showModal}
                              onHide={() => setShowModal(false)}/>
                {!remove && <div>
                    <CheckBox
                        disabled={evaluator.evaluator_evaluation_status_id === 4}
                        id={name}
                        hidden={!showCheckbox}
                        options={[{
                            id: evaluator.id,
                            value: evaluator.id,
                            checked: selected,
                            onChange: (event) => onChangeEvaluatorSelection(event.target),
                        }]}/>
                </div>}
                {/*<Form.Group className="mb-0" controlId="formBasicCheckbox">*/}
                {/*    <Form.Check className={`${showCheckbox ? 'mr-2': ''}`}  type="checkbox" hidden={!showCheckbox}*/}
                {/*                value={evaluator.id} checked={selected}*/}
                {/*                onChange={(event) => onChangeEvaluatorSelection(event.target)}/>*/}
                {/*</Form.Group>*/}
            </div>

            <div style={{display: "flex", flex: 10}}
                 onClick={handleSelectionEvaluator}>
                <div style={{
                    height: 32,
                    width: 32,
                    borderRadius: "50%",
                    alignSelf: "center",
                    marginRight: 6,
                    marginLeft: 0
                }}>
                    <img alt={'evaluator-img'}
                         src={evaluator?.picture ? evaluator?.picture : NoProfile}
                         style={{width: 32, borderRadius: "50%"}}/>
                </div>
                <div style={{
                    flex: 7,
                    display: "flex",
                    flexDirection: "column",
                    alignSelf: "center",
                    fontWeight: 700,
                    fontSize: 14,
                    lineHeight: 1,
                    height: "90%",
                    justifyContent: "space-evenly"
                }}>
                    <Name>{evaluator.full_name}</Name>
                    <span style={{
                        fontSize: 12,
                        alignSelf: "baseline",
                        border: "1px solid black",
                        padding: "2px 3px",
                        borderRadius: 4,
                        backgroundColor: "rgb(209 209 209)",
                        marginTop: 4
                    }}>{t("Assessments")}: {evaluator.finished_assessments_count}/{evaluator.count_applications_phase}</span>
                </div>
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    fontWeight: 500,
                    fontSize: 12
                }}>


                    {remove && <>

                        {evaluator.evaluator_evaluation_status_id === 1 ?
                            (
                                <IconButton
                                    disabled={evaluator.evaluator_evaluation_status_id === 4}
                                    onClick={handleConfirmDeleteAllocation}
                                    type={"Delete"}
                                    data-cy="delete-button"
                                />
                            ) :
                            (<IconButton
                                disabled={evaluator.evaluator_evaluation_status_id === 4}
                                onClick={() => setShowModal(true)}
                                type={"Disabled"}
                            />)}
                    </>}

                    <IconButton buttonRef={eyeButtonElement}
                                onClick={() => openModal(evaluator)}
                                type="Expand"/>
                </div>
            </div>
        </StyledAllocationCard>
    )
}

export default EvaluatorCard