import React from "react";
import styled from 'styled-components';
import {Avatar, Dropdown, Space} from "antd";
import {useLayoutContext} from "../../../modules/project/components/layout/context/LayoutContext";
import LogoSolv from "../../../assets/img/solv/logo.png";
import {useThemeContext} from "../../context/ThemeContext"
import {DownOutlined} from "@ant-design/icons";
import {useUserContext} from "../../context/UserContext";
import {HOST_ENTREPRENEUR} from "../../../settings";


const StyledLogoContainer = styled.div`
  width: 100%;
  display: ${({collapsed}) => collapsed ? "none" : "block"};
  overflow: hidden;
`

const StyledLogoImg = styled.img`
  object-fit: contain;
  max-width: 138px;
  height: 45px;
  display: block;
  margin-left: 8px;
`
const StyledDownOutlined = styled(DownOutlined)`
  color: var(--text_color_menu);
`

export default function EnvironmentDropdown() {
    const {_user} = useUserContext();
    let hostname = window.location.hostname;
    let port = window.location.port ? ":" + window.location.port : "";
    const {themeConfig} = useThemeContext();
    const clients = _user.clients ?? [];
    const {collapsed} = useLayoutContext();


    const getEnvironmentLink = ({client, id}) => {
        const environmentLink = '//' + client.domain + port;
        if (client.domain === hostname) {
            return undefined;
        }
        return {
            label: <a href={environmentLink}><Space><Avatar size="small"
                                                            src={client["logo_url"]}/>{client["name"]}
            </Space></a>,
            key: id + 1,
        };
    }

    const environments = [
        !HOST_ENTREPRENEUR.includes(hostname) && {
            label: <a href={"//" + HOST_ENTREPRENEUR}><Space><Avatar
                size="small" src={LogoSolv}/>Solv Network</Space></a>,
            key: 0,
        },
        ...clients.map((client, id) => getEnvironmentLink({client, id}))
    ];

    return (
        <Dropdown trigger="click" menu={{items: environments}}>
            <Space style={{cursor: 'pointer'}}>
                <StyledLogoContainer collapsed={collapsed}>
                    <StyledLogoImg
                        src={themeConfig["img_action_bar"]}
                        alt="logo"/>
                </StyledLogoContainer>
                <StyledDownOutlined/>
            </Space>
        </Dropdown>
    );
}




