import {Col, Row} from "antd";
import React from "react";
import PhasesTimeLine from "../../../../../data_display/PhasesTimeline";

export default function ScheduleChallengeTab({challenge}) {
    return (
        <Row gutter={24}>
            <Col span={24}>
                <PhasesTimeLine programId={challenge.program.id} program={challenge?.program}/>
            </Col>
        </Row>
    );
}