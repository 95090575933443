import React, {useEffect, useState} from "react";
import {useProgramContext} from "../../../../ProgramsNavigator";
import {Col, Progress, Row, Tooltip, Typography} from 'antd';
import {useTranslation} from "react-i18next";
import useTable from "../../../../../global/hooks/useTable";
import Table from "../../../../../global/components/data_display/Table";
import DrawerStartupPerfil from "./DrawerStartupPerfil";
import styled from "styled-components";

const Description = styled.div`
    margin-bottom: 32px;
    font-size: 14px;
    white-space: pre-wrap;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    overflow: hidden;
    -webkit-box-orient: vertical;
    display: -webkit-box;
`
export default function StartupsTableSection({hasRadarData, updateTable}) {
    const {program} = useProgramContext();
    const {t} = useTranslation()
    const [open, setOpen] = useState(false)
    const [startup, setStartup] = useState()
    const {
        tableData,
        isLoading: isLoadingTable,
        tableParams,
        handleTableChange,
        resetTable
    } = useTable({
        url: `/program_hunt/${program.id}/list_organizations`,
        extraParams: {
            page_size: 1000
        },
        makeRequest: hasRadarData?.organizations_count > 0,
        hasPagination: false,
    })

    function handleOnRowClick(value) {
        setStartup(value)
        setOpen(true)
    }

    const columns = [
        {
            title: t('Company'),
            dataIndex: 'name',
            sorter: 'name',
        },
        {
            title: t('Challenge'),
            dataIndex: 'challenge_name',
            sorter: 'challenge_name',
        },
        {
            title: t('Industry'),
            dataIndex: 'industry',
        },

        {
            title: t('Location'),
            dataIndex: 'headquarters_local',
            ellipsis: true,
        },
        {
            title: t('Description'),
            dataIndex: 'description',
            width: 300,
            render: value => <Tooltip title={value}>
                <Description>{value || '-'}</Description>
            </Tooltip>
        },
        {
            title: t('Probability of solution'),
            dataIndex: 'match_porcentage',
            ellipsis: true,
            render: (value, object) => {

                return <Progress percent={value}/>
            }
        },
        {
            title: t('Source'),
            dataIndex: 'score',
            width: '50px',
            render: (value, object) => {

                return object?.match_porcentage === 0 ? t('Manual') : 'Silvia'

            }
        },
    ]

    useEffect(() => {
        if (updateTable) {
            resetTable()
        }
    }, [updateTable])

    return (
        <>
            <Row gutter={[16, 16]} style={{marginTop: 44}}>

                <Col>
                    <Typography.Title
                        level={5}>{t("Highlight Startups")}</Typography.Title>
                </Col>
                <Col flex={'1'}>
                    {/*<FlexContainer justify={'end'} gap={16}>*/}

                    {/*    <Button*/}

                    {/*        type='link'*/}
                    {/*        // onClick={handleExportProgram}*/}
                    {/*        // loading={isExportLoading}*/}
                    {/*        icon={<DownloadOutlined/>}/>*/}
                    {/*</FlexContainer>*/}


                </Col>
                <Col xs={24}>
                    <Table
                        emptyDescription={t('There are no startups selected')}
                        dataSource={tableData}
                        onChange={handleTableChange}
                        loading={isLoadingTable}
                        tableParams={tableParams}
                        onRowClick={handleOnRowClick}
                        columns={columns}/>
                </Col>
            </Row>
            <DrawerStartupPerfil open={open} setOpen={setOpen}
                                 startup_id={startup?.id}
                                 resetTable={resetTable}/>
            {/*<DrawerManualSearchStartups open={true}/>*/}
        </>

    );
}
