import React from "react";
import TruncateText from "../../responsive/TruncateText";
import Flex from "../../responsive/Flex";
import Dot from "../../other/Dot";

export default function LegendItem({isActive = true, label, onClick}) {

    return (
        <Flex wrap={false} style={{cursor: "pointer", alignItems: "center", overflow: "hidden",  opacity: isActive ? 1: 0.4}} gap={8} onClick={onClick}>
            <Dot color={label.color} size={9}/>
            <TruncateText style={{color: !isActive && "gray", minWidth: 0}}>
                {label?.text}
            </TruncateText>
        </Flex>
    )
}