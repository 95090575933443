import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import Button from "../../../../../components/data_entry/Button";

import {DateFormatByUserTimezone} from "../../../../../utils/dates";
import useTable from "../../../../../hooks/useTable";
import {Col, Row} from "antd";
import Table from "../../../../../components/data_display/Table";
import Drawer from "../../../../../components/layout/Drawer";
import {RANKING_STATUS} from "../../../../../utils/Constants";
import DrawerRankingPlacements
    from "../../../../../components/data_display/DrawerRankingPlacements";


const Title = styled.h2`
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0px;
`


export default function RankingHistory({url, hideColumns=[]}) {

    const [open, setOpen] = useState(false)
    const [openPlacements, setOpenPlacements] = useState(false)
    const [rankingId, setRankingId] = useState(false)
    const {t} = useTranslation();
    const {
        handleRequestTable,
        tableData,
        isLoading,
        tableParams,
        handleTableChange,
    } = useTable({
        url: url,
        waitExternalLoading:!open,
        extraParams: {status: RANKING_STATUS.CLOSED},
        hasPagination: true,
        dependenceRequest:[open]
    })


    function handleOpenPlacements(ranking) {
        setRankingId(ranking.id)
        setOpenPlacements(true)
    }

    function onClose() {
        setOpen(false)
    }

    const columns = [
        {
            title: t('Date'),
            dataIndex: 'open_at',
            key: 'open_at',
            render: (value, ranking) =>
                <>  {DateFormatByUserTimezone(ranking?.open_at)} {(ranking && !ranking.endless) && <>- {DateFormatByUserTimezone(ranking?.close_at)}</>}
                </>
        },
        {
            title: t('Source'),
            dataIndex: 'source_name',
            key: 'source_name',
        },
        {
            title: t('Winner'),
            dataIndex: 'first_position.user_obj.name',
            key: 'first_position',
        }, {
            title: t('Actions'),
            // dataIndex: 'name',
            // sorter: 'name',
            key: 'action',
            width: '202px',
            render: (value, ranking) => <Button type={'link'}
                                                onClick={() => handleOpenPlacements(ranking)}>{t('See placements')}</Button>
        },

    ]


    return (
        <>
            <Button onClick={() => setOpen(true)} type={'default'}>
                {t('View history')}
            </Button>
            <Drawer width={1200} title={t('Ranking history')} open={open}
                    onClose={onClose}>

                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Title>
                            {t('Previous ranking list')}
                        </Title>
                    </Col>
                    <Col xs={24}>
                        <Table columns={columns.filter(({dataIndex}) => !hideColumns.includes(dataIndex))} onChange={handleTableChange}
                               tableParams={tableParams}
                               loading={isLoading}
                               noScroll
                               dataSource={tableData}
                        />
                    </Col>
                </Row>
            </Drawer>
            <DrawerRankingPlacements id={rankingId} open={openPlacements} setOpen={setOpenPlacements} hasClose={false}/>
        </>
    )
}
