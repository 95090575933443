import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {
    ExclamationCircleOutlined,
    PlusOutlined, SettingOutlined,
} from "@ant-design/icons";
import Button from "../../../../../components/data_entry/Button";
import Drawer from "../../../../../components/layout/Drawer";

import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import api from "../../../../../services/api";
import {
    ToastNotification
} from "../../../../../components/feedback/ToastNotification";
import * as ConstantsMessage from "../../../../../utils/fileWithMessage";
import {BuildFieldErrorsDict} from "../../../../../utils/Utils";
import {
    useLayoutContext
} from "../../../../../../modules/project/components/layout/context/LayoutContext";

import RemixIcon from "../../../../../components/data_display/RemixIcon";
import {neutral_5} from "../../../../../utils/Colors";
import {Modal} from "antd";

const Message = styled.span`
    font-size: 14px;
`
const StyledSettingOutlined = styled(SettingOutlined)`
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;

    //background: red;

    &:hover {
        background: ${neutral_5};
    }
`
export default function ResetPointsButton({bodyRequest}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const {updateLayoutContent} = useLayoutContext()

    function onClose() {
        setOpen(false)
    }


    async function handleReset() {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: t(
                "Proceed with caution: this change cannot be undone. Do you want to reset everyone's score in the environment?",
            ),
            onOk: async () => {
                try {
                    setIsLoading(true);

                    await api.post(`/action/reset_all_points`);
                    updateLayoutContent()
                    onClose()
                    ToastNotification(t(ConstantsMessage.SUCCESS));


                } catch (error) {

                    BuildFieldErrorsDict(error, null, null);
                } finally {
                    setIsLoading(false);
                }
            },
            okButtonProps: {danger: true, "data-cy":"confirm-reset-score-button"},
            okText: t('Reset score'),
            cancelText: t('Go back')

        })


    };

    return (
        <>
            <StyledSettingOutlined
                onClick={() => setOpen(true)}
                data-cy="points-managements-settings-button"
            />


            <Drawer title={t('Advanced settings')} open={open}
                    onClose={onClose}>
                <FlexContainer gap={8}>
                    <Message>
                        {t('Reset all environment members\' scores')}
                    </Message>
                    <Button onClick={handleReset} data-cy="reset-score-button" icon={<RemixIcon
                        remixIconName={'ri-delete-bin-6-line'}/>}
                            danger>{t('Reset score')}</Button>
                </FlexContainer>
            </Drawer>

        </>


    )
}
