import React, {useEffect} from "react";
import {
    Link,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch
} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    useLayoutContext
} from "../../../modules/project/components/layout/context/LayoutContext";
import {AdminRoute} from "../../routes/PrivateRoute";
import PageEnvironmentGeneral from "../../../pages/Environment/TabGeneral/PageEnvironmentGeneral";
import PageEnvironmentUsers from "../../../pages/Environment/PageEnvironmentUsers";
import PageEnvironmentApperance
    from "../../../pages/Environment/TabEnvironmentApperance/PageEnvironmentApperance";
import PageEnvironmentFinancial
    from "../../../pages/Environment/PageEnvironmentFinancial";


export default function EnvironmentNavigatorPage({hasPermission}) {
    const {t} = useTranslation();
    const {url} = useRouteMatch();
    const {initializeLayout,} = useLayoutContext();

    let {path} = useRouteMatch();
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        const pathNameList = location.pathname.split('/')
        if (pathNameList[pathNameList.length - 1] === 'environment') {
            history.push(`${location.pathname}/general`)
        }
    }, [location]);


    const environmentMenuItems = [
        {
            label: <Link to={`${url}/general`}>{t("General")}</Link>,
            key: `${url}/general`,
        },
        {
            label: <Link to={`${url}/managers`}>{t("Users")}</Link>,
            key: `${url}/managers`,
        },
        {
            label: <Link to={`${url}/theme`}>{t("Appearance")}</Link>,
            key: `${url}/theme`,
        },
        // {
        //     label: <Link to={`${url}/financial`}>{t("Financial")}</Link>,
        //     key: `${url}/financial`,
        // },


    ];


    useEffect(() => {
        initializeLayout({
            navbarMenuItems: environmentMenuItems,
        });
    }, []);

    return (

        <Switch>
            <AdminRoute path={`${path}/general`}
                        hasPermission={hasPermission}
                        render={(props) => <PageEnvironmentGeneral/>}
            />
             <AdminRoute path={`${path}/managers`}
                        hasPermission={hasPermission}
                        render={(props) => <PageEnvironmentUsers/>}
            />
            <AdminRoute path={`${path}/theme`}
                        hasPermission={hasPermission}
                        render={(props) => <PageEnvironmentApperance/>}
            />
            <AdminRoute path={`${path}/financial`} //OBS se mudar o nome dessa roda deve ser trocado dentro da função getLayout() dentor no LayoutSwitch.js
                        hasPermission={hasPermission}
                        render={(props) => <PageEnvironmentFinancial/>}
            />

        </Switch>
    );
}

