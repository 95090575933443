import {createContext, useContext, useEffect, useState} from "react";
import api from "../../../../../services/api";
import {BuildFieldErrorsDict} from "../../../../../utils/Utils";

const ApplicationDrawerContext = createContext({});


export function ApplicationDrawerContextProvider({children}) {

    const [solution, setSolution] = useState(undefined);
    const [groupedApplications, setGroupedApplications] = useState(undefined)
    const [originatedChallenges, setOriginatedChallenges] = useState()
    const [messageCount, setMessageCount] = useState();
    const [requestsCount, setRequestsCount] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [hasEvaluatorRequestPermission, setHasEvaluatorRequestPermission] = useState(false);
    const [commentMode, setCommentMode] = useState()
    const [topic, setTopic] = useState()
    const [updateContentOnClose, setUpdateContentOnClose] = useState(false)

    const fetchSolution = async (id) => {
        try {
            if (!id) return;
            setIsLoading(true)
            const response = await api.get(`v2/solutions/${id}`);
            const responsePermission = await api.get(`/dynamic_permissions/check_evaluator_request_permission/${id}`);
            const requestCountResponse = await api.get(`diligences/count_by_application/${response.data.id}`)
            setRequestsCount(requestCountResponse.data.count);
            setSolution(response.data);
            setHasEvaluatorRequestPermission(responsePermission.data.permission);
            setMessageCount(response.data.unread_messages_count);
        } catch (exception) {
        } finally {
            setIsLoading(false)
        }
    }
    const renderPhaseContent = ({
                                    phase,
                                    solutionResult,
                                    applicationContent,
                                    evaluationContent,
                                    applicationIncompleteContent,
                                    evaluationIncompleteContent
                                }) => {
        if (solutionResult.is_finished) {
            switch (phase.type) {
                case 1:
                    return applicationContent;
                case 2:
                    return evaluationContent;
            }
        } else {
            switch (phase.type) {
                case 1:
                    return applicationIncompleteContent;
                case 2:
                    return evaluationIncompleteContent;
            }
        }
    }

    const fetchGroupedApplications = async () => {
        try {
            // const url = ;
            setIsLoading(true)
            const {data} = await api.get(`v2/applications/${solution.id}/list_grouped`);
            setGroupedApplications(data);
        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    }

    const fetchOriginatedChallenges = async () => {
        try {
            const url = `/applications/${solution?.id}/originated_challenges`;
            const {data} = await api.get(url);
            setOriginatedChallenges(data);
        } catch (error) {

        }
    }

    async function fetchTopic(search = '') {
        try {
            const response = await api.get(`/topics/${solution.topic_id}`,);
            setTopic(response.data)

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        }
    };

    function refreshContentOnClose() {
        setUpdateContentOnClose(true)
    }

    return (
        <ApplicationDrawerContext.Provider value={{
            messageCount,
            setMessageCount,
            solution,
            hasEvaluatorRequestPermission,
            setSolution,
            isLoading,
            groupedApplications,
            setGroupedApplications,
            originatedChallenges,
            setOriginatedChallenges,
            fetchOriginatedChallenges,
            fetchSolution,
            renderPhaseContent,
            fetchGroupedApplications,
            requestsCount,
            setRequestsCount,
            commentMode,
            setCommentMode,
            fetchTopic,
            topic,
            setTopic, updateContentOnClose,refreshContentOnClose
        }}>
            {children}
        </ApplicationDrawerContext.Provider>
    );
}

export function useApplicationDrawerContext() {
    return useContext(ApplicationDrawerContext);
}