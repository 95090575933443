import React from 'react'
import styled from 'styled-components'

import {FlexContainer} from "../authentication/StyledComponents";


const Container = styled(FlexContainer)`
    padding:16px;
    background: white;
    border-radius: 6px;
`

const Title = styled.h1`
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 0;

`

export default function HeaderTitleContainer({title, rigthContent, ...props}) {


    return (

        <Container gap={16} justify={'space-between'} wrap={'wrap'} {...props}>
            <Title>{title}</Title>
            <FlexContainer gap={16}>
                {rigthContent}
            </FlexContainer>
        </Container>

    );
}