import React, {useEffect, useRef, useState} from "react";
import TagSelector from "../../../components/form/TagSelector";
import {Alert, Button, Form, Grid, Popover, Row, Space, Switch, Typography} from "antd";
import moment from "moment/moment";
import {EditTwoTone, ExclamationCircleOutlined} from "@ant-design/icons";
import styled from "styled-components";
import DatePickerLocal from "../../../../../global/components/data_entry/DatePickerLocal";
import MoneyInput from "../../../components/form/MoneyInput";
import { useTranslation } from "react-i18next";
import SolvTag from "../../../../../global/components/tag/SolvTag";
import TruncateText from "../../../../../global/components/responsive/TruncateText";

const {Text} = Typography;

const IndicatorStyledCard = styled(Alert)`
  padding: 24px !important;
  flex-grow: 1;
  background-color: ${({type}) => type ? undefined : "white"};
  cursor: pointer;
`;

function IndicatorValue({left, right}) {
    const ref = useRef();
    const [fontSize, setFontSize] = useState("100%");

    const screens = Grid.useBreakpoint();


    useEffect(()=>{
        if (ref.current) {
            let size = ref.current?.offsetWidth / 5;
            if (size < 50) {
                size = 50;
            }
            setFontSize(`${size}%`);
        }
    }, [screens])
    return <>
        <div ref={ref} style={{width: "100%", display: "flex", flexWrap: "wrap", height: "max-content", gap: 8, paddingTop: 24, fontSize: "32px"}}>
            <Text type="secondary" style={{fontSize: 12, verticalAlign: "top"}}>{left}</Text><Text style={{fontSize, fontWeight: 500}}>{right}</Text>
        </div>
    </>;
}

export function IndicatorFormItem({indicatorResponse}) {
    const { t } = useTranslation();
    
    const {indicator} = indicatorResponse;

    const tagCreateUrl = `/project_management/project_indicator_responses/${indicatorResponse?.id}/create_tag`;

    const inputProps = {
        prefix: <Text style={{fontSize: 12}} type="secondary">{indicator["indicator_view"]["field_prefix"]}</Text>,
        suffix: <EditTwoTone type="primary"/>,
        size: "large",
        style: {width: "100%"},
    };

    if (indicator["indicator_type"] === "date") {
        return <>
            <Form.Item name="stored_value"><DatePickerLocal {...inputProps} data-cy="indicator-date-input"/></Form.Item>
            <Form.Item name="is_reached" label={t("Reached")} valuePropName="checked"><Switch data-cy="switch-input"/></Form.Item>
        </>;
    }

    if (indicator["indicator_type"] === "tags") {
        return <>
            {/*<Form.Item name="tags">*/}
            {/*    <TagSelector tags={indicatorResponse["indicator"]["all_tags"]} tagCreateUrl={tagCreateUrl} {...inputProps} allowClear data-cy="tag-selector-input"/>*/}
            {/*</Form.Item>*/}
        </>;
    }

    if (indicator["indicator_view"]["name"] === "currency") {
        return <Form.Item name="stored_value" initialValue={indicatorResponse["stored_value"]}><MoneyInput {...inputProps} data-cy="money-input"/></Form.Item>;
    }

}

function IndicatorProjectCard({indicator, indicatorResponse, handleSubmit, type, form, isEditing, extraDescription, ...props}) {
    const { t } = useTranslation();
    const getSelectedTags = () => {
        return indicatorResponse["tags"]?.map((tag) => tag.id);
    };

    useEffect(() => {
        if (indicatorResponse && isEditing) {
            form.setFieldsValue({
                "stored_value": indicatorResponse["stored_value"],
                "tags": getSelectedTags(),
                "is_reached": indicatorResponse["is_reached"],
            });
        }
    }, [indicatorResponse]);

    const renderTags = () => {
        const [showPopupControls, setShowPopupControls] = useState(false);
        const {tags} = indicatorResponse;
        const showedTags = tags.slice(0, 4);
        const hiddenTags = tags.slice(4);

        useEffect(() => {
            if (tags.length > 4) {
                setShowPopupControls(true);
            } else {
                setShowPopupControls(false);
            }
        }, [tags]);


        return (<>
                <div style={{display: "flex", gap: 4, flexWrap: "wrap"}}>
                    {showedTags.map((tag) => <SolvTag color={tag.color}>{tag.name}</SolvTag>)}
                    {showPopupControls &&
                        <Popover
                            trigger="hover"
                            content={<div style={{display: "flex", gap: 4, flexWrap: "wrap", maxWidth: "300px"}}>
                                {hiddenTags.map((tag) => <SolvTag color={tag.color}>{tag.name}</SolvTag>)}
                            </div>}>
                            <Button style={{all: "unset"}}>...</Button>
                        </Popover>}
                </div>

            </>
        );
    };
    const renderIndicatorValue = () => {
        if (indicator["indicator_type"] === "tags") {
            return <></>
            // return renderTags();
        }
        if (indicator["indicator_type"] === "date") {
            if (indicatorResponse["is_reached"]) {
                return <IndicatorValue left={t("Reached at")} right={moment(indicatorResponse["updated_at"]).format('DD/MM/YYYY')}/>;
            } else {
                if (indicatorResponse["is_late"]) {
                    return <IndicatorValue left={t("Delay of")} right={indicatorResponse["natural_date"]}/>;
                }
                return <IndicatorValue left={indicatorResponse["stored_value"] && t("Forecast")} right={indicatorResponse["natural_date"]}/>;
            }
        }
        return <IndicatorValue left={indicator["indicator_view"]["field_prefix"]} right={indicatorResponse["value_display"]}/>;
    };

    return <>
        <IndicatorStyledCard
            type={type}
            style={{minHeight: "240px", borderRadius: "8px"}}
            description={
                <>
                    <Row wrap={false} align="middle" style={{gap: 4}}>
                        <ExclamationCircleOutlined/>
                        <div style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                            <Text>{t(indicator.name)}</Text></div>
                    </Row>
                    <div  style={{height: "90px"}}>
                        <Text type="secondary">
                            <TruncateText lines={4}>
                                {t(indicator.description)}
                            </TruncateText>
                        </Text>
                    </div>
                    {extraDescription}
                    {indicatorResponse && isEditing &&
                        <Form onFinish={handleSubmit} form={form} data-cy="indicator-form">
                            <IndicatorFormItem indicatorResponse={indicatorResponse} form={form}/>
                        </Form>
                    }
                    {indicatorResponse && !isEditing && renderIndicatorValue()}
                </>
            } {...props} />
    </>;
}

export default IndicatorProjectCard;