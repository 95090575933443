import React from "react";

import {useTranslation} from "react-i18next";

import {Switch} from "antd";
import CollapseFormItem
    from "../../../../global/components/data_entry/CollapseFormItem";
import TransparentCollapse
    from "../../../../global/components/misc/TransparentCollapse";


export default function SectionEnvironmentIA({form, FORM_NAMES}) {
    const {t} = useTranslation();


    return (
        <TransparentCollapse
            header={t("Artificial intelligence")}>

            <CollapseFormItem
                input={<Switch/>}
                extraFormItemProps={{valuePropName: 'checked'}}
                help={t('Enable authors to use AI-powered inspiration to help write their applications')}
                name={FORM_NAMES.CHAT_IA_ACTIVED}
                label={t('Enable AI-Powered Response Inspiration')}/>


        </TransparentCollapse>


    );
}
