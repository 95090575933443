import React from 'react';
import {Space} from "antd";
import {CopyOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {ToastNotification} from "../feedback/ToastNotification";
import {useTranslation} from "react-i18next";
import {FlexContainer} from "../authentication/StyledComponents";
import SolvTag from "../tag/SolvTag";
import {gold_1} from "../../utils/Colors";

const colors = {
    'pending': gold_1,
    'consolidated': '#DDE2FB',
    'revoked': '#FFA39E',
}

const Status_Text = {
    'pending': 'Pending',
    'consolidated': 'Consolidated',
    'revoked': 'Revoked',
}

export default function TagActionGamification({status, ...props}) {

    const {t} = useTranslation()
    return (
       <SolvTag
                    color={colors[status]}>{t(Status_Text[status])}</SolvTag>

    )
}