import React from "react";
import DefaultComponent from "./DefaultComponent";
import ImageInput from "../../../Form/ImageInput";

function ImageForm(props) {
    return (
        <DefaultComponent {...props}>
            <ImageInput />
        </DefaultComponent>
    )
}

export default ImageForm