import React, {createContext, useContext, useEffect} from "react";
import {Link, useLocation} from "react-router-dom";
import ExtraHeader from "../../../modules/project/components/layout/ExtraHeader";
import {
    useLayoutContext
} from "../../../modules/project/components/layout/context/LayoutContext";
import {useTranslation} from "react-i18next";

const TagsContext = createContext();

export const TagsProvider = ({children}) => {
    const {initializeLayout} = useLayoutContext();
    const location = useLocation();
    const {t} = useTranslation();



    return <TagsContext.Provider
        value={{}}>
        {children}
    </TagsContext.Provider>
}

export const useTagsContext = () => {
    return useContext(TagsContext);
}

