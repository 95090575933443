import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {
    PlusOutlined,
} from "@ant-design/icons";
import {GamificationCard} from "./StyledComponents";
import {Form as FormAnt} from "antd";
import Button from "../../../../../components/data_entry/Button";
import Drawer from "../../../../../components/layout/Drawer";
import Form from "../../../../../components/data_entry/form/Form";
import InputFormField
    from "../../../../../components/data_entry/form/InputFormField";
import DateRangePickerFormField
    from "../../../../../components/data_entry/form/DateRangePickerFormField";
import CheckboxFormField
    from "../../../../../components/data_entry/form/CheckboxFormField";
import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import api from "../../../../../services/api";
import {
    ToastNotification
} from "../../../../../components/feedback/ToastNotification";
import * as ConstantsMessage from "../../../../../utils/fileWithMessage";
import {BuildFieldErrorsDict} from "../../../../../utils/Utils";
import DatePickerFormField
    from "../../../../../components/data_entry/form/DatePickerFormField";
import {
    useLayoutContext
} from "../../../../../../modules/project/components/layout/context/LayoutContext";
import moment from "moment/moment";


const FORM_NAMES = {
    TITLE: 'title',
    ENDLESS: 'endless',

    DATES: 'dates',
    START_DATE: 'start_date',
}

export default function AddNewRankingButton({bodyRequest}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [form] = FormAnt.useForm()
    const isEndless = FormAnt.useWatch(FORM_NAMES.ENDLESS, form)
    const {updateLayoutContent} = useLayoutContext()

    function onClose() {
        setOpen(false)
        form.resetFields()
    }

    async function getValuesForm() {
        let data = await form.getFieldsValue()
        const newData = {...data}

        if (isEndless) {
            newData['open_at'] = newData[FORM_NAMES.START_DATE]
            newData['close_at'] = newData[FORM_NAMES.START_DATE]//OBS: quando não tem fim, o bank coloca uma data longa, esta enviado so para não da erro de requireds
            delete newData[FORM_NAMES.START_DATE]
        } else {
            newData['open_at'] = newData[FORM_NAMES.DATES][0]
            newData['close_at'] = newData[FORM_NAMES.DATES][1]
            delete newData[FORM_NAMES.DATES]
        }
        return newData
    }

    async function handleSubmit() {
        try {
            setIsLoading(true);
            let data = await getValuesForm()
            if(bodyRequest){
                data = {...data, ...bodyRequest}
            }
            await api.post(`/ranking`, data);
            updateLayoutContent()
            onClose()
            ToastNotification(t(ConstantsMessage.SUCCESS));


        } catch (error) {
            const startDateError = error?.response?.data?.errors?.open_at[0]
            if (startDateError) {
                if (isEndless) {
                    form.setFields([{
                        name: FORM_NAMES.START_DATE,
                        errors: [startDateError]
                    }])
                } else {
                    form.setFields([{
                        name: FORM_NAMES.DATES,
                        errors: [startDateError]
                    }])
                }
            }
            BuildFieldErrorsDict(error, form.setFields, null);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Button onClick={() => setOpen(true)} icon={<PlusOutlined/>}
                    type={'default'}>{t('Create new ranking')}</Button>

            <Drawer title={t('New ranking')} open={open} onClose={onClose}
                    footer={
                        <FlexContainer justify="end"><Button
                            onClick={form.submit}
                            loading={isLoading}>{t("Create")}</Button></FlexContainer>
                    }>
                <Form form={form} disabled={isLoading} onFinish={handleSubmit}>

                    <InputFormField showCount maxLength={30} form={{
                        name: FORM_NAMES.TITLE,
                        label: t('Title'), rules: [{
                            required: true,
                            message: t('This field is require')
                        }],
                    }}/>
                    <CheckboxFormField mode={'single'}
                                       label={t('Endless ranking')} form={{
                        name: FORM_NAMES.ENDLESS,
                        rules: [{
                            required: true,
                            message: t('This field is require')
                        }],
                    }}/>

                    {isEndless ? (
                        <DatePickerFormField disabledDate={(currentDate) => !(currentDate >= moment().add(0, 'days')) } form={{
                            name: FORM_NAMES.START_DATE,
                            label: t('Start date'),
                            rules: [{
                                required: true,
                                message: t('This field is require')
                            }],
                        }}/>
                    ) : (
                        <DateRangePickerFormField
                            disabledDate={(currentDate) => !(currentDate >= moment().add(0, 'days')) }
                            style={{width: '100%'}}
                                                  form={{
                                                      name: FORM_NAMES.DATES,
                                                      label: t('Ranking period'),
                                                      rules: [{
                                                          required: true,
                                                          message: t('This field is require')
                                                      }],
                                                  }}/>)}
                </Form>
            </Drawer>

        </>


    )
}
