import React from 'react';
import styled from 'styled-components';
import {Col, Row, Space, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {
    ReactComponent as MessagesIcon
} from "../../../assets/Icon/messages.svg";
import RemixIcon from "./RemixIcon";
import {PushpinOutlined} from "@ant-design/icons";
import {DatetimeFormatByTimezone} from "../../utils/dates";
import RobotImage from "../../../assets/img/robot.webp";
import {getOrDefault} from "../../utils/Utils";
import UserAvatar from "../misc/UserAvatar";
import SolvTag from "../tag/SolvTag";
import PermissionTagsList from "../tag/PermissionTagsList";

const Container = styled.div`
  cursor: pointer;
  min-height: 90px;
  position: relative;
  padding: 16px;
  width: 100%;
  background: ${({isVisibled}) => isVisibled ? 'white' : '#F5F5F5'};
`

const InfoContainer = styled.div`
  font-size: 12px;
`

const Badge = styled.div`

  position: absolute;
  top: 50%;
  padding: 6px;
  right: 0;
  font-size: 12px;
  background: #FF4D4F;
  border-radius: 16px;
  color: white;
  transform: translateY(-50%);
`

const StyledTitle = styled(Typography.Title)`
  font-size: 14px !important;
  margin-bottom: 0px !important;
  font-weight: ${({isVisibled}) => isVisibled ? '700' : '400'} !important;
`


const Divider = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    width: 3px;
    height: 3px;
    background: black;
    border-radius: 50%;

  }
`

export default function CardTopic({
                                      title,
                                      permission,
                                      hasPin,
                                      lastMessage,
                                      totalMessages,
                                      totalUnreadMessage, ...props
                                  }) {
    const {t} = useTranslation()

    let isVisibled = !(totalUnreadMessage === 0);

    function handleMessagePlural(value) {
        if (value > 1) return t('messages')
        return t('message')
    }


    return (

        <Container
            isVisibled={isVisibled} {...props}>
            <Row>
                <Col xs={20} md={22}>
                    <Row gutter={[16, 16]}>
                        {hasPin && <Col flex={'0'}>
                            <PushpinOutlined/>
                        </Col>}
                        <Col flex={'auto'}>
                            <StyledTitle
                                isVisibled={isVisibled}
                                level={5}>{title}</StyledTitle>
                        </Col>
                        <Col xs={24}>
                            <InfoContainer>
                                <Space align={'center'} size={8} wrap>


                                    <MessagesIcon/>

                                    <span>{totalMessages} {handleMessagePlural(totalMessages)}</span>


                                    {!!lastMessage && (
                                        <>
                                            <Divider/>
                                            <Space size={8}
                                                   wrap><span>{t('Last message')}:</span>
                                                <span>{DatetimeFormatByTimezone(lastMessage.created_at)} {t('by')}</span></Space>
                                            <Space size={8}>
                                                <UserAvatar
                                                    size={16}
                                                    data={
                                                        {
                                                            picture: getOrDefault(lastMessage.owner, 'picture', RobotImage),
                                                            name: getOrDefault(lastMessage.owner, 'first_name', t('System Message'))
                                                        }
                                                    }/>

                                                <span>  {getOrDefault(lastMessage.owner, 'first_name', t('System Message'))} {getOrDefault(lastMessage.owner, 'last_name')}  </span>
                                            </Space>

                                        </>
                                    )}
                                    <Divider/>
                                    <PermissionTagsList
                                        permissions={{[permission]: true}}/>


                                </Space>
                            </InfoContainer>
                        </Col>

                    </Row>
                </Col>
                {totalUnreadMessage > 0 && <Col xs={4} md={2}>
                    <Badge>
                        <Space size={8}><RemixIcon
                            remixIconName={'ri-message-2-line'}/>
                            <span>{totalUnreadMessage}</span></Space>
                    </Badge>
                </Col>}


            </Row>

        </Container>
    )
}