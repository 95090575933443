import React, {useEffect, useRef, useState} from 'react';
import BottomDrawer from "../../../../components/layout/BottomDrawer";
import {useTranslation} from "react-i18next";
import api from "../../../../services/api";
import {BuildFieldErrorsDict} from "../../../../utils/Utils";
import {Flex, Typography} from "antd";
import UserAvatar from "../../../../components/misc/UserAvatar";
import Loading from "../../../../components/feedback/Loading";
import InfiniteScroll from "react-infinite-scroller";

const {Text} = Typography

function ReactionsDrawer({topic, setTopic}) {

    const {t} = useTranslation()

    const [loading, setLoading] = useState(false)
    const [usersReactions, setUsersReactions] = useState([])

    const initialUrl = `/topics/${topic}/users_reactions?page=1&page_size=10`
    const [nextUrl, setNextUrl] = useState(initialUrl)

    const reactionsContainerRef = useRef()

    const fetchUsersReactions = async () => {
        try {
            setLoading(true)
            let keepUsersReactions = [...usersReactions]
            const {data} = await api.get(nextUrl)
            setUsersReactions([...keepUsersReactions, ...data?.results])
            setNextUrl(data?.next)
        } catch (e) {
            BuildFieldErrorsDict(e, false, false)
        } finally {
            setLoading(false)
        }
    }

    const handleFetchUsersReactions = () => {
        if (topic && !loading) {
            void fetchUsersReactions()
        }
    }

    useEffect(() => {
        setNextUrl(initialUrl)
    }, [topic]);


    return (
        <BottomDrawer
            title={t("Reactions")}
            open={!!topic}
            setOpen={setTopic}
            onClose={() => setUsersReactions([])}
            height={448}
        >
            <div ref={reactionsContainerRef}>
                <InfiniteScroll
                    hasMore={!!nextUrl}
                    loadMore={handleFetchUsersReactions}
                    getScrollParent={() => reactionsContainerRef.current}
                    useWindow={false}
                    loader={<Loading style={{height: 100}}/>}
                >
                    <Flex vertical={true} gap={8}>
                        {
                            usersReactions?.map(({user, permissions, is_member}) => (
                                <Flex gap={4}>
                                    <UserAvatar size={"large"} data={user}/>
                                    <Flex vertical={true} justify={"center"}>
                                        <Text style={{fontSize: 12, fontWeight: 500}}>
                                            {user.full_name}
                                        </Text>
                                        <Flex gap={2}>
                                            {permissions?.map((permission, index) => (
                                                <Flex gap={2}>
                                                    <Text type="secondary" style={{fontSize: 10}}>
                                                        {`${t(permission)}`}
                                                    </Text>
                                                    {index < (permissions.length - 1) &&
                                                        <Text style={{fontSize: 10}} type="secondary">|</Text>}
                                                </Flex>
                                            ))}
                                            {is_member &&
                                                <Flex gap={2}>
                                                    {(permissions.length > 0) && <Text style={{fontSize: 10}} type="secondary">|</Text>}
                                                    <Text strong={true} style={{fontSize: 10}}>{t("Author")}</Text>
                                                </Flex>
                                            }
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))
                        }
                    </Flex>
                </InfiniteScroll>
            </div>
        </BottomDrawer>
    );
}

export default ReactionsDrawer;