import React from 'react';
import IndicatorCheckpointCardFactory
    from "../../ProjectManagement/components/IndicatorCheckpoint/IndicatorCheckpointCardFactory";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {formatNumber} from "../../../utils/utils";

const IndicatorPreviewContainer = styled.div`
    pointer-events: none;
`

function PreviewIndicatorCard({indicatorType, indicatorName}) {
    const {t} = useTranslation();
    const getIndicatorConfig = ({name, type, value, max, prefix=t("Value"), inverse=false}) => {
        return (
            {
                "stored_value": value,
                "value_display": type === "currency" ? formatNumber(value) : value,
                "scaled_value": value,
                "is_scalable": true,
                "indicator": {
                    "indicator_view": {
                        "name": type,
                        "field_prefix": prefix,
                        "type": "integer"
                    },
                    "name": name,
                    "indicator_type": "integer",
                    "inverse_importancy": inverse,
                    "levels_colorized": (["rating", "percent"].includes(type)),
                    "scale_min": 0,
                    "scale_max": max || value 
                },
                "previous_values": [
                    {
                        "status_report": "2",
                        "stored_value": (value * 0.3),
                        "value_display": 3,
                        "scaled_value": 3
                    },
                    {
                        "status_report": "3",
                        "stored_value": (value * 0.5),
                        "value_display": 5,
                        "scaled_value": 5
                    },
                    {
                        "status_report": "4",
                        "stored_value": value,
                        "value_display": 10,
                        "scaled_value": 10
                    }
                ],
                "is_editable": false,
            }
        )
    }

    const previewChoices = {
        rating: getIndicatorConfig({name: indicatorName, type: "rating", value: 10, prefix: t("Level")}),
        rating_inverse: getIndicatorConfig({name: indicatorName, type: "rating", value: 10, prefix: t("Level"), inverse: true}),
        currency: getIndicatorConfig({name: indicatorName, type: "currency", value: 100000, prefix: "$"}),
        number: getIndicatorConfig({name: indicatorName, type: "number", value: 100, prefix: t("Amount")}),
        percent: getIndicatorConfig({name: indicatorName, type: "percent", value: 20, max: 100, prefix: "%"}),
    }

    let selectedPreview = previewChoices[indicatorType]

    return (
        <IndicatorPreviewContainer>
            {selectedPreview &&
                <IndicatorCheckpointCardFactory indicatorResponse={selectedPreview}/>
            }
        </IndicatorPreviewContainer>
    );
}

export default PreviewIndicatorCard;