import SolvCollapse
    from "../../../../../../global/components/misc/SolvCollapse";
import {useTranslation} from "react-i18next";
import {Col, Form, Input, Row, Switch, Typography} from "antd";
import WideSpace from "../../../../../../global/components/layout/WideSpace";
import React, {useEffect} from "react";

import SwitchFormField
    from "../../../../../../global/components/data_entry/form/SwitchFormField";
import InputFormField
    from "../../../../../../global/components/data_entry/form/InputFormField";
import TransparentCollapse from "../../../../../../global/components/misc/TransparentCollapse";
import CollapseFormItem from "../../../../../../global/components/data_entry/CollapseFormItem";
import {useRules} from "../../../../../../global/hooks/useRules";


const {Text, Title} = Typography;

export default function MetricsAndTrackingCollapse({
                                                       form,
                                                       FORM_NAMES,
                                                       program
                                                   }) {
    const {t} = useTranslation();
    const {IS_REQUIRED} = useRules()

    // const hasFacebookPixelIdValue = Form.useWatch(FORM_NAMES.HAS_FACEBOOK_PIXEL_ID, form);
    // const facebookPixelIdValue = Form.useWatch(FORM_NAMES.FACEBOOK_PIXEL_ID, form);
    // const hasGoogleAnalyticsIdValue = Form.useWatch(FORM_NAMES.HAS_GOOGLE_ANALYTICS_ID, form);
    // const hasGoogleTagManagerIdValue = Form.useWatch(FORM_NAMES.HAS_GOOGLE_TAG_MANAGER_ID, form);

    return (
        <TransparentCollapse header={t("Metrics")}>
            <CollapseFormItem
                              name={FORM_NAMES.HAS_FACEBOOK_PIXEL_ID}
                              label={t('Integrate Facebook Pixel')}
                              help={t("Allows you to use your Facebook pixel to collect traffic data.")}
                              extraFormItemProps={{valuePropName:"checked"}}
                              input={<Switch />}
                              renderContent={(value) => (
                                  <CollapseFormItem name={FORM_NAMES.FACEBOOK_PIXEL_ID}
                                    rules={value ? [IS_REQUIRED] : []}
                                    label={t("Facebook pixel id")}
                                    input={<Input disabled={!value} data-cy="facebook-pixel-id-input"/>}
                                  />
                              )}
            />

        </TransparentCollapse>
    );
}
        // <SolvCollapse title={<> {t("Tracking")}</>} bordered active={true}>
        //     <WideSpace direction="vertical" size="large">
        //         <Row gutter={[16, 16]}>
        //
        //             <Col xs={24} md={12}>
        //                 <SwitchFormField
        //                     form={{
        //                         help: t("Allows you to use your Facebook pixel to collect traffic data."),
        //                         name: FORM_NAMES.HAS_FACEBOOK_PIXEL_ID,
        //                         label: t('Integrate Facebook Pixel'),
        //                     }}
        //                 />
        //             </Col>
        //             <Col xs={24} md={12}>
        //                 <InputFormField
        //
        //                     disabled={!hasFacebookPixelIdValue}
        //                     form={{
        //                         rules:
        //                             [{
        //                                 required: hasFacebookPixelIdValue,
        //                                 message: t("This field is required")
        //                             }],
        //                         name: FORM_NAMES.FACEBOOK_PIXEL_ID,
        //                         label: t("Facebook pixel id"),
        //                     }}
        //                     data-cy="facebook-pixel-id-input"
        //                 />
        //             </Col>
        //
        //
        //             {/*<Col xs={24} md={12}>*/}
        //             {/*    <SwitchFormField*/}
        //             {/*        form={{*/}
        //             {/*            help: t("Allows you to use your Google Analytics to collect traffic data."),*/}
        //             {/*            name: FORM_NAMES.HAS_GOOGLE_ANALYTICS_ID,*/}
        //             {/*            label: t('Integrate google analytics'),*/}
        //             {/*        }}*/}
        //             {/*    />*/}
        //             {/*</Col>*/}
        //             {/*<Col xs={24} md={12}>*/}
        //             {/*    <InputFormField*/}
        //             {/*        disabled={!hasGoogleAnalyticsIdValue}*/}
        //
        //             {/*        form={{*/}
        //             {/*            rules:*/}
        //             {/*                [{*/}
        //             {/*                    required: hasGoogleAnalyticsIdValue,*/}
        //             {/*                    message: t("This field is required")*/}
        //             {/*                }],*/}
        //             {/*            name: FORM_NAMES.GOOGLE_ANALYTICS_ID,*/}
        //             {/*            label: t("Google analytics id"),*/}
        //             {/*        }}*/}
        //             {/*    />*/}
        //             {/*</Col>*/}
        //
        //             {/*<Col xs={24} md={12}>*/}
        //             {/*    <SwitchFormField*/}
        //             {/*        form={{*/}
        //             {/*            help: t("Allows you to use your Google Tag Manager to collect traffic data."),*/}
        //             {/*            name: FORM_NAMES.HAS_GOOGLE_TAG_MANAGER_ID,*/}
        //             {/*            label: t('Integrate google tag manager'),*/}
        //             {/*        }}*/}
        //             {/*    />*/}
        //             {/*</Col>*/}
        //             {/*<Col xs={24} md={12}>*/}
        //             {/*    <InputFormField*/}
        //
        //             {/*        disabled={!hasGoogleTagManagerIdValue}*/}
        //             {/*        form={{*/}
        //             {/*            rules:*/}
        //             {/*                [{*/}
        //             {/*                    required: hasGoogleTagManagerIdValue,*/}
        //             {/*                    message: t("This field is required")*/}
        //             {/*                }],*/}
        //             {/*            name: FORM_NAMES.GOOGLE_TAG_MANAGER_ID,*/}
        //             {/*            label: t("Google tag manager id"),*/}
        //             {/*        }}*/}
        //             {/*    />*/}
        //             {/*</Col>*/}
        //         </Row>
        //
        //     </WideSpace>
        // </SolvCollapse>
