import React from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {useProgramContext} from "../../../../ProgramsNavigator";
import useDefaultGetRequest
    from "../../../../../global/hooks/useDefaultGetRequest";
import Loading from "../../../../../global/components/feedback/Loading";
import {RANKING_STATUS} from "../../../../../global/utils/Constants";
import {Col, Empty, Row} from "antd";
import RankingCard
    from "../../../../../global/components/data_display/RankingCard";
import CollapseFormItem
    from "../../../../../global/components/data_entry/CollapseFormItem";
import AddNewRankingButton
    from "../../../../../global/pages/gamification/pages/ranking/components/AddNewRankingButton";


const Title = styled.h2`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
`
export default function SectionOpenRankingProgram({}) {
    const {t} = useTranslation();
    const {program} = useProgramContext()
    const {
        data: rankings,
        isLoading
    } = useDefaultGetRequest({
        url: `/ranking/program/${program?.id}/get_ranking_object_related/`,
        makeRequest: !!program.id,
        params: {
            status: RANKING_STATUS.OPENED,
            // environment_rank: false
        },
        dataDefault: []
    })


    return (

        <>

            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Title
                        level={6}>{t('Current ranking')}</Title>
                </Col>
                {isLoading ? (
                    <Col xs={24}><Loading height={'100px'}/></Col>
                ) : rankings?.results?.length > 0 ? rankings?.results?.map((ranking) =>
                    <Col xs={24} key={ranking.id}><RankingCard
                        ranking={ranking}/></Col>
                ) : <Col xs={24}><Empty
                    description={t('There are no active rankings at the moment.')}/></Col>}
                <Col xs={24}>
                    <CollapseFormItem label={t('Create new ranking')}
                                      help={t('You can only have one ranking at a time. You cannot create a ranking whose date conflicts with another ranking.')}
                                      input={<AddNewRankingButton
                                          bodyRequest={{
                                              model_name: 'program',
                                              object_id: program.id
                                          }}/>}/>
                </Col>

            </Row>


        </>

    );
}
