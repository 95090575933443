import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Col, Empty, Flex, Row, Space, Typography} from "antd";
import styled from "styled-components";
import NewWindow from "react-new-window";
import {PrinterOutlined, FieldTimeOutlined} from "@ant-design/icons"
import Search from "../../data_entry/Search";
import Button from "../../data_entry/Button";
import ShortInformation from "../../data_display/ShortInformation";
import ChatComment from "../../data_display/ChatComment";
import {DatetimeFormatByTimezone} from "../../../utils/dates";
import moment from "moment-timezone";
import {FlexContainer} from "../../authentication/StyledComponents";
import InfiniteScroll from "react-infinite-scroller";
import {useLayoutContext} from "../../../../modules/project/components/layout/context/LayoutContext";
import Loading from "../../feedback/Loading";


const ContainerHeader = styled.div`
  width: 100%;
`


export default function BodyMessageChat({
                                            isLoading,
                                            is_admin_or_manager,
                                            search,
                                            topic,
                                            value,
                                            comments,
                                            open,
                                            handleFetchMessages,
                                            nextUrl,
                                            ...props
                                        }) {
    const {t} = useTranslation()
    const refContainerMessages = useRef();
    const [showPdf, setShowPdf] = useState(false);
    const [lastDateFetch, setLastDateFetch] = useState(moment());

    useEffect(() => {
        if (comments?.length > 0) {
            setLastDateFetch(moment())
            refContainerMessages.current.parentElement.scrollTo(0, refContainerMessages.current.scrollHeight)
        }
    }, [comments])

    return (
        <>
            <Row span={24} gutter={[8,8]}>
                <Col flex={'1'}>
                    <Search placeholder={t("Search")} value={search?.value}
                            loading={isLoading}
                            onChange={({target: {value}}) => search?.onChange?.(value)}
                            onSearch={search?.onSearch}/>
                </Col>
                {topic?.is_admin_or_manager && comments?.length > 0 &&
                    <Col>
                        <Button type="default" icon={<PrinterOutlined/>} onClick={() => setShowPdf(true)} />
                    </Col>}
                {/* <Col xs={24}>
                    <ShortInformation>
                        {t("Internal topic available for environment collaborators.")}
                    </ShortInformation>
                </Col> */}

            </Row>

            <div ref={refContainerMessages} style={{paddingTop: 16}}>
                    <InfiniteScroll
                        hasMore={!!nextUrl}
                        loadMore={handleFetchMessages}
                        isReverse={false}
                        getScrollParent={() => refContainerMessages.current}
                        useWindow={false}
                        loader={<Loading style={{height: 100}}/>}

                    >
                        <Flex vertical={true} gap={8}>
                            {
                                comments?.length > 0 ? (comments.map((comment) => (
                                        <ChatComment {...comment}/>
                                    ))
                                ) : <Empty/>
                            }
                        </Flex>
                    </InfiniteScroll>
                <Row gutter={[8,8]}>
                    <Col xs={24}>
                        <FlexContainer justify={'center'}>
                            <Space style={{margin: '0 auto', paddingTop:16}}>
                                <FieldTimeOutlined/>
                                <span>{t('Last message update at')}</span>
                                <span>{DatetimeFormatByTimezone(lastDateFetch)}</span>
                            </Space>
                        </FlexContainer>
                    </Col>
                </Row>
            </div>
            {showPdf && (
                <NewWindow center="screen" copyStyles={true}
                           onUnload={() => setShowPdf(false)} onOpen={(w) => {
                    setTimeout(() => {
                        w.print()
                    }, 2000);
                }}>
                    <div style={{background: '#FAFAFA', padding: 10}}>
                        <Row gutter={[8, 8]}>
                         <Typography.Title>{t('Messages')}</Typography.Title>

                            {comments.map((comment) => (
                                <Col xs={24} key={comment.id} >
                                    <ChatComment {...comment}/>
                                </Col>))
                            }
                        </Row>
                    </div>
                </NewWindow>
            )}
        </>

    );
}

