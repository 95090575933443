import React from "react";
import styled from "styled-components";
import DragIcon from "../../../../assets/Icon/drag.svg";
import {useTranslation} from "react-i18next";

const StyleDragImg = styled.img`
  width: 22px;
  margin-right: 2px;
  margin-left: -16px
`
const StyledIcon = styled.img`
  width: 25px;
  padding-right: 8px;
`
const StyleDragName = styled.span`
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`

function DraggableCard({name, icon}) {
    const {t} = useTranslation();

    return (
        <div style={{display: 'flex'}}>
            <StyleDragImg src={DragIcon} alt={'drag-icon'}/>
            <StyledIcon src={icon} alt={'dragebble-item-icon'}/>
            <StyleDragName title={name}> {t(name)} </StyleDragName>
        </div>
    )
}

export default DraggableCard