import SolvTag from "./SolvTag";
import {
    blue_1,
} from "../../utils/Colors";

const APPLICATION_STATUS = {
    "reproved": "#FFF1F0",
    "given_up": "#FFF1F0",
    "approved": "#F6FFED",
    "classified": "#F6FFED",
    "waiting_evaluation": "#F0F5FF",
    "in_filling": "#F9F0FF",
    "grouped": "#FFF7E6",
    "submitted": "#FAFAFA"
    // "discontinued": "#FFF7E6", TODO: Legado
    // "implemented": "#FFF7E6"
}


export default function ApplicationTagStatus({application, ...props}) {

    function getApplicationStatusColor(application) {
        if (!application || application?.is_archived) {
            return '#fff';
        }
        return APPLICATION_STATUS[application.status];
    }


    const statusColor = getApplicationStatusColor(application);
    return <SolvTag maxWidth={150} color={statusColor} style={{textTransform: 'capitalize'}} {...props}>{application?.status_display}</SolvTag>
}
