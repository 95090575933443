import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {
    FlexContainer
} from "../../../../components/authentication/StyledComponents";
import {neutral_5, neutral_8} from "../../../../utils/Colors";
import Drawer from "../../../../components/layout/Drawer";
import {
    Col,
    Row,
    Radio,
    Badge,
    Typography,
    Space,
    Form as FormAnt
} from "antd";
import Button from "../../../../components/data_entry/Button";
import {FilterOutlined, InfoCircleOutlined} from "@ant-design/icons";
import RequestSelect from "../../../../components/data_entry/RequestSelect";
import {useInnovationPanelContext} from "../context/InnovationPanelContext";
import FilterButton from "../../../../components/button/FilterButton";
import FilterTags from "../../../../components/other/FilterTags";
import {APPLICATION_TAG, PROJECT_TAG} from "../../../../utils/Choices";
import Form from "../../../../components/data_entry/form/Form";

const Alert = styled.div`
    padding: 16px;
    border-radius: 8px;
    border: 1px solid ${neutral_5};

`

export default function ButtonFilterProjectsChart() {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false)
    const [form] = FormAnt.useForm()
    const {
        setProjectsFilters,
        projectsFilters,
        PROJECT_FILTER_FORM_NAMES,
        updateProjectsFilters
    } = useInnovationPanelContext()
    useEffect(() => {
        if (open) {
            form.setFieldsValue({
                [PROJECT_FILTER_FORM_NAMES.TAGS_ISNULL]: projectsFilters[PROJECT_FILTER_FORM_NAMES.TAGS_ISNULL],
                [PROJECT_FILTER_FORM_NAMES.TAGS__ID__IN]: projectsFilters[PROJECT_FILTER_FORM_NAMES.TAGS__ID__IN],
                [PROJECT_FILTER_FORM_NAMES.PROJECTS__ID__IN]: projectsFilters[PROJECT_FILTER_FORM_NAMES.PROJECTS__ID__IN]
            });
        }
    }, [open]);

    function onClose() {
        setOpen(false)
    }

    function handleSubmit(data) {
        onClose()

          if (data[PROJECT_FILTER_FORM_NAMES.TAGS_ISNULL] || data[PROJECT_FILTER_FORM_NAMES.TAGS__ID__IN]?.length === 0  || !data[PROJECT_FILTER_FORM_NAMES.TAGS__ID__IN]) {

            delete data[PROJECT_FILTER_FORM_NAMES.TAGS__ID__IN]

        }
        if(!data[PROJECT_FILTER_FORM_NAMES.TAGS_ISNULL]){
            delete data[PROJECT_FILTER_FORM_NAMES.TAGS_ISNULL]
        }

        updateProjectsFilters(data[PROJECT_FILTER_FORM_NAMES.PROJECTS__ID__IN])
        if (data[PROJECT_FILTER_FORM_NAMES.PROJECTS__ID__IN]?.length === 0 || !data[PROJECT_FILTER_FORM_NAMES.PROJECTS__ID__IN]) {
            delete data[PROJECT_FILTER_FORM_NAMES.PROJECTS__ID__IN]
        }
        setTimeout(() => setProjectsFilters(data), 100)


    }

    function handleResetFilters() {
        form.resetFields()
    }


    return (
        <>
            <FilterButton hasText
                filters={projectsFilters}
                onClick={() => setOpen(true)}/>
            <Drawer open={open} onClose={onClose}
                    title={t('Project filter')}
                    destroyOnClose
                    footer={<FlexContainer justify={'end'} gap={10}>
                        <Button
                            type={'default'}
                            onClick={handleResetFilters}>{t('Reset filters')}</Button>
                        <Button onClick={form.submit}>{t('Save')}</Button>
                    </FlexContainer>}
                    width={520}>
                <Form  form={form}
                    // disabled={isLoading}
                      onFinish={handleSubmit}>
                    <Row gutter={[8, 8]}>
                        <Col xs={24}>
                            <Alert>

                                <Typography.Title level={5}>
                                    <Space size={10}>

                                        <InfoCircleOutlined/>
                                        {t('About the project filter')}
                                    </Space>

                                </Typography.Title>
                                <span>
                                {t('Select the projects you want to compare in the innovation dashboard. We recommend selecting a maximum of 5 projects to avoid compromising the clarity of the data.')}
                            </span>
                            </Alert>
                        </Col>
                        <Col xs={24}>

                             <FormAnt.Item name={PROJECT_FILTER_FORM_NAMES.PROJECTS__ID__IN}>
                                <RequestSelect allowClear
                                    // onChange={_setPrograms}
                                    // value={_programs}
                                               mode={'multiple'}
                                               labelField={'name'}
                                               url={'analytics/dashboard/manager_dashboard/get_projects'}/>
                            </FormAnt.Item>

                        </Col>
                         <Col xs={24}>
                            <FilterTags filterName={PROJECT_FILTER_FORM_NAMES.TAGS__ID__IN}
                                        noTagName={PROJECT_FILTER_FORM_NAMES.TAGS_ISNULL}
                                        targets={[PROJECT_TAG]}/>
                        </Col>
                    </Row>
                </Form>
            </Drawer>

        </>


    )
}

