import styled from 'styled-components';
import {Grid, Layout, Menu as MenuAnt} from "antd";
import {useLayoutContext} from "../../../modules/project/components/layout/context/LayoutContext";

import React, {useEffect, useState} from "react";
import MenuBody from "./MenuBody";
import MenuHeader from "./MenuHeader";
import MenuFooter from "./MenuFooter";
import {useUserContext} from "../../context/UserContext";
import {usePermissionContext} from "../../context/PermissionContext";
import {useThemeContext} from "../../context/ThemeContext";
import {useLocation} from 'react-router-dom';

const {Sider, Content} = Layout;

const StyledSider = styled(Sider)`
    position: relative;
    background: var(--background_menu);
    
    .ant-layout-sider-children {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }
    
    border-inline-end: none !important;

    ${({screens}) => {
        if (!screens.md) {
            return `
              position: absolute; 
              height: 100vh;
              z-index: 999;
          `
        }
    }}

`;

const OPENED_SIDE_BAR_WIDTH = 225;
const COLLAPSED_SIDE_BAR_WIDTH = 64;

export default function Menu() {
    const {collapsed} = useLayoutContext();
    const screens = Grid.useBreakpoint();
    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState([]);
    const location = useLocation();
    const {initializeLayout} = useLayoutContext();

    const {user_client,} = useUserContext();
    const {
        hasFullAdminPermission,
    } = usePermissionContext();
    const {themeConfig: {client}} = useThemeContext()
    
    const show_assing_button = user_client && user_client.is_active && hasFullAdminPermission && client?.billing_plan == 1
    
    useEffect(() => {
        initializeLayout({});
    }, []);

    useEffect(() => {
        setDefaultSelectedKeys([location.pathname.split("/")[1]])
    }, [location]);

    return (

        <StyledSider
            collapsible
            trigger={null}
            screens={screens}
            collapsed={collapsed}
            collapsedWidth={screens.md ? COLLAPSED_SIDE_BAR_WIDTH : 0}
            width={!screens.md && screens.md !== undefined ? "100%" : OPENED_SIDE_BAR_WIDTH}
        >
            <MenuHeader/>
            <div style={{flexGrow: 1, overflowX: "hidden"}}>
                <MenuBody defaultSelectedKeys={defaultSelectedKeys}/>
            </div>
            <MenuFooter show_assing_button={show_assing_button} defaultSelectedKeys={defaultSelectedKeys} />
        </StyledSider>

    );
}
