import React, {useEffect, useState} from 'react';
import {Input, Row, Space, Typography} from 'antd';
import {assessmentStatusTagColor} from '../../../../../global/utils/Colors';
import {useTranslation} from 'react-i18next';
import Table from '../../../../../global/components/data_display/Table';
import SolvTag from '../../../../../global/components/tag/SolvTag';
import DisplayAssessements from './components/DisplayAssessements';
import {useParams} from "react-router-dom";
import api from "../../../../../global/services/api";
import useTable from "../../../../../global/hooks/useTable";
import {CardContainer} from '../../../../../global/components/misc/CardContainer';
import Button from '../../../../../global/components/data_entry/Button';
import {DownloadOutlined} from '@ant-design/icons';
import {buildURI} from 'react-csv/lib/core';
import TruncateText from "../../../../../global/components/responsive/TruncateText";
import {renderProfile, renderYesNo} from "../../../../../global/components/tables/utils";
import {usePhasesContext} from "../../../../PhasesNavigator";
import {ToastNotification} from '../../../../../global/components/feedback/ToastNotification';
import {ERROR, SUCCESS} from '../../../../../global/utils/fileWithMessage';
import {FlexContainer} from "../../../../../global/components/authentication/StyledComponents";
import UserAvatar from '../../../../../global/components/misc/UserAvatar';

const {Title, Link} = Typography

function CSVExportElement({children, getData, filename, separator = ";"}) {
    const [isLoading, setIsLoading] = useState();
    const {t} = useTranslation();

    const handleOnClick = async () => {
        try {
            if (!isLoading) {
                setIsLoading(true);
                const result = await getData();
                const link = document.createElement("a");
                link.download = filename;
                link.href = buildURI(result.data, filename, result.headers, separator);
                link.click()
                link.remove();
                ToastNotification(t(SUCCESS));
            }
        } catch (error) {
            ToastNotification(t(ERROR));
        } finally {
            setIsLoading(false);
        }
    }
    return React.cloneElement(children, {loading: isLoading, onClick: handleOnClick});
}

export default function PageAssessmentsListPhase() {
    const {t} = useTranslation();
    const {phase_id} = useParams();
    const [additionalColumns, setAdditionalColumns] = useState();
    const {phase} = usePhasesContext();
    const [search, setSearch] = useState("")

    const {
        tableData, isLoading, tableParams, handleTableChange,
    } = useTable({
        url: `v2/evaluator_assessments/phase/${phase_id}/phase_assessments`,
        hasPagination: true,
        search
    })

    const renderAdditionalColumns = (value, record, type) => {
        switch (type) {
            case "feedback":
                return <div style={{maxWidth: "300px"}}><TruncateText>{value}</TruncateText></div>
            case "yes_no":
                return renderYesNo(value, t)
            default:
                return <span>{value}</span>
        }
    }

    const handleFetchFormVisibleQuestions = async () => {
        try {
            const response = await api.get(`v2/evaluator_assessments/phase/${phase_id}/visible_form_questions`);
            setAdditionalColumns(response.data.map((item) => ({
                title: item.label,
                dataIndex: item.name,
                type: item.type,
                render: (value, record) => renderAdditionalColumns(value, record, item.type)
            })))
        } catch (error) {
        }
    }

    useEffect(() => {
        !additionalColumns && handleFetchFormVisibleQuestions()
    }, [additionalColumns]);

    const columns = [
        {
            title: t('Code'),
            dataIndex: 'application_code',
            sorter: 'application__code',
        },
        {
            title: t('Evaluator'),
            dataIndex: 'evaluator_name',
        },
        {
            title: t("Author"),
            dataIndex: "team",
            render: (value) => <Space size={4}><UserAvatar data={value} /> {value.name}</Space>,
        },
        {
            title: t('Application Title'),
            dataIndex: 'application_name',
            ellipsis: true,
        },
        {
            title: t('Status'),
            dataIndex: 'status',
            ellipsis: true,
            render: (value, record) => {
                if (!value) return '-';
                const statusColor = assessmentStatusTagColor[value];
                return <SolvTag color={statusColor}>{t(record.status_display)}</SolvTag>
            }
        },
        {
            title: t('Score'),
            dataIndex: 'score',
            width: '50px',
        },
        {
            title: t('Performed'),
            dataIndex: 'performed',
            // sorter: 'all_evaluations',
            key: 'performed',
            width: '10px',
        },
    ]
    if (additionalColumns) {
        columns.push(
            ...additionalColumns
        )
    }

    const getCSVData = async () => {
        const swapKey = {status: "status_display"}
        const headers = columns.map((col) => ({label: col.title, key: swapKey[col.dataIndex] || col.dataIndex}));
        const columnTypes = additionalColumns?.map((col) => ([col.dataIndex, col.type]));
        const config = {
            params: {
                export: true
            }
        }
        const response = await api.get(`v2/evaluator_assessments/phase/${phase_id}/phase_assessments`, config);
        const data = response.data.map((row) => {
            columnTypes?.forEach((record) => {
                const [column, type] = record;
                if (type === "yes_no") {
                    const value = row[column];
                    row[column] = value === "true" ? t("Yes") : t("No");
                }
            })
            return row;
        });

        return {headers, data};
    }

    return (
        <>
            <DisplayAssessements/>
            <Title level={5}>{t("Evaluations carried out in this phase")}</Title>
            <CardContainer>
                <Row justify='end' style={{padding: "16px 0"}}>
                    <FlexContainer gap={8}>
                        <Input.Search onSearch={(value) => setSearch(value)} allowClear/>
                        {tableData &&
                            <CSVExportElement
                                getData={getCSVData}
                                filename={`${t("Exportation")} - ${phase.program_name} - ${phase.title}.csv`}
                            >
                                <Button type='default' icon={<DownloadOutlined/>}>{t('Export')}</Button>
                            </CSVExportElement>}
                    </FlexContainer>
                </Row>
                <Table
                    dataSource={tableData}
                    columns={columns}
                    onChange={handleTableChange}
                    tableParams={tableParams}
                    loading={isLoading}
                />
            </CardContainer>
        </>
    );
}