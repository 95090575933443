import React from 'react';
import {InfoCircleOutlined} from "@ant-design/icons";
import {Typography} from "antd";
import {useTranslation} from "react-i18next";

const {Text} = Typography

export default function FormLabelHelp({label, setHelpDrawerOpen}) {
    const {t} = useTranslation()
    const onCLick = (e) => {
        e.preventDefault()
        setHelpDrawerOpen(label)
    }

    return (
        <Text>{`${t(label)} `} {setHelpDrawerOpen && <InfoCircleOutlined style={{color:"#00000073"}} onClick={onCLick}/>}</Text>
    )
}

