import ReCAPTCHA from "react-google-recaptcha";
import {GOOGLE_CAPTCHA_SITE_ID} from "../../../settings";
import React from "react";
import styled from 'styled-components'

const ReCaptchaButtonStyle = styled(ReCAPTCHA)`
  width: 100%;
  display: flex;
  justify-content: center;
`

const ReCaptchaButton = ({setCaptchaValue}) => {

    function onChangeCaptcha(value) {
        setCaptchaValue(value);
    }

    return (
        <ReCaptchaButtonStyle
            sitekey={GOOGLE_CAPTCHA_SITE_ID}
            onChange={onChangeCaptcha}
            onExpired={() => {
                onChangeCaptcha(false)
            }}
        />
    )
}

export default ReCaptchaButton