import React from "react";
import NotContent from "../../NotContent/NotContent";
import Modal from "react-bootstrap/Modal";
import Loading from "../../../global/components/feedback/Loading";
import styled from 'styled-components'
import HeaderFormInfo from "../../HeaderFormInfo";
import api from "../../../global/services/api";
import {Col, Row} from "react-bootstrap";
import Label from "../../Form/Label";
import {BuildFieldErrorsDict} from "../../../global/utils/Utils";
import {useTranslation} from "react-i18next";

const MainContainer = styled.div`
  display: flex;
`
const AssementsContainer = styled.div`
  width: 30%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 0px 5px -1px,
  rgba(0, 0, 0, 0.3) 0px 0px 3px -1px;
  margin: 8px;
  min-height: 100%;
  border-radius: 4px;
  padding: 8px;
`

const InfoContainer = styled.div`
  height: 500px;
  overflow: auto !important;
  border-radius: 4px;
  width: 100%;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 0px 5px -1px,
  rgba(0, 0, 0, 0.3) 0px 0px 3px -1px;
  margin: 8px;
  padding: 8px;
`

const Title = styled.h2`
  text-align: center;
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
`

const AssementsCard = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid #DDD;
  background-color: ${({selected}) => (selected ? '#d5d4d4' : '#fff')};

  padding: 6px 8px;
  height: 80px;
  transition: 0.5s;
  cursor: pointer;
  margin-bottom: 4px;
  &:hover {
    border-color: #969595;
  }
`

const SubTitle = styled.h3`
  color: #4C4C4C;
  margin-bottom: 8px;
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`

const Text = styled.div`
  font-size: 12px;
  color: #646464;
  align-self: center;
`

const SubLabel = styled.span`
  font-weight: 500;
`

const AssementsCurrentContainer = styled.div`
  overflow: hidden;
`

export default function ModalAllocationsEvaluator({
                                       evaluatorInfo,
                                       showEvaluatorModal,
                                       setShowEvaluatorModal
                                   }) {

    const {t} = useTranslation()
    const [isLoading, setIsloading] = React.useState(false)
    const [currentAssesment, setCurrentAssesment] = React.useState()

    function closeModal() {
        setShowEvaluatorModal(false)
        setCurrentAssesment(null)
    }

    async function fetchAssesment(id) {
        try {
            setIsloading(true)
            const response = await api.get(`assessments/${id}`,{'version':'v2'});
            if (response.status === 200) {
                setCurrentAssesment(response.data)
            }

        } catch (error) {

            BuildFieldErrorsDict(error, null, false);
        } finally {
            setIsloading(false);
        }
    }


    const showInput = (input, index) => {


        let key = currentAssesment.id + input.name + index
        if (input.type === 'score_with_feedback') {

            const elementFeedback = input.show_feedback ?
                <div style={{marginBottom: 16, marginTop: 10}}>
                    <Label required={input.required} >Feedback</Label>
                    <p>{input[`feedback_${input.name}`]? input[`feedback_${input.name}`]: t('No answered yet')}</p>
                </div> : ''


            return (
                <div key={key}>
                    <Label required={true} >{input.label}</Label>
                    <p>{input.score? input.score: t('No answered yet')}</p>
                    {elementFeedback}
                </div>
            )
        }

    }


    return (

        <Modal size="lg" show={showEvaluatorModal}
               onHide={closeModal} animation={false}>
            <Modal.Header closeButton>
                {evaluatorInfo !== null && (
                    <h1 className={'modal-title-custom'}>{evaluatorInfo.evaluator.full_name}</h1>
                )}

            </Modal.Header>
            <Modal.Body>
                {evaluatorInfo === null ? <Loading/> : (
                    <MainContainer>
                        <AssementsContainer>
                            <Title>{t("Applications")}</Title>
                            {evaluatorInfo.assessments.length === 0 ? (
                                <NotContent title={t("No assesment yet")}/>
                            ) : (
                                evaluatorInfo.assessments.map((assessment) => (
                                    <AssementsCard
                                        selected={currentAssesment && assessment.id === currentAssesment.id}
                                        onClick={() => fetchAssesment(assessment.id)}>
                                        <SubTitle title={assessment.team_name}>{assessment.team_name}</SubTitle>
                                        <Text><SubLabel>{t('Score:')}</SubLabel> {assessment.score}/100</Text>
                                        <Text><SubLabel>{t('Status:')}</SubLabel> {t(assessment.status_display)}
                                        </Text>

                                    </AssementsCard>
                                ))
                            )}

                        </AssementsContainer>
                        <InfoContainer>
                            {evaluatorInfo.assessments.length === 0 ?
                                <NotContent title={t("No assesment yet")}/>
                                : isLoading ? (<Loading/>) : (
                                    <AssementsCurrentContainer>
                                        {currentAssesment ? (
                                            <>
                                                <HeaderFormInfo
                                                    profile={currentAssesment.application.team?.picture}
                                                    banner={currentAssesment.application.team.banner}>

                                                    <Row>
                                                        <Col xs={6}>
                                                            <SubTitle>{currentAssesment.application.team.name}</SubTitle>
                                                        </Col>
                                                        <Col xs={5}>
                                                            <Text><SubLabel>{t('Status:')}</SubLabel> {t(currentAssesment.status_display)}
                                                            </Text>

                                                        </Col>
                                                        <Col xs={4}>
                                                            <Text><SubLabel>{t('Score:')}</SubLabel> {currentAssesment.score} of
                                                                100 </Text>

                                                        </Col>
                                                    </Row>
                                                    {currentAssesment.application.challenge && (
                                                        <Row>
                                                            <Col>
                                                                <Text><SubLabel>{t('Challenge')}:</SubLabel> {currentAssesment.application.challenge.name}
                                                                </Text>

                                                            </Col>
                                                        </Row>


                                                    )}


                                                </HeaderFormInfo>
                                                {currentAssesment.form_evaluate_with_response ? currentAssesment.form_evaluate_with_response.map((input, index) =>
                                                    <div
                                                        key={'inputcontent-' + index}>{showInput(input, index)}</div>) : t('NOT FORM')}
                                            </>
                                        ) : (
                                            <NotContent
                                                title={t("No assesment selected")}/>
                                        )}

                                    </AssementsCurrentContainer>
                                )
                            }
                        </InfoContainer>
                    </MainContainer>
                )}
            </Modal.Body>
        </Modal>

    )
}