import {
    Col,
    Row,
    Typography
} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import Button from "../../../../components/data_entry/Button";
import {
    FlexContainer
} from "../../../../components/authentication/StyledComponents";

import TagList from "./components/TagList";
import {PlusOutlined} from "@ant-design/icons";
import Search from "../../../../components/data_entry/Search";
import DynamicDropdown
    from "../../../../components/data_entry/DynamicDropdown";
import useSystemBreakpoint from "../../../../hooks/useSystemBreakpoint";
import RemixIcon from "../../../../components/data_display/RemixIcon";
import useDefaultGetRequest from "../../../../hooks/useDefaultGetRequest";
import Empty from "../../../../components/data_display/Empty";
import Loading from "../../../../components/feedback/Loading";
import AddUpdateNewTagDrawer from "./components/AddUpdateNewTagDrawer";
import Pagination from "../../../../components/navigation/Pagination";
import useUpdateLocalRequest from "../../../../hooks/useUpdateLocalRequest";


// todo atualizar contadores depois de desrelacionar o objeto a tag
// todo order da listagem



const ORDER_OPTIONS = {
    DATE_CREATED:'-pk',
    MORE_OBJECTS: '-objects_counter_total',
    ALPHABETICAL_ORDER:'name',
}

export default function TagsPage({}) {
    const {t} = useTranslation();
    const [order, setOrder] = useState(ORDER_OPTIONS.DATE_CREATED)
    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState()
    const [isLoading, setIsLoading] = useState()
    const [tags, setTags] = useState([])
    const {isPhone} = useSystemBreakpoint()
    const {makeRequest, updateLocalContent} = useUpdateLocalRequest();
    // const {
    //     data: tags,
    //     isLoading: isLoading
    // } = useDefaultGetRequest({
    //     url: `api/tags`,
    //     hasPagination: true,
    //     params: {
    //         main_tag: true, search: search,
    //         order: order//todo falta validar,
    //     },
    //     dependence: [search]
    // })

    const PaginationRequest = {
        url: `api/tags`,
        setIsLoading: setIsLoading,
        setData: setTags,
        // makeRequest: open,
        extraParams: {
            main_tag: true, search_with_children: search,
            ordering: order
        },
        // makeRequest: !!user_client_authenticated?.id,
        dependence: [search, order, makeRequest]
    }

    function handleOrder({key}) {
        setOrder(key)
    }

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Typography.Title level={5} style={{marginBottom: 0}}>
                        {t('Your groupings and tags')}
                    </Typography.Title>
                </Col>
                <Col xs={24}>
                    <FlexContainer justify={'end'} gap={16}>
                        <Search width={300} onSearch={(value) => {
                            setIsLoading(true)//gambiarra não toque
                            setSearch(value)
                        }}/>
                        <DynamicDropdown icon={<RemixIcon
                            remixIconName={'ri-arrow-up-down-line'}/>} items={[
                            {label: t("Date created"), key: ORDER_OPTIONS.DATE_CREATED},
                            {label: t("More objects"), key: ORDER_OPTIONS.MORE_OBJECTS},
                            {label: t("Alphabetical order"), key: ORDER_OPTIONS.ALPHABETICAL_ORDER},
                        ]}
                                         selectedKey={order}
                                         handleChange={handleOrder}/>
                        <Button icon={<PlusOutlined/>}
                                onClick={() => setOpen(true)}>
                            {!isPhone && t('Create new tag')}
                        </Button>
                    </FlexContainer>
                </Col>
                <Col xs={24}>
                    {isLoading ? <Loading/> : tags.length > 0 ? (
                        tags.map((tag) => <TagList externalLoading={isLoading}
                                                   updateListLocalContent={updateLocalContent}
                                                   search={search}
                                                   style={{marginBottom: 16}}
                                                   key={tag.id} tag={tag}/>)
                    ) : (<Empty white
                                description={t('You haven\'t created any tags. To create a tag, click the \'Create a Tag\' button above!')}/>)}


                </Col>

                <Col xs={24}>
                    <FlexContainer justify={'end'}
                                   style={{background: 'white', padding: 16}}>
                        <Pagination request={PaginationRequest}

                            // showTotal={(total) => t('total_ranking_users', {total: total})}
                                    size="small"
                            // hideOnSinglePage
                        />
                    </FlexContainer>
                </Col>


            </Row>
            <AddUpdateNewTagDrawer setOpen={setOpen} open={open} isMainTag/>
        </>
    );
}