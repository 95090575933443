import React from 'react';
import {Modal, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {isURL} from "../../utils/Utils";
import {SESSION_COOKIE_DOMAIN} from "../../../settings";
import { transformLink } from '../../../modules/project/utils/utils';

const {confirm} = Modal;
const {Link} = Typography

export default function LinkRedirectConfirm({link, text, defaultTarget = "_blank", style={fontSize:16}}) {
    const {t} = useTranslation()
    link =  transformLink(link);
    
    const handleOnClickEvent = async (event) => {
        event.preventDefault()
        if (!isURL(link)) {
            return;
        }
        
        const url = new URL(link);
        if (url.hostname.slice(url.hostname.indexOf(".")) === SESSION_COOKIE_DOMAIN) {
            return window.open(link, "_blank")
        }

        confirm({
            title: t("External link to the SOLV platform."),
            content: t("This link takes you beyond the Solv platform. We cannot guarantee the security of pages or files that do not belong to the platform. Do you wish to proceed?"),
            onOk: () => {
                return window.open(link, "_blank");
            }
        })
    }

    return (
        <Link href={link} target={defaultTarget} style={style} onClick={handleOnClickEvent}>{text || link}</Link>
    );
}
