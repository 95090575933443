import React, {useEffect, useRef} from "react";
import "./styles.css";
import styled from "styled-components";

export const SIMPLE_EDITOR_OPTIONS = {
    toolbar: {
        items: [
            'bold', 'italic', 'strikethrough', 'underline', '|', 'indent', 'outdent', '|',
            'bulletedList', 'numberedList', 'todoList', 'link',
        ],
        shouldNotGroupWhenFull: false,
    },
    horizontalLine: "<br>",
    removePlugins: [
        'CKBox',
        'CKFinder',
        // 'EasyImage',
        // 'Base64UploadAdapter',
        'RealTimeCollaborativeComments',
        'RealTimeCollaborativeTrackChanges',
        'RealTimeCollaborativeRevisionHistory',
        'PresenceList',
        'Comments',
        'TrackChanges',
        'TrackChangesData',
        'RevisionHistory',
        'Pagination',
        'WProofreader',
        'MathType',
        'SlashCommand',
        'Template',
        'DocumentOutline',
        'FormatPainter',
        'TableOfContents',
    ],
    mention: {
        feeds: [
            {
                marker: '@',
                feed: [
                    '@apple', '@bears', '@brownie', '@cake', '@cake', '@candy', '@canes', '@chocolate', '@cookie', '@cotton', '@cream',
                    '@cupcake', '@danish', '@donut', '@dragée', '@fruitcake', '@gingerbread', '@gummi', '@ice', '@jelly-o',
                    '@liquorice', '@macaroon', '@marzipan', '@oat', '@pie', '@plum', '@pudding', '@sesame', '@snaps', '@soufflé',
                    '@sugar', '@sweet', '@topping', '@wafer',
                ],
                minimumCharacters: 1,
            },
        ],
    },
    resize_enabled: true,
};

const StyledContainer = styled.div`
  flex-grow: ${props => props.flex ? 1 : "unset"};

  .ck-editor__editable {
    min-height: ${props => props.minHeight || props.height || "inherit"};
    max-height: ${props => props.maxHeight || props.height || "inherit"};
  }
`

function CkEditor({
                value = "",
                toolbar,
                list,
                heading,
                placeholder,
                fontFamily,
                fontSize,
                htmlSupport,
                htmlEmbed,
                link,
                mention,
                removePlugins,
                height,
                maxHeight,
                minHeight,
                disabled,
                onChange,
                flex = false,
    }) {
    const container = useRef();
    const editor = useRef();
    const options = {
        toolbar,
        list,
        heading,
        placeholder,
        fontFamily,
        fontSize,
        htmlSupport,
        htmlEmbed,
        link,
        mention,
        removePlugins
    };
    
    useEffect(() => {
        if (!editor.current) {
            CKEDITOR.ClassicEditor.create(container.current, options).then((newEditor) => {
                newEditor.setData(value || ''); // Atualizar o valor quando houver initialValue no Form.Item
                newEditor.model.document.on("change", () => {
                    onChange && onChange(newEditor.getData());
                });
                editor.current = newEditor;
            });
        }
    }, []);
    
    useEffect(() => {
        if (editor.current) {
            if (disabled) {
                editor.current.enableReadOnlyMode("feature-id");
            } else {
                editor.current.disableReadOnlyMode("feature-id");
            }
        }
    }, [disabled, editor.current]);
    
    // Não remover o editor.current. Aparentemente só dispara uma vez,
    // mas como o valor permanece atualizando, o useEffect consegue atualizar o componente
    // useEffect(()=>{
    //     if ((value === "" || value) && editor.current && !hasInitial) {
    //         editor.current.setData(value || '');
    //         editor.current.model.change( writer => {writer.setSelection( editor.current.model.document.getRoot(), 'end' );} );
    //         console.log("changed text");
    //         setHasInitial(true);
    //     }
    // }, [value, editor.current]);
        
    return (
        <StyledContainer 
            height={height} 
            maxHeight={maxHeight}
            minHeight={minHeight} 
            flex={flex}
        >
            <div ref={container} />
        </StyledContainer>);
}

export default CkEditor;