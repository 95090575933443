import React, {useEffect, useRef, useState} from "react";
import IconRemove from "../../../../assets/IconsFormComponents/trash.svg";
import IconCog from "../../../../assets/IconsFormComponents/cog.svg";
import ChoiceCircle
    from "../../../../assets/IconsFormComponents/choice_circle.svg";
import ChoiceSquare
    from "../../../../assets/IconsFormComponents/choice_square.svg";
import {
    ButtonsContent, Checkbox,
    ChoicesContent,
    ComponentMainRow,
    Content,
    ContentNoPointerEvents,
    Description,
    HeaderInput,
    InputContent,
    LabelTextAreaInput, MaxLenghtInput,
    OpenOptionsButton,
    OptionInput,
    OptionsContent,
    ParagraphInput,
    RemoveButton,
    RemoveChoiceButton,
    RenderedContent,
} from "../StyledComponents";
import Label from "../../../Form/Label";
import DescriptionInput from "../../../Form/Description";
import styled from "styled-components";
import DragIcon from "../../../../assets/Icon/drag.svg";
import {useTranslation} from "react-i18next";
import IconHeader from "../../../../assets/IconsFormComponents/new_header.svg";
import IconParagraph
    from "../../../../assets/IconsFormComponents/new_paragraph.svg";
import IconTextInput
    from "../../../../assets/IconsFormComponents/text_input.svg";
import IconTextArea
    from "../../../../assets/IconsFormComponents/text_area.svg";
import IconEmail from "../../../../assets/IconsFormComponents/email.svg";
import IconLink from "../../../../assets/IconsFormComponents/link.svg";
import IconNumber from "../../../../assets/IconsFormComponents/number.svg";
import IconDate from "../../../../assets/IconsFormComponents/date.svg";
import IconSelect from "../../../../assets/IconsFormComponents/new_select.svg";
import IconMultiSelect
    from "../../../../assets/IconsFormComponents/new_multi_select.svg";
import IconCheckbox
    from "../../../../assets/IconsFormComponents/new_checkbox.svg";
import IconRadioButton
    from "../../../../assets/IconsFormComponents/radio_button.svg";
import IconImage from "../../../../assets/IconsFormComponents/image.svg";
import IconDocument from "../../../../assets/IconsFormComponents/document.svg";
import IconScore from "../../../../assets/IconsFormComponents/score.svg";
import SectionIcon
    from "../../../../assets/IconsFormComponents/section_icon.svg";

import IconToggle
    from "../../../../assets/IconsFormComponents/toggle_line.svg";
import IconFeedback from "../../../../assets/newicons/SendFeedback.svg";
import CkEditor, {
    SIMPLE_EDITOR_OPTIONS
} from "../../../../modules/project/components/CKEditor";
import {
    FlexContainer
} from "../../../../global/components/authentication/StyledComponents";
import Select from "../../../../global/components/data_entry/Select";
import {generateSelectOptions} from "../../../../modules/project/utils/utils";
import { COMPONENT_MAX_LENGTH_DEFAULT } from "./Factory";

const NameContent = styled.div`
  visibility: ${props => props.hide ? "hidden" : 'visible'};
  background: #eeedef;
  font-size: 10px;
  border-radius: 5px;
`

const ComponentNameLabel = styled.label`
  font-size: 9px;
  margin-right: 2px;
`
const ComponentIcon = styled.img`
  width: 12px;
  margin-right: 2px;
  margin-left: 2px;
`

const StyleDragImg = styled.img`
  width: 22px;
  margin-right: 2px;
  transform: rotate(90deg);
`

const StyleDragImgDiv = styled.div`
  width: 100%;
  display: flex;
  //justify-content: center;
  //margin-bottom: -15px;
  margin-top: -2px;
  justify-content: space-between;
`

export const EditableIcon = styled.img`
  position: ${props => props.fixed ? "absolute" : 'inherit'};
  width: 10px;
  margin-top: 6px;
  margin-left: 3px;
  transform: scaleX(-1);

`
export const SpanTitle = styled.span`
    display: flex;
    position: relative;
    width: 100%;
    background-color: transparent;

    &:after {
        content: attr(title);
        z-index: 999;
        position: absolute;
        padding: 8px 5px 0px 5px;
        top: 5px;
        //right: 10%;
        font-size: 15px;
        font-weight: 400;
        opacity: 0.4;
    }
`

export function getIcon(type_question) {
    switch (type_question) {
        case 'header':
            return IconHeader
        case 'paragraph':
            return IconParagraph
        case 'text_input':
            return IconTextInput
        case 'text_area':
            return IconTextArea
        case 'email':
            return IconEmail
        case 'link':
            return IconLink
        case 'number':
            return IconNumber
        case 'date':
            return IconDate
        case 'select':
            return IconSelect
        case 'multiple_select':
            return IconMultiSelect
        case 'checkbox':
            return IconCheckbox
        case 'radio':
            return IconRadioButton
        case 'image':
            return IconImage
        case 'document':
            return IconDocument
        case 'score_with_feedback':
            return IconScore
        case 'yes_no':
            return IconToggle
        case 'feedback':
            return IconFeedback
        case 'section':
            return SectionIcon
        default:
            return IconHeader

    }
}

export function getComponentName(type_question) {
    switch (type_question) {
        case 'header':
            return 'Big Title'
        case 'paragraph':
            return 'Small Title'
        case 'text_input':
            return 'Short answer'
        case 'text_area':
            return 'Long answer'
        case 'email':
            return 'Email'
        case 'link':
            return 'Link'
        case 'number':
            return 'Number'
        case 'date':
            return 'Date'
        case 'select':
            return 'Single choice list'
        case 'multiple_select':
            return 'Multiple choice list'
        case 'checkbox':
            return 'Multiple choice checkbox'
        case 'radio':
            return 'Single choice checkbox'
        case 'image':
            return 'Image upload'
        case 'document':
            return 'Document upload'
        case 'score_with_feedback':
            return 'Rating Input'
        case 'question':
            return 'Question'
        case 'feedback':
            return 'Feedback'
        case 'section':
            return 'Section'
        case 'yes_no':
            return 'Yes No Selection'
        default:
            return 'Not Found Name'

    }
}

export function ComponentNameContent({type, hide}) {
    const {t} = useTranslation();
    return (
        <NameContent hide={hide}>
            <ComponentIcon src={getIcon(type)}/>
            <ComponentNameLabel
                data-cy="component-name-label">{t(getComponentName(type))}</ComponentNameLabel>
        </NameContent>
    )
}

function DefaultComponent(props) {
    const newOptionInput = useRef(null);
    const firstOptionInput = useRef(null);
    const settingsButton = useRef(null);
    const labelInputRef = useRef(null);
    const [newOption, setNewOption] = useState('');
    const [openOptions, setOpenOptions] = useState(true);
    // const choices = props.choices
    const [choicesOptions, setChoicesOptions] = useState(props.choices);

    const {t} = useTranslation();

    const resizeLabelInput = () => {
        labelInputRef.current.setAttribute('style', `height:auto`)
        labelInputRef.current.setAttribute('style', `height:${labelInputRef.current.scrollHeight}px`)
    }
    useEffect(() => {
        resizeLabelInput()
    }, [])


    const resizeTextAreaInput = (e) => {
        e.target.setAttribute('style', `height:auto`)
        e.target.setAttribute('style', `height:${e.target.scrollHeight}px`)
    }

    const resizeInput = (e) => {
        // e.target.setAttribute('style', `width:auto`)
        // e.target.setAttribute('style', `width:${e.target.scrollWidth < 900 ? e.target.scrollWidth : 900}px`)
    }


    useEffect(() => {
        resizeLabelInput()
    }, [openOptions])

    const handleNewOptionFocus = () => {
        newOptionInput.current.focus();
    }
    const disableEnter = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    }
    const handleEditLabel = () => {
        setOpenOptions(true)
        setTimeout(() => {
            labelInputRef.current.focus();
        }, 100)
    }
    const removeChoice = (index) => {
        const newChoices = [...choicesOptions]
        newChoices.splice(index, 1)
        setChoicesOptions([...newChoices])
        props.onchange(props.index, 'choices', [...newChoices])
    }

    const addNewOption = () => {
        if (newOption) {
            const newChoice = [...choicesOptions, {"value": newOption}]
            // if(newChoice)
            setChoicesOptions(newChoice)
            props.onchange(props.index, 'choices', newChoice)
            setNewOption('')
            handleNewOptionFocus()
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            addNewOption()
        }
    }

    const handleEnterOnUpdate = (event) => {
        if (event.key === 'Enter') {
            handleNewOptionFocus()
        }
    }

    const renderLabelOption = (number) => {
        switch (props.type) {
            case 'select':
                return <label>{number}.</label>
            case 'multiple_select':
                return <label>{number}.</label>
            case 'checkbox':
                return <img src={ChoiceSquare} alt={'Square Option'}/>
            case 'radio':
                return <img src={ChoiceCircle} alt={'Circle Option'}/>
            case 'section':
                return <img src={ChoiceCircle} alt={'Circle Option'}/>
            default:
                <></>
        }

    }
    const handleInputFocus = (event) => {
        event.target.select()
        setOpenOptions(true)
    }
    const handleClickRendered = (event) => {
        setOpenOptions(true)
        firstOptionInput.current && setTimeout(() => {
            firstOptionInput.current.focus();
        }, 100);
    }

    const changeOptionValue = (choiceIndex, newValue) => {
        let items = [...choicesOptions];
        let item = {...items[choiceIndex]};
        item.value = newValue;
        items[choiceIndex] = item;
        setChoicesOptions([...items])
        props.onchange(props.index, 'choices', [...items])
    }
    const vinculateSection = (section_id, position) => {

        let items = [...choicesOptions];
        let item = {...items[position]};
        item.section = section_id;
        items[position] = item;
        setChoicesOptions([...items])

        props.onchange(props.index, 'choices', [...items])
    }


    function generateSectionOptions() {
        //gera as opções dos selects da seção//
        return props.phaseForm.sections.sections.filter((currentSection) => {
            const isParentSections = props.parent_section?.id === currentSection.id
            const currentSectionIsLinked = props.phaseForm.sections.linked_sections.some((item) => item.linked_question !== props.name && currentSection.id === item.section_id)
            const parentSectionIsLinkedInAnotherSection = props.phaseForm.sections.linked_sections.some((item) =>  props.parent_section?.id === item.section_id && currentSection.id === item.parent_section_id)

            return !(isParentSections) && !currentSectionIsLinked && !parentSectionIsLinkedInAnotherSection
        }).map((item) => ({label: item.name, value: item.id,}))
    }


    const renderChoices = () => {
        return (choicesOptions && <ChoicesContent>
                {choicesOptions.length > 0 && choicesOptions.map((option, index) =>
                    <div style={{width: '100%'}}
                         key={props.index + 'c' + index}>
                        <RemoveChoiceButton
                            style={choicesOptions.length === 1 ? {'visibility': 'hidden'} : {}}
                            onClick={() => {
                                removeChoice(index)
                            }}><img src={IconRemove}
                                    alt={'Remove Component'}/></RemoveChoiceButton>
                        {renderLabelOption(index + 1)}
                        <OptionInput
                            ref={index === 0 ? firstOptionInput : null}
                            placeholder={t("new option?")}
                            value={option.value} onFocus={handleInputFocus}
                            onKeyPress={handleEnterOnUpdate}
                            onInput={resizeInput}
                            onChange={(e) => changeOptionValue(index, e.target.value)}
                            data-cy="option-input"/>

                          {props.type === 'section' && <Select  value={option.section} onChange={(value) => vinculateSection(value,index)} allowClear style={{width: 220, marginLeft: 8, marginTop: 4}}
                                                             options={generateSectionOptions()} />}


                    </div>
                )}
                <div>
                    <RemoveChoiceButton style={{'visibility': 'hidden'}}><img
                        src={IconRemove}
                        alt={'Remove Component'}/></RemoveChoiceButton>
                    {renderLabelOption(choicesOptions.length + 1)}
                    <OptionInput placeholder={t("Insert text here")}
                                 value={newOption}
                                 onBlur={() => addNewOption()}
                                 onKeyPress={handleKeyPress}
                                 ref={newOptionInput}
                                 onChange={(e) => {

                                     setNewOption(e.target.value)
                                 }}
                                 newOption={true}
                                 data-cy="option-input"
                    />
                </div>
            </ChoicesContent>
        )
    }
    const validate_max_lenght = (e, maxSize) => {
        
        if (e.target.value > 0 && e.target.value <= maxSize) {
            props.onchange(props.index, 'max_length', parseInt(e.target.value))
        } else {
            props.onchange(props.index, 'max_length', 0)
        }
    }
    const renderOptions = () => {
        return (
            <OptionsContent>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    // width: '100%'
                }}>
                    {["text_area", "text_input"].includes(props.type) && <>{t("Max. Length")}
                        <MaxLenghtInput type={"number"} 
                                        min={0}
                                        max={COMPONENT_MAX_LENGTH_DEFAULT[props.type]}
                                        defaultValue={props.max_length || COMPONENT_MAX_LENGTH_DEFAULT[props.type]}
                                        onChange={(e) => validate_max_lenght(e, COMPONENT_MAX_LENGTH_DEFAULT[props.type])}/></>}
                    <Checkbox
                        disabled={props.type === 'section' }
                        type={"checkbox"}
                        onChange={(e) => props.onchange(props.index, 'required', e.target.checked)}
                        defaultChecked={props.required}/>{t("Required")}
                </div>
            </OptionsContent>
        )
    }

    const renderHeader = () => {
        return (<>
            {/*<EditableIcon src={IconEdit} fixed={false} alt={t('Editable')}/>*/}
            <HeaderInput ref={labelInputRef} type={'text'}
                         defaultValue={props.label} autoComplete={'off'}
                         onFocus={handleInputFocus}
                         onInput={resizeLabelInput} rows={1}
                         onKeyPress={disableEnter}
                         onChange={(e) => props.onchange(props.index, 'label', e.target.value)}
                         data-cy="header-input"/></>)
    }

    const renderParagraph = () => {
        return (<>
                {/*<EditableIcon src={IconEdit} fixed={false} alt={t('Editable')}/>*/}
                <ParagraphInput ref={labelInputRef} type={'text'}
                                defaultValue={props.label} autoComplete={'off'}
                                rows={1}
                                onKeyPress={disableEnter}
                                onChange={(e) => props.onchange(props.index, 'label', e.target.value)}
                                onInput={resizeLabelInput}
                                onFocus={handleInputFocus}
                                data-cy="paragraph-input"/></>
        )
    }
    const renderTitleComponent = () => {
        return (<>{props.header && renderHeader()}{props.paragraph && renderParagraph()}</>)
    }

    const renderDescriptionComponent = () => {
        // TODO - Input de descrição antigo
        let component = (
            <Description
                placeholder={t("Description")}
                rows={1}
                autoComplete={'off'}
                onInput={resizeTextAreaInput}
                onChange={(e) => props.onchange(props.index, 'help_text', e.target.value)}
                defaultValue={props?.help_text}
                data-cy="description-input"
            />
        )
        if (props.header) {
            component = (
                <CkEditor
                    value={props?.help_text}
                    onChange={(data) => props.onchange(props.index, 'help_text', data)}
                    flex={true}
                    maxHeight="300px"
                    {...SIMPLE_EDITOR_OPTIONS}
                    data-cy="description-input"
                />
            )
        }
        return component;
    }


    return (
        <ComponentMainRow data-cy="draggable-card">
            <StyleDragImgDiv>
                <ComponentNameContent type={props.type} hide={true}/>
                <StyleDragImg src={DragIcon}/>
                <ComponentNameContent type={props.type}/>
            </StyleDragImgDiv>
            <Content style={{width: '100%'}}>
                {(props.header || props.paragraph) ? renderTitleComponent() :
                    <InputContent>
                        {/*<EditableIcon src={IconEdit} alt={t('Editable')}/>*/}
                        <Label hidden={openOptions} required={props.required}
                               onClick={handleEditLabel}>{props.label}</Label>
                        <LabelTextAreaInput ref={labelInputRef}
                                            placeholder={t("Write down your question statement.")}
                                            hidden={!openOptions} type={'text'}
                                            defaultValue={props.label}
                                            onInput={resizeLabelInput}
                                            autoComplete={'off'} rows={1}
                                            onFocus={handleInputFocus}
                                            onKeyPress={disableEnter}
                                            onChange={(e) => props.onchange(props.index, 'label', e.target.value)}
                                            data-cy="paragraph-input"
                        />
                    </InputContent>}
                <ButtonsContent>
                    <OpenOptionsButton style={{'visibility': 'hidden'}}
                                       onClick={() => setOpenOptions(!openOptions)}
                                       ref={settingsButton}
                                       alt={'Open Component'}><img
                        src={IconCog}
                        alt={'Open Component'}/></OpenOptionsButton>
                    <RemoveButton type={"button"}
                                  onClick={(event) => props.remove(event, props.index)}
                                  data-cy="remove-question-button">
                        <img src={IconRemove} alt={'Remove Component'}/>
                    </RemoveButton>
                </ButtonsContent>
            </Content>
            <RenderedContent show={openOptions} onClick={handleClickRendered}>
                <ContentNoPointerEvents>
                    {props.help_text && <DescriptionInput
                        style={{marginBottom: 10}}>{props.help_text}</DescriptionInput>}
                    <div style={{width: '100%'}}>
                        {props.children}
                    </div>
                </ContentNoPointerEvents>
            </RenderedContent>
            <RenderedContent show={!openOptions}
                             style={{flexWrap: 'wrap', cursor: "initial"}}>
                <Content>
                    {/*<EditableIcon src={IconEdit} alt={'editable'}/>*/}
                    {/* {renderDescriptionComponent()} */}
                    {/* TODO - Input de descrição novo */}
                    <CkEditor
                        index={props.index}
                        placeholder={t("Describe the content in detail, including important and relevant information")}
                        value={props?.help_text}
                        onChange={(data) => props.onchange(props.index, 'help_text', data)}
                        flex={true}
                        maxHeight="300px"
                        {...SIMPLE_EDITOR_OPTIONS}
                        data-cy="description-input"
                    />
                </Content>
                {props.showChildren && props.children}
                <RenderedContent>
                    {renderChoices()}
                </RenderedContent>
                <RenderedContent>
                    {!(props.header || props.paragraph) && renderOptions()}
                </RenderedContent>
            </RenderedContent>
        </ComponentMainRow>

    )
}

export default DefaultComponent