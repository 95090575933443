import React, {useEffect, useState} from 'react';
import {Col, Row} from "react-bootstrap";
import {getProgram} from "../../../../global/utils/_query";
import {useHistory, useParams, useRouteMatch} from "react-router-dom";
import {LongCardContainer} from "../../../../components/GenericStyles";
import Subtitle from "../../../../components/Title/Subtitle";
import styled from 'styled-components'
import ButtonDefault from "../../../../components/Buttons/ButtonDefault";
import NewInteraction from "./NewInteraction/NewInteraction";
import api from "../../../../global/services/api";
import NotContent from "../../../../components/NotContent/NotContent";
import {DatetimeFormatByTimezone} from "../../../../global/utils/dates";
import {BuildFieldErrorsDict} from "../../../../global/utils/Utils";
import {useTranslation} from "react-i18next";

const CustomLongCardContainer = styled(LongCardContainer)`
  justify-content: space-between;
  margin-bottom: 0;
  margin-top: 10px;

`

const ListCardContainer = styled.div`
  border: 2px solid #F8F8F8;
  margin-bottom: 10px;
  padding: 3px;
  border-radius: 8px;
`

const LeftContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const RightContainer = styled.div`
  min-height: 60px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

`

const CardBadgeWarning = styled.div`
  width: auto;
  background-color: #fffce8;
  padding: 3px;
  color: #CCB400;
  border-radius: 6px;
  text-align: center;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  font-weight: bold;
`
const CardBadgeError = styled(CardBadgeWarning)`
  background-color: #FEF2EC;
  color: #F44336;
`
const CardBadgeSucess = styled(CardBadgeWarning)`
  background-color: rgb(222, 247, 228);
  color: rgb(40, 167, 69);;
`

const StyledInfo = styled.div`
  color: #646464;
  font-weight: normal;
  font-style: normal;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 0;
`

const StyledInfoTitle = styled.span`
  font-weight: 600;
  font-size: 12px;
`

function InteractionProgram() {
    const [program, setProgram] = useState({public: true})
    const {t} = useTranslation()
    let {id} = useParams()
    const history = useHistory()
    const {url} = useRouteMatch();

    useEffect(() => {
        getProgram(id, null, setProgram)
        getProgramInteractions()
    }, [id]);

    const [modalShow, setModalShow] = React.useState(false);
    const [manualInteractions, setManualInteractions] = useState([])
    const [interaction, setInteraction] = useState({})


    const getProgramInteractions = async () => {
        try {

            const response = await api.get(`interaction/program/${id}`);
            setManualInteractions(response.data.manual_interactions)

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        }
    }

    useEffect(() => {
        if (interaction.id) {
            setModalShow(true)
        }
    }, [interaction]);

    const createManualInteraction = (interaction) => {
        setManualInteractions([...manualInteractions, interaction])
    }

    const updateManualInteraction = (interaction) => {
        let aux = manualInteractions.map((item) => {
            if (interaction.id === item.id) {
                return interaction
            } else {
                return item
            }
        })
        setManualInteractions(aux)
    }

    const handleCardManualInteractionEvent = (event, interaction) => {
        let handlecardevent = event.target.getAttribute("data-handlecardevent")
        if (handlecardevent !== 'false') {
            // if (interaction.status === 'created') {
            //     setInteraction(interaction)
            // } else {
            //     alert('Criar component para interaction')
            // }
            // setInteraction(interaction)
            history.push(`${url}/${interaction.id}`)
        }
    }

    const getStatusBadge = (interaction) => {
        let status = interaction.status
        switch (status) {
            case 'created':
                return ''
            case 'sending':
                return <CardBadgeWarning>
                    {t('Sending')}
                </CardBadgeWarning>
            case 'completed':
                return <CardBadgeSucess>
                    {t('Completed')}
                </CardBadgeSucess>
            case 'error':
                return <CardBadgeError>
                    {t('Error')}
                </CardBadgeError>

            default:
                return ''
        }

    }
    return (
        <>
            <NewInteraction interaction={interaction}
                            show={modalShow}
                            onHide={() => {
                                setModalShow(false);
                                setInteraction({});
                            }}
                            createManualInteraction={createManualInteraction}
                            updateManualInteraction={updateManualInteraction}
            />
            <>
                <div className="py-2 text-end">
                    <ButtonDefault
                        style={{
                            margin: 0,
                            // visibility: 'hidden'
                        }}
                        onClick={() => {
                            history.push(`interaction/create_interaction`);
                        }}
                        action={'primary create'}
                        label={t('Create interaction')}
                        data-cy="create-interaction-button"/>
                </div>
                <ListCardContainer>
                    <Row>
                        {manualInteractions.length === 0 &&
                            <NotContent
                                title={t("Don't have manual interactions yet.")}/>}
                        {manualInteractions.map((manual_interaction) =>
                            <Col sm={12}
                                 key={'manual-iteracion' + manual_interaction.id}>
                                <CustomLongCardContainer
                                    onClick={(event) => handleCardManualInteractionEvent(event, manual_interaction)}>
                                    <Col sm={8}>
                                        <LeftContainer>
                                            <Subtitle>{manual_interaction.name}</Subtitle>
                                            <Row>
                                                <Col>
                                                    <StyledInfo>
                                                        <StyledInfoTitle>{t('Date created:')} </StyledInfoTitle> {DatetimeFormatByTimezone(manual_interaction.date_create)}
                                                    </StyledInfo>
                                                </Col>
                                                {(manual_interaction.date_update && manual_interaction.status !== 'created') &&
                                                    <Col>
                                                        <StyledInfo>
                                                            <StyledInfoTitle>{t('Date Sent:')} </StyledInfoTitle> {DatetimeFormatByTimezone(manual_interaction.date_update)}
                                                        </StyledInfo>
                                                    </Col>
                                                }
                                            </Row>
                                        </LeftContainer>
                                    </Col>
                                    <Col sm={4}>
                                        <RightContainer>
                                            <CardBadgeWarning>Manual</CardBadgeWarning>

                                            {getStatusBadge(manual_interaction)}
                                        </RightContainer>
                                    </Col>
                                </CustomLongCardContainer>
                            </Col>,
                        )}
                    </Row>
                </ListCardContainer>

            </>
        </>
    );
}

export default InteractionProgram;