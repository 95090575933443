import IndicatorCheckpointCard, {ValueComponent} from "./IndicatorCheckpointCard";
import {Slider} from "antd";
import {useStatusReportContext} from "../../context/StatusReportContext";
import IndicatorHistory from "./IndicatorHistory";
import {useThemeContext} from "../../../../../../global/context/ThemeContext";
import styled from "styled-components";
import {getScaledValueIfScaledIndicator} from "../../../../utils/utils";
import { useTranslation } from "react-i18next";

const SliderStyler = styled.div`
  .ant-slider-disabled {
    opacity: 0.4;
  }
  
  .ant-slider-disabled .ant-slider-track {
    background-color: ${({color})=>color} !important;
  }
  .ant-slider-disabled .ant-slider-handle {
    display: none;
  }
  
`;


function IndicatorRatingCheckpointCard({indicatorResponse, ...props}) {
    // Scaled value should be treated as range picker normal value (it will be converted to percent once it arrives to backend);
    const { t } = useTranslation();
    const {indicator} = indicatorResponse;
    const {updateIndicatorResponse} = useStatusReportContext();
    const {getLevelColor} = useThemeContext();

    const handleValueChange = (value) => {
        updateIndicatorResponse({id: indicatorResponse["id"], values: {"scaled_value": value, "value_display": value}});
    };

    const scaledValue = getScaledValueIfScaledIndicator({value: indicatorResponse["scaled_value"], indicatorResponse, indicator});
    const color = getLevelColor({
        value: scaledValue,
        levelsColorized: indicator["levels_colorized"],
        inverseImportancy: indicator["inverse_importancy"],
    });

    const sliderStyle = {
        backgroundColor: color,
        borderColor: color,
    };

    return <>
        <IndicatorCheckpointCard indicatorResponse={indicatorResponse} {...props}>
            <ValueComponent
                value={indicatorResponse["value_display"]}
                prefix={t(indicator["indicator_view"]["field_prefix"])}
                color={color}
            />
            <SliderStyler color={color} data-cy={`indicator-slider-${indicatorResponse.indicator.internal_code}`}>
                <Slider
                    disabled={indicatorResponse["has_formulas"]}
                    value={indicatorResponse["scaled_value"]}
                    onChange={handleValueChange}
                    trackStyle={sliderStyle}
                    handleStyle={sliderStyle}
                    min={indicator["scale_min"]}
                    max={indicator["scale_max"]}

                />
            </SliderStyler>
            <IndicatorHistory indicatorResponse={indicatorResponse} yField="scaled_value"/>
        </IndicatorCheckpointCard>
    </>;
}

export default IndicatorRatingCheckpointCard;