import {Button, Form, Input, Popover, Select, Space, Tag} from "antd";
import {useEffect, useMemo, useState} from "react";
import PreventPropagationWrapper from "../utils/PreventPropagationWrapper";
import {CloseOutlined, EditOutlined} from "@ant-design/icons";
import InputColorPicker from "./InputColorPicker";

import api from "../../../../global/services/api";
import { useTranslation } from "react-i18next";
import SolvTag from "../../../../global/components/tag/SolvTag";

// TODO - analisar uma melhor forma de fazer isso.

function TagCustomizer({children, tag, onSubmit, onOpenChange}) {
    const [form] = Form.useForm()
    const { t } = useTranslation()
    return <>
        <Popover trigger="hover" placement="bottom" destroyTooltipOnHide={true} onOpenChange={onOpenChange} content={
            <PreventPropagationWrapper>
                <Form form={form} onFinish={onSubmit}>
                    <Form.Item name="name" initialValue={tag?.name} ><Input placeholder="Tag name" /></Form.Item>
                    <Form.Item name="color" initialValue={tag?.color}><InputColorPicker form={form}/></Form.Item>
                    <div style={{textAlign: "end"}}>
                        <Button type="primary" htmlType="submit">{t("Save")}</Button>
                    </div>
                </Form>
            </PreventPropagationWrapper>
        }>
            {children}
        </Popover>
    </>
}

function TagRenderer({props, tagList, updateTag, getTag, onOpenChange}) {
    const {value} = props;
    const tag = getTag(value);


    const onPreventMouseDown = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleSubmit = async (data) => {
        updateTag(tag.id, data);
        await api.patch(`/project_management/project_tags/${tag.id}`, data);
    };

    return useMemo(()=>(
          <SolvTag style={{margin: 2}} {...props} color={tag?.color} onMouseDown={onPreventMouseDown}
                   closeIcon={<CloseOutlined style={{color: "var(--base-color)"}}/>}>
            <TagCustomizer tag={tag} onSubmit={handleSubmit} onOpenChange={onOpenChange}>
                <EditOutlined/>
            </TagCustomizer>
            {tag?.name}
        </SolvTag>
    ), [tag, props, tagList]);

}

function TagSelector({tags, tagCreateUrl, onChange, ...props}) {
    const [tagList, setTagList] = useState([]);
    const [options, setOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    const getTag = (id) => {
        return tagList.find((tag)=>tag.id === id);
    }

    const addTag = ({id, name}) => {
        setTagList([...tagList, {id: id, name: name}])
    }
    const updateTag = (id, values) => {
        setTagList(tagList.map((tag) => {
            if (tag.id === id) {
                tag = {...tag, ...values}
            }
            return tag;
        }));
    }

    const createTag = async (name) => {
        const response = await api.post(tagCreateUrl, {name: name});
        return response.data.id;
    }

    const handleChange = async (value) => {
        const updatedValue = await Promise.all(value.map(async (tag) => {
            if (typeof tag === "string") {
                const id = await createTag(tag);
                addTag({id, name: tag});
                return id;
            }
            return tag;
        }));
        onChange(updatedValue);
    };

    useEffect(()=>{
        setOptions(tagList?.map(
            (tag)=>({label: tag.name, value: tag.id})
        ));
    }, [tagList]);

    useEffect(()=>{
        setTagList(tags)
    }, [tags])

    const onOpenChange = () => {
        setOpen(false);
    }

    return <>
        <Select
            open={open}
            mode="tags"
            onDropdownVisibleChange={(open)=>setOpen(open)}
            filterOption={true}
            optionFilterProp="label"
            placeholder={t("Select one or more tags")}
            showArrow
            tagRender={(props)=>
                <TagRenderer
                    props={props}
                    updateTag={updateTag}
                    getTag={getTag}
                    tagList={tagList}
                    onOpenChange={onOpenChange}
                />}
            {...props}
            options={options}
            onChange={handleChange}
        />
    </>
}

export default TagSelector;