import {ProjectManagementContextProvider, useProjectManagementContext} from "./context/ProjectManagementContext";
import {Redirect, Switch, useLocation, useRouteMatch} from "react-router-dom";
import {ProjectManagementRoute} from "../../../../global/routes/PrivateRoute";
import ProjectManagementTimelinePage from "./ProjectManagementTimelinePage";
import ProjectManagementCheckpointsPage from "./ProjectManagementCheckpointsPage";
import ProjectManagementDashboardPage from "./ProjectManagementDashboardPage";
import ProjectManagementLinkedPage from "./ProjectManagementLinkedPage";
import ProjectManagementSettingsPage from "./ProjectManagementSettingsPage";
import React from "react";
import {usePermissionContext} from "../../../../global/context/PermissionContext";
import ProjectManagementPlanningPage from "./ProjectManagementPlanningPage";

function ProjectManagementRouter() {
    const {path} = useRouteMatch();
    const location = useLocation();
    const {projectPermissions, checkProjectPermissionContext} = useProjectManagementContext();
    
    return (
        <>
            {projectPermissions &&
                <Switch>
                    <ProjectManagementRoute
                        exact
                        path={`${path}/dashboard`}
                        hasPermission={checkProjectPermissionContext("viewer", "manager")}
                        render={() =>
                            <ProjectManagementDashboardPage />}
                    />
                    <ProjectManagementRoute
                        exact
                        path={`${path}/timeline`}
                        hasPermission={checkProjectPermissionContext("manager")}
                        render={() =>
                            <ProjectManagementPlanningPage/>}
                    />
                    <ProjectManagementRoute
                        exact
                        path={`${path}/checkpoints`}
                        hasPermission={checkProjectPermissionContext("manager")}
                        render={() =>
                            <ProjectManagementCheckpointsPage />}
                    />
                    <ProjectManagementRoute
                        exact
                        path={`${path}/linked`}
                        hasPermission={checkProjectPermissionContext("manager")}
                        render={() =>
                            <ProjectManagementLinkedPage />}
                    />
                    <ProjectManagementRoute
                        exact
                        path={`${path}/settings`}
                        hasPermission={checkProjectPermissionContext("manager")}
                        render={() =>
                            <ProjectManagementSettingsPage />}
                    />
                    <Redirect to={{ pathname: '/404', state: { broken_url: window.location.host + location.pathname } }} />
                </Switch>
            }
        </>
    );
}

function ProjectManagement() {
    return <ProjectManagementContextProvider>
        <ProjectManagementRouter />
    </ProjectManagementContextProvider>
}

export default ProjectManagement;