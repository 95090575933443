import React, {useEffect, useState} from 'react';
import {Col} from "react-bootstrap";
import './styles.css'
import api from "../../global/services/api"

import {Link} from "react-router-dom";
import ProgramFilter from "../../components/ProgramFilter";
import 'react-confirm-alert/src/react-confirm-alert.css';
import NotContent from "../../components/NotContent/NotContent";
import Slider from "@mui/material/Slider";
import {HOST_ENTREPRENEUR} from "../../settings";
import {DatetimeFormatByTimezone} from "../../global/utils/dates";
import {useTranslation} from "react-i18next";
import {useLayoutContext} from "../../modules/project/components/layout/context/LayoutContext";
import Loading from "../../global/components/feedback/Loading";
import {ToastNotification} from "../../global/components/feedback/ToastNotification";

function Evaluations() {
    const {t} = useTranslation()
    const [programsUserClient, setProgramsUserClient] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const port = window.location.port ? ':' + window.location.port : ''

    const {initializeLayout,} = useLayoutContext();
    useEffect(() => {
        initializeLayout({
             navbarMenuItems: [
                {
                    label: <Link to={``}>{t("Evaluations")}</Link>,
                    key: `/evaluations`
                },


            ]
        });
    }, []);

    useEffect(() => {
        async function fetchProgramsUserClient() {
            try {
                setIsLoading(true);
                const response = await api.get(`programs/evaluators`);
                setProgramsUserClient(response.data ? response.data : []);
            } catch (response) {
                ToastNotification(response.data['detail'],'error');
            } finally {
                setIsLoading(false);
            }
        };

        fetchProgramsUserClient()
    }, []);

    const renderCard = (programUserClient) => {
        return (
            <div className={'card-dasboard'} data-cy="program-evaluations-card">

                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 7,
                    fontSize: 12,
                    marginRight: 15,
                    color: '#4C4C4C',
                    alignItems: 'flex-start'
                }}>
                    <span style={{
                        fontWeight: 'bold',
                        fontSize: 12,
                        marginBottom: 6,
                        color: 'rgb(76, 76, 76)',
                        textAlign: "initial"
                    }}>{programUserClient.program.name}
                    </span>

                    <span
                        style={{
                            color: '#646464',
                            fontSize: 12,
                            fontWeight: 'normal'
                        }}>  <span
                        style={{fontWeight: 600}}>{t('Launch time')}:</span> {programUserClient.program.current_phase.date_start ? DatetimeFormatByTimezone(programUserClient.program.current_phase.date_start) : t('No launch time')}
                        {programUserClient.program.current_phase.date_end && <>
                            <span
                                style={{fontWeight: 600}}> {t("Deadline:")}</span> {DatetimeFormatByTimezone(programUserClient.program.current_phase.date_end)}</>}</span>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 5,
                    fontSize: 15,
                    fontWeight: 500
                }}>
                    <span style={{
                        marginBottom: 6,
                        color: '#4C4C4C',
                        textAlign: "initial"
                    }}>{t("Applications")}: {programUserClient.assessments_sended} / {programUserClient.assessments_all}
                    </span>
                    <Slider
                        aria-label="Temperature"
                        value={programUserClient.assessments_sended}
                        max={programUserClient.assessments_all}
                        style={{color: '#2e7d32'}}
                    />
                </div>
            </div>

        )
    }

    function getSpecificPhase(programUserClient) {

        if (programUserClient.first_phase_with_assessments) {
            return programUserClient.first_phase_with_assessments
        }
        return programUserClient.phases[0]
    }

    return (
        <>{isLoading ? <Loading/> : <Col style={{padding: 0, margin: 0}}
                                         className={'program-list-evaluations'}>
            <div>
                <div className={'program-container'}>
                    <div data-id='program-filter-container'
                         className={"hide-effect"}>
                        <ProgramFilter/>program-filter-container
                    </div>
                    {programsUserClient.length === 0 ? <NotContent
                            title={t("Don't have evaluation yet.")}/> :
                        <div>

                            <div
                                style={{width: "100%", marginTop: 12}}>
                                {programsUserClient.map(programUserClient => {
                                        return window.location.host === HOST_ENTREPRENEUR ?
                                            <a key={'evaluatecard' + programUserClient.id}
                                               href={'//' + programUserClient.program.client_url + port + `/evaluations/${programUserClient.id}/phases/${getSpecificPhase(programUserClient)}/`}
                                               className={'card-program-container-dash'}>{renderCard(programUserClient)}</a>
                                            : <Link
                                                key={'evaluatecard' + programUserClient.id}
                                                to={`/evaluations/${programUserClient.id}/phases/${getSpecificPhase(programUserClient)}/`}
                                                className={'card-program-container-dash'}>
                                                {renderCard(programUserClient)}
                                            </Link>
                                    }
                                )}
                            </div>
                        </div>}
                </div>
            </div>
        </Col>}
        </>

    )
}

export default Evaluations;