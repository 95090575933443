import React from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {FlexContainer} from "../authentication/StyledComponents";

import {
    neutral_2, neutral_4,
    neutral_5,
    neutral_8,
    sunrise_6, sunrise_9,
    sunset_orange_6
} from "../../utils/Colors";
import RemixIcon from "./RemixIcon";
import {Space, Tooltip, Typography} from "antd";
import UserAvatar from "../misc/UserAvatar";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import {formatPoints, toCompactNumber} from "../../utils/Utils";
import LimitNumber from "./LimitNumber";

const Container = styled(FlexContainer)`
    padding: 16px 24px;
    border-radius: 8px;
    width: 100%;
    background: white;
    border-bottom: 1px solid ${neutral_4};
`
const StyledTitle = styled(Typography.Title)`
    //text-overflow: ellipsis;
    max-width: ${({limitWidth}) => limitWidth ? '250px' : '500px'};

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

const Badge = styled.div`
    padding: 0px 8px;
    border-radius: 16px;
    color: white;
    background: ${({background}) => background};
`
const IconContainer = styled.div`
    padding: 11px 9px;
    border-radius: 8px;
        //background: ${({background}) => background};

    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: white;
`


export default function RankingPositionCard({position}) {
    const {t} = useTranslation();
    const {isPhone, isDesktop} = useSystemBreakpoint()

    function position_color() {
        switch (position.position) {
            case 1:
                return sunrise_6 ;

            case 2:
                return neutral_8;

            case 3:
                return sunset_orange_6;

            default:
                return neutral_5;

        }
    }

    return (

        <Container gap={16}>
            <FlexContainer align={'center'} gap={16}
                           style={{flex: 1, minWidth: 100}}>
                <Badge background={position_color()}>
                    {position.position.toString().padStart(2, '0')}
                </Badge>

                <UserAvatar data={position.user_obj}/>
                <StyledTitle level={5} style={{marginBottom: 0}}
                             limitWidth={isPhone || isDesktop}>
                    {position.user_obj.name}
                </StyledTitle>
            </FlexContainer>


            <FlexContainer gap={8} justify={'center'}>
                <RemixIcon remixIconName={'ri-copper-coin-line'}/>
                <span
                    style={{lineHeight: 1}}>
                    <LimitNumber value={position.points}/>
              </span>
            </FlexContainer>


        </Container>

    );
}

