import {useProgramDetailContext} from "../context/ProgramDetailContext";
import WideSpace from "../../../../components/layout/WideSpace";
import {Col, Image, Row, Space, Typography} from "antd";
import styled from "styled-components";
import UserAvatar from "../../../../components/misc/UserAvatar";
import DisplayTextElements
    from "../../../../components/misc/DisplayTextElements";
import {useTranslation} from "react-i18next";
import Button from "../../../../components/data_entry/Button";
import {BulbOutlined, SettingOutlined} from "@ant-design/icons";
import TagsList from "../../../../components/tag/TagsList";
import useSystemBreakpoint from "../../../../hooks/useSystemBreakpoint";
import {Link} from "react-router-dom";
import {toTitleCase} from "../../../../../modules/project/utils/utils";
import useApplication from "../../../../hooks/useApplication";

const {Text} = Typography
const {Paragraph} = Typography;
import React, {useEffect} from 'react';
import MultiLineText from "../../../../components/data_display/MultiLineText";
import useQuery from "../../../../hooks/useQuery";
import useDefaultGetRequest from "../../../../hooks/useDefaultGetRequest";
import {RANKING_STATUS} from "../../../../utils/Constants";
import {useThemeContext} from "../../../../context/ThemeContext";
import RankingPlacements
    from "../../../../components/data_display/RankingPlacements";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import DangerousHtmlPreviewer from "../../../../components/data_display/DangerousHtmlPreviewer";


const StyledLargeBoldText = styled(Text)`
    font-size: 16px;
    font-weight: 700;
`;

const StyledBoldText = styled(Text)`
    font-size: 14px;
    font-weight: 500;
`;

const StyledLargeButton = styled(Button)`
    width: 100%;
    height: 60px;
    font-size: 20px;
    font-weight: 500;
`

const StyledSmallButton = styled(Button)`
    width: 55px;
    height: 60px;
    font-size: 20px;
    font-weight: 500;
`

const StyledButtonsContainer = styled.div`
    display: flex;
    gap: 8px;
    justify-content: end;
`


function DescriptionProgramTab() {
    const {t} = useTranslation();
    const screens = useBreakpoint();
    const {program: challenge, getProgramInfo} = useProgramDetailContext();
    const program = getProgramInfo();
    const {themeConfig: {client}} = useThemeContext()
    const {isDesktop} = useSystemBreakpoint();
    const {handleApplyChallenge, loadingRequest} = useApplication();

    const query = useQuery();

    // Se vier da pagina publica, iniciar preenchimento do formulario automaticamente.
    useEffect(() => {
        challenge && query.get("apply") === 'yes' && handleApplyChallenge(challenge)
    }, [challenge]);

    const {
        data
        // isLoading
    } = useDefaultGetRequest({
        url: `/ranking/program/${program?.id}/get_ranking_object_related/`,
        params: {status: RANKING_STATUS.OPENED},
        makeRequest: !!program?.id && client?.gamification_actived && client?.show_ranking_in_home,
        dataDefault: []
    })

    const rankings = data?.results;

    return (
        <WideSpace direction="vertical" size="large">
            <Image src={program?.banner} style={{objectFit: "cover"}}
                   width="100%" height={190}/>
            <Row justify="space-between" gutter={[16, 16]}>
                <Col span={isDesktop ? 14 : 24}>
                    <WideSpace direction="vertical">
                        <Row>
                            <Space>
                                <UserAvatar size="large"
                                            data={{picture: program?.profile}}/>
                                <StyledLargeBoldText>{program?.name}</StyledLargeBoldText>
                            </Space>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <WideSpace direction="vertical" size="middle">
                                    <Row>
                                        {/*TODO quando for inserir esse codigo novamente tem que levar em consideração programas continuios*/}
                                        {/*{program?.time_limit &&*/}
                                        {/*    <Col span={14}>*/}
                                        {/*        <DisplayTextElements title={t("REGISTRATIONS UP UNTIL")}*/}
                                        {/*            content={program?.time_limit}*/}
                                        {/*            type={getTimeLimitType(program?.days_left)} />*/}
                                        {/*    </Col>*/}
                                        {/*}*/}
                                        <Col
                                            span={program?.time_limit ? 10 : 24}>
                                            <DisplayTextElements
                                                title={t("TYPE OF SUBMISSION")}
                                                content={program?.team_types}
                                                labelField="name"
                                            />
                                        </Col>
                                    </Row>
                                    {isDesktop && program &&
                                        <Row>
                                            <TagsList
                                                tags={program?.sectors_display.map((sector, key) => ({
                                                    name: sector,
                                                    color: "#FFF"
                                                }))}/>
                                        </Row>
                                    }
                                </WideSpace>
                            </Col>
                        </Row>
                    </WideSpace>
                </Col>
                <Col span={isDesktop ? 10 : 24}>
                    <StyledButtonsContainer>
                        <StyledLargeButton
                            style={{maxWidth: isDesktop && "315px"}}
                            loading={loadingRequest}
                            onClick={() => handleApplyChallenge(challenge)}>
                            <WideSpace size="large">
                                <BulbOutlined/>
                                {`${t("Suggest")} ${t(toTitleCase(program?.application_name_choice))}`}
                            </WideSpace>
                        </StyledLargeButton>
                        {challenge?.has_program_manager_permission &&
                            <Link to={`/programs/${program?.id}/phases`}>
                                <StyledSmallButton type="default">
                                    <SettingOutlined/>
                                </StyledSmallButton>
                            </Link>
                        }
                    </StyledButtonsContainer>
                </Col>
            </Row>
            <Row gutter={[24,24]}>

                {program?.description && <Col flex={'1'}>
                    <WideSpace direction="vertical">
                        <StyledBoldText>{t("About the program")}</StyledBoldText>
                        <DangerousHtmlPreviewer content={program.description}/>
                    </WideSpace>
                </Col>}

                {(screens.xl || screens.xxl) && client?.gamification_actived && client?.show_ranking_in_home && rankings?.length > 0 &&
                    <Col>
                        <RankingPlacements
                            open_at={rankings?.[0].open_at}
                            close_at={rankings?.[0].close_at}
                            endless={rankings?.[0].endless}
                            id={rankings?.[0]?.id}
                            title={t('Program ranking')}/>

                    </Col>}
            </Row>
        </WideSpace>
    );
}


export default DescriptionProgramTab;