import {Card, Col, Form as FormAnt, Row, Space} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import SectionOpenCampaign from "./components/SectionOpenCampaign";

import AddNewCampaignButton from "./components/AddNewCampaignButton";
import useDefaultGetRequest from "../../../../hooks/useDefaultGetRequest";
import {
    FlexContainer
} from "../../../../components/authentication/StyledComponents";
import InformationCard
    from "../../../../components/data_display/InformationCard";
import CollapseFormItem
    from "../../../../components/data_entry/CollapseFormItem";
import SectionScheduledCampaign from "./components/SectionScheduledCampaign";
import CampaignHistory from "./components/CampaignHistory";



export default function BonusCampaignPage() {
    const {t} = useTranslation();


    return (

        <FlexContainer gap={16} direction={'column'} align={'streech'}>
            <InformationCard title={t('Campaigns by program')}
                             text={t('To add campaigns to specific programs, go to the program page')}/>
            <CollapseFormItem label={t('Create new campaign')}
                              help={t('Set the time, period and bonuses that apply to the campaign.')}
                              input={<AddNewCampaignButton />}/>
            <SectionOpenCampaign/>
            <SectionScheduledCampaign/>
            <CollapseFormItem label={t('View campaign history')}
                              input={<CampaignHistory url={`/point_event`}/>}/>

        </FlexContainer>

    );
}

