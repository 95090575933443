import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {
    useApplicationDrawerContext
} from "../../context/ApplicationDrawerContext";

import Chat from "../../../../chat/Chat";
import api from "../../../../../../services/api";
import {BuildFieldErrorsDict} from "../../../../../../utils/Utils";
import Loading from "../../../../../feedback/Loading";

export default function MessagesSolutionTab({...props}) {
    const {
        setMessageCount,
        topic,
        fetchTopic,
    } = useApplicationDrawerContext();

    useEffect(() => {
        !topic && void fetchTopic()
        setMessageCount(0)
    }, []);

    return (
        topic ? <Chat topic={topic}/> : <Loading/>
    );
}