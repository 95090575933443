import React, {useEffect, useState} from "react";
import {Chart} from "react-google-charts";
import {
    REACT_APP_GOOGLE_API_KEY
} from "../../../../../confis/env_files/env_homologacao";
import api from "../../../../../global/services/api";
import {
    BuildFieldErrorsDict,
    sumArray
} from "../../../../../global/utils/Utils";
import {useTranslation} from "react-i18next";
import LoadingFull from "../../../../../global/components/other/LoadingFull";
import {Empty} from "antd";

const defaultOptions = {
    region: "BR",
    displayMode: 'markers',
    enableRegionInteractivity: true,
    backgroundColor: "#ffffff",
};


function ChartState({data, options}) {
    return (
        <Chart
            chartType="GeoChart"
            data={data}
            options={options}
            mapsApiKey={REACT_APP_GOOGLE_API_KEY}
            width="100%"
            height="100%"
        />
    )
}


const DEFAULT_CONFIG_REQUEST = {
    version: 'v1'
}

// DOC: https://www.react-google-charts.com/examples/geo-chart

export default function GeoChartState({
                                          legend_key = 'state',
                                          value = 'count',
                                          tooltip_title = 'Count',
                                          request,
                                      }) {
    const {t} = useTranslation()
    const [isLoading, setIsloading] = useState(false)
    const [data, setData] = useState();
    const [maxAxis, setMaxAxis] = useState(0);

    const getColorAxis = () => {
        return {
            colors: ["#D9D9D9", "#87E8DE", "#36CFC9", "#006D75"],
            values: [0, maxAxis],
        }
    }


    useEffect(() => {

        if (request?.url) {
            const fetchData = async () => {

                try {
                    setIsloading(true)
                    const config = {
                        params: request.params,
                        ...DEFAULT_CONFIG_REQUEST,
                        ...request.config
                    }
                    let response;
                    if (request?.body) {
                        response = await api.post(request?.url, request?.body, config);
                    } else {
                        response = await api.get(request?.url, config);
                    }
                    const DATA_TITLE = ['State', t(tooltip_title)]
                    const formated_data = response.data?.map((element) => ([element[legend_key], element[value]]))

                    setMaxAxis(sumArray(response.data, value));
                    if (formated_data.length > 0) {
                        setData([DATA_TITLE, ...formated_data]);
                    }else{
                        setData(null)
                    }
                } catch (error) {
                    BuildFieldErrorsDict(error, null, false);
                } finally {
                    setIsloading(false)
                }
            };
            void fetchData();
        }
    }, [JSON.stringify(request)]);


    const options = {
        ...defaultOptions,
        sizeAxis: {minValue: 0, maxValue: maxAxis},
        colorAxis: getColorAxis(),
    }


    if (isLoading) return <LoadingFull/>

    return (
        <>
            {data ? <ChartState data={data} options={options}/> : <Empty/>}
        </>
    );
}