import React, {useEffect, useState} from "react";

import { Typography} from "antd";
import {useTranslation} from "react-i18next";
import {
    FlexContainer
} from "../../../../../global/components/authentication/StyledComponents";
import Loading from "../../../../../global/components/feedback/Loading";


export default function LoadingSearch() {
    const {t} = useTranslation()


    return (

        <FlexContainer direction={'column'} style={{maxWidth: 470,margin: '0 auto', textAlign: 'center'}}>
            <Loading height={'50px'}/>
            <Typography.Title level={5} style={{marginTop: 16}}>
                {t('We are looking for the best options for you!')}
            </Typography.Title>
            <Typography.Text type={'secondary'}>
                {t('During the AI search, the screen will be unavailable to all program managers while we search for the best startups for you.')}
            </Typography.Text>
        </FlexContainer>

    );
}
