import React, {useState} from "react";
import FixedFooter
    from "../../../../../../global/components/layout/FixedFooter";
import {useTranslation} from "react-i18next";
import styled from 'styled-components';
import {
    FlexContainer
} from "../../../../../../global/components/authentication/StyledComponents";
import {ExclamationCircleOutlined, SendOutlined} from "@ant-design/icons";
import Button from "../../../../../../global/components/data_entry/Button";
import {Modal, Typography} from "antd";
import {BuildFieldErrorsDict} from "../../../../../../global/utils/Utils";
import {
    ToastNotification
} from "../../../../../../global/components/feedback/ToastNotification";
import {
    useLayoutContext
} from "../../../../../../modules/project/components/layout/context/LayoutContext";
import {useParams} from "react-router-dom";
import api from "../../../../../../global/services/api";


const StyledFlexContainer = styled(FlexContainer)`
    padding: 16px 32px;
    background: white;
    border-top: 1px solid rgba(0, 0, 0, .06);
`
export default function FooterApplicationSelection({
                                                       setIsLoadingSubmit,
                                                       isLoadingSubmit
                                                   }) {
    const {t} = useTranslation()
    const {updateLayoutContent} = useLayoutContext()
    const {phase_id} = useParams()

    function handleSubmit() {
        Modal.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: t(
                "The evaluation cannot be changed after submission. Do you want to confirm shipping?",
            ),
            onOk: async () => {
                try {
                    setIsLoadingSubmit(true);
                    const response = await api.put(`phases/${phase_id}/submit_approved_applications`);
                     updateLayoutContent()
                    ToastNotification(t("Successfully"));
                } catch (error) {
                    BuildFieldErrorsDict(error, null, false);
                } finally {
                    setIsLoadingSubmit(false);
                }
            },
            okText: t('Send'),
            okButtonProps: {'data-cy': "confirm-submit-button"},
            cancelText: t('Go back')

        })
    }


    return (


        <FixedFooter>
            <StyledFlexContainer justify={'end'} gap={24}>
                <Typography.Text
                    type={'secondary'}>{t('Authors will not be notified of approval or disapproval.')}</Typography.Text>
                <Button icon={<SendOutlined/>}
                        loading={isLoadingSubmit}
                        onClick={handleSubmit}
                        data-cy="submit-selections-button">{t('Complete selection')}</Button>
            </StyledFlexContainer>
        </FixedFooter>


    )
}

