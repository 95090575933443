import styled from "styled-components";

import {Tooltip} from "antd";
import {
    formatPointNumber,
    toCompactNumber,
    toFormatNumber
} from "../../utils/Utils";

const LIMIT_POINTS = 1000000
export default function LimitNumber({limit = LIMIT_POINTS, value=0}) {
    const _value = formatPointNumber(value)

    function formatPoints() {

        if (value >= limit) {
            return toCompactNumber().format(value)
        }
        return _value
    }

    return <Tooltip
        title={value >= LIMIT_POINTS && _value}>{formatPoints()}</Tooltip>
}
