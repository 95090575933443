import {Col, Empty} from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Loading from '../../../../../global/components/feedback/Loading';

const StyledColumn = styled(Col)`
    flex-grow: 1;
`

export function truncateChars(text, maxChars=8) {
    if (text?.length > maxChars) {
        return text.slice(0, maxChars) + "...";
    }
    return text;
}

function ParentWithChildren({children, parent}) {
    return parent ? React.cloneElement(parent, {children}): children;
}

export function ChartRenderer({data, chart, isActive, parent, columnOptions}){


    const { t } = useTranslation();
    let children = chart;

    if (isActive === false){
        return <></>;
    }
    else if (!data) {
        children = <Loading />;
    }
    else if (data.length === 0) {
        children = <div style={{flexGrow: "1", display: "grid", placeContent: "center"}}>
            <Empty description={t("There isn't enough data to build this graphic.")}/>
        </div>;
    }
    
    return (
        <StyledColumn {...columnOptions}>
            <ParentWithChildren parent={parent}>
                {children}
            </ParentWithChildren>
        </StyledColumn>
    );
};
