import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import Button from "../../../../../components/data_entry/Button";
import Drawer from "../../../../../components/layout/Drawer";


import {Badge, Col, Row} from "antd";
import {useThemeContext} from "../../../../../context/ThemeContext";
import DateRangePicker
    from "../../../../../components/data_entry/DateRangePicker";
import useSystemBreakpoint from "../../../../../hooks/useSystemBreakpoint";
import Loading from "../../../../../components/feedback/Loading";
import TransactionGamificationCard
    from "../../../../../components/data_display/TransactionGamificationCard";
import Empty from "../../../../../components/data_display/Empty";
import Pagination from "../../../../../components/navigation/Pagination";
import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import useDefaultGetRequest from "../../../../../hooks/useDefaultGetRequest";
import {
    useLayoutContext
} from "../../../../../../modules/project/components/layout/context/LayoutContext";


const QUERY_PARAMS_NAMES = {
    START_DATE: 'start',
    END_DATE: 'end',
    STATUS: 'status',
}


export default function ValidatePointsButton({count}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false)
    const [hasChange, setHasChange] = useState(false)
    const [dates, setDates] = useState([])
    const [actions, setActions] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const {isPhone} = useSystemBreakpoint()
    const {themeConfig: {client}} = useThemeContext();
    const {updateLayoutContent} = useLayoutContext();

    function onClose() {
        setOpen(false)
        setDates([])
        if (hasChange) {
            updateLayoutContent()
        }
        setHasChange(false)
    }

    const PaginationRequest = {
        url: `action`,
        setIsLoading: setIsLoading,
        setData: setActions,
        makeRequest: open,
        extraParams: {
            [QUERY_PARAMS_NAMES.START_DATE]: dates[0],
            [QUERY_PARAMS_NAMES.END_DATE]: dates[1],
            [QUERY_PARAMS_NAMES.STATUS]: 'pending',
            type:"entry"
        },
        dependence: [...dates, open]
    }

    return (
        <>


            <Button block={isPhone} onClick={() => setOpen(true)}
                    type={'default'} data-cy="validate-points-button">{t('Validate points')} <Badge
                style={{marginLeft: 10}}
                count={count}/></Button>


            <Drawer width={512} isGrayBody title={t('Validate points')}
                    open={open}
                    onClose={onClose}>


                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <DateRangePicker value={dates} style={{width: '100%'}}
                                         onChange={(date) => setDates(date)}/>
                    </Col>
                    {isLoading ? (<Col xs={24}><Loading/></Col>) :
                        (
                            actions?.length > 0 ? actions?.map((action) =>
                                    <TransactionGamificationCard dropInfo setHasChange={setHasChange}
                                                                 key={action.id} {...action}/>) :
                                <Col xs={24}><Empty/></Col>
                        )}
                    <Col xs={24}>
                        <FlexContainer justify={'end'}>
                            <Pagination request={PaginationRequest}

                                // showTotal={(total) => t('total_ranking_users', {total: total})}
                                        size="small"
                                        hideOnSinglePage
                            />
                        </FlexContainer>
                    </Col>
                </Row>
            </Drawer>

        </>


    )
}
