import {useTranslation} from "react-i18next";
import React, {useState} from "react";

import styled from "styled-components";
import {Col, Row, Typography} from "antd";
import {neutral_4, page_background} from "../../../../global/utils/Colors";
import SolvCollapse from "../../../../global/components/misc/SolvCollapse";
import CircularImage
    from "../../../../global/components/data_display/CircularImage";


const Container = styled.div`
    min-height: 100vh;
    background: ${page_background};
`


const Content = styled.div`
    min-height: 100vh;
    max-width: 1200px;
    margin: 0 auto;
`


const ProgramName = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
`

const Title = styled(Typography.Title)`
    border-bottom: 1px solid ${neutral_4};
    padding-bottom: 8px;
`

const HeaderContainer = styled.div`
    padding: 8px;
    display: flex;
    gap: 16px;
    align-items: center;
`


export default function SimpleInitiativeRegistrationCollapse({
                                                                 title,
                                                                 program,
                                                                 children,
                                                                 footer,
                                                                 ...props
                                                             }) {
    const {t} = useTranslation();


    return <Container {...props}>
        <Content>
            <SolvCollapse active padding={'24px'} locked
                          title={<HeaderContainer>
                              <CircularImage src={program.profile} size={32}/>
                              <ProgramName>{program.client_name} - {program.name}</ProgramName>
                          </HeaderContainer>} bordered>
                <Row>
                    {title && <Col xs={24}>
                        <Title level={4}>{title}</Title>

                    </Col>}
                    <Col xs={24}>
                        {children}
                    </Col>
                </Row>
            </SolvCollapse>
            {footer}
        </Content>

    </Container>
}