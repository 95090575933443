import {Col, Row} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    FlexContainer
} from "../../../../components/authentication/StyledComponents";
import SectionAmountPoints from "./components/SectionAmountPoints";
import Search from "../../../../components/data_entry/Search";
import ResetPointsButton from "./components/ResetPointsButton";
import {useThemeContext} from "../../../../context/ThemeContext";
import ValidatePurchaseButton from "./components/ValidatePurchaseButton";
import useSystemBreakpoint from "../../../../hooks/useSystemBreakpoint";
import SectionPurchaseTable from "./components/SectionPurchaseTable";
import HistoryTransactionsButton from "./components/HistoryTransactionsButton";

export default function StoryActivityPage({count}) {
    const {t} = useTranslation();
    const [search, setSearch] = useState('');
    const {themeConfig: {client}} = useThemeContext();
       const {isPhone} = useSystemBreakpoint()

    return (
        <Row gutter={[32, 32]}>
            <Col xs={24}>
                <SectionAmountPoints/>
            </Col>
            <Col xs={24}>
                <FlexContainer justify={'space-between'} wrap={'wrap'} gap={16}>

                        <ValidatePurchaseButton count={count}/>
                    <FlexContainer gap={16}
                                   style={{flex: 1}}
                                   justify={'end'}>
                        <Search style={{minWidth: 264, flex: isPhone? 1:0}}
                                onSearch={(value) => setSearch(value)}/>

                    </FlexContainer>

                </FlexContainer>
            </Col>
            <Col xs={24}>
                <SectionPurchaseTable  search={search} />
            </Col>
        </Row>
    );
}
