import {Card, Flex, Row, Skeleton, Timeline, Typography} from "antd";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {LockOutlined} from "@ant-design/icons";
import {DatetimeFormatByTimezone,} from "../../utils/dates";
import {getBackgroundColorByPhaseDate} from "../other/Utils";
import {useEffect, useState} from "react";
import api from "../../services/api";
import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import TruncateText from "../responsive/TruncateText";

const {Text, Title} = Typography;

const StyledSmallText = styled(Text)`
    font-size: 12px;
    font-weight: 400;
`;

const StyledBoldText = styled(Text)`
    font-size: 14px;
    font-weight: 500;
`;

export default function PhasesTimeLine({programId, program}) {
    const {t} = useTranslation();
    const [phases, setPhases] = useState(undefined);
    const {isPhone} = useSystemBreakpoint()

    const hasToShowDates = () => {
        if (program) {
            return program.duration !== "continuous"
        }
        return false
    }

    const fetchPhases = async () => {
        try {
            const response = await api.get(`/programs/${programId}/phases?hide_phases=1`);
            setPhases(response.data);
        } catch (error) {
        }
    };

    useEffect(() => {
        if (programId) {
            void fetchPhases();
        }
    }, [programId]);

    return (
        <Skeleton loading={!phases}>
            <Timeline style={isPhone && {paddingTop:16}}>
                {phases?.map((phase) => {
                    const background = phase.is_locked ? "#F0F0F0" : getBackgroundColorByPhaseDate(phase);
                    return (
                        <Timeline.Item key={phase.key} color="blue">
                            <Card bordered={false} style={{background}}>
                                <Flex vertical={true} gap={8}>
                                    <Row justify="space-between">
                                        <Flex vertical={true} justify="center" flex={"1"} gap={8}>
                                            <Flex justify="space-between" align="center">
                                                <TruncateText>
                                                    <Title level={5} style={{margin:0}}>{phase.title}</Title>
                                                </TruncateText>
                                                {phase.is_locked && <LockOutlined/>}
                                            </Flex>
                                            <Text type="secondary">{phase?.short_description}</Text>
                                        </Flex>
                                    </Row>
                                    <Row align="middle">
                                        {hasToShowDates() && (
                                            <Flex vertical={isPhone} gap={!isPhone ? 8 : undefined}>
                                                <Flex align={"center"} gap={4}>
                                                    <StyledSmallText type="secondary">{t("Start Date")}:</StyledSmallText>
                                                    <StyledBoldText>
                                                        {DatetimeFormatByTimezone(phase.date_start)}
                                                    </StyledBoldText>
                                                </Flex>
                                                <Flex align={"center"} gap={4}>
                                                    <StyledSmallText type="secondary">{t("End Date")}:</StyledSmallText>
                                                    <StyledBoldText>
                                                        {phase.date_end ? DatetimeFormatByTimezone(phase.date_end) : "-"}
                                                    </StyledBoldText>
                                                </Flex>
                                            </Flex>
                                        )}
                                    </Row>
                                </Flex>
                            </Card>
                        </Timeline.Item>
                    );
                })}
            </Timeline>
        </Skeleton>
    );
}
