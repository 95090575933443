import {Col, Modal, Row, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {
    FlexContainer
} from "../../../../components/authentication/StyledComponents";

import Search from "../../../../components/data_entry/Search";
import Table from "../../../../components/data_display/Table";
import useTable from "../../../../hooks/useTable";
import Button from "../../../../components/data_entry/Button";
import {ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons";
import DrawerAddOrEditShopItem from "./components/DrawerAddOrEditShopItem";
import api from "../../../../services/api";
import {
    ToastNotification
} from "../../../../components/feedback/ToastNotification";
import {
    BuildFieldErrorsDict,
    formatPointNumber,
    toFormatNumber
} from "../../../../utils/Utils";
import DrawerHistoryShopItem from "./components/DrawerHistoryShopItem";


export default function ShopItemsPage() {
    const {t} = useTranslation();
    const [search, setSearch] = useState('')
    const [openShopItem, setOpenShopItem] = useState(false)
    const [openShopItemHistory, setOpenShopItemHistory] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [shopItemId, setShopItemId] = useState()
    const [shopItemHistory, setShopItemHistory] = useState()
    const {
        resetTable,
        tableData,
        isLoading: isLoadingTable,
        tableParams,
        handleTableChange,
    } = useTable({
        url: '/store_itens',
        extraParams: {search: search},
        hasPagination: true,
        dependenceRequest: [search]
    })
    const columns = [
        // {
        //     title: t('Id'),
        //     dataIndex: 'id',
        //     key: 'id',
        //
        // },
        {
            title: t('Name'),
            dataIndex: 'name',
            key: 'name',
            width: 200

        },
        {
            title: t('Stock'),
            dataIndex: 'stock',
            key: 'stock',
            render: (value, _) => formatPointNumber(value)
        }, {
            title: t('Reserved'),
            dataIndex: 'reserved_count',
            key: 'reserved_count',
            noEmptyIcon: true,
        },
        {
            title: t('Actions'),
            width: 150,
            key: 'actions',
            render: (value, campaign) =>
                <FlexContainer>
                    <Button loading={isLoading}
                            onClick={() => handleEditShopItem(campaign)}
                            type="link">{t("Edit")}</Button>
                    <Button loading={isLoading}
                            onClick={() => handleDeleteShopItem(campaign)}
                            type="link">{t("Delete")}</Button>
                    <Button loading={isLoading}
                            onClick={() => handleSeeHistoryShopItem(campaign)}
                            type="link">{t("See history")}</Button>
                </FlexContainer>
        },


    ]

    function handleSeeHistoryShopItem(item) {
        setShopItemHistory(item)
        setOpenShopItemHistory(true)
    }

    function handleEditShopItem(item) {
        setShopItemId(item.id)
        setOpenShopItem(true)
    }

    function handleDeleteShopItem(item) {
        Modal.confirm({
                icon: <ExclamationCircleOutlined/>,
                title: t('Delete item'),
                content: t("Are you sure you want to delete the item?"),
                onOk: async () => {
                    try {
                        setIsLoading(true)
                        const response = await api.delete(`/store_itens/${item.id}`);
                        ToastNotification(t('Successfully'))
                        resetTable()
                    } catch (error) {
                        BuildFieldErrorsDict(error, null, false)

                    } finally {
                        setIsLoading(false)
                    }
                },
                okText: t('Delete'),
                cancelText: t('Go back')
            }
        )

    }

    useEffect(() => {
        if (!openShopItem) {
            setShopItemId(null)
        }
    }, [openShopItem])

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24} lg={14}>
                    <Typography.Title style={{marginBottom: 0}} level={5}>
                        {t('Store Items')}
                    </Typography.Title>
                </Col>
                <Col xs={24} lg={10}>
                    <FlexContainer gap={16}>
                        <Search onSearch={setSearch}/>
                        <Button onClick={() => setOpenShopItem(true)}
                                icon={<PlusOutlined/>}>{t('New item')}</Button>


                    </FlexContainer>

                </Col>
                <Col xs={24}>
                    <Table
                        columns={columns}
                        onChange={handleTableChange}
                        tableParams={tableParams}
                        loading={isLoadingTable}
                        noScroll
                        dataSource={tableData}
                    />
                </Col>
            </Row>
            <DrawerAddOrEditShopItem open={openShopItem}
                                     setOpen={setOpenShopItem}
                                     shopItemId={shopItemId}/>
            <DrawerHistoryShopItem item={shopItemHistory}
                                   open={openShopItemHistory}
                                   setOpen={setOpenShopItemHistory}/>
        </>
    );
}

