import styled from "styled-components";
import {Collapse, Typography} from "antd";
import {useFormCollapse} from "../data_entry/CollapseFormItem";
import React from "react";
import {neutral_1} from "../../utils/Colors";

const {Text} = Typography

const StyledTransparentCollapse = styled(Collapse)`
    width: 100%;
    background-color: transparent;

    .ant-collapse-header  {
        padding: 16px 44px 16px 16px;
        //background: blue;
    }
    
    .ant-collapse-content-box {
        padding: 0;
    }
    
    .transparent-collapse  {
        & > .ant-collapse-header {
            padding: 16px !important;
            border-radius: 8px !important;
            background: ${({$isCollapsed}) => $isCollapsed && neutral_1 };
            
            & > .ant-collapse-expand-icon span {
                right: 16px;
            }
        }

    }
`

const StyledText = styled(Text)`
    font-weight: 500;
`

export default function TransparentCollapse({children, header, ...props}) {
    const {activeKey, handleCollapseChange, child, getExpandIcon} = useFormCollapse()
    const isCollapsed = activeKey?.length === 0
    return (
        <StyledTransparentCollapse
            onChange={handleCollapseChange}
            activeKey={activeKey}
            expandIcon={getExpandIcon}
            expandIconPosition="end"
            bordered={false}
            className={"transparent-collapse"}
            $isCollapsed={isCollapsed}
            {...props}
        >
            <Collapse.Panel key={["1"]} className="transparent-collapse" header={<StyledText>{header}</StyledText>} forceRender={true}>
                <div ref={child}>
                    {children}
                </div>
            </Collapse.Panel>
        </StyledTransparentCollapse>
    )
}