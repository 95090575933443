import React, {useEffect, useState} from 'react';
import ApplicationDrawer from "../../../components/other/application/ApplicationDrawer/ApplicationDrawer";
import DrawerProfileUser from "../../profile/components/DrawerProfileUser/DrawerProfileUser";
import {
    applicationColumns,
    assessmentColumns,
    companyColumns,
    memberColumns,
    programColumns, projectColumns,
} from "../config/databaseColumns";
import {ApplicationFilterForm, CompaniesTeamsFilterForm, TeamMemberFilterForm} from "../config/databaseFilterForms";
import {useTranslation} from "react-i18next";
import api from "../../../services/api";
import {renderIndicator} from "../../../../modules/project/components/tables/utils";
import {useHistory} from 'react-router-dom/cjs/react-router-dom';


function getUrlByScope(name, scope, scopeObj) {
    const scopeDatabases = {
        application: "applications_database",
        companyTeams: "teams_database",
        member: "team_members_database",
        assessment: "evaluations_database",
    }
    if (scope && scopeObj) return `${scope}/${scopeObj?.id}/${scopeDatabases[name]}`
}

const EVALUATION_TYPE = 2

function getSubLinksByScope(scope, scopeObj, t, origin = "programs", currentPhase = false) {

    const canHaveAssessmentLink = () => {
        if (currentPhase?.type === EVALUATION_TYPE || currentPhase?.type === 0) {
            return true
        }
        if (scope === "programs" && !origin) {
            return true
        }
        return scope === "phases" && scopeObj?.type === EVALUATION_TYPE;
    }

    const getPathByOrigin = (path) => {
        switch (origin) {
            case "database":
                return `${path}`
            default:
                return `database/${path}`

        }
    }

    const subLinkConfig = [
        {
            label: t("Application"),
            path: getPathByOrigin("applications"),
        },
        canHaveAssessmentLink() ? {
            label: t("Assessments"),
            path: getPathByOrigin("assessments"),
        } : null,
        {
            label: t("Companies & Teams"),
            path: getPathByOrigin("companies"),
        },
        {
            label: t("Team Members"),
            path: getPathByOrigin("members"),
        }
    ].filter(Boolean);

    const getLink = (path) => {
        switch (scope) {
            case "programs":
                if (origin === "database") {
                    return `/database/program/${scopeObj.id}/${path}`
                }
                return `/programs/${scopeObj.id}/${path}`
            case "phases":
                return `/programs/${scopeObj.program}/phases/${scopeObj.id}/${path}`

        }
    }

    if (scope) {
        return subLinkConfig.map(config => {
            return (
                {
                    link: getLink(config.path),
                    key: config.path,
                    label: config.label
                }
            )
        })
    }
}


export default function useDatabase({name, scope, scopeObj, origin}) {
    const {t} = useTranslation()
    const [extraBody, setExtraBody] = useState({})
    const [rowContentData, setRowContentDataContent] = useState()
    const [requestData, setRequestData] = useState({[name]:{body: {}, extraParams: {}}})
    const [open, setOpen] = useState(false)
    const history = useHistory();

    const isScope = scope && !!scopeObj

    const formToParams = {
        application: ["show_archived"],
        challenge: ["show_archived"]
    }

    const handleOpenDrawer = (row) => {
        setRowContentDataContent(row.id)
        if (DATABASES[name]?.rowContentDrawer !== undefined) {
            setOpen(true)
        }
    }

    const handleUpdateFilter = (data) => {
        const newParams = {}

        formToParams[name]?.map((paramKey) => {
            const paramValue = data[paramKey]
            if (paramValue !== undefined) {
                newParams[paramKey] = paramValue
                delete data[paramKey]
            }
        })

        for (const key in data) {
            if (key.includes("__in") && data[key]) {

                let l = []
                data[key].map(d => {
                    let aux = d
                    if (typeof d === "string") {
                        if (d.includes(",")) {
                            aux = d.split(',')
                        }
                    }
                    if (Array.isArray(d)) {
                        aux = [...d]
                    }
                    if (Array.isArray(aux)) {
                        aux.map(a => l.push(a))
                    } else {
                        l.push(aux)
                    }
                })

                data[key] = l

            }

            if (key.includes("__range")) {
                if (data[key]?.every(item => item === "")) {
                    delete data[key]
                }
            }

            if (data[key]?.length === 0) {
                delete data[key]
            }

        }
        setRequestData({[name]: {body: data, extraParams: {...requestData.extraParams, ...newParams}}})
    }

    const handleUpdateExtraParams = (value) => {
        setRequestData({[name]:{body: requestData[name].body, extraParams: {...requestData[name].extraParams, ...value}}})
    }

    const fetchProjectColumns = async (setProjectColumns) => {
        try {
            const {data} = await api.get("v1/project_management/table/project_table_columns");
            const indicatorsColumns = (data?.map((column, index) => {
                column.title = t(column.title);
                column.align = "center";
                column.dataIndex = column.data_index;
                column.render = renderIndicator;
                column.sequence = index;
                column.visible = column.visible || true;
                column.key = column.title.toLowerCase().replace(/\s+/g, '_');

                delete column.data_index

                return column;
            }))
            setProjectColumns(projectColumns(indicatorsColumns, t))
        } catch (e) {
            console.error("Error fetching columns:", e);
            return [];
        }
    }


    const DATABASES = {
        application: {
            url: isScope ? getUrlByScope(name, scope, scopeObj) : "database/applications",
            config: {version: "v1"},
            columns: applicationColumns(),
            rowContentDrawer: () => <ApplicationDrawer solutionId={rowContentData} open={open} setOpen={setOpen}/>,
            filterForm: <ApplicationFilterForm scope={scope} scopeObj={scopeObj} extraParams={requestData.extraParams}/>
        },
        challenge: {
            url: "v1/applications_challenge/list_post_database",
            config: {version: "v1"},
            columns: applicationColumns(),
            rowContentDrawer: () => <ApplicationDrawer solutionId={rowContentData} open={open} setOpen={setOpen}/>,
            filterForm: <ApplicationFilterForm/>
        },
        companyTeams: {
            url: isScope ? getUrlByScope(name, scope, scopeObj) : "/database/companieAndTeams",
            config: {version: "v1"},
            columns: companyColumns(),
            filterForm: <CompaniesTeamsFilterForm/>
        },
        member: {
            url: isScope ? getUrlByScope(name, scope, scopeObj) : "/database/team_members",
            config: {version: "v1"},
            rowContentDrawer: () => <DrawerProfileUser id={rowContentData} open={open} setOpen={setOpen}/>,
            columns: memberColumns(),
            filterForm: <TeamMemberFilterForm/>
        },
        assessment: {
            url: isScope && `database/${getUrlByScope(name, scope.slice(0, -1), scopeObj)}`,
            config: {version: "v1"},
            columns: assessmentColumns()
        },
        programs: {
            url: "/programs/home",
            config: {version: "v1"},
            columns: programColumns(),
            onClick: (scopeObj) => {
                // history.push(`/database/program/${scopeObj.id}/applications`);
                history.push(`/programs/${scopeObj.id}/database/applications`)
            },
        },
        projects: {
            url: "/project_management/table/project_table",
            config: {version: "v1"},
            columns: undefined
        }
    }

    const subLinks = getSubLinksByScope(scope, scopeObj, t, origin)
    const {columns, url, config, rowContentDrawer, filterForm, onClick} = DATABASES[name]

    return {
        url,
        columns,
        config,
        rowContentDrawer,
        filterForm,
        subLinks,
        onClick,
        handleOpenDrawer,
        handleUpdateFilter,
        requestData, setRequestData,
        handleUpdateExtraParams,
        fetchProjectColumns,
        extraBody, setExtraBody
    }

}

