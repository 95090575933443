import React, {useEffect, useState} from "react";
import {Column} from "@ant-design/plots";
import api from "../../../../../../global/services/api";
import {
    BuildFieldErrorsDict,
    findMaxValueByKey,
    getMaxValueFromArray,
} from "../../../../../../global/utils/Utils";
import {useThemeContext} from "../../../../../../global/context/ThemeContext";
import {ChartRenderer, truncateChars} from "../../utils/chartUtils";
import ChartLegendWrapper
    from "../../../../../../global/components/data_display/ChartWrapper/ChartLegendWrapper";
import useProjectChart from "../../hooks/useProjectChart";
import Loading from "../../../../../../global/components/feedback/Loading";


export default function ColumnChart({filters,parent, extraParams, columnOptions, expand}) {
    const [data, setData] = useState();
    const {themeConfig} = useThemeContext();
    const [isActive, setIsActive] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const {handleChartClickProjectRedirect} = useProjectChart();


    const xField = "project_name"
    const yField = "value_display"
    const colorField = "project_name"
    const colorSourceKey = "project_color"

    const axis = {
        x: {
            labelAlign: "horizontal",
            tickLength: 0,
        },
        y: {
            tickCount: findMaxValueByKey(data, "display_value"),
            tickLength: 0,
        }
    }

    const config = {
        data,
        xField,
        yField,
        colorField,
        autoFit: true,
        animate: null,
        axis: {
            x: {
                labelFormatter: (datum) => truncateChars(datum, 5),
                labelAlign: "horizontal",
                tickLength: 0,
            },
            y: {
                tickCount: findMaxValueByKey(data, "display_value"),
                tickLength: 0,
            }
        },
        style: {
            fill: (d) => d.project_color,
            cursor:"pointer",
        },
        tooltip: {
            title: false,
            items: [(d) => (
                {
                    color: d.project_color,
                    field: xField,
                    channel: "x",
                    value: `${d.value_display}`
                }),
            ],
        },
        scale: {
            y: {
                domain: [0, getMaxValueFromArray(data, yField, true)]
            }
        },
        markBackground: {
            style: {
                fill: "transparent"
            }
        },
        _legend: {
            colorField,
            colorSourceKey,
            rows: 1,
            cols: 3,
        },
        drawerConfig: {
            axis: {
                x: {
                    ...axis.x,
                    labelFormatter: (text) => truncateChars(text, 20),
                }
            },
            _legend: {
                rows: 2,
            },
        },
        onReady: ({chart}) => {
            chart.on('interval:click', (event) => {
                handleChartClickProjectRedirect({event});
            });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                    setIsLoading(true)
                const url = `/analytics/dashboard/project_client_dashboard/${themeConfig.client.id}/risk_report`
                const response = await api.post(url, filters,{params: extraParams})
                setIsActive(response.data.is_active)
                setData(response.data.responses)
            } catch (error) {
                BuildFieldErrorsDict(error, null, false);
            }finally {
                setIsLoading(false)
            }
        };
        fetchData();
    }, [JSON.stringify(extraParams),filters]);


    if(isLoading) return <Loading/>
    return (
        <>
            {
                <ChartRenderer
                    data={data}
                    chart={<ChartLegendWrapper chart={Column}
                                               config={config}/>}
                    parent={parent}
                    columnOptions={columnOptions}
                    isActive={isActive}
                    expand={expand}
                />
            }
        </>
    );
}
