import React, {useEffect} from "react";

import {useHistory, useParams} from 'react-router-dom';
import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../utils/Utils";
import {useTranslation} from "react-i18next";
import Loading from "../../components/feedback/Loading";
import {ToastNotification} from "../../components/feedback/ToastNotification";
import LayoutLogin from "../../layouts/authentication/LayoutLogin";

export default function ConfirmEmail() {
    const {t} = useTranslation()
    const history = useHistory();
    let {uuid} = useParams();

    useEffect(() => {
        handleRegisterConfirmEmail()
    }, [uuid]);


    const handleRegisterConfirmEmail = async () => {
        try {
            const response = await api.get('auth/register_confirm_email/' + uuid);
            if (response.data.is_confirmed_email) {
                ToastNotification(t('Email confirmed'))

            } else {
                ToastNotification(t('Email not confirmed'), 'error')
            }
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            history.push('/login')
        }
    };

    return (
        <LayoutLogin>
            <Loading/>
        </LayoutLogin>
    );
}