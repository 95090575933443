import React, {useEffect, useState} from "react";
import {Col, Form as FormAnt, Row} from 'antd';
import {useTranslation} from "react-i18next";
import {
    FlexContainer
} from "../../../../../global/components/authentication/StyledComponents";
import Drawer from "../../../../../global/components/layout/Drawer";
import FilterButton
    from "../../../../../global/components/button/FilterButton";
import Button from "../../../../../global/components/data_entry/Button";
import Form from "../../../../../global/components/data_entry/form/Form";
import RequestSelectFormField
    from "../../../../../global/components/data_entry/form/RequestSelectFormField";


const FORM_NAMES = {
    STATE: 'states',
    TAGS: 'tags'
}

export default function ManualStartupsFilterButton({
                                                       setChoosedFilter,
                                                       choosedFilter
                                                   }) {
    const {t} = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [form] = FormAnt.useForm()


    function onClose() {
        setOpen(false)
    }

    function handleSubmit(values) {

        setChoosedFilter(({filter}) => ({...filter, ...values}))

        onClose()
    }


    useEffect(() => {
        if (open) {
            form.setFieldsValue({
                [FORM_NAMES.TAGS]: choosedFilter?.tags,
                [FORM_NAMES.STATE]: choosedFilter?.states,
            })
        }
    }, [open])


    return (

        <>
            <FilterButton onClick={() => setOpen(true)}/>
            <Drawer open={open} onClose={onClose}
                    title={t('Filter')}
                    destroyOnClose
                    footer={<FlexContainer justify={'end'} gap={10}>

                        <Button onClick={form.submit}>{t('Save')}</Button>
                    </FlexContainer>}
                    width={520}>
                <Form form={form}
                    // disabled={isLoading}
                      onFinish={handleSubmit}>
                    <Row gutter={[8, 8]}>
                        <Col xs={24}>

                            <RequestSelectFormField
                                labelField={'state'}
                                valueField={'state'}
                                form={{
                                    name: FORM_NAMES.STATE,

                                    label: t("State"),

                                }} url={'/program_hunt/get_states'}
                                mode="multiple"/>
                        </Col>
                        <Col xs={24}>

                            <RequestSelectFormField
                                form={{
                                    name: FORM_NAMES.TAGS,

                                    label: t("Sector"),

                                }}
                                url={'/program_hunt/get_counters?limit=1000'}
                                mode="multiple"/>
                        </Col>
                    </Row>


                </Form>

            </Drawer>
        </>


    );
}

