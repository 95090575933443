import React, {useEffect, useMemo, useState} from 'react';
import {Column} from '@ant-design/plots';
import {useTranslation} from "react-i18next";
import {
    BuildFieldErrorsDict,
    countValuesByKey,
    findMaxValueByKey,
    getFunnelColorGradient
} from '../../../../../../global/utils/Utils';
import {ChartRenderer, truncateChars} from '../../utils/chartUtils';
import api from "../../../../../../global/services/api";
import {useUserContext} from "../../../../../../global/context/UserContext";
import {useThemeContext} from "../../../../../../global/context/ThemeContext";
import ChartLegendWrapper
    from "../../../../../../global/components/data_display/ChartWrapper/ChartLegendWrapper";
import {Badge, Space} from "antd";
import Dot from "../../../../../../global/components/other/Dot";
import Loading from "../../../../../../global/components/feedback/Loading";
import useProjectChart from '../../hooks/useProjectChart';

export default function StackedColumnChart({
                                               filters,
                                               parent,
                                               extraParams,
                                               columnOptions,
                                               expand
                                           }) {
    const {user_client} = useUserContext();
    const {themeConfig} = useThemeContext();
    const {t} = useTranslation()
    const [isActive, setIsActive] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState();
    const [tooltipData, setTooltipData] = useState({})
    const {handleChartClickProjectRedirect} = useProjectChart();


    const colorGradient = useMemo(() => getFunnelColorGradient(data), [data])


    const xField = "phase"
    const yField = "value"
    const nameField = "project"
    const colorField = "phase"
    const colorSourceKey = "color"

    const getMaxTick = () => {
        let count = countValuesByKey(data, colorField);
        count = Object.values(count).sort((a, b) => b - a);
        return count[0]
    }

    const axis = {
        x: {
            labelFormatter: (text) => t(text),
            title: false,
            tickLength: 0,
        },
        y: {
            tickCount: data && getMaxTick(),
            // tickLength: 0,
        }
    }

    const config = {
        data,
        xField,
        yField,
        autoFit: true,
        stack: true,
        animate: null,
        style: {
            fill: (d, index) => (data && d.color),
            cursor: "pointer",
        },
        axis: {
            x: {
                ...axis.x,
                title: false,
                labelFormatter: (text) => truncateChars(t(text), 10),
                tickLength: 0,
            },
            y: {
                ...axis.y
            }
        },
        interaction: {
            tooltip: {
                render: (event, {title, items}) => (
                    <Space direction="vertical">
                        {/*<div>{t("Phase")}: {t(title)} - {items.length} { items?.length > 1 ? t("projects") : t("project") }</div>*/}
                        <div>{tooltipData.phase}</div>
                        <Space direction="vertical">
                            <Space size={4}>
                                <Dot color={tooltipData.color} size={8}/>
                                {tooltipData.project}
                            </Space>
                        </Space>
                    </Space>
                )
            }
        },
        tooltip: {
            title: (d) => d.phase,
            items: [(d, index, data, column) => (
                {
                    color: d.color,
                    name: t(d[nameField]),
                    value: d[yField]
                }
            )],
        },
        _legend: {
            colorField,
            colorSourceKey,
            rows: 1,
            cols: 3,
            labelFormatter: (label) => {
                return {
                    text: t(label.text),
                    color: colorGradient[label.order]
                }
            }
        },
        drawerConfig: {
            axis,
            _legend: {
                rows: 2,
            },
        },
        onReady: ({chart}) => {
            chart.on("tooltip:show", (event) => {
                setTooltipData(event.data.data)
            })
            chart.on('interval:click', (event) => {
                handleChartClickProjectRedirect({event, fieldName: "id"});
            });
        }
    }

    useEffect(() => {
        if (user_client) {
            const fetchData = async () => {
                try {
                    setIsLoading(true)
                    const url = `/analytics/dashboard/project_client_dashboard/${themeConfig.client.id}/project_by_phase_report`
                    const response = await api.post(url, filters, {params: extraParams})
                    setIsActive(response.data.is_active)

                    const data = []

                    response.data.map(phases => {
                        let obj = {phase: phases.phase}
                        if (phases.projects.length > 0) {
                            phases.projects.map(project => {
                                obj = {...obj, ...project}
                                data.push(obj)
                            })
                        } else {
                            data.push(obj)
                        }
                    })

                    setData(data)
                } catch (error) {
                    BuildFieldErrorsDict(error, null, false);
                } finally {
                    setIsLoading(false)
                }
            };
            void fetchData();
        }
    }, [user_client, JSON.stringify(extraParams), filters]);


    if (isLoading) return <Loading/>
    return (
        <>
            {
                <ChartRenderer
                    data={data}
                    chart={<ChartLegendWrapper chart={Column}
                                               config={config}/>}
                    parent={parent}
                    columnOptions={columnOptions}
                    isActive={isActive}/>
            }
        </>
    )
}