import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import styled from "styled-components";
import useSystemBreakpoint from "../../../hooks/useSystemBreakpoint";
import {Col, Row} from "antd";
import {
    FlexContainer
} from "../../../components/authentication/StyledComponents";

import TransactionGamificationCard
    from "../../../components/data_display/TransactionGamificationCard";
import Loading from "../../../components/feedback/Loading";
import Empty from "../../../components/data_display/Empty";
import Pagination from "../../../components/navigation/Pagination";
import {useUserContext} from "../../../context/UserContext";
import FilterActionWalletButton from "./FilterActionWalletButton";


const Title = styled.span`
    font-size: 14px;
    font-weight: 500;
`
const QUERY_PARAMS_NAMES = {
    START_DATE: 'start',
    END_DATE: 'end',
    STATUS: 'status',
    SEARCH: 'search',
    MOVIMENT: 'moviment',
    USER_CLIENT: 'user_client',
    TYPE_TRANSACTION: 'type',
}


const FORM_NAMES_FILTERS = {

    DATES: 'dates',
    MOVIMENT: 'moviment',
    STATUS: 'status',
}


export default function WalletTransactionHistory({value, isPending}) {
    const {t} = useTranslation()
    const {isPhone, isDesktop} = useSystemBreakpoint()
    const [actions, setActions] = useState([])
    const [filters, setFilters] = useState({
        [FORM_NAMES_FILTERS.MOVIMENT]: '',
        [FORM_NAMES_FILTERS.STATUS]: [  'pending','consolidated', 'revoked'],
    })
    const [isLoading, setIsLoading] = useState(false)
    const {_user: {user_client_authenticated}} = useUserContext()


    const PaginationRequest = {
        url: `action`,
        setIsLoading: setIsLoading,
        setData: setActions,
        extraParams: {
            [QUERY_PARAMS_NAMES.START_DATE]: filters[FORM_NAMES_FILTERS.DATES]?.[0],
            [QUERY_PARAMS_NAMES.END_DATE]: filters[FORM_NAMES_FILTERS.DATES]?.[1],
            [QUERY_PARAMS_NAMES.STATUS]: filters[FORM_NAMES_FILTERS.STATUS],
            [QUERY_PARAMS_NAMES.USER_CLIENT]: user_client_authenticated?.id,
            [QUERY_PARAMS_NAMES.TYPE_TRANSACTION]: filters[FORM_NAMES_FILTERS.MOVIMENT],
            // [QUERY_PARAMS_NAMES.SEARCH]: Search,
            'order': '-id',


        },
        makeRequest: !!user_client_authenticated?.id,
        dependence: [JSON.stringify(filters), user_client_authenticated?.id]
    }

    return (
        <Row gutter={[24, 24]}>
            <Col xs={24}>
                <Title>{t('Your transaction history')}</Title>
            </Col>
            <Col xs={24}>
                <FlexContainer justify={'END'} gap={16}>


                    <FilterActionWalletButton
                        filters={filters}
                        FORM_NAMES_FILTERS={FORM_NAMES_FILTERS}
                        setFilters={setFilters}/>

                </FlexContainer>

            </Col>
            <Col xs={24}>
                <Row gutter={[16, 16]}>


                    {isLoading ? (<Col xs={24}><Loading/></Col>) :
                        (
                            actions?.length > 0 ? actions?.map((action) =>
                                    <Col xs={24}><TransactionGamificationCard
                                        noActions
                                        key={action.id} {...action}/></Col>) :
                                <Col xs={24}><Empty/></Col>
                        )}
                </Row>
            </Col>
            <Col xs={24}>
                <FlexContainer justify={'end'}>
                    <Pagination request={PaginationRequest}

                        // showTotal={(total) => t('total_ranking_users', {total: total})}
                                size="small"
                                hideOnSinglePage
                    />
                </FlexContainer>
            </Col>
        </Row>
    )
}
