import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import api from "../../../../services/api";
import { BuildFieldErrorsDict } from "../../../../utils/Utils";


const ProgramDetailContext = createContext({})

export default function ProgramDetailContextProvider({ children }) {

    const {id} = useParams()
    const [program, setProgram] = useState()
    const [challenges, setChallenges] = useState()
    const [hasProgramPermission, setHasProgramPermission] = useState()
    const history = useHistory()

    const fetchProgram = async () => {
        try {
            const url = `/challenge_details/${id}/program`
            const {data} = await api.get(url, {version: "v2"})
            setProgram(data)
            setHasProgramPermission(data.has_program_permission)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
            history.push('/404')
        }
    }

    const fetchChallenges = async () => {
        try {
            const url = `/programs/challenges/${id}`
            const { data } = await api.get(url, {version: "v2"})
            setChallenges(data)
            return data
        } catch (error) {

        }
    }

    const getProgramInfo = () => {
        const challenge = program; // Abstração para facilitar o entendimento
        return challenge?.program;
    }

    useEffect(() => {
        !program && void fetchProgram()
        !challenges && void fetchChallenges()
    }, [program]);

    const value = {
        program,
        challenges,
        getProgramInfo,
        hasProgramPermission,
        fetchChallenges,
    }

    return (
        <ProgramDetailContext.Provider value={value}>{children}</ProgramDetailContext.Provider>
    );
}

export function useProgramDetailContext() {
    return useContext(ProgramDetailContext)
}

