import tinycolor from "tinycolor2";
import styled from "styled-components";
import {Typography} from "antd";
import {useMemo} from "react";
import {FlexContainer} from "../authentication/StyledComponents";
import CardNumber from "./CardNumber";



const colors = [
    "#483D8B", "#2F4F4F", "#2E8B57", "#556B2F", "#7FFF00", "#7CFC00", "#ADFF2F",
    "#006400", "#556B2F", "#8B4513", "#BC8F8F", "#A0522D", "#FFA07A",
    "#FF4500", "#DA70D6", "#EEE8AA", "#98FB98", "#AFEEEE", "#DB7093", "#FFEFD5", "#FFDAB9",
    "#CD853F", "#FFC0CB", "#DDA0DD", "#2F4B8E", "#3D7072", "#4A6140", "#4D3346", "#67JA45",
    "#7A3A5B", "#9F5E43", "#B3E240", "#BAD656", "#C0B352", "#C41875", "#D1C7FC", "#E03535",
    "#F2C55C", "#260D4F", "#324F29", "#475A23", "#4A898B", "#4E5FB5", "#4F7344", "#4F7FB2", "#548C8E",
]


export default function CardFilterStartup({
                  title, number,onClick
              }) {
    const style = useMemo(() => {
        const randomIndex = Math.round(Math.random() * colors.length)
        const tColor = tinycolor(colors[randomIndex])
        return {
            color: tColor.getOriginalInput(),
            background: tColor.getLuminance() < 0.5 ? tColor.brighten(50).toString() : tColor.darken(20).toString()
        }
    }, [])
    const _number = number > 9999 ? '+9999' : number.toString()?.padStart(4, "0")
    return (
        <div>
        <CardNumber label={title}  number={_number} customStyle={style} onClick={onClick}/>
</div>
    )
}