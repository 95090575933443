import Loading from "../../global/components/feedback/Loading";
import {
    ButtonLogin, CheckboxContainer, IconContainer,
    ImageContainer, InputContainerCustom,
    InputLogin,
    InputWrapper,
    InviteLabel,
    InviteMessageContainer, InviteTitle,
    LoginContainer,
    LogoContainer,
    MainContainer,
    StyledLabel,
    StyledLogoImg, StyledPassWordCheckList
} from "./StyledAuthComponents";
import React, {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {useThemeContext} from "../../global/context/ThemeContext";
import {BuildFieldErrorsDict} from "../../global/utils/Utils";
import api from "../../global/services/api";
import UserContext, {useUserContext} from "../../global/context/UserContext";
import {hasAcceptedCookies} from "../../global/utils/Auth";
import MessageError from "../Form/MessageError";
import {ReactComponent as EmailIcon} from "../../assets/img/solv/email_icon.svg";
import ShowHiddenPassword from "../ShowHiddenPassword";

export function UserRegisterConfirmation() {
    const {t} = useTranslation();
    const {themeConfig} = useThemeContext()
    const [isLoading, setIsLoading] = useState(false)
    const [validateFields, setValidateFields] = useState({})
    const {setRefresh} = useUserContext();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [registerPassword, setRegisterPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');


    const handleSubmitConfirmation = async () => {
        try {
            setIsLoading(true)

            let data = {
                'first_name': firstName,
                'last_name': lastName,
            }
            if (registerPassword) {
                data = {
                    ...data,
                    "new_password": newPassword,
                    "confirm_password": confirmPassword
                }
            }
            const response = await api.patch(`/auth/user/confirm_user_registration/`, data);
            setRefresh(true)
        } catch (error) {
            BuildFieldErrorsDict(error, setValidateFields, true)
            setIsLoading(false)
        }
    }

    function handleAddPassword(e) {
        let checked = e.target.checked
        if (!checked) {
            setNewPassword('')
            setConfirmPassword('')
        }
        setRegisterPassword(checked)
    }


    return (<MainContainer>

        <>
            <ImageContainer>
                <img src={themeConfig.login_img_cover}/>
            </ImageContainer>
            <LoginContainer>
                <LogoContainer>
                    <StyledLogoImg src={themeConfig.login_logo_cover}/>
                </LogoContainer>
                {isLoading ?
                    (<Loading/>) : (
                        <>

                            <InviteMessageContainer>
                                {hasAcceptedCookies() ? (
                                    <div>
                                        <InviteTitle>{t("Welcome to Solv")}</InviteTitle>
                                        <InviteLabel style={{marginTop: 16}}>{t("You are logging in via an invite.")}</InviteLabel>
                                        <InviteLabel>{t("Complete the data to start your access:")}</InviteLabel>
                                        <InputContainerCustom
                                            style={{marginTop: 16}}>
                                            <StyledLabel
                                                required={true}>{t("First Name")} *</StyledLabel>
                                            <InputWrapper>
                                                <InputLogin type={'text'}
                                                            onChange={(e) => setFirstName(e.target.value)}
                                                            value={firstName}
                                                    // required={true}
                                                            name={"first_name"}
                                                            autoComplete={themeConfig.is_cache_browser_login ? 'on' : "off"}
                                                            placeholder={t("Type your first name here")}/>
                                                {validateFields.first_name &&
                                                <MessageError
                                                    style={{color: 'red'}}>{validateFields.first_name}</MessageError>}
                                                <IconContainer>
                                                    <EmailIcon/>
                                                </IconContainer>
                                            </InputWrapper>
                                        </InputContainerCustom>
                                        <InputContainerCustom>
                                            <StyledLabel
                                                required={true}>{t("Last Name")} *</StyledLabel>
                                            <InputWrapper>
                                                <InputLogin type={'text'}
                                                            onChange={(e) => setLastName(e.target.value)}
                                                            value={lastName}
                                                    // required={true}
                                                            name={"last_name"}
                                                            autoComplete={themeConfig.is_cache_browser_login ? 'on' : "off"}
                                                            placeholder={t("Type your last name here")}/>
                                                {validateFields.last_name &&
                                                <MessageError
                                                    style={{
                                                        color: 'red',
                                                        alignSelf: 'flex-start'
                                                    }}>{validateFields.last_name}</MessageError>}
                                                <IconContainer>
                                                    <EmailIcon/>
                                                </IconContainer>
                                            </InputWrapper>
                                        </InputContainerCustom>
                                        <CheckboxContainer>
                                            <input
                                                id={'registerPassword'}
                                                type={'checkbox'}
                                                style={{cursor: 'pointer'}}
                                                checked={registerPassword}
                                                onChange={handleAddPassword}/>
                                            <StyledLabel
                                                htmlFor={'registerPassword'}>
                                                {t('I would like to register a password.')}
                                            </StyledLabel>
                                        </CheckboxContainer>
                                        {registerPassword && (
                                            <>
                                                <InputContainerCustom style={{marginTop: 16}}>
                                                    <StyledLabel
                                                        required={true}>{t("Password")} *</StyledLabel>
                                                    <InputWrapper>
                                                        <InputLogin
                                                            onChange={(e) => setNewPassword(e.target.value)}
                                                            placeholder={t("Type your password here...")}
                                                            messageError={validateFields.password}
                                                            value={newPassword}
                                                            required={true}
                                                            name={"new_password"}
                                                            type={'password'}
                                                            label={"Password"}
                                                            id={"new_password"}
                                                            autoComplete="off"/>
                                                        {validateFields.new_password &&
                                                        <MessageError
                                                            style={{color: 'red'}}>{validateFields.new_password}</MessageError>}
                                                        <IconContainer>
                                                            <ShowHiddenPassword
                                                                id={"new_password"}/>
                                                        </IconContainer>
                                                    </InputWrapper>
                                                </InputContainerCustom>
                                                <InputContainerCustom>
                                                    <StyledLabel
                                                        required={true}>{t("Confirm Password")} *</StyledLabel>
                                                    <InputWrapper>
                                                        <InputLogin
                                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                                            placeholder={t("Type your password here...")}
                                                            value={confirmPassword}
                                                            required={true}
                                                            name={"confirm_password"}
                                                            type={'password'}
                                                            label={"Confirm Password"}
                                                            id={"confirm_password"}
                                                            autoComplete="off"/>
                                                        {validateFields.confirm_password &&
                                                        <MessageError
                                                            style={{color: 'red'}}>{validateFields.confirm_password}</MessageError>}
                                                        <br/>
                                                        <IconContainer>
                                                            <ShowHiddenPassword
                                                                id={"confirm_password"}/>
                                                        </IconContainer>
                                                        <StyledLabel>
                                                            <StyledPassWordCheckList
                                                                rules={["minLength", "specialChar", "number", "capital", 'lowercase', "match"]}
                                                                minLength={8}
                                                                iconSize={12}
                                                                value={newPassword}
                                                                valueAgain={confirmPassword}

                                                                messages={{
                                                                    minLength: t("Password has more than 8 characters."),
                                                                    specialChar: t("Password has special characters."),
                                                                    number: t("Password has a number."),
                                                                    capital: t("Password has a capital letter."),
                                                                    lowercase: t("Password has a lowercase letter"),
                                                                    match: t("Passwords match."),
                                                                }}
                                                            />
                                                        </StyledLabel>
                                                    </InputWrapper>
                                                </InputContainerCustom>

                                            </>
                                        )}
                                        <ButtonLogin style={{
                                            marginBottom: 20,
                                            marginTop: 40
                                        }}
                                                     onClick={() => handleSubmitConfirmation()}>{t("CONFIRMAR")}</ButtonLogin>
                                    </div>
                                ) : (
                                    <InviteLabel>{t("You must accept cookies in order to proceed.")}.</InviteLabel>
                                )}

                            </InviteMessageContainer>
                        </>
                    )}
            </LoginContainer>
        </>
    </MainContainer>)
}