import React from 'react';
import styled from 'styled-components';
import {Col, Row, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {DatetimeFormatByTimezone} from "../../utils/dates";


const Container = styled.div`

  padding: 16px 0 16px 0;
  width: 100%;
  border-bottom: 1px solid #F0F0F0;
`

export default function CardHistory({
                                        title,
                                        message,
                                        date,
                                        owner
                                    }) {
    const {t} = useTranslation()

    return (

        <Container>


            <Row>
                <Col xs={24}>
                    <Typography.Title
                        level={5}>{title}</Typography.Title>
                </Col>
                <Col xs={24}>
                    <Typography.Text
                        type="secondary">{message}</Typography.Text>
                </Col>
                <Col xs={24}>
                    <Typography.Text>{t("User")}: {owner} </Typography.Text>
                </Col>
                <Col xs={24}>
                    <Typography.Text
                        type="secondary">{t("Date")}: {DatetimeFormatByTimezone(date, false)}</Typography.Text>
                </Col>

            </Row>


        </Container>
    )
}