import React, {useEffect, useState} from 'react';
import {TreeSelect as AntTreeSelect} from "antd";

export default function TreeSelect({
                                       optionsSettings,//se não tiver tem que formatar o treedata fora
                                     treeData = [],
                                       ...props
                                   }) {
    const [_treeData, _setTreeData] = useState()


    function formatOptions(options, optionsSettings) {
        return options.map((option) => {
            const newOption = {}
            Object.keys(optionsSettings).forEach((key) => {
                if (key === 'selectable') {
                    newOption[key] = optionsSettings[key]
                } else if (key === 'children') {
                    const newOptionsSettings = {...optionsSettings[key]}
                    delete newOptionsSettings['name']
                    newOption[key] = formatOptions(option[optionsSettings[key].name], newOptionsSettings)
                } else {
                    newOption[key] = option[optionsSettings[key]]
                }

            })
            return newOption
        })
    }


    useEffect(() => {
        if (optionsSettings && treeData.length > 0) {
            _setTreeData(formatOptions(treeData, optionsSettings))
        }else{
            _setTreeData(treeData)
        }

    }, [optionsSettings, treeData]);
    return (
        <AntTreeSelect treeNodeFilterProp={'title'} allowClear treeData={_treeData} {...props}/>
    );
}