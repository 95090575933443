import {HolderOutlined} from "@ant-design/icons";
import {Card, Checkbox, Flex, Switch, Typography} from "antd";
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import OrderingTable from "../../../components/data_display/OrderingTable";
import Drawer from "../../../components/layout/Drawer";
import RemixIcon from "../../../components/data_display/RemixIcon";
import styled from "styled-components";
import {neutral_6} from "../../../utils/Colors";
import HelpCard from "../../../components/other/HelpCard";

const {Text} = Typography

const StyledOrderingTable = styled(OrderingTable)`

    .ant-table-cell {
        padding: 8px 4px !important;
    }

    .ant-table-tbody > tr > td {
        border: none
    }
`

const AllCheckboxContainer = styled(Flex)`

    padding-left: 22px;
    padding-bottom: 0;

    & > * {
        padding: 0 4px;
    }
`

export default function ColumnSettingsDrawer({open, setOpen, columns, setColumns}) {
    const {t} = useTranslation();

    const handleUpdateVisible = (checked, key, allMode = false) => {
        setColumns(prevState => (
            prevState.map(item => {
                if ((item.key === key) || allMode) {
                    item.visible = checked
                }
                return item
            })
        ))
    }

    const allVisible = columns.map(col => col.visible).every(Boolean)

    const _columns = [
        {
            title: t('Sort'),
            dataIndex: 'sort',
            width: "10px",
            render: (v, record) => {
                const {_dragHandleProps} = record
                return (<RemixIcon remixIconName={"ri-drag-move-fill"} {..._dragHandleProps}/>)
            },
            key: "1"
        },
        {
            title: t('Column'),
            dataIndex: 'visible',
            width: "10px",
            key: "2",
            render: (value, record) => {
                return (
                    <Checkbox checked={allVisible || value} onChange={(e) => {
                        handleUpdateVisible(e.target.checked, record.key)
                    }}/>
                )
            }
        },
        {
            title: t('Visible'),
            dataIndex: 'title',
            key: "3",
        },
    ];

    return (
        <Drawer open={open} setOpen={setOpen} title={t("Columns Settings")} destroyOnClose={true}>
            <Flex vertical={true} gap={16}>
                <HelpCard
                    title={"Personalize your view"}
                    description={"Choose the columns you want to view in the table. You can also rearrange the order of the columns below; the selected sequence will be directly reflected in the table. This view will be saved when you exit the table."}
                    icon={<RemixIcon remixIconName={"ri-fingerprint-fill"} style={{fontSize: 20}}/>}
                />
                <Flex vertical={true} gap={8}>
                    <AllCheckboxContainer>
                        <Checkbox checked={allVisible}
                            onChange={(e) => handleUpdateVisible(e.target.checked, undefined, true)}
                        />
                        <Text>{t("All")}</Text>
                    </AllCheckboxContainer>
                    <StyledOrderingTable
                        columns={_columns}
                        dataSource={columns}
                        setDataSource={setColumns}
                        orderKey={"sequence"}
                        showHeader={false}
                    />
                </Flex>
            </Flex>
        </Drawer>
    );
}

