import { RightSquareOutlined} from "@ant-design/icons";
import { Collapse, Typography } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

const StyledCollapse = styled(Collapse)`
  ${props=> props.outlined ? "":  "border: none !important;"}
  max-width: 100%;

  .ant-collapse-header {
    padding-block: 0 !important;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding-right: ${props => props.locked && "16px !important"};
    background-color: ${props => props.headerBackground};
    align-items: center !important;
    height: 50px;
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
    border-bottom-left-radius: ${props => (props.isActive ? "0" : "8px")} !important;
    border-bottom-right-radius: ${props => (props.isActive ? "0" : "8px")} !important;
  }

  .ant-collapse-item {
    ${props=> props.outlined ? "":  "border: none !important;"}
    border-radius: ${props => (!props.isActive ? "0" : "8px")} !important;
  }

  .ant-collapse-header {
    font-weight: ${props => props.bold && "500"};
    line-height: 64px !important;
    cursor: ${props => props.locked && "unset !important"};
  }
  
  .ant-collapse-header-text {
    max-width: 95% !important;
    display: block !important;
    flex: unset !important;
    //height: inherit !important;
  }
  
  .ant-collapse-arrow svg {
    transform: rotate(${props => props.rotate? "90deg" : "0deg"});
    transition-duration: 1s;
  }

  .ant-collapse-content {
    border-color: var(--font-fading-color) !important;
    background-color: ${props => props.headerBackground} !important;
  }

  .ant-collapse-content-box {
    padding: ${props => props.padding ?? "12px 16px"} !important;
    height: 100% !important;
  }

  .ant-collapse-content-box > div {
    height: 100% !important;
  }
  &.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-extra{
    //margin-left: unset;
  }
`;

const {Text} = Typography;


export default function SolvCollapse({ children, content, title, renderExtra, padding, locked, showIconWhenLocked=false, active, bordered, outlined = false,
                          bold = true, collapseStyle, contentStyle={fontSize:16}, handleActive,
                          headerBackground = "white", expandIcon, style, dataCy, ...props}) {
    // expandIcon = <Text style={contentStyle}>{expandIcon || <RightSquareOutlined />}</Text>;
    
    const [isActive, setIsActive] = useState(active);
	const handleChange = (keys) => {
        const activeState = keys.length > 0;
        if (!locked) {
            setIsActive(activeState);
            handleActive && handleActive(activeState);
        }
    };

    useEffect(()=>{
      setIsActive(active);
    }, [active]);

    return (
        <StyledCollapse
            rotate={isActive && !locked}
            bold={bold}
            padding={padding}
            locked={locked}
            activeKey={isActive && "1"}
            bordered={false}
            outlined={outlined}
            onChange={handleChange}
            expandIconPosition="end"
            style={collapseStyle}
            expandIcon={()=>(showIconWhenLocked && locked || !locked) && expandIcon}
            headerBackground={headerBackground}
            isActive={isActive}

        >
            <Collapse.Panel header={title} key="1" style={contentStyle} extra={typeof renderExtra === "function" ? renderExtra(isActive) : renderExtra} data-cy={dataCy}>
                {content}
                {children}
            </Collapse.Panel>
        </StyledCollapse>);
}
