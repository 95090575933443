import React from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import RankingCard from "../../../../../components/data_display/RankingCard";
import useDefaultGetRequest from "../../../../../hooks/useDefaultGetRequest";
import {RANKING_STATUS} from "../../../../../utils/Constants";
import Loading from "../../../../../components/feedback/Loading";
import {Col, Row} from "antd";
import Empty from "../../../../../components/data_display/Empty";

const Title = styled.h2`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
`
export default function SectionOpenRanking({}) {
    const {t} = useTranslation();
    const {
        data: rankings,
        isLoading
    } = useDefaultGetRequest({
        url: `/ranking`,
        params: {status: RANKING_STATUS.OPENED, environment_rank:true},
        dataDefault: []
    })


    return (

        <>

            <Row gutter={[16,16]}>
                <Col xs={24}><Title
                level={6}>{t('Current ranking')}</Title></Col>
                {isLoading ? (
                    <Col xs={24}><Loading height={'100px'}/></Col>
                ) : rankings?.results?.length > 0 ? rankings?.results?.map((ranking) =>
                    <Col xs={24} key={ranking.id}><RankingCard
                        ranking={ranking}/></Col>
                ) : <Col xs={24}><Empty
                    description={t('There are no active rankings at the moment.')}/></Col>}

            </Row>


        </>

    );
}
