import styled from 'styled-components';
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';

const StyleDivContent = styled.div.attrs(() => ({
  className: 'show-number-list'
}))`
  text-align-last: ${({ aling }) => aling};
  font-size: ${({ font_size }) => font_size}px;
`


const text_aling = [
  'auto',
  'start',
  'end',
  'left',
  'right',
  'center',
  'justify'
];


function ShowNumberList({count_displayed= 0, count_total= 0, aling, margin, font_size = 11}) {
    const {t} = useTranslation();

    return (
        <StyleDivContent aling={aling} font_size={font_size} margin={margin}>{t("Showing")} {count_displayed} {t("of")} {count_total}</StyleDivContent>
    )
}

ShowNumberList.propTypes = {
  aling: PropTypes.oneOf(text_aling).isRequired,
};

export default ShowNumberList