import React from "react";
import {
    useLayoutContext
} from "../../../modules/project/components/layout/context/LayoutContext";
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from "@ant-design/icons";
import styled from 'styled-components'
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  //margin-left: -8px; 
    
  & > * {
    color: ${({color}) => color ? color : 'black'};
    font-size: ${({isMobile}) => isMobile ? '24px' : '16px'};
  }
`

export function MenuCollapseButton({color}) {
    const {collapsed, setCollapsed} = useLayoutContext();
    const {md} = useBreakpoint();

    return (
        <Container className={"MenuButton"} isMobile={!md}
                   onClick={() => setCollapsed(!collapsed)} color={color}>
            {collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
        </Container>
    )
}
