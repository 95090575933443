import { Badge, Grid, Space, Tooltip, Typography } from "antd";
import SolvCollapse from "../../../global/components/misc/SolvCollapse";
import { useTranslation } from "react-i18next";
import ProjectTable from "./tables/ProjectTable";
import { useEffect, useState } from "react";
import api from "../../../global/services/api";
import {blue_8} from "../../../global/utils/Colors";
import Button from "../../../global/components/data_entry/Button";
import RemixIcon from "../../../global/components/data_display/RemixIcon";
import { generateExportReport, REPORTS } from "../../../global/pages/database/hooks/useExportDatabase";

const { Text } = Typography;

function InfoItem({ title, count, color }) {
    const textStyle = {
        fontSize: 12
    };
    return (
        <Space size={8} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Text type="secondary" style={textStyle} >{title}</Text><Badge color={color?.background} style={{ color: color?.text, ...textStyle }} count={count} showZero />
        </Space>);
}

function ProjectCollapse({phase, title, mdExtra, extra}) {

    const screens = Grid.useBreakpoint();
    const [isActive, setIsActive] = useState(phase && phase.count_projects > 0);
    const [projects, setProjects] = useState(undefined);
    const [projectCount, setProjectCount] = useState(0);
    const [isExportLoading, setIsExportLoading] = useState(false);
    const [indicatorColumns, setIndicatorColumns] = useState([]);
    const { t } = useTranslation();

    const fetchProjects = async () => {
        try {
            // const url = `/project_management/table/project_table` + (phase ? `/${phase?.order}` : '');
            const url = `/project_management/table/project_table_columns`
            const response = await api.get(url);
            // setProjects(response.data.items);
            // setProjectCount(response.data.items.length);
            setIndicatorColumns(response.data);
        } catch (exception) {
        }
    }
    
    const handleExport = async () => {
        const event = {
            detail: {setIsLoading: setIsExportLoading}
        }
        generateExportReport(REPORTS.PROJECT_LIST)(event);
    }

    useEffect(()=>{
        if (!projects && isActive) { 
            void fetchProjects();
        }
    }, [projects, isActive]);

    const renderExtra = (isActive) => {
        return (
          isActive && (
            <Space size="large">
              {screens.md && (
                <>
                  <InfoItem
                    title={t("Projects")}
                    count={projectCount}
                    color={{ background: "#F0F5FF", text: blue_8 }}
                  />
                  {/* <InfoItem title={t("Pending activities")} count={pendingActivitiesCount} color={{ background: "#FFF1F0", text: "#F5222D" }} /> */}
                  {/* <InfoItem title={t("Late checkpoints")} count={lateCheckpointsCount} color={{ background: "#FFF1F0", text: "#F5222D" }} /> */}
                  {extra}
                </>
              )}
              {mdExtra}
              <Tooltip title={t("Export")}>
                <Button
                    loading={isExportLoading}
                    type="default"
                    onClick={handleExport}
                >
                  <RemixIcon remixIconName={"ri-download-cloud-line"} />
                </Button>
              </Tooltip>
            </Space>
          )
        );
    };
    
    useEffect(()=>{
        if (!phase) {
            setIsActive(true);
        }
    }, [phase]);

    return (
        <SolvCollapse title={title}
            locked={!phase}
            active={isActive}
            renderExtra={renderExtra}
            content={<ProjectTable phase={phase} indicatorColumns={indicatorColumns} setProjectCount={setProjectCount} />}
            padding={0}
            handleActive={(isActive)=>setIsActive(isActive)}
        />);
}

export default ProjectCollapse;