import React from "react";
import DefaultComponent from "./DefaultComponent";
import Input from "../../../Form/Input";

function EmailForm(props) {

    return (
        <DefaultComponent {...props}>
             <Input type={'email'} placeholder={'user@domain.com'} />
        </DefaultComponent>
    )
}

export default EmailForm