import React from 'react';


import {useTranslation} from "react-i18next";
import useDefaultGetRequest
    from "../../../../../../global/hooks/useDefaultGetRequest";
import {useParams} from "react-router-dom";
import {Col, Row} from "antd";
import {
    dust_red_7,
    lime_6, neutral_8,
} from "../../../../../../global/utils/Colors";
import {UserOutlined, ClockCircleOutlined} from "@ant-design/icons";
import MetricsCard
    from "../../../../../../global/components/data_display/MetricsCard";
import RemixIcon
    from "../../../../../../global/components/data_display/RemixIcon";


export default function DisplayEvaluators() {
    const {t} = useTranslation()
    let {phase_id} = useParams()
    const {
        data: evaluatorsData,
        isLoading: isLoadingEvaluatorsData
    } = useDefaultGetRequest({
        // url: `phase_evaluatuions_counters/${phase_id}`,
        url: `evaluator_assessments/phase/${phase_id}/count_status_by_evaluator`,
        config: {version: 'v2'},
        dependence: [phase_id]
    })


    return (

        <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} lg={8}>
                <MetricsCard color={lime_6}
                    loading={isLoadingEvaluatorsData}
                             icon={
                                 <UserOutlined/>}
                             name={t('With completed reviews')}
                             value={evaluatorsData?.done_evaluations}/></Col>
            <Col xs={24} sm={12} lg={8}>
                <MetricsCard color={dust_red_7}
                    loading={isLoadingEvaluatorsData}
                             icon={
                                 <ClockCircleOutlined/>}
                             name={t('With pending reviews')}
                             value={evaluatorsData?.pending_evaluations}/></Col>
               <Col  xs={24} sm={24} lg={8}>
            <MetricsCard
                color={neutral_8}
                loading={isLoadingEvaluatorsData}
                icon={<RemixIcon remixIconName={'ri-spam-3-fill'}/>}
                name={t('Invalid evaluations')}
                value={evaluatorsData?.invalid_evaluations}
            />
        </Col>

        </Row>


    )
}