import React from 'react';
import {Empty as EmptyAnt, Space} from "antd";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
// import {ReactComponent as EmptyIcon} from "../../assets/icons/empty_icon.svg";


// const StyledEmptyDescription = styled.span`
//   color: ${gray};
//
// `
//
// const StyledEmptyIcon = styled(EmptyIcon)`
//
//   width: 64px;
//   height: 40px;
//   //transform: translateY(35px);
// `

//
// const StyledEmpty = styled(EmptyAnt)`
//
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
// `

const StyledEmpty = styled(EmptyAnt)`
    margin: 0;
    .ant-empty-description{
        text-align: ${({alingText}) => alingText};
    }
    background: ${({white}) => white && 'white'};
    ${({white}) => {
        if(white){
            return `
            
                background: white;
                padding-top:32px; 
                padding-bottom:32px; 
            `
        }
    }}
   
`

export default function Empty({children, white=false, alingText='center',description='There are no data yet.', title='', ...props}) {
    const {t} = useTranslation();
    return (
        <StyledEmpty
            white={white}
            alingText={alingText}
            description={
                <Space direction='vertical' size={0}>
                    {t(title)}
                    {t(description)}
                </Space>
            }

            {...props}>
            {children && children}
        </StyledEmpty>
    )
}