import React from 'react';
import WideSpace from '../../components/layout/WideSpace';
import { useTranslation } from 'react-i18next';
import PasswordChangeForm from './components/PasswordChangeForm';
import EmailChangeForm from './components/EmailChangeForm';

export default function SecurityProfilePage() {
  const {t} = useTranslation();
  return (
    <WideSpace direction="vertical" size="large">
      <EmailChangeForm />
      <PasswordChangeForm />
    </WideSpace>
  );
}