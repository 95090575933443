import React, {useEffect, useState} from 'react';
import {Form, Input, Space, Typography} from "antd";
import TextAreaFormField from "../../../../../../data_entry/form/TextAreaFormField";
import {useTranslation} from "react-i18next";
import {useRules} from "../../../../../../../hooks/useRules";
import {AttachmentDraggerNewUploader,} from "../../../../../../data_entry/AttachmentUploader";
import {convertToFormData, getFiles} from "../../../../../../../../modules/project/utils/utils";
import api from "../../../../../../../services/api";
import {BuildFieldErrorsDict} from "../../../../../../../utils/Utils";
import {ToastNotification} from "../../../../../../feedback/ToastNotification";
import { ACCEPT_REQUEST_FORMATS } from '../../../../../../../utils/Constants';
import useFileUpload from "../../../../../../../hooks/useFileUpload";


const {Text} = Typography

export default function RequestForm({request, form, attachmentForm, setOpen, fetchRequests}) {

    const {t} = useTranslation();
    const {IS_REQUIRED} = useRules();
    const [loading, setLoading] = useState(false);

    const [attachments, setAttachments] = useState([]);
    const handleFilesChange = (data) => {
        setAttachments(getFiles(data));
    }

    const handleAttachmentSubmit = async (data) => {
        try {
            const {id, ...rest} = data
            const calls = [];
            for (const path of rest.path) {
                const data = convertToFormData({
                    path,
                    name: path.name
                });
                calls.push(api.post(`/file_upload/content/diligenceresponse/object/${id}/upload/`, data));
            }
            await Promise.all(calls);

        } catch (error) {
            ToastNotification(t("File(s) couldn't be uploaded"), "erro");
        }
    }

    const fixData = (data) => {
        data['diligence'] = request?.id
        return data
    }

    const handleSubmit = async (data) => {
        try {
            setLoading(false);
            data = fixData(data)
            data['files_upload'] = data.files_upload.map(({pk})=> pk)

            const response = await api.post(`/diligence_responses`, data);
            // await attachmentForm.setFieldValue('id', response.data.id) // fazer uploads, só que pode dar erro no upload
            // await attachmentForm.submit();
            setOpen(false)
            ToastNotification(t("Request successfully applied!"), "success");
            await fetchRequests()
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setLoading(true);
        }
    }

    return (
        <>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <TextAreaFormField
                    form={{
                        name: "description",
                        label: t("Request answer"),
                        rules: [IS_REQUIRED],
                    }}
                    data-cy="request-description-input"
                />
            {/*<Form form={attachmentForm} layout="vertical" onFinish={handleAttachmentSubmit}>*/}
            {/*    <Form.Item name={"id"} hidden><Input/></Form.Item>*/}
                <Form.Item
                    label={
                        <Space size={4}>
                            <Text>{t("Add attachment")}</Text>
                            <Text type="secondary" style={{fontSize: 12}}>(pdf, png, jpeg, jpg, xlsx) 10MB</Text>
                        </Space>
                    }
                    name="files_upload"
                    valuePropName={"fileList"}
                    initialValue={attachments}
                    rules={request?.type_dilig === "file" ? [IS_REQUIRED] : []}
                >
                    <AttachmentDraggerNewUploader
                        onChange={handleFilesChange}
                        MAX_TOTAL_SIZE={100}
                        accept={ACCEPT_REQUEST_FORMATS}
                        data-cy="request-attachment-input"

                    />
                </Form.Item>
            </Form>
            {/*</Form>*/}
        </>
    );
}

