import React, {useState} from 'react';
import FormInteraction
    from "./Programs/TabBarProgram/InteractionProgram/NewInteraction/FormInteraction";
import {useParams} from "react-router-dom";
import {getProgram} from "../global/utils/_query";
import {useTranslation} from "react-i18next";

function Interaction() {
    const [program, setProgram] = useState({public: true})
    const {t} = useTranslation();
    let {id, id_interaction} = useParams()
    React.useEffect(() => {
        getProgram(id, null, setProgram)
    }, []);
    return (
        <>
            {/*<HeaderPage/>*/}
            <FormInteraction/>
        </>
    );
}

export default Interaction;