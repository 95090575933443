import React from "react";
import DefaultComponent
    from "../../PhaseFormBuilder/FormComponents/DefaultComponent";
import {useTranslation} from "react-i18next";
import styled from 'styled-components';
import YesNoInputForm from "../../../Form/YesNoInputForm";

const InfoContainer = styled.span`
  font-size: 12px;
  line-height: 22px;
`
const Color = styled.span`
  ${({color}) => {
      if(color){
          return `color:${color}`;
      }
  }}
  
`

function YesNoForm(props) {
    const {t} = useTranslation()
    return (
        <DefaultComponent showChildren={true} {...props}>
            <YesNoInputForm />
            <InfoContainer>
                {t("Final result format")}: <Color color={'#52C41A'}>100% {t('yes')}</Color> <Color color={'#FAAD14'}>{t("50% to 99%")} {t('yes')}</Color> <Color color={'#FF4D4F'}>{t("0% to 50%")} {t('yes')}</Color>
            </InfoContainer>
        </DefaultComponent>
    )
}

export default YesNoForm