import React from 'react';
import styled from 'styled-components';


const ImageContainer = styled.img`
  display: block;
  max-width: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 50%;
  background: ${({background}) => background ? background : 'transparent'};

  ${({size}) => {
    if (size) {
      return `
            width: ${size}px;
            height: ${size}px;
          `
    }
  }}
`

export default function CircularImage({
                                          size,
                                          background,
                                          ...props
                                      }) {
    return (

        <ImageContainer background={background} size={size} {...props}/>
    )
}