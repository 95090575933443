import React from 'react'

import './style.css'
import RequiredAsterisk from "../../RequiredAsterisk";

function DateInput(
    {onChange, inputId, inputName, textLabel, required, textAlert, inputValue, defaultValue, elementDescription}) {
    return (
        <div className={'input-container'}>
            <label htmlFor={inputId}>{textLabel}{required ?  <RequiredAsterisk/>: ''}</label>
             {elementDescription}
            <input id={inputId} required={required} type='date' onChange={onChange}
                   name={inputName} value={inputValue} defaultValue={defaultValue}/>
            {textAlert ?
                <p className={'input-alert'}>{textAlert}</p> : <></>}
        </div>
    )
}

export default DateInput;