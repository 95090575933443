import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import api from "axios";
import {BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import FactoryDashboardProgramPhaseCharts
    from "./FactoryDashboardProgramPhaseCharts";
import {PHASE} from "../../../../../global/utils/Constants";
import SolvCollapse from "../../../../../global/components/misc/SolvCollapse";


export default function SectionDashboardProgramPhases({filters}) {
    let {id} = useParams();
    const {t} = useTranslation();
    const [phases, setPhases] = useState([])
    const [isLoading, setIsLoading] = useState()

    useEffect(() => {
        const fetchPhases = async () => {
            try {
                setIsLoading(true)
                const response = await api.post(`/phase_kamban/kamban_get_phases/${id}`, filters,{version: 'v2'});
                setPhases(response.data)

            } catch (error) {
                BuildFieldErrorsDict(error, null, false)

            } finally {

                setIsLoading(false)
            }
        };
        fetchPhases();
    }, []);

    return (
        <>
            {
                phases.filter(({type}) => type !== PHASE.INFORMATION).map((phase) => (
                    <SolvCollapse
                        title={t(phase.title)}
                        bordered

                    >
                        <FactoryDashboardProgramPhaseCharts
                            phase={phase}/>
                    </SolvCollapse>
                ))
            }
        </>

    );
}