import {DragDropContext} from 'react-beautiful-dnd';
import NotContent from "../../NotContent/NotContent";
import EvaluatorCard from "./EvaluatorCard";
import ApplicationCard from "./ApplicationCard";
import React, {useEffect, useRef, useState} from "react";
import DroppableComponent from "../Allocation/DragDropComponents/droppable";
import DraggableComponent from "../Allocation/DragDropComponents/draggable";
import api from "../../../global/services/api";
import Loading from "../../../global/components/feedback/Loading";

import {Link, useParams} from "react-router-dom";
import OffCanvasComponent from "../../Offcanvas";
import Select from "react-select";
import _ from "lodash";
import styled from "styled-components";
import ModalAllocationsEvaluator from "./ModalAllocationsEvaluator";
import {useAutoSaveContext} from "../../../global/context/AutoSaveContext";
import FilterQuestions
    from "../../Application/FormQuestionList/FilterQuestions";
import InfiniteScroll from 'react-infinite-scroller';
import {ReactComponent as FilterIcon} from '../../../assets/Icon/filter.svg'
import {FilterTitle} from "../../Filters/StyledFilters";
import FormSelect from "../../Form/FormSelect/Index";
import {BuildFieldErrorsDict} from "../../../global/utils/Utils";
import {useTranslation} from "react-i18next";
import CheckBox from "../../Form/CheckBox";
import ShowNumberList from "../../Pagination/ShowNumberList/ShowNumberList";
import SelectCountList from "../../Pagination/SelectCountList/SelectCountList";
import {confirmAlert} from "react-confirm-alert";
import ButtonDefault from "../../Buttons/ButtonDefault";
import {
    ButtonContainer,
    ConfirmContainer,
    Body,
    Header,
    Close
} from "./StyledAllocationComponents";
import Title from "../../Title/Title";
import {ReactComponent as CloseIcon} from "../../../assets/Icon/clear.svg";
import FilterButtons from "../../Filters/FilterButtons";
import ApplicationsHistorySectorsFilters
    from "../../Application/FiltersComponent/ApplicationsHistorySectorsFilters";
import {
    ToastNotification
} from "../../../global/components/feedback/ToastNotification";
import {
    FlexContainer
} from "../../../global/components/authentication/StyledComponents";
import Button from "../../../global/components/data_entry/Button";
import {
    useLayoutContext
} from "../../../modules/project/components/layout/context/LayoutContext";
import {SUCCESS} from "../../../global/utils/fileWithMessage";
import IconButton from '../../IconButton/Index';
import { DeleteOutlined } from '@ant-design/icons';


const TitleName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-height: 32px;
  width: 220px;
`

const HeaderDroppableCenter = styled.div`
  width: 100%;
  padding: 16px 10px 6.61px 14px;
  display: flex;
  align-items: center;
  z-index: 2;

  & > :first-child {
    width: min-content;
  }
`

const TitleDroppable = styled.h6`
  font-size: 22px !important;
  font-weight: 500;

  text-align: center;
  width: 100%;
  margin: 0;
`

export const ShowNumberListContainer = styled.div`
  display: flex;
  justify-content: ${({selected}) => selected === 0 ? 'end' : 'space-between'};
  margin-left: 15px;
  margin-right: 15px;
`

const FilterButtom = styled(FilterIcon)`
  color: ${(props) => props.fill ? props.fill : '#4C4C4C'};

  &:hover {
    cursor: pointer;
  }
`

const StyledLink = styled(Link)`
  color: var(--active_menu_color);
`

function AllocationComponent(props) {
    let {id, phase_id} = useParams();
    const {t} = useTranslation()
    const formQuestions = useRef(null);
    const [activatedApplication, setActivatedApplication] = useState(null)
    const [activatedEvaluator, setActivetedEvaluator] = useState(null)
    const [evaluatorsSelecteds, setEvaluatorsSelecteds] = useState([])
    const [applicationsSelecteds, setApplicationsSelecteds] = useState([])

    const [allocationsSelecteds, setAllocationsSelecteds] = useState([])
    const [loadingAllocation, setLoadingAllocation] = useState(false)
    const [loadingApplications, setLoadingApplications] = useState(false)
    const [loadingEvaluators, setLoadingEvaluators] = useState(false)

    const [applications, setApplications] = useState([]);
    const [evaluators, setEvaluators] = useState([]);
    const [allocations, setAllocations] = useState([])

    //FILTERS
    const [isOrdenedEvaluators, setIsOrdenedEvaluators] = useState(true)
    const [orderEvaluators, setOrderEvaluators] = useState('name')
    const [orderApplications, setOrderApplications] = useState('name')

    const [evaluatorFilter, setEvaluatorFilter] = useState('all')
    const [applicationFilter, setApplicationFilter] = useState('all')

    const [showEvaluatorsFilters, setShowEvaluatorsFilters] = useState(false);
    const [showApplicationsFilters, setShowApplicationsFilters] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [showClearFilters, setShowClearFilters] = useState(false);
    const [resetFilters, setResetFilters] = useState(false);
    const [challenges, setChallenges] = useState([])
    //MODAL
    //modal evaluator
    const [showEvaluatorModal, setShowEvaluatorModal] = useState(false);
    const [currentEvaluatorInfo, setCurrentEvaluatorInfo] = useState(null);


    //INFINITE SCROLL
    const [totalEvaluators, setTotalEvaluators] = useState(0)
    const [totalApplications, setTotalApplications] = useState(0)
    const [urlMoreEvaluators, setUrlMoreEvaluators] = useState('')
    const [hasMoreEvaluators, setHasMoreEvaluators] = useState(true)
    const [urlMoreApplications, setUrlMoreApplications] = useState('')
    const [hasMoreApplications, setHasMoreApplications] = useState(true)

    const [dragedInSelecteds, setDragedInSelecteds] = useState(false)
    const [isLoadingRelocate, setIsLoadingRelocate] = useState(false)
    const {updateLayoutContent} = useLayoutContext()
    const [filterDataApplications, setFilterDataApplications] = useState([])
    const {
        showAutoSave,
        hideAutoSave,
        startSave,
        setAutoSaveSuccess,
        setAutoSaveError
    } = useAutoSaveContext()

    useEffect(() => {
        showAutoSave()
        return () => {
            hideAutoSave()
        };
    }, [])

    useEffect(() => {
        showClearFilters === false && getApplications()
        setResetFilters(false)
    }, [showClearFilters])

    const getEvaluators = async () => {
        try {
            setLoadingEvaluators(true)
            let url = urlMoreEvaluators || `/evaluators/${phase_id}`
            const response = await api.get(url)
            const newArray = [...evaluators, ...response.data.results]
            setEvaluators(newArray)
            response.data.count !== totalEvaluators && setTotalEvaluators(response.data.count)
            let next = response.data.next
            setHasMoreEvaluators(!!next)
            setUrlMoreEvaluators(next)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setLoadingEvaluators(false)
        }
    }

    const getApplications = async () => {
        if (hasMoreApplications) {
            try {
                setLoadingApplications(true)
                let url = urlMoreApplications || `/applications_allocation/${phase_id}`
                const response = await api.get(url)
                const newArray = urlMoreApplications ? [...applications, ...response.data.results] : response.data.results
                setApplications(newArray)
                let next = response.data.next
                response.data.count !== totalApplications && setTotalApplications(response.data.count)
                setHasMoreApplications(!!next)
                setUrlMoreApplications(next)
            } catch (error) {
                BuildFieldErrorsDict(error, null, false)
            } finally {
                setLoadingApplications(false)
            }
        }
    };

    const getChallenges = async () => {
        try {
            const response = await api.get(`programs/${id}/challenges`);
            let aux = []
            response.data.map((option) => {
                aux.push({value: option.id, label: option.name})
            })
            setChallenges(aux);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        }
    };

    const getQuestions = async () => {
        try {
            const response = await api.get(`phases/${phase_id}/get_filter_question_phase`);
            setQuestions(response.data);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        }
    };

    const fetchEvalutorInfo = async (evaluator) => {
        setCurrentEvaluatorInfo(null)
        try {
            setShowEvaluatorModal(true)
            let url = `/members/get_modal_information/evaluator/${evaluator.id}/phase/${phase_id}`
            const response = await api.get(url);
            setCurrentEvaluatorInfo({
                'evaluator': evaluator,
                'assessments': response.data.assessments
            });
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

            setShowEvaluatorModal(false)
        }
    }


    useEffect(() => {
        getQuestions();
        getChallenges();
    }, []);


    useEffect(() => {
        sortEvaluators()
    }, [orderEvaluators]);


    useEffect(() => {
        if (!isOrdenedEvaluators && evaluators.length > 0) {
            setIsOrdenedEvaluators(true)
            sortEvaluators()
        }
    }, [evaluators, isOrdenedEvaluators]);


    const openFiltersEvaluators = () => {
        setShowEvaluatorsFilters(true)
    }

    const openFiltersApplications = () => {
        setShowApplicationsFilters(true)
    }

    const checkFilterEvaluator = (evaluator) => {
        switch (evaluatorFilter) {
            case 'all':
                return true
            case 'no-applications':
                return evaluator.count_applications_phase === 0
            case 'has-applications':
                return evaluator.count_applications_phase > 0
            default:
                break;
        }
    }

    const checkFilterApplication = (application) => {
        switch (applicationFilter) {
            case 'all':
                return true
            case 'no-evaluators':
                return application.get_count_assassment_current_phase === 0
            case 'has-evaluators':
                return application.get_count_assassment_current_phase > 0
            default:
                break;
        }
    }

    const _comparation = (a, b) => {
        if (a > b) {
            return 1
        }
        if (a < b) {
            return -1
        }
        return 0
    }
    const sortEvaluators = () => {
        switch (orderEvaluators) {
            case 'name':
                evaluators.sort((a, b) => {
                    let info_a = a.full_name.toLowerCase()
                    let info_b = b.full_name.toLowerCase()
                    return _comparation(info_a, info_b)
                })
                break;
            case 'more':
                evaluators.sort((a, b) => {
                    let info_b = b.count_applications_phase
                    let info_a = a.count_applications_phase
                    return _comparation(info_b, info_a) //reverse order
                })
                break;
            case 'less':
                evaluators.sort((a, b) => {
                    let info_a = a.count_applications_phase
                    let info_b = b.count_applications_phase
                    return _comparation(info_a, info_b)
                })
                break;
            default:
                break;
        }
        setEvaluators([...evaluators])
    }

    useEffect(() => {
        if (activatedApplication) {
            getApplicationAllocations(activatedApplication.application_id)
            setActivetedEvaluator(null)
        } else {
            setEvaluatorsSelecteds([])
            setAllocationsSelecteds([])
            setAllocations([])
        }
    }, [activatedApplication])

    useEffect(() => {
        if (activatedEvaluator) {
            getEvaluatorAllocations(activatedEvaluator.id)
            setActivatedApplication(null)
        } else {
            setApplicationsSelecteds([])
            setAllocationsSelecteds([])
        }
    }, [activatedEvaluator])

    const getApplicationAllocations = async (id) => {
        try {
            setLoadingAllocation(true)
            const response = await api.get(`/evaluator_application_phase/get_association_by_applications/${id}/phase/${phase_id}`);
            setAllocations(response.data)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
            setAllocations([])
        } finally {
            setLoadingAllocation(false)
        }
    }

    const getEvaluatorAllocations = async (id_evaluator) => {
        try {
            setLoadingAllocation(true)
            const response = await api.get(`/evaluator_application_phase/get_association_by_evaluator/${id_evaluator}/phase/${phase_id}`);
            setAllocations(response.data)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
            setAllocations([])
        } finally {
            setLoadingAllocation(false)
        }
    }

    const submitApplicationsAllocateds = async (result) => {
        try {
            startSave()

            let association_json = {
                'evaluator': activatedEvaluator.id,
                'applications': JSON.stringify(getListIDsAssociated(result)),
                'phase': phase_id
            }
            let response = await api.post('/evaluator_application_phase/association_by_applications', association_json)
            refreshCounts(response.data)
            handleWarning(response.data)
            setAutoSaveSuccess()
            getEvaluatorAllocations(activatedEvaluator.id)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
            setAutoSaveError()
            setAllocations([...allocations])
        }
    }


    const submitEvaluatorsAllocateds = async (result) => {
        try {
            startSave()
            let association_json = {
                'application': activatedApplication.application_id,
                'evaluators': JSON.stringify(getListIDsAssociated(result)),
                'phase': phase_id
            }
            let response = await api.post('/evaluator_application_phase/association_by_evaluators', association_json)
            refreshCounts(response.data)
            handleWarning(response.data)
            setAutoSaveSuccess()
            getApplicationAllocations(activatedApplication.application_id)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
            setAutoSaveError()
            setAllocations([...allocations])

        }
    }

    const updateEvaluatorCount = (refreshed_counters, removed = false) => {

        let new_evaluators = [...evaluators]
        refreshed_counters.forEach((obj) => {

            let index = _.findIndex(evaluators, function (o) {
                return o.id === obj.id;
            });
            if (index !== -1) {
                if (removed) {
                    new_evaluators[index].count_applications_phase = new_evaluators[index].count_applications_phase - 1
                } else {
                    new_evaluators[index].count_applications_phase = obj.application_count_evaluators
                }
            }
        })

        setEvaluators(new_evaluators)
    }

    const updateApplicationCount = (refreshed_counters, removed = false) => {
        let new_applications = [...applications]
        refreshed_counters.forEach((obj) => {
            let index = _.findIndex(applications, function (o) {
                return o.id === obj.id;
            });
            if (index !== -1) {
                if (removed) {
                    new_applications[index].get_count_assassment_current_phase = new_applications[index].get_count_assassment_current_phase - 1

                } else {
                    new_applications[index].get_count_assassment_current_phase = obj.applications_count_evaluator
                }
            }
        })
        setApplications(new_applications)
    }
    const handleWarning = (json) => {
        json.warning && ToastNotification(json.warning, 'warning')
    }
    const refreshCounts = (json) => {
        let returned_counts = json.dict_return
        if (activatedEvaluator) {
            updateEvaluatorCount([{
                'id': activatedEvaluator.id,
                'application_count_evaluators': returned_counts.evaluator_count_applications
            }])
            updateApplicationCount(returned_counts.applications)
        } else {
            updateApplicationCount([{
                'id': activatedApplication.id,
                'applications_count_evaluator': json.dict_return.application_count_evaluators
            }])
            updateEvaluatorCount(json.dict_return.evaluators)

        }
    }

    const selectionEvaluator = (value) => {
        setAllocationsSelecteds([])
        setAllocations([])
        activatedEvaluator && activatedEvaluator.id === value.id ? setActivetedEvaluator(null) : setActivetedEvaluator(value)
    }
    const selectionApplication = (value) => {
        setAllocationsSelecteds([])
        setAllocations([])
        activatedApplication && activatedApplication.id === value.id ? setActivatedApplication(null) : setActivatedApplication(value)
    }

    const addSelectedApplication = (value) => {
        const auxlist = [...applicationsSelecteds, value];
        setApplicationsSelecteds(auxlist)
    }

    const addSelectedEvaluator = (value) => {
        const auxlist = [...evaluatorsSelecteds, value];
        setEvaluatorsSelecteds(auxlist)
    }

    const addSelectedAllocation = (value) => {
        const auxlist = [...allocationsSelecteds, value];
        setAllocationsSelecteds(auxlist)
    }

    const removeSelectedEvaluator = (value) => {
        const auxlist = evaluatorsSelecteds.filter(item => item !== value);
        setEvaluatorsSelecteds(auxlist)
    }

    const removeSelectedApplication = (value) => {
        const auxlist = applicationsSelecteds.filter(item => item !== value);
        setApplicationsSelecteds(auxlist)
    }

    const removeSelectedAllocation = (value) => {
        const auxlist = allocationsSelecteds.filter(item => item !== value);
        setAllocationsSelecteds(auxlist)
    }

    const onChangeApplicationSelection = (checkbox) => {
        let value = parseInt(checkbox.value)
        checkbox.checked ? addSelectedApplication(value) : removeSelectedApplication(value)
    }

    const onChangeEvaluatorSelection = (checkbox) => {
        let value = parseInt(checkbox.value)
        checkbox.checked ? addSelectedEvaluator(value) : removeSelectedEvaluator(value)
    }
    const onChangeAllocationSelection = (checkbox) => {
        let value = parseInt(checkbox.value)
        checkbox.checked ? addSelectedAllocation(value) : removeSelectedAllocation(value)
    }

    const selectAllEvaluators = (checkbox) => {
        let aux_list = []
        checkbox.checked && evaluators.forEach((evaluator) => {
            aux_list.push(evaluator.id)
        })
        setEvaluatorsSelecteds(aux_list)
    }
    const selectAllApplications = (checkbox) => {
        let aux_list = []
        checkbox.checked && applications.forEach((application) => {
            aux_list.push(application.id)
        })
        setApplicationsSelecteds(aux_list)
    }
    const selectAllAllocations = (checkbox) => {
        let aux_list = []
        checkbox.checked && allocations.forEach((allocation) => {
            if (allocation.evaluator_evaluation_status_id === 1) {
                aux_list.push(allocation.id)
            }
        })
        setAllocationsSelecteds(aux_list)
    }
    const copy = (source, destination, droppableSource, droppableDestination, selecteds) => {
        const sourceClone = Array.from(source);
        const destClone = Array.from(destination);
        selecteds.forEach((item_index, for_index) => {
            let [copy] = [sourceClone[item_index]];
            !isObjAllocated(copy, destClone) && destClone.splice(destClone.length, 0, copy);
        })
        // let [copy] = sourceClone.splice(droppableSource.index, 1);
        // destClone.splice(droppableDestination.index, 0, createJsonComponent(copy.type));
        return destClone;
    };

    const handleConfirmDeleteAllocation = () => {
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <ConfirmContainer>
                        <Header>
                            <Title noCamel={true}>{t("WARNING")}</Title>
                            <Close onClick={() => {
                                onClose();
                            }}>
                                <CloseIcon/>
                            </Close>
                        </Header>
                        <Body>
                            <p>{t("Are you sure you want to remove the selected evaluations? Please remember that by doing this, the applications will no longer be evaluated by this evaluator. However, you can still reinsert the applications for them to evaluate again.")}</p>
                        </Body>
                        <ButtonContainer>
                            <ButtonDefault onClick={onClose}
                                           action={'secondary'}
                                           label={t("Cancel")}/>
                            <ButtonDefault
                                onClick={() => {
                                    removeSelectedAllocations();
                                    onClose();
                                }}
                                action={'primary'}
                                label={t("Remove")}
                                data-cy={"confirm-remove-button"}
                            />
                        </ButtonContainer>
                    </ConfirmContainer>
                );
            }
        });
    };

    const removeSelectedAllocations = () => {
        const newAllocations = allocations.filter(
            (allocation)=> {
                if (!allocationsSelecteds.includes(allocation.id) && allocation.evaluator_evaluation_status_id === 1) {
                    activatedEvaluator ? updateApplicationCount([{id: allocation.id}], true) : updateEvaluatorCount([{id: allocation.id}], true)
                    return true;
                }
                return false;
            }

        )
        setAllocationsSelecteds([])
        setAllocations([...newAllocations])
        
        activatedEvaluator ? submitApplicationsAllocateds([...newAllocations]) : submitEvaluatorsAllocateds([...newAllocations])
    }

    const remove = (index, pk) => {
        allocations.splice(index, 1)
        activatedEvaluator ? updateApplicationCount([{id: pk}], true) : updateEvaluatorCount([{id: pk}], true)
        removeSelectedAllocation(pk)
        setAllocations([...allocations])
        activatedEvaluator ? submitApplicationsAllocateds([...allocations]) : submitEvaluatorsAllocateds([...allocations])

    }

    const getListIDsAssociated = (allocations) => {
        let result = []
        allocations.forEach((allocation) => (
            result.push(allocation.application_id ? allocation.application_id : allocation.id)
        ))
        return result
    }

    const getSelectedsIndex = (origin, selecteds_pks, source_index) => {
        let out_list = []
        if (selecteds_pks.length === 0 || !dragedInSelecteds) {
            out_list.push(source_index)
        } else {
            selecteds_pks.forEach((pk) => {
                origin.forEach((item, index) => {
                    if (item.id === pk) {
                        out_list.push(index)
                        return;
                    }
                })
            })
        }
        return out_list
    }
    const onDragStart = result => {
        const {source, draggableId} = result;
        id = parseInt(draggableId.split('-')[1])
        if (source.droppableId === 'applications') {
            setDragedInSelecteds(applicationsSelecteds.includes(id))
        } else {
            setDragedInSelecteds(evaluatorsSelecteds.includes(id))
        }
    }

    const onDragEnd = result => {
        const {source, destination} = result;
        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId !== destination.droppableId) {
            let origin
            let selecteds = []
            if (source.droppableId === 'applications') {
                origin = applications
                selecteds = getSelectedsIndex(origin, applicationsSelecteds, source.index)
                let result = copy(origin, allocations, source, destination, selecteds);
                submitApplicationsAllocateds(result)
                setAllocations(result);

            } else {
                origin = evaluators
                selecteds = getSelectedsIndex(origin, evaluatorsSelecteds, source.index)
                let result = copy(origin, allocations, source, destination, selecteds);
                submitEvaluatorsAllocateds(result)
                setAllocations(result);

            }

        }
    };
    const isObjAllocated = (json, list) => {
        let exists = false
        if (json) {
            list.forEach((allocation) => {
                if (_.isEqual(allocation.id, json.id)) {
                    if (_.isEqual(allocation.full_name, json.full_name) || _.isEqual(allocation.team, json.team)) {
                        exists = true
                        return
                    }
                }
            })
        }
        return exists
    }


    const renderAllocations = () => {
        return (
            allocations.length === 0 ?
                    <div >
                        <NotContent title={activatedEvaluator === null ? t("Drag an evaluator to here") : t("Drag an application to here")}/>
                    </div>
                    : <div style={{
                        width: "100%",
                        padding: 8,
                        marginTop: 4,
                        position: 'relative'
                    }}

                    >

                        {allocations.map((allocation, index) =>
                            <DraggableComponent
                                id={'allocation-' + index}
                                index={index}
                                key={'allocation-' + index}
                                isMultipleSelected={allocationsSelecteds.length}
                                isSelected={allocationsSelecteds && allocationsSelecteds.id === allocation.id}
                                isDisabled={true}
                                render_clone={false}
                            >
                                {activatedEvaluator ?
                                    <div data-cy="draggable-allocation-card" style={{width: '100%'}}>
                                        <ApplicationCard
                                            name={"center_application_"}
                                            phase={props.phase}
                                            key={'allocation-' + index}
                                            application={allocation}
                                            index={index}
                                            showCheckbox={true}
                                            selectionApplication={()=>{}}
                                            selected={allocationsSelecteds.includes(allocation.id)}
                                            onChangeApplicationSelection={onChangeAllocationSelection}
                                            active={activatedEvaluator}
                                            refreshCounts={refreshCounts}
                                            getAllocation={getEvaluatorAllocations}
                                            refreshApplications={getApplications}
                                        />
                                    </div>
                                    :
                                    <div data-cy="draggable-allocation-card" style={{width: '100%'}}>
                                        <EvaluatorCard
                                            name={"center_evaluator_"}
                                            phase={props.phase}
                                            key={'allocation-' + index}
                                            evaluator={allocation}
                                            index={index}
                                            showCheckbox={true}
                                            selectionEvaluator={() => {}}
                                            selected={allocationsSelecteds.includes(allocation.id)}
                                            onChangeEvaluatorSelection={onChangeAllocationSelection}
                                            openModal={fetchEvalutorInfo}
                                            active={activatedApplication}
                                            refreshCounts={refreshCounts}
                                            getAllocation={getApplicationAllocations}
                                            data-cy="allocation-draggable-card"
                                        />
                                    </div>
                                }
                            </DraggableComponent>
                        )}
                    </div>
        )
    }

    const handleClearFilters = () => {
        setFilterDataApplications({})
        setHasMoreApplications(true)
        setUrlMoreApplications('')
        setResetFilters(true)
        setShowClearFilters(false)
    }


    const showFilterButtons = () => {
        let formData = new FormData(formQuestions.current);
        //VALIDACAO SE POSSUI DADOS PRA REALIZAR A REQUISICAO
        let tags_to_remove = []
        let contador = 0
        for (var pair of formData.entries()) {
            contador = contador + 1;
            if (!pair[1]) {
                tags_to_remove.push(pair[0]);
            }
        }
        tags_to_remove.forEach((tag) => {
            formData.delete(tag)
        })
        if (contador - tags_to_remove.length > 1) {
            setShowClearFilters(true)
            setFilterDataApplications(formData)
        } else {
            setFilterDataApplications(null)
        }
    }

    const handleSubmitFilterApplications = async (ordening = false) => {
        if (filterDataApplications == null) {
            handleClearFilters()
            return
        }

        try {
            setLoadingApplications(true)
            // let response = await api.post(`phases/${phase_id}/application_allocation?page_size=1000&orderning=${ordening || orderApplications}`, filterDataApplications);
            let response = await api.post(`phases/${phase_id}/history_allocation?page_size=1000&orderning=${ordening || orderApplications}`, filterDataApplications);
            setHasMoreApplications(false)
            if (response.data.length === 0) {
                ToastNotification('No applications with this filters', 'error');
            } else {
                ToastNotification('Success');
            }
            !isObjAllocated(activatedApplication, response.data) && setActivatedApplication(null)
            setApplications(response.data)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setLoadingApplications(false)
        }
    }

    const filterOptions = [{
        value: 'name',
        label: `${t('Name (A-Z)')}`
    }, {value: '-name', label: `${t('Name (Z-A)')}`}, {
        value: 'more',
        label: `${t('More evaluators')}`
    }, {
        value: 'less',
        label: `${t('Less evaluators')}`
    }]

    function delay(delayInms) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(2);
            }, delayInms);
        });
    }

    function getTitle() {
        return activatedEvaluator ? `${activatedEvaluator.full_name}` : activatedApplication.project_name
    }

    async function handleRelocate() {
        try {
            setIsLoadingRelocate(true)
            const response = await api.get(`/phase/realocate_evaluators/${phase_id}`, {version: 'v2'});
            ToastNotification(SUCCESS)
            updateLayoutContent()
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoadingRelocate(false)
        }
    }

    return (
        <>
            <DragDropContext ref={props.refContainer} onDragEnd={onDragEnd}
                             onDragStart={onDragStart}>
                <div style={{
                    width: "100%",
                    display: "flex",
                    // marginTop: 8
                }}>
                    <DroppableComponent id={'evaluators'} disabled_drop={true}>
                        <InfiniteScroll
                            loadMore={getEvaluators}
                            hasMore={!loadingEvaluators && hasMoreEvaluators}
                            useWindow={false}
                            threshold={150}
                            loader={<span key={'evaluator_loading'}/>}
                        >
                            <div style={{
                                background: 'white',
                                position: 'sticky',
                                top: 0, zIndex: 1
                            }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '16px 10px 0px 14px'
                                }}>
                                    <div>
                                        <CheckBox
                                            hidden={evaluators.length === 0 || !activatedApplication}
                                            options={[{
                                                id: 'evaluator_checkAll',
                                                checked: evaluatorsSelecteds.length === evaluators.length,
                                                onChange: (e) => selectAllEvaluators(e.target),
                                            }]}/>
                                    </div>


                                    <div className="titleAllocation"
                                         style={{
                                             width: "100%",
                                             display: "flex",
                                             flexWrap: "wrap",
                                             justifyContent: "space-between",
                                             zIndex: 1
                                         }}>
                                        {t("Evaluators")}
                                        <FlexContainer gap={8}
                                                       justify={'space-between'}>
                                            {props.program?.auto_allocation &&
                                                <Button
                                                    loading={isLoadingRelocate}
                                                    type={'default'}
                                                    onClick={handleRelocate}
                                                    data-cy="relocate-button">
                                                    {t('Relocate')}
                                                </Button>}
                                            <Link className="linkAllocation"
                                                  to={`/programs/${id}/settings`}
                                                  style={{fontSize: 12}}
                                                  data-cy="add-member-link">{t("Add member(s)")}</Link>
                                            <FilterButtom
                                                onClick={openFiltersEvaluators}/>
                                        </FlexContainer>

                                    </div>
                                </div>
                                <ShowNumberListContainer
                                    selected={evaluatorsSelecteds.length}>
                                    {evaluatorsSelecteds.length === 0 ? '' :
                                        <SelectCountList
                                            count_selected={evaluatorsSelecteds.length}/>}
                                    <ShowNumberList
                                        count_displayed={evaluators.length}
                                        count_total={totalEvaluators}/>
                                </ShowNumberListContainer>
                            </div>
                            {(evaluators.length === 0 && !hasMoreEvaluators) ?
                                (
                                    <div style={{
                                        marginTop: 40,
                                        textAlign: 'center'
                                    }}>
                                        <NotContent
                                            title={t("No evaluators avaible in this phase")}/>
                                        <StyledLink
                                            to={`/programs/${id}/settings`}
                                            data-cy="invite-evaluators-link">
                                              {t('Click here to invite evaluators')}
                                        </StyledLink>
                                    </div>
                                ) :
                                (
                                    <div style={{
                                        width: "100%",

                                        padding: 8,
                                        marginTop: 4,
                                        position: 'relative',
                                        display: "flex",
                                        flexDirection: "column"
                                    }}
                                        data-cy="draggable-evaluator-card-container"
                                    >
                                        {evaluators.map((evaluator, index) => checkFilterEvaluator(evaluator) &&
                                            <DraggableComponent
                                                id={'evaluator-' + evaluator.id}
                                                index={index}
                                                key={'evaluator-' + evaluator.id}
                                                isMultipleSelected={evaluatorsSelecteds.length}
                                                isSelected={activatedEvaluator === evaluator}
                                                isDisabled={!activatedApplication}
                                                isRelated={isObjAllocated(evaluator, allocations)}
                                                isDragedSelected={dragedInSelecteds}
                                                render_clone={true}
                                            >
                                                <div style={{width: '100%'}} data-cy={`draggable-evaluator-card-${evaluator.id}`}>
                                                    <EvaluatorCard
                                                        name={'evaluator'}
                                                        evaluator={evaluator}
                                                        selectionEvaluator={selectionEvaluator}
                                                        onChangeEvaluatorSelection={onChangeEvaluatorSelection}
                                                        selected={evaluatorsSelecteds.includes(evaluator.id)}
                                                        showCheckbox={!!activatedApplication}
                                                        openModal={fetchEvalutorInfo}
                                                        active={activatedApplication}
                                                        phase={props.phase}
                                                    />
                                                </div>
                                            </DraggableComponent>
                                        )}
                                        {loadingEvaluators && <Loading
                                            style={{height: evaluators.length === 0 ? 200 : 25}}/>}
                                    </div>
                                )


                            }
                        </InfiniteScroll>
                    </DroppableComponent>
                    <DroppableComponent id={'allocations'}>
                        <div data-cy="droppable-allocation-container">
                            <div style={{
                                background: 'white',
                                position: 'sticky',
                                top: 0, zIndex: 1
                            }}>
                                <HeaderDroppableCenter>

                                    <div>
                                        <CheckBox
                                            hidden={allocations.length === 0}
                                            options={[{
                                                id: 'center_checkAll',
                                                checked: allocationsSelecteds.length >= allocations.length,
                                                onChange: (e) => selectAllAllocations(e.target),
                                            }]}/>
                                    </div>


                                    <TitleDroppable

                                        id="foo">
                                        {activatedEvaluator === null && activatedApplication === null ? t("Evaluator or application")
                                            : (
                                                <>
                                                <span
                                                    title={getTitle()}>{getTitle()}</span>
                                                    {/*<span>{!loadingAllocation ? ` (${allocations.length})` : ''}</span>*/}
                                                </>

                                            )
                                        }
                                    </TitleDroppable>


                                    <div style={{
                                            visibility: allocationsSelecteds.length === 0 ? 'hidden' : 'visible'
                                        }}>
                                        {/* <IconButton type="Delete" onClick={() =>handleConfirmDeleteAllocation()}/> */}
                                        <Button
                                            loading={isLoadingRelocate}
                                            icon={<DeleteOutlined/>}
                                            type={'default'}
                                            danger={true}
                                            onClick={handleConfirmDeleteAllocation}
                                            data-cy="remove-button">
                                            {t('Remove')}
                                        </Button>
                                    </div>

                                </HeaderDroppableCenter>
                                
                                <ShowNumberListContainer
                                    selected={allocationsSelecteds.length}>
                                    {allocationsSelecteds.length === 0 ? '' :
                                        <SelectCountList
                                            count_selected={allocationsSelecteds.length}/>}
                                    <ShowNumberList
                                        count_displayed={allocations.length}
                                        count_total={allocations.length}/>
                                </ShowNumberListContainer>

                            </div>
                            
                            {loadingAllocation ?
                                <Loading/> : activatedEvaluator === null && activatedApplication === null ?
                                    <div style={{marginTop: 50, padding: 8}}>
                                        <NotContent
                                            title={t("Click on an evaluator or an application to get started")}/>
                                    </div> : renderAllocations()}

                        </div>
                    </DroppableComponent>
                    <DroppableComponent id={'applications'}
                                        disabled_drop={true}>
                        <InfiniteScroll
                            initialLoad={false}
                            loadMore={getApplications}
                            hasMore={!loadingApplications && hasMoreApplications}
                            useWindow={false}
                            threshold={150}
                            loader={<div key={'application_loading'}
                                         style={{height: 200}}/>}
                        >

                            <div style={{
                                background: 'white',
                                position: 'sticky',
                                top: 0, zIndex: 1
                            }}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '16px 10px 0px 14px',

                                }}>

                                    <div>
                                        <CheckBox
                                            hidden={applications.length === 0 || !activatedEvaluator}
                                            options={[{
                                                id: 'applic_checkAll',
                                                checked: applicationsSelecteds.length === applications.length,
                                                onChange: (e) => selectAllApplications(e.target),
                                            }]}/>
                                    </div>

                                    <div className="titleAllocation"
                                         style={{
                                             paddingLeft: 20,
                                             marginLeft: 10,
                                             zIndex: 1
                                         }}>{t("Applications")} <FilterButtom
                                        fill={showClearFilters && '#2581c4'}
                                        onClick={openFiltersApplications}/>
                                    </div>

                                </div>

                                <ShowNumberListContainer
                                    selected={applicationsSelecteds.length}>
                                    {applicationsSelecteds.length === 0 ? '' :
                                        <SelectCountList
                                            count_selected={applicationsSelecteds.length}/>}
                                    <ShowNumberList
                                        count_displayed={applications.length}
                                        count_total={totalApplications}/>
                                </ShowNumberListContainer>
                            </div>

                            {
                                (applications.length === 0 && !hasMoreApplications) ?
                                    <NotContent
                                        title={t("Don't have applications yet")}/>
                                    : <div style={{
                                        width: "100%",
                                        padding: 8,
                                        marginTop: 4,
                                        position: 'relative',
                                        display: "flex",
                                        flexDirection: "column"
                                    }}
                                        data-cy="draggable-application-container"
                                    >
                                        {applications.map((application, index) => checkFilterApplication(application) &&
                                            <DraggableComponent
                                                id={'application-' + application.id}
                                                index={index}
                                                key={'application-' + application.id}
                                                isMultipleSelected={applicationsSelecteds.length}
                                                isSelected={activatedApplication === application}
                                                isDisabled={!activatedEvaluator}
                                                isRelated={isObjAllocated(application, allocations)}
                                                isDragedSelected={dragedInSelecteds}
                                                // was_submitted={application.was_submitted}
                                                render_clone={true}>
                                                <div data-cy={`draggable-application-card-${application.id}`} style={{width: '100%'}}>
                                                    <ApplicationCard
                                                        name={"application_"}
                                                        phase={props.phase}
                                                        application={application}
                                                        showCheckbox={!!activatedEvaluator}
                                                        selectionApplication={selectionApplication}
                                                        selected={applicationsSelecteds.includes(application.id)}
                                                        onChangeApplicationSelection={onChangeApplicationSelection}
                                                        active={activatedEvaluator}/>
                                                </div>
                                            </DraggableComponent>
                                            )}
                                        {loadingApplications && <Loading
                                            style={{height: applications.length === 0 ? 200 : 25}}/>}
                                    </div>
                            }
                        </InfiniteScroll>
                    </DroppableComponent>
                </div>
            </DragDropContext>
            <OffCanvasComponent show={showEvaluatorsFilters}
                                handleClose={setShowEvaluatorsFilters}>
                <span style={{
                    fontSize: 18,
                    fontWeight: 500,
                    marginTop: -20,
                    marginBottom: 20
                }}>{t("Filter Evaluators")}</span>
                <span style={{
                    fontSize: 17,
                    fontWeight: 500
                }}>{t('Order by')}</span>
                <Select options={filterOptions}
                        onChange={(e) => setOrderEvaluators(e.value)}/>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 8,
                    marginBottom: 12
                }}
                     onChange={(event) => setEvaluatorFilter(event.target.value)}>
                    <span style={{
                        fontSize: 17,
                        fontWeight: 500,
                        marginTop: 5
                    }}>{t('Filter')}</span>
                    <div>
                        <input type="radio" value={'all'} readOnly={true}
                               checked={evaluatorFilter === 'all'}/>
                        <label style={{margin: '0 0 0 5px'}}>{t("All")}</label>
                    </div>
                    <div>
                        <input type="radio" value={'no-applications'}
                               readOnly={true}
                               checked={evaluatorFilter === 'no-applications'}/>
                        <label
                            style={{margin: '0 0 0 5px'}}>{t("No application")}</label>
                    </div>
                    <div>
                        <input type="radio" value={'has-applications'}
                               readOnly={true}
                               checked={evaluatorFilter === 'has-applications'}/>
                        <label
                            style={{margin: '0 0 0 5px'}}>{t("Has application")}</label>
                    </div>
                </div>
            </OffCanvasComponent>
            <OffCanvasComponent show={showApplicationsFilters}
                                handleClose={setShowApplicationsFilters}
                                isRight={true}
                                title={t('Filter applications')}>
                <FilterButtons showClearFilters={showClearFilters}
                               handleClearFilters={handleClearFilters}
                               handleApplyFilters={handleSubmitFilterApplications}/>
                <FormSelect onChange={(e) => {
                    setOrderApplications(e.value)
                    setShowClearFilters(true);
                    // handleSubmitFilterApplications(e.value)
                }}
                            id={'orderning'}
                            name={'orderning'}
                            options={filterOptions}
                            value={orderApplications}
                            label={t('Order by')}
                            multiple={false}/>

                <form ref={formQuestions}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                         onChange={(event) => {
                             setApplicationFilter(event.target.value);
                             setShowClearFilters(true);
                         }
                         }>
                        <FilterTitle>{t('Filters')}</FilterTitle>
                        <div>
                            <input type="radio" name={'evaluators_filter'}
                                   value={'all'} readOnly={true}
                                   checked={applicationFilter === 'all'}/>
                            <label
                                style={{margin: '0 0 0 5px'}}>{t("All")}</label>
                        </div>
                        <div>
                            <input type="radio" name={'evaluators_filter'}
                                   value={'no-evaluators'} readOnly={true}
                                   checked={applicationFilter === 'no-evaluators'}/>
                            <label
                                style={{margin: '0 0 0 5px'}}>{t("No evaluators")}</label>
                        </div>
                        <div>
                            <input type="radio" name={'evaluators_filter'}
                                   value={'has-evaluators'} readOnly={true}
                                   checked={applicationFilter === 'has-evaluators'}/>
                            <label
                                style={{margin: '0 0 0 5px'}}>{t("Has evaluators")}</label>
                        </div>
                    </div>
                    {resetFilters === false && <FormSelect onChange={() => {
                        setTimeout(showFilterButtons, 1)
                    }}
                                                           name={'application__challenge__id__in'}
                                                           options={challenges}
                                                           label={t('Challenges')}
                                                           multiple={true}/>
                    }
                    <ApplicationsHistorySectorsFilters
                        handleSubmitForm={showFilterButtons}
                        resetFilters={resetFilters}/>

                    {resetFilters === false &&
                        <FilterQuestions questions={questions}
                                         handleSubmitForm={showFilterButtons}/>}
                </form>
            </OffCanvasComponent>
            <ModalAllocationsEvaluator evaluatorInfo={currentEvaluatorInfo}
                                       showEvaluatorModal={showEvaluatorModal}
                                       setShowEvaluatorModal={setShowEvaluatorModal}/>


        </>
    )
}

export default AllocationComponent