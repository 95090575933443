import React from 'react';
import {Skeleton, Radio, Space} from "antd";
import {FlexContainer} from "../authentication/StyledComponents";
import useGenerateInputOptions from "../../hooks/useGenerateInputOptions";
import Empty from "../data_display/Empty";
import WideSpace from "../layout/WideSpace";

export default function RadioField({
                                       request,
                                       key_label = 'name', key_value = 'id',
                                       mode,
                                       translateLabelOptions = false,
                                       label,
                                       direction = 'vertical',
                                       options = [],
                                       children,
                                       ...props
                                   }) {

    const {_options, isLoading} = useGenerateInputOptions({
        request,
        options,
        key_label,
        key_value
    })


    const HAS_OPTIONS = _options?.length > 0

    return (
        <>
            {
                mode === 'single' ? (
                    <Radio {...props}>{label}</Radio>
                ) : (
                    <Radio.Group {...props} style={{width: '100%'}}>
                        {isLoading ? <RadioLoading/> : (
                            <WideSpace direction={direction} align={HAS_OPTIONS ? 'start': 'center'}>
                                {HAS_OPTIONS  ?
                                (_options?.map((item) => {
                                    return <Radio {...item}
                                                  key={item.value}>{item.label}</Radio>
                                })): <Empty/>}
                            </WideSpace>
                        )}

                    </Radio.Group>)
            }
        </>
    )
}


function RadioLoading() {
    return (
        <FlexContainer gap={8} direction={'column'}>
            <Space>
                <Skeleton.Avatar active size={12}
                                 shape={'circle'}/>
                <Skeleton.Input active size={12}
                                shape={'square'}/>
            </Space>
            <Space>
                <Skeleton.Avatar active size={12}
                                 shape={'circle'}/>
                <Skeleton.Input active size={12}
                                shape={'square'}/>
            </Space>
            <Space>
                <Skeleton.Avatar active size={12}
                                 shape={'circle'}/>
                <Skeleton.Input active size={12}
                                shape={'square'}/>
            </Space>

        </FlexContainer>
    )
}