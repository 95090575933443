import React from 'react';

import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {
    DeleteOutlined,
    EditOutlined,
} from "@ant-design/icons";
import {Tooltip} from "antd";
import {
    FlexContainer
} from "../../../../../../global/components/authentication/StyledComponents";
import {
    danger,
    neutral_10,
    neutral_5
} from "../../../../../../global/utils/Colors";


const SectionContainer = styled(FlexContainer)`
    cursor: pointer;
    min-width: ${({minContent}) => minContent ? 'min-content' : '220px'};
    max-width: ${({minContent}) => minContent ? 'min-content' : '220px'};
    background: ${({actived}) => actived ? 'var(--primary_btn_color)' : 'white'};
    color: ${({actived}) => actived ? 'var(--primary_btn_text_color)' : 'black'};
    font-weight: bold;
    padding: 8px 16px;
    border-style: solid;
    border-width: 1px;
    border-radius: 8px;
    border-color: ${({actived}) => actived ? 'var(--primary_btn_color)' : neutral_5};

    & .actions {
        display: none;
    }

    &:hover {
        ${({actived}) => {
            if (!actived) {
                return `
                color: var(--primary_btn_color);
        border: 1px solid var(--primary_btn_color);
                `
            }
        }}
        & .actions {
            display: ${({hasActions}) => hasActions ? 'flex' : 'none'};
        }

        & > :last-child {

            width: min-content;
        }
    }
`

const ActionContainer = styled.div`

    padding: 0px 8px;
    border-radius: 4px;

    &:hover {
        color: var(--primary_btn_text_color);
        background: ${({disabled}) => disabled ? "transparent" : "var(--primary_btn_color)"};

        &.delete {
            background: ${({disabled}) => disabled ? "transparent" : danger};

            svg {
                color: ${({disabled}) => disabled ? neutral_10 : 'white'};


            }
        }
    }


    &.delete {

        svg {
            color: ${({disabled}) => disabled ? neutral_10 : danger};


        }
    }

    color: ${({disabled}) => disabled && neutral_10} !important;
    cursor: ${({disabled}) => disabled ? "not-allowed" : 'pointer'};




`
const Title = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    //max-width: 220px;
    width: 100%;

    & + * {
        width: 0px;
    }
`

export default function SectionButton({
                                          name, minContent,
                                          disabledRemoveSection,
                                          actived = false,
                                          onEditClick,
                                          onDeleteClick,
                                          onClick,
                                          ...props
                                      }) {
    const {t} = useTranslation()
    return <SectionContainer onClick={onClick} actived={actived}
                             justify={'space-between'} minContent={minContent}
                             hasActions={!!onEditClick || !!onDeleteClick}
                             gap={16}>
        <Tooltip title={name}>
            <Title>  {name}</Title>
        </Tooltip>
        <FlexContainer className={'actions'}>
            {onEditClick && <ActionContainer onClick={(e) => {
                e.stopPropagation()
                onEditClick?.()
            }}>
                <EditOutlined/>
            </ActionContainer>}
            {onDeleteClick && <Tooltip
                title={disabledRemoveSection && t("This section has been linked to an option, please unlink it to be able to delete it")}>
                <ActionContainer disabled={disabledRemoveSection}
                                 className={'delete'}
                                 onClick={(e) => {
                                     e.stopPropagation()
                                     !disabledRemoveSection && onDeleteClick?.()
                                 }}>
                    <DeleteOutlined/>
                </ActionContainer>
            </Tooltip>}
        </FlexContainer>


    </SectionContainer>
}


