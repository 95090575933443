import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import PermissionTagsList from "../../tag/PermissionTagsList";
import DotInfoCard from "../DotInfoCard";
import InfoCardBase from "../InfoCardBase";
import RemixIcon from "../RemixIcon";
import ApplicationInfo from "./components/ApplicationInfo";

const {Text} = Typography


export default function ApplicationInfoCardBase({
                                                    application,
                                                    children, ...props
                                                }) {
    const {t} = useTranslation()

    return (
        <InfoCardBase {...props}>
            <ApplicationInfo application={application}/>
                {children}
                {application?.challenge &&
                    <DotInfoCard icon={<RemixIcon remixIconName={"ri-puzzle-line"} style={{display: "contents"}}/>} label={t("Challenge")}
                                 info={application?.challenge.name}/>}
                <PermissionTagsList
                    permissions={application.program.permissions}/>
        </InfoCardBase>

    );
}
