import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    Col,
    Form as FormAntd,
    Modal,
    Row,
    Space,
    Tooltip,
    Typography
} from "antd";
import {
    useLayoutContext
} from "../../../modules/project/components/layout/context/LayoutContext";
import {ExclamationCircleOutlined, RightOutlined} from '@ant-design/icons';
import {CardContainer} from "../../components/misc/CardContainer";
import styled from 'styled-components'
import ExtraHeader
    from "../../../modules/project/components/layout/ExtraHeader";
import ProgramBGImage from '../../../assets/img/program_BG.png'
import {neutral_1, neutral_7} from "../../utils/Colors";
import CustomButton from "../../components/data_entry/CustomButton";
import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../utils/Utils";
import Loading from "../../components/feedback/Loading";
import Empty from "../../components/data_display/Empty";
import {GLOBAL_PATHS} from "../../utils/RouteUtils";
import {ToastNotification} from "../../components/feedback/ToastNotification";
import TooltipInformation
    from "../../components/data_display/TooltipInformation";
import Drawer from "../../components/layout/Drawer";
import CardHistory from "../../components/data_display/CardHistory";
import {FlexContainer} from "../../components/authentication/StyledComponents";
import TextArea from "../../components/data_entry/TextArea";
import Button from "../../components/data_entry/Button";
import RequestSelect from "../../components/data_entry/RequestSelect";
import RequestSelectFormField
    from "../../components/data_entry/form/RequestSelectFormField";
import Form from "../../components/data_entry/form/Form";
import SelectFormField from "../../components/data_entry/form/SelectFormField";
import {generateSelectOptions} from "../../../modules/project/utils/utils";

const {Title, Paragraph, Text} = Typography

const CustomText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box;
`
const Container = styled.div`
    border-radius: 13px;
    overflow: hidden;
    background-image: url("${ProgramBGImage}");
`
const CreateProgramContainer = styled.div`
    padding: 20px 46px;
`
const StyledCardContainer = styled(CardContainer)`
    border-radius: 8px;
    min-height: 100%;
    padding: 48px 16px;
`
const StyledSpace = styled(Space)`
    width: 100%;
`

const FORM_NAME_DUPLICATE = {
    PROGRAM: 'program',
}

export default function TemplatesProgram() {
    const {t} = useTranslation();

    const history = useHistory();
    const [isLoading, setIsLoading] = useState();
    const [templates, setTemplates] = useState([]);
    const [openDuplicateOffCanvas, setOpenDuplicateOffCanvas] = useState(false);
    const [programs, setPrograms] = useState([]);
    const [form] = FormAntd.useForm();

    useEffect(() => {
        fetchTemplates()
        fetchPrograms()
    }, [])

    async function fetchTemplates() {
        try {
            setIsLoading(true)
            const response = await api.get(`/clone/get_program_templates`);
            setTemplates(response.data)

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        } finally {
            setIsLoading(false)
        }
    }

    async function fetchPrograms() {
        try {
            const response = await api.get(`programs`, {
                version: 'v2',
                params: {fields: 'id,name'}
            });
            setPrograms(response.data)

        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        }
    }

    const {
        initializeLayout,
    } = useLayoutContext();
    useEffect(() => {
        initializeLayout({
            extraHeaderContent: <ExtraHeader breadcrumbItems={[
                {label: t("Programs"), key: 1},
                {label: t("New Program"), key: 2},
            ]}/>,
            navbarMenuItems: [
                {
                    label: <Link to={``}>{t("New Program")}</Link>,
                    key: `/programs`
                },
            ]
        });
    }, []);


    async function handleCreateCloneProgram(program_id) {
        try {
            setIsLoading(true)
            const response = await api.post(`/clone/program_template/${program_id}`);
            ToastNotification(t('Successfully'))
            history.push(`/programs/${response.data.new_program_id}/settings`)


        } catch (error) {
            BuildFieldErrorsDict(error, null, false)

        } finally {
            setIsLoading(false)
        }
    }


    function handleDuplicateProgram(data) {
        Modal.confirm({
                icon: <ExclamationCircleOutlined/>,
                title: t('New program'),
                content: t("The new program will have the same steps and forms. Only entries will not be copied. Do you want to proceed?"),
                onOk: async () => {
                    try {
                        setIsLoading(true)
                        const response = await api.post(`/clone/program_template/${data[FORM_NAME_DUPLICATE.PROGRAM]}`);
                        ToastNotification(t('Successfully'))
                        history.push(`/programs/${response.data.new_program_id}/settings`)


                    } catch (error) {
                        BuildFieldErrorsDict(error, null, false)

                    } finally {
                        setIsLoading(false)
                    }
                },
                okText: t('Create'),
                cancelText: t('Go back')
            }
        )

    }

    return (
        <>
            <Container>
                <Row gutter={[0, 0]}>
                    <Col xs={0} lg={12}>
                        <CreateProgramContainer>
                            <Title level={3}>
                                {t('Create your program')}
                            </Title>
                            <Paragraph type={'secondary'}>
                                {t('In this step you can choose between the types of innovation that will meet your needs.')}
                            </Paragraph>
                        </CreateProgramContainer>

                    </Col>
                    <Col flex={'1'}>
                        <StyledCardContainer>
                            <StyledSpace size={32} direction={'vertical'}>
                                <StyledSpace size={16} direction={'vertical'}>
                                    <Text>
                                        {t('Manual settings')}
                                    </Text>
                                    <CustomButton icon={<RightOutlined/>}
                                                  onClick={() => history.push(GLOBAL_PATHS.CREATE_PROGRAM)}
                                                  data-cy="configure-manually-button">
                                        <Text>{t('Configure manually')}</Text>
                                        <TooltipInformation
                                            title={
                                                <CustomText>{t('Create your program from scratch without using a template or pre-defined settings.')}</CustomText>}/>
                                    </CustomButton>
                                </StyledSpace>
                                <StyledSpace size={16} direction={'vertical'}>
                                    <Text>
                                        {t('Existing Programs')}
                                    </Text>
                                    <CustomButton icon={<RightOutlined/>}
                                                  onClick={() => setOpenDuplicateOffCanvas(true)}>
                                        <Text>{t('Create from existing program')}</Text>
                                        <TooltipInformation
                                            title={
                                                <CustomText>{t('The new program will have the same steps and forms. Only entries will not be copied.')}</CustomText>}/>
                                    </CustomButton>
                                </StyledSpace>
                                <StyledSpace size={16} direction={'vertical'}>
                                    <Text>
                                        {t('Templates')}
                                    </Text>
                                    {isLoading ?
                                        <Loading/> : templates.length > 0 ? templates.map(({
                                                                                               name,
                                                                                               description,
                                                                                               id
                                                                                           }) => (
                                            <CustomButton key={id}
                                                          icon={
                                                              <RightOutlined/>}
                                                          onClick={() => handleCreateCloneProgram(id)}>
                                                <Text>{name}</Text>
                                                {description &&
                                                    <TooltipInformation
                                                        title={
                                                            <CustomText>{description}</CustomText>}/>}
                                            </CustomButton>
                                        )) : <Empty/>}

                                </StyledSpace>
                            </StyledSpace>


                        </StyledCardContainer>
                    </Col>

                </Row>
            </Container>

            <Drawer open={openDuplicateOffCanvas}
                    onClose={() => setOpenDuplicateOffCanvas(false)}
                    footer={
                        <FlexContainer justify={'end'}>
                            <Button
                                onClick={form.submit}>{t('Duplicate')}</Button>
                        </FlexContainer>

                    }
                    title={t('Duplicate program')}>
                <Form form={form} onFinish={handleDuplicateProgram}>
                    <SelectFormField
                        options={generateSelectOptions(programs, 'name')}
                        form={{
                            layout: 'vertical',
                            rules: [{
                                required: true,
                                message: t('This field is require')
                            }],
                            name: FORM_NAME_DUPLICATE.PROGRAM,
                            label: t("Select the program"),

                        }} url={'programs/home'}
                    />
                </Form>


            </Drawer>
        </>


    )
}
