import { Button, Dropdown, Flex, Form, Space, Typography } from "antd";
import {
    CalendarOutlined,
    CalendarTwoTone,
    DownloadOutlined,
    FullscreenExitOutlined,
    FullscreenOutlined, InfoCircleOutlined, LayoutOutlined, LayoutTwoTone,
    LoadingOutlined,
    PlusOutlined,
    WindowsFilled,
} from "@ant-design/icons";
import React, { useEffect, useMemo, useRef, useState } from "react";
import SolvCollapse from "../../../../global/components/misc/SolvCollapse";
import DynamicDropdown from "../../../../global/components/data_entry/DynamicDropdown";
import TaskOffCanvas from "./components/TaskOffCanvas";
import { ViewMode } from "gantt-task-react";
import ProjectTimeline from "./components/ProjectTimeline/ProjectTimeline";
import { useParams } from "react-router-dom";
import api from "../../../../global/services/api";
import { useLayoutContext } from "../../components/layout/context/LayoutContext";
import moment from "moment";
import { BuildFieldErrorsDict } from "../../../../global/utils/Utils";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import styled from "styled-components";
import { useProjectManagementContext } from "./context/ProjectManagementContext";
import { useTranslation } from "react-i18next";
import {useSolvModalContext} from "../../components/modal/SolvTourModalList";
import { DateFormat, DatetimeByTimezoneSystem } from "../../../../global/utils/dates";
import html2canvas from "html2canvas";
import { ToastNotification } from "../../../../global/components/feedback/ToastNotification";
import { PROJECT_COLOR } from "../../../../global/utils/Colors";
import HelpCard from "../../../../global/components/other/HelpCard";


const StyledContainer = styled.div`
    
    ${({ active }) => {
        if (active) {
            return `
                height: 100vh !important;
                .ant-collapse, .ant-collapse-item{
                    height: 100vh !important;
                }
                
                .ant-collapse-content, .ant-collapse-content-box{
                    height: 100% !important;
                }import { BuildFieldErrorsDict } from './../../../../global/utils/Utils';

                
                ._3eULf{
                    height: 90%;
                }
            `
        }
    }}
  
`

const { Text } = Typography;

function getTaskPayload(task) {
    return {
        ...task,
        start: moment(task.start).format('YYYY-MM-DD'),
        end: moment(task.end).format('YYYY-MM-DD'),
    }
}

function storeTaskLocal({ projectId }) {
    const doStoreTask = (task) => {
        let tasks = JSON.parse(localStorage.getItem("tasks")) || {};
        const projectTasks = tasks[projectId] || {};
        projectTasks[task.id] = true;
        tasks[projectId] = projectTasks;
        localStorage.setItem("tasks", JSON.stringify(tasks));
    };

    const doUnstoreTask = (task) => {
        let tasks = JSON.parse(localStorage.getItem("tasks")) || {};
        const projectTasks = tasks[projectId] || {};
        delete projectTasks[task.id];
        tasks[projectId] = projectTasks;
        localStorage.setItem("tasks", JSON.stringify(tasks));
    };

    const doRetrieveTask = (id) => {
        let tasks = JSON.parse(localStorage.getItem("tasks")) || {};
        const projectTasks = tasks[projectId] || {};
        return projectTasks[id];
    };

    return { doStoreTask, doUnstoreTask, doRetrieveTask }
}

function ProjectManagementTimelinePage() {
    const [form] = Form.useForm();
    const [tasks, setTasks] = useState();
    const [openTaskOffCanvas, setOpenTaskOffCanvas] = useState(false);
    const [viewMode, setViewMode] = useState(ViewMode.Day);
    const [showTable, setShowTable] = useState(true);
    const [currentUpdatingTask, setCurrentUpdatingTask] = useState();
    const { openNotification, isMobile } = useLayoutContext();
    const [taskChoices, setTaskChoices] = useState([]);
    const [viewDate, setViewDate] = useState(false);
    const handle = useFullScreenHandle();
    const { id } = useParams();
    const container = useRef();
    const { setRefreshBadges, project } = useProjectManagementContext();
    const { doStoreTask, doUnstoreTask, doRetrieveTask } = storeTaskLocal({ projectId: id })
    const { t } = useTranslation();
    const {openTourModalByIdentifier} = useSolvModalContext();
    const [loadingExport, setLoadingExport] = useState(false);

    const viewModes = [
        { label: t("Day"), key: ViewMode.Day },
        { label: t("Week"), key: ViewMode.Week },
        { label: t("Month"), key: ViewMode.Month },
        { label: t("Year"), key: ViewMode.Year },
    ];

    const fetchTaskChoices = async () => {
        const response = await api.get(`/project_management/project/${id}/tasks`, { params: { choices: true } });
        setTaskChoices(response.data);
    }
    const fetchTasks = async () => {
        const response = await api.get(`/project_management/project/${id}/tasks`);
        setTasks(response.data.map((task) => {
            const hideChildren = !!doRetrieveTask(task.id);
            return {
                ...task,
                start: moment(task.start).toDate(),
                end: moment(task.end).toDate(),
                hideChildren: hideChildren,
            }
        }),
        );
        await fetchTaskChoices();
        setRefreshBadges(["timelineBadges"]);
    };

    const updateTaskLocal = ({ id, taskData }) => {
        if (tasks) {
            setTasks(tasks?.map((task) => {
                if (task.id === id) {
                    return { ...task, ...taskData };
                }
                return task;
            }));
        }
    }

    const handleShowTaskCreateForm = () => {
        form.resetFields();
        form.setFieldsValue({ "form_type": "task", "type": "task" });
        setCurrentUpdatingTask(undefined);
        setOpenTaskOffCanvas(true);
    };

    const handleShowTaskUpdateForm = (task) => {
        form.resetFields();
        form.setFieldsValue({ 
            ...task, 
            start: DateFormat(task.start), 
            end: DateFormat(task.end), 
            date: DateFormat(task.start) 
        });
        setCurrentUpdatingTask(task);
        setOpenTaskOffCanvas(true);
    };

    const createTask = async (data) => {
        try {

            const payload = {
                "project_portfolio": id,
                ...data,
            };
            await api.post(`/project_management/project_tasks`, payload);
            openNotification({ type: "success", message: t("Task Created"), getContainer: () => container.current });
            setOpenTaskOffCanvas(false);
            setCurrentUpdatingTask(undefined);
        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, false);
        }
    };

    const partialUpdateTask = async ({ payload, id }) => {
        return await api.patch(`/project_management/project_tasks/${id}`, payload);
    }
    const updateTask = async (data) => {
        try {
            const payload = {
                ...data,
                "dependency": data["dependency"] || null,
                "project_task": data["project_task"] || null,
            }
            await partialUpdateTask({ payload, id: currentUpdatingTask?.id })
            openNotification({ type: "success", message: t("Task Updated"), getContainer: () => container.current });
            setOpenTaskOffCanvas(false);
            setCurrentUpdatingTask(undefined);
        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, false);
        }
    };

    const handleDraggingUpdate = async (task) => {
        const payload = getTaskPayload(task);
        await partialUpdateTask({ payload, id: task.id });
        await fetchTasks();
    }

    const deleteTask = async ({ id }) => {
        try {
            await api.delete(`/project_management/project_tasks/${id}`);
            setOpenTaskOffCanvas(false);
            openNotification({ type: "success", message: t("Task Deleted"), getContainer: () => container.current });
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
    };
    const handleAddSubtask = (task) => {
        setCurrentUpdatingTask(undefined);
        form.resetFields();
        form.setFieldsValue({
            "form_type": "task",
            "project_task": task["id"],
            "start": DateFormat(task.start),
            "end": DateFormat(task.end),
            "date": DateFormat(task.start),
        });
        setOpenTaskOffCanvas(true);
    }
    const handleExpand = (task) => {
        task.hideChildren = !task.hideChildren;
        task.hideChildren ? doStoreTask(task) : doUnstoreTask(task);
        updateTaskLocal({ id: task.id, taskData: task });
    }

    const handleDelete = async ({ id }) => {
        await deleteTask({ id });
        await fetchTasks();
    }

    const handleSubmit = async (data) => {
        if (currentUpdatingTask) {
            await updateTask(data);
        } else {
            await createTask(data);
        }
        await fetchTasks();
    };

    const getCurrentDate = () => {
        if (viewDate) {
            return moment().toDate()
        }
        return undefined;
    }

    useEffect(() => {
        if (id && !tasks) {
            void fetchTasks();
        }
    }, [id]);

    useEffect(() => {
        if (isMobile) {
            setShowTable(false);
        }
    }, []);

    const handleExportGantt = async () => {
        try{
            const preview = document.createElement("div");
            const content =  document.getElementsByClassName("_CZjuD")[0];
            const width = content.getElementsByClassName("_2B2zv")[0].offsetWidth;
            preview.innerHTML += content.innerHTML;
            document.body.appendChild(preview);
            const filename = `Timeline - ${project.name} - ${DatetimeByTimezoneSystem(Date())}.png` 
            await html2canvas(preview, {width}).then((canvas)=>canvas.toBlob((blob)=>{
                document.body.removeChild(preview);
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a", );
                a.download = filename
                a.href = url
                a.target = '_blank'
                a.click()
                ToastNotification(t('Finished'));
            }))
        } catch (e) {
            console.log(e)
            ToastNotification(t('Error saving image'), 'error');
        }finally {
            setLoadingExport(false);
        }
    }
    const changeViewModeAndExport = ({key}) => {
        setLoadingExport(true);
        setViewMode(key);
        setTimeout(handleExportGantt, 10);
    }


    const renderExtra = useMemo(() => {

        return (<Space>
            <Button type="primary" onClick={handleShowTaskCreateForm} data-cy="new-task-button"><PlusOutlined />{t("New Task")}</Button>
            {React.createElement(viewDate ? CalendarTwoTone : CalendarOutlined, {
                onClick: () => setViewDate(!viewDate)
            })}
            {React.createElement(showTable ? LayoutTwoTone : LayoutOutlined, {
                onClick: () => setShowTable(!showTable)
            })}
            {React.createElement(handle.active ? FullscreenExitOutlined : FullscreenOutlined, {
                onClick: () => handle.active ? handle.exit() : handle.enter(),
                style: { color: handle.active && `${PROJECT_COLOR}` }
            })}
            
            {!loadingExport ? 
                <Dropdown 
                    menu={{
                        items: viewModes,
                        onClick: changeViewModeAndExport
                    }} 
                    trigger="click" 
                    getPopupContainer={(c)=>container.current || c}
                >
                    <DownloadOutlined />
                </Dropdown>: 
                <LoadingOutlined />}
            <InfoCircleOutlined onClick={() => openTourModalByIdentifier('project_timeline') } />
        </Space>);
    }, [loadingExport, handle, viewDate, showTable]);

    return (
        <Flex vertical={true} gap={24}>
            <HelpCard
                    title={t("Physical schedule planning")}
                    description={t("Physical schedule planning provides high-level monitoring of the most important project activities.")}
                    icon={<InfoCircleOutlined/>}
            />
            <FullScreen handle={handle} active={handle.active}>
                <StyledContainer active={handle.active} ref={container}>
                    <TaskOffCanvas
                        container={container.current}
                        open={openTaskOffCanvas}
                        setOpen={setOpenTaskOffCanvas}
                        task={currentUpdatingTask}
                        taskChoices={taskChoices}
                        form={form}
                        handleSubmit={handleSubmit}
                        handleDelete={handleDelete}
                    />
                    <SolvCollapse
                        bold={false}
                        bordered={true}
                        active={true}
                        locked={true}
                        title={
                            <Space>
                                <DynamicDropdown
                                    container={container.current}
                                    title={<Text style={{ fontWeight: "normal" }}>{t("View")}:</Text>}
                                    items={viewModes}
                                    handleChange={({ key }) => setViewMode(key)}
                                    selectedKey={viewMode}
                                />
                            </Space>
                        }
                        renderExtra={renderExtra}

                    >
                        <ProjectTimeline
                            tasks={tasks}
                            viewMode={viewMode}
                            viewDate={getCurrentDate()}
                            showTable={showTable}
                            handleAddSubtask={handleAddSubtask}
                            handleExpand={handleExpand}
                            handleDelete={handleDelete}
                            onDoubleClick={handleShowTaskUpdateForm}
                            handleDragging={handleDraggingUpdate}
                        />
                    </SolvCollapse>
                </StyledContainer>
            </FullScreen>
        </Flex>
    );
}

export default ProjectManagementTimelinePage;