import {useLayoutContext} from "../components/layout/context/LayoutContext";
import {Form, Modal} from "antd";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {convertToFormData} from "../utils/utils";
import api from "../../../global/services/api";
import {BuildFieldErrorsDict} from "../../../global/utils/Utils";
import {CloseCircleOutlined} from "@ant-design/icons";
import {useThemeContext} from "../../../global/context/ThemeContext";
import {useHistory} from "react-router-dom";

const {confirm} = Modal;

export const useProjectRequest = (project=null, setProject) => {

    const { openNotification } = useLayoutContext();
    const {themeConfig} = useThemeContext()
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()

    const handleProjectNameConflict = (response) => {
        if (response.data?.name !== form.getFieldValue("name")) {
            form.setFieldValue("name", response.data?.name);
            openNotification({
                type: "warning",
                message: t("Your project has been renamed due to the existence of another project with the same name in the environment."),
            });
        }
    };
    
    const prepareData = (data) => {
        const newData = { 
            ...data, 
            date_start: data.date_range[0], 
            date_end: data.date_range[1], 
        }
        newData.avatar = newData.avatar?.filter(file=>file instanceof File);
        return newData;
    };
    
    const handleCreate = async (data) => {
        try {
            setIsLoading(true)
            const config = {
                headers: {"content-type": "multipart/form-data"},
            };
            const payload = convertToFormData({
                ...data,
                client: themeConfig.client.id,
            }, false);
            const response = await api.post(`/project_management/project`, payload, config);
            openNotification({type: "success", message: t("Project saved successfully")});
            handleProjectNameConflict(response);
            history.push(`/project-portfolio/project/${response.data.id}/settings`);
        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, false);
        } finally {
            setIsLoading(false)
        }
    };
    
    const handleUpdate = async (data) => {
        try {
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            const payload = convertToFormData(data, false);
            await api.post(`/project_management/project/${project?.id}/change_current_phase`, {phase: data.phase});
            const response = await api.patch(`/project_management/project/${project.id}`, payload, config);
            setProject && setProject(response.data);
            openNotification({ type: "success", message: t("Project saved successfully") });
            handleProjectNameConflict(response);
        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, false);
        }
    };

    const handleSubmitLoading = async (data) => {
        data = prepareData(data);
        setIsLoading(true);
        try {
            if (project === null) {
                await handleCreate(data);
            } else {
                await handleUpdate(data);
            }
        } catch (error) {
            BuildFieldErrorsDict(error, false, false);
            // console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const deleteProject = async () => {
        try {
            await api.delete(`/project_management/project/${project.id}`);
            openNotification({ type: "success", message: t("Project deleted") });
            history.push("/project-portfolio");
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
    };

    const handleDeleteProject = () => {
        confirm({
            title: t("Do you really want to delete this project?"),
            icon: <CloseCircleOutlined style={{ color: "red" }} />,
            okText: t("Yes"),
            okButtonProps: { "data-cy": "confirm-delete-project-button" },
            okType: "danger",
            cancelText: t("No"),
            onOk() {
                void deleteProject();
            },
        });
    };

    const cleanFormValues = (project) => {
        return {...project, avatar: project?.avatar_display}
    }

    const handleSave = async () => {
        try {
            setIsLoading(true);
            await form.validateFields();
            form.submit();
        } finally {
            setIsLoading(false);
        }
    };

    return {
        form, isLoading,
        handleProjectNameConflict, handleSubmitLoading,
        handleDeleteProject, handleCreate, handleSave, cleanFormValues
    }

}