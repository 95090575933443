import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {
    Form,
    Input,
    Select,
    Typography,
    Checkbox,
    Radio,
    InputNumber,
    Skeleton,
    Empty,
    Modal, Space,
} from "antd";
import WideSpace from "../../components/layout/WideSpace";
import {
    AttachmentDraggerUploader
} from '../../components/data_entry/AttachmentUploader';
import DatePickerLocal from "../../components/data_entry/DatePickerLocal";
import {ACCEPT_DOCUMENT_FORMATS} from "../../utils/Constants";
import DangerousHtmlPreviewer
    from "../../components/data_display/DangerousHtmlPreviewer";
import {transformLink} from "../../../modules/project/utils/utils";
import useFormValidations from "../../hooks/useFormValidations";
import TextArea from "../../components/data_entry/TextArea";
import IAInputButton from "../../components/button/IAInputButton";
import SectionInput from "./components/SectionInput";

function LinkInput({value = '', ...props}) {
    let link = transformLink(value);
    return <>
        <a href={link} target="_blank" className="underline-link">{value}</a>
        <Input value={value} {...props} />
    </>
}

function Header({children, help, size}) {
    return <WideSpace direction="vertical" size={0} style={{
        lineHeight: "initial",
        paddingBottom: "8px",
        paddingTop: "8px"
    }}>
        <Typography.Text style={{
            fontSize: size === "large" ? 24 : 16,
            fontWeight: 500
        }}>{children}</Typography.Text>
        <Typography.Text type="secondary"
                         style={{fontSize: 13}}>{help}</Typography.Text>
    </WideSpace>
}

const ITEM_FORM_TYPES = {
    PARAGRAPH: "paragraph",
    HEADER: "header",
    TEXT_AREA: "text_area",
    NUMBER: "number",
    SELECT: "select",
    MULTIPLE_SELECT: "multiple_select",
    RADIO: "radio",
    CHECKBOX: "checkbox",
    IMAGE: "image",
    DOCUMENT: "document",
    EMAIL: "email",
    LINK: "link",
    DATE: "date",
    TEXT_INPUT: "text_input",
    // HEADER: "header",
    // PARAGRAPH: "paragraph",
    SECTION: "section",
}

export default function ApplicationFormContentEditable({
                                                           hasIAChat = false,
                                                           form,
                                                           phaseForm,
                                                           application,
    application_id
                                                       }) {
    const {t} = useTranslation();
    const {form: formItems, phase} = phaseForm || {};
    const {linkValidator} = useFormValidations();


    const getFormResponse = (application) => {
        if (!application?.response_form) {
            return null;
        }
        const responseValues = Object.fromEntries(
            Object.entries(application.response_form).map(
                ([key, item]) => {
                    let value = item.value;
                    if (item.type === undefined) {
                        let _formitem = formItems.find((_item) => _item.name === key)
                        if (_formitem !== undefined) {
                            item['type'] = _formitem.type
                        }
                    }
                    const type_file = item.type === ITEM_FORM_TYPES.IMAGE || item.type === ITEM_FORM_TYPES.DOCUMENT
                    if (type_file) {
                        let itemName = t("File");
                        if (item.type === ITEM_FORM_TYPES.IMAGE) itemName = t("Image");
                        if (item.type === ITEM_FORM_TYPES.DOCUMENT) itemName = t("Document");
                        value = item.value ? [{
                            name: itemName,
                            url: item.value
                        }] : [];
                    }
                    if ((item.type === "radio" || item.type === "section") &&  value.length > 0) {
                        value = value.at(0);
                    }
                    return [key, value];
                }
            )
        );
        return responseValues;
    }

    const getValuePropName = (item) => {
        switch (item?.type) {
            case ITEM_FORM_TYPES.DOCUMENT:
            case ITEM_FORM_TYPES.IMAGE:
                return "fileList"
            default:
                return "value"
        }
    }

    const getRules = (item) => {
        const rules = []
        item.required && rules.push({required: item.required});
        item.type === "email" && rules.push({type: "email"});
        item.type === "link" && rules.push({validator: linkValidator});
        item.max_length && rules.push({max: item});
        return rules;
    }

    const getInputByType = ({type, max_length, ...props}) => {

        const getChoices = () => {
            const choices = props.choices?.map((item, count) => ({
                key: count,
                value: item.value,
                label: item.value
            }));
            return choices;
        }

        switch (type) {
            case ITEM_FORM_TYPES.TEXT_AREA:
                return <IAInputButton question_id={props.name}
                                      actived={hasIAChat}
                                      application_id={application_id}
                                      initialMessage={'teste'}><TextArea
                    data-cy="text-area-input"
                    autoSize={{minRows: 4}} maxLength={max_length}
                    showCount/></IAInputButton>
            case ITEM_FORM_TYPES.NUMBER:
                return <InputNumber style={{width: "100%"}}
                                    data-cy="number-input"/>

            case ITEM_FORM_TYPES.SELECT:
                return <Select showSearch options={getChoices()}
                               data-cy="select"/>;
            case ITEM_FORM_TYPES.MULTIPLE_SELECT:
                return <Select options={getChoices()} mode="multiple"
                               data-cy="multiple-select"/>
            case ITEM_FORM_TYPES.RADIO:
                return <Radio.Group data-cy="radio-group">
                    <Space direction="vertical">
                        {getChoices().map((item) => {
                            return <Radio {...item}>{item.label}</Radio>
                        })}
                    </Space>
                </Radio.Group>
            case ITEM_FORM_TYPES.CHECKBOX:
                return <Checkbox.Group data-cy="checkbox-group">
                    <Space direction="vertical">
                        {getChoices().map((item) => {
                            return <Checkbox {...item}>{item.label}</Checkbox>
                        })}
                    </Space>
                </Checkbox.Group>
            case ITEM_FORM_TYPES.IMAGE:
                return <AttachmentDraggerUploader
                    description={t("Support for a single image upload")}
                    maxCount={1} data-cy="image-uploader"/>
            case ITEM_FORM_TYPES.DOCUMENT:
                return <AttachmentDraggerUploader
                    description={t("Support for a single document upload")}
                    maxCount={1} accept={ACCEPT_DOCUMENT_FORMATS}
                    data-cy="document-uploader"/>
            case ITEM_FORM_TYPES.EMAIL:
                return <Input type="email" data-cy="email-input"/>
            case ITEM_FORM_TYPES.LINK:
                return <LinkInput data-cy="link-input"/>
            case ITEM_FORM_TYPES.DATE:
                return <DatePickerLocal style={{display: "block"}}
                                        data-cy="date-picker"/>
            case ITEM_FORM_TYPES.TEXT_INPUT:
                return <IAInputButton 
                            question_id={props.name}
                            actived={hasIAChat}
                            application_id={application_id}
                            initialMessage={'teste'}>
                                <Input data-cy="input" showCount maxLength={max_length} />
                        </IAInputButton>
            default:
                return <Input data-cy="input" showCount maxLength={max_length} />
        }
    }

    const renderItem = (item, key) => {
        const help = item.help_text ?
            <DangerousHtmlPreviewer content={item.help_text}/> : '';
        switch (item.type) {
            case ITEM_FORM_TYPES.HEADER:
                return <Header help={help} size="large">{item.label}</Header>
            case ITEM_FORM_TYPES.PARAGRAPH:
                return <Header help={help} size="small">{item.label}</Header>
            case ITEM_FORM_TYPES.SECTION:
                return <SectionInput item={item}
                                    formInstance={form}
                                     formBuild={{
                                         hasIAChat,
                                         application,
                                         form
                                     }}
                                     phaseForm={phaseForm}
                                     form={{
                                         name: item.name,
                                         label: item.label,
                                         rules: getRules(item),
                                         extra: help
                                     }}/>
            default:
                return (
                    <Form.Item name={item.name} label={item.label} key={key}
                               rules={getRules(item)}
                               extra={help}
                               valuePropName={getValuePropName(item)}>
                        {getInputByType(item)}
                    </Form.Item>
                )
        }
    }

    useEffect(() => {
        const formResponse = getFormResponse(application);
        if (formResponse) {
            form.setFieldsValue(formResponse);
        }
    }, [application]);

    return (
        <WideSpace direction="vertical" size={24}>

            {formItems?.length > 0 ? (
                <Skeleton loading={!formItems}>
                    <WideSpace direction="vertical" size={0}>
                        {formItems?.map((item, key) => renderItem(item, key))}
                    </WideSpace>
                </Skeleton>
            ) : (
                <Empty description={t("There is no form yet.")}/>
            )}

        </WideSpace>
    );
}