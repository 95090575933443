import {Alert, Col, Typography, Row, Select, Skeleton, Space} from "antd"
import SolvCollapse from "../../misc/SolvCollapse"
import React, {useCallback, useEffect, useRef, useState} from "react";
import TeamSelectableCard from "./TeamSelectableCard";
import Loading from "../../feedback/Loading";
import CardButton from "../../button/CardButton";
import {PlusCircleOutlined} from "@ant-design/icons";
import TeamDrawer from "./TeamDrawer";
import {useTranslation} from "react-i18next";
import styled from "styled-components";

const { Text } = Typography

const StyledDiv = styled.div`
    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }

`

export default function TeamSelectorComponent({
                                                  value,
                                                  status,
                                                  onChange,
                                                  displayTypes,
                                                  disabled = false,
                                                  editorMode = false,
                                                  title,
                                                  teams,
                                                  setTeams,
                                                  fetchUserTeams,
                                                  enableFormAlert
                                              }) {
    const [openTeamDrawer, setOpenTeamDrawer] = useState(false);
    const [formType, setFormType] = useState('')
    const [editTeamId, setEditTeamId] = useState(undefined);

    const [selectedTeamId, setSelectedTeamId] = useState(undefined);
    const {t} = useTranslation();

    useEffect(() => {
        if(teams !== undefined && value){
            const team = teams?.find(({id}) => id === value)
            if(displayTypes.some(({name}) => name === team.type)){
                setSelectedTeamId(value)
            }
        }
    }, [teams]);

    const isCurrentlySelected = (id) => {
        return (value === id);
    }
    const handleOpenCreateDrawer = (formType) => {
        setEditTeamId(undefined);
        setFormType(formType);
        // setOpenTeamTypeModal(false)
        setOpenTeamDrawer(true);
    }

    const handleOpenEditDrawer = (teamId, teamType) => {
        setFormType(teamType);
        setEditTeamId(teamId);
        setOpenTeamDrawer(true);
    }

    const reloadTeams = useCallback(() => {
        fetchUserTeams();
    }, []);


    useEffect(() => {
        !teams && fetchUserTeams();
        window.addEventListener("reloadTeams", reloadTeams);
        return () => {
            window.removeEventListener("reloadTeams", reloadTeams);
        };
    }, [teams]);

    // useEffect(() => {
    //     handleTeamSelect(value);
    // }, [value]);

    const handleTeamSelect = (teamId, teamType) => {
        if (!editorMode) {
            const team = filteredTeams?.some((item) => item.id === teamId) ? teamId : null
            handleChange(team)
        } else {
            teamId && handleOpenEditDrawer(teamId, teamType);
        }
    }

    function handleChange(team) {
        setSelectedTeamId(team);
        onChange && onChange(team);
    }

    const handleCloseDrawer = () => {
        setOpenTeamDrawer(false);
    }

    const getDisplayTypes = () => {
        return displayTypes?.map((item) => typeof item === "string" ? item : item?.name);
    }

    const getFilteredTeams = (teams) => {
        const types = getDisplayTypes();
        return teams?.filter(team => types.includes(team.type))
    }

    const filteredTeams = getFilteredTeams(teams);

    return (
        <div style={{border: status === 'error' ? '1px solid red' : ''}}>
            <SolvCollapse collapseStyle={{border: '1px solid red'}}
                          status={status} title={title} active={true}
                          bordered={true}>
                <StyledDiv className={disabled && "disabled"}>
                    <Select style={{display: "block"}}
                            placeholder={t("Select a challenge")}
                            hidden={true}/>
                    {/*<WideSpace direction="vertical" size={24}>*/}

                    <Row gutter={[24, 24]}>
                        <Skeleton loading={teams === undefined}/>
                        {teams !== undefined && <>
                            <Col xs={24}> {!selectedTeamId && !editorMode &&
                                <Alert
                                    message={t('It is necessary to select the program participant. If you don\'t already have a company or team, create a new one below.')}
                                    type="warning" showIcon/>}</Col>
                            {/*{(filteredTeams?.length === 0) &&*/}
                            {/*    <WideSpace*/}
                            {/*        style={{justifyContent: "center"}}><Empty/></WideSpace>}*/}
                            {!filteredTeams && <Loading/>}
                            {filteredTeams?.map((team, key) => (
                                <Col span={24} sm={12} md={8}
                                     data-cy={`team-card-${team.id}`}>
                                    <TeamSelectableCard
                                        key={key} team={team}
                                        onClick={() => !disabled && handleTeamSelect(team.id, team.type)}
                                        isSelected={editorMode ? false : isCurrentlySelected(team.id)}
                                    />
                                </Col>
                            ))}
                            {(!displayTypes || getDisplayTypes().includes("Team")) &&
                                <Col span={24} sm={12} md={8}>
                                    <CardButton height="104px"
                                                justify="center"
                                                icon={<PlusCircleOutlined/>}
                                                text={
                                                    <Space direction="vertical" size={0} style={{fontSize: 16, fontWeight: 700}}> 
                                                        {t("Create Team")} 
                                                        <Text style={{fontSize: 12}} type="secondary">
                                                            {t("Create your team to participate in programs available in solv")}
                                                        </Text>
                                                    </Space> 
                                                }
                                                onClick={() => handleOpenCreateDrawer("Team")}
                                                data-cy="create-team-button"/>
                                </Col>}
                            {(!displayTypes || getDisplayTypes().includes("Company")) &&
                                <Col span={24} sm={12} md={8}>
                                    <CardButton height="104px"
                                                justify="center"
                                                icon={<PlusCircleOutlined/>}
                                                text={
                                                    <Space direction="vertical" size={0} style={{fontSize: 16, fontWeight: 700}}> 
                                                        {t("Create Company")} 
                                                        <Text style={{fontSize: 12}} type="secondary">
                                                            {t("Create your company to participate in the programs available in solv")}
                                                        </Text>
                                                    </Space>
                                                }
                                                onClick={() => handleOpenCreateDrawer("Company")}
                                                data-cy="create-company-button"/>
                                </Col>}
                        </>}


                    </Row>
                    {/*</WideSpace>*/}
                    {/* <TeamTypeModal open={openTeamTypeModal} setOpen={setOpenTeamTypeModal} handleOpenCreateDrawer={handleOpenCreateDrawer}/> */}
                    <TeamDrawer 
                        open={openTeamDrawer}
                        onClose={handleCloseDrawer}
                        onCreate={handleChange}
                        formType={formType} teamId={editTeamId}
                        teams={teams} setTeams={setTeams}
                        enableFormAlert={enableFormAlert}
                    />
                </StyledDiv>
            </SolvCollapse>
        </div>
    )
}