import {useEffect, useState} from "react";
import {Pagination as PaginationAnt} from "antd";
import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../utils/Utils";

export default function Pagination({
                                       fetchData,
                                       defaultPageSize = 10,
                                       // defaultPage = 1,
                                       request,
                                       ...props
                                   }) {
    const [pageSize, setPageSize] = useState(defaultPageSize)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const dependence = request?.dependence ||[]
    useEffect(() => {//primeiro request e caso algum elemento de fora modifique
        const  hasMakeRequestProperty = request.hasOwnProperty('makeRequest')
        if ((hasMakeRequestProperty && request.makeRequest) || !hasMakeRequestProperty) {
            handleFetchData(1, pageSize)
        }
    }, [...dependence])

    function handleFetchData(page, pageSize) {
        setPage(page)
        setPageSize(pageSize)
        if (fetchData) {
            fetchData(page, pageSize, setTotal)
        } else {
            fetchDataDefault(page, pageSize)
        }
    }

    async function fetchDataDefault(page, pageSize) {
        try {
            request.setIsLoading(true)
            let extraParams = {}
            if (request.extraParams) {
                extraParams = request.extraParams
            }
            const params = {
                page_size: pageSize,
                page: page,
                ...extraParams
            }
            let response;
            if (request.method === 'POST') {
                response = await api.post(request.url, {}, {params: params});
            } else {
                response = await api.get(request.url, {params: params});

            }
            request?.setResponse?.(response)
            request.setData(response.data.results)
            setTotal(response.data.count)
            // setTotal(response.data.)
        } catch (error) {

            BuildFieldErrorsDict(error, null, false);
        } finally {
            request.setIsLoading(false);
        }
    }

    return (
        <PaginationAnt onChange={handleFetchData} current={page}
                       pageSize={pageSize} total={total} {...props}/>
    )
}