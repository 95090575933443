import { createContext, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import {useLayoutContext} from "../../../components/layout/context/LayoutContext";
import api from "../../../../../global/services/api";

import { useTranslation } from "react-i18next";
import {usePermissionContext} from "../../../../../global/context/PermissionContext";

const ProjectHomeContext = createContext({});

export function ProjectHomeContextProvider({ children }) {

    const { initializeLayout } = useLayoutContext();
    const {hasProjectAdminPermission, hasProjectAdminOrManagerPermission} = usePermissionContext();

    const { t } = useTranslation();

    const menuItems = [
        {
            label: <Link to="/project-portfolio" data-cy="dashboard-tab">{t("Dashboard")}</Link>,
            key: '/project-portfolio',
        },
        {
            label: <Link to="/project-portfolio/list" data-cy="projects-tab">{t("Projects")}</Link>,
            key: '/project-portfolio/list',
            hasPermission: hasProjectAdminOrManagerPermission,
        },
        {
            label: <Link to="/project-portfolio/settings" data-cy="settings-tab">{t("Settings")}</Link>,
            key: '/project-portfolio/settings',
            hasPermission: hasProjectAdminPermission,
        },
    ]

    useEffect(() => {
        initializeLayout({
            navbarMenuItems: menuItems,
            
            useOldContentLayout: false,
        });
    }, []);

    return <ProjectHomeContext.Provider> {children} </ProjectHomeContext.Provider>;
}

export function useProjectHomeContext() {
    return useContext(ProjectHomeContext);
}