import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {
    FlexContainer
} from "../../../../components/authentication/StyledComponents";
import Drawer from "../../../../components/layout/Drawer";
import {Col, Row, Radio, Typography} from "antd";
import Button from "../../../../components/data_entry/Button";
import {InfoCircleOutlined} from "@ant-design/icons";
import {useInnovationPanelContext} from "../context/InnovationPanelContext";
import {
    DrawerProjectChartFactory,
    PROJECT_CHART_NAMES
} from "./ProjectChartFactory";
import Empty from "../../../../components/data_display/Empty";


const StyledRadio = styled(Radio)`

    width: 100%;

    & > :last-child {
        width: 100%;
    }
`
const StyledRow = styled(Row)`
    .ant-card:has(.inactive) {
        display: none;
    }

    .ant-col:not(:has(div)) {
        display: none;
    }
`

export default function DrawerProjectSelectChart({
                                                     open,
                                                     setOpen,
                                                     position,
                                                 }) {
    const {t} = useTranslation();
    const [choice, setChoice] = useState()

    const {
        initialDate,
        finalDate,
        projectsFilters,
        PROJECT_FILTER_FORM_NAMES,
        addProjectChart,
        projectChartList,
        indicatorsProject
    } = useInnovationPanelContext()


    const options = useMemo(() => {
        if (open) {
            const graphsInList = projectChartList.filter((item) => item).map((item) => Number(item?.type?.split('-')[0]) || '')
            return indicatorsProject.filter((indicator) => indicator.is_active && !graphsInList.includes(indicator.id))
        }
        return []
    }, [projectChartList, indicatorsProject, open])

    function onClose() {
        setOpen(false)
        setChoice(null)
    }

    function get_indicator_type(indicator) {
        switch (indicator.internal_code) {
            case "24":
                return PROJECT_CHART_NAMES.PROJECT_RADAR
            case "25":
                return PROJECT_CHART_NAMES.PROJECT_BY_PHASES
            case "23":
                return PROJECT_CHART_NAMES.RISK
            case "15":
                return PROJECT_CHART_NAMES.TOTAL_EXPECTED_INVESTIMENT
            case "21":
                return PROJECT_CHART_NAMES.TOTAL_INVESTIMENT_MADE
            default:
                return PROJECT_CHART_NAMES.DINAMIC

        }


    }

    return (
        <Drawer open={open} onClose={onClose}
                title={t('Select chart')}
                destroyOnClose
                footer={<FlexContainer justify={'end'}>
                    <Button disabled={!!!choice}
                            onClick={() => {
                                onClose()
                                addProjectChart(position, choice)

                            }}>{t('Save')}</Button>
                </FlexContainer>}
                width={1024}>
            <Radio.Group style={{width: '100%'}} value={choice}
                         onChange={({target: {value}}) => setChoice(value)}>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Typography.Text style={{fontSize: 14}}
                                         type="secondary"><InfoCircleOutlined/> {t('Inactive indicator charts cannot be added to the innovation dashboard.')}
                        </Typography.Text>
                    </Col>
                    {options.length === 0 &&  open && <Col xs={24}><Empty/></Col>}
                    {options.map((indicator, index) => (
                        <Col xs={24} lg={12} key={indicator.id}>
                            <StyledRadio
                                value={`${indicator.id}-${get_indicator_type(indicator)}`}>
                                <DrawerProjectChartFactory
                                    type={get_indicator_type(indicator)}
                                    indicator={indicator}
                                    projects={projectsFilters[PROJECT_FILTER_FORM_NAMES.PROJECTS__ID__IN]}
                                    bodyFilters={projectsFilters}
                                    initialDate={initialDate}
                                    finalDate={finalDate}/>
                            </StyledRadio>
                        </Col>
                    ))}

                </Row>
            </Radio.Group>
        </Drawer>

    )
}

