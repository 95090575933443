import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import RoutesDatabase from "../modules/program/routes/RoutesDatabase";
import {Link, useLocation, useRouteMatch} from "react-router-dom";
import {useLayoutContext} from "../modules/project/components/layout/context/LayoutContext";
import ExtraHeader from "../modules/project/components/layout/ExtraHeader";

function Database({hasPermission}) {
    const {t} = useTranslation();
    const {url} = useRouteMatch();
    const location = useLocation()

    const databaseMenuItems = [
        {
            label: <Link to={`${url}`}>{t("Home")}</Link>,
            key: `${url}/home`,
        },
        {
            label: <Link to={`${url}/applications`}>{t("Applications")}</Link>,
            key: `${url}/applications`,
        },
        // {
        //     label: <Link to={`${url}/challenges`}>{t("Challenges")}</Link>,
        //     key: `${url}/challenges`,
        // },
        {
            label: <Link to={`${url}/programs`}>{t("Programs")}</Link>,
            key: `${url}/programs`,
        },{
            label: <Link to={`${url}/projects`}>{t("Projects")}</Link>,
            key: `${url}/projects`,
        },
        {
            label: <Link to={`${url}/companies`}>{t("Companies & Teams")}</Link>,
            key: `${url}/companies`,
        },
        {
            label: <Link to={`${url}/members`}>{t("Team Members")}</Link>,
            key: `${url}/members`,
        },
    ];

    const [origin, setOrigin] = useState()
    const [extraHeaderLeft, setExtraHeaderLeft] = useState()

    const pageLayoutSettings = {
        navbarMenuItems: databaseMenuItems,
    }

    const {initializeLayout} = useLayoutContext();

    useEffect(() => {
        initializeLayout(pageLayoutSettings);
    }, [origin, extraHeaderLeft, location])

    return (<RoutesDatabase noTab indexRoute={'home'} hasPermission={hasPermission} setOrigin={setOrigin} setExtraHeaderLeft={setExtraHeaderLeft} />);
}

export default Database;