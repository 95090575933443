import {Col, Row} from "antd";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    FlexContainer
} from "../../../../components/authentication/StyledComponents";
import SectionAmountPoints from "./components/SectionAmountPoints";
import Search from "../../../../components/data_entry/Search";
import ResetPointsButton from "./components/ResetPointsButton";
import {useThemeContext} from "../../../../context/ThemeContext";
import ValidatePointsButton from "./components/ValidatePointsButton";
import useSystemBreakpoint from "../../../../hooks/useSystemBreakpoint";
import SectionUsersTable from "./components/SectionUsersTable";
import HistoryTransactionsButton from "./components/HistoryTransactionsButton";

export default function PointsManagementPage({count}) {
    const {t} = useTranslation();
    const [search, setSearch] = useState('');
    const [refreshCounts, setRefreshCounts] = useState(0);
    const {themeConfig: {client}} = useThemeContext();
       const {isPhone} = useSystemBreakpoint()

    function handleRefreshCounts(){
           setRefreshCounts(refreshCounts + 1)
    }
    return (
        <Row gutter={[32, 32]}>
            <Col xs={24}>
                <SectionAmountPoints refreshCounts={refreshCounts}/>
            </Col>
            <Col xs={24}>
                <FlexContainer justify={'space-between'} wrap={'wrap'} gap={16}>
                    {client?.manual_points_validation &&
                        <ValidatePointsButton count={count} handleRefreshCounts={handleRefreshCounts}/>}
                    <FlexContainer gap={16}
                                   style={{flex: !client?.manual_points_validation || isPhone ? 1 : 0}}
                                   justify={'end'}>
                        <Search style={{minWidth: 264, flex: isPhone? 1:0}}
                                onSearch={(value) => setSearch(value)} data-cy="search-points-management-input" />
                        <ResetPointsButton/>
                    </FlexContainer>

                </FlexContainer>
            </Col>
            <Col xs={24}>
                <SectionUsersTable handleRefreshCounts={handleRefreshCounts} search={search} />
            </Col>
        </Row>
    );
}
