import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {
    FlexContainer
} from "../../../../components/authentication/StyledComponents";
import {neutral_5, neutral_8} from "../../../../utils/Colors";
import Drawer from "../../../../components/layout/Drawer";
import {
    Col,
    Row,
    Radio,
    Badge,
    Typography,
    Space,
    Form as FormAnt
} from "antd";
import Button from "../../../../components/data_entry/Button";
import {FilterOutlined, InfoCircleOutlined} from "@ant-design/icons";
import RequestSelect from "../../../../components/data_entry/RequestSelect";
import {useInnovationPanelContext} from "../context/InnovationPanelContext";
import FilterTags from "../../../../components/other/FilterTags";
import {APPLICATION_TAG, PROGRAM_TAG} from "../../../../utils/Choices";
import Form from "../../../../components/data_entry/form/Form";
import FilterButton from "../../../../components/button/FilterButton";
import { toTitleCase } from "../../../../../modules/project/utils/utils";

const Alert = styled.div`
    padding: 16px;
    border-radius: 8px;
    border: 1px solid ${neutral_5};

`


export default function ButtonFilterProgramsChart() {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false)
    const [form] = FormAnt.useForm()
    const {
        updateProgramsFilters,
        programsFilters, setProgramsFilters,PROGRAM_FILTER_FORM_NAMES
    } = useInnovationPanelContext()

    useEffect(() => {
        if (open) {
            form.setFieldsValue({
                [PROGRAM_FILTER_FORM_NAMES.TAGS_ISNULL]: programsFilters[PROGRAM_FILTER_FORM_NAMES.TAGS_ISNULL],
                [PROGRAM_FILTER_FORM_NAMES.TAGS__ID__IN]: programsFilters[PROGRAM_FILTER_FORM_NAMES.TAGS__ID__IN],
                [PROGRAM_FILTER_FORM_NAMES.PROGRAMS__ID__IN]: programsFilters[PROGRAM_FILTER_FORM_NAMES.PROGRAMS__ID__IN]
            });
        }
    }, [open]);

    function onClose() {
        setOpen(false)
    }


    function handleSubmit(data) {
        onClose()

        if (data[PROGRAM_FILTER_FORM_NAMES.TAGS_ISNULL] || data[PROGRAM_FILTER_FORM_NAMES.TAGS__ID__IN]?.length === 0  || !data[PROGRAM_FILTER_FORM_NAMES.TAGS__ID__IN]) {

            delete data[PROGRAM_FILTER_FORM_NAMES.TAGS__ID__IN]

        }
        if(!data[PROGRAM_FILTER_FORM_NAMES.TAGS_ISNULL]){
            delete data[PROGRAM_FILTER_FORM_NAMES.TAGS_ISNULL]
        }

        updateProgramsFilters(data[PROGRAM_FILTER_FORM_NAMES.PROGRAMS__ID__IN])
        if (data[PROGRAM_FILTER_FORM_NAMES.PROGRAMS__ID__IN]?.length === 0 || !data[PROGRAM_FILTER_FORM_NAMES.PROGRAMS__ID__IN]) {
            delete data[PROGRAM_FILTER_FORM_NAMES.PROGRAMS__ID__IN]
        }
        setTimeout(() => setProgramsFilters(data), 100)


    }

    function handleResetFilters() {
        form.resetFields()
    }
    return (
        <>
            <FilterButton hasText
                filters={programsFilters}
                onClick={() => setOpen(true)}/>
            <Drawer open={open} onClose={onClose}
                    title={t('Program filter')}
                    destroyOnClose
                    footer={<FlexContainer justify={'end'} gap={10}>
                        <Button
                            type={'default'}
                            onClick={handleResetFilters}>{t('Reset filters')}</Button>
                        <Button onClick={form.submit}>{t('Save')}</Button>
                    </FlexContainer>}
                    width={520}>
                <Form  form={form}
                    // disabled={isLoading}
                      onFinish={handleSubmit}>
                    <Row gutter={[8, 8]}>
                        <Col xs={24}>
                            <Alert>

                                <Typography.Title level={5}>
                                    <Space size={10}>

                                        <InfoCircleOutlined/>
                                        {t('About the program filter')}
                                    </Space>

                                </Typography.Title>
                                <span>
                                {t('Select the programs you want to compare in the innovation dashboard. We recommend selecting a maximum of 5 programs to avoid compromising the clarity of the data.')}
                            </span>
                            </Alert>
                        </Col>
                        <Col xs={24}>
                            <FormAnt.Item name={PROGRAM_FILTER_FORM_NAMES.PROGRAMS__ID__IN}>
                                <RequestSelect allowClear
                                    // onChange={_setPrograms}
                                    // value={_programs}
                                    translate
                                    mode={'multiple'}
                                    formatLabel={(item)=>`${item.name} - ${toTitleCase(t(item.status))}`}
                                    // labelField={'name'}
                                    url={'analytics/dashboard/manager_dashboard/get_programs'}/>
                            </FormAnt.Item>

                        </Col>
                        <Col xs={24}>
                            <FilterTags filterName={PROGRAM_FILTER_FORM_NAMES.TAGS__ID__IN}
                                        noTagName={PROGRAM_FILTER_FORM_NAMES.TAGS_ISNULL}
                                        targets={[APPLICATION_TAG]}/>
                        </Col>
                    </Row>


                </Form>

            </Drawer>

        </>


    )
}

