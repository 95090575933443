import moment from "moment-timezone";
import {useContext} from "react";
import {useUserContext} from "../context/UserContext";
import dayjs from 'dayjs';


export const DatetimeNow = () => {
    const {_user} = useUserContext();
    return moment().tz(_user? _user.time_zone: 'UTC').format();
}

export const DatetimeByTimezone = (datetime) => {
    const {_user} = useUserContext();
    return moment(datetime).tz(_user? _user.time_zone: 'UTC').format();
}

export const DateFormat = (date, format = 'YYYY-MM-DD', user) => {
    return moment(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(format);
}

export const DateFormatUser = (date, user) => {
    const formatByLanguageCode = {
        "en-us": "YYYY-MM-DD",
        "pt-br": "DD/MM/YYYY",
    }
    return moment(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format(formatByLanguageCode[user?.language_code]);
}

export const DatetimeByTimezoneSystem = (datetime) => {
    return moment(datetime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format("DD/MM/YYYY HH:mm [GMT]Z");
}


export const DatetimeFormatByTimezone = (datetime, showGMT=true, default_value='') => {
    const {_user} = useUserContext();
    let gmt = '[GMT]Z'
    if(!showGMT){
        gmt=''
    }
    if(!datetime){
        return default_value
    }

    return moment(datetime).tz(_user? _user.time_zone: 'UTC').format(`DD/MM/YYYY, HH:mm ${gmt}`);
}

export const DateFormatByUserTimezone = (date, user) => {
    if(date) {
        const {_user} = useUserContext();
        return moment(date).tz(_user ? _user.time_zone : 'UTC').format("DD/MM/YYYY");
    }
    return date
}

export const HourFormatByUserTimezone = (hour, gmt=true) => {
    const {_user} = useUserContext();
    return moment(hour).tz(_user? _user.time_zone: 'UTC').format(gmt ? "HH:mm [GMT]Z" : "HH:mm");
}
export const HourNowByUserTimezone = () => {
    const {_user} = useUserContext();
    return moment().tz(_user? _user.time_zone: 'UTC').format("HH:mm [GMT]Z");
}


export function parseDate(date, parseFormat='YYYY-MM-DD'){
    return date && dayjs(date).format(parseFormat);
    // return date && moment(date).format(parseFormat)
}


export function calculateTimeDelta(datetime, language = "en") {
    datetime = new Date(datetime);
    const currentDate = new Date();
    const differenceInMilliseconds = currentDate - datetime

    const timedelta = {
        year: Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24 * 365.25)),
        month: Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24 * 30.44)),
        week: Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24 * 7)),
        day: Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24)),
        hour: Math.floor(differenceInMilliseconds / (1000 * 60 * 60)),
        minute: Math.floor(differenceInMilliseconds / (1000 * 60)),
    }

    for (let key in timedelta) {
        if (timedelta[key] > 0) {
            return {key, value: timedelta[key]}
        }
    }
    return {key: language === "pt-br" ? "agora" : "now", value: 0}
}

