import api from "../services/api";
import {ToastNotification} from "../components/feedback/ToastNotification";
import * as ConstantsMessage from "../utils/fileWithMessage";
import {BuildFieldErrorsDict} from "../utils/Utils";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";


export default function useChallenge() {
    const {t} = useTranslation();
    const history = useHistory();

    const handleCreateSolution = async () => {
        let solutionId;
        try {
            const response = await api.post(`solutions`, {}, {version: "v2"});
            if (response.status === 201) {
                solutionId = response.data.application_id;
                ToastNotification(t(ConstantsMessage.SUCCESS));
            }
        } catch (error) {
            let response = error.response;
            if (response.status === 301) {
                solutionId = response.data.application_id
                ToastNotification(response.data.detail, 'warning')
            } else {
                BuildFieldErrorsDict(error, null, false)
                history.push('/404')
            }
        } finally {
            return solutionId;
        }
    }

    const handleCreateSolutionAndRedirect = async ({redirectParams}) => {
        const solutionId = await handleCreateSolution();
        const params = new URLSearchParams({...redirectParams});
        if (solutionId) {
            history.push(`/solutions/${solutionId}?${params.toString()}`);
        }
    }

    const handleCreateChallenges = async () => {
        try {
            const response = await api.post(`applications_challenge`);
            if (response.status === 201) {
                const application_id = response.data.application_id
                history.push(`/applications_challenges/${application_id}`)
                ToastNotification(t(ConstantsMessage.SUCCESS));
            }
        } catch (error) {
            let response = error.response;
            if (response.status === 301) {
                const application_id = response.data.application_id
                history.push(`/applications_challenges/${application_id}`)
                ToastNotification(response.data.detail, 'warning')
            } else {
                BuildFieldErrorsDict(error, null, false)
                history.push('/404')
            }

        }
    }
    return {handleCreateSolution, handleCreateSolutionAndRedirect, handleCreateChallenges}
}