import React from "react";
import {useTranslation} from "react-i18next";
import styled from 'styled-components'
import ButtonDefault from "../Buttons/ButtonDefault";

const ButtonsContent = styled.div`
  display: flex;
  position: sticky;
  top: -10px;
  padding: 10px 5px 10px 5px;
  margin-left: -5px;
  margin-right: -10px;
  background: white;
  z-index: 999;
  justify-content: space-between;
`

function FilterButtons({showClearFilters, handleClearFilters, handleApplyFilters}) {
    const {t} = useTranslation();

    return (
        <ButtonsContent hidden={!showClearFilters}>
            <ButtonDefault type={"button"} style={{margin: 0}} action="secondary" onClick={(e) => {
                e.preventDefault();
                handleClearFilters()
            }}
                           label={t("Clear filters")}/>
            <ButtonDefault type={"button"} style={{margin: 0}} action="primary" onClick={(e) => {
                e.preventDefault();
                handleApplyFilters()
            }}
                           label={t("Apply filters")}/>
        </ButtonsContent>
    )
}

export default FilterButtons