import styled from 'styled-components';
/*
    Cuidado ao utilizar este componente, ele renderiza qualquer string em html e pode
    causar vulnerabilidade XSS, assegure-se sempre de aplicar em strings tratadas pelo backend
    utilizando a biblioteca html_sanitizer.
*/

const StyledHtmlContent = styled.div`
    width: 100%;
    input, form {
        pointer-events: none;
    }
    
    ul:has(li > input) {
        list-style-type: none;
        padding: 0;
        margin: 0;
        li {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;
        }
    }

    p {
        margin-bottom: unset;
    }
    a {
        text-decoration: underline !important;
    }
`

const RawBeta = `<span style="
    align-items: center;
    background-color: black;
    color: white;
    border-radius: 10px;
    padding: 2px 4px;
    font-size: 10px;"
    title="BETA">BETA</span>`

const mappedComponents = {
    '{{beta}}': RawBeta,
}

export default function DangerousHtmlPreviewer({content = "", safe=true, style}) {


    const getPreparedHtml = (content) => {
        let html = "";
        Object.keys(mappedComponents).forEach((component)=>{
            html += content?.replace(component, mappedComponents[component]);
        });
        return html;
    }

    const props = {
        style,
        dangerouslySetInnerHTML: {__html: getPreparedHtml(content)},
    }
    if (!safe) {
        delete props["dangerouslySetInnerHTML"]
        props["children"] = content;
    }
    return <StyledHtmlContent {...props} />
}
