import React, {useEffect} from 'react'
import {
    Col,
    Divider,
    Form as FormAntd,
    Row,
    Typography,
    Radio,
    Space
} from 'antd';
import {
    FlexContainer,
    QuestionContainer,
    QuestionTitle
} from "../../../../authentication/StyledComponents";
import WideSpace from "../../../../layout/WideSpace";
import {useTranslation} from "react-i18next";
import {PieChartOutlined} from '@ant-design/icons'
import styled from 'styled-components'
import TextArea from "../../../TextArea";
import {danger} from "../../../../../utils/Colors";

const {Text} = Typography;

const Container = styled.span`
    color:  ${danger} !important;
`

export default function Required({required, label}) {

    if (!required) return null
    return (

        <Container>
            *

        </Container>


    )
}
