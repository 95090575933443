import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useLayoutContext } from '../../../modules/project/components/layout/context/LayoutContext';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import RoutesProfile from '../../../modules/program/routes/RoutesProfile';

function ProfileNavigatorPage() {
    const { t } = useTranslation();

    const profileMenuItems = [
        {
            label: <Link data-cy={"profile-link"} to={`/profile`}>{t("Profile")}</Link>,
            key: `/profile`,
        },
        {
            label: <Link data-cy={"companies-and-teams-link"} to={`/profile/teams`}>{t("Companies & Teams")}</Link>,
            key: `/profile/teams`,
        },
        {
            label: <Link data-cy={"security-link"} to={`/profile/security`}>{t("Security")}</Link>,
            key: `/profile/security`,
        },
    ]

    const {
        initializeLayout,
    } = useLayoutContext();

    useEffect(() => {
        initializeLayout({
            navbarMenuItems: profileMenuItems,
        });
    }, []);


    return (<RoutesProfile />)
    
}

export default function ProfileNavigator() {
    return <ProfileNavigatorPage />
}