import React, {useState} from 'react';
import Drawer from "../../../layout/Drawer";
import Form from "../../../data_entry/form/Form";
import TextAreaFormField from "../../../data_entry/form/TextAreaFormField";
import {useTranslation} from "react-i18next";
import Flex from "../../../responsive/Flex";
import {Button, Form as AntForm, Skeleton} from "antd";
import {SendOutlined} from "@ant-design/icons";
import {useRules} from "../../../../hooks/useRules";
import InputFormField from "../../../data_entry/form/InputFormField";
import api from "../../../../services/api";
import {BuildFieldErrorsDict} from "../../../../utils/Utils";
import NoteCard from "./NoteCard";
import {ToastNotification} from "../../../feedback/ToastNotification";
import Empty from "../../../data_display/Empty";
import WideSpace from "../../../layout/WideSpace";

function NoteFormDrawer({open, setOpen, noteId, notes, loading, fetchData}) {
    const {t} = useTranslation()
    const [form] = AntForm.useForm()
    const {IS_REQUIRED} = useRules()
    const [submitLoading, setSubmitLoading] = useState(false)

    const handleSubmit = async (data) => {
        try {
            setSubmitLoading(true)
            void await api.post('/user_notes', data)
            void await fetchData()
            ToastNotification(t("Note susscessfuly created!"))
            form.resetFields()
        } catch (error){
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setSubmitLoading(false)
        }
    }

    return (
        <Drawer
            open={open}
            setOpen={setOpen}
            title={t("Notes")}
            footer={
                <Form form={form} onFinish={handleSubmit}>
                    <TextAreaFormField
                        form={{
                            name: "comment",
                            label: t("Leave your new note"),
                            rules:[IS_REQUIRED]
                        }}
                        data-cy="comment-input"
                    />
                    <InputFormField
                        form={{
                            name: "note",
                            initialValue: noteId,
                            style:{display:"none"}
                        }}
                    />
                    <Flex justify="end">
                        <Button loading={submitLoading} onClick={form.submit} data-cy="send-note-button">
                            <SendOutlined/> {t("Send")}
                        </Button>
                    </Flex>
                </Form>
            }
        >
            <Skeleton loading={loading}>
                {notes?.length === 0 && <Empty />}
                <WideSpace direction="vertical" size="middle">
                    {notes?.map(note => <NoteCard note={note} fetchData={fetchData}/>)}
                </WideSpace>

            </Skeleton>
        </Drawer>
    );
}

export default NoteFormDrawer;