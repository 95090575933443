import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {HOST_ENTREPRENEUR} from "../../settings";

export default function useEnvironment(){
    const port = window.location.port ? ':' + window.location.port : ''
    const history = useHistory();

    const handleEnvironmentRedirect = (clientUrl, link) => {
        if (window.location.host === HOST_ENTREPRENEUR) {
            window.location.href = `//${clientUrl}${port}${link}` 
        } else {
            history.push(link)
        }
    }

    return {handleEnvironmentRedirect};
}