import {Col, Grid, Row, Skeleton, Space, Typography} from "antd";
import UserAvatar
    from "../../../../../../misc/UserAvatar";
import RemixIcon from "../../../../../../data_display/RemixIcon";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import TagsList from "../../../../../../tag/TagsList";
import WideSpace from "../../../../../../layout/WideSpace";
import useSystemBreakpoint
    from "../../../../../../../hooks/useSystemBreakpoint";
import {valueOrEmpty} from "../../../../../../../utils/Utils";
import DrawerProfileUser
    from '../../../../../../../pages/profile/components/DrawerProfileUser/DrawerProfileUser';
import {useState} from "react";


const {Text, Link} = Typography;

const StyledTextData = styled(Text)`
    font-size: 12px;
    vertical-align: top;
`;

const StyledTextDescription = styled(Text)`
    font-size: 13px;
    line-height: 17px;
`;

const StyledTextTitle = styled(Text)`
    font-weight: 500;
`;

export default function TeamProfileSummary({team, handleOpenDrawer}) {
    const {t} = useTranslation();
    const {isDesktop} = useSystemBreakpoint();
    const description = team.description ?? team.short_description;

    return (
        <Skeleton loading={!team}>
            {team &&
                <Row>
                    {isDesktop &&
                        <Col lg={3} style={{textAlign: "center"}}><UserAvatar
                            data={team} size={86}/></Col>}
                    <Col span={24} lg={21}>
                        <WideSpace size={16} direction="vertical">
                            <Space>
                                {!isDesktop && <UserAvatar data={team}/>}
                                <div>
                                    <div>
                                        <StyledTextTitle>{team.name}</StyledTextTitle>
                                        {team.linkedin &&
                                            <a href={team.linkedin}
                                               target="_blank"><RemixIcon
                                                remixIconName="ri-linkedin-box-fill"
                                                style={{
                                                    fontSize: 18,
                                                    color: "#0050B3"
                                                }}/></a>}
                                    </div>
                                    <div>
                                        {team.email && <StyledTextData
                                            type="secondary">{team.email}</StyledTextData>
                                        }
                                    </div>
                                    {team.type === 'Individual' && <Link to="#"
                                                                         onClick={handleOpenDrawer}>{t("View profile")}</Link>}

                                </div>
                            </Space>
                            {description &&
                                <StyledTextDescription>{description}</StyledTextDescription>}
                            <WideSpace direction="vertical" size="small">
                                <StyledTextTitle>{t("General Data")}:</StyledTextTitle>
                                {team.type !== 'Individual' ? (<>
                                    <StyledTextData>{`${t("City (of most members)")}: ${valueOrEmpty(team.address?.city)}`}</StyledTextData>
                                    <StyledTextData>{`${t("Phone number")}: ${valueOrEmpty(team.phone_number)}`}</StyledTextData>
                                    <StyledTextData>{`${t("Company size")}: ${valueOrEmpty(t(team.sectors_size_display))}`}</StyledTextData>
                                    <StyledTextData>{`${t("Team Size")}: ${valueOrEmpty(team.number_of_employees_display)}`}</StyledTextData>
                                </>) : (<>
                                    <StyledTextData>{`${t("City (of most members)")}: ${valueOrEmpty(team.address?.city)}`}</StyledTextData>
                                    <StyledTextData>{`${t("Phone number")}: ${valueOrEmpty(team.phone_number)}`}</StyledTextData>
                                </>)}
                            </WideSpace>
                            <WideSpace direction="vertical" size="small">
                                <StyledTextTitle>{t("Categories")}:</StyledTextTitle>
                                <Row gutter={[0, 8]}>
                                    <Col span={24} md={12}>
                                        <WideSpace direction="vertical"
                                                   size="small">
                                            {team.sectors_persona &&
                                                <div><StyledTextData
                                                    type="secondary">{t("Company sectors")}:</StyledTextData><TagsList
                                                    tags={team.sectors_persona}
                                                    defaultValue={'-'}/></div>}
                                            {team.sectors_institution &&
                                                <div><StyledTextData
                                                    type="secondary">{t("Company personas")}:</StyledTextData><TagsList
                                                    tags={team.sectors_institution}
                                                    defaultValue={'-'}/></div>}
                                            {team.sectors_technology &&
                                                <div><StyledTextData
                                                    type="secondary">{t("Technologies")}:</StyledTextData><TagsList
                                                    tags={team.sectors_technology}
                                                    defaultValue={'-'}/></div>}
                                        </WideSpace>
                                    </Col>
                                    <Col span={24} md={12}>
                                        <WideSpace direction="vertical"
                                                   size="small">
                                            {team.sectors_transaction &&
                                                <div><StyledTextData
                                                    type="secondary">{t("Transactions type")}:</StyledTextData><TagsList
                                                    tags={team.sectors_transaction}
                                                    defaultValue={'-'}/></div>}
                                            {team.sectors_client &&
                                                <div><StyledTextData
                                                    type="secondary">{t("Client sectors")}:</StyledTextData><TagsList
                                                    tags={team.sectors_client}
                                                    defaultValue={'-'}/></div>}
                                        </WideSpace>
                                    </Col>
                                </Row>
                            </WideSpace>
                        </WideSpace>
                    </Col>
                </Row>
            }
        </Skeleton>
    )
}