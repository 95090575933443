import React from 'react';
import {Switch, useRouteMatch} from "react-router-dom";
import {ManagerRoute} from "../../../../routes/PrivateRoute";
import DescriptionProgramTab from "../components/DescriptionProgramTab";
import ScheduleProgramTab from "../components/ScheduleProgramTab";
import ChallengesProgramTab from "../components/ChallengesProgramTab";
import MessagesProgramTab from "../components/MessagesProgramTab";
import AttachmentsProgramTab from "../components/AttachmentsProgramTab";
import {useProgramDetailContext} from "../context/ProgramDetailContext";
import {usePermissionContext} from "../../../../context/PermissionContext";

export default function ProgramDetailRouter({program}) {
    const {path} = useRouteMatch();
    const {hasProgramPermission} = useProgramDetailContext()
    const {programHasChallengeMappingPermission} = usePermissionContext()

    console.log(path)

    return (
        <Switch>
            <ManagerRoute
                exact
                path={`${path}`}
                hasPermission={true}
                component={DescriptionProgramTab}
            />
            <ManagerRoute
                exact
                path={`${path}/schedule`}
                hasPermission={true}
                component={ScheduleProgramTab}
            />
            <ManagerRoute
                exact
                path={`${path}/challenges`}
                hasPermission={!programHasChallengeMappingPermission(program)}
                component={ChallengesProgramTab}
            />
            <ManagerRoute
                exact
                path={`${path}/messages`}
                hasPermission={hasProgramPermission !== false}
                component={MessagesProgramTab}
            />
            <ManagerRoute
                exact
                path={`${path}/attachments`}
                hasPermission={true}
                component={AttachmentsProgramTab}
            />
        </Switch>
    );
}

