import styled from "styled-components";
import {Card, Typography} from "antd";
import {neutral_2} from "../../../../../../../../utils/Colors";
import {useTranslation} from "react-i18next";
import Flex from "../../../../../../../responsive/Flex";
import React from "react";

const {Text} = Typography


const StyledFeedbackCard = styled(Card)`
    background: ${neutral_2};
    border-radius: 8px;
`

export default function PhaseFeedbackCard({feedback}) {
    const {t} = useTranslation()
    return (
        feedback ?
            <StyledFeedbackCard>
                <Flex vertical={true}>
                    <Text>{t("Program Feedback")}:</Text>
                    {feedback}
                </Flex>
            </StyledFeedbackCard> : <></>
    )
}
