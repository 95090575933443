
import styled from "styled-components";
import RadioButton from "../data_entry/RadioButton";


const StyledRadioButton = styled(RadioButton)`
  display: ${({block}) => block && "flex"};
  .ant-radio-button-wrapper{
    flex-grow: 1;
    //overflow: hidden;
  }
`

export default function GrowRadioButton({...props}){
    return(
        <StyledRadioButton {...props} />
    )
}