import React from 'react';

function ManageProgramsIcon({fill, className="anticon", height="14", width="14"}) {
    return (
        <span className={className}>
            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32" height={height} width={width} fill={fill} >
                <title>Vector-svg</title>
                <path id="Layer" fill-rule="evenodd" class="s0"
                      d="m15.8 0h11.5c0.4 0 0.6 0.3 0.6 0.6v12.3c0 0.3-0.2 0.6-0.6 0.6h-11.5c-0.3 0-0.6-0.3-0.6-0.6v-12.3c0-0.3 0.3-0.6 0.6-0.6zm2 10.8h7.5v-8.1h-7.5zm-17.2-10.7h11.5c0.4 0 0.7 0.3 0.7 0.6v12.2c0 0.4-0.3 0.7-0.7 0.7h-11.5c-0.3 0-0.6-0.3-0.6-0.7v-12.2c0-0.3 0.3-0.6 0.6-0.6zm2 10.7h7.6v-8h-7.6zm-2 5.3h11.5c0.4 0 0.7 0.3 0.7 0.6v12.3c0 0.3-0.3 0.6-0.7 0.6h-11.5c-0.3 0-0.6-0.3-0.6-0.6v-12.3c0-0.3 0.3-0.6 0.6-0.6zm2 10.8h7.6v-8.1h-7.6z"/>
                <path id="Layer" fill-rule="evenodd" class="s0"
                      d="m15 23.6l1-0.9q-0.1-0.5-0.1-0.9 0-0.5 0.1-0.9l-1-0.9q-0.1-0.1-0.1-0.2-0.1-0.2 0-0.3v-0.1q0.4-1.2 1.1-2.1l0.1-0.1q0.1-0.1 0.2-0.1 0.1-0.1 0.3 0l1.2 0.4q0.6-0.5 1.4-0.9l0.2-1.3q0.1-0.2 0.2-0.3 0.1-0.1 0.2-0.1c0.8-0.2 1.6-0.2 2.3 0h0.1q0.1 0 0.2 0.1 0.1 0.1 0.2 0.3l0.2 1.3q0.8 0.4 1.4 0.9l1.2-0.4q0.1-0.1 0.3 0 0.1 0 0.2 0.1v0.1q0.8 0.9 1.2 2.1v0.1c0.1 0.2 0 0.4-0.1 0.5l-1 0.9q0.1 0.4 0.1 0.9 0 0.4-0.1 0.9l1 0.9q0.1 0.1 0.1 0.2 0.1 0.2 0 0.3v0.1q-0.4 1.1-1.2 2.1-0.1 0.2-0.2 0.2-0.2 0-0.3 0l-1.2-0.5q-0.6 0.6-1.4 0.9l-0.2 1.4q-0.1 0.1-0.2 0.2-0.1 0.1-0.2 0.2h-0.1q-1.1 0.2-2.3 0-0.1-0.1-0.2-0.2-0.1-0.1-0.2-0.2l-0.2-1.4q-0.8-0.3-1.4-0.9l-1.2 0.5q-0.2 0-0.3 0-0.1 0-0.2-0.2h-0.1q-0.7-1-1.1-2.1v-0.1c-0.1-0.2 0-0.4 0.1-0.5zm2-2.5q-0.1 0.3-0.1 0.7 0 0.4 0.1 0.7l0.1 0.6-1.1 1q0.3 0.6 0.6 1.2l1.4-0.5 0.4 0.4q0.5 0.4 1.2 0.7l0.5 0.2 0.3 1.5q0.6 0.1 1.2 0l0.3-1.5 0.5-0.2q0.6-0.3 1.2-0.7l0.4-0.4 1.4 0.5q0.3-0.6 0.6-1.2l-1.1-1 0.1-0.6q0-0.4 0-0.7 0-0.4 0-0.7l-0.1-0.6 1.1-1.1q-0.3-0.6-0.6-1.1l-1.4 0.5-0.4-0.4q-0.6-0.5-1.2-0.7l-0.5-0.2-0.3-1.6q-0.6 0-1.2 0l-0.3 1.6-0.5 0.2q-0.7 0.2-1.2 0.7l-0.4 0.4-1.4-0.5q-0.3 0.5-0.6 1.1l1.1 1zm4-2.1c1.4 0 2.5 1.2 2.5 2.7 0 1.5-1.1 2.8-2.5 2.8-1.4 0-2.6-1.3-2.6-2.8 0-1.5 1.2-2.7 2.6-2.7zm-1.2 3.9q0.3 0.3 0.6 0.4 0.3 0.2 0.6 0.2c0.4 0 0.8-0.2 1.1-0.6q0.3-0.2 0.4-0.5 0.1-0.3 0.1-0.7c0-0.5-0.2-0.9-0.5-1.2-0.3-0.4-0.7-0.5-1.1-0.5-0.4 0-0.9 0.1-1.2 0.5q-0.2 0.2-0.3 0.5-0.1 0.4-0.1 0.7c0 0.5 0.1 0.9 0.4 1.2z"/>
            </svg>
        </span>
    );
}

export default ManageProgramsIcon;

