import React, {useRef} from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import CarouselPro from "../../../../components/misc/CarouselPro";
import CampaignStoryCard from "./CampaignStoryCard";
import useSystemBreakpoint from "../../../../hooks/useSystemBreakpoint";
import {Flex} from "antd";

const FullScreenDiv = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const BackgroundDiv = styled.div`
    position: fixed;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    height: 100vh;
    width: 100vw;
`

const CloseButton = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    color: white;
`;

const ContainerCard = styled.div`
    &.enabled {
        padding: 5px;
        z-index: 2;
        transform: none;
        opacity: 1;
        transition: transform 0.3s ease, opacity 0.3s ease;
    }

    &.disabled {
        transform: scale(0.95);
        filter: brightness(0.3);
        background: rgba(0, 0, 0, 0.8);
        border-radius: 16px;
        transition: transform 0.3s ease, opacity 0.3s ease;
    }

    transition: all .3s ease;

`

export default function CampaignsFullScreenModal({
                                                     isVisible,
                                                     onClose,
                                                     campaigns,
                                                     activeIndex,
                                                     setActiveIndex,
                                                 }) {
    if (!isVisible) return null;

    const getPageSize = () => {
        if (campaigns?.length === 1) {
            return 1
        }

        if (campaigns?.length >= 5) {
            return 5
        }
        return campaigns?.length
    }

    const isFirstScroll = useRef(false);

    const handleOnClose = () => {
        isFirstScroll.current = true
        onClose && onClose()
    }

    const {isPhone} = useSystemBreakpoint()

    const onScrollRight = () => {
        let indexDifference = isFirstScroll.current ? 0 : 1
        isFirstScroll.current = false
        setActiveIndex(prevState => prevState + indexDifference)
        if (activeIndex > (campaigns?.length - 2)) {
            setActiveIndex(0)
        }
    }

    const onScrollLeft = () => {
        let indexDifference = isFirstScroll.current ? 0 : 1
        isFirstScroll.current = false
        setActiveIndex(prevState => prevState - indexDifference)
        if (activeIndex < 1) {
            setActiveIndex(campaigns?.length - 1)
        }
    }

    return ReactDOM.createPortal(
        <FullScreenDiv>
            <BackgroundDiv onClick={handleOnClose}/>
            <CloseButton onClick={handleOnClose}>X</CloseButton>
            {campaigns && activeIndex !== false &&

                (campaigns.length > 5 || isPhone) ?

                <CarouselPro
                    pageSize={getPageSize()}
                    autoplaySpeed={3000}
                    changePageOnItemClick={true}
                    justifyWhenCantNavigate={"center"}

                    onLoad={({setItemIndex}) => {
                        setItemIndex(activeIndex)
                    }}

                    onScrollRight={onScrollRight}
                    onScrollLeft={onScrollLeft}
                    onAutoScroll={({canNavigate}) => {
                        if(!canNavigate){
                            void onScrollRight()
                        }
                    }}

                    autoScrollDeps={[activeIndex]}

                    renderItem={(item) => (
                        <Flex style={{width:"100%"}} justify="center">
                            <Flex style={{width:317}}>
                                {item}
                            </Flex>
                        </Flex>
                    )}

                >
                    {campaigns?.map((item, index) => {
                        return (
                            <ContainerCard
                                id={"card - " + index}
                                className={activeIndex === index ? "enabled" : "disabled"}
                                onClick={(e) => {
                                    setActiveIndex(index)
                                }}
                            >
                                <CampaignStoryCard campaignItem={item}/>
                            </ContainerCard>
                        )
                    })}
                </CarouselPro>
                :
                <Flex justify="center" gap={16} style={{width:"100%"}}>
                    {campaigns?.map((item, index) => {
                        return (
                            <Flex style={{width:317}}>
                                <ContainerCard
                                    id={"card - " + index}
                                    className={activeIndex === index ? "enabled" : "disabled"}
                                    onClick={(e) => {
                                        setActiveIndex(index)
                                    }}
                                >
                                    <CampaignStoryCard campaignItem={item}/>
                                </ContainerCard>
                            </Flex>
                        )
                    })}
                </Flex>
            }
        </FullScreenDiv>,
        document.body
    );
}
