import React from "react";
import styled from 'styled-components'
import {useHistory} from "react-router-dom";
import {useThemeContext} from "../../global/context/ThemeContext";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const MainContent = styled.div`
  width: 100%;
  height: 50px;
  background: ${(props) => props.background};
  //position: fixed;
  display: flex;
  justify-content: center;
  top: 0;
  font-size: 0.8rem;
`

const MaxWidthContent = styled(Row)`
  max-width: 1280px;
  width: 100%;
  height: 50px;
  background: ${(props) => props.background};
  display: flex;
  justify-content: center;
  top: 0;
`

const FirstContent = styled(Col)`
  background-color: inherit;
  //margin: 0 !important;
  height: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
  //position: fixed;
  max-width: 1280px;
  z-index: 1;
  top: 0;
  display: flex;
  flex: 2;
`
const LastContent = styled(Col)`
  background-color: inherit;
  //margin: 0 !important;
  //padding:0;
  height: 50px;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  //position: fixed;
  max-width: 1280px;
  z-index: 1;
  top: 0;
  display: flex;
  flex: 1;
  //padding-right: 20px;
`

const LogoContent = styled.div`
  flex: 1;
  //margin-left: 20px;
  align-items: center;
  //justify-content: center;
  display: flex;

`

const Logo = styled.img`
  max-height: 40px;
  max-width: 90px;
  margin-right: 20px;
  cursor: pointer;
`

const LinkStyle = styled.a`
  border-radius: 8px;
  border: 0;
  margin-right: 20px;
    white-space: nowrap;
  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }
`

const LoginButton = styled.button`
  padding: 6px 16px;
  border-radius: 8px;
  border: 1px solid transparent;
white-space: nowrap;
  &:hover {
    border: 1px solid white;
  }
`

function PublicActionBar() {
    const history = useHistory();
    const {themeConfig} = useThemeContext()
    const {t} = useTranslation();

    const Redirect = (type) => {
        localStorage.setItem("show_program", history.location.pathname);
        if (type === "Login") {
            history.push('/login')
        } else {
            history.push('/register')
        }

    }

    return (
        <MainContent background='var(--background_menu)'>
            <MaxWidthContent>
                <FirstContent>
                    <LogoContent>
                        <Logo src={themeConfig.img_action_bar} alt={'logo'}
                              onClick={() => history.push('/')}/>
                    </LogoContent>
                </FirstContent>
                <LastContent>
                    {/*<ButtonDefault color={'secondary'} label={'Sign in'}*/}
                    {/*               style={{marginRight:20}}*/}
                    {/*    onClick={() => history.push('/register')}*/}
                    {/*/>*/}
                    <LinkStyle onClick={() => Redirect("Register")}
                        // href="/register"
                               style={{color: `var(--text_color_menu)`}}>{t("Create an account")}</LinkStyle>
                    <LoginButton style={{
                        background: `var(--primary_btn_color)`,
                        color: `var(--primary_btn_text_color)`
                    }}
                                 onClick={() => Redirect("Login")}
                    >{t("Login")}</LoginButton>
                </LastContent>
            </MaxWidthContent>
        </MainContent>
    )
}

export default PublicActionBar