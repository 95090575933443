import {ProjectHomeContextProvider} from "./context/ProjectHomeContext";
import {ProjectManagementRoute} from "../../../../global/routes/PrivateRoute";
import ProjectHomePage from "./ProjectHomePage";
import {Redirect, Switch, useLocation, useRouteMatch} from "react-router-dom";
import ProjectDashboardPage from "./ProjectDashboardPage";
import ProjectSettingsPage from "./ProjectSettingsPage";
import React from "react";
import {usePermissionContext} from "../../../../global/context/PermissionContext";

function ProjectHome() {
    const {path} = useRouteMatch();
    const location = useLocation();
    const {hasProjectAdminOrManagerPermission, hasProjectAdminPermission, hasProjectPortfolioMenuAccessPermission} = usePermissionContext();
    return (
        <ProjectHomeContextProvider>
            <Switch>
                <ProjectManagementRoute
                    exact
                    path={`${path}`}
                    hasPermission={hasProjectPortfolioMenuAccessPermission}
                    render={(props) => <ProjectDashboardPage {...props} />}
                />
                <ProjectManagementRoute
                    exact
                    path={`${path}/list`}
                    hasPermission={hasProjectAdminOrManagerPermission}
                    render={(props) => <ProjectHomePage {...props} />}
                />
                <ProjectManagementRoute
                    exact
                    path={`${path}/settings`}
                    hasPermission={hasProjectAdminPermission}
                    render={(props) => <ProjectSettingsPage {...props} />}
                />
                <Redirect to={{pathname: '/404', state: {broken_url: window.location.host + location.pathname}}}/>
            </Switch>
        </ProjectHomeContextProvider>);
}

export default ProjectHome;