import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {neutral_2} from "../../../../utils/Colors";
import styled from 'styled-components';
import {Col, Row, Typography} from "antd";
import DrawerProjectSelectChart from "../components/DrawerProjectSelectChart";
import {
    ProjectChartsFactory
} from "../components/ProjectChartFactory";
import {
    FlexContainer
} from "../../../../components/authentication/StyledComponents";
import ButtonFilterProjectsChart
    from "../components/ButtonFilterProjectsChart";
import {useInnovationPanelContext} from "../context/InnovationPanelContext";
import Loading from "../../../../components/feedback/Loading";

const Container = styled.div`
    margin-top: 24px;
    background: ${neutral_2};
    padding: 16px;
    border-radius: 12px;
`


export default function SectionProjectChartsBuild() {
    const {t} = useTranslation();
    const {
        initialDate,
        finalDate,isLoadingUpdateCharts,
        projects, isLoadingIndicators,
        projectChartList, deleteProjectChart,
        indicatorsProject, updateColumnProjectChart,
        projectsFilters, PROJECT_FILTER_FORM_NAMES
    } = useInnovationPanelContext()

    const [open, setOpen] = useState(false)
    const positionRef = useRef()

    function handleOpenDrawer(position) {
        setOpen(true)
        positionRef.current = position
    }

    return (
        <>
            <Container>
                <Row gutter={[19, 19]}>
                    <Col xs={24}>
                        <FlexContainer justify={'space-between'}>
                            <Typography.Title level={5}>
                                {t('Projects')}
                            </Typography.Title>
                            <ButtonFilterProjectsChart/>

                        </FlexContainer>

                    </Col>
                    {isLoadingIndicators && <Col xs={24}><Loading/></Col>}
                    {!isLoadingIndicators && projectChartList.map(({type, columnSize=1}, position) => (
                         <Col xs={24} lg={8 * columnSize} key={position}>
                            <ProjectChartsFactory type={type?.split('-')[1]}
                                                  isLoadingUpdateCharts={isLoadingUpdateCharts}
                                                  columnSize={columnSize}
                                                  indicator={indicatorsProject?.find((item) => type?.split('-')[0] == item.id)}
                                                  initialDate={initialDate}
                                                  finalDate={finalDate}
                                                     projects={projectsFilters[PROJECT_FILTER_FORM_NAMES.PROJECTS__ID__IN]}
                                                  bodyFilters={projectsFilters}
                                                   onChangeColumns={(columnSize) => updateColumnProjectChart(position, columnSize)}
                                                  onDelete={() => deleteProjectChart(position)}
                                                  handleOpenDrawer={() => handleOpenDrawer(position)}/>
                        </Col>

                    ))}

                </Row>
            </Container>
            <DrawerProjectSelectChart open={open} setOpen={setOpen}
                                      position={positionRef.current}/>

        </>
    )
}