import styled from 'styled-components';
import {Alert, Layout} from "antd";
import {
    useLayoutContext
} from "../../modules/project/components/layout/context/LayoutContext";
import React, {useEffect} from "react";
import WelcomeModal from '../pages/WelcomeModal';
import SolvTourModalList
    from "../../modules/project/components/modal/SolvTourModalList";
import ActionBar from "../../components/HeaderPage/ActionBar";
import Menu from "../components/layout/Menu";
import useSystemBreakpoint from '../hooks/useSystemBreakpoint';
import AlertBlockModal from "../components/feedback/AlertBlockModal";
import {useTranslation} from "react-i18next";
import {useUserContext} from "../context/UserContext";
import {useThemeContext} from "../context/ThemeContext";
import {usePermissionContext} from "../context/PermissionContext";
import {useLocation} from "react-router-dom";
import {DEBUG} from "../../settings";
import DevTools from "../components/other/DevTools";
import {page_background} from "../utils/Colors";
import InviteOffCanvas from "../../components/InviteOffCanvas";

const {Content} = Layout;

const StyledContentOld = styled.div` //todo validar pra remover depois
    border-radius: 4px;
    max-width: ${({fullWidth}) => fullWidth ? "100%" : "1200px"};
    margin: auto;
    overflow: visible;

    background-color: ${(props) => props.noBackground ? "transparent" : "white"};

`;

const StyledContent = styled(Content)`
    background-color: ${({customBackgroundColor}) => customBackgroundColor ? customBackgroundColor : page_background};
    padding: ${({isDesktop}) => isDesktop ? '24px' : '8px'};
    height: 100%;
    position: relative;
    overflow: auto;
`

export default function LayoutSystem({content}) {
    const {isDesktop} = useSystemBreakpoint();
    const {t} = useTranslation()
    const {_user} = useUserContext();
    const location = useLocation()
    const {themeConfig} = useThemeContext()
    const {
        hasFullAdminPermission,
    } = usePermissionContext();
    const {
        customBackgroundColor,
        isConfidential,
        useOldContentLayout,
        confidentialMessage,
        refLayoutContent,
        hasActionBar,
        shouldUpdateContent,
    } = useLayoutContext();

    const renderContent = () => {
        // if (isConfidential) {
        //     content = <ConfidentialContent message={confidentialMessage} />;
        // }
        return (
            <StyledContentOld
                noMargin={useOldContentLayout.noMargin}
                fullWidth={true}
                noBackground={!useOldContentLayout || useOldContentLayout.noBackground}>
                {content}
            </StyledContentOld>
        )
    }

    useEffect(() => {
        if (refLayoutContent.current) {
            refLayoutContent.current.scrollTo(0, 0)
        }
    }, [location])

    return (
        <>
            {DEBUG && <DevTools/>}
            <Layout id="solv-layout" style={{
                minHeight: "100vh",
                maxHeight: "100vh",
                overflow: 'hidden'
            }}>
                <Menu/>
                {/*user_in_queue*/}
                <Layout>
                    {hasActionBar && <ActionBar/>}

                    {_user.user_in_queue && <Alert
                        message={t('Your user is in a waiting queue.')}
                        type="warning"/>}
                    {hasFullAdminPermission && themeConfig?.client?.user_limit_reached &&
                        <Alert
                            message={t('You have reached the maximum number of users for your plan and cannot add more members. Upgrade to add more members.')}
                            type="warning"/>}
                    <StyledContent
                        isDesktop={isDesktop}
                        ref={refLayoutContent}
                        customBackgroundColor={customBackgroundColor}>
                        {!shouldUpdateContent && renderContent()}
                    </StyledContent>
                </Layout>
            </Layout>
            <AlertBlockModal/>
            <WelcomeModal/>
            <SolvTourModalList/>
            <InviteOffCanvas />
        </>
    );
}