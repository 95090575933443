import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Skeleton, Table as TableAnt} from "antd";

import useSystemBreakpoint from "../../hooks/useSystemBreakpoint";
import {maskIntNumber} from "../../utils/Utils";
import Empty from "./Empty";
import {useTranslation} from "react-i18next";

const StyledEmpty = styled(Empty)`
    padding: 73px;
`
const StyledTable = styled(TableAnt)`

    .ant-table-content {
        overflow-x: auto;
    }

    tr:hover td {
        cursor: ${({hasClick}) => hasClick ? 'pointer' : 'default'};
    }

`

export default function Table({
                                  onRowClick,
                                  tableParams,
                                  emptyDescription,
                                  scroll,
                                  noPagination = false,
                                  columns,
                                  noScroll = false,
                                  showQuickJumper = true,
                                  showSizeChanger = true,
                                  tableHeightDiff = 0, // Diminui em pixels do scrool da tabela
                                  hideOnSinglePage = true,
                                  rowKey = 'id',
                                  ...props
                              }) {
    const {isPhone, isTablet} = useSystemBreakpoint();
    const tableRef = useRef();
    const {t} = useTranslation()

    function getObjectValue(obj, columnName) {
        // debugger

        if (obj && columnName.includes('.')) {
            let names = columnName.split('.')
            let item = obj[names[0]]
            if (item) {
                return getObjectValue(item, names.slice(1,).join('.'))
            }
        }
        return <>{obj[columnName] !== null && obj[columnName] ? obj[columnName] : '-'}</>
    }

    const _get_columns = () => {
        return columns.map((column) => {
            if (tableParams?.sorter && column.dataIndex === tableParams?.sorter.field) {
                column['defaultSortOrder'] = tableParams?.sorter.order
            }
            if (!column.render && !column.noEmptyIcon) {
                column.render = (value, record) => {
                    return getObjectValue(record, column.dataIndex)
                }
            }
            return column
        })
    }

    return (
        <>
            <StyledTable
                ref={tableRef}
                size={'large'}
                showSorterTooltip={false}
                hasClick={!!onRowClick}
                onRow={(record) => {
                    return {
                        onClick: (e) => onRowClick && onRowClick(record, event)
                        // onDoubleClick: (e) => onRowClick && onRowClick(record)
                    }
                }}
                pagination={noPagination ? false : {
                    hideOnSinglePage: hideOnSinglePage,
                    ...tableParams?.pagination,
                    showQuickJumper: showQuickJumper,
                    locale: {
                        jump_to: t('go to'),
                        page: '',
                    },
                    position: isPhone && ["bottomCenter"],
                    showTotal: (total, range) => `${maskIntNumber(total)} ${t('results')}`,
                    // showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    size: 'small',
                    showSizeChanger: showSizeChanger
                }}
                locale={{
                    sortTitle: '',
                    emptyText: <StyledEmpty
                        description={emptyDescription && emptyDescription}/>,
                }}
                columns={_get_columns()}
                scroll={{x: "max-content", ...scroll}}
                rowKey={rowKey}
                {...props}
            />
        </>
    )
}