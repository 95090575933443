import {Flex, Space, Typography} from "antd";
import UserAvatar from "../../../components/misc/UserAvatar";
import ApplicationTagStatus
    from "../../../components/tag/ApplicationTagStatus";
import React from "react";
import {ASSESSMENT_STATUS_COLOR, COLORS} from "../../../utils/Colors";
import TagsList from "../../../components/tag/TagsList";
import TruncateText from "../../../components/responsive/TruncateText";
import {DateFormatByUserTimezone, DateFormatUser} from "../../../utils/dates";
import {useTranslation} from "react-i18next";
import {renderAvatars} from "../../../components/tables/utils";
import SolvTag from "../../../components/tag/SolvTag";
import {useUserContext} from "../../../context/UserContext";
import {PushpinOutlined} from "@ant-design/icons";
import api from "../../../services/api";
import {
    renderIndicator
} from "../../../../modules/project/components/tables/utils";
import {Link} from "react-router-dom";
import DangerousHtmlPreviewer
    from "../../../components/data_display/DangerousHtmlPreviewer";

const {Text} = Typography

export function setupDefaultProps(columns) {
    return columns.map((column, index) => {
        if (!column?.render) {
            column.render = (value) => {
                if (!value) {
                    return "-"
                }
                return value
            }
        }
        column.sequence = index
        column.visible = column.visible || true
        return column
    })
}


function getColumn(title, dataIndex, sorter, width) {
    return {title, dataIndex, sorter, width, key: dataIndex + "-key"}
}


function renderStringAsTags(value) {
    if (!value) return "-";
    let _colors = [...COLORS];
    const tags = value.split(",").map((v) => {
        const randomIndex = Math.floor(Math.random() * _colors.length);
        const color = _colors[randomIndex];
        _colors.splice(randomIndex, 1);
        return {name: v.trim(), color};
    });
    return <TagsList tags={tags} maxSize={1}/>;
}


function renderTruncate(value) {
    return <TruncateText>{value || "-"}</TruncateText>
}


function renderDate(value) {
    if (!value) return "-"
    return DateFormatByUserTimezone(value) || "-"
}

function renderAvatar(value, record) {
    if (!value) return "-";
    return (
        <Flex align="center" gap={8}>
            <UserAvatar data={typeof value === "object" ? value : record}/>
            <Text>{typeof value === "object" ? value?.name : value}</Text>
        </Flex>
    )
}

function translateOptionsString(options, t) {
    options = options.split(", ")
    options = options.map(item => t(item))
    return options.join(", ")
}

export const applicationColumns = () => {
    const {t} = useTranslation()
    const {_user} = useUserContext();
    return (
        setupDefaultProps([
            getColumn(t("Code"), ["code"], "code", "50px"),
            {
                ...getColumn(t("Application Title"), ["name"], "name", "400px"),
                render: renderTruncate,
            },
            {
                ...getColumn(t("Status"), ["status_display"], "status"),
                render: (value, record) => {
                    if (!value) return '-';
                    return <ApplicationTagStatus application={record}/>
                },
            },
            {
                ...getColumn(t("Author"), ["team"], "team__name"),
                render: (value, record) => {

                    if (!record.team) {
                        return record.author_name
                    }
                    return renderAvatar(value, record)
                },
                exportKey: "team.name"
            },
            {
                ...getColumn("Email",  ["team", "email"], "team_email"),
                render: (value, record) => {

                    if (!record.team) {
                        return record.author_email
                    }
                    return value
                },
            },
            {
                ...getColumn(t("Phone number"), ["team", "phone_number"], "team__phone_number"),
                render: (value, record) => {

                    if (!record.team) {
                        return record.author_phone
                    }
                    return value
                },
            },
            getColumn(t("Headquarters address"), ["team", "address_str"], "team__address"),
            getColumn(t("Application Type"), ["team", "type_display"], "team__type"),
            getColumn(t("Score"), ["score_average"], "score_average"),
            getColumn(t("No weight"), ["score_unweighted"], "score_unweighted"),
            {
                ...getColumn(t("Program"), ["program", "name"], "program", "400px"),
                render: renderTruncate
            },
            {
                ...getColumn(t("Challenge"), ["challenge_name"], "challenge", "400px"),
                render: renderTruncate
            },
            getColumn(t("Current Phase"), ["current_phase", "title"], "current_phase"),
            {
                ...getColumn(t("Application date"), ["date_create"], "date_create"),
                render: renderDate,
                exportCallback: (value) => DateFormatUser(value, _user)
            },
            // {
            //     ...getColumn(t("Short Description"), ["team", "short_description"], "team__short_description"),
            //     width: "400px",
            //     render: renderTruncate,
            // },
            // getColumn(t("Document ID"), ["team", "cnpj"], "team__cnpj"),
            // {
            //     ...getColumn(t("Start of operation"), ["team", "date_start_operating"], ["team__date_start_operating"]),
            //     render: renderDate,
            //     exportCallback: (value) => DateFormatUser(value, _user)
            // },
            // {
            //     ...getColumn(t("Description"), ["team", "description"], "team__description"),
            //     width: "400px",
            //     render: renderTruncate
            // },
            // getColumn(t("Number of employees"), ["team", "number_of_employees"], "team__number_of_employees"),
            // getColumn(t("Size"), ["team", "sectors_size_str"], "team__sectors_size"),
            // {
            //     ...getColumn(t("Company sectors"), ["team", "sectors_institution_str"]),
            //     render: renderStringAsTags,
            //     exportCallback: (value) => translateOptionsString(value, t)
            // },
            // {
            //     ...getColumn(t("Technologies"), ["team", "sectors_technology_str"]),
            //     render: renderStringAsTags,
            //     exportCallback: (value) => translateOptionsString(value, t)
            // },
            // {
            //     ...getColumn(t("Client sectors"), ["team", "sectors_client_str"]),
            //     render: renderStringAsTags,
            //     exportCallback: (value) => translateOptionsString(value, t)
            // },
            // getColumn(t("Site"), ["team", "site"], "team__site"),
            // getColumn("Twitter", ["team", "twitter"], "team__twitter"),
            // getColumn("Facebook", ["team", "facebook"], "team__facebook"),
            // getColumn("Instagram", ["team", "instagram"], "team__instagram"),
            // getColumn("Linkedin", ["team", "linkedin"], "team__linkedin"),
            // getColumn(t("Máx"), ["score_max"], ["score_max"], "score_max"),
            // getColumn(t("Min"), ["score_min"], ["score_min"], "score_min"),
            // getColumn(t("Standard Deviation"), ["standard_deviation"], "standard_deviation"),
            // getColumn(t("Sum"), ["score_sum"], "score_sum"),
        ])
    )
}


export const companyColumns = () => {
    const {t} = useTranslation()
    const {_user} = useUserContext()
    return (
        setupDefaultProps([
                {
                    ...getColumn(t("Name"), ["name"], "name"),
                    render: renderAvatar
                },
                getColumn(t("Application Type"), ["type_display"], "team__type"),
                getColumn(t("Document ID"), ["cnpj"], "cnpj"),
                getColumn(t("Email"), ["email"], "email"),
                {
                    ...getColumn(t("Short description"), ["short_description"], "short_description", "400px"),
                    render: renderTruncate
                },
                {
                    ...getColumn(t("Description"), ["description"], "description", "400px"),
                    render: renderTruncate
                },
                {
                    ...getColumn(t("Start of operation"), ["date_start_operating"], "date_start_operating"),
                    render: renderDate,
                    exportCallback: (value) => DateFormatUser(value, _user)
                },
                getColumn(t("Last program application"), ["most_recent_program_environment"]),
                getColumn(t("Total applications"), ["applications_environment"]),
                {
                    ...getColumn(t("First application Date"), ["first_application_environment"]),
                    render: renderDate,
                    exportCallback: (value) => DateFormatUser(value, _user)
                },
                getColumn(t("Headquarters address"), ["address_str"], "address"),
                getColumn(t("Phone number"), ["phone_number"], "phone_number"),
                {
                    ...getColumn(t("Company sectors"), ["sectors_institution_str"]),
                    render: renderStringAsTags,
                    exportCallback: (value) => translateOptionsString(value, t)
                },
                {
                    ...getColumn(t("Company personas"), ["sectors_persona_str"]),
                    render: renderStringAsTags,
                    exportCallback: (value) => translateOptionsString(value, t)
                },
                {
                    ...getColumn(t("Technologies"), ["sectors_technology_str"]),
                    render: renderStringAsTags,
                    exportCallback: (value) => translateOptionsString(value, t)
                },
                {
                    ...getColumn(t("Transaction type"), ["sectors_transaction_str"]),
                    render: renderStringAsTags,
                    exportCallback: (value) => translateOptionsString(value, t)
                },
                {
                    ...getColumn(t("Client sectors"), ["sectors_client_str"]),
                    render: renderStringAsTags,
                    exportCallback: (value) => translateOptionsString(value, t)
                },
                // getColumn(t("Size"), ["sectors_size"], "sectors_size"),
                // getColumn("Site", ["site"], "site"),
                // getColumn("Twitter", ["twitter"], "twitter"),
                // getColumn("Facebook", ["facebook"], "facebook"),
                // getColumn("Instagram", ["instagram"], "instagram"),
                // getColumn("Linkedin", ["linkedin"], "linkedin"),
            ]
        ))
}


export const memberColumns = () => {
    const {t} = useTranslation()
    const {_user} = useUserContext()
    return (
        setupDefaultProps([
            {
                ...getColumn(t("Name"), ["full_name"], "first_name"),
                render: renderAvatar
            },
            getColumn(t("Email"), ["email"], "email"),
            //numero de inscrições
            //numero de programas participante
            {
                ...getColumn(t("Biography"), ["biografy"]),
                width: "400px",
                render: renderTruncate
            },
            {
                ...getColumn(t("Birthday"), ["date_of_birth"], "date_of_birth"),
                render: renderDate,
                exportCallback: (value) => DateFormatUser(value, _user)
            },
            // é membro do ambiente?
            {
                ...getColumn(t("Teams"), ["teams"]),
                render: (value) => renderAvatars(value)
            },
            // empresas
            {
                ...getColumn(t("Address"), ["address"]),
                render: (value) => {
                    if (!value) return "-";
                    return (
                        <span
                            title={`${value.city}, ${value.country}`}>{`${value.city}, ${value.country}`}</span>);
                },
            },
            getColumn(t("Mobile Phone"), ["mobile_phone_number"]),
            getColumn(t("Gender"), ["genre"], "genre"),
            getColumn(t("Linkedin"), ["linkedin"]),
        ])
    )
}

export const assessmentColumns = () => {
    const {t} = useTranslation()
    return (
        setupDefaultProps([
            getColumn(t("Code"), ["application_code"], "application__code"),
            {
                ...getColumn(t("Application"), ["application_name"], "application__name", "400px"),
                render: renderTruncate
            },
            {
                ...getColumn(t("Evaluator"), ["evaluator_name"], "evaluate__user_client__user__first_name"),
                // render: renderAvatar
            },
            {
                ...getColumn("Status", ["status"], "status"),
                render: (value) => (
                    <SolvTag color={ASSESSMENT_STATUS_COLOR[value]}>
                        {t(value)}
                    </SolvTag>
                )
            },
            getColumn(t("Score"), ["score"], "score"),
            getColumn(t("No weight"), ["score_unweighted"], "score_unweighted"),
            //avaliações totais
            //quão interessante é a proposta
            //o que achou da proposta
            // deve ser aprovada (yes or no)
        ])
    )
}

export const programColumns = () => {
    const {t} = useTranslation()
    return setupDefaultProps([
        {
            title: t('Program Name'),
            dataIndex: 'name', // sorter: 'equipment__pin_17',
            key: 'name',
            ellipsis: true,
            width: '400px',
            sorter: "name",
            render: (value, record) => {
                return <Space size={8}>
                    <span data-cy={`program-row-${record.id}`}>{value}</span>
                    {record.is_innovation_program && <PushpinOutlined/>}
                </Space>
            }
        },
        {
            title: t('Applications'),
            dataIndex: 'valid_applications_count',
            key: 'valid_applications_count',
            width: '200px',
            render: (value) => {
                return <>
                    {value || '-'}</>
            }
        },
        // inscrições em preenchimento
        //tags
        {
            title: t('Date start'),
            dataIndex: 'date_start',
            key: 'date_start',
            width: '200px',
            sorter: "date_start",
            render: (value) => {
                return DateFormatByUserTimezone(value)
            }
        },
        {
            title: t('Date end'),
            dataIndex: 'date_end',
            key: 'date_end',
            width: '200px',
            sorter: "date_end",
            render: (value) => {
                return <>
                    {value ? DateFormatByUserTimezone(value) : '-'}</>
            }
        },
        //tipo
        //status
        //numero de etapas
        {
            title: t('Description'),
            dataIndex: 'description',
            key: 'description',
            render: (value) => (
                <div style={{width: 500}}>
                    <TruncateText>
                        <DangerousHtmlPreviewer content={value}/>
                    </TruncateText>
                </div>
            )
        },])
}

export const projectColumns = (indicatorColumns, t) => {
    return setupDefaultProps([
        // codigo do projeto
        {
            title: t("Project"),
            dataIndex: "name",
            key: "name",
            // fixed: isDesktop && "left",
            width: "300px",
            render: (_, record) => {
                return <Space size="middle"><Link
                    to={`/project-portfolio/project/${record.id}/dashboard`}
                    data-cy={`project-row-${record.id}`}>{record.name}</Link></Space>
            }
        },
        //status
        {
            title: t("Start Date"),
            dataIndex: "date_start",
            key: "date_start",
            render: renderDate
        },
        {
            title: t("End Date"),
            dataIndex: "date_end",
            key: "date_end",
            render: renderDate
        },
        // numero de task
        {
            title: "Checkpoints",
            dataIndex: "status_report_count",
            key: "status_report_count",
            width: "100px",
        },
        //frequencia checkpoint
        //numero de vinculos
        {
            title: "Stakeholders",
            dataIndex: "stakeholders",
            key: "stakeholders",
            width: "200px",
            render: renderAvatars,
        },
        ...indicatorColumns,
    ])
    // return indicatorColumns
}