import {createContext, useContext} from "react";

const StatusReportContext = createContext({});

export function StatusReportContextProvider({children, statusReport, statusReportForm, formIsValid, setFormIsValid, setStatusReport, fetchStatusReport}) {
    const updateIndicatorResponse = ({id, values}) => {
        setStatusReport({
            ...statusReport,
            "indicator_responses": statusReport["indicator_responses"].map((indicatorResponse) => {
                if (`${indicatorResponse.id}` === `${id}`) {
                    return {...indicatorResponse, ...values};
                }
                return indicatorResponse;
            }),
        });
    };
    return <>
        <StatusReportContext.Provider value={{statusReport, updateIndicatorResponse, statusReportForm, formIsValid, setFormIsValid, fetchStatusReport}}>
            {children}
        </StatusReportContext.Provider>
    </>;
}

export function useStatusReportContext() {
    return useContext(StatusReportContext);
}