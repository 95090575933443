import React, {useEffect, useState} from 'react';
import './style.css'
import {Link, useHistory, useParams, useRouteMatch} from "react-router-dom";
import {getProgram} from "../../../../global/utils/_query";
import {BuildFieldErrorsDict} from "../../../../global/utils/Utils";
import {useTranslation} from "react-i18next";
import ExtraHeader
    from "../../../../modules/project/components/layout/ExtraHeader";
import {
    useLayoutContext
} from "../../../../modules/project/components/layout/context/LayoutContext";
import {CONTINUOUS} from "../../../../global/utils/Choices";
import Form from "../../../../global/components/data_entry/form/Form";
import {Col, Row, Form as FormAnt} from "antd";
import SolvCollapse from "../../../../global/components/misc/SolvCollapse";
import InputFormField
    from "../../../../global/components/data_entry/form/InputFormField";
import SwitchFormField
    from "../../../../global/components/data_entry/form/SwitchFormField";
import Button from "../../../../global/components/data_entry/Button";
import useQuery from "../../../../global/hooks/useQuery";
import Loading from "../../../../global/components/feedback/Loading";
import {PHASE} from "../../../../global/utils/Constants";
import DatePickerFormField
    from "../../../../global/components/data_entry/form/DatePickerFormField";
import api from "../../../../global/services/api";
import {SUCCESS} from "../../../../global/utils/fileWithMessage";
import {FORM_NAME} from "./constants_phase";


export default function PageCreatePhase() {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false)
    const [form] = FormAnt.useForm()
    const [program, setProgram] = useState()
    const {id} = useParams()
    const query = useQuery()
    const {url} = useRouteMatch()

    const {t} = useTranslation();


    useEffect(() => {
        getProgram(id, setIsLoading, setProgram)
    }, []);

    const {initializeLayout} = useLayoutContext()

    useEffect(() => {
        if (program) {
            initializeLayout({
                extraHeaderContent: <ExtraHeader breadcrumbItems={[
                    {label: program.name, key: 1},
                    {label: "Create phase", key: 2}
                ]} returnPath={url.split("/").slice(0, -2).join("/")}/>,
                showAvatar: false,
                navbarMenuItems: [
                    {label: <Link to={url}>General</Link>, key: url}
                ],
            });
        }
    }, [program]);

    async function getFieldsValue() {
        const data = await form.getFieldsValue()
        const type = Number(query.get('type'))
        data['type'] = type
        data['sequence'] = query.get('sequence')
        data['program'] = String(program.id)
        data['is_evaluator'] = PHASE.INFORMATION !== type
        return data
    }

    const handleSubmit = async () => {
        try {
            setIsLoading(true)
            const data = await getFieldsValue()
            const response = await api.post(`/phases`, data);
            const phase = response.data
            let url = 'update'
            if (PHASE.EVALUATION === phase.type) url = 'evaluateform'
            else if (PHASE.APPLICATION === phase.type) url = 'form'

            history.push(`/programs/${id}/phases/${phase.id}/${url}`)
            ToastNotification(t(SUCCESS));

        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, null)
        } finally {
            setIsLoading(false);
        }
    };


    const isNotContinousProgram = program?.duration !== CONTINUOUS


    return (<>
        {!program ? <Loading/> : (
            <Form disabled={isLoading} form={form} onFinish={handleSubmit}>
                <Row gutter={[0, 24]}>
                    <Col xs={24}>
                        <SolvCollapse active bordered
                                      title={t("General")}>
                            <Row gutter={[24, 0]}>

                                <Col xs={24} md={12}>
                                    <InputFormField form={{
                                        name: FORM_NAME.TITLE,
                                        label: t('Title'),
                                        rules: [{
                                            required: true,
                                            message: t('This field is require')
                                        }]
                                    }} data-cy="title-input"/>
                                </Col>
                                <Col xs={24} md={12}>
                                    <InputFormField form={{
                                        name: FORM_NAME.SHORT_DESCRIPTION,
                                        label: t('Description'),

                                    }} data-cy="description-input"/>
                                </Col>
                                {isNotContinousProgram &&
                                    <Col xs={24} md={12}>
                                        <DatePickerFormField
                                            showTime
                                            form={{
                                                name: FORM_NAME.DATE_START,
                                                label: t('Launch time'),
                                                rules: [
                                                    {
                                                        required: true,
                                                        message: t('This field is require'),

                                                    },
                                                    ({getFieldValue}) => ({
                                                        validator(rule, dateStart) {
                                                            const dateEnd = getFieldValue(FORM_NAME.DATE_END)

                                                            if (dateEnd && dateEnd <= dateStart) {
                                                                return Promise.reject(new Error(t('The launch time  cannot be greater than the deadline')));
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    })
                                                ]
                                            }} data-cy="date-start-select"/>
                                    </Col>}
                                {isNotContinousProgram &&
                                    <Col xs={24} md={12}>
                                        <DatePickerFormField
                                            showTime
                                            form={{
                                                name: FORM_NAME.DATE_END,
                                                label: t('Deadline'),
                                                rules: [{
                                                    required: true,
                                                    message: t('This field is require')
                                                }, ({getFieldValue}) => ({
                                                    validator(rule, dateEnd) {
                                                        const dateStart = getFieldValue(FORM_NAME.DATE_START)

                                                        if (dateStart && dateEnd <= dateStart) {
                                                            return Promise.reject(new Error(t('The deadline cannot be shorter than the launch time')));
                                                        }
                                                        return Promise.resolve();
                                                    }
                                                })]
                                            }} data-cy="date-end-select"/>
                                    </Col>}
                                {/*{isUpdate() &&*/}
                                {/*    <Col xs={24} md={12}>*/}
                                {/*        <SwitchFormField form={{*/}
                                {/*            name: FORM_NAME.IS_ACTIVE,*/}
                                {/*            label: t('Active step'),*/}
                                {/*            help: t('Select to activate this step.'),*/}
                                {/*            rules: [{*/}

                                {/*                required: true,*/}
                                {/*                message: t('This field is require')*/}
                                {/*            }]*/}
                                {/*        }}/>*/}
                                {/*    </Col>}*/}

                            </Row>


                        </SolvCollapse>
                    </Col>
                    <Col xs={24}>
                        <Row justify={'end'} gutter={[8, 8]}>
                            <Col>
                                <Button type={'default'} danger
                                        loading={isLoading}
                                        onClick={() => history.goBack()}
                                >{t("Cancel")}</Button>
                            </Col>
                            <Col>

                                <Button
                                    loading={isLoading}
                                    htmlType={'submit'}
                                    data-cy="submit-create-phase-button">{t("Save")}</Button>
                            </Col>

                        </Row>
                    </Col>
                </Row>


            </Form>
        )}

    </>);
}
