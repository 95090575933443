import React from 'react';
import {useTranslation} from "react-i18next";
import SolvTag from "../tag/SolvTag";
import {gold_1, polar_green_1,} from "../../utils/Colors";
import {
    AWAITING_VALIDATION_PURCHASE, REVOKED_PURCHASE,
    SUCCESSFUL_PURCHASE
} from "../../utils/Constants";

const colors = {
    [AWAITING_VALIDATION_PURCHASE]: gold_1,
    [SUCCESSFUL_PURCHASE]: polar_green_1
    ,
    [REVOKED_PURCHASE]: '#FFA39E',
}

const GAMIFICATION_PURCHASE_STATUS = {
    [AWAITING_VALIDATION_PURCHASE]: 'Waiting for validation',
    [SUCCESSFUL_PURCHASE]: 'Finished',
    [REVOKED_PURCHASE]: 'Revoked',
}
export default function TagPurchaseGamification({status, ...props}) {

    const {t} = useTranslation()
    return (
        <SolvTag
            color={colors[status]}>{t(GAMIFICATION_PURCHASE_STATUS[status])}</SolvTag>

    )
}