import {useEffect, useState} from "react";
import {generateOptionsField} from "../utils/Utils";
import {useTranslation} from "react-i18next";
import useDefaultGetRequest from "./useDefaultGetRequest";

export default function useGenerateInputOptions({
                                                    request,
                                                    options=[],
                                                    key_label='name',
                                                    key_value='id',
                                                    translateLabelOptions = false
                                                }) {
    const [_options, _setOptions] = useState([])
    const {t} = useTranslation()

    const {
        data,
        isLoading
    } = useDefaultGetRequest({
        ...request,
        dataDefault: [],
        makeRequest: !!request?.url,
        dependence: [JSON.stringify(request)]
    })
    useEffect(() => {
        if (options.length > 0 && !request?.url) {
            _setOptions(options)
        }
    }, [options])

    useEffect(() => {

        if (!isLoading && data?.length > 0) {

            if (translateLabelOptions) {
                _setOptions(generateOptionsField(data, key_label, key_value).map((item) => ({...item, label: t(item.label)})))
            } else {
                _setOptions(generateOptionsField(data, key_label, key_value))
            }
        }
    }, [data, isLoading])


    return {
        _options
    }
}