import {useEffect, useState} from "react";
import {Bar} from '@ant-design/plots';
import {useThemeContext} from "../../../../../../global/context/ThemeContext";
import api from "../../../../../../global/services/api";
import ChartLegendWrapper from "../../../../../../global/components/data_display/ChartWrapper/ChartLegendWrapper";
import {useTranslation} from "react-i18next";
import {getLanguage} from "../../../../../../global/utils/translation";
import {ChartRenderer} from "../../utils/chartUtils";
import useProjectChart from "../../hooks/useProjectChart";


const getProgressGradient = ({color = "#00f", bgColor = "#f5f5f5", percent = 0}) => {
    return `linear-gradient(0deg, ${color} ${percent}%, ${bgColor} ${percent}%)`
}

export default function TimelineChartV2({filters,expand}) {
    const {themeConfig} = useThemeContext();
    const [data, setData] = useState();
    const {t} = useTranslation();
    const lang = getLanguage();
    const {handleChartClickProjectRedirect} = useProjectChart();

    const fetchData = async () => {
        try {
            const response = await api.post(`/analytics/dashboard/project_client_dashboard/${themeConfig.client.id}/timeline_report`,filters);
            setData(response.data.map((item) => ({
                id: item.id,
                name: item.name,
                styles: item.styles,
                color: item.styles?.backgroundColor,
                start: new Date(item.main_task?.start),
                end: new Date(item.main_task?.end),
                progress: item.main_task?.progress || 0,
            })));
        } catch (exception) {
        }
    };

    useEffect(() => {
        // if (!data) {
            void fetchData();
        // }
    }, [ filters]);

    const colorField = 'name';
    const colorSourceKey = 'color';
    const idSourceKey = 'id';

    const config = {
        data,
        height: (365 + (data?.length * 20)),
        xField: 'name',
        yField: ['start', 'end'],
        markBackground: {
            style: {
                fill: 'transparent',
            },
        },
        animate:null,
        colorField: 'name',
        tooltip: {
            items: [
                (d) => (
                    {field: 'start', name: t('Start Date'), value: d.start?.toLocaleDateString(lang),color: d.color}
                ),
                (d) => (
                    {field: 'end', name: t('End Date'), value: d.end?.toLocaleDateString(lang),color: d.color}
                ),
                (d)=>(
                    {field: 'progress', name: t('Progress'), value: `${d.progress}%`, color: d.color}
                )
            ],
        },
        axis: {
            x: {
                tickLength: 0,
                size: expand ? 500 : 200,
                labelAutoEllipsis: [{type: "ellipsis", maxLength: 5}],
                labelFontSize: 14,
                labelFill: "#000000d9",
                labelFontWeight: 950,

            },
            y: {
                labelFormatter: (d) => d?.toLocaleDateString(lang, {day: "numeric", month: "short", year: "numeric"}),
                labelAutoRotate: false,
            }
        },
        style: {
            fill: (task) => {
                return getProgressGradient({
                    color: task.styles?.progressColor,
                    bgColor: task.styles?.backgroundColor,
                    percent: task.progress
                })
            }
        },
        _legend: {
            idSourceKey,
            colorField,
            colorSourceKey,
            rows: expand ? 2 : 1,
            cols: 3,
        },
        onReady: ({chart}) => {
            chart.on('interval:click', (event)=> {
                handleChartClickProjectRedirect({event, fieldName: "id"});
            });
        }
    };

    return (
        <ChartRenderer
            data={data}
            chart={<ChartLegendWrapper chart={Bar} config={config}/>}
        />
    );

}