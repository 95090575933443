import {BuildFieldErrorsDict} from "../global/utils/Utils";
import React, {createContext, useContext, useEffect, useState} from "react";
import api from "../global/services/api";
import {Link, useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import RoutesProgram from "../modules/program/routes/RoutesProgram";
import ExtraHeader from "../modules/project/components/layout/ExtraHeader";
import {
    useLayoutContext
} from "../modules/project/components/layout/context/LayoutContext";
import {usePermissionContext} from "../global/context/PermissionContext";
import {useThemeContext} from "../global/context/ThemeContext";

export const ProgramContext = createContext();

export const ProgramContextProvider = ({children}) => {
    const [program, setProgram] = useState({public: true});
    const [isLoadingProgram, setIsLoadingProgram] = useState(false);
    const {id} = useParams();
    const history = useHistory()

    const getProgram = async () => {
        try {
            setIsLoadingProgram(true)
            const response = await api.get(`programs/${id}`);
            setProgram(response.data);
        } catch (error) {
            if (error.response?.status === 404) {
                history.push("/home")
            }
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoadingProgram(false)
        }
    };

    const [programType, setProgramType] = useState("mappingOfChallenges")


    useEffect(() => {
        if (id) {
            getProgram()
        }
    }, [id]);

    return <ProgramContext.Provider
        value={{
            program, setProgram,
            programType, setProgramType, isLoadingProgram
        }}>{children}</ProgramContext.Provider>
}

export const useProgramContext = () => {
    return useContext(ProgramContext);
}

function ProgramsNavigatorPage({hasPermission}) {
    const {t} = useTranslation();
    const {id} = useParams();
    const {program} = useProgramContext();
    const {initializeLayout} = useLayoutContext();
    const {themeConfig: {client}} = useThemeContext();

    const {programHasChallengeMappingPermission, hasGamificationAdminPermission} = usePermissionContext()

    useEffect(() => {
        initializeLayout({
            extraHeaderContent: <ExtraHeader left={program.name}
                                             returnPath={`/programs`}/>,
            navbarMenuItems: [
                {
                    label: <Link
                        to={`/programs/${id}/phases`}>{t("Phases")}</Link>,
                    key: `/programs/${id}/phases`
                },
                {
                    label: <Link
                        to={`/programs/${id}/dashboard`}>{t("Dashboard")}</Link>,
                    key: `/programs/${id}/dashboard`
                },
                {
                    label: <Link
                        to={`/programs/${id}/challenges`}>{t("Challenges")}</Link>,
                    key: `/programs/${id}/challenges`,
                    hasPermission: !programHasChallengeMappingPermission(program)
                },

                {
                    label: <Link
                        to={`/programs/${id}/interaction`}>{t("Interaction")}</Link>,
                    key: `/programs/${id}/interaction`
                },

                {
                    label: <Link to={`/programs/${id}/database`}>{t("Database")}</Link>,
                    key: `/programs/${id}/database`,
                    isActiveForChildren: true,
                },
                {
                    label: <Link
                        to={`/programs/${id}/gamification`}>{t("Gamification")}</Link>,
                    key: `/programs/${id}/gamification`,
                    hasPermission: hasGamificationAdminPermission && client?.gamification_actived
                },

                {
                    label: <Link
                        to={`/programs/${id}/organizers`}>{t("Organizers")}</Link>,
                    key: `/programs/${id}/organizers`
                },
                {
                    label: <Link to={`/programs/${id}/startups`}>{t("Startup Radar")}</Link>,
                    key: `/programs/${id}/startups`,
                    hasPermission:  process.env.REACT_APP_ENV !== 'prod'
                },
                {
                    label: <Link
                        to={`/programs/${id}/settings`}>{t("Settings")}</Link>,
                    key: `/programs/${id}/settings`
                },

            ],
        });
    }, [program]);

    return (<RoutesProgram hasPermission={hasPermission} program={program}/>)
}


export default function ProgramsNavigator({hasPermission}) {
    return <ProgramContextProvider>
        <ProgramsNavigatorPage hasPermission={hasPermission}/>
    </ProgramContextProvider>
}