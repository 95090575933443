import { Form as FormAnt, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FlexContainer } from "../../../../../global/components/authentication/StyledComponents";
import Button from "../../../../../global/components/data_entry/Button";
import EmailTagsFormField from "../../../../../global/components/data_entry/form/EmailTagsFormField";
import Form from "../../../../../global/components/data_entry/form/Form";
import { ToastNotification } from "../../../../../global/components/feedback/ToastNotification";
import Drawer from "../../../../../global/components/layout/Drawer";
import WideSpace from "../../../../../global/components/layout/WideSpace";
import api from "../../../../../global/services/api";
import { BuildFieldErrorsDict } from "../../../../utils/Utils";

const StyledForm = styled(Form)`
  //.ant-form-item {
  //  margin: 0;
  //}
`
const FORM_NAME = {
    PERMISSIONS: 'permission',
    EMAILS: 'emails',
}

export default function TeamMemberInviteDrawer({open, setOpen, teamId, resetTable, onAdd}) {

    const {t} = useTranslation();
    const [form] = FormAnt.useForm()
    const [isLoading, setIsLoading] = useState(false);


    const onSubmit = async (data) => {
        try {
            setIsLoading(true);
            await api.post(`/teams/${teamId}/add_members`, data);
            handleCloseDrawer();
            onAdd && onAdd()
            ToastNotification(t("Successfully"));
        } catch (error) {
            if (error.response?.data?.detail) {
                ToastNotification(error.response.data.detail, 'warning')
            }
            BuildFieldErrorsDict(error, null, false)
        } finally {
            resetTable();
            setIsLoading(false);
        }
    }


    const handleCloseDrawer = () => {
        form.resetFields()
        setOpen(false)
    }

    return (

        <Drawer
            title={t("New User")}
            open={open}
            onClose={handleCloseDrawer}
            footer={
                <FlexContainer justify="end"><Button
                    onClick={form.submit}
                    loading={isLoading}
                    data-cy="submit-button">{t("Insert")}</Button></FlexContainer>
            }
        >
            <WideSpace direction="vertical" size="middle"
                       style={{height: "100%"}}>
                <StyledForm form={form}
                            onFinish={onSubmit}
                            disabled={isLoading}>

                    <EmailTagsFormField
                        form={{
                            name: FORM_NAME.EMAILS,
                            rules: [{
                                required: true,
                                message: t('This field is require')
                            }],
                            label: t("User emails"),
                        }}/>
                </StyledForm>
            </WideSpace>
        </Drawer>

    )
}