import {
    AuditOutlined,
    BulbOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    EditOutlined,
    LoadingOutlined,
    TeamOutlined
} from "@ant-design/icons";
import {Button, Card, Flex, Typography} from "antd";
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import styled from "styled-components";
import HappyEmpty from "../global/components/data_display/HappyEmpty";
import RemixIcon from "../global/components/data_display/RemixIcon";
import Drawer from "../global/components/layout/Drawer";
import DrawerTabs from "../global/components/layout/DrawerTabs";
import {useUserContext} from "../global/context/UserContext";
import useEventListener from "../global/hooks/useEventListener";
import api from "../global/services/api";
import {
    cyan_7,
    dust_red_7,
    error,
    lime_7,
    magenta_5,
    neutral_4,
    neutral_5,
    neutral_7,
    primary_6,
    success
} from "../global/utils/Colors";
import {BuildFieldErrorsDict} from "../global/utils/Utils";
import {calculateTimeDelta} from "../global/utils/dates";
import {useLayoutContext} from "../modules/project/components/layout/context/LayoutContext";

const {Text} = Typography

const notificationIconType = {
    users_gray: {icon: <TeamOutlined/>, color:neutral_7},
    audit_blue : {icon: <AuditOutlined/>, color:cyan_7},
    light_bulb_pink : {icon: <BulbOutlined />, color:magenta_5},
    pen_pink: {icon: <EditOutlined />, color:magenta_5},
    doc_write_red: {icon: <AuditOutlined/>, color:dust_red_7},
    doc_write_green: {icon: <AuditOutlined/>, color:lime_7},
    pack_blue: {icon: <RemixIcon remixIconName="ri-copper-coin-line"/>, color:"#6A78BE"},
}

const exportIconStatus = {
    error: {
        icon: <CloseCircleOutlined/>,
        color: error,
        message: "There was a problem processing this export. Please try again."
    },
    sent: {
        icon: <CheckCircleOutlined/>,
        color: success,
        message: "Processing completed. Click here to download. The download will be available for 3 days."
    },
    sending: {icon: <LoadingOutlined/>, color: primary_6, message: "Please wait while we export your table"},
    queue: {icon: <LoadingOutlined/>, color: primary_6, message: "Please wait while we export your table"},
}

const NotificationIconContainer = styled.div`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    border-radius: 8px;
    background: ${({$background}) => $background};
    color: #FFFFFF;
`

const StyledCard = styled(Card)`

    background: ${({$isRead}) => $isRead ? "white" : neutral_4};
    &:hover {
        background: ${neutral_5};
    }
    transition: all ease 0.3s;
    cursor: ${({$urlAction}) => $urlAction ? "pointer" : "default"};
`


function CardLinkWrapper({children, urlAction}){
    return(
        !urlAction ? children :
            <Link to={urlAction}>
                {children}
            </Link>
    )
}

function UpdatableDeltaTimeText({datetime, open}){

    const {_user} = useUserContext();
    const rtf = new Intl.RelativeTimeFormat(_user?.language_code, {style: 'long'});
    const [timeDelta, setTimeDelta] = useState(calculateTimeDelta(datetime, _user?.language_code))

    const needUpdateKey = ["now", "agora", "minute"]

    useEffect(() => {
        if (needUpdateKey.includes(timeDelta?.key)) {
            const intervalId = setInterval(() => {
                setTimeDelta(calculateTimeDelta(datetime, _user?.language_code))
            }, 60000);
            return () => clearInterval(intervalId);
        }
    }, [timeDelta, _user]);

    useEffect(() => {
        setTimeDelta(calculateTimeDelta(datetime, _user?.language_code))
    }, [open, _user]);

    return <Text type="secondary">{timeDelta?.value === 0 ? timeDelta?.key : rtf.format(-timeDelta?.value, timeDelta?.key)}</Text>
}

const VISIBLE = '/target_notification_web/mark_visible'
const READ = '/target_notification_web/mark_read'

function NotificationTab({open}) {
    const {t} = useTranslation()
    const {notifications, readNotifications, setReadNotifications, _user} = useUserContext()
    const [isLoading, setIsLoading] = useState(false)

    const handleMark = async (url) => {
        try {
            setIsLoading(true)
            void await api.post(url)
        } catch (e) {
            BuildFieldErrorsDict(error, false, false)
        } finally {
            setIsLoading(false)
        }
    }
    const handleReadNotifications = () => {
        setReadNotifications(
            notifications?.filter(notification => !notification.is_read).map(notification => notification.id)
        )
    }

    useEventListener("update-read-notifications", handleReadNotifications);

    useEffect(() => {
        void handleMark(READ)
    }, []);

    return (
        <Flex vertical={true} gap={16}>
            {notifications && notifications.length > 0 &&
                <Flex flex={1} justify="end">
                    <Button icon={<DeleteOutlined/>} type="primary" ghost={true} loading={isLoading}
                            style={{maxWidth: 64, border: "unset"}} onClick={() => handleMark(VISIBLE)}
                    >
                        {t("Clear")}
                    </Button>
                </Flex>
            }
            {
                notifications && notifications?.length === 0 ?
                    <HappyEmpty message="You've read everything! There are no notifications."/> :
                        notifications?.map(notification => {
                        return (
                            <CardLinkWrapper urlAction={notification?.url_action} key={notification.id}>
                                <StyledCard
                                    $isRead={notification.is_read || readNotifications?.includes(notification.id)}
                                    bordered={false} $urlAction={notification.url_action}>
                                    <Flex gap={16} flex={1} align="center">
                                        {notificationIconType[notification.icon] &&
                                            <NotificationIconContainer
                                                $background={notificationIconType[notification.icon]["color"]}>
                                                {notificationIconType[notification.icon]["icon"]}
                                            </NotificationIconContainer>
                                        }
                                        <Flex flex={1}>
                                            <Flex vertical={true} flex={1}>
                                                <Flex justify="space-between">
                                                    <Text strong={true}>{notification.title}</Text>
                                                    <UpdatableDeltaTimeText datetime={notification?.date_create} open={open}/>
                                                </Flex>
                                                <Text
                                                    style={{whiteSpace: "preserve-breaks"}}>{notification.message}
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </StyledCard>
                            </CardLinkWrapper>
                        )
                    })
            }
        </Flex>
    )

}

function ExportTab({open}) {
    const {t} = useTranslation()
    const {list_export_report} = useUserContext()
    const [isLoading, setIsLoading] = useState(false)

    const handleHideInfo = async () => {
        try {
            setIsLoading(true)
            void await api.post('analytics/export_report/hide_info')
        } catch (error){
            BuildFieldErrorsDict(error, false, false)
        }finally {
            setIsLoading(false)
        }
    }

    return (
        <Flex vertical={true} gap={16} justify="end">
            {list_export_report && list_export_report.length > 0 &&
                <Flex flex={1} justify="end">
                    <Button icon={<DeleteOutlined/>} type="primary" ghost={true} loading={isLoading}
                            style={{maxWidth: 64, border: "unset"}} onClick={handleHideInfo}
                    >
                        {t("Clear")}
                    </Button>
                </Flex>
            }

            <Flex vertical={true} gap={16}>
                {
                    list_export_report && list_export_report.length === 0 ? <HappyEmpty message="You haven't exported anything yet."/> :
                    list_export_report && list_export_report?.map((exp) => {
                        const translatedTableName = t(exp.type, {defaultValue: exp.type});

                        return (
                            <a href={exp.path} target={"_blank"} style={{pointerEvents: exp.status !== "sent" && "none"}}>
                                <StyledCard bordered={false} key={exp.id} $isRead={true} $urlAction={exp.path}>
                                    <Flex gap={16} flex={1} align="center">
                                        <Text
                                            style={{
                                                fontSize: 24,
                                                color: exportIconStatus[exp.status]["color"],
                                            }}
                                        >
                                            {exportIconStatus[exp.status]["icon"]}
                                        </Text>
                                        <Flex flex={1}>
                                            <Flex vertical={true} flex={1}>
                                                <Flex justify="space-between">
                                                    <Text strong={true}>
                                                        {t("Export")} {translatedTableName}
                                                    </Text>
                                                    <UpdatableDeltaTimeText datetime={exp?.created_at} open={open}/>
                                                </Flex>
                                                <Text>
                                                    {t(exportIconStatus[exp.status]["message"], {
                                                        tableName: translatedTableName,
                                                    })}
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </StyledCard>
                            </a>
                        );
                    })}
            </Flex>
        </Flex>
    )
}

export default function InviteOffCanvas() {
    const {t} = useTranslation();
    const [activeKey, setActiveKey] = useState("1")
    const {showNotificationArea, setShowNotificationArea} = useLayoutContext()

    const getItems = () => {
        return [
            {
                label: t("Notifications"),
                key: "1",
                children: <NotificationTab open={showNotificationArea}/>,
            },
            {
                label: t("Exports"),
                key: "2",
                children: <ExportTab open={showNotificationArea}/> ,
            }
        ]
    }

    const handleOnClose = () => {
        setShowNotificationArea(false)
        dispatchEvent(new CustomEvent("update-read-notifications"))
    }

    const handleOpenOnExportTab = () => {
        setActiveKey("2")
        setShowNotificationArea(true)
    }

    useEventListener(`open-notification-export-tab`, handleOpenOnExportTab);

    return (
        <Drawer title={t("Notifications")}
                open={showNotificationArea}
                onClose={handleOnClose}
                hideTopBorder={true}
                bodyStyle={{padding: 0}}
                headerPadding={"16px 16px 0 16px"}
                isGrayBody={true}
                destroyOnClose={false}
        >
            {showNotificationArea &&
                <DrawerTabs items={getItems()} defaultActiveKey={activeKey}  />
            }
        </Drawer>
    )
}