import { Card, Space, Typography } from "antd";
import WideSpace from "../../layout/WideSpace";
import UserAvatar from "../../misc/UserAvatar";
import styled from "styled-components";
import { CheckCircleTwoTone } from "@ant-design/icons";
import LimitedSpace from "../../responsive/LimitedSpace";
import TruncateText from "../../responsive/TruncateText";
import {useTranslation} from "react-i18next";

const StyledButtonCard = styled(Card)`
    cursor: pointer;
    height: 104px;
    ${props=>props.isSelected && `
        background-color: #f5f5f5;
        border-color: #1890FF;
    `}

    :hover {
        background-color: #f5f5f5;
    }
`;

export default function TeamSelectableCard({team, onClick, isSelected}) {
    const {t} = useTranslation()
    return (
        <StyledButtonCard onClick={onClick} isSelected={isSelected}>
            <WideSpace>
                {isSelected && <CheckCircleTwoTone />}
                <UserAvatar data={team} />
                <LimitedSpace direction="vertical" style={{ lineHeight: "initial" }} size={0}>
                    <TruncateText title={team.name} lines={2}><Typography.Text style={{ fontSize: "16px", fontWeight: 700 }}>{team.name || "-"}</Typography.Text></TruncateText>
                    <Typography.Text>{t(team.type) || "-"}</Typography.Text>
                </LimitedSpace>
            </WideSpace>
        </StyledButtonCard>
    );
}
