import {Col, Input, Row} from "antd";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import {usePermissionContext} from "../../../context/PermissionContext";
import {
    SolutionDrawerSharedContextProvider
} from "../../../context/SolutionDrawerSharedContext";
import {useUserContext} from "../../../context/UserContext";
import useQuery from "../../../hooks/useQuery";
import api from "../../../services/api";
import InnovationProfileCard
    from "./components/HomeInnovationProfileCard/InnovationProfileCard";
import HomeProgramCard from "./components/HomeProgramCard";
import InfiniteScroll from "react-infinite-scroller";
import Loading from "../../../components/feedback/Loading";
import {
    useLayoutContext
} from "../../../../modules/project/components/layout/context/LayoutContext";
import BreakLayoutSystemContent
    from "../../../components/layout/BreakLayoutSystemContent";

import {BuildFieldErrorsDict} from "../../../utils/Utils";
import SectionWithInput from "../InnovationPanel/Sections/SectionWithInput";
import useSystemBreakpoint from "../../../hooks/useSystemBreakpoint";
import {
    FlexContainer
} from "../../../components/authentication/StyledComponents";
import {page_background} from "../../../utils/Colors";
import DynamicDropdown from './../../../components/data_entry/DynamicDropdown';

import Empty from './../../../components/data_display/Empty';
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import RankingPlacements
    from "../../../components/data_display/RankingPlacements";
import {useThemeContext} from "../../../context/ThemeContext";
import useDefaultGetRequest from "../../../hooks/useDefaultGetRequest";
import {RANKING_STATUS} from "../../../utils/Constants";
import Search from "../../../components/data_entry/Search";


const PageContainer = styled(FlexContainer)`
    border-radius: 24px 24px 0 0;

    margin-top: -20px;
    z-index: 2;
    position: relative;
    background: ${page_background};
    padding: ${({isPhone}) => isPhone ? '16px' : '24px'};
`

function HomePage() {
    const hasChangedSearch = useRef(true);
    const hasChangedOrder = useRef(true);

    const {hasSameDomainSolution} = usePermissionContext();
    const {themeConfig: {client}} = useThemeContext()
    const {_user} = useUserContext();
    const {t} = useTranslation();
    const [programs, setPrograms] = useState([]);
    const [userSolutionsCount, setUserSolutionsCount] = useState(undefined);
    const [userApprovedSolutionsCount, setUserApprovedSolutionsCount] = useState(undefined);
    const initialUrl = 'home/open_programs?page=1&page_size=5';
    const [nextUrl, setNextUrl] = useState(initialUrl);
    const [isLoadingInfinite, setIsLoadingInfinite] = useState(false);
    const {refLayoutContent} = useLayoutContext();
    const {isPhone} = useSystemBreakpoint();
    const screens = useBreakpoint();
    const sortModes = [
        { label: t("Launch Date"), key: "-date_start" },
        { label: t("Closing Date"), key: "first_phase_end_date" },
    ]
    const [order, setOrder] = useState("-date_start");
    const [search, setSearch] = useState("");

    let query = useQuery();
    const history = useHistory();

    const fetchPrograms = async () => {
        try {
            setIsLoadingInfinite(true);
            let url = nextUrl;
            let keepPrograms = [...programs];
            if (hasChangedSearch.current || hasChangedOrder.current) {
                url = initialUrl;
                keepPrograms = [];
                hasChangedSearch.current = false;
                hasChangedOrder.current = false;
            }
            const config = {version: "v2", params: {search, order}}
            const response = await api.get(url, {...config});
            setPrograms([...keepPrograms, ...response.data.results])
            setNextUrl(response.data.next);

        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        } finally {
            setIsLoadingInfinite(false)
        }
    };

    const fetchSolutionsCount = async () => {
        try {
            const response = await api.get(`v2/home/solutions_count_by_client`);
            setUserSolutionsCount(response.data.solutions_suggested);
            setUserApprovedSolutionsCount(response.data.solutions_approved);
        } catch (exception) {
            BuildFieldErrorsDict(error, null, false);
        }
    };


    const handleLoadMore = () => {
        if (!isLoadingInfinite) {
            void fetchPrograms();
        }
    }

    const handleSearch = (value) => {
        hasChangedSearch.current = true;
        setSearch(value);
    }

    const handleSort = ({key}) => {
        hasChangedOrder.current = true;
        setOrder(key);
    }

    useEffect(() => { // GAMBIARRA DO REDIRECT DO SITE, NÃO TOQUE
        if (query.get("redirect") === 'site') {

            history.push(`/login`);
        }
    }, [query]);

    useEffect(() => {
        void fetchSolutionsCount();
    }, []);

    useEffect(() => {
        void fetchPrograms();
    }, [search, order]);

    const {
        data: {results: rankings},
        // isLoading
    } = useDefaultGetRequest({
        url: `/ranking`,
        makeRequest: !!(client?.gamification_actived && client?.show_ranking_in_home),
        params: {status: RANKING_STATUS.OPENED , environment_rank:true},
        dataDefault: []
    })


    return (
        <BreakLayoutSystemContent>
            <SectionWithInput title={`${t("Available Programs")}`}>
                <Search onSearch={handleSearch} allowClear
                              placeHolder={t('Search for a program')}
                              data-cy="search-program-input"/>
            </SectionWithInput>

            <PageContainer isPhone={isPhone} gap={24} direction={'column'}
                           align={'stretch'}>
                <Row gutter={[0, 16]} style={{width: '100%'}}>
                    {hasSameDomainSolution &&
                        <Col span={24} style={{padding: 0}}>
                            <InnovationProfileCard
                                user={_user}
                                solutionsCount={userSolutionsCount}
                                approvedSolutionsCount={userApprovedSolutionsCount}
                            />
                        </Col>}
                    <Col xs={24}>
                        <DynamicDropdown items={sortModes} selectedKey={order}
                                         handleChange={handleSort}/>
                    </Col>
                    <Col xs={24}>
                        <Row gutter={[24, 24]}>
                            <Col flex={'1'}>
                                <InfiniteScroll
                                    loadMore={handleLoadMore}
                                    hasMore={!!nextUrl}
                                    useWindow={false}
                                    getScrollParent={() => refLayoutContent.current}
                                    loader={<Loading style={{height: 100}}/>}
                                    style={{width: "100%"}}
                                    data-cy="infinite-scroll"
                                >
                                    {programs?.map((program, index) => (
                                        <Col span={24} key={program.id}
                                             style={{paddingBottom: 16}}>
                                            <HomeProgramCard
                                                program={program}/>
                                        </Col>
                                    ))}
                                    {programs.length === 0 &&
                                        <Empty description="No results"/>}
                                </InfiniteScroll>
                            </Col>
                            {(screens.xl || screens.xxl) && client?.gamification_actived && client?.show_ranking_in_home  &&
                                <Col>
                                    <RankingPlacements
                                        open_at={rankings?.[0]?.open_at}
                                        close_at={rankings?.[0]?.close_at}
                                        endless={rankings?.[0]?.endless}
                                        id={rankings?.[0]?.id}
                                        title={t('Overall ranking')}/>

                                </Col>}
                        </Row>
                    </Col>

                </Row>
            </PageContainer>
        </BreakLayoutSystemContent>
    );
}

export default function Home() {
    return <SolutionDrawerSharedContextProvider>
        <HomePage/>
    </SolutionDrawerSharedContextProvider>
}