import React, {useEffect, useState} from "react";
import Drawer from "../../../../../global/components/layout/Drawer";
import Button from "../../../../../global/components/data_entry/Button";
import {useTranslation} from "react-i18next";
import {
    FlexContainer
} from "../../../../../global/components/authentication/StyledComponents";
import {Form, Modal, Space, Typography} from "antd";
import imageChallenge from "../../../../../assets/img/upload_challenge.png";
import api from "../../../../../global/services/api";
import BannerUploader
    from "../../../../../global/components/data_entry/BannerUploader";
import InputFormField
    from "../../../../../global/components/data_entry/form/InputFormField";
import TextAreaFormField
    from "../../../../../global/components/data_entry/form/TextAreaFormField";
import {
    ToastNotification
} from "../../../../../global/components/feedback/ToastNotification";
import RequestSelectFormField
    from "../../../../../global/components/data_entry/form/RequestSelectFormField";
import {convertToFormData} from "../../../../../modules/project/utils/utils";
import ChallengeDrawer
    from "../../../../../global/components/other/challenge/ChallengeDrawer/ChallengeDrawer";
import {useThemeContext} from "../../../../../global/context/ThemeContext";
import InfoCard from "../../../../../global/components/data_display/InfoCard";
import OriginatingApplicationCard from "./OriginatingApplicationCard";
import WideSpace from "../../../../../global/components/layout/WideSpace";
import TreeSelectFormField
    from "../../../../../global/components/data_entry/form/TreeSelectFormField";
import {BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import {CloseCircleOutlined} from "@ant-design/icons";
import {
    useLayoutContext
} from "../../../../../modules/project/components/layout/context/LayoutContext";

const {Text, Title} = Typography;
const {confirm} = Modal


const FORM_NAMES = {
    ORIGINATING_APPLICATION: 'originating_application',

}

export default function ChallengeDrawerForm({
                                                open,
                                                setOpen,
                                                challengeId,
                                                program,
                                                externalOriginatingApplicationID,
                                                onAfterSubmit,
                                                initialFieldsValue,
                                                onClose
                                            }) {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const rules = [{required: true}];
    const [loading, setLoading] = useState(false)
    const [fetchLoading, setFetchLoading] = useState(false)
    const [hasChange, setHasChange] = useState(false)
    const [selectedProgram, setSelectedProgram] = useState()
    const [previewOpen, setPreviewOpen] = useState(false)
    const [applications, setApplications] = useState()
    const {updateLayoutContent} = useLayoutContext()
    const originatingApplication = Form.useWatch(FORM_NAMES.ORIGINATING_APPLICATION, form)

    useEffect(() => {
        const fetchApplications = async () => {
            try {
                const response = await api.get(`/get_program_tree`);
                setApplications(response.data);
            } catch (error) {

            }
        }

        fetchApplications()
    }, [])
    const {themeConfig} = useThemeContext()

    const config = {
        headers: {'content-type': 'multipart/form-data'}
    };

    const challengePreview = {
        ...form.getFieldsValue(),
        profile: form.getFieldValue("post_image"),
        client_profile: themeConfig["img_profile"],
        program: selectedProgram || program
    }

    const fixDataPayload = async (data) => {
        originatingApplication && data.set("originating_application", originatingApplication)
        const postImage = data.get("post_image");
        if (postImage.includes("blob:")) {
            const blob = await fetch(postImage).then(r => r.blob());
            data.append('post_image_upload', blob, 'challenge_image.png');
        }
        data.delete("program_name")
        return data
    }

    const fetchChallenge = async () => {
        try {
            setFetchLoading(true)
            const {data} = await api.get(`challenges/${challengeId}`)
            form.setFieldsValue(data)
        } catch (error) {
            BuildFieldErrorsDict(error, null)
        } finally {
            setFetchLoading(false)
        }
    }

    const handleSubmit = async (data) => {
        data = convertToFormData(data, false)
        data = await fixDataPayload(data)
        challengeId ? void handleEditSubmit(data) : void handleAddSubmit(data)
    }

    const handleAddSubmit = async (data) => {
        try {
            setLoading(true)
            await api.post("/challenges", data, config)
            onAfterSubmit && onAfterSubmit();
            ToastNotification(t("Challenge created successfully!"), 'success')
            setOpen(false)
            form.resetFields()
        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields)
            // ToastNotification(error.response.data.errors, 'error')
        } finally {
            setLoading(false)
        }
    }

    const handleEditSubmit = async (data) => {
        try {
            setLoading(true)
            await api.patch(`/challenges/${challengeId}`, data, config)
            onAfterSubmit && onAfterSubmit();
            ToastNotification(t("Challenge Edited successfully!"), 'success')
            setOpen(false)
        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields)
        } finally {
            setLoading(false)
        }
    }

    function handleClose() {
        setOpen(false);
        form.resetFields()
        setHasChange(false)
        onClose?.()
    }

    const confirmExit = () => {
        confirm({
            title: t("There are unsaved changes"),
            content: t("Do you really want to leave?"),
            okText: t("Yes"),
            cancelText: t("No"),
            okButtonProps: {'data-cy': 'confirm-leave-challenge-form-button'},
            onOk: handleClose,
        });
    };

    const handleValidateChangeOnClose = () => {
        if (hasChange) {
            confirmExit()
        } else {
            handleClose()
        }
    }

    const handleOnChange = () => {
        setHasChange(true)
    }

    useEffect(() => {
        challengeId && fetchChallenge()
        !challengeId && form.resetFields()
    }, [challengeId]);

    useEffect(() => {
        initialFieldsValue && form.setFieldsValue({...initialFieldsValue})
    }, [open, initialFieldsValue]);


    const confirmDeletion = () => {
        confirm({
            title: t("Remove challenge"),
            content: t("Are you sure you want to confirm the deletion?"),
            icon: <CloseCircleOutlined style={{color: "red"}}/>,
            okText: t("Remove"),
            okButtonProps: {'data-cy': 'confirm-delete-challenge-button'},
            okType: "danger",
            cancelText: t("Cancel"),
            async onOk() {
                try {
                    await api.delete(`/challenges/${challengeId}`)

                    ToastNotification(t("Challenge successfully removed"), 'success')
                    updateLayoutContent()

                    setOpen(false)
                } catch (error) {
                    BuildFieldErrorsDict(error, null)
                } finally {
                    setLoading(false)
                }

            },
        });
    };

    const OPEN_FROM_APPLICATION_DRAWER = !!externalOriginatingApplicationID
    const IS_EDIT_MODE = !!challengeId

    return (
        <Drawer
            width={520}
            open={open}
            onClose={handleValidateChangeOnClose}
            footer={
                <FlexContainer gap={8} justify="end">
                    <Button type="default"
                            onClick={() => setPreviewOpen(true)}
                            disable={previewOpen}>
                        {t("Preview")}
                    </Button>
                    {!OPEN_FROM_APPLICATION_DRAWER && IS_EDIT_MODE &&
                        <Button danger onClick={confirmDeletion}
                                data-cy="delete-challenge-button"
                                loading={loading}>{t("Delete")}</Button>}
                    <Button onClick={() => form.submit()} loading={loading}
                            data-cy="submit-button">
                        {t(challengeId ? "Save" : "Create")}
                    </Button>
                </FlexContainer>
            }
            title={
                <Space>
                    <Text
                        strong>{t(IS_EDIT_MODE ? "Edit Challenge" : "New Challenge")}</Text>
                </Space>
            }
            destroyOnClose={true}
            loading={IS_EDIT_MODE && fetchLoading}
        >
            <WideSpace size="middle" direction="vertical">
                <Form layout="vertical" form={form} onFinish={handleSubmit}
                      onValuesChange={handleOnChange}>
                    <Title level={5}>{t("Challenge data")}</Title>

                    <RequestSelectFormField
                        form={{
                            label: t("Program"),
                            name: "program_id",
                            rules: rules,
                            initialValue: !OPEN_FROM_APPLICATION_DRAWER && program?.id,
                        }}
                        url="v2/programs/options"
                        disabled={!OPEN_FROM_APPLICATION_DRAWER || IS_EDIT_MODE }
                        preserveData={true}
                        onLoadValue={({data}) => {
                            setSelectedProgram(data);
                        }}
                        data-cy="program_select"
                    />

                    <InputFormField form={{
                        name: 'name',
                        label: t("Name"),
                        rules: [...rules, {
                            type: 'text',
                        }]
                    }}
                                    data-cy="name-input"
                    />
                    <TextAreaFormField form={{
                        name: 'description',
                        label: t("Description"),
                        rules: rules,
                    }}
                                       data-cy="description-input"
                    />
                    <RequestSelectFormField
                        form={{
                            label: t("Business sectors"),
                            name: "sectors",
                            rules: rules,
                        }}
                        mode={'multiple'}
                        url="/sectors_client"
                        data-cy="sectors-select"
                    />
                    <Form.Item name="post_image" valuePropName="img"
                               label={t("Challenge Image")}
                               help={`${t("Size")} (800x600). Max. 2MB`}>
                        <BannerUploader imgDefault={imageChallenge}
                                        ratio={4 / 3}
                                        styleProps={{height: "100%"}}
                                        objectFit="contain"
                        />
                    </Form.Item>
                    <Title level={5}
                           style={{marginTop: 34}}>{t('Origin of the challenge')}</Title>
                    <TreeSelectFormField showSearch
                                         disabled={OPEN_FROM_APPLICATION_DRAWER}
                                         optionsSettings={{
                                             title: 'name',
                                             value: 'id',
                                             selectable: false,
                                             children: {
                                                 name: 'applications_json',
                                                 title: 'title',
                                                 value: 'id'
                                             }
                                         }}
                                         treeData={applications}
                                         form={{
                                             initialValue: externalOriginatingApplicationID,
                                             label: t('Source Application'),
                                             name: FORM_NAMES.ORIGINATING_APPLICATION
                                         }}/>
                </Form>

                {originatingApplication && <OriginatingApplicationCard
                    originatingApplicationId={originatingApplication}
                    challengeId={challengeId}/>}

            </WideSpace>
            <ChallengeDrawer open={previewOpen} setOpen={setPreviewOpen}
                             challengePreview={challengePreview}/>
        </Drawer>
    );
}