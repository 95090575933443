import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, Col, Row, Typography, Form } from "antd";
import CircularImage from "../../../../global/components/data_display/CircularImage";
import InputFormField from "../../../../global/components/data_entry/form/InputFormField";
import PasswordFormField from "../../../../global/components/data_entry/form/PasswordFormField";
import { NavLink } from "react-router-dom";
import { onChangePicture } from "../../../../modules/project/utils/utils";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

const { Title } = Typography;

const EnviromentTitle = styled(Title)`
    width: max-content;
`;
const StyledLayout = styled.div`
    background: white;
    height: ${({ xs }) => (xs ? "800px" : "400px")};
    border-radius: 9px;
    box-shadow: 0px 10.121326446533203px 31.488574981689453px 8.996734619140625px #0000000d;
    overflow: hidden;
`;

const Image = styled.img`
    display: block;

    max-width: 100%;
    max-height: 100%;
    height: 100%;
    min-height: 200px;
    object-position: center;
    object-fit: cover;
`;
const Container = styled.div`
    padding: 16px 55px 16px;
    max-width: 500px;
    height: 100%;
    margin: 0 auto;
`;

const StyledAuthButton = styled(Button)`
    background: ${({ background }) => background} !important;
    color: ${({ color }) => color} !important;
`;
export default function LoginPreview({ FORM_NAMES, form }) {
    const { t } = useTranslation();
    const login_img_cover = Form.useWatch(FORM_NAMES.LOGIN_IMG_COVER, form) || [];
    const login_logo_cover = Form.useWatch(FORM_NAMES.LOGIN_LOGO_COVER, form) || [];
    const login_text_color_button = Form.useWatch(FORM_NAMES.LOGIN_TEXT_COLOR_BUTTON, form);
    const login_background_button = Form.useWatch(FORM_NAMES.LOGIN_BACKGROUND_BUTTON, form);
    const { xs, sm } = useBreakpoint();

    const [loginLogoCover, setLoginLogoCover] = useState();
    const [loginImgCover, setLoginImgCover] = useState();

    useEffect(() => {
        if (login_img_cover) {
            onChangePicture(login_img_cover, setLoginImgCover);
        }
    }, [login_img_cover]);

    useEffect(() => {
        if (login_logo_cover) {
            onChangePicture(login_logo_cover, setLoginLogoCover);
        }
    }, [login_logo_cover]);
    return (
        <>
            <StyledLayout xs={xs}>
                <Row>
                    <Col xs={24} sm={12}>
                        <Image src={loginImgCover} />
                    </Col>
                    <Col xs={24} sm={12}>
                        <Container>
                            <Row gutter={[0, 16]} style={{}}>
                                <Col xs={24}>
                                    <CircularImage
                                        size={125}
                                        style={{ margin: "0 auto" }}
                                        src={loginLogoCover}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <EnviromentTitle level={4} style={{ margin: "0 auto" }}>
                                    </EnviromentTitle>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs={24}>
                                    <InputFormField
                                        form={{
                                            label: t("Email"),
                                        }}
                                        placeholder={t("login")}
                                    />
                                </Col>
                                <Col xs={24}>
                                    <PasswordFormField
                                        type={"password"}
                                        form={{
                                            style: { marginBottom: 4 },
                                            label: t("Password"),
                                        }}
                                        placeholder={t("Password")}
                                    />
                                    <NavLink to={"#"}>{t("Forgot password?")}</NavLink>
                                </Col>

                                <Col xs={24} style={{ marginTop: 24 }}>
                                    <Form.Item style={{ marginBottom: 0 }}>
                                        <StyledAuthButton
                                            block
                                            color={login_text_color_button}
                                            background={login_background_button}
                                            type="primary"
                                        >
                                            {t("Access")}
                                        </StyledAuthButton>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </StyledLayout>
        </>
    );
}
