import { Card, Typography } from 'antd';
import React from 'react';
import styled from 'styled-components';
import Flex from '../responsive/Flex';
import TruncateText from '../responsive/TruncateText';
import { neutral_3 } from '../../utils/Colors';

const {Text} = Typography;

const StyledCard = styled(Card)`
    /* box-shadow: 0 0 8px #00000026; */
    border: 1px solid ${neutral_3};
    padding: 0 8px;
    overflow: hidden;
    border-radius: 8px;
    justify-content: space-between;
    height: 64px;
    
    .ant-card-body {
        padding: 0;
        width: 100%;
    }
`
const StyledTextValue = styled(Text)`
    font-size: 12px;
`;

const StyledTextIcon = styled(Text)`
    font-size: 24px;
`;


export default function UserInfoCard({value, icon}) {
    return (
        <StyledCard>
            <Flex align='center' vertical={true} gap={0}>
                <StyledTextIcon>{icon}</StyledTextIcon>
                <TruncateText style={{textAlign: "center", maxWidth: "inherit"}}>
                    <StyledTextValue>
                        {value}
                    </StyledTextValue>
                </TruncateText>
            </Flex>
        </StyledCard>

    );
}
