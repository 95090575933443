import React, {useState} from 'react';
import {Col, Row, Typography} from "antd";
import {useTranslation} from "react-i18next";
import {
    ReactComponent as PlusGamificationIcon
} from "../../../../../assets/Icon/amount_plus_gamification.svg"
import styled from "styled-components";

import AmountCardGamification
    from "../../../../../global/components/data_display/AmountCardGamification";
import {
    FlexContainer
} from "../../../../../global/components/authentication/StyledComponents";
import {useProgramContext} from "../../../../ProgramsNavigator";
import useDefaultGetRequest
    from "../../../../../global/hooks/useDefaultGetRequest";
import RemixIcon
    from "../../../../../global/components/data_display/RemixIcon";
import SquaresLoading
    from "../../../../../global/components/feedback/SquaresLoading";

const {Text} = Typography

const ListAmount = styled(FlexContainer)`
    //flex: 1;
    //width: min-content;
    overflow-y: scroll;
    width: 100%;
`
export default function ProgramGamificationAmounts(props) {
    const {t} = useTranslation()
    const {program} = useProgramContext()
    const {
        data: gamificationAmounts,
        isLoading: isLoading
    } = useDefaultGetRequest({
        url: `/action/program/${program.id}/get_object_releted_counters/`,
        makeRequest: !!program.id,
        dependence: [program.id]
    })
    return (
        <>
            {isLoading ? <SquaresLoading/> :
                <>
                    <ListAmount gap={16}>
                        <AmountCardGamification width={'372px'}
                                                title={t('Points awarded in this program')}
                                                color={"#6A78BE"}
                                                value={gamificationAmounts?.all_valid_points}
                                                icon={<RemixIcon
                                                    remixIconName={'ri-copper-coin-line'}/>}/>
                        <AmountCardGamification width={'372px'}
                                                title={t('Points awarded (last 7 days)')}
                                                color={"#6CBE6A"}
                                                value={gamificationAmounts?.valid_points_last_7_days}
                                                icon={
                                                    <PlusGamificationIcon/>}/>
                        <AmountCardGamification width={'372px'}
                                                title={t('Pending points')}
                                                color={"#BAA775"}
                                                value={gamificationAmounts?.pending_points}
                                                icon={<RemixIcon
                                                    remixIconName={'ri-copper-coin-line'}/>}/>

                    </ListAmount>
                </>}
        </>

    );
}


