import React from 'react';
import { Tooltip} from "antd";
import {ExclamationCircleOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {neutral_7} from "../../utils/Colors";

const StyledExclamationCircleOutlined = styled(ExclamationCircleOutlined)`
  color: ${({color}) => color};
  cursor: pointer;
`
export default function TooltipInformation({color=neutral_7, ...props}) {


    return (
        <Tooltip placement="topRight" {...props}>
            <StyledExclamationCircleOutlined color={color}/>
        </Tooltip>

    )
}