import { Column } from "@ant-design/plots";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../../utils/utils";

const data = [
    { project: "1", value: 8094 },
    { project: "2", value: 10000 },
    { project: "3", value: 7094 },
    { project: "4", value: 5094 },
    { project: "5", value: 3094 },
    { project: "6", value: 2094 },
    { project: "7", value: 6094 },
    { project: "8", value: 9094 },
    { project: "9", value: 4094 },
    { project: "10", value: 6094 },
    { project: "11", value: 5094 }
  ];

function ModelChart({color}) {
    const {t} = useTranslation();
    const config = {
        data,
        height:140,
        paddingBottom:30,
        autofit: true,
        animate: null,
        xField: "project",
        yField: "value",
        axis: {
            x:{
                title: t("Projects"),
                tickLength: 0,
            },
            y: {
                title: t("Value"),
                labelFormatter: (val) => {
                    if (val === 0) {
                        return val.toString();
                    } else {
                        return `R$ ${formatNumber(val)}`;
                    }
                },
                tickLength: 0,
            },
        },
        style: {
            fill: ({ project }) => {
                if (project === "4") {
                    return color;
                }
                return "#D9D9D9";
            },
        },
        tooltip: {
            items: [(d) => (`R$ ${formatNumber(d.value)}`)
            ],
        },
        legend: false,
    };
    return <Column {...config} />;
}

export default ModelChart;