import React, {useEffect, useState} from 'react';
import {Badge, Button, Col, Flex} from 'antd';
import GrowRadioButton from '../../../../global/components/responsive/GrowRadioButton';
import ProjectManagementTimelinePage from './ProjectManagementTimelinePage';
import {useTranslation} from 'react-i18next';
import InvestmentFinancialPlanningPage from "./InvestmentFinancialPlanningPage";
import {SettingOutlined} from "@ant-design/icons";
import PlanningInformationDrawer from "../../components/drawer/PlanningInformationDrawer";
import api from "../../../../global/services/api";
import {useProjectManagementContext} from "./context/ProjectManagementContext";

export default function ProjectManagementPlanningPage(props) {
    const {t} = useTranslation();
    const {project} = useProjectManagementContext();
    const [selected, setSelected] = useState(true);
    const [planningInformationDrawerOpen, setPlanningInformationDrawerOpen] = useState("")
    const [indicatorResponseList, setIndicatorResponseList] = useState()

    const fetchProjectIndicatorResponses = async () => {
        const response = await api.get(`/project_management/project/${project.id}/indicator_responses/main`);
        const data = response.data.filter(indicatorResponse => indicatorResponse?.indicator?.indicator_type !== "tags")
        setIndicatorResponseList(data);
    };

    useEffect(() => {
        !indicatorResponseList && project && fetchProjectIndicatorResponses()
    }, [indicatorResponseList, project]);

    const hasNotConfiguredIndicator = indicatorResponseList?.some(item => item.stored_value === null);

    return (
        <>
            <Col xs={24}>
                <Flex justify="space-between">
                    <GrowRadioButton
                        onChange={(value) => setSelected(value)}
                        value={selected}
                        options={[
                            {value: true, label: t('Schedule Planning')},
                            {value: false, label: t('Financial Planning')}
                        ]}
                    />
                    <Button icon={<SettingOutlined/>} onClick={() => setPlanningInformationDrawerOpen("planningInformation")} >
                        {t("Planning information")}
                        {hasNotConfiguredIndicator && <Badge color="red" count="!"/>}
                    </Button>
                </Flex>

                <div style={{marginTop: '20px'}}>
                    {selected ? (
                        <ProjectManagementTimelinePage/>
                    ) : (
                        <InvestmentFinancialPlanningPage/>
                    )}
                </div>
            </Col>
            <PlanningInformationDrawer
                open={planningInformationDrawerOpen}
                setOpen={setPlanningInformationDrawerOpen}
                indicatorResponseList={indicatorResponseList}
                fetchProjectIndicatorResponses={fetchProjectIndicatorResponses}
            />
        </>
    );
}