export function set_filter_params(key, params) {
    let url = window.location.pathname
    // localStorage.setItem(`${url}_${key}`, JSON.stringify(params));
}

export function get_filter_params(key, deafult = '') {
    const query = new URLSearchParams(window.location.search)
    let url = window.location.pathname
    const hasload = !!query.get('load')
    if (hasload) {
        let params = localStorage.getItem(`${url}_${key}`);
        if (params) {
            try {
                return JSON.parse(params)
            } catch (e) {
                return params
            }
        }
    }
    return deafult

}