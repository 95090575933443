import {ProjectManagementRoute} from "../../../global/routes/PrivateRoute";
import ProjectManagement from "../pages/ProjectManagement";
import ProjectHome from "../pages/ProjectHome";
import ProjectPortfolioContextProvider from "../context/ProjectPortfolioContext";
import React from "react";
import ProjectCreate from "../pages/ProjectCreate";
import {Redirect, Switch, useLocation, useRouteMatch} from "react-router-dom";
import "../css/portfolio-styles.css";
import {usePermissionContext} from "../../../global/context/PermissionContext";

function RoutesPortfolio() {
    const {path} = useRouteMatch();
    const location = useLocation();
    const {hasProjectAdminOrManagerPermission, hasProjectPortfolioMenuAccessPermission} = usePermissionContext();

    return (
        <ProjectPortfolioContextProvider>
            <Switch>
                <ProjectManagementRoute
                    exact
                    path={`${path}/project/create`}
                    hasPermission={hasProjectAdminOrManagerPermission}
                    component={ProjectCreate}
                />

                <ProjectManagementRoute
                    path={`${path}/project/:id`}
                    hasPermission={hasProjectPortfolioMenuAccessPermission}
                    render={() =>
                        <ProjectManagement />
                    }
                />

                <ProjectManagementRoute
                    path={`${path}`}
                    hasPermission={hasProjectPortfolioMenuAccessPermission}
                    render={() =>
                        <ProjectHome />
                    }
                />
                <Redirect to={{ pathname: "/404", state: { broken_url: window.location.host + location.pathname } }} />
            </Switch>
        </ProjectPortfolioContextProvider>);
}

export default RoutesPortfolio