import React from "react";
import DefaultComponent from "./DefaultComponent";
import Input from "../../../Form/Input";
import {useTranslation} from "react-i18next";

function LinkForm(props) {
    const {t} = useTranslation()
    return (
        <DefaultComponent {...props}>
            <Input  placeholder={t('Enter your link here...')} />

        </DefaultComponent>
    )
}

export default LinkForm