import { Card, Space, Typography } from "antd";
import styled from "styled-components";
import { neutral_2, secondary_color } from "../../../../../../../utils/Colors";
import { HourglassOutlined } from "@ant-design/icons";
import Flex from "../../../../../../responsive/Flex";
import { useTranslation } from "react-i18next";


const {Text} = Typography;

const StyledCard = styled(Card)`
    border-radius: 10px;
    border-color: 1px solid ${neutral_2};

    .ant-card-head {
        border: none;
    }
`

export default function RequestNotAnswered() {
    const {t} = useTranslation();

    return (
      <StyledCard>
        <Flex justify="center" align="center">
            <Space direction="vertical" align="center">
                <HourglassOutlined style={{fontSize: 20, color: `${secondary_color}`}}/>
                <Text type="secondary">{t("The author has not yet responded to the request")}</Text>
            </Space>
        </Flex>         
      </StyledCard>
    );
}
