import React from 'react'
import styled from 'styled-components'
import { Space} from "antd";

import {CheckCircleOutlined} from '@ant-design/icons';
import {FlexContainer} from "../authentication/StyledComponents";


const Container = styled.div`
    width: 100%;
 
    padding: 24px;
    //max-width: 426px;
    height: 546px;
    display: flex;
    flex-direction: column;
`


const Title = styled.h3`
    font-size: 18px;
    line-height: 22px;
    font-weight: 500;
    margin-bottom:0;

`

const SubTitle = styled.span`
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: #00000073;

`

const MainTitle = styled.div`

    padding-top: 24px;

`


const Item = styled.div`
    font-size: 12px;
    font-weight: 600;
    display: flex;
    gap: 10px;
    justify-content: center;

    svg {
        width: 16px;
        height: 16px;
    }
`

const StyledFlexContainer = styled(FlexContainer)`
    margin-top: 36px;
    flex: 1;
`

export default function CardPrice({header, title, benefits, footer, ...props}) {


    return (

        <Container {...props}>
            <Space direction={'vertical'} size={8}>
                <Space size={8}>
                    {header.icons}
                </Space>

                <Title level={5}>
                    {header.title}
                </Title>
                <SubTitle>
                    {header.subtitle}
                </SubTitle>
            </Space>
            {title && <MainTitle>
                {title}
            </MainTitle>}
            <StyledFlexContainer gap={12} align={'start'}
                   direction={'column'}>
                {benefits.map((value) => (
                    <Item>
                        <CheckCircleOutlined/>
                        <span> {value}</span>
                    </Item>
                ))}

            < /StyledFlexContainer>
            {footer}
        </Container>

    );
}