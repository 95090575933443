import React from 'react';
import {Select as AntSelect} from "antd";

export default  function Select(props) {

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <AntSelect style={{width: '100%'}} filterOption={filterOption}  {...props}/>
    );
}