import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import BreakLayoutSystemContent
    from "../../../components/layout/BreakLayoutSystemContent";
import styled from 'styled-components';
import {
    FlexContainer
} from "../../../components/authentication/StyledComponents";

import useSystemBreakpoint from "../../../hooks/useSystemBreakpoint";
import {
    page_background
} from "../../../utils/Colors";
import SectionProgramsChartsBuild from "./Sections/SectionProgramsChartsBuild";
import {usePermissionContext} from "../../../context/PermissionContext";
import SectionMetrics from "./Sections/SectionMetrics";
import Loading from "../../../components/feedback/Loading";
import SectionProjectChartsBuild from "./Sections/SectionProjectChartsBuild";
import {
    InnovationPanelProvider,
    useInnovationPanelContext
} from "./context/InnovationPanelContext";
import {Spin} from "antd";
import ButtonHelp from "./components/ButtonHelp";
import DateRangePicker from "../../../components/data_entry/DateRangePicker";
import SectionWithInput from "./Sections/SectionWithInput";

const ChartsContainer = styled(FlexContainer)`
    border-radius: 24px 24px 0 0;

    margin-top: -20px;
    z-index: 2;
    position: relative;
    background: ${page_background};
    padding: ${({isPhone}) => isPhone ? '24px 16px' : ' 24px 24px'};
`


export default function InnovationPanel() {
    return (<InnovationPanelProvider>
        <InnovationPanelInternal/>
    </InnovationPanelProvider>)

}


function InnovationPanelInternal() {
    const {t} = useTranslation();
    const {isPhone} = useSystemBreakpoint()
    const {hasProgramAdminOrManagerPermission, hasProjectAdminOrManagerPermission, hasProjectViewerPermission,hasProgramViewerPermission} = usePermissionContext()
    const {isInitialLoading, isLoadingUpdateCharts, dates, setDates} = useInnovationPanelContext()

    if (isInitialLoading) return <Loading/>

    return (<BreakLayoutSystemContent>
        <SectionWithInput title={t('Your Innovation Dashboard')} icon={<ButtonHelp/>}>
            <DateRangePicker value={dates} onChange={(date) => setDates(date)}/>
        </SectionWithInput>

        {isInitialLoading && <Loading/>}

        {!isInitialLoading &&
            <ChartsContainer isPhone={isPhone} gap={24} direction={'column'}
                             align={'stretch'}>
                <SectionMetrics/>
                <Spin spinning={isLoadingUpdateCharts} tip={t('Loading')}>
                    {(hasProgramAdminOrManagerPermission || hasProgramViewerPermission) &&
                        <SectionProgramsChartsBuild/>}
                    {(hasProjectAdminOrManagerPermission || hasProjectViewerPermission) &&
                        <SectionProjectChartsBuild />}
                </Spin>
            </ChartsContainer>}
    </BreakLayoutSystemContent>);
}