import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import api from "../../../../global/services/api";
import {getProgram} from "../../../../global/utils/_query";
import * as ConstantsMessage
    from "../../../../global/utils/fileWithMessage";
import {
    useConfirmUnsavedChangesContext
} from "../../../../global/context/ConfirmUnsavedChangesContext";
import {BuildFieldErrorsDict} from "../../../../global/utils/Utils";
import {useTranslation} from "react-i18next";
import {
    ToastNotification
} from "../../../../global/components/feedback/ToastNotification";
import {CONTINUOUS} from "../../../../global/utils/Choices";
import Loading from "../../../../global/components/feedback/Loading";
import Form from "../../../../global/components/data_entry/form/Form";
import SolvCollapse from "../../../../global/components/misc/SolvCollapse";
import InputFormField
    from "../../../../global/components/data_entry/form/InputFormField";
import {FORM_NAME} from "./constants_phase";
import DatePickerFormField
    from "../../../../global/components/data_entry/form/DatePickerFormField";
import SwitchFormField
    from "../../../../global/components/data_entry/form/SwitchFormField";
import Button from "../../../../global/components/data_entry/Button";
import {Col, Form as FormAnt, Modal, Row, Tooltip} from "antd";
import {neutral_13} from "../../../../global/utils/Colors";
import TooltipInformation
    from "../../../../global/components/data_display/TooltipInformation";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {PHASE} from "../../../../global/utils/Constants";
import {
    FlexContainer
} from "../../../../global/components/authentication/StyledComponents";
import HistoricPhaseButton
    from "../../../../global/components/data_display/HistoricPhaseButton";


export default function PageUpdatePhase() {
    const {id, phase_id} = useParams();
    const [form] = FormAnt.useForm()
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);

    const [phase, setPhase] = useState();
    const URL = `phases/${phase_id}`;

    const {setOpenConfirmation} = useConfirmUnsavedChangesContext();
    const [program, setProgram] = useState();
    const {t} = useTranslation();


    useEffect(() => {

        getProgram(id, setIsLoading, setProgram);
        const getPhase = async () => {
            try {
                setIsLoading(true);
                const response = await api.get(URL);
                setPhase(response.data);
            } catch (error) {
                BuildFieldErrorsDict(error, null, false);
            } finally {
                setIsLoading(false);
            }
        };
        getPhase();
    }, []);


    async function getFieldsValue() {
        //todo falta validar o submit

        const data = {...phase, ...await form.getFieldsValue()}
        return data
    }

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const data = await getFieldsValue()
            const response = await api.put(URL, data);

            setOpenConfirmation(false);
            ToastNotification(t(ConstantsMessage.SUCCESS));
            const phase = response.data
            let urlPath = `/programs/${id}/phases`
            if (PHASE.EVALUATION === phase.type) urlPath = `/programs/${id}/phases/${phase.id}/evaluateform`
            else if (PHASE.APPLICATION === phase.type) urlPath = `/programs/${id}/phases/${phase.id}/form`
            setTimeout(() => {
                history.push(urlPath);
            }, 100)


        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, null);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheckSubmit = async () => {

        const date_start = await form.getFieldValue(FORM_NAME.DATE_START)
        const date_end = await form.getFieldValue(FORM_NAME.DATE_END)
        const datesHasChanged = date_start !== phase.date_start || date_end !== phase.date_end

        if (program.is_confidential && datesHasChanged) {
            Modal.confirm({
                icon: <ExclamationCircleOutlined/>,
                title: t("Date Changing"),
                content: t(
                    "This program has hidden subscription mode enabled. Changing step data can compromise data secrecy. The change will be registered and will be visible to all managers. Would you like to receive with the change?",
                ),
                onOk: handleSubmit,
                okText: t('Yes'),
                cancelText: t('No')

            })
        } else handleSubmit();
    }
    const handleDelete = () => {
        Modal.confirm({
                icon: <ExclamationCircleOutlined/>,
                title: t("Delete Phase"),
                content: t("Are you sure you want to delete this phase?"),
                onOk: async () => {
                    try {
                        setIsLoading(true);
                        await api.delete(`phases/${phase_id}`);
                        ToastNotification(t("Phase was successfully deleted"));
                        history.push(`/programs/${id}/phases`);
                    } catch (error) {
                        BuildFieldErrorsDict(error, null, false);
                    } finally {
                        setIsLoading(false);
                    }
                },
                okText: t('Yes'),
                cancelText: t('No')

            }
        )


    };

    const isNotContinousProgram = program?.duration !== CONTINUOUS
    const hasApplications = phase?.applications > 0
    const cannotDeletePhase = (() => {
        if(phase?.sequence === 1){
            return t("It is not possible to delete this phase because it is the initial registration phase.")
        }
        if(hasApplications){
            return t("Phase cannot be deleted because there are applications in it.")
        }
        return false
    })()

    return (
        <>
            {!program || !phase ? <Loading/> : (

                <Row gutter={[24, 24]}>
                    <Col xs={24}>
                        <FlexContainer justify={'end'}>
                            <HistoricPhaseButton phase={phase}/>
                        </FlexContainer>

                    </Col>
                    <Col xs={24}>

                        <Form initialValues={phase}
                              onValuesChange={(changedValues) => setOpenConfirmation(true)}
                              disabled={isLoading} form={form}
                              onFinish={handleCheckSubmit}>
                            <Row gutter={[0, 24]}>
                                <Col xs={24}>
                                    <SolvCollapse active bordered
                                                  title={t("General")}>
                                        <Row gutter={[24, 0]}>

                                            <Col xs={24} md={12}>
                                                <InputFormField form={{
                                                    name: FORM_NAME.TITLE,
                                                    label: t('Title'),
                                                    rules: [{
                                                        required: true,
                                                        message: t('This field is require')
                                                    }]
                                                }}/>
                                            </Col>
                                            <Col xs={24} md={12}>
                                                <InputFormField form={{
                                                    name: FORM_NAME.SHORT_DESCRIPTION,
                                                    label: t('Description'),
                                                    rules:[
                                                        {max:1000, message: t('characterLimit', { count: 1000 })},
                                                    ],
                                                    validateDebounce:1000
                                                }}/>
                                            </Col>

                                            {(phase.sequence === 1 || isNotContinousProgram) &&
                                                <Col xs={24} md={12}>
                                                    <DatePickerFormField
                                                        showTime
                                                        form={{
                                                            name: FORM_NAME.DATE_START,
                                                            label: t('Launch time'),
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: t('This field is require'),

                                                                },
                                                                ({getFieldValue}) => ({
                                                                    validator(rule, dateStart) {
                                                                        const dateEnd = getFieldValue(FORM_NAME.DATE_END)

                                                                        if (dateEnd && dateEnd <= dateStart) {
                                                                            return Promise.reject(new Error(t('The launch time  cannot be greater than the deadline')));
                                                                        }
                                                                        return Promise.resolve();
                                                                    }
                                                                })
                                                            ]
                                                        }}/>
                                                </Col>}
                                            {isNotContinousProgram &&
                                                <Col xs={24} md={12}>
                                                    <DatePickerFormField
                                                        showTime
                                                        form={{
                                                            name: FORM_NAME.DATE_END,
                                                            label: t('Deadline'),
                                                            rules: [{
                                                                required: true,
                                                                message: t('This field is require')
                                                            }, ({getFieldValue}) => ({
                                                                validator(rule, dateEnd) {
                                                                    const dateStart = getFieldValue(FORM_NAME.DATE_START)

                                                                    if (dateStart && dateEnd <= dateStart) {
                                                                        return Promise.reject(new Error(t('The deadline cannot be shorter than the launch time')));
                                                                    }
                                                                    return Promise.resolve();
                                                                }
                                                            })]
                                                        }}/>
                                                </Col>}


                                            <Col xs={24} md={12}>
                                                <SwitchFormField form={{
                                                    name: FORM_NAME.IS_ACTIVE,
                                                    label: t('Active step'),
                                                    help: t('Select to activate this step.'),
                                                }}/>
                                            </Col>
                                            {program.is_confidential && phase.type === PHASE.APPLICATION &&
                                                <Col xs={24} md={12}>
                                                    <SwitchFormField form={{
                                                        name: FORM_NAME.IS_CONFIDENTIAL,
                                                        label: t("Hidden inscriptions"),
                                                    }}/>
                                                </Col>}

                                        </Row>


                                    </SolvCollapse>
                                </Col>
                                <Col xs={24}>
                                    <Row justify={'end'} gutter={[8, 8]}>
                                        <Col>
                                            <Tooltip title={cannotDeletePhase || ""}>
                                                <div>
                                                    <Button type={'default'} danger
                                                        loading={isLoading}
                                                        disabled={cannotDeletePhase}
                                                        onClick={handleDelete}
                                                    >
                                                        {t("Delete")}
                                                    </Button>
                                                </div>
                                            </Tooltip>
                                        </Col>
                                        <Col>
                                            <Button
                                                loading={isLoading}
                                                htmlType={'submit'}>{t("Save")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>


                        </Form>

                    </Col>
                </Row>
            )}
        </>
    );
}
