import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {
    ExclamationCircleOutlined,
    PlusOutlined, SettingOutlined,
} from "@ant-design/icons";
import Button from "../../../../../components/data_entry/Button";
import Drawer from "../../../../../components/layout/Drawer";

import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import api from "../../../../../services/api";
import {
    ToastNotification
} from "../../../../../components/feedback/ToastNotification";
import * as ConstantsMessage from "../../../../../utils/fileWithMessage";
import {BuildFieldErrorsDict} from "../../../../../utils/Utils";
import {
    useLayoutContext
} from "../../../../../../modules/project/components/layout/context/LayoutContext";

import RemixIcon from "../../../../../components/data_display/RemixIcon";
import {neutral_5} from "../../../../../utils/Colors";
import {Modal} from "antd";
import Table from "../../../../../components/data_display/Table";
import useTable from "../../../../../hooks/useTable";
import Flex from "../../../../../components/responsive/Flex";
import DrawerAddPoints from "./DrawerAddPoints";
import UserHistorypointsButton from "./UserHistorypointsButton";

const Message = styled.span`
    font-size: 14px;
`
const StyledSettingOutlined = styled(SettingOutlined)`
    cursor: pointer;
    padding: 4px;
    border-radius: 4px;

    //background: red;

    :hover {
        background: ${neutral_5};
    }
`


const QUERY_PARAMS_NAMES = {
    SEARCH: 'search',
}

export default function SectionUsersTable({handleRefreshCounts, search}) {
    const {t} = useTranslation();
    const [currentUserToAddPoint, setCurrentUserToAddPoint] = useState()
    const [userId, setUserId] = useState()
    const [openUserHistoryPoint, setOpenCurrentUserHistoryPoint] = useState()



    const {
        tableData, isLoading, tableParams, handleTableChange,resetTable
    } = useTable({
        // useHandleRequestTable: false,
        url: `gamification_users`,
        hasPagination: true,
        extraParams: {
            [QUERY_PARAMS_NAMES.SEARCH]: search,
        },
        dependenceRequest: [search],
    })

    const columns = [
        {
            title: t('Name'),
            dataIndex: 'user.name',
            sorter: 'user__first_name',
            key: 'name',
        }, {
            title: t('Email'),
            dataIndex: 'user.email',
            sorter: 'user__email',
            key: 'email',

        }, {
            title: t('Points'),
            dataIndex: 'wallet_points',
            sorter: 'wallet_points',
            key: 'points',
            noEmptyIcon: true,
            render: (_, record) => (
                <span data-cy={`wallet-points-${record.user.email}`}>{_}</span>
            )

        }, {
            title: t("Actions"),
            dataIndex: "action",
            key: "action",
            render: (_, record) => (
                <FlexContainer>
                    <Button loading={isLoading}
                       onClick={() => handleOpenHistoryPoints(record)}
                       type="link">{t("See history")}</Button>
                    <Button loading={isLoading}
                            onClick={() => handleOpenAddPoints(record)}
                            type="link" data-cy={`add-points-button-${record.id}`}>{t("Add points")}</Button>
                </FlexContainer>


            ),
        }]


    function handleOpenAddPoints(record) {
        // setCurrentUserToAddPoint(record.id)
        setCurrentUserToAddPoint(record.id)

    }
    function handleOpenHistoryPoints(record) {
        // setCurrentUserToAddPoint(record.id)
        setUserId(record.id)
        setOpenCurrentUserHistoryPoint(true)

    }


    function updateCounts(){
        handleRefreshCounts()
        resetTable()
    }

    return (
        <>
            <Table columns={columns}
                   onChange={handleTableChange}
                   tableParams={tableParams}
                   loading={isLoading}
                // onRowClick={handleOnRowClick}
                   noScroll
                   dataSource={tableData}/>
            <DrawerAddPoints updateCounts={updateCounts} user_id={currentUserToAddPoint} onClose={() => setCurrentUserToAddPoint(null)}/>
            <UserHistorypointsButton  userId={userId} open={openUserHistoryPoint} setOpen={setOpenCurrentUserHistoryPoint} />
        </>


    )
}
