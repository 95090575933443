import { Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";


function SolvModal({children, title, footer, open, setOpen, maskClosable=false, ...props }) {
    const handleCancel = () => {setOpen(false);};
    return (
        <Modal
            open={open}
            closeIcon={< CloseCircleOutlined style={{color:"#000000D9", fontSize:16}}/>}
            title={title}
            maskClosable = {maskClosable}
            footer={footer}
            onCancel={handleCancel}
            {...props}
        >
            {children}
        </Modal>
    )
}

export default SolvModal