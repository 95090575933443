import {renderBadge, renderCurrency, renderDate, renderProgress, renderTags} from "../../../../global/components/tables/utils";

export function renderIndicator(indicator = {}, maxTagsOverflow = 1) {
    const valueIsNull = indicator.value_display === null || indicator.value_display === undefined;
    const tagsIsNull = !indicator.tags || !indicator.tags.length;
    if (valueIsNull && tagsIsNull) {
        return '-';
    }
    switch (indicator.view) {
        case "date":
            return renderDate(indicator.value_display);
        case "currency":
            return renderCurrency(indicator.value_display);
        case "rating":
            return renderBadge(indicator.value_display, indicator.color);
        case "progress":
            return renderProgress(indicator.value_display);
        case "tags":
            return renderTags(indicator.tags, maxTagsOverflow);
        default:
            return indicator.value_display;
    }
}