import React, {useEffect, useRef, useState} from "react";
import {Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import api from "../../../../../global/services/api";
import Loading from "../../../../../global/components/feedback/Loading";
import {useParams} from "react-router-dom";

import styled from "styled-components";
import NotContent from "../../../../../components/NotContent/NotContent";
import OffCanvasComponent from "../../../../../components/Offcanvas";
import FilterIcon from "../../../../../assets/Icon/filter.svg";
import {getProgram} from "../../../../../global/utils/_query";
import FilterQuestions from "../../../../../components/Application/FormQuestionList/FilterQuestions";
import Title from "../../../../../components/Title/Title";
import ButtonDefault from "../../../../../components/Buttons/ButtonDefault";
import {StyledPhaseFeedBack} from "./styles";
import SpanStatusApplication from "../../../../../components/SpanStatusApplication/SpanStatusApplication";
import CardApplication from "../../../../../components/CardApplication/CardApplication";
import RadioButton from "../../../../../components/Form/RadioButton";
import Modal from "react-bootstrap/Modal";
import TextArea from "../../../../../components/Form/TextArea";
import {confirmAlert} from "react-confirm-alert";
import FeedbackApplicationsSectorsFilters from "../../../../../components/Phases/PhaseFeedback/FeedbackApplicationsSectorsFilters";
import CheckBox from "../../../../../components/Form/CheckBox";
import FormSelect from "../../../../../components/Form/FormSelect/Index";
import {BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import {useTranslation} from "react-i18next";
import SelectCountList from "../../../../../components/Pagination/SelectCountList/SelectCountList";
import ShowNumberList from "../../../../../components/Pagination/ShowNumberList/ShowNumberList";
import * as PropTypes from "prop-types";
import {ShowNumberListContainer} from "../../../../../components/Phases/Allocation";
import FilterButtons from "../../../../../components/Filters/FilterButtons";
import {ButtonsContainer, FilterButton, FilterButtonsContainer} from "../ApplicationSelection";
import Search from "../../../../../components/Search";
import {useLayoutContext} from "../../../../../modules/project/components/layout/context/LayoutContext";
import {ToastNotification} from "../../../../../global/components/feedback/ToastNotification";
import {CardContainer} from "../../../../../global/components/misc/CardContainer";
import ApplicationDrawer
    from "../../../../../global/components/other/application/ApplicationDrawer/ApplicationDrawer";

const FilterButtom = styled.img`
  &:hover {
    cursor: pointer;
  }
`;

const StyledModalButtonRight = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const StyledRedButton = styled.button`
  margin-right: 8px;
  background-color: #fef2ec;
  color: #f44336;
  width: 100px;
  padding: 4px 0px;
  border: 1px solid #f8f8f8;
  border-radius: 4px;
  margin-left: 8px;

  &:hover {
    background-color: #fef2ec;
    color: #f44336;
    border: 1px solid;
  }

  &:disabled {
    color: #adadad;
    background-color: #efefef;
    cursor: not-allowed;
    border: 1px solid transparent;
  }
`;

const StyledGreenButton = styled.button`
  background-color: #def7e4;
  color: #28a745;
  padding: 4px 0;
  border: 1px solid #f8f8f8;
  border-radius: 4px;
  margin-right: 8px;

  &:hover {
    background-color: #def7e4;
    color: #28a745;
    border: 1px solid;
  }

  &:disabled {
    color: #adadad;
    background-color: #efefef;
    cursor: not-allowed;
    border: 1px solid transparent;
  }
`;

const MiniButtonReprove = styled.button`
  max-height: 36px;
`;

ShowNumberListContainer.propTypes = {children: PropTypes.node};

function PhaseFeedback() {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingApplications, setIsLoadingApplications] = useState(false);
    const [feedbacksApplication, setFeedbacksApplication] = useState([]);
    const [approvedDefaultFeedback, setApprovedDefaultFeedback] = useState("");
    const [reprovedDefaultFeedback, setReprovedDefaultFeedback] = useState("");
    const [lastValueFeedback, setLastValueFeedback] = useState({
        approved: "",
        reproved: "",
    });
    const [search, setSearch] = useState("");
    const [program, setProgram] = useState({public: true});
    const [isSelectAll, setIsSelectAll] = useState();
    const {id, phase_id} = useParams();
    const checkAllElement = useRef();
    const cardsApplications = useRef([]);
    const [applicationsIdsSelected, setApplicationsIdsSelected] = useState([]);
    // const [listMode, setListMode] = useState(false)
    //FILTER / ORDER
    const [selectedOrder, setSelectedOrder] = useState("name");
    const [orderChanged, setOrderChanged] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState();
    const [statusFilter, setStatusFilter] = useState("all");
    const [challenges, setChallenges] = useState([]);
    const [showApplicationsFilters, setShowApplicationsFilters] = useState(false);
    const [questions, setQuestions] = useState([]);

    const [showClearFilters, setShowClearFilters] = useState(false);
    const [showAlertForm, setShowAlertForm] = useState(false);
    const [dataForm, setDataForm] = useState({});
    const URL = `phases/${phase_id}`;

    const formQuestions = useRef(null);
    const [resetFilters, setResetFilters] = useState(false);

    //MODAL
    const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [messageModal, setMessageModal] = useState("");
    const [showInformation, setShowInformation] = useState(false);
    const [applicationModal, setApplicationModal] = useState(null);
    const [feedbackModal, setFeedbackModal] = useState(null);
    const [isSendLoading, setIsSendLoading] = useState(false);
    const {hasInitialized, setIsConfidential} = useLayoutContext();

    const [filterData, setFilterData] = useState([]);
    const {t} = useTranslation();


    useEffect(() => {
        if (hasInitialized, program) {
            setIsConfidential(program["check_is_confidential"]);
        }
    }, [hasInitialized, program]);

    //END FILTER
    useEffect(() => {
        setResetFilters(false);
    }, [showClearFilters]);

    const getFeedbacks = async (status = "all") => {
        try {
            setResetFilters(true);
            setShowClearFilters(false);
            setStatusFilter(status);
            setIsSelectAll(false);
            setIsLoadingApplications(true);
            const responseActualCard = await api.get(`/phase_feedback_application/phase/${phase_id}?status=${status}&orderning=${selectedOrder}`);
            let applicationResponse = responseActualCard.data.map((application) => {
                return {
                    ...application,
                    defaultChecked: ["2,3"],
                    statusFeedback: application.is_customized_message,
                    messageActual: application.message,
                };
            });
            setFeedbacksApplication(applicationResponse);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        } finally {
            setIsLoadingApplications(false);
            setResetFilters(false);
        }
    };

    const getDefaultFeedback = async () => {
        try {
            const response = await api.get(`phases/${phase_id}`);
            setApprovedDefaultFeedback(response.data.default_approved_feedback);
            setReprovedDefaultFeedback(response.data.default_reproved_feedback);
            setLastValueFeedback({
                approved: response.data.default_approved_feedback,
                reproved: response.data.default_reproved_feedback,
            });
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
    };

    const getQuestions = async () => {
        try {
            const response = await api.get(`phases/${phase_id}/get_filter_question_phase`);
            setQuestions(response.data);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
    };

    const getChallenges = async () => {
        try {
            const response = await api.get(`programs/${id}/challenges`);
            let aux = [];
            response.data.map((option) => {
                aux.push({value: option.id, label: option.name});
            });
            setChallenges(aux);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
    };

    useEffect(() => {
        getFeedbacks();
        getQuestions();
        getDefaultFeedback();
        getChallenges();
    }, []);

    const handleClearFilters = () => {
        setFilterData({});
        getFeedbacks(statusFilter);
        setResetFilters(true);
        setQuestions([...questions]);
        setShowClearFilters(false);
    };

    const handleApplyFilters = async () => {
        if (filterData == null) {
            handleClearFilters();
            return;
        }

        try {
            setIsLoadingApplications(true);
            setApplicationsIdsSelected([]);
            setIsSelectAll(false);
            let response = await api.post(
                `/phase_feedback_application/phase/${phase_id}?status=${statusFilter}&orderning=${selectedOrder}&search=${search}`,
                filterData,
            );
            if (response.data.length === 0) {
                ToastNotification(t("No applications with this filters"),'warning');
            }
            setFeedbacksApplication(response.data);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        } finally {
            setIsLoadingApplications(false);
        }
    };

    const showFilterButtons = () => {
        let formData = new FormData(formQuestions.current);
        //VALIDACAO SE POSSUI DADOS PRA REALIZAR A REQUISICAO
        let tags_to_remove = [];
        let contador = 0;
        for (var pair of formData.entries()) {
            contador = contador + 1;
            if (!pair[1]) {
                tags_to_remove.push(pair[0]);
            }
        }
        tags_to_remove.forEach((tag) => {
            formData.delete(tag);
        });
        if (contador - tags_to_remove.length > 1) {
            setShowClearFilters(true);
            setFilterData(formData);
            // getApplicationsFilter()
        } else {
            setFilterData(null);
        }
    };
    const getStatus = (status_filter) => {
        if (status_filter === statusFilter) {
            return "active";
        }
    };

    const renderOptions = () => {
        return (
            <>
                <ButtonsContainer>
                    <CheckBox
                        id="AllChecked"
                        ref={checkAllElement}
                        name="AllChecked"
                        onChange={handleSelectAll}
                        defaultChecked={isSelectAll && ["1"]}
                        options={[
                            {
                                id: "1",
                                label: t("All"),
                                checked: isSelectAll,
                            },
                        ]}
                    />
                    {isSelectAll || applicationsIdsSelected.length > 0 ? (
                        <>
                            <RadioButton
                                id="AllChecked"
                                name="AllChecked"
                                options={[
                                    {
                                        id: "1",
                                        label: t("Customized feedback"),
                                        onClick: () => changeTypeCustomizeFeedback(true),
                                    },
                                    {
                                        id: "2",
                                        label: t("Standard feedback"),
                                        onClick: () => changeTypeCustomizeFeedback(false),
                                    },
                                ]}
                            />
                            <FilterButtonsContainer>
                                <MiniButtonReprove className="miniButtonReprove" onClick={confirmAllDontSend}>
                                    {t("Don't send")}
                                </MiniButtonReprove>
                                <ButtonDefault action={"primary"} label={t("Send")} onClick={confirmAllSend}/>
                            </FilterButtonsContainer>
                        </>
                    ) : (
                        <>
                            <Search
                                value={search}
                                placeholder={t("Type here to search applications...")}
                                onChange={(e) => setSearch(e.target.value)}
                                onClick={() => handleApplyFilters()}
                                onBlur={() => handleApplyFilters()}
                            />
                            <FilterButtom
                                style={{
                                    width: 14,
                                    marginRight: 8,
                                    marginLeft: 8,
                                }}
                                src={FilterIcon}
                                onClick={() => setShowApplicationsFilters(true)}
                            />
                            {/*<ListSwitchButtons setListMode={setListMode} listMode={listMode}/>*/}
                        </>
                    )}
                </ButtonsContainer>
            </>
        );
    };

    const orderOptions = [
        {value: "name", label: t("Name (A-Z)")},
        {value: "-name", label: t("Name (Z-A)")},
        {value: "more", label: t("More evaluators allocated")},
        {value: "less", label: t("Less evaluators allocated")},
        {value: "higher", label: t("Higher average score")},
        {value: "lower", label: t("Lower average score")},
    ];

    const statusOptions = [
        {value: "true", label: t("Aproved")},
        {value: "false", label: t("Reproved")},
        {value: "null", label: t("Pending")},
    ];

    const getPhase = async () => {
        try {
            setIsLoading(true);
            const response = await api.get(URL);
            setShowAlertForm(!response.data.has_form);
            setDataForm(response.data);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        } finally {
            setIsLoading(false);
        }
    };

    const closeFeedbackModal = () => {
        setOpenFeedbackModal(false);
        setApprovedDefaultFeedback(lastValueFeedback.approved);
        setReprovedDefaultFeedback(lastValueFeedback.reproved);
    };

    useEffect(() => {
        getProgram(id, setIsLoading, setProgram);
        getPhase();
        setShowModal(false);
    }, []);

    const feedbackOptions = (feedback_application) => {
        if (feedback_application.is_application_aproved) {
            return [
                {id: "1", label: t("Customized feedback")},
                {id: "2", label: t("Approved standard feedback")},
            ];
        } else {
            return [
                {id: "1", label: t("Customized feedback")},
                {id: "3", label: t("Reproved standard feedback")},
            ];
        }
    };

    const handleSelecteds = ({currentTarget}, feedback) => {
        const {value} = currentTarget;
        let status = value === "1";

        const newFeed = feedbacksApplication.map((item) => {
            if (feedback.id === item.id)
                return {
                    ...item,
                    defaultChecked: [value],
                    statusFeedback: status,
                    is_customized_message: status,
                };
            return item;
        });
        setFeedbacksApplication(newFeed);
        handleSelectedsPut(feedback.id, status);
    };

    const handleSelectedsPut = async (id, status) => {
        try {
            await api.patch(`phase_feedback_application/${id}`, {
                is_customized_message: status,
            });
            ToastNotification(t("Successfully"));
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
    };

    const confirmDontSent = (id) => {
        confirmAlert({
            title: t("Confirm action"),
            message: t("Are you sure you don't want to send feedback to this application? This action can't be undone"),
            buttons: [
                {
                    label: t("Cancel"),
                    onClick: () => "",
                },
                {
                    label: t("Yes"),
                    onClick: () => dontSent(id),
                },
            ],
        });
    };
    const dontSent = async (id) => {
        try {
            await api.patch(`phase_feedback_application/${id}`, {
                status: "dont_sent",
            });
            ToastNotification(t("Successfully"));
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        } finally {
            getFeedbacks(statusFilter);
        }
    };

    function modalFeedbackOpen(item) {
        checkingMessage(item);
        setFeedbackModal(item);
        setTimeout(() => setShowModal(true), 100);
    }

    const modalApplicationInfo = async (item) => {
        try {
            const response = await api.get(`applications/${item.application.id}`);
            setApplicationModal(response.data);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        } finally {
            setTimeout(() => setShowInformation(true), 100);
        }
    };

    function checkingMessage(item) {
        if (item.messageActual !== null && item.statusFeedback) {
            setMessageModal(item.messageActual);
        } else if (item.messageActual == null && item.statusFeedback) {
            setMessageModal("");
        } else if (item.statusFeedback === false && item.is_application_aproved) {
            setMessageModal(approvedDefaultFeedback);
        } else {
            setMessageModal(reprovedDefaultFeedback);
        }
    }

    const importFromStandard = async (item) => {
        let newMessageModal = messageModal || '';
        if (messageModal?.trim() !== "") newMessageModal += "\n";
        if (item.is_application_aproved) {
            setMessageModal(newMessageModal + approvedDefaultFeedback);
        } else {
            setMessageModal(newMessageModal + reprovedDefaultFeedback);
        }
    };

    const importFromEvaluators = async (item) => {
        try {
            const response = await api.get(`phase_feedback_application/${item.id}/message_import_from_evaluators`);
            if (response.data.comp_score.length !== 0) {
                const parsedCompscore = response.data.comp_score.reduce((acc, curr, index) => {
                    let responsesTitle = "";
                    curr.reponses.forEach((elemento) => {
                        responsesTitle += `\n${t("EVALUATOR")}: ${elemento.evaluator.split(" ")[1]}${
                            elemento.feedback == null ? "" : `\n${t("FEEDBACK")}: ${elemento.feedback} `
                        }${elemento.score == null ? "\n" : ` \n${t("SCORE")}: ${elemento.score}\n`}`;
                    });
                    return `${acc}${curr.title.toString()}${responsesTitle}\n`;
                }, "");
                let newMessageModal = messageModal || ''
                if (messageModal?.trim() !== "") newMessageModal += "\n";
                setMessageModal(newMessageModal + parsedCompscore);
            } else {
                ToastNotification(t("No evaluators feedback message was found"),'warning');
            }
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
    };

    const saveMessage = async (item, show_toast = true) => {
        try {
            if (messageModal.length === 0) {
                await api.patch(`phase_feedback_application/${item.id}`, {
                    message: messageModal,
                    status: "pending",
                });
            } else {
                await api.patch(`phase_feedback_application/${item.id}`, {
                    message: messageModal,
                    status: "written",
                });
            }
            const messageActualResponse = feedbacksApplication.map((itemMessage) => {
                if (itemMessage.id === item.id && messageModal.length === 0) {
                    return {
                        ...itemMessage,
                        messageActual: messageModal,
                        status: "pending",
                    };
                } else if (itemMessage.id === item.id) {
                    return {
                        ...itemMessage,
                        messageActual: messageModal,
                        status: "written",
                    };
                } else {
                    return {
                        ...itemMessage,
                    };
                }
            });
            setFeedbacksApplication(messageActualResponse);
            show_toast && ToastNotification(t("Successfully"));
            return true;
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
            return false;
        }
    };

    const sendMessage = async (item) => {
        var saved = saveMessage(item, false);
        if (!saved) {
            return false;
        }
        if (messageModal.length === 0 && feedbackModal.is_customized_message) {
            ToastNotification(t("There is no saved message"),'error');
        } else {
            setIsSendLoading(true);
            try {
                await api.post(`phase_feedback_application/${item.id}/send_email_feedback`);
                const sendMessageArray = feedbacksApplication.map((itemMessage) => {
                    if (itemMessage.id === item.id) {
                        return {
                            ...itemMessage,
                            is_send: true,
                            status: "sent",
                        };
                    } else {
                        return {
                            ...itemMessage,
                        };
                    }
                });
                setFeedbacksApplication(sendMessageArray);
                ToastNotification(t("Successfully"));
                setShowModal(false);
            } catch (error) {
                BuildFieldErrorsDict(error, null, false);
            } finally {
                setIsSendLoading(false);
            }
        }
    };

    async function setFeedbacks() {
        try {
            const data = {};
            if (approvedDefaultFeedback?.trim() !== "") data["default_approved_feedback"] = approvedDefaultFeedback;
            if (reprovedDefaultFeedback?.trim() !== "") data["default_reproved_feedback"] = reprovedDefaultFeedback;

            await api.patch(`phases/${phase_id}`, data);
            setLastValueFeedback({
                approved: approvedDefaultFeedback,
                reproved: reprovedDefaultFeedback,
            });
            ToastNotification(t("Successfully"));
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
    }

    const checkSendButton = () => {
        return messageModal === null || messageModal === "" || feedbackModal.is_send;
    };

    function handleSelectAll({currentTarget}) {
        setIsSelectAll(currentTarget.checked);
        cardsApplications.current
            .filter((item) => item !== null)
            .forEach((card) => {
                card.querySelector('input[type="checkbox"]').checked = currentTarget.checked;
            });
        if (currentTarget.checked) {
            setApplicationsIdsSelected(feedbacksApplication.map((feedback) => feedback.application.id));
        } else {
            setApplicationsIdsSelected([]);
        }
    }

    function handleSelectCard({currentTarget}, item) {
        if (currentTarget.checked) {
            setApplicationsIdsSelected([...applicationsIdsSelected, item.application.id]);
        } else {
            setApplicationsIdsSelected((feeds) => {
                return feeds.filter((id) => item.application.id !== id);
            });
        }
    }

    useEffect(() => {
        //Tira todas as seleções quando muda de fintro no botoes de all, pending ...
        setApplicationsIdsSelected([]);
        setIsSelectAll(false);
    }, [statusFilter]);

    const confirmAllDontSend = () => {
        confirmAlert({
            title: t("Confirm action"),
            message: t("Are you sure you don't want to send feedback to this all applications? This action can't be undone"),
            buttons: [
                {
                    label: t("Cancel"),
                    onClick: () => "",
                },
                {
                    label: t("Yes"),
                    onClick: async () => {
                        try {
                            setIsLoading(true);
                            const feedbacksList = feedbacksApplication.filter(
                                (feed) => applicationsIdsSelected.includes(feed.application.id) && feed.status !== "dont_sent" && feed.status !== "sent",
                            );
                            const data = {
                                ids_feedbacks: feedbacksList.map((feed) => feed.id),
                            };
                            await api.post(`phase_feedback_application/dont_send_for_list`, data);
                            ToastNotification(t("Successfully"));
                        } catch (error) {
                            BuildFieldErrorsDict(error, null, false);
                        } finally {
                            setIsLoading(false);
                            getFeedbacks(statusFilter);
                        }
                    },
                },
            ],
        });
    };

    const confirmAllSend = (id) => {
        confirmAlert({
            title: t("Confirm action"),
            // message: t("Are you sure you want to send feedback to this all application? This action can't be undone"),
            message: t("Do you want to send feedbacks to all applications? This action cannot be undone"),
            buttons: [
                {
                    label: t("Cancel"),
                    onClick: () => "",
                },
                {
                    label: t("Yes"),
                    onClick: async () => {
                        try {
                            setIsLoading(true);
                            const feedbacksList = feedbacksApplication.filter(
                                (feed) => applicationsIdsSelected.includes(feed.application.id) && feed.status !== "dont_sent" && feed.status !== "sent",
                            );
                            const data = {
                                ids_feedbacks: feedbacksList.map((feed) => feed.id),
                            };
                            await api.post(`phase_feedback_application/send_email_for_feedback_list`, data);
                            ToastNotification(t("Successfully"));
                        } catch (error) {
                            BuildFieldErrorsDict(error, null, false);
                        } finally {
                            setIsLoading(false);
                            getFeedbacks(statusFilter);
                        }
                    },
                },
            ],
        });
    };

    async function changeTypeCustomizeFeedback(status) {
        const feedbacksList = feedbacksApplication.filter(
            (feed) => applicationsIdsSelected.includes(feed.application.id) && feed.status !== "dont_sent" && feed.status !== "sent",
        );

        try {
            const data = {
                ids_feedbacks: feedbacksList.map((feed) => feed.id),
                is_customized_message: status,
            };
            await api.post(`phase_feedback_application/change_type_feedback`, data);
            setFeedbacksApplication((feedbacks) => {
                const newfeedbacks = feedbacks.map((feed) => {
                    if (applicationsIdsSelected.includes(feed.application.id) && feed.status !== "dont_sent" && feed.status !== "sent") {
                        feed.is_customized_message = status;
                        feed.statusFeedback = status;
                    }
                    return feed;
                });
                return newfeedbacks;
            });
            ToastNotification(t("Successfully"));
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
    }

    return (
        <CardContainer>
            <StyledPhaseFeedBack>
                {renderOptions()}
                <div className="d-flex align-items-center justify-content-between">
                    <FilterButtonsContainer>
                        <FilterButton onClick={() => getFeedbacks("all")} className={"statusQtdSpan " + getStatus("all")}>
                            {t("All")}
                        </FilterButton>
                        <FilterButton onClick={() => getFeedbacks("pending")} className={"statusQtdSpan " + getStatus("pending")}>
                            {t("Pending Items")}
                        </FilterButton>
                        <FilterButton onClick={() => getFeedbacks("written")} className={"statusQtdSpan " + getStatus("written")}>
                            {t("Written")}
                        </FilterButton>
                        <FilterButton onClick={() => getFeedbacks("dont_sent")} className={"statusQtdSpan " + getStatus("dont_sent")}>
                            {t("Not sent")}
                        </FilterButton>
                        <FilterButton onClick={() => getFeedbacks("sent")} className={"statusQtdSpan " + getStatus("sent")}>
                            {t("Sent")}
                        </FilterButton>
                    </FilterButtonsContainer>
                    <ShowNumberListContainer selected={applicationsIdsSelected.length}>
                        {applicationsIdsSelected.length === 0 ? "" :
                            <SelectCountList count_selected={applicationsIdsSelected.length}/>}
                        <ShowNumberList count_displayed={feedbacksApplication.length}
                                        count_total={feedbacksApplication.length}/>
                    </ShowNumberListContainer>
                </div>
                {isLoadingApplications ? (
                    <Loading/>
                ) : feedbacksApplication.length === 0 ? (
                    <div>
                        <NotContent title={t("Don't have evaluation yet.")}/>
                    </div>
                ) : (
                    <div>
                        {feedbacksApplication.map((item, index) => (
                            <div key={index}
                                 style={statusFilter === "all" ? {} : statusFilter === item.status ? {} : {display: "none"}}>
                                <CardApplication
                                    status={item}
                                    refElement={(e) => (cardsApplications.current[index] = e)}
                                    application={item.application}
                                    checked={applicationsIdsSelected}
                                    onClickSelected={(event) => handleSelectCard(event, item)}
                                    onClickName={() => modalApplicationInfo(item)}
                                >
                                    <div className={"moreInfo-right"}>
                                        <SpanStatusApplication isApproved={item.is_application_aproved}/>

                                        <RadioButton
                                            id={item.id}
                                            name={item.id}
                                            defaultChecked={item.is_customized_message ? "1" : ["3", "2"]}
                                            disabled={item.status === "dont_sent" || item.status === "sent"}
                                            options={feedbackOptions(item)}
                                            onChange={(event) => handleSelecteds(event, item)}
                                        />
                                        <StyledRedButton
                                            onClick={() => confirmDontSent(item.id)}
                                            className="miniButtonReprove"
                                            disabled={item.status === "dont_sent" || item.status === "sent"}
                                        >
                                            {t("Don't send")}
                                        </StyledRedButton>
                                        <StyledGreenButton
                                            onClick={() => modalFeedbackOpen(item)}
                                            style={{width: 161}}
                                            disabled={item.status === "dont_sent"}
                                            className="miniButtonAprove"
                                        >
                                            {t("Review feedback")}
                                        </StyledGreenButton>
                                    </div>
                                </CardApplication>
                            </div>
                        ))}
                    </div>
                )}
                <div className="d-flex align-items-center justify-content-end gap-2">
                    <ButtonDefault
                        action={"primary"}
                        label={t("Write Standard Feedbacks")}
                        onClick={() => setOpenFeedbackModal(true)}
                    />
                </div>
            </StyledPhaseFeedBack>
            <ApplicationDrawer solutionId={applicationModal?.id} open={showInformation} setOpen={setShowInformation}  />
            <Modal size="lg" show={showModal} onHide={() => setShowModal(false)} animation={false}>
                <Modal.Header closeButton>
                    <Title>{t("Feedback")}</Title>
                </Modal.Header>
                {feedbackModal === null ? null : (
                    <Modal.Body>
                        {isSendLoading ? (
                            <Loading/>
                        ) : (
                            <>
                                <Row>
                                    <Col>
                                        <TextArea
                                            disabled={feedbackModal.statusFeedback ? false : true}
                                            style={{height: 300}}
                                            value={messageModal}
                                            onChange={(e) => setMessageModal(e.target.value)}
                                            required
                                            id="description"
                                        />
                                    </Col>
                                </Row>

                                <div
                                    className={"buttons-modal"}
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <div
                                        className={"moreInfo-left"}
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            gap: 8,
                                        }}
                                    >
                                        <ButtonDefault
                                            color={"tertiary"}
                                            disabled={feedbackModal.is_send || feedbackModal.statusFeedback === false}
                                            label={t("Import evaluators feedback")}
                                            onClick={() => importFromEvaluators(feedbackModal)}
                                        />
                                        <OverlayTrigger overlay={<Tooltip
                                            id="tooltip-disabled">{t("You need to write standard feedback to import")}</Tooltip>}>
                                            <span className="d-inline-block">
                                                <ButtonDefault
                                                    color={"tertiary"}
                                                    disabled={
                                                        feedbackModal.is_send ||
                                                        feedbackModal.statusFeedback === false ||
                                                        (!reprovedDefaultFeedback && !feedbackModal.is_application_aproved) ||
                                                        (!approvedDefaultFeedback && feedbackModal.is_application_aproved)
                                                    }
                                                    onClick={() => importFromStandard(feedbackModal)}
                                                    label={t("Import standard feedback")}
                                                />
                                            </span>
                                        </OverlayTrigger>
                                    </div>
                                    <div
                                        className={"moreInfo-right"}
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            gap: 8,
                                        }}
                                    >
                                        <ButtonDefault
                                            onClick={() => saveMessage(feedbackModal)}
                                            color={"secondary"}
                                            disabled={feedbackModal.is_send || feedbackModal.statusFeedback === false}
                                            label={t("Save")}
                                        />
                                        <ButtonDefault
                                            action={"primary"}
                                            onClick={() => sendMessage(feedbackModal)}
                                            disabled={checkSendButton(feedbackModal)}
                                            label={t("Send")}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </Modal.Body>
                )}
            </Modal>
            <Modal size="lg" show={openFeedbackModal} onHide={() => closeFeedbackModal()} animation={false}>
                <Modal.Header closeButton>
                    <Title>{t("Feedback standard")}</Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <TextArea
                                label={t("Approved standard feedback")}
                                value={approvedDefaultFeedback && approvedDefaultFeedback}
                                onChange={(e) => setApprovedDefaultFeedback(e.target.value)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TextArea
                                label={t("Reproved standard feedback")}
                                value={reprovedDefaultFeedback && reprovedDefaultFeedback}
                                onChange={(e) => setReprovedDefaultFeedback(e.target.value)}
                            />
                            <StyledModalButtonRight>
                                <ButtonDefault
                                    action={"primary"}
                                    disabled={
                                        !(
                                            reprovedDefaultFeedback?.trim() !== "" &&
                                            approvedDefaultFeedback?.trim() !== ""
                                        )
                                    }
                                    label={t("Save")}
                                    onClick={setFeedbacks}
                                />
                            </StyledModalButtonRight>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <OffCanvasComponent show={showApplicationsFilters} handleClose={setShowApplicationsFilters} title={t("Filter applications")} isRight={true}>
                <FilterButtons showClearFilters={showClearFilters} handleClearFilters={handleClearFilters} handleApplyFilters={handleApplyFilters}/>
                <form ref={formQuestions}>
                    {resetFilters === false && (
                        <>
                            <FormSelect
                                onChange={(e) => {
                                    setOrderChanged(true);
                                    setSelectedOrder(e.value);
                                    setShowClearFilters(true);
                                }}
                                name={"orderning"}
                                options={orderOptions}
                                value={selectedOrder}
                                label={t("Order by")}
                                multiple={false}
                            />

                            <FormSelect
                                onChange={(e) => {
                                    setTimeout(showFilterButtons, 1);
                                }}
                                name={"is_application_aproved"}
                                options={statusOptions}
                                value={selectedStatus}
                                label={t("Status")}
                                multiple={false}
                            />

                            <FormSelect
                                onChange={() => {
                                    setTimeout(showFilterButtons, 1);
                                }}
                                name={"application__challenge__id__in"}
                                options={challenges}
                                label={t("Challenges")}
                                multiple={true}
                            />
                        </>
                    )}
                    <FeedbackApplicationsSectorsFilters resetFilters={resetFilters} handleSubmitForm={showFilterButtons}/>
                    {resetFilters === false && <FilterQuestions questions={questions} handleSubmitForm={showFilterButtons}/>}
                </form>
            </OffCanvasComponent>
        </CardContainer>
    );
}

export default PhaseFeedback;
