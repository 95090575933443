import {Bar} from "@ant-design/plots";
import {useEffect, useState} from "react";
import {
    BuildFieldErrorsDict,
    getMaxValueFromArray
} from "../../../../../global/utils/Utils";
import api from "../../../../../global/services/api";
import LoadingFull from "../../../../../global/components/other/LoadingFull";
import Empty from "../../../../../global/components/data_display/Empty";
import {useTranslation} from "react-i18next";
import ChartLegendWrapper
    from "../../../../../global/components/data_display/ChartWrapper/ChartLegendWrapper";


const DEFAULT_CONFIG_REQUEST = {
    version: 'v1'
}

export default function ProjectRadarChart({
                                              onColumnClick,
                                              request,
                                              legend_key,
                                              groups,
                                              expand
                                          }) {
    const {t} = useTranslation();
    const [isLoading, setIsloading] = useState(false)
    const [datas, setDatas] = useState()
    const seriesField = "label"
    const colorField = "color"


    useEffect(() => {
        if (request?.url) {
            // console.log(request)
            const fetchData = async () => {
                try {
                    setIsloading(true)
                    const config = {
                        params: request.params,
                        ...DEFAULT_CONFIG_REQUEST,
                        ...request.config
                    }
                    let response;
                    if (request?.body) {
                        response = await api.post(request?.url, request?.body, config);
                    } else {
                        response = await api.get(request?.url, config);
                    }
                    const data = response.data?.reduce((acc, item) => {

                        const values = Object.keys(groups).map(
                            (key_group) => ({
                                [legend_key]: item[legend_key],
                                label: groups[key_group].label,
                                value: item[key_group],
                                color: groups[key_group].color,
                                object: item
                            })
                        )
                        acc.push(...values);
                        return acc;
                    }, []);
                    setDatas(data);
                } catch (error) {
                    BuildFieldErrorsDict(error, null, false);
                } finally {
                    setIsloading(false)
                }
            };
            fetchData();
        }
    }, [JSON.stringify(request)]);

    const config = {
        data: datas,
        xField: legend_key,
        seriesField: seriesField,
        colorField: colorField,
        yField: "value",
        autoFit: true,
        animate: null,
        style: {
            fill: (item) => {
                return item.color
            },
            cursor: "pointer",
        },
        axis: {
            x: {
                labelAlign: "horizontal",
                tickLength: 0,
                size: 100,
                labelAutoEllipsis: [{type: "ellipsis", maxLength: 10}],
            },
            y: {
                size: 20,
                labelAutoRotate: [{type: "rotate", optionalAngles: [90]}],
                tickLength: 0,
            }
        },
        tooltip: {
            // só ta funcionando com o código repetido ;)
            items: [
                (item) => ({
                    name: t(item.label),
                    value: item["value"],
                    color: item.color
                }),
                (item) => ({
                    name: t(item.label),
                    value: item["value"],
                    color: item.color
                }),
            ]
        },
        scale: {
            y: {
                domain: [0, getMaxValueFromArray(datas, "value", true)]
            }
        },
        markBackground: {
            style: {
                fill: "transparent"
            }
        },
        _legend: {
            colorField,
            rows: 1,
            cols: 3,
            labelFormatter: (label) => {
                return {
                    ...label,
                    text: t(label.text),

                    // color: label.color
                }
            }
        },
        drawerConfig: {
            _legend: {
                rows: 2,
            }
        },
        onReady: ({chart}) => {
            chart.on('interval:click', (event) => {
                onColumnClick?.(event)
            });
        }

    };

    if (isLoading) return <LoadingFull/>

    return (
        <>
            {datas?.length === 0 && <Empty/>}
            {datas?.length >= 1 &&
                <ChartLegendWrapper chart={Bar} config={config}
                                    expand={expand}/>}
        </>
    );
}
