import React, {useState} from 'react';
import {Card, Row, Typography} from "antd";
import Button from "../../../../global/components/data_entry/Button";
import OrderingTable from "../../../../global/components/data_display/OrderingTable";
import WideSpace from "../../../../global/components/layout/WideSpace";
import {HolderOutlined, InfoCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import ChallengeDrawerForm from './components/ChallengeDrawerForm';
import TruncateText from "../../../../global/components/responsive/TruncateText";
import {useProgramContext} from "../../../ProgramsNavigator";


const {Text} = Typography

function Challenges(props) {

    let {id} = useParams()
    const {t} = useTranslation()
    const [openChallengeDrawer, setOpenChallengeDrawer] = useState(false);
    const [challengeId, setChallengeId] = useState()
    const {program} = useProgramContext()

    const columns = [
        {
            title: t('Sort'),
            dataIndex: 'sort',
            width: 30,
            fixed:'left',
            // className: 'drag-visible',
            render: (v, record) => {
                const {_dragHandleProps} = record
                return(<HolderOutlined {..._dragHandleProps}/>)
            },
        },
        {
            title: t("Challenge"),
            dataIndex: 'name',
            // className: 'drag-visible',
        },
        {
            title: t("Total Applications"),
            dataIndex: "applications"
        },
        {
            title: t("Submitted Applications"),
            dataIndex: "applications_submitted"
        },
        {
            title: t("Description"),
            dataIndex: "description",
            width: 700,
            render: (value) => (<TruncateText>{value}</TruncateText>)
        }
    ];

    const onRowClick = (record) => {
        setChallengeId(record.id)
        setOpenChallengeDrawer(true)
    }

    const handleOpenForNewChallenge = () => {
        setChallengeId(undefined)
        setOpenChallengeDrawer(true)
    }

    return (
        <>
            <Card id="orderingTableCard">
                <WideSpace direction="vertical">
                    <Row justify="end">
                        <Button onClick={() => handleOpenForNewChallenge()}
                                data-cy="new-challenge-button">
                            <PlusOutlined/> {t("New Challenge")}
                        </Button></Row>

                    <OrderingTable columns={columns}
                                   fetchUrl={`programs/${id}/challenges`}
                                   patchUrl={`/programs/${id}/change_challenges_order`}
                                   onRowClick={onRowClick}
                                   id={'program-challenges'}
                    />
                    <Text style={{fontSize: 14}}
                        type="secondary"><InfoCircleOutlined/> {t("The order of the challenges in the table changes the order in which they are displayed on the program page.")}
                    </Text>
                </WideSpace>
            </Card>
            <ChallengeDrawerForm 
                open={openChallengeDrawer}
                setOpen={setOpenChallengeDrawer}
                challengeId={challengeId}
                program={program}
                onAfterSubmit={()=> {
                    dispatchEvent(new Event(`refresh-ordering-table-program-challenges`))
                }}
                onClose={() => setChallengeId(undefined)}
            />
        </>
    );
}

export default Challenges;