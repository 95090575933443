import React, {useEffect, useState} from "react";
import {Card, Col, Row} from "react-bootstrap";
import PublicActionBar from "../../components/ActionBar/PublicActionBar";
import styled from 'styled-components'
import {useHistory, useLocation, useParams} from "react-router-dom";
import api from "../../global/services/api";
import CircleImage from "../../components/CircleImage";
import {
    ReactComponent as ExternalLink
} from "../../assets/Icon/external_link.svg"
import FilesView from "../../components/Form/FilesView";
import Footer from "../../components/Footer";
import {DatetimeByTimezoneSystem} from "../../global/utils/dates";
import ButtonDefault from "../../components/Buttons/ButtonDefault";
import {useTranslation} from "react-i18next";
import useQuery from "../../global/hooks/useQuery";
import BannerImg from "../../assets/img/1024x240.png";
import challengeLogo from "../../assets/img/challenge.png";
import {useThemeContext} from "../../global/context/ThemeContext";
import useFacebookPixel from "../../global/hooks/useFacebookPixel";
import DangerousHtmlPreviewer
    from "../../global/components/data_display/DangerousHtmlPreviewer";
import {useUserContext} from "../../global/context/UserContext";


const StyleDivBanner = styled.div`
    width: 100%;
    min-height: 240px;
    max-height: 266px;
    margin-top: 20px;
`

const StyleImgBanner = styled.img`
    width: 100%;
    height: 250px;
    border-radius: 8px;
    object-fit: cover;
`

const StyleDivProfileImg = styled.div`
    display: flex;
    margin-top: -35px;
`

const StyleCalendarImg = styled.img`
    width: 10px;
    margin-right: 3px;
`
const StyleDivTitleTag = styled.span`
    display: flex;
    height: 70px;
`

const StyleDivTags = styled.div`
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`

const StyleSpanTags = styled.span`
    background: #DFDFDF;
    border-radius: 18px;
    font-size: 12px;
    padding: 6px 8px;
    font-weight: 500;
    //margin: 0px 8px
`

const StyleSpanTitle = styled.span`
    font-size: 33px;
    font-weight: 500;
    margin-right: 12px;
    align-self: center;

    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-height: 32px;
    max-height: 64px;
`

const StyleSpanDate = styled.span`
    font-weight: 200;
`

const StyleSpanTime = styled.span`
    font-weight: 550;
`

const StyleDivChallenger = styled(Row)`
    background: #F9F9F9;
    border: 1px solid #EEEEEE;
    gap: 32px;

`

const StyleSpanTitleDescription = styled.span`
    font-size: 15px;
    font-weight: 600;
    height: 50px;
    display: flex;
    align-items: center;
    margin-top: 15px;
    color: #4B4B4B;
`

const StyleSpanDescription = styled.div`
    font-size: 15px;
    font-weight: 200;
    width: 100%;
    color: inherit;
    display: flex;
    white-space: pre-line;
    //margin-bottom: 40px;
`

const ChallengeText = styled.div`
    background: #F9F9F9;
    border-radius: 14px;
    padding: 10px;
    text-align: center;
    display: flex;
    gap: 24px;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
`
const ChallengesContent = styled.div`
    margin-top: -15px;
`

const ChallengeCard = styled.div`
    width: 100% !important;

    border-radius: 14px;
    overflow: hidden;
    background: #F9F9F9;
    display: flex;
    flex-direction: column;
    filter: drop-shadow(15px 5px 8px #4b4b4b);
    cursor: pointer;

    &:hover > div > button {
        background: var(--active_menu_color);
        border-color: var(--active_menu_color);

    }

`
const ChallengeName = styled.div`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.1em;
    color: #4B4B4B;
    padding: 15px 10px 0 10px;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 3.3em;
`
const ImagePanel = styled.div`
    width: 100%;
    overflow: hidden;

`

const ChallengeImage = styled.img`
    width: 100%;
    height: 15rem;
    object-fit: cover;

`

const MoreDetailsButton = styled.button`
    width: 100%;
    border-radius: 14px;
    background: #4B4B4B;
    color: #FFFFFF;
    font-size: 10px;
    font-weight: 500;
    border-style: none;
    padding: 4px;

    &:hover {
        background: var(--active_menu_color);
        border-color: var(--active_menu_color);
    }
`

const StyledPhaseCard = styled(Card)`
    ${({isOpen}) => {
        if (isOpen) {
            return `
            color: black;
            background-color: #F5F5F5;
          `
        }
        return `
        color: #00000073;
      `
    }}
`;

const StyledRow = styled(Row)`
    color: #00000073;
`;

function PhaseCard({phase, duration}) {
    const {t} = useTranslation();

    const isOpen = phase["is_open"];
    return (
        <StyledPhaseCard className="p-2 col-12 col-md-6 shadow-sm mb-2"
                         isOpen={isOpen}>
            <span
                style={{fontSize: "15px"}}> {t("Phase")}: {phase["title"]}</span>
            <StyledRow className="mt-3" isOpen={isOpen}>
                {duration !== "continuous" &&
                    <>
                        <Col>
                            <StyleSpanDescription>
                                {t("Start")}: {phase["date_start"] ? DatetimeByTimezoneSystem(phase["date_start"]) : '---'}
                            </StyleSpanDescription>
                        </Col>
                        <Col>
                            <StyleSpanDescription>
                                {t("End")}: {phase["date_end"] ? DatetimeByTimezoneSystem(phase["date_end"]) : '---'}
                            </StyleSpanDescription>
                        </Col>
                    </>
                }
            </StyledRow>
        </StyledPhaseCard>
    )
}

function PublicProgram() {
    const {t} = useTranslation();
    const {isAuthenticated} = useUserContext();
    const history = useHistory();
    const location = useLocation();
    const [program, setProgram] = useState(null);
    useFacebookPixel({id: program?.programsettings?.tracking_configuration?.facebook_pixel_id})
    const [challenges, setChallenges] = useState([]);

    const [isLoadingProgram, setIsLoadingProgram] = useState(true);
    const [isLoadingChallenges, setIsLoadingChallenges] = useState(true);

    const {slug} = useParams();
    const querys = useQuery();
    const {themeConfig} = useThemeContext();

    const fetchChallenges = async () => {
        try {
            let response = await api.get(`/public/program/${slug}/challenges`);
            setChallenges(response.data);
            setIsLoadingChallenges(false)
        } catch (error) {
            // BuildFieldErrorsDict(error, null, false)
        }
    };

    const renderChallenge = (challenge, onClick) => {
        return (
            <ChallengeCard onClick={onClick}>
                <ImagePanel>
                    {challenge.post_image ?
                        <ChallengeImage src={challenge.post_image}
                                        alt={'challenge-banner'}/> :
                        <ChallengeImage src={challengeLogo} alt="test"/>}
                </ImagePanel>
                <ChallengeText>
                    <ChallengeName> {challenge.name} </ChallengeName>
                    <MoreDetailsButton
                        onClick={onClick}>{t("More Details")}<ExternalLink
                        width={10}
                        height={10}/></MoreDetailsButton>
                </ChallengeText>
            </ChallengeCard>
        )
    }
    useEffect(() => {

        if (!querys.get('no_redirect_to_internal_detail') && isAuthenticated() && program !== null) {
            history.push(`/available-programs/detail/${program.id}`)
        }
    }, [program])

    const getProgram = async () => {
        try {
            let response = await api.get(`/public/program/${slug}`);
            setProgram(response.data);
            setIsLoadingProgram(false)
        } catch (error) {
            if (error.response?.status === 404) {
                history.push('/404/', {broken_url: window.location.host + location.pathname})
            }
        }
    };

    useEffect(async () => {
        await getProgram()
        await fetchChallenges()
    }, [slug]);

    const handleRedirect = (event) => {
        localStorage.setItem("show_program", history.location.pathname);

        if (program.allow_simple_initiative) {
            history.push(`/simple_initiative_registration/${slug}`)
        } else if (isAuthenticated()) {
            history.push(`/available-programs/detail/${program.id}`)
        } else {

            history.push(`/login?next=/available-programs/detail/${program.id}${event.target.id === 'subscribe_now' ? '?apply=yes' : ''}`)

        }
    }

    return (
        <div style={{ overflow: "hidden"}}>
            <PublicActionBar/>
            {!isLoadingProgram && <Col>
                <Row style={{
                    justifyContent: 'center',
                    maxWidth: 1280,
                    padding: 0,
                    margin: '0 auto 0'
                }}>
                    <Col xs={12}>
                        <StyleDivBanner>
                            <StyleImgBanner
                                src={program.banner ? program.banner : BannerImg}/>
                        </StyleDivBanner>
                    </Col>
                    <Col xs={12}>
                        <Row>
                            <Col sm={3} xs={4} md={2} lg={2}>
                                <StyleDivProfileImg>
                                    <CircleImage
                                        style={{border: '2px solid #fff'}}
                                        program={true} width={'140px'}
                                        height={'140px'}
                                        src={themeConfig.img_profile ? themeConfig.img_profile : ""}
                                        alt="logo"/>
                                </StyleDivProfileImg>
                            </Col>
                            <Col xs={12} md={10}>
                                <Row>
                                    <Col xs={12}>
                                        <StyleDivTitleTag>
                                            <StyleSpanTitle
                                                title={program.name}>{program.name}</StyleSpanTitle>
                                        </StyleDivTitleTag>
                                    </Col>

                                </Row>
                                <Row>

                                    {/*<StyleSpanDate>*/}
                                    {/*    <StyleCalendarImg src={LaunchtimeSvg}/> {t('Launch Date')}: <StyleSpanTime>{program.date_start ? DatetimeByTimezoneSystem(program.date_start) : t("No launch time")}</StyleSpanTime>*/}
                                    {/*</StyleSpanDate>*/}

                                    <StyleDivTags>{program.sectors_display.map((item) => (
                                        <StyleSpanTags>{t(item)}</StyleSpanTags>
                                    ))}</StyleDivTags>
                                    <ButtonDefault style={{
                                        marginTop: 16,
                                        marginBottom: 0,
                                        marginLeft: 'auto',
                                        marginRight: 16
                                    }} action={'primary'}
                                                   id="subscribe_now"
                                                   onClick={handleRedirect}
                                                   label={t('Subscribe now')}/>

                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <StyleSpanTitleDescription>
                                    {t("DESCRIPTION")}
                                </StyleSpanTitleDescription>
                                <StyleSpanDescription>
                                    <DangerousHtmlPreviewer
                                        content={program.description === '' ? t('No description') : program.description}/>
                                </StyleSpanDescription>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {program && program.files?.length !== 0 ?
                                    <StyleSpanTitleDescription>
                                        {t("ATTACHMENTS")}
                                    </StyleSpanTitleDescription> : null}
                                {program.files &&
                                    <FilesView files={program.files}/>}
                            </Col>
                        </Row>

                        {!program.allow_simple_initiative && <Row>
                            <Col>
                                <StyleSpanTitleDescription>
                                    {t("APPLICATION TYPE")}
                                </StyleSpanTitleDescription>
                                <StyleSpanDescription>
                                    <ul>
                                        {program.team_types_display.map((item) => {
                                            return <li>{t(item)}</li>
                                        })}
                                    </ul>
                                </StyleSpanDescription>

                            </Col>
                        </Row>}
                        <Row>
                            <Col>
                                <StyleSpanTitleDescription>
                                    {t("PROGRAM TIMELINE")}
                                </StyleSpanTitleDescription>

                                {program?.phases.map((phase) => (
                                    <PhaseCard phase={phase}
                                               duration={program.duration}/>
                                ))}
                                {/*<PhaseCard phase={endPhase} />*/}

                                {/*<StyleSpanDate>*/}
                                {/*   {program.date_end && <>*/}
                                {/*       <StyleCalendarImg src={LaunchtimeSvg}/>*/}
                                {/*       {t('Closing Date')}:*/}
                                {/*    <StyleSpanTime>{DatetimeByTimezoneSystem(program.date_end)}</StyleSpanTime> </>}*/}
                                {/*</StyleSpanDate>*/}
                            </Col>
                        </Row>
                        <Row>
                            {challenges.length > 0 &&
                                <Col>
                                    <StyleSpanTitleDescription>{t("CHALLENGES")}</StyleSpanTitleDescription>
                                </Col>
                            }
                            {isLoadingChallenges > 0 ?
                                <Col sm={2}>
                                    <MoreDetailsButton
                                        onClick={handleRedirect}>{t("More Details")}
                                        <ExternalLink
                                            width={10}
                                            height={10}/>
                                    </MoreDetailsButton>
                                </Col> : <></>
                            }
                        </Row>
                    </Col>
                    {!isLoadingChallenges && challenges.length > 0 &&
                        <Col xs={12}>
                            <ChallengesContent>
                                <StyleDivChallenger className="p-0 p-md-2">
                                    {challenges.map((challenge, index) => (
                                        <Col xs={12} sm={12} md={3}
                                             key={'challenge-' + index}>
                                            {renderChallenge(challenge, handleRedirect)}
                                        </Col>)
                                    )}
                                </StyleDivChallenger>
                            </ChallengesContent>
                        </Col>
                    }
                </Row>
                <Footer/>
            </Col>}
        </div>
    )
}


export default PublicProgram