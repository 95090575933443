import SolvTourModal from "./SolvTourModal";
import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import api from "../../../../global/services/api";
import UserContext, {
    useUserContext
} from "../../../../global/context/UserContext";

const SolvTourModalContext = createContext({});

export function SolvTourModalContextProvider({children}) {
    const [onboard, setOnboard] = useState(null);
    const [currentOnboard, setCurrentOnboard] = useState(null);
    const [showTourModal, setShowTourModal] = useState(false);
    const [showCheckbox, setShowCheckbox] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const {_user} = useUserContext();
    const getOnboardByIdentifier = (identifier) => {
        return onboard?.find((obj) => obj.identifier === identifier);
    };

    const openTourModalByIdentifier = (identifier, showCheckbox = false) => {
        setCurrentStep(0);
        setCurrentOnboard(getOnboardByIdentifier(identifier));
        setShowTourModal(true);
        setShowCheckbox(showCheckbox);
    };

    const refreshOnboard = () => {
        void fetchOnboard();
        setCurrentOnboard(currentOnboard.identifier);
    };

    const fetchOnboard = async () => {
        try {
            const {status, data} = await api.get('/onboard/module_onboard');
            if (status === 200) {
                setOnboard(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!onboard && _user) {
            void fetchOnboard();
        }
    }, [onboard,_user]);

    return (
        <SolvTourModalContext.Provider
            value={{
                currentStep,
                setCurrentStep,
                showCheckbox,
                showTourModal,
                setShowTourModal,
                currentOnboard,
                refreshOnboard,
                openTourModalByIdentifier,
            }}>
            {children}
        </SolvTourModalContext.Provider>);
}

export const useSolvModalContext = () => {
    return useContext(SolvTourModalContext);
}

function SolvTourModalList() {
    const {t} = useTranslation();
    const {openTourModalByIdentifier} = useSolvModalContext();


    const location = useLocation();
    useEffect(() => {
        switch (location.pathname) {
            case "/project":
                openTourModalByIdentifier("project_project-portfolio", true);
        }
    }, [location]);

    return <SolvTourModal />;
}

export default SolvTourModalList;