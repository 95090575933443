import {createContext, useContext, useEffect, useState} from "react";
import api from "../../../../../services/api";
import {
    useLayoutContext
} from "../../../../../../modules/project/components/layout/context/LayoutContext";
import {BuildFieldErrorsDict} from "../../../../../utils/Utils";

const ContextProfileUser = createContext({});


export function ContextProviderProfileUser({
                                               id,
                                               phase_id,
                                               program_id,
                                               open,
                                               children
                                           }) {

    const [userData, setUserData] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [updatePage, setUpdatePage] = useState()
    const {updateLayoutContent} = useLayoutContext()
    useEffect(() => {
        if (!open) {
            setUserData('')
            if (updatePage) {

                updateLayoutContent()
            }
        }
    }, [open])

       function updatePageOnClose() {
        setUpdatePage(true)
    }


    useEffect(() => {
        if (id && open) {
            async function fetchUser() {
                try {
                    setIsLoading(true);
                    const response = await api.get(`user_info/${id}/get_info`);
                    setUserData(response.data);
                } catch (error) {
                    BuildFieldErrorsDict(error, null, false);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchUser()
        }
    }, [id, open]);

    return (
        <ContextProfileUser.Provider value={{
            userData,
            isLoading,
            id,
            updatePageOnClose,
            phase_id, program_id

        }}>
            {children}
        </ContextProfileUser.Provider>
    );
}

export function useContextProfileUser() {
    return useContext(ContextProfileUser);
}