import {Pie} from "@ant-design/plots";
import React, {useEffect, useState} from "react";
import api from "../../../../../../global/services/api";
import {ChartRenderer} from "../../utils/chartUtils";
import {useThemeContext} from "../../../../../../global/context/ThemeContext";
import {useTranslation} from "react-i18next";
import {formatNumber} from "../../../../utils/utils";
import ChartLegendWrapper
    from "../../../../../../global/components/data_display/ChartWrapper/ChartLegendWrapper";
import useProjectChart from "../../hooks/useProjectChart";
import Loading from "../../../../../../global/components/feedback/Loading";


function TotalExpectedInvestmentChart({filters,
                                          parent,
                                          extraParams,
                                          columnOptions,
                                          expand
                                      }) {
    const {t} = useTranslation()
    const [data, setData] = useState(undefined)
    const [isActive, setIsActive] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const {themeConfig} = useThemeContext()
    const {handleChartClickProjectRedirect} = useProjectChart();


    const getTotalValue = (_data) => (_data?.reduce((acc, item) => acc + item[angleField], 0))

    const angleField = "stored_value"
    const colorField = "project_name"
    const colorSourceKey = "project_color"
    const idSourceKey = "project"
    const [totalValue, setTotalValue] = useState()

    const fontSize = Math.ceil(24 - (String(totalValue)?.length / 2))

    const config = {
        data,
        angleField,
        colorField,
        autoFit: true,
        innerRadius: .8,
        margin: 0,
        label: false,
        inset: 0,
        animate: null,
        interaction: {
            elementHighlightByColor: {
                link: true
            }
        },
        style: {
            fill: (d) => {
                return d.project_color || "#000";
            },
            cursor:"pointer",
        },
        annotations: [{
            type: 'text',
            tooltip: false,
            style: {
                text: `R$ ${formatNumber(totalValue)}` || 0,
                x: '50%',
                y: '50%',
                textAlign: 'center',
                fontSize,
                fontWeight: "800",
            },
        }],
        tooltip: {
            items: [
                (d) => {
                    const item = data.find((item) => item.project === d.project);
                    return {
                        name: t(item.project_name),
                        value: `R$ ${formatNumber(d.stored_value)}`,
                        color: item.project_color || "#000"
                    };
                }
            ]
        },
        _legend: {
            colorField,
            colorSourceKey,
            idSourceKey,
            rows: 1,
            cols: 3,
        },
        drawerConfig: {
            _legend: {
                rows: 2,
            },
        },
        onReady: ({chart}) => {
            chart.on('interval:click', (event) => {
                handleChartClickProjectRedirect({event})
            });
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)
                const response = await api.post(`/analytics/dashboard/project_client_dashboard/${themeConfig.client.id}/total_expected_investment_report`, filters,{params: extraParams});
                setIsActive(response.data.is_active);
                setData(response.data.responses);
                setTotal(response.data.total);
            } catch (exception) {
            } finally {
                setIsLoading(false)
            }
        };
        fetchData();
    }, [JSON.stringify(extraParams), filters]);


    if (isLoading) return <Loading/>
    return (
        <ChartRenderer
            data={data}
            chart={
                <ChartLegendWrapper chart={Pie} config={config}
                                    afterUpdateData={(displayData) => {
                                        setTotalValue(getTotalValue(displayData))
                                    }}
                />}
            parent={parent}
            columnOptions={columnOptions}
            isActive={isActive}
            expand={expand}
        />
    );
}

export default TotalExpectedInvestmentChart;