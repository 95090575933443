import styled from "styled-components";

export const Badge = styled.div`
    background: ${({background}) => background ? background : "#DFDFDF"};
    color: ${({color}) => color ? color : "#000"};
    border-radius: 18px;
    font-size: 12px;
    padding: 6px 8px;
    font-weight: 500;
    text-transform: capitalize;
    line-height: 1;
    sup {
        font-weight: ${({fontWeight}) => fontWeight};
    }
`



