import React from 'react';
import WideSpace from "../../../../../../../global/components/layout/WideSpace";
import {useTranslation} from "react-i18next";
import {Card, Space, Typography} from "antd";
import styled from "styled-components";
import {neutral_2} from "../../../../../../../global/utils/Colors";
import AssessmentSimplifiedView from '../../../../../../../global/components/other/application/ApplicationDrawer/components/DescriptionSolutionTab/components/assessmentComponents/AssessmentSimplifedView';
import AssessmentDetailedView from '../../../../../../../global/components/other/application/ApplicationDrawer/components/DescriptionSolutionTab/components/assessmentComponents/AssessmentDetailedView';

const {Text} = Typography

const StyleTitleQuestion = styled(Text)`
    font-size: 14px;
    font-weight: 500;
`

const StyledCard = styled(Card)`
    border-radius: 10px;
    border-color: 1px solid ${neutral_2};

    .ant-card-head {
        border: none;
    }
`

export default function FeedbackHelp() {
    const {t} = useTranslation()

    const HELP = {
        phase: {title: t("Evaluation"), sequence: 4},
        feedback: t("Lack of Project Detail: The project documentation and presentation lack essential details. It is important to provide clear information on the following aspects: Specific objectives: What exactly does the application intend to solve? What problems does it address? Scope: What is the scope of the project? What functionality is included? Recommendations: Develop a detailed plan, including milestones and deliverables. Seek mentoring or consulting from experts in the field to fill knowledge gaps."),
        form: {
            "comp_score": [
                {
                    "title": t("Question about initiatives."),
                    "type": "score_with_feedback",
                    "answer_avg": "50.00",
                    "reponses": [
                        {
                            "evaluator": "Evaluator",
                            "scale": {
                                "min": 0,
                                "max": 10
                            },
                            "score": "5",
                            "feedback": t("I liked")
                        }
                    ]
                },
                {
                    "title": t("Question about project maturity."),
                    "type": "score_with_feedback",
                    "answer_avg": "20.00",
                    "reponses": [
                        {
                            "evaluator": "Evaluator",
                            "scale": {
                                "min": 0,
                                "max": 10
                            },
                            "score": "2",
                            "feedback": t("I didn't like it")
                        }
                    ]
                },
                {
                    "title": t("Question about project feasibility."),
                    "type": "score_with_feedback",
                    "answer_avg": "20.00",
                    "reponses": [
                        {
                            "evaluator": "Evaluator",
                            "scale": {
                                "min": 0,
                                "max": 10
                            },
                            "score": "2",
                            "feedback": ""
                        }
                    ]
                }
            ],
        },
        solutionResult: {
            "is_aproved": true,
            "standard_deviation": "3.00",
            "status": "approved",
            "status_display": "Approved",
            "step": 1493,
            "score_max": "35.00",
            "score_min": "35.00",
            "score_unweighted": "30.00",
            "score_average": "30.00",
            "protocol": null,
            "is_finished": true,
            "has_phase_actions": false
        }
    }

    const helpList = [
        {
            title: t("Why should the applicant see the notes?"),
            description: t("To improve understanding, the proponent of the idea in this program will be able to view the criteria that were used to evaluate"),
            component: null
        },
        {
            title: t("What can be shown?"),
            description: t("Solv allows the program manager to choose how it will be shown to the user in 2 ways: Average per question and detailed assessment."),
            component: null
        },
        {
            title: t("What is average per question?"),
            description: t("The average per question shows, per application, the average of the marks given in that question and the overall average for the application."),
            component: <AssessmentSimplifiedView form={HELP.form} solutionResult={HELP.solutionResult}/>,
        },
        {
            title: t("What is Detailed Assessment?"),
            description: t("The detailed assessment shows all the marks given for that question without detailing the evaluators, feedback per assessment and the overall average of the application."),
            component: <AssessmentDetailedView form={HELP.form} solutionResult={HELP.solutionResult} viewerMode={true}/>,
        }
    ];
    return (
        <WideSpace size="middle" direction="vertical">
            {helpList.map(help => (
                <StyledCard>
                    <Space size="small" direction="vertical">
                        <StyleTitleQuestion> {help.title} </StyleTitleQuestion>
                        <Text type="secondary">
                            {help.description}
                        </Text>
                        {help.component ? help.component : <></>}
                    </Space>
                </StyledCard>
            ))}
        </WideSpace>
    );
}

