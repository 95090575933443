import React, {useEffect, useState} from 'react';
import {Avatar, Card, Skeleton, Space, Typography} from "antd";
import styled from "styled-components";
import Flex from "../../../../../../responsive/Flex";
import {useTranslation} from "react-i18next";
import UserAvatar from "../../../../../../misc/UserAvatar";
import {PaperClipOutlined} from "@ant-design/icons";
import WideSpace from "../../../../../../layout/WideSpace";
import api from "../../../../../../../services/api";
import {BuildFieldErrorsDict} from "../../../../../../../utils/Utils";
import {DateFormatByUserTimezone, DatetimeFormatByTimezone} from "../../../../../../../utils/dates";
import RequestNotAnswered from "./RequestNotAnswered";

const {Text} = Typography

const StyledCard = styled(Card)`
    gap: 16px;
    border-radius: 10px;
`

export default function RequestAnswer({request}) {
    const {t} = useTranslation()
    const [diligenceResponses, setDiligenceResponses] = useState()
    const [diligenceAttachments, setDiligenceAttachments] = useState()
    const [loading, setLoading] = useState(false)

    // const fetchAttachments = async (diligence) => {
    //     try {
    //         const {data} = await api.get(`/file_upload/content/diligenceresponse/object/${diligence.id}/list_uploads/`)
    //         diligence.attachments = data.results
    //     } catch (error) {
    //         BuildFieldErrorsDict(error, null, false)
    //     }
    // }


    const fetchDiligenceResponse = async () => {
        try {
            setLoading(true)
            const diligences = await api.get(`/diligences/${request?.id}/list_responses`)

            // const diligenceAttachmentPromises = []
            // diligences.data.forEach(diligence => {
            //     diligenceAttachmentPromises.push(fetchAttachments(diligence))
            // })
            // await Promise.all(diligenceAttachmentPromises)

            setDiligenceResponses(diligences.data)
        } catch (error) {
            console.log(error)
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        void fetchDiligenceResponse()
    }, [request]);

    return (
        <Skeleton loading={loading}>
            {diligenceResponses?.length === 0 &&
                <RequestNotAnswered />
            }
            {diligenceResponses?.map(response => (
                <StyledCard key={response.id}>
                    <WideSpace direction="vertical">
                        <Flex justify="space-between">
                            <Space direction="vertical" size={4}>
                                <Text strong>{t("Answer author")}</Text>
                                <UserAvatar data={response?.owner}/>
                            </Space>
                            <Space direction="vertical" size={4}>
                                <Text strong>{t("Replied at")}</Text>
                                <div>{DatetimeFormatByTimezone(response?.created_at, false)}</div>
                            </Space>
                        </Flex>

                        <WideSpace direction="vertical" size={4}>
                            <Text strong>{t("Answer")}</Text>
                            <Text>{response.description}</Text>

                            {response.files_upload?.map(file => (
                                <div>
                                    <Text type="secondary"><PaperClipOutlined/></Text>
                                    <a href={file.path} target="_blank"> {file.name}</a>
                                </div>
                            ))}
                        </WideSpace>

                    </WideSpace>
                </StyledCard>
            ))}
        </Skeleton>
    );
}
