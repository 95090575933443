import React from 'react';
import {Badge, Card, Row, Typography} from "antd";
import styled from "styled-components";
import SolvTag from "../../../../../../tag/SolvTag";
import TruncateText from "../../../../../../responsive/TruncateText";
import WideSpace from "../../../../../../layout/WideSpace";
import {ClockCircleOutlined} from "@ant-design/icons";
import Flex from "../../../../../../responsive/Flex";
import {CHOICES_REQUEST_STATUS} from "../../../../../../../utils/Choices";
import {useTranslation} from "react-i18next";
import {sunset_orange_6} from "../../../../../../../utils/Colors";

const StyledCard = styled(Card)`
    border-radius: 8px;
    border-color: ${({$borderColor}) => $borderColor};
    .ant-card-body {
        padding: 16px;
    }
    cursor: pointer;
`

const {Text} = Typography


export default function RequestCardButton({request, onClick}) {
    const {t} = useTranslation()

    return (
        <StyledCard $borderColor={CHOICES_REQUEST_STATUS[request?.status]?.border}
                    onClick={onClick} data-cy={`request-card-button-${request?.id}`}
        >
            <Flex align="center">
                <SolvTag color={CHOICES_REQUEST_STATUS[request?.status]?.color}>
                         {t(CHOICES_REQUEST_STATUS[request?.status]?.label)}
                </SolvTag>
                <Flex grow={true}>
                    <TruncateText>
                        <Text strong>{request?.title}</Text>
                    </TruncateText>
                </Flex>
                {request?.status === "waiting" && request?.deadline > 0 &&
                    <Flex gap={4}>
                        <Text style={{color: sunset_orange_6}}><ClockCircleOutlined /></Text>
                        <Badge color={sunset_orange_6} count={request?.deadline_display} />
                    </Flex>
                }
            </Flex>
        </StyledCard>
    );
}
