import {Table} from "antd";
import styled from "styled-components";

const StyledTable = styled(Table)`
    .ant-table-pagination-right{
        padding-right: 16px;
    }
`

const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record) => ({
        disabled: record.name === 'Disabled User',
        name: record.name,
    }),
};

function SolvTable({columns, dataSource, hiddenColumns, onRowClick, allowRowSelection = false, rowKey="id", hideOnSinglePage = true, ...props}) {

    const onRow = (record, rowIndex) => {
        return {
            onClick: () => {
                onRowClick && onRowClick(record, rowIndex);
            },
            style: {
                cursor: onRowClick && "pointer",
            },
        };
    };

    // useEffect(() => {
    //     window.addEventListener('error', e => {
    //         if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'ResizeObserver loop completed with undelivered notifications.') {
    //             const resizeObserverErrDiv = document.getElementById(
    //                 'webpack-dev-server-client-overlay-div',
    //             );
    //             const resizeObserverErr = document.getElementById(
    //                 'webpack-dev-server-client-overlay',
    //             );
    //             if (resizeObserverErr) {
    //                 resizeObserverErr.setAttribute('style', 'display: none');
    //             }
    //             if (resizeObserverErrDiv) {
    //                 resizeObserverErrDiv.setAttribute('style', 'display: none');
    //             }
    //         }
    //     });
    // }, []);

    const configureDefaultRenderer = (columns) => {
        columns?.forEach((column) => {
            if (!column.render) {
                column.render = (item) => item || "-";
            }
        });
    }
    
    const filterShownColumns = (columns) => {
        if (hiddenColumns) {
            return columns.filter(item=>!(hiddenColumns.includes(item.dataIndex)));
        }
        return columns
    }
    
    configureDefaultRenderer(columns);
    columns = filterShownColumns(columns);
     
    return (
        <StyledTable
            rowKey={rowKey}
            columns={columns}
            dataSource={dataSource}
            loading={dataSource?.length === undefined}
            scroll={{x: "max-content"}}
            onRow={onRow}
            rowSelection={allowRowSelection && rowSelection}
            pagination={{hideOnSinglePage: hideOnSinglePage}}
            {...props}
        />);
}

export default SolvTable;