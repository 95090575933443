import React, {useEffect, useState} from "react";
import {Form as FormAnt, Upload} from "antd";
import Form from "../components/data_entry/form/Form";
import Button from "../components/data_entry/Button";
import styled from 'styled-components'
import {UploadOutlined} from "@ant-design/icons";
import api from "../services/api";
import {BuildFieldErrorsDict} from "../utils/Utils";
import axios from "axios";
import {useTranslation} from "react-i18next";
import useFileUpload from "../hooks/useFileUpload";
import {ACCEPT_IMAGE_FORMATS} from "../utils/Constants";

const Container = styled.div`
    width: 500px;
    padding: 50px;
    margin: 0 auto;

`
export default function TestTitans() {
    const [form] = FormAnt.useForm()
    const imagens = FormAnt.useWatch('imagens', form)
    console.log(imagens)


    return (
        <Container>
            <Form form={form}>
                <FormAnt.Item name={'imagens'}>
                    <UploadPOC/>
                </FormAnt.Item>
            </Form>
        </Container>
    );

}




function UploadPOC({onChange, accept= ACCEPT_IMAGE_FORMATS}) {

    const {t} = useTranslation()
    const fileProps = useFileUpload({accept: accept,externalOnChange: onChange})

    return (<Upload {...fileProps}>
        <Button icon={<UploadOutlined/>}>Upload</Button>
    </Upload>)
}
