import React from "react";
import {Switch, Route,} from "react-router-dom";
import Login from "../pages/authentication/Login";
import Register from "../pages/authentication/Register";
import Recover from "../pages/authentication/Recover";
import MagicLink from "../pages/authentication/MagicLink";
import LayoutSwitch from "../layouts/authentication/LayoutSwitch";
import ConfirmEmail from "../pages/authentication/ConfirmEmail";
import NoticeConfirmEmail from "../pages/authentication/NoticeConfirmEmail";
import ResetPassword from "../pages/authentication/ResetPassword";
import {PrivateRoute} from "./PrivateRoute";

import PublicProgram from "../../pages/Programs/PublicProgram";
import {Maintenance, NotFound, NotPermission} from "../../components/Auth/ErrorPage/NotFound";
import NoticeBlockLogin from "../../components/Auth/NoticeBlockLogin";
import SimpleInitiativeRegistration from "../../pages/Programs/SimpleInitiative/SimpleInitiativeRegistration";
import SimpleInitiativeForm from "../../pages/Programs/SimpleInitiative/SimpleInitiativeForm";
import { DEBUG } from "../../settings";
import TestPage from "../pages/TestPage";
import TestTitans from "../pages/TestTitans";


const Routes = () => {
    return (
        <Switch>
            {/*NEW ANTD SCREENS*/}
            <Route path="/login" exact component={Login}/>
            <Route path="/reset/:uidb64/:token" exact component={ResetPassword}/>
            <Route path="/register" exact component={Register}/>
            <Route path="/recover_password" exact component={Recover}/>
            <Route path="/register_confirm_email/:uuid" exact component={ConfirmEmail}/>
            <Route path="/notice_confirm_email/:email" component={NoticeConfirmEmail}/>
            <Route path="/magic_link/:token" component={MagicLink}/>
            <Route path="/notice_block_login/:email" component={NoticeBlockLogin}/>
            <Route path="/notice_block_login/:email" component={NoticeBlockLogin}/>
            {DEBUG && <Route path="/test" component={TestPage}/>}
            {DEBUG && <Route path="/test_titans" component={TestTitans}/>}

            {/*OPEN APPLICATIONS SCREENS*/}
            <Route path="/simple_initiative_registration/:slug" exact component={SimpleInitiativeRegistration}/>
            <Route path="/simple_initiative_registration_form/:uuid" exact component={SimpleInitiativeForm}/>


            {/*OLD BOOTSTRAP SCREENS*/}
            <Route path="/show_program/:slug" exact component={PublicProgram}/>
            <Route path="/404" exact component={NotFound}/>
            <Route path="/403" exact component={NotPermission}/>
            <Route path="/maintenance" exact component={Maintenance}/>

            <PrivateRoute path="/" component={LayoutSwitch}/>
        </Switch>
    );
};
export default Routes;