import React, {useState} from "react";
import {useTranslation} from "react-i18next";

import Button from "../../../../../components/data_entry/Button";
import Drawer from "../../../../../components/layout/Drawer";

import {
    FlexContainer
} from "../../../../../components/authentication/StyledComponents";
import api from "../../../../../services/api";
import {
    ToastNotification
} from "../../../../../components/feedback/ToastNotification";
import * as ConstantsMessage from "../../../../../utils/fileWithMessage";
import {BuildFieldErrorsDict} from "../../../../../utils/Utils";
import {
    useLayoutContext
} from "../../../../../../modules/project/components/layout/context/LayoutContext";

import {Badge, Form as FormAnt, Modal} from "antd";
import Form from "../../../../../components/data_entry/form/Form";
import InputNumberFormField
    from "../../../../../components/data_entry/form/InputNumberFormField";
import TextAreaFormField
    from "../../../../../components/data_entry/form/TextAreaFormField";
import {MAX_LIMIT_GAMIFICATION_POINTS} from "../../../../../utils/Constants";



const FORM_NAMES = {
    DESCRIPTION: 'description',
    POINTS: 'points',
    NOTIFY_USER: 'notify_user',

}

export default function DrawerAddPoints({updateCounts, user_id, onClose}) {
    const {t} = useTranslation();
    const [form] = FormAnt.useForm()
    const [isLoading, setIsLoading] = useState()
    const {updateLayoutContent} = useLayoutContext()

    async function handleSubmit(data) {
        try {
            setIsLoading(true);
            data['user_client'] = user_id
            const response = await api.post(`/action/add_points`, data);

            ToastNotification(t(ConstantsMessage.SUCCESS));
            _onClose()
            updateCounts()
            // updateLayoutContent()

        } catch (error) {
            BuildFieldErrorsDict(error, form.setFields, null);
        } finally {
            setIsLoading(false);
        }
    };

    function _onClose() {
        form.resetFields()
        onClose?.()
    }

    return (

        <Drawer isGrayBody title={t('Add points')} open={!!user_id}
                onClose={_onClose} footer={
            <FlexContainer justify="end"><Button
                onClick={form.submit}
                loading={isLoading}>{t("Add points")}</Button></FlexContainer>
        }>
            <Form form={form} disabled={isLoading} onFinish={handleSubmit}>

                <InputNumberFormField min={1} max={MAX_LIMIT_GAMIFICATION_POINTS} form={{
                    name: FORM_NAMES.POINTS,
                    label: t('Amount of points'), rules: [{
                        required: true,
                        message: t('This field is require')
                    }],
                }}/>
                <TextAreaFormField showCount maxLength={255} form={{
                    name: FORM_NAMES.DESCRIPTION,
                    label: t('Justification'), rules: [{
                        required: true,
                        message: t('This field is require')
                    }],
                }}/>
                {/*<CheckboxFormField mode={'single'}*/}
                {/*                   label={t('Notify employee')} form={{*/}
                {/*    name: FORM_NAMES.NOTIFY_USER,*/}

                {/*}}/>*/}
                {/*<ShortInformation>{t('The employee will receive a notification in SOLV informing them of the change in points.')}</ShortInformation>*/}
            </Form>
        </Drawer>


    )
}
