import React, {useEffect, useState} from "react";
import {Tooltip} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import "./styles.css";
import api from "../../../../../global/services/api";
import Loading from "../../../../../global/components/feedback/Loading";
import {useParams} from "react-router-dom";
import {confirmAlert} from "react-confirm-alert";

import styled from "styled-components";
import {
    ReactComponent as Approve
} from "../../../../../assets/Icon/approve.svg";
import {
    ReactComponent as Reprove
} from "../../../../../assets/Icon/reprove.svg";

import {getProgram} from "../../../../../global/utils/_query";
import ButtonDefault from "../../../../../components/Buttons/ButtonDefault";
import TwinButtons from "../../../../../components/TwinButtons";
import FormSelect from "../../../../../components/Form/FormSelect/Index";
import {BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import {useTranslation} from "react-i18next";
import ApplicationsHistorySectorsFilters
    from "../../../../../components/Application/FiltersComponent/ApplicationsHistorySectorsFilters";
import SpanStatusApplication
    from "../../../../../components/SpanStatusApplication/SpanStatusApplication";
import NeoTable from "../../../../../components/NeoTable/NeoTable";
import {
    useLayoutContext
} from "../../../../../modules/project/components/layout/context/LayoutContext";
import {
    ToastNotification
} from "../../../../../global/components/feedback/ToastNotification";
import PercentTable
    from "../../../../../global/components/feedback/PercentTable";
import {CardContainer} from "../../../../../global/components/misc/CardContainer";
import ApplicationDrawer
    from "../../../../../global/components/other/application/ApplicationDrawer/ApplicationDrawer";
import useApplicationSelection from "./useApplicationSelection";


export const ButtonsContainer = styled.div.attrs(() => ({
    className: "buttons-container",
}))`
  display: flex;
  padding-left: 14px;
  height: 68px;
  gap: 8px;

  align-items: center;

  & > div:first-child {
    width: 160px;
    flex-shrink: 0;
  }

  & > div:last-child {
    margin-left: auto;
  }
`;

export const FilterButtonsContainer = styled.div.attrs(() => ({
    className: "filter-buttons-container",
}))`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const FilterButton = styled.button.attrs(() => ({
    className: "filter-buttons",
}))`
  color: #adadad;
  padding: 0px 15px;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid #adadad;
  height: 100%;
  display: flex;
  align-items: center;
  white-space: nowrap;


  &.active {
    border: solid;
    border-color: var(--active_menu_color);
    color: var(--active_menu_color);
  }
`;

function ApplicationSelection() {
    const [searchValue, setSearchValue] = useState("");
    const [selecteds, setSelecteds] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingProgram, setIsLoadingProgram] = useState(false);
    const [program, setProgram] = useState({public: true});
    const [statusApprovedQtd, setStatusApprovedQtd] = useState({
        pending: 0,
        approved: 0,
        reproved: 0,
        all: 0,
    });
    //FILTER / ORDER
    const [selectedOrder, setSelectedOrder] = useState("name");
    const [statusFilter, setStatusFilter] = useState("all");

    const [questions, setQuestions] = useState([]);
    const {id, phase_id} = useParams();
    const [challenges, setChallenges] = useState([]);
    const [dataForm, setDataForm] = useState({});
    const URL = `phases/${phase_id}`;


    const [modalShow, setModalShow] = React.useState(false);
    const [applicationModalShow, setApplicationModalShow] = useState([]);
    const {historyApplicationColumn} = useApplicationSelection();
    const [allColumnsPhases, setAllColumnsPhases] = useState(historyApplicationColumn);

    const {t} = useTranslation();
    const {
        setPageBottomContent,
        setIsConfidential,
        hasInitialized,
        resetPageBottom
    } = useLayoutContext();


    useEffect(() => {
        if (hasInitialized, program) {
            setIsConfidential(program["check_is_confidential"]);
        }
    }, [hasInitialized, program]);

    const openInfoModal = async ({object: historyApplication}) => {
        try {
            const response = await api.get(`applications/${historyApplication.application_id}`);
            setApplicationModalShow(response.data);
            setModalShow(true);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
    };

    const statusApplications = async () => {
        try {
            const response = await api.get(`applications/application_status/${phase_id}`);
            setStatusApprovedQtd(response.data);
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
    };

    useEffect(() => {
        statusApplications();

        const getColumns = async () => {
            try {
                const response = await api.get(`phases/${phase_id}/get_yes_no_columns`);
                const newValues = response.data.map(({label, name}) => ({
                    Header: label,
                    accessor: name,
                    Cell: ({_, row}) => {
                        return (
                            <PercentTable value={row.original?.total_yes_no_question[name]}>
                                {row.original?.total_yes_no_question[name]}% {t('Yes')}
                            </PercentTable>
                        );
                    },
                }))
                setAllColumnsPhases([...allColumnsPhases, ...newValues])
            } catch (error) {
                BuildFieldErrorsDict(error, null, false);
            }
        };
        const getQuestions = async () => {
            try {
                const response = await api.get(`phases/${phase_id}/get_filter_question_phase`);
                setQuestions(response.data);
            } catch (error) {
                BuildFieldErrorsDict(error, null, false);
            }
        };

        const getChallenges = async () => {
            try {
                const response = await api.get(`programs/${id}/challenges`);
                let aux = [];
                response.data.map((option) => {
                    aux.push({value: option.id, label: option.name});
                });
                setChallenges(aux);
            } catch (error) {
                BuildFieldErrorsDict(error, null, false);
            }
        };

        getColumns();
        getQuestions();
        getChallenges();
    }, []);

    const handleFinishAllApproved = () => {
        confirmAlert({
            title: t("Submit approved applications"),
            message: `${t("Are you sure you want to submit all approved applications?")} ${t("Pending")}: ${statusApprovedQtd.pending} ${t("Approved")}: ${
                statusApprovedQtd.approved
            } ${t("Reproved")}: ${statusApprovedQtd.reproved}`,
            buttons: [
                {
                    label: t("No"),
                    onClick: () => "",
                },
                {
                    label: t("Yes"),
                    onClick: async () => {
                        try {
                            setIsLoading(true);
                            const response = await api.put(`phases/${phase_id}/submit_approved_applications`);
                            statusApplications();

                            // await getApplicationsFilter()
                            ToastNotification(t("Successfully"));
                        } catch (error) {
                            BuildFieldErrorsDict(error, null, false);
                        } finally {
                            setIsLoading(false);
                        }
                    },
                },
            ],
        });
    };

    async function aproveOrReproveApplications(action, applicationsList, refreshTable) {
        try {
            const idApplications = applicationsList.filter((application) => !application.was_submitted).map((application) => application.id);

            const data = {
                action: action,
                applications: idApplications,
            };
            const response = await api.post(`applications/change_status`, data);
            statusApplications();

            refreshTable && refreshTable();
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        }
    }

    function approveOrReproveSelectedApplication(action, refreshTable) {
        aproveOrReproveApplications(action, selecteds, refreshTable);
    }

    const handleApprovedOrReprovedItem = async (action, item) => {
        aproveOrReproveApplications(action, [item]);
    };

    const getStatus = (status_filter) => {
        if (status_filter === statusFilter) {
            return "active";
        }
    };

    useEffect(() => {
        getProgram(id, setIsLoadingProgram, setProgram);

        const getPhase = async () => {
            try {
                const response = await api.get(URL);
                setDataForm(response.data);
            } catch (error) {
                BuildFieldErrorsDict(error, null, false);
            }
        };

        getPhase();
    }, []);

    function changeStatusApplication(e, aprove, historyApplication, {
        changeObject,
        removeObject
    }) {
        // aprove pode ser True ou false

        let action = "pending";
        if (historyApplication.is_aproved !== aprove) {
            action = aprove ? "approve" : "reprove";
        }

        handleApprovedOrReprovedItem(action, historyApplication);
        if (statusFilter !== "all") {
            removeObject(historyApplication.id);
        } else {
            changeObject(historyApplication.id, {
                ...historyApplication,
                is_aproved: action === "pending" ? null : aprove,
            });
        }
    }

    return (
        <CardContainer>
            <>
                {/*{renderOptions()}*/}
                <div style={{paddingBottom: 8}}>
                    {isLoading ? (
                        <Loading/>
                    ) : (
                        <NeoTable
                            topSpace={110}
                            leftActions={(refreshTable) => (
                                <>
                                    {selecteds.length === 0 ? (

                                        <FilterButtonsContainer>
                                            <FilterButton
                                                onClick={() => setStatusFilter("all")}
                                                className={getStatus("all")}>
                                                {t("All")}
                                            </FilterButton>
                                            <FilterButton
                                                onClick={() => setStatusFilter("pending")}
                                                className={getStatus("pending")}>
                                                {t("Pending Items")}
                                            </FilterButton>
                                            <FilterButton
                                                onClick={() => setStatusFilter("repproved")}
                                                className={getStatus("repproved")}>
                                                {t("Reproved Applications")}
                                            </FilterButton>
                                            <FilterButton
                                                onClick={() => setStatusFilter("approved")}
                                                className={getStatus("approved")}>
                                                {t("Approved Applications")}
                                            </FilterButton>
                                        </FilterButtonsContainer>
                                    ) : (
                                        <FilterButtonsContainer>
                                            <button
                                                data-id="button-reprove-all"
                                                className={`miniButtonReprove ${
                                                    selecteds
                                                        .filter((i) => !i.was_submitted)
                                                        .find((application) => application.is_aproved || application.is_aproved === null)
                                                        ? ""
                                                        : "hidebutton"
                                                } `}
                                                onClick={() => approveOrReproveSelectedApplication("reprove", refreshTable)}
                                            >
                                                <Reprove/> {t("Reprove")}
                                            </button>
                                            <button
                                                data-id="button-approve-all"
                                                className={`miniButtonAprove ${
                                                    selecteds
                                                        .filter((i) => !i.was_submitted)
                                                        .find((application) => application.is_aproved === false || application.is_aproved === null)
                                                        ? ""
                                                        : "hidebutton"
                                                } `}
                                                onClick={() => approveOrReproveSelectedApplication("approve", refreshTable)}
                                            >
                                                <Approve/> {t("Approve")}
                                            </button>
                                        </FilterButtonsContainer>
                                    )}
                                </>

                            )}
                            right={() => <>
                                <OverlayTrigger
                                    overlay={<Tooltip id="tooltip-disabled">You
                                        need to approve/reprove all
                                        applications
                                        before submit</Tooltip>}>
                                    <ButtonDefault
                                        style={{marginTop: 0, marginBottom: 0}}
                                        action={"primary"}
                                        disabled={!(statusApprovedQtd.approved > 0 || statusApprovedQtd.reproved > 0)}
                                        label={t("Submit selections")}
                                        onClick={handleFinishAllApproved}
                                        data-cy="submit-selections-button"
                                    />
                                </OverlayTrigger>
                            </>}
                            filterComponents={
                                <>
                                    <FormSelect
                                        id={"submited"}
                                        name={"was_submitted"}
                                        options={[
                                            {
                                                value: true,
                                                label: t("Yes"),
                                            },
                                            {
                                                value: false,
                                                label: t("No"),
                                            },
                                        ]}
                                        clearable={true}
                                        value={selectedOrder}
                                        label={t("Submited")}
                                        multiple={false}
                                    />

                                    <FormSelect
                                        name={"application__challenge__id__in"}
                                        options={challenges}
                                        label={t("Challenges")}
                                        multiple={true}/>
                                    <ApplicationsHistorySectorsFilters
                                        resetFilters={false}/>
                                </>
                            }
                            actionColumnCell={({
                                                   object: historyApplication,
                                                   rowTable,
                                                   actionsRow
                                               }) => {
                                return (
                                    <>
                                        {!historyApplication.was_submitted ? (
                                            <TwinButtons
                                                initialActive={
                                                    historyApplication.is_aproved === null ? null : historyApplication.is_aproved ? "right" : "left"
                                                }
                                                left={
                                                    <button
                                                        className="miniButtonReprove"
                                                        onClick={(e) => changeStatusApplication(e, false, historyApplication, actionsRow)}
                                                        data-cy="reprove-button"
                                                    >
                                                        <Reprove/> {t("Reprove")}
                                                    </button>
                                                }
                                                right={
                                                    <button
                                                        className="miniButtonAprove"
                                                        onClick={(e) => changeStatusApplication(e, true, historyApplication, actionsRow)}
                                                        data-cy="approve-button"
                                                    >
                                                        <Approve/> {t("Approve")}
                                                    </button>
                                                }
                                            />
                                        ) : (
                                            <SpanStatusApplication
                                                isApproved={historyApplication.is_aproved}/>
                                        )}
                                    </>
                                );
                            }}
                            columns={allColumnsPhases}
                            hasChecked={true}
                            titleFilter={"Filter applications"}
                            searchValue={searchValue}
                            onRowClick={openInfoModal}
                            hasPagination={true}
                            pageSize={30}
                            setSelecteds={setSelecteds}
                            onChangeSearch={({target}) => setSearchValue(target.value)}
                            hasSearch={true}
                            hasManageColumns={true}
                            url={`phases/${phase_id}/history_allocation?status=${statusFilter}&calculate_yes_no_question=yes`}
                            hasExport={true}
                            exportFileName={"applications"}
                        />
                    )}
                </div>
            </>
            <ApplicationDrawer solutionId={applicationModalShow.id} open={modalShow} setOpen={setModalShow}  />
        </CardContainer>
    );
}

export default ApplicationSelection;
