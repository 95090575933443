import styled from "styled-components";
import { Tag } from "antd";
import tinycolor from "tinycolor2";
import { darken } from "polished";

const StyledTag = styled(Tag)`
    --base-color: ${({$tagColor}) => {
        if (!$tagColor) return;
        const luminance = tinycolor($tagColor).getLuminance();
        if (luminance >= 0.6) {
            return darken(luminance * 0.5, $tagColor);
        } else {
            return 'white';
        }
    }};
    --border-color: ${({$tagColor}) => {
        if (!$tagColor) return;
        return darken(0.2, $tagColor);
    }};

    max-width: inherit;
    background-color: ${props => props.$tagColor};
    border-color: var(--border-color);
    color: var(--base-color);
    height: max-content;
    height: 24px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: text-bottom;
    max-width: ${props => `${props.$maxWidth}px` || "initial"};
    /* display: flex; */
    align-items: center;
    gap: 4px;
`;

function SolvTag({ children, color, style, maxWidth, ...props }) {
  return (
    <StyledTag $tagColor={color} $maxWidth={maxWidth} style={style} {...props}>
      {children}
    </StyledTag>
  );
}

export default SolvTag;