import {Flex, Space, Typography} from "antd";
import {useTranslation} from "react-i18next";
import WideSpace from "../../../../../layout/WideSpace";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import DescriptionSolutionCollapses from "./components/DescriptionSolutionCollapses";
import {useApplicationDrawerContext} from "../../context/ApplicationDrawerContext";
import {DateFormatByUserTimezone} from "../../../../../../utils/dates";
import {FieldTimeOutlined} from "@ant-design/icons";
import useSystemBreakpoint from "../../../../../../hooks/useSystemBreakpoint";
import ChallengeDrawer from "../../../../challenge/ChallengeDrawer/ChallengeDrawer";
import {useState} from "react";
import ApplicationInfoCards from "./components/ApplicationInfoCards";
import styled from "styled-components";

const ImageContainer = styled.img`
    max-height: 327px;
    width: 100%;
    border-radius: 8px;
`

const {Text} = Typography;

export default function DescriptionSolutionTab() {
    const {t} = useTranslation()
    const {isDesktop} = useBreakpoint()
    const {solution} = useApplicationDrawerContext();
    const {isPhone} = useSystemBreakpoint();
    const [challengeDrawerOpen, setChallengeDrawerOpen] = useState(false)

    return (
        <div style={{padding: isDesktop ? "0 10px" : "0"}}>
            <WideSpace direction={"vertical"} size="middle">
                {solution.banner_url && <ImageContainer src={solution.banner_url}/>}
                <ApplicationInfoCards solution={solution} setChallengeDrawerOpen={setChallengeDrawerOpen} />
                <Flex justify="space-between">
                    <Text strong>{t("Application phases")}</Text>
                    <Text type="secondary" style={{whiteSpace: "nowrap"}} className="keep-width">
                        {solution.date_submited && (
                            <Space size={4}>
                                <FieldTimeOutlined/>
                                {!isPhone && <>{t("Application date")}:</>} {DateFormatByUserTimezone(solution.date_submited)}
                            </Space>
                        )}
                    </Text>
                </Flex>
                <DescriptionSolutionCollapses phases={solution.phases}/>
                <ChallengeDrawer challengeIndex={solution.challenge_index} open={challengeDrawerOpen}
                                 setOpen={setChallengeDrawerOpen}/>
            </WideSpace>
        </div>
    );
}
