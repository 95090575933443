import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {
    PlusOutlined,
} from "@ant-design/icons";
import {Form as FormAnt} from "antd";
import {
    useLayoutContext
} from "../../../../../modules/project/components/layout/context/LayoutContext";
import useOptionsTranslation
    from "../../../../../global/hooks/useOptionsTranslation";
import * as ConstantsMessage
    from "../../../../../global/utils/fileWithMessage";
import Button from "../../../../../global/components/data_entry/Button";
import Drawer from "../../../../../global/components/layout/Drawer";
import {
    FlexContainer
} from "../../../../../global/components/authentication/StyledComponents";
import InformationCard
    from "../../../../../global/components/data_display/InformationCard";
import Form from "../../../../../global/components/data_entry/form/Form";
import InputFormField
    from "../../../../../global/components/data_entry/form/InputFormField";
import DateRangePickerFormField
    from "../../../../../global/components/data_entry/form/DateRangePickerFormField";
import SelectFormField
    from "../../../../../global/components/data_entry/form/SelectFormField";
import InputNumberFormField
    from "../../../../../global/components/data_entry/form/InputNumberFormField";
import {
    ACTIONS_CAMPAIGN_CHOICES,
    TYPE_BONUS_CAMPAIGN_CHOICES, VALUE_CAMPAIGN
} from "../../../../../global/utils/Choices";
import {useProgramContext} from "../../../../ProgramsNavigator";
import moment from "moment";
import {
    ToastNotification
} from "../../../../../global/components/feedback/ToastNotification";
import {BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import api from "axios";
import ImageUploadField
    from "../../../../../global/components/data_entry/form/ImageUploadField";
const FORM_NAMES = {
    NAME: 'name',
    DESCRIPTION: 'description',
  IMAGE_FILE: 'image_file',
    TYPE_ACTION: 'type_action',
    START_DATE: 'date_start',
    END_DATE: 'date_end',
    DATES: 'dates',
    AMOUNT: 'amount',
    METHOD: 'method',
}

export default function AddNewCampaignProgramButton({bodyRequest}) {
    const {t} = useTranslation();
    const [open, setOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [form] = FormAnt.useForm()
    const bonusTypeValue = FormAnt.useWatch(FORM_NAMES.METHOD, form)
    const {updateLayoutContent} = useLayoutContext()
    const {translationOptions} = useOptionsTranslation()
    const {program} = useProgramContext()

    function onClose() {
        setOpen(false)
        form.resetFields()
    }

    async function getValuesForm() {
        let data = await form.getFieldsValue()
        const newData = {...data}


        newData[FORM_NAMES.START_DATE] = newData[FORM_NAMES.DATES][0]
         newData[FORM_NAMES.IMAGE_FILE] = newData[FORM_NAMES.IMAGE_FILE][0]?.pk
        newData[FORM_NAMES.END_DATE] = newData[FORM_NAMES.DATES][1]
        delete newData[FORM_NAMES.DATES]

        return newData
    }

    async function handleSubmit() {
        try {
            setIsLoading(true);
            let data = await getValuesForm()
            data['object_id'] = program.id
            data['model_name'] = 'program'
            await api.post(`/point_event`, data);
            updateLayoutContent()
            onClose()
            ToastNotification(t(ConstantsMessage.SUCCESS));


        } catch (error) {
            const startDateError = error?.response?.data?.errors?.[FORM_NAMES.START_DATE][0]
            if (startDateError) {

                form.setFields([{
                    name: FORM_NAMES.DATES,
                    errors: [startDateError]
                }])

            }
            BuildFieldErrorsDict(error, form.setFields, null);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <>
            <Button onClick={() => setOpen(true)} icon={<PlusOutlined/>}
                    type={'default'}>{t('Create new campaign')}</Button>

            <Drawer title={t('New campaign')} open={open} onClose={onClose}
                    footer={
                        <FlexContainer justify="end"><Button
                            onClick={form.submit}
                            loading={isLoading}>{t("Create")}</Button></FlexContainer>
                    }>

                <Form form={form} disabled={isLoading}
                      onFinish={handleSubmit}>

                    <InputFormField showCount maxLength={30} form={{
                        name: FORM_NAMES.NAME,
                        label: t('Name'), rules: [{
                            required: true,
                            message: t('This field is require')
                        }],
                    }}/>
                 <ImageUploadField form={{
                        name: FORM_NAMES.IMAGE_FILE,
                        label: t("Image"),
                        rules: [{
                            required: true,
                            message: t('This field is require')
                        }],
                        help: `${t("Size")} (472x472). Max. 2MB`
                    }}/>
                    <InputFormField form={{
                        name: FORM_NAMES.DESCRIPTION,
                        label: t('Description'), rules: [
                            {max:255, message: t('characterLimit', { count: 255 })},
                            {required: true, message: t('This field is require')}
                        ],
                    }}/>


                    <DateRangePickerFormField
                        disabledDate={(currentDate) => !(currentDate >= moment().add(0, 'days'))}
                        style={{width: '100%'}}
                        form={{
                            name: FORM_NAMES.DATES,
                            label: t('Campaign period'),
                            rules: [{
                                required: true,
                                message: t('This field is require')
                            }],
                        }}/>
                    <SelectFormField
                        options={translationOptions(ACTIONS_CAMPAIGN_CHOICES)}
                        form={{
                            name: FORM_NAMES.TYPE_ACTION,
                            label: t('Action that applies'),
                            rules: [{
                                required: true,
                                message: t('This field is require')
                            }],
                            help: t('Choose an action to which the bonuses are applied.')
                        }}/>
                    <SelectFormField
                        options={translationOptions(TYPE_BONUS_CAMPAIGN_CHOICES)}
                        form={{
                            name: FORM_NAMES.METHOD,
                            label: t('Bonus Type'),
                            rules: [{
                                required: true,
                                message: t('This field is require')
                            }],
                        }}/>
                    <InputNumberFormField min={0}
                                          // max={bonusTypeValue === VALUE_CAMPAIGN ? 1000 : 100}
                                          disabled={!bonusTypeValue} form={{
                        name: FORM_NAMES.AMOUNT,

                        help: t('Maximum 100% percentage bonus or 1000 fixed amount.'),
                        label: t('Bonus'), rules: [{
                            required: true,
                            message: t('This field is require')
                        }, {max:bonusTypeValue === VALUE_CAMPAIGN ? 1000 : 100, message:t('Maximum limit'),type: 'number'}],
                    }}/>
                </Form>
            </Drawer>

        </>


    )
}
