import React from 'react'

import styled from "styled-components";

const StyledLabel = styled.label`
    color:red !important;
    margin-left:4px; 
`

export default function RequiredAsterisk(props) {
    return <StyledLabel {...props}>*</StyledLabel>
}