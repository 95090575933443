import React from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import useDefaultGetRequest
    from "../../../../../global/hooks/useDefaultGetRequest";
import {Col, Row} from "antd";
import Loading from "../../../../../global/components/feedback/Loading";
import CampaignCard
    from "../../../../../global/components/data_display/CampaignCard";
import Empty from "../../../../../global/components/data_display/Empty";
import {CAMPAIGN_STATUS} from "../../../../../global/utils/Constants";
import {useProgramContext} from "../../../../ProgramsNavigator";


const Title = styled.h2`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
`
export default function SectionOpenCampaignProgram({}) {
    const {t} = useTranslation();
    const {program} = useProgramContext()
    const {
        data: campaigns,
        isLoading
    } = useDefaultGetRequest({
        url: `/point_event/program/${program.id}/get_campaign_object_related/`,
        params: {
            status: CAMPAIGN_STATUS.IN_PROGRESS,
            // environment_campaign: true
        },
        makeRequest: !!program?.id,
        dataDefault: [],
        dependence: [program?.id]
    })


    return (

        <>

            <Row gutter={[16, 16]}>
                <Col xs={24}><Title
                    level={6}>{t('Current campaigns')}</Title></Col>
                {isLoading ? (
                    <Col xs={24}><Loading height={'100px'}/></Col>
                ) : campaigns?.results?.length > 0 ? campaigns?.results?.map((campaign) =>
                    <Col xs={24} key={campaign.id}><CampaignCard
                        campaign={campaign}/></Col>
                ) : <Col xs={24}><Empty
                    description={t('There are no active campaigns at the moment.')}/></Col>}

            </Row>


        </>

    );
}
