import { Space } from "antd";
import { useTranslation } from "react-i18next";
import EnvironmentLink from "../../link/EnvironmentLink";
import { Badge } from "../Badge";
import RemixIcon from "../RemixIcon";
import ApplicationInfoCardBase from "./ApplicationInfoCardBase";
import ApplicationInfoMessage from "./components/ApplicationInfoMessage";
import SolvTextTag from "../../tag/SolvTextTag";
import ApplicationTagStatus from "../../tag/ApplicationTagStatus";

export default function ApplicationTopicInfoCard({topic, onClick}) {
    const {t} = useTranslation();

    return (
        <ApplicationInfoCardBase
            application={topic.solution}
            color={topic.solution.logged_user_is_member ? "#FF9DC6" : "#C290A5"}
            title={topic.solution.date_submited ? topic.solution.name :
                <EnvironmentLink clientUrl={topic.solution.program?.client_url}
                    to={`/solutions/${topic.solution.id}`}>{t("Complete the form submission.")}
                </EnvironmentLink>}
            extra={
                <>
                    <ApplicationTagStatus application={topic.solution}/>
                    {(topic.unread_messages_count > 0) &&
                        <Badge background="#FF4D4F">
                            <Space size={8}>
                                <RemixIcon
                                    remixIconName={'ri-message-2-line'} />
                                <span>{topic.unread_messages_count}</span>
                            </Space>
                        </Badge>
                    }
                </>
            }
            onClick={onClick}
        >
            <ApplicationInfoMessage topic={topic} />
        </ApplicationInfoCardBase>
    )
}