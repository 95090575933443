import { Card } from "antd"
import styled from "styled-components";


export default function InfoCard({
  children, padding, borderless=false, borderColor="#D9D9D9",
  borderSize=10, borderRadius=true,
  borderBottom: headerBorder = true, ...props
}) {
    const StyledCard = styled(Card)`
        .ant-card-body {
            /* padding: ${({padding}) => padding ? `${padding}px` : "24px"}; */
            padding: ${({ padding }) =>
            Array.isArray(padding)
            ? `${padding[0]}px ${padding[1]}px`
            : padding
            ? `${padding}px`
            : "24px"};
            margin: 0;
        }
        
        border-left: ${({borderColor, borderSize}) => (borderColor ? `${borderSize}px solid ${borderColor}` : "none")};
        border-radius: ${({borderRadius}) => (borderRadius ? "2px" : "none")};
        .ant-card-head{
          ${({headerBorder})=>!headerBorder && `border-bottom: unset;`}
        }
    `;
    return (
        <StyledCard
            padding={padding}
            borderColor={borderColor}
            borderRadius={borderRadius}
            borderSize={borderSize}
            headerBorder={headerBorder} {...props}
        >
            {children}
        </StyledCard>);

}