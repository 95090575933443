import React, {useState} from 'react';
import styled from "styled-components";
import {neutral_3, neutral_5, purple_5} from "../../../../utils/Colors";
import Flex from "../../../responsive/Flex";
import {useTranslation} from "react-i18next";
import {Badge} from "antd";
import RemixIcon from "../../../data_display/RemixIcon";
import useSystemBreakpoint from "../../../../hooks/useSystemBreakpoint";
import NoteFormDrawer from "./NoteFormDrawer";
import useDefaultGetRequest from "../../../../hooks/useDefaultGetRequest";

const StyledSpan = styled.span`
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    border: 1px solid ${neutral_5};
    border-radius: 5px;
    padding: 0 7px;
    cursor: pointer;
    transition: ease .5s all;
    height: 28px;
`

export default function NoteCountButtonDrawer({url}) {
    const {t} = useTranslation()
    const {isPhone} = useSystemBreakpoint()
    const [open, setOpen] = useState(false)
    const {data, fetchData} = useDefaultGetRequest({url, dependence:[url]})

    return (
        <>
            <StyledSpan onClick={() => setOpen(true)} data-cy="note-count-button-drawer">
                <Flex align="center" fullHeight={true}>
                    <RemixIcon remixIconName={"ri-chat-1-fill"} style={{color:purple_5}}/>
                    {!isPhone && t("Notes")}
                    <Badge count={data?.user_notes?.length} size="small" showZero={true}/>
                </Flex>
            </StyledSpan>
            <NoteFormDrawer open={open} setOpen={setOpen} noteId={data?.id} notes={data?.user_notes} fetchData={fetchData}/>
        </>
    );
}
