import React from "react";
import {ContextProviderProfileUser} from "./context/ContextProfileUser";
import MainProfileUser from "./tabs/MainProfileUser";



export default function DrawerProfileUser({id, phase_id,program_id,open, setOpen, ...props}) {
    return (
        <ContextProviderProfileUser id={id} phase_id={phase_id} program_id={program_id} open={open}>
            <MainProfileUser open={open} setOpen={setOpen} {...props}/>
        </ContextProviderProfileUser>)
}
