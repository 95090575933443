import { useTranslation } from "react-i18next";
import SolvTag from "./SolvTag";
import {Button, Popover} from "antd";
import CustomTag from "./CustomTag";


export default function TagsList({isCustom=false,tags=[], maxSize=3, defaultValue='', style}) {
    const {t} = useTranslation();
    const previewTags = tags.slice(0, maxSize);

    let showPopover = false;

    if (tags.length > maxSize) {
        showPopover = true;
    }

    const drawTags = (tags) => {
        if(isCustom){
            return tags.map((tag) => <CustomTag key={tag.id} {...tag}/>)
        }
        return tags.map((tag, index) => <SolvTag key={index} color={tag.color} title={tag.name}>{t(tag.name)}</SolvTag>)

    }

    return (
        <div style={{ display: "flex", alignItems: "center", gap: 8, ...style}}>
            {tags.length > 0 ? drawTags(previewTags): <>{defaultValue}</>}
            {showPopover &&
                <Popover
                    trigger="hover"
                    content={<div style={{ width: "200px", display: "flex", gap: 8, flexWrap: "wrap" }} >{drawTags(tags)}</div>}>
                    <Button size="small" style={{ margin: 0 }}>...</Button>
                </Popover>
            }
        </div>
    )
}