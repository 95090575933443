import React from "react";
import { Modal } from "react-bootstrap";
import ButtonDefault from "./Buttons/ButtonDefault";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledSpacing = styled.div`
    * {
        margin: 4px !important;
    }
`;

function ModalConfirm(props) {
    const { t } = useTranslation();
    return (
        <Modal show={props.show} onHide={props.onHide} centered>
            <Modal.Body>
                <Modal.Title style={{ color: " var(--primary_btn_color)" }}>{props.title}</Modal.Title>
                {props.message}
            </Modal.Body>
            <Modal.Footer style={{ border: "none" }}>
                <StyledSpacing>
                    <ButtonDefault
                        action="secondary"
                        onClick={() => {
                            props.onCancel && props.onCancel();
                            props.onHide();
                        }}
                        label={t("No")}
                    />
                    <ButtonDefault
                        action="primary"
                        onClick={() => {
                            props.onConfirm && props.onConfirm();
                            props.onHide();
                        }}
                        label={t("Yes")}
                    />
                </StyledSpacing>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalConfirm;
