import { Typography, Card, Space, Avatar, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import Flex from "../../../../../../../global/components/responsive/Flex";
import styled from "styled-components";
import evaluatorOne from "../../../../../../../assets/img/evaluator_1.png";
import evaluatorTwo from "../../../../../../../assets/img/evaluator_2.png";
import {
    GrayContainer
} from "../../../../../../../global/components/authentication/StyledComponents";
import {EVALUATION_FORMATS} from "../../../../../../../global/utils/Constants";

const { Text, Title } = Typography;

const StyledCard = styled(Card)`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: ${props => props.justifyCenter ? "center" : "start"};
    border: ${props => props.hasWeight ? '1px solid #D9D9D9' : '1px solid #F5F5F5'};
    border-radius: 8px;

    .ant-card-body {
        padding: 8px;
    }
`;

const dataWeighted = {
    score: "8.00",
    evaluations: [
    {
        id: 1,
        evaluator_image: evaluatorOne,
        assessments: [
            {
                title: "QUESTION 1",
                score: "6",
                weight: "33.33%",
            },
            {
                title: "QUESTION 2",
                score: "8",
                weight: "33.33%",
            },
            {
                title: "QUESTION 3",
                score: "9",
                weight: "33.34%",
            },
            {
                total: true,
                score: "7.66",
            },
        ]
    },
    {
        id: 2,
        evaluator_image: evaluatorTwo,
        assessments: [
            {
                title: "QUESTION 1",
                score: "8",
                weight: "33.33%",
            },
            {
                title: "QUESTION 2",
                score: "7",
                weight: "33.33%",
            },
            {
                title: "QUESTION 3",
                score: "10",
                weight: "33.34%",
            },
            {
                total: true,
                score: "8.33",
            },
        ]
    },
]};

const dataUnweighted = {
    score: "6.50",
    evaluations: [
    {
        id: 1,
        evaluator_image: evaluatorOne,
        assessments: [
            {
                title: "QUESTION 1",
                score: "7",
            },
            {
                title: "QUESTION 2",
                score: "6",
            },
            {
                title: "QUESTION 3",
                score: "9",
            },
            {
                total: true,
                score: "7.33"
            },
        ]
    },
    {
        id: 2,
        evaluator_image: evaluatorTwo,
        assessments: [
            {
                title: "QUESTION 1",
                score: "6",
            },
            {
                title: "QUESTION 2",
                score: "7",
            },
            {
                title: "QUESTION 3",
                score: "4",
            },
            {
                total: true,
                score: "5.66",
            },
        ]
    },
]}

export default function AssessmentFormatHelp({evaluationFormat}) {
    const { t } = useTranslation();
    const data = evaluationFormat === "weighted" ? dataWeighted: dataUnweighted;
    return (
        <GrayContainer>
            <Row gutter={[8,8]}>
                <Col span={24}>
                    <Title level={5}>{t("Example of")} {t(EVALUATION_FORMATS[evaluationFormat])}</Title>
                    <Text style={{ fontSize: 14 }} type="secondary">
                        {t("The data below is fictitious, just to display the grade format, considering a standard scale of 0 to 10",)}.
                    </Text>
                    {data.evaluations.map((evaluation, evaluatorIndex) => (
                        <div key={evaluatorIndex}>
                            <Title level={5}>{t("Evaluator")} {evaluation.id}</Title>
                            <Row gutter={[16, 16]} style={{paddingBottom: 16}}>
                                {evaluation.assessments.map((item, index) => (
                                    <Col key={index} xs={24} sm={24} md={12} lg={12} xl={12} flex={1} style={{ width: "100%" }}>
                                        <StyledCard hasWeight={!item.weight}>
                                            <Flex gap={8} align="center">
                                                <Avatar src={evaluation.evaluator_image} />
                                                {item.total ? (
                                                    <Space direction="vertical" size="small" style={{all: 'unset'}}>
                                                        <Text style={{ fontSize: 10 }} type="secondary">
                                                            {t("SCORE")}
                                                            {t(item.title)}
                                                        </Text>
                                                        <Text style={{ fontWeight: 700, fontSize: 14 }}> {item.score}</Text>
                                                    </Space>
                                                ) : (
                                                    <Space direction="vertical" size="small" style={{all: 'unset'}}>
                                                        <Text style={{ fontSize: 10 }} type="secondary">
                                                            {t("SCORE")} - {t(item.title)}
                                                            <Text style={{ fontWeight: 700, fontSize: 14 }}> {item.score}</Text>
                                                        </Text>
                                                        <Text style={{ fontSize: 10 }} type="secondary">
                                                            {t("WEIGHT")} - {t(item.title)}
                                                            <Text style={{ fontWeight: 700, fontSize: 14, verticalAlign: 'middle'}}> {item.weight || "-"}</Text>
                                                        </Text>
                                                    </Space>
                                                )}
                                            </Flex>
                                        </StyledCard>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    ))}
                </Col>
                <Col span={24}>
                    <StyledCard justifyCenter>
                        <Space direction="vertical" size="middle" style={{all: 'unset'}}>
                            <Text style={{ fontWeight: 700, fontSize: 16 }}>{t("FINAL SCORE")}</Text>
                            <Text style={{ fontWeight: 500, fontSize: 38 }}>{data.score}</Text>
                        </Space>
                    </StyledCard>
                </Col>
            </Row>
        </GrayContainer>
    );
}

