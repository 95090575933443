import React from 'react';
import {useTranslation} from "react-i18next";
import {Typography} from "antd";

const {Text} = Typography;


export default function ChartTotalDisplay({description, total}) {
    const {t} = useTranslation();
    return (
        <div style={{display: "flex", flexDirection: "column"}}>
            <Text type="secondary">{t(description)}:</Text>
            <Text style={{fontSize: 20, fontWeight: 500}}>R${total}</Text>
        </div>
    );
}
