import {usePermissionContext} from "../../../../../../context/PermissionContext";
import {useTranslation} from "react-i18next";
import Button from "../../../../../data_entry/Button";
import Search from "../../../../../data_entry/Search";
import {useEffect, useState} from "react";
import RequestFormDrawer from "./components/RequestFormDrawer";
import WideSpace from "../../../../../layout/WideSpace";
import RemixIcon from "../../../../../data_display/RemixIcon";
import RequestCardButton from "./components/RequestCardButton";
import {BuildFieldErrorsDict} from "../../../../../../utils/Utils";
import api from "../../../../../../services/api";
import {useApplicationDrawerContext} from "../../context/ApplicationDrawerContext";
import {Skeleton} from "antd";
import Empty from "../../../../../data_display/Empty";
import RequestDrawer from "./components/RequestDrawer";

export default function RequestsSolutionTab() {
    const {t} = useTranslation();
    const {hasProgramAdminOrManagerPermission, hasProgramEvaluatorPermission} = usePermissionContext();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [requests, setRequests] = useState()
    const [search, setSearch] = useState()
    const [loading, setLoading] = useState(false);
    const [openShowRequestDrawer, setOpenShowRequestDrawer] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const {solution, hasEvaluatorRequestPermission, setRequestsCount} = useApplicationDrawerContext();

    const fetchRequests = async () => {
        try {
            setLoading(true)
            const {data} = await api.get(`diligences/list_by_application/${solution?.id}`, {params: {search}})
            setRequests(data);
            setRequestsCount(data.filter((request=>request.status === 'waiting')).length)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        void fetchRequests()
    }, [search]);


    const handleShowRequest = (request) => {
        setSelectedRequest(request);
        setOpenShowRequestDrawer(true);
    };

    const getHasRequestPermission = () => {
        const {logged_user_is_member: hasTeamPermission} = solution || {};
        const {allow_evaluator_to_be_candidate: hasSelfEvaluationPermission} = solution?.program || {};

        if (hasProgramAdminOrManagerPermission) {
            return true;
        }

        if (hasEvaluatorRequestPermission) {
            return !(hasTeamPermission && !hasSelfEvaluationPermission);
        }

        return false;
    };


    return (
        <>
            <WideSpace direction="vertical" size="large">
                <div>
                    <WideSpace size="middle" style={{justifyContent: "end"}}>
                        <Search placeholder={t("Search")} onSearch={(value) => {
                            setSearch(value)
                        }}/>
                        {getHasRequestPermission() &&
                            <Button type="primary" onClick={() => setDrawerOpen(true)} data-cy="create-request-button" loading={loading}>
                                <RemixIcon remixIconName="ri-draft-line"/>
                                {t("Request a requisition")}
                            </Button>
                        }
                    </WideSpace>
                </div>
                <Skeleton loading={loading}>
                    <WideSpace direction="vertical" size="middle">
                        {requests?.length > 0 ?
                            requests?.map(request => (
                                <RequestCardButton
                                    request={request}
                                    onClick={() => handleShowRequest(request)}
                                />
                            )) :
                            <Empty/>
                        }
                    </WideSpace>
                </Skeleton>
            </WideSpace>
            <RequestFormDrawer
                open={drawerOpen} 
                setOpen={setDrawerOpen}
                solution={solution} 
                fetchRequests={fetchRequests}
                setLoading={setLoading}
                loading={loading}
            />
            {selectedRequest &&
                <RequestDrawer
                    open={openShowRequestDrawer}
                    setOpen={setOpenShowRequestDrawer}
                    request={selectedRequest}
                    fetchRequests={fetchRequests}
                />
            }
        </>
    );
}