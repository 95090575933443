import React from "react";
import DefaultComponent from "./DefaultComponent";
import Input from "../../../Form/Input";
import {useTranslation} from "react-i18next";

function TextInputForm(props) {
    const {t} = useTranslation();

    return (
        <DefaultComponent {...props}>
            <Input placeholder={t("Type your answer here...")} />
        </DefaultComponent>
    )
}

export default TextInputForm