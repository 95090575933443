import {Area} from '@ant-design/plots';
import {useTranslation} from 'react-i18next';
import {formatNumber} from '../../../../utils/utils';

function LineAreaChart({data, indicator, smooth = true, color="#165DFF"}) {
    const {t} = useTranslation();

    const config = {
        data,
        height:300,
        xField: "status_report_date",
        yField: "stored_value",
        seriesField: "xField",
        style: {
            shape: smooth ? 'smooth' : undefined,
            fill: `linear-gradient(-90deg, #E8F1FF 0%, ${color} 100%)`,
        },
        tooltip: {
            title: ({order, status_report_date}) => (
                `Checkpoint Nº${order} - ${status_report_date.slice(0,10)}`
            ),
            items: [(d) => ({
                    name: t(indicator.name),
                    value: smooth? `R$ ${formatNumber(d.stored_value)}` : d.stored_value,
                })
            ],
        },
        axis:{
            x:{
                title:"Checkpoint",
                tickLength: 0,
                labelFormatter: (text) => text.slice(0, 10)
            },
            y:{
                title:t("Value"),
                tickLength: 0,
                labelFormatter: smooth? (d) => `R$ ${formatNumber(d)}` : (d) => d
            }
        },

    };
    return <Area {...config} />
}

export default LineAreaChart;