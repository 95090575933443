import React, {useMemo} from 'react';
import {getScaledValueIfScaledIndicator} from "../../../../utils/utils";
import {Column} from "@ant-design/plots";
import {ArrowDownOutlined, ArrowUpOutlined} from "@ant-design/icons";
import {Space} from "antd";
import styled from "styled-components";
import {useChartColor} from "../DashboardIndicatorCharts/hooks/useChartColor";
import {DateFormatUser} from "../../../../../../global/utils/dates";
import {useUserContext} from "../../../../../../global/context/UserContext";
import {useTranslation} from "react-i18next";

const StyledContainer = styled.div`
    height: 15px;
    width: 100px;
`;

export function IndicatorHistoryChart({data, indicatorResponse, yField, indicator}) {
    const {getColorByImportancy} = useChartColor({indicator, indicatorResponse});
    const {_user} = useUserContext();
    const {t} = useTranslation()
    
    const xyConfig = {
        grid: false,
        label: false,
        tick: false,
    }

    const config = {
        xField: "status_report",
        yField: yField,
        data: data || indicatorResponse.previous_values,
        height:15,
        width:100,
        padding:0,
        margin:0,
        inset: 0,
        autoFit: true,
        axis: {
            x: xyConfig,
            y: xyConfig,
        },
        style: {
            fill: ({scaled_value}) => {
                return getColorByImportancy(scaled_value)
            },
            padding: 10,
        },
        // interaction:{
        //     tooltip: false,
        // },
        animate: { enter: { type: 'growInY' } },
        tooltip: {
            title: ({order, status_report_date, status_report}) => {
                let value = status_report_date || status_report;
                return `Checkpoint Nº${order} - ${value}`
            },
            items: [(d) => (
                {
                    name: t(indicator.name),
                    color: getColorByImportancy(d[yField]),
                    value: d[yField]
                })
            ],
        },
    }

    return (
        <Column {...config}/>
    );
}

export default function IndicatorHistory({indicatorResponse, yField = "stored_value"}) {
    const {indicator} = indicatorResponse;
    const {getColor} = useChartColor({indicator, indicatorResponse});
    
    const previousValues = [
        ...indicatorResponse["previous_values"],
        {
            "status_report": indicatorResponse["status_report"],
            "stored_value": parseFloat(indicatorResponse["stored_value"]),
            "scaled_value": indicatorResponse["scaled_value"],
        },
    ];
    
    const hasIncreased = () => {
        const currentValue = indicatorResponse[yField];
        const previousResponse = indicatorResponse["previous_values"].at(-1);
        const previousValue = previousResponse ? previousResponse[yField] : 0;
        return currentValue >= previousValue;
    };

    const color = getColor(getScaledValueIfScaledIndicator({
        value: indicatorResponse[yField],
        indicatorResponse,
        indicator
    }));
    const arrowStyle = {color: color};

    return useMemo(() => {
        return (
            <Space>
                <StyledContainer>
                    <IndicatorHistoryChart data={previousValues} yField={yField} indicator={indicator}
                                           indicatorResponse={indicatorResponse}/>
                </StyledContainer>
                    {hasIncreased() ? <ArrowUpOutlined style={arrowStyle}/> : <ArrowDownOutlined style={arrowStyle}/>}
            </Space>
        )
    }, [indicatorResponse])

}
