import {Input} from 'antd';
import {useEffect, useState} from "react";
import {getLanguage} from "../../../../global/utils/translation";

function setNumberGrouping({value, groupSeparator}) {
    let finalNumber = ''

    while (true) {
        let lastNumber = value.slice(-3)
        if (lastNumber) {
            if (finalNumber) {
                finalNumber = `${lastNumber}${groupSeparator}${finalNumber}`
            } else {
                finalNumber = lastNumber
            }
            let indexToSlice
            if (value.length < 3) {
                indexToSlice = -3
            } else {
                indexToSlice = (value.length - 3)
            }
            value = value.slice(0, indexToSlice)
        } else {
            break;
        }
    }
    return finalNumber
}

export function numberMask({value, limit = 11, groupSeparator, decimalSeparator}) {
    let number = value.toString().replace(/([^\d]|^0+)/g, '');
    if (number === '') {
        return `0${decimalSeparator}00`
    }
    if (number.length > limit) {

        return value.replace(/\d$/, '')
    }
    if (number.length <= 3) {

        if (number.length === 1) {
            number = `0${decimalSeparator}0${number}`
        }
        if (number.length === 2) {
            number = `0${decimalSeparator}${number}`
        }
        if (number.length === 3) {
            number = `${number.slice(0, 1)}${decimalSeparator}${number.slice(1, 3)}`;
        }
    } else {

        number = `${setNumberGrouping({value: number.slice(0, (number.length - 2)), groupSeparator})}${decimalSeparator}${number.slice(-2)}`
    }

    return number
}

function getMaskedNumber({value, limit, language}){
    const currentLanguage = language || getLanguage();
    if (currentLanguage === "pt-br"){
        return numberMask({value, limit, groupSeparator: '.', decimalSeparator: ',' })
    }
    if (currentLanguage === "en-us"){
        return numberMask({value, limit, groupSeparator: ',', decimalSeparator: '.' })
    }
}

function getDecimalNumber({value, language}){
    const currentLanguage = language || getLanguage();
    if (currentLanguage === "pt-br"){
        return value.replaceAll('.','').replaceAll(',','.');
    }
    if (currentLanguage === "en-us"){
        return value.replaceAll(',','')
    }
}

const MoneyInput = ({value, onChange, limit = 14, ...props}) => {
    const [formattedValue, setFormattedValue] = useState('0.00');
    const handleChange = (event) => {
        const {value} = event.target;
        const maskedValue = getMaskedNumber({value: value, limit});
        setFormattedValue(maskedValue)
        onChange && onChange(String(getDecimalNumber({value: maskedValue})), maskedValue)
    }

    useEffect(()=>{
        if (value !== undefined && value !== null) {
            setFormattedValue(getMaskedNumber({value: Number(value).toFixed(2), limit}));
        }
    }, [value]);

    return (
        <Input
            value={formattedValue}
            onChange={handleChange}
            controls={false}
            {...props}
        />
    );
};

export default MoneyInput;
