import React from 'react'
import Label from "./Label";
import MessageError from "./MessageError";
import InputContainer from "./InputContainer";
import {StyledInput} from "./GenericStyledForm";
import InterrogationTip from "../InterrogationTip";
import styled from 'styled-components'

const StyledPreLabel = styled.div`
  display: flex;
  align-items: center;

  span {
    border: 1px solid rgb(221, 221, 221);
    border-right: none !important;
    color: #949494;
    height: 100%;
    padding: 7px 5px 7px 5px;
    margin-right: -2px;
    border-radius: 6px 0 0 6px;
  }
`

export default function Input({
                                  id,
                                  required,
                                  label,
                                  preLabel,
                                  messageError,
                                  elementDescription,
                                  refElementInput,
                                  information,
                                  ...props
                              }) {
    return (
        <InputContainer>
            <div style={{display: "flex", alignItems: "center"}}>
                {label &&
                    <Label htmlFor={id} required={required}>{label}</Label>}
                {information ? <InterrogationTip title={information}/> : null}
            </div>
            {elementDescription}
            <div style={{display: 'flex', flexDirection: 'column',}}>
                <StyledPreLabel>
                    {preLabel&&<span>{preLabel}</span>}
                    <StyledInput preLabel={preLabel} ref={refElementInput} id={id} required={required} {...props} />
                </StyledPreLabel>
                {messageError && <MessageError style={{textAlign: 'left'}}>{messageError}</MessageError>}
            </div>
        </InputContainer>
    )
}