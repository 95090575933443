import React from "react";
import { Space, Card, Typography } from "antd";
import { useTranslation } from "react-i18next";
import SolvTable from './../../../../../global/components/tables/SolvTable';
import { renderIndicator } from "../../../components/tables/utils";
import useTable from "../../../../../global/hooks/useTable";
import Table from './../../../../../global/components/data_display/Table';
import styled from 'styled-components';

const { Text } = Typography;
const StyledCard = styled(Card)`
    .ant-card-head-title {
        white-space: normal !important;
    }
`;

function AutomaticIndicatorList({statusReport}) {
    const { t } = useTranslation();

    const {
        tableData, tableParams, isLoading, handleTableChange, resetTable
    } = useTable({
        url: `/project_management/project_indicator_responses/status_report/${statusReport.id}/list?`,
        extraParams: {automatic: true},
        waitExternalLoading: !statusReport,
        hasPagination: true,
        pageSize: 10
    });


    const columns = [
        { title: t("Indicator"), dataIndex: "name", width: "50%", key: 0, render: (name)=>t(name) },
        { title: t("Value"), dataIndex: "", key: 1, width: "50%", render: renderIndicator },
    ];


    return (<StyledCard
            padding={"1px"}
            bodyStyle={{ padding: 0 }}
            title={<Space size="small" direction="vertical">
                <Text>{t("Automatic Indicators")}</Text>
                <Text style={{ fontSize: 12, fontWeight: "normal" }}>
                    {t("Indicators calculated based on the data from other indicators and the project.")}
                </Text>
            </Space>}

        >
            <Table 
                columns={columns} 
                scroll={{ x: 0 }} 
                dataSource={tableData} 
                onChange={handleTableChange}
                isLoading={isLoading} 
                tableParams={tableParams}
                showSizeChanger={false}
                showQuickJumper={false}
                hideOnSinglePage={true}
            />
        </StyledCard>)
}

export default AutomaticIndicatorList;