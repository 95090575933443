import React, {useState} from 'react'
import styled from 'styled-components'
import {ReactComponent as CloseEye} from "../assets/Icon/close_eye.svg"
import {ReactComponent as OpenEye} from "../assets/Icon/open_eye.svg"
import {t} from "i18next";

const StyledDiv = styled.div`
  display: contents;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  svg {
    cursor: pointer;
  }
`


export default function ShowHiddenPassword({id}) {
    const [close, setClose] = useState(false)

    const ToggleVisibility = () => {
        const temp = document.getElementById(id);
        if (temp.type === "password") {
            temp.type = "text";
        } else {
            temp.type = "password";
        }
    }

    const changeEye = () => {
        if (id) {
            setClose(!close)
            ToggleVisibility()
        }
    }

    return (
        <StyledDiv onClick={() => changeEye()}>
            {!close ? <CloseEye title={t("Click to show your password")}/> :
                <OpenEye title={t("Click to hide your password")}/>}
        </StyledDiv>
    )
}