import React from 'react';
import Box from "../../assets/img/dashboard_box.svg"

function NotContent(props) {
    const {title, subtitle, link, href} = props

    return (
        // <div>
            <div style={{
                minHeight: "200px",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                display: "flex",
                flexDirection: "column", marginTop: 20
            }}>
                <img alt={'box'} src={Box} width="100"/>
                <span style={{fontWeight: 700, color: "#646464", fontSize: 16, marginTop: 8}}>{title}</span>
                <span style={{fontSize: 14, color: "#949494", marginTop: 8}}><a href={href}>{link} </a>{subtitle}</span>
            </div>
        // </div>
    );
}

export default NotContent;