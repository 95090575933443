import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import api from "../../../global/services/api";
import SimpleInitiativeRegistrationCollapse
    from "./components/SimpleInitiativeRegistrationCollapse";
import {Col, Row, Form as FormAnt, Space, Modal, Typography} from "antd";
import Form from "../../../global/components/data_entry/form/Form";
import RequestSelect
    from "../../../global/components/data_entry/RequestSelect";
import InputFormField
    from "../../../global/components/data_entry/form/InputFormField";
import ApplicationFormContentEditable
    from "../../../global/pages/application/ApplicationFormContentEditable";
import styled from "styled-components";
import FixedFooter from "../../../global/components/layout/FixedFooter";
import {
    FlexContainer
} from "../../../global/components/authentication/StyledComponents";
import Button from "../../../global/components/data_entry/Button";
import {
    ReactComponent as PoweredSolvIcon
} from '../../../assets/Icon/powed_solv.svg'
import {
    convertToFormData
} from "../../../modules/project/utils/utils";
import {BuildFieldErrorsDict, getApplicationTitleConvention} from "../../../global/utils/Utils";
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import {
    ToastNotification
} from "../../../global/components/feedback/ToastNotification";
import {polar_green_6} from "../../../global/utils/Colors";

const StyledFlexContainer = styled(FlexContainer)`
    padding: 16px 32px;
    background: white;
    border-top: 1px solid rgba(0, 0, 0, .06);
`
const Container = styled.div`
    padding-top: 24px;
    padding-bottom: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
`

const OPEN_APPLICATION = 'in_filling'

export default function SimpleInitiativeForm() {
    const {t} = useTranslation();
    const [application, setApplication] = useState(null);
    const [submited, setSubmited] = useState(false);
    const [program, setProgram] = useState({});
    const [isLoadingSubmit, setIsLoadingSubmit] = useState()
    const [isLoadingSave, setIsLoadingSave] = useState()
    const {slug, uuid} = useParams();
    const history = useHistory()
    const [form] = FormAnt.useForm()



    const fetchApplication = async () => {
        try {
            let response = await api.get(`/simple_initiative/${uuid}`, {'version': 'v2'});


            setApplication({...response.data});
            form.setFieldsValue({
                challenge: response.data.challenge,
                name: response.data.name,
            })
            setProgram({
                ...response.data.program,
                profile: response.data.program.client_profile
            })
        } catch (error) {
            if (error.response?.status === 400) {
                history.push('/404/', {broken_url: window.location.host + location.pathname})
            }
            // BuildFieldErrorsDict(error, null, false)
        }
    };


    useEffect(async () => {
        await fetchApplication()
    }, [uuid]);

    const handleSave = async () => {
        try {
            setIsLoadingSave(true)
            const data = form.getFieldsValue(true);
            const formData = convertToFormData(data, false);
            await api.post(`/simple_initiative/${uuid}/save_simple_initiative`, formData, {version: "v2"});
            if (application?.status === OPEN_APPLICATION) {
                Modal.confirm({
                    title: t("Application needs to be submitted"),
                    icon: <ExclamationCircleOutlined/>,
                    content: t(
                        "The Form needs to be submitted to be valid. Applications that are not submitted upon reopening will be automatically excluded from the selection process.",
                    ),
                    okText: t('Got it'),
                    okCancel: ''

                });
            }
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoadingSave(false)
        }
    }


    const handleSubmit = async () => {

        Modal.confirm({
            title: t("Do you want to submit the application?"),
            content: t("This form does not allow editing after submission. Do you want to submit?"),
            okText: t("Submit Application"),
            width: 500,
            cancelText: t("No"),
            onOk: async () => {
                try {
                    setIsLoadingSubmit(true);
                    const data = await form.getFieldsValue();
                    const formData = convertToFormData(data, false);
                    await api.post(`/simple_initiative/${uuid}/save_simple_initiative`, formData, {version: "v2"});
                    //uma linha em baixo
                    const response = await api.post(`/simple_initiative/${uuid}/submit_simple_initiative`, null, {version: "v2"});
                    const protocol = response.data.protocol;
                    setSubmited(true)
                    // history.push('/home');
                    ToastNotification(
                        t("submittedApplication", {protocol})
                    );
                } catch (error) {
                    BuildFieldErrorsDict(error, form.setFields, null)

                } finally {
                    setIsLoadingSubmit(false)
                }
            },
        });

    }

    return <>
        <SimpleInitiativeRegistrationCollapse program={program}
                                              footer={<Container>
                                                  <PoweredSolvIcon/></Container>}

        >
            {!submited ? (<>

                <Form disabled={isLoadingSubmit}
                    // initialValues={initialValues}
                      form={form}
                      onFinish={handleSubmit}
                >
                    <Row>
                        {program.has_challenges && <Col xs={24}>
                            <FormAnt.Item name="challenge"
                                          label={t('Select the Challenge')}
                                          rules={[{
                                              required: true,
                                              message: t("This field is required")
                                          }]}>
                                <RequestSelect
                                    url={`v2/programs/${program?.id}/challenge_list`}
                                    labelField="name"
                                    style={{display: "block"}}
                                    placeholder={t("Select the challenge")}
                                    allowClear={true}
                                    // disabled={isEditingDisabled()}
                                    data-cy="challenge-select"
                                />
                            </FormAnt.Item>
                        </Col>}
                        <Col xs={24}>
                            <InputFormField form={{
                                label: t('application_name', {application: program?.application_name_display}),
                                name: 'name',
                                style: {fontWeight: 500},
                                rules: [{
                                    required: true,
                                    message: t('This field is required')
                                }
                                ]
                            }}/>
                        </Col>
                        <Col xs={24}>
                            <ApplicationFormContentEditable
                                form={form}
                                phaseForm={{form: application?.current_phase_application_form, sections: application?.sections}}
                                application={application}
                            />
                        </Col>

                    </Row>

                </Form>


            </>) : (<FlexContainer align={'center'} direction={'column'} gap={10}>
                <CheckCircleOutlined
                    style={{fontSize: 150, color: polar_green_6}}/>
                <Typography.Title level={5}>
                    {t('Congratulations, your registration has been successful!')}
                </Typography.Title>
                {application.author_email &&
                    <>
                        <Typography.Text>
                            {t('If you want to follow the registration, log in to SOLV using the button below!')}
                        </Typography.Text>
                        <Button block onClick={() => history.push('/login')}>
                            {t('Track registration')}
                        </Button>
                    </>

                }
            </FlexContainer>)}

        </SimpleInitiativeRegistrationCollapse>
        {!submited && <FixedFooter>
            <StyledFlexContainer justify={'end'}>
                {application?.status === OPEN_APPLICATION ? (
                    <Space size={16}>

                        <Button type="default"
                                loading={isLoadingSave || isLoadingSubmit}
                                onClick={handleSave}
                            // disabled={areActionsDisabled()}
                        >{t("Save")}</Button>

                        <Button
                            loading={isLoadingSave || isLoadingSubmit}
                            onClick={form.submit}
                            // onClick={confirmApplicationSubmission}
                            // disabled={areActionsDisabled()}
                        >{t("Submit")}</Button>
                    </Space>) : (
                    <Typography.Text
                        style={{color: "green"}}>{t('Form submited')}</Typography.Text>
                )}
            </StyledFlexContainer>
        </FixedFooter>}
    </>
}