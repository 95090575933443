import {Alert, Form, InputNumber, Slider, Space, Switch} from "antd";
import React from "react";
import {useTranslation} from "react-i18next";
import CollapseFormItem from "../../../../../../global/components/data_entry/CollapseFormItem";
import Select from "../../../../../../global/components/data_entry/Select";
import WideSpace from "../../../../../../global/components/layout/WideSpace";
import TransparentCollapse from "../../../../../../global/components/misc/TransparentCollapse";
import useOptionsTranslation from "../../../../../../global/hooks/useOptionsTranslation";
import {
    CHOICES_PROGRAM_EVALUATION_FORMAT,
    CHOICES_TEAM_EVALUATION_RESULTS_VIEW_TYPE
} from "../../../../../../global/utils/Choices";
import styled from "styled-components";
import {neutral_2, secondary_color} from "../../../../../../global/utils/Colors";
import AssessmentFormatHelp from "./helpDrawersContent/AssessmentFormatHelp";
import FeedbackHelp from "./helpDrawersContent/FeedbackHelp";
import ScoreHelp from "./helpDrawersContent/ScoreHelp";
import AutoAllocationHelp from "./helpDrawersContent/AutoAllocationHelp";
import AssessmentViewTypeHelp from "./helpDrawersContent/AssessmentViewTypeHelp";


const StyledAlert = styled(Alert)`
    .ant-alert-message {
        color: ${secondary_color};
    }
`


export default function ScoreEvaluatorCollapse({form, FORM_NAMES, program, setHelpDrawerOpen}) {
    const {t} = useTranslation();
    const {translationOptions} = useOptionsTranslation();
    const scoreRange = Form.useWatch(FORM_NAMES.SCORE_RANGE, form) || [0, 10];
    const evaluationFormat = Form.useWatch(FORM_NAMES.EVALUATION_FORMAT, form);
    const evaluationResultViewType = Form.useWatch(FORM_NAMES.TEAM_EVALUATION_RESULT_VIEW_TYPE, form);

    const hasEvaluations = program?.has_evaluations

    const scoreSlider = <Slider
        marks={{0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10}}
        min={0}
        max={10}
        defaultValue={[0, 10]}
        range
        disabled={hasEvaluations}
    />

    const HELP = {
        average: t("Determines the type of evaluation. The unweighted average is the sum of the grades divided by the number of evaluations, while the weighted average considers different weights for each grade."),
        enableDiligences: t("The evaluator may issue new demands to the authors, who can respond without the need for a new step. The manager can always make requests."),
        allowAuthorSeeGrades: t("The application author will be able to see grades and feedback given by the evaluators at that stage. The evaluators are not identified."),
        teamEvaluationResultViewType: t("The visualization can be the average grade or show all the grades of the evaluations in detail.")
    }

    return (
        <TransparentCollapse header={t("Evaluation")}>
            <WideSpace direction="vertical" size="middle">
                <StyledAlert message={t("You will not be able to change this setting after assessments begin.")}
                             type="warning"/>
                <CollapseFormItem
                    input={<Switch data-cy="auto-allocation-switch"/>}
                    name={FORM_NAMES.AUTO_ALLOCATION}
                    label={t("Automatic allocation of evaluators")}
                    drawerProps={{title:t("Automatic allocation of evaluators"), bodyStyle:{background:neutral_2}, content:<AutoAllocationHelp />}}
                    extraFormItemProps={{valuePropName:"checked"}}
                    help={t("Make sure to enter evaluators at each step")}
                    renderContent={
                        (value) => (
                            <>
                                <CollapseFormItem
                                    name={FORM_NAMES.APPLICATION_MAX_EVALUATORS}
                                    label={t("Evaluation limit per application")}
                                    help={t("If empty, the allocation will be all evaluators evaluating all applications")}
                                    input={<InputNumber style={{width:"100%"}} disabled={!value} data-cy="application-max-evaluators-input"/>}
                                />
                            </>
                        )
                    }
                />
                <CollapseFormItem
                    input={scoreSlider}
                    name={FORM_NAMES.SCORE_RANGE}
                    label={t("Rating scale")}
                    drawerProps={{title:t("Scale Exemple"), bodyStyle:{background:neutral_2}, content:<ScoreHelp scoreRange={scoreRange}/>}}
                    help={t("Determines the scale that the evaluator can mark to evaluate. The scale can go from 0 to 10 or its range can be reduced")}
                    extraFormItemProps={{disabled: program?.get_valid_evaluation_count > 0}}
                    wideInput
                />
                <CollapseFormItem
                    input={<Select options={translationOptions(CHOICES_PROGRAM_EVALUATION_FORMAT)}
                                   data-cy="evaluation-format-select" disabled={hasEvaluations}/>}
                    name={FORM_NAMES.EVALUATION_FORMAT}
                    label={t("Evaluation Format")}
                    help={HELP.average}
                    drawerProps={{title:t("Evaluation Format"), bodyStyle:{background:neutral_2}, content:<AssessmentFormatHelp evaluationFormat={evaluationFormat}/>}}
                    extraFormItemProps={{disabled: program?.get_valid_evaluation_count > 0, initialValue: "unweighted"}}
                />
                <CollapseFormItem
                    input={<Switch />}
                    name={FORM_NAMES.ENABLE_EVALUATOR_DILIGENCES}
                    label={t("Allow the evaluator to issue a request")}
                    help={HELP.enableDiligences}
                    extraFormItemProps={{valuePropName:"checked"}}
                />
                <CollapseFormItem
                    input={<Switch disabled={hasEvaluations}/>}
                    name={FORM_NAMES.ALLOW_APPLICANTS_FEEDBACK_VIEW}
                    label={
                        <Space size="small">
                            {t("Allow the program applicant to see the grades and feedback given by the authors")}
                        </Space>
                    }
                    help={HELP.allowAuthorSeeGrades}
                    extraFormItemProps={{valuePropName:"checked"}}
                    drawerProps={{title:t("The candidate can see the grades"), bodyStyle:{background:neutral_2}, content:<FeedbackHelp/>}}
                    renderContent={
                        (value) => (
                            <>
                                <CollapseFormItem
                                    name={FORM_NAMES.TEAM_EVALUATION_RESULT_VIEW_TYPE}
                                    label={t("Application Author View")}
                                    help={HELP.teamEvaluationResultViewType}
                                    drawerProps={{title:t(evaluationResultViewType), bodyStyle:{background:neutral_2}, content:<AssessmentViewTypeHelp type={evaluationResultViewType}/>}}
                                    input={<Select options={translationOptions(CHOICES_TEAM_EVALUATION_RESULTS_VIEW_TYPE)} disabled={!value || hasEvaluations}/>}
                                />
                            </>
                        )
                    }
                />
            </WideSpace>
        </TransparentCollapse>
    );
}
