import React, {useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {FlexContainer} from "../authentication/StyledComponents";
import {Col, Input, Modal, Row, Typography} from "antd";
import Button from "../data_entry/Button";
import api from "../../services/api";
import {BuildFieldErrorsDict} from "../../utils/Utils";
import {
    useLayoutContext
} from "../../../modules/project/components/layout/context/LayoutContext";
import Drawer from "../layout/Drawer";

import RankingPositionCard from "./RankingPositionCard";
import Pagination from "../navigation/Pagination";
import Loading from "../feedback/Loading";
import Empty from "./Empty";


export default function DrawerRankingPlacements({
                                                    id,
                                                    open,
                                                    setOpen,
                                                    hasClose = true
                                                }) {

    const [isLoadingPositions, setIsLoadingPositions] = useState(false)
    const [search, setSearch] = useState('')
    const [positions, setPositions] = useState([])
    const {t} = useTranslation();
    const {updateLayoutContent} = useLayoutContext()

    function onClose() {
        setOpen(false)
    }


    const PaginationRequest = {
        url: `/ranking/${id}/get_ranking_data`,
        setIsLoading: setIsLoadingPositions,
        setData: setPositions,
        makeRequest: open && id,
        extraParams: {
            search: search
        },
        dependence: [search, open,id]

    }


    function handleCloseHanking() {
        Modal.confirm({
            title: t("Close ranking"),
            icon: <ExclamationCircleOutlined/>,
            content: t("Are you sure you want to close the ranking?"),
            onOk: async () => {
                try {
                    setIsLoadingPositions(true);

                    await api.post(`/ranking/${id}/finish_ranking`);
                    updateLayoutContent()
                } catch (error) {
                    BuildFieldErrorsDict(error, null, null)
                } finally {
                    setIsLoadingPositions(false);
                }
            },
            cancelButtonProps: {loading: isLoadingPositions},
            okText: t('Yes'),
            cancelText: t('No')

        });

    }

    return (
        <>
            <Drawer isGrayBody title={t('See ranking')} open={open}
                    onClose={onClose}


                    footer={
                        hasClose && <FlexContainer justify="end">
                            <Button
                                onClick={handleCloseHanking}
                                loading={isLoadingPositions}
                                danger>{t("Close ranking")}</Button></FlexContainer>
                    }>
                <FlexContainer style={{marginBottom: 16}} justify={'end'}>
                    <Input.Search style={{width: 264}}
                                  onSearch={(value) => setSearch(value)}
                                  allowClear/>
                </FlexContainer>
                <Row >
                    {isLoadingPositions ? <Col xs={24}><Loading/> </Col> :
                        positions?.length > 0 ?
                            positions?.map((position) =>
                                <Col xs={24} key={position.id}>
                                    <RankingPositionCard position={position}/></Col>) :
                            <Col xs={24}><Empty/></Col>}

                </Row>

                <FlexContainer style={{marginTop: 32}} justify={'end'}>
                    <Pagination request={PaginationRequest}

                                showTotal={(total) => t('total_ranking_users', {total: total})}
                                size="small"
                                hideOnSinglePage
                    />
                </FlexContainer>

            </Drawer>
        </>
    )
}
