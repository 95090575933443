import {EVALUATION_FORMATS} from "./Constants"
import {
    blue_1,
    dust_red_1,
    neutral_2,
    polar_green_1,
    primary_1,
    sunset_orange_1,
    sunset_orange_6
} from "./Colors";
import {
    AreaChartOutlined,
    BookOutlined,
    BulbOutlined,
    CreditCardOutlined,
    DollarOutlined,
    IdcardOutlined, PartitionOutlined,
    RocketOutlined, SmileOutlined, StarOutlined,
    TagOutlined, ThunderboltOutlined, UserOutlined
} from "@ant-design/icons";

export const CHALLENGE_MAPPING = 'challenge_mapping'
export const TEMPORARY = 'temporary'
export const CONTINUOUS = 'continuous'
export const ACTIVE = 'active'
export const INTERNAL_INNOVATION = 'internal_innovation'
export const OPEN_INNOVATION = 'open_innovation'
export const SOLUTION = 'solution'

export const CHOICES_PROGRAM_TYPE = [
    {value: OPEN_INNOVATION, label: 'Open innovation'},
    {value: INTERNAL_INNOVATION, label: 'Internal Innovation'},
    {value: CHALLENGE_MAPPING, label: 'Challenge Mapping'}
]


export const CHOICES_TEAM_TYPE = [
    {value: 1, label: 'Individual'},
    {value: 2, label: 'Team'},
    {value: 3, label: 'Company'},
]


export const USER_TAG = 1
export const PROGRAM_TAG = 2
export const PROJECT_TAG = 3
export const APPLICATION_TAG = 4


export const TAG_TARGETS_CHOICES = [
    {value: USER_TAG, label: 'User'},
    {value: PROGRAM_TAG, label: 'Program'},
    {value: PROJECT_TAG, label: 'Project'},
    {value: APPLICATION_TAG, label: 'Application'}
]




export const CHOICES_APPLICATION_NAME_TYPE = { //mapeamento desafio
    [CHALLENGE_MAPPING]:
        [{value: 'challenge', label: 'Challenge'},
            {value: 'problem', label: 'Problem'},
            {value: 'opportunity', label: 'Opportunity'},],
    default:
        [ //desafio
            {value: SOLUTION, label: 'Solution'},
            {value: 'idea', label: 'Idea'},
            {value: 'initiative', label: 'Initiative'},
            {value: 'application', label: 'Application'},
            {value: 'startup', label: 'Startup'},
            {value: 'company', label: 'Company'},
            {value: 'order', label: 'Order'},
            {value: 'demand', label: 'Demand'},
            {value: 'kaizen', label: 'Kaizen'},
            {value: 'collaborator', label: 'Employee'},
        ]
}


export const CHOICES_PROGRAM_STATUS = [
    {value: ACTIVE, label: 'Active'},
    {value: 'inactive', label: 'Inactive'},
    {value: 'archived', label: 'Archived'},
]


export const CHOICES_PROGRAM_DURATION = [
    {value: CONTINUOUS, label: 'Continuous'},
    {value: TEMPORARY, label: 'Temporary'},
]

export const CHOICES_PROGRAM_EVALUATION_FORMAT = [
    {value: 'weighted', label: EVALUATION_FORMATS['weighted']},
    {value: 'unweighted', label: EVALUATION_FORMATS['unweighted']},
]


export const CHOICES_PROJECT_STATUS = [
    {value: 'active', label: 'Active'},
    {value: 'inactive', label: 'Inactive'},
    {value: 'completed', label: 'Completed'}
]

export const CHOICES_PROJECT_CHECKPOINTS = [
    {value: 360, label: 'Yearly'},
    {value: 180, label: 'Biannual'},
    {value: 30, label: 'Monthly'},
    {value: 15, label: 'Biweekly'},
    {value: 7, label: 'Weekly'}
]

export function getChoicesForecastFrequency(checkpointFrequency=30) {
    return CHOICES_PROJECT_CHECKPOINTS.filter(freq => freq.value >= checkpointFrequency)
}

export const CHOICES_FORECAST_TYPES = [
    {value: 'automatic', label: 'Automatic'},
    {value: 'manual', label: 'Manual'},
]


export const CHOICES_REQUEST_TYPE = [
    {value: 'file', label: 'Mandatory attachment'},
    {value: 'text', label: 'Text response'},
]

export const CHOICES_REQUEST_STATUS = {
    waiting: {
        label: "Waiting",
        color: sunset_orange_1,
        border: sunset_orange_6
    },
    answered: {label: "Answered", color: blue_1, border: ""},
    rejected: {label: "Rejected", color: dust_red_1, border: ""},
    accepted: {label: "Accepted", color: polar_green_1, border: ""},
    closed_without_response: {
        label: "Closed Without Response",
        color: neutral_2,
        border: ""
    },
};

export const CHOICES_TEAM_EVALUATION_RESULTS_VIEW_TYPE = [
    {value: "score_average", label: "Grade average"},
    {value: "detailed_evaluation", label: "Detailed Assessment"}
]

export const CHOICES_GENRE = [
    {value: 'male', label: 'Male',},
    {value: 'female', label: 'Female',},
    {value: 'other', label: 'Other',},
    {value: 'not_declared', label: 'I prefer not to declare',},
]


export const ACTIONS_CAMPAIGN_CHOICES = [
    {value: 'submit_application', label: 'Submit application'},
    {value: 'classified_application', label: 'Classified application'},
    {value: 'aproved_application', label: 'Aproved application'},
    {value: 'finished_assessment', label: 'Finished assessment'},
    {value: 'all_actions', label: 'All Actions'},
]

export const APPLICATION_STATUS_CHOICES = [
    { value: 'in_filling', label: 'In filling'},
    { value: 'submitted', label: 'Submitted'},
    { value: 'waiting_evaluation', label: 'Waiting evaluation'},
    { value: 'approved', label: 'Approved'},
    { value: 'reproved', label: 'Reproved'},
    { value: 'grouped', label: 'Grouped'},
    { value: 'given_up', label: 'Given Up'},
    { value: 'archived', label: 'Archived' },
]

export const VALUE_CAMPAIGN = 'value'
export const PERCENTAGE_CAMPAIGN = 'percentage'

export const TYPE_BONUS_CAMPAIGN_CHOICES = [
    {value: VALUE_CAMPAIGN, label: 'Value'},
    {value: PERCENTAGE_CAMPAIGN, label: 'Percentage'}
]


export const ICONS_CHOICES = [
    {value: '1', label: <TagOutlined />},
    {value: '2', label: <AreaChartOutlined />},
    {value: '3', label: <BookOutlined />},
    {value: '4', label: <BulbOutlined />},
    {value: '5', label: <CreditCardOutlined />},
    {value: '6', label: <DollarOutlined />},
    {value: '7', label: <IdcardOutlined />},
    {value: '8', label: <RocketOutlined />},
    {value: '9', label: <ThunderboltOutlined />},
    {value: '10', label: <UserOutlined />},
    {value: '11', label: <StarOutlined />},
    {value: '12', label: <SmileOutlined />},
    {value: '13', label: <PartitionOutlined />},
]
