import React from 'react';
import styled from "styled-components";
import {Tooltip} from "antd";

const StyledDiv = styled.div`
    border: #f0f0f0 1px solid;
    border-radius: 8px;
    padding: 2px 4px 2px 4px;
    font-size: 14px;
`

export default function TransparentBadge({children, count, tooltip}) {
    return(
        <Tooltip title={tooltip}>
            <StyledDiv>
                {children}
                {count}
            </StyledDiv>
        </Tooltip>
    )
}
