import React from "react";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {Col, Row} from "antd";
import useDefaultGetRequest
    from "../../../../../global/hooks/useDefaultGetRequest";
import {CAMPAIGN_STATUS} from "../../../../../global/utils/Constants";
import Loading from "../../../../../global/components/feedback/Loading";
import CampaignCard
    from "../../../../../global/components/data_display/CampaignCard";
import Empty from "../../../../../global/components/data_display/Empty";
import {useProgramContext} from "../../../../ProgramsNavigator";


const Title = styled.h2`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
`
export default function SectionScheduledCampaignProgram() {
    const {t} = useTranslation();
    const {program} = useProgramContext()
    const {
        data: campaigns,
        isLoading
    } = useDefaultGetRequest({
        url: `/point_event/program/${program.id}/get_campaign_object_related/`,
        params: {
            status: CAMPAIGN_STATUS.SCHEDULED,
            // environment_rank:true,
            ordering: 'date_start',
            page_size: 1000
        },
        makeRequest: !!program.id,
        dependence: [program?.id],
        dataDefault: []
    })


    return (

        <>

            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Title
                        level={6}>{t('Scheduled campaigns')}</Title>
                </Col>
                {isLoading ? (
                    <Col xs={24}><Loading height={'100px'}/></Col>
                ) : campaigns?.results?.length > 0 ? campaigns?.results?.map((campaign) =>
                    <Col xs={24} key={campaign.id}>
                        <CampaignCard url={`point_event/${campaign.id}`}
                                      campaign={campaign}/></Col>
                ) : <Col xs={24}><Empty
                    description={t('There are no campaigns scheduled at the moment.')}/></Col>}

            </Row>


        </>

    );
}
