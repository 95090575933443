import { useRouteMatch, Switch } from "react-router-dom";
import { Route } from "react-router-dom/cjs/react-router-dom.min";
import { RoutePermission } from './../../../global/routes/PrivateRoute';
import Profile from "../../../global/pages/profile/Profile";
import SecurityProfilePage from "../../../global/pages/profile/SecurityProfilePage";
import TeamListManager from "../../../global/components/data_entry/TeamSelector/TeamListManager";

export default function RoutesProfile() {
    let { path } = useRouteMatch();
    return (
        <Switch>
            <Route
                exact
                path={`${path}`}
                render={(props) =><Profile />}
            />
            <Route
                exact
                path={`${path}/teams`}
                render={(props) => <TeamListManager />}
            />
            <Route
                exact
                path={`${path}/security`}
                render={(props) => <SecurityProfilePage />}
            />
        </Switch>
    );
}