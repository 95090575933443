import { useState, useEffect } from 'react';

export const useTabFocus = () => {
    const [isFocused, setIsFocused] = useState(document.visibilityState === 'visible');
    const [lastFocusedTab, setLastFocusedTab] = useState(null);

    useEffect(() => {
        const handleVisibilityChange = () => {
            const isTabVisible = document.visibilityState === 'visible';
            setIsFocused(isTabVisible);

            if (isTabVisible) {
                localStorage.setItem('last-focused-tab', window.name || `tab-${Date.now()}`);
                setLastFocusedTab(localStorage.getItem('last-focused-tab'));
            }
        };

        if (!window.name) {
            window.name = `tab-${Date.now()}`;
        }

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        setLastFocusedTab(localStorage.getItem('last-focused-tab'));
    }, []);

    return { isFocused, lastFocusedTab, thisTab: window.name };
};
