import api from "../services/api";
import {BuildFieldErrorsDict} from "./Utils";

export const getProgram = async (id,setIsLoading, setProgram) => {
    try {
        setIsLoading && setIsLoading(true)
        const response = await api.get(`programs/${id}`);
        setProgram(response.data);

    } catch (error) {
        BuildFieldErrorsDict(error, null, false)
    } finally {
        setIsLoading && setIsLoading(false);
    }
};

