import React from 'react'
import styled from 'styled-components'


const TwinContainer = styled.div`
  width: fit-content;
  max-width: 100%;
  display: flex;
  //gap: 4px;
`

const GenericButtonContainer = styled.div`
  position: relative;

  
  ${({active, is_hover_brother}) => active && !is_hover_brother ? 'none' : `
    & *{
        color:#ADADAD;
        background-color: #efefef;
        
    }
    
    &:not(:hover) svg path{
            fill: #ADADAD;
        }
  `}
`

const LeftContainer = styled(GenericButtonContainer)`




`

const RightContainer = styled(GenericButtonContainer)`



`


export default function TwinButtons({key,initialActive, left, right, disabled}) {
    const [active, setActive] = React.useState(initialActive ? initialActive : '')
    const [currentHover, setCurrentHover] = React.useState()
    const elementLeftContainer = React.useRef()
    const elementRightContainer = React.useRef()

    function handleClickButtonContainer(typeButton) {
        if (typeButton === active) {
            setActive(null)
            return;
        }
        setActive(typeButton)
    }

    React.useEffect(() => {
        setActive(initialActive)
    }, [initialActive])

    function handleHoverButton({currentTarget}) {
        if (!currentTarget.contains(elementLeftContainer.current)) {
            setCurrentHover('right')
        } else {
            setCurrentHover('left')
        }

    }

    function handleLeaveButton({target}) {
        setCurrentHover(null)
    }


    return (
        <TwinContainer key={key}>
            <LeftContainer ref={elementLeftContainer}
                           active={active === 'left'}
                           is_hover_brother={currentHover === 'right'}
                           onClick={() => !disabled && handleClickButtonContainer('left')}
                           onMouseEnter={(event) => !disabled && handleHoverButton(event)}
                           onMouseLeave={(event) => !disabled && handleLeaveButton(event)}>
                {left}
            </LeftContainer>
            <RightContainer ref={elementRightContainer}
                            active={active === 'right'}
                            is_hover_brother={currentHover === 'left'}
                            onClick={() => !disabled && handleClickButtonContainer('right')}
                            onMouseEnter={(event) => !disabled && handleHoverButton(event)}
                            onMouseLeave={(event) => !disabled && handleLeaveButton(event)}>
                {right}
            </RightContainer>
        </TwinContainer>
    )
}