import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from "react-i18next";
import InputContainer from "./InputContainer";
import Label from "./Label";
import MessageError from "./MessageError";
import {neutral_1} from "../../global/utils/Colors";


const ValueContainer = styled.div`
  width: 100%;
  text-align: start;
  padding: 1rem 1rem;
  background: ${neutral_1};
  border: 1px solid rgb(221, 221, 221);
  border-radius: 4px;
  position: relative;
  display: flex;
  gap: 16px;
  justify-content: center;
  flex-wrap: wrap;

`

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonContainer = styled.button`

  border-radius: 4px;
  padding: 8px 16px;
  cursor: ${({disabled}) => disabled ? 'not-allowed' : 'pointer'};
  border: 1px solid rgb(221, 221, 221);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  outline: none;


  background-color: ${({
                         actived,
                         disabled
                       }) => actived ? disabled ? 'rgb(221, 221, 221)' : '#004999;' : 'transparent;'};
  color: ${({
              actived,
              disabled
            }) => actived && !disabled ? 'white' : '#949494'};

  &:hover {
    color: white ;
    background-color: #004999;
  }

  font-size: 18px;

`


const HiddenInput = styled.input`
  display: none;
`


export default function YesNoInputForm({
                                           id,
                                           required,
                                           value,
                                           name,
                                           label,
                                           messageError,
                                           elementDescription,
                                           onClick,
                                           disabled,
                                           ...props
                                       }) {
    const {t} = useTranslation();
    const [options, setOptions] = useState([
        {value: false, label: t('No')}, {
            value: true,
            label: t('Yes')
        }])
    const [valueHidden, setValueHidden] = useState(value === 'true' || value === true ? true : value === 'false' || value === false ? false : '') // gambiarra


    function handlerClickButton(item) {
        const newValue = {...item, name}

        if (valueHidden === item.value) {
            newValue.value = undefined;
        }
        setValueHidden(newValue.value)
        onClick && onClick(newValue)

    }


    return (
        <InputContainer>
            {label &&
                <Label htmlFor={id} required={required}>{label}</Label>}
            {elementDescription}
            <MainContainer>
                <ValueContainer data-cy="yes-no-container">
                    {options.map((item, index) => (
                        <ButtonContainer
                            disabled={disabled}
                            onClick={(event) => {
                                event.preventDefault();
                                !disabled && handlerClickButton(item)
                            }}
                            key={`${id}${name}${item.value}${index}`}
                            actived={valueHidden === item.value}
                            data-cy="yes-no-button">
                            <span>{item.label}</span>
                        </ButtonContainer>
                    ))}

                    <HiddenInput name={name} value={valueHidden} readOnly/>
                </ValueContainer>
                {messageError && <MessageError
                    style={{textAlign: 'left'}}>{messageError}</MessageError>}
            </MainContainer>


        </InputContainer>
    )

}