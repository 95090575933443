import React, {useEffect, useRef, useState} from 'react';
import './style.css'
import api from "../../../../../global/services/api";

import Loading from "../../../../../global/components/feedback/Loading";
import PhaseFormBuilder
    from "../../../../../components/Phases/PhaseFormBuilder";
import {useParams} from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import NotContent from "../../../../../components/NotContent/NotContent";
import Select from "react-select";
import {Alert} from "react-bootstrap";
import {
    useAutoSaveContext
} from "../../../../../global/context/AutoSaveContext";

import {Modal as ModalAntd} from 'antd';
import {getProgram} from "../../../../../global/utils/_query";
import * as ConstantsMessage
    from "../../../../../global/utils/fileWithMessage";
import ButtonDefault from "../../../../../components/Buttons/ButtonDefault";
import {BuildFieldErrorsDict} from "../../../../../global/utils/Utils";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {
    ToastNotification
} from "../../../../../global/components/feedback/ToastNotification";
import {
    CardContainer
} from "../../../../../global/components/misc/CardContainer";
import {
    FlexContainer
} from "../../../../../global/components/authentication/StyledComponents";
import {
    danger,
    neutral_10,
    neutral_5
} from "../../../../../global/utils/Colors";

import AddSectionButton from "./components/AddSectionButton";
import {v4 as uuid} from "uuid";
import ModalFormSection from "./components/ModaFormSection";
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";
import SectionButton from "./components/SectionButton";
import ModalPhaseFormPreview from "./components/ModalPhaseFormPreview";

const AlertContainer = styled.div`
    width: 74%;
`
const SectionListContainer = styled(FlexContainer)`
    margin-bottom: 24px;
`


const ListSectionsContainer = styled(FlexContainer)`
    //flex: 1;
    width: min-content;
    overflow-y: scroll;
    padding-bottom: 8px;

`


export default function PhaseForm() {
    let {phase_id} = useParams()
    let {id} = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [phaseForm, setPhaseForm] = useState(false)
    const [openEditModal, setOpenEditModal] = useState(false)
    const [editedSection, setEditedSection] = useState()
    const [isSaving, setIsSaving] = useState(false)
    const [copyOptions, setCopyOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState([])
    const [showModalPreview, setShowModalPreview] = useState(false);
    const [showModalCopy, setShowModalCopy] = useState(false);
    const [isLoadingImport, setIsLoadingImport] = useState(false)
    const {t} = useTranslation();
    const formRef = useRef([]);
    const [currentSection, setCurrentSection] = useState(null);
    const [form, setForm] = useState([]);

    // console.log('renderizou')


    useEffect(() => {
        const getPhaseData = async () => {
            try {
                setIsLoading(true)
                const response = await api.get(`/phases/${phase_id}/phase_form`);
                setPhaseForm(response.data)
                formRef.current = response.data.form ? response.data.form : []
                // setFormulario(response.data.form ? response.data.form : [])


            } catch (error) {
                BuildFieldErrorsDict(error, null, false)
            } finally {
                setIsLoading(false);
            }
        };
        getPhaseData()
    }, [])

    //INIT AUTO SAVE
    const {
        showAutoSave,
        hideAutoSave,
        startSave,
        setAutoSaveSuccess,
        setAutoSaveError
    } = useAutoSaveContext()
    const [isChangedForm, setIsChangedForm] = useState(false)
    const [isRequestSending, setIsRequestSending] = useState(false)

    useEffect(() => {
        showAutoSave()
        return () => {
            hideAutoSave()
        };
    }, [])


    // const handleAutoSave = async (form, removedItem = null) => {
    //
    //     try {
    //
    //         const data = {'form': form};
    //         await api.patch(`phase_forms/${dataRequest.id}`, data);
    //         if (removedItem) {
    //             api.post(`phase_forms/${dataRequest.id}/remove_question`, {
    //                 "question": removedItem.name
    //             });
    //         }
    //     } catch (e) {
    //         console.log(e)
    //         BuildFieldErrorsDict(e, null, false)
    //     } finally {
    //         // setIsRequestSending(false)
    //     }
    // }
    //END AUTO SAVE

    //Functions of component manipulation
    const onChangeValue = (index, key, value) => {

        formRef.current[index][key] = value
        updateCurrentForm()


    }


    function updateCurrentForm() {

        const newPhaseForm = {...phaseForm}
        if (currentSection) {
            const newSections = [...newPhaseForm.sections.sections.map((section) => {
                if (currentSection.id === section.id) {
                    section.questions = formRef.current
                }
                return section
            })]

            newPhaseForm.sections.sections = newSections

        } else {
            newPhaseForm.form = formRef.current
        }
        setPhaseForm(newPhaseForm)
        validateLinkedSections()
    }


     function validateLinkedSections() {
        setPhaseForm((phaseForm) => {

            const allForm = [{section_id:null, form:phaseForm.form}, ...phaseForm.sections.sections.map(({questions,id}) => ({section_id:id,form:questions}))]

            const newLinked = [];
            allForm.forEach(({section_id, form}) => {
                form.forEach((question) => {

                    if (question.type === 'section') {
                        question.choices.forEach((choice) => {
                            if (choice.section && !phaseForm?.sections?.linked_sections.some(({id}) => id === choice.section)) {
                                newLinked.push({
                                    section_id: choice.section,
                                    linked_question: question.name,
                                    parent_section_id: section_id
                                })
                            }
                        })
                    }
                })
            })

            const newPhaseForm = {
                ...phaseForm,
                sections: {
                    ...phaseForm?.sections,
                    linked_sections: newLinked
                }
            }
            return newPhaseForm

        })
    }


    const copyForm = async () => {
        setShowModalCopy(true)
        try {
            setIsLoadingImport(true)
            const response = await api.get(`phases/${phase_id}/clone_phase_form`);

            setCopyOptions(response.data)
        } catch (error) {
            BuildFieldErrorsDict(error, null, false)
        } finally {
            setIsLoadingImport(false)
        }
    }

    const handleCopyPhase = (phase) => {
        setIsLoading(true)

        const newPhaseForm = {
            ...phaseForm,
            form: phase.json_form || [],
            sections: phase.sections
        }
        setPhaseForm(newPhaseForm)

        setTimeout(() => {
            setIsLoading(false);
             handleMainForm()
        }, 1000);
        // // getForm(phase)
    }

    const handleSaveForm = async () => {
        // if (formRef.current.length === 0) {
        //     ToastNotification(t("Empty form cannot be saved"), 'warning');
        //     return
        // }
        const data = {
            form: phaseForm.form,
            sections: phaseForm.sections
        };
        try {
            const response = await api.patch(`phase_forms/${phaseForm.id}`, data);
            if (response.status === 200) {
                ToastNotification(t(ConstantsMessage.SUCCESS));
            }
        } catch (error) {
            BuildFieldErrorsDict(error, null, t('Error to save form'))
        }
    };
    const handleCloseModalCopy = () => {
        setShowModalCopy(false)
        setSelectedOption({})
    }

    const openPreview = () => {
        //todo revalidar
        setShowModalPreview(true)
    }

    const handleSaveModal = () => {
        handleCloseModalCopy()
        handleSaveForm()
    }


    function handleCreateNewSection({name}) {
        const newSection = {
            "id": `section_${uuid()}`,
            "name": name,
            "questions": []
        }

        const newPhaseForm = {...phaseForm}
        newPhaseForm.sections.sections = [...newPhaseForm.sections.sections, newSection]
        setPhaseForm(newPhaseForm)
        setIsSaving(true)
        handleOpenSection(newSection)
    }

    function handleUpdateSection({name}) {
        const newSection = {
            ...editedSection,
            "name": name,
        }

        const newPhaseForm = {...phaseForm}
        newPhaseForm.sections.sections = [...newPhaseForm.sections.sections.map((section) => {
            if (section.id === newSection.id) {
                return newSection
            }
            return section
        })]
        setPhaseForm(newPhaseForm)
        setIsSaving(true)
    }

    useEffect(() => {
        if (isSaving) {
            handleSaveForm()
            setIsSaving(false)
        }
    }, [isSaving])


    function handleOpenSection(section, position) {
        formRef.current = section.questions
        setCurrentSection(section)


        // setForm(section.questions)
        // setTimeout(() => setCurrentSection(section), 500 )

    }

    function handleOpenEditModal(section) {
        setOpenEditModal(true)
        setEditedSection(section)
    }

    function handleDeleteSection(deletedSection) {
        ModalAntd.confirm({
            icon: <ExclamationCircleOutlined/>,
            content: t(
                "Are you sure you want to delete the section?",
            ),
            onOk: async () => {

                const newPhaseForm = {...phaseForm}
                newPhaseForm.sections.sections = [...newPhaseForm.sections.sections.filter((section) => section.id !== deletedSection.id)]
                setPhaseForm(newPhaseForm)
                if (deletedSection.id === currentSection?.id) {
                    handleMainForm()
                }
                setIsSaving(true)
            },
            okButtonProps: {type: 'danger'},
            okText: t('Delete'),
            cancelText: t('Cancel')

        })


    }

    function disabledRemoveSection(section) {
        return phaseForm.sections.linked_sections?.some((linked_section) => linked_section.section_id === section.id)
    }


    function handleMainForm() {
        formRef.current = phaseForm.form
        setCurrentSection(null)
    }

    return (
        <>
            <FlexContainer gap={10}
                           style={{marginBottom: 16, maxWidth: '100%'}}
                           align={'start'}>
                <SectionButton name={t('Form')}
                               minContent
                               actived={!currentSection}
                               onClick={handleMainForm}/>
                {phaseForm?.sections?.sections?.length > 0 &&
                    <ListSectionsContainer gap={10}>
                        <ModalFormSection open={openEditModal}
                                          setOpen={setOpenEditModal}
                                          onSave={handleUpdateSection}
                                          editedSection={editedSection}

                        />
                        {phaseForm.sections.sections.map((item, position) =>
                            <SectionButton key={item.id}
                                           actived={currentSection?.id === item.id}
                                           onClick={() => handleOpenSection(item, position)}
                                           disabledRemoveSection={disabledRemoveSection(item)}  {...item}
                                           onEditClick={() => handleOpenEditModal(item)}
                                           onDeleteClick={() => handleDeleteSection(item)}
                            />)}
                    </ListSectionsContainer>}
                <AddSectionButton onClick={handleCreateNewSection}/>
            </FlexContainer>
            <CardContainer>
                {phaseForm?.applicationSubmited ?
                    <AlertContainer><Alert variant="warning">
                        <Alert.Heading>{t("Attention!")}</Alert.Heading>
                        <p>
                            {t("there are applications submitted at this phase, edits of this form will not be applied to the submitted applications.")}
                        </p>
                    </Alert></AlertContainer> : ''}

                <div className={'phase-form-container'}>

                    {isLoading ? <Loading/> :
                        <PhaseFormBuilder
                            // sections={phaseForm?.sections?.sections}
                            phaseForm={phaseForm}
                            formRef={formRef}
                            updateCurrentForm={updateCurrentForm}
                            currentSection={currentSection}
                            handleOpenPreview={openPreview}
                            // handleAutoSave={handleAutoSave}
                            // setFormulario={setFormulario}
                            currentForm={form}
                            onChangeValue={onChangeValue}
                            copyForm={copyForm}/>

                    }
                    <div className={'action-buttons'}>
                        <ButtonDefault
                            style={{marginTop: 0, marginBottom: 0}}
                            action={'primary'}
                            onClick={handleSaveForm}
                            label={t('Save')}
                            data-cy="submit-button"/>
                    </div>
                    <ModalPhaseFormPreview phaseForm={phaseForm}
                                           open={showModalPreview}
                                           setOpen={setShowModalPreview}/>

                    <Modal size="lg" show={showModalCopy}
                           onHide={handleCloseModalCopy} animation={false}>
                        <Modal.Header closeButton>
                            <h1 className={'modal-title-custom'}>{t("Import form")}</h1>
                        </Modal.Header>
                        <Modal.Body>
                            {isLoadingImport ? <Loading/> :
                                copyOptions.length ? <>
                                    <label>{t("Programs")}</label>
                                    <Select
                                        options={copyOptions.map((program) => ({
                                            label: program.name,
                                            value: program.id,
                                        }))}
                                        onChange={(e) => setSelectedOption(e)}/>
                                    {copyOptions.filter((program) => program.id === selectedOption.value).map((program) => {

                                        return <div key={program.id}
                                                    id={program.id}
                                                    className={'copy-program-container'}>
                                            {
                                                program.phases_with_form.filter(({type}) => type === 1).map((phase, index) => (
                                                    <div key={index}
                                                        className={'copy-phase-container'}>
                                                        <button
                                                            onClick={() => handleCopyPhase(phase)}>{phase.title}
                                                        </button>
                                                    </div>
                                                ))
                                            }
                                        </div>;

                                    })}
                                </> : <NotContent
                                    title={t("Don't have forms to copy")}/>}
                        </Modal.Body>
                        {!isLoadingImport && copyOptions.length ? <>
                            <Modal.Footer>
                                <ButtonDefault label={t("Close")}
                                               action={"secondary"}
                                               onClick={handleCloseModalCopy}/>
                                <ButtonDefault label={t("Save")}
                                               action={"primary"}
                                               onClick={handleSaveModal}/>
                            </Modal.Footer>
                        </> : ''}
                    </Modal>
                </div>
            </CardContainer>

        </>

    );
}

